import React, {useMemo} from 'react'

import {v4 as uuid} from 'uuid'

import {SkeletonItem} from '@/components/skeleton'

import './data-table.scss'

const HEADER_LIST = [50, 50, 100, 50, 50, 100, 100, 50, 100]
const ROW_LIST = [65, 50, 75, 80, 60, 45, 75, 60, 80, 75]

const getHeaderRow = (columnAmount: number) => (
  <tr>
    {HEADER_LIST.slice(0, columnAmount || HEADER_LIST.length).map(
      (width, index) => (
        <th key={index} className="ant-table-cell">
          <SkeletonItem
            className="data-table__skeleton-item"
            width={width}
            height={18}
          />
        </th>
      )
    )}
  </tr>
)
const getBodyRow = (itemWidth: number, columnAmount: number) => (
  <tr key={uuid()} className="ant-table-row">
    {new Array(columnAmount || 9).fill(`${itemWidth}%`).map((width, index) => (
      <th key={index} className="ant-table-cell">
        <SkeletonItem
          className="data-table__skeleton-item"
          width={width}
          height={15}
        />
      </th>
    ))}
  </tr>
)

type TProps = {
  columnAmount: number
  rowAmount?: number
}

export const DataTableSkeletonView: React.FC<TProps> = ({
  columnAmount,
  rowAmount = 0,
}) => {
  const rowList = rowAmount > 0 ? ROW_LIST.slice(0, rowAmount) : ROW_LIST
  return useMemo(
    () => (
      <div className="data-table__skeleton data-table">
        <div className="ant-table ant-table-small ant-table-bordered ant-table-scroll-horizontal">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table>
                <thead className="ant-table-thead">
                  {getHeaderRow(columnAmount)}
                </thead>
                <tbody className="ant-table-tbody">
                  {rowList.map(itemWidth =>
                    getBodyRow(itemWidth, columnAmount)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ),
    [columnAmount, rowList]
  )
}
