import React from 'react'

import {useParams} from 'react-router-dom'

import {Paper, PaperVariant} from '@/components/paper/paper'

import AnnouncementForm from './announcement-form'

const AnnouncementNew = () => {
  const {id} = useParams() as {
    id: string
  }

  return (
    <Paper variant={PaperVariant.form}>
      <AnnouncementForm id={id} />
    </Paper>
  )
}

export default AnnouncementNew
