import request from 'superagent'

import {TITSMSelectorNoBC} from '@/modules/ITSM/typedef'
import {getHeadersWithGRPC} from '@/services/api'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {commentsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {Comment} from '../../services/openAPI/Api'

export const getComments = async ({
  entity,
  limit,
  bookmark,
}: TITSMSelectorNoBC): Promise<ISuperAgentResMultiple<Comment>> =>
  await request
    .get(commentsApi().get(entity, limit, bookmark))
    .set(getHeadersWithGRPC())

export const postComment = async (
  external_id: string,
  text: string,
  entity: string
) =>
  await request
    .post(commentsApi().post)
    .send({external_id, text, entity})
    .set(getHeadersWithGRPC())

export const markCommentAsReadByUser = async (id: string) =>
  await request.post(commentsApi().postReadBy(id)).set(getHeadersWithGRPC())

export const getSingleComment = async (
  id: string
): Promise<ISuperAgentResSingle<Comment>> =>
  await request.get(commentsApi().getOne(id)).set(getHeadersWithGRPC())
