import {useCallback, useEffect, useMemo} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'

import {
  AnnouncenentsQuickFilters,
  ItsmAssets,
  TAnnouncement,
} from '@/modules/ITSM/typedef'
import {TKey, translate} from '@/services/i18n'
import {
  setItsmTableFilters,
  setItsmTableQuickFilters,
} from '@/modules/ITSM/store/list-table/table-actions'
import {usePrevious} from '@/hooks/usePrevious'
import {
  selectItsmTableFilters,
  selectItsmTableQuickFilter,
} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'
import {TFilterListTable} from '@/modules/ITSM/components/itsm-list-table/typedf'

const useGetQuickFilters = (asset: ItsmAssets) => {
  const dispatch = useDispatch()

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, asset)
  )

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<TAnnouncement>(state, asset)
  )

  const getAssetQuickFilters = useMemo((): Array<{
    name: string
    query?: Record<string, any>
  }> => {
    return [
      {name: AnnouncenentsQuickFilters.All, query: {}},
      {
        name: AnnouncenentsQuickFilters.Active,
        query: {
          $and: [
            {
              active_to: {$gte: moment().toISOString()},
            },
          ],
        },
      },
    ]
  }, [])

  const setQuickFilters = useCallback(
    (
      quickFilterActivePassed?:
        | TFilterListTable<TAnnouncement>
        | Record<string, never>
    ) => {
      const {query} =
        getAssetQuickFilters.find(({name}) => name === quickFilterActive) || {}

      dispatch(
        setItsmTableFilters(asset, {
          ...query,
          ...quickFilterActivePassed,
        })
      )
    },
    [asset, dispatch, getAssetQuickFilters, quickFilterActive]
  )

  const prevQuickFilterActive = usePrevious(quickFilterActive)

  useEffect(() => {
    if (
      (Object.keys(filters).length > 0 &&
        quickFilterActive === AnnouncenentsQuickFilters.All) ||
      (Object.keys(filters).length === 0 &&
        quickFilterActive === AnnouncenentsQuickFilters.Active)
    ) {
      setQuickFilters(
        quickFilterActive === AnnouncenentsQuickFilters.Active ? filters : {}
      )
    }
  }, [filters, prevQuickFilterActive, quickFilterActive, setQuickFilters])

  const getQuickFilters = () => {
    return (getAssetQuickFilters || []).map(({name}) => ({
      title: translate(name as TKey),
      isActive: quickFilterActive === name,
      onClick: () =>
        dispatch(
          setItsmTableQuickFilters(asset, name as AnnouncenentsQuickFilters)
        ),
    }))
  }

  return {getQuickFilters, setQuickFilters}
}

export default useGetQuickFilters
