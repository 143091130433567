export const options = [
  {label: 'customer', value: 'customer'},
  {label: 'supplier', value: 'supplier'},
  {label: 'both', value: 'both'},
]

export const iconResolved = {
  approved: {
    fontSize: '24px',
    color: 'green',
  },
  removed: {
    fontSize: '24px',
    color: 'red',
  },
}
