import {useCallback, useEffect, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'

export function useInitFetchData<T extends Record<string, any>>(
  args?: {
    key: keyof T
    asyncF: () => Promise<Record<string, any>[]>
  }[]
) {
  const [initFetchData, setInitFetchData] = useState<
    {[key in keyof T]: Record<string, any>[]} | Record<string, never>
  >()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getData = useCallback(
    async (asyncF: () => Promise<Record<string, any>[]>) => {
      try {
        handleRequested()

        const result = await asyncF()

        if (result) {
          handleSuccess()
          return result
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  useEffect(() => {
    if (
      !initFetchData ||
      (args && Object.keys(initFetchData || []).length !== args.length)
    ) {
      let sequence = Promise.resolve()

      let newinitFetchData = {...initFetchData}

      args?.forEach(async ({asyncF, key}, i) => {
        sequence = sequence
          .then(() => {
            return getData(asyncF)
          })
          .then(async result => {
            newinitFetchData = {
              ...newinitFetchData,
              [key]: result,
            }

            const isTheLast = i === args.length - 1

            if (isTheLast) {
              setInitFetchData(newinitFetchData)
            }
          })
      })
    }
  }, [initFetchData, getData])

  return {initFetchData, status}
}
