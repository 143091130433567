export const twoColumns = {
  xs: {span: 24},
  lg: {span: 12},
}

export const threeColumns = {
  xs: {span: 24},
  sm: {span: 12},
  xl: {span: 8},
}
