import {TIncident} from '../typedf'

export const getInitialValues = (values: TIncident) => {
  return {
    customer_product: values.customer_product?.uuid,
    supplier_product: values.supplier_product?.uuid,
    assignment_group: values.assignment_group?.uuid,
    assigned_to: values.assigned_to?.uuid,
    location: values.location?.uuid,
    expected_end: values.expected_end,
    expected_start: values.expected_start,
    short_description: values.short_description,
    description: values.description,
  }
}
