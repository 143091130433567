import {
  initialSlaValues,
  TSlaValues,
} from '@/modules/ITSM/components/incident/incident-reducer'

import {IncidentState} from '../constants'
import {TIncident} from '../typedf'

import {getRemainingTime} from './get-sla-ramaining-time'

export const getSLAtime = (record: TIncident): TSlaValues => {
  const {state_id} = record
  const elapsedTime = record.sla_elapsed_time || 0

  let slaValues: TSlaValues = initialSlaValues

  if (
    state_id === IncidentState.Open ||
    state_id === IncidentState.Pre_on_hold
  ) {
    slaValues = {
      endTime: record.response_time_due,
      startTime: record.virtual_response_time_start,
      elapsedTime,
    }
  } else if (
    state_id === IncidentState.In_progress ||
    state_id === IncidentState.On_hold ||
    state_id === IncidentState.Resolved ||
    state_id === IncidentState.Closed
  ) {
    slaValues = {
      endTime: record.resolution_time_due,
      startTime: record.virtual_resolution_time_start,
      elapsedTime,
    }
  }

  const remainingTime = getRemainingTime(record, slaValues.endTime)

  return {...slaValues, remainingTime}
}
