import React from 'react'

export const Telia = ({width, height}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 269.8 283.4"
  >
    <path
      d="M186.8,210.9c36.8-21.3,63-48.1,75.2-76.6a278.07,278.07,0,0,0,7.8-65.9c0-3.3-.2-6.4-.4-9.4-2.1,34.5-46.7,77.8-105.6,106.1C92.7,199.3,39.4,208.5,15.6,182.4A249.11,249.11,0,0,0,47.5,230C76.6,256.1,124.9,246.8,186.8,210.9Z"
      fill="#990ae3"
    />
    <path
      d="M155.4,140.8C222.5,108.4,269.2,62.4,261,31.9,247.7,8.8,220.6,0,191.6,0,105.6,0,0,56.7,0,129c0,9.3,2,19.7,5.7,30.6C25.3,185.6,85.3,174.6,155.4,140.8Z"
      fill="#990ae3"
    />
    <path
      d="M149.7,243.3h0c-47.2,19.1-74.2,13-89.1,1.1l-.2-.2c23.1,23,49.4,39.2,72.5,39.2,40.4,0,95.8-49,122-125.1C240.1,185.8,208.5,219.6,149.7,243.3Z"
      fill="#990ae3"
    />
    <path
      d="M149.7,243.3h0c58.7-23.7,90.4-57.5,105.2-84.9,2.7-7.7,5-15.8,7.1-24.1-12.1,28.5-38.4,55.3-75.2,76.6-61.9,35.9-110.1,45.2-139.4,19.2,4.2,4.9,8.6,9.7,13.1,14.2l.2.2C75.6,256.3,102.6,262.4,149.7,243.3Z"
      fill="#fff"
    />
    <path
      d="M163.7,165.2c58.9-28.3,103.5-71.7,105.6-106.1A68,68,0,0,0,261,31.9c8.1,30.5-38.6,76.5-105.6,108.9C85.3,174.6,25.3,185.6,5.7,159.6a170.37,170.37,0,0,0,9.7,22.9C39.2,208.6,92.6,199.4,163.7,165.2Z"
      fill="#fff"
    />
  </svg>
)
