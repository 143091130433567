import {ContentState, EditorState} from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import momentTimezone from 'moment-timezone'

import store from '@/redux/store'
import {DATE_FORMAT} from '@/constants'
import {generateConfig} from '@/generateConfig'

/**
 * Exports functions used in whole system
 *
 */

export const randomString = string_length => {
  const chars = '0123456789'
  let randomString = ''
  for (let i = 0; i < string_length; i++) {
    let rnum = Math.floor(Math.random() * chars.length)
    randomString += chars.substring(rnum, rnum + 1)
  }
  return randomString
}

export const getName = record => {
  return record.name
}

export const getAssignmentGroupsName = record => {
  return record ? record.name && record.name : null
}

export const getFullLocation = record => {
  if (record && record.full_location) return record.full_location
  else
    return record
      ? (record.country ? record.country : '') +
          (record.city ? ', ' + record.city : '') +
          (record.street ? record.street : '')
      : null
}

export const round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export const compareArrays = (arrFirst, arrSec) => {
  let arrFirstNew, arrSecNew

  if (arrFirst.length > arrSec.length) {
    arrFirstNew = arrFirst
    arrSecNew = arrSec
  } else {
    arrFirstNew = arrSec
    arrSecNew = arrFirst
  }

  return arrFirstNew.filter(x => !arrSecNew.includes(x)).length > 0
}

export const removeDuplicatesFromObj = (array, key) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter(i => i[key] !== item[key])

    return [...removed, item]
  }, [])
}

export const generateCopyLink = (entity, id) => {
  const {channelID} = store.getState().entries
  return `${
    generateConfig().BASE_URL
  }/blits/itsm/service-management/${entity.replace(
    '_',
    '-'
  )}/${id}?space_id=${channelID}`
}

export const setStateAction = (newState, setNewState) => {
  setNewState(prevState => {
    return {
      ...prevState,
      ...newState,
    }
  })
}

export const extensionPicker = fileType => {
  let extType = ''
  switch (fileType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      extType = 'xlsx'
      break
    case 'application/vnd.ms-excel':
      extType = 'xls'
      break
    case 'application/msword':
      extType = 'doc'
      break
    case 'application/octet-stream':
      extType = 'xls'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      extType = 'docx'
      break
    case 'text/plain':
      extType = 'txt'
      break
    case 'application/pdf':
      extType = 'pdf'
      break
    case 'image/jpg':
      extType = 'jpg'
      break
    case 'image/jpeg':
      extType = 'jpeg'
      break
    case 'image/png':
      extType = 'png'
      break
    case 'image/svg+xml':
      extType = 'svg'
      break
    case 'application/zip':
      extType = 'zip'
      break
    case 'message/rfc822':
      extType = 'eml'
      break
    case 'application/vnd.ms-outlook':
      extType = 'msg'
      break
    case 'text/csv':
      extType = 'csv'
      break
    case 'html':
      extType = 'text/html'
      break
    case 'video/mp4':
      extType = 'mp4'
      break
    default:
      extType = fileType
      break
  }
  return extType
}

export const fillFormWithMap = (results, form) => {
  for (let index = 0; index < results.length; index++) {
    switch (results[index].types[0]) {
      case 'route': {
        const number =
          results[0].types[0] === 'street_number' ? results[0].short_name : ''
        form.setFieldsValue({street: results[index].short_name + ',' + number})
        break
      }
      case 'intersection': {
        const nbr =
          this.state.results[0].types[0] === 'street_number'
            ? this.state.results[0].short_name
            : ''
        form.setFieldsValue({street: results[index].short_name + ',' + nbr})
        break
      }
      case 'locality':
        form.setFieldsValue({city: results[index].short_name})
        break
      case 'neighborhood':
        form.setFieldsValue({city: results[index].long_name})
        break
      case 'administrative_area_level_1':
        form.setFieldsValue({state_province: results[index].long_name})
        break
      case 'country':
        form.setFieldsValue({country: results[index].long_name})
        break
      case 'postal_code':
        form.setFieldsValue({zip: results[index].short_name})
        break
      default:
        break
    }
  }
}

export const parseRichText = value => {
  const contentBlock = htmlToDraft(value)
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    return EditorState.createWithContent(contentState)
  }
}

export const getTimeInTimezone = (val, timeZone) => {
  const date = momentTimezone.tz(momentTimezone(val), timeZone)
  return `${date.format(DATE_FORMAT)} [UTC ${date.parseZone().format('Z')}]`
}
