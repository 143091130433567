import {useState, useEffect, useCallback} from 'react'

import {ContentState, convertToRaw, EditorState} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export function useSetEditorState(initText = '') {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [editorText, setEditorText] = useState<string>(initText)

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState)
    const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    setEditorText(htmlText === '<p></p>\n' ? '' : htmlText)
  }

  const checkContent = (message?: string) => {
    if (
      !editorState ||
      (editorState && !editorState.getCurrentContent().hasText())
    ) {
      return Promise.reject(new Error(message))
    }
    return Promise.resolve('')
  }

  const setText = useCallback((text?: string) => {
    if (text) {
      const contentBlock = htmlToDraft(text)

      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      onEditorStateChange(editorState)
    }
  }, [])

  useEffect(() => {
    if (initText !== '<p></p>\n') {
      setText(initText)
    }
  }, [initText, setText])

  useEffect(() => {
    if (initText === editorText) {
      setText(initText)
    }
  }, [editorText, initText, setText])
  return {
    editorState,
    editorText,
    onEditorStateChange,
    checkContent,
    setEditorText,
    setText,
    setEditorState,
  }
}
