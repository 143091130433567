import {useCallback} from 'react'

import {Entities, RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {patchIncident, postIncident} from '@/modules/ITSM/api/incidentsRequests'
import {patchKRequest, postKRequest} from '@/modules/ITSM/api/kRequestsRequests'
import {ISuperAgentResSingle} from '@/api/response-typedf'

import {TIncidentReq, TIncidentRes} from '../typedf'

export function usePatchIncident(
  entity: string
): {
  readonly updateEntity: (
    data: Partial<TIncidentReq>,
    id?: string
  ) => Promise<ISuperAgentResSingle<TIncidentRes> | undefined>
  readonly updateEntityStatus: RequestStatus
} {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const updateEntity = useCallback(
    async (data: Partial<TIncidentReq>, id?: string) => {
      try {
        handleRequested()

        const result =
          entity === Entities.INCIDENT
            ? await patchIncident(data, id)
            : await patchKRequest(data, id)
        if (result) {
          handleSuccess()
          return result
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [entity, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    updateEntity,
    updateEntityStatus: status,
  }
}

export function usePostIncident(entity: string) {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const postEntity = useCallback(
    async (
      data: TIncidentReq,
      id: string
    ): Promise<TIncidentRes | undefined> => {
      try {
        handleRequested()

        const {
          body: {result},
        } =
          entity === Entities.INCIDENT
            ? await postIncident(data, id)
            : await postKRequest(data, id)
        if (result) {
          handleSuccess()
          return result
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [entity, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    postEntity,
    postEntityStatus: status,
  }
}
