import {TDefaultQuickFilters} from '@/components/filter/typedef'

enum MainIncidentsQuickFilters {
  Opened = 'opened',
  AssignedToMe = 'assigned-to-me',
  NotAssignedToMe = 'not-assigned-to-me',
  OpenedByMe = 'opened-by-me',
  ClosedOrCancelled = 'closed-or-cancelled',
  AssignedToMyGroups = 'assigned-to-my-groups',
  Scheduled = 'scheduled',
  Resolved = 'resolved',
  Unassigned = 'unassigned',
  ToAction = 'to-action',
}

export type TIncidentsQuickFilters =
  | TDefaultQuickFilters
  | MainIncidentsQuickFilters

export const IncidentsQuickFilters = {
  ...TDefaultQuickFilters,
  ...MainIncidentsQuickFilters,
}
