import React, {useEffect, useState} from 'react'

import {Form, Input, Select} from 'antd'
import {useSelector} from 'react-redux'

import ErrorPage from '@/components/error/error-page/error-page'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import {TKey, translate} from '@/services/i18n'
import {patchInvitation} from '@/modules/ITSM/api/invitationsRequests'
import {org_roles} from '@/modules/ITSM/utils/Constants'
import {Drawer} from '@/components/drawer'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {useGetRoles} from '@/modules/ITSM/hooks/use-get-roles'
import {selectChannelID} from '@/redux/entries/entries-selector'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {TInvitation, TInviteResponse} from '@/modules/ITSM/typedef'
import {usePatchEntity} from '@/hooks/useUpdateData'
import {checkIsLoading} from '@/utils/check-is-loading'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {emailValidator} from '@/utils/form-validators/email-validator'

import './invitation-drawer.scss'

const {Option} = Select

type TProps = {
  record: TInvitation
  onClose: () => void
  getData: () => void
}

const InvitationDrawer = ({record, onClose, getData}: TProps) => {
  const [form] = Form.useForm()

  const {roles, getRoles} = useGetRoles()

  const channelId = useSelector(selectChannelID)

  const [modalVisible, setModalVisible] = useState(false)

  const {
    saveEntity: updateInvitation,
    saveEntityStatus: updateInvitationStatus,
  } = usePatchEntity<TInvitation, TInviteResponse>(patchInvitation)

  const handleSubmit = async (values: TInvitation) => {
    const result = await updateInvitation(values, record.ccInviteId.value)

    if (result) {
      if (
        result?.body?.result?.Fail === null &&
        result?.body?.result?.Success
      ) {
        onClose()
        getData()
      } else {
        setModalVisible(true)
      }
    }
  }
  useEffect(() => {
    getRoles(channelId)
  }, [channelId, getRoles])

  const intiRoles: Array<{name: string; uuid: string}> = JSON.parse(
    record.roles.value
  )

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        open={true}
        destroyOnClose={true}
        title={' '}
        className="list-table-drawer"
      >
        <SentryErrorBoundary
          fallback={
            <ErrorPage
              resolvers={[onClose]}
              buttonTitle={translate('close')}
              noRedirect={true}
            />
          }
        >
          <DrawerForm<TInvitation>
            onFinish={handleSubmit}
            form={form}
            loadingSubmit={checkIsLoading(updateInvitationStatus)}
            handleCancel={onClose}
          >
            <div>
              <FormItemGeneric<TInvitation>
                name="email"
                initialValue={''}
                label={translate('email')}
                rules={[{required: true}, {validator: emailValidator}]}
              >
                <Input />
              </FormItemGeneric>

              <FormItemGeneric<TInvitation>
                name="redirectUrl"
                initialValue={record.redirectUrl || ''}
                rules={[
                  {
                    required: true,
                  },
                ]}
                label={translate('redirectUrl')}
              >
                <Input />
              </FormItemGeneric>

              {record.signingPath && (
                <FormItemGeneric<TInvitation>
                  name="signingPath"
                  label={translate('signingPath')}
                  initialValue={record.signingPath || ''}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </FormItemGeneric>
              )}

              {record.channelInviteId && (
                <FormItemGeneric<TInvitation>
                  name="orgRole"
                  initialValue={
                    record && record.orgRole
                      ? Object.values(org_roles)[record.orgRole]
                      : null
                  }
                  label={translate('org_role')}
                >
                  <Select showSearch getPopupContainer={e => e.parentNode}>
                    {Object.values(org_roles)
                      .slice(1)
                      .map((role, index) => {
                        return (
                          <Option key={index} value={role}>
                            {translate(role as TKey)}
                          </Option>
                        )
                      })}
                  </Select>
                </FormItemGeneric>
              )}
              <FormItemGeneric<TInvitation>
                name={['roles', 'value']}
                initialValue={intiRoles.map(({uuid}) => uuid)}
                label={translate('roles')}
              >
                <Select
                  showSearch
                  getPopupContainer={e => e.parentNode}
                  mode="multiple"
                >
                  {roles.map(({name, uuid}) => {
                    return (
                      <Option key={uuid} value={uuid}>
                        {name}
                      </Option>
                    )
                  })}
                </Select>
              </FormItemGeneric>
            </div>
          </DrawerForm>
        </SentryErrorBoundary>
        <Modal
          open={modalVisible}
          modalType={ModalType.ERROR}
          cancelText={translate('close')}
          handleCancel={() => setModalVisible(false)}
        >
          {translate('updating_channel_failed')}
        </Modal>
      </Drawer>
    </>
  )
}

export default InvitationDrawer
