import moment, {Moment} from 'moment'

import {TCustomerProduct, TLocation} from '@/modules/ITSM/typedef'
import {ResponseTypes} from '@/modules/ITSM/utils/Constants'

import {TimeTypes} from './constants'

type TPricingPolicy = {
  from: Moment
  price_multiplier: number
  to: Moment
}

type TWeekday = {
  support_from: Moment | null
  support_to: Moment | null
}

export const changeDataFormat = (data: {
  values: Record<string, any>
  initialValues: TCustomerProduct | undefined
  pricingUnit: string
  additionalPricingUnit: string
  inactiveTimeout: string
  location?: TLocation
}) => {
  const {
    values,
    initialValues,
    pricingUnit,
    additionalPricingUnit,
    inactiveTimeout,
    location,
  } = data

  const setBankHolidays = (value: TWeekday) => {
    if (value?.support_from && value?.support_to) {
      return {
        support_from: value?.support_from.format('HH:mm'),
        support_to: value?.support_to.format('HH:mm'),
      }
    }
    return
  }

  const setHolidaysArray = (value: Moment[]) => {
    if (value) {
      return value.map((it: Moment) => it.format('YYYYMMDD'))
    }
    if (!value && initialValues?.holidays) {
      return initialValues?.holidays
    }
    return []
  }

  const setWeekdaysArray = (value: TWeekday[]) => {
    if (value) {
      return value.reduce((acc, rec, ind) => {
        if (rec.support_from && rec.support_to) {
          return {
            ...acc,
            [ind < 6 ? ind + 1 : 0]: {
              support_from: rec?.support_from.format('HH:mm'),
              support_to: rec?.support_to.format('HH:mm'),
            },
          }
        }

        return acc
      }, {})
    }
    if (!value && initialValues?.sla?.weekdays) {
      return initialValues?.sla?.weekdays
    }
    return {}
  }

  const setHolidayPricingPolicies = (value: TPricingPolicy[]) => {
    if (value) {
      return value.map(policyItem => {
        return {
          from: policyItem?.from.format('HH:mm'),
          price_multiplier: +policyItem.price_multiplier,
          to: policyItem?.to.format('HH:mm'),
        }
      })
    }
    if (!value && initialValues?.pricing_policy?.holiday_pricing_policies) {
      return initialValues?.pricing_policy?.holiday_pricing_policies
    }
    return []
  }

  const setWeekdayPricingPolicies = (value: TPricingPolicy[][]) => {
    if (value) {
      return {
        ...value?.map(weekday =>
          weekday?.map(policyItem => {
            return {
              from: policyItem?.from.format('HH:mm'),
              price_multiplier: +policyItem.price_multiplier,
              to: policyItem?.to.format('HH:mm'),
            }
          })
        ),
      }
    }
    if (!value && initialValues?.pricing_policy?.weekday_pricing_policies) {
      return initialValues?.pricing_policy?.weekday_pricing_policies
    }
    return {}
  }

  const setMaxResponseTime = () => {
    if (values?.sla_max_response_time !== undefined) {
      return values?.sla_max_response_time * 60 ** 2
    }
    if (ResponseTypes.HOURS) {
      return values?.response_time * 60 ** 2
    }
    return 0
  }

  const setMaxResponseTimeBusinessDays = () => {
    if (values?.sla_max_response_time !== undefined) {
      return values?.sla_max_response_time_business_days
    }
    if (ResponseTypes.DAYS) {
      return values?.response_time
    }
    return 0
  }
  const newObject: TCustomerProduct = {
    active_from: values.active_from
      ? values.active_from.toISOString()
      : moment().toISOString(),
    active_to: values.active_to
      ? values.active_to.toISOString()
      : moment()
          .add(4, 'year')
          .toISOString(),
    chargeable: values.chargeable,
    code: values?.code || '',
    dispatch: values.dispatch,
    holidays: setHolidaysArray(values.holidays),
    ...(location && {location}),
    name: values.name,
    pricing_policy: {
      additional_hour_rate: +values?.pricing_policy_additional_hour_rate,
      additional_pricing_unit:
        additionalPricingUnit === TimeTypes.MIN
          ? +values?.pricing_policy_additional_pricing_unit * 60
          : +values?.pricing_policy_additional_pricing_unit * 60 ** 2,

      currency: values?.pricing_policy_currency,
      holiday_pricing_policies: setHolidayPricingPolicies(
        values?.holiday_pricing_policies
      ),
      pricing_unit:
        pricingUnit === TimeTypes.MIN
          ? +values?.pricing_policy_pricing_unit * 60
          : +values?.pricing_policy_pricing_unit * 60 ** 2,

      primary_hour_rate: +values?.pricing_policy_primary_hour_rate,
      primary_hours_number: +values?.pricing_policy_primary_hours_number,
      rate: +values?.pricing_policy_rate,
      travel_distance_unit_rate: +values?.pricing_policy_travel_distance_unit_rate,
      travel_fix_rate: +values?.pricing_policy_travel_fix_rate,
      travel_hour_rate: +values?.pricing_policy_travel_hour_rate,
      travel_pricing_unit: +values?.pricing_policy_travel_pricing_unit,
      weekday_pricing_policies: setWeekdayPricingPolicies(
        values?.weekday_pricing_policies
      ),
    },
    pricing_type: values?.pricing_type.replaceAll(' ', '_').toLowerCase(),
    product_type: values?.product_type,
    resolution_time_eval: values?.resolution_time_eval
      .replaceAll(' ', '_')
      .toLowerCase(),
    sla: {
      inactive_timeout:
        inactiveTimeout === ResponseTypes.HOURS
          ? values?.sla_inactive_timeout * 60 ** 2
          : values?.sla_inactive_timeout * 60 ** 2 * 24,
      max_resolution_time: +values?.sla_max_resolution_time * 60 ** 2,
      max_resolution_time_business_days: +values?.sla_max_resolution_time_business_days,
      max_response_time: +setMaxResponseTime(),
      max_response_time_business_days: +setMaxResponseTimeBusinessDays(),
      timezone: values?.sla_timezone,
      safe_time_interval: +values?.sla_safe_time_interval * 60 ** 2,
      weekdays: setWeekdaysArray(values.weekdays),
      ...(setBankHolidays(values.sla_bank_holidays)
        ? {bank_holidays: setBankHolidays(values.sla_bank_holidays)}
        : {}),
    },
    sla_type: values?.sla_type,
  }

  return newObject
}
