import React from 'react'

import {Col, Row} from 'antd'

import './tab-title.scss'

export const TabTitle: React.FC<{title: string}> = ({title}) => (
  <Row>
    <Col className="tab-title" span={24}>
      <span className="tab-title__name">{title}</span>
    </Col>
  </Row>
)
