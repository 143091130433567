import {Flow, TAccountDefaultQuery} from '@/sites/account-default/typedef'

export const hasPaymentDetails = (query: TAccountDefaultQuery) => {
  if (query.flow === Flow.PRODUCTS && query.interval && query.payload) {
    return {
      hasPayment: true,
      flow: Flow.PRODUCTS,
      paymentDetails: {
        interval: query.interval,
        payload: query.payload,
      },
    }
  }

  if (
    query.flow === Flow.SERVICES &&
    query.interval &&
    query.payload &&
    query.unit_amount
  ) {
    return {
      hasPayment: true,
      flow: Flow.SERVICES,
      paymentDetails: {
        interval: query.interval,
        payload: query.payload,
        unit_amount: query.unit_amount,
      },
    }
  }

  return {
    hasPayment: false,
  }
}
