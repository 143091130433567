import React from 'react'

import {Button as AntButton, Dropdown, MenuProps, Typography} from 'antd'
import {BellOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'

import {getErrorMessage} from '@/components/toast/utils/get-error-message'
import {copyToClipboard} from '@/components/modal/utils'
import {translate} from '@/services/i18n'
import {
  clearErrorMessages,
  removeErrorMessage,
} from '@/redux/toast-message/toast-actions'
import {selectToastErrorMessages} from '@/redux/toast-message/toast-selector'

import './top-header-bell-notifications.scss'

const {Text} = Typography

const TopHeaderBellNotifications = () => {
  const dispatch = useDispatch()

  const errorMessages = useSelector(selectToastErrorMessages)
  const isDisabled = errorMessages.length === 0

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <>
          {!isDisabled && (
            <div className="top-header-bell-notifications__list">
              <ul>
                {errorMessages?.map((message, index) => (
                  <li key={JSON.stringify(message)}>
                    <Text>{getErrorMessage(message)}</Text>
                    <div className="top-header-bell-notifications__btns">
                      <AntButton
                        danger
                        type="text"
                        onClick={() => copyToClipboard(JSON.stringify(message))}
                      >
                        {translate('copy_error')}
                      </AntButton>
                      <AntButton
                        shape="circle"
                        type="text"
                        icon={<CloseCircleOutlined />}
                        onClick={() => dispatch(removeErrorMessage(index))}
                      />
                    </div>
                  </li>
                ))}
              </ul>

              <div className="top-header-bell-notifications__footer">
                <AntButton
                  danger
                  type="text"
                  onClick={() => dispatch(clearErrorMessages())}
                >
                  {translate('clear_all')}
                </AntButton>
              </div>
            </div>
          )}
        </>
      ),
    },
  ]

  return (
    <Dropdown
      className="top-header-bell-notifications"
      menu={{items}}
      placement="bottomRight"
    >
      <div
        className={classnames('top-header-bell-notifications__icon', {
          'top-header-bell-notifications__icon--disabled': isDisabled,
        })}
      >
        <BellOutlined />
        <span className="top-header-bell-notifications__counter">
          {errorMessages.length}
        </span>
      </div>
    </Dropdown>
  )
}

export default TopHeaderBellNotifications
