import {RequestStatus} from '@/typedef'

import * as ActionTypes from '../actionTypes'

import {IUserProfile, TUserProfileActionTypes} from './typedef'

const initialState: IUserProfile = {
  userProfile: {
    accountId: '',
    accountName: '',
    orgId: '',
    orgName: '',
    channelId: '',
    channelName: '',
  },
  requestStatus: RequestStatus.INITIAL,
}

export default function userProfileReducer(
  state = initialState,
  action: TUserProfileActionTypes
): IUserProfile {
  switch (action.type) {
    case ActionTypes.RESET_GENERAL_PROFILE: {
      return initialState
    }

    case ActionTypes.SET_FETCH_GENERAL_PROFILE_REQUESTED: {
      return {
        ...state,
        requestStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.SET_FETCH_GENERAL_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload.userProfile,
        requestStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.SET_FETCH_GENERAL_PROFILE_FAILED: {
      return {
        ...state,
        requestStatus: RequestStatus.FAILED,
      }
    }

    default:
      return state
  }
}
