import {useCallback, useState} from 'react'

import {useParams} from 'react-router-dom'

import {RequestStatus, TUser} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {fetchEvents} from '@/modules/ITSM/api/eventsRequests'
import {fetchUsers} from '@/modules/ITSM/api/userRequests'

import {getCallersUuid} from '../utils'
import {TEvent} from '../typedf'

export function useGetEvents(): {
  readonly getData: (endpoint: string) => Promise<TEvent[] | undefined>
  readonly entityData: TEvent[] | undefined
  readonly fetchStatus: RequestStatus
  readonly getCallersById: (page: number, entityDataPassed?: TEvent[]) => void
  readonly callersResolved: Array<TUser>
} {
  const {page} = useParams<{page: string}>()

  const [entityData, setEntityData] = useState<TEvent[]>()
  const [callersResolved, setCallersResolved] = useState<Array<TUser>>([])

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getCallersById = useCallback(
    async (page: number, entityDataPassed?: TEvent[]) => {
      handleRequested()

      const {
        body: {result: users},
      } = await fetchUsers<TUser>({
        selector: {
          $or: getCallersUuid(entityDataPassed || entityData || [], page),
        },
      })

      if (users) {
        setCallersResolved(users)
        handleSuccess()
      }
    },
    [entityData, handleRequested, handleSuccess]
  )

  const getData = useCallback(
    async (endpoint: string) => {
      try {
        handleRequested()
        const {
          body: {result},
        } = await fetchEvents(endpoint)
        if (result) {
          setEntityData(result)
          handleSuccess()
          getCallersById(Number(page), result)
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [
      getCallersById,
      handleFail,
      handleRequested,
      handleReset,
      handleSuccess,
      page,
    ]
  )

  return {
    getData,
    entityData,
    fetchStatus: status,
    getCallersById,
    callersResolved,
  }
}
