import React, {Key, ReactNode, useRef, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {Col, Form, Row} from 'antd'
import {ContentState, EditorState} from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import {v4} from 'uuid'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {Editor} from 'react-draft-wysiwyg'

import {Button} from '@/components/button'
import {setError} from '@/redux/actions'
import {TKey, translate} from '@/services/i18n'
import {handleMessage} from '@/utils/handle-message'
import {Assets, Entities, RequestStatus} from '@/typedef'
import {draftToolbarOptions} from '@/modules/ITSM/utils/Constants'
import Chatroom from '@/modules/ITSM/components/chat-room/index'
import {selectUserID} from '@/redux/user/userSelector'
import AttachmentsButton, {
  TOnUpdateParams,
} from '@/modules/ITSM/components/attachments/attachments-button'
import {TAttachment} from '@/modules/ITSM/sites/Expenses/typedef'
import {EditorWrapper} from '@/components/editor'
import {postComment} from '@/modules/ITSM/api/ts-version/comment-requests'
import {TITSMSelectorNoBC} from '@/modules/ITSM/typedef'
import {postWorkNote} from '@/modules/ITSM/api/ts-version/work-notes-requests'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import ErrorPage from '@/components/error/error-page/error-page'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import FormItemCustom from '@/components/form/form-item-custom'
import {Comment} from '@/modules/ITSM/services/openAPI/Api'
import {postAttachmentV2} from '@/modules/ITSM/api/attachmentsRequests'

import {getAttachmentLink} from '../../description-editor/get-attachment-link'
import {AttachmentsModal} from '../../attachments-modal/attachments-modal'

import {createCommentData} from './utils/create-comment-data'

import './notes-comments-form.scss'

type TProps = {
  isDisabled: boolean
  entity: string
  id?: string
  paginationComponent?: ReactNode
  loading: boolean
  allComments: Array<Comment>
  fetchComments: (options: TITSMSelectorNoBC) => Promise<Comment[] | undefined>
  markAsReadByUser: (commentId: string) => any
  getComment: (commentId: string) => any
  noDataMessage: string
  asset: Assets
  saveEntityAsync: typeof postWorkNote | typeof postComment
}

export const NotesCommentForm = (props: TProps) => {
  const {
    isDisabled,
    entity,
    id,
    fetchComments,
    paginationComponent,
    loading,
    allComments,
    getComment,
    markAsReadByUser,
    noDataMessage,
    saveEntityAsync,
    asset,
  } = props

  const dispatch = useDispatch()

  const userID = useSelector(selectUserID)

  const [attachmentsModal, setAttachmentsModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key>>()
  const [selectedAttachments, setSelectedAttachments] = useState<
    Array<TAttachment>
  >()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const filesDataRef = useRef<TOnUpdateParams>()

  const {
    editorState,
    onEditorStateChange,
    editorText,
    setText,
  } = useSetEditorState()

  const confirmExistingAttachments = () => {
    const links = getAttachmentLink({selectedAttachments})
    getDescription(links)
  }

  const confirmUploadedAttachments = (
    uploadedAttachments: Array<TAttachment>
  ) => {
    const links = getAttachmentLink({uploadedAttachments})

    if (links) {
      getDescription(links)
    }
  }

  const getDescription = (links: string) => {
    const newComment = editorText + (links !== '' && links)
    const contentBlock = htmlToDraft(newComment)

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorContentState = EditorState.createWithContent(contentState)
      handleSubmit(editorContentState)
    }

    setText(newComment)
    setAttachmentsModal(false)
    setSelectedRowKeys([])
    setSelectedAttachments([])
  }

  const onSelectChange = (
    selectedRowKeys: Array<Key>,
    selectedAttachments: Array<TAttachment>
  ) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedAttachments(selectedAttachments)
  }

  const resetFields = () => {
    setText('')
    onEditorStateChange(EditorState.createEmpty())
  }

  const commentWereRead = async (id: string) => {
    try {
      await markAsReadByUser(id)
    } catch (err) {
      dispatch(setError(err))
    }
  }

  const setCommentWasRead = async (uuid: string) => {
    try {
      await commentWereRead(uuid)
      await getComment(uuid)
    } catch (err) {
      dispatch(setError(err))
    }
  }

  const handleSubmit = async (editorState: EditorState) => {
    setLoadingSubmit(true)
    try {
      const data =
        id &&
        createCommentData({
          id,
          entity,
          editorState,
        })

      if (data) {
        handleMessage(RequestStatus.REQUESTED)

        const {
          body: {uuid},
        } = await saveEntityAsync(v4(), data.text || '', data.entity)

        resetFields()
        handleMessage(RequestStatus.SUCCEEDED)

        if (uuid) {
          await commentWereRead(uuid)
          await fetchComments({resetBookmarks: true})
        }
      }
    } catch (err) {
      dispatch(setError(err))
      handleMessage(RequestStatus.FAILED)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const handleUploadAttachment = async () => {
    const {
      filesAmount = 0,
      addAttachments: addFileAttachments,
      status: filesAttachmentStatus = RequestStatus.INITIAL,
    } = filesDataRef.current || {}
    const saveAttachments = async () => {
      if (addFileAttachments) {
        await addFileAttachments()
      }
    }
    if (filesAmount > 0 && filesAttachmentStatus === RequestStatus.INITIAL) {
      saveAttachments()
    }
  }

  const onAddFiles = (params: TOnUpdateParams) => {
    filesDataRef.current = {...params}
  }

  const br_entity = entity === Entities.BILLING_REPORT

  return (
    <>
      {!isDisabled && (
        <>
          <Form>
            <Row>
              <Col span={24} e2e-test="comment_editor">
                <EditorWrapper>
                  <FormItemCustom initialValue={''}>
                    <SentryErrorBoundary fallback={<ErrorPage />}>
                      <Editor
                        editorState={editorState}
                        toolbar={draftToolbarOptions}
                        wrapperClassName="wysiwyg-editor"
                        onEditorStateChange={onEditorStateChange}
                      />
                    </SentryErrorBoundary>
                  </FormItemCustom>
                </EditorWrapper>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                {!br_entity && (
                  <div className="notes-comment-form__file-attaching">
                    <AttachmentsButton<TAttachment>
                      onAddAttachments={confirmUploadedAttachments}
                      immediateAttachments={true}
                      onUpdate={onAddFiles}
                      entity={entity}
                      entityId={id || ''}
                      asyncF={postAttachmentV2}
                    />
                    <Button
                      htmlType="submit"
                      onClick={() => setAttachmentsModal(true)}
                      title={translate('attach_existing_files')}
                      size="large"
                    />
                  </div>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() =>
                    filesDataRef.current &&
                    filesDataRef.current.filesAmount > 0 &&
                    filesDataRef.current.status === RequestStatus.INITIAL
                      ? handleUploadAttachment()
                      : handleSubmit(editorState)
                  }
                  disabled={loadingSubmit || !editorText}
                  title={`${translate('save')} ${translate(
                    asset as TKey
                  ).toLowerCase()}`}
                  size="large"
                  e2e-test="submit-button"
                />
              </Col>
            </Row>
          </Form>
        </>
      )}
      <Chatroom
        data={allComments}
        getFullMessageData={getComment}
        markReadComment={setCommentWasRead}
        userID={userID}
        entity={entity}
        noDataMessage={noDataMessage}
        paginationComponent={paginationComponent}
        loading={loading}
      />
      {attachmentsModal && (
        <AttachmentsModal
          id={id}
          entity={entity}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          visible={attachmentsModal}
          confirmAttachments={confirmExistingAttachments}
          hide={() => setAttachmentsModal(false)}
        />
      )}
    </>
  )
}
