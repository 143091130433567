import {call, put, select} from 'redux-saga/effects'

import {fetchCmdbIdentityMe} from '@/modules/CMDB/api/identity-requests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {TCmdbIdentityMe} from '@/modules/CMDB/typedef'

import {
  fetchCmdbIdentityMeFailed,
  fetchCmdbIdentityMeSuccess,
} from '../store/user/cmdb-user-actions'
import {selectCmdbSpaceId} from '@/redux/entries/entries-selector'
import {setCmdbNamespaceId} from '@/redux/entries/entries-actions'

type TParams = {body: TCmdbIdentityMe}

export function* getCmdbIdentityMeSaga() {
  try {
    const {
      body: {role: cmdbUserRole, name, namespaces, userId},
    }: TParams = yield call(fetchCmdbIdentityMe)

    const selectedNamespaceId: string = yield select(selectCmdbSpaceId)

    const hasPersistedSpaceInMySpaces = namespaces.some(
      space => space?.id === selectedNamespaceId
    )

    if (!hasPersistedSpaceInMySpaces) {
      yield put(setCmdbNamespaceId(namespaces[0]?.id || ''))
    }

    yield put(
      fetchCmdbIdentityMeSuccess({
        cmdbUserRole,
        cmdbUserNameInChannel: name,
        cmdbNamespaces: namespaces,
        cmdbUserId: userId,
      })
    )
  } catch (err) {
    yield put(fetchCmdbIdentityMeFailed({err}))
    yield put(setToastMessage({message: err}))
  }
}
