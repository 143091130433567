import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {eventsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

export const postApproveEvent = async data => {
  return await request
    .post(eventsApi().approve)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchEvent = async ({id}) => {
  return await request
    .get(eventsApi().getOne + '-get')
    .set(getHeadersWithGRPC())
    .query({id})
}

export const putEvent = async data => {
  return await request
    .put(eventsApi().getOne)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const deleteEvent = async id => {
  return await request
    .delete(eventsApi().getOne)
    .set(getHeadersWithGRPC())
    .query({id})
}

export const postEvent = async data => {
  return await request
    .post(eventsApi().getOne)
    .send(data)
    .set(getHeadersWithGRPC())
}

export const fetchEvents = async endpoint => {
  return await request.get(eventsApi().get + endpoint).set(getHeadersWithGRPC())
}
