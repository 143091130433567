import React, {useContext, useState} from 'react'

import {Col, Divider, Row} from 'antd'
import {DollarCircleOutlined} from '@ant-design/icons'
import {InlineWidget} from 'react-calendly'
import {Typography} from '@/components/typography/typography'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

import {Button} from '@/components/button'

import './payment-step.scss'

import {translate} from '@/services/i18n'
import {generateConfig} from '@/generateConfig'

import {useSubscriptionCheckoutSession} from '@/sites/account-default/hooks/use-subscription-checkout-session'
import {OverlaySpinner} from '@/components/overlay-spinner'
import {ContactForm} from '@/components/form/contact-form/contact-form'
import {AccountDefaultContext} from '@/sites/account-default/account-default-context/account-default-context'
import {PaymentStatus} from '@/sites/account-default/typedef'

export const PaymentStep = () => {
  const {search} = useLocation()
  const {state} = useContext(AccountDefaultContext)

  const [hasVisibleForm, setHasVisibleForm] = useState(false)

  const {'payment-status': paymentStatus} = queryString.parse(search) as {
    ['payment-status']?: PaymentStatus
  }

  const {generatePaymentSession} = useSubscriptionCheckoutSession()

  const handlePaymentFirstAttempt = async () => {
    await generatePaymentSession(state.accessToken)
  }

  const handleContactForm = () => {
    //TODO: send contact form
  }

  if (state.isLoading) {
    return (
      <OverlaySpinner
        text={translate('redirect_to_payment')}
        idName={'payment-step'}
      />
    )
  }

  if (paymentStatus === PaymentStatus.CANCEL) {
    return (
      <Row className="payment-step" gutter={[24, 24]}>
        <Col xs={24} className="payment-step__title mb-16">
          <Typography title="not_convinced_at_this_time" level={4} />
          <Typography
            className="mb-24"
            title="contact_our_specialist_or_request_quote"
            level={5}
          />
        </Col>

        <Col
          xs={24}
          md={6}
          className="payment-step__buttons flex flex--column mt-30"
        >
          <Button
            className="mb-24"
            {...(!hasVisibleForm && {type: 'primary'})}
            title={translate('set_up_call')}
            onClick={() => setHasVisibleForm(false)}
          />
          <Button
            {...(hasVisibleForm && {type: 'primary'})}
            title={translate('request_a_quote')}
            onClick={() => setHasVisibleForm(true)}
          />
          <Divider />

          <Button
            className="payment-step__pay-now"
            type="primary"
            title={translate('pay_now')}
            onClick={() => generatePaymentSession(state.accessToken)}
          />
        </Col>
        <Col xs={24} md={18}>
          {hasVisibleForm ? (
            <>
              <Typography title="contact_form" level={4} className="mb-20" />
              <ContactForm onSubmit={handleContactForm} />
            </>
          ) : (
            <InlineWidget
              url={generateConfig().CALENDLY_URL}
              pageSettings={{hideEventTypeDetails: true}}
            />
          )}
        </Col>
      </Row>
    )
  }

  return (
    <div className="payment-step flex flex--column flex--alignCenter flex--justifyEnd">
      <Typography className="mb-24" title="purchase_your_products" level={3} />
      <DollarCircleOutlined className="payment-step__icon mb-24" />

      <Button
        type="primary"
        title={translate('proceed_to_payment')}
        onClick={handlePaymentFirstAttempt}
      />
    </div>
  )
}
