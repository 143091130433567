import React, {MouseEvent, useCallback, useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'

import {UploadButton} from '@/components/upload-button/upload-button'
import {translate} from '@/services/i18n'
import {useFetchHook} from '@/hooks/useFetch'
import {checkIsLoading} from '@/utils/check-is-loading'
import {Button} from '@/components/button'
import {RequestStatus} from '@/typedef'
import {useUploadFiles} from '@/hooks/use-upload-files'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import './attachments.scss'
import {CustomCheckbox} from '@/components/checkbox/checkbox'

import {ItsmAssets} from '../../typedef'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'

export type TOnUpdateParams = {
  filesAmount: number
  status: RequestStatus
  addAttachments?: () => Promise<any>
}

type TProps<T> = {
  onAddAttachments?: (uploadedAttachments: Array<T>) => void
  immediateAttachments?: boolean
  onUpdate?: (params: TOnUpdateParams) => void
  entityId?: string
  entity?: string
  asyncF: (
    fileData: Record<string, any>,
    queryParams?: string
  ) => Promise<ISuperAgentResSingle<T>>
  asset?: ItsmAssets.Documents
  options?: string[]
  option?: string
}

const AttachmentsButton = <T extends Record<string, any>>({
  onAddAttachments,
  onUpdate,
  immediateAttachments = false,
  entityId,
  entity,
  asyncF,
  asset,
  options,
  option,
}: TProps<T>) => {
  const [checkedGroup, setCheckedGroup] = useState<CheckboxValueType[]>([])
  const [checkedSingle, setCheckedSingle] = useState(false)

  const {
    uploadFiles,
    fileList,
    resetUploadedFiles,
    filesConverted,
  } = useUploadFiles()

  const filesNumber = fileList.length

  useEffect(() => {
    if (filesNumber === 0) {
      setCheckedGroup([])
    }
  }, [filesNumber])

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })
  const generateQueryParams = option
    ? `${option}=1`
    : checkedGroup
        .reduce<string>((queryString, param) => {
          return `${queryString}&${param}=1`
        }, '')
        .toLocaleLowerCase('en-US')
        .replace(/ /g, '-')

  const addAttachment = useCallback(
    async (file: any): Promise<T | undefined> => {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('type', file.file.type)

      const fileData = {
        data: formData,
        ...(entityId ? {id: entityId} : {}),
        ...(entity ? {entity} : {}),
        name: file.file.name,
      }
      try {
        const {
          body: {result},
        } = await asyncF(fileData, generateQueryParams)

        if (result) return result
      } catch (err) {
        handleFail(err)
      }
    },
    [asyncF, entity, entityId, handleFail, generateQueryParams]
  )

  const attachmentsAction = useCallback(async () => {
    const allAttachments: Array<T> = []

    let sequence = Promise.resolve()

    filesConverted.forEach((file, i) => {
      sequence = sequence
        .then(() => {
          handleRequested()
          return addAttachment(file)
        })
        .then(async result => {
          if (result) allAttachments.push(result)
          handleReset()

          const isTheLast = i === filesConverted.length - 1

          if (isTheLast) {
            resetUploadedFiles()
            if (onAddAttachments) {
              await onAddAttachments(allAttachments)
            }

            handleSuccess()
          }
        })
        .finally(() => {
          handleReset()
        })
    })
  }, [
    filesConverted,
    handleRequested,
    addAttachment,
    handleReset,
    resetUploadedFiles,
    onAddAttachments,
    handleSuccess,
  ])

  useEffect(() => {
    if (onUpdate) {
      onUpdate({
        status,
        filesAmount: filesNumber,
        ...(entityId ? {addAttachments: attachmentsAction} : null),
      })
    }
  }, [attachmentsAction, entityId, onUpdate, filesNumber, status])

  const saveAttachments = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    await attachmentsAction()
  }

  const cancelAttachments = () => {
    resetUploadedFiles()
  }

  const upload_btn = (
    <UploadButton uploadProps={uploadFiles()} text={translate('upload_docs')} />
  )
  const onGroupValuesChange = (checkedValues: CheckboxValueType[]) =>
    setCheckedGroup(checkedValues)

  const onSingleValueChange = (e: CheckboxChangeEvent) =>
    setCheckedSingle(e.target.checked)

  return (
    <div className="documents_buttons_container">
      {upload_btn}
      {!immediateAttachments && filesNumber > 0 && (
        <>
          {(options || option) && (
            <CustomCheckbox
              title={translate('visibility_rights')}
              options={options}
              onGroupValuesChange={onGroupValuesChange}
              onSingleValueChange={onSingleValueChange}
              name={option}
              checked={checkedSingle}
            />
          )}
          <Row className="mt-10 mb-10">
            <Col>
              <Button
                title={translate('upload')}
                type="primary"
                onClick={saveAttachments}
                disabled={checkIsLoading(status)}
              />
              <Button
                type="default"
                title={translate('cancel')}
                onClick={cancelAttachments}
                className="ml-10"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default AttachmentsButton
