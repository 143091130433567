import {useCallback} from 'react'

import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import {deleteEvent} from '@/modules/ITSM/api/eventsRequests'

import {TEvent} from '../typedf'

export function useDeleteEvent(): {
  readonly deleteEntity: (
    id: string
  ) => Promise<ISuperAgentResSingle<TEvent> | undefined>
  readonly deleteEntityStatus: RequestStatus
} {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const deleteEntity = useCallback(
    async (id: string) => {
      try {
        handleRequested()

        await deleteEvent(id)

        handleSuccess()

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    deleteEntity,
    deleteEntityStatus: status,
  } as const
}
