import {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {setPriorityValues} from '@/redux/actions'
import {TAppState} from '@/redux/store'
import {getPriorityValues} from '@/modules/ITSM/api/ticketRequests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

export const useGetPriorityValues = () => {
  const {priority_values} = useSelector((state: TAppState) => state.general)

  const dispatch = useDispatch()

  useEffect(() => {
    const loadData = async () => {
      try {
        const {
          body: {
            result: {value: priorityValuesRes},
          },
        } = await getPriorityValues()

        const {impact_text, priority_text, urgency_text} = priorityValuesRes

        dispatch(
          setPriorityValues([{impact_text}, {priority_text}, {urgency_text}])
        )
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    }

    if (!priority_values || priority_values.length === 0) loadData()
  }, [priority_values, dispatch])
}
