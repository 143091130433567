import React from 'react'

import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {TAssignmentRule} from '@/modules/ITSM/typedef'

export const AssignmentRulesRowValue = ({
  title,
  value,
}: TColumnRender<TAssignmentRule>) => {
  switch (title) {
    case 'ticket_type':
      return <div>{(value as string).replace('_', ' ').toLowerCase()}</div>

    default:
      return <div>{value}</div>
  }
}
