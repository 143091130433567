import React, {useEffect, useState} from 'react'

import {Col, Input, Row, Form, Select, InputNumber} from 'antd'
import {v4 as uuid} from 'uuid'
import {useHistory} from 'react-router-dom'
import moment, {Moment} from 'moment'
import {Rule} from 'antd/lib/form'

import {ItsmAssets, TLocation, TSupplierProduct} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'
import FormItemCustom from '@/components/form/form-item-custom'
import {currencies, SlaTypes} from '@/modules/ITSM/utils/Constants'
import {scrollToField} from '@/utils/scroll-to-field'
import {Button} from '@/components/button/button'
import {filterOption} from '@/utils/filter-option'
import {useQuery} from '@/utils/useQuery'
import {entityDetailPath} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/constants/routes-config'
import {usePatchEntity, usePostEntity} from '@/hooks/useUpdateData'
import {saveLocation} from '@/modules/ITSM/api/locationRequests'

import {NewLocationButton} from '@/modules/ITSM/components/createNewLocation/newLocationButton'
import {selectValueOnFocus} from '@/utils/select-value-on-focus'

import {patchSupplier, postSupplierProduct} from '../../../api/productsRequests'
import {PricingType, TimeTypes} from '../CustomerProducts/utils/constants'

import {useGetOrgsRoles} from './utils/useGetOrgsRoles'
import './supplier-product-form.scss'
import {parseFetchedData} from './utils/parse-fetched-data'
import {changeDataFormat} from './utils/change-data-format'
import DatePicker from '@/components/date-picker/date-picker'

const Option = Select.Option

type TSupplierProductForm = {
  entityData?: TSupplierProduct
  id: string
  getData?: ({
    id,
    selector,
  }: {
    id: string
    selector?: Record<string, any>
  }) => Promise<TSupplierProduct | undefined>
}

const SupplierProductsForm = ({
  entityData,
  id,
  getData,
}: TSupplierProductForm) => {
  const [form] = Form.useForm()
  const isCopy = useQuery('copy')
  const history = useHistory()
  const asset = ItsmAssets.SupplierProducts

  const {orgsRoles} = useGetOrgsRoles()
  const initialValues = parseFetchedData(entityData)

  const [pricingUnit, setPricingUnit] = useState<string>(TimeTypes.MIN)
  const [additionalPricingUnit, setAdditionalPricingUnit] = useState<string>(
    TimeTypes.MIN
  )

  const [productLocation, setProductLocation] = useState<TLocation>()

  useEffect(() => {
    setProductLocation(entityData?.location)
  }, [entityData?.location])

  const [btnDisabled, setBtnDisabled] = useState(true)

  const {saveEntity: updateSupplierProduct} = usePatchEntity<TSupplierProduct>(
    patchSupplier
  )

  const {saveEntity: insertSupplierProduct} = usePostEntity<TSupplierProduct>(
    postSupplierProduct
  )

  const selectPricingUnitHandler = (value: string) => {
    setPricingUnit(value)
    const unitValue = form.getFieldValue('pricing_unit')
    form.setFieldsValue(
      value === TimeTypes.HR
        ? {
            pricing_unit: Math.round((unitValue / 60) * 100) / 100,
          }
        : {pricing_unit: Math.round(unitValue * 60)}
    )
  }
  const selectAdditionalPricingUnitHandler = (value: string) => {
    setAdditionalPricingUnit(value)
    const unitValue = form.getFieldValue('additional_pricing_unit')
    form.setFieldsValue(
      value === TimeTypes.HR
        ? {
            additional_pricing_unit: Math.round((unitValue / 60) * 100) / 100,
          }
        : {additional_pricing_unit: Math.round(unitValue * 60)}
    )
  }

  const handleSubmit = async (values: TSupplierProduct) => {
    const submitData =
      orgsRoles &&
      productLocation &&
      changeDataFormat({
        values,
        entityData,
        orgsRoles,
        productLocation,
        pricingUnit,
        additionalPricingUnit,
      })
    if (submitData && entityData && !isCopy) {
      const result = await updateSupplierProduct(submitData, id)
      setBtnDisabled(true)
      if (!result) return
      if (getData) getData({id})
    }
    if (submitData && (!entityData || isCopy)) {
      const result = await insertSupplierProduct(submitData, id)
      if (!result) return
      return history.push(entityDetailPath[asset]?.(id))
    }
  }

  useEffect(() => {
    const name = form.getFieldValue('name')
    if (isCopy && !/\w*COPY\b/.test(name)) {
      form.setFieldsValue({name: `${name} COPY`})
    }
  }, [entityData?.name, form, isCopy])

  const copyClickHandler = () => {
    const productCopyId = uuid()
    history.push({
      pathname: entityDetailPath[asset]?.(productCopyId),
      search: 'copy=true',
    })
    setBtnDisabled(false)
    form.scrollToField('name')
  }

  const changeRateHandler = () => {
    const newTime = form.getFieldValue('rate')
    form.setFieldsValue({
      primary_hour_rate: 0,
      rate: newTime,
    })
  }

  const changePrimaryUnitRateHandler = () => {
    const newTime = form.getFieldValue('primary_hour_rate')
    form.setFieldsValue({
      primary_hour_rate: newTime,
      rate: 0,
    })
  }

  const changeHandler = (_: Moment | null, date: string, interval: string) => {
    form.setFieldsValue(
      interval === 'active_from'
        ? {active_from: date ? moment(date) : null}
        : {active_to: date ? moment(date) : null}
    )
  }

  const validateLocation = (rule: Rule, value: TLocation) => {
    return new Promise<void>((resolve, reject) => {
      if (!productLocation) {
        reject(translate('location_cannot_be_empty_field'))
      }
      resolve()
    })
  }

  const onSave = (locationData: TLocation) => {
    setBtnDisabled(false)
    setProductLocation(locationData)

    form.setFieldsValue({
      location: `${locationData?.country} ${locationData?.city} ${locationData?.street}`,
    })
  }

  const label = (
    <div className="location-select__label">
      <span>{translate('location')}</span>
      <NewLocationButton
        record={productLocation}
        itemType={ItsmAssets.CustomerProducts}
        formRules={{name: [{required: true}]}}
        onSave={onSave}
        asyncF={saveLocation}
      />
    </div>
  )

  return (
    <>
      <Form
        layout="vertical"
        className="product-form__form-grid"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}
        onFinishFailed={errorFields => {
          scrollToField({errorFields, form})
        }}
        onValuesChange={() => setBtnDisabled(false)}
      >
        <div>
          <FormItemCustom
            name="name"
            label={translate('product_name')}
            rules={[{required: true}]}
          >
            <Input className="full-width" onFocus={selectValueOnFocus} />
          </FormItemCustom>
          <Row>
            <Col className="col-flex--two">
              <FormItemCustom
                name="code"
                label={translate('code')}
                rules={[{required: true}]}
              >
                <Input onFocus={selectValueOnFocus} />
              </FormItemCustom>

              <FormItemCustom
                name="org_display_name"
                label={translate('org_display_name')}
                rules={[{required: true}]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  getPopupContainer={e => e.parentNode}
                >
                  {orgsRoles &&
                    Object.entries(orgsRoles).map((e, i) => {
                      return (
                        <Option key={i} value={e[1].name}>
                          {e[1].name}
                        </Option>
                      )
                    })}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--two">
              <FormItemCustom
                name="pricing_type"
                label={translate('pricing_type')}
                rules={[{required: true}]}
              >
                <Select
                  showSearch
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                >
                  {Object.values(PricingType).map(type => (
                    <Option value={type} key={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>

              <FormItemCustom
                name="sla_type"
                label={translate('sla_type')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                >
                  {Object.values(SlaTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--two" id="col">
              <FormItemCustom
                label={label}
                name="location"
                rules={[
                  {
                    required: true,
                    validator: validateLocation,
                  },
                ]}
                required
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItemCustom
                name="active_from"
                label={translate('active_from')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  getPopupContainer={e => e.parentNode as HTMLElement}
                  minuteStep={5}
                  onChange={(_, date) =>
                    changeHandler(_, date as any, 'active_from')
                  }
                />
              </FormItemCustom>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--two">
              <h4>{translate('pricing_policy')}</h4>
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--four">
              <FormItemCustom
                name={'rate'}
                label={translate('rate')}
                rules={[{required: true}]}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onChange={changeRateHandler}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
              <FormItemCustom
                name={'primary_hour_rate'}
                label={translate('primary_unit_rate')}
                rules={[{required: true}]}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onChange={changePrimaryUnitRateHandler}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>

              <FormItemCustom
                name="additional_hour_rate"
                label={translate('additional_unit_rate')}
                rules={[{required: true}]}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
              <FormItemCustom
                name={'currency'}
                label={translate('currency')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                >
                  {currencies.map(currency => (
                    <Option value={currency.value} key={currency.value}>
                      {currency.value}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--four">
              <FormItemCustom
                name="pricing_unit"
                label={translate('pricing_unit')}
                rules={[{required: true}]}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
              <FormItemCustom>
                <Select
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                  onChange={selectPricingUnitHandler}
                  value={pricingUnit}
                >
                  {Object.values(TimeTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
              <FormItemCustom
                name="additional_pricing_unit"
                label={translate('additional_pricing_unit')}
                rules={[{required: true}]}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
              <FormItemCustom>
                <Select
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                  onChange={selectAdditionalPricingUnitHandler}
                  value={additionalPricingUnit}
                >
                  {Object.values(TimeTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--two">
              <FormItemCustom
                name="primary_hours_number"
                label={translate('primary_units_number')}
                rules={[{required: true}]}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--two">
              <FormItemCustom
                name="travel_fix_rate"
                label={translate('travel_fix_rate')}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
              <FormItemCustom
                name="travel_hour_rate"
                label={translate('travel_hour_rate')}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--two">
              <FormItemCustom
                name="travel_pricing_unit"
                label={translate('travel_pricing_unit')}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
              <FormItemCustom
                name="travel_distance_unit_rate"
                label={translate('travel_distance_unit_rate')}
              >
                <InputNumber
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                  onFocus={selectValueOnFocus}
                />
              </FormItemCustom>
            </Col>
          </Row>
        </div>
        <Button
          title={translate('submit')}
          type="primary"
          disabled={btnDisabled}
          size="large"
          htmlType="submit"
        />
        {entityData && !isCopy && (
          <Button
            className="form__copy-button"
            title={translate('copy')}
            type="primary"
            size="large"
            htmlType="button"
            onClick={copyClickHandler}
          />
        )}
      </Form>
    </>
  )
}

export default SupplierProductsForm
