import {Button} from '@/components/button/button'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {generateConfig} from '@/generateConfig'
import {getAccountBalanceAmount} from '@/modules/Subscriptions/utils/get-account-balance-amount'
import {setBalanceTransaction} from '@/redux/billing/billing-action-creators'
import {
  selectCustomer,
  selectTransactionAmount,
  selectTransactionCounter,
} from '@/redux/billing/billing-selector'

import {translate} from '@/services/i18n'
import {useQuery} from '@/utils/useQuery'
import {LoadingOutlined} from '@ant-design/icons'
import {Spin} from 'antd'
import classNames from 'classnames'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import './account-balance.scss'
import {PaymentModal} from './payment-modal'

type TProps = {
  className?: string
}

export const AccountBalance: React.FC<TProps> = ({className}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const transactionAmount = useSelector(selectTransactionAmount)
  const customer = useSelector(selectCustomer)
  const fetchCount = useSelector(selectTransactionCounter)
  const balance = useQuery('balance')
  const dispatch = useDispatch()

  const handleOpenBalanceTopUp = () => setModalOpen(true)
  const handleOpenErrorModal = () => {
    dispatch(setBalanceTransaction(0))
  }
  const balanceAmount = getAccountBalanceAmount(
    transactionAmount,
    balance,
    customer?.balance
  )

  const spinnerClassName = classNames({
    'account-balance-spinner': balance,
    'account-balance-spinner__hidden': !balance,
  })

  const accountBalanceClassName = classNames({
    'account-balance__hidden': !generateConfig().FEATURE_FLAGS
      .isTopUpBalanceEnabled,
    ...(className && {[className]: true}),
  })

  return (
    <div className={accountBalanceClassName}>
      <Modal
        info={translate('account_balance_mismatch')}
        handleCancel={handleOpenErrorModal}
        open={fetchCount === 0 && transactionAmount !== 0}
        cancelText={translate('cancel')}
        modalType={ModalType.INFO}
      />
      <div className={'account-balance-wrapper'}>
        <div className="account-balance-text-wrapper">
          <p className="account-balance-text ">
            {`${translate('balance')}:`}&nbsp;
          </p>
          <div className="account-balance-amount-wrapper">
            <p
              className="account-balance-text"
              data-testid="account-balance-amount"
            >{`${balanceAmount} `}</p>
            {balance && (
              <div className={spinnerClassName}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            )}
          </div>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          title={translate('top_up')}
          onClick={handleOpenBalanceTopUp}
        />
      </div>
      <PaymentModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  )
}
