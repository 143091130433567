import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TAssignmentGroup} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'

import AssignmentGroupsFormNewContainer from './AssignmentGroupsFormNewContainer'
import AsignmentGroupsWrapper from './assignment-groups-from-container/assignment-groups-wrapper'

const AssignmentGroups = () => (
  <ItsmListTable<TAssignmentGroup>
    asset={ItsmAssets.AssignmentGroups}
    newComponent={<AssignmentGroupsFormNewContainer />}
    detailComponent={<AsignmentGroupsWrapper />}
    drawerTitleNew={translate('assignment_group')}
    rowKey="uuid"
  />
)

export default AssignmentGroups
