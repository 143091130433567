import React from 'react'

import {useParams} from 'react-router-dom'

import CustomerProductForm from './customer-product-form'

const CustomerProductNew = () => {
  const {id} = useParams() as {
    id: string
  }

  return <CustomerProductForm id={id} />
}

export default CustomerProductNew
