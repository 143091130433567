import has from 'lodash/has'

import {TDataTablePreset} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {TBillingItem} from '@/modules/ITSM/typedef'

export const getUpdatedColumns = (
  columns: TDataTablePreset,
  record: TBillingItem
): TDataTablePreset => {
  const newColumns = [...columns] as TDataTablePreset

  if (record) {
    const getIndex = (columnTitle: string) =>
      newColumns.findIndex(({title}) => title === columnTitle)

    if (!has(record, 'supplier')) {
      const columnIndex = getIndex('supplier_expenses')
      newColumns[columnIndex]['selected'] = false
    }

    if (!has(record, 'customer')) {
      const columnIndex = getIndex('customer_expenses')
      newColumns[columnIndex]['selected'] = false
    }

    return newColumns
  }
  return newColumns
}
