import React, {useCallback, useEffect, useRef, useState} from 'react'

import {Col, Form, Input, Row} from 'antd'
import isEqual from 'lodash/isEqual'
import {Moment} from 'moment'
import {withErrorBoundary} from '@sentry/react'

import {translate} from '@/services/i18n'
import EtaPicker from '@/modules/ITSM/components/form-components/eta-picker'
import {LocationSelect} from '@/modules/ITSM/components/locationSelect/LocationSelect'
import {setIncidentTabIsBlocked} from '@/modules/ITSM/components/incident/incident-reducer'
import ErrorPage from '@/components/error/error-page/error-page'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import {Button} from '@/components/button/button'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {usePrevious} from '@/hooks/usePrevious'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {RequestStatus} from '@/typedef'

import {createData} from '../incident-k-request-detail-form/utils'
import {checkIfDisabled} from '../utils/check-if-disabled'
import {useStateContext} from '../../incident/incident-context'
import {TIncident, TIncidentReq, TIncidentValue} from '../typedf'
import {useGetPriorityValues} from '../hooks/useGetPriorityValues'
import IncidentAssignment from '../../incident/incident-assignment/incident-assignment'
import DescriptionEditor from '../../description-editor/description-editor'
import {TOnUpdateParams} from '../../attachments/attachments-button'

import {getInitialValues} from './utils'

const TextArea = Input.TextArea

const IncidentAssigneeStatusForm = () => {
  const [form] = Form.useForm()
  const {
    entityData,
    updateEntity,
    getData,
    isLoading,
    dispatch,
  } = useStateContext<TIncident>()

  const [, setRefresh] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    state_id,
    location,
    expected_start,
    expected_end,
    description,
    short_description,
    supplier_product,
    customer_product,
    assignment_group,
    assigned_to,
  } = entityData || {}

  const {
    editorState,
    onEditorStateChange,
    editorText,
    setText,
  } = useSetEditorState(description)

  const prevEditorText = usePrevious(editorText)

  const filesDataRef = useRef<TOnUpdateParams>()

  useGetPriorityValues()

  const isDisabled = state_id !== undefined && checkIfDisabled(state_id)

  const getFormattedData = useCallback(
    (values: TIncidentReq) => {
      values['description'] = editorText
      return createData({...values})
    },
    [editorText]
  )

  const checkValues = useCallback(() => {
    entityData &&
      setBtnDisabled(
        isEqual(
          getFormattedData(form.getFieldsValue()),
          getInitialValues(entityData)
        )
      )
  }, [entityData, form, getFormattedData])

  useEffect(() => {
    if (prevEditorText !== editorText) checkValues()
  }, [checkValues, editorText, prevEditorText])

  const onAddFilesChange = (params: TOnUpdateParams) => {
    filesDataRef.current = {...params}
    if (params.filesAmount > 0) {
      setBtnDisabled(false)
    }
  }

  const handleUpdateIncident = async (values: TIncidentValue) => {
    const data = getFormattedData({
      ...values,
      cmdb_ref: values.cmdb_ref ? [values.cmdb_ref] : undefined,
    })
    const result =
      entityData &&
      (await updateEntity(
        getDataDifference(getInitialValues(entityData), data)
      ))
    if (result !== undefined) {
      setBtnDisabled(true)
      dispatch(setIncidentTabIsBlocked(false))
      await getData(
        form.getFieldValue('assigned_to') !== entityData?.assigned_to
      )
      form.resetFields()
    }
  }

  const onAddedAttachments = () => {
    form.submit()
  }

  const handleUploadAttachment = async () => {
    const {
      filesAmount = 0,
      addAttachments: addFileAttachments,
      status: filesAttachmentStatus = RequestStatus.INITIAL,
    } = filesDataRef.current || {}
    const saveAttachments = async () => {
      if (addFileAttachments) {
        await addFileAttachments()
      }
    }
    if (filesAmount > 0 && filesAttachmentStatus === RequestStatus.INITIAL) {
      saveAttachments()
    }
  }

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])

  return (
    <Form
      validateMessages={{
        // eslint-disable-next-line
        required: '${label} is required',
      }}
      onFinish={(values: TIncidentValue) => {
        filesDataRef.current &&
        filesDataRef.current.filesAmount > 0 &&
        filesDataRef.current.status === RequestStatus.INITIAL
          ? handleUploadAttachment()
          : handleUpdateIncident(values)
      }}
      form={form}
      layout="vertical"
      onValuesChange={checkValues}
    >
      <IncidentAssignment
        form={form}
        setRefresh={() => setRefresh(Math.random())}
        isDisabled={isDisabled}
        supplierProduct={supplier_product}
        customerProduct={customer_product}
        assignmentGroup={assignment_group}
        assignedTo={assigned_to}
        stateId={state_id}
      />
      <Row gutter={[16, 16]}>
        <EtaPicker
          expectedDateInit={expected_start}
          name="expected_start"
          isDisabled={isDisabled}
        />
        <EtaPicker
          expectedDateInit={expected_end}
          name="expected_end"
          isDisabled={!form.getFieldValue('expected_start') || isDisabled}
          disabledDates={(d: Moment) =>
            !d || d.isBefore(form.getFieldValue('expected_start'))
          }
        />
      </Row>
      {location && (
        <Row>
          <Col span={24}>
            <LocationSelect
              recordLocationData={location}
              locationId={form.getFieldValue('location') || location?.uuid}
              setRefresh={() => setRefresh(Math.random())}
              isDisabled={true}
              required
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <FormItemGeneric<TIncidentValue>
            initialValue={short_description || null}
            name="short_description"
            label={translate('title')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea readOnly={isDisabled} rows={5} />
          </FormItemGeneric>
        </Col>
      </Row>
      <DescriptionEditor
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        isDisabled={isDisabled}
        description={description}
        setText={setText}
        editorText={editorText}
        immediateAttachments={true}
        onAddFiles={onAddFilesChange}
        onAddAttachments={onAddedAttachments}
      />
      {!isDisabled && (
        <Row>
          <Col span={24} className="mb-30 mt-10">
            <Button
              disabled={isLoading || btnDisabled}
              htmlType="submit"
              type="primary"
              title={translate('save')}
              size="large"
            />
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default withErrorBoundary(IncidentAssigneeStatusForm, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
