export const countriesList = [
  {name: 'Afghanistan', code: 'af'},
  {name: 'Albania', code: 'al'},
  {name: 'Algeria', code: 'dz'},
  {name: 'American Samoa', code: 'as'},
  {name: 'Andorra', code: 'ad'},
  {name: 'Angola', code: 'ao'},
  {name: 'Anguilla', code: 'ai'},
  {name: 'Antarctica', code: 'aq'},
  {name: 'Antigua and Barbuda', code: 'ag'},
  {name: 'Argentina', code: 'ar'},
  {name: 'Armenia', code: 'am'},
  {name: 'Aruba', code: 'aw'},
  {name: 'Australia', code: 'au'},
  {name: 'Austria', code: 'at'},
  {name: 'Azerbaijan', code: 'az'},
  {name: 'Bahamas', code: 'bs'},
  {name: 'Bahrain', code: 'bh'},
  {name: 'Bangladesh', code: 'bd'},
  {name: 'Barbados', code: 'bb'},
  {name: 'Belarus', code: 'by'},
  {name: 'Belgium', code: 'be'},
  {name: 'Belize', code: 'bz'},
  {name: 'Benin', code: 'bj'},
  {name: 'Bermuda', code: 'bm'},
  {name: 'Bhutan', code: 'bt'},
  {name: 'Bolivia', code: 'bo'},
  {name: 'Bosnia and Herzegovina', code: 'ba'},
  {name: 'Botswana', code: 'bw'},
  {name: 'Bouvet Island', code: 'bv'},
  {name: 'Brazil', code: 'br'},
  {name: 'British Indian Ocean Territory', code: 'io'},
  {name: 'Brunei Darussalam', code: 'bn'},
  {name: 'Bulgaria', code: 'bg'},
  {name: 'Burkina Faso', code: 'bf'},
  {name: 'Burundi', code: 'bi'},
  {name: 'Cambodia', code: 'kh'},
  {name: 'Cameroon', code: 'cm'},
  {name: 'Canada', code: 'ca'},
  {name: 'Cape Verde', code: 'cv'},
  {name: 'Cayman Islands', code: 'ky'},
  {name: 'Central African Republic', code: 'cf'},
  {name: 'Chad', code: 'td'},
  {name: 'Chile', code: 'cl'},
  {name: 'China', code: 'cn'},
  {name: 'Christmas Island', code: 'cx'},
  {name: 'Cocos (Keeling) Islands', code: 'cc'},
  {name: 'Colombia', code: 'co'},
  {name: 'Comoros', code: 'km'},
  {name: 'Congo', code: 'cg'},
  {name: 'Democratic Republic of the Congo', code: 'cd'},
  {name: 'Cook Islands', code: 'ck'},
  {name: 'Costa Rica', code: 'cr'},
  {name: "`Cote D'ivoire`", code: 'ci'},
  {name: 'Croatia', code: 'hr'},
  {name: 'Cuba', code: 'cu'},
  {name: 'Cyprus', code: 'cy'},
  {name: 'Czech Republic', code: 'cz'},
  {name: 'Denmark', code: 'dk'},
  {name: 'Djibouti', code: 'dj'},
  {name: 'Dominica', code: 'dm'},
  {name: 'Dominican Republic', code: 'do'},
  {name: 'Ecuador', code: 'ec'},
  {name: 'Egypt', code: 'eg'},
  {name: 'El Salvador', code: 'sv'},
  {name: 'Equatorial Guinea', code: 'gq'},
  {name: 'Eritrea', code: 'er'},
  {name: 'Estonia', code: 'ee'},
  {name: 'Ethiopia', code: 'et'},
  {name: 'Falkland Islands (Malvinas)', code: 'fk'},
  {name: 'Faroe Islands', code: 'fo'},
  {name: 'Fiji', code: 'fj'},
  {name: 'Finland', code: 'fi'},
  {name: 'France', code: 'fr'},
  {name: 'French Guiana', code: 'gf'},
  {name: 'French Polynesia', code: 'pf'},
  {name: 'French Southern Territories', code: 'tf'},
  {name: 'Gabon', code: 'ga'},
  {name: 'Gambia', code: 'gm'},
  {name: 'Georgia', code: 'ge'},
  {name: 'Germany', code: 'de'},
  {name: 'Ghana', code: 'gh'},
  {name: 'Gibraltar', code: 'gi'},
  {name: 'Greece', code: 'gr'},
  {name: 'Greenland', code: 'gl'},
  {name: 'Grenada', code: 'gd'},
  {name: 'Guadeloupe', code: 'gp'},
  {name: 'Guam', code: 'gu'},
  {name: 'Guatemala', code: 'gt'},
  {name: 'Guinea', code: 'gn'},
  {name: 'Guinea-Bissau', code: 'gw'},
  {name: 'Guyana', code: 'gy'},
  {name: 'Haiti', code: 'ht'},
  {name: 'Heard Island and Mcdonald Islands', code: 'hm'},
  {name: 'Holy See (Vatican City State)', code: 'va'},
  {name: 'Honduras', code: 'hn'},
  {name: 'Hong Kong', code: 'hk'},
  {name: 'Hungary', code: 'hu'},
  {name: 'Iceland', code: 'is'},
  {name: 'India', code: 'in'},
  {name: 'Indonesia', code: 'id'},
  {name: 'Iran, Islamic Republic of', code: 'ir'},
  {name: 'Iraq', code: 'iq'},
  {name: 'Ireland', code: 'ie'},
  {name: 'Israel', code: 'il'},
  {name: 'Italy', code: 'it'},
  {name: 'Jamaica', code: 'jm'},
  {name: 'Japan', code: 'jp'},
  {name: 'Jordan', code: 'jo'},
  {name: 'Kazakhstan', code: 'kz'},
  {name: 'Kenya', code: 'ke'},
  {name: 'Kiribati', code: 'ki'},
  {name: "Korea, the Democratic People's Republic of", code: 'kp'},
  {name: 'Korea, Republic of', code: 'kr'},
  {name: 'Kuwait', code: 'kw'},
  {name: 'Kyrgyzstan', code: 'kg'},
  {name: "Lao People's Democratic Republic", code: 'la'},
  {name: 'Latvia', code: 'lv'},
  {name: 'Lebanon', code: 'lb'},
  {name: 'Lesotho', code: 'ls'},
  {name: 'Liberia', code: 'lr'},
  {name: 'Libyan Arab Jamahiriya', code: 'ly'},
  {name: 'Liechtenstein', code: 'li'},
  {name: 'Lithuania', code: 'lt'},
  {name: 'Luxembourg', code: 'lu'},
  {name: 'Macao', code: 'mo'},
  {name: 'Macedonia', code: 'mk'},
  {name: 'Madagascar', code: 'mg'},
  {name: 'Malawi', code: 'mw'},
  {name: 'Malaysia', code: 'my'},
  {name: 'Maldives', code: 'mv'},
  {name: 'Mali', code: 'ml'},
  {name: 'Malta', code: 'mt'},
  {name: 'Marshall Islands', code: 'mh'},
  {name: 'Martinique', code: 'mq'},
  {name: 'Mauritania', code: 'mr'},
  {name: 'Mauritius', code: 'mu'},
  {name: 'Mayotte', code: 'yt'},
  {name: 'Mexico', code: 'mx'},
  {name: 'Micronesia, Federated States of', code: 'fm'},
  {name: 'Moldova, Republic of', code: 'md'},
  {name: 'Monaco', code: 'mc'},
  {name: 'Mongolia', code: 'mn'},
  {name: 'Montserrat', code: 'ms'},
  {name: 'Morocco', code: 'ma'},
  {name: 'Mozambique', code: 'mz'},
  {name: 'Myanmar', code: 'mm'},
  {name: 'Namibia', code: 'na'},
  {name: 'Nauru', code: 'nr'},
  {name: 'Nepal', code: 'np'},
  {name: 'Netherlands', code: 'nl'},
  {name: 'Netherlands Antilles', code: 'an'},
  {name: 'New Caledonia', code: 'nc'},
  {name: 'New Zealand', code: 'nz'},
  {name: 'Nicaragua', code: 'ni'},
  {name: 'Niger', code: 'ne'},
  {name: 'Nigeria', code: 'ng'},
  {name: 'Niue', code: 'nu'},
  {name: 'Norfolk Island', code: 'nf'},
  {name: 'Northern Mariana Islands', code: 'mp'},
  {name: 'Norway', code: 'no'},
  {name: 'Oman', code: 'om'},
  {name: 'Pakistan', code: 'pk'},
  {name: 'Palau', code: 'pw'},
  {name: 'Palestinian Territory, Occupied', code: 'ps'},
  {name: 'Panama', code: 'pa'},
  {name: 'Papua New Guinea', code: 'pg'},
  {name: 'Paraguay', code: 'py'},
  {name: 'Peru', code: 'pe'},
  {name: 'Philippines', code: 'ph'},
  {name: 'Pitcairn', code: 'pn'},
  {name: 'Poland', code: 'pl'},
  {name: 'Portugal', code: 'pt'},
  {name: 'Puerto Rico', code: 'pr'},
  {name: 'Qatar', code: 'qa'},
  {name: 'Reunion', code: 're'},
  {name: 'Romania', code: 'ro'},
  {name: 'Russian Federation', code: 'ru'},
  {name: 'Rwanda', code: 'rw'},
  {name: 'Saint Helena', code: 'sh'},
  {name: 'Saint Kitts and Nevis', code: 'kn'},
  {name: 'Saint Lucia', code: 'lc'},
  {name: 'Saint Pierre and Miquelon', code: 'pm'},
  {name: 'Saint Vincent and the Grenadines', code: 'vc'},
  {name: 'Samoa', code: 'ws'},
  {name: 'San Marino', code: 'sm'},
  {name: 'Sao Tome and Principe', code: 'st'},
  {name: 'Saudi Arabia', code: 'sa'},
  {name: 'Senegal', code: 'sn'},
  {name: 'Serbia and Montenegro', code: 'cs'},
  {name: 'Seychelles', code: 'sc'},
  {name: 'Sierra Leone', code: 'sl'},
  {name: 'Singapore', code: 'sg'},
  {name: 'Slovakia', code: 'sk'},
  {name: 'Slovenia', code: 'si'},
  {name: 'Solomon Islands', code: 'sb'},
  {name: 'Somalia', code: 'so'},
  {name: 'South Africa', code: 'za'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'gs'},
  {name: 'Spain', code: 'es'},
  {name: 'Sri Lanka', code: 'lk'},
  {name: 'Sudan', code: 'sd'},
  {name: 'Suriname', code: 'sr'},
  {name: 'Svalbard and Jan Mayen', code: 'sj'},
  {name: 'Swaziland', code: 'sz'},
  {name: 'Sweden', code: 'se'},
  {name: 'Switzerland', code: 'ch'},
  {name: 'Syrian Arab Republic', code: 'sy'},
  {name: 'Taiwan, Province of China', code: 'tw'},
  {name: 'Tajikistan', code: 'tj'},
  {name: 'Tanzania, United Republic of', code: 'tz'},
  {name: 'Thailand', code: 'th'},
  {name: 'Timor-Leste', code: 'tl'},
  {name: 'Togo', code: 'tg'},
  {name: 'Tokelau', code: 'tk'},
  {name: 'Tonga', code: 'to'},
  {name: 'Trinidad and Tobago', code: 'tt'},
  {name: 'Tunisia', code: 'tn'},
  {name: 'Turkey', code: 'tr'},
  {name: 'Turkmenistan', code: 'tm'},
  {name: 'Turks and Caicos Islands', code: 'tc'},
  {name: 'Tuvalu', code: 'tv'},
  {name: 'Uganda', code: 'ug'},
  {name: 'Ukraine', code: 'ua'},
  {name: 'United Arab Emirates', code: 'ae'},
  {name: 'United Kingdom', code: 'uk'},
  {name: 'United States', code: 'us'},
  {name: 'United States Minor Outlying Islands', code: 'um'},
  {name: 'Uruguay', code: 'uy'},
  {name: 'Uzbekistan', code: 'uz'},
  {name: 'Vanuatu', code: 'vu'},
  {name: 'Venezuela', code: 've'},
  {name: 'Viet Nam', code: 'vn'},
  {name: 'Virgin Islands, British', code: 'vg'},
  {name: 'Virgin Islands, U.S.', code: 'vi'},
  {name: 'Wallis and Futuna', code: 'wf'},
  {name: 'Western Sahara', code: 'eh'},
  {name: 'Yemen', code: 'ye'},
  {name: 'Zambia', code: 'zm'},
  {name: 'Zimbabwe', code: 'zw'},
]

export const parseCountryMap: Record<string, string> = {
  ax: 'Åland Islands',
  af: 'Afghanistan',
  al: 'Albania',
  dz: 'Algeria',
  as: 'American Samoa',
  ad: 'Andorra',
  ao: 'Angola',
  ai: 'Anguilla',
  aq: 'Antarctica',
  ag: 'Antigua and Barbuda',
  ar: 'Argentina',
  am: 'Armenia',
  aw: 'Aruba',
  au: 'Australia',
  at: 'Austria',
  az: 'Azerbaijan',
  bs: 'Bahamas',
  bh: 'Bahrain',
  bd: 'Bangladesh',
  bb: 'Barbados',
  by: 'Belarus',
  be: 'Belgium',
  bz: 'Belize',
  bj: 'Benin',
  bm: 'Bermuda',
  bt: 'Bhutan',
  bo: 'Bolivia',
  ba: 'Bosnia and Herzegovina',
  bw: 'Botswana',
  bv: 'Bouvet Island',
  br: 'Brazil',
  io: 'British Indian Ocean Territory',
  bn: 'Brunei Darussalam',
  bg: 'Bulgaria',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodia',
  cm: 'Cameroon',
  ca: 'Canada',
  cv: 'Cape Verde',
  ky: 'Cayman Islands',
  cf: 'Central African Republic',
  td: 'Chad',
  cl: 'Chile',
  cn: 'China',
  cx: 'Christmas Island',
  cc: 'Cocos (Keeling) Islands',
  co: 'Colombia',
  km: 'Comoros',
  cg: 'Congo',
  cd: 'Democratic Republic of the Congo',
  ck: 'Cook Islands',
  cr: 'Costa Rica',
  ci: "`Cote D'ivoire`",
  hr: 'Croatia',
  cu: 'Cuba',
  cy: 'Cyprus',
  cz: 'Czech Republic',
  dk: 'Denmark',
  dj: 'Djibouti',
  dm: 'Dominica',
  do: 'Dominican Republic',
  ec: 'Ecuador',
  eg: 'Egypt',
  sv: 'El Salvador',
  gq: 'Equatorial Guinea',
  er: 'Eritrea',
  ee: 'Estonia',
  et: 'Ethiopia',
  fk: 'Falkland Islands (Malvinas)',
  fo: 'Faroe Islands',
  fj: 'Fiji',
  fi: 'Finland',
  fr: 'France',
  gf: 'French Guiana',
  pf: 'French Polynesia',
  tf: 'French Southern Territories',
  ga: 'Gabon',
  gm: 'Gambia',
  ge: 'Georgia',
  de: 'Germany',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gr: 'Greece',
  gl: 'Greenland',
  gd: 'Grenada',
  gp: 'Guadeloupe',
  gu: 'Guam',
  gt: 'Guatemala',
  gn: 'Guinea',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  ht: 'Haiti',
  hm: 'Heard Island and Mcdonald Islands',
  va: 'Holy See (Vatican City State)',
  hn: 'Honduras',
  hk: 'Hong Kong',
  hu: 'Hungary',
  is: 'Iceland',
  in: 'India',
  id: 'Indonesia',
  ir: 'Iran, Islamic Republic of',
  iq: 'Iraq',
  ie: 'Ireland',
  il: 'Israel',
  it: 'Italy',
  jm: 'Jamaica',
  jp: 'Japan',
  jo: 'Jordan',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  ki: 'Kiribati',
  kp: "Korea, the Democratic People's Republic of",
  kr: 'Korea, Republic of',
  kw: 'Kuwait',
  kg: 'Kyrgyzstan',
  la: "Lao People's Democratic Republic",
  lv: 'Latvia',
  lb: 'Lebanon',
  ls: 'Lesotho',
  lr: 'Liberia',
  ly: 'Libyan Arab Jamahiriya',
  li: 'Liechtenstein',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mo: 'Macao',
  mk: 'Macedonia',
  mg: 'Madagascar',
  mw: 'Malawi',
  my: 'Malaysia',
  mv: 'Maldives',
  ml: 'Mali',
  mt: 'Malta',
  mh: 'Marshall Islands',
  mq: 'Martinique',
  mr: 'Mauritania',
  mu: 'Mauritius',
  yt: 'Mayotte',
  mx: 'Mexico',
  fm: 'Micronesia, Federated States of',
  md: 'Moldova, Republic of',
  mc: 'Monaco',
  mn: 'Mongolia',
  ms: 'Montserrat',
  ma: 'Morocco',
  mz: 'Mozambique',
  mm: 'Myanmar',
  na: 'Namibia',
  nr: 'Nauru',
  np: 'Nepal',
  nl: 'Netherlands',
  an: 'Netherlands Antilles',
  nc: 'New Caledonia',
  nz: 'New Zealand',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nigeria',
  nu: 'Niue',
  nf: 'Norfolk Island',
  mp: 'Northern Mariana Islands',
  no: 'Norway',
  om: 'Oman',
  pk: 'Pakistan',
  pw: 'Palau',
  ps: 'Palestinian Territory, Occupied',
  pa: 'Panama',
  pg: 'Papua New Guinea',
  py: 'Paraguay',
  pe: 'Peru',
  ph: 'Philippines',
  pn: 'Pitcairn',
  pl: 'Poland',
  pt: 'Portugal',
  pr: 'Puerto Rico',
  qa: 'Qatar',
  re: 'Reunion',
  ro: 'Romania',
  ru: 'Russian Federation',
  rw: 'Rwanda',
  sh: 'Saint Helena',
  kn: 'Saint Kitts and Nevis',
  lc: 'Saint Lucia',
  pm: 'Saint Pierre and Miquelon',
  vc: 'Saint Vincent and the Grenadines',
  ws: 'Samoa',
  sm: 'San Marino',
  st: 'Sao Tome and Principe',
  sa: 'Saudi Arabia',
  sn: 'Senegal',
  cs: 'Serbia and Montenegro',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapore',
  sk: 'Slovakia',
  si: 'Slovenia',
  sb: 'Solomon Islands',
  so: 'Somalia',
  za: 'South Africa',
  gs: 'South Georgia and the South Sandwich Islands',
  es: 'Spain',
  lk: 'Sri Lanka',
  sd: 'Sudan',
  sr: 'Suriname',
  sj: 'Svalbard and Jan Mayen',
  sz: 'Swaziland',
  se: 'Sweden',
  ch: 'Switzerland',
  sy: 'Syrian Arab Republic',
  tw: 'Taiwan, Province of China',
  tj: 'Tajikistan',
  tz: 'Tanzania, United Republic of',
  th: 'Thailand',
  tl: 'Timor-Leste',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinidad and Tobago',
  tn: 'Tunisia',
  tr: 'Turkey',
  tm: 'Turkmenistan',
  tc: 'Turks and Caicos Islands',
  tv: 'Tuvalu',
  ug: 'Uganda',
  ua: 'Ukraine',
  ae: 'United Arab Emirates',
  uk: 'United Kingdom',
  us: 'United States',
  um: 'United States Minor Outlying Islands',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  vu: 'Vanuatu',
  ve: 'Venezuela',
  vn: 'Viet Nam',
  vg: 'Virgin Islands, British',
  vi: 'Virgin Islands, U.S.',
  wf: 'Wallis and Futuna',
  eh: 'Western Sahara',
  ye: 'Yemen',
  zm: 'Zambia',
  zw: 'Zimbabwe',
}
