import LandingPage from '@/sites/LoginPage/landing-page'
import {Purchase} from '@/sites/purchase/purchase'
import {Services} from '@/sites/services/services'

import mainConfig from '@/global-routes'

export type TRouteData = {
  component: () => JSX.Element
  props: {
    path: string
    exact?: boolean
  }
}

export const publicRoutes: Array<TRouteData> = [
  {
    component: LandingPage,
    props: {
      path: mainConfig.general.landingPage.path(),
    },
  },
  {
    component: Purchase,
    props: {
      path: mainConfig.general.purchase.path(),
    },
  },
  {
    component: Services,
    props: {
      path: mainConfig.general.services.path(),
    },
  },
]
