import React, {useEffect} from 'react'

import {useHistory, useParams} from 'react-router-dom'

import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {useGetSingleData} from '@/hooks/useGetSignleData'
import {TAnnouncement} from '@/modules/ITSM/typedef'
import {useQuery} from '@/utils/useQuery'
import {fetchAnnouncement} from '@/modules/ITSM/api/announcementsRequests'
import {Paper, PaperVariant} from '@/components/paper/paper'

import AnnouncementForm from './announcement-form'

const AnnouncementDetails = () => {
  const {id} = useParams() as {
    id: string
  }
  const isCopy = useQuery('copy')
  const history = useHistory()

  const {entityData, getData} = useGetSingleData<TAnnouncement>(
    fetchAnnouncement
  )

  useEffect(() => {
    if (!entityData) {
      getData({id})
    }
  }, [entityData, getData, history, id, isCopy])

  return (
    <Paper variant={PaperVariant.form}>
      <Skeleton
        active={!entityData}
        view={<FormSkeleton className="incident-k-request-tabs" />}
      >
        <AnnouncementForm entityData={entityData} getData={getData} id={id} />
      </Skeleton>
    </Paper>
  )
}

export default AnnouncementDetails
