import {ReactNode} from 'react'

export type TGeneratorFilters = Array<{
  isApplied: boolean
  title: string
  filter?: ReactNode
}>

export type TQuickFilter = {
  iconAfter?: ReactNode
  isActive: boolean
  onClick: () => void
  title: string
}

export enum TDefaultQuickFilters {
  None = 'none',
  All = 'all',
}
