import {useEffect, useState} from 'react'

import {fetchOrgsRole} from '@/modules/ITSM/api/itsmOrgRequests'

export const useGetAssignmentGroupUsers = () => {
  const [orgsRoles, setOrgsRoles] = useState<Array<string>>()

  useEffect(() => {
    const getOrgsRoles = async () => {
      const {
        body: {
          result: {value: orgRolesRes},
        },
      } = await fetchOrgsRole()
      const internalOrgs: Array<string> = []

      for (const key in orgRolesRes) {
        if (orgRolesRes[key].role !== 'supplier') internalOrgs.push(key)
      }
      setOrgsRoles(internalOrgs)
    }

    if (!orgsRoles) getOrgsRoles()
  }, [orgsRoles])

  return {
    orgsRoles,
  }
}
