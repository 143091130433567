import React, {Fragment} from 'react'

import {Col, Form, Input, Row} from 'antd'

import {translate} from '@/services/i18n'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import FormItemCustom from '@/components/form/form-item-custom'

const Statistics = props => {
  const [form] = Form.useForm()

  const {stats = {}} = props

  return (
    <Form form={form} layout="vertical">
      <Fragment>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom
              name="avg_price"
              label={translate('avg_price')}
              initialValue={
                stats.avg_price
                  ? stats.avg_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' EUR'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom
              name="avg_work_time"
              label={translate('avg_work_time')}
              initialValue={
                stats.avg_work_time
                  ? Math.round(stats.avg_work_time / 3600) + ' h'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom
              name="max_price"
              label={translate('max_price')}
              initialValue={
                stats.max_price
                  ? stats.max_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' EUR'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom
              name="max_work_time"
              label={translate('max_work_time')}
              initialValue={
                stats.max_work_time
                  ? Math.round(stats.max_work_time / 3600) + ' h'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom
              name="median_price"
              label={translate('median_price')}
              initialValue={
                stats.median_price
                  ? stats.median_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' EUR'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom
              name="median_work_time"
              initialValue={
                stats.median_work_time
                  ? Math.round(stats.median_work_time / 3600) + ' h'
                  : null
              }
              label={translate('median_work_time')}
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom
              name="min_price"
              label={translate('min_price')}
              initialValue={
                stats.min_price
                  ? stats.min_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' EUR'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom
              name="min_work_time"
              label={translate('min_work_time')}
              initialValue={
                stats.min_work_time
                  ? Math.round(stats.min_work_time / 3600) + ' h'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom
              name="std_deviation_price"
              label={translate('std_deviation_price')}
              initialValue={
                stats.std_deviation_price
                  ? stats.std_deviation_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' EUR'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom
              name="std_deviation_work_time"
              label={translate('std_deviation_work_time')}
              initialValue={
                stats.std_deviation_work_time
                  ? Math.round(stats.std_deviation_work_time / 3600) + ' h'
                  : null
              }
            >
              <Input readOnly />
            </FormItemCustom>
          </Col>
        </Row>
      </Fragment>
    </Form>
  )
}

export default Statistics
