import globalRoutes from '@/global-routes'

const {
  moduleRoutes: {
    cmdb: {path: cmdbModulePath},
  },
} = globalRoutes

const cmdbRoutes = {
  cmdbDashboard: {
    name: 'cmdbDashboard',
    path: () => `${cmdbModulePath()}/assets/cmdbDashboard`,
  },
  assetsNew: {
    name: 'assetsNew',
    path: () => `${cmdbModulePath()}/manage-assets/asset-new`,
  },
  assetDetails: {
    name: 'manage-assets',
    path: (id = ':id?') => `${cmdbModulePath()}/manage-assets/assets/${id}`,
  },
  manageAssets: {
    name: 'manage-assets',
    path: () => `${cmdbModulePath()}/manage-assets/assets`,
  },
  assetTypes: {
    name: 'asset-types',
    path: () => `${cmdbModulePath()}/manage-assets/asset-types`,
  },
  assetTypeDetail: {
    name: 'assetTypeDetail',
    path: (id = ':id?') =>
      `${cmdbModulePath()}/manage-assets/asset-types/${id}`,
  },
  locations: {
    name: 'locations',
    path: () => `${cmdbModulePath()}/manage-assets/locations`,
  },
  locationNew: {
    name: 'locationNew',
    path: () => `${cmdbModulePath()}/manage-assets/locations/new`,
  },
  locationDetail: {
    name: 'locationDetail',
    path: (id = ':id?') => `${cmdbModulePath()}/manage-assets/locations/${id}`,
  },
  users: {
    name: 'users',
    path: () => `${cmdbModulePath()}/manage-account/users`,
  },
  userNew: {
    name: 'userNew',
    path: () => `${cmdbModulePath()}/manage-account/users/new`,
  },
  userDetail: {
    name: 'userDetail',
    path: (id = ':id?') => `${cmdbModulePath()}/manage-account/users/${id}`,
  },
  accounts: {
    name: 'accounts',
    path: () => `${cmdbModulePath()}/manage-account/accounts`,
  },
  namespaces: {
    name: 'namespaces',
    path: () => `${cmdbModulePath()}/manage-account/namespaces`,
  },
  namespaceNew: {
    name: 'namespaces',
    path: () => `${cmdbModulePath()}/manage-account/namespaces/new`,
  },
  namespaceDetail: {
    name: 'namespaceDetail',
    path: (id = ':id?') =>
      `${cmdbModulePath()}/manage-account/namespaces/${id}`,
  },
  cmdbSettings: {
    name: 'cmdbSettings',
    path: () => `${cmdbModulePath()}/manage-account/cmdb-settings`,
  },
}

export default cmdbRoutes
