import {postSubscription, searchPricesByMetadata} from '@/api/billing-requests'

import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {generateSearchPriceParam} from '@/modules/Subscriptions/utils/generate-search-price-param'

import {Interval, IPrice, PriceFeatureFlagKeys} from '@/modules/typedef'
import {MetadataType} from '@/modules/Subscriptions/utils/constants'
import {useState} from 'react'
import {RequestStatus} from '@/typedef'

export const useSubscribeAllInclusive = () => {
  const [subscribeStatus, setSubscribeStatus] = useState(RequestStatus.INITIAL)
  const subscribeAllInclusive = async (orgToken: string) => {
    try {
      setSubscribeStatus(RequestStatus.REQUESTED)
      const {
        body: {result: allInclusivePrices},
      } = await searchPricesByMetadata({
        queryParam: generateSearchPriceParam(
          'feature_flag',
          PriceFeatureFlagKeys.ALL_INCLUSIVE
        ),
        headers: createAuthorizationHeader(orgToken),
      })

      const initialPrice =
        allInclusivePrices.find(
          (price: IPrice) => price.recurring?.interval === Interval.YEAR
        ) || allInclusivePrices[0]

      if (!initialPrice) {
        setSubscribeStatus(RequestStatus.FAILED)
        return new Error('no found the all inclusive price')
      }

      await postSubscription({
        priceId: initialPrice.id,
        trial: true,
        interval: Interval.MONTH,
        type: MetadataType.BLITS_SOFTWARE_PRODUCT,
        headers: createAuthorizationHeader(orgToken),
      })

      setSubscribeStatus(RequestStatus.SUCCEEDED)
    } catch (error) {
      setSubscribeStatus(RequestStatus.FAILED)
      return error
    } finally {
      setSubscribeStatus(RequestStatus.INITIAL)
    }
  }

  return {
    subscribeStatus,
    subscribeAllInclusive,
  }
}
