import {REHYDRATE} from 'redux-persist/lib/constants'

import {RequestStatus} from '@/typedef'
import globalRoutes from '@/global-routes'

import * as ActionTypes from '../actionTypes'

const initialSate = {
  grant: '',
  org_id: '',
  channel_invite: '',
  channel_id: '',
  invitationStatus: RequestStatus.INITIAL,
  postOrganizationStatus: RequestStatus.INITIAL,
  grantRequestStatus: RequestStatus.INITIAL,
  postIdentityStatus: RequestStatus.INITIAL,
  postIdentityFailMessage: '',
  grantReqError: '',
  infoMessage: '',
  accountId: '',
  accounts: [],
  organizations: [],
  step: undefined,
  redirectUrl: '',
  invitationAccepted: false,
  apiErrorMssg: '',
}

export default function invitationReducer(state = initialSate, action) {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        grant: action.payload?.invitation?.grant || '',
        channel_invite: action.payload?.invitation?.channel_invite || '',
        channel_id: action.payload?.invitation?.channel_id || '',
      }

    case ActionTypes.SET_INVIT_ORG_ID_GRANT: {
      return {
        ...state,
        org_id: action.payload.org_id,
        grant: action.payload.grant,
        redirectUrl: undefined,
      }
    }

    case ActionTypes.SET_POST_GRANT_REQUESTED: {
      return {
        ...state,
        grantRequestStatus: RequestStatus.REQUESTED,
        invitationStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.SET_POST_GRANT_SUCCEEDED: {
      return {
        ...state,
        grantRequestStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.SET_POST_GRANT_FAILED: {
      return {
        ...state,
        grantRequestStatus: RequestStatus.FAILED,
        grantReqError: action.payload,
      }
    }

    case ActionTypes.REFRESH_INVIT_ORG_TKN_REQUESTED: {
      return {
        ...state,
      }
    }

    case ActionTypes.POST_IDENTITY_REQUESTED: {
      return {
        ...state,
        postIdentityStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.POST_IDENTITY_SUCCEEDED: {
      return {
        ...state,
        postIdentityStatus: RequestStatus.SUCCEEDED,
        infoMessage: 'the_invitation_was_accepted',
        invitationStatus: RequestStatus.SUCCEEDED,
        grant: '',
      }
    }
    case ActionTypes.POST_IDENTITY_FAILED: {
      return {
        ...state,
        infoMessage: 'failed_to_accept_the_invitation',
        postIdentityFailMessage: action.payload,
        postIdentityStatus: RequestStatus.FAILED,
        invitationStatus: RequestStatus.FAILED,
      }
    }
    // channel
    case ActionTypes.GET_ACCOUNT_SUCCEEDED: {
      return {
        ...state,
        accountId: action.payload,
      }
    }

    case ActionTypes.GET_ACCOUNTS_SUCCEEDED: {
      return {
        ...state,
        accounts: action.payload,
      }
    }

    case ActionTypes.GET_ORG_ACCOUNTS_SUCCEEDED: {
      return {
        ...state,
        organizations: action.payload,
      }
    }

    case ActionTypes.POST_INVIT_CHANNEL_ID_GRANT: {
      return {
        ...state,
        grant: action.payload.grant,
      }
    }

    case ActionTypes.SET_CHANNEL_INVIT_ORG_ID_GRANT: {
      return {
        ...state,
        grant: action.payload.grant,
        channel_invite: action.payload.channel_invite,
      }
    }

    case ActionTypes.SET_CHANNEL_STEP: {
      return {
        ...state,
        step: action.payload,
      }
    }

    case ActionTypes.ACCEPT_INVITE_REQUESTED: {
      return {
        ...state,
        invitationStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.ACCEPT_INVITE_SUCCEEDED: {
      return {
        ...state,
        grant: '',
        grant_org: '',
        channel_invite: '',
        redirectUrl: action.payload.redirectUrl,
        infoMessage: action.payload.infoMessage,
        invitationStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.SET_INVITE_MODAL_MESSAGE: {
      return {
        ...state,
        infoMessage: action.payload,
      }
    }

    case ActionTypes.INVITATION_ALREADY_ACCEPTED: {
      return {
        ...state,
        grant: '',
        grant_org: '',
        channel_invite: '',
        redirectUrl: globalRoutes.general.homePage.path(),
        infoMessage: action.payload,
        invitationAccepted: true,
        invitationStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.ACCEPT_INVITE_FAILED: {
      return {
        ...state,
        grant: '',
        grant_org: '',
        channel_invite: '',
        apiErrorMssg: action.payload.apiErrorMssg,
        infoMessage: action.payload.infoMessage,
        invitationStatus: RequestStatus.FAILED,
        redirectUrl: action.payload.redirectUrl,
      }
    }

    case ActionTypes.INVITATION_SET_REDIRECT: {
      return {
        ...state,
        redirectUrl: action.payload,
      }
    }

    case ActionTypes.POST_ORGANIZATION_REQUESTED: {
      return {
        ...state,
        postOrganizationStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.POST_ORGANIZATION_SUCCEEDED: {
      return {
        ...state,
        postOrganizationStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.POST_ORGANIZATION_FAILED: {
      return {
        ...state,
        postOrganizationStatus: RequestStatus.FAILED,
      }
    }
    case ActionTypes.POST_ORGANIZATION_RESET: {
      return {
        ...state,
        postOrganizationStatus: RequestStatus.INITIAL,
      }
    }

    default:
      return state
  }
}
