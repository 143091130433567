import React from 'react'

import {withErrorBoundary} from '@sentry/react'
import {Checkbox, Col, Form, Input, Row} from 'antd'
import moment from 'moment'
import 'moment-duration-format'

import ErrorPage from '@/components/error/error-page/error-page'
import {translate} from '@/services/i18n'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {DATE_FORMAT} from '@/constants'
import {getUserFullName} from '@/utils/get-user-full-name'
import FormItemCustom from '@/components/form/form-item-custom'

import {TIncident} from './typedf'
import {IncidentState} from './constants'

const IncidentStatusLog = ({record}: {record: TIncident | undefined}) => {
  return (
    <Form layout="vertical">
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('createdAt')}>
            <Input
              value={
                record?.created_at &&
                moment(record.created_at)
                  .format(DATE_FORMAT)
                  .toString()
              }
              readOnly
            />
          </FormItemCustom>
        </Col>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('created_by')}>
            <Input
              value={record?.created_by && getUserFullName(record.created_by)}
              placeholder={translate('empty')}
              readOnly
            />
          </FormItemCustom>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('updated_at')}>
            <Input
              value={
                record?.updated_at &&
                moment(record.updated_at)
                  .format(DATE_FORMAT)
                  .toString()
              }
              readOnly
            />
          </FormItemCustom>
        </Col>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('updated_by')}>
            <Input
              value={record?.updated_by && getUserFullName(record.updated_by)}
              placeholder={translate('empty')}
              readOnly
            />
          </FormItemCustom>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('active')}>
            <Checkbox disabled checked={!!record?.active} />
          </FormItemCustom>
        </Col>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('activity_due')}>
            <Input
              value={
                record?.activity_due &&
                moment(record.activity_due)
                  .format(DATE_FORMAT)
                  .toString()
              }
              readOnly
            />
          </FormItemCustom>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('response_time_spent')}>
            <Input
              value={
                (
                  (record?.response_time_spent &&
                    moment
                      .duration(record.response_time_spent, 'seconds')
                      .format('h:mm') + record?.response_time_spent) ||
                  0
                )?.toString() +
                (record?.response_time_spent || 0 > 3599 ? ' HH:mm' : ' min')
              }
              readOnly
              placeholder={translate('empty')}
            />
          </FormItemCustom>
        </Col>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('response_time_due')}>
            <Input
              value={
                record?.response_time_due &&
                moment(record.response_time_due)
                  .format(DATE_FORMAT)
                  .toString()
              }
              readOnly
            />
          </FormItemCustom>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('resolution_time_spent')}>
            <Input
              value={
                (
                  (record?.resolution_time_spent &&
                    moment
                      .duration(record.resolution_time_spent, 'seconds')
                      .format('h:mm') + record?.resolution_time_spent) ||
                  0
                )?.toString() +
                (record?.resolution_time_spent || 0 > 3599 ? ' HH:mm' : ' min')
              }
              placeholder={translate('empty')}
              readOnly
            />
          </FormItemCustom>
        </Col>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('resolution_time_due')}>
            <Input
              value={
                record?.resolution_time_due &&
                moment(record.resolution_time_due)
                  .format(DATE_FORMAT)
                  .toString()
              }
              readOnly
            />
          </FormItemCustom>
        </Col>
      </Row>
      {(record?.state_id === IncidentState.Closed ||
        record?.state_id === IncidentState.Cancelled) && (
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom label={translate('resolved_at')}>
              <Input
                value={
                  record?.resolved_at &&
                  moment(record.resolved_at)
                    .format(DATE_FORMAT)
                    .toString()
                }
                readOnly
              />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom label={translate('resolved_by')}>
              <Input
                value={
                  record.resolved_by && getUserFullName(record.resolved_by)
                }
                readOnly
                placeholder={translate('empty')}
              />
            </FormItemCustom>
          </Col>
        </Row>
      )}
      {record?.state_id === IncidentState.Cancelled && (
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemCustom label={translate('closed_at')}>
              <Input
                value={
                  record?.closed_at &&
                  moment(record.closed_at)
                    .format(DATE_FORMAT)
                    .toString()
                }
                readOnly
              />
            </FormItemCustom>
          </Col>
          <Col {...twoColumns}>
            <FormItemCustom label={translate('closed_by')}>
              <Input
                value={record?.closed_by && getUserFullName(record.closed_by)}
                placeholder={translate('empty')}
                readOnly
              />
            </FormItemCustom>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('last_reopened_by')}>
            <Input
              value={
                record?.last_reopened_by &&
                getUserFullName(record.last_reopened_by)
              }
              readOnly
              placeholder={translate('empty')}
            />
          </FormItemCustom>
        </Col>
        <Col {...twoColumns}>
          <FormItemCustom label={translate('last_reopened_at')}>
            <Input
              value={
                record?.last_reopened_at &&
                moment(record.last_reopened_at)
                  .format(DATE_FORMAT)
                  .toString()
              }
              readOnly
            />
          </FormItemCustom>
        </Col>
      </Row>
    </Form>
  )
}

export default withErrorBoundary(IncidentStatusLog, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
