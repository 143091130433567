import {useCallback, useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {ItsmAssets} from '@/modules/ITSM/typedef'
import {TKey, translate} from '@/services/i18n'
import {
  setItsmTableFilters,
  setItsmTableQuickFilters,
} from '@/modules/ITSM/store/list-table/table-actions'
import {usePrevious} from '@/hooks/usePrevious'
import {
  selectItsmTableFilters,
  selectItsmTableQuickFilter,
} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'
import {TFilterListTable} from '@/modules/ITSM/components/itsm-list-table/typedf'

import {ExpensesQuickFilters, TExpense} from '../typedef'

type TProps = {
  asset: ItsmAssets
}

const useGetQuickFilters = ({asset}: TProps) => {
  const dispatch = useDispatch()

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, asset)
  )
  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<TExpense>(state, asset)
  )

  const getAssetQuickFilters = useCallback((): Array<{
    name: string
    query?: Record<string, any>
  }> => {
    return [
      {name: ExpensesQuickFilters.All, query: {}},
      {name: ExpensesQuickFilters.Approved, query: {approved: {$exists: true}}},
      {
        name: ExpensesQuickFilters.NotApproved,
        query: {approved: {$exists: false}, removed: {$exists: false}},
      },
      {
        name: ExpensesQuickFilters.Removed,
        query: {removed: {$exists: true}, approved: {$exists: false}},
      },
    ]
  }, [])

  const setQuickFilters = useCallback(
    (passedFilters?: TFilterListTable<TExpense> | Record<string, never>) => {
      const {query} =
        getAssetQuickFilters().find(({name}) => name === quickFilterActive) ||
        {}

      dispatch(
        setItsmTableFilters(asset, {
          ...query,
          ...passedFilters,
        })
      )
    },
    [asset, dispatch, getAssetQuickFilters, quickFilterActive]
  )

  const prevQuickFilterActive = usePrevious(quickFilterActive)

  useEffect(() => {
    if (prevQuickFilterActive !== quickFilterActive) {
      setQuickFilters(
        quickFilterActive === ExpensesQuickFilters.None ? filters : {}
      )
    }
  }, [filters, prevQuickFilterActive, quickFilterActive, setQuickFilters])

  useEffect(() => {
    if (
      !Object.values(ExpensesQuickFilters).includes(
        quickFilterActive as ExpensesQuickFilters
      )
    ) {
      dispatch(setItsmTableQuickFilters(asset, ExpensesQuickFilters.Approved))
    }
    if (
      Object.keys(filters).length === 0 &&
      quickFilterActive === ExpensesQuickFilters.None
    ) {
      dispatch(setItsmTableQuickFilters(asset, ExpensesQuickFilters.All))
    }
  }, [asset, dispatch, filters, quickFilterActive])

  const getQuickFilters = () => {
    return (getAssetQuickFilters() || []).map(({name}) => ({
      title: translate(name as TKey),
      isActive: quickFilterActive === name,
      onClick: () =>
        dispatch(setItsmTableQuickFilters(asset, name as ExpensesQuickFilters)),
    }))
  }
  return {getQuickFilters, setQuickFilters}
}

export default useGetQuickFilters
