import {useCallback, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResponse} from '@/api/response-typedf'

export function useRequestData<T>() {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const [entityData, setEntityData] = useState<T>()

  const run = useCallback(
    async (promise: Promise<ISuperAgentResponse<T>>) => {
      try {
        handleRequested()
        setEntityData(undefined)
        const res = await promise

        if (res?.body) {
          setEntityData(res.body)
          handleSuccess()

          return res
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    entityData,
    run,
    runEntityStatus: status,
  }
}
