import {transformPrice} from '@/modules/Subscriptions/utils/transform-price'

export const transformDescription = ({
  date,
  budget,
  'payment-period': paymentPeriod,
  description,
}: {
  date?: string
  budget?: string
  'payment-period'?: string
  description?: string
}) => {
  let result = ''

  if (date) result = date
  if (budget) {
    const formattedBudget = transformPrice(parseInt(budget) * 100)
    result = result ? `${result} - ${formattedBudget}` : formattedBudget
  }
  if (paymentPeriod) {
    result = result ? `${result} - ${paymentPeriod}` : paymentPeriod
  }
  if (description) result = result ? `${result}\n${description}` : description

  return result
}
