import {translate} from '@/services/i18n'
import {servicesReverseMap} from '@/sites/services/constants/services-reverce-map'

import {SideTabs} from '@/sites/services/typedef'

export const generateServiceProductName = (
  selectedServices: Record<string, string | string[]>
) => {
  return Object.keys(selectedServices).reduce(
    (nameString: string, service: string) => {
      const serviceValue = selectedServices[service]
      return typeof serviceValue === 'string'
        ? nameString
            .concat(' - ')
            .concat(`${servicesReverseMap[service][serviceValue]}`)
        : nameString.concat(
            serviceValue.reduce(
              (fromArrayNameString: string, value: string) => {
                return fromArrayNameString.concat(
                  `, ${servicesReverseMap[service][value.toLocaleLowerCase()]}`
                )
              },
              ''
            )
          )
    },
    translate(SideTabs.END_USER_SUPPORT)
  )
}
