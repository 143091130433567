import {History} from 'history'

import {generateConfig} from '@/generateConfig'

import {assetTypeIcons} from '../sites/assets-list/constants'

export const handleRouterPush = (history: History, pathname: string) =>
  history.push({pathname, search: history.location.search})

export const getFormattedJson = (value: Record<string, any> | string) => {
  return JSON.stringify(value, undefined, 4)
}

export const normalizeTitle = (title: string) => {
  if (assetTypeIcons[title]) {
    return assetTypeIcons[title].toLowerCase().replaceAll(' ', '-')
  }
  return null
}

export const getIconsPath = (name: string) => {
  return `/assets/asset-icons/${name}.svg`
  // const isDev = process.env.NODE_ENV === 'development'
  //
  // return isDev
  //   ? `/assets/asset-icons/${name}.svg`
  //   : `${generateConfig().BASE_URL}/assets/asset-icons/${name}.svg`
}
