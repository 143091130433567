import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {TListOptions} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {
  creditSystem,
  creditSystemHistory,
} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getBalance = async () => {
  return await request.options(creditSystem().options).set(getHeadersWithGRPC())
}

export const postCredit = async (data: Record<string, any>) => {
  return await request
    .post(creditSystem().post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getCreditMode = async () => {
  return await request.get(creditSystem().get).set(getHeadersWithGRPC())
}

export const fetchCreditHistory = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(creditSystemHistory().options + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
    })
