import React, {useEffect, useRef} from 'react'

import {RequestStatus, TListOptions} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'

import {useSafeDispatch} from './useSafeDispatch'
import {usePrevious} from './usePrevious'

type TAsyncState<T> = {
  status: RequestStatus
  data: T[]
  error?: string
  bookMark?: string
  fetchMoreRecords?: boolean
}
type TAsyncAction<T> =
  | {
      type: RequestStatus.REQUESTED
    }
  | {
      type: RequestStatus.SUCCEEDED
      data: Array<T>
      bookmark: string
      fetchMoreRecords: boolean
    }
  | {type: RequestStatus.FAILED; error: string}

function asyncReducer<T>(state: TAsyncState<T>, action: TAsyncAction<T>) {
  switch (action.type) {
    case RequestStatus.REQUESTED: {
      return {
        status: RequestStatus.REQUESTED,
        data: state.data,
        bookMark: state.bookMark,
      }
    }
    case RequestStatus.SUCCEEDED: {
      return {
        status: RequestStatus.SUCCEEDED,
        error: '',
        bookMark: action.bookmark,
        data: action.data,
        numberOfRecords: action?.data?.length || 0,
        fetchMoreRecords: action.fetchMoreRecords,
      }
    }
    case RequestStatus.FAILED: {
      return {
        status: RequestStatus.FAILED,
        data: [],
        error: action.error,
        bookMark: '',
      }
    }
    default: {
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`)
    }
  }
}
export function useGetAllBmData<T>(
  promise: (options: TListOptions<T>) => Promise<ISuperAgentResMultiple<T>>,
  initialState?: TAsyncState<T>
) {
  const [state, unsafeDispatch] = React.useReducer<
    React.Reducer<TAsyncState<T>, TAsyncAction<T>>
  >(asyncReducer, {
    status: RequestStatus.INITIAL,
    error: '',
    bookMark: undefined,
    fetchMoreRecords: false,
    data: [],
    ...initialState,
  })

  const {data, bookMark, error, status, fetchMoreRecords} = state
  const selectorRef = useRef<Record<string, any>>({})
  const dataRef = useRef<T[]>([])

  const dispatch = useSafeDispatch(unsafeDispatch)
  const prevbookmark = usePrevious(bookMark)

  const run = React.useCallback(
    async ({
      selector,
      resetData,
    }: {
      selector: Record<string, any>
      resetData?: boolean
    }) => {
      selectorRef.current = selector

      dispatch({type: RequestStatus.REQUESTED})

      try {
        const {
          body: {bookmark, result},
        } = await promise({
          passedBookmark: bookMark,
          selector,
        })

        dataRef.current = [
          ...(resetData ? [] : dataRef.current),
          ...(result || []),
        ]

        dispatch({
          type: RequestStatus.SUCCEEDED,
          ...(result?.length === 10 ? {bookmark} : {data: dataRef.current}),
          fetchMoreRecords: result?.length === 10,
        })
      } catch (err) {
        dispatch({type: RequestStatus.FAILED, err})
      }
    },
    [bookMark, dispatch, promise]
  )

  useEffect(() => {
    if (fetchMoreRecords && prevbookmark !== bookMark) {
      run({selector: selectorRef.current})
    }
  }, [fetchMoreRecords, prevbookmark, run, bookMark])

  return {
    data,
    bookMark,
    error,
    status,
    run,
  }
}
