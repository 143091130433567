import {Stats, TYear} from '@/modules/ITSM/sites/finance-dashboard/types'
import {translate} from '@/services/i18n'

type TTransformData = Array<{
  name: string
  data: Array<{
    x: number
    y: number
  }>
}>

export const transformYearlyOverviewData = (data: Array<TYear>) =>
  data.reduce<TTransformData>(
    (accumulateTransformedData, yearStatistic) =>
      Object.values(Stats).map((stat, index) => ({
        name: translate(stat),
        data: [
          ...(accumulateTransformedData[index]?.data || []),
          {
            x: Number(yearStatistic.year),
            y: Math.round(
              yearStatistic.data.reduce(
                (accumulateCostOrRevenue, monthStatistic) =>
                  accumulateCostOrRevenue + monthStatistic[stat],
                0
              )
            ),
          },
        ],
      })),
    []
  )
