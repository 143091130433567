import {Entities} from '@/typedef'

import {IncidentState} from '../../incident-k-request/constants'
import {IncidentTaskState} from '../../incident-task/typedf'

export const checkIfClosedState = (
  state_id: number,
  entity: Entities | string
) => {
  if (entity === Entities.INCIDENT) return state_id === IncidentState.Closed
  if (entity === Entities.INCIDENT_TASK)
    return (
      state_id === IncidentTaskState.Closed_complete ||
      state_id === IncidentTaskState.Closed_incomplete ||
      state_id === IncidentTaskState.Closed_skipped
    )
}
