export const countryOptions = [
  {
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    label: 'Åland Islands',
    value: 'AX',
  },
  {
    label: 'Albania',
    value: 'AL',
  },
]
