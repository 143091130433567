export const ApiMethod = {
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  DELETE: 'DELETE',
}

export enum RequestStatusCode {
  STATUS_200 = 200,
  STATUS_400 = 400,
  STATUS_401 = 401,
  STATUS_403 = 403,
  STATUS_500 = 500,
}
