import {Cz, De, En, Fr, It, Sp} from '@/components/language-switcher/assets'

export const LANGUAGE_DATA: Record<string, {language: string; flag: string}> = {
  en: {
    language: 'EN',
    flag: En,
  },

  de: {
    language: 'DE',
    flag: De,
  },
  cz: {
    language: 'ČZ',
    flag: Cz,
  },
  sp: {
    language: 'ES',
    flag: Sp,
  },
  fr: {
    language: 'FR',
    flag: Fr,
  },
  it: {
    language: 'IT',
    flag: It,
  },
}

export const DEFAULT_LANGUAGE = 'en'
export const availableLanguages = Object.keys(LANGUAGE_DATA).map(lng => lng)
