import React, {ReactNode} from 'react'

import classNames from 'classnames'

import {EllipsisTextHint} from '@/components/ellipsis-text-hint/ellipsis-text-hint'

import './details-card.scss'

type TDetailItem = {
  label: string
  value: string
  fullWidth?: boolean
}

export type TDetailsCard = {
  header: string
  customContent?: ReactNode
  items?: Array<TDetailItem>
}

export const DetailsCard: React.FC<TDetailsCard> = props => {
  const {header, customContent, items} = props

  const body =
    items && items.length
      ? items.map(item => {
          const itemClass = classNames('details-card__detail-item', {
            'details-card__detail-item--wide': item.fullWidth,
          })
          return (
            <div key={item.label} className={itemClass}>
              <div className="details-card__label">{item.label}</div>
              <EllipsisTextHint>{item.value}</EllipsisTextHint>
            </div>
          )
        })
      : null
  return (
    <div className="details-card">
      <div className="details-card__header">{header}</div>
      {customContent}
      {body && <div className="details-card__body">{body}</div>}
    </div>
  )
}
