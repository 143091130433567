import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import RoleDrawer from '@/modules/ITSM/sites/ITSMSettings/Roles/RoleDrawer'

const RoleDetail = () => {
  const {getEntityData, onClose, record} = useListTableStateContext()

  return (
    <RoleDrawer
      uuid={record?.uuid}
      onClose={onClose}
      getEntityData={getEntityData}
    />
  )
}

export default RoleDetail
