import {RequestStatus} from '@/typedef'
import {ActionTypes} from '@/modules/ITSM/store/action-types'
import {ChannelInviteStep} from '@/modules/ITSM/components/accept-invite/typedf'

import {TChannelInviteTypes, TInitialState} from './typedef'

const initialSate: TInitialState = {
  refreshTokenStatus: RequestStatus.INITIAL,
  postIdentityStatus: RequestStatus.INITIAL,
  invitationLoadingStatus: RequestStatus.INITIAL,
  acceptInviteStatus: RequestStatus.REQUESTED,
  acceptInviteError: '',
  currentStep: ChannelInviteStep.CREATE_ACCOUNT,
  identityExists: false,
  user_defaults: undefined,
}

export default function invitationReducer(
  state = initialSate,
  action: TChannelInviteTypes
) {
  switch (action.type) {
    case ActionTypes.REFRESH_INVITE_ORG_TOKEN_REQUESTED: {
      return {
        ...state,
        refreshTokenStatus: RequestStatus.REQUESTED,
        acceptInviteStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.REFRESH_INVITE_ORG_TOKEN_SUCCESS: {
      return {
        ...state,
        refreshTokenStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.REFRESH_INVITE_ORG_TOKEN_FAILED: {
      return {
        ...state,
        refreshTokenStatus: RequestStatus.FAILED,
        acceptInviteStatus: RequestStatus.FAILED,
        acceptInviteError: action.payload,
      }
    }

    case ActionTypes.POST_IDENTITY_CHANNEL_INVITE_REQUESTED: {
      return {
        ...state,
        postIdentityStatus: RequestStatus.REQUESTED,
      }
    }
    case ActionTypes.POST_IDENTITY_CHANNEL_INVITE_SUCCESS: {
      return {
        ...state,
        postIdentityStatus: RequestStatus.SUCCEEDED,
        acceptInviteStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.POST_IDENTITY_CHANNEL_INVITE_FAILED: {
      return {
        ...state,
        postIdentityStatus: RequestStatus.FAILED,
        acceptInviteStatus: RequestStatus.FAILED,
        acceptInviteError: action.payload,
      }
    }

    case ActionTypes.SET_INVITE_LOADING_STATUS: {
      return {
        ...state,
        invitationLoadingStatus: action.payload,
      }
    }

    case ActionTypes.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload,
      }
    }

    case ActionTypes.SET_IDENTITY_EXISTS: {
      return {
        ...state,
        identityExists: true,
      }
    }

    case ActionTypes.SET_USER_DEFAULTS: {
      return {
        ...state,
        user_defaults: action.payload,
      }
    }

    case ActionTypes.SET_ACCEPT_INVITE_ERROR: {
      return {
        ...state,
        acceptInviteError: action.payload,
      }
    }

    default:
      return state
  }
}
