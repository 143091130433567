import {months as MONTHS} from '../../../utils/Constants'

const getValues = (value: Record<string, any>) => {
  if (value.customer_sums) {
    return value.customer_sums.currency_sums
  }

  const supplierSumsKeys = Object.keys(value.supplier_sums || {})

  if (value.supplier_sums && supplierSumsKeys.length === 1) {
    return value.supplier_sums[supplierSumsKeys[0]].currency_sums
  }

  return null
}

type TCurrentBillingReportData = {
  [key: string]: {
    amount: string
    expense: string
    number_of_billing_items: number
    org_display_name: string
    travel_time: number
    work_time: number
  }
}

type TGetCurrentBillingReportData = (
  data: Record<string, any>
) => {
  currencyRates: Record<string, number>
  intervalStart: string
  customerSums: TCurrentBillingReportData | null
  supplierSums: TCurrentBillingReportData | null
}

export const getCurrentBillingReportData: TGetCurrentBillingReportData = data => {
  const {
    customer_sums: customerSums,
    supplier_sums: supplierSums = {},
    currency_rates: currencyRates,
    interval_start: intervalStart,
  } = data
  const values: Record<string, any> = getValues(data) || {}
  const isSupplierSumsOneItem = Object.keys(supplierSums).length === 1

  return {
    currencyRates,
    intervalStart,
    customerSums: customerSums ? values : null,
    supplierSums:
      !customerSums && isSupplierSumsOneItem
        ? values
        : supplierSums.total?.currency_sums,
  }
}

export const getLocaleString = (
  value: string | number,
  options?: Record<string, unknown>
) =>
  Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  })

export const getDate = (intervalStart: string) => {
  const months: Record<string, string> = MONTHS as any

  return `${months[intervalStart.substring(5, 7)]} ${new Date(
    intervalStart
  ).getFullYear()}`
}
