import React, {useRef} from 'react'

import moment from 'moment'
import {Typography} from 'antd'

import {DATE_FORMAT} from '@/constants'
import {TIncidentProgress} from '@/modules/ITSM/components/incident-k-request/typedf'
import {ImportanceIndicator} from '@/components/importance-indicator/importance-indicator'
import {translate} from '@/services/i18n'

import {TLocation, TUser} from '../../typedef'
import ProgressBar from '../incident-k-request/incident-request-progress-bar/incident-request-progress-bar'

import './tile.scss'

const {Text} = Typography

type TProps<T> = {
  rowClick: (record: T, rowIndex: number | undefined, target?: any) => void
  incidentProgress?: TIncidentProgress
  entityData: T
}

export const Tile = <T extends Record<string, unknown>>({
  rowClick,
  entityData,
}: TProps<T>) => {
  const popupContainer = useRef(null)

  const {
    created_at,
    caller,
    short_description,
    priority,
    priority_text,
    number,
    state_id,
    assigned_to,
    location,
  } = entityData

  const onClick = () => {
    if (rowClick) rowClick(entityData, undefined)
  }

  const createdDate = moment(created_at as string).format(DATE_FORMAT)

  const {full_name} = (caller as TUser) || {}

  return (
    <div className="tile" onClick={onClick} ref={popupContainer}>
      <div className="tile__header">
        <div className="tile__name">{number as string}</div>
        <div className="tile__description">
          <Text ellipsis={{tooltip: true}}>{short_description as string}</Text>
        </div>
      </div>
      <div className="tile__details">
        <div className="tile__detail-item">
          <label>{translate('priority')}</label>

          <ImportanceIndicator
            importance={priority as number}
            indicatorText={`P${priority}`}
            importanceText={priority_text as string}
          />
        </div>
        <div className="tile__detail-item">
          <label>{translate('status')}</label>
          <div className="tile__progress-container">
            <ProgressBar<T> record={entityData} value={state_id as number} />
          </div>
        </div>
      </div>
      <div className="tile__details">
        <div className="tile__detail-item">
          <label>{translate('caller')}</label>
          <Text ellipsis={{tooltip: true}}>{full_name || '-'}</Text>
        </div>
        <div className="tile__detail-item">
          <label>{translate('assigned_to')}</label>
          <Text ellipsis={{tooltip: true}}>
            {(assigned_to as TUser)?.full_name || '-'}
          </Text>
        </div>
      </div>
      <div className="tile__footer">
        <div className="tile__footer-item">{createdDate}</div>
        <div className="tile__footer-item">{(location as TLocation)?.name}</div>
      </div>
    </div>
  )
}
