import {Coords} from 'google-map-react'

import {TCountry} from '@/typedef'

export enum AddressTypes {
  COUNTRY = 'country',
  LOCALITY = 'locality',
  SUB_LOCALITY = 'sublocality',
  ROUTE = 'route',
  STREET_NUMBER = 'street_number',
  POSTAL_CODE = 'postal_code',
  POSTAL_TOWN = 'postal_town',
  ADMIN_LEVEL_1 = 'administrative_area_level_1',
  ADMIN_LEVEL_2 = 'administrative_area_level_2',
}
type TAddressData = Array<{
  long_name: string
  short_name: string
  types: AddressTypes
}>

type TGetLongNames = (data?: TAddressData) => string[]

export const getLongNames: TGetLongNames = data =>
  data ? data.map(({long_name}) => long_name) : []

type TGetNames = (data?: TAddressData) => Array<TCountry>

export const getAllNames: TGetNames = data =>
  data
    ? data.map(({long_name, short_name}) => ({
        longName: long_name,
        shortName: short_name,
      }))
    : []

type TFilterByAddressType = (
  data: TAddressData,
  type: AddressTypes
) => TAddressData

export const filterByAddressType: TFilterByAddressType = (data, type) =>
  data.filter(({types}) => types.includes(type))

type TMergeStreetsAndStreetsNumbers = (
  streets: string[],
  streetNumbers: string[]
) => string[]

export const mergeStreetsAndStreetsNumbers: TMergeStreetsAndStreetsNumbers = (
  streets,
  streetNumbers
) =>
  streets.map(
    (street, index) =>
      `${street}${streetNumbers[index] ? `, ${streetNumbers[index]}` : ''}`
  )

type TParseGeocodingRequestData = (
  requestData: any
) => {
  cities: string[]
  countries: TCountry[]
  states: string[]
  streets: string[]
  zipCodes: string[]
  coordinates: Coords
}

export const parseGeocodingResponse: TParseGeocodingRequestData = requestData => {
  const data = requestData?.body?.results?.[0]

  if (data) {
    const {geometry = {}, address_components: addressComponents = []} = data
    const countries = getAllNames(
      filterByAddressType(addressComponents, AddressTypes.COUNTRY)
    )

    const states = getLongNames(
      filterByAddressType(addressComponents, AddressTypes.ADMIN_LEVEL_1)
    )

    const getCities = () => {
      const citiesByLocality = getLongNames(
        filterByAddressType(addressComponents, AddressTypes.LOCALITY)
      )
      if (citiesByLocality.length > 0) {
        return citiesByLocality
      }
      const citiesByPostalTown = getLongNames(
        filterByAddressType(addressComponents, AddressTypes.POSTAL_TOWN)
      )
      if (citiesByPostalTown.length > 0) {
        return citiesByPostalTown
      }
      const citiesBySubLocality = getLongNames(
        filterByAddressType(addressComponents, AddressTypes.SUB_LOCALITY)
      )
      if (citiesBySubLocality.length > 0) {
        return citiesBySubLocality
      }
      const citiesByAdminLevel1 = getLongNames(
        filterByAddressType(addressComponents, AddressTypes.ADMIN_LEVEL_1)
      )
      if (citiesByAdminLevel1.length > 0) {
        return citiesByAdminLevel1
      }
      return getLongNames(
        filterByAddressType(addressComponents, AddressTypes.ADMIN_LEVEL_2)
      )
    }
    const streets = getLongNames(
      filterByAddressType(addressComponents, AddressTypes.ROUTE)
    )
    const streetNumbers = getLongNames(
      filterByAddressType(addressComponents, AddressTypes.STREET_NUMBER)
    )
    const zipCodes = getLongNames(
      filterByAddressType(addressComponents, AddressTypes.POSTAL_CODE)
    )

    return {
      countries,
      states,
      cities: getCities(),
      streets: mergeStreetsAndStreetsNumbers(streets, streetNumbers),
      zipCodes,
      coordinates: geometry.location,
    }
  }

  return {
    countries: [],
    states: [],
    cities: [],
    streets: [],
    zipCodes: [],
    coordinates: {lat: 0, lng: 0},
  }
}
