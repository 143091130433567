import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import {CreateNewLocation} from '@/modules/ITSM/components/createNewLocation/createNewLocation'
import {TLocation} from '@/modules/ITSM/typedef'
import {patchLocation} from '@/modules/ITSM/api/locationRequests'

const LocationDrawer = () => {
  const {getEntityData, onClose, record} = useListTableStateContext<TLocation>()

  return (
    <CreateNewLocation
      record={record}
      asyncF={(data: TLocation) => patchLocation(data, record?.uuid)}
      onSave={getEntityData}
      onClose={onClose}
      formRules={{name: [{required: true}]}}
    />
  )
}

export default LocationDrawer
