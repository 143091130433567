import {call, put} from 'redux-saga/effects'

import {getCreditMode} from '@/modules/ITSM/api/creditRequests'
import {getMyAccess} from '@/modules/ITSM/api/accessRequests'
import {
  getCreditAccessFailed,
  getCreditAccessSuccess,
  getMyAccessFailed,
  getMyAccessSuccess,
} from '@/redux/settings/settingsActions'

export function* getCreditModeSaga() {
  try {
    const {
      body: {
        result: {
          value: {credit_mode},
        },
      },
    } = yield call(getCreditMode)

    yield put(getCreditAccessSuccess(credit_mode))
  } catch (err) {
    yield put(getCreditAccessFailed())
  }
}

export function* geyMyAccess() {
  try {
    const {
      body: {result},
    } = yield call(getMyAccess)
    yield put(getMyAccessSuccess(result))
  } catch (err) {
    yield put(getMyAccessFailed())
  }
}
