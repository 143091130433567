import React from 'react'

import {Layout as AntLayout} from 'antd'
import {BasicProps} from 'antd/lib/layout/layout'
import classNames from 'classnames'

import './layout.scss'

export const Layout: React.FC<BasicProps> = ({className, ...props}) => {
  const classes = classNames('layout', className)

  return <AntLayout className={classes} {...props} />
}
