import {useCallback, useEffect, useRef, useState} from 'react'

import {useHistory} from 'react-router-dom'

import globalRoutes from '@/global-routes'

const useNavPrompt = ({shouldBlock}) => {
  const history = useHistory()

  const _unblock = useRef(null)
  const _navPath = useRef(null)

  const [blocked, setBlocked] = useState(false)

  useEffect(() => {
    if (shouldBlock) {
      _unblock.current = history.block(location => {
        if (
          location.pathname === globalRoutes.general.login.path() ||
          location.pathname === globalRoutes.general.homePage.path()
        ) {
          setBlocked(false)
          return
        }
        setBlocked(true)
        _navPath.current = location.pathname
        return false
      })

      return () => {
        if (_unblock.current) {
          _unblock.current()
        }
      }
    }
  }, [shouldBlock, history])

  const navigate = useCallback(() => {
    if (_unblock.current) {
      _unblock.current()
      setBlocked(false)
      if (_navPath.current) {
        history.push(_navPath.current)
      }
    }
  }, [history])

  const hidePrompt = useCallback(() => {
    setBlocked(false)
  }, [])

  return {
    blocked,
    navigate,
    hidePrompt,
  }
}

export default useNavPrompt
