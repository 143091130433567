export const getOptionContent = (entityData: any, key: string) => {
  const newEntityData: {name: string; value: string}[] = []

  switch (key) {
    case 'org_name':
      for (const key in entityData.value) {
        newEntityData.push({
          name: entityData.value[key].name,
          value: key,
        })
      }

      return newEntityData
    case 'email':
      for (const key in entityData) {
        newEntityData.push({
          name: entityData[key].email,
          value: entityData[key].uuid,
        })
      }

      return newEntityData

    case 'full_name':
      for (const key in entityData) {
        newEntityData.push({
          name: entityData[key].full_name,
          value: entityData[key].uuid,
        })
      }

      return newEntityData

    default:
      return []
  }
}
