import React, {useCallback, useState} from 'react'

import {Col, Form as AntForm, Row} from 'antd'
import {FormInstance} from 'antd/lib/form/Form'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'

import {TKey, translate} from '@/services/i18n'
import {Button} from '@/components/button'
import {scrollToField} from '@/utils/scroll-to-field'
import {OverlaySpinner} from '@/components/overlay-spinner'

import './form.scss'

type TProps<T> = {
  form: FormInstance
  children: React.ReactNode
  header?: React.ReactNode
  onSubmit: (values: T) => void
  initialValues?: Partial<T>
  onCancel?: () => void
  isLoading?: boolean
  className?: string
  received?: boolean
  onValuesChangeCustom?: (values: T) => void
  submitButtonTitle?: TKey
  saveButtonDisabled?: boolean
}

export const Form = <T extends Record<string, any>>({
  children,
  header,
  onSubmit,
  onCancel,
  form,
  className,
  initialValues,
  isLoading = false,
  onValuesChangeCustom,
  submitButtonTitle = 'save',
  saveButtonDisabled = true,
}: TProps<T>) => {
  const [btnDisabled, setBtnDisabled] = useState(saveButtonDisabled)

  const checkValues = useCallback(() => {
    setBtnDisabled(isEqual(form.getFieldsValue(), initialValues))
  }, [form, initialValues])

  const cancelHandler = () => {
    if (onCancel) {
      setBtnDisabled(true)
      onCancel()
    }
  }

  const onValuesChange = (values: T) => {
    checkValues()
    onValuesChangeCustom && onValuesChangeCustom(values)
  }

  const modifierForm = {
    'form--loading': isLoading,
  }
  return (
    <div className={classnames('form', className, modifierForm)}>
      {header && header}

      {isLoading && (
        <div className="form__spinner">
          <OverlaySpinner idName={'form'} />
        </div>
      )}
      <AntForm
        form={form}
        layout="vertical"
        //eslint-disable-next-line no-template-curly-in-string
        validateMessages={{required: '${label} is required'}}
        onFinish={onSubmit}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinishFailed={errorFields => {
          scrollToField({errorFields, form})
        }}
      >
        {children}
        <Row>
          <Col span={24} className="mb-30 mt-15">
            {onCancel && (
              <Button
                onClick={cancelHandler}
                e2e-test={'cancel-button'}
                title={translate('cancel')}
              />
            )}
            <Button
              e2e-test={'submit-button'}
              data-testid={'save-button'}
              disabled={btnDisabled || isLoading}
              className={onCancel ? 'ml-10' : ''}
              type="primary"
              htmlType="submit"
              title={translate(submitButtonTitle)}
            />
          </Col>
        </Row>
      </AntForm>
    </div>
  )
}
