import {Interval} from '@/modules/typedef'
import {generateServiceProductName} from '@/sites/account-default/utils/generate-service-product-name'
import {SideTabs, TServicePayload} from '@/sites/services/typedef'

export const transformServicesPayload = (
  services: TServicePayload,
  interval: Interval,
  unit_amount: number
) => [
  {
    quantity: 1,
    price_data: {
      currency: 'eur',
      product_data: {
        name: generateServiceProductName(services[SideTabs.END_USER_SUPPORT]),
      },
      recurring: {
        interval,
      },
      unit_amount,
      options: {...services, interval},
    },
  },
]
