import React, {useState} from 'react'

import {Form, Input} from 'antd'
import {useSelector} from 'react-redux'

import {Button} from '@/components/button'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import Modal from '@/components/modal/modal'

import {translate} from '@/services/i18n'
import {handleAPIErrorBySentry} from '@/services/sentry'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {selectToastErrorMessages} from '@/redux/toast-message/toast-selector'
import {emailValidator} from '@/utils/form-validators/email-validator'

import {useCustomAuth} from '@/hooks/use-custom-auth'

import {ModalType} from '@/components/modal/typedef'

import './accept-invite-error-modal.scss'

type TProps = {
  handleReload: () => void
  visible?: boolean
  noBackground?: boolean
  setReloadCount: (count: number) => void
  reloadCount: number
  errorText?: string | unknown
}

type TIssueReq = {
  full_name: string
  email: string
  description: string
}

const OrgInviteErrorModal = ({
  handleReload,
  visible,
  noBackground,
  setReloadCount,
  reloadCount,
  errorText,
}: TProps) => {
  const [form] = Form.useForm()
  const errors = useSelector(selectToastErrorMessages)
  const {signOutRedirect} = useCustomAuth()

  const [isDisplaySuccessModal, setIsDisplaySuccessModal] = useState(false)

  const handleSubmitIssue = (values: TIssueReq) => {
    handleAPIErrorBySentry(errors as Record<string, any>, values)

    setReloadCount(0)
    setIsDisplaySuccessModal(true)
  }

  const IssueForm = () => (
    <Form
      layout="vertical"
      // eslint-disable-next-line
      validateMessages={{required: '${label} is required'}}
      form={form}
      className="issue-form"
      onFinish={handleSubmitIssue}
    >
      <FormItemGeneric<TIssueReq>
        name="full_name"
        rules={[
          {
            required: true,
          },
        ]}
        label={translate('full_name')}
      >
        <Input onFocus={setAutocompleteNone} />
      </FormItemGeneric>
      <FormItemGeneric<TIssueReq>
        name="email"
        rules={[{required: true}, {validator: emailValidator}]}
        label={translate('email')}
      >
        <Input onFocus={setAutocompleteNone} />
      </FormItemGeneric>
      <FormItemGeneric<TIssueReq>
        name="description"
        rules={[
          {
            required: true,
          },
        ]}
        label={translate('description')}
      >
        <Input.TextArea />
      </FormItemGeneric>
      <div className="ant-modal-footer">
        <Button
          title={translate('send')}
          key="submit"
          type="primary"
          htmlType="submit"
        />
        <Button
          title={translate('cancel')}
          key="cancel"
          onClick={signOutRedirect}
        />
      </div>
    </Form>
  )
  if (reloadCount === 2) {
    return (
      <Modal
        info={translate('tellAboutTheIssue')}
        open={visible !== undefined ? visible : true}
        noBackground={noBackground}
        className="issue-modal"
        modalType={ModalType.INFO}
      >
        <IssueForm />
      </Modal>
    )
  }

  if (isDisplaySuccessModal)
    return (
      <Modal
        className="org-invite-error-modal"
        info={translate('feedback_successfully_sent')}
        handleAccept={signOutRedirect}
        open={true}
        okText={translate('ok')}
        noBackground={false}
        modalType={ModalType.INFO}
      />
    )

  return (
    <Modal
      className="org-invite-error-modal"
      info={
        typeof errorText === 'string'
          ? errorText
          : translate('oops_something_went_wrong')
      }
      handleAccept={() => {
        setReloadCount((reloadCount || 0) + 1)
        handleReload()
      }}
      handleCancel={signOutRedirect}
      open={visible !== undefined ? visible : true}
      okText={translate('tryAgain')}
      cancelText={translate('cancel')}
      noBackground={noBackground}
      modalType={ModalType.INFO}
    />
  )
}

export default OrgInviteErrorModal
