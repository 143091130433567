import {useCallback, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {TSaasResMultiple} from '@/modules/SAAS/api/response-typedf'

type TPassedArgs<T> = {
  skipToken?: string
  result?: Array<T>
}

export function useGetRecursiveMultipleData<T extends Record<string, any>>(
  asyncF: (passedArgs: any) => Promise<TSaasResMultiple<T>>
) {
  const [entityData, setEntityData] = useState<T[]>([])

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getData = useCallback(
    async (
      payload: TPassedArgs<T> & Record<string, any>
    ): Promise<Array<T> | undefined> => {
      const {result: passedResult = []} = payload
      handleRequested()

      try {
        const {
          body: {result, skipToken},
        } = await asyncF(payload)

        if (!result) {
          setEntityData([])
          handleSuccess()
          return
        }

        const accumalateResult = [...passedResult, ...result]

        if (skipToken) {
          const prevResult = await getData({
            ...payload,
            skipToken,
            result: accumalateResult,
          })

          return [...(prevResult ? prevResult : []), ...result]
        } else {
          setEntityData(accumalateResult)
          handleSuccess()

          return result
        }
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [asyncF, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    getData,
    entityData,
    fetchStatus: status,
  }
}
