import React, {useEffect, useState} from 'react'

import {Menu, MenuProps, Space, Tooltip} from 'antd'

import {Dropdown} from '@/components/dropdown'
import i18n, {translate} from '@/services/i18n'

import {DEFAULT_LANGUAGE, LANGUAGE_DATA} from './constants'
import {getAvailableLanguage} from './get-available-language'

import './language-switcher.scss'

export const LanguageSwitcher: React.FC = () => {
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE)

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    const chosenLanguage = localStorage.getItem('i18nextLng')

    const localizeLanguage = window.navigator.language.split('-')[0]

    const languageToSet = getAvailableLanguage(chosenLanguage)
    setCurrentLanguage(languageToSet || localizeLanguage)

    i18n.on('languageChanged', lng => {
      setCurrentLanguage(lng)
    })
  }, [])

  const onClick: MenuProps['onClick'] = ({key}) => {
    changeLanguage(key)
  }

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <Menu
          onClick={onClick}
          items={Object.keys(LANGUAGE_DATA).map(key => ({
            key,
            label: LANGUAGE_DATA[key].language,
          }))}
        />
      ),
    },
  ]

  return (
    <Tooltip
      overlayClassName="language-switcher__tooltip"
      title={translate('translations_coming_soon')}
      placement="bottom"
    >
      <Dropdown menu={{items}} placement="bottomLeft" arrow disabled>
        <Space className="language-switcher__text">
          <img
            className="language-switcher__flag"
            src={LANGUAGE_DATA[currentLanguage].flag}
            alt={LANGUAGE_DATA[currentLanguage].language}
          />
          {LANGUAGE_DATA[currentLanguage].language}
        </Space>
      </Dropdown>
    </Tooltip>
  )
}
