import {useCallback} from 'react'

import {v4 as uuid} from 'uuid'

import {useFetchHook} from '@/hooks/useFetch'
import {postCheckIn} from '@/modules/ITSM/api/timelogsRequests'

export function useTimelogCheckIn() {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const handleCheckIn = useCallback(
    async (entity: string) => {
      try {
        handleRequested()
        const result = await postCheckIn(uuid(), {
          entity,
          remote: false,
          chargeable: true,
        })
        if (result) {
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    handleCheckIn,
    status,
  } as const
}
