import {translate} from '@/services/i18n'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import cmdbRoutes from '@/modules/CMDB/routes/cmdb-routes'
import saasRoutes from '@/modules/SAAS/routes/saas-routes'

export const MODULES = {
  ITSM: {
    name: 'ITSM',
    title: translate('service_desk'),
    description: translate('itsm_description'),
    icon: 'ITSM-ICON',
    path: itsmRoutes.serviceManagementDashboard.path(),
  },
  CMDB: {
    name: 'CMDB',
    title: translate('it_assets'),
    description: translate('cmdb_description'),
    icon: 'CMDB-ICON',
    path: cmdbRoutes.cmdbDashboard.path(),
  },
  SAAS: {
    name: 'SAAS',
    title: translate('SAAS'),
    description: translate('saas_description'),
    icon: 'SAAS-ICON',
    path: saasRoutes.dashboard.path(),
  },
}
