import {FormInstance} from 'antd'
import {InternalNamePath} from 'antd/lib/form/interface'

import {scrollSettings} from '../modules/ITSM/components/incident/constants'

type TProps = {
  errorFields: {
    errorFields: {name: InternalNamePath; errors: string[]}[]
  }
  form: FormInstance
}

export const scrollToField = ({errorFields, form}: TProps) => {
  form.scrollToField(errorFields.errorFields?.[0]?.name, scrollSettings)
}
