import React from 'react'

import './editor-wrapper.scss'

type TProps = {
  children: React.ReactNode
}

export const EditorWrapper: React.FC<TProps> = ({children}: TProps) => (
  <div className="wysiwyg-editor-wrapper">{children}</div>
)
