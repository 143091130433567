import {useCallback} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {postCheckOut} from '@/modules/ITSM/api/timelogsRequests'
import {TTimelogReq} from '@/modules/ITSM/typedef'

export function useTimelogCheckOut() {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const handleCheckOut = useCallback(
    async (data: TTimelogReq) => {
      try {
        handleRequested()
        const {
          body: {result},
        } = await postCheckOut(data)
        if (result) {
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    handleCheckOut,
    status,
  }
}
