import React from 'react'

import {Form, FormInstance} from 'antd'

import {FormSkeleton, Skeleton} from '@/components/skeleton'

import {DrawerFormSubmitBtns} from '../drawer-form-submit-btns/drawer-form-submit-btns'

import './drawer-form.scss'

type TProps<T> = {
  children: React.ReactElement
  loadingSubmit: boolean
  handleCancel: () => void
  form?: FormInstance
  onFinish: (values: T) => void
  loadingFetch?: boolean
  onValuesChange?: (values: T) => void
  initialValues?: T | Record<string, never>
  className?: string
  formRef?: React.RefObject<FormInstance<any>>
}

const DrawerForm = <T extends Record<string, unknown>>({
  children,
  loadingSubmit,
  handleCancel,
  loadingFetch,
  onValuesChange,
  className = '',
  onFinish,
  formRef,
  ...props
}: TProps<T>) => {
  return (
    <Form
      layout="vertical"
      // eslint-disable-next-line
      validateMessages={{required: '${label} is required'}}
      className={`drawer-form ${className}`}
      onValuesChange={onValuesChange}
      autoComplete="none"
      onFinish={onFinish}
      ref={formRef}
      {...props}
    >
      <Skeleton active={!!loadingFetch} view={<FormSkeleton />}>
        {children}
        <DrawerFormSubmitBtns
          loadingSubmit={loadingSubmit}
          handleCancel={handleCancel}
        />
      </Skeleton>
    </Form>
  )
}

export default DrawerForm
