import React, {useEffect, useState} from 'react'

import {Col, Form, Input, Row, Select} from 'antd'
import {useHistory, useParams} from 'react-router-dom'
import queryString from 'query-string'
import {useDispatch} from 'react-redux'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {translate} from '@/services/i18n'
import {
  PRIORITY_OPTIONS,
  scrollSettings,
} from '@/modules/ITSM/components/incident/constants'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {Entities} from '@/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import {Button} from '@/components/button'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import IncidentAssignment from '@/modules/ITSM/components/incident/incident-assignment/incident-assignment'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {LocationSelect} from '../../components/locationSelect'
import CallerSelect from '../../components/form-components/caller-select'
import DescriptionEditor from '../../components/description-editor/description-editor'
import {TIncidentTaskReq} from '../../components/incident-task/typedf'
import {randomString} from '../../utils/Helpers'
import {useGetIncidentRequest} from '../../components/incident-k-request/hooks/useGetIncidentRequest'
import {usePostIncidentTask} from '../../components/incident-task/hooks/usePostIncidentTask'
import {resetItsmTableBookmarks} from '../../store/list-table/table-actions'
import {ItsmAssets} from '../../typedef'

import './incident-task-new.scss'

const Option = Select.Option
const TextArea = Input.TextArea

const IncidentTaskNew = () => {
  const {taskId} = useParams<{taskId: string}>()

  const incidentID = (queryString.parse(window.location.search).incident ||
    '') as string

  const [form] = Form.useForm()

  const [, setRefresh] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()

  const {
    saveEntity: saveIncidentTask,
    saveEntityStatus: saveIncidentTaskStatus,
  } = usePostIncidentTask()

  const {getData: getIncident} = useGetIncidentRequest(
    Entities.INCIDENT,
    incidentID
  )

  const goBack = () => {
    // @ts-ignore typescript error
    history.goBack()
  }

  const {
    editorState,
    onEditorStateChange,
    setText,
    editorText,
  } = useSetEditorState()

  useEffect(() => {
    form.setFieldsValue({
      number: 'ICT' + randomString(7),
    })
  }, [form])

  useEffect(() => {
    const fetchIncident = async () => {
      const result = await getIncident()

      if (result)
        form.setFieldsValue({
          incident: result.number,
        })
    }

    fetchIncident()
  }, [form, getIncident])

  const handleSubmit = async (values: TIncidentTaskReq) => {
    values['description'] = editorText
    values['incident'] = incidentID

    const result = await saveIncidentTask(values, taskId)
    if (result !== undefined) {
      dispatch(resetItsmTableBookmarks(ItsmAssets.IncidentTasks))
      history.push(
        itsmRoutes.serviceManagementIncidentsTaskDetail.path(result?.uuid)
      )
    }
  }

  return (
    <Paper variant={PaperVariant.form}>
      <Row>
        <Col>
          <div className="flex mb-15">
            <GoBackButton onClick={goBack} />
          </div>
        </Col>
      </Row>
      <Form
        // eslint-disable-next-line
        validateMessages={{required: '${label} is required'}}
        form={form}
        layout="vertical"
        className="incident-task-new"
        onFinish={handleSubmit}
        scrollToFirstError={scrollSettings}
      >
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentTaskReq>
              name="number"
              rules={[
                {
                  required: true,
                },
              ]}
              label={translate('number')}
            >
              <Input readOnly />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentTaskReq>
              initialValue={4}
              name="priority"
              rules={[
                {
                  required: true,
                },
              ]}
              label={translate('priority')}
            >
              <Select getPopupContainer={e => e.parentNode}>
                {Object.keys(PRIORITY_OPTIONS).map(key => (
                  <Option value={Number(key)} key={key}>
                    {
                      PRIORITY_OPTIONS[
                        (key as unknown) as keyof typeof PRIORITY_OPTIONS
                      ]
                    }
                  </Option>
                ))}
              </Select>
            </FormItemGeneric>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="incident-task-new__details" {...twoColumns}>
            <LocationSelect
              locationId={form.getFieldValue('location')}
              setRefresh={() => setRefresh(Math.random())}
            />
          </Col>
          <Col {...twoColumns}>
            <CallerSelect
              callerValue={form.getFieldValue('caller')}
              setRefresh={() => setRefresh(Math.random())}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentTaskReq>
              name="item"
              label={translate('item')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentTaskReq>
              name="incident"
              label={translate('incident')}
            >
              <Input readOnly />
            </FormItemGeneric>
          </Col>
        </Row>
        <IncidentAssignment
          form={form}
          setRefresh={() => setRefresh(Math.random())}
        />
        <Row>
          <Col span={24}>
            <FormItemGeneric<TIncidentTaskReq>
              name="short_description"
              label={translate('title')}
              initialValue={null}
              rules={[
                {
                  required: true,
                  message: translate('short_description_req'),
                },
              ]}
            >
              <TextArea rows={5} />
            </FormItemGeneric>
          </Col>
        </Row>
        <DescriptionEditor
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          setText={setText}
          withFileAttaching={false}
        />

        <Row className="mb-15">
          <Col span={24}>
            <p>
              <Button
                title={translate('save')}
                type="primary"
                htmlType="submit"
                disabled={checkIsLoading(saveIncidentTaskStatus)}
                size="large"
              />
              <Button
                title={translate('cancel')}
                className="ml-10"
                onClick={goBack}
                size="large"
              />
            </p>
          </Col>
        </Row>
      </Form>
    </Paper>
  )
}

export default IncidentTaskNew
