import React, {useContext, useState} from 'react'

import LogoWrapper from '@/components/logo-wrapper/logo-wrapper'
import Modal from '@/components/modal/modal'
import {translate} from '@/services/i18n'
import {ModalType} from '@/components/modal/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import {getBEndToken, postAcceptInvite} from '@/api/token-request'
import {fetchOrganizations} from '@/api/org-requests'
import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {
  InviteOrgContext,
  setInviteOrgStep,
  setInviteOrgToken,
} from '@/sites/accept-org-invite/context/org-invite-context'
import {InvitationStep} from '@/sites/accept-org-invite/typedef'
import OrgInviteErrorModal from '@/sites/accept-org-invite/error-modal/org-invite-error-modal'
import {parsePostAcceptInviteError} from '@/sites/accept-org-invite/utils/parse-post-accept-invite-error'

import {useApiData} from '@/hooks/use-api-data'
import {useCustomAuth} from '@/hooks/use-custom-auth'
import queryString from 'query-string'
import {TInviteQuery} from '@/typedef'
import {useLocation} from 'react-router-dom'

export const AcceptOrgInviteModal = () => {
  const {search} = useLocation()
  const {dispatch} = useContext(InviteOrgContext)
  const {signOutRedirect} = useCustomAuth()

  const {grant} = queryString.parse(search) as TInviteQuery

  const [reloadCount, setReloadCount] = useState(0)
  const [isDisplayTryMoreErrorModal, setIsDisplayTryMoreErrorModal] = useState(
    false
  )

  const {run: getBackEndToken, status: getBackEndTokenStatus} = useApiData(
    getBEndToken
  )

  const {
    run: postAcceptInvitation,
    status: postAcceptInvitationStatus,
    error: postAcceptInviteError,
  } = useApiData(postAcceptInvite)

  const {
    run: fetchOrganizationList,
    status: fetchOrganizationsStatus,
  } = useApiData(fetchOrganizations)

  const handleAcceptInvite = async () => {
    if (!grant) return signOutRedirect()

    const tokenResponse = await getBackEndToken({})
    const noAccNoOrgAccessToken = tokenResponse?.body?.access_token

    if (!noAccNoOrgAccessToken) return setIsDisplayTryMoreErrorModal(true)

    dispatch(setInviteOrgToken(noAccNoOrgAccessToken))

    const acceptInvitationResponse = await postAcceptInvitation({
      grantToken: grant,
      headers: createAuthorizationHeader(noAccNoOrgAccessToken),
    })

    if (!acceptInvitationResponse) return

    const orgsResponse = await fetchOrganizationList(
      createAuthorizationHeader(noAccNoOrgAccessToken)
    )

    const organizations = orgsResponse?.body

    /* TODO: add check for account 'accounts.length === 0' */
    if (organizations) {
      return dispatch(setInviteOrgStep(InvitationStep.CREATE_ACC_AND_ORG))
    }

    return setIsDisplayTryMoreErrorModal(true)
  }

  return (
    <LogoWrapper>
      <OrgInviteErrorModal
        handleReload={() => setIsDisplayTryMoreErrorModal(false)}
        visible={isDisplayTryMoreErrorModal}
        setReloadCount={setReloadCount}
        reloadCount={reloadCount}
      />
      <Modal
        info={parsePostAcceptInviteError(postAcceptInviteError)}
        handleCancel={signOutRedirect}
        open={!!postAcceptInviteError}
        cancelText={translate('cancel')}
        modalType={ModalType.INFO}
      />
      <Modal
        open={!(postAcceptInviteError || isDisplayTryMoreErrorModal)}
        noBackground
        okText={translate('invite_accept')}
        cancelText={translate('invite_decline')}
        info={translate('accept_invitation_question')}
        handleAccept={handleAcceptInvite}
        handleCancel={signOutRedirect}
        modalType={ModalType.INFO}
        loading={checkIsLoading([
          getBackEndTokenStatus,
          postAcceptInvitationStatus,
          fetchOrganizationsStatus,
        ])}
      />
    </LogoWrapper>
  )
}
