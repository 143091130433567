import {AssigneeStates, IncTabs} from '../constants'

export const setStepNumber = (tab: string) => {
  switch (true) {
    case tab === IncTabs.OverviewTab ||
      tab === IncTabs.IncidentStatusLogTab ||
      tab === IncTabs.CommentsTab ||
      tab === IncTabs.AttachmentsTab ||
      tab === IncTabs.WorkNotesTab:
      return '0'
    case tab === AssigneeStates.Proposed ||
      tab === AssigneeStates.Accepted ||
      tab === AssigneeStates.Rejected:
      return '1'
    case tab === IncTabs.InProgressTab ||
      tab === IncTabs.OnHoldTab ||
      tab === IncTabs.TimeLogsTab ||
      tab === IncTabs.MapTab ||
      tab === IncTabs.SvrTab ||
      tab === IncTabs.IncidentTasksTab ||
      tab === IncTabs.ExpensesTab ||
      tab === IncTabs.WatchListTab:
      return '2'
    case tab === IncTabs.ResolutionTab:
      return '3'
    case tab === IncTabs.ResolvedTab:
      return '4'
    default:
      return ''
  }
}
