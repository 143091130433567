import {RequestStatus} from '@/typedef'

import * as ActionTypes from '../actionTypes'

import {USER_DEFAULT} from './constants'

const initialState = {
  requestStatus: RequestStatus.INITIAL,
  reAuthStatus: RequestStatus.INITIAL,
  reqIdentityMeStatus: RequestStatus.INITIAL,
  authFailed: false,
  refreshOrgTokenStatus: RequestStatus.INITIAL,
  backEndTokenFetchStatus: RequestStatus.INITIAL,
  refreshOrgTokenError: '',
  error: '',
  identityMeError: '',
  loginToken: '',
  email: '',
  accessToken: '',
  backEndToken: '',
  tokenExp: '',
  userNameInChannel: '',
  company: '',
  user_defaults: USER_DEFAULT,
  legalEntityDetails: null,
  userID: '',
  userAccess: [],
  module: '',
  orgRole: '',
  emptySpace: null,
  docs_read: null,
  app_version: '',
  type: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_USER_STATE: {
      return {
        ...state,
        ...initialState,
      }
    }

    case ActionTypes.RESET_USER_NAME_ID: {
      return {
        ...state,
        userID: '',
        userNameInChannel: '',
        email: '',
      }
    }

    case ActionTypes.SET_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
      }
    }

    case ActionTypes.SET_BACK_END_TOKEN_REQUESTED: {
      return {
        ...state,
        backEndTokenFetchStatus: RequestStatus.REQUESTED,
      }
    }
    case ActionTypes.SET_BACK_END_TOKEN_SUCCESS: {
      return {
        ...state,
        backEndToken: action.payload.token,
        tokenExp: action.payload.tokenExp,
        backEndTokenFetchStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.SET_BACK_END_TOKEN_FAILED: {
      return {
        ...state,
        backEndTokenFetchStatus: RequestStatus.FAILED,
      }
    }

    case ActionTypes.FETCH_PROFILE_REQUESTED: {
      return {
        ...state,
        requestStatus: RequestStatus.REQUESTED,
        reAuthStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.FETCH_PROFILE_SUCCESS_INIT: {
      return {
        ...state,
        requestStatus: RequestStatus.SUCCEEDED,
        emptySpace: null,
      }
    }

    case ActionTypes.FETCH_PROFILE_FAILED: {
      return {
        ...state,
        requestStatus: RequestStatus.FAILED,
        error: action.payload,
        reAuthStatus: RequestStatus.FAILED,
      }
    }

    case ActionTypes.RESET_IDENTITY_ME_STATUS: {
      return {
        ...state,
        reqIdentityMeStatus: RequestStatus.INITIAL,
      }
    }

    case ActionTypes.FETCH_ORG_TOKEN_REQUESTED: {
      return {...state, requestStatus: RequestStatus.REQUESTED}
    }

    case ActionTypes.FETCH_ORG_TOKEN_FAILED: {
      return {
        ...state,
        requestStatus: RequestStatus.FAILED,
        error: action.payload,
        reAuthStatus: RequestStatus.FAILED,
      }
    }

    case ActionTypes.FETCH_IDENTITY_ME_REQUESTED: {
      return {
        ...state,
        requestStatus: RequestStatus.REQUESTED,
        reqIdentityMeStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.FETCH_IDENTITY_ME_SUCCESS: {
      return {
        ...state,
        userID: action.payload,
      }
    }

    case ActionTypes.FETCH_IDENTITY_ME_FAILED: {
      return {
        ...state,
        reqIdentityMeStatus: RequestStatus.FAILED,
        identityMeError: action.payload,
        reAuthStatus: RequestStatus.FAILED,
        userID: '',
        userNameInChannel: '',
        email: '',
      }
    }

    case ActionTypes.FETCH_SAAS_IDENTITY_ME_REQUESTED: {
      return {
        ...state,
        requestStatus: RequestStatus.REQUESTED,
        reAuthStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.SET_USER_INVITE_DEFAULTS: {
      return {
        ...state,
        user_defaults: action.payload,
      }
    }

    case ActionTypes.FETCH_USER_REQUESTED: {
      return {...state, requestStatus: RequestStatus.REQUESTED}
    }

    case ActionTypes.FETCH_USER_SUCCESS: {
      return {
        ...state,
        reqIdentityMeStatus: RequestStatus.SUCCEEDED,
        reAuthStatus: RequestStatus.SUCCEEDED,
        userNameInChannel: action.payload.userNameInChannel,
        email: action.payload.email,
        company: action.payload.company,
        orgRole: action.payload.orgRole,
        company_types: action.payload.company_types,
        docs_read: action.payload.docs_read,
        app_version: action.payload.app_version,
        type: action.payload.type,
      }
    }

    case ActionTypes.FETCH_USER_FAILED: {
      return {
        ...state,
        reqIdentityMeStatus: RequestStatus.FAILED,
        error: action.payload,
        reAuthStatus: RequestStatus.FAILED,
      }
    }

    case ActionTypes.REFRESH_GET_USER_STATUS: {
      return {
        ...state,
        reAuthStatus: RequestStatus.INITIAL,
        requestStatus: RequestStatus.INITIAL,
      }
    }

    case ActionTypes.SET_ENTRIES_ORG: {
      return {
        ...state,
        orgName: action.payload.orgName,
        orgID: action.payload.orgID,
      }
    }

    case ActionTypes.SET_COMPANY_TYPES: {
      return {
        ...state,
        company_types: action.payload,
      }
    }

    case ActionTypes.SET_MODULE_NAME: {
      return {
        ...state,
        module: action.payload,
      }
    }
    case ActionTypes.REFRESH_ORG_TOKEN_REQUESTED: {
      return {
        ...state,
        refreshOrgTokenStatus: RequestStatus.REQUESTED,
      }
    }

    case ActionTypes.REFRESH_ORG_TOKEN_SUCCESS: {
      return {
        ...state,
        refreshOrgTokenStatus: RequestStatus.SUCCEEDED,
      }
    }

    case ActionTypes.REFRESH_ORG_TOKEN_FAILED: {
      return {
        ...state,
        refreshOrgTokenError: action.payload,
        refreshOrgTokenStatus: RequestStatus.FAILED,
      }
    }

    case ActionTypes.REFRESH_ORG_TOKEN_RESET: {
      return {
        ...state,
        refreshOrgTokenStatus: RequestStatus.INITIAL,
      }
    }

    case ActionTypes.SET_EMPTY_SPACE: {
      return {
        ...state,
        emptySpace: action.payload,
      }
    }

    case ActionTypes.RESET_MODULE_MOUNT_STATUS: {
      return {
        ...state,
        reAuthStatus: RequestStatus.INITIAL,
      }
    }

    case ActionTypes.SET_AUTH_FAILED: {
      return {
        ...state,
        authFailed: action.payload,
      }
    }

    default:
      return state
  }
}
