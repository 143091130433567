import React, {useCallback, useEffect, useState} from 'react'

import classnames from 'classnames'
import {Col, Divider, Row} from 'antd'
import {CheckOutlined} from '@ant-design/icons'

import {OverlaySpinner} from '@/components/overlay-spinner'
import {PurchaseAddon} from '@/sites/purchase/components/purchase-addon/purchase-addon'
import {PurchaseCartDrawer} from '@/sites/purchase/components/purchase-cart-drawer/purchase-cart-drawer'
import {PurchasePrice} from '@/sites/purchase/components/purchase-price/purchase-price'
import {RadioGroup} from '@/components/radio-group/radio-group'
import SvgIcon from '@/components/svg-icon/svg-icon'

import './purchase.scss'

import {checkIsLoading} from '@/utils/check-is-loading'
import {countQuantityPurchases} from '@/sites/purchase/utils/count-quantity-purchases'
import {getModulesAddons} from '@/sites/purchase/utils/get-modules-addons'
import {formatPrice} from '@/modules/Subscriptions/utils/format-price'
import {translate} from '@/services/i18n'

import {useGetStripeListDataGeneric} from '@/modules/SAAS/hooks/use-get-stripe-list-data/use-get-stripe-list-data-generic'

import {
  ModuleFeatureFlags,
  TExtraAddons,
  TPurchasesAddons,
  TPurchasesModule,
} from '@/sites/purchase/typedef'

import {detailsModules} from '@/sites/purchase/constants/details-purchase-modules'
import {cyclePriceOptions} from '@/sites/purchase/constants/cycle-prices-options'
import {
  filterPricesForAddons,
  transformExtraAddons,
} from '@/sites/purchase/utils/filter-prices-for-addons'
import {Button} from '@/components/button'
import {Interval} from '@/modules/typedef'
import {useHistory} from 'react-router-dom'
import globalRoutes from '@/global-routes'
import {generatePurchaseProducts} from '@/sites/purchase/utils/generate-purchase-products'
import {useQuery} from '@/utils/useQuery'
import {useCustomAuth} from '@/hooks/use-custom-auth'
import {parseSubscriptionsMessage} from './constants/parse-subscriptions-message'
import {MobilePurchase} from './components/purchase-mobile.tsx/mobile-purchase'
import {generateModules} from './utils/generate-modules'
import {PurchaseModule} from './components/purchase-module/purchase-module'

import {Flow} from '@/sites/account-default/typedef'

const requiredModule = ModuleFeatureFlags.ITSM

export const Purchase = () => {
  const history = useHistory()
  const {signUpRedirect} = useCustomAuth()
  const module = useQuery('module') as ModuleFeatureFlags

  const [isDisplayCart, setIsDisplayCart] = useState(false)
  const [isDisplayNext, setIsDisplayNext] = useState(false)
  const [intervalType, setIntervalType] = useState<Interval>(Interval.YEAR)
  const [selectedModules, setSelectedModules] = useState<TPurchasesModule>([
    requiredModule,
  ])

  const [selectedExtraAddons, setSelectedExtraAddons] = useState<TExtraAddons>(
    []
  )

  const [selectedAddons, setSelectedAddons] = useState<TPurchasesAddons>({
    [ModuleFeatureFlags.ITSM]: [],
    [ModuleFeatureFlags.CMDB]: [],
    [ModuleFeatureFlags.SAAS]: [],
  })

  const {
    data: prices,
    run: getProducts,
    status: fetchStatus,
  } = useGetStripeListDataGeneric()

  const {generalPrices, supportServices} =
    prices &&
    generatePurchaseProducts({
      prices,
    })

  const modules = generalPrices[intervalType].filter(
    price => price.feature_flag && selectedModules.includes(price.feature_flag)
  )

  const addons = filterPricesForAddons(
    generalPrices[intervalType],
    selectedAddons
  )

  const extraAddons = transformExtraAddons(
    selectedExtraAddons,
    supportServices[intervalType]
  )

  const handleSelectedPurchaseModule = useCallback(
    (module: ModuleFeatureFlags) => {
      if (selectedModules.some(selectedModule => selectedModule === module)) {
        setSelectedModules(
          selectedModules.filter(selectedModule => selectedModule !== module)
        )
        return setSelectedAddons(prev => ({
          ...prev,
          ...(module ? {[module]: []} : {}),
        }))
      }

      setSelectedModules([...selectedModules, module])
    },
    [selectedModules]
  )

  const handleSelectAddon = (module: ModuleFeatureFlags, addon: string) => {
    if (selectedAddons[module].includes(addon)) {
      return setSelectedAddons(prevState => ({
        ...prevState,
        [module]: prevState[module].filter(
          selectedAddon => selectedAddon !== addon
        ),
      }))
    }

    return setSelectedAddons(prevState => ({
      ...prevState,
      [module]: [...prevState[module], addon],
    }))
  }

  const handleSelectExtraAddon = (extraAddon: string) => {
    if (selectedExtraAddons.includes(extraAddon)) {
      return setSelectedExtraAddons(prevState =>
        prevState.filter(
          selectedExtraAddon => selectedExtraAddon !== extraAddon
        )
      )
    }

    return setSelectedExtraAddons([...selectedExtraAddons, extraAddon])
  }

  const handleSubmitCart = () => {
    const purchaseIds = [
      ...modules,
      ...extraAddons,
      ...addons[ModuleFeatureFlags.ITSM],
      ...addons[ModuleFeatureFlags.CMDB],
      ...addons[ModuleFeatureFlags.SAAS],
    ].map(({id, usage_type}) => ({
      id,
      usage_type,
    }))

    signUpRedirect({
      flow: Flow.PRODUCTS,
      interval: intervalType,
      payload: JSON.stringify(purchaseIds),
    })
  }

  const handleBuyNow = () => {
    setIsDisplayNext(true)
  }

  const modulesAddonList = getModulesAddons(generalPrices[intervalType])
  const supportServicesList = getModulesAddons(supportServices[intervalType])

  const modulesList = generateModules(
    generalPrices,
    intervalType,
    selectedModules
  )

  useEffect(() => {
    if (module) {
      setSelectedModules([requiredModule, module])
    }
  }, [module])

  useEffect(() => {
    getProducts()
  }, [getProducts])

  if (checkIsLoading(fetchStatus)) {
    return <OverlaySpinner idName={'purchase'} />
  }

  return (
    <>
      <PurchaseCartDrawer
        interval={intervalType}
        isDisplayNext={isDisplayNext}
        visible={isDisplayCart || isDisplayNext}
        onClose={() => {
          setIsDisplayCart(false)
          setIsDisplayNext(false)
        }}
        modules={modules}
        addons={addons}
        extraAddons={extraAddons}
        handleSubmit={handleSubmitCart}
      />
      <MobilePurchase
        selectedModules={selectedModules}
        selectedAddons={selectedAddons}
        selectedExtraAddons={selectedExtraAddons}
        setIsDisplayCart={setIsDisplayCart}
        intervalType={intervalType}
        handleSelectedPurchaseModule={handleSelectedPurchaseModule}
        formatPrice={formatPrice}
        modulesAddonList={modulesAddonList}
        handleSelectAddon={handleSelectAddon}
        supportServicesList={supportServicesList}
        handleSelectExtraAddon={handleSelectExtraAddon}
        handleBuyNow={handleBuyNow}
        setIntervalType={setIntervalType}
        modulesList={modulesList}
      />
      <div className="purchase" data-testid="purchase">
        <div className={'purchase__header'}>
          <div className="purchase__inner-header">
            <div className="flex flex--spaceBetween">
              <div className="purchase__logo-wrapper">
                <SvgIcon
                  name="blits-logo"
                  className="purchase__logo"
                  width={100}
                  onClick={() =>
                    history.push(globalRoutes.general.homePage.path())
                  }
                />
              </div>
              <h4 className="purchase-title">
                {translate('finalise_your_purchase')}
              </h4>
              <div
                data-testid="cart"
                className="purchase__cart"
                onClick={() => setIsDisplayCart(true)}
              >
                <SvgIcon
                  name="cart"
                  className="purchase__cart-icon"
                  width={30}
                />

                <div className="purchase__counter-wrapper">
                  <span className="purchase__counter">
                    {`${countQuantityPurchases(
                      selectedModules,
                      selectedAddons,
                      selectedExtraAddons
                    )}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center mt-16 mb-8 fs-16">
              <h4 className="purchase-title__mobile">
                {translate('finalise_your_purchase')}
              </h4>
            </div>
            <div className="flex flex--justifyCenter mb-16 mt-16">
              <RadioGroup
                options={cyclePriceOptions}
                onChange={({target: {value}}) => setIntervalType(value)}
                value={intervalType}
              />
            </div>
            <Row gutter={[12, 24]}>
              {modulesList.map(({isSelectedModule, moduleProduct}) => {
                return (
                  <Col key={moduleProduct?.category} xs={24} md={8} lg={8}>
                    {moduleProduct && (
                      <PurchaseModule
                        {...(requiredModule !== moduleProduct?.category &&
                        moduleProduct
                          ? {
                              onClick: () =>
                                handleSelectedPurchaseModule(
                                  moduleProduct?.category
                                ),
                            }
                          : {})}
                        isSelected={isSelectedModule}
                        title={moduleProduct?.name || moduleProduct?.category}
                        price={formatPrice(
                          moduleProduct?.unit_amount,
                          moduleProduct?.currency,
                          moduleProduct?.interval
                        )}
                        icon={moduleProduct?.icon}
                        pricingUnitMetadata={moduleProduct?.per_custom_unit}
                        module={moduleProduct?.category}
                      />
                    )}
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>

        <Divider className={classnames('purchase__divider  ')}>
          {translate('basic')}
        </Divider>
        <Row gutter={[12, 24]}>
          {Object.values(ModuleFeatureFlags).map(module => (
            <Col key={module} className="mb-16" xs={24} md={12} lg={8}>
              <div className="purchase__basic-list">
                {detailsModules[module].basicAddons.map(({id, title}) => (
                  <div className="flex purchase__basic-list-title" key={id}>
                    <div>
                      <CheckOutlined className="purchase-addon__checkbox mr-8 pt-4" />
                    </div>
                    {title}
                  </div>
                ))}
              </div>
            </Col>
          ))}
        </Row>

        <Divider className={classnames('purchase__divider')}>
          {translate('addons')}
        </Divider>
        <Row gutter={[12, 24]}>
          {Object.values(ModuleFeatureFlags).map(module => (
            <Col key={module} className="mb-16" xs={24} md={12} lg={8}>
              <div className="purchase__basic-list">
                {modulesAddonList[module].map(price => (
                  <div className="mb-16" key={price.id}>
                    <PurchaseAddon
                      title={price.name}
                      description={price.description || ''}
                      price={
                        <PurchasePrice
                          price={formatPrice(
                            price.unit_amount,
                            price.currency,
                            price.interval
                          )}
                          contactSalesPrice={price.contact_sales_price}
                          pricingUnitLabel={
                            price.per_custom_unit ||
                            parseSubscriptionsMessage[module]
                          }
                        />
                      }
                      checked={selectedAddons[module].some(
                        selectedAddon => selectedAddon === price.productId
                      )}
                      disabled={
                        !selectedModules.some(
                          featureFlag => featureFlag === module
                        )
                      }
                      onClickCheckbox={e =>
                        handleSelectAddon(module, price.productId)
                      }
                    />
                  </div>
                ))}
              </div>
            </Col>
          ))}
        </Row>

        <Col xs={24}>
          <Divider className={classnames('purchase__divider')}>
            {translate('support_services')}
          </Divider>
        </Col>

        <Row>
          {Object.values(ModuleFeatureFlags).map(module => (
            <Col key={module} xs={24} md={8} lg={8}>
              {supportServicesList[module].map(
                ({
                  name,
                  description,
                  interval,
                  currency,
                  unit_amount,
                  contact_sales_price,
                  per_custom_unit,
                  productId,
                }) => (
                  <div className="mb-16" key={name}>
                    <PurchaseAddon
                      title={name}
                      description={description}
                      price={
                        <PurchasePrice
                          price={formatPrice(unit_amount, currency, interval)}
                          contactSalesPrice={contact_sales_price}
                          pricingUnitLabel={
                            per_custom_unit || parseSubscriptionsMessage[module]
                          }
                        />
                      }
                      checked={selectedExtraAddons.includes(productId)}
                      onClickCheckbox={() => handleSelectExtraAddon(productId)}
                    />
                  </div>
                )
              )}
            </Col>
          ))}
        </Row>
        <Button
          className="purchase__buy-now"
          type="primary"
          data-testid="purchase_button"
          title={translate('buy_now')}
          onClick={handleBuyNow}
        />
      </div>
    </>
  )
}
