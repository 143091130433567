import {FilterTypes} from '@/modules/SAAS/SAAS-constants'
import {SaasAssets} from '@/modules/SAAS/typedf'

import {TDataTablePreset} from '../typedf'

export const saasColumnsDefaults: {[key in SaasAssets]: TDataTablePreset} = {
  [SaasAssets.Users]: [
    {
      title: 'first_name',
      dataIndex: 'first_name',
      index: 0,
      selected: true,
      filterType: FilterTypes.Select,
      width: '20%',
    },
    {
      title: 'last_name',
      dataIndex: 'last_name',
      index: 1,
      selected: true,
      filterType: FilterTypes.Select,
      width: '20%',
    },
    {
      title: 'email',
      dataIndex: 'email',
      index: 2,
      selected: true,
      filterType: FilterTypes.Select,
      width: '35%',
    },
    {
      title: 'lastLogin',
      dataIndex: 'lastLogin',
      index: 3,
      selected: true,
      filterType: FilterTypes.NA,
      width: '25%',
    },
  ],
  [SaasAssets.AppUsers]: [
    {
      title: 'first_name',
      dataIndex: 'first_name',
      index: 0,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'last_name',
      dataIndex: 'last_name',
      index: 1,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'type',
      dataIndex: 'type',
      index: 3,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'email',
      dataIndex: 'email',
      index: 4,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'unassign',
      dataIndex: ['unassign', 'userId'],
      index: 5,
      selected: true,
      width: '155px',
      filterType: FilterTypes.NA,
    },
  ],
  [SaasAssets.AppGroups]: [
    {
      title: 'display_dame',
      dataIndex: 'displayName',
      index: 0,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'azure_id',
      dataIndex: 'azureId',
      index: 1,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'unassign',
      dataIndex: ['unassign', 'groupId'],
      index: 5,
      selected: true,
      width: '155px',
      filterType: FilterTypes.NA,
    },
    // {
    //   title: 'users',
    //   dataIndex: 'users',
    //   index: 2,
    //   selected: true,
    //   filterType: FilterTypes.NA,
    // },
  ],
  [SaasAssets.Apps]: [
    {
      title: 'display_name',
      dataIndex: 'display_name',
      index: 0,
      selected: true,
      filterType: FilterTypes.Select,
      width: '25%',
    },
    {
      title: 'vendor_name',
      dataIndex: 'vendor_name',
      index: 1,
      selected: true,
      filterType: FilterTypes.Select,
      width: '25%',
    },
    {
      title: 'number_of_licences',
      dataIndex: 'number_of_licences',
      index: 2,
      selected: true,
      filterType: FilterTypes.NA,
      width: '25%',
    },
    {
      title: 'user_count',
      dataIndex: 'user_count',
      index: 3,
      selected: true,
      filterType: FilterTypes.NA,
      width: '25%',
    },
  ],
  [SaasAssets.SettingApps]: [
    {
      title: 'display_name',
      dataIndex: 'display_name',
      index: 0,
      selected: true,
      filterType: FilterTypes.Select,
    },
    {
      title: 'vendor_name',
      dataIndex: 'vendor_name',
      index: 1,
      selected: true,
      filterType: FilterTypes.Select,
    },
    {
      title: 'number_of_licences',
      dataIndex: 'number_of_licences',
      index: 2,
      selected: true,
      filterType: FilterTypes.NA,
    },
  ],
  [SaasAssets.SystemUsers]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      selected: true,
      filterType: FilterTypes.Select,
    },
    {
      title: 'email',
      dataIndex: 'email',
      index: 1,
      selected: true,
      filterType: FilterTypes.Select,
    },
  ],
  [SaasAssets.SettingsNamespaces]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'owner',
      dataIndex: 'owner',
      index: 1,
      selected: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'domain',
      dataIndex: 'domain',
      index: 2,
      selected: true,
      filterType: FilterTypes.NA,
    },
  ],
  [SaasAssets.SettingsAppsDiscover]: [
    {
      title: 'display_name',
      dataIndex: 'display_name',
      index: 0,
      selected: true,
      filterType: FilterTypes.Select,
    },
  ],
  [SaasAssets.GroupsSettings]: [
    {
      title: 'displayName',
      dataIndex: 'displayName',
      index: 0,
      selected: true,
      filterType: FilterTypes.NA,
    },
  ],
}
