import {AddonItemTypes, TServicesForm} from '@/sites/services/typedef'

export const normalizeServerValues = (values: TServicesForm) => {
  return {
    [AddonItemTypes.NUMBER_OF_USERS]: Array.isArray(values?.['number-of-users'])
      ? values?.['number-of-users'][0]
      : values['number-of-users'],
    [AddonItemTypes.SUPPORT_TYPE]: Array.isArray(values?.['support-type'])
      ? values?.['support-type'][0]
      : values['support-type'],
    [AddonItemTypes.SUPPORT]: Array.isArray(values?.['support'])
      ? values?.['support'][0]
      : values['support'],
    [AddonItemTypes.RESPOND_TIME]: values['respond-time'],
    country: values.country,
  }
}
