import {
  ActionTypes,
  TAnyAction,
} from '@/modules/CMDB/store/module-tables/action-types'
import {TFilterListTable} from '@/modules/CMDB/components/cmdb-list-table/typedf'
import {CMDBAssets, TColumnDefault} from '@/modules/CMDB/typedef'
import {SortingKeys, SortingValues} from '@/typedef'
import {cmdbColumnsDefaults} from '@/modules/CMDB/components/cmdb-list-table/constants/columns-default'

// @ts-nocheck
type TInitialState = Record<
  CMDBAssets,
  {
    columns: TColumnDefault
    filters: TFilterListTable
    sort: Record<SortingKeys, SortingValues> | null
  }
>

const defaultAssetEntries = {
  columns: cmdbColumnsDefaults,
  filters: {},
  sort: null,
}

const initalState: TInitialState = {
  [CMDBAssets.Users]: {
    ...defaultAssetEntries,
    columns: cmdbColumnsDefaults[CMDBAssets.Users],
  },
  [CMDBAssets.Accounts]: {
    ...defaultAssetEntries,
    columns: cmdbColumnsDefaults[CMDBAssets.Accounts],
  },
  [CMDBAssets.NameSpaces]: {
    ...defaultAssetEntries,
    columns: cmdbColumnsDefaults[CMDBAssets.NameSpaces],
  },
  [CMDBAssets.Assets]: {
    ...defaultAssetEntries,
    columns: cmdbColumnsDefaults[CMDBAssets.Assets],
  },
  [CMDBAssets.AssetTypes]: {
    ...defaultAssetEntries,
    columns: cmdbColumnsDefaults[CMDBAssets.AssetTypes],
  },
  [CMDBAssets.Locations]: {
    ...defaultAssetEntries,
    columns: cmdbColumnsDefaults[CMDBAssets.Locations],
  },
}

export default function cmdbDataTableReducer(
  state = initalState,
  action: TAnyAction
): TInitialState {
  // @ts-ignore
  switch (action.type) {
    case ActionTypes.SET_SMDB_TABLE_MODIFIERS: {
      const {
        asset,
        tableModifiers: {filters, columns},
        // @ts-ignore
      } = action.payload

      return {
        ...state,
        [asset]: {
          columns,
          filters,
        },
      }
    }
    case ActionTypes.RESET_SMDB_LIST_TABLE_FILTERS: {
      // @ts-ignore
      const {asset} = action.payload

      return {
        ...state,
        [asset]: {
          // @ts-ignore
          ...state[asset],
          filters: {},
        },
      }
    }

    case ActionTypes.SET_SMDB_LIST_TABLE_FILTERS: {
      // @ts-ignore
      const {asset, filters} = action.payload
      return {
        ...state,
        [asset]: {
          // @ts-ignore
          ...state[asset],
          filters: {
            // @ts-ignore
            ...state[asset].filters,
            ...filters,
          },
        },
      }
    }

    case ActionTypes.SET_CMDB_LIST_TABLE_COLUMNS: {
      // @ts-ignore
      const {asset, columns} = action.payload

      return {
        ...state,
        [asset]: {
          // @ts-ignore
          ...state[asset],
          columns,
        },
      }
    }

    case ActionTypes.RESET_CMDB_LIST_TABLE_COLUMNS: {
      // @ts-ignore
      const {asset} = action.payload
      return {
        ...state,
        // @ts-ignore
        [asset]: initalState[asset],
      }
    }

    case ActionTypes.SET_SMDB_LIST_TABLE_SORTERS: {
      // @ts-ignore
      const {asset, sort} = action.payload

      return {
        ...state,
        [asset]: {
          // @ts-ignore
          ...state[asset],
          sort,
        },
      }
    }

    case ActionTypes.SET_CMDB_LIST_TABLE_SHARED_URL_FILTERS: {
      const {
        asset,
        data: {sort, filters, columns},
        // @ts-ignore
      } = action.payload
      return {
        ...state,
        [asset]: {sort: sort ?? null, filters: filters || {}, columns},
      }
    }

    case ActionTypes.RESET_CMDB_LIST_TABLE_FILTER: {
      // @ts-ignore
      const {asset, key} = action.payload

      // @ts-ignore
      const newFilters = {...state[asset].filters}

      delete newFilters[key]

      return {
        ...state,
        [asset]: {
          // @ts-ignore
          ...state[asset],
          filters: newFilters,
        },
      }
    }

    default:
      return state
  }
}
