import React, {FC} from 'react'

import classnames from 'classnames'

import './form-item-styled-wrapper.scss'
import {TChildren} from '@/typedef'

type TProps = {
  isGrey?: boolean
} & TChildren

const FormItemStyledWrapper: FC<TProps> = ({isGrey, children}) => {
  const wrapperClassnames = classnames('form-item-wrapper', {
    'form-item-wrapper__grey': isGrey,
  })

  return <div className={wrapperClassnames}>{children}</div>
}

export default FormItemStyledWrapper
