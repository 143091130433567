import React, {MouseEvent, useCallback, useEffect, useState} from 'react'

import {useHistory, useParams} from 'react-router-dom'
import {Col, Form, Row} from 'antd'
import isEqual from 'lodash/isEqual'

import {Button} from '@/components/button'
import {checkIsLoading} from '@/utils/check-is-loading'
import {useGetSingleData} from '@/hooks/useGetSignleData'
import {postApproveEvent} from '@/modules/ITSM/api/eventsRequests'
import {fetchUsers} from '@/modules/ITSM/api/userRequests'
import {TCustomerProduct, TUser} from '@/modules/ITSM/typedef'
import {fetchCustomerProduct} from '@/modules/ITSM/api/productsRequests'
import {translate} from '@/services/i18n'
import {LocationSelect} from '@/modules/ITSM/components/locationSelect'
import {Entities} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {scrollSettings} from '@/modules/ITSM/components/incident/constants'
import {FormSkeleton} from '@/components/skeleton/templates/form-skeleton'
import {Skeleton} from '@/components/skeleton/skeleton'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {TEvent} from '../typedf'
import EventForm from '../event-form'
import {createData, getInitialValues, getUpdateValues} from '../utils'
import {useUpdateEvent} from '../hooks/useUpdateEvent'
import {EventsQuickFilters, requiredEditFields} from '../constants'
import {useGetEvent} from '../hooks/useGetEvent'
import EventLocation from '../event-location'
import {useDeleteEvent} from '../hooks/useDeleteEvent'

const {
  serviceManagementIncidentsDetail,
  serviceManagementRequestsDetail,
  serviceManagementEventsPage,
} = itsmRoutes

const Event = () => {
  const [form] = Form.useForm()

  const {status: approvingStatus, handleRequested, handleFail} = useFetchHook()

  const {updateEntity, updateEntityStatus} = useUpdateEvent()
  const [btnDisabled, setBtnDisabled] = useState(true)

  const [locationSelectVisible, setLocationSelectVisible] = useState(false)

  const [isNewLocation, setNewLocation] = useState(false)

  const history = useHistory()

  const {id} = useParams<{id: string}>()

  const {getData: getEvent, entityData: event} = useGetEvent()

  const {deleteEntity, deleteEntityStatus} = useDeleteEvent()

  const {caller, customer_product} = event || {}

  const {getData: getCaller, entityData: callerInit} = useGetSingleData<
    TUser[]
    // @ts-ignore
  >(fetchUsers)

  const {
    getData: getCustomerProduct,
    entityData: customerProdInit,
    // @ts-ignore
  } = useGetSingleData<TCustomerProduct[]>(fetchCustomerProduct)

  useEffect(() => {
    if (caller) {
      ;(async () => {
        await getCaller({
          id: '',
          selector: {uuid: caller, active: true},
        })
      })()
    }
  }, [caller, getCaller])

  useEffect(() => {
    if (customer_product) {
      ;(async () => {
        await getCustomerProduct({
          id: '',
          selector: {uuid: customer_product},
        })
      })()
    }
  }, [customer_product, getCustomerProduct])

  useEffect(() => {
    ;(async () => {
      const result = await getEvent(id)

      if (result)
        form.setFieldsValue({
          ...(result.customer_product
            ? {customer_product: result.customer_product}
            : {}),
          ...(result.caller ? {caller: result.caller} : {}),
        })
    })()
  }, [form, getEvent, id])

  const checkValues = useCallback(
    (values: TEvent) => {
      event &&
        setBtnDisabled(
          isEqual(createData(getUpdateValues(values)), getInitialValues(event))
        )
    },
    [event]
  )

  const handleUpdate = async (
    event: MouseEvent<HTMLElement>,
    approving?: boolean
  ) => {
    event.preventDefault()
    await form.validateFields(['email', 'caller', 'short_description'])

    const values = form.getFieldsValue(requiredEditFields)
    const data = createData(values)
    const result = await updateEntity({...data, id})

    if (result && !approving) {
      await getEvent(id)
      setBtnDisabled(true)
    }
  }

  const createApproveData = (values: TEvent) => {
    return isNewLocation
      ? {
          id,
          location: {
            latitude: form.getFieldValue('latitude'),
            longitude: form.getFieldValue('longitude'),
            place:
              form.getFieldValue('street') +
              ' ' +
              form.getFieldValue('city') +
              ' ' +
              form.getFieldValue('zip') +
              ' ' +
              form.getFieldValue('state_province') +
              ' ' +
              form.getFieldValue('country'),
          },
        }
      : {
          id,
          location: {
            uuid: values.location,
          },
        }
  }

  const handleApprove = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    const requiredFields = ['customer_product', 'requestedType']
    let values
    try {
      values = await form.validateFields(
        isNewLocation
          ? [...requiredFields, 'street', 'latitude', 'longitude']
          : [...requiredFields, 'location']
      )

      try {
        const data = createApproveData(values)

        await handleUpdate(event, true)

        handleRequested()
        await postApproveEvent(data)

        const {requestedType, ref_incident} = (await getEvent(id)) || {}

        if (requestedType && ref_incident)
          history.push(
            requestedType === Entities.INCIDENT
              ? serviceManagementIncidentsDetail.path(ref_incident)
              : serviceManagementRequestsDetail.path(ref_incident)
          )
      } catch (err) {
        handleFail(err)
      }
    } catch (err) {
      // @ts-expect-error as unknown
      form.scrollToField(err.errorFields[0]?.name, scrollSettings)
    }
  }

  const handleDelete = async () => {
    await deleteEntity(id)
    history.push(
      serviceManagementEventsPage.path(EventsQuickFilters.NotApproved, '1')
    )
  }

  const resetNewLocation = () => {
    if (!isNewLocation) form.resetFields(['location'])
    setNewLocation(!isNewLocation)
  }

  const headerBtns = (
    <div>
      <Button
        type="primary"
        e2e-test={'event-update-btn'}
        disabled={btnDisabled || checkIsLoading(updateEntityStatus)}
        onClick={handleUpdate}
        title={translate('update')}
      />

      <Button
        loading={checkIsLoading(deleteEntityStatus)}
        e2e-test={'event-delete-btn'}
        onClick={
          locationSelectVisible
            ? () => {
                setLocationSelectVisible(false)
                handleDelete()
              }
            : () => handleDelete()
        }
        className="ml-10"
        title={translate('delete')}
      />
    </div>
  )

  return (
    <Paper className="event" variant={PaperVariant.form}>
      <Skeleton active={!event} view={<FormSkeleton />}>
        <EventForm
          form={form}
          record={event}
          callerInit={callerInit?.[0]}
          customerProdInit={customerProdInit?.[0]}
          headerBtns={headerBtns}
          checkValues={checkValues}
          customerProdRequired={locationSelectVisible}
        >
          {!isNewLocation && locationSelectVisible && !event?.approved_at && (
            <Row gutter={24}>
              <Col span={24} className="mb-15">
                <LocationSelect
                  {...{
                    locationId: form.getFieldValue('location'),
                  }}
                  required
                />

                <Button
                  title={translate('new_location')}
                  onClick={() => setNewLocation(true)}
                  size="large"
                />
              </Col>
            </Row>
          )}
          {isNewLocation && <EventLocation form={form} />}
          <Row>
            <Col span={24}>
              {event && !event.approved_at && (
                <Button
                  title={translate('approve')}
                  size="large"
                  type="primary"
                  //   loading={state.loadingAprroving}
                  onClick={(event: MouseEvent<HTMLElement>) => {
                    !locationSelectVisible
                      ? setLocationSelectVisible(true)
                      : handleApprove(event)
                  }}
                  disabled={checkIsLoading(approvingStatus)}
                  e2e-test="event-approve-btn"
                />
              )}
              {locationSelectVisible && (
                <Button
                  className="ml-10"
                  title={`${translate('cancel')} ${translate('approve')}`}
                  size="large"
                  onClick={() => {
                    setLocationSelectVisible(false)
                    form.resetFields(['location'])
                  }}
                />
              )}
              {isNewLocation && locationSelectVisible && (
                <Button
                  onClick={resetNewLocation}
                  className="ml-10"
                  title={translate('choose_existing_location')}
                  size="large"
                />
              )}
            </Col>
          </Row>
        </EventForm>
      </Skeleton>
    </Paper>
  )
}

export default Event
