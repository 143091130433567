import React from 'react'

import classNames from 'classnames'

import './block.scss'

type TProps = {
  active?: boolean
  cursor?: boolean
  selected?: boolean
  onClick?: () => void
  children?: React.ReactNode
  className?: string
}

export const Block: React.FC<TProps> = (props: TProps) => {
  const {active, cursor, selected, onClick, children, className} = props
  const blockClass = classNames('block', className, {
    'block--active': active,
    'block--cursor': cursor,
    'block--selected': selected,
  })

  return (
    <div className={blockClass} onClick={onClick}>
      <div className="block__content">{children}</div>
    </div>
  )
}
