import {useState} from 'react'

import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {fetchCities} from '../../../api/citiesRequest'

export const useCities = () => {
  const dispatch = useDispatch()
  const [cities, setCities] = useState<string[]>([])

  const getCities = async (country: string) => {
    if (country) {
      try {
        const {body = {}} = await fetchCities(country)
        setCities(body.data || [])
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    }
  }

  return {
    getCities,
    cities,
    setCities,
  }
}
