import {translate} from '@/services/i18n'

export const parsePostAcceptInviteError = (err: unknown) => {
  if (err instanceof Error) {
    const parsedResponse = JSON.parse(JSON.stringify(err))
    const type = parsedResponse?.response.body?.type

    if (type?.includes('invitation-email-mismatch')) {
      return translate('invitation_email_mismatch')
    }
    if (type?.includes('invitation-expired')) {
      return translate('invitation_expired')
    }
    if (type?.includes('invitation-invalid')) {
      return translate('invitation_invalid')
    }
  }

  return translate('oops_something_went_wrong')
}
