import {TIdentityReq} from '@/modules/ITSM/components/identity-form/typedf'

export enum InvitationStep {
  ACCEPT_INVITE = 'ACCEPT_INVITE',
  CREATE_ACC_AND_ORG = 'CREATE_ACC_AND_ORG',
  CREATE_IDENTITY = 'CREATE_IDENTITY',
  CREATE_PROFILE = 'CREATE_PROFILE',
}

export type TCreatorAccOrgForChannelInvite = {
  companyName: string
  account: string
  org_name: string
}

export type TProfileForm = {
  account: string
  organization: string
  channel: string
}

export type TUserFormPayload = TIdentityReq & {terms: boolean}
