import React, {FC, Fragment} from 'react'

import {Divider} from 'antd'
import moment from 'moment'

import {Button} from '@/components/button'
import {Drawer} from '@/components/drawer'
import SvgIcon from '@/components/svg-icon/svg-icon'

import './purchase-cart-drawer.scss'

import {calculateTotalPrice} from '@/sites/purchase/utils/calculate-total-price'
import {translate} from '@/services/i18n'
import {transformPrice} from '@/modules/Subscriptions/utils/transform-price'

import {ModuleFeatureFlags, TNormalizedProduct} from '@/sites/purchase/typedef'
import {Interval} from '@/modules/typedef'

import {cyclePrice} from '@/sites/purchase/constants/cycle-prices-options'

const defaultCurrency = 'usd'

type TProps = {
  isDisplayNext: boolean
  visible: boolean
  onClose: () => void
  interval: Interval
  addons: Record<ModuleFeatureFlags, Array<TNormalizedProduct>>
  extraAddons: Array<TNormalizedProduct>
  modules: Array<TNormalizedProduct>
  handleSubmit: () => void
}

export const PurchaseCartDrawer: FC<TProps> = ({
  isDisplayNext,
  visible,
  onClose,
  interval,
  modules,
  addons,
  extraAddons,
  handleSubmit,
}) => {
  const total = calculateTotalPrice({
    addons,
    extraAddons,
    modules,
  })

  const currency = modules?.[0]?.currency || defaultCurrency
  const submitButtonTitle = isDisplayNext ? 'next' : 'buy_now'
  return (
    <Drawer
      className="purchase-cart-drawer"
      open={visible}
      placement="right"
      onClose={onClose}
      title={translate('shopping_cart')}
    >
      <div data-testid="purchase-cart-drawer">
        {Object.values(ModuleFeatureFlags).map((moduleName, index) => {
          const moduleProduct = modules.find(
            price => price.feature_flag === moduleName
          )

          return (
            moduleProduct && (
              <div key={moduleProduct.id} data-testid="shopping-cart-drawer">
                <Divider className="purchase-cart__divider" dashed>
                  {moduleProduct.name}
                </Divider>
                <table className="purchase-cart-drawer__prices">
                  <thead>
                    {index === 0 && (
                      <tr>
                        <th>{translate('item')}</th>
                        <th>{translate('price')}</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    <tr className={'purchase-cart-drawer__price-wrapper'}>
                      <td>{translate('basic_plan')}</td>
                      <td>
                        <div>
                          {transformPrice(moduleProduct.unit_amount, currency)}
                        </div>
                        <div className="purchase-cart-drawer__price-cycle">
                          {cyclePrice[interval]}
                        </div>
                      </td>
                    </tr>
                    {addons[moduleName]?.map(addon => (
                      <tr
                        key={addon.id}
                        className={'purchase-cart-drawer__price-wrapper'}
                      >
                        <td>{addon.name}</td>
                        <td>
                          <p>{transformPrice(addon.unit_amount, currency)}</p>
                          <div className="purchase-cart-drawer__price-cycle">
                            {cyclePrice[interval]}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )
        })}
        {extraAddons.length > 0 && (
          <>
            <Divider className={'m-24'} dashed>
              {translate('support_services')}
            </Divider>
            <table className="purchase-cart-drawer__prices">
              <tbody>
                {extraAddons.map(addon => (
                  <tr key={addon.id}>
                    <td>{addon.name}</td>
                    <td>
                      <div>{transformPrice(addon.unit_amount, currency)}</div>
                      <div className="purchase-cart-drawer__price-cycle">
                        {cyclePrice[interval]}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <Divider dashed />
        <table className="purchase-cart-drawer__charge">
          <tbody>
            <tr>
              <td>{translate('todays_charge')}</td>
              <td>
                <div>{transformPrice(total, currency)}</div>
                <p className="purchase-cart-drawer__taxes-sub-title">
                  {translate('before_taxes')}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <Divider dashed />
        <table className="purchase-cart-drawer__recurring mb-20">
          <tbody>
            <tr>
              <td>{`${translate('recurring')} ${
                cyclePrice[interval]
              } ${translate('charge')}`}</td>
              <td>
                <p>{transformPrice(total, currency)}</p>
              </td>
            </tr>
            <tr>
              <td>{translate('next_charge_date')}</td>
              <td>
                <div>
                  {moment()
                    .add(1, 'year')
                    .format('MMM DD, YYYY')}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex flex--justifyEnd ">
          <Button
            onClick={handleSubmit}
            title={translate(submitButtonTitle)}
            data-testid={submitButtonTitle}
            type="primary"
            className="purchase-cart-drawer__button"
          />
        </div>
        <div className="mt-24 ">
          <SvgIcon
            name="powered_by_stripe_blurple"
            className="purchase-cart-drawer__stripe-icon"
          />
        </div>
      </div>
    </Drawer>
  )
}
