import storeProvider from '@/redux/storeProvider'
import {setAccessToken, setInitialStateUser} from '@/redux/user/userActions'
import {clearUIState} from '@/redux/ui/uiActions'
import {setInitialStateGeneral} from '@/redux/actions'
import {resetEntriesReducer} from '@/redux/entries/entries-actions'
import {clearToastMessage} from '@/redux/toast-message/toast-actions'
import {resetGeneralProfile} from '@/redux/profile/profileActions'

export const resetModuleState = () => {
  const store = storeProvider.getStore()
  const {isHistoryBlock} = store.getState().ui
  const accessToken = store.getState().itsmUser.accessToken

  if (!isHistoryBlock) {
    store.dispatch(setInitialStateUser())
    store.dispatch(setAccessToken({accessToken}))
    store.dispatch(clearUIState())
    store.dispatch(setInitialStateGeneral())
    store.dispatch(resetEntriesReducer())
    store.dispatch(clearToastMessage())
    store.dispatch(resetGeneralProfile())
  }
}
