// @ts-nocheck UserGroupsFormContainer is not refactored to typescript

import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'

import UserGroupsFormContainer from './user-groups-form-container/user-groups-form-container'

const UserGroupsDetailWrapper = () => {
  const {getEntityData, onClose, record} = useListTableStateContext()

  return (
    <UserGroupsFormContainer
      getEntityData={getEntityData}
      onClose={onClose}
      record={record}
    />
  )
}

export default UserGroupsDetailWrapper
