import React from 'react'

import {useHistory} from 'react-router-dom'
import {Col, Form, Row} from 'antd'

import {Button} from '@/components/button'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {checkIsLoading} from '@/utils/check-is-loading'
import {translate} from '@/services/i18n'
import {Paper} from '@/components/paper/paper'

import EventForm from './event-form'
import {TEvent} from './typedf'
import {usePostEvent} from './hooks/usePostEvent'
import {createData} from './utils'

const EventNew = () => {
  const [form] = Form.useForm()

  const {saveEntity, saveEntityStatus} = usePostEvent()

  const history = useHistory()

  const handleSubmit = async (values: TEvent) => {
    const result = await saveEntity(createData(values))

    if (result !== undefined)
      history.push(itsmRoutes.serviceManagementEventsDetail.path(result.id))
  }

  return (
    <Paper className="event-new">
      <EventForm handleSubmit={handleSubmit} form={form}>
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              htmlType={'submit'}
              disabled={checkIsLoading(saveEntityStatus)}
              e2e-test={'submit-event'}
              title={translate('submit')}
            />
          </Col>
        </Row>
      </EventForm>
    </Paper>
  )
}

export default EventNew
