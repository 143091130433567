import React from 'react'

import {ItsmAssets, TUser} from '@/modules/ITSM/typedef'
import ItsmListTableSettings from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {getUserFullName} from '@/utils/get-user-full-name'

import IdentitiesDrawer from './IdentitiesDrawer'

const Users = () => {
  return (
    <ItsmListTableSettings<TUser>
      asset={ItsmAssets.Users}
      detailComponent={<IdentitiesDrawer />}
      getDetailComponentTitle={(record: TUser) => getUserFullName(record)}
      rowKey="uuid"
    />
  )
}

export default Users
