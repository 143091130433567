import {useCallback, useContext} from 'react'

import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

import {
  getCustomCheckoutSession,
  searchPricesByMetadata,
} from '@/api/billing-requests'
import {transformProductsPayload} from '@/modules/Subscriptions/utils/transform-subscription-stripe-payload'
import {MetadataType} from '@/modules/Subscriptions/utils/constants'
import {generateSearchPriceParam} from '@/modules/Subscriptions/utils/generate-search-price-param'
import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {useGenerateSessionReturnLinks} from '@/sites/account-default/hooks/use-generate-session-return-links'
import {transformServicesPayload} from '@/modules/Subscriptions/utils/transform-services-subscription-stripe-payload'
import {hasPaymentDetails} from '@/sites/account-default/utils/has-payment-details'
import {
  AccountDefaultContext,
  setAccountDefaultError,
  setAccountDefaultProgressStatus,
} from '@/sites/account-default/account-default-context/account-default-context'

import {TPaymentSessionPayload} from '@/api/typedf'
import {
  AccountDefaultError,
  Flow,
  TAccountDefaultQuery,
} from '@/sites/account-default/typedef'
import {
  Interval,
  IPrice,
  PriceFeatureFlagKeys,
  SubscriptionMode,
} from '@/modules/typedef'
import {
  subscrCheckoutSessionStepProgress,
  subscrCheckoutSessionSteps,
} from '@/sites/account-default/constants/subscr-checkout-session-steps'

export const useSubscriptionCheckoutSession = () => {
  const {search} = useLocation()
  const query = queryString.parse(search) as TAccountDefaultQuery
  const {paymentDetails, flow} = hasPaymentDetails(query)

  const {dispatch} = useContext(AccountDefaultContext)

  const generateSessionReturnLinks = useGenerateSessionReturnLinks()

  const generatePaymentSession = useCallback(
    async (token: string) => {
      dispatch(
        setAccountDefaultProgressStatus(
          subscrCheckoutSessionStepProgress[subscrCheckoutSessionSteps.INITIAL]
        )
      )

      const type = MetadataType.BLITS_SOFTWARE_PRODUCT
      const mode = SubscriptionMode.SUBSCRIPTION

      const returnLinks = generateSessionReturnLinks(query)

      try {
        let payload: TPaymentSessionPayload
        if (flow === Flow.PRODUCTS) {
          payload = {
            ...returnLinks,
            items: transformProductsPayload(JSON.parse(paymentDetails.payload)),
            mode,
            payment_method_collection: 'always',
            subscription_data: {
              metadata: {type, interval: paymentDetails.interval},
            },
          }
        } else if (flow === Flow.SERVICES && paymentDetails.unit_amount) {
          payload = {
            ...returnLinks,
            items: transformServicesPayload(
              JSON.parse(paymentDetails.payload),
              paymentDetails.interval,
              parseInt(paymentDetails.unit_amount)
            ),
            mode,
            payment_method_collection: 'if_required',
            subscription_data: {
              trial_period_days: 30,
              metadata: {interval: paymentDetails.interval},
            },
          }
        } else {
          const {
            body: {result: allInclusivePrices},
          } = await searchPricesByMetadata({
            queryParam: generateSearchPriceParam(
              'feature_flag',
              PriceFeatureFlagKeys.ALL_INCLUSIVE
            ),
            headers: createAuthorizationHeader(token),
          })

          dispatch(
            setAccountDefaultProgressStatus(
              subscrCheckoutSessionStepProgress[
                subscrCheckoutSessionSteps.ALL_INCLUSIVE_PRICE
              ]
            )
          )

          const initialPriceId =
            allInclusivePrices.find(
              (price: IPrice) => price.recurring?.interval === Interval.YEAR
            ) || allInclusivePrices[0]

          if (!initialPriceId) {
            return dispatch(
              setAccountDefaultError(
                AccountDefaultError.NO_FOUND_ALL_INCLUSIVE_PRICE
              )
            )
          }
          payload = {
            ...returnLinks,
            items: transformProductsPayload([
              {
                id: initialPriceId.id,
                usage_type: initialPriceId.recurring.usage_type,
              },
            ]),
            mode,
            subscription_data: {
              trial_period_days: 30,
              metadata: {type, interval: Interval.MONTH},
            },
          }
        }

        const response = await getCustomCheckoutSession({
          payload,
          headers: createAuthorizationHeader(token),
        })

        dispatch(
          setAccountDefaultProgressStatus(
            subscrCheckoutSessionStepProgress[
              subscrCheckoutSessionSteps.CREATE_CHECKOUT_SESSION
            ]
          )
        )

        window.location.replace(response.body.result.url)
      } catch (error) {
        dispatch(setAccountDefaultError(error))
      }
    },
    [
      dispatch,
      flow,
      generateSessionReturnLinks,
      paymentDetails?.interval,
      paymentDetails?.payload,
      paymentDetails?.unit_amount,
      query,
    ]
  )

  return {
    generatePaymentSession,
  }
}
