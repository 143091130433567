import React, {lazy, useEffect} from 'react'

import moment from 'moment/moment'
import {Col, Row} from 'antd'

import {useGetBillingReportsList} from '@/hooks/use-get-billing-reports-list'
import {StatisticsCard} from '@/modules/ITSM/sites/finance-dashboard/statistics-card/statistics-card'
import {FinanceDashboardTile} from '@/modules/ITSM/sites/finance-dashboard/tile/finance-dashboard-tile'
import {getDate} from '@/modules/ITSM/sites/BillingReports/current-billing-reports/utils'
import {transformOverviewData} from '@/modules/ITSM/sites/finance-dashboard/utils/transform-overview-data'
import {transformYearlyOverviewData} from '@/modules/ITSM/sites/finance-dashboard/utils/transform-yearly-overview-data'
import {checkIsLoading} from '@/utils/check-is-loading'
import {yearlyBarOptions} from '@/modules/ITSM/sites/finance-dashboard/constants/yearly-bar-options'
import {currentYearAreaOptions} from '@/modules/ITSM/sites/finance-dashboard/constants/current-year-area-options'
import {ChartType} from '@/components/charts/apex-chart/typed'
import {ApexChart} from '@/components/charts/apex-chart/apex-chart'

import './finance-dashboard.scss'

const BillingReports = lazy(() =>
  import('../BillingReports/billing-reports/billing-reports')
)

const FinanceDashboard = () => {
  const {
    getData,
    fetchStatus,
    entityData,
    flatBillingReportsData,
  } = useGetBillingReportsList()

  const currentMonthBillingReportData =
    flatBillingReportsData?.[flatBillingReportsData.length - 1]
  const previousMonthBillingReportData =
    flatBillingReportsData[flatBillingReportsData.length - 2]

  useEffect(() => {
    getData({
      date_from: '2015-01-01T00:00:00Z',
      date_to: '2100-01-01T00:00:00Z',
    })
  }, [getData])

  return (
    <>
      <div className="finance-dashboard">
        <h2 className="finance-dashboard__date">
          {getDate(moment().format('YYYY-MM-DD'))}
        </h2>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} lg={6} className="finance-dashboard__tile">
            <FinanceDashboardTile
              currentTotal={currentMonthBillingReportData?.revenue}
              previousTotal={previousMonthBillingReportData?.revenue}
              loading={checkIsLoading(fetchStatus)}
            />
          </Col>
          <Col xs={24} sm={12} lg={6} className="finance-dashboard__tile">
            <FinanceDashboardTile
              currentTotal={currentMonthBillingReportData?.cost}
              previousTotal={previousMonthBillingReportData?.cost}
              loading={checkIsLoading(fetchStatus)}
              isRevenue
            />
          </Col>
          <Col xs={24} md={12} lg={12}>
            <StatisticsCard
              currentMonth={currentMonthBillingReportData}
              previousMonth={previousMonthBillingReportData}
              loading={checkIsLoading(fetchStatus)}
            />
          </Col>
          <Col xs={24} md={12}>
            <ApexChart
              type={ChartType.bar}
              options={yearlyBarOptions}
              series={transformYearlyOverviewData(entityData)}
              loading={checkIsLoading(fetchStatus)}
            />
          </Col>
          <Col xs={24} lg={12}>
            <ApexChart
              type={ChartType.area}
              options={currentYearAreaOptions}
              series={transformOverviewData(
                entityData.length > 0
                  ? entityData[entityData.length - 1]?.data
                  : []
              )}
              loading={checkIsLoading(fetchStatus)}
            />
          </Col>
        </Row>
      </div>
      <BillingReports />
    </>
  )
}

export default FinanceDashboard
