import React from 'react'

import {Switch} from 'antd'
import classNames from 'classnames'
import {SortableElement, SortableHandle} from 'react-sortable-hoc'

import {Icon, IconType} from '@/components/icon'
import {TKey, translate} from '@/services/i18n'
import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import {generateTitle} from '@/utils/table/generate-title'

import './table-columns-customizer.scss'

const DragHandle = SortableHandle(() => {
  return (
    <Icon
      type={IconType.BRAILLE_SIX_DOTS}
      size={10}
      className={'table-columns-customizer__drag-handle'}
    />
  )
})

type TProps = {
  value: TKey
  isChecked?: boolean
  isRequired?: boolean
  handleActiveColumns: (columnName: string, isAdded: boolean) => void
}

export const SortableListItem = (props: TProps) => {
  const {value, isChecked, isRequired, handleActiveColumns} = props
  const onSwitchChange = (isChecked: boolean) => {
    handleActiveColumns(value, isChecked)
  }
  const switchClass = classNames('table-columns-customizer__switch', {
    'table-columns-customizer__switch--checked': isChecked,
  })

  return (
    <div className="table-columns-customizer__item">
      <div className="flex">
        <div className="table-columns-customizer__drag-handle-wrapper">
          <DragHandle />
        </div>

        {translate(value as TKey).includes('_')
          ? generateTitle(value)
          : parseToUpperCase(translate(value as TKey))}
      </div>
      <Switch
        checked={isChecked}
        size={'small'}
        className={switchClass}
        disabled={isRequired}
        onChange={onSwitchChange}
      />
    </div>
  )
}

export const SortableItem = SortableElement<TProps>(SortableListItem)
