import {call, put, select} from 'redux-saga/effects'

import {
  fetchIdentityMeFailed,
  getIdentityMeSuccess,
  getUser,
  getUserFailed,
  getUserSuccess,
  setUserInviteDefaults,
} from '@/redux/user/userActions'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {selectUserID} from '@/redux/user/userSelector'
import {setRedirection} from '@/redux/ui/uiActions'
import {getModalErrorData} from '@/utils/get-modal-error-data'
import {translate} from '@/services/i18n'
import {fetchIdentityMe} from '@/modules/ITSM/api/identityRequests'
import {fetchUser} from '@/modules/ITSM/api/userRequests'

import {normalizeFetchUserPayload} from './utils'
import {getHeadersWithGRPC} from '@/services/api'

import mainConfig from '@/global-routes'

export function* getIdentityMeSaga({payload: onSelect}) {
  try {
    /*
    * mock erorr
    * // if (!localStorage.getItem('mockId')) { 
    //   mockGetIdentitiesMeError() 
    // } else {
    * */
    const {
      body: {
        result: {users, user_defaults},
      },
    } = yield call(fetchIdentityMe, getHeadersWithGRPC())

    if (users) {
      yield put(getIdentityMeSuccess(users[0]))
      yield put(getUser(onSelect))
    } else {
      yield put(setUserInviteDefaults(user_defaults))
      yield put(getUserFailed())
      yield put(setRedirection(mainConfig.general.identity.path()))
    }
  } catch (err) {
    // if (isApiError(err)) {
    //   yield put(setRefreshIdentityAddMe({space: channelID, name: channelName}))
    // } else

    if (
      err?.response?.body?.error.includes(
        'getting channel client failed: event service creation failed:'
      )
    ) {
      yield put(
        setToastMessage({
          message: getModalErrorData(err, translate('get_identity_failed')),
        })
      )
      yield put(getUserFailed(err))

      // yield put(
      //   setRedirectAuthFailedUrl(globalRoutes.general.orgChannelDefault.path())
      // )
    } else {
      yield put(fetchIdentityMeFailed(err))
      yield put(getUserFailed(err))
      yield put(
        setToastMessage({
          message: getModalErrorData(err, translate('get_identity_failed')),
        })
      )
    }
  }
}

export function* getUserSaga() {
  const userId = yield select(selectUserID)

  try {
    const {
      body: {result},
    } = yield call(fetchUser, userId)
    // } = mockIdentityWID()
    yield put(getUserSuccess(normalizeFetchUserPayload(result)))
  } catch (err) {
    yield put(getUserFailed(err))
    yield put(
      setToastMessage({
        message: getModalErrorData(err, translate('get_usr_failed')),
      })
    )
  }
}
