import {call, put} from 'redux-saga/effects'

import {getVersion} from '@/modules/ITSM/api/versionRequests'
import {getVersionFailed, getVersionSuccess} from '@/redux/ui/uiActions'

export function* getVersionSaga() {
  try {
    const {
      body: {result},
    } = yield call(getVersion)
    yield put(getVersionSuccess(result.version))
  } catch (err) {
    yield put(getVersionFailed())
  }
}
