import React, {useCallback, useEffect, useRef, useState} from 'react'

import {InfoCircleOutlined} from '@ant-design/icons'
import {Col, Form, Input, Row, Select, Tooltip} from 'antd'
import {useSelector} from 'react-redux'
import isEqual from 'lodash/isEqual'
import {withErrorBoundary} from '@sentry/react'
import AssetSelect from '@/modules/ITSM/components/form-components/asset-select'
import {Button} from '@/components/button'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import CallerSelect from '@/modules/ITSM/components/form-components/caller-select'
import CreatedByInput from '@/modules/ITSM/components/form-components/created-by-input'
import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {selectPriorityValues} from '@/redux/reducers/generalSelector'
import {TKey, translate} from '@/services/i18n'
import {LocationSelect} from '@/modules/ITSM/components/locationSelect'
import {RESOLVE} from '@/constants'
import {getUserFullName} from '@/utils/get-user-full-name'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import {checkIfDisabled} from '@/modules/ITSM/components/incident-k-request/utils/check-if-disabled'
import {usePrevious} from '@/hooks/usePrevious'
import {setIncidentTabIsBlocked} from '@/modules/ITSM/components/incident/incident-reducer'
import {scrollToField} from '@/utils/scroll-to-field'
import ErrorPage from '@/components/error/error-page/error-page'
import {categoriesApi, userApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {useGetCategoryAssets} from '@/hooks/use-get-category-assets'
import {otherAsset} from '@/modules/ITSM/constants/other-asset'
import {RequestStatus} from '@/typedef'
import '@/styles/_form.scss'

import DescriptionEditor from '../../description-editor/description-editor'
import IncidentAssignment from '../../incident/incident-assignment/incident-assignment'
import {TIncident, TIncidentReq, TIncidentValue} from '../typedf'
import {useGetPriorityValues} from '../hooks/useGetPriorityValues'
import {TOnUpdateParams} from '../../attachments/attachments-button'

import {createData, getInitialValues} from './utils'
import {getNewLocationButton} from '../../locationSelect/utils/get-new-location-button'
import {TLocation} from '@/modules/ITSM/typedef'
import {saveLocation} from '@/modules/ITSM/api/locationRequests'

const Option = Select.Option
const TextArea = Input.TextArea
const otherAssetId = otherAsset.id

const IncidentDetailForm = () => {
  const [form] = Form.useForm()
  const {
    entityData,
    updateEntity,
    getData,
    isLoading,
    dispatch: incidentDispatch,
  } = useStateContext<TIncident>()

  const filesDataRef = useRef<TOnUpdateParams>()

  useGetPriorityValues()

  const {
    cmdb_ref,
    description,
    state_id,
    number,
    priority_text,
    caller,
    created_by,
    category,
    subcategory,
    impact_text,
    urgency_text,
    location,
    contact_type,
    watch_list,
    short_description,
    supplier_product,
    customer_product,
    assignment_group,
    assigned_to,
  } = entityData || {}

  const {assets, getAssets} = useGetCategoryAssets()

  const {
    editorState,
    onEditorStateChange,
    editorText,
    setText,
  } = useSetEditorState(description)

  const prevEditorText = usePrevious(editorText)

  const [, setRefresh] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const [newLocation, setNewLocation] = useState<TLocation | undefined>(
    location
  )

  const handleCategory = (uuid: string, callerUuid: string) => {
    form.setFieldsValue({
      cmdb_ref: otherAssetId,
      subcategory: null,
    })

    getAssets(uuid, callerUuid)
    setRefresh(Math.random())
  }

  const handleCaller = (val: string) => {
    form.setFieldsValue({
      cmdb_ref: otherAssetId,
    })

    getAssets(form.getFieldValue('category'), val)
  }

  const urgencyText: string[] = useSelector(state =>
    selectPriorityValues(state, 'urgency_text')
  )

  const impactText: string[] = useSelector(state =>
    selectPriorityValues(state, 'impact_text')
  )

  useEffect(() => {
    incidentDispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [incidentDispatch, btnDisabled])

  const getFormattedData = useCallback(
    (values: TIncidentReq) => {
      values['description'] = editorText
      return createData({...values})
    },
    [editorText]
  )

  const checkValues = useCallback(() => {
    entityData &&
      setBtnDisabled(
        isEqual(
          getFormattedData(form.getFieldsValue()),
          getInitialValues(entityData)
        )
      )
  }, [entityData, form, getFormattedData])

  const isDisabled = state_id !== undefined && checkIfDisabled(state_id)

  const onAddFilesChange = (params: TOnUpdateParams) => {
    filesDataRef.current = {...params}
    if (params.filesAmount > 0) {
      setBtnDisabled(false)
    }
  }

  const handleUpdateIncident = async (values: TIncidentValue) => {
    const data = getFormattedData({
      ...values,
      cmdb_ref: values.cmdb_ref ? [values.cmdb_ref] : undefined,
    })
    const result =
      entityData &&
      (await updateEntity(
        getDataDifference(getInitialValues(entityData), data)
      ))
    if (result !== undefined) {
      setBtnDisabled(true)
      await getData()
    }
  }

  const onAddedAttachments = () => {
    form.submit()
  }

  const handleUploadAttachment = async () => {
    const {
      filesAmount = 0,
      addAttachments: addFileAttachments,
      status: filesAttachmentStatus = RequestStatus.INITIAL,
    } = filesDataRef.current || {}
    const saveAttachments = async () => {
      if (addFileAttachments) {
        await addFileAttachments()
      }
    }
    if (filesAmount > 0 && filesAttachmentStatus === RequestStatus.INITIAL) {
      saveAttachments()
    }
  }

  useEffect(() => {
    if (prevEditorText !== editorText) checkValues()
  }, [checkValues, editorText, prevEditorText])

  useEffect(() => {
    if (category?.uuid && caller?.uuid) {
      getAssets(category.uuid, caller.uuid)
    }
  }, [caller?.uuid, category?.uuid, getAssets])

  return (
    <Form
      validateMessages={{
        // eslint-disable-next-line
        required: '${label} is required',
      }}
      onFinish={(values: TIncidentValue) => {
        filesDataRef.current &&
        filesDataRef.current.filesAmount > 0 &&
        filesDataRef.current.status === RequestStatus.INITIAL
          ? handleUploadAttachment()
          : handleUpdateIncident(values)
      }}
      form={form}
      layout="vertical"
      onValuesChange={checkValues}
      onFinishFailed={errorFields => scrollToField({errorFields, form})}
    >
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentValue>
            name="number"
            initialValue={number}
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('number')}
          >
            <Input readOnly />
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentValue>
            name="priority"
            initialValue={
              priority_text ? translate(priority_text as TKey) : null
            }
            rules={[
              {
                required: true,
              },
            ]}
            label={
              <span>
                {translate('priority')}
                <Tooltip
                  placement="top"
                  className="incident-detail-form__tooltip"
                  title={translate('priority_tooltip')}
                >
                  <InfoCircleOutlined className="incident-detail-form__tooltip ml-5" />
                </Tooltip>
              </span>
            }
          >
            <Input readOnly />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <CallerSelect
            callerInit={caller}
            callerValue={form.getFieldValue('caller')}
            setRefresh={() => setRefresh(Math.random())}
            isDisabled={isDisabled}
            onChange={handleCaller}
          />
        </Col>
        <Col {...twoColumns}>
          <CreatedByInput createdByInit={created_by} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <SelectWithBM
            api={categoriesApi().get}
            label={translate('category')}
            name="category"
            disabled={isDisabled}
            initialValue={category ? [category] : []}
            selector={{parent: {$exists: false}}}
            rules={{
              required: true,
            }}
            searchKey="name"
            optionContent={(res: {name: string}) => res.name}
            onChange={(categoryUuid: string) =>
              handleCategory(categoryUuid, form.getFieldValue('caller'))
            }
          />
        </Col>
        <Col {...twoColumns}>
          <SelectWithBM
            api={categoriesApi().get}
            label={translate('subcategory')}
            disabled={isDisabled || !form.getFieldValue('category')}
            name="subcategory"
            initialValue={subcategory ? [subcategory] : []}
            selector={{
              parent: {$in: [form.getFieldValue('category')]},
            }}
            rules={{
              required: !!form.getFieldValue('category'),
              message: translate('subcategory_req'),
            }}
            searchKey="name"
            optionContent={(res: {name: string}) => res.name}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <AssetSelect
            initialValue={cmdb_ref?.[0] || otherAssetId}
            disabled={isDisabled}
            userAssetList={assets}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentValue>
            name="impact_text"
            initialValue={
              impact_text ? translate(impact_text as TKey).toLowerCase() : null
            }
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('impact')}
          >
            <Select
              getPopupContainer={e => e.parentNode}
              className="full-width"
              disabled={isDisabled}
            >
              {impactText &&
                Object.values(impactText).map((name, key) => (
                  <Option value={name} key={key}>
                    {translate(name as TKey)}
                  </Option>
                ))}
            </Select>
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentValue>
            name="urgency_text"
            initialValue={
              urgency_text
                ? translate(urgency_text as TKey).toLowerCase()
                : null
            }
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('urgency')}
          >
            <Select
              getPopupContainer={e => e.parentNode}
              className="full-width"
              disabled={isDisabled}
            >
              {urgencyText &&
                Object.values(urgencyText).map((name, key) => (
                  <Option value={name} key={key}>
                    {translate(name as TKey)}
                  </Option>
                ))}
            </Select>
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <LocationSelect
            recordLocationData={newLocation}
            locationId={form.getFieldValue('location') || newLocation?.uuid}
            setRefresh={() => setRefresh(Math.random())}
            isDisabled={isDisabled}
            labelSuffix={getNewLocationButton({
              setNewLocation,
              form,
              saveLocation,
              setRefresh,
            })}
            required
          />
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentValue>
            name="contact_type"
            initialValue={contact_type || 'portal'}
            label={translate('source')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              getPopupContainer={e => e.parentNode}
              disabled={isDisabled}
              className="full-width"
            >
              <Option value="unknown">{translate('unknown')}</Option>
              <Option value="portal">{translate('portal')}</Option>
              <Option value="email">{translate('email')}</Option>
              <Option value="email_worker">{translate('email_worker')}</Option>
              <Option value="phone">{translate('telephone')}</Option>
              <Option value="customer">{translate('customer')}</Option>
            </Select>
          </FormItemGeneric>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* @ts-ignore not refactored to typescript */}
          <SelectWithBM
            mode="multiple"
            api={userApi().get + RESOLVE}
            label={translate('watch_list')}
            searchKey="full_name"
            name="watch_list"
            initialValue={watch_list}
            optionContent={getUserFullName}
            getPopupContainer={() => document.body}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemGeneric<TIncidentValue>
            initialValue={short_description || null}
            name="short_description"
            label={translate('title')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea readOnly={isDisabled} rows={5} />
          </FormItemGeneric>
        </Col>
      </Row>
      <DescriptionEditor
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        isDisabled={isDisabled}
        description={description}
        setText={setText}
        editorText={editorText}
        immediateAttachments={true}
        onAddFiles={onAddFilesChange}
        onAddAttachments={onAddedAttachments}
      />
      <IncidentAssignment
        form={form}
        setRefresh={() => setRefresh(Math.random())}
        isDisabled={isDisabled}
        supplierProduct={supplier_product}
        customerProduct={customer_product}
        assignmentGroup={assignment_group}
        assignedTo={assigned_to}
        stateId={state_id}
      />
      {!isDisabled && (
        <Row>
          <Col span={24} className="mb-30 mt-10">
            <Button
              title={translate('save')}
              disabled={isLoading || btnDisabled}
              htmlType="submit"
              type="primary"
              size="large"
              e2e-test="submit-button"
            />
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default withErrorBoundary(IncidentDetailForm, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
