type TGetSortValue = (
  pathname?: string
) => Array<{[key: string]: string}> | undefined

export const PATH_NAMES = [
  'assignment_group',
  'category',
  'customer_product',
  'supplier_product',
  'rejected_reason',
  'location',
]

export const getSortValue: TGetSortValue = pathname => {
  if (pathname) {
    if (pathname.includes('user_private')) {
      return [{surname: 'asc'}]
    }
    if (PATH_NAMES.includes(pathname)) {
      return [{name: 'asc'}]
    }
  }

  return undefined
}
