import React, {useEffect} from 'react'

import {withErrorBoundary} from '@sentry/react'
import {Col, Collapse, Descriptions, Row, Spin} from 'antd'
import {useSelector} from 'react-redux'

import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import ErrorPage from '@/components/error/error-page/error-page'
import {translate} from '@/services/i18n'
import {getUserFullName} from '@/utils/get-user-full-name'
import {checkIsLoading} from '@/utils/check-is-loading'
import {ItsmAssets, TTimeLog} from '@/modules/ITSM/typedef'
import {getTimeInTimezone} from '@/modules/ITSM/utils/Helpers'
import {apiService} from '@/modules/ITSM/api/api-service'
import {selectItsmAssetPagination} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'
import {usePrevious} from '@/hooks/usePrevious'

import {useStateContext} from '../../incident/incident-context'
import {TIncident} from '../typedf'
import {useGetDataListTableNoFilters} from '../../itsm-list-table-no-filters/hooks/use-get-data-list-table-no-filters'

import './incident-k-request-svr.scss'

const {Panel} = Collapse
const {Item} = Descriptions

const SVR = () => {
  const asset = ItsmAssets.SVR
  const {entityData, entity} = useStateContext<TIncident>()

  const {number, external_id} = entityData || {}

  const {
    getEntityData: fetchTimeLogs,
    entityData: timeLogsData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTableNoFilters<TTimeLog>({
    asyncF: apiService[asset],
    asset,
    entity,
  })

  const paginationAsset =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const prevPaginationAsset = usePrevious(paginationAsset)

  useEffect(() => {
    if (
      prevPaginationAsset?.bookmarks.length &&
      !paginationAsset.bookmarks.length
    ) {
      fetchTimeLogs({resetBookmarks: true})
    }
  }, [fetchTimeLogs, paginationAsset, prevPaginationAsset])

  return (
    <Spin spinning={checkIsLoading(fetchStatus)}>
      <Row>
        <Col className="SVR__column" span={24}>
          {timeLogsData && timeLogsData.length > 0 && (
            <Collapse accordion>
              {timeLogsData.map((timelog: TTimeLog, index: number) => (
                <Panel
                  className="SVR"
                  header={
                    !timelog.end
                      ? getTimeInTimezone(timelog.start, timelog.timezone)
                      : `${getTimeInTimezone(
                          timelog.start,
                          timelog.timezone
                        )} - ${getTimeInTimezone(
                          timelog.end,
                          timelog.timezone
                        )}`
                  }
                  key={index}
                >
                  <Descriptions>
                    <Item label={translate('FE_name')}>
                      {getUserFullName(timelog.created_by)}
                    </Item>
                    <Item label={translate('ticket_number')}>{number}</Item>
                    <Item label={translate('external_ticket_number')}>
                      {external_id}
                    </Item>
                    <Item label={translate('arrival_date')}>
                      {getTimeInTimezone(timelog.start, timelog.timezone)}
                    </Item>
                    <Item label={translate('departure_date')}>
                      {timelog.end &&
                        getTimeInTimezone(timelog.end, timelog.timezone)}
                    </Item>
                    <Item label={translate('cmdb_updated')}>
                      {(timelog.cmdb_updated || false).toString()}
                    </Item>
                    <Item label={translate('visit_summary')}>
                      {timelog.visit_summary}
                    </Item>
                  </Descriptions>
                </Panel>
              ))}
            </Collapse>
          )}

          <div style={timeLogsData?.length === 0 ? {visibility: 'hidden'} : {}}>
            <PaginationWithBookmarks
              fetch={(bookmark?: string) => {
                fetchTimeLogs({passedBookmark: bookmark})
              }}
              numberOfRecords={numberOfRecords}
              pagination={paginationAsset}
            />
          </div>
        </Col>
      </Row>
    </Spin>
  )
}

export default withErrorBoundary(SVR, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
