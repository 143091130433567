import React from 'react'

import {Paper} from '@/components/paper/paper'
import {SkeletonItem} from '@/components/skeleton'

import './apex-chart-skeleton.scss'

export const ApexChartSkeleton = () => {
  return (
    <Paper className="apex-chart-skeleton">
      <SkeletonItem
        className="apex-chart-skeleton__title"
        width={100}
        height={20}
      />
      <SkeletonItem width="100%" height="260px" />
    </Paper>
  )
}
