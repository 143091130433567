import {SideTabs} from '@/sites/services/typedef'

import {ReactComponent as SvgEndUder} from '../asserts/end-user.svg'
import {ReactComponent as SvgClowdApp} from '../asserts/cloud-app.svg'
import {ReactComponent as SvgComputerSetting} from '../asserts/computer-setting.svg'

export const icons = {
  [SideTabs.END_USER_SUPPORT]: SvgEndUder,
  [SideTabs.BUSINESS_APPS_SUPPORT_MANAGEMENT]: SvgClowdApp,
  [SideTabs.IT_ASSETS_SUPPORT_MANAGEMENT]: SvgComputerSetting,
}
