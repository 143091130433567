export const EN = {
  over: 'over',
  enjoy_your_free_tier_with_kompitech: 'Enjoy your free tier with Kompitech',
  for_extra_features_please_choose_any_subscription_plan_below:
    'For extra features please choose any subscription plan below',
  missing_channel: 'Missing channel',
  channel_already_exist: 'Channel already exist',
  please_create_channel_in_the: 'Please create a channel in the',
  _organization: 'organization.',
  oops_something_went_wrong_info:
    'We will get back to you as soon as it it resolved \n  Thank you',
  please_wait_we_are_creating_your_channel:
    'Please wait, we are creating your channel...',
  please_wait_we_are_sending_your_contact_form:
    'Please wait, we are sending your contact form...',
  select_countries: 'Select countries',
  previous: 'Previous',
  next_step: 'Next step',
  contact_sale: 'Contact Sale',
  end_user_support_title: 'Free ITSM (IT Service Management System) Included',
  business_apps_support_management_title: 'SaaS Management Automation System',
  it_assets_support_management_tittle: 'IT Assets Management Automation',
  incidents_and_request_management: 'Incidents and request management.',
  employees_self_service_portal: 'Employees self-service portal.',
  define_service_main_and_sub_categories:
    'Define service main and sub-categories.',
  slas_matrix_management: 'SLAs matrix management.',
  documentation_management: 'Documentation management.',
  unlimited_organizations: 'Unlimited organizations.',
  unlimited_channels: 'Unlimited channels.',
  svr_automation: 'SVR Automation.',
  billing_automation: 'Billing automation.',
  billing_automation_it_provider_module:
    'Billing automation: IT provider module.',
  work_time_tracker: 'Work time tracker.',
  workflows_and_processes_automation: 'Workflows and processes automation.',
  expenses_report_management: 'Expenses report management.',
  audit_log_and_retention: 'Audit log and retention.',
  premium_support: 'Premium support.',
  custom_integration: 'Custom integration.',
  auto_discovery_of_your_entire_saas_apps:
    'Auto-discovery of your entire SaaS apps.',
  saas_users_onboarding_automation: 'SaaS users onboarding automation.',
  auto_provisioning_of_saas_users_and_groups:
    'Auto-provisioning of SaaS users and groups.',
  auto_discovery_of_all_unassigned_saas_licenses_and_save_up_to_40_percent_cost:
    'Auto-discovery of all unassigned SaaS licenses and save up to 40% cost.',
  auto_discovery_of_all_unutilized_or_under_utilized_apps_auto_discovery:
    'Auto-discovery of all unutilized or under-utilized apps auto-discovery.',
  use_azure_ad_free_plan_azure_ad_p1_p2_optional:
    'Use Azure AD free plan. (Azure AD P1/P2 optional).',
  use_with_g_suite_or_okta_identity_providers:
    'Use with G-Suite or Okta identity providers.',
  view_users_access_audit_logs_with_unlimited_retention_duration:
    'View users access audit logs with unlimited retention duration.',
  on_premise_assets_discovery: 'On-premise assets discovery.',
  cloud_assets_discovery: 'Cloud assets discovery.',
  remote_users_asset_discovery: 'Remote users asset discovery.',
  assets_lifecycle_management: 'Assets lifecycle management.',
  vulnerabilities_scanning: 'Vulnerabilities scanning.',
  seamless_integration: 'Seamless integration.',
  pricing: 'Pricing',
  setup_your_services: 'Setup your services',
  billed: 'Billed',
  'number-of-users': 'Select number of users',
  'support-type': "What's the type of user support required?",
  support: 'Specify the support hours',
  'respond-time': 'Any incident should be responded to within',
  onsite: 'Onsite',
  both: 'Both',
  asap: 'ASAP',
  endToEndUser: 'End-user Support',
  supportManagement: 'Business Apps Support & Management',
  itAssets: 'IT Assets Support & Management',
  our_prices: 'Our prices',
  yes_hide_it: 'Yes, hide it',
  no_keep_it: 'No, keep it',
  redirect_to_payment: 'Redirect to payment',
  pay_now: 'Pay now',
  contact_form: 'Contact Form',
  request_a_quote: 'Request a quote',
  phone_number_is_not_valid: 'Phone number is not valid',
  not_convinced_at_this_time: 'Not convinced at this time?',
  contact_our_specialist_or_request_quote:
    'Set up a call with our specialist or request a quote.',
  purchase_your_products: 'Purchase your products',
  proceed_to_payment: 'proceed to payment',
  payment: 'Payment',
  basic_plan: 'Basic Plan',
  todays_charge: "Today's charge",
  before_taxes: 'before taxes',
  recurring: 'Recurring',
  charge: 'charge',
  next_charge_date: 'Next charge date',
  the_payment: 'The payment',
  buy_now: 'Buy now',
  processing_your_subscription_creation:
    'Processing your subscription creation …',
  purchase: 'purchase',
  finalise_your_purchase: 'Customize your Products and Services',
  basic: 'Basic',
  total_price_per_month: 'Total price per month',
  total_price_per_year: 'Total price per year',
  support_services: ' IT Support Services',
  it_support_automation: 'IT support automation',
  it_asset_automation: 'IT asset automation',
  saas_automation: 'SaaS automation',
  module: 'Module',
  addons: 'Add-ons',
  shopping_cart: 'Shopping Cart',
  saas_dashboard_info_start:
    'Please enter your information to get start for seeing Dashboard of SaaS Management',
  saas_dashboard_description_info_start:
    'Please enter your information to see the Dashboard with the SaaS Analytics',
  setup_guide: 'Setup Guide',
  set_up: 'Setup now',
  channel_exist_info:
    'channel already exists. Please try another channel name.',
  landing_page_content:
    'Manage your complete tech stack in a unified dashboard.',
  log_in: 'Log in',
  week: 'Week',
  week_abbreviation: 'wk',
  last_month: 'Last month',
  overview_yearly: 'Overview Yearly',
  by_state_change: 'By state change',
  closed_by_priority: 'Closed by priority',
  created_by_priority: 'Created by priority',
  resolved_by_priority: 'Resolved by priority',
  earning: 'Earning',
  statisticsCard: 'Statistics Card',
  total: 'Total',
  growth: 'growth',
  current_month: 'current month',
  granularity: 'Granularity',
  requiredAssignmentGroup:
    'Required assignment group if you selected the supplier product',
  client_id: 'Client id',
  client_secret: 'Client secret',
  tenant_id: 'Tenant id',
  identity_provider: 'Identity Provider',
  user_details: 'User details',
  manage_applications_access: 'Manage applications access',
  generate_password: 'Generate password',
  remove_assigned_user:
    'You will remove the user from the application. Are you sure?',
  remove_assigned_group:
    'You will remove the group from the application. Are you sure?',
  waiting_for_processing_to_complete: 'Waiting for processing to complete',
  processing_not_completed: 'Processing not completed',
  repeat_refreshing_table: 'Repeat refreshing table',
  server_error: 'Server Error',
  applied_filters: 'Applied filters',
  apps_added_per_month: 'Apps added per month',
  app_user_ratios: 'App User Ratios',
  users_per_app: 'Users per app',
  average_resolution_time_hrs: 'Average Resolution Time hrs',
  day_s: 'day(s)',
  street_not_found: 'Street not found',
  name: 'Name',
  azure_id: 'Azure id',
  customer: 'Customer',
  accounts: 'Accounts',
  account: 'Account',
  bannerConfirmation: 'You will not see the banner anymore. Are you sure?',
  products: 'Support rates',
  product: 'Product',
  customer_products: 'Customer Products',
  create_customer_product: 'Create new Customer Product',
  internal_products: 'Internal Products',
  contracts: 'Contracts',
  incidents: 'Incidents',
  incidents_up: 'Incidents',
  requests_up: 'Requests',
  incident: 'Incident',
  create_incident: 'Create new Incident',
  opportunities: 'Opportunities',
  opportunity: 'Opportunity',
  create_opportunity: 'Create new Opportunity',
  locations: 'Locations',
  location: 'Location',
  latLongChanged: 'Latitude or Longitude changed manually',
  legal_entity: 'Legal Entity',
  contacts: 'Contacts',
  contact: 'Contact',
  create_contact: 'Create new Contact',
  source: 'Source',
  leads: 'Leads',
  lead: 'Lead',
  create_lead: 'Create new Lead',
  menu_skill_matrix: 'SKILL MATRIX',
  events: 'Events',
  event: 'Event',
  create_event: 'Create new Event',
  problems: 'Problems',
  problem: 'Problem',
  url: 'URL',
  grpc_metadata_space: 'GRPC-Metadata-Space',
  time: 'Time',
  something_went_wrong: 'Something went wrong',
  go_back: 'Go back',
  oops: 'Oops...!',
  error_403: 'Error 403',
  error_404: 'Error 404',
  page_not_found: 'Page not found',
  unable_to_get_data: 'Unable to get data',
  unable_to_submit_data: 'Unable to submit data',
  unable_to_delete_data: 'Unable to delete data',
  you_dont_have_permission_to_access_this_page:
    'You do not have permission to access this page',
  no_permissions:
    'Missing permissions, please contact your channel Admin for help',
  no_permissions_to_edit:
    'You do not have permission to edit products, please contact your channel Admin for help',
  mailboxes: 'Mailboxes',
  resellers: 'Resellers',
  reseller: 'Reseller',
  suppliers: 'Suppliers',
  supplier: 'Supplier',
  clients: 'Clients',
  partners: 'Partners',
  create_new_partner: 'Create New Partner',
  all: 'All',
  edit: 'Edit',
  back: 'Back',
  save: 'Save',
  save_skip: 'Save/Skip',
  confirm_assign_app: 'Proceed to assign app',
  save_as: 'Save as',
  cancel: 'Cancel',
  new: 'New',
  convert_to_opportunity: 'Convert to opportunity',
  kill: 'Kill',
  link: 'Link',
  start: 'Start',
  add_filter: 'Add Filter',
  select_filter: 'Select filter',
  available_columns: 'Available columns',
  selected_columns: 'Selected columns',
  billing_info: 'Billing Info',
  deleted_at: 'Deleted at',
  updated_by: 'Updated by',
  deleted_by: 'Deleted by',
  due_days: 'Due days',
  payment_terms: 'Payment terms',
  options: 'Options',
  customize_table: 'Customize table',
  default_filters: 'Clear all filters',
  product_subcategory_id: 'Product subcategory',
  assignment_group_pm_id: 'Assignment group PM',
  primary_contact_id: 'Primary contact',
  sale_stage: 'Sale stage',
  is_draft: 'Is draft',
  resolution_time: 'Resolution time',
  participants: 'Participants',
  parent_product_category: 'Parent product category',
  you_dont_have_right_to_access_settings:
    "You don't have a right to access settings",
  you_dont_have_right_to_access_itsm: "You don't have a right to access ITSM",
  invitation_is_needed_to_access_cmdb: 'Invitation is needed to access CMDB',
  default_account: 'Default account',
  select_account: 'Select your account',
  id: 'ID',
  type: 'Type',
  no_cmdb_invite: 'Exclude CMDB Invite',
  no_saas_invite: 'Exclude SAAS Invite',
  parent_account: 'Parent Account',
  vat_number: 'VAT number',
  currency: 'Currency',
  creditHold: 'Credit hold',
  tax_group: 'Tax group',
  creditLimit: 'Credit limit',
  ledger: 'Ledger',
  method: 'Method',
  freightTerms: 'Freight terms',
  employees: 'Employees',
  employees_number: 'Employees Number',
  note: 'Note',
  website: 'Website',
  january: 'Jan',
  february: 'Feb',
  march: 'Mar',
  april: 'Apr',
  may: 'May',
  june: 'Jun',
  july: 'Jul',
  august: 'Aug',
  september: 'Sep',
  october: 'Oct',
  november: 'Nov',
  december: 'Dec',
  location_type: 'Location type',
  legal_entities: 'Legal Entities',
  create_legal_entity: 'Create New Legal Entity',
  registration_number: 'Registration number',
  please_add_account_before_save: 'Please add account before save',
  customer_product: 'Customer Product',
  internal_product: 'Internal Product',
  create_internal_product: 'Create new Internal Product',
  supplierProduct: 'Supplier Product',
  description: 'Description',
  startDate: 'Start date',
  endDate: 'End date',
  automaticRenewal: 'Automatic renewal',
  unitGroup: 'Unit group',
  defaultUnit: 'Default unit',
  coverage: 'Coverage',
  category: 'Category',
  subcategory: 'Subcategory',
  family: 'Family',
  price: 'Price',
  status: 'Status',
  discount: 'Discount',
  billingPeriod: 'Billing period',
  hr: 'Human Resources',
  hourType: 'Hour type',
  configuration: 'Configuration',
  price_type: 'Price type',
  period: 'Period',
  limit: 'Limit',
  code: 'Code',
  dispatch: 'Dispatch',
  slaType: 'Sla type',
  resolution_time_evaluation: 'Resolution time evaluation',
  engineer_onsite_time: 'Engineer on-site time',
  at_assign: 'At assign',
  at_create: 'At create',
  product_type: 'Product type',
  active_from: 'Active from',
  more: 'More',
  less: 'Less',
  copy_product: 'Copy product',
  renewal_remind_date: 'Renewal remind date',
  signed_date: 'Signed Date',
  signed: 'Signed',
  canceled: 'Canceled',
  batch_input: 'Batch Input',
  batch_input_of_weekdays: 'Batch input of weekdays',
  select_weeks: 'Select Weeks',
  please_select_in_order: 'Please select in order',
  per_ticket: 'Per ticket',
  custom: 'Custom',
  fix_price: 'Fix price',
  hardware: 'Hardware',
  software: 'Software',
  service: 'Service',
  services: 'Service contract',
  service_hours: 'Service hours',
  business_hours: 'Business hours',
  out_of_hours: 'Out of hours',
  weekend_hours: 'Weekend hours',
  public_holidays: 'Public holidays',
  nonstop: '24/7',
  holiday: 'Holiday',
  holidays: 'Holidays',
  add_holiday: 'Add Holiday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  location_group: 'Location group',
  unable_to_edit_supplier_standard_pro:
    'Unable to edit this Supplier Standard Product (except price list) due:',
  unable_to_edit_internal_pro:
    'Unable to edit this Internal Product (except price list) due:',
  unable_to_edit_internal_pro_since_in_customer_pro:
    'Internal Product has references to Customer Product.',
  unable_to_edit_internal_product_since_prices_been_assigned:
    'Internal Product has one or more option in Price List',
  unable_to_edit_supplier_standard_product_since_prices_been_assigned:
    'Supplier Standard Product has one or more option in Price List',
  unable_to_edit_product_family: 'Unable to edit this Product Family due:',
  unable_to_edit_product_family_since_internal_or_supplier:
    'Product Family has reference to Internal Product or Supplier Standard Product',
  unable_to_edit_product_category:
    'Unable to edit this Product Category (except Category Items) due:',
  unable_to_edit_product_category_since_internal_or_supplier:
    'Product Category has references to Internal Product or Supplier Standard Product',
  sw: 'SW',
  hw: 'HW',
  svc: 'SVC',
  standard: 'Standard',
  detail_pricing_policies: 'Detail of Pricing policies',
  check_details: 'Check details',
  price_list: 'Price list',
  option_hours: 'hours',
  days: 'days',
  duplicate: 'Duplicate',
  min: 'min',
  mins: 'mins',
  response_time_period: 'Response time period',
  weekday_pricing_policies: 'Weekday pricing policies',
  step: 'Step',
  step_1: 'Step 1',
  step_2: 'Step 2',
  step_3: 'Step 3',
  step_4: 'Step 4',
  next: 'Next',
  save_as_draft: 'Save as draft',
  status_is_draft: 'Status: Draft',
  unable_to_save: 'Unable to save',
  please_go_back_to_step1_and_fill_out_all_required_fields:
    'Please go back to step 1 and fill out all required fields.',
  will_be_changed_based_on_chosen_internal_product:
    ' will be changed based on chosen Internal product.',
  successfully_filled: 'Successfully filled',
  fields_are_filled_from_customer_product_template_data:
    'Fields are filled from customer product template data.',
  unable_to_create_new_customer_product_template_since:
    'Unable to create new Customer Product Template since customer product template with this sla type is already created',
  holiday_cannot_be_empty_field: "Holiday can't be an empty field.",
  location_cannot_be_empty_field: "Location can't be an empty field.",
  thresholds: 'thresholds',
  input_number: 'Input number',
  multiply_prices: 'Multiply prices',
  multiply_times: 'Multiply times',
  multiply: 'Multiply',
  times: 'times',
  apply: 'Apply',
  import_price_list: 'Import price list',
  price_list_was_successfully_changed: 'Price list was successfully changed.',
  price_list_was_successfully_imported: 'Price list was successfully imported.',
  failed_to_import_price_list: 'Failed to import price list.',
  import_internal_product: 'Import internal product',
  import: 'Import',
  internal_product_was_successfully_imported:
    'Internal product was successfully imported.',
  failed_to_import_internal_product: 'Failed to import internal product',
  failed_to_export: 'Failed to export',
  internalProductPrices: 'internal product prices',
  select_supplier: 'Select supplier',
  supplier_standard_products: 'Supplier Standard Products',
  supplier_standard_product: 'Supplier Standard Product',
  create_supplier_standard_product: 'Create new Supplier Standard Product',
  supplier_standard_product_was_successfully_imported:
    'Supplier standard product was successfully imported.',
  failed_to_import_supplier_standard_product:
    'Failed to import supplier standard product.',
  import_supplier_standard_product: 'Import supplier standard product',
  import_product: 'Import Product',
  save_attachment: 'Save attachment',
  replace_attachment: 'Replace attachment',
  attachment: 'Attachment',
  customer_stakeholders: 'Customer Stakeholders',
  customer_stakeholder: 'Customer Stakeholder',
  primary_stakeholders: 'Primary Stakeholders',
  primary_stakeholder: 'Primary Stakeholder',
  perception_analysis: 'Perception Analysis',
  proposal_pricequote: 'Proposal Price quote',
  negotiation: 'Negotiation',
  connect_products: 'Connect Products',
  immediate: 'Immediate',
  this_quarter: 'This Quarter',
  next_quarter: 'Next Quarter',
  this_year: 'This Year',
  unknown_framerate: 'Unknown Framerate',
  select_stakeholders_to_check:
    'Please select stakeholders before checking the box.',
  qualify: 'Qualify',
  develop: 'Develop',
  propose: 'Propose',
  copy_opportunity_info: "The opportunity's link will be copied",
  copy_success_opportunity: "The opportunity's link was copied",
  opportunity_document: 'Opportunity document',
  agreement_management: 'Agreement Management',
  project_management: 'Project Management',
  contract_terms: 'Contract terms',
  termination_notice: 'Termination notice',
  auto_renew: 'Auto-renew',
  number_of_months: 'Number of months',
  section: 'Section',
  print: 'Print',
  will_be_autogenerated: ' will be autogenerated.',
  months: 'months',
  visibility: 'Visibility',
  public: 'Public',
  comment_is_unavaiable_since_no_participants_in_opportunity:
    'Comment is unavailable since there is no participants in this opportunity.',
  please_add_at_least_one_contact: 'Please add at least one contact',
  min_tickets_per_period: 'Min tickets per period',
  unable_to_change_status: 'Unable to change status',
  expected_date_closed_is_before_current_date:
    'Expected date closed is before current date.',
  status_of_opportunity_was_changed_successfully:
    'Status of opportunity was changed successfully',
  customer_product_templates: 'Customer Product Templates',
  customer_product_template: 'Customer Product Template',
  convert: 'convert',
  ad_hoc_channel: 'Ad hoc channel',
  opp_create_channel_successed:
    "Successfully created. Setting up channel will take couple minutes. You will get email notification once it's ready.",
  opp_create_channel_failed: 'Creating channel was failed.',
  email_will_be_filled_according_to_chosen_contact:
    'Email will be filled according to chosen contact',
  ad_hoc_channel_has_reference_from_this_opportunity:
    'Ad hoc channel has reference from this opportunity',
  organization_invite: 'Organization invite',
  remove_this: 'Remove this',
  chanel_create_msg:
    'We are setting up a new channel. The process will take a couple of minutes. You will get an email notification once the channel is ready',
  we_are_on_it: 'We are working on it',
  add_existing: 'Add existing',
  by_creating_new: 'by creating new',
  pricing_policy_from: 'Pricing Policy from',
  pricing_policy_to: 'Pricing Policy to',
  travel_fix_rate: 'Travel fix rate',
  travel_hour_rate: 'Travel hour rate',
  sla_thresholds: 'Sla thresholds',
  max_response_time_business_days: 'Max response time business days',
  max_resolution_time_business_days: 'Max resolution time business days',
  safe_time_interval: 'Safe time interval',
  bank_holidays: 'Bank holidays',
  weekdays: 'Weekdays',
  pricing_policy: 'Pricing policy',
  holiday_pricing_policies: 'Holiday pricing policies',
  weekdays_pricing_policies: 'Weekdays pricing policies',
  sla: 'SLA',
  breach_sla: 'Breach SLA',
  add_holiday_pricing_policy: 'Add holiday pricing policy',
  add_weekday_pricing_policy: 'Add weekday pricing policy',
  response_time: 'Response time',
  max_response_time: 'Max response time',
  max_resolution_time: 'Max resolution time',
  rate: 'Rate',
  sla_type: 'SLA type',
  pricing_unit: 'Pricing unit',
  unit: 'Unit',
  inactive_timeout: 'Inactive timeout',
  pricing_type: 'Pricing type',
  resolution_time_eval: 'Resolution time eval',
  add_sla_threshold: 'Add sla threshold',
  add_threshold: 'Add threshold',
  support_from: 'Support from',
  support_to: 'Support to',
  price_multiplier: 'Price multiplier',
  credit_percentage: 'Credit percentage',
  support_from_support_to_should_be_different_time:
    'Support from and Support to should be different time',
  sec: 'sec',
  p1: 'P1',
  p2: 'P2',
  p3: 'P3',
  p4: 'P4',
  p5: 'P5',
  p6: 'P6',
  p7: 'P7',
  p8: 'P8',
  p9: 'P9',
  p10: 'P10',
  primary_unit_rate: 'Primary unit rate',
  primary_units_number: 'Primary units number',
  additional_unit_rate: 'Additional unit rate',
  primaryHoursNumber: 'Primary unit number',
  additionalHourRate: 'Additional units rate',
  policy: 'Policy',
  price_lists: 'Price Lists',
  priceList: 'Price List',
  add_opportunity_by_creating_new: 'Add Opportunity by creating new',
  plase_add_opportunity_before_saving: 'Please add opportunity before saving',
  hourly: 'Hourly',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
  annually: 'Annually',
  per_visit: 'Per visit',
  product_family: 'Product family',
  resolution: 'Resolution',
  comments: 'Comments',
  comment: 'Comment',
  resources: 'Resources',
  number: 'Number',
  opened: 'Opened',
  queue: 'Queue',
  closed: 'Closed',
  caller: 'Caller',
  urgency: 'Urgency',
  watch_list: 'Watch list',
  state: 'State',
  assigned_to: 'Assigned to',
  new_comment: 'New comment',
  post: 'Post',
  wholeDate: '{{date, dddd[,] MMM Do YYYY}}',
  dateKey: '{{date, LL}}',
  first_hour_rate: 'First Hour Rate',
  travel_cost: 'Travel cost',
  offer_cost: 'Offer cost',
  'opened-by-me': 'Opened by me',
  parent: 'Parent',
  none: 'None',
  logs: 'logs',
  time_report: 'Time reports',
  break_time: 'Break time',
  work_time: 'Work time',
  check_in: 'Check IN',
  check_out: 'Check OUT',
  check_in_time: 'Check IN time',
  check_out_time: 'Check OUT time',
  edit_check_out: 'Edit check OUT',
  check_in_location: 'Check IN location',
  check_out_location: 'Check OUT location',
  customer_location: 'Customer location',
  incentive: 'Incentive',
  incentive_paid_at: 'Incentive paid at',
  customer_no_location: "Customer didn't chose location yet.",
  assigned_group: 'Assigned group',
  by_status: 'By status',
  'assigned-to-me': 'Assigned to me',
  'not-assigned-to-me': 'Not assigned to me',
  'assigned-to-my-groups': 'Assigned to my groups',
  incident_name: 'Incident name',
  incident_status: 'Incident status',
  copy_info: 'The link will be copied',
  copy_success_info: 'The link was copied',
  sla_status: 'SLA status',
  elapsed_time: 'Elapsed time',
  remaining_time: 'Remaining time',
  overview: 'Overview',
  create_delivery: 'Create delivery',
  rejected_reason: 'Rejected reason',
  rejected_reasons_req: 'Choose rejected reason',
  resolution_notes_req: 'Resolution notes are required',
  cancel_incident: 'Cancel incident',
  file_name: 'File name',
  file_type: 'File type',
  attachments: 'Attachments',
  upload_attachments: 'Upload attachments',
  upload_attachment: 'Upload attachment',
  attach_existing_files: 'Attach existing files',
  delete_attachment_question: 'Do you want to delete this attachment?',
  save_attachments: 'Save attachments',
  resolve: 'Resolve',
  add: 'Add',
  'closed-or-cancelled': 'Closed or cancelled',
  scheduled: 'Scheduled',
  resolution_code: 'Resolution code',
  resolution_code_req: 'Resolution code is required',
  uploading_files_warning: 'Cannot upload files larger than 4 MB',
  stop_editing_question: 'Are you sure you want to leave?',
  timelog_must_be_closed: 'Time log must be closed',
  cancellationNotes: 'Cancellation notes',
  cancellation_note_req: 'Cancellation note is required',
  cancellation_note: 'Cancellation note',
  customer_product_must_be_selected: '"Customer product" must be selected',
  the_timelog_must_be_closed: 'The time log must be closed',
  export: 'Export',
  incident_tasks: 'Incident tasks',
  incident_task: 'Incident task',
  incident_task_name: 'Incident task name',
  closed_skipped: 'Closed skipped',
  ok: 'OK',
  to_be_escalated: 'To be escalated',
  escalated: 'Escalated',
  breached: 'Breached',
  go_to_parent: 'Go to parent',
  solved_work_around: 'Solved (Work around)',
  solved_permanently: 'Solved (Permanently)',
  solved_remotely_work_around: 'Solved remotely (Work around)',
  solved_remotely_permanently: 'Solved remotely (Permanently)',
  not_solved_not_rep: 'Not Solved (Not reproducible)',
  not_solved_too_costly: 'Not Solved (Too costly)',
  closed_by_caller: 'Closed/Resolved by Caller',
  awaiting_caller: 'Awaiting Caller',
  awaiting_change: 'Awaiting Change',
  awaiting_problem: 'Awaiting Problem',
  awaiting_vendor: 'Awaiting Vendor',
  created_ad: 'Created at',
  updated_at: 'Updated at',
  resolved_at: 'Resolved at',
  resolved_by: 'Resolved by',
  closed_at: 'Closed at',
  closure_notes: 'Closure Notes',
  resolution_notes: 'Resolution Notes',
  contact_type: 'Contact Type',
  expected_start: 'ETA',
  expected_end: 'ETD',
  impact: 'Impact',
  external_id: 'External id',
  caused_by_change_id: 'Caused by change id',
  on_hold_reason: 'On Hold reason',
  pre_on_hold: 'Pre on hold',
  problem_id: 'Problem id',
  change_request_id: 'Change request id',
  severity: 'Severity',
  active: 'Active',
  activity_due: 'Activity due',
  last_reopened_at: 'Last reopened at',
  last_reopened_by: 'Last reopened by',
  reopen_count: 'Reopen count',
  reassignment_count: 'Reassignment count',
  response_time_due: 'Response time due',
  response_time_spent: 'Response time spent',
  resolution_time_due: 'Resolution time due',
  resolution_time_spent: 'Resolution time spent',
  work_note: 'Work note',
  work_notes: 'Work notes',
  new_work_note: 'New work note',
  read_by: 'Read by',
  sla_update_at: 'SLA updated at',
  allow_geolocation:
    'You need to allow geolocation for the timelogs to work right way!',
  unsupported_geolocation: 'Geolocation is not supported in this browser!',
  lead_status_log: 'Lead status log',
  incident_status_log: 'Incident status log',
  incident_additional_details: 'Incident additional details',
  customer_service: 'Customer service',
  supplier_service: 'Supplier service',
  assignee_state: 'Assignee state',
  assignee_state_0: 'Unassigned',
  expected_start_req: 'ETA is required',
  work_note_req: 'Work note is required',
  comment_req: 'Comment is required',
  cancel_incident_question: 'Do you want to cancel this incident?',
  cancel_k_request_question: 'Do you want to cancel this k request?',
  email: 'Email',
  portal: 'Portal',
  phone: 'Phone',
  email_worker: 'Email worker',
  telephone: 'Telephone',
  is_cancelled: 'Is cancelled',
  visit_summary: 'Visit summary',
  remote: 'Remote',
  check_in_time_generation: 'Check IN Time was generated by Checking IN.',
  check_out_time_generation:
    'Check OUT Time will be generated by Checking OUT, or you can enable the check out field by Edit Check OUT button.',
  ticket_have_to_be_in_progress: 'The ticket must be in "in progress" state',
  no_checkout_yet: 'Not Check OUTed yet',
  select_checkout: 'Select Check OUT Time',
  end: 'End',
  SVR: 'SVR',
  external_ticket_number: 'External ticket number',
  time_logs_resolve: 'Time logs resolve',
  FE_name: 'FE name',
  ticket_number: 'Ticket number',
  departure_date: 'Departure date',
  arrival_date: 'Arrival date',
  requests: 'Requests',
  request: 'Request',
  request_item: 'Request Item',
  request_items: 'Request Items',
  request_task: 'Request Task',
  request_tasks: 'Request Tasks',
  request_type: 'Request type',
  request_type_required: 'Request type required',
  initiated_from: 'Initiated from',
  chargeable: 'Chargeable',
  requested_due_time: 'Requested due date',
  scheduled_delivery_time: 'Scheduled delivery date',
  estimated_delivery_time: 'Estimated delivery date',
  estimated_start_time: 'Estimated start date',
  estimated_end_time: 'Estimated end date',
  actual_work_start_time: 'Actual work start time',
  actual_work_end_time: 'Actual work end time',
  opened_at: 'Opened at',
  opened_by: 'Opened by',
  item: 'Item',
  request_id: 'Request id',
  requested_for: 'Requested for',
  quantity: 'Quantity',
  approvers: 'Approvers',
  approvement_policy: 'Approvement policy',
  approved_by_one: 'Approved by at least one',
  approved_by_all: 'Approved by all',
  closed_completed: 'Closed Complete',
  closed_incomplete: 'Closed Incomplete',
  request_stateid_0: 'Draft',
  request_name: 'Request name',
  requested_due_time_req: 'Requested due date is required',
  cancel_request: 'Cancel request',
  open_request: 'Open request',
  cancel_request_item: 'Cancel request item',
  cancel_task: 'Cancel task',
  cancel_task_question: 'Do you want to cancel this request task?',
  cancel_request_question: 'Do you want to cancel this request?',
  cancel_request_item_question: 'Do you want to cancel this request item?',
  accept_approvers: 'accept approvers',
  awaiting_approval: 'Awaiting Approval',
  time_session_stateid_0: 'Travel',
  time_session_stateid_1: 'Work',
  time_session_stateid_2: 'Break',
  time_session_stateid_3: 'Travel back',
  add_timespan: 'Add timespan',
  travel_back: 'travel back',
  travel: 'travel',
  work: 'work',
  date_range: 'Date range',
  start_time: 'Start time',
  end_time: 'End time',
  arrive_site: 'Arrive site',
  left_site: 'Left site',
  date: 'Date',
  user: 'User',
  travel_hours: 'Travel hours',
  onsite_hours: 'Ontime hours',
  time_log: 'Time log',
  time_logs: 'Time logs',
  expenses: 'Expenses',
  expense: 'Expense',
  engineer_location: 'Engineer location',
  location_currently_unavailable: 'Location is currently unavailable',
  priority: 'Priority',
  forecast: 'Forecast',
  owner: 'Owner',
  nextAction: 'Next action',
  customerNeeds: 'Customer needs',
  decisionMaker: 'Decision maker',
  closeDate: 'Close date',
  closeQuarter: 'Close quarter',
  value: 'Value',
  probability: 'Probability',
  created_by: 'Created by',
  modifiedAt: 'Modified at',
  modifiedBy: 'Modified by',
  salesTeam: 'Sales team',
  long_description: 'Message',
  revenueLine: 'Revenue line',
  nonRevenueLine: 'Non revenue line',
  types: 'Types',
  updates: 'Updates',
  additional_cost: 'Additional cost',
  add_cost: 'Add cost',
  save_failed: 'Save failed',
  please_save_customer_products_table:
    'Please save editing data in customer products table',
  there_is_empty_table_in_product_tab: 'There is an empty table in product tab',
  street: 'Street',
  city: 'City',
  zip: 'Zip',
  latitude: 'Latitude',
  longitude: 'Longitude',
  country: 'Country',
  operation: 'Operation',
  state_province: 'State/province',
  new_location: 'New Location',
  city_code: 'City code',
  to_select_state_please_choose_country_beforehand:
    'To select state, please choose country beforehand',
  to_select_city_please_choose_state_beforehand:
    'To select city, please choose state beforehand',
  unabled_to_find_selected_place_in_map:
    'Unabled to find selected place in map',
  unable_to_edit_location_since_in_customer_pro:
    'Unable to edit this Location since it has references to Customer Product.',
  sales: 'Sales',
  ITSM: 'ITSM',
  serviceManagement: 'Service Management',
  marketing: 'Marketing',
  finance_menu: 'Finance',
  humanResources: 'Human Resources',
  settings: 'Settings',
  TimeZone: 'TimeZone',
  reports: 'Reports',
  converted: 'Converted',
  billing: 'Billing reports',
  month: 'Month',
  task_number: 'Task number',
  task_type: 'Task type',
  contract: 'Contract',
  create_contract: 'Create new Contract',
  contract_dept: 'Contract dept',
  response_sla_expiry: 'Response SLA expiry',
  resolution_sla_expiry: 'Fix SLA expiry',
  work_start: 'Work start',
  work_finish: 'Work finish',
  made_sla: 'Made SLA',
  invoices: 'Invoices',
  purchaseOrder: 'Purchase order',
  quotes: 'Quotes',
  from: 'From',
  to: 'To',
  amount_in_eur: 'Amount €',
  timesheet: 'Time Sheet',
  candidates: 'Candidates',
  interviews: 'Interviews',
  holidaySchedule: 'Holiday schedule',
  campaign: 'Campaign',
  campaigns: 'Campaigns',
  dashboard: 'Dashboard',
  parentLead: 'Parent lead',
  parentCampaign: 'Parent campaign',
  mailbox: 'Mailbox',
  sendNotifications: 'Enable notifications',
  sendCommentNotifications: 'Comment notifications',
  addMailbox: 'Add mailbox',
  badRequest: 'Bad request',
  unauthorized:
    'Unauthorized request. After close this message, you will be redirect to login page.',
  error: 'Error',
  noPermission: "You don't have rights. Please, contact your manager.",
  openedTimelogWarning:
    'You cannot resolve the incident when the timelog is still opened.',
  endCustomer: 'End customer',
  customer_name: 'Customer name',
  campaign_name: 'Campaign name',
  campaign_type: 'Campaign type',
  contact_person: 'Contact person',
  job_title: 'Job title',
  lead_source: 'Lead source',
  fax: 'Fax',
  rank: 'Rank',
  communication_method: 'Communication method',
  source_code: 'Source code',
  stage: 'Stage',
  remove_lead_product: 'Remove Proposed solution',
  proposed_solutions: 'Proposed solutions',
  budget: 'Budget',
  product_category: 'Product category',
  number_of_employees: 'Number of employees',
  add_proposed_solution: 'Add proposed solution',
  copy_lead_info: "The lead's link will be copied",
  copy_lead_success: "The lead's link was copied",
  industry: 'Industry',
  contact_saved: 'The contact was successfully saved.',
  account_saved: 'The account was successfully saved.',
  productCategory: 'Product category',
  product_category_id: 'Product category',
  account_changed_due_to_reference:
    'Account has changed due to the reference of the chosen data.',
  contact_form_lost:
    'If you select from existing contacts, the information of contact form will be lost.',
  contacts_in_table_lost:
    'If you fill out contact form, the information of contacts in table form will be lost.',
  account_form_lost:
    'If you select from existing account, the information of account form will be lost.',
  account_field_lost:
    'If you fill out account form, the chosen account in account field will be lost.',
  unable_convert_to_opportunity: 'Unable to convert to opportunity',
  account_form_should_be_saved_before_convert_but_different_contacts:
    'Account form should be saved before convert but it has different references from contacts.',
  primary_contact_should_be_saved_before_convert:
    'Primary contact should be saved before convert.',
  account_form_should_be_saved_before_convert_but_different_account:
    'Account form should be saved before convert but contact in table already has different references of account.',
  contacts_in_table_already_has_different_references_of_account:
    'Contacts in table already has different references of account',
  assign_to_me: 'Assign to me',
  failed_to_add: 'Failed to add',
  successfully_added: 'Successfully added',
  unable_to_add_contact_reason:
    'Unable to add contacts in table due to different references of accounts. Please delete irrelavarent contacts first.',
  region: 'Region',
  planned_quarter: 'Planned quarter',
  number_of_targets: 'Number of targets',
  targetted_industries: 'Targetted industries',
  summary: 'Summary',
  offer: 'Offer',
  totalCost: 'Total cost',
  oppTotal: 'Opportunity total',
  budget_amount: 'Budget amount',
  campaignExpense: 'Campaign expense',
  addCampaignExpense: 'Add campaign expense',
  first_name: 'First name',
  group: 'Group',
  mobilePhone: 'Mobile phone',
  timezone: 'Timezone',
  address: 'Address',
  notes: 'Notes',
  assignment_group_type: 'Assignment group type',
  assignment_group_type_added: 'Assignment group type is added',
  success: 'Success',
  company_types_req: 'Company types is required',
  description_of_type: 'Description of type',
  search_for_users: 'Search for users to add to this',
  product_subcategory: 'Product Subcategory',
  currencies: 'Currencies',
  symbol: 'Symbol',
  rate_to_eur: 'Rate to Euro',
  fax_phone: 'Fax Phone',
  external: 'External',
  industries: 'Industries',
  tag: 'Tag',
  sub_location: 'Sub Location',
  parent_location: 'Parent Location',
  country_code: 'Country code',
  user_was_successfully_created: 'User was successfully created',
  resource: 'Resource',
  scope: 'Scope',
  my_roles: 'My roles',
  permissions: 'Permissions',
  assign_role: 'Assign role',
  role: 'Role',
  defaultNamespace: 'default namespace',
  assign_role_to_user: 'Assign role to user',
  accessible_menu: 'Accessible Menu',
  assignmentGroups: 'Assignment Groups',
  inboundLeads: 'Inbound Leads',
  myProfile: 'My Profile',
  productFamilies: 'Product Families',
  productCategories: 'Product Categories',
  assets: 'Assets',
  apps: 'Apps',
  query_list: 'Query list',
  invoke_list: 'Invoke list',
  service_agreements: 'Service agreements',
  model: 'Model',
  sub_model: 'Sub-model',
  sub_id: 'Sub ID',
  invited_by: 'Invited by',
  identity_account: 'Identity account',
  redirect_url: 'Redirect URL',
  sign_in_path: 'Sign in path',
  name_space: 'Namespace',
  features: 'Features',
  feature: 'Feature',
  bugs: 'Bugs',
  bug: 'Bug',
  categoryItems: 'Category Items',
  categoryItem: 'Category Item',
  please_input_at_least_one_permission: 'Please input at least one permission',
  at_least_one_product_is_required_to_export:
    'At least one product is required to export',
  itsm_settings: 'ITSM Settings',
  user_name: 'User name',
  user_email: 'User email',
  identity_settings: 'All system users',
  users_list: 'My users',
  default_org_channel: 'Default organization and channel',
  categories: 'Categories',
  announcements: 'Announcements',
  announcement: 'Announcement',
  organization: 'Organization',
  email_notifications: 'Email Notifications',
  notification: 'Notification',
  notifications: 'Notifications',
  extra_notifications: 'Extra notifications',
  external_emails_req: 'External emails are required',
  external_emails: 'External emails',
  validate_external_email: 'Email is not valid',
  email_notifications_description:
    'Use to enable or disable email notifications',
  email_signature: 'Email signature',
  email_signature_req: 'Email signature required',
  documentation: 'Documentation',
  download: 'Download',
  confirm_reading_documents:
    'By accepting this message I confirm that I have read and acknowledged all documentation regarding Valmet PC installations and Field Engineer credentials provided by KompiTech',
  confirm_reading_docs:
    'You need to confirm that the documentation has been read',
  confirm_doc_has_beeen_read:
    'Please, confirm that the documentation has been read',
  upload_docs: 'Upload documents',
  read_on: 'Read on',
  confirm: 'Confirm',
  nda: 'NDA',
  mfa: 'MFA',
  contract_type: 'Contract type',
  hourly_wage: 'Hourly wage',
  fixed_travel_wage: 'Fixed travel wage',
  hourly_travel_wage: 'Hourly travel wage',
  travel_distance_unit_rate: 'Travel distance unit rate',
  travel_unit_rate: 'Distance unit rate',
  travel_distance_units: 'Travel distance units',
  employee: 'Employee',
  contractor: 'Contractor',
  nonEngineer: 'Non-engineer',
  user_group: 'User group',
  new_user_group: 'Create new user group',
  object_not_empty: 'Object cannot be empty!',
  action_not_empty: 'Action cannot be empty!',
  field_not_empty: 'Field cannot be empty!',
  name_not_empty: 'Name cannot be empty!',
  entity_type_not_empty: 'Entity type cannot be empty!',
  add_permission: 'Add permission',
  name_of_role: 'Name of new role',
  full_name: 'Full name',
  name_of_rej_reason: 'Name of rejected reason',
  create_rej_reason: 'Create new rejected reason',
  update_rej_reason: 'Update rejected reason',
  time_zone: 'Time zone',
  no_user_in_identity: 'No user in this identity!',
  assignment_group: 'Assignment group',
  new_assignment_group: 'Create new Assignment Group',
  user_groups: 'User groups',
  add_user_groups: 'Add User Groups',
  notification_emails: 'Notifications (emails)',
  notification_users: 'Notifications (users)',
  cancel_identity_creation_question:
    "if you don't create an identity you will not have an access to the chosen organization's information. Are you sure you want to cancel?",
  assignment_rules: 'Assignment rules',
  new_assignment_rule: 'New assignment rule',
  org_name: 'Organization name',
  org_display_name: 'Organization name',
  succesfully_joined: 'Joined successfully',
  failed_to_join: 'Failed to join',
  set_as_default: 'Set as default',
  travel_pricing_unit: 'Travel pricing unit',
  new_supplier_product: 'New supplier product',
  additional_pricing_unit: 'Additional Pricing Unit',
  contractors: 'Contractors',
  invitor_name: 'Invitator name',
  redirectUrl: 'Redirect url',
  signingPath: 'Signing path',
  invitations: 'Invitations',
  updating_channel_failed: 'Updating the channel has failed',
  accepted: 'Accepted',
  pending: 'Pending',
  org_invitation: 'Organization invitation',
  user_invitation: 'User invitation',
  delete_invitation_question: 'Do you want to delete this invitation?',
  docs_read: 'Documentation read on',
  registration_date: 'Registration date',
  not_read: 'Not read',
  invite: 'Invite',
  invitation: 'Invitation',
  failed_to_create_new_invitation: 'Failed to create new invitation',
  creating_user_was_failed: 'Creating user was failed',
  user_is_already_created: 'User is already created',
  invitation_was_successfully_sent: 'Invitation was successfully sent',
  unable_to_create_new_user: 'Unable to create new user',
  app_version: 'Aplication Version',
  permission: 'Permission',
  sc_settings: 'SC Settings',
  create: 'Create',
  read: 'Read',
  update: 'Update',
  create_comment: 'create comment',
  read_comment: 'read comment',
  create_worknote: 'create worknote',
  read_worknote: 'read worknote',
  assignable: 'assignable',
  update_overrides: 'update overrides',
  execute: 'execute',
  secret_key: 'Secret key',
  travel_incentive: 'Travel incentive',
  work_incentive: 'Work incentive',
  unavailable: 'Unavailable',
  secret_key_tooltip:
    'Input your secret key for case of restoring your fingerprint. Save this key!',
  confirm_secret_key: 'Confirm secret key',
  identity_not_found: 'Identity not found!',
  fill_identity_form:
    'Please fill the form below, to be able to continue on BLITS.',
  fill_form: 'Please fill the form below.',
  account_info: 'Account info',
  contact_info: 'Contact info',
  fill_contact_form: 'Fill contact form ',
  fill_account_form: 'Fill account form ',
  close_contact_form: 'Close contact form',
  close_account_form: 'Close account form',
  space_select: 'Select space in which you want to interact.',
  secret_key_validation: 'Secret keys must be exactly the same!',
  secret_key_empty: 'Secret keys cannot be empty!',
  save_changes: 'There are some unsaved changes, do you want to leave anyway?',
  yes: 'Yes',
  no: 'No',
  or: 'Or ',
  new_role: 'New role',
  setting_object: 'Object',
  is_enabled: 'Active',
  custom_report: 'Custom report',
  roles: 'Roles',
  surname: 'Surname',
  ticket_type: 'Ticket type',
  assignment_rule: 'Assignment rule',
  assignment_group_req: 'Assignment group is required',
  ticket_type_req: 'Ticket type is required',
  assignee: 'assignee',
  new_announcement: 'new announcement',
  active_to: 'Active to',
  body: 'Body',
  org_role: 'Organization role',
  provider: 'Provider',
  org_role_req: 'Organization role is required',
  active_from_req: 'Active from is required',
  active_to_req: 'Active to is required',
  inboundLead: 'Inbound Lead',
  sender: 'Email sender',
  origin: 'Origin',
  subject: 'Subject',
  content: 'Content',
  approved: 'Approved',
  approve: 'Approve',
  decline: 'Decline',
  change_status: 'Change status',
  date_created: 'Date created',
  quote: 'Quote',
  ticket: 'Ticket',
  createdAt: 'Created at',
  quoteDate: 'Quote date',
  expirationDate: 'Expiration date',
  customerCost: 'Customer cost',
  shortDescription: 'Short description',
  primaryQuote: 'Primary quote',
  monthlyRecurringRevenue: 'Monthly recurring revenue',
  NonRecurringRevenue: 'Non recurring revenue',
  skill_matrices: 'Skill matrices',
  skill_matrix: 'Skill matrix',
  skills: 'Skills',
  skill: 'Skill',
  training_status: 'Traning status',
  trained: 'Trained',
  required: 'Required',
  users: 'Users',
  members: 'Members',
  job: 'Job',
  training_request: 'Training request',
  training_requests: 'Training requests',
  requested_item: 'Requested item',
  requestTask: 'Request task',
  requestTasks: 'Request tasks',
  request_status: 'Request status',
  people: 'People',
  detail: 'Detail',
  detail_heading: 'details',
  blits: 'Blits',
  general: 'General',
  internal: 'Internal',
  sales_s: 'Sales',
  billing_information: 'Billing information',
  shipping_information: 'Shipping information',
  customer_information: 'Customer information',
  details: 'Details',
  delete: 'Delete',
  reset: 'Reset',
  close: 'Close',
  search: 'Search',
  filter: 'Filter',
  quick_filter: 'Quick filter',
  select: 'Select',
  select_as: 'Select as',
  select_from_existing: 'Select from existing',
  submit: 'Submit',
  unknown: 'Unknown',
  org_channel_selector_header:
    'Please select your default organization and channel',
  account_org_selector_header: 'Please select account and organization',
  org_selector_header: 'Please select organization',
  org_select: 'Select organization',
  kompitech_copyright: '2022 KompiTech',
  kompitech_intro_text: ' Quick, Automated, Secure, Reliable…',
  it_service_managment: 'IT Service management',
  login_title:
    'End-to-end IT Service Management platform powered by blockchain technology',
  login_subtitle1:
    '- Automate service capturing, agreements, transition, audits, reports, billing and invoicing',
  login_subtitle2:
    '- Increase faster tickets resolution, transparency and security',
  login_subtitle3: '- Real-time CMDB IT assets tracking',
  login_subtitle4: '- Seamless integration with your ITSM system',
  login_subtitle5: '- And many more ...',
  create_new: 'Create new ',
  login_with_kompi_acc: 'Log in with KompiTech account',
  login_failed_try_again: 'Log in has failed, try it again please!',
  success_login: 'Successful log in',
  loading: 'Loading',
  login: 'SSO',
  login_microsoft: 'Microsoft',
  login_google: 'Google',
  login_email: 'Email',
  sign_in_with_title: 'Or sign in with',
  logout: 'Log out',
  login_failed: 'Log in failed!',
  back_to_login: 'Go back to login page',
  created: 'Created',
  updated: 'Updated',
  deleted: 'Deleted',
  put_on_hold: 'Put on hold',
  space: 'Space',
  channel: 'Channel',
  channel_details: 'Channel details',
  select_channel: 'Select channel',
  continue: 'Continue',
  not_found: 'Not Found',
  user_not_found: 'User not found!',
  reopen: 'Reopen',
  invitation_has_expired: 'The invitation has expired.',
  contact_host: 'Please contact your host to create a new invitation for you.',
  contact_host_to_create_invitation:
    'Please contact your host to create an invitation for you.',
  org_id: 'Organization id',
  cancel_user_creation: "Cancel the user's creation",
  create_user_invite: 'Invite & create user',
  cancel_user_details: 'Cancel user details',
  this_invitation_has_been_already_accepted:
    'This invitation has been already accepted',
  we_are_setting_up_your_workspace_please_wait:
    'Please wait, we are setting up your workspace...',
  sending_invitation_has_failed: 'Sending the invitation has failed',
  accessing_the_channels_has_failed: 'Accessing the channels has failed',
  the_invitation_was_accepted: 'The invitation was accepted',
  failed_to_accept_the_invitation: 'Failed to accept the invitation',
  company_is_chosen_based_on_current_users_company:
    "Company is chosen based on current user's company",
  if_company_for_new_organization_already_exists_please_select_it:
    'If company for the new organization already exists, please select it',
  title: 'Title',
  statistics: 'Statistics',
  action: 'Action',
  preview: 'Preview',
  cancelled: 'Cancelled',
  human_resources_coming_soon: 'Human Resources coming soon',
  translations_coming_soon: 'Translations coming soon',
  request_access: 'Request access',
  setting_up_access_for_the_channel: 'Setting up access for the channel',
  has_failed: 'has failed',
  has_successfully_completed: 'has successfully completed',
  channels: 'Channels',
  billing_reports: 'Billing Reports',
  billing_parent_number: 'Parent Number',
  billing_task_number: 'Task Number',
  billing_visit_number: 'Visit Number',
  billing_task_type: 'Task Type',
  billing_partner_ref: 'Partner Ref.',
  billing_party_ref: '3rd Party Ref.',
  billing_contract_department: 'Contract Dept.',
  billing_contract_dept: 'Contract Dept',
  billing_eta_time: 'ETA Time',
  billing_eta_status: 'ETA Status',
  billing_resolved_at: 'Resolved At',
  billing_resolved_by: 'Resolved By',
  billing_made_sla: 'Made Sla',
  billing_response_sla_expiry: 'Response SLA Expiry',
  billing_fix_sla_expiry: 'Resolution SLA Expiry',
  billing_visit_summary: 'Visit Summary',
  billing_work_start: 'Work Start',
  billing_work_finish: 'Work Finish',
  billing_travel_time: 'Travel Time',
  travelPrice: 'Travel Price',
  billing_travel_distance_price: 'Travel Distance Price',
  billing_travel_distance_cost: 'Travel Distance Cost',
  billing_hourly_rate: 'Hourly Rate',
  billing_travel_fix: 'Travel Fix',
  billing_travel_fix_price: 'Travel Fix Price',
  billing_travel_fix_cost: 'Travel Fix Cost',
  billing_travel_hourly_price: 'Travel Hourly Price',
  billing_travel_hourly_cost: 'Travel Hourly Cost',
  billing_travel_distance_units: 'Travel Distance Units',
  billing_work_time_price: 'Work Time Price',
  billing_work_time_cost: 'Work Time Cost',
  billing_cost_total: 'Cost Total',
  billing_total_price: ' Total Price',
  billing_contract_rate_type: 'Contract Type',
  billing_work_time: 'Work Time',
  billing_time_rate: 'Time Rate',
  billing_work_time_total: 'Time Total',
  billing_incentives: 'Incentives',
  billing_total_cost: 'Total Cost',
  billing_work_total_price: 'Client Total Price',
  work_price_details_duration: 'Work Price Details Duration',
  work_price_details_start: 'Work Price Details Start',
  work_price_details_end: 'Work Price Details End',
  work_price_details_total: 'Work Price Details Total',
  work_price_details_work: 'Work Price Details Work',
  work_cost_details_duration: 'Work Cost Details Duration',
  work_cost_details_start: 'Work Cost Details Start',
  work_cost_details_end: 'Work Cost Details End',
  work_cost_details_total: 'Work Cost Details Total',
  work_cost_details_work: 'Work Cost Details Work',
  customize_dashboard: 'Toggle to customise your dashboard',
  customize: 'Customize',
  add_widget: 'Add a Widget',
  pick_widget: 'Pick a Widget to Add',
  new_incidents_by_priority: 'New Incidents By Priority',
  open_incidents_by_priority: 'Open Incidents By Priority',
  percentage_of_new_critical_incidents: '% of New Critical Incidents',
  resolved_incidents_by_priority: 'Resolved Incidents By Priority',
  age_of_open_incidents_by_priority: 'Age of Open Incidents by Priority',
  open_incidents_longer_than_one_week: 'Open Incidents Over a Week',
  open_critical_tasks: 'Open Critical Tasks',
  critical_incidents: 'Critical Incidents',
  new_critical_tasks: 'New Critical Tasks',
  open_timelogs: 'Open time logs',
  hours: 'hrs',
  itil_summary: 'ITIL Summary',
  new_incidents_created: 'New Incidents Created',
  total_incidents: 'Total Incidents',
  resolution_time_by_priority: 'Resolution Time by Priority',
  average_resolution_time: 'Average Resolution Time',
  resolved_incidents_by_me: 'Resolved Incidents by Me',
  incidents_age_days: 'Incidents Age(days)',
  resolution_time_hrs: 'Resolution Time(hrs)',
  incidents_by_state: 'Incidents By State',
  critical: 'Critical',
  high: 'High',
  moderate: 'Moderate',
  low: 'Low',
  planning: 'Planning',
  average_time: 'Average',
  assigned: 'Assigned',
  daily_reports: 'Daily Reports',
  in_progress: 'In Progress',
  on_hold: 'On Hold',
  rejected: 'Rejected',
  waiting: 'Waiting',
  resolved: 'Resolved',
  open: 'Open',
  percentage: 'Percentage',
  select_date_range: 'Please select date range!',
  select_location: 'Please Select Location!',
  start_date: 'Start Date',
  end_date: 'End Date',
  billing_report_stateid_0: 'draft',
  billing_report_stateid_3: 'declined',
  interval_start: 'Interval start',
  interval_end: 'Interval end',
  expense_sum: 'Expense sum',
  approval_deadline: 'Approval deadline',
  po_document: 'PO document',
  po_number: 'PO number',
  po_expense_sum: 'PO expense sum',
  billing_report: 'Billing report',
  billing_report_approved: 'Billing report approved',
  billing_report_pending: 'Billing report pending approval',
  approval_deadline_seconds: 'Approval deadline (seconds)',
  processed_count: 'Processed count',
  upload_po: 'Upload po',
  approval_status: 'Approval status',
  proposed: 'Proposed',
  billing_items: 'Billing items',
  download_po_document: 'Download PO document',
  export_tracking_report: 'Export tracking report',
  export_custom_report: 'Export custom report',
  set_proposed_state: 'Set proposed state',
  current_br: 'Current billing report',
  travel_time_sum: 'Travel time sum',
  work_time_sum: 'Work time sum',
  time_sum: 'Time sum',
  customer_sum: 'Customer sum',
  supplier_sum: 'Supplier sum',
  total_amount_of_expenses: 'Total amount of expenses',
  customer_price: 'Customer price',
  supplier_price: 'Supplier price',
  total_amount_of_travel_time: 'Total amount of travel time',
  total_amount_of_working_time: 'Total amount of working time',
  expenses_sum_info: 'Sum of all the expenses from all the tickets',
  customer_price_info: 'Amount to be invoiced to the customer',
  supplier_price_info: 'The amount to be paid to the suppliers',
  total_amount_of_travel_time_info:
    "Total amount of travel time (engineers' travel time)",
  total_amount_of_working_time_info:
    "Total amount of working time (engineers' working time)",
  select_billing_reports: 'Select billing reports',
  export_bi: 'Export billing items',
  export_selected_br: 'Export selected',
  supplier_EUR: 'Supplier price EUR',
  supplier_USD: 'Supplier price USD',
  customer_EUR: 'Customer price EUR',
  total_revenue: 'Total revenue',
  total_costs: 'Total costs',
  visits_number: 'Number of visits',
  gross_margin: 'Gross margin',
  export_chargeable: 'Export chargeable',
  export_all: 'Export all',
  avg_price: 'Average price',
  avg_work_time: 'Average work time',
  max_price: 'Max price',
  min_price: 'Min price',
  max_work_time: 'Max work time',
  median_price: 'Median price',
  median_work_time: 'Median work time',
  min_work_time: 'Min work time',
  anomalies: 'Anomalies',
  std_deviation_work_time: 'Standard deviation work time',
  std_deviation_price: 'Standard deviation price',
  std_deviation_cost: 'Standard deviation cost',
  median_absolute_deviation_work_time:
    'Median absolute deviation of working time',
  median_absolute_deviation_price: 'Median price of absolute deviation',
  median_absolute_deviation_cost: 'Median cost of absolute deviation',
  neg_price_spread: 'Negative price spread',
  std_deviation: 'Standard deviation',
  invalidate: 'invalidate',
  validate: 'validate',
  invalid: 'invalid',
  cost: 'Cost',
  revenue: 'Revenue',
  xlsx: '.xlsx',
  csv: '.csv',
  add_channel: 'Add channel',
  invite_new_user_to_channel: 'Invite a new user to a channel',
  the_invitation_was_sent: 'The invitation was sent',
  you_need_approving_role: 'You need the approving role',
  ref_number: 'Ref number',
  creating_organization_requested: 'Creating your organization',
  creating_organization_success: 'Organization successfully created',
  creating_organization_failed: 'Failed creating organization',
  empty: '',
  select_user: 'Select an user',
  no_reassignment: 'No reassigning yet',
  no_reopening: 'No reopening yet',
  no_resolution_time: 'No resolution time spent yet',
  no_response_time: 'No response time spent yet',
  not_created_yet: 'Not created yet',
  not_updated_yet: 'Not updated yet',
  not_resolved_yet: 'Not resolved yet',
  not_approved_yet: 'Not approved yet',
  not_closed_yet: 'Not closed yet',
  not_reopened_yet: 'Not reopened yet',
  incentive_wasnt_paid: "Incentive wasn't paid yet",
  add_users: 'Add users',
  validate_incident_number:
    'Incident Number must start with INC, followed by 7 digits!',
  validate_request_number:
    'Request Number must start with REQ, followed by 7 digits!',
  validate_request_item_number:
    'Request Item Number must start with RQI, followed by 7 digits!',
  validate_request_task_number:
    'Request Task Number must start with RQT, followed by 7 digits!',
  validate_krequest_number:
    'K request  must start with KRQ, followed by 7 digits!',
  validate_number: 'Use only digits please!',
  validate_uuid: 'Use uuid version 4 please!',
  validate_input: 'Invalid input!',
  field: 'Field',
  is_not_valid: 'is not valid!',
  is_required: 'is required',
  future_date: 'Future date is not allowed.',
  validate_email: 'This email is not valid',
  valid_email: 'Please enter a valid email',
  contact_type_req: 'Contact type is required',
  location_req: 'Location is required',
  priority_req: 'Priority is required',
  customer_product_req: 'Customer product is required',
  on_hold_reason_req: 'On Hold reason is required',
  caller_req: 'Caller is required',
  category_req: 'Category is required',
  subcategory_req: 'Subcategory is required',
  impact_req: 'Impact is required',
  urgency_req: 'Urgency is required',
  short_description_req: 'Short description is required',
  organization_req: 'Please select your organization!',
  channel_req: 'Please select your channel!',
  price_type_req: 'Price type is required',
  timezone_req: 'Timezone is required',
  pricing_period_req: 'Pricing period is required',
  approvement_policy_req: 'Approving policy is required',
  sales_stage_req: 'Please select sales stage.',
  purchase_timeframe_req: 'Please select purchase timeframe.',
  assignment_sales_req: 'Please select assignment group Sales.',
  assignment_pm_req: 'Please select assignment group Project manager.',
  assignment_contract_req: 'Please select assignment group Contract.',
  first_name_req: 'First name is required',
  last_name_req: 'Last name is required',
  period_req: 'Period is required',
  pricing_period: 'Pricing is period',
  lead_req: 'Lead is required',
  no_access_to_channels: "You don't have access to these channels:",
  try_again_later: 'Please, try again later',
  start_date_req: 'Start date is required',
  end_date_req: 'End date is required',
  start_date_should_current_date:
    'Start date should be later than current date.',
  end_date_should_start_date: 'End date should be later than start date.',
  renual_date_should_start_date:
    'Renual remind date should be between start date and end date.',
  signed_date_should_start_date:
    'Signed date should be earlier than start date.',
  number_of_sections_req: 'Number of sections is required',
  document_req: 'Document is required',
  should_be_later_than: ' should be later than ',
  should_be_before: ' should be before ',
  should_be_later_than_current_date: ' should be later than current date',
  active_from_should: 'Active from to should be before active to.',
  active_to_should: 'Active to should be later than current date.',
  service_hours_req: 'Service hours is required',
  holiday_is_duplicated: 'Holiday is duplicated',
  period_is_duplicated: 'Period is duplicated',
  resolution_time_eval_req: 'Resolution time evaluation is required',
  product_type_req: 'Product type is required',
  internal_product_req: 'Internal Product is required',
  credit_percentage_req: 'Credit percentage is required',
  limit_req: 'Limit is required',
  name_req: 'Name is required',
  email_req: 'Email is required',
  role_req: 'Role is required',
  sla_type_req: 'Sla type is required',
  product_family_req: 'Product family is required',
  product_category_req: 'Product category is required',
  max_response_time_req: 'Max response time is required',
  max_resolution_time_req: 'Max resolution time is required',
  max_load_req: 'Max load is required',
  packaging_type_req: 'Packaging type is required',
  passport_no_req: 'Passport number is required',
  this_field_is_required: 'This field is required',
  this_field_should_be_greater_than_0: 'This field should be greater than 0',
  this_field_should_be_less_than_100: 'This field should be less than 100',
  account_req: 'Account is required',
  type_req: 'Type is required',
  country_req: 'Country is required',
  purchase_order_req: 'Purchase order is required',
  state_req: 'State is required',
  bivac_code_req: 'Bivac code is required',
  fob_price_req: 'Fob price is required',
  unit_price_req: 'Unit price is required',
  transport_unit_price_req: 'Transport unit price is required',
  transport_description_req: 'Transport description is required',
  freight_price_req: 'Freight price is required',
  event_type_req: 'Event type is required',
  route_type_req: 'Route type is required',
  fxi_number_req: 'Fxi number is required',
  loading_date_req: 'Loading date is required',
  shipping_method_req: 'Shopping method is required',
  unit_product_price_req: 'Unit product price is required',
  expected_weight_req: 'Expected weight is required',
  insurance_unit_price_req: 'Insurance unit price is required',
  insurance_description_req: 'Insurance description is required',
  latitude_req: 'Latitude is required',
  longitude_req: 'Longitude is required',
  choose_existing_location: 'Choose existing location',
  status_req: 'Status is required',
  scope_req: 'Scope is required',
  action_req: 'Action is required',
  model_req: 'Model is required',
  id_req: 'ID is required',
  sub_id_req: 'Sub ID is required',
  resolution_time_req: 'Resolution time is required',
  support_to_req: 'Support to is required',
  location_type_req: 'Location type is required',
  min_tickets_per_period_req: 'Min tickets per period is required',
  sign_in_path_req: 'Sign in path is required',
  redirect_url_req: 'Redirect URL is required',
  primary_assignment_group_req: 'Primary assignment group is required',
  customer_assignment_group_req: 'Customer assignment group is required',
  supplier_assignment_group_req: 'Supplier assignment group is required',
  city_code_req: 'City code is required',
  country_code_req: 'Country code is required',
  destination_reached_manually_reason_req:
    'Reason why destination reached is set manually is required',
  channel_name_req: 'Channel name is required',
  supplier_standard_product_req: 'Supplier Standard Product is required',
  category_items_req: 'Category items is required',
  go_to_incident: 'Go to incident',
  go_to_incident_task: 'Go to incident task',
  go_to_request: 'Go to request',
  go_to_request_item: 'Go to request item',
  go_to_request_task: 'Go to request task',
  go_to: 'Go to ',
  work_note_created_in: 'Work note in',
  timelog_created_in: 'Time log in',
  comment_created_in: 'Comment in',
  refreshing_updated_item_info: 'Somebody updated this',
  bad_json_schema: 'Bad JSON Schema',
  dynamic_form: 'Dynamic form',
  priority_tooltip: 'The Priority is counted from Impact and Urgency.',
  entity_types: 'Entity types',
  entity_type: 'Entity type',
  parent_category: 'Parent Category',
  add_category: 'Add category',
  reasons: 'Reasons',
  reason: 'Reason',
  add_reason: 'Add reason',
  kind: 'Kind',
  select_kind: 'Select kind',
  please_input_the_name: 'Please input the name',
  create_reason: 'Create reason',
  please_select_kind: 'Please select kind',
  AssigneeRejectedReason: 'Assignee rejected reason',
  OnHoldReason: 'On hold reason',
  reject_reasons: 'Reject reasons',
  reject_reason: 'Reject reason',
  reject_reason_require: 'Rejected reason is required',
  add_reject_reason: 'Add reject reason',
  set_your_default_org_and_channel: 'Set your default organization and channel',
  my_wallet: 'My wallet',
  identity_not_created: 'An identity for this user is not created',
  cold_call: 'Cold call',
  self_generated: 'Self generated',
  partner: 'Partner',
  public_relations: 'Public relations',
  direct_mail: 'Direct mail',
  conference: 'Conference',
  other: 'Other',
  recycled: 'Recycled',
  dead: 'Dead',
  source_description: 'Source description',
  status_description: 'Status description',
  assigned_user: 'Assigned user',
  last_name: 'Last name',
  do_not_call: 'Do not call',
  mobile: 'Mobile',
  referred_by: 'Referred by',
  account_description: 'Account description',
  account_website: 'Account website',
  post_code: 'Post code',
  department: 'Department',
  annual_revenue: 'Annual revenue',
  date_reviewed_should: 'Date reviewed should be later than current date.',
  proposed_solution: 'Proposed solution',
  estimated_budget: 'Estimated Budget',
  date_reviewed: 'Date reviewed',
  platform: 'Platform',
  please_fill_name_type_location_type_fields_in_account_form:
    'Please fill name, type and location type fields in account form',
  please_fill_out_account_form_or_account_table:
    'Please fill out account form or account table',
  please_fill_out_contact_form_or_contact_table:
    'Please fill out contact form or contact table',
  data_of_contact_form_was_saved_as_contact:
    'Data of contact form was saved as contact',
  please_save_account_in_account_table_before_saving:
    'Please save account in account table before saving',
  prospecting: 'Prospecting',
  qualification: 'Qualification',
  needs_analysis: 'Needs analysis',
  value_proposition: 'Value proposition',
  perception_analysis_contract_creation:
    'Perception analysis / Contract creation',
  proposal_price_quote: 'Proposal / Price quote',
  negotiation_review: 'Negotiation / Review',
  closed_won: 'Closed won',
  closed_lost: 'Closed lost',
  medium: 'Medium',
  legal_name: 'Legal name',
  sales_stage: 'Sales stage',
  expected_date_closed: 'Expected date closed',
  new_business: 'New business',
  existing_business: 'Existing business',
  associated_opportunities: 'Associated Opportunities',
  supplier_products: 'Supplier Products',
  purchase_timeframe: 'Purchase Timeframe',
  stakeholders_identified: 'Stakeholders Identified',
  stakeholders: 'Stakeholders',
  stakeholder: 'Stakeholder',
  customer_needs: 'Customer Needs',
  purchase_process: 'Purchase Process',
  existing_solution: 'Existing Solution',
  assigned_team: 'Assigned team',
  primary_contact: 'Primary Contact',
  undo_as_primary: 'Undo as primary',
  individual: 'Individual',
  committee: 'Committee',
  sure_to_cancel: 'Are you sure to cancel?',
  view_details: 'View Details',
  threshold: 'Threshold',
  assignment_group_sales: 'Assignment group Sales',
  assignment_group_pm: 'Assignment group Project Manager',
  assignment_group_contract: 'Assignment group Contract',
  emails: 'Emails',
  ticket_roles: 'Ticket roles',
  messages: 'Messages',
  assign_to: 'Assign to',
  assigned_name: 'Assigned name',
  ticket_priority: 'Ticket priority',
  product_set: 'Product set',
  product_name: 'Product name',
  supplier_product: 'Supplier product',
  warning: 'Warning',
  plaese_save_before:
    'Please save or discard changes before going to different tab.',
  auto_renew_should: 'Auto-renew should be checked',
  document_type: 'Document type',
  document: 'Document',
  sure_to_delete: 'Are you sure you want to delete this?',
  deleting_question: 'Are you sure you want to delete this',
  number_of_sections: 'Number of sections',
  version: 'Version',
  upload_document: 'Upload document',
  menu_list: 'Menu List',
  revision: 'Revision',
  revision_comment: 'Revision comment',
  see_all_version: 'See all version',
  no_of_version: 'No. of version',
  new_revision: 'New Revision',
  requesting: 'Requesting...',
  successfully_saved: 'Successfully saved',
  successfully_deleted: 'Successfully deleted',
  successfully_exported: 'Successfully exported',
  the_status_was_changed_successfully: 'The status was changed successfully',
  revision_successfully_saved: 'The revision was successfully created.',
  document_successfully_saved: 'The document was successfully created.',
  unable_to_save_different_reference_contacts_and_account:
    'Unable to save due to different references between contacts and account',
  unable_to_save_different_reference_stakeholders_and_account:
    'Unable to save due to different references between stakeholders and account',
  unable_to_save_different_reference_legal_entitiy_and_account:
    'Unable to save due to different references between legal entity and account',
  unable_to_save_different_reference_customer_products_and_account:
    'Unable to save due to different references between customer products and account',
  please_fill_first_name_and_last_name_in_contact_form:
    'Please fill first name and last name in contact form',
  margin_of_pricing_unit: 'Margin of pricing unit',
  margin_of_additional_pricing_unit: 'Margin of additional pricing unit',
  please_add_data_in_empty_table_before_adding_new_tab:
    'Please add data in empty table before adding new tab',
  propose_opportunity: 'Propose opportunity',
  create_new_supplier_opp_from_this_opp:
    'Create new supplier opportunity from this opportunity',
  supplier_opp: 'Supplier Opp',
  supplierOpportunities: 'Supplier Opportunities',
  supplier_opportunity: 'Supplier Opportunity',
  copy_supplier_opportunity_info:
    "The supplier opportunity's link will be copied",
  copy_success_supplier_opportunity:
    "The supplier opportunity's link was copied",
  supplier_opportunity_comment: 'Supplier Opportunity comment',
  customer_budget_amount: 'Customer budget amount',
  kompitech_budget_amount: 'Kompitech budget amount',
  customer_contact: 'Customer Contact',
  customer_primary_contact: 'Customer primary contact',
  primary_assignment_group: 'Primary assignment group',
  customer_assignment_group: 'Customer assignment group',
  supplier_assignment_group: 'Supplier assignment group',
  my_user: 'My user',
  offers: 'Offers',
  please_add_offer_before_comment: 'Please add offer before leaving a comment.',
  customerContacts: 'Customer Contacts',
  primaryContacts: 'Primary Contacts',
  invoice_amount: 'Invoice amount',
  share: 'Share',
  accept: 'Accept',
  reject: 'Reject',
  k_requests: 'K requests',
  k_request: 'K request',
  create_k_request: 'Create new K request',
  k_request_status_log: 'Request status log',
  site: 'Site',
  virtual_resolution_time_start: 'Resolution time start',
  virtual_response_time_start: 'Response time start',
  closed_by: 'Closed by',
  time_session: 'Shift',
  time_sessions: 'Shifts',
  tickets: 'Tickets',
  start_work: 'Start work',
  add_to_time_session: 'Add to shift',
  all_timelogs_must_be_closed: 'All time logs of this shift must be closed',
  current_time_log: 'Current time log',
  ticket_must_be_added_to_the_time_session:
    'The ticket must be added to the shift',
  time_session_must_be_in_work_state: 'The shift must be in "work" state',
  ticket_must_be_accepted_and_assigned_to_current_user:
    'The ticket must be accepted and assigned to the current user',
  incident_must_be_assigned_and_accepted_by_user:
    'The Incident must be assigned and accepted by current user',
  request_item_must_be_in_inprogess_or_approved_state:
    'The request item must be in "in progress" or "approved" state',
  ticket_must_be_in_open_state: 'The ticket must be in "open" state',
  ticket_must_be_in_open_or_in_progress_state:
    'The ticket must be in "open" or "in progress" state',
  cmdb_updated: 'CMDB updated',
  actions_performed_onsite: 'Actions performed on-site',
  unexpected_issues_on_field_engineer_visit:
    'Unexpected issues on field engineer visit',
  travel_time: 'Travel time',
  hrs: 'Hrs',
  createUser: 'Create a user',
  createAccount: 'Create account',
  createOrganization: 'Create organization',
  createChannel: 'Create channel',
  set_up_call: 'Set up call',
  accountName: 'Account name',
  zipcode: 'Postal code',
  companyEmail: 'Company email',
  billingInfo: 'Company billing information',
  companyName: 'Company name',
  address1: 'Address line 1',
  address2: 'Address line 2',
  agree: 'I agree to ',
  terms_and_conditions: 'terms and conditions',
  account_select: 'Select account',
  unset: 'Unset',
  unassign: 'Unassign',
  unassigned: 'Unassigned',
  credit: 'Credit',
  global: 'Global',
  local: 'Local',
  kompitech: 'KompiTech',
  account_manager: 'Account manager',
  no_notes_added: 'No notes added',
  total_credit: 'Total credit',
  my_accounts: 'My accounts',
  tier: 'Account tier',
  organizations: 'Organizations',
  delete_confirmation: 'Are you sure you want to delete this?',
  select_grantLevel: 'Please select the permission level',
  permission_level: 'Permission level',
  admin: 'Admin',
  users_accounts: 'Users accounts',
  manage_accounts: 'Manage users accounts',
  new_channel: 'New channel',
  channel_name: 'Channel name',
  app_name: 'App name',
  cc_name: 'CC name',
  orderer: 'Orderer',
  orderers: 'Orderers',
  joined_peers: 'Joined peers',
  join_peer: 'Join peer',
  anchor: 'Anchor',
  endorsing: 'Endorsing',
  ledger_query: 'Ledger Query',
  chaincode_query: 'Chaincode Query',
  event_source: 'Event Source',
  upgrade_channel: 'Upgrade chaincode',
  upgrade: 'Upgrade',
  no_comments_added: 'No comments added',
  no_work_notes_added: 'No work notes added',
  not_approved: 'Not approved',
  'not-approved': 'Not approved',
  start_free_trial: 'START A FREE TRIAL',
  readyToBeLoaded: 'Ready to be loaded',
  inTransfer: 'In transfer',
  delivered: 'Delivered',
  paid: 'Paid',
  partially_to_be_loaded: 'Partially to be loaded',
  partially_in_transfer: 'Partially in transfer',
  order_state_5: 'Partially delivered',
  delivery_state_3: 'Destination reached',
  delivery_state_4: 'Offloaded',
  order: 'Order',
  orders: 'Orders',
  order_status_log: 'Status log',
  deliveries: 'Deliveries',
  delivery: 'Delivery',
  fob_price: 'Fob price',
  freight_price: 'Freight price',
  total_price: 'Total price',
  unit_price: 'Unit price',
  weight: 'Weight',
  material: 'Material',
  customer_req: 'Customer is required',
  material_req: 'Material is required',
  bivac_code: 'Bivac code',
  tracking: 'Tracking',
  cancelled_by: 'Cancelled by',
  cancelled_at: 'Cancelled at',
  fxi_number: 'Fxi number',
  validate_order_number:
    'Order Number must start with ORD, followed by 7 digits!',
  no_location_points: 'There are no location points',
  create_order: 'Create order',
  time_sent: 'Time sent',
  please_add_document: 'Please add document before adding delivery',
  you_can_add_delivery_only_new:
    'You can add delivery only when order status is new',
  delivery_success_added: 'The delivery was successfully added',
  unable_map_no_tracking_data:
    'Unable to display map since tracking data is currently unavailable',
  upload: 'Upload',
  copy_success_order: "The order's link was copied",
  loading_order: 'Loading order',
  truck_no: 'Truck No',
  driver_name: 'driver name',
  sn: 'sn',
  trailer1: 'Trailer 1',
  passport_number: 'Passport #',
  tonnage: 'Tonnage',
  delete_this: 'Delete this ',
  in_tons: ' in tons',
  order_req: 'Order is required',
  customer_route: 'Customer route',
  route_customer: 'Customer route',
  customer_routes: 'Customer routes',
  tracking_device: 'Tracking device',
  tracking_devices: 'Tracking devices',
  driver: 'driver',
  driver_req: 'driver is required',
  truck: 'Truck',
  location_sent: 'Location sent',
  trailers: 'Trailers',
  trailers_req: 'Trailers are required',
  shipping_method: 'Shipping method',
  road_tanker: 'Road tanker',
  FTL: 'FTL',
  unit_product_price: 'Unit product price',
  total_product_price: 'Total product price',
  insurance: 'Insurance',
  loading_date: 'Loading date',
  transports: 'Transports',
  truck_req: 'Truck is required',
  add_transport: 'Add transport',
  remove_transport: 'Remove transport',
  document_type_0: 'Feri',
  document_type_1: 'Feri application',
  document_type_2: 'Feri draft',
  document_type_3: 'BV',
  document_type_4: 'RIT',
  document_type_5: 'Supplier invoice',
  document_type_6: 'Customer invoice',
  document_type_7: 'Road manifest',
  document_type_8: 'Weighbridge',
  document_type_9: 'COA',
  document_type_10: 'Customs export release',
  weight_type_req: 'Weight is required',
  file_req: 'File is required',
  supplier_req: 'Supplier is required',
  transporter_req: 'Transporter is required',
  transporter: 'Transporter',
  route_req: 'Route is required',
  upload_file: 'Upload file',
  expected_weight: 'Expected weight',
  eta: 'Expected time of arrival in days',
  verify: 'Verify',
  verified: 'Verified',
  weight_different_make_sure_continue:
    'Weight is different than expected weight. Do you want to continue?',
  copy_success_delivery: "The delivery's link was copied",
  export_loading_order: 'Export loading order',
  status_changed_ready_loaded:
    'Status has changed from new to ready to be loaded.',
  please_select_delivery_only_new:
    'Please select deliveries which status is new.',
  unable_to_select_status: 'Unable to select this status.',
  download_pdf: 'Download PDF',
  download_image: 'Download image',
  download_xlsx: 'Download Excel',
  manually_reached_reason: 'Manually reached reason',
  latitude_of_destination: 'Latitude of destination',
  longitude_of_destination: 'Longitude of destination',
  destination_reached_manually_reason:
    'Reason why destination reached is set manually',
  pod_documents: 'POD documents',
  non_pod_documents: 'Non POD documents',
  destination: 'Destination',
  route: 'Route',
  routes: 'Routes',
  routeTypesT: 'Route type',
  agents: 'Agents',
  reached: 'Reached',
  province: 'Province',
  import_clearing_agent: 'Import clearing agent',
  import_clearing_agent_req: 'Import clearing agent is required',
  bv_agent: 'BV agent',
  bv_agent_req: 'BV agent is required',
  feri_agent: 'Feri agent',
  feri_agent_req: 'Feri agent is required',
  rit_agent: 'RIT agent',
  rit_agent_req: 'RIT agent is required',
  export_clearing_agent: 'Export clearing agent',
  export_clearing_agent_req: 'Export clearing agent is required',
  source_address: 'Source address',
  street_address: 'Street address',
  remove_poi: 'Remove POI',
  manually_reason: 'Manually reason',
  event_type: 'Event type',
  points_of_interest: 'Points of interest',
  add_poi: 'Add POI',
  create_route: 'Create route',
  destination_address: 'Destination address',
  copy_success_route: "The route's link was copied",
  load_from_map: 'Load from map',
  route_type_0: 'Default',
  route_type_1: 'No agents',
  materials: 'Materials',
  create_material: 'Create material',
  material_type_0: 'Bags',
  material_type_1: 'Containers',
  packaging_type: 'Packaging type',
  validate_material_number:
    'Material Number must start with MAT, followed by 7 digits!',
  pod: 'P.O.D.',
  trailer_type_0: 'Tank',
  trailer_type_1: 'Semi truck',
  trailer_type_2: 'Container truck',
  trailer_type_3: 'Flat truck',
  trailer: 'Trailer',
  max_load: 'Max load',
  create_trailer: 'Create trailer',
  create_truck: 'Create truck',
  brand: 'Brand',
  passport_no: 'Passport number',
  street_address_req: 'Street address is required',
  city_req: 'City is required',
  company: 'Company',
  trucks: 'Trucks',
  drivers: 'Drivers',
  create_driver: 'Create driver',
  user_req: 'User is required',
  companies: 'Companies',
  vendor_types: 'Vendor types',
  company_address: 'Company Address',
  company_contact: 'Company Contact',
  emergency_contact: 'Emergency Contact',
  mailing_list: 'Mailing List',
  add_email: 'Add email',
  remove_email: 'Remove email',
  email_shouldnt_duplicated: "Email shouldn't be duplicated.",
  arrived_at: 'Arrived at',
  departed_at: 'Departed at',
  smth_went_wrong: 'Something went wrong Please try again',
  acc_create_failed: 'Creating account has failed',
  getting_orgs_failed: 'Getting organizations has failed',
  getting_channls_failed: 'Getting channels has failed',
  save_user_org_ch_failed: "Saving user's organization & channel has failed",
  switchAccount: 'Switch account',
  accept_usr_invite_fail: 'Accepting invitation has failed ',
  get_identity_failed: 'Getting identity has failed',
  post_identity_failed: 'Posting identity has failed',
  get_usr_failed: 'Getting user has failed',
  start_typing: 'Start typing...',
  permissionDenied: 'Permission denied',
  noPermissionAccount: "You don't have access to account:",
  error_copied: 'Error was copied successfully',
  copy_error: 'Copy error',
  current_time_session: 'Current shift',
  resolved_timesessions: 'Resolved shift',
  removed: 'Removed',
  remove: 'Remove',
  newAsset: 'New Asset',
  schemas: 'Schemas',
  newSchema: 'New schema',
  assetTypes: 'Asset types',
  newAssetType: 'New Asset Type',
  assetManagement: 'Asset management',
  CHART_assetByType: 'Assets by type',
  CHART_assetUsageByType: 'Assets usage',
  CHART_newAssetFrequency: 'Assets frequency',
  myAssets: 'My assets',
  myApps: 'My apps',
  schemaName: 'Schema name',
  namespaces: 'Namespaces',
  application_discover: 'App discovery',
  schemaDesc: 'Schema description',
  subState: 'Sub State',
  modelCategory: 'Model Category',
  serialNumber: 'Serial Number',
  assetType: 'Asset Type',
  manageAssetTypes: 'Manage Asset Types',
  incorrectFormat: 'Incorrect format',
  newFieldsMapping: 'New fields mapping',
  fieldsMappingName: 'Fields mapping name',
  fieldsMappingDescription: 'Fields mapping description',
  newLocation: 'New location',
  assetFields: 'Asset fields',
  displayName: 'Display name',
  assetSchemaId: 'Asset schema ID',
  mapping: 'Mapping',
  assetSchema: 'Asset schema',
  manageAccount: 'Manage Account',
  newAccount: 'New account',
  newNamespace: 'New namespace',
  newUser: 'New user',
  editUser: 'Edit User',
  changeRoleTo: 'Change Role To',
  editProfile: 'Edit profile',
  addNewUser: 'Add new user',
  defaultTenant: 'Default tenant',
  userList: 'User List',
  saving: 'Saving',
  failed: 'Failed',
  addNewAsset: 'New asset',
  asset: 'Asset',
  count: 'count',
  approvalState: 'Approval state',
  approver: 'Approver',
  deviceActivity: 'Device activity',
  newDevice: 'New Device',
  assetInfo: 'Asset info',
  selectAsset: 'Select asset',
  true: 'true',
  false: 'false',
  addMore: 'Add more',
  addMoreFields: 'Add more fields',
  icon: 'Icon',
  confirmDeleteAsset: 'Are you sure you want to delete asset?',
  CMDB: 'CMDB',
  SC: 'SC',
  SAAS: 'SaaS Management',
  totalPrice: 'Total  price',
  ref: 'Reference',
  invoice: 'Invoice',
  valid: 'Valid',
  importCategory: 'Valid',
  grantLevel: 'Permission level',
  legalEntity: 'Legal entity',
  legalEntities: 'Legal entities',
  internalProducts: 'Internal Products',
  short_description: 'Short description',
  created_at: 'Created at',
  state_id: 'Status',
  additional_hour_rate: 'Additional unit rate',
  primary_hour_rate: 'Pimary unit rate',
  unit_rate: 'Unit rate',
  opportunity_id: 'Opportunity',
  created_by_id: 'Created by',
  updated_by_id: 'Updated by',
  assigned_to_id: 'Assigned to',
  assignment_group_contract_id: 'Assignment group Contract',
  lead_id: 'Lead',
  assignment_group_sales_id: 'Assignment group Sales',
  assignment_group_id: 'Assignment group',
  customer_assignment_group_id: 'Customer assignment group',
  chosen_offers: 'Chosen offers',
  primary_assignment_group_id: 'Primary assignment',
  customer_primary_contact_id: 'Customer primary contact',
  supplier_assignment_group_id: 'Supplier assignment group',
  referred_by_id: 'Referred by',
  escalation_level: 'Escalation Level',
  industry_id: 'Industry',
  parent_product_category_id: 'Parent product category',
  assignment_groups: 'Assignment Groups',
  inbound_leads: 'Inbound Leads',
  supplier_opportunities: 'Supplier Opportunities',
  category_items: 'Category Items',
  product_categories: 'Product Categories',
  product_families: 'Product Families',
  my_profile: 'My Profile',
  document_type_id: 'Document type',
  customerProductTemplate: 'Customer Product Template',
  customerProduct: 'Customer Product',
  assignmentGroup: 'Assignment group',
  OOHrates: 'OOH rates',
  OOHratesInfo: `Out of hours rates (OOH) are applied based on the pricing plan. If you don't want to charge out of hours rate disable this option.`,
  priceLists: 'Price Lists',
  customerProducts: 'Customer Products',
  documentType: 'Document',
  internalProduct: 'Internal Product',
  supplier_standard_product_id: 'Supplier Standard Product',
  supplierStandardProduct: 'Supplier Standard Product',
  account_id: 'Account',
  supplierProducts: 'Supplier Products',
  supplier_opportunity_id: 'Supplier Oportunity',
  location_id: 'Location',
  supplierOpportunity: 'Supplier Oportunity',
  customer_contacts: 'Customer Contacts',
  primary_contacts: 'Primary Contacts',
  legal_entity_id: 'Legal Entity',
  currency_id: 'Currency',
  type_id: 'Type',
  supplierStandardProducts: 'Supplier Standard Products',
  invited_by_id: 'Invited by',
  productFamily: 'Product family',
  product_family_id: 'Product Family',
  supplier_expenses: 'Supplier expenses',
  customer_expenses: 'Customer expenses',
  internal_product_price: 'Internal Product Price',
  supplier_standard_product_price: 'Supplier Standard Product Price',
  at_time_log: 'At time log',
  support_from_is_required: 'Support from is required',
  support_to_is_required: 'Support to is required',
  primary_hours_number: 'Primary hours number',
  unit_number: 'Unit number',
  internal_product_id: 'Internal Product',
  recenterGraph: 'Recenter graph',
  pick_icon: 'Pick Icon',
  selectedIcon: 'Selected icon',
  clickSelect: 'Click to select',
  addDef: 'Add new definition',

  definitions: 'Definitions',
  definition: 'Definition',
  enums: 'Enums',
  addEnum: 'Add new enum',
  enum: 'Enum',
  valueNumber: 'This value should be number',
  // CMDB Assets
  Users: 'users',
  Accounts: 'accounts',
  create_new_account: 'Create new account',
  Assets: 'assets',
  NameSpaces: 'name spaces',
  AssetTypes: 'asset types',
  AssetSchemas: 'asset schemas',
  MyAssets: 'my assets',
  FieldMappings: 'field mappings',
  Locations: 'locations',
  Apps: 'Apps',

  list: 'List',
  customFilter: 'Custom filter',
  sort: 'Sort',
  ascending: 'ascending',
  descending: 'descending',
  refresh: 'Refresh',
  appsList: 'App list',
  addNewApp: 'New app',
  vendor: 'Vendor',
  vendors: 'Vendors',
  app: 'App',
  appCritical: 'How critical the app to your business?',
  number_of_license: 'Number of licenses',
  billing_cycle: 'Billing cycle',
  const_per_user: 'Cost per user',
  access: 'Access',
  PERUSER: 'Per user',
  PERACCESS: 'Per access',
  CUSTOM: 'Custom option',
  add_new_vendor: 'Add new vendor',
  add_new_product: 'Add new product',
  add_new_prodct_vendor_title: 'New vendor account',
  choose_vendor: 'Choose Vendor',
  resolve_ticket_message:
    'Please ensure your time-logs are correct before you mark a ticket as resolved. Incorrect timelogs can result in problems with processing payments.',
  create_new_type: 'create new type',
  link_copied: 'link copied',
  add_new: 'add new',
  advanced_filters: 'advanced filters',
  filters: 'Filters',
  fields_view: 'fields view',
  user_type_info: `Select "engineer" for all how can work as field engineer. For others please select "non-engineer"`,
  no_cmdb_saas_invite:
    "Caution! check this if only you don't want user to have access to CMDB/SAAS",
  sort_by: 'sort by',
  filter_by: 'filter by',
  time_logs_to_check: 'Time logs to check',
  time_logs_reviewed: 'I confirm that I have reviewed my time logs',
  registrationNumber: 'Registration number',
  companyAddress: 'Company address',
  view_columns: 'View columns',
  working_time: 'Working time',
  expected_end_created_at: 'Expected end (created at)',
  expected_end_updated_at: 'Expected end (updated at)',
  expected_start_created_at: 'Expected start (created at)',
  expected_start_updated_at: 'Expected start (updated at)',
  confirm_override_table_settings:
    'Do you want to override your table settings which were set from the share link?',
  how_can_we_help: 'How can we help?',
  current_opened_tickets: 'Currently opened tickets',
  current_opened_requests: 'Currently opened requests',
  requested_by: 'Requested by',
  request_item_desc: 'Request item description',
  enter_imei_number: 'Enter your IMEI number',
  clear_all: 'Clear all',
  stuff_members: 'Stuff members',
  identities: 'Identities',
  team_members: 'team members',
  manager: 'Manager',
  cmdb_ref: 'Asset',
  collapse: 'Collapse',
  expand: 'Expand',
  pending_requests: 'Pending requests',
  no_pending_requests: 'No pending requests',
  'my-requests': 'My requests',
  user_data: 'user data',
  'engineer-manager': 'Engineer manager',
  'non-engineer-manager': 'Non engineer manager',
  'non-engineer': 'Non engineer',
  engineer: 'Engineer',
  'account-login': 'Log in with your',
  reset_all: 'Reset all',
  cant_log: "Can't log in?",
  sign_up: 'Sign Up',
  sign_up_login: 'Sign Up for an account',
  privacy_policy: 'Privacy statement',
  user_notice: 'User notice',
  create_time_session: 'Start work',
  tickets_open_timelogs: 'Tickets with opened time logs',
  custom_reports: 'Custom reports',
  get_report: 'Get report',
  accept_invitation_question: 'Do you want to accept the invitation?',
  create_identity: 'Create identity',
  identity_is_created: 'The indentity is already created',
  skip: 'skip',
  cmdbInvite: 'Invite to CMDB',
  org_and_channel: 'Organization & channel',
  iAgreeWith: 'I agree with',
  tryAgain: 'Try again',
  tellAboutTheIssue: 'Please tell about the issue',
  send: 'send',
  home: 'Home',
  welcome_blits: 'Welcome to the KompiTech BLiTS Platform',
  welcome_text: 'KompiTech BLiTS Platform',
  select_category_get_started: 'Please select your module to get started',
  service_desk: 'IT service desk',
  it_assets: 'IT Assets',
  itsm_description: 'IT support automation tools',
  cmdb_description: 'IT asset inventory automation',
  saas_description: 'SaaS management automation',
  invite_accept: 'Yes, accept',
  invite_decline: 'No, decline',
  oops_something_went_wrong: 'Oops! Something went wrong',
  copy_error_msg:
    'Please press on "Copy error" below to be reported to service desk',
  feedback_successfully_sent:
    'Thank you!\n Your feedback was successfully sent',
  more_than_year: '+ 1 year',
  unnasigned: 'Unassigned',
  knowledge_base_search: 'Knowledge base search',
  something_broken: 'Something broken?',
  order_device: 'Order device',
  access_request: 'Access request',
  employee_onboarding: 'Employee onboarding /\n offboarding',
  view_all: 'View all',
  agree_conditions: 'You need to agree with terms and conditions',
  invite_user: 'Invite user',
  create_user: 'Create user',
  create_group: 'Create group',
  rate_and_pricing: 'Rate & pricing (optional)',
  invite_org: 'Invite organization',
  incident_expenses: 'Expenses',
  invite_send_seccess: 'Thank you! \n Your Invitation was successfully sent ',
  send_new_invite: 'Send another invite',
  fix_it_soon: 'We’ll fix it soon so please try again a bit later...',
  comments_error:
    'ERROR: Sorry, an error happened and the comment cannot be rendered. Please, contact the support',
  supportFrom: 'support from',
  supportTo: 'support to',
  working_days: 'Working days',
  more_options: 'Show more options',
  product_group: 'Product group',
  input_not_number: 'The input is not a number',
  primary_hours: 'Primary hours',
  additional_hours: 'Additional hours',
  support_from_less: 'Support from should be less than support to',
  support_to_more: 'Support from should be later than support to',
  active_from_less: 'Active from should be less than active to',
  active_to_more: 'Active from should be later than active to',
  interval_overlap: 'Interval values should not overlap',
  asset_type: 'Asset type',
  asset_types: 'Asset types',
  total_assets: 'Total assets:',
  domain: 'Domain',
  operating_system: 'OS',
  build_version: 'Build & Version',
  asset_location: 'Asset Location',
  ip_location: 'IP Location',
  dns_name: 'DNS Name',
  fqn: 'FQDN',
  system_squ: 'System SKU',
  express_code: 'Express Code',
  memory: 'Memory',
  processor: 'Processor',
  esxi_server: 'Server',
  antivirus: 'ANTIVIRUS',
  motherBoard: 'Motherboard',
  audio_2: 'Audio (2)',
  optical: 'Optical',
  graphics: 'Graphics',
  hard_disc: 'Hard Disk',
  warranty: 'Warranty',
  active_state: 'State',
  purchased: 'Purchased',
  serial_number: 'Serial Number',
  contact_asset: 'Contact',

  last_user: 'Last user',
  last_user_type: 'Type',

  scan_server: 'Scan Server',
  asset_installation: 'Installation',
  asset_upTime: 'Uptime',

  first_seen: 'First Seen',
  last_seen: 'Last Seen',
  last_tried: 'Last Tried',

  ad_company: 'AD Company',
  ad_location: 'AD Location',
  ad_ou: 'OU',

  ad_manager: 'Ad Manager',
  ad_comments: 'Ad Comments',
  ad_description: 'Ad Description',

  network_header: 'Network',

  manufacturer: 'Manufacturer',
  all_users: 'All users',
  configured_apps: 'Configured apps',
  system_users: 'System users',
  groups: 'Groups',
  applications: 'Applications',
  app_role: 'Application role',
  password: 'Password',
  mail_nickname: 'Mail nickname',
  invalid_password:
    'Password must contain a minimum of 8 characters, at least 1 upper case letter, 1 lower case letter, 1 special character and 1 numeric character',
  assign: 'Assign',
  number_of_licences: 'Number of licences',
  vendor_name: 'Vendor name',
  app_users: 'App users',
  group_id: 'Group id',
  login_komp_acc: 'Login with KompiTech account',
  signup_komp_acc: 'Sign up',
  copy: 'Copy',
  STATE_UNKNOWN: 'Unknown',
  STATE_NEW: 'New',
  STATE_RUNNING: 'Running',
  STATE_ERROR: 'Error',
  STATE_UNAVAILABLE: 'Unavailable',
  STATE_SOLD: 'Sold',
  STATE_BROKEN: 'Broken',
  STATE_BEING_REPAIRED: 'Being repaired',
  STATE_IN_STOCK: 'In stock',

  'network-cols-name': 'Name',
  'network-cols-macAddress': 'Mac address',
  'network-cols-ipAddress': 'IP Address',
  'network-cols-subnet': 'Subnet',
  'network-cols-mask': 'Mask',
  'network-cols-getaway': 'Getaway',
  'network-cols-connection': 'Connection',
  ipAddress: 'IP address',
  macAddress: 'Mac address',
  lastUser: 'Last user',
  operatingSystem: 'Operating system',
  purchaseDate: 'Purchase date',
  firstSeen: 'First seen',
  lastSeen: 'Last seen',
  notAvailable: 'Not available',
  deletedAt: 'Deleted at',
  invitorName: 'Invitor name',
  downloading: 'Downloading',
  assign_user_to_asset: 'Assign user to asset',
  assign_location_to_asset: 'Assign location to asset',
  visibility_rights: 'Please, set visibility rights',
  no_recent_incidents: 'No incidents were added recently',
  no_recent_requests: 'No requests were added recently',
  no_data: 'No data',
  no_data_available: 'No data available',
  start_shift: 'Start timer',
  'to-action': 'To Action',
  subscriptions: 'Subscriptions',
  select_plan: 'Plans and pricing',
  cards_and_invoices: 'Payment method',
  plans_and_packages: 'Billing',
  change_plan: 'Change plan',
  active_subscriptions: 'Active subscriptions',
  credit_cards: 'Payment method',
  free_organization_channel: 'Free Organization and Channel',
  no_start_timelog_tickets: 'No tickets to start shift available',
  per_month: 'month',
  per_agent: 'agent',
  per_user: 'user',
  per_asset: 'asset',
  per_year: 'year',
  current_products: 'Current plans',
  filter_by_module: 'Filter by module',
  ticket_management_subscrp: 'IT service desk',
  ticket_management_subscrp_sub: 'IT service automation tools',
  ticket_management_subscrp_sub_saas: 'SaaS management automation',
  ticket_management_subscrp_sub_cmdb: 'IT Assets inventory automation',
  support_management: 'Support management',
  due: 'Due',
  asset_management: 'Assets management',
  basic_product: 'Basic product',
  active_since: 'Active since',
  choose_plan: 'Choose plan',
  product_details: 'Product details',
  add_customers_and_suppliers: 'Add customers and supplier as organizations',
  free: 'Free',
  subscription_added: 'Congratulation! Subscription is added to your plan',
  subscription_cancelled: 'Subscription is canceled',
  cancel_confirm: 'Sure to cancel?',
  subscribe_confirm: 'Sure to subscribe?',
  load_more: 'Load more ...',
  total_subscriptions: 'Subscription total per month/year',
  billing_address: 'Billing address',
  no_card_added: 'You don`t have attached payment card yet',
  processing: 'processing…',
  more_details: 'Get more details',
  payment_of: 'Your payment of',
  thank_you: 'Thank you!',
  payment_failed: 'Sorry, your payment has failed.',
  manage: 'Manage',
  manage_cards_hint: 'Add / delete payment card, set default',
  manage_card: 'Manage cards',
  check_payment:
    'We  have issues charging your card. Please, check your payment methods',
  manage_cards: 'Manage cards',
  warranties_header: 'Warranties',
  'warranties-cols-shipDate': 'Ship date',
  'warranties-cols-purchaseCountry': 'Purchase country',
  'warranties-cols-startDate': 'Start date',
  'warranties-cols-endDate': 'End date',
  'warranties-cols-service': 'Service',
  sshServer: 'SSH Server',
  httpServer: 'HTTP Server',
  httpTitle: 'HTTP Title',
  ftp: 'FTP',
  smtp: 'SMTP',
  C: 'C:',
  view_subscriptions_and_services: 'View subscriptions and services',
  free_trial_end: 'Looks like your free trial is over',
  use_not_subscribed:
    'But you still have full access some free products and options',
  add_card: 'Please add credit card in order to use Blits',
  confirm_assign: 'Proceed to assign',
  confirm_unassign: 'Proceed to unassign',
  less_to_previous_month: 'less to previous month',
  not_subscribed: 'You don`t have a subscription to visit this page',
  total_numbers: 'Total',
  total_users: 'Total users',
  unused_apps_users: 'Unused apps and inactive users',
  in_warning: 'In warning',
  in_alert: 'In alert',
  warnings_in_usage: 'Warnings in usage',
  alerts_in_usage: 'Alerts in usage',
  warnings_in_login: 'Warnings in login',
  alerts_in_login: 'Alerts in login',
  total_apps: 'Total apps',
  unused_apps: 'Unused apps',
  inactive_users: 'Inactive users',
  off: 'off',
  manage_products: 'Manage products',
  show_products: 'Show products',
  show_services: 'Show services',
  purchaced_services: 'Purchaced services',
  purchaced_products: 'Purchaced products',
  invitation_to_Blits: 'You were invited to join BLITS',
  proceed: 'Proceed',
  invitation_email_mismatch: 'User logged in email differs from invitation',
  invitation_expired: 'Invitation expired',
  invitation_invalid:
    'Invalid invitation link please contact your administrator',
  free_trial: 'Free trial',
  azure_active_directory: 'Azure active directory',
  sign_in_loading: 'Signing you in...',
  sign_out_loading: 'Signing you out...',
  setup: 'Setup',
  get_started: 'Get started',
  configure_identity_provider: 'Configure SAAS Identity Provider',
  obtain_azure_credentials: 'Obtain Azure AD credentials',
  start_see_dashboard: 'Get Start to see the Dashboard',
  setup_saas_identity_provider: 'Setup SaaS Identity Provider',
  setup_now: 'Setup now',
  happy_users: 'Happy users',
  why_customers_love_us: 'This is Why Our Customers Love What We Do…',
  feedback_from_clients:
    'This is feedback from our clients who have \n used our services, they are all happy.',
  promotional: 'promotional',
  subscribe_to_newsletter: 'Subscribe To Our Newsletter',
  companies_already_empoweres_it_stack:
    'These Companies Are Already Empowered Their IT Stack',
  when_will_you: 'When Will You?',
  contact_us: 'Contact us',
  back_to_dashboard: 'Back to dashboard',
  top_up: 'Top up',
  balance: 'Balance',
  account_balance_info: 'Please, input amount to top up your balance',
  account_balance_mismatch: 'Account balance update error',
  manage_plans: 'Manage plans',
  new_asset: 'New asset',
  asset_name: 'Asset name',
  assetTypeId: 'Asset type id',
  all_accounts: 'Avaliable to all accounts',
  profile: 'Profile',
  ticket_management: 'IT Support Management Automation',
  saas_management: 'SaaS Management Automation',
  product_of_kompitech: 'A product of KompiTech',
  service_rsesources: 'Services & Resources',
  it_outsourcing: 'IT Outsourcing',
  it_field_services: 'IT Field Services',
  sign_in_sign_up: 'Sign in / Sign up',
  terms_of_use: 'Terms of use',
  private_policy: 'Privacy Policy',
  copyright: ' © 2023 KompiTech All rights reserved.',
  get_ewsletter: 'Get Newsletter',
  subscribe: 'Subscribe to every weekly read our newsletter.',
  enter_email: 'Enter email',
  metered_price_delete:
    'You cannot cancel subscription to product with metered price. Please, contact your manager.',
  add_location_permission: 'You don`t have permission to create location',
}
