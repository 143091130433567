import React from 'react'

import {Avatar, Card, Col, Form, List} from 'antd'
import moment from 'moment'

import {DATE_FORMAT} from '@/constants'
import {getNameAbbreviation} from '@/utils/get-name-abbreviation'

import {translate} from '@/services/i18n'

import {setColor} from '../../utils/set-color'
import {Comment} from '../../services/openAPI/Api'

import './chat-room.scss'

interface IErrorMessage {
  data: Comment
}

export default function ErrorMessage({data}: IErrorMessage) {
  const createdDate = moment(data.created_at).format(DATE_FORMAT)
  const color = data.created_by && data.created_by.uuid.substring(0, 6)
  return (
    <Col span={24}>
      <Form.Item>
        <Card>
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  shape="square"
                  className="itsm-chatroom__avatar"
                  style={setColor('background', color)}
                  size="large"
                >
                  <b>
                    {data.created_by &&
                      getNameAbbreviation(
                        `${data.created_by.name} ${data.created_by.surname}`
                      )}
                  </b>
                </Avatar>
              }
              title={<span>{translate(['comments_error'])}</span>}
              description={
                <div className="itsm-chatroom__title">
                  <span>{`${data.created_by.name} ${data.created_by.surname} at ${createdDate}`}</span>
                </div>
              }
            />
          </List.Item>
        </Card>
      </Form.Item>
    </Col>
  )
}
