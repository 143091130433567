import React, {useContext, useState} from 'react'

import {InfoCircleOutlined} from '@ant-design/icons'
import {Popover} from 'antd'
import {useSelector} from 'react-redux'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {TKey, translate} from '@/services/i18n'
import {fetchOneCustomerProduct} from '@/modules/ITSM/api/productsRequests'
import {
  ProductContext,
  TProductContext,
} from '@/modules/ITSM/components/incident-k-request/incident-k-request-detail-form/context/product-context'
import {TCustomerProduct} from '@/modules/ITSM/typedef'
import {selectAppVersion} from '@/redux/user/userSelector'
import {AppVersion} from '@/typedef'
import {productApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import ProductDetails from '../product-details/ProductDetails'
import ProductDetailsUP from '../product-details/product-details-up'

type TProps = {
  customerProdInit?: TCustomerProduct | undefined
  customerProdValue: string
  resetSupplierProd?: () => void
  setRefresh: () => void
  isRequired?: boolean
  isDisabled?: boolean
  inputLabel?: TKey
}

const CustomerProductSelect = ({
  customerProdInit,
  customerProdValue: customerId,
  resetSupplierProd,
  setRefresh,
  isRequired,
  isDisabled,
  inputLabel = 'customer_product',
}: TProps) => {
  const [newCustomerProduct, setNewCustomerProduct] = useState<
    TCustomerProduct | undefined
  >()

  const {setProductCode} = useContext<TProductContext>(ProductContext)

  const appVersion = useSelector(selectAppVersion)

  const handleGetCostumerProduct = async (
    info?: string,
    selectedId?: string
  ) => {
    const id = selectedId || customerId
    if (id) {
      const {
        body: {result},
      } = await fetchOneCustomerProduct({id})

      if (result)
        if (info) setNewCustomerProduct(result)
        else setProductCode(result.code)
    }
  }

  const handleDisplayInfo = () => {
    if (customerProdInit?.uuid !== customerId) handleGetCostumerProduct('info')
    else setNewCustomerProduct(customerProdInit)
  }

  const customerProductDetails = newCustomerProduct || customerProdInit

  return (
    <div id="customer-product-select">
      <Popover
        open={!!newCustomerProduct}
        {...(customerProductDetails && {
          content:
            appVersion === AppVersion.APP ? (
              <ProductDetails customer_product={customerProductDetails} />
            ) : (
              <ProductDetailsUP customer_product={customerProductDetails} />
            ),
        })}
        getPopupContainer={() =>
          document.getElementById('customer-product-select') as HTMLElement
        }
        trigger="click"
        placement="bottom"
        onOpenChange={open => {
          !open && setNewCustomerProduct(undefined)
        }}
      >
        <SelectWithBM
          onFocusAction={() => {
            setNewCustomerProduct(undefined)
          }}
          api={productApi().customer}
          label={
            <span>
              {translate(inputLabel)}
              {customerId && (
                <InfoCircleOutlined
                  className="ml-5"
                  onClick={handleDisplayInfo}
                />
              )}
            </span>
          }
          disabled={isDisabled}
          name="customer_product"
          // recognizeFieldChange={recognizeFieldChange}
          onClear={() => {
            resetSupplierProd && resetSupplierProd()
            setProductCode('')
            setRefresh()
          }}
          selector={{
            $or: [
              {active_to: {$exists: false}},
              {active_to: {$gte: new Date()}},
            ],
          }}
          initialValue={customerProdInit ? [customerProdInit] : []}
          rules={{
            required: isRequired,
            message: translate('customer_product_req'),
          }}
          optionContent={(rec: {name: string}) => {
            return rec.name
          }}
          onChange={(value: string) => {
            resetSupplierProd && resetSupplierProd()
            handleGetCostumerProduct('', value)
            setRefresh()
          }}
          searchKey="name"
          getPopupContainer={(e: HTMLElement) => e.parentNode}
          blockOnFocus={true}
          fetchOnOpen={true}
        />
      </Popover>
    </div>
  )
}

export default CustomerProductSelect
