import React from 'react'

import {Icon, IconType} from '@/components/icon'

import './custom-check-icon.scss'

export const CustomCheckIcon: React.FC = () => {
  return (
    <div className="custom-check-icon">
      <Icon type={IconType.CHECK} size={12} />
    </div>
  )
}
