import React from 'react'
import {Space, Spin} from 'antd'
import './overlay-spinner.scss'
import {LogoutButton} from '../logout-button/logout-button'

type TProps = {
  text?: string
  isLogoutButtonVisible?: boolean
  idName?: string
}

export const OverlaySpinner: React.FC<TProps> = ({
  text,
  isLogoutButtonVisible = false,
  idName,
}) => {
  return (
    <>
      {isLogoutButtonVisible && <LogoutButton />}
      <Space size="middle" className="overlay-spinner">
        <div
          {...(idName && {id: idName})}
          className="flex flex--column flex--justifyCenter"
        >
          {text && <div className="mb-20 fs-16">{text}</div>}
          <Spin size="large" />
        </div>
      </Space>
    </>
  )
}
