import React from 'react'

import {Link, Redirect} from 'react-router-dom'

import globalRoutes from '@/global-routes'
import {translate} from '@/services/i18n'

const FailedLogin = () => {
  const loginPath = globalRoutes.general.login.path()

  return (
    <div className="login-success-failed">
      <Redirect
        to={{
          pathname: loginPath,
          search: '?status=failed',
        }}
      />
      <h1>{translate('login_failed')}</h1>
      <Link to={loginPath}>{translate('back_to_login')}</Link>
    </div>
  )
}

export default FailedLogin
