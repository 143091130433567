import {translate} from '@/services/i18n'
import {convertTime} from '@/utils/convert-time'

export const convertSlaResponseTime = (sec: number) => {
  const {days, hours, minutes} = convertTime(sec)

  if (sec > 8640000) return translate('unset').toLowerCase()

  return (
    (days ? days + 'd ' : '') +
    (hours ? hours + 'h ' : '') +
    (minutes ? minutes + 'min' : '')
  )
}
