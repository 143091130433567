import {useCallback, useEffect, useState} from 'react'

import {TSelector} from '@/modules/CMDB/typedef'
import {ISuperAgentResMultiple} from '@/modules/CMDB/api/typedf'
import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/modules/CMDB/hooks/useFetch'
import {getHeaders} from '@/modules/CMDB/api/generate-cmdb-api-url'
import {CmdbListMetadata} from '@/modules/CMDB/api-types/Api'

export function useGetFiltersData<T extends Record<string, any>>(
  asyncF: (options: TSelector) => Promise<ISuperAgentResMultiple<T>>
): {
  readonly getEntityData: (options?: TSelector) => Promise<T[] | undefined>
  readonly entityData: T[]
  readonly totalRecords: number
  readonly fetchStatus: RequestStatus
} {
  const [entityData, setEntityData] = useState<T[] | null>(null)
  const [metaData, setMetaData] = useState<CmdbListMetadata>()

  const {space} = getHeaders()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getEntityData = useCallback(
    async (options?: TSelector) => {
      if (space) {
        try {
          handleRequested()
          const {
            body: {result, metadata: metaDataRes},
          } = await asyncF({...options})
          if (result && metaDataRes) {
            setEntityData(result)
            setMetaData(metaDataRes)
          }
          handleSuccess()
          return result
        } catch (err) {
          handleFail(err)
        } finally {
          handleReset()
        }
      }
    },
    [asyncF, handleFail, handleRequested, handleReset, handleSuccess, space]
  )

  useEffect(() => {
    if (!entityData && space) {
      getEntityData()
    }
  }, [entityData, getEntityData, space])

  return {
    getEntityData,
    entityData: entityData || [],
    totalRecords: parseInt(metaData?.totalRecords || '0'),
    fetchStatus: status,
  } as const
}
