import {TTimeSession} from '@/modules/ITSM/sites/time-session/typedef'

export type TAction = {
  type: ActionType
  payload: TTimeSession
}

export enum ActionType {
  SET_TIME_SESSION = 'SET_TIME_SESSION',
  CLEAR_TIME_SESSION = 'CLEAR_TIME_SESSION',
  SET_TICKET_TYPE = 'SET_TICKET_TYPE',
}
