export enum Status {
  FINISH = 'finish',
  WAIT = 'wait',
  PROCESS = 'process',
}

export type TSteps = {
  content: JSX.Element
  header: string
  subHeader?: string
}

export enum ChannelInviteStep {
  CREATE_ACCOUNT,
  CREATE_ORGANIZATION,
}

export enum ChannelCreateStep {
  CREATE_ACCOUNT,
  SCHEDULE_CALENDLY,
}
