import {useEffect, useRef} from 'react'

type TUseEffectAfterMount = (
  cb: () => void,
  dependencies: Array<unknown>
) => void

export const useEffectAfterMount: TUseEffectAfterMount = (cb, dependencies) => {
  const isMounted = useRef<boolean>(true)

  useEffect(() => {
    if (!isMounted.current) {
      return cb()
    }
    isMounted.current = false
  }, [dependencies, cb])
}
