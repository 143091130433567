import {useCallback, useState} from 'react'

import {useRequestStatusHooks} from '@/hooks/use-request-status-hooks'
import {getOrgs} from '@/api/accountRequests'
import {TOrganization} from '@/typedef'

export const useGetAccountOrgs = () => {
  const [orgs, setOrgs] = useState<TOrganization[]>([])
  const {
    status,
    handleRequest,
    handleSuccess,
    handleFail,
    handleReset,
  } = useRequestStatusHooks()

  return {
    getOrgs: useCallback(
      async (accountId: string) => {
        try {
          handleRequest()
          const {body: orgs} = await getOrgs(accountId)
          setOrgs(orgs)
          handleSuccess()
          return orgs
        } catch (err) {
          handleFail(err)
        } finally {
          handleReset()
        }
      },
      [handleRequest, handleSuccess, handleFail, handleReset]
    ),
    orgs,
    orgsFetchStatus: status,
    resetOrgs: () => setOrgs([]),
  } as const
}
