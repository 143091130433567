import moment from 'moment/moment'

import {translate} from '@/services/i18n'
import {kFormatter} from '@/utils/kFormatter'
import {
  Align,
  Curve,
  Type,
  VerticalAlign,
} from '@/components/charts/apex-chart/typed'

const fontColor = 'rgba(58, 53, 65, 0.87)'

export const currentYearAreaOptions = {
  title: {
    text: `${translate('overview')} ${moment().year()}`,
  },
  yaxis: {
    labels: {
      formatter: (value: number) => `${kFormatter(value)} EUR`,
    },
  },
  xaxis: {
    type: Type.datetime,
    labels: {
      format: 'yyyy/MM',
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    y: {
      formatter: (value: number) => `${kFormatter(value)} EUR`,
    },
    x: {
      format: 'MMMM yyyy',
    },
  },
  markers: {
    size: 4,
    strokeWidth: 1,
  },
  stroke: {
    curve: Curve.smooth,
    width: 3,
  },
  noData: {
    text: translate('no_data_available'),
    align: Align.center,
    verticalAlign: VerticalAlign.middle,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '20px',
      fontWeight: 600,
      color: fontColor,
    },
  },
}
