import {
  ASSIGNEE_STATES_NAMES,
  INCIDENT_STATES_NAMES,
  IncidentState,
  AssigneeStates,
} from '../constants'
import {TIncident} from '../typedf'

import {findDisabledSteps} from './find-disabled-steps'

export const generateSteps = (entityData: TIncident | undefined) => {
  let activeStep: number | undefined,
    stepFields: string[] = [],
    blockedFields: number[] = [],
    checkedFields: number[] = []

  const {state_id, assignee_state} = entityData || {}
  const assigneeState = assignee_state && ASSIGNEE_STATES_NAMES[assignee_state]

  if (assigneeState) {
    switch (true) {
      case (state_id === IncidentState.Open ||
        state_id === IncidentState.Pre_on_hold) &&
        assignee_state === AssigneeStates.Unset:
        stepFields = [
          state_id === IncidentState.Pre_on_hold
            ? INCIDENT_STATES_NAMES.On_hold
            : INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.In_progress,
          INCIDENT_STATES_NAMES.Resolved,
          INCIDENT_STATES_NAMES.Closed,
        ]
        activeStep = 0
        blockedFields = [1, 2, 3, 4]
        checkedFields = []
        break
      case (state_id === IncidentState.Open ||
        state_id === IncidentState.Pre_on_hold) &&
        (assignee_state === AssigneeStates.Proposed ||
          assignee_state === AssigneeStates.Accepted ||
          assignee_state === AssigneeStates.Rejected):
        stepFields = [
          state_id === IncidentState.Pre_on_hold
            ? INCIDENT_STATES_NAMES.On_hold
            : INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.In_progress,
          INCIDENT_STATES_NAMES.Resolution,
          INCIDENT_STATES_NAMES.Resolved,
        ]
        activeStep = 1
        blockedFields = [2, 3, 4]
        checkedFields = [0]
        break
      case state_id === IncidentState.In_progress:
        stepFields = [
          INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.In_progress,
          INCIDENT_STATES_NAMES.Resolution,
          INCIDENT_STATES_NAMES.Resolved,
        ]
        activeStep = 2
        blockedFields = [4]
        checkedFields = [0]
        break

      case state_id === IncidentState.On_hold:
        stepFields = [
          INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.On_hold,
          INCIDENT_STATES_NAMES.Resolution,
          INCIDENT_STATES_NAMES.Resolved,
        ]
        activeStep = 2
        blockedFields = [4]
        checkedFields = [0]

        break

      case state_id === IncidentState.Resolved:
        stepFields = [
          INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.In_progress,
          INCIDENT_STATES_NAMES.Resolution,
          INCIDENT_STATES_NAMES.Resolved,
        ]
        activeStep = 4
        blockedFields = []
        checkedFields = [0, 2, 4]

        break
      case state_id === IncidentState.Closed:
        stepFields = [
          INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.In_progress,
          INCIDENT_STATES_NAMES.Resolution,
          INCIDENT_STATES_NAMES.Closed,
        ]

        activeStep = 4
        blockedFields = []
        checkedFields = [0, 2, 4]
        break
      case state_id === IncidentState.Cancelled:
        stepFields = [
          INCIDENT_STATES_NAMES.Open,
          assigneeState,
          INCIDENT_STATES_NAMES.In_progress,
          INCIDENT_STATES_NAMES.Resolution,
          INCIDENT_STATES_NAMES.Resolved,
        ]

        blockedFields = findDisabledSteps(entityData)
        checkedFields = [0, 2, 4].filter(
          e => findDisabledSteps(entityData).indexOf(e) === -1
        )
        break
      default:
        break
    }
  }

  return {
    activeStep,
    stepFields,
    blockedFields,
    checkedFields,
  }
}
