import React from 'react'

import {Tooltip} from 'antd'
import {useDispatch, useSelector} from 'react-redux'

import {translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {Button} from '@/components/button/button'

import {IncidentState} from '../incident-k-request/constants'
import {TimeSessionState} from '../../sites/time-session/typedef'
import {useStateContext} from '../incident/incident-context'
import {TIncident} from '../incident-k-request/typedf'
import {selectTimeSession} from '../../store/time-session/selectors'
import {resetItsmTableFilters} from '../../store/list-table/table-actions'
import {ItsmAssets} from '../../typedef'

import {useTimelogCheckIn} from './hooks/useTimelogCheckIn'

type TProps = {
  refetchTimeLogs?: () => void
  isEnabled?: boolean
}

const CheckInBtn = ({refetchTimeLogs, isEnabled}: TProps) => {
  const {entityData, entity, getData} = useStateContext<TIncident>()
  const dispatch = useDispatch()

  const timeSession = useSelector(selectTimeSession)

  const {handleCheckIn, status} = useTimelogCheckIn()

  const TSentities = timeSession?.entities?.map(
    (entity: string) => entity.split(':')[1]
  )

  const {uuid, state_id} = entityData || {}

  const not_exists_in_TS = TSentities && uuid && TSentities.indexOf(uuid) === -1

  const disabledTS = not_exists_in_TS === undefined || not_exists_in_TS

  const TStravelState =
    timeSession &&
    (timeSession.state_id === TimeSessionState.Travel ||
      timeSession.state_id === TimeSessionState.TravelBack)

  const getTooltipTitle = () => {
    if (disabledTS) {
      return translate('ticket_must_be_added_to_the_time_session')
    }
    if (state_id === IncidentState.On_hold) {
      return translate('ticket_have_to_be_in_progress')
    }
    if (TStravelState) {
      return translate('time_session_must_be_in_work_state')
    }
    return null
  }

  const checkIn = async () => {
    const result = await handleCheckIn(`${entity}:${uuid}`)
    if (result) {
      dispatch(resetItsmTableFilters(ItsmAssets.TimelogExpenses))
      dispatch(resetItsmTableFilters(ItsmAssets.SVR))
      dispatch(resetItsmTableFilters(ItsmAssets.TimeLogsResolve))

      if (refetchTimeLogs) refetchTimeLogs()
      else {
        getData()
        dispatch(resetItsmTableFilters(ItsmAssets.TimeLogs))
      }
    }
  }

  return (
    <Tooltip placement="top" title={!isEnabled && getTooltipTitle()}>
      <span>
        <Button
          title={translate('check_in')}
          type="primary"
          disabled={
            checkIsLoading(status) ||
            !!(
              !isEnabled &&
              (disabledTS ||
                (timeSession?.state_id !== undefined &&
                  (disabledTS ||
                    state_id === IncidentState.On_hold ||
                    TStravelState)))
            )
          }
          onClick={checkIn}
          className="mr-10"
          e2e-test={'checkin-btn'}
        />
      </span>
    </Tooltip>
  )
}

export default CheckInBtn
