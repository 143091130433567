import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'

import DataTable from '@/components/data-table/data-table'
import {checkIsLoading} from '@/utils/check-is-loading'
import {TAppState} from '@/redux/store'
import ListTableHeader from '@/components/list-table-header/list-table-header'
import {usePrevious} from '@/hooks/usePrevious'
import {setItsmTableQuickFilters} from '@/modules/ITSM/store/list-table/table-actions'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

import {generateActiveColumns} from '../../components/itsm-list-table/utils/generate-active-columns'
import {ItsmAssets} from '../../typedef'
import {
  selectItsmTableColumns,
  selectItsmTableQuickFilter,
} from '../../store/list-table/selectors'
import {TColumnRender} from '../../components/itsm-list-table/typedf'

import {useGetEvents} from './hooks/use-get-events'
import {eventsOptions, EventsQuickFilters} from './constants'
import {TEvent} from './typedf'
import {EventsRowValue} from './events-row-value'
import useGetQuickFilters from './hooks/use-get-quick-filters'

const asset = ItsmAssets.Events

const Events = () => {
  const dispatch = useDispatch()
  const {page} = useParams<{page: string}>()

  const [currentPage, setCurrentPage] = useState(Number(page))
  const history = useHistory()

  const {getQuickFilters} = useGetQuickFilters(asset)

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, ItsmAssets.Events)
  ) as EventsQuickFilters

  const prevQuickFilterActive = usePrevious(quickFilterActive)

  const {
    getData: getEvents,
    entityData: events,
    fetchStatus: fetchEventsStatus,
    getCallersById,
    callersResolved,
  } = useGetEvents()

  useEffect(() => {
    if (!events) {
      getEvents(
        eventsOptions?.[quickFilterActive]?.endpoint ||
          eventsOptions?.[EventsQuickFilters.All]?.endpoint
      )
    }
  }, [currentPage, events, getCallersById, getEvents, quickFilterActive])

  useEffect(() => {
    if (prevQuickFilterActive && prevQuickFilterActive !== quickFilterActive) {
      setCurrentPage(1)
      getEvents(eventsOptions?.[quickFilterActive]?.endpoint)
    }
  }, [quickFilterActive, prevQuickFilterActive, getEvents])

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const handlePaginationClick = async (page: number) => {
    setCurrentPage(page)
    getCallersById(page)
    history.replace(
      itsmRoutes.serviceManagementEventsPage.path(
        quickFilterActive,
        page.toString()
      )
    )
  }

  const onRowClick = (record: TEvent) => {
    history.push(itsmRoutes.serviceManagementEventsDetail.path(record.id))
  }

  const handleNew = () => {
    history.push(itsmRoutes.serviceManagementEventsNew.path())
  }

  const handleGetRowValue = (props: TColumnRender<TEvent>) => (
    <EventsRowValue callersResolved={callersResolved} {...props} />
  )

  const handleResetQuickFilter = (asset: ItsmAssets) => {
    dispatch(setItsmTableQuickFilters(asset, EventsQuickFilters.All))
  }

  return (
    <>
      <ListTableHeader<ItsmAssets>
        title={asset}
        asset={asset}
        previewMode={false}
        setSorting={() => null}
        generatedFilters={[]}
        resetFilters={() => null}
        onSearch={() => null}
        moduleSorters={{}}
        quickFilters={getQuickFilters && getQuickFilters()}
        resetQuickFilter={handleResetQuickFilter}
        handleNew={handleNew}
      />

      <DataTable<TEvent>
        data={events || []}
        columns={generateActiveColumns({
          columns,
          asset,
          getRowValue: handleGetRowValue,
        })}
        loading={checkIsLoading(fetchEventsStatus)}
        pagination={{
          pageSize: 10,
          current: currentPage,
          onChange: handlePaginationClick,
        }}
        onRowClick={onRowClick}
      />
    </>
  )
}

export default Events
