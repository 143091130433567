import React, {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import {selectRedirectToList} from '@/redux/ui/uiSelector'
import {setRedirectToList} from '@/redux/ui/uiActions'
import {Entities} from '@/typedef'
import {setItsmTableQuickFilters} from '@/modules/ITSM/store/list-table/table-actions'
import {IncidentsQuickFiltersUP} from '@/modules/ITSM/sites/incident-user-portal/constants'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

import IncidentDetail from '../../components/incident-k-request/incident-k-request-detail/incident-k-request-detail'
import {ItsmAssets} from '../../typedef'

const Incident = () => {
  const {id} = useParams<{id: string}>()

  const history = useHistory()
  const dispatch = useDispatch()

  const redirectToList = useSelector(selectRedirectToList)

  useEffect(() => {
    if (redirectToList) {
      dispatch(setRedirectToList())
      dispatch(
        setItsmTableQuickFilters(
          ItsmAssets.Incidents,
          IncidentsQuickFiltersUP.Opened
        )
      )

      history.push(itsmRoutes.serviceManagementIncidents.path())
    }
  }, [dispatch, history, redirectToList])

  return (
    <IncidentDetail
      id={id}
      entity={Entities.INCIDENT}
      asset={ItsmAssets.Incidents}
    />
  )
}

export default Incident
