export default {
  moduleRoutes: {
    itsm: {
      path: () => '/itsm',
    },
    cmdb: {
      path: () => `/cmdb`,
    },
    saas: {
      path: () => `/saas`,
    },
    subscriptions: {
      path: () => `/subscriptions`,
    },
  },
  general: {
    homePage: {
      name: 'Home',
      path: () => '/',
    },
    landingPage: {
      name: 'landingPage',
      path: () => '/landing',
    },
    purchase: {
      name: 'purchase',
      path: () => '/buy',
    },
    services: {
      name: 'services',
      path: () => '/services',
    },
    login: {
      name: 'Login',
      path: () => '/login',
    },
    adfs: {
      name: 'ADFS',
      path: () => '/adfs',
    },
    success: {
      name: 'Success',
      path: () => '/success',
    },
    failed: {
      name: 'Failed',
      path: () => '/failed',
    },
    acceptInvitationITSM: {
      name: 'acceptInvitation',
      path: () => '/acceptInvitation',
    },
    acceptInvitationITSMstepper: {
      name: 'acceptInvitation',
      path: () => '/accept-invitation',
    },
    acceptOrgInvite: {
      name: 'acceptInvitation',
      path: () => '/accept-org-invitation',
    },
    initialSettingChannels: {
      name: 'InitialSettingChannels',
      path: () => '/initialSettings/channels',
    },
    initialSetting: {
      name: 'InitialSetting',
      path: () => '/initial',
    },
    identity: {
      name: 'Identity',
      path: () => '/identity',
    },
    orgChannelDefault: {
      name: 'orgChannelDefault',
      path: () => '/orgChannelDefault',
    },
    settings: {
      name: 'settings',
      path: () => '/settings',
    },
    accountDefault: {
      name: 'accountDefault',
      path: () => '/account-default',
    },
    paymentSuccess: {
      name: 'paymentSuccess',
      path: () => '/payment-success',
    },
    paymentFailed: {
      name: 'paymentFailed',
      path: () => '/payment-failed',
    },
    profileDefault: {
      name: 'profileDefault',
      path: () => '/profile-default',
    },
  },
}
