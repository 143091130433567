import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {
  getToken,
  itsmMySpacesApi,
  itsmOrganizationApi,
  myOrganizationApi,
  orgRolesApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'

export const fetchOrgBySubApp = async auth => {
  return await request
    .get(itsmOrganizationApi().get)
    .set(auth ? auth : getToken())
}

export const fetchMySpaces = async auth => {
  return await request.get(itsmMySpacesApi().getSpaces).set(auth)
}

export const fetchOrgsRole = async () => {
  return await request.get(orgRolesApi().get).set(getHeadersWithGRPC())
}

export const getMyOrganizations = async () => {
  return await request.get(myOrganizationApi.get).set(getHeadersWithGRPC())
}
