export const replacerFunc = () => {
  const visited = new WeakSet()
  return (_: any, value: string) => {
    if (typeof value === 'object' && value !== null) {
      if (visited.has(value)) {
        return
      }
      visited.add(value)
    }
    return value
  }
}
