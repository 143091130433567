import {TBillingReportsFinanceDashboard} from '@/modules/ITSM/sites/finance-dashboard/types'

export const parseDataPerYearMapNewApi = (
  data: TBillingReportsFinanceDashboard
) => {
  type TYearsData = {
    [key: string]: TBillingReportsFinanceDashboard
  }

  const yearsDataMap = data.reduce((acc, val, i) => {
    const intersectionYear = val.interval_end.split('-')[0]

    acc[intersectionYear] = acc[intersectionYear]
      ? acc[intersectionYear].concat(val)
      : [val]

    return acc
  }, {} as TYearsData)

  return Object.keys(yearsDataMap).map(res => ({
    year: res,
    data: yearsDataMap[res],
  }))
}
