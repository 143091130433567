import React, {ReactNode} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import arrayMove from 'array-move'
import {ExpandableConfig} from 'antd/lib/table/interface'
import {useHistory, useParams} from 'react-router-dom'

import DataTable from '@/components/data-table/data-table'
import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import {TAppState} from '@/redux/store'
import {TableColumnsCustomizer} from '@/components/table-columns-customizer/table-columns-customizer'
import {TKey} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {RequestStatus, SortingKeys, SortingValues, TListArgs} from '@/typedef'
import ListTableHeader from '@/components/list-table-header/list-table-header'
import {TDefaultQuickFilters, TQuickFilter} from '@/components/filter/typedef'
import {ExpensesQuickFilters} from '@/modules/ITSM/sites/Expenses/typedef'

import {TPagination} from '../../../store/list-table/reducer'
import {ItsmAssets} from '../../../typedef'
import {
  selectItsmAssetPagination,
  selectItsmTableColumns,
  selectItsmTableFilters,
  selectItsmTableQuickFilter,
  selectItsmTableSorters,
} from '../../../store/list-table/selectors'
import {
  resetItsmTableFilter,
  resetItsmTableFilters,
  setItsmTableColumns,
  setItsmTableFilters,
  setItsmTableQuickFilters,
  setItsmTableSorters,
} from '../../../store/list-table/table-actions'
import {TColumnRender, TDataTablePreset} from '../typedf'
import {generateActiveColumns} from '../utils/generate-active-columns'
import {useGenerateInputFiler} from '../hooks/use-generate-input-filter'
import {itsmSorters, keysToDelete} from '../constants/filters-config'
import {entityDetailPath} from '../itsm-list-table-settings/constants/routes-config'

type TItsmListTableProps<T> = {
  asset: ItsmAssets
  getRowValue?: (props: TColumnRender<T>) => React.ReactNode
  fetchStatus: RequestStatus
  getEntityData: ({
    passedBookmark,
    resetBookmarks,
    passedFilters,
  }: TListArgs<T>) => Promise<T[] | undefined>
  entityData: T[]
  numberOfRecords: number
  getQuickFilters?: () => TQuickFilter[] | undefined
  setQuickFilters?: () => void
  className?: string
  getUpdatedColumns?: (columns: TDataTablePreset) => TDataTablePreset
  rowKey?: string
  expandable?: ExpandableConfig<T>
  exportBtns?: ReactNode
  handleNew?: () => void
}

const ItsmListTable = <T extends Record<string, any>>({
  asset,
  getRowValue,
  getEntityData,
  entityData,
  fetchStatus,
  numberOfRecords,
  getQuickFilters,
  setQuickFilters,
  className,
  rowKey,
  getUpdatedColumns,
  expandable,
  exportBtns,
  handleNew,
}: TItsmListTableProps<T>) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {quickFilter} = useParams<{quickFilter: string}>()

  const pagination = useSelector((state: TAppState) =>
    selectItsmAssetPagination(state, asset)
  )

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, asset)
  )

  const {
    generatedFilters,
    resetFilter,
    resetAllFilters,
    inputValues,
  } = useGenerateInputFiler<T>(asset)

  const handleSorter = (sorter: Record<SortingKeys, SortingValues> | null) => {
    dispatch(setItsmTableSorters(asset, sorter))
  }

  const handleResetFilters = (asset: ItsmAssets) => {
    dispatch(resetItsmTableFilters(asset))
    resetAllFilters()
    setQuickFilters && setQuickFilters()
  }

  const onReset = (key: string) => {
    dispatch(resetItsmTableFilter(asset, key))
    resetFilter(key)
  }

  const handleActiveCols = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const movedItems = arrayMove(columns, oldIndex, newIndex)
      dispatch(setItsmTableColumns(asset, movedItems))
    }
  }

  const handleSetSelectedColumn = (selectedTitle: string, isAdded: boolean) => {
    const dataWithSelectedColumn = columns.map(data => {
      return data.title === selectedTitle ? {...data, selected: isAdded} : data
    })

    dispatch(setItsmTableColumns(asset, dataWithSelectedColumn))
  }

  const onSearch = (key: string) => {
    if (!key) return

    if (keysToDelete[asset]?.[key]) {
      delete inputValues[keysToDelete[asset]?.[key] as string]
    }

    if (quickFilterActive !== TDefaultQuickFilters.None) {
      dispatch(
        setItsmTableFilters(
          asset,
          inputValues[key] !== undefined ? {[key]: inputValues[key]} : {}
        )
      )
      dispatch(setItsmTableQuickFilters(asset, ExpensesQuickFilters.None))
    } else {
      dispatch(setItsmTableFilters(asset, inputValues))
    }
  }

  const draggableData = columns.map(column => ({
    title: column.title as TKey,
    isChecked: column.selected,
  }))

  const handleResetQuickFilter = () => {
    dispatch(setItsmTableQuickFilters(asset, ExpensesQuickFilters.All))
  }

  const handleRowClick = (record: T) => {
    if (asset === ItsmAssets.Announcements) {
      history.push(entityDetailPath[asset]?.(record.uuid), {
        quickFilter,
      })
    }
  }

  return (
    <>
      <ListTableHeader<ItsmAssets>
        handleNew={handleNew}
        title={asset as TKey}
        asset={asset}
        setSorting={handleSorter}
        generatedFilters={generatedFilters}
        resetFilters={handleResetFilters}
        resetQuickFilter={handleResetQuickFilter}
        {...(sorter && {sorter})}
        {...(className && {className})}
        onReset={onReset}
        previewMode={false}
        moduleSorters={itsmSorters}
        onSearch={onSearch}
        tableCustomizer={
          <TableColumnsCustomizer
            items={draggableData}
            updateItemOrder={handleActiveCols}
            handleActiveColumns={handleSetSelectedColumn}
          />
        }
        quickFilters={getQuickFilters && getQuickFilters()}
        extraBtns={exportBtns}
      />

      <DataTable<T>
        data={entityData}
        columns={generateActiveColumns({
          columns: getUpdatedColumns ? getUpdatedColumns(columns) : columns,
          asset,
          getRowValue,
        })}
        onRowClick={handleRowClick}
        loading={checkIsLoading(fetchStatus)}
        rowKey={rowKey}
        expandable={expandable}
        customPager={
          <PaginationWithBookmarks<TPagination<T>>
            pagination={pagination}
            numberOfRecords={numberOfRecords}
            fetch={bookmark => {
              getEntityData({
                passedBookmark: bookmark,
                ...(sorter && {
                  passedSorter: sorter,
                }),
                ...(filters && {
                  passedFilters: {
                    ...filters,
                  },
                }),
              })
            }}
          />
        }
      />
    </>
  )
}

export default ItsmListTable
