import {SaasApplication, SaasUser} from '@/modules/SAAS/services/openAPI/Api'

export enum SaasAssets {
  Users = 'Users',
  AppUsers = 'AppUsers',
  AppGroups = 'AppGroups',
  Apps = 'Apps',
  SettingApps = 'SettingApps',
  SystemUsers = 'SystemUsers',
  SettingsNamespaces = 'SettingsNamespaces',
  SettingsAppsDiscover = 'SettingsAppsDiscover',
  GroupsSettings = 'GroupsSettings',
}

export type TSaasSpace = {
  id: string
  name: string
  owner: string
  IdentityProviders: Array<string>
  Applications: Array<string>
  domain: string
  Users: Array<string>
}

export type TSaasSpaceCreator = Pick<TSaasSpace, 'name' | 'domain'>

export type TSaasSpaces = Array<TSaasSpace>

export type TAppMetric = {
  name: string
  usersCount: string
}

export type TAppMetrics = Array<TAppMetric>

export type TSaasMetrics = {
  totalUserCount: string
  totalAppCount: string
  appMetrics: TAppMetrics
  inactiveUsersSummary: {inWarning: string; inAlert: string}
  unusedAppsSummary: {inWarning: string; inAlert: string}
}

export type TSaasIdentityMe = {
  role: string
  orgID: string
  name: string
  email: string
  namespaces: TSaasSpaces
  userId: string
}

export enum AssignUnassign {
  ASSIGN = 'assign',
  UNASSIGN = 'unassign',
}

export type TRefreshAppUsers = {
  actionType: AssignUnassign
  userIds: Array<string>
  count?: number
  result?: Array<SaasUser>
}

export type TRefreshAppGroups = {
  actionType: AssignUnassign
  userIds: Array<string>
  count?: number
  result?: SaasApplication
}

export type TFilterListTable = Record<string, string>

export interface ISaasApplicationDiscoverReq {
  name?: string
  displayName?: string
  vendorName?: string
  azureId?: string
  azureAppId?: string
  numberOfLicences?: number
}

export interface ISaasIdentityProvider {
  id: string
  clientId: string
  clientSecret: string
  tenantId: string
}
