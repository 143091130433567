import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import {CreateNewLocation} from '@/modules/ITSM/components/createNewLocation/createNewLocation'
import {TLocation} from '@/modules/ITSM/typedef'
import {saveLocation} from '@/modules/ITSM/api/locationRequests'

const LocationDrawerNew = () => {
  const {getEntityDataInit, onClose} = useListTableStateContext<TLocation>()

  return (
    <CreateNewLocation
      asyncF={saveLocation}
      onClose={onClose}
      onSave={getEntityDataInit}
      formRules={{name: [{required: true}]}}
    />
  )
}

export default LocationDrawerNew
