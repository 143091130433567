import React from 'react'

import {v4 as uuid} from 'uuid'

import {SkeletonItem} from '@/components/skeleton'

import './incident-header.scss'

export const IncidentHeaderButtonsSkeletonView = () => (
  <div className="flex flex--alignCenter">
    {new Array(3).fill(1).map(() => (
      <SkeletonItem key={uuid()} className="ml-20" width={90} height={25} />
    ))}
  </div>
)
