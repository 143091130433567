import React, {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {Modal as AntdModal} from 'antd'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table/itsm-list-table-nobookmark'
import {TDocument} from '@/api/typedf'
import {selectDocsRead, selectUserOrgRole} from '@/redux/user/userSelector'
import Modal from '@/components/modal/modal'
import useNavPrompt from '@/hooks/blockNavHook'
import {ModalType} from '@/components/modal/typedef'
import {translate, TKey} from '@/services/i18n'
import {Button} from '@/components/button/button'
import {usePostEntity} from '@/hooks/useUpdateData'
import {getUser} from '@/redux/user/userActions'

import {useGetDocs} from '../../hooks/docsHooks'
import {ItsmAssets} from '../../typedef'
import {TColumnRender} from '../../components/itsm-list-table/typedf'
import {contractRole, org_roles} from '../../utils/Constants'
import AttachmentsButton from '../../components/attachments/attachments-button'
import {postConfirmReading, postDoc} from '../../api/documents-api'

import {DocumentsRowValue} from './documents-row-value'
import {useDownloadDocument} from './hooks/use-download-document'
import './documents.scss'

const {confirm} = AntdModal

const Documents = () => {
  const {getDocs, docs, fetchDocsStatus} = useGetDocs()
  const {downloadDocument} = useDownloadDocument()
  const docs_read = useSelector(selectDocsRead)

  const {saveEntity: confirmReading} = usePostEntity(postConfirmReading)

  const dispatch = useDispatch()

  const {blocked, hidePrompt} = useNavPrompt({
    shouldBlock: docs_read === '',
  })

  const orgRole = useSelector(selectUserOrgRole)

  const showConfirmModal = () => {
    confirm({
      title: translate('confirm_reading_documents'),
      async onOk() {
        const result = await confirmReading({})
        if (result) {
          dispatch(getUser())
          getDocs()
        }
      },
      okText: translate('confirm'),
      cancelText: translate('cancel'),
    })
  }

  useEffect(() => {
    getDocs()
  }, [getDocs])

  const handleGetRowValue = (props: TColumnRender<TDocument>) => (
    <DocumentsRowValue downloadDocument={downloadDocument} {...props} />
  )

  return (
    <>
      <Modal
        open={blocked}
        modalType={ModalType.INFO}
        onCancel={() => hidePrompt()}
        handleCancel={() => hidePrompt()}
        cancelText={translate('close')}
      >
        {translate('confirm_doc_has_beeen_read')}
      </Modal>

      <div>
        {docs_read !== undefined && docs_read === '' && (
          <div className="confirmation_block">
            {translate('confirm_reading_docs')}

            <Button title={translate('confirm')} onClick={showConfirmModal} />
          </div>
        )}
      </div>

      <ItsmListTable<TDocument>
        asset={ItsmAssets.Documents}
        getRowValue={handleGetRowValue}
        entityData={docs}
        fetchStatus={fetchDocsStatus}
        tableHeader={
          orgRole === org_roles.provider && (
            <AttachmentsButton<TDocument>
              asset={ItsmAssets.Documents}
              onAddAttachments={getDocs}
              asyncF={postDoc}
              options={contractRole.map(role => translate(role as TKey))}
            />
          )
        }
      />
    </>
  )
}

export default Documents
