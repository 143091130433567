import {useCallback, useState} from 'react'

import {useDispatch} from 'react-redux'

import {RequestStatus} from '@/typedef'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

type TResultData = {
  status: RequestStatus
  errorMessage: string
  handleRequest: () => void
  handleSuccess: () => void
  handleFail: (message: string | any) => void
  handleReset: () => void
}

export function useRequestStatusHooks(): TResultData {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INITIAL)
  const dispatch = useDispatch()

  return {
    status,
    errorMessage,
    handleRequest: useCallback(() => {
      setStatus(RequestStatus.REQUESTED)
    }, []),
    handleSuccess: useCallback(() => {
      setStatus(RequestStatus.SUCCEEDED)
    }, []),
    handleFail: useCallback(
      message => {
        setStatus(RequestStatus.FAILED)
        dispatch(setToastMessage({message}))
        setErrorMessage(message)
      },
      [dispatch]
    ),
    handleReset: useCallback(() => {
      setStatus(RequestStatus.INITIAL)
    }, []),
  } as const
}
