import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {timeLogApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TTimeLog, TTimelogReq} from '../typedef'

export const getTimeLogs = async (
  bookmark: string | undefined,
  query = {},
  resolve = ''
) => {
  return await request
    .options(timeLogApi().getTimelogs + resolve)
    .send({
      bookmark,
      selector: query,
      sort: [{created_at: 'desc'}],
    })
    .set(getHeadersWithGRPC())
}

export const getTimeLog = async (id: string) => {
  return await request.get(timeLogApi(id).getOne).set(getHeadersWithGRPC())
}

export const postChangeVisitSummary = async (data: TTimelogReq) => {
  return await request
    .post(timeLogApi().changeVisitSummary)
    .set(getHeadersWithGRPC())
    .send(data)
}
export const patchUpdateOHH = async (
  data: {ignore_extra_pricing_policy: boolean},
  id: string
) => {
  return await request
    .patch(timeLogApi(id).updateTimelog)
    .set(getHeadersWithGRPC())
    .send(data)
}

enum CancelEndpointes {
  cancelTimelogByServiceDesk = 'cancelTimelogByServiceDesk',
  cancelTimelogByEngineer = 'cancelTimelogByEngineer',
}

export const deleteTimeLog = async (
  canelTimeLogEndpoint: CancelEndpointes,
  query: Record<string, unknown>
) => {
  return await request
    .post(timeLogApi()[canelTimeLogEndpoint])
    .set(getHeadersWithGRPC())
    .send(query)
}

export const postEditTimeLog = async (data: Record<string, unknown>) => {
  return await request
    .post(timeLogApi().updateTimespan)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postEditTravel = async (data: TTimeLog) => {
  return await request
    .post(timeLogApi().editTravelTime)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchTimeLogs = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(timeLogApi().getTimelogs + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })

export const postCheckIn = async (
  uuid: string,
  data: {
    entity: string
    remote: boolean
    chargeable: boolean
  }
) => {
  return await request
    .post(timeLogApi(uuid).getOne)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postCheckOut = async (data: TTimelogReq) => {
  return await request
    .post(timeLogApi().postCheckout)
    .set(getHeadersWithGRPC())
    .send(data)
}
