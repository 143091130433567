import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TCustomerProduct} from '@/modules/ITSM/typedef'

const CustomerProducts = () => (
  <ItsmListTable<TCustomerProduct>
    asset={ItsmAssets.CustomerProducts}
    rowKey="uuid"
  />
)

export default CustomerProducts
