import React from 'react'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {ImportanceIndicator} from '@/components/importance-indicator/importance-indicator'
import IncidentTaskBtns from '@/modules/ITSM/components/incident-task/incident-task-btns/incident-task-btns'

import './incident-task-header.scss'

type TProps = {
  goBackButtonHandler: () => void
  numberId?: string
  priority?: number
  priorityText?: string
}

export const IncidentTaskHeader: React.FC<TProps> = ({
  goBackButtonHandler,
  numberId,
  priority,
  priorityText,
}) => {
  return (
    <div className="incident-task-header">
      <div className="incident-task-header__left-side">
        <GoBackButton onClick={goBackButtonHandler} />
        {numberId && (
          <div className="incident-task-header__number-id">{numberId}</div>
        )}
        {priority && (
          <ImportanceIndicator
            className="incident-task-header__indicator"
            importance={priority}
            indicatorText={`P${priority}`}
            importanceText={priorityText}
          />
        )}
      </div>
      <div className="incident-task-header__right-side">
        <IncidentTaskBtns />
      </div>
    </div>
  )
}
