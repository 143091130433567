import React from 'react'

import {ItsmAssets, TIdentity} from '@/modules/ITSM/typedef'
import ItsmListTableSettings from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {getUserFullName} from '@/utils/get-user-full-name'

import IdentitiesDrawer from './IdentitiesDrawer'

const Identities = () => {
  return (
    <ItsmListTableSettings<TIdentity>
      asset={ItsmAssets.Identities}
      detailComponent={<IdentitiesDrawer />}
      getDetailComponentTitle={(record: TIdentity) =>
        record.users?.[0] && getUserFullName(record.users[0])
      }
      rowKey="fingerprint"
    />
  )
}

export default Identities
