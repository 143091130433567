import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {categoriesApi} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getCategories = async (
  bookmark: string,
  query: Record<string, any>,
  resolve = ''
) => {
  return await request
    .options(categoriesApi().get + resolve)
    .send({
      bookmark,
      selector: query,
    })
    .set(getHeadersWithGRPC())
}

export const patchCategory = async (
  id: string,
  data: Record<string, unknown>
) => {
  return await request
    .patch(categoriesApi(id).patch)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postCategory = async (data: Record<string, unknown>) => {
  return await request
    .post(categoriesApi().post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchCategories = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(categoriesApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })
