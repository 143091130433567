import React, {useCallback, useEffect, useRef, useState} from 'react'

import {Col, Divider, Form as AntForm, Input, Row, Select} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {EditorState} from 'draft-js'
import noop from 'lodash/noop'
import queryString from 'query-string'

import {TKey, translate} from '@/services/i18n'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {Entities, RequestStatus} from '@/typedef'
import {selectPriorityValues} from '@/redux/reducers/generalSelector'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {randomString} from '@/modules/ITSM/utils/Helpers'
import {useGetIncidentRequest} from '@/modules/ITSM/components/incident-k-request/hooks/useGetIncidentRequest'
import {createStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {initialState} from '@/modules/ITSM/components/incident/incident-reducer'
import {TOnUpdateParams} from '@/modules/ITSM/components/attachments/attachments-button'
import {Form} from '@/components/form/form/form'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {categoriesApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {saveLocation} from '@/modules/ITSM/api/locationRequests'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import AssetSelect from '@/modules/ITSM/components/form-components/asset-select'
import {
  defaultCategory,
  defaultSubCategory,
} from '@/modules/ITSM/components/incident-k-request/constants'
import {useGetCategoryAssets} from '@/hooks/use-get-category-assets'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import IncidentAssignment from '@/modules/ITSM/components/incident/incident-assignment/incident-assignment'
import {otherAsset} from '@/modules/ITSM/constants/other-asset'

import CallerSelect from '../../form-components/caller-select'
import DescriptionEditor from '../../description-editor/description-editor'
import CreatedByInput from '../../form-components/created-by-input'
import {LocationSelect} from '../../locationSelect'
import {TLocation} from '../../../typedef'
import {TIncident, TIncidentReq, TIncidentValue} from '../typedf'
import {useGetPriorityValues} from '../hooks/useGetPriorityValues'
import {usePatchIncident, usePostIncident} from '../hooks/usePatchIncident'
import {
  createData,
  getInitialValues,
} from '../incident-k-request-detail-form/utils'
import {transformDescription} from '@/modules/ITSM/components/incident-k-request/utils/transform-description'
import {TItsmUser} from '@/redux/user/typedef'
import {getNewLocationButton} from '../../locationSelect/utils/get-new-location-button'

type TProps = {
  entity: Entities
  id: string
  asset: string
}
const Option = Select.Option
const TextArea = Input.TextArea
const otherAssetId = otherAsset.id

const IncidentContext = createStateContext<TIncident>()

const IncidentKrequestNew = ({entity, id}: TProps) => {
  const {search} = useLocation()
  const queryParams = queryString.parse(search) as {
    date?: string
    budget?: string
    description?: string
    'short-description'?: string
    'payment-period'?: string
    'set-defaults'?: string
  }
  const hasDefaultValues = queryParams['set-defaults'] === 'true'
  const dispatch = useDispatch()
  const filesDataRef = useRef<TOnUpdateParams>()
  const [form] = AntForm.useForm()
  const [, setRefresh] = useState(0)
  const [entityData, setEntityData] = useState<TIncident | undefined>(undefined)
  const [newLocation, setNewLocation] = useState<TLocation | undefined>(
    hasDefaultValues
      ? {
          full_location: 'Default',
          name: 'Default',
          uuid: '66bc7da0-0d64-4bb2-b60c-fdb7dbec7ae0',
        }
      : {}
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const user = useSelector((state: {itsmUser: TItsmUser}) => {
    return state.itsmUser
  })
  const {userNameInChannel, userID} = user || {}

  const {getData} = useGetIncidentRequest(entity, id)
  const {assets, getAssets} = useGetCategoryAssets()
  const {updateEntity} = usePatchIncident(entity)

  const {postEntity: postIncident} = usePostIncident(entity)

  const history = useHistory()

  const {
    editorState,
    onEditorStateChange,
    setText,
    editorText,
  } = useSetEditorState(transformDescription(queryParams))

  const getFilesData = () => {
    const {
      filesAmount = 0,
      addAttachments: addFileAttachments,
      status: filesAttachmentStatus = RequestStatus.INITIAL,
    } = filesDataRef.current || {}

    return {filesAmount, addFileAttachments, filesAttachmentStatus}
  }

  const handleCategory = (uuid: string, callerUuid: string) => {
    form.setFieldsValue({
      cmdb_ref: otherAssetId,
      subcategory: null,
    })

    getAssets(uuid, callerUuid)
    setRefresh(Math.random())
  }

  const handleCaller = (val: string) => {
    form.setFieldsValue({
      cmdb_ref: otherAssetId,
    })

    getAssets(form.getFieldValue('category'), val)
    setRefresh(Math.random())
  }

  const urgencyText: string[] = useSelector(state =>
    selectPriorityValues(state, 'urgency_text')
  )

  const impactText: string[] = useSelector(state =>
    selectPriorityValues(state, 'impact_text')
  )

  useGetPriorityValues()

  useEffect(() => {
    form.setFieldsValue({
      number: (entity === Entities.INCIDENT ? 'INC' : 'KRQ') + randomString(7),
    })
  }, [form, entity])

  useEffect(() => {
    if (userNameInChannel) form.setFieldsValue({created_by: userNameInChannel})
  }, [form, userNameInChannel])

  useEffect(() => {
    const {
      filesAmount,
      filesAttachmentStatus,
      addFileAttachments,
    } = getFilesData()
    const saveAttachments = async () => {
      if (addFileAttachments) {
        await addFileAttachments()
      }
    }

    if (
      entityData &&
      filesAmount > 0 &&
      filesAttachmentStatus === RequestStatus.INITIAL
    ) {
      saveAttachments()
    }
  }, [entityData])

  const createIncident = async (values: TIncidentValue) => {
    setIsLoading(true)

    const {cmdb_ref, ...restValues} = values

    const result = await postIncident(
      {
        ...restValues,
        description: editorText,
        cmdb_ref: cmdb_ref ? [cmdb_ref] : undefined,
      },
      id
    )

    if (getFilesData().filesAmount === 0) {
      if (result?.uuid) {
        redirectToCreatedIncident(result.uuid)
      }
    } else {
      const incidentData = await getData(true)

      setEntityData(incidentData)
    }

    setIsLoading(false)
  }

  const getFormattedData = useCallback(
    (values: TIncidentValue) => {
      const {cmdb_ref, description, ...restValues} = values

      return createData({
        ...restValues,
        description: editorText,
        cmdb_ref: cmdb_ref ? [cmdb_ref] : undefined,
      })
    },
    [editorText]
  )

  const updateIncident = async (values: TIncidentValue) => {
    const data = getFormattedData(values)

    if (entityData) {
      const dataDifference = getDataDifference(
        getInitialValues(entityData),
        data
      ) as TIncidentReq

      await updateEntity(dataDifference, entityData.uuid)
      redirectToCreatedIncident(entityData.uuid)
    }
  }

  const redirectToCreatedIncident = (uuid: string) => {
    history.push(
      entity === Entities.INCIDENT
        ? itsmRoutes.serviceManagementIncidentsDetail.path(uuid)
        : itsmRoutes.serviceManagementRequestsDetail.path(uuid)
    )
  }

  const onAddFiles = (params: TOnUpdateParams) => {
    filesDataRef.current = {...params}
  }

  const onAddedAttachments = () => {
    form.submit()
  }

  useEffect(() => {
    if (userID) {
      getAssets(defaultCategory[0].uuid, userID)
    }
  }, [getAssets, userID])

  const header = (
    <Row>
      <Col span={24}>
        <GoBackButton onClick={() => history.goBack()} />
        <Divider />
      </Col>
    </Row>
  )

  const initialValues = hasDefaultValues
    ? {
        short_description:
          queryParams['short-description'] || translate('incident'),
        impact_text: 'low',
        urgency_text: 'low',
        contact_type: 'portal',
      }
    : {short_description: queryParams['short-description']}

  return (
    <IncidentContext.Provider
      value={{
        entity,
        entityData,
        dispatch,
        incState: initialState,
        updateEntity: noop,
        getData: noop,
      }}
    >
      <Form<TIncidentValue>
        form={form}
        onSubmit={entityData ? updateIncident : createIncident}
        onCancel={() => {
          form.resetFields()
          onEditorStateChange(EditorState.createEmpty())
        }}
        isLoading={isLoading}
        header={header}
        saveButtonDisabled={false}
        initialValues={initialValues}
      >
        <Row hidden={true}>
          <FormItemGeneric<TIncidentReq>
            name="number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly />
          </FormItemGeneric>
        </Row>
        <Row className="mt-15" gutter={24}>
          <Col {...twoColumns}>
            <CallerSelect
              callerValue={form.getFieldValue('caller')}
              setRefresh={() => setRefresh(Math.random())}
              callerInit={{
                full_name: userNameInChannel,
                uuid: userID,
              }}
              onChange={handleCaller}
            />
          </Col>
          <Col {...twoColumns}>
            <CreatedByInput
              createdByInit={{
                full_name: user.userNameInChannel,
                uuid: user.userID,
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <SelectWithBM
              api={categoriesApi().get}
              label={translate('category')}
              name="category"
              initialValue={[defaultCategory[0]]}
              selector={{parent: {$exists: false}}}
              rules={{
                required: true,
              }}
              searchKey="name"
              optionContent={(res: {name: string}) => res.name}
              onChange={(categoryUuid: string) =>
                handleCategory(categoryUuid, form.getFieldValue('caller'))
              }
            />
          </Col>
          <Col {...twoColumns}>
            <SelectWithBM
              api={categoriesApi().get}
              label={translate('subcategory')}
              name="subcategory"
              initialValue={[defaultSubCategory[0]]}
              disabled={!form.getFieldValue('category')}
              selector={{
                parent: {$in: [form.getFieldValue('category')]},
              }}
              rules={{
                required: !!form.getFieldValue('category'),
                message: translate('subcategory_req'),
              }}
              searchKey="name"
              optionContent={(res: {name: string}) => res.name}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <AssetSelect
              initialValue={otherAsset.name}
              userAssetList={assets}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentReq>
              name="impact_text"
              rules={[
                {
                  required: true,
                },
              ]}
              label={translate('impact')}
            >
              <Select
                getPopupContainer={e => e.parentNode}
                className="full-width"
                data-testid="impact_text"
              >
                {impactText &&
                  Object.values(impactText).map((name, key) => (
                    <Option value={name} key={key} e2e-test={`impact_${name}`}>
                      {translate(name as TKey)}
                    </Option>
                  ))}
              </Select>
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentReq>
              name="urgency_text"
              rules={[
                {
                  required: true,
                },
              ]}
              label={translate('urgency')}
            >
              <Select
                getPopupContainer={e => e.parentNode}
                className="full-width"
                data-testid="urgency_text"
              >
                {urgencyText &&
                  Object.values(urgencyText).map((name, key) => (
                    <Option value={name} key={key} e2e-test={`urgency_${name}`}>
                      {translate(name as TKey)}
                    </Option>
                  ))}
              </Select>
            </FormItemGeneric>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col {...twoColumns}>
            <LocationSelect
              required
              locationId={form.getFieldValue('location')}
              setRefresh={() => setRefresh(Math.random())}
              labelSuffix={getNewLocationButton({
                setNewLocation,
                form,
                saveLocation,
                setRefresh,
              })}
              recordLocationData={newLocation}
            />
          </Col>

          <Col {...twoColumns}>
            <FormItemGeneric<TIncidentReq>
              name="contact_type"
              label={translate('source')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                getPopupContainer={e => e.parentNode}
                e2e-test="source"
                data-testid="contact_type"
              >
                <Option value="unknown" e2e-test="unknown">
                  {translate('unknown')}
                </Option>
                <Option value="portal" e2e-test="portal">
                  {translate('portal')}
                </Option>
                <Option value="email" e2e-test="email">
                  {translate('email')}
                </Option>
                <Option value="email_worker" e2e-test="email_worker">
                  {translate('email_worker')}
                </Option>
                <Option value="phone" e2e-test="telephone">
                  {translate('telephone')}
                </Option>
                <Option value="customer" e2e-test="customer">
                  {translate('customer')}
                </Option>
              </Select>
            </FormItemGeneric>
          </Col>
        </Row>
        <IncidentAssignment
          form={form}
          setRefresh={() => setRefresh(Math.random())}
        />
        <Row>
          <Col span={24}>
            <FormItemGeneric<TIncidentReq>
              name="short_description"
              label={translate('title')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={5} />
            </FormItemGeneric>
          </Col>
        </Row>
        <DescriptionEditor
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          setText={setText}
          onAddFiles={onAddFiles}
          immediateAttachments={true}
          showAttachButton={editorText === undefined}
          onAddAttachments={onAddedAttachments}
          editorText={editorText}
        />
      </Form>
    </IncidentContext.Provider>
  )
}

export default IncidentKrequestNew
