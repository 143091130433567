import React, {useCallback, useEffect, useState} from 'react'

import {withErrorBoundary} from '@sentry/react'
import {Col, Form, Row} from 'antd'
import isEqual from 'lodash/isEqual'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {Button} from '@/components/button'
import ErrorPage from '@/components/error/error-page/error-page'
import {RESOLVE} from '@/constants'
import {getUserFullName} from '@/utils/get-user-full-name'
import {userApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TUser} from '../../typedef'
import {useStateContext} from '../incident/incident-context'
import {setIncidentTabIsBlocked} from '../incident/incident-reducer'

import {TIncident} from './typedf'

type TProps = {
  isDisabled: boolean
}

const WatchLiat = ({isDisabled}: TProps) => {
  const [form] = Form.useForm()
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    entityData,
    getData,
    updateEntity,
    isLoading,
    dispatch,
  } = useStateContext<TIncident>()

  const {watch_list} = entityData || {}

  const watchListResolved = watch_list?.map((user: TUser) => user.uuid)

  const handleSubmit = async (values: TIncident) => {
    const result = await updateEntity({
      watch_list: values.watch_list,
    })

    if (result !== undefined) await getData()
    recognizeFieldChange()
  }

  const recognizeFieldChange = useCallback(() => {
    setBtnDisabled(
      isEqual(form.getFieldsValue(), {watch_list: watchListResolved})
    )
  }, [form, watchListResolved])

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])

  useEffect(() => {
    recognizeFieldChange()
  }, [recognizeFieldChange, watchListResolved])

  return (
    <Form
      onValuesChange={recognizeFieldChange}
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
    >
      <Row>
        <Col span={24}>
          {/* @ts-ignore not refactored to typescript*/}
          <SelectWithBM
            mode="multiple"
            api={userApi().get + RESOLVE}
            label={translate('watch_list')}
            searchKey="full_name"
            name="watch_list"
            initialValue={watch_list}
            optionContent={getUserFullName}
            getPopupContainer={() => document.body}
            disabled={isDisabled}
          />
        </Col>
      </Row>

      {!isDisabled && (
        <div className="mb-15">
          <Col span={24}>
            <p>
              <Button
                type="primary"
                title={translate('save')}
                size="large"
                htmlType="submit"
                disabled={isLoading || btnDisabled}
              />
            </p>
          </Col>
        </div>
      )}
    </Form>
  )
}

export default withErrorBoundary(WatchLiat, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
