import React, {FC} from 'react'

import {Typography} from 'antd'

import {TKey, translate} from '@/services/i18n'
import {Icon, IconType} from '@/components/icon'
import {TGeneratorFilters} from '@/components/filter/typedef'

import './filter-bar.scss'

const {Title} = Typography

type TProps = {
  generatedFilters: TGeneratorFilters
  removeFilter: (key: string) => void
}

export const FilterBar: FC<TProps> = ({generatedFilters, removeFilter}) => {
  return (
    <div className="filter-bar">
      <Title className="filter-bar__title" level={5}>
        {translate('applied_filters')}:
      </Title>
      {generatedFilters.map(
        ({title, isApplied}) =>
          isApplied && (
            <button
              className="filter-bar__button"
              onClick={() => removeFilter(title)}
              key={title}
            >
              {translate(title as TKey)}
              <Icon
                className="filter-bar__close"
                type={IconType.CLOSE}
                size={12}
              />
            </button>
          )
      )}
    </div>
  )
}
