import React from 'react'

import {v4 as uuid} from 'uuid'

import './incident-k-request-tile-list.scss'
import {TChildren} from '@/typedef'
import {Tile, TileSkeletonView} from '@/modules/ITSM/components/tile'
import {PlusOutlined} from '@ant-design/icons'
import {translate} from '@/services/i18n'
import {Button} from '@/components/button/button'
import {Paper} from '@/components/paper/paper'
type TProps<T> = {
  data: Array<T>
  rowClick: (record: T, rowIndex: number | undefined, target?: any) => void
  loading?: boolean
  handleNew: () => void
}

const Container: React.FC<TChildren> = ({children}) => (
  <div className="incident-k-request-tile-list">{children}</div>
)

export const IncidentKRequestTileList = <T extends Record<string, unknown>>({
  data,
  rowClick,
  loading,
  handleNew,
}: TProps<T>) => {
  if (loading) {
    return (
      <Container>
        {new Array(10).fill(0).map(() => (
          <TileSkeletonView key={uuid()} />
        ))}
      </Container>
    )
  }

  return (
    <>
      <Paper className="flex flex--justifyEnd">
        <Button
          type="primary"
          title={translate('add_new')}
          onClick={handleNew}
          icon={<PlusOutlined />}
        />
      </Paper>
      {data?.length > 0 ? (
        <Container>
          {data.map((item, i) => (
            <Tile<T> key={i.toString()} rowClick={rowClick} entityData={item} />
          ))}
        </Container>
      ) : (
        <div className="incident-k-request-tile-list__no-data">No Data</div>
      )}
    </>
  )
}
