type TItsmTableCreators = typeof import('./table-actions')

type TActionCreators = TItsmTableCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

export enum ActionTypes {
  SET_SAAS_LIST_TABLE_COLUMNS = 'SET_SAAS_LIST_TABLE_COLUMNS',
  RESET_SAAS_LIST_TABLE_FILTERS = 'RESET_SAAS_LIST_TABLE_FILTERS',
  RESET_SAAS_LIST_TABLE_FILTER = 'RESET_SAAS_LIST_TABLE_FILTER',
  SET_SAAS_LIST_TABLE_FILTERS = 'SET_SAAS_LIST_TABLE_FILTERS',
}
