import {
  ModuleFeatureFlags,
  TExtraAddons,
  TNormalizedProduct,
  TPurchasesAddons,
} from '@/sites/purchase/typedef'
import {filterPriceById} from './filter-price-by-id'

export const filterPricesForAddons = (
  prices: Array<TNormalizedProduct>,
  addons: TPurchasesAddons
) => ({
  [ModuleFeatureFlags.ITSM]: filterPriceById(
    prices,
    addons[ModuleFeatureFlags.ITSM]
  ),
  [ModuleFeatureFlags.CMDB]: filterPriceById(
    prices,
    addons[ModuleFeatureFlags.CMDB]
  ),

  [ModuleFeatureFlags.SAAS]: filterPriceById(
    prices,
    addons[ModuleFeatureFlags.SAAS]
  ),
})

export const transformExtraAddons = (
  selectedExtraAddons: TExtraAddons,
  services: TNormalizedProduct[]
) => {
  return selectedExtraAddons.reduce((acc, val) => {
    return [...acc, ...services.filter(res => res.productId === val)]
  }, [] as Array<TNormalizedProduct>)
}
