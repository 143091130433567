import {useCallback, useState} from 'react'

import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {fetchEvent} from '@/modules/ITSM/api/eventsRequests'

import {TEvent} from '../typedf'

export function useGetEvent(): {
  readonly getData: (id: string) => Promise<TEvent | undefined>
  readonly entityData: TEvent | undefined
  readonly fetchStatus: RequestStatus
} {
  const [entityData, setEntityData] = useState<TEvent>()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getData = useCallback(
    async (id: string) => {
      try {
        handleRequested()
        const {
          body: {result},
        } = await fetchEvent({id})
        if (result) {
          setEntityData(result)
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    getData,
    entityData,
    fetchStatus: status,
  }
}
