import {TInitialState} from '@/sites/account-default/account-default-context/account-default-context'
import {User} from 'oidc-client-ts'

export const normalizeSentryUserInfo = (
  state: TInitialState,
  hydraData?: User | null
) => ({
  category: 'account-default',
  progressStatus: state.progressStatus,
  billingSubscriptions: state.billingSubscriptions,
  accountId: state.accountId,
  orgId: state.orgId,
  orgName: state.orgName,
  step: state.step,
  hydraData,
})
