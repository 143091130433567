import {RequestStatus} from '@/typedef'

export const initialState = {
  status: RequestStatus.INITIAL,
  data: undefined,
  numberOfRecords: 0,
  fetchMoreRecords: false,
  columns: [],
  bookmark: undefined,
  filerData: [],
  args: {},
  filters: {},
  sorter: {},
}

export const FETCH_DATA_REQUESTED = 'FETCH_DATA_REQUESTED'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'
export const RESET_FETCH_DATA_STATUS = 'RESET_FETCH_DATA_STATUS'
export const RESET_DATA_STATE = 'RESET_DATA_STATE'

export const SET_BOOKMARK = 'SET_BOOKMARK'
export const SET_FETCH_MORE_RECORDS = 'SET_FETCH_MORE_RECORDS'
export const SET_NUMBER_OF_RECORDS = 'SET_NUMBER_OF_RECORDS'
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SET_ARGUMENTS = 'SET_ARGUMENTS'
export const SET_DATA = 'SET_DATA'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SORTER = 'SET_SORTER'

export const getDataRequested = () => ({
  type: FETCH_DATA_REQUESTED,
})

export const getDataSuccess = (data, numberOfRecords, status) => ({
  type: FETCH_DATA_SUCCESS,
  payload: {data, numberOfRecords, status},
})

export const getDataFailed = err => ({
  type: FETCH_DATA_FAILED,
  payload: err,
})

export const setBookmark = bookmark => ({
  type: SET_BOOKMARK,
  payload: bookmark,
})

export const setFilters = filters => ({
  type: SET_FILTERS,
  payload: filters,
})

export const setSorter = sorter => ({
  type: SET_SORTER,
  payload: sorter,
})

export const resetFetchDataStatus = () => ({
  type: RESET_FETCH_DATA_STATUS,
})

export const resetDataState = () => ({
  type: RESET_DATA_STATE,
})

export const setFetchMoreRecords = hasMoreRecords => ({
  type: SET_FETCH_MORE_RECORDS,
  payload: hasMoreRecords,
})

export const setNumberOfRecords = count => ({
  type: SET_NUMBER_OF_RECORDS,
  payload: count,
})

export const setArguments = args => ({
  type: SET_ARGUMENTS,
  payload: args,
})

export function itsmDataTableReducer(state, action) {
  switch (action.type) {
    case FETCH_DATA_REQUESTED: {
      return {...state, status: RequestStatus.REQUESTED}
    }
    case FETCH_DATA_SUCCESS: {
      return {
        ...state,
        status: action.payload.status || RequestStatus.SUCCEEDED,
        data: action.payload.data,
        numberOfRecords: action.payload.numberOfRecords,
        fetchMoreRecords: action.payload.numberOfRecords === 10,
      }
    }

    case FETCH_DATA_FAILED: {
      return {...state, error: action.payload, status: RequestStatus.FAILED}
    }

    case SET_BOOKMARK: {
      return {...state, bookmark: action.payload}
    }

    case SET_FILTERS: {
      return {...state, filters: action.payload}
    }

    case SET_SORTER: {
      return {...state, sorter: action.payload}
    }

    case RESET_FETCH_DATA_STATUS: {
      return {...state, status: RequestStatus.INITIAL}
    }

    case SET_FETCH_MORE_RECORDS: {
      return {
        ...state,
        fetchMoreRecords: action.payload,
      }
    }

    case SET_NUMBER_OF_RECORDS: {
      return {
        ...state,
        numberOfRecords: state.numberOfRecords + action.payload,
      }
    }

    case SET_ARGUMENTS: {
      return {
        ...state,
        args: action.payload,
      }
    }

    case RESET_DATA_STATE: {
      return (state = {...initialState, data: []})
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
