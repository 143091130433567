import React from 'react'
import {Layout} from '../layout'
import {Col, Input, Layout as AntLayout, Row} from 'antd'
import './public-page-layout.scss'
import SvgIcon from '../../svg-icon/svg-icon'
import {TKey, translate} from '@/services/i18n'
import {ModuleFeatureFlags, ModuleLinks} from '@/sites/purchase/typedef'
import {MailOutlined, SendOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {hasFooter} from '../utils/has-footer'

const {Footer, Content} = AntLayout

type TProps = {
  children: JSX.Element
}

export const PublicPageLayout: React.FC<TProps> = ({children}) => {
  const {
    location: {pathname},
  } = useHistory()

  return (
    <Layout>
      <Content>{children}</Content>
      {hasFooter(pathname) && (
        <Footer className="footer">
          <div className="footer__container">
            <Row gutter={24} className="footer__container-row mt-30">
              <Col xs={24} lg={7}>
                <div className="mb-20">
                  <a href="https://www.blits-platform.com">
                    <SvgIcon
                      name="blits-logo"
                      className="footer__blits-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="footer__logo-text">
                  {translate('product_of_kompitech')}
                </div>
              </Col>
              <Col xs={24} lg={6}>
                <div className="fs-16 bold mb-20">{translate('products')}</div>
                {Object.values(ModuleFeatureFlags).map(module => (
                  <div className="footer__products" key={module}>
                    <a href={ModuleLinks[module]}>
                      {translate(module as TKey)}
                    </a>
                  </div>
                ))}
              </Col>
              <Col xs={24} lg={5}>
                <div className="fs-16 bold mb-20">
                  {translate('service_rsesources')}
                </div>
                <div className="footer__products">
                  <a href="https://kompitech.com">
                    {translate('it_outsourcing')}
                  </a>
                </div>
                <div className="footer__products">
                  <a href="https://www.blits-platform.com/services/">
                    {translate('it_field_services')}
                  </a>
                </div>
                <div className="footer__products">
                  <a href="https://manage.blits-platform.com/">
                    {translate('sign_in_sign_up')}
                  </a>
                </div>
                <div className="footer__products">
                  <a href="https://www.blits-platform.com/contact-us/">
                    {translate('contact_us')}
                  </a>
                </div>
                <div className="footer__products">
                  <a href="https://www.blits-platform.com/terms-of-use/">
                    {translate('terms_of_use')}
                  </a>
                </div>
                <div className="footer__products">
                  <a href="https://www.blits-platform.com/privacy-policy-2/">
                    {translate('private_policy')}
                  </a>
                </div>
              </Col>
              <Col xs={24} lg={6}>
                <div className="fs-16 bold mb-20">
                  {translate('get_ewsletter')}
                </div>
                <div className="footer__products">{translate('subscribe')}</div>
                <div className="mb-30">
                  <Input
                    className="footer__mail"
                    placeholder={translate('enter_email')}
                    addonAfter={
                      <div>
                        <SendOutlined className="footer__mail-icon" />
                      </div>
                    }
                    addonBefore={<MailOutlined />}
                  />
                </div>
                <div className="flex">
                  <div>
                    <a href="https://twitter.com/kompitech">
                      <SvgIcon
                        name="twitter"
                        className="footer__blits-social"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                  <div className="ml-20 mr-20">
                    <a href="https://www.youtube.com/channel/UC3JK1pFsXkTTej33rsfOSHQ">
                      <SvgIcon
                        name="youtube"
                        className="footer__blits-social"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.linkedin.com/company/kompitech">
                      <SvgIcon
                        name="linkedin"
                        className="footer__blits-social"
                        width={30}
                        height={30}
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer__copyright">{translate('copyright')}</div>
        </Footer>
      )}
    </Layout>
  )
}
