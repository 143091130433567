import {TAttachment} from '../../sites/Expenses/typedef'

type TData = {
  uploadedAttachments?: Array<TAttachment>
  selectedAttachments?: Array<TAttachment>
}

export const getAttachmentLink = (data: TData) => {
  const {uploadedAttachments, selectedAttachments} = data
  let links = '',
    attachments: Array<TAttachment> = [],
    newUploadedAttachments = uploadedAttachments

  if (uploadedAttachments)
    newUploadedAttachments = uploadedAttachments.filter(
      attachment => !!attachment.uuid
    )

  if (newUploadedAttachments && newUploadedAttachments.length > 0) {
    attachments = newUploadedAttachments
  } else if (selectedAttachments && selectedAttachments.length > 0) {
    attachments = selectedAttachments
  }

  if (
    attachments.length > 0 &&
    attachments.some(e => Object.keys(e).indexOf('uuid') !== -1)
  ) {
    attachments.forEach(el => {
      links += ` <a href=uuid:${el.uuid}>${el.metadata?.file_name}</a> `
    })
  }
  return links
}
