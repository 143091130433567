import React, {MouseEvent} from 'react'

import moment from 'moment'

import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import DataTable from '@/components/data-table/data-table'
import {Reasons, TAnomaly, TTicket} from '@/modules/ITSM/typedef'
import {useGetBillingItems} from '@/modules/ITSM/sites/BillingReports/billing-items/hooks/use-get-billing-items'
import {checkIsLoading} from '@/utils/check-is-loading'
import {TTableItem} from '@/modules/ITSM/sites/BillingReports/billing-items/typedf'
import {createAnomaliesTableData} from '@/modules/ITSM/sites/BillingReports/billing-items/utils/create-anomalies-table-data'

const PAGE_SIZE = 10

type TProps = {
  anomalies: Array<TAnomaly>
  uuid?: string
}

export const Anomalies: React.FC<TProps> = ({anomalies, uuid}) => {
  const {
    billingItems,
    fetchStatus: fetchStatusBillingItems,
    handleValidation,
    validationLoadingStates,
  } = useGetBillingItems({
    anomalies,
  })

  const getColumns = () => {
    return [
      {
        title: translate('reasons'),
        key: 'reasons',
        dataIndex: 'reasons',
        width: '20%',
        render: (val?: Array<Reasons>) => {
          return <div>{val?.map(reason => translate(reason)).join(', ')}</div>
        },
      },
      {
        title: translate('customerProduct'),
        key: 'contract',
        dataIndex: ['item', 'customer', 'product_name'],
        render: (val?: string) => {
          return <div className="nowrap">{val}</div>
        },
      },
      {
        title: translate('start'),
        key: 'start',
        dataIndex: ['item', 'work_start'],
        render: (val?: string) => {
          return <div className="nowrap">{moment(val).format(DATE_FORMAT)}</div>
        },
      },
      {
        title: translate('end'),
        key: 'end',
        dataIndex: ['item', 'work_end'],
        render: (val?: string) => {
          return <div className="nowrap">{moment(val).format(DATE_FORMAT)}</div>
        },
      },
      {
        title: translate('totalPrice'),
        key: 'price',
        dataIndex: ['item', 'customer', 'total_price'],
        render: (val?: string) => {
          const price = val ? parseFloat(val).toFixed(2) : ''
          return <div className="nowrap">{price}</div>
        },
      },
      {
        title: translate('totalCost'),
        key: 'cost',
        dataIndex: ['item', 'supplier', 'total_cost'],
        render: (val?: string) => {
          const cost = val ? parseFloat(val).toFixed(2) : ''
          return <div className="nowrap">{cost}</div>
        },
      },
      {
        title: translate('link'),
        key: 'path',
        dataIndex: ['item', 'ticket'],
        render: (val?: TTicket) => {
          const inc = val?.docType.toLowerCase()
          const id = val?.uuid
          const number = val?.number
          return (
            inc &&
            id &&
            number && (
              <a
                href={`/blits/itsm/service-management/${inc}/${id}/time-logs`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {number}
              </a>
            )
          )
        },
      },
      {
        ...(uuid
          ? {
              title: translate('valid'),
              key: 'valid',

              render: (val?: TTableItem) => (
                <div hidden={!val?.invalid}>{translate('invalid')}</div>
              ),
            }
          : {}),
      },
      {
        ...(uuid
          ? {
              title: translate('action'),
              key: 'number',
              zIndex: '20',
              render: (val?: TTableItem) => (
                <Button
                  className="validateBtn"
                  title={
                    val?.invalid
                      ? translate('validate')
                      : translate('invalidate')
                  }
                  loading={!!val?.uuid && validationLoadingStates[val?.uuid]}
                  onClick={(e: MouseEvent) => {
                    if (val) {
                      handleValidation(e, val, uuid)
                    }
                  }}
                />
              ),
            }
          : {}),
      },
    ]
  }

  return (
    <DataTable
      loading={checkIsLoading(fetchStatusBillingItems)}
      bordered={true}
      rowKey="uuid"
      data={createAnomaliesTableData(anomalies, billingItems)}
      columns={getColumns()}
      pagination={anomalies?.length > PAGE_SIZE ? {pageSize: PAGE_SIZE} : false}
    />
  )
}
