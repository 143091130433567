import React, {ReactNode} from 'react'

import {Modal as AntdModal, ModalProps, Spin} from 'antd'
import classNames from 'classnames'

import './modal.scss'
import {Icon} from '@/components/icon'
import {Button} from '@/components/button'

import {translate} from '@/services/i18n'

import {ModalIcon} from './utils'
import {ModalType} from './typedef'

type TProps = {
  noBackground?: boolean
  okText?: string
  cancelText?: string
  handleAccept?: () => void
  handleCancel?: () => void
  info?: string
  className?: string
  isDisabled?: boolean
  modalType: ModalType
  haveIcon?: boolean
  isFullWidth?: boolean
  loading?: boolean
  loadingText?: string
  children?: ReactNode
} & ModalProps

const Modal: React.FC<TProps> = ({
  noBackground = true,
  okText = translate('ok'),
  cancelText = translate('cancel'),
  handleAccept,
  handleCancel,
  info,
  className,
  isDisabled,
  modalType = ModalType.INFO,
  haveIcon = true,
  isFullWidth = false,
  loading = false,
  loadingText,
  children,
  ...props
}) => {
  const modalClass = classNames('modal-wrapper', {
    'no-background': noBackground,
    'modal-wrapper--full-width': isFullWidth,
    ...(className && {[className]: true}),
  })

  return (
    <AntdModal
      centered
      destroyOnClose={true}
      className={modalClass}
      footer={[
        handleAccept && (
          <Button
            title={okText}
            key="submit"
            type="primary"
            onClick={handleAccept}
            disabled={isDisabled}
          />
        ),
        handleCancel && (
          <Button
            title={cancelText}
            key="cancel"
            onClick={handleCancel}
            disabled={isDisabled}
          />
        ),
      ]}
      {...props}
    >
      {modalType && haveIcon && (
        <div className="modal__icon mb-16 flex">
          <Icon
            type={ModalIcon[modalType]}
            className={`modal-icon ${modalType}`}
          />
        </div>
      )}
      {info && <div className="full-width mb-24 text-center">{info}</div>}

      {loading && (
        <div className="modal__spinner">
          {loadingText && <div className="mb-20 fs-16">{loadingText}</div>}
          <Spin size="large" />
        </div>
      )}
      <div className="modal__content" e2e-test="modal-content">
        {children}
      </div>
    </AntdModal>
  )
}
export default Modal
