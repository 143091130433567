import React, {ReactNode, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import arrayMove from 'array-move'

import {ItsmAssets} from '@/modules/ITSM/typedef'
import DataTable from '@/components/data-table/data-table'
import {TableColumnsCustomizer} from '@/components/table-columns-customizer/table-columns-customizer'
import {TAppState} from '@/redux/store'
import {TKey} from '@/services/i18n'
import {RequestStatus, SortingKeys, SortingValues} from '@/typedef'
import {isRequesting} from '@/utils/check-api-status'
import {
  resetItsmTableFilter,
  resetItsmTableFilters,
  setItsmTableColumns,
  setItsmTableFilters,
  setItsmTableSorters,
} from '@/modules/ITSM/store/list-table/table-actions'
import ListTableHeader from '@/components/list-table-header/list-table-header'
import {generateFilteredData} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table/utils'

import {
  selectItsmTableColumns,
  selectItsmTableFilters,
  selectItsmTableSorters,
} from '../../../store/list-table/selectors'
import {useGenerateInputFiler} from '../hooks/use-generate-input-filter'
import {TColumnRender, TDataTablePreset} from '../typedf'
import {generateActiveColumns} from '../utils/generate-active-columns'
import {itsmSorters} from '../constants/filters-config'

type TITSMListTableNOBookmarkProps<T> = {
  asset: ItsmAssets
  getRowValue?: (props: TColumnRender<T>) => React.ReactNode
  fetchStatus: RequestStatus
  entityData: T[]
  getUpdatedColumns?: (columns: TDataTablePreset) => TDataTablePreset
  handleRowClick?: (record: T) => void
  tableHeader?: ReactNode
}

const ITSMListTableNOBookmark = <T extends Record<string, any>>({
  asset,
  getRowValue,
  entityData,
  fetchStatus,
  getUpdatedColumns,
  handleRowClick,
  tableHeader,
}: TITSMListTableNOBookmarkProps<T>) => {
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState(1)

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const handleActiveCols = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const movedItems = arrayMove(columns, oldIndex, newIndex)
      dispatch(setItsmTableColumns(asset, movedItems))
    }
  }

  const handleSorter = (sorter: Record<SortingKeys, SortingValues> | null) => {
    dispatch(setItsmTableSorters(asset, sorter))
  }

  const handleSetSelectedColumn = (selectedTitle: string, isAdded: boolean) => {
    const dataWithSelectedColumn = columns.map(data => {
      return data.title === selectedTitle ? {...data, selected: isAdded} : data
    })

    dispatch(setItsmTableColumns(asset, dataWithSelectedColumn))
  }

  const {
    generatedFilters,
    resetFilter,
    resetAllFilters,
    inputValues,
  } = useGenerateInputFiler<T>(asset)

  const draggableData = columns.map(column => ({
    title: column.title as TKey,
    isChecked: column.selected,
  }))

  const onSearch = () => {
    setCurrentPage(1)
    dispatch(setItsmTableFilters(asset, inputValues))
  }

  const onReset = (key: string) => {
    dispatch(resetItsmTableFilter(asset, key))
    resetFilter(key)
    setCurrentPage(1)
  }

  const handleResetFilter = (asset: ItsmAssets) => {
    dispatch(resetItsmTableFilters(asset))
    resetAllFilters()
    setCurrentPage(1)
  }

  const handlePaginationClick = async (page: number) => {
    setCurrentPage(page)
  }

  const filteredData = generateFilteredData(entityData, filters)

  return (
    <>
      <ListTableHeader<ItsmAssets>
        title={asset as TKey}
        asset={asset}
        previewMode={false}
        setSorting={handleSorter}
        onSearch={onSearch}
        onReset={onReset}
        generatedFilters={generatedFilters}
        moduleSorters={itsmSorters}
        resetFilters={handleResetFilter}
        {...(sorter && {sorter})}
        tableCustomizer={
          <TableColumnsCustomizer
            items={draggableData}
            updateItemOrder={handleActiveCols}
            handleActiveColumns={handleSetSelectedColumn}
          />
        }
      />
      <DataTable<T>
        data={filteredData}
        onRowClick={handleRowClick}
        tableHeader={tableHeader}
        columns={generateActiveColumns({
          columns: getUpdatedColumns ? getUpdatedColumns(columns) : columns,
          asset,
          getRowValue,
        })}
        loading={isRequesting(fetchStatus)}
        pagination={{
          pageSize: 10,
          current: currentPage,
          onChange: handlePaginationClick,
        }}
      />
    </>
  )
}

export default ITSMListTableNOBookmark
