import store from '../../../redux/store'

export const checkAccess = type => {
  let functionType

  const {accessFunctions} = store.getState().settings.myAccess

  if (
    type === 'cancelTicket' ||
    type === 'updateTimelogTimespan' ||
    type === 'cancelTimelogByServiceDesk' ||
    type === 'updateTimelogTimespan' ||
    type === 'addCredit' ||
    type === 'closeTicket' ||
    type === 'updateTimeSessionTimespan' ||
    type === 'deleteTimeSessionTimespan' ||
    type === 'addTimeSessionTimespan'
  ) {
    functionType = 'functions_invoke'
  } else if (type === 'location' || type === 'billing_report_approval') {
    functionType = 'assets_create'
  }

  return accessFunctions?.[functionType]?.includes(type)
}
