import React, {Fragment, useCallback, useEffect, useState} from 'react'

import {useDispatch} from 'react-redux'
import {Col, Divider, Row} from 'antd'
import moment, {MomentInput} from 'moment'
import {withErrorBoundary} from '@sentry/react'
import {saveAs} from 'file-saver'

import {Button} from '@/components/button'
import {PreviewModal} from '@/components/modal/preview-modal/preview-modal'
import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import ErrorPage from '@/components/error/error-page/error-page'
import {Pagination} from '@/components/pagination/pagination'
import DataTable from '@/components/data-table/data-table'
import {getNameAndExtension} from '@/utils/get-name-and-extension'
import {checkIsOnlyDownloadableFile} from '@/utils/check-is-only-downloadable-file'
import {checkExtension} from '@/components/modal/preview-modal/utils'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {
  getAllAttachmentsV2,
  getAttachmentV2,
  postAttachmentV2,
} from '../../api/attachmentsRequests'
import {TAttachment} from '../../sites/Expenses/typedef'
import {TIncident} from '../incident-k-request/typedf'
import {extensionPicker} from '../../utils/Helpers'

import AttachmentsButton from './attachments-button'

type TProps = {
  disField: boolean
}

const actionType = {
  DOWNLOAD: 'download',
  PREVIEW: 'preview',
}

const AttachmentsTab = ({disField}: TProps) => {
  const {entityData, entity} = useStateContext<TIncident>()
  const [pageNumber, setPageNumber] = useState(1)
  const {uuid: id} = entityData || {}
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentRecord, setCurrentRecord] = useState<TAttachment>()
  const [attachments, setAttachments] = useState<Array<TAttachment>>([])

  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const dispatch = useDispatch()

  const fetchAttachments = useCallback(
    async (pages?: number) => {
      setLoading(true)
      const offset = pages ? (pages - 1) * 10 : 0
      try {
        const res = await getAllAttachmentsV2(id, entity, offset)

        setTotalCount(res?.body?.total_count)
        setAttachments(
          res?.body?.result.sort(
            (a: {created_at: string}, b: {created_at: string}) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        )
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }

      setLoading(false)
    },
    [dispatch, entity, id]
  )

  useEffect(() => {
    if (attachments.length <= 0) {
      fetchAttachments()
    }
  }, [attachments.length, fetchAttachments])

  const attachmentAction = async (record: TAttachment, docAction: string) => {
    try {
      const res = await getAttachmentV2(record.uuid)

      const {body, type, header} = res
      if (docAction === actionType.DOWNLOAD) {
        const fileType = extensionPicker(type)
        const file = new Blob([body], {
          type,
        })
        saveAs(file, header['x-file-name'] || 'download.' + fileType)
      } else {
        setCurrentRecord({
          data: body,
          file_type: type,
          file_name: header['x-file-name'],
        })
        setShowPreviewModal(true)
      }
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    }
  }

  const getColumns = () => {
    return [
      {
        title: translate('file_name'),
        dataIndex: ['metadata', 'file_name'],
        width: 150,
      },
      {
        title: translate('createdAt'),
        dataIndex: ['metadata', 'created_at'],
        width: 150,
        render: (val: MomentInput) => moment(val).format(DATE_FORMAT),
      },
      {
        title: translate('file_type'),
        dataIndex: ['metadata', 'file_name'],
        width: 80,
        render: (text: string | void, record: TAttachment) => {
          const {extension} = getNameAndExtension(record?.metadata?.file_name)

          return extension
        },
      },

      {
        title: translate('action'),
        dataIndex: 'action',
        width: 120,
        render: (text: string | void, record: TAttachment) => {
          const {extension} = getNameAndExtension(record?.metadata?.file_name)

          return (
            <span className="attachments__actions">
              <Button
                title={translate('preview')}
                className="btn-preview btn-sm"
                disabled={
                  !!(extension && checkIsOnlyDownloadableFile(extension))
                }
                onClick={() => attachmentAction(record, actionType.PREVIEW)}
              />
              <Divider type="vertical" />
              <Button
                title={translate('download')}
                className="btn-download btn-sm"
                onClick={() => attachmentAction(record, actionType.DOWNLOAD)}
              />
            </span>
          )
        },
      },
    ]
  }

  const onPreviewModalCancel = () => {
    setShowPreviewModal(false)
    setCurrentRecord(undefined)
  }

  const {extension: currentExtension} = getNameAndExtension(
    currentRecord && currentRecord.file_name
  )

  const handlePagination = (page: number) => {
    fetchAttachments(page)
    setPageNumber(page)
  }

  return (
    <Fragment>
      <div id="fileContents" />

      <Row>
        <Col span={24}>
          <DataTable
            className="attachments__table"
            loading={loading}
            data={attachments}
            rowKey="uuid"
            columns={getColumns()}
            customPager={
              <Pagination
                current={pageNumber}
                onChange={handlePagination}
                total={totalCount || 10}
                pageSize={10}
                showSizeChanger={false}
              />
            }
          />
          {!disField && (
            <AttachmentsButton<TAttachment>
              onAddAttachments={() => fetchAttachments()}
              entity={entity}
              entityId={id || ''}
              asyncF={postAttachmentV2}
            />
          )}
        </Col>
      </Row>

      {currentRecord?.file_type && currentRecord?.data && showPreviewModal ? (
        <PreviewModal
          extension={currentExtension ? checkExtension(currentExtension) : ''}
          data={currentRecord.data}
          onCancel={onPreviewModalCancel}
        />
      ) : null}
    </Fragment>
  )
}

export default withErrorBoundary(AttachmentsTab, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
