import React, {useCallback, useEffect, useState} from 'react'

import {Col, Form, Input, Row, Select} from 'antd'
import isEqual from 'lodash/isEqual'
import {withErrorBoundary} from '@sentry/react'

import {Button} from '@/components/button'
import CallerSelect from '@/modules/ITSM/components/form-components/caller-select'
import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {translate} from '@/services/i18n'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {LocationSelect} from '@/modules/ITSM/components/locationSelect'
import {PRIORITY_OPTIONS} from '@/modules/ITSM/components/incident/constants'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {usePrevious} from '@/hooks/usePrevious'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import {setIncidentTabIsBlocked} from '@/modules/ITSM/components/incident/incident-reducer'
import ErrorPage from '@/components/error/error-page/error-page'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import DescriptionEditor from '../../description-editor/description-editor'
import {TIncidentTask, TIncidentTaskReq} from '../typedf'
import {getInitialValues} from '../constants'
import {checkIfDisabled} from '../utils/check-if-disabled'
import {useGetPriorityValues} from '../../incident-k-request/hooks/useGetPriorityValues'
import IncidentAssignment from '../../incident/incident-assignment/incident-assignment'

const Option = Select.Option
const TextArea = Input.TextArea

const IncidentTaskForm = () => {
  const [form] = Form.useForm()
  const {
    entityData,
    isLoading,
    updateEntity,
    getData,
    dispatch,
  } = useStateContext<TIncidentTask>()

  const {
    description,
    state_id,
    number,
    caller,
    location,
    item,
    short_description,
    priority,
    incident,
    supplier_product,
    customer_product,
    assignment_group,
    assigned_to,
  } = entityData || {}

  const {
    editorState,
    onEditorStateChange,
    editorText,
    setText,
  } = useSetEditorState(description)

  const [btnDisabled, setBtnDisabled] = useState(true)

  const [, setRefresh] = useState(0)

  const prevEditorText = usePrevious(editorText)

  useGetPriorityValues()

  const getFormattedData = useCallback(
    (values: TIncidentTaskReq) => {
      delete values['incident']
      values['description'] = editorText
      return values
    },
    [editorText]
  )

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])

  const checkValues = useCallback(() => {
    entityData &&
      setBtnDisabled(
        isEqual(
          getFormattedData(form.getFieldsValue()),
          getInitialValues(entityData)
        )
      )
  }, [entityData, form, getFormattedData])

  const isDisabled = state_id !== undefined && checkIfDisabled(state_id)

  const handleUpdateIncident = async (values: TIncidentTaskReq) => {
    const data = getFormattedData(values)
    const result =
      entityData &&
      (await updateEntity(
        getDataDifference(getInitialValues(entityData), data)
      ))
    if (result !== undefined) {
      setBtnDisabled(true)
      await getData()
    }
  }

  useEffect(() => {
    if (prevEditorText !== editorText) checkValues()
  }, [checkValues, editorText, prevEditorText])

  const priorityOptions: {[key: string]: any} = PRIORITY_OPTIONS
  return (
    <Form
      validateMessages={{
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} is required',
      }}
      onFinish={handleUpdateIncident}
      form={form}
      layout="vertical"
      onValuesChange={checkValues}
    >
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentTaskReq>
            name="number"
            initialValue={number}
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('number')}
          >
            <Input readOnly />
          </FormItemGeneric>
        </Col>

        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentTaskReq>
            name="priority"
            label={translate('priority')}
            initialValue={priority || 4}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select getPopupContainer={e => e.parentNode} disabled={isDisabled}>
              {Object.keys(priorityOptions).map(key => (
                <Option value={Number(key)} key={key}>
                  {priorityOptions[Number(key)]}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <LocationSelect
            recordLocationData={location}
            locationId={form.getFieldValue('location') || location?.uuid}
            setRefresh={() => setRefresh(Math.random())}
            isDisabled={isDisabled}
            required
          />
        </Col>
        <Col {...twoColumns}>
          <CallerSelect
            callerInit={caller}
            callerValue={form.getFieldValue('caller')}
            setRefresh={() => setRefresh(Math.random())}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentTaskReq>
            name="item"
            label={translate('item')}
            initialValue={item}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </FormItemGeneric>
        </Col>

        <Col {...twoColumns}>
          <FormItemGeneric<TIncidentTaskReq>
            name="incident"
            label={translate('incident')}
            initialValue={incident?.number}
          >
            <Input disabled />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <FormItemGeneric<TIncidentTaskReq>
            initialValue={short_description || null}
            name="short_description"
            label={translate('title')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea readOnly={isDisabled} rows={5} />
          </FormItemGeneric>
        </Col>
      </Row>
      <DescriptionEditor
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        isDisabled={isDisabled}
        description={description || ''}
        setText={setText}
        editorText={editorText}
      />
      <IncidentAssignment
        form={form}
        setRefresh={() => setRefresh(Math.random())}
        isDisabled={isDisabled}
        supplierProduct={supplier_product}
        customerProduct={customer_product}
        assignmentGroup={assignment_group}
        assignedTo={assigned_to}
        stateId={state_id}
      />
      {!isDisabled && (
        <Row>
          <Col span={24} className="mb-30 mt-10">
            <Button
              title={translate('save')}
              disabled={isLoading || btnDisabled}
              htmlType="submit"
              type="primary"
              size="large"
            />
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default withErrorBoundary(IncidentTaskForm, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
