import {Interval, IPrice} from '@/modules/typedef'
import {MetadataType} from '@/modules/Subscriptions/utils/constants'
import {TNormalizedProduct} from '@/sites/purchase/typedef'

export const generatePurchaseProducts = ({prices}: {prices: IPrice[]}) => {
  const sortedPrices = prices.sort((a, b) => {
    if (a.product.name > b.product.name) {
      return 1
    }
    if (b.product.name > a.product.name) {
      return -1
    }
    return 0
  })
  const generalPrices = sortedPrices.reduce<
    Record<Interval, Array<TNormalizedProduct>>
  >(
    (acc, price) => {
      if (
        price.product.metadata.type === MetadataType.BLITS_SOFTWARE_PRODUCT &&
        price.product.active &&
        price.recurring?.interval &&
        (price.recurring?.interval === Interval.MONTH ||
          price.recurring?.interval === Interval.YEAR)
      ) {
        return {
          ...acc,
          [price.recurring.interval]: [
            ...acc[price.recurring.interval],
            {
              id: price.id,
              name: price.product.name,
              description: price.product.description,
              interval: price.recurring.interval,
              currency: price.currency,
              unit_amount: price.unit_amount,
              feature_flag: price.metadata.feature_flag,
              product_type: price.product.metadata.type,
              priceType: price.metadata.product_type,
              category: price.product.metadata.category,
              usage_type: price.recurring.usage_type,
              contact_sales_price: price.metadata.contact_sales_price,
              per_custom_unit: price.product.metadata.per_custom_unit,
              productId: price.product.id,
            },
          ],
        }
      }
      return acc
    },
    {[Interval.YEAR]: [], [Interval.MONTH]: []}
  )

  const supportServices = sortedPrices.reduce<
    Record<Interval, Array<TNormalizedProduct>>
  >(
    (acc, price) => {
      if (
        price.product.metadata.type === MetadataType.BLITS_SUPPORT_SERVICE &&
        price.product.active &&
        price.recurring?.interval &&
        (price.recurring?.interval === Interval.MONTH ||
          price.recurring?.interval === Interval.YEAR)
      ) {
        return {
          ...acc,
          [price.recurring.interval]: [
            ...acc[price.recurring.interval],
            {
              id: price.id,
              name: price.product.name,
              description: price.product.description,
              interval: price.recurring.interval,
              currency: price.currency,
              unit_amount: price.unit_amount,
              feature_flag: price.metadata.feature_flag,
              product_type: price.product.metadata.type,
              category: price.product.metadata.category,
              usage_type: price.recurring.usage_type,
              contact_sales_price: price.metadata.contact_sales_price,
              per_custom_unit: price.product.metadata.per_custom_unit,
              productId: price.product.id,
            },
          ],
        }
      }
      return acc
    },
    {[Interval.YEAR]: [], [Interval.MONTH]: []}
  )

  return {
    generalPrices,
    supportServices,
  }
}
