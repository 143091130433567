import isArray from 'lodash/isArray'

import i18n from './i18n'
import {TKey} from './translations'

export const translate = (text: TKey | Array<TKey>): string => {
  if (isArray(text)) {
    return text.map((item: TKey) => i18n.t(item)).join(' ')
  }
  return i18n.t(text)
}
