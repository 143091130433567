import React, {useRef, useState} from 'react'

import {Button, Col, Form as AntForm, Form, Radio, Row, Tabs} from 'antd'

import {ServicesTabPane} from '@/sites/services/components/services-tab-pane'
import {ServicesTab} from '@/sites/services/components/services-tab'

import './services.scss'

import {useCustomAuth} from '@/hooks/use-custom-auth'

import {translate} from '@/services/i18n'
import {defaultSelectAddons} from '@/sites/services/constants/default-select-addons'
import {viewSideTab} from '@/sites/services/constants/view-side-tab'
import {hasCountryField} from '@/sites/services/utils/has-country-field'
import {calculateServicesTotalPrice} from '@/sites/services/utils/calculate-services-total-price'
import {cyclePriceOptions} from '@/sites/services/constants/cycle-price-options'
import {basePrice} from '@/sites/services/constants/base-price'

import {validateInputValue} from '@/sites/services/utils/validate-input-value'

import {AddonItemTypes, SideTabs, TServicesForm} from '@/sites/services/typedef'
import {Flow} from '@/sites/account-default/typedef'
import {Interval} from '@/modules/typedef'

const contactSaleUrl =
  'https://staging-kompitechcom.kinsta.cloud/contact/#:~:text=What%20are%20your%20IT%20needs'

export const Services = () => {
  const formRef = useRef(null)
  const [form] = AntForm.useForm<TServicesForm>()
  const [intervalType, setIntervalType] = useState<Interval>(Interval.YEAR)
  const [activeTabKey, setActiveTabKey] = useState<SideTabs>(
    SideTabs.END_USER_SUPPORT
  )
  const [selectedServices, setSelectedServices] = useState(defaultSelectAddons)

  const {signUpRedirect} = useCustomAuth()

  const handleFreeTrial = () => {
    // TODO: add validation
    signUpRedirect({
      flow: Flow.SERVICES,
      interval: intervalType,
      payload: JSON.stringify(form.getFieldsValue()),
      unit_amount: '1',
    })
  }

  const handleIntervalType = (value: Interval) => {
    setIntervalType(value)
  }

  const handleValuesChange = (values: TServicesForm) => {
    const [addonType, item] = Object.entries(values)[0]
    const addonTypeKey: AddonItemTypes = addonType as AddonItemTypes
    const validatedValue = validateInputValue(
      selectedServices[activeTabKey],
      addonTypeKey,
      item
    )
    const isShouldRemoveCountryField =
      addonType === AddonItemTypes.SUPPORT_TYPE &&
      !hasCountryField(validatedValue)

    const newValues = {
      [addonType]: validatedValue,
      ...(isShouldRemoveCountryField && {
        [AddonItemTypes.COUNTRY]: undefined,
      }),
    }

    setSelectedServices(prev => {
      return {
        ...prev,
        [activeTabKey]: {
          ...prev[activeTabKey],
          ...newValues,
        },
      }
    })

    form.setFieldsValue(newValues)
  }

  const handleChangeTabs = (key: SideTabs) => {
    // TODO: add validation
    form.setFieldsValue({
      [AddonItemTypes.COUNTRY]: undefined,
      ...selectedServices[key],
    })
    setActiveTabKey(key)
  }

  const handleContactSales = () => {
    window.open(contactSaleUrl, '_blank', 'noreferrer')
  }

  return (
    <div className="services__wrapper">
      <div className="services">
        <h1 className="services__title mb-22">{translate('pricing')}</h1>
        <div className="services__header">
          <p className="services__sub-title">
            {translate('setup_your_services')}
          </p>
          <div className="services__interval flex flex--justifyCenter">
            <Radio.Group
              value={intervalType}
              options={cyclePriceOptions}
              onChange={({target: {value}}) => handleIntervalType(value)}
            />
          </div>
        </div>

        <div className="services__tabs">
          <Form
            initialValues={selectedServices[activeTabKey]}
            form={form}
            validateMessages={{required: 'Please select an option'}}
            onValuesChange={handleValuesChange}
            ref={formRef}
          >
            <Tabs
              className="services__tabs mb-30"
              tabPosition="left"
              type="card"
              activeKey={activeTabKey}
              onChange={key => handleChangeTabs(key as SideTabs)}
              defaultActiveKey={SideTabs.END_USER_SUPPORT}
              tabBarExtraContent={
                <h4 className="services__total text-center">
                  {`$${calculateServicesTotalPrice(
                    basePrice[intervalType],
                    selectedServices
                  )} `}
                  <span>{`/${translate('user')}/${translate('month')}`}</span>
                </h4>
              }
              items={viewSideTab.map((value, index) => ({
                key: value,
                label: <ServicesTab name={value} stepNum={index + 1} />,
                children: (
                  <>
                    <ServicesTabPane
                      name={value}
                      displayCountry={hasCountryField(
                        selectedServices[activeTabKey]?.['support-type']
                      )}
                    />
                    <Row className="services__btns" gutter={[16, 16]}>
                      <Col xs={24} sm={12}>
                        <Button
                          className="services__btn services__btn--outline services__contact-btn"
                          onClick={handleContactSales}
                        >
                          {translate('contact_sale')}
                        </Button>
                      </Col>

                      <Col className="flex flex--justifyCenter" xs={24} sm={12}>
                        <Button
                          className="services__btn services__btn--secondary mr-11"
                          onClick={() =>
                            handleChangeTabs(viewSideTab[index - 1])
                          }
                          disabled={value === SideTabs.END_USER_SUPPORT}
                        >
                          {translate('previous')}
                        </Button>
                        <Button
                          className="services__btn services__btn--primary"
                          htmlType="submit"
                          onClick={() =>
                            value === SideTabs.IT_ASSETS_SUPPORT_MANAGEMENT
                              ? handleFreeTrial()
                              : handleChangeTabs(viewSideTab[index + 1])
                          }
                        >
                          {translate(
                            value === SideTabs.IT_ASSETS_SUPPORT_MANAGEMENT
                              ? 'free_trial'
                              : 'next_step'
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              }))}
            />
          </Form>
        </div>
      </div>
    </div>
  )
}
