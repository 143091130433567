import React, {ReactNode} from 'react'

import {Table} from 'antd'
import {ColumnsType} from 'antd/es/table'
import {
  ExpandableConfig,
  GetRowKey,
  TableLocale,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/lib/table/interface'
import classNames from 'classnames'
import {isFunction} from 'lodash'

import {TFilter} from '@/modules/CMDB/components/cmdb-list-table/typedf'
import {Skeleton} from '@/components/skeleton'
import {Paper} from '@/components/paper/paper'

import {DataTableSkeletonView} from './data-table-skeleton-view'

import './data-table.scss'
import '../pagination/pagination.scss'

type TDataTableProps<T> = {
  bordered?: boolean
  data: Array<T>
  columns: ColumnsType<T>
  onRowClick?: (record: T, rowIndex: number | undefined, target?: any) => void
  customPager?: ReactNode
  tableHeader?: ReactNode
  tableFilters?: ReactNode
  locale?: TableLocale
  rowClassName?: ((record: T, index: number) => string) | string
  loading?: boolean
  rowSelection?: TableRowSelection<T>
  components?: {
    body?: {
      cell?: any
    }
  }
  scroll?: Record<string, number | string>
  rowKey?: string | GetRowKey<T>
  handleFilterClick?: (some: any, filters: TFilter, sorter: any) => void
  pagination?: TablePaginationConfig | false
  className?: string
  indentSize?: number
  showHeader?: boolean
  expandable?: ExpandableConfig<T>
}

const DataTable = <T extends Record<string, any>>({
  bordered,
  data,
  onRowClick,
  customPager,
  columns,
  rowClassName = '',
  loading,
  tableHeader,
  rowSelection,
  components,
  scroll,
  rowKey,
  pagination,
  className,
  indentSize,
  showHeader,
  handleFilterClick,
  expandable,
  locale,
}: TDataTableProps<T>) => {
  const getPagination: () => TablePaginationConfig | false = () => {
    if (customPager || pagination === false) {
      return false
    }
    return {
      position: ['bottomLeft'],
      showSizeChanger: false,
      hideOnSinglePage: true,
      pageSize: 10,
      ...pagination,
      className: 'pagination',
    }
  }

  const tableClasses = classNames('data-table', className)

  const getRowClassName = (record: T, index: number) => {
    if (isFunction(rowClassName)) {
      return rowClassName(record, index)
    }

    return onRowClick ? `pointer-cursor ${rowClassName}` : rowClassName
  }

  return (
    <Paper>
      {tableHeader && (
        <div e2e-test="table-header" className="data-table__header">
          {tableHeader}
        </div>
      )}

      <Skeleton
        className="data-table__wrapper"
        active={Boolean(loading)}
        view={
          <DataTableSkeletonView
            columnAmount={columns?.length}
            rowAmount={pagination ? pagination?.pageSize : data?.length}
          />
        }
      >
        <Table<T>
          bordered={bordered}
          size="small"
          className={tableClasses}
          e2e-test="data-table-test"
          rowKey={rowKey || (record => record.uuid)}
          rowClassName={getRowClassName}
          dataSource={data}
          columns={columns}
          onRow={(record, rowIndex) => ({
            onClick: (event: any) => {
              onRowClick && onRowClick(record, rowIndex, event.target)
            },
          })}
          rowSelection={rowSelection}
          components={components}
          pagination={getPagination()}
          scroll={scroll ? scroll : {x: 1000}}
          indentSize={indentSize}
          showHeader={showHeader}
          onChange={handleFilterClick}
          expandable={expandable}
          locale={locale}
        />
      </Skeleton>
      {customPager && (
        <div className="data-table__custom-pager" e2e-test="table-pager">
          {customPager}
        </div>
      )}
    </Paper>
  )
}

export default DataTable
