import {servicePriceConfig} from '@/sites/services/constants/service-price-config'
import {countryConfig} from '@/sites/services/constants/country-config'
import {normalizeServerValues} from '@/sites/services/utils/normolize-server-values'
import {AddonItemTypes, TServicesForm} from '@/sites/services/typedef'

type TValues = Partial<Record<string, string | Array<string>>>

const calculateAddonRate = (addon: string, values: TValues) => {
  const addonItem = values[addon]

  if (!addonItem) return 0
  if (!Array.isArray(addonItem)) {
    return servicePriceConfig[addon].items[addonItem] || 0
  }
  if (addonItem.length === 0) return 0
  if (addon === AddonItemTypes.COUNTRY) {
    return (
      addonItem.reduce((acc, item) => acc + countryConfig[item] || 0, 0) /
      addonItem.length
    )
  }

  return (
    addonItem.reduce((acc, item) => {
      const respondTimeRate = servicePriceConfig[addon].items[item] || 0

      return acc + respondTimeRate
    }, 0) || 0
  )
}
const calculateAddonPrice = (
  basePrice: number,
  addon: string,
  values: TValues
) => basePrice + (basePrice * calculateAddonRate(addon, values)) / 100

export const calculateModuleTotalPrice = (
  basePrice: number,
  values: TServicesForm
) => {
  const normalizedValues = normalizeServerValues(values)

  basePrice = calculateAddonPrice(
    basePrice,
    'number-of-users',
    normalizedValues
  )
  basePrice = calculateAddonPrice(basePrice, 'support-type', normalizedValues)
  basePrice = calculateAddonPrice(
    basePrice,
    AddonItemTypes.COUNTRY,
    normalizedValues
  )
  basePrice = calculateAddonPrice(basePrice, 'support', normalizedValues)

  return Math.ceil(
    calculateAddonPrice(basePrice, 'respond-time', normalizedValues)
  )
}
