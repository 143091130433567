import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {TListOptions} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {
  assignmentGroupsApi,
  getToken,
} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TAssignmentGroup} from '../typedef'

export const fetchAssignmentGroups = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(assignmentGroupsApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })

export const patchAssignmentGroup = async (
  id: string,
  data: TAssignmentGroup
) => {
  return await request
    .patch(assignmentGroupsApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postAssignmentGroup = async (
  uuid: string,
  data: TAssignmentGroup
) => {
  return await request
    .post(assignmentGroupsApi(uuid).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getDefaultRolesOrg = async (
  data: Record<string, unknown>,
  selectedChannlID: string
) => {
  const token = getToken()
  return await request
    .options(assignmentGroupsApi().get)
    .set({'Grpc-Metadata-space': selectedChannlID, ...token})
    .send(data)
}
