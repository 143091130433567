import {ElementType, useState} from 'react'
import {themeKompiTech} from './kompitech'
import {Kompitech, Telia} from '@/components/main-logo/logo'
import {ThemeConfig} from 'antd/lib/config-provider'
import {themeTeliaCarrie} from './telia-carrier'

export enum AppThemes {
  KOMPITECH = 'kompitech-theme',
  TELIA_CARRIER = 'telia-carrier-theme',
}

type TAppThemeConfig = {
  [key in AppThemes]: {
    styles: ThemeConfig
    image: ElementType
  }
}

export const AppThemeConfig: TAppThemeConfig = {
  [AppThemes.KOMPITECH]: {styles: themeKompiTech, image: Kompitech},
  [AppThemes.TELIA_CARRIER]: {styles: themeTeliaCarrie, image: Telia},
}

export const useAppTheme = () => {
  const [theme, setTheme] = useState(AppThemeConfig[AppThemes.KOMPITECH])

  const setAppTheme = (newTheme: AppThemes) => {
    setTheme(AppThemeConfig[newTheme])
  }

  return {theme, setAppTheme}
}
