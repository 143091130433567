import {useAuth} from 'react-oidc-context'
import {useDispatch} from 'react-redux'
import {setInitialStateUser} from '@/redux/user/userActions'
import {clearUIState} from '@/redux/ui/uiActions'
import {setInitialStateGeneral} from '@/redux/actions'
import {resetEntriesReducer} from '@/redux/entries/entries-actions'
import {clearToastMessage} from '@/redux/toast-message/toast-actions'

export const useCustomAuth = () => {
  const auth = useAuth()
  const dispatch = useDispatch()

  const resetModule = () => {
    dispatch(setInitialStateUser())
    dispatch(clearUIState())
    dispatch(setInitialStateGeneral())
    dispatch(resetEntriesReducer())
    dispatch(clearToastMessage())
  }

  const signOutRedirect = () => {
    resetModule()

    auth.signoutRedirect()
  }

  const signUpRedirect = (state?: Record<string, string>) => {
    resetModule()

    auth.signinRedirect({
      extraQueryParams: {ui_entry: 'SignUp'},
      state,
    })
  }

  const loginRedirect = async () => {
    resetModule()

    auth.signinRedirect({
      prompt: 'login',
    })
  }

  return {
    signOutRedirect,
    loginRedirect,
    signUpRedirect,
  }
}
