import {useCallback} from 'react'

import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import {putEvent} from '@/modules/ITSM/api/eventsRequests'

import {TEvent} from '../typedf'

export function useUpdateEvent(): {
  readonly updateEntity: (
    data: TEvent,
    id?: string
  ) => Promise<ISuperAgentResSingle<TEvent> | undefined>
  readonly updateEntityStatus: RequestStatus
} {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const updateEntity = useCallback(
    async (data: TEvent) => {
      try {
        handleRequested()

        const {
          body: {result},
        } = await putEvent(data)
        if (result) {
          handleSuccess()
          return result
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    updateEntity,
    updateEntityStatus: status,
  }
}
