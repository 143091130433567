import React from 'react'

import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import globalRoutes from '@/global-routes'
import {translate} from '@/services/i18n'
import {setOrgToken} from '@/redux/user/userActions'
import {OverlaySpinner} from '@/components/overlay-spinner'
import {Content, Footer, Layout} from '@/components/layout'

import './success-login.scss'

class SuccessLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      token: null,
      redirect: false,
      redirectTo: globalRoutes.general.initialSetting.path(),
    }
  }

  render() {
    const {error, redirect, redirectTo} = this.state

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Layout className="success-login">
        {error ? (
          <Content>
            <h2 className="text-center">{translate('something_went_wrong')}</h2>
            <h3 className="text-center">{`${translate('error')} ${error}`}</h3>
          </Content>
        ) : (
          <OverlaySpinner />
        )}
        <Footer className="success-login__footer">
          {translate('kompitech_copyright')}
        </Footer>
      </Layout>
    )
  }
}

export default connect(null, {setOrgToken})(SuccessLogin)
