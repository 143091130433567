import {ItsmAssets} from '@/modules/ITSM/typedef'

import {
  incidentsRequestsColumns,
  incidentsRequestsColumnsUP,
} from '../itsm-list-table-incidents-requests/column-table-config'
import {FilterTypes, TDataTablePreset} from '../typedf'

const timeLogsColumns = [
  {
    title: 'user',
    dataIndex: ['created_by', 'full_name'],
    index: 0,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'check_in_time',
    dataIndex: 'start',
    index: 1,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'check_out_time',
    dataIndex: 'end',
    index: 2,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'travel_time',
    dataIndex: 'travel',
    index: 3,
    selected: true,
    advancedRender: true,
  },

  {
    title: 'break_time',
    dataIndex: 'break',
    index: 4,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'work_time',
    dataIndex: 'work',
    index: 5,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'customer_location',
    dataIndex: 'customer_location',
    index: 6,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'cmdb_updated',
    dataIndex: 'cmdb_updated',
    index: 7,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'visit_summary',
    dataIndex: 'visit_summary',
    index: 8,
    selected: true,
    advancedRender: true,
  },

  {
    title: 'is_cancelled',
    dataIndex: 'is_cancelled',
    index: 9,
    selected: true,
    advancedRender: true,
  },
]
export const itsmColumnsDefaults: {[key in ItsmAssets]: TDataTablePreset} = {
  [ItsmAssets.Invitations]: [
    {
      title: 'email',
      dataIndex: 'email',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'roles',
      dataIndex: ['roles', 'value'],
      index: 1,
      quickFilter: true,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'state',
      dataIndex: 'state',
      index: 2,
      quickFilter: true,
      selected: true,
      advancedRender: true,
      filterType: FilterTypes.Select,
    },
    {
      title: 'invitorName',
      dataIndex: ['invitorName'],
      index: 3,
      quickFilter: true,
      selected: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'invitorOrg',
      dataIndex: ['invitorOrg', 'display_name'],
      index: 4,
      quickFilter: true,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'type',
      dataIndex: 'type',
      index: 5,
      quickFilter: true,
      selected: true,
      advancedRender: true,
    },
  ],

  [ItsmAssets.AssignmentGroups]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'org_name',
      dataIndex: 'org_name',
      index: 1,
      filterType: FilterTypes.SelectFetch,
      quickFilter: true,
      selected: true,
    },

    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 2,
      quickFilter: false,
      selected: true,
    },
  ],
  [ItsmAssets.Roles]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.Identities]: [
    {
      title: 'full_name',
      dataIndex: ['users', '0', 'full_name'],
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'email',
      dataIndex: ['users', '0', 'email'],
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'is_enabled',
      dataIndex: 'is_enabled',
      index: 2,
      filterType: FilterTypes.Boolean,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.Users]: [
    {
      title: 'full_name',
      dataIndex: 'full_name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'email',
      dataIndex: 'email',
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.Suppliers]: [
    {
      title: 'full_name',
      dataIndex: 'full_name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'email',
      dataIndex: 'email',
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'org_display_name',
      dataIndex: 'org_display_name',
      index: 2,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.RejectReasons]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 1,
      quickFilter: false,
      selected: true,
    },
  ],
  [ItsmAssets.UserGroups]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },

    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 1,
      quickFilter: false,
      selected: true,
    },
  ],
  [ItsmAssets.AssignmentRules]: [
    {
      title: 'ticket_type',
      dataIndex: 'ticket_type',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'assignment_group',
      dataIndex: ['assignment_group', 'name'],
      index: 1,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'assignee',
      dataIndex: ['assignee', 'full_name'],
      index: 2,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'location',
      dataIndex: ['location', 'name'],
      index: 3,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'category',
      dataIndex: ['category', 'name'],
      index: 4,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'subcategory',
      dataIndex: ['subcategory', 'name'],
      index: 5,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 6,
      quickFilter: false,
      selected: false,
    },
  ],
  [ItsmAssets.Announcements]: [
    {
      title: 'title',
      dataIndex: 'title',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'body',
      dataIndex: 'body',
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'org_role',
      dataIndex: 'org_role',
      index: 2,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'active_from',
      dataIndex: 'active_from',
      index: 3,
      filterType: FilterTypes.NA,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'active_to',
      dataIndex: 'active_to',
      index: 4,
      filterType: FilterTypes.NA,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.SupplierProducts]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'code',
      dataIndex: 'code',
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'org_name',
      dataIndex: 'org_name',
      index: 2,
      filterType: FilterTypes.SelectFetch,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.CustomerProducts]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'code',
      dataIndex: 'code',
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'country',
      dataIndex: ['location', 'country'],
      index: 2,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'city',
      dataIndex: ['location', 'city'],
      index: 3,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'rate',
      dataIndex: ['pricing_policy', 'rate'],
      index: 5,
      filterType: FilterTypes.SearchNumber,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'primary_hour_rate',
      dataIndex: ['pricing_policy', 'primary_hour_rate'],
      index: 6,
      filterType: FilterTypes.SearchNumber,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'additional_hour_rate',
      dataIndex: ['pricing_policy', 'additional_hour_rate'],
      index: 7,
      filterType: FilterTypes.SearchNumber,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'sla_type',
      dataIndex: 'sla_type',
      index: 8,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.Notifications]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'user_group',
      dataIndex: ['user_group', 'name'],
      index: 1,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 2,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'updated_at',
      dataIndex: 'updated_at',
      index: 3,
      quickFilter: false,
      selected: false,
    },
  ],
  [ItsmAssets.Categories]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      filterType: FilterTypes.Search,
      quickFilter: true,
      selected: true,
    },
    {
      title: 'type',
      dataIndex: 'parent',
      index: 1,
      filterType: FilterTypes.Search,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 2,
      quickFilter: true,
      selected: true,
    },
  ],
  [ItsmAssets.Incidents]: incidentsRequestsColumns,
  [ItsmAssets.Requests]: incidentsRequestsColumns,
  [ItsmAssets.TimeLogs]: timeLogsColumns,
  [ItsmAssets.TimelogExpenses]: timeLogsColumns,
  [ItsmAssets.SVR]: [],
  [ItsmAssets.TimeLogsResolve]: [
    {
      title: 'check_in_time',
      dataIndex: 'start',
      index: 0,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'check_out_time',
      dataIndex: 'end',
      index: 1,
      selected: true,
      advancedRender: true,
    },
  ],

  [ItsmAssets.IncidentsUP]: incidentsRequestsColumnsUP,
  [ItsmAssets.RequestsUP]: incidentsRequestsColumnsUP,
  [ItsmAssets.IncidentExpenses]: [
    {
      title: 'description',
      dataIndex: 'description',
      index: 0,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'invoice_amount',
      dataIndex: 'price',
      index: 1,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'kind',
      dataIndex: 'kind',
      index: 2,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'file_name',
      dataIndex: ['attachment', 'file_name'],
      index: 3,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'file_type',
      dataIndex: ['attachment', 'file_type'],
      index: 4,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 5,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      index: 6,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
  ],
  [ItsmAssets.IncidentTasks]: [
    {
      title: 'number',
      dataIndex: 'number',
      index: 0,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'short_description',
      dataIndex: 'short_description',
      index: 1,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'priority',
      dataIndex: 'priority',
      index: 2,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'state_id',
      dataIndex: 'state_id',
      index: 3,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 4,
      quickFilter: false,
      selected: true,
    },
  ],
  [ItsmAssets.Credits]: [
    {
      title: 'amount',
      dataIndex: 'amount',
      index: 0,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'description',
      dataIndex: 'description',
      index: 1,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 2,
      quickFilter: false,
      selected: true,
    },
  ],
  [ItsmAssets.Expenses]: [
    {
      title: 'file_name',
      dataIndex: ['attachment', 'file_name'],
      index: 0,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'file_type',
      dataIndex: ['attachment', 'file_type'],
      index: 1,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'kind',
      dataIndex: 'kind',
      index: 2,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'description',
      dataIndex: 'description',
      index: 3,
      quickFilter: true,
      selected: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'price',
      dataIndex: 'price',
      index: 4,
      quickFilter: true,
      selected: true,
      filterType: FilterTypes.SearchNumber,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 5,
      quickFilter: false,
      selected: true,
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      index: 6,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'status',
      dataIndex: 'approved',
      index: 7,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
  ],
  [ItsmAssets.BillingItems]: [
    {
      title: 'date',
      dataIndex: ['ticket', 'created_at'],
      index: 0,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'number',
      dataIndex: ['ticket', 'number'],
      index: 1,
      selected: true,
      quickFilter: true,
      filterType: FilterTypes.Search,
    },

    {
      title: 'billing_task_type',
      dataIndex: ['ticket', 'docType'],
      index: 2,
      selected: true,
    },

    {
      title: 'short_description',
      dataIndex: ['ticket', 'short_description'],
      index: 3,
      selected: true,
      quickFilter: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'contract',
      dataIndex: 'partner_name',
      index: 4,
      selected: true,
    },
    {
      title: 'billing_contract_dept',
      dataIndex: 'partner_department',
      index: 5,
      selected: true,
    },
    {
      title: 'country',
      dataIndex: ['location', 'country'],
      index: 6,
      selected: true,
      quickFilter: true,
      filterType: FilterTypes.Select,
    },
    {
      title: 'city',
      dataIndex: ['location', 'city'],
      index: 7,
      selected: true,
      quickFilter: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'state',
      dataIndex: ['location', 'state_province'],
      index: 8,
      selected: true,
    },
    {
      title: 'product',
      dataIndex: ['customer', 'product_name'],
      index: 9,
      selected: true,
    },
    {
      title: 'priority',
      dataIndex: ['ticket', 'priority'],
      index: 10,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'status',
      dataIndex: ['ticket', 'state_id'],
      index: 11,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'billing_response_sla_expiry',
      dataIndex: ['ticket', 'response_time_due'],
      index: 12,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_fix_sla_expiry',
      dataIndex: ['ticket', 'resolution_time_due'],
      index: 13,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_resolved_at',
      dataIndex: ['ticket', 'resolved_at'],
      index: 14,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'billing_made_sla',
      dataIndex: ['ticket', 'made_sla'],
      index: 16,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'billing_visit_summary',
      dataIndex: 'visit_summary',
      index: 17,
      selected: true,
    },
    {
      title: 'user',
      dataIndex: ['user', 'full_name'],
      index: 18,
      selected: true,
      filterType: FilterTypes.SelectWithBM,
      quickFilter: true,
    },

    {
      title: 'work_start',
      dataIndex: 'work_start',
      index: 20,
      selected: true,
      advancedRender: true,
      filterType: FilterTypes.DateRange,
      quickFilter: true,
    },
    {
      title: 'work_end',
      dataIndex: 'work_end',
      index: 21,
      selected: true,
      advancedRender: true,
      filterType: FilterTypes.DateRange,
      quickFilter: true,
    },

    {
      title: 'remote',
      dataIndex: 'remote',
      index: 22,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'billing_total_cost',
      dataIndex: ['supplier', 'total_cost'],
      index: 23,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_total_price',
      dataIndex: ['customer', 'total_price'],
      index: 24,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_travel_time',
      dataIndex: 'travel_time',
      index: 25,
      selected: true,
    },

    {
      title: 'travelPrice',
      dataIndex: ['customer', 'travel_price'],
      index: 26,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'billing_travel_hourly_cost',
      dataIndex: ['supplier', 'travel_hourly_cost'],
      index: 27,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_travel_hourly_price',
      dataIndex: ['customer', 'travel_hourly_price'],
      index: 28,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'billing_travel_fix_cost',
      dataIndex: ['supplier', 'travel_fix_cost'],
      index: 29,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_travel_fix_price',
      dataIndex: ['customer', 'travel_fix_price'],
      index: 30,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_travel_distance_units',
      dataIndex: 'travel_distance_units',
      index: 31,
      selected: true,
    },
    {
      title: 'billing_travel_distance_cost',
      dataIndex: ['supplier', 'travel_distance_cost'],
      index: 32,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_travel_distance_price',
      dataIndex: ['customer', 'travel_distance_price'],
      index: 33,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_work_time',
      dataIndex: 'work_time',
      index: 34,
      selected: true,
    },
    {
      title: 'billing_work_time_cost',
      dataIndex: ['supplier', 'work_time_cost'],
      index: 35,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'billing_work_time_price',
      dataIndex: ['customer', 'work_time_price'],
      index: 36,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'chargeable',
      dataIndex: 'chargeable',
      index: 37,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'closure_notes',
      dataIndex: ['ticket', 'resolution_notes'],
      index: 38,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'supplier_expenses',
      dataIndex: ['supplier', 'expenses'],
      index: 39,
      selected: true,
      advancedRender: true,
    },

    {
      title: 'customer_expenses',
      dataIndex: ['customer', 'expenses'],
      index: 40,
      selected: true,
      advancedRender: true,
    },
  ],
  [ItsmAssets.Locations]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      selected: true,
      filterType: FilterTypes.Search,
      quickFilter: true,
    },
    {
      title: 'country',
      dataIndex: 'country',
      index: 1,
      selected: true,
      filterType: FilterTypes.Select,
      quickFilter: true,
    },
    {
      title: 'city',
      dataIndex: 'city',
      index: 2,
      selected: true,
      filterType: FilterTypes.Search,
      quickFilter: true,
    },
    {
      title: 'street',
      dataIndex: 'street',
      index: 3,
      selected: true,
      filterType: FilterTypes.Search,
      quickFilter: true,
    },
    {
      title: 'zip',
      dataIndex: 'zip',
      index: 4,
      selected: true,
      filterType: FilterTypes.Search,
      quickFilter: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 5,
      quickFilter: false,
      selected: false,
    },
    {
      title: 'updated_at',
      dataIndex: 'updated_at',
      index: 6,
      quickFilter: false,
      selected: false,
    },
    {
      title: 'created_by',
      dataIndex: ['created_by', 'full_name'],
      filterType: FilterTypes.SelectWithBM,
      index: 7,
      quickFilter: true,
      selected: false,
    },
  ],
  [ItsmAssets.Events]: [
    {
      title: 'caller',
      dataIndex: 'caller',
      index: 0,
      selected: true,
      advancedRender: true,
    },
    {
      title: 'short_description',
      dataIndex: 'short_description',
      index: 1,
      selected: true,
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
      index: 2,
      selected: true,
    },
  ],
  [ItsmAssets.Documents]: [
    {
      title: 'name',
      dataIndex: 'name',
      index: 0,
      selected: true,
      quickFilter: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'file_type',
      dataIndex: 'file_type',
      index: 1,
      selected: true,
      quickFilter: true,
      filterType: FilterTypes.Select,
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      index: 2,
      quickFilter: false,
      selected: true,
      advancedRender: true,
    },
  ],
}
