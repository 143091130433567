import {translate} from '@/services/i18n'
import {Interval} from '@/modules/typedef'

export const cyclePrice = {
  [Interval.YEAR]: translate('annually'),
  [Interval.MONTH]: translate('monthly'),
}

export const cyclePriceOptions = [
  {label: cyclePrice[Interval.YEAR], value: Interval.YEAR},
  {label: cyclePrice[Interval.MONTH], value: Interval.MONTH},
]
