import {useCallback, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {TUser} from '@/modules/ITSM/typedef'
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'

import {getRolesGrantableByMe} from '../api/rolesRequests'

export const useGetRoles = () => {
  const [roles, setRoles] = useState<TUser[]>([])

  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const getRoles = useCallback(
    async (channelId: string) => {
      handleRequested()

      try {
        const {
          body: {result},
        } = await getRolesGrantableByMe({
          'Grpc-Metadata-space': channelId,
          ...getToken(),
        })

        if (result) {
          setRoles(result)
          handleSuccess()
        }
      } catch (err) {
        handleFail(err)
      }
    },
    [handleRequested, handleSuccess, handleFail]
  )

  return {roles, status, getRoles}
}
