import React from 'react'

import {Form} from 'antd'
import {FormItemProps} from 'antd/es/form/FormItem'
import {NamePath} from 'antd/es/form/interface'

import FormItemStyledWrapper from './form-item-styled-wrapper/form-item-styled-wrapper'

interface IFromItemProps extends Omit<FormItemProps, 'name'> {
  name?: NamePath
  isGrey?: boolean
}

const FormItem = Form.Item

/**
 * FormItemCustom to be used when Antd. form is managing state & used to generate api paylaod
 * will validate field names per api types so no api errors caused by 'wrong string' in payload
 * ps:
 *  * had to named FormItemCustom vs FormItem as autoimport is alway suggesitng to import FormItem from antd
 *  * type assertion on name={name as NamePath} is requried to keep antd happy with FormItemProps => name
 * */

const FormItemCustom = ({
  name,
  children,
  isGrey = false,
  ...props
}: IFromItemProps) => {
  return (
    <FormItemStyledWrapper {...{isGrey}}>
      <FormItem name={name} {...props}>
        {children}
      </FormItem>
    </FormItemStyledWrapper>
  )
}

export default FormItemCustom
