import React, {useEffect, useState} from 'react'

import {Col, Form, Row, Select, Spin} from 'antd'
import {useDispatch, useSelector} from 'react-redux'

import {saveGeneralProfileSuccess} from '@/redux/profile/profileActions'
import {selectModuleProfile} from '@/redux/profile/profileSelector'
import {translate} from '@/services/i18n'
import {threeColumns} from '@/utils/table/constants/grid-columns'
import {RequestStatus} from '@/typedef'
import {useGetChannelsLocal} from '@/hooks/orgChannelHooks'
import {filterOption} from '@/utils/filter-option'
import {useGetAccountOrgs} from '@/hooks/use-get-account-orgs'
import {useGetMyAccounts} from '@/hooks/useGetMyAccounts'
import {handleMessage} from '@/utils/handle-message'
import {fetchProfile, postMyProfile} from '@/api/my-profile-requests'
import {Button} from '@/components/button'
import FormItemCustom from '@/components/form/form-item-custom'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

const {Option} = Select

type TStateOrg = {
  name: string
  id: string
}

type TStateSpace = {
  name: string
  space: string
}

export const OrgChannelDefault = () => {
  const {orgId, orgName, channelId, channelName, accountId, accountName} =
    useSelector(selectModuleProfile) || {}

  const [btnDisabled, setBtnDisabled] = useState(true)

  const [organization, setOrganization] = useState<TStateOrg>({
    name: orgName || '',
    id: orgId || '',
  })
  const [channel, setChannel] = useState<TStateSpace>({
    name: channelName || '',
    space: channelId || '',
  })
  const [account, setAccount] = useState({
    name: accountName,
    id: accountId,
  })

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const {
    channels,
    resetChannels,
    getChannels: fetchChannels,
    status: channelFetchStatus,
  } = useGetChannelsLocal()

  const {getOrgs, orgs, orgsFetchStatus, resetOrgs} = useGetAccountOrgs()

  const {
    getAccounts,
    data: accounts,
    status: accountsFetchStatus,
  } = useGetMyAccounts()

  const dispatch = useDispatch()

  const handleOrgSelect = async (id: string) => {
    setOrganization({
      id,
      name: orgs?.find(organization => organization.id === id)?.name || '',
    })
    setChannel({name: '', space: ''})
    resetChannels()
  }

  const handleChangeSpace = (space: string) => {
    setChannel({
      space,
      name: channels?.find(s => s.space === space)?.name || '',
    })
  }

  const handleCancel = () => {
    setOrganization({id: orgId, name: orgName})
    setChannel({space: channelId, name: channelName})
    setAccount({name: accountName, id: accountId})

    setBtnDisabled(true)
  }

  const handleAccountSelect = async (accountId: string) => {
    try {
      const accountName = accounts?.find(e => e.id === accountId)?.name || ''

      setOrganization({name: '', id: ''})
      setChannel({name: '', space: ''})

      setAccount({
        id: accountId,
        name: accountName,
      })
      resetChannels()
      resetOrgs()
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    try {
      setLoadingSubmit(true)
      handleMessage(RequestStatus.REQUESTED)

      await postMyProfile({
        accountId: account.id,
        orgId: organization.id,
        channelId: channel.space,
      })
      const {
        body: {profile},
      } = await fetchProfile()

      handleMessage(RequestStatus.SUCCEEDED)
      dispatch(saveGeneralProfileSuccess(profile))
      setBtnDisabled(true)
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      handleMessage(RequestStatus.FAILED)
    } finally {
      setLoadingSubmit(false)
    }
  }

  useEffect(() => {
    setBtnDisabled(!(account.id && organization.id && channel.space))
  }, [account.id, channel.space, organization.id])

  return (
    <Form layout="vertical">
      <Row className="org-channel-default" gutter={24}>
        <Col {...threeColumns}>
          <FormItemCustom label={translate('account')}>
            <Select
              showSearch
              onFocus={getAccounts}
              onChange={handleAccountSelect}
              filterOption={filterOption}
              value={account.name}
            >
              {accountsFetchStatus === RequestStatus.REQUESTED ? (
                <Option value="loading" key="loading">
                  <Spin />
                </Option>
              ) : (
                accounts.map(account => {
                  return (
                    <Option key={account.id} value={account.id}>
                      {account.name}
                    </Option>
                  )
                })
              )}
            </Select>
          </FormItemCustom>
        </Col>
        <Col {...threeColumns}>
          <FormItemCustom label={translate('organization')}>
            <Select
              showSearch
              getPopupContainer={e => e.parentNode}
              onSelect={handleOrgSelect}
              onFocus={() => getOrgs(account.id)}
              filterOption={filterOption}
              value={organization.name}
              loading={orgsFetchStatus === RequestStatus.REQUESTED}
            >
              {orgsFetchStatus === RequestStatus.REQUESTED ? (
                <Option value="loading" key="loading">
                  <Spin />
                </Option>
              ) : (
                orgs.map(organization => {
                  return (
                    <Option key={organization.id} value={organization.id}>
                      {organization.name}
                    </Option>
                  )
                })
              )}
            </Select>
          </FormItemCustom>
        </Col>
        <Col {...threeColumns}>
          <FormItemCustom label={translate('channel')}>
            <Select
              showSearch
              getPopupContainer={e => e.parentNode}
              onFocus={() => fetchChannels(organization.id)}
              onChange={handleChangeSpace}
              filterOption={filterOption}
              loading={channelFetchStatus === RequestStatus.REQUESTED}
              value={channel.name}
              disabled={!organization.id}
            >
              {channelFetchStatus === RequestStatus.REQUESTED ? (
                <Option value="loading" key="loading">
                  <Spin />
                </Option>
              ) : (
                channels
                  ?.filter(e => e.active)
                  ?.map(el => (
                    <Option value={el.space} key={el.space}>
                      {el.name}
                    </Option>
                  ))
              )}
            </Select>
          </FormItemCustom>
        </Col>
        <Col span={24}>
          <p>
            <Button
              title={translate('save')}
              type="primary"
              onClick={handleSubmit}
              disabled={btnDisabled || loadingSubmit}
              size="large"
            />
            <Button
              title={translate('cancel')}
              className="ml-10"
              size="large"
              onClick={handleCancel}
            />
          </p>
        </Col>
      </Row>
    </Form>
  )
}
