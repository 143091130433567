import React, {useEffect, useState} from 'react'

import {EllipsisOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons'
import {Button} from 'antd'

import './pagination.scss'

type TProps<T> = {
  numberOfRecords: number
  fetch: (bookmark?: string) => void
  pagination: T
}

const minimumToShowJump = 5
const jumpInPages = 3
const nextPage = 1
const previousPage = 1
const lastVisitedPage = 1
const fixOfIndex = 2
const startOfArray = 0
const showPreviousPages = 2

export const PaginationWithBookmarks = <T extends Record<string, any>>({
  numberOfRecords,
  fetch,
  pagination,
}: TProps<T>) => {
  const [indicationOfNextPage, setIndicationOfNextPage] = useState(true)

  useEffect(() => {
    const numberOfRecordsDefault = Boolean(
      numberOfRecords && numberOfRecords < 10
    )
    setIndicationOfNextPage(!numberOfRecordsDefault)
  }, [numberOfRecords])

  const activeBookmark = pagination?.bookmark
  const bookmarksInPagination =
    pagination?.bookmarks?.length >= 2 ? pagination.bookmarks.slice(0, -1) : []

  //if index of active bookmark is 0 then number in btn is 2
  const indexOfActiveBookmark = activeBookmark
    ? bookmarksInPagination.indexOf(activeBookmark)
    : -1

  const paginationExists = pagination && Object.keys(pagination).length > 0

  return (
    <div className="pagination">
      {/* left arrow  */}
      <Button
        onClick={() => {
          fetch(pagination.bookmarks[indexOfActiveBookmark - previousPage])
        }}
        className="arrow"
        key="left"
        disabled={!paginationExists || !activeBookmark}
      >
        <LeftOutlined />
      </Button>

      {/* first page */}
      <Button
        onClick={() => {
          fetch(undefined)
        }}
        key="first"
        className={activeBookmark === undefined ? 'active' : ''}
      >
        1
      </Button>
      {/* step -3 pages */}
      {indexOfActiveBookmark > jumpInPages ? (
        <Button
          type="dashed"
          key="double-left"
          onClick={() => {
            fetch(pagination.bookmarks[indexOfActiveBookmark - jumpInPages])
          }}
        >
          <EllipsisOutlined />
        </Button>
      ) : null}

      {/* map through pages which will be dynamic */}
      {bookmarksInPagination.length > 0 &&
        bookmarksInPagination
          .slice(
            indexOfActiveBookmark > jumpInPages
              ? indexOfActiveBookmark - showPreviousPages
              : startOfArray,
            bookmarksInPagination.length - indexOfActiveBookmark >
              minimumToShowJump
              ? indexOfActiveBookmark + jumpInPages
              : pagination.bookmarks.length
          )
          .map((bookmark: string) => {
            return (
              <Button
                className={bookmark === activeBookmark ? 'active' : ''}
                onClick={() => {
                  fetch(bookmark)
                }}
                key={pagination.bookmarks.indexOf(bookmark) + 1}
              >
                {pagination.bookmarks.indexOf(bookmark) + fixOfIndex}
              </Button>
            )
          })}
      {/* step +3 pages and last known page */}
      {bookmarksInPagination.length - indexOfActiveBookmark >
        minimumToShowJump && (
        <span>
          <Button
            type="dashed"
            key="double-right"
            onClick={() => {
              fetch(pagination.bookmarks[indexOfActiveBookmark + jumpInPages])
            }}
          >
            <EllipsisOutlined />
          </Button>
          <Button
            onClick={() => {
              fetch(
                pagination.bookmarks[
                  bookmarksInPagination.length - lastVisitedPage
                ]
              )
            }}
            key="last"
          >
            {pagination.bookmarks.length}
          </Button>
        </span>
      )}

      {/* indication of next page */}
      {paginationExists && numberOfRecords > 10 && indicationOfNextPage && (
        <div className={'flex flex--alignCenter mr-3'}>
          <EllipsisOutlined />
        </div>
      )}

      {/* right arrow  */}
      <Button
        onClick={() => {
          fetch(pagination.bookmarks[indexOfActiveBookmark + nextPage])
        }}
        className="arrow"
        key="right"
        disabled={numberOfRecords <= 10 || !paginationExists}
      >
        <RightOutlined />
      </Button>
    </div>
  )
}
