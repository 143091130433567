import request from 'superagent'

import {getBackendTokenHeaders, profileApi} from '@/services/api'

import {THeaders, TProfileData, TProfileRes} from './typedf'
import {ISuperAgentResponse} from './response-typedf'

export const fetchProfile = async (headers?: Record<string, string>) => {
  return await request
    .get(profileApi().get)
    .set(headers || getBackendTokenHeaders())
}

export const postMyProfile = async (
  data: Record<string, unknown>,
  headers?: THeaders
): Promise<ISuperAgentResponse<TProfileRes>> =>
  await request
    .post(profileApi().post)
    .set(headers || getBackendTokenHeaders())
    .send(data)

export const postMyProfileWithHeaders = async ({
  payload,
  headers,
}: {
  payload: TProfileData
  headers: {Authorization: string}
}): Promise<ISuperAgentResponse<TProfileRes>> =>
  await request
    .post(profileApi().post)
    .set(headers)
    .send(payload)
