import {ISubscriptionDatum, PriceFeatureFlagKeys} from '@/modules/typedef'

export const extractFeatureFlagsSubscriptions = (
  data: Array<ISubscriptionDatum>
) => {
  return data.reduce((total, {items: {data}}) => {
    return [
      ...total,
      ...data.flatMap(({price}) => {
        return total.concat(price.metadata.feature_flag as PriceFeatureFlagKeys)
      }),
    ]
  }, [] as Array<PriceFeatureFlagKeys>)
}
