import React, {FC} from 'react'

import {Col, Form as AntdForm, Input, Row} from 'antd'

import {Form} from '@/components/form/form/form'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {translate} from '@/services/i18n'

import {TCCreateAccount} from '@/components/accountForm/types'
import {twoColumns} from '@/utils/table/constants/grid-columns'

type TProps = {
  onSubmit: (values: {channel_name: string}) => void
  initialValues: {channel_name?: string}
}

export const CreateChannelForm: FC<TProps> = ({onSubmit, initialValues}) => {
  const [form] = AntdForm.useForm()

  return (
    <Form form={form} onSubmit={onSubmit} initialValues={initialValues}>
      <Row>
        <Col {...twoColumns}>
          <FormItemGeneric<TCCreateAccount>
            name="channel_name"
            label={translate('channel_name')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
      </Row>
    </Form>
  )
}
