import {ActionTypes} from '@/modules/ITSM/store/action-types'
import {ChannelInviteStep} from '@/modules/ITSM/components/accept-invite/typedf'
import {RequestStatus} from '@/typedef'
import {TUserDefaults} from '@/modules/ITSM/typedef'

export const refreshInviteOrgTokenReq = (grant_org: string) => {
  return {
    type: ActionTypes.REFRESH_INVITE_ORG_TOKEN_REQUESTED,
    payload: grant_org,
  }
}

export const refreshOrgTokenSuccess = () => {
  return {
    type: ActionTypes.REFRESH_INVITE_ORG_TOKEN_SUCCESS,
  }
}

export const refreshOrgTokenFailed = (error: string | unknown) => {
  return {
    type: ActionTypes.REFRESH_INVITE_ORG_TOKEN_FAILED,
    payload: error,
  }
}

export const postIdentityChannelInviteReq = (orgToken: string) => {
  return {
    type: ActionTypes.POST_IDENTITY_CHANNEL_INVITE_REQUESTED,
    payload: orgToken,
  }
}

export const postIdentityChannelInviteFailed = (error: string | unknown) => {
  return {
    type: ActionTypes.POST_IDENTITY_CHANNEL_INVITE_FAILED,
    payload: error,
  }
}

export const postIdentityChannelInviteSuccess = () => {
  return {
    type: ActionTypes.POST_IDENTITY_CHANNEL_INVITE_SUCCESS,
  }
}

export const setIdentityExists = () => {
  return {
    type: ActionTypes.SET_IDENTITY_EXISTS,
  }
}

export const setInviteLoadingStatus = (status: RequestStatus) => {
  return {
    type: ActionTypes.SET_INVITE_LOADING_STATUS,
    payload: status,
  }
}

export const setStep = (step: ChannelInviteStep) => {
  return {
    type: ActionTypes.SET_CURRENT_STEP,
    payload: step,
  }
}

export const setUserDefaults = (user_defaults: TUserDefaults) => {
  return {
    type: ActionTypes.SET_USER_DEFAULTS,
    payload: user_defaults,
  }
}

export const setAcceptInviteError = (error: string | unknown | null) => {
  return {
    type: ActionTypes.SET_ACCEPT_INVITE_ERROR,
    payload: error,
  }
}
