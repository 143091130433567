import moment from 'moment'

import {
  TBankHolidays,
  TCustomerProduct,
  TWeekdays,
} from '@/modules/ITSM/typedef'
import {
  dayNumberFormat,
  DAYS,
  ProductTypes,
  SlaTypes,
} from '@/modules/ITSM/utils/Constants'
import {TWeekdayPricingPolicy} from '@/typedef'

import {TFormValues} from './types'
import {PricingType, ResolutionTimeEval} from './constants'

export const parseFetchedData = (entityData?: TCustomerProduct) => {
  const setMaxResponseTimeBusinessDays = (
    max_response_time: number,
    max_response_time_business_days: number
  ) => {
    if (max_response_time !== undefined) {
      return max_response_time_business_days
    }
    return 0
  }

  const setWeekdaysArray = (weekdays: {[key in string]: TWeekdays}) => {
    if (Object.values(weekdays)?.length > 0) {
      return DAYS.map((_, index) => {
        const day = Object.values(weekdays || {})[index]
        return {
          support_from: day?.support_from
            ? moment(day?.support_from, 'HH:mm')
            : null,
          support_to: day?.support_to ? moment(day?.support_to, 'HH:mm') : null,
        }
      })
    } else {
      return new Array(5).fill({
        support_from: moment('08:00', 'HH:mm'),
        support_to: moment('17:00', 'HH:mm'),
      })
    }
  }

  const setBankHolidays = (bank_holidays: TBankHolidays) => {
    return {
      support_from: bank_holidays?.support_from
        ? moment(bank_holidays?.support_from, 'HH:mm')
        : null,
      support_to: bank_holidays?.support_to
        ? moment(bank_holidays?.support_to, 'HH:mm')
        : null,
    }
  }

  const setWeekdayPricingPolicies = (weekday_pricing_policies: {
    [x: number]: {[x: number]: TWeekdayPricingPolicy}
  }) => {
    const weekdayObject =
      weekday_pricing_policies && Object.values(weekday_pricing_policies).length
    return Object.values(
      weekdayObject > 0 ? weekday_pricing_policies : new Array(7).fill([])
    )?.map(weekday =>
      (weekday as TWeekdayPricingPolicy[])?.map(it => {
        return {
          ...it,
          from: moment(it.from, 'HH:mm'),
          to: moment(it.to, 'HH:mm'),
        }
      })
    )
  }

  const setHolidayPricingPolicies = (
    holiday_pricing_policies: TWeekdayPricingPolicy[]
  ) => {
    if (holiday_pricing_policies) {
      return holiday_pricing_policies?.map(it => {
        return {
          ...it,
          from: moment(it.from, 'HH:mm'),
          to: moment(it.to, 'HH:mm'),
        }
      })
    }
    return []
  }

  const setHolidaysArray = (holidays: string[]) => {
    if (holidays?.length > 0) {
      return holidays.map((it: string) => moment(it, dayNumberFormat))
    }
    return []
  }

  const setMaxResponseTime = (max_response_time: number) => {
    if (max_response_time !== undefined) {
      return Math.round(max_response_time / 60 ** 2)
    }
    return 0
  }

  if (entityData) {
    const {
      name,
      code,
      dispatch,
      pricing_policy: {
        additional_hour_rate,
        additional_pricing_unit,
        currency,
        pricing_unit,
        primary_hour_rate,
        primary_hours_number,
        rate,
        travel_distance_unit_rate,
        travel_fix_rate,
        travel_hour_rate,
        travel_pricing_unit,
        weekday_pricing_policies,
        holiday_pricing_policies,
      } = {
        additional_hour_rate: null,
        additional_pricing_unit: null,
        currency: null,
        pricing_unit: null,
        primary_hour_rate: null,
        primary_hours_number: null,
        rate: null,
        travel_distance_unit_rate: null,
        travel_fix_rate: null,
        travel_hour_rate: null,
        travel_pricing_unit: null,
        weekday_pricing_policies: null,
        holiday_pricing_policies: null,
      },
      product_type,
      resolution_time_eval,
      chargeable,
      holidays,
      active_from,
      active_to,
      pricing_type,
      location,
      sla_type,
      sla: {
        inactive_timeout,
        max_resolution_time,
        max_resolution_time_business_days,
        max_response_time,
        max_response_time_business_days,
        timezone,
        safe_time_interval,
        weekdays,
        bank_holidays,
      },
    } = (entityData as TCustomerProduct) || {}

    const formValuesObject: TFormValues = {
      name,
      code,
      sla_type,
      pricing_policy_currency: currency,
      pricing_policy_primary_hour_rate: primary_hour_rate,
      pricing_policy_additional_hour_rate: additional_hour_rate,
      pricing_policy_additional_pricing_unit: additional_pricing_unit
        ? Math.round(additional_pricing_unit / 60)
        : '',

      pricing_policy_primary_hours_number: primary_hours_number,
      pricing_policy_pricing_unit: pricing_unit
        ? Math.round(pricing_unit / 60)
        : '',
      pricing_policy_rate: rate,
      pricing_policy_travel_fix_rate: travel_fix_rate,
      pricing_policy_travel_hour_rate: travel_hour_rate,
      pricing_policy_travel_pricing_unit: travel_pricing_unit,
      pricing_policy_travel_distance_unit_rate: travel_distance_unit_rate,

      location: `${location?.country || ''} ${location?.city ||
        ''} ${location?.street || ''}`,
      response_time: max_response_time_business_days,
      dispatch: dispatch !== undefined ? dispatch : true,
      product_type: product_type || ProductTypes.SERVICES,

      sla_max_response_time: setMaxResponseTime(max_response_time),
      sla_max_response_time_business_days: setMaxResponseTimeBusinessDays(
        max_response_time,
        max_response_time_business_days
      ),
      sla_max_resolution_time:
        max_resolution_time !== undefined
          ? Math.round(max_resolution_time / 60 ** 2)
          : 0,
      sla_max_resolution_time_business_days:
        max_resolution_time_business_days !== undefined
          ? max_resolution_time_business_days
          : 0,
      sla_safe_time_interval:
        safe_time_interval !== undefined
          ? Math.round(safe_time_interval / 60 ** 2)
          : 2,
      sla_inactive_timeout:
        inactive_timeout !== undefined
          ? Math.round((inactive_timeout / 60 ** 2 / 24) * 100) / 100
          : 10,
      sla_timezone: timezone || '',

      active_from: active_from ? moment(active_from) : moment(),
      active_to: active_to ? moment(active_to) : moment().add(5, 'year'),
      pricing_type: PricingType[pricing_type] || PricingType.per_visit,
      chargeable: chargeable !== undefined ? chargeable : true,
      weekdays: setWeekdaysArray(weekdays),
      holiday_pricing_policies: holiday_pricing_policies
        ? setHolidayPricingPolicies(holiday_pricing_policies)
        : null,
      holidays: setHolidaysArray(holidays),
      resolution_time_eval:
        ResolutionTimeEval[resolution_time_eval] ||
        ResolutionTimeEval.at_assign,
      weekday_pricing_policies: weekday_pricing_policies
        ? setWeekdayPricingPolicies(weekday_pricing_policies)
        : null,
      sla_bank_holidays: setBankHolidays(bank_holidays || {}),
    }
    return formValuesObject
  } else {
    const newFormValuesObject: TFormValues = {
      name: '',
      code: '',
      sla_type: SlaTypes.P1,
      pricing_policy_currency: '',
      pricing_policy_primary_hour_rate: '',
      pricing_policy_additional_hour_rate: '',
      pricing_policy_additional_pricing_unit: '',
      pricing_policy_primary_hours_number: '',
      pricing_policy_pricing_unit: '',
      pricing_policy_rate: 0,
      pricing_policy_travel_fix_rate: 0,
      pricing_policy_travel_hour_rate: 0,
      pricing_policy_travel_pricing_unit: 0,
      pricing_policy_travel_distance_unit_rate: 0,

      location: '',
      response_time: 0,
      dispatch: true,
      product_type: ProductTypes.SERVICES,

      sla_max_response_time: 0,
      sla_max_response_time_business_days: 0,
      sla_max_resolution_time: 0,
      sla_max_resolution_time_business_days: 0,
      sla_safe_time_interval: 2,
      sla_inactive_timeout: 10,
      sla_timezone: '',

      active_from: moment(),
      active_to: moment().add(4, 'year'),
      pricing_type: PricingType.per_visit,
      chargeable: true,
      weekdays: setWeekdaysArray({}),
      holiday_pricing_policies: setHolidayPricingPolicies([]),
      holidays: setHolidaysArray([]),
      resolution_time_eval: ResolutionTimeEval.at_assign,
      weekday_pricing_policies: setWeekdayPricingPolicies({}),
      sla_bank_holidays: setBankHolidays({}),
    }
    return newFormValuesObject
  }
}
