import React from 'react'

import classNames from 'classnames'

import {
  IncidentState,
  INCIDENT_STATE_OPTIONS,
} from '../../../incident-k-request/constants'

import './itsm-list-table-state-label.scss'

type TProps = {
  state_id: IncidentState
}

const ItsmListTableStateLabel = ({state_id}: TProps) => {
  const incidentStateOptionName = INCIDENT_STATE_OPTIONS[state_id].name
  const stateLabelClass = classNames(
    'state-label',
    `state-label--${incidentStateOptionName.toLowerCase()}`
  )

  return <div className={stateLabelClass}>{incidentStateOptionName}</div>
}

export default ItsmListTableStateLabel
