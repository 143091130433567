import React, {useState} from 'react'

import classnames from 'classnames'
import {getIconsPath} from '@/modules/CMDB/utils/utils'
import {Col, Row, Tooltip} from 'antd'
import {translate} from '@/services/i18n'

import {Button} from '@/components/button'

import './landing-page.scss'

import {useCustomAuth} from '@/hooks/use-custom-auth'

import SvgIcon from '@/components/svg-icon/svg-icon'

export default function LandingPage() {
  const {loginRedirect, signUpRedirect} = useCustomAuth()
  const [hideTooltip, setHideTooltip] = useState(
    !!localStorage.getItem('hide_login_btn_tooltip')
  )

  const onVisibleChangeTooltip = (isOpened: boolean) => {
    if (!isOpened) {
      localStorage.setItem('hide_login_btn_tooltip', 'true')
      setHideTooltip(true)
    }
  }

  return (
    <div className="landing-page">
      <div className="landing-page__header">
        <SvgIcon name="blits-logo" className="landing-page__logo" width={100} />
      </div>
      <Row className="landing-page__body" gutter={[24, 24]}>
        <Col
          xs={24}
          lg={10}
          className="flex flex--alignCenter flex--justifyCenter mb-24"
        >
          <div className="landing-page__text-block">
            <h2>{translate('welcome_blits')}</h2>
            <p>{translate('landing_page_content')}</p>
            <div className="flex flex--justifyEnd">
              <Tooltip
                {...(hideTooltip ? {visible: false} : {})}
                defaultOpen={true}
                onOpenChange={onVisibleChangeTooltip}
                placement="topLeft"
                overlayClassName="landing-page__tooltip"
                trigger={['click', 'hover']}
                title="Here you can log into your account"
              >
                <Button
                  className={classnames('mr-24', {
                    'landing-page__tooltip-border': !hideTooltip,
                  })}
                  title={translate('log_in')}
                  type="primary"
                  onClick={() => loginRedirect()}
                />
              </Tooltip>
              <Button
                title={translate('sign_up')}
                onClick={() => signUpRedirect()}
              />
            </div>
          </div>
        </Col>
        <Col className="landing-page__img" xs={24} lg={14}>
          <img
            className="landing-page__screen full-width"
            src={getIconsPath('CMDB')}
            title="CMDB Dashboard Screen"
            alt="cmdb"
          />
          <img
            className="landing-page__mask"
            src={getIconsPath('mask')}
            title="mask"
            alt="mask"
          />
        </Col>
      </Row>
    </div>
  )
}
