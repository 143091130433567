import React, {Fragment, useEffect, useState} from 'react'

import moment, {Moment} from 'moment'
import {
  Button as AntButton,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd'
import {Rule} from 'antd/lib/form'
import {MinusOutlined, PlusOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import classnames from 'classnames'
import {v4 as uuid} from 'uuid'

import {Button} from '@/components/button/button'
import {translate} from '@/services/i18n'
import './customer-product-form.scss'
import {
  currencies,
  dayNumberFormat,
  DAYS,
  ProductTypes,
  ResponseTypes,
  SlaTypes,
} from '@/modules/ITSM/utils/Constants'
import {NewLocationButton} from '@/modules/ITSM/components/createNewLocation/newLocationButton'
import {ItsmAssets, TCustomerProduct, TLocation} from '@/modules/ITSM/typedef'
import {TIME_ZONES} from '@/constants'
import {
  patchCustomerProduct,
  postCustomerProduct,
} from '@/modules/ITSM/api/productsRequests'
import {Dash} from '@/components/dash/dash'
import {usePatchEntity, usePostEntity} from '@/hooks/useUpdateData'
import {TWeekdayPricingPolicy} from '@/typedef'
import {entityDetailPath} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/constants/routes-config'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {OverlaySpinner} from '@/components/overlay-spinner'
import {scrollToField} from '@/utils/scroll-to-field'
import {saveLocation} from '@/modules/ITSM/api/locationRequests'
import {useQuery} from '@/utils/useQuery'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import FormItemCustom from '@/components/form/form-item-custom'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {changeDataFormat} from './utils/change-data-format'
import Weekdays from './weekdays'
import {TFormValues} from './utils/types'
import {
  activeValidation,
  validateBankHolidays,
  validateHoliday,
} from './utils/utils'
import PricingPolicyItem from './pricing-policy-item'
import {PricingType, ResolutionTimeEval, TimeTypes} from './utils/constants'
import {parseFetchedData} from './utils/parse-fetched-data'
import {checkIsLoading} from '@/utils/check-is-loading'
import DatePicker from '@/components/date-picker/date-picker'
import TimePicker from '@/components/time-picker/time-picker'

const Option = Select.Option

const {Panel} = Collapse

type TCustomerProductForm = {
  entityData?: TCustomerProduct
  id: string
  getData?: ({
    id,
    selector,
  }: {
    id: string
    selector?: Record<string, any>
  }) => Promise<TCustomerProduct | undefined>
}

const CustomerProductForm = ({
  entityData,
  id,
  getData,
}: TCustomerProductForm) => {
  const history = useHistory()
  const asset = ItsmAssets.CustomerProducts
  const [form] = Form.useForm()
  const isCopy = useQuery('copy')

  const isDiasabled = entityData && !entityData.pricing_policy
  const initialFormState = parseFetchedData(entityData)

  const {
    saveEntity: updateCustomerProduct,
    saveEntityStatus: patchStatus,
  } = usePatchEntity<TCustomerProduct>(patchCustomerProduct)

  const {
    saveEntity: insertCustomerProduct,
    saveEntityStatus: postStatus,
  } = usePostEntity<TCustomerProduct>(postCustomerProduct)

  useEffect(() => {
    setProductLocation(entityData?.location)
  }, [entityData?.location])

  const [btnDisabled, setBtnDisabled] = useState(true)
  const [activeKey, setActiveKey] = useState('')
  const [responseTimeUnit, setResponseTimeUnit] = useState<string>(
    ResponseTypes.DAYS
  )
  const [inactiveTimeout, setInactiveTimeout] = useState<string>(
    ResponseTypes.DAYS
  )
  const [pricingUnit, setPricingUnit] = useState<string>(TimeTypes.MIN)
  const [additionalPricingUnit, setAdditionalPricingUnit] = useState<string>(
    TimeTypes.MIN
  )

  const [productLocation, setProductLocation] = useState<
    TLocation | undefined
  >()

  useEffect(() => {
    form.resetFields()
    setPricingUnit(TimeTypes.MIN)
    setAdditionalPricingUnit(TimeTypes.MIN)
    setInactiveTimeout(ResponseTypes.DAYS)
  }, [entityData, form])

  const selectResponseUnitHandler = (value: string) => {
    setResponseTimeUnit(value)
    if (value === ResponseTypes.HOURS) {
      const slaResponseTile = form.getFieldValue('sla_max_response_time')
      form.setFieldsValue({response_time: slaResponseTile})
    }
    if (value === ResponseTypes.DAYS) {
      const slaResponseTile = form.getFieldValue(
        'sla_max_response_time_business_days'
      )
      form.setFieldsValue({response_time: slaResponseTile})
    }
  }

  const inactiveTimeoutUnitHandler = (value: string) => {
    setInactiveTimeout(value)
    const timeout = form.getFieldValue('sla_inactive_timeout')
    if (value === ResponseTypes.HOURS) {
      const timeout = form.getFieldValue('sla_inactive_timeout')
      form.setFieldsValue({
        sla_inactive_timeout: Math.round(timeout * 24),
      })
    }
    if (value === ResponseTypes.DAYS) {
      form.setFieldsValue({
        sla_inactive_timeout: Math.round((timeout / 24) * 100) / 100,
      })
    }
  }

  const responseTimeHandler = () => {
    const newTime = form.getFieldValue('response_time')
    form.setFieldsValue(
      responseTimeUnit === ResponseTypes.HOURS
        ? {
            sla_max_response_time: newTime,
            sla_max_response_time_business_days: 0,
          }
        : {
            sla_max_response_time: 0,
            sla_max_response_time_business_days: newTime,
          }
    )
  }

  const maxResolutionTimeHandler = () => {
    const newTime = form.getFieldValue('sla_max_resolution_time')
    form.setFieldsValue({
      sla_max_resolution_time: newTime,
      sla_max_resolution_time_business_days: 0,
    })
  }
  const maxResolutionTimeBDHandler = () => {
    const newTime = form.getFieldValue('sla_max_resolution_time_business_days')
    form.setFieldsValue({
      sla_max_resolution_time: 0,
      sla_max_resolution_time_business_days: newTime,
    })
  }

  const selectPricingUnitHandler = (value: string) => {
    setPricingUnit(value)
    const unitValue = form.getFieldValue('pricing_policy_pricing_unit')
    form.setFieldsValue(
      value === TimeTypes.HR
        ? {
            pricing_policy_pricing_unit:
              Math.round((unitValue / 60) * 100) / 100,
          }
        : {pricing_policy_pricing_unit: Math.round(unitValue * 60)}
    )
  }
  const selectAdditionalPricingUnitHandler = (value: string) => {
    setAdditionalPricingUnit(value)
    const unitValue = form.getFieldValue(
      'pricing_policy_additional_pricing_unit'
    )
    form.setFieldsValue(
      value === TimeTypes.HR
        ? {
            pricing_policy_additional_pricing_unit:
              Math.round((unitValue / 60) * 100) / 100,
          }
        : {pricing_policy_additional_pricing_unit: Math.round(unitValue * 60)}
    )
  }

  const generateSubmitData = (location?: TLocation) => {
    const values = form.getFieldsValue()
    return changeDataFormat({
      values,
      initialValues: entityData,
      pricingUnit,
      additionalPricingUnit,
      inactiveTimeout,
      location,
    })
  }

  const submitHandler = async () => {
    const submitData = generateSubmitData(productLocation)
    if (submitData && entityData && !isCopy) {
      const result = await updateCustomerProduct(submitData, id)
      setBtnDisabled(true)
      if (!result) return
      if (getData) getData({id})
    }
    if (submitData && (!entityData || isCopy)) {
      const result = await insertCustomerProduct(submitData, id)
      if (!result) return
      return history.push(entityDetailPath[asset]?.(id) as string)
    }
  }

  const goBackHandler = () => {
    history.push(itsmRoutes.settingsITSMCustomerProducts.path())
  }

  const changeHandler = (_: Moment | null, date: string, interval: string) => {
    form.setFieldsValue(
      interval === 'active_from'
        ? {active_from: date ? moment(date) : null}
        : {active_to: date ? moment(date) : null}
    )
  }

  const changeBankHolidaysHandler = (
    _: Moment | null,
    date: string,
    interval: string
  ) => {
    form.setFieldsValue(
      interval === 'support_from'
        ? {active_from: date ? moment(date, 'HH:mm') : null}
        : {active_to: date ? moment(date, 'HH:mm') : null}
    )
  }

  const changeHolidayHandler = (_: Moment, date: string, index: number) => {
    const holidays: string[] = form.getFieldValue('holidays')
    form.setFieldsValue({
      holidays: holidays.map((item, ind) => {
        if (ind === index) {
          if (date) {
            return moment(date, dayNumberFormat)
          } else {
            return null
          }
        } else {
          return item
        }
      }),
    })
    form.validateFields()
  }

  const onSave = (locationData: TLocation) => {
    setBtnDisabled(false)
    setProductLocation(locationData)

    form.setFieldsValue({
      location: `${locationData?.country} ${locationData?.city} ${locationData?.street}`,
    })
  }

  const validatorValue = (value: Moment) => {
    const holidayString = (
      form.getFieldValue('holidays') || []
    ).map((holiday: string) => moment(holiday).format(dayNumberFormat))
    return {
      holidayString,
      value,
    }
  }

  const getBankHolidayValidationData = (value: Moment, array: string[]) => {
    const pairValue = form.getFieldValue(array)
    return {
      value,
      pairValue,
      array,
    }
  }

  const collapseHandler = () =>
    activeKey === '' ? setActiveKey('1') : setActiveKey('')

  const isLoading = checkIsLoading([postStatus, patchStatus])

  const modifierForm = {
    'form--loading': checkIsLoading([postStatus, patchStatus]),
  }

  useEffect(() => {
    const name = form.getFieldValue('name')
    if (isCopy && !/\w*COPY\b/.test(name)) {
      form.setFieldsValue({name: `${name} COPY`})
    }
  }, [entityData?.name, form, isCopy])

  const copyClickHandler = () => {
    const productCopyId = uuid()
    history.push({
      pathname: entityDetailPath[asset]?.(productCopyId),
      search: 'copy=true',
    })
    setBtnDisabled(false)
    form.scrollToField('name')
  }

  return (
    <Paper
      className={classnames('form', modifierForm)}
      variant={PaperVariant.form}
    >
      <GoBackButton onClick={goBackHandler} />
      {isLoading && (
        <div className="form__spinner">
          <OverlaySpinner idName={'customer-product-form'} />
        </div>
      )}
      {isDiasabled && (
        <Row>
          <h6 className="form-permission text-center">
            {translate('no_permissions_to_edit')}
          </h6>
        </Row>
      )}
      <Form
        layout="vertical"
        className="product-form__form-grid"
        form={form}
        onFinish={submitHandler}
        initialValues={initialFormState}
        onFinishFailed={errorFields => {
          scrollToField({errorFields, form})
          setActiveKey('1')
        }}
        onValuesChange={() => setBtnDisabled(false)}
        autoComplete="off"
      >
        <div>
          <Row>
            <Col>
              <h5>{translate('general')}</h5>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--two">
              <FormItemGeneric<TFormValues>
                name="name"
                label={translate('name')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDiasabled} autoComplete="off" />
              </FormItemGeneric>
              <FormItemGeneric<TFormValues>
                name="code"
                label={translate('code')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={isDiasabled} />
              </FormItemGeneric>
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--two" id="col">
              <FormItemGeneric<TFormValues>
                label={
                  <div className="location-select__label">
                    <span>{translate('location')}</span>
                    <NewLocationButton
                      record={productLocation}
                      itemType={ItsmAssets.CustomerProducts}
                      formRules={{name: [{required: true}]}}
                      onSave={onSave}
                      asyncF={saveLocation}
                    />
                  </div>
                }
                name="location"
              >
                <Input readOnly />
              </FormItemGeneric>

              <FormItemGeneric<TFormValues>
                name="sla_timezone"
                label={translate('time_zone')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={isDiasabled}
                  showSearch={true}
                  allowClear={true}
                >
                  {TIME_ZONES.map(({value, time}) => (
                    <Option value={value} key={value}>
                      {`${value} [UTC${time}]`}
                    </Option>
                  ))}
                </Select>
              </FormItemGeneric>
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--two-nested">
              <FormItemGeneric<TFormValues>
                name="sla_type"
                label={translate('sla_type')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={isDiasabled}
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                >
                  {Object.values(SlaTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemGeneric>

              <FormItemGeneric<TFormValues>
                label={translate('response_time')}
                name="response_time"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled={isDiasabled}
                  type="number"
                  onChange={responseTimeHandler}
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                />
              </FormItemGeneric>
              <FormItemCustom>
                <Select
                  disabled={isDiasabled}
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                  onChange={selectResponseUnitHandler}
                  value={responseTimeUnit}
                >
                  {Object.values(ResponseTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>{translate('pricing_policy')}</h5>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--one-currency">
              <FormItemGeneric<TFormValues>
                name={'pricing_policy_currency'}
                label={translate('currency')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  disabled={isDiasabled}
                  showSearch
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                >
                  {currencies.map(currency => (
                    <Option value={currency.value} key={currency.value}>
                      {currency.value}
                    </Option>
                  ))}
                </Select>
              </FormItemGeneric>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>{translate('primary_hours')}</h6>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--four-with-unit">
              <FormItemGeneric<TFormValues>
                name={'pricing_policy_primary_hour_rate'}
                label={translate('unit_rate')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber disabled={isDiasabled} type="number" />
              </FormItemGeneric>
              <FormItemGeneric<TFormValues>
                label={translate('unit_number')}
                name={'pricing_policy_primary_hours_number'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled={isDiasabled}
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                />
              </FormItemGeneric>
              <FormItemGeneric<TFormValues>
                label={translate('unit')}
                name={'pricing_policy_pricing_unit'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled={isDiasabled}
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                />
              </FormItemGeneric>
              <FormItemCustom>
                <Select
                  disabled={isDiasabled}
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                  onChange={selectPricingUnitHandler}
                  value={pricingUnit}
                >
                  {Object.values(TimeTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>

          <Row>
            <Col>
              <h6>{translate('additional_hours')}</h6>
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--two-nested">
              <FormItemGeneric<TFormValues>
                name={'pricing_policy_additional_hour_rate'}
                rules={[
                  {
                    required: true,
                  },
                ]}
                label={translate('unit_rate')}
              >
                <InputNumber disabled={isDiasabled} type="number" />
              </FormItemGeneric>

              <FormItemGeneric<TFormValues>
                label={translate('unit')}
                name={'pricing_policy_additional_pricing_unit'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled={isDiasabled}
                  type="number"
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                />
              </FormItemGeneric>

              <FormItemCustom>
                <Select
                  disabled={isDiasabled}
                  getPopupContainer={e => e.parentNode}
                  className="full-width"
                  onChange={selectAdditionalPricingUnitHandler}
                  value={additionalPricingUnit}
                >
                  {Object.values(TimeTypes).map(name => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItemCustom>
            </Col>
          </Row>

          <Collapse ghost activeKey={activeKey} onChange={collapseHandler}>
            <Panel header={translate('more_options')} key="1" forceRender>
              <Row>
                <Col>
                  <FormItemGeneric<TFormValues>
                    name="dispatch"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDiasabled}>
                      {translate('dispatch')}
                    </Checkbox>
                  </FormItemGeneric>
                </Col>
              </Row>
              <Row>
                <Col className="col-flex--two">
                  <FormItemGeneric<TFormValues>
                    name="product_type"
                    label={translate('product_type')}
                  >
                    <Select
                      disabled={isDiasabled}
                      getPopupContainer={e => e.parentNode}
                      className="full-width"
                    >
                      {Object.values(ProductTypes).map(name => (
                        <Option value={name} key={name}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormItemGeneric>

                  <FormItemGeneric<TFormValues>
                    name="product_group"
                    label={translate('product_group')}
                  >
                    <Input disabled={isDiasabled} />
                  </FormItemGeneric>
                </Col>
              </Row>

              <Row>
                <Col className="col-flex--two">
                  <FormItemGeneric<TFormValues>
                    name={'sla_max_response_time'}
                    label={translate('max_response_time')}
                  >
                    <Input readOnly />
                  </FormItemGeneric>

                  <FormItemGeneric<TFormValues>
                    name={'sla_max_response_time_business_days'}
                    label={translate('max_response_time_business_days')}
                  >
                    <Input readOnly />
                  </FormItemGeneric>
                </Col>
              </Row>
              <Row>
                <Col className="col-flex--two">
                  <FormItemGeneric<TFormValues>
                    name={'sla_max_resolution_time'}
                    label={translate('max_resolution_time')}
                  >
                    <InputNumber
                      disabled={isDiasabled}
                      type="number"
                      onChange={maxResolutionTimeHandler}
                      onKeyDown={e => {
                        if (e.key === '.') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </FormItemGeneric>

                  <FormItemGeneric<TFormValues>
                    name={'sla_max_resolution_time_business_days'}
                    label={translate('max_resolution_time_business_days')}
                  >
                    <InputNumber
                      disabled={isDiasabled}
                      type="number"
                      onChange={maxResolutionTimeBDHandler}
                      onKeyDown={e => {
                        if (e.key === '.') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </FormItemGeneric>
                </Col>
              </Row>
              <Row>
                <Col className="col-flex--two col-flex--two-mobile">
                  <FormItemGeneric<TFormValues>
                    name="active_from"
                    label={translate('active_from')}
                    dependencies={['active_to']}
                    rules={[
                      {
                        required: true,
                        validateTrigger: 'onChange',

                        validator: (_: Rule, value: Moment) =>
                          activeValidation(_, {
                            value,
                            intervalName: 'active_from',
                            interval: form.getFieldValue('active_to'),
                          }),
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={isDiasabled}
                      getPopupContainer={e => e.parentNode as HTMLElement}
                      minuteStep={5}
                      onChange={(_, date) =>
                        changeHandler(_, date as any, 'active_from')
                      }
                    />
                  </FormItemGeneric>

                  <FormItemGeneric<TFormValues>
                    name="active_to"
                    label={translate('active_to')}
                    dependencies={['active_from']}
                    rules={[
                      {
                        required: true,
                        validateTrigger: 'onChange',
                        validator: (_: Rule, value: Moment) =>
                          activeValidation(_, {
                            value,
                            intervalName: 'active_to',
                            interval: form.getFieldValue('active_from'),
                          }),
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={isDiasabled}
                      getPopupContainer={e => e.parentNode as HTMLElement}
                      minuteStep={5}
                      onChange={(_, date) =>
                        changeHandler(_, date as any, 'active_to')
                      }
                    />
                  </FormItemGeneric>
                </Col>
              </Row>

              <Row>
                <Col className="col-flex--three">
                  <FormItemGeneric<TFormValues>
                    name="pricing_type"
                    label={translate('pricing_type')}
                  >
                    <Select
                      disabled={isDiasabled}
                      showSearch
                      getPopupContainer={e => e.parentNode}
                      className="full-width"
                    >
                      {Object.values(PricingType).map(type => (
                        <Option value={type} key={type}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </FormItemGeneric>
                  <FormItemGeneric<TFormValues>
                    name={'pricing_policy_rate'}
                    label={translate('rate')}
                  >
                    <InputNumber disabled={isDiasabled} type="number" />
                  </FormItemGeneric>
                  <FormItemGeneric<TFormValues>
                    name="chargeable"
                    valuePropName="checked"
                    className="ant-form-item__checkbox"
                  >
                    <Checkbox disabled={isDiasabled}>
                      {translate('chargeable')}
                    </Checkbox>
                  </FormItemGeneric>
                </Col>
              </Row>

              <Row>
                <Col className="col-flex--four">
                  <FormItemGeneric<TFormValues>
                    name={'pricing_policy_travel_fix_rate'}
                    label={translate('travel_fix_rate')}
                  >
                    <InputNumber disabled={isDiasabled} type="number" />
                  </FormItemGeneric>
                  <FormItemGeneric<TFormValues>
                    name={'pricing_policy_travel_hour_rate'}
                    label={translate('travel_hour_rate')}
                  >
                    <InputNumber disabled={isDiasabled} type="number" />
                  </FormItemGeneric>
                  <FormItemGeneric<TFormValues>
                    name={'pricing_policy_travel_pricing_unit'}
                    label={translate('travel_pricing_unit')}
                  >
                    <InputNumber disabled={isDiasabled} type="number" />
                  </FormItemGeneric>
                  <FormItemGeneric<TFormValues>
                    name={'pricing_policy_travel_distance_unit_rate'}
                    label={translate('travel_distance_unit_rate')}
                  >
                    <InputNumber disabled={isDiasabled} type="number" />
                  </FormItemGeneric>
                </Col>
              </Row>
              <>
                <Row>
                  <Col className="col-flex--one">
                    <Collapse ghost>
                      <Panel
                        collapsible={isDiasabled ? 'disabled' : 'header'}
                        key={1}
                        header={translate('weekday_pricing_policies')}
                      >
                        {form.getFieldValue('weekday_pricing_policies')?.map(
                          (
                            it: {
                              [x: number]: {
                                [x: number]: TWeekdayPricingPolicy
                              }
                            },
                            dayNumber: number
                          ) => {
                            return (
                              <Form.List
                                key={dayNumber}
                                name={['weekday_pricing_policies', dayNumber]}
                              >
                                {(fields, {add, remove}) => {
                                  return (
                                    <Collapse ghost>
                                      <Panel
                                        header={DAYS[dayNumber]}
                                        key={dayNumber}
                                      >
                                        <div>
                                          {fields.map((field, fieldIndex) => {
                                            return (
                                              <Row key={fieldIndex + dayNumber}>
                                                <Col className="col-flex--weekday">
                                                  <PricingPolicyItem
                                                    field={field}
                                                    form={form}
                                                    fieldIndex={fieldIndex}
                                                    dayNumber={dayNumber}
                                                  />
                                                  <div className="button-wrapper">
                                                    <AntButton
                                                      onClick={() =>
                                                        remove(field.name)
                                                      }
                                                      title={translate(
                                                        'policy'
                                                      )}
                                                      icon={<MinusOutlined />}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            )
                                          })}
                                          <Form.Item>
                                            <Row>
                                              <Col className="add-weekday-policy">
                                                <AntButton
                                                  onClick={() => {
                                                    add({
                                                      from: moment(
                                                        '00:00',
                                                        'HH:mm'
                                                      ),
                                                      price_multiplier: 1,
                                                      to: moment(
                                                        '23:59',
                                                        'HH:mm'
                                                      ),
                                                    })
                                                  }}
                                                  icon={<PlusOutlined />}
                                                  title={translate(
                                                    'add_weekday_pricing_policy'
                                                  )}
                                                />
                                              </Col>
                                            </Row>
                                          </Form.Item>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  )
                                }}
                              </Form.List>
                            )
                          }
                        )}
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </>

              <Row>
                <Col className="col-flex--one">
                  <Weekdays form={form} disabled={isDiasabled} />
                </Col>
              </Row>

              <Row>
                <Col className="col-flex--one">
                  <Form.List name={['holiday_pricing_policies']}>
                    {(fields, {add, remove}) => {
                      return (
                        <Collapse ghost>
                          <Panel
                            collapsible={isDiasabled ? 'disabled' : 'header'}
                            header={translate('holiday_pricing_policies')}
                            key={1}
                          >
                            <div>
                              {fields.map((field, fieldIndex) => {
                                return (
                                  <Row key={field.key}>
                                    <Col className="col-flex--weekday">
                                      {
                                        <PricingPolicyItem
                                          field={field}
                                          form={form}
                                          fieldIndex={fieldIndex}
                                        />
                                      }
                                      <div className="button-wrapper">
                                        <AntButton
                                          onClick={() => remove(field.name)}
                                          title={translate('policy')}
                                          icon={<MinusOutlined />}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              })}
                              <Form.Item>
                                <Row>
                                  <Col className="add-weekday-policy">
                                    <AntButton
                                      onClick={() =>
                                        add({
                                          from: moment('00:00', 'HH:mm'),
                                          price_multiplier: 1,
                                          to: moment('23:59', 'HH:mm'),
                                        })
                                      }
                                      icon={<PlusOutlined />}
                                      title={translate(
                                        'add_holiday_pricing_policy'
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </Form.Item>
                            </div>
                          </Panel>
                        </Collapse>
                      )
                    }}
                  </Form.List>
                </Col>
              </Row>

              <Row>
                <Col className="col-flex--two-nested">
                  <FormItemGeneric<TFormValues>
                    name={'sla_safe_time_interval'}
                    label={translate('safe_time_interval')}
                  >
                    <InputNumber
                      disabled={isDiasabled}
                      type="number"
                      onKeyDown={e => {
                        if (e.key === '.') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </FormItemGeneric>

                  <FormItemGeneric<TFormValues>
                    name={'sla_inactive_timeout'}
                    label={translate('inactive_timeout')}
                  >
                    <InputNumber
                      disabled={isDiasabled}
                      type="number"
                      onKeyDown={e => {
                        if (e.key === '.') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </FormItemGeneric>
                  <FormItemCustom>
                    <Select
                      disabled={isDiasabled}
                      getPopupContainer={e => e.parentNode}
                      className="full-width"
                      onChange={inactiveTimeoutUnitHandler}
                      value={inactiveTimeout}
                    >
                      {Object.values(ResponseTypes).map(name => (
                        <Option value={name} key={name}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormItemCustom>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>{translate('bank_holidays')}</h6>
                </Col>
              </Row>
              <Row>
                <Col className="col-flex--two col-flex--two-mobile">
                  <FormItemGeneric<TFormValues>
                    name={['sla_bank_holidays', 'support_from']}
                    label={translate('from')}
                    rules={[
                      {
                        validateTrigger: 'onChange',
                        validator: (rule, value) =>
                          validateBankHolidays(
                            rule,
                            getBankHolidayValidationData(value, [
                              'sla_bank_holidays',
                              'support_to',
                            ])
                          ),
                      },
                    ]}
                  >
                    <TimePicker
                      disabled={isDiasabled}
                      getPopupContainer={(e: any) =>
                        e.parentNode as HTMLElement
                      }
                      minuteStep={5}
                      onChange={(_: any, date: any) =>
                        changeBankHolidaysHandler(_, date, 'support_from')
                      }
                    />
                  </FormItemGeneric>

                  <FormItemGeneric<TFormValues>
                    name={['sla_bank_holidays', 'support_to']}
                    label={translate('to')}
                    rules={[
                      {
                        validateTrigger: 'onChange',
                        validator: (rule, value) =>
                          validateBankHolidays(
                            rule,
                            getBankHolidayValidationData(value, [
                              'sla_bank_holidays',
                              'support_from',
                            ])
                          ),
                      },
                    ]}
                  >
                    <TimePicker
                      disabled={isDiasabled}
                      getPopupContainer={(e: any) =>
                        e.parentNode as HTMLElement
                      }
                      minuteStep={5}
                      onChange={(_: any, date: any) =>
                        changeBankHolidaysHandler(_, date, 'support_to')
                      }
                    />
                  </FormItemGeneric>
                </Col>
              </Row>

              <Row>
                <Col className="col-flex--two">
                  <Form.List name="holidays">
                    {(fields, {add, remove}) => {
                      return (
                        <Collapse
                          ghost
                          className="col-flex__holidays-container"
                        >
                          <Panel header={translate('holidays')} key={1}>
                            {fields.map((field, index) => (
                              <Fragment key={field.key}>
                                <Row>
                                  <Col className="col-flex--holiday">
                                    <FormItemCustom
                                      label={`${index + 1}) ${translate(
                                        'holiday'
                                      )}`}
                                      name={field.name}
                                      rules={[
                                        {
                                          required: true,
                                          validateTrigger: 'onChange',
                                          validator: (
                                            rule: Rule,
                                            value: Moment
                                          ) =>
                                            validateHoliday(
                                              rule,
                                              validatorValue(value)
                                            ),
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        disabled={isDiasabled}
                                        getPopupContainer={e =>
                                          e.parentNode as HTMLElement
                                        }
                                        format={dayNumberFormat}
                                        placeholder={translate('empty')}
                                        onChange={(_, date) =>
                                          changeHolidayHandler(
                                            _ as Moment,
                                            date as any,
                                            index
                                          )
                                        }
                                      />
                                    </FormItemCustom>
                                    <div className="button-wrapper">
                                      <AntButton
                                        disabled={isDiasabled}
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                        title={translate('holiday')}
                                        icon={<MinusOutlined />}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <Dash />
                                  </Col>
                                </Row>
                              </Fragment>
                            ))}
                            <Form.Item>
                              <Row>
                                <Col className="add-weekday-policy">
                                  <AntButton
                                    disabled={isDiasabled}
                                    onClick={() => {
                                      add()
                                    }}
                                    icon={<PlusOutlined />}
                                    title={translate('add_holiday')}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                          </Panel>
                        </Collapse>
                      )
                    }}
                  </Form.List>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormItemGeneric<TFormValues>
                    name={'resolution_time_eval'}
                    label={translate('resolution_time_eval')}
                  >
                    <Select
                      disabled={isDiasabled}
                      getPopupContainer={e => e.parentNode}
                      className="full-width"
                    >
                      {Object.values(ResolutionTimeEval).map(name => (
                        <Option value={name} key={name}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormItemGeneric>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <Button
          title={translate('submit')}
          type="primary"
          disabled={btnDisabled || isDiasabled}
          size="large"
          htmlType="submit"
        />
        <Button
          disabled={isDiasabled}
          className="form__copy-button"
          title={translate('copy')}
          type="primary"
          size="large"
          htmlType="button"
          onClick={copyClickHandler}
        />
      </Form>
    </Paper>
  )
}

export default CustomerProductForm
