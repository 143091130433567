export const activeCampaignFieldsConfig = {
  company: 19,
  leadSource: 9,
  address: 22,
  description: 23,
  'short-description': 24,
  budget: 25,
  'first-name': 26,
  'last-name': 27,
  'phone-number': 28,
  'payment-period': 29,
  userSignupToBlits: 30,
}
