import {FilterFunc} from 'rc-select/lib/Select'

export const filterOption: FilterFunc<any> = (inputValue, option) => {
  const {children} = option?.props || {}

  if (children) {
    const optionValue =
      typeof children === 'string'
        ? children
        : children?.props?.children?.props?.children || ''

    return optionValue.toLowerCase().includes(inputValue.trim().toLowerCase())
  }

  return true
}
