import {SortingValues} from '@/typedef'

import {ItsmAssets, TQuickFilters} from '../../typedef'
import {ActionTypes} from '../action-types'
import {
  TDataTablePreset,
  TFilterListTable,
} from '../../components/itsm-list-table/typedf'

export const setItsmTableColumns = (
  asset: ItsmAssets,
  columns: TDataTablePreset
) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_COLUMNS,
    payload: {asset, columns},
  } as const)

export const setItsmTablePage = ({
  asset,
  bookmark,
}: {
  asset: ItsmAssets
  bookmark?: string | undefined
}) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_BOOKMARK,
    payload: {asset, bookmark},
  } as const)

export const setItsmTableBookmarks = ({
  asset,
  bookmark,
  bookmarks,
}: {
  asset: ItsmAssets
  bookmark: string | undefined
  bookmarks: Array<string>
}) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_BOOKMARKS,
    payload: {asset, bookmark, bookmarks},
  } as const)

export const setItsmTableSorters = (
  asset: ItsmAssets,
  sort?: Record<string, SortingValues> | null
) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_SORTERS,
    payload: {asset, sort},
  } as const)

export const setItsmTableFilter = <T extends Record<string, any>>(
  asset: ItsmAssets,
  filter: TFilterListTable<T>
) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_FILTER,
    payload: {asset, filter},
  } as const)

export const setItsmTableFilters = <T extends Record<string, any>>(
  asset: ItsmAssets,
  filters: TFilterListTable<T>
) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_FILTERS,
    payload: {asset, filters},
  } as const)

export const resetItsmTableFilter = (asset: ItsmAssets, key: string) =>
  ({
    type: ActionTypes.RESET_ITSM_LIST_TABLE_FILTER,
    payload: {asset, key},
  } as const)

export const resetItsmTableFilters = (asset: ItsmAssets) =>
  ({
    type: ActionTypes.RESET_ITSM_LIST_TABLE_FILTERS,
    payload: {asset},
  } as const)

export const resetItsmTableBookmarks = (asset: ItsmAssets) =>
  ({
    type: ActionTypes.RESET_ITSM_LIST_TABLE_BOOKMARKS,
    payload: {asset},
  } as const)

export const resetItsmAllTableFilters = () =>
  ({
    type: ActionTypes.RESET_ITSM_ALL_LIST_TABLE_FILTERS,
  } as const)

export const setItsmTableQuickFilters = (
  asset: ItsmAssets,
  quickFilter: TQuickFilters | undefined
) =>
  ({
    type: ActionTypes.SET_ITSM_LIST_TABLE_QUICK_FILTER,
    payload: {asset, quickFilter},
  } as const)
