export const normalizeFetchUserPayload = (data: Record<string, any>) => {
  return {
    email: data.email,
    userNameInChannel: data.full_name,
    orgRole: data.org_role,
    docs_read: data.docs_read,
    app_version: data.app_version,
    type: data.type,
  }
}
