import {TToastActionTypes} from '@/redux/toast-message/toast-typed'
import {ModalType} from '@/components/modal/typedef'
import {getAlertType} from '@/components/error/utils'

import * as ActionTypes from '../actionTypes'

type TUiState = {
  message: Record<string, any> | null
  errorMessages: Array<Record<string, any>>
}

const initialState: TUiState = {
  message: null,
  errorMessages: [],
}

export default function uiReducer(
  state = initialState,
  action: TToastActionTypes
): TUiState {
  switch (action.type) {
    case ActionTypes.SET_TOAST_MESSAGE: {
      const {message, type} = action.payload

      if (type && type !== ModalType.ERROR) {
        return {
          ...state,
          message,
        }
      }
      const isErrorMessage = getAlertType(message) === ModalType.ERROR
      return {
        ...state,
        ...(isErrorMessage
          ? {errorMessages: [message, ...state.errorMessages]}
          : {message}),
      }
    }

    case ActionTypes.CLEAR_TOAST_MESSAGE: {
      return {...state, message: null}
    }

    case ActionTypes.REMOVE_ERROR_MESSAGE: {
      const errorMessages = [...state.errorMessages]
      errorMessages.splice(action.payload, 1)

      return {
        ...state,
        errorMessages,
      }
    }

    case ActionTypes.CLEAR_ERROR_MESSAGES: {
      return {...state, errorMessages: []}
    }

    default: {
      return state
    }
  }
}
