import React from 'react'

import {useParams} from 'react-router-dom'

import {Paper, PaperVariant} from '@/components/paper/paper'

import SupplierProductsForm from './supplier-product-form'

const SupplierProductNew = () => {
  const {id} = useParams<{id: string}>()

  return (
    <Paper variant={PaperVariant.form}>
      <SupplierProductsForm id={id} />
    </Paper>
  )
}

export default SupplierProductNew
