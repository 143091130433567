import {
  AccountDefaultError,
  AccountDefaultModal,
} from '@/sites/account-default/typedef'
import {translate} from '@/services/i18n'
import {findChannelNameInExistChannelError} from '@/utils/find-channel-name-in-exist-channel-error'

export const normalizeError = (error: unknown, orgName = '') => {
  if (
    error instanceof Error &&
    error.message === AccountDefaultError.NO_SPACE
  ) {
    return {
      error,
      errorType: AccountDefaultModal.NO_CHANNEL,
      errorTitle: translate('missing_channel'),
      errorDescription: `${translate(
        'please_create_channel_in_the'
      )} "${orgName}" ${translate('_organization')}`,
      loadingText: translate('please_wait_we_are_creating_your_channel'),
    }
  }

  const channelName = findChannelNameInExistChannelError(error)
  if (channelName) {
    return {
      error,
      errorType: AccountDefaultModal.CHANNEL_EXIST,
      errorTitle: translate('channel_already_exist'),
      errorDescription: `"${channelName}" ${translate('channel_exist_info')}`,
      loadingText: translate('please_wait_we_are_creating_your_channel'),
    }
  }

  return {
    error,
    errorType: AccountDefaultModal.GENERAL_ERROR,
    errorTitle: translate('oops_something_went_wrong'),
    errorDescription: translate('oops_something_went_wrong_info'),
    loadingText: translate('please_wait_we_are_sending_your_contact_form'),
  }
}
