import {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {RefreshType} from '@/typedef'
import {getOrgs} from '@/api/accountRequests'

import {getAccount, getGrantLevel, getSubs} from '../api/accountRequests'

export const useGetAccount = id => {
  const dispatch = useDispatch()

  const [account, setAccount] = useState(undefined)

  useEffect(() => {
    const loadData = async () => {
      try {
        const {body: accountRes} = await getAccount(id)
        setAccount(accountRes)
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    }

    if (!account) {
      loadData()
    }
  }, [account, id, dispatch])

  return [account]
}

export const useFetchOrgs = id => {
  const dispatch = useDispatch()

  const [orgs, setOrgs] = useState(null)
  const [loadingOrgs, setLoadingOrgs] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      try {
        const {body: accountOrgsRes} = await getOrgs(id)
        setOrgs(accountOrgsRes)
        setLoadingOrgs(false)
      } catch (err) {
        setLoadingOrgs(false)
        dispatch(setToastMessage({message: err}))
      }
    }

    if (!orgs) {
      setLoadingOrgs(true)
      loadData()
    }
  }, [orgs, id, dispatch])

  return [orgs, loadingOrgs]
}

export const useFetchSubs = (id, hasUserAccounts) => {
  const dispatch = useDispatch()

  const [subs, setSubs] = useState(null)
  const [loadingSubs, setLoadingSubs] = useState(false)
  const [grantLevel, setGrantLevel] = useState([])

  const {refresh} = useSelector(state => state.general)

  useEffect(() => {
    const loadData = async () => {
      try {
        const {body: subsRes} = await getSubs(id)
        setSubs(subsRes)

        if (!hasUserAccounts) {
          const {
            body: {canGrantLevels: grantLevelsRes},
          } = await getGrantLevel(id)

          setGrantLevel(grantLevelsRes)
        }
        setLoadingSubs(false)
      } catch (err) {
        setLoadingSubs(false)
        dispatch(setToastMessage({message: err}))
      }
    }

    if (!subs || (subs && refresh.type === RefreshType.Subs)) {
      setLoadingSubs(true)
      loadData()
    }
  }, [subs, refresh, id, hasUserAccounts, dispatch])

  return [subs, grantLevel, loadingSubs]
}
