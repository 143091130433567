import React, {ReactNode} from 'react'

import * as Sentry from '@sentry/react'

import {TChildren} from '@/typedef'

export const ErrorBondaryContext = React.createContext<{
  resetError: () => void
} | null>(null)

type TProps = {
  children: ReactNode
  fallback: ReactNode
} & TChildren

const SentryErrorBoundary: React.FC<TProps> = ({children, fallback}) => {
  return (
    /*
      // @ts-ignore error from Sentry types, docs clearly show that `children` are passed to `ErrorBoundary` */
    <Sentry.ErrorBoundary
      fallback={({resetError}) => (
        <ErrorBondaryContext.Provider value={{resetError}}>
          {fallback}
        </ErrorBondaryContext.Provider>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default SentryErrorBoundary
