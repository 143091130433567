import React, {useEffect, useState} from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table/itsm-list-table-nobookmark'
import {ItsmAssets, TInvitation} from '@/modules/ITSM/typedef'
import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {apiService} from '@/modules/ITSM/api/api-service'
import {useGetMultipleData} from '@/hooks/useGetMultipleData'

import {InvitationsRowValue} from './invitations-row-value'
import InvitationDrawer from './invitation-drawer/invitation-drawer'

const Invitations = () => {
  const asset = ItsmAssets.Invitations

  const [drawerDetail, setDrawerDetail] = useState<TInvitation | undefined>()

  const {entityData, fetchStatus, getData} = useGetMultipleData<TInvitation>(
    apiService[asset]
  )

  const handleGetRowValue = (props: TColumnRender<TInvitation>) => (
    <InvitationsRowValue props={props} />
  )

  const handleRowClick = (record: TInvitation) => {
    if (record?.state === 3) setDrawerDetail(record)
  }

  const handleOnclose = () => setDrawerDetail(undefined)

  useEffect(() => {
    getData({})
  }, [])

  return (
    <>
      {drawerDetail && (
        <InvitationDrawer
          record={drawerDetail}
          onClose={handleOnclose}
          getData={() => getData({})}
        />
      )}

      <ItsmListTable<TInvitation>
        asset={ItsmAssets.Invitations}
        getRowValue={handleGetRowValue}
        entityData={entityData || []}
        fetchStatus={fetchStatus}
        handleRowClick={handleRowClick}
      />
    </>
  )
}

export default Invitations
