import React, {useEffect, useState} from 'react'

import {Input} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import debounce from 'lodash/debounce'

import {translate} from '@/services/i18n'
import {selectValueOnFocus} from '@/utils/select-value-on-focus'
import {usePrevious} from '@/hooks/usePrevious'

import './quick-search-table-header.scss'

type TProps = {
  changeFilterValue: (value: string) => void
  value?: string
}

const QuickSearchTableHeader: React.FC<TProps> = ({
  changeFilterValue,
  value,
}) => {
  const [ticketNumber, setTicketNumber] = useState<string>(value || '')

  const prevValue = usePrevious(value)

  useEffect(() => {
    if (prevValue && value === '') setTicketNumber('')
  }, [prevValue, value])

  const handleChange = (ticket: string) => {
    setTicketNumber(ticket)
    debouncedSearch(ticket)
  }

  const debouncedSearch = React.useMemo(
    () =>
      debounce(val => {
        changeFilterValue(val)
      }, 800),
    [changeFilterValue]
  )

  return (
    <Input
      allowClear
      onFocus={selectValueOnFocus}
      placeholder={translate('ticket_number')}
      onChange={e => handleChange(e.target.value)}
      prefix={<SearchOutlined />}
      value={ticketNumber}
      className={'quick-search-table-header'}
    />
  )
}
export default QuickSearchTableHeader
