import {TDefaultQuickFilters} from '@/components/filter/typedef'

enum MainRequestsQuickFilters {
  MyRequests = 'my-requests',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Opened = 'opened',
  On_hold = 'on_hold',
  Resolved = 'resolved',
}

export type TRequestsQuickFilters =
  | TDefaultQuickFilters
  | MainRequestsQuickFilters

export const RequestsQuickFilters = {
  ...MainRequestsQuickFilters,
  ...TDefaultQuickFilters,
}
