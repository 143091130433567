import {
  TAssignmentGroup,
  TCustomerProduct,
  TLocation,
  TSupplierProduct,
  TTimeLog,
  TUser,
} from '../../typedef'
import {AssigneeStates} from '../incident-k-request/constants'
import {TIncident} from '../incident-k-request/typedf'

export enum IncidentTaskState {
  New = 0,
  In_progress = 1,
  Closed_complete = 2,
  Closed_incomplete = 3,
  Closed_skipped = 4,
}

export type TIncidentTask = {
  response_time_due: string
  created_at: string
  resolution_time_due: string
  resolution_time_start: string
  closed_at: string
  state_id: IncidentTaskState
  assignee_state: AssigneeStates
  uuid: string
  incident: TIncident
  timelogs: TTimeLog[]
  assigned_to: TUser
  caller: TUser
  item: string
  priority: number
  number: string
  location: TLocation
  short_description: string
  description: string
  supplier_product: TSupplierProduct
  customer_product: TCustomerProduct
  assignment_group: TAssignmentGroup
  resolution_notes: string
  response_time_start: string
}

export type TIncidentTaskReq = {
  assignee_state: AssigneeStates
  uuid: string
  incident: string | undefined
  assigned_to: TUser
  caller: TUser
  item: string
  priority: number
  number: string
  location: TLocation
  short_description: string
  description: string | undefined
  supplier_product: TSupplierProduct
  customer_product: TCustomerProduct
  assignment_group: TAssignmentGroup
}

export enum IncTaskTabs {
  OverviewTab = 'overview',
  AttachmentsTab = 'attachments',
  TimeLogsTab = 'time_logs',
  InProgressTab = 'in_progress',
  WorkNotesTab = 'work_notes',
  ExpensesTab = 'expenses',
  ResolutionTab = 'resolution',
}

export type TIncidentState = {
  New: string
  In_progress: string
  Closed_complete: string
  Closed_incomplete: string
  Closed_skipped: string
  Resolution: string
}
