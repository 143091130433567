import React, {useContext, useState} from 'react'

import {EllipsisOutlined} from '@ant-design/icons'
import {useSelector} from 'react-redux'
import {Drawer} from 'antd'
import classNames from 'classnames'

import {useCustomAuth} from '@/hooks/use-custom-auth'
import useIsMobile from '@/hooks/use-is-mobile'
import {selectAppVersion, selectModuleName} from '@/redux/user/userSelector'
import {LanguageSwitcher} from '@/components/language-switcher/language-switcher'
import {Button} from '@/components/button'
import {selectWindowWidth} from '@/redux/reducers/generalSelector'
import {SCREEN_SIZES} from '@/constants'
import {AppVersion, Modules} from '@/typedef'
import TopHeaderBellNotifications from '@/core/app-shell/top-header/bell-notification/top-header-bell-notifications'
import UserMenu from './user-menu/user-menu'

import './top-header.scss'
import {AccountBalance} from './account-balance/account-balance'
import {AbilityContext} from '@/services/abilities/ability-context'
import {selectHasErrorMessages} from '@/redux/toast-message/toast-selector'

type TProps = {
  extraContent?: React.ReactNode
  handleClickUserMenu?: (redirectTo: string) => void
  isShowBalance?: boolean
}

const TopHeader: React.FC<TProps> = ({
  handleClickUserMenu,
  extraContent,
  isShowBalance = true,
}) => {
  const {signOutRedirect} = useCustomAuth()

  const [mobileDrawerVisible, setMobileDrawerVisible] = useState<boolean>(false)
  const moduleName = useSelector(selectModuleName)
  const windowWidth = useSelector(selectWindowWidth)
  const hasError = useSelector(selectHasErrorMessages)
  const appVersion = useSelector(selectAppVersion) as AppVersion | undefined
  const mediumScreenSize = windowWidth < SCREEN_SIZES.FULL
  const isPortal = appVersion === AppVersion.PORTAL

  const isMobile = useIsMobile()
  const ability = useContext(AbilityContext)

  const langSwitcherClasses = classNames('top-header__lang-switcher', {
    'top-header__lang-switcher--home-page': !moduleName,
  })

  const toggleMobileDrawer = () => {
    setMobileDrawerVisible(!mobileDrawerVisible)
  }
  const desktopActionMenu = (
    <div className="top-header__menu">
      {!isPortal && (
        <div className={langSwitcherClasses}>
          <LanguageSwitcher />
        </div>
      )}
      {extraContent && (
        <>
          <div className="top-header__extra-content">{extraContent}</div>
        </>
      )}
    </div>
  )

  const mobileActionMenu = (
    <div className="top-header__mobile-menu">
      <Button icon={<EllipsisOutlined />} onClick={toggleMobileDrawer} />
      <Drawer
        open={mobileDrawerVisible}
        className="top-header__mobile-menu-drawer"
        closable={true}
        onClose={toggleMobileDrawer}
      >
        <div className="top-header__inner-drawer">
          {!isPortal && (
            <div className={langSwitcherClasses}>
              <LanguageSwitcher />
            </div>
          )}
          {extraContent}
        </div>
      </Drawer>
    </div>
  )

  return (
    <div className="top-header">
      {isShowBalance &&
        !isMobile &&
        ability.can('view', Modules.Subscriptions) && <AccountBalance />}
      {hasError && <TopHeaderBellNotifications />}
      {isMobile || mediumScreenSize ? mobileActionMenu : desktopActionMenu}
      <>
        {handleClickUserMenu && (
          <UserMenu
            handleClick={handleClickUserMenu}
            handleLogout={signOutRedirect}
          />
        )}
      </>
    </div>
  )
}

export default TopHeader
