import {useState} from 'react'

import {Coords} from 'google-map-react'

import {fetchGoogleMapAddressByCoordinates} from '@/api/google-map-requests'
import {parseGeocodingResponse} from '@/utils/parse-geocoding-response'
import {RequestStatus, TCountry} from '@/typedef'

import {useFetchHook} from './useFetch'

type TMapHooks = () => {
  setSearchPosition: ({lat, lng}: Coords) => Promise<void>
  searchData?: Array<{formatted_address: string; address_components?: string}>
  country?: TCountry
  state?: string
  city?: string
  street?: string
  zipCode?: string
  isLoading: boolean
}

export const useGeocodingByPosition: TMapHooks = () => {
  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const [searchData, setSearchData] = useState<
    Array<{formatted_address: string}>
  >([])
  const [country, setCountry] = useState<TCountry>()
  const [state, setState] = useState<string>()
  const [city, setCity] = useState<string>()
  const [street, setStreet] = useState<string>()
  const [zipCode, setZipCode] = useState<string>()

  const setSearchPosition = async ({lat, lng}: Coords) => {
    try {
      handleRequested()
      const data = await fetchGoogleMapAddressByCoordinates({lat, lng})
      const parsedData = parseGeocodingResponse(data)

      setCountry(parsedData.countries[0])
      setState(parsedData.states[0])
      setCity(parsedData.cities[0])
      setStreet(parsedData.streets[0])
      setZipCode(parsedData.zipCodes[0])

      setSearchData(data?.body?.results)
      handleSuccess()
    } catch (err) {
      handleFail(err)
    }
  }

  return {
    setSearchPosition,
    searchData,
    country,
    state,
    city,
    street,
    zipCode,
    isLoading: status === RequestStatus.REQUESTED,
  } as const
}
