import {TSaasSpace} from '../../typedf'
import {TActionTypes} from './actionTypes'

export const setSAASUserInitialState = () =>
  ({
    type: TActionTypes.RESET_SAAS_USER_STATE,
  } as const)

export const fetchSaasIdentityMeSuccess = (saasUser: {
  saasUserRole?: string
  saasUserNameInChannel?: string
  saasNamespaces?: Array<TSaasSpace>
  saasUserId: string
}) => {
  return {
    type: TActionTypes.FETCH_SAAS_IDENTITY_ME_SUCCESS,
    payload: saasUser,
  } as const
}
export const fetchSaasIdentityMeFailed = (payload: unknown) =>
  ({
    type: TActionTypes.FETCH_SAAS_USER_FAILED,
    payload,
  } as const)

export const selectSAASNamespace = (namespace: TSaasSpace) => {
  return {
    type: TActionTypes.SELECT_SAAS_NAMESPACE,
    payload: namespace,
  } as const
}

export const addSaasNamespace = (namespace: TSaasSpace) => {
  return {
    type: TActionTypes.ADD_SAAS_NAMESPACE,
    payload: namespace,
  } as const
}
