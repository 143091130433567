import React from 'react'

import classNames from 'classnames'

import './paper.scss'

export enum PaperVariant {
  default = 'default',
  tab = 'tab',
  form = 'form',
}

type TPaper = {
  variant?: PaperVariant
  children: React.ReactNode
  className?: string
  onClick?: () => void
  e2eTest?: string
  dataTestId?: string
}

export const Paper: React.FC<TPaper> = ({
  variant = PaperVariant.default,
  children,
  className = '',
  onClick,
  e2eTest,
  dataTestId,
}) => {
  return (
    <div
      className={classNames('paper', {
        [`paper--${variant}`]: variant !== PaperVariant.default,
        [className]: className,
        'paper--clickable': onClick,
      })}
      {...(onClick ? {onClick} : {})}
      {...(e2eTest ? {e2eTest} : {})}
      {...(dataTestId ? {'data-testid': dataTestId} : {})}
    >
      {children}
    </div>
  )
}
