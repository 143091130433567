import {call, put, StrictEffect} from 'redux-saga/effects'

import {
  postIdentityChannelInviteReq,
  refreshInviteOrgTokenReq,
  refreshOrgTokenFailed,
  refreshOrgTokenSuccess,
} from '@/redux/channel-invitation/channel-invitation-actions'
import {setBETokenSuccess} from '@/redux/user/userActions'
import {setOrganization} from '@/redux/entries/entries-actions'
import {fetchOrgBySubApp} from '@/modules/ITSM/api/itsmOrgRequests'
import {getOrganizationName} from '@/components/org-select-dropdown/utils/utils'
import {getBEndToken} from '@/api/token-request'

export function* refreshTokenInvitationSaga({
  payload: grant_org,
}: ReturnType<typeof refreshInviteOrgTokenReq>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const {
      body: {access_token, expires_in},
    } = yield call(getBEndToken, {
      id: grant_org,
    })
    if (access_token) {
      yield put(refreshOrgTokenSuccess())
      yield put(postIdentityChannelInviteReq(access_token))
      yield put(setBETokenSuccess({token: access_token, tokenExp: expires_in}))

      const {
        body: {orgs},
      } = yield call(fetchOrgBySubApp)
      yield put(
        setOrganization({
          orgID: grant_org,
          orgName: orgs ? getOrganizationName(orgs, grant_org) || '' : '',
        })
      )
    }
  } catch (err) {
    yield put(refreshOrgTokenFailed(err))
  }
}
