import {
  SET_ERROR,
  SET_INITIAL_STATE,
  SET_LOCATION,
  SET_MY_ACCESS,
  SET_PRIORITY_VALUES,
  SET_REDIRECT,
  SET_REFRESH,
  SET_WIDGETS,
  SET_WINDOW_WIDTH,
} from '../actionTypes'

const initialState = {
  pathname: [],
  err: false,
  priority_values: [],
  unsaved_changes: {},
  refresh: {},
  redirect: false,
}

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT: {
      return {
        ...state,
        redirect: action.payload.redirect,
        values: action.payload.values,
      }
    }

    case SET_ERROR: {
      return {
        ...state,
        err: action.payload.err,
        onCancel: action.payload.onCancel,
      }
    }

    case SET_REFRESH: {
      return {
        ...state,
        refresh: action.payload,
      }
    }

    case SET_INITIAL_STATE: {
      return {
        ...state,
        ...initialState,
        pathname: state.pathname,
      }
    }

    case SET_LOCATION: {
      const pathnameArray = [...state.pathname, action.payload.pathname]
      if (pathnameArray.length > 2) {
        pathnameArray.splice(0, 1)
      }

      return {
        ...state,
        pathname: pathnameArray,
      }
    }

    case SET_MY_ACCESS:
    case SET_PRIORITY_VALUES:
    case SET_WIDGETS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case SET_WINDOW_WIDTH: {
      return {
        ...state,
        windowWidth: action.payload,
      }
    }

    default:
      return state
  }
}

export default generalReducer
