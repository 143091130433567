import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {fetchLocations} from '@/modules/ITSM/api/locationRequests'
import {fetchUsers} from '@/modules/ITSM/api/userRequests'
import {ItsmAssets} from '@/modules/ITSM/typedef'

const getLocationsSelector = (
  selectorIds: Record<string, any>,
  key: string
) => ({
  location: {
    $or: selectorIds[key].length ? selectorIds[key] : [''],
  },
})

const getIdentitiesSelector = (
  selectorIds: Record<string, any>,
  key: string
) => ({
  $or: selectorIds[key].length
    ? selectorIds[key].map((el: string) => ({
        users: {$elemMatch: {$eq: el}},
      }))
    : [{users: {$elemMatch: {$eq: ''}}}],
})

export const searchFetchColumnKeys: {
  [key in ItsmAssets]?: Record<
    string,
    {
      asyncF: ({
        bookmark,
        selector,
      }: {
        bookmark?: string | undefined
        selector: Record<string, any>
      }) => Promise<ISuperAgentResMultiple<Record<string, any>>> | undefined
      propertyName: string
      prepareSelector: (
        selectorIds: Record<string, any>,
        key: string
      ) => {[key: string]: any}
    }
  >
} = {
  [ItsmAssets.IncidentsUP]: {
    country: {
      asyncF: fetchLocations,
      propertyName: 'location',
      prepareSelector: getLocationsSelector,
    },
  },
  [ItsmAssets.RequestsUP]: {
    country: {
      asyncF: fetchLocations,
      propertyName: 'location',
      prepareSelector: getLocationsSelector,
    },
  },
  [ItsmAssets.Incidents]: {
    country: {
      asyncF: fetchLocations,
      propertyName: 'location',
      prepareSelector: getLocationsSelector,
    },
  },
  [ItsmAssets.Requests]: {
    country: {
      asyncF: fetchLocations,
      propertyName: 'location',
      prepareSelector: getLocationsSelector,
    },
  },
  [ItsmAssets.Identities]: {
    full_name: {
      asyncF: fetchUsers,
      propertyName: 'users',
      prepareSelector: getIdentitiesSelector,
    },
    email: {
      asyncF: fetchUsers,
      propertyName: 'users',
      prepareSelector: getIdentitiesSelector,
    },
  },
}
