export const billingReportColumns = [
  {
    title: 'interval_start',
    dataIndex: 'interval_start',
    index: 0,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'interval_end',
    dataIndex: 'interval_end',
    index: 1,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'month',
    dataIndex: 'month',
    index: 2,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'revenue',
    dataIndex: 'customer_sums',
    index: 3,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'cost',
    dataIndex: 'supplier_sums',
    index: 4,
    selected: true,
    advancedRender: true,
  },

  {
    title: 'expenses',
    dataIndex: 'expenses',
    index: 5,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'travel_time',
    dataIndex: 'travel_time',
    index: 6,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'working_time',
    dataIndex: 'working_time',
    index: 7,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'ref_number',
    dataIndex: 'ref_no',
    index: 8,
    selected: true,
    advancedRender: true,
  },
]

export enum BillingReportTabs {
  OverviewTab = 'overview',
  BillingItemsTab = 'billing-items',
  StatisticsTab = 'statistics',
  AnomaliesTab = 'anomalies',
  InvoicesTab = 'invoices',
  CommentsTab = 'comments',
}
