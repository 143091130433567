import React from 'react'

import {Editor as WysiwygEditor, EditorProps} from 'react-draft-wysiwyg'
import classNames from 'classnames'

import './editor.scss'

export const Editor: React.FC<EditorProps> = props => {
  const {wrapperClassName, ...editorProps} = props
  const editorClass = classNames('wysiwyg-editor', wrapperClassName)
  return <WysiwygEditor wrapperClassName={editorClass} {...editorProps} />
}
