import {useCallback} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {TResSingle} from '@/modules/SAAS/api/response-typedf'
import * as Sentry from '@sentry/react'

export function usePostDataGeneric<T extends Record<string, any>>(
  isSilentModeError = false
) {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const run = useCallback(
    async (asyncF: (passedArgs?: T) => Promise<TResSingle>, passedArgs?: T) => {
      handleRequested()
      try {
        const result = await asyncF(passedArgs)

        handleSuccess()

        return result
      } catch (err) {
        if (isSilentModeError) {
          Sentry.captureException(err)
          return
        }
        if (err instanceof Error) {
          const errorMessage = JSON.parse(err.message).message
          if (errorMessage === 'cannot add kompitech_saas to kompitech_saas') {
            handleFail({status: 403, message: errorMessage})
          }
        } else handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess, isSilentModeError]
  )

  return {
    run,
    status,
  }
}
