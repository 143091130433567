import React, {useContext} from 'react'

import {Col, Form as AntdForm, Input, Row} from 'antd'
import {useLocation} from 'react-router-dom'

import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {
  TCCreateAccount,
  TCreateChannel,
  TCreatorAccAndOrg,
} from '@/components/accountForm/types'
import {Form} from '@/components/form/form/form'

import {translate} from '@/services/i18n'
import {AccountDefaultContext} from '@/sites/account-default/account-default-context/account-default-context'

import './create-account-step.scss'

import {
  TAccountDefaultQuery,
  TPrefillCreateAccountData,
} from '@/sites/account-default/typedef'

import {rules} from '@/components/form/constants/rules'
import queryString from 'query-string'
import {createUuidChannelName} from '@/utils/create-uuid-channel-name'
import {useCreateAccount} from '@/sites/account-default/hooks/use-create-account'
import {twoColumns} from '@/utils/table/constants/grid-columns'

export const CreateAccountStep = () => {
  const [form] = AntdForm.useForm()
  const {search} = useLocation()
  const queryParams = queryString.parse(
    decodeURI(search)
  ) as TPrefillCreateAccountData & Partial<TAccountDefaultQuery>

  const {state} = useContext(AccountDefaultContext)
  const {createAccount} = useCreateAccount()

  const setAccountChannelField = (companyName: string) => {
    const {channel_name, account} = form.getFieldsValue()
    if (!channel_name && companyName) {
      form.setFieldsValue({
        channel_name: `${companyName}-channel`,
      })
    }
    if (!account && companyName) {
      form.setFieldsValue({
        account: `${companyName}-account`,
      })
    }
  }

  return (
    <div className="create-account-step">
      <Form<TCreatorAccAndOrg>
        className="create-account-step__form"
        form={form}
        onSubmit={createAccount}
        submitButtonTitle={'save'}
        isLoading={state.isLoading}
        saveButtonDisabled={false}
        initialValues={{
          firstname: queryParams['first-name'],
          surname: queryParams['last-name'],
          email: queryParams['email'],
          phone: queryParams['phone-number'],
          account: queryParams.company && `${queryParams.company}-account`,
          companyName: queryParams.company,
          channel_name:
            queryParams.company && createUuidChannelName(queryParams.company),
        }}
      >
        <Row gutter={24}>
          <Col {...twoColumns}>
            <FormItemGeneric<TCCreateAccount>
              name="companyName"
              label={translate('company')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onBlur={e => setAccountChannelField(e.target.value)} />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TCCreateAccount>
              name="email"
              label={translate('email')}
              validateTrigger={['onBlur']}
              rules={[{required: true}, rules.email]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric
              name="phone"
              label={translate('phone')}
              validateTrigger={['onBlur']}
              rules={[{required: true}, rules.phone]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns} />
          <Col {...twoColumns}>
            <FormItemGeneric<TCCreateAccount>
              name="firstname"
              label={translate('first_name')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TCCreateAccount>
              name="surname"
              label={translate('surname')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TCCreateAccount>
              name="account"
              label={translate('account')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TCreateChannel>
              name="channel_name"
              label={translate('channel_name')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
