import {TAppState} from '../store'

export function selectAccessToken(state: TAppState) {
  return state.itsmUser.accessToken
}

export function selectBETokenStatus(state: TAppState) {
  return state.itsmUser.backEndTokenFetchStatus
}

export function selectBEndToken(state: TAppState) {
  return state.itsmUser.backEndToken
}
