import queryString from 'query-string'
import {v4 as uuid} from 'uuid'
import {omit} from 'lodash'

import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import globalRoutes from '@/global-routes'

export enum RedirectPathName {
  REQUEST_NEW = 'request-new',
}

export const generateRedirectPath = (query?: string) => {
  const queryParams = query ? queryString.parse(query) : {}

  switch (queryParams?.redirectTo) {
    case RedirectPathName.REQUEST_NEW:
      return {
        pathname: itsmRoutes.serviceManagementRequestsNew.path(uuid()),
        search: queryString.stringify(omit(queryParams, ['redirectTo'])),
      }
    default:
      return {pathname: globalRoutes.general.homePage.path()}
  }
}
