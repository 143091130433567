import React, { useCallback, useEffect, useReducer } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import IncidentHeader from '@/modules/ITSM/components/incident/incident-header/incident-header';
import { useSafeDispatch } from '@/hooks/useSafeDispatch';
import { checkIsLoading } from '@/utils/check-is-loading';
import { Entities } from '@/typedef';
import { IncidentDetailMobile } from '@/modules/ITSM/components/incident-k-request/incident-detail-mobile/incident-detail-mobile';
import styles from '@/styles/_variables.module.scss';
import { selectWindowWidth } from '@/redux/reducers/generalSelector';
import TicketWrapperSteps from '@/modules/ITSM/components/incident/ticket-wrapper-steps/ticket-wrapper-steps';
import { selectTimeSession } from '@/modules/ITSM/store/time-session/selectors';
import { setTicketType } from '@/modules/ITSM/store/time-session/actions';
import { ItsmAssets } from '@/modules/ITSM/typedef';
import store, { TAppState } from '@/redux/store';
import { selectItsmTableQuickFilter } from '@/modules/ITSM/store/list-table/selectors';
import { IncidentsQuickFilters } from '@/modules/ITSM/sites/Incident/constants';
import { usePrevious } from '@/hooks/usePrevious';
import useIsMobile from '@/hooks/use-is-mobile';
import { Paper, PaperVariant } from '@/components/paper/paper';

import {
  incidentReducer,
  initialState,
  setCurrentTab,
  setProgress,
  setSlaValues,
} from '../../incident/incident-reducer';
import { IncidentProgressBar } from '../../incident/incident-progress-bar/incident-progress-bar';
import { createStateContext } from '../../incident/incident-context';
import IncidentKRequestTabs from '../incident-k-request-tabs/incident-k-request-tabs';
import {
  generateIncidentsListPathITSM,
  generateIncidentStatePathITSM,
} from '../utils';
import { useGetIncidentRequest } from '../hooks/useGetIncidentRequest';
import { usePatchIncident } from '../hooks/usePatchIncident';
import { generateSteps } from '../utils/generate-steps';
import { getSLAtime } from '../utils/get-sla-time';
import { refreshProgressBar } from '../utils/refresh-progress-bar';
import { getActiveTab } from '../utils/get-active-tab';
import { TIncident, TIncidentReq } from '../typedf';
import { setTab } from '../utils/set-tab';
import useRemovePagination from '../hooks/use-remove-pagination';
import { setRedirectToList } from '@/redux/ui/uiActions';

type TProps = {
  id: string;
  entity: Entities;
  asset: ItsmAssets;
};

export const IncidentContext = createStateContext<TIncident>();

const IncidentKRequestDetail = ({ id, entity, asset }: TProps) => {
  //for e2e tests
  if (localStorage.getItem('cypress')) window.store = store;

  const history = useHistory();
  const rdxDispatch = useDispatch();
  useRemovePagination();

  const [incState, unsafeDispatch] = useReducer(incidentReducer, initialState);
  const dispatch = useSafeDispatch(unsafeDispatch);
  const mainStoreDispatch = useDispatch();
  const { state: initTab } = useParams<{ state: string }>();
  const { search } = useLocation();
  const { state_id: timeSessionState, entities: timeSessionEntities } = useSelector(selectTimeSession) || {};

  const prevTimeSessionState = usePrevious(timeSessionState);
  const prevTimeSessionEntities = usePrevious(timeSessionEntities);

  const { getData, entityData, fetchStatus } = useGetIncidentRequest(entity, id);
  const isEntityDataExist = Boolean(entityData);
  const { updateEntity: updateIncident, updateEntityStatus } = usePatchIncident(entity);

  const windowWidth = useSelector(selectWindowWidth);

  const isMobile = useIsMobile();

  const showMobileView = isMobile || windowWidth <= styles.fullScreen;
  const { slaValues, currentTab } = incState || {};
  const isIncidentDataLoading = checkIsLoading(fetchStatus);
  const queryParam = window.location.search;

  const setActiveTab = useCallback(
    (result: TIncident, init?: boolean) => {
      const { state_id, assignee_state } = result;
      const activeTab = getActiveTab({ state_id, assignee_state });

      const noQueryParamsInitTab = initTab?.replace(queryParam, '');
      activeTab &&
        dispatch(setCurrentTab((init && noQueryParamsInitTab) || activeTab));
    },
    [dispatch, initTab, queryParam]
  );

  const updateData = useCallback(
    async (setTab?: boolean) => {
      if (isEntityDataExist) {
        const result = await getData();

        if (result !== undefined && setTab) {
          setActiveTab(result);
        }
      }
    },
    [isEntityDataExist, getData, setActiveTab]
  );

  useEffect(() => {
    if (
      timeSessionState !== prevTimeSessionState ||
      JSON.stringify(timeSessionEntities) !==
        JSON.stringify(prevTimeSessionEntities)
    ) {
      updateData(true);
    }
  }, [
    prevTimeSessionEntities,
    prevTimeSessionState,
    timeSessionEntities,
    timeSessionState,
    updateData,
  ]);

  useEffect(() => {
    mainStoreDispatch(setTicketType(entity));
  }, [entity, mainStoreDispatch]);

  useEffect(() => {
    if (!isIncidentDataLoading) {
      (async () => {
        const result = await getData();
        if (result) {
          dispatch(setSlaValues(getSLAtime(result)));

          return setActiveTab(result, true);
        }

        rdxDispatch(setRedirectToList(true));
      })();
    }
  }, [rdxDispatch, dispatch, getData, id, initTab]);

  const { elapsedTime } = slaValues || {};
  useEffect(() => {
    if (elapsedTime !== null && slaValues) {
      dispatch(setProgress(refreshProgressBar(slaValues)));
    }
  }, [dispatch, elapsedTime]);

  useEffect(() => {
    if (currentTab && !isIncidentDataLoading) {
      history.replace({
        pathname:
          generateIncidentStatePathITSM(entity, id, currentTab) +
          (search || ''),
      });
    }
  }, [currentTab, dispatch, entity, history, id]);

  useEffect(() => {
    const refetchData = async () => {
      const result = await getData(true);
      if (result) {
        const slaValues = getSLAtime(result);

        dispatch(setSlaValues(slaValues));
        dispatch(setProgress(refreshProgressBar(slaValues)));
      }
    };

    const interval = setInterval(() => refetchData(), 60000);

    return () => clearInterval(interval);
  }, [dispatch, getData, id]);

  const { callGoBack } = history.location.state || {};

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, asset)
  );

  const handleGoBack = () => {
    if (quickFilterActive)
      history.push(generateIncidentsListPathITSM(entity, quickFilterActive));
    // @ts-ignore typescript error
    else if (callGoBack) history.goBack();
    else
      history.replace(
        generateIncidentsListPathITSM(entity, IncidentsQuickFilters.Opened)
      );
  };

  return (
    <IncidentContext.Provider
      value={{
        incState: incState || initialState,
        entityData,
        dispatch,
        getData: updateData,
        updateEntity: (values: TIncidentReq) =>
          updateIncident(values, entityData?.uuid),
        entity,
        isLoading: checkIsLoading(updateEntityStatus),
      }}
    >
      <Paper className="mb-24">
        <IncidentHeader
          loading={isIncidentDataLoading}
          backButtonHandler={handleGoBack}
          numberId={entityData?.number}
          priority={entityData?.priority}
          priorityText={entityData?.priority_text}
          onHoldReason={entityData?.on_hold_reason}
        />
      </Paper>
      {showMobileView ? (
        <Paper className="mb-24" variant={PaperVariant.tab}>
          <IncidentDetailMobile loading={isIncidentDataLoading} />
        </Paper>
      ) : (
        <>
          <Paper className="mb-24">
            <TicketWrapperSteps
              steps={generateSteps(entityData)}
              setTab={setTab}
            />
            <IncidentProgressBar />
          </Paper>
          <Paper variant={PaperVariant.tab}>
            <IncidentKRequestTabs />
          </Paper>
        </>
      )}
    </IncidentContext.Provider>
  );
};

export default IncidentKRequestDetail;
