import queryString from 'query-string'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {generateRedirectPath} from '@/utils/generate-redirect-path'
import {cleanQueryParams} from '@/sites/account-default/utils/clean-query-params'
import {getProfile} from '@/redux/user/userActions'

export const useAccountDefaultRedirect = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (query?: Record<string, string>) => {
    history.push(
      generateRedirectPath(
        queryString.stringify(
          cleanQueryParams(query || history.location.search)
        )
      )
    )

    dispatch(getProfile())
  }
}
