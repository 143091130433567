import storeProvider from '@/redux/storeProvider'
import {
  TimeSessionState,
  TTimeSession,
} from '@/modules/ITSM/sites/time-session/typedef'
import {
  clearTimeSessionData,
  setTimeSessionData,
} from '@/modules/ITSM/store/time-session/actions'

type TParams = {
  state?: TimeSessionState
  timeSession?: TTimeSession
}

export const updateTimeSessionStoreData = ({state, timeSession}: TParams) => {
  const store = storeProvider.getStore()

  if (state === TimeSessionState.Closed) {
    store.dispatch(clearTimeSessionData())
  } else if (timeSession) {
    store.dispatch(setTimeSessionData(timeSession))
  }
}
