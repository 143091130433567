import {useCallback, useState} from 'react'

import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {TLocation} from '@/modules/ITSM/typedef'

import {fetchLocation} from '../../../api/locationRequests'

type TUseLocationData = () => {
  getLocationData: (id: string) => Promise<void>
  locationData: TLocation | undefined
  setLocationData: (data: TLocation) => void
}

export const useLocationData: TUseLocationData = () => {
  const [locationData, setLocationData] = useState<TLocation>()
  const dispatch = useDispatch()

  const getLocationData = useCallback(
    async (id: string) => {
      try {
        const {body = {}} = await fetchLocation(id)
        setLocationData(body.result)
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    },
    [dispatch]
  )

  return {locationData, getLocationData, setLocationData}
}
