import request from 'superagent'

import {getBackendTokenHeaders, getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {identitiesApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {TListOptions} from '@/typedef'

export const fetchIdentityMe = async (
  headers: Record<string, any>,
  resolve = ''
) => {
  return await request
    .get(identitiesApi().getMe + resolve)
    .set(headers || getHeadersWithGRPC())
}

export const postIdentity = async (): Promise<ISuperAgentResMultiple<{
  Fail?: Array<string>
  Success?: Array<string>
}>> => {
  return await request.post(identitiesApi().post).set(getBackendTokenHeaders())
}

export const getIdentities = async <T extends Record<string, any>>({
  passedBookmark,
  selector,
  sort,
  resolve,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(identitiesApi().get + resolve || '')
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
        users: {
          $exists: true,
        },
      },
      sort,
    })

export const getIdentityMeOrgName = async () => {
  return await request.get(identitiesApi().getMe).set(getBackendTokenHeaders())
}
