import React, {useState} from 'react'

import {Popover} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'

import {translate} from '@/services/i18n'
import {fetchUsers} from '@/modules/ITSM/api/userRequests'
import {TUser} from '@/modules/ITSM/typedef'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {RESOLVE} from '@/constants'
import {userApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {useGetAssignmentGroupUsers} from '../incident-k-request/hooks/useGetAssignmentGroupUsers'
import UserDetails from '../user-details/user-details'

type TProps = {
  assignedToInit?: TUser | undefined
  assignmentGroupValue: string
  assignedToValue: string
  orgNameSupplierProduct?: string
  setRefresh: () => void
  isDisabled?: boolean
  name?: string
}

const AssignedToSelect = ({
  assignedToInit,
  assignmentGroupValue,
  assignedToValue,
  orgNameSupplierProduct,
  setRefresh,
  isDisabled,
  name,
}: TProps) => {
  const {orgsRoles} = useGetAssignmentGroupUsers()
  const [newAssignedTo, setNewAssignedTo] = useState<Record<string, unknown>>()

  const getUser = async () => {
    const {
      body: {result},
    } = await fetchUsers({
      selector: {uuid: assignedToValue},
    })

    if (result) setNewAssignedTo(result[0])
  }

  const handleDisplayInfo = () => {
    if (assignedToInit?.uuid !== assignedToValue) getUser()
    else setNewAssignedTo(assignedToInit)
  }

  return (
    <Popover
      open={!!newAssignedTo}
      content={<UserDetails userDetails={newAssignedTo} />}
      getPopupContainer={() =>
        document.getElementById('assigned-to-select') as HTMLElement
      }
      trigger="click"
      placement="bottom"
      onOpenChange={open => !open && setNewAssignedTo(undefined)}
    >
      <SelectWithBM
        api={userApi().get + RESOLVE}
        label={
          <span>
            {translate('assigned_to')}
            {assignedToValue && (
              <InfoCircleOutlined
                className="ml-5"
                onClick={handleDisplayInfo}
              />
            )}
          </span>
        }
        name={name || 'assigned_to'}
        selector={{
          ...(assignmentGroupValue
            ? {assignment_groups: {$all: [assignmentGroupValue]}}
            : {}),
          ...(orgNameSupplierProduct
            ? {org_name: orgNameSupplierProduct}
            : {
                $or: orgsRoles?.map(role => ({
                  org_name: role,
                })),
              }),
          ...{
            $or: [
              {
                type: 'engineer',
              },
              {
                type: 'engineer-manager',
              },
            ],
          },
        }}
        initialValue={assignedToInit ? [assignedToInit] : []}
        optionContent={(rec: TUser) => {
          return rec.full_name || ''
        }}
        searchKey="full_name"
        getPopupContainer={(e: HTMLElement) => e.parentNode}
        onSelect={setRefresh}
        disabled={isDisabled}
        fetchOnOpen={true}
        blockFocus={true}
      />
    </Popover>
  )
}

export default AssignedToSelect
