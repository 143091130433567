import {translate} from '@/services/i18n'
import {Entities} from '@/typedef'

import {IncidentState} from '../../incident-k-request/constants'

import {checkIfClosedState} from './check-of-closed-state'

export const getSlaName = (
  state_id: number,
  entity: Entities | string,
  progress?: number
) => {
  if (checkIfClosedState(state_id, entity)) {
    return translate('closed')
  }

  if (state_id === IncidentState.Cancelled) {
    return translate('cancelled')
  } else if (progress !== undefined) {
    if (progress < 50) return translate('ok')
    if (progress >= 50 && progress < 75) return translate('to_be_escalated')
    if (progress >= 75 && progress < 100) return translate('escalated')
    if (progress >= 100) return translate('breached')
    return ''
  }
}
