import moment from 'moment'
import {v4 as uuid} from 'uuid'

import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {Entities} from '@/typedef'

import {IncidentState} from './constants'

export const findHours = data => {
  let endTime, startTime, progress, remainingTime, elapsedTime
  if (
    data.state_id === IncidentState.Open ||
    data.state_id === IncidentState.Pre_on_hold
  ) {
    endTime = data.response_time_due
    startTime = data.virtual_response_time_start
  } else if (
    data.state_id === IncidentState.On_hold ||
    data.state_id === IncidentState.In_progress ||
    data.state_id === IncidentState.Resolved ||
    data.state_id === IncidentState.Closed
  ) {
    endTime = data.resolution_time_due
    startTime = data.virtual_resolution_time_start
  } else {
    endTime = undefined
    startTime = undefined
  }

  elapsedTime =
    data.state_id !== IncidentState.Cancelled && data.sla_elapsed_time
      ? data.sla_elapsed_time
      : 0

  if (startTime && endTime) {
    if (
      data.state_id === IncidentState.Open ||
      data.state_id === IncidentState.In_progress
    ) {
      if (new Date().getTime() < new Date(endTime).getTime()) {
        remainingTime = moment(endTime).diff(moment(), 'seconds')
      } else {
        remainingTime = 0
      }
    } else if (
      data.state_id === IncidentState.Pre_on_hold ||
      data.state_id === IncidentState.On_hold
    ) {
      if (new Date().getTime() < new Date(endTime).getTime()) {
        remainingTime = moment(endTime).diff(
          moment(data.on_hold_start),
          'seconds'
        )
      } else {
        remainingTime = 0
      }
    } else if (
      data.state_id === IncidentState.Resolved ||
      data.state_id === IncidentState.Closed
    ) {
      if (new Date().getTime() < new Date(endTime).getTime()) {
        remainingTime = moment(endTime).diff(
          moment(data.resolved_at),
          'seconds'
        )
      } else {
        remainingTime = 0
      }
    } else {
      remainingTime = 0
    }
    progress = Math.round(
      (elapsedTime * 100) / moment(endTime).diff(moment(startTime), 'seconds')
    )
  } else {
    progress = 0
    remainingTime = 0
  }
  return {progress, remainingTime, elapsedTime}
}

export const generateIncidentsDetailPathITSM = (entity, id) => {
  return entity === Entities.INCIDENT
    ? itsmRoutes.serviceManagementIncidentsDetail.path(id)
    : itsmRoutes.serviceManagementRequestsDetail.path(id)
}

export const generateIncidentStatePathITSM = (entity, id, key) => {
  return entity === Entities.INCIDENT
    ? itsmRoutes.serviceManagementIncidentsState.path(id, key)
    : itsmRoutes.serviceManagementRequestsState.path(id, key)
}

export const generateNewIncidentsPathITSM = entity => {
  return entity === Entities.INCIDENT
    ? itsmRoutes.serviceManagementIncidentsNew.path(uuid())
    : itsmRoutes.serviceManagementRequestsNew.path(uuid())
}

export const generateIncidentsListPathITSM = (entity, option) => {
  return entity === Entities.INCIDENT
    ? itsmRoutes.serviceManagementIncidents.path(option)
    : itsmRoutes.serviceManagementRequests.path(option)
}
