import React, {useState, useEffect} from 'react'

import moment from 'moment'
import {Divider, Modal} from 'antd'

import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import DataTable from '@/components/data-table/data-table'

import TimespansTable from './time-session-timespans'
import {TTimeSpan, TTimeSession, TProps} from './typedef'
import './time-session.scss'

const TimeSessionsTableTS: React.FC<TProps> = ({
  timeSessions,
  getTimeSessions,
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTimeSession, setModalTimeSession] = useState<TTimeSession>()

  const rowClick = (timeSession: TTimeSession) => {
    setModalVisible(!modalVisible)
    setModalTimeSession(timeSession)
  }

  useEffect(() => {
    if (modalVisible) {
      const updatedModalRecord = timeSessions.find(
        current => current.uuid === modalTimeSession?.uuid
      )

      setModalTimeSession(updatedModalRecord)
    }
  }, [timeSessions, modalVisible, modalTimeSession?.uuid])

  const getColumns = () => {
    return [
      {
        title: translate('createdAt'),
        key: 'created_at',
        dataIndex: 'created_at',
        sorter: (a: TTimeSession, b: TTimeSession) =>
          moment(a.created_at).unix() - moment(b.created_at).unix(),
        render: (val: string) => {
          return moment(val).format(DATE_FORMAT)
        },
      },

      {
        title: translate('start'),
        key: 'start',
        dataIndex: 'timespans',
        render: (timeSpans: TTimeSpan[]) => {
          return (timeSpans || []).map((timeSpan: TTimeSpan, index: number) => (
            <div key={index}>
              <div>
                {`start: `}
                {moment(timeSpan.start).format(DATE_FORMAT)}
              </div>
              {timeSpans.length > index + 1 && (
                <Divider type="horizontal" dashed />
              )}
            </div>
          ))
        },
      },
      {
        title: translate('end'),
        key: 'end',
        dataIndex: 'timespans',
        render: (timeSpans: TTimeSpan[]) => {
          return timeSpans.map((timeSpan: TTimeSpan, index: number) => (
            <div key={index}>
              <div>
                {`end: `} {moment(timeSpan.end).format(DATE_FORMAT)}
              </div>
              {timeSpans.length > index + 1 && (
                <Divider type="horizontal" dashed />
              )}
            </div>
          ))
        },
      },
    ]
  }

  return (
    <div>
      <DataTable
        rowKey="uuid"
        data={timeSessions || []}
        columns={getColumns()}
        rowClassName="pointer-cursor"
        onRowClick={rowClick}
        pagination={{
          total: timeSessions?.length,
          defaultPageSize: 10,
        }}
      />
      <Modal
        wrapClassName="modal-wrapper"
        open={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
        width={'50%'}
        destroyOnClose={true}
        className="ts-modal"
      >
        <TimespansTable
          timeSession={modalTimeSession || []}
          getTimeSessions={getTimeSessions}
          getClosedTimeSessions={true}
        />
      </Modal>
    </div>
  )
}
export default TimeSessionsTableTS
