import React from 'react'

import {Divider, Tooltip} from 'antd'
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'

import {translate} from '@/services/i18n'
import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {checkIsOnlyDownloadableFile} from '@/utils/check-is-only-downloadable-file'
import {getNameAndExtension} from '@/utils/get-name-and-extension'
import {Button} from '@/components/button'
import {ActionType} from '@/modules/ITSM/typedef'
import {RequestStatus} from '@/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'

import {ExpensesActionType, TAttachment, TExpense} from './typedef'
import {iconResolved} from './constants'

export const ExpensesRowValue = ({
  title,
  value,
  record,
  attachmentAction,
  approveDeleteExpense,
  approveDeleteExpenseStatus,
  approvalAccess,
}: TColumnRender<TExpense> & {
  attachmentAction: (attachment: TAttachment, action: ActionType) => void
  approveDeleteExpense: (id: string, action: ExpensesActionType) => void
  approveDeleteExpenseStatus: RequestStatus
  approvalAccess: boolean
}) => {
  switch (title) {
    case 'actions': {
      const {extension} = getNameAndExtension(record?.attachment.file_name)

      return (
        <div className="flex">
          <Button
            disabled={!!(extension && checkIsOnlyDownloadableFile(extension))}
            onClick={() =>
              record && attachmentAction(record.attachment, ActionType.PREVIEW)
            }
            title={translate('preview')}
            className="btn-preview btn-sm"
          />

          <Divider type="vertical" />
          <Button
            onClick={() =>
              record && attachmentAction(record.attachment, ActionType.DOWNLOAD)
            }
            title={translate('download')}
            className="btn-download btn-sm"
          />
        </div>
      )
    }
    case 'status': {
      const resolvedExpense = record?.removed || record?.approved

      if (!resolvedExpense) {
        return (
          <div className="flex">
            <Button
              title={translate('remove')}
              className="mr-8 btn-preview btn-sm"
              onClick={() =>
                record?.uuid &&
                approveDeleteExpense(record.uuid, ExpensesActionType.REMOVE)
              }
              disabled={
                checkIsLoading(approveDeleteExpenseStatus) || !approvalAccess
              }
            />

            <Button
              title={translate('approve')}
              type="primary"
              className="btn-download btn-sm"
              onClick={() =>
                record?.uuid &&
                approveDeleteExpense(record.uuid, ExpensesActionType.APPROVE)
              }
              disabled={
                checkIsLoading(approveDeleteExpenseStatus) || !approvalAccess
              }
            />
          </div>
        )
      }
      return (
        <div>
          {record?.approved ? (
            <Tooltip placement="top" title={translate('approved')}>
              <CheckCircleOutlined style={iconResolved.approved} />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={translate('removed')}>
              <CloseCircleOutlined style={iconResolved.removed} />
            </Tooltip>
          )}
        </div>
      )
    }
    default:
      return <div>{value}</div>
  }
}
