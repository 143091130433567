import moment from 'moment'

import {translate} from '@/services/i18n'

export const getDaysFromHours = (value: number) => {
  const hours = value % 24
  const mins = (hours * 60) % 60

  const isMoreThanOneYear =
    Math.floor(moment.duration(value, 'hours').asDays()) > 365
  if (value && isMoreThanOneYear) {
    const overYearLabel = translate('more_than_year')
    return overYearLabel
  } else {
    const dataReturn = `${
      Math.floor(value / 24) >= 1 ? Math.floor(value / 24) + ' days' : ''
    }  ${Math.floor(hours)} h ${Math.floor(mins)} mins`
    return dataReturn
  }
}
