import {TTimeSession} from '@/modules/ITSM/sites/time-session/typedef'
import {TIncident} from '@/modules/ITSM/components/incident-k-request/typedf'

import {ActionType, TAction} from './typedef'

const initialState: TState = {
  timeSession: null,
  ticketType: null,
  ticketData: null,
}

export type TState = {
  timeSession: TTimeSession | null
  ticketType: string | null
  ticketData: TIncident | null
}

export default function timeSessionReducer(
  state = initialState,
  action: TAction
) {
  switch (action.type) {
    case ActionType.SET_TIME_SESSION: {
      return {...state, timeSession: action.payload}
    }
    case ActionType.CLEAR_TIME_SESSION: {
      return {...state, timeSession: initialState.timeSession}
    }
    case ActionType.SET_TICKET_TYPE: {
      return {...state, ticketType: action.payload}
    }

    default:
      return state
  }
}
