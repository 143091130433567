import React, {HTMLAttributes, MouseEvent, ReactNode} from 'react'

import classNames from 'classnames'
import parse from 'html-react-parser'

import './text-content.scss'

type TTextContent = {
  onClick?: (event: MouseEvent<HTMLElement>) => void
  children?: ReactNode
  text: string
  isTextArea?: boolean
  isDisabled?: boolean
  title?: string
}

type TProps = TTextContent & HTMLAttributes<HTMLDivElement>

export const TextContent: React.FC<TProps> = (props: TProps) => {
  const {text, className, onClick, isTextArea, isDisabled, title} = props
  const divClass = classNames(
    'itsm-text-content',
    {'itsm-text-content--text-area': isTextArea},
    {'itsm-text-content--text-area--disabled ': isDisabled},
    className
  )
  return (
    <>
      {title && <div className="fs-12">{title}</div>}
      <div className={divClass} onClick={onClick}>
        {parse(text)}
      </div>
    </>
  )
}
