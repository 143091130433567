import React from 'react'

import {CloseSquareOutlined, CheckSquareOutlined} from '@ant-design/icons'
import moment from 'moment'

import {DATE_FORMAT} from '@/constants'
import {translate} from '@/services/i18n'

import {getFullLocation, getTimeInTimezone} from '../../utils/Helpers'
import {TTimeLog, TTimelogForm, TTimelogReq} from '../../typedef'

export const getColumns = () => {
  const pluralizeHours = (seconds: number) =>
    seconds === 3600 ? ' hr' : ' hrs'

  return [
    {
      title: translate('user'),
      fixed: 'left',
      className: 'time-logs-table--fixed-column',
      render: (_: any, record: TTimeLog) => {
        return <div className="nowrap">{record?.created_by?.full_name}</div>
      },
    },
    {
      title: translate('check_in_time'),
      dataIndex: 'start',
      render: (val: string, record: TTimeLog) => {
        const productTimezone = record.customer_product?.sla?.timezone
        return (
          <div className="nowrap">
            {productTimezone
              ? getTimeInTimezone(val, productTimezone)
              : moment(val).format(DATE_FORMAT)}
          </div>
        )
      },
    },
    {
      title: translate('check_out_time'),
      dataIndex: 'end',
      render: (val: string, record: TTimeLog) => {
        const productTimezone = record.customer_product?.sla?.timezone

        return val !== undefined ? (
          <div className="nowrap">
            {productTimezone
              ? getTimeInTimezone(val, productTimezone)
              : moment(val).format(DATE_FORMAT)}
          </div>
        ) : null
      },
    },
    {
      title: translate('travel_time'),
      dataIndex: 'travel',
      render: (val: number, record: TTimeLog) => {
        const units = val > 3599 ? pluralizeHours(val) : ' min'
        const endValue =
          record.end !== undefined && record.travel === undefined ? '0 min' : ''
        return (
          <div className="nowrap">
            {record.end !== undefined
              ? moment.duration(val, 'seconds').format('h:mm') + units
              : endValue}
          </div>
        )
      },
    },
    {
      title: translate('break_time'),
      dataIndex: 'break',
      render: (val: number, record: TTimeLog) => {
        const units = val > 3599 ? pluralizeHours(val) : ' min'
        const endValue =
          record.end !== undefined && record.break === undefined ? '0 min' : ''
        return (
          <div className="nowrap">
            {record.end !== undefined
              ? moment.duration(val, 'seconds').format('h:mm') + units
              : endValue}
          </div>
        )
      },
    },
    {
      title: translate('work_time'),
      dataIndex: 'work',
      render: (val: number, record: TTimeLog) => {
        const units = val > 3599 ? pluralizeHours(val) : ' min'
        const endValue =
          record.end !== undefined && record.break === undefined ? '0 min' : ''
        return (
          <div className="nowrap">
            {record.end !== undefined
              ? moment.duration(val, 'seconds').format('h:mm') + units
              : endValue}
          </div>
        )
      },
    },

    {
      title: translate('customer_location'),
      dataIndex: 'customer_location',
      render: (_: any, record: TTimeLog) => {
        const loc = record.location

        if (loc && loc !== null) {
          return <div>{getFullLocation(loc)}</div>
        } else {
          return ''
        }
      },
    },
    {
      title: translate('cmdb_updated'),
      dataIndex: 'cmdb_updated',
      render: (val: boolean) => {
        return val ? (
          <CheckSquareOutlined className="fs-24" />
        ) : (
          <CloseSquareOutlined className="fs-24" />
        )
      },
    },
    {
      title: translate('visit_summary'),
      dataIndex: 'visit_summary',
      width: 150,
      render: (val: string) =>
        val !== undefined ? <div className="fs-12">{val}</div> : null,
    },
    {
      title: translate('is_cancelled'),
      dataIndex: 'is_cancelled',
      render: (val: boolean) => val && 'True',
    },
  ]
}

export const createDataCheckOut = (
  values: TTimelogForm,
  timeLogOpened: TTimeLog[]
): TTimelogReq => {
  return {
    timelog: timeLogOpened[0].uuid,
    cmdb_updated: values.cmdb_updated,
    ignore_extra_pricing_policy: !values.ignore_extra_pricing_policy,
    visit_summary: `${translate('actions_performed_onsite')}: ${
      values.actions_performed_onsite
    },\n
      ${translate('unexpected_issues_on_field_engineer_visit')}: ${
      values.unexpected_issues_on_field_engineer_visit
    }`,
  }
}

export const getInitialValues = (values: TTimeLog) => {
  return {
    cmdb_updated: values.cmdb_updated,
    ignore_extra_pricing_policy: !values.ignore_extra_pricing_policy,
    actions_performed_onsite: prepareInitialValues(
      values.visit_summary,
      'actions'
    ),
    unexpected_issues_on_field_engineer_visit: prepareInitialValues(
      values.visit_summary,
      'issues'
    ),
  }
}

export const prepareInitialValues = (visitSummary: string, type: string) => {
  let start,
    end,
    result = ''

  if (visitSummary) {
    if (type === 'actions') {
      start =
        visitSummary.trim().indexOf('Actions performed on-site:') +
        'Actions performed on-site:'.length

      end = visitSummary
        .trim()
        .indexOf('Unexpected issues on field engineer visit')

      result = (visitSummary.trim().substring(start, end) || '').trim()
    } else {
      start =
        visitSummary.indexOf('Unexpected issues on field engineer visit:') +
        'Unexpected issues on field engineer visit:'.length

      result = (visitSummary.trimStart().substring(start) || '').trimStart()
    }

    if (result.slice(-1) === ',')
      result = result.substring(0, result.length - 1)
    return result
  }
}

export const createData = (id: string, values: Record<string, unknown>) => {
  return {
    timelog: id,
    cmdb_updated: !!values.cmdb_updated,
    visit_summary: `${translate('actions_performed_onsite')}: ${
      values.actions_performed_onsite
    },\n
    ${translate('unexpected_issues_on_field_engineer_visit')}: ${
      values.unexpected_issues_on_field_engineer_visit
    }`,
  }
}
