import {TAppState} from '../store'

export function selectVersion(state: TAppState) {
  return state.ui.version.currentVersion
}

export function selectRedirectUrl(state: TAppState) {
  return state.ui.redirectUrl
}

export function selectRedirectToList(state: TAppState) {
  return state.ui.redirectToList
}

export function selectUiModalMessage(state: TAppState) {
  const {
    uiModalMessage,
    uiModalType,
    modalRedirectUrl,
    resolvers,
    error,
  } = state.ui
  return {uiModalMessage, uiModalType, modalRedirectUrl, resolvers, error}
}

export const selectBlayoutData = ({
  general,
  ui,
  itsmUser,
  profile,
}: TAppState) => {
  const {
    redirect,
    values,
    err: error,
    cancel_upadating: cancelUpdating,
  } = general
  return {
    ui,
    error,
    profile,
    redirect,
    values,
    cancelUpdating,
    itsmUser,
  }
}
