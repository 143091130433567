import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import RoleDrawer from '@/modules/ITSM/sites/ITSMSettings/Roles/RoleDrawer'

const RoleNew = () => {
  const {getEntityDataInit, onClose, record} = useListTableStateContext()

  return (
    <RoleDrawer
      newRole={true}
      uuid={record?.uuid}
      onClose={onClose}
      getEntityData={getEntityDataInit}
    />
  )
}

export default RoleNew
