import {SaasApplication} from '@/modules/SAAS/services/openAPI/Api'
import {CmdbAsset} from '@/modules/CMDB/api-types/Api'

export const appsNormalize = (apps?: Array<SaasApplication>) =>
  apps?.map(({display_name, id}) => ({
    name: display_name,
    id,
  })) || []

export const assetsNormalize = (assets?: Array<CmdbAsset>) =>
  assets?.map(({name, id}) => ({
    name,
    id: id || '',
  })) || []
