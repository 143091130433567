import request from 'superagent'
import queryString from 'query-string'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {expensesApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TExpense} from '../sites/Expenses/typedef'

export const fetchTimelogExpenses = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(expensesApi().get + resolve)
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        entity: `timelog:${queryString.parse(window.location.search).timelog}`,
      },
      sort,
    })

export const postExpense = async (data: TExpense, id?: string) => {
  return await request
    .post(expensesApi().post(id))
    .set(getHeadersWithGRPC())
    .send(data)
}
export const getExpensesSingleton = async () => {
  return await request
    .get(expensesApi().singleton)
    .send()
    .set(getHeadersWithGRPC())
}
export const approveExpenseApi = async (data: {expense: string}) => {
  return await request
    .post(expensesApi().approve)
    .set(getHeadersWithGRPC())
    .send(data)
}
export const deleteExpenseApi = async (data: {expense: string}) => {
  return await request
    .post(expensesApi().delete)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchExpenses = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  sort,
  selector,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(expensesApi().get + resolve)
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector,
      sort,
    })
