import React, {useEffect, useReducer} from 'react'

import {useHistory, useLocation, useParams} from 'react-router-dom'
import {Spin} from 'antd'

import TicketWrapperSteps from '@/modules/ITSM/components/incident/ticket-wrapper-steps/ticket-wrapper-steps'
import {useSafeDispatch} from '@/hooks/useSafeDispatch'
import {usePatchEntity} from '@/hooks/useUpdateData'
import {Entities} from '@/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import {IncidentTaskHeader} from '@/modules/ITSM/components/incident-task/incident-task-header/incident-task-header'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {patchIncidentTask} from '../../../api/incidentTasksRequests'
import {
  incidentReducer,
  initialState,
  setCurrentTab,
  setIncidentTabIsBlocked,
  setProgress,
  setSlaValues,
} from '../../incident/incident-reducer'
import {IncTabs} from '../../incident-k-request/constants'
import {createStateContext} from '../../incident/incident-context'
import {refreshProgressBar} from '../../incident-k-request/utils/refresh-progress-bar'
import {IncidentProgressBar} from '../../incident/incident-progress-bar/incident-progress-bar'
import {useGetIncidentTask} from '../hooks/useGetIncidentTask'
import {TIncidentTask, TIncidentTaskReq} from '../typedf'
import {generateSteps} from '../utils/generate-steps'
import {getActiveTab} from '../utils/get-active-tab'
import {getSLAtime} from '../utils/get-sla-time'
import IncidentTaskTabs from '../incident-task-tabs/incident-task-tabs'
import {setTab} from '../utils/set-tab'

import './incident-task-detail.scss'

type TProps = {
  id: string
}

const IncidentTaskDetail = ({id}: TProps) => {
  const history = useHistory()

  const {getData, entityData, fetchStatus} = useGetIncidentTask(id)

  const [incState, unsafeDispatch] = useReducer(incidentReducer, initialState)
  const dispatch = useSafeDispatch(unsafeDispatch)

  const {search} = useLocation()

  const {saveEntity: updateIncident, saveEntityStatus} = usePatchEntity<
    TIncidentTaskReq
  >(patchIncidentTask)

  const {state: initTab} = useParams<{state: string}>()

  const {slaValues, currentTab} = incState || {}

  const setActiveTab = (result: TIncidentTask, init?: boolean) => {
    const {state_id, assignee_state} = result

    const activeTab = getActiveTab({state_id, assignee_state})

    activeTab && dispatch(setCurrentTab((init && initTab) || activeTab))
  }

  useEffect(() => {
    ;(async () => {
      const result = await getData()
      if (result) {
        dispatch(setSlaValues(getSLAtime(result)))

        setActiveTab(result, true)
      }
    })()
  }, [getData, id])

  useEffect(() => {
    const refetchData = async () => {
      const result = await getData(true)
      if (result) {
        const slaValues = getSLAtime(result)

        dispatch(setSlaValues(slaValues))

        dispatch(setProgress(refreshProgressBar(slaValues)))
      }
    }

    const interval = setInterval(() => refetchData(), 60000)

    return () => clearInterval(interval)
  }, [dispatch, getData, id])

  const {elapsedTime} = slaValues || {}
  useEffect(() => {
    if (elapsedTime !== null && slaValues) {
      dispatch(setProgress(refreshProgressBar(slaValues)))
    }
  }, [dispatch, elapsedTime])

  useEffect(() => {
    if (currentTab)
      history.replace({
        pathname:
          itsmRoutes.serviceManagementIncidentsTaskState.path(id, currentTab) +
          (search ? search : ''),
      })
  }, [currentTab, dispatch, history, id])

  const handleGoBack = () => {
    entityData &&
      history.push(
        itsmRoutes.serviceManagementIncidentsState.path(
          entityData.incident.uuid,
          IncTabs.IncidentTasksTab
        )
      )
  }

  const IncidentContext = createStateContext<TIncidentTask>()

  return (
    <Spin
      wrapperClassName="incident-task-detail__spinner"
      spinning={checkIsLoading(fetchStatus)}
      size="large"
    >
      <IncidentContext.Provider
        value={{
          incState: incState || initialState,
          entityData,
          dispatch,
          getData: async setTab => {
            const result = entityData && (await getData())
            if (result !== undefined && setTab) setActiveTab(result)
            dispatch(setIncidentTabIsBlocked(false))
          },
          updateEntity: (values: TIncidentTaskReq) =>
            entityData?.uuid && updateIncident(values, entityData.uuid),
          isLoading: checkIsLoading(saveEntityStatus),
          entity: Entities.INCIDENT_TASK,
        }}
      >
        <Paper className="mb-24">
          <IncidentTaskHeader
            goBackButtonHandler={handleGoBack}
            numberId={entityData?.number}
            priority={entityData?.priority}
          />
        </Paper>
        <Paper className="mb-24">
          <TicketWrapperSteps
            steps={generateSteps(entityData)}
            setTab={setTab}
          />
          <IncidentProgressBar />
        </Paper>
        <Paper className="mb-24" variant={PaperVariant.tab}>
          <IncidentTaskTabs />
        </Paper>
      </IncidentContext.Provider>
    </Spin>
  )
}

export default IncidentTaskDetail
