import {omit} from 'lodash'
import queryString from 'query-string'

const removeAccountDefaultParams = [
  'company',
  'first-name',
  'last-name',
  'email',
  'phone-number',
  'payment-status',
]

export const cleanQueryParams = (query: Record<string, string> | string) => {
  if (typeof query === 'string')
    return omit(queryString.parse(query), removeAccountDefaultParams)

  return omit(query, removeAccountDefaultParams)
}
