import React from 'react'

import {Col, Row} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {translate} from '@/services/i18n'
import ErrorPage from '@/components/error/error-page/error-page'

import {TextContent} from '../text-content/text-content'

const CancellationNote = ({cancellationNote}: {cancellationNote: string}) => {
  return (
    <Row>
      <Col span={24}>
        <>
          <TextContent
            text={cancellationNote}
            className="mb-30"
            isTextArea={true}
            title={translate('cancellation_note')}
          />
        </>
      </Col>
    </Row>
  )
}
export default withErrorBoundary(CancellationNote, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
