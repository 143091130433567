import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TIncidentRes} from '@/modules/ITSM/components/incident-k-request/typedf'
import {itsmColumnsDefaults} from '@/modules/ITSM/components/itsm-list-table/constants/columns-defaults'
import {ItsmAssets, TBillingItem} from '@/modules/ITSM/typedef'
import {TListOptions} from '@/typedef'

import {TTicketSelector} from '../typedf'

export const parseAsyncFunction = <T extends Record<string, any>>(
  asyncF: ({
    selector,
    ...rest
  }: TListOptions<T> & TTicketSelector) => Promise<
    ISuperAgentResMultiple<TBillingItem>
  >
) => {
  return ({selector, ...rest}: TListOptions<T> & TTicketSelector) => {
    const ticketFields = itsmColumnsDefaults[
      ItsmAssets.BillingItems
    ].filter(({dataIndex}) => dataIndex.includes('ticket'))

    const newSelector = {...selector}
    let ticketSelector: {[key in keyof TIncidentRes]?: string} = {}

    for (const key in newSelector) {
      if (ticketFields.find(({title}) => title === key)) {
        ticketSelector = {...ticketSelector, [key]: newSelector[key]}

        delete newSelector[key]
      }
    }

    return asyncF({
      selector: newSelector,
      ...(Object.keys(ticketSelector).length > 0 && {
        ticket_selector: ticketSelector,
      }),
      ...rest,
    })
  }
}
