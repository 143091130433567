import React, {useEffect, useState} from 'react'

import {v4 as uuid} from 'uuid'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import arrayMove from 'array-move'
import {PlusOutlined} from '@ant-design/icons'

import DataTable from '@/components/data-table/data-table'
import {TAppState} from '@/redux/store'
import {TableColumnsCustomizer} from '@/components/table-columns-customizer/table-columns-customizer'
import {TKey, translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {SortingKeys, SortingValues} from '@/typedef'
import ListTableHeader from '@/components/list-table-header/list-table-header'
import QuickSearchTableHeader from '@/components/list-table-header/quick-search-table-header'
import {selectWindowWidth} from '@/redux/reducers/generalSelector'
import {TDefaultQuickFilters} from '@/components/filter/typedef'
// import {largeScreen} from '@/styles/_variables.module.scss'
import useGetIncidentsRequestsListTableData from '@/modules/ITSM/components/itsm-list-table/hooks/use-get-incidents-requests-list-table-data'
import {Pagination} from '@/components/pagination/pagination'
import {Button} from '@/components/button'

import {apiService} from '../../../api/api-service'
import {ItsmAssets, TQuickFilters, View} from '../../../typedef'
import {
  selectItsmBookmark,
  selectItsmTableColumns,
  selectItsmTableFilters,
  selectItsmTableQuickFilter,
  selectItsmTableSorters,
} from '../../../store/list-table/selectors'
import {
  resetItsmTableFilter,
  resetItsmTableFilters,
  setItsmTableColumns,
  setItsmTableFilter,
  setItsmTableFilters,
  setItsmTablePage,
  setItsmTableQuickFilters,
  setItsmTableSorters,
} from '../../../store/list-table/table-actions'
import {useInitFetchData} from '../hooks/use-get-init-fetch'
import {useGenerateInputFiler} from '../hooks/use-generate-input-filter'
import {generateActiveColumns} from '../utils/generate-active-columns'
import {
  apiServiceInitFetch,
  itsmSorters,
  keysToDelete,
} from '../constants/filters-config'
import {
  entityDetailPath,
  entityNewPath,
} from '../itsm-list-table-settings/constants/routes-config'
import {ExportBtn} from '../../export-btn/export-btn'
import {IncidentKRequestTileList} from '../../incident-k-request/incident-k-request-tile-list/incident-k-request-tile-list'
import {TColumnRender, TFilterListTable} from '../typedf'
import {getQuickFilter} from '../utils/get-quick-filter'
import {getCurrentPage} from '../utils/get-current-page'

import useExportAsset from './hooks/use-export-asset'
import {IncidentsRequestdRowValue} from './itsm-list-table-incidents-requests-row-value'

import './itsm-list-table-incidents-requests.scss'

type TIncidentsRequestsListTableProps<T> = {
  asset: ItsmAssets
  assetQuickFilters: Array<{
    name: TQuickFilters
    query: TFilterListTable<T>
  }>
  isAssetQuickFiltersLoading?: boolean
}

const IncidentsRequestsListTable = <T extends Record<string, any>>({
  asset,
  assetQuickFilters,
  isAssetQuickFiltersLoading = false,
}: TIncidentsRequestsListTableProps<T>) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {exportAsset, isExportLoading} = useExportAsset(asset)

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, asset)
  )

  const [view, setView] = useState(View.TABLE)

  const {
    entityData,
    getEntityData,
    getQuickFilters,
    numberOfRecords,
    fetchStatus,
  } = useGetIncidentsRequestsListTableData<T>(
    asset,
    assetQuickFilters,
    apiService[asset]
  )

  const {initFetchData} = useInitFetchData<T>(apiServiceInitFetch<T>()[asset])
  const currentWindowWidth = useSelector(selectWindowWidth)

  /*
  useEffect(() => {
    const currentView =
      currentWindowWidth <= largeScreen ? View.TILE : View.TABLE
    setView(currentView)
  }, [currentWindowWidth])
  */

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const quickFilter = useSelector(
    (state: TAppState) =>
      selectItsmTableQuickFilter(state, asset) || TDefaultQuickFilters.All
  )

  const page = useSelector((state: TAppState) =>
    selectItsmBookmark(state, asset)
  )
  const handleNew = () => {
    history.push(entityNewPath[asset]?.(uuid()) as string)
  }

  const {
    generatedFilters,
    inputValues,
    resetFilter,
    resetAllFilters,
  } = useGenerateInputFiler<T>(asset)

  const sorterHandler = (sorter: Record<SortingKeys, SortingValues> | null) => {
    dispatch(setItsmTableSorters(asset, sorter))
    getEntityData({
      passedSorter: sorter,
      passedFilters: {
        ...filters,
        ...getQuickFilter(quickFilter, assetQuickFilters),
      },
    })
  }

  const resetFiltersHandler = (asset: ItsmAssets) => {
    dispatch(setItsmTableQuickFilters(asset, TDefaultQuickFilters.All))
    dispatch(resetItsmTableFilters(asset))
    dispatch(setItsmTablePage({asset}))
    resetAllFilters()
    getEntityData({
      passedSorter: sorter,
      passedFilters: {},
    })
  }

  const resetQuickFilterHandler = (asset: ItsmAssets) => {
    dispatch(setItsmTableQuickFilters(asset, TDefaultQuickFilters.All))
    resetAllFilters()
    dispatch(setItsmTablePage({asset, bookmark: undefined}))
    getEntityData({
      passedSorter: sorter,
      passedFilters: {
        ...filters,
      },
    })
  }

  const onSearch = (key: string) => {
    if (!key) return
    if (keysToDelete[asset]?.[key]) {
      delete inputValues[keysToDelete[asset]?.[key] as string]
    }

    if (quickFilterActive !== TDefaultQuickFilters.None) {
      dispatch(setItsmTableQuickFilters(asset, TDefaultQuickFilters.None))
    }

    dispatch(
      setItsmTableFilter(
        asset,
        inputValues[key] !== undefined ? {[key]: inputValues[key]} : {}
      )
    )
    dispatch(setItsmTablePage({asset}))
    getEntityData({
      passedSorter: sorter,
      passedFilters: {
        ...getQuickFilter(quickFilter, assetQuickFilters),
        ...filters,
        [key]: inputValues[key],
      },
    })
  }

  const onReset = (key: string) => {
    if (Object.keys(filters).length === 1 && Object.keys(filters)[0] === key) {
      dispatch(setItsmTableQuickFilters(asset, TDefaultQuickFilters.All))
    }
    dispatch(resetItsmTableFilter(asset, key))
    dispatch(setItsmTablePage({asset}))
    resetFilter(key)
    delete filters[key]
    getEntityData({
      passedSorter: sorter,
      passedFilters: {
        ...filters,
        ...getQuickFilter(quickFilter, assetQuickFilters),
      },
    })
  }

  const handleActiveCols = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const movedItems = arrayMove(columns, oldIndex, newIndex)
      dispatch(setItsmTableColumns(asset, movedItems))
    }
  }

  const handleSetSelectedColumn = (selectedTitle: string, isAdded: boolean) => {
    const dataWithSelectedColumn = columns.map(data => {
      return data.title === selectedTitle ? {...data, selected: isAdded} : data
    })

    dispatch(setItsmTableColumns(asset, dataWithSelectedColumn))
  }

  const handleRowClick = (record: Record<string, any>) => {
    dispatch(setItsmTableQuickFilters(asset, quickFilterActive))
    history.push(entityDetailPath[asset]?.(record.uuid))
  }

  const draggableData = columns.map(column => ({
    title: column.title as TKey,
    isChecked: column.selected,
  }))

  const changeFilterValue = (key: string) => {
    if (key.length <= 0) {
      dispatch(setItsmTableQuickFilters(asset, TDefaultQuickFilters.All))
      dispatch(resetItsmTableFilters(asset))
    } else {
      dispatch(setItsmTableQuickFilters(asset, TDefaultQuickFilters.None))
      dispatch(setItsmTableFilters(asset, {number: key}))
    }

    if (!key) {
      delete filters['number']
    }

    getEntityData({
      passedSorter: sorter,
      passedFilters: {
        ...getQuickFilter(quickFilter, assetQuickFilters),
        ...filters,
        ...(key && {number: key}),
      },
    })
  }

  const handleGetRowValue = (props: TColumnRender<T>) => (
    <IncidentsRequestdRowValue<T> {...props} />
  )
  const handlePaginationClick = async (page: number) => {
    getEntityData({
      passedBookmark: ((page - 1) * 10).toString(),
      passedSorter: sorter,
      passedFilters: {
        ...filters,
        ...getQuickFilter(quickFilter, assetQuickFilters),
      },
    })
  }

  const customPager = (
    <Pagination
      showSizeChanger={false}
      onChange={handlePaginationClick}
      current={page ? getCurrentPage(page) : 1}
      total={numberOfRecords}
    />
  )

  const isLoading =
    isAssetQuickFiltersLoading ||
    checkIsLoading(fetchStatus) ||
    !entityData ||
    ((apiServiceInitFetch || []).length > 0 &&
      Object.keys(initFetchData || []).length !== apiServiceInitFetch?.length)

  return (
    <>
      <ListTableHeader<ItsmAssets>
        title={asset as TKey}
        asset={asset}
        handleNew={handleNew}
        setSorting={sorterHandler}
        generatedFilters={generatedFilters}
        resetFilters={resetFiltersHandler}
        resetQuickFilter={resetQuickFilterHandler}
        {...(sorter && {sorter})}
        onSearch={onSearch}
        onReset={onReset}
        moduleSorters={itsmSorters}
        quickFilters={getQuickFilters && getQuickFilters()}
        tableCustomizer={
          <TableColumnsCustomizer
            items={draggableData}
            updateItemOrder={handleActiveCols}
            handleActiveColumns={handleSetSelectedColumn}
          />
        }
        isLoading={isAssetQuickFiltersLoading}
      />
      {view === View.TABLE ? (
        <DataTable<T>
          tableHeader={
            <div className={'flex w-100'}>
              <ExportBtn onChange={exportAsset} isLoading={isExportLoading} />
              <div className="list-table-header__top-table-right">
                <QuickSearchTableHeader
                  changeFilterValue={changeFilterValue}
                  value={
                    inputValues?.number ? inputValues?.number.toString() : ''
                  }
                />
                <Button
                  className={'buttons list-table-header__btn-new'}
                  type="primary"
                  title={translate('add_new')}
                  onClick={handleNew}
                  icon={<PlusOutlined />}
                  e2e-test="add_new"
                />
              </div>
            </div>
          }
          data={entityData || []}
          columns={generateActiveColumns({
            columns,
            asset,
            dataResolved: initFetchData,
            getRowValue: handleGetRowValue,
          })}
          onRowClick={handleRowClick}
          loading={isLoading}
          customPager={customPager}
        />
      ) : (
        <>
          <IncidentKRequestTileList<T>
            data={entityData || []}
            rowClick={handleRowClick}
            loading={isLoading}
            handleNew={handleNew}
          />
          {customPager}
        </>
      )}
    </>
  )
}

export default IncidentsRequestsListTable