import {TUserFormPayload} from '@/sites/accept-org-invite/typedef'

export const createUserPayload = (values: TUserFormPayload) => {
  const {terms, ...newValues} = values

  return {
    ...newValues,
    name: newValues.name.trim(),
    surname: newValues.surname.trim(),
    email: newValues.email.trim(),
    notification: newValues.notification,
    phone: (newValues.phone || '').trim(),
    unavailable: newValues.unavailable,
    timezone: newValues.timezone,
    type: newValues.type,
    contract_type: newValues.contract_type,
  }
}
