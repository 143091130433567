import * as ActionTypes from '../actionTypes'

export const getCreditAccessSuccess = creditAccess => ({
  type: ActionTypes.FETCH_CREDIT_ACCESS_SUCCESS,
  payload: creditAccess,
})

export const getCreditAccessFailed = () => ({
  type: ActionTypes.FETCH_CREDIT_ACCESS_FAILED,
})

export const getCreditAccessReq = () => ({
  type: ActionTypes.FETCH_CREDIT_ACCESS_REQUESTED,
})

export const getMyAccessSuccess = myAccess => ({
  type: ActionTypes.FETCH_MY_ACCESS_SUCCESS,
  payload: myAccess,
})

export const getMyAccessFailed = () => ({
  type: ActionTypes.FETCH_MY_ACCESS_FAILED,
})

export const getMyAccessReq = () => ({
  type: ActionTypes.FETCH_MY_ACCESS_REQUESTED,
})

export const resetSettingsState = () => ({
  type: ActionTypes.RESET_SETTINGS_STATE,
})
