import {SortingKeys, SortingValues} from '@/typedef'
import {CmdbNamespace} from './api-types/Api'

export type TSorterPayload = Partial<Record<SortingKeys, SortingValues>>
export type TFilterPayload = Record<string, Array<Record<string, unknown>>>

export type TSelector = {
  page?: string
  detail?: boolean
  filters?: TFilterPayload
  sort?: Array<TSorterPayload>
}

export type TCmdbIdentityMe = {
  role: string
  orgID: string
  name: string
  email: string
  namespaces: Array<CmdbNamespace>
  userId: string
}

export enum CMDBAssets {
  Users = 'Users',
  Accounts = 'Accounts',
  NameSpaces = 'NameSpaces',
  Assets = 'Assets',
  AssetTypes = 'AssetTypes',
  Locations = 'Locations',
}

export enum CMDBReports {
  Reports = 'Reports',
}

export enum FilterTypes {
  Search = 'Search',
  // CheckBox = 'CheckBox',
  Select = 'Select',
  SelectWithBM = 'SelectBM',
  Date = 'Date',
  NA = 'NA',
}

export type TDataTablePreset = Array<{
  title: string
  selected: boolean
  index: number
  quickFilter: boolean
  filterType: FilterTypes
  dataIndex?: string | string[]
}>

export type TColumnDefault = TDataTablePreset
