import {FilterTypes} from '../typedf'

const columnTableConfig = {
  number: {
    title: 'number',
    dataIndex: 'number',
    index: 0,
    filterType: FilterTypes.Search,
    quickFilter: false,
    selected: true,
  },
  caller: {
    title: 'caller',
    dataIndex: ['caller', 'full_name'],
    index: 1,
    filterType: FilterTypes.SelectWithBM,
    quickFilter: true,
    selected: true,
  },
  short_description: {
    title: 'short_description',
    dataIndex: 'short_description',
    index: 2,
    filterType: FilterTypes.Search,
    quickFilter: true,
    selected: true,
  },
  priority: {
    title: 'priority',
    dataIndex: 'priority',
    index: 3,
    filterType: FilterTypes.Select,
    quickFilter: true,
    selected: true,
    advancedRender: true,
  },
  state_id: {
    title: 'state_id',
    dataIndex: 'state_id',
    index: 4,
    filterType: FilterTypes.Select,
    quickFilter: true,
    selected: true,
    advancedRender: true,
  },
  location: {
    title: 'location',
    dataIndex: ['location', 'name'],
    index: 5,
    filterType: FilterTypes.SelectWithBM,
    quickFilter: true,
    selected: true,
  },
  country: {
    title: 'country',
    dataIndex: ['location', 'country'],
    index: 6,
    filterType: FilterTypes.Search,
    quickFilter: true,
    selected: true,
  },
  assigned_to: {
    title: 'assigned_to',
    dataIndex: ['assigned_to', 'full_name'],
    index: 7,
    filterType: FilterTypes.SelectWithBM,
    quickFilter: true,
    selected: true,
    advancedRender: true,
  },
  created_at: {
    title: 'created_at',
    dataIndex: 'created_at',
    index: 8,
    quickFilter: false,
    selected: true,
  },
  assignee_state: {
    title: 'assignee_state',
    dataIndex: 'assignee_state',
    index: 9,
    filterType: FilterTypes.Select,
    quickFilter: true,
    selected: false,
  },
  contact_type: {
    title: 'contact_type',
    dataIndex: 'contact_type',
    filterType: FilterTypes.Select,
    index: 10,
    quickFilter: true,
    selected: false,
  },
  created_by: {
    title: 'created_by',
    dataIndex: ['created_by', 'full_name'],
    filterType: FilterTypes.SelectWithBM,
    index: 11,
    quickFilter: true,
    selected: false,
  },
  updated_at: {
    title: 'updated_at',
    dataIndex: 'updated_at',
    index: 12,
    quickFilter: false,
    selected: false,
  },
  updated_by: {
    title: 'updated_by',
    dataIndex: ['updated_by', 'full_name'],
    filterType: FilterTypes.SelectWithBM,
    index: 13,
    quickFilter: true,
    selected: false,
  },
  cmdb_ref: {
    title: 'cmdb_ref',
    dataIndex: ['cmdb_ref', '0'],
    index: 14,
    filterType: FilterTypes.SelectFetch,
    quickFilter: false,
    selected: true,
  },
}

export const incidentsRequestsColumnsUP = [
  columnTableConfig.number,
  {...columnTableConfig.caller, quickFilter: false},
  columnTableConfig.short_description,
  columnTableConfig.priority,
  columnTableConfig.state_id,
  columnTableConfig.location,
  columnTableConfig.created_at,
  columnTableConfig.assignee_state,
  columnTableConfig.contact_type,
  columnTableConfig.created_by,
  columnTableConfig.updated_at,
  columnTableConfig.updated_by,
]

export const incidentsRequestsColumns = [
  columnTableConfig.number,
  columnTableConfig.caller,
  columnTableConfig.short_description,
  columnTableConfig.priority,
  columnTableConfig.state_id,
  columnTableConfig.location,
  columnTableConfig.assigned_to,
  columnTableConfig.created_at,
  columnTableConfig.assignee_state,
  columnTableConfig.contact_type,
  columnTableConfig.created_by,
  columnTableConfig.updated_at,
  columnTableConfig.updated_by,
]

export const IncidentDefFields = [
  'uuid',
  'state_id',
  'response_time_due',
  'resolution_time_due',
  'resolution_time_start',
  'resolved_at',
  'closed_at',
  'expected_start',
  'on_hold_start',
  'sla_elapsed_time',
  'virtual_resolution_time_start',
  'virtual_response_time_start',
  'created_by',
  'updated_by',
]
