import {ModuleFeatureFlags, TNormalizedProduct} from '@/sites/purchase/typedef'
import {BlitsModule} from '@/modules/Subscriptions/utils/constants'

export const getModulesAddons = (prices: Array<TNormalizedProduct>) =>
  prices.reduce<
    {
      [key in ModuleFeatureFlags]: Array<TNormalizedProduct>
    }
  >(
    (accPrices, price) => ({
      [ModuleFeatureFlags.ITSM]:
        price.priceType !== 'module' &&
        price.category?.toLowerCase() === BlitsModule.ticket_management
          ? [...accPrices[ModuleFeatureFlags.ITSM], price]
          : accPrices[ModuleFeatureFlags.ITSM],
      [ModuleFeatureFlags.CMDB]:
        price.priceType !== 'module' &&
        price.category?.toLowerCase() === BlitsModule.asset_management
          ? [...accPrices[ModuleFeatureFlags.CMDB], price]
          : accPrices[ModuleFeatureFlags.CMDB],
      [ModuleFeatureFlags.SAAS]:
        price.priceType !== 'module' &&
        price.category?.toLowerCase() === BlitsModule.saas_management
          ? [...accPrices[ModuleFeatureFlags.SAAS], price]
          : accPrices[ModuleFeatureFlags.SAAS],
    }),
    {
      [ModuleFeatureFlags.ITSM]: [],
      [ModuleFeatureFlags.CMDB]: [],
      [ModuleFeatureFlags.SAAS]: [],
    }
  )
