import {Stats, TBillingItem} from '@/modules/ITSM/sites/finance-dashboard/types'
import {translate} from '@/services/i18n'

type TTransformData = Array<{
  name: string
  data: Array<{
    x: number
    y: number
  }>
}>

export const transformOverviewData = (data: Array<TBillingItem>) =>
  data.reduce<TTransformData>(
    (accumulateTransformedData, monthStatistic) =>
      Object.values(Stats).map((stat, index) => ({
        name: translate(stat),
        data: [
          ...(accumulateTransformedData[index]?.data || []),
          {
            x: new Date(monthStatistic.interval_start).getTime(),
            y: monthStatistic[stat],
          },
        ],
      })),
    []
  )
