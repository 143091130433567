import moment from 'moment'
import difference from 'lodash/difference'
import transform from 'lodash/transform'
import fromPairs from 'lodash/fromPairs'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import toPairs from 'lodash/toPairs'

const changeDateFormatToISO = (data: Record<string, any>) => {
  Object.keys(data).forEach(key => {
    if (data[key] instanceof moment) {
      data[key] = data[key].toISOString()
    }
  })
  return data
}

const resetNullValueToUndefined = (data: Record<string, any>) => {
  Object.keys(data).forEach(key => {
    if (isNull(data[key])) {
      data[key] = undefined
    }
  })
  return data
}

const filterCommonKeys = (
  object: Record<string, any>,
  baseObject: Record<string, any>
) =>
  Object.fromEntries(
    Object.entries(object).filter(data =>
      Object.keys(baseObject).includes(data[0])
    )
  )

const addValueFromUuid = (
  object: Record<string, any>,
  baseObject: Record<string, any>
) => {
  const dataWithUuidValue = Object.keys(object).reduce(
    (acc: Record<string, any>, key) => {
      if (object[key]) {
        acc[key] = object[key].uuid ?? object[key]
      }

      return acc
    },
    {}
  )
  const updatedData = resetNullValueToUndefined(
    changeDateFormatToISO(dataWithUuidValue)
  )

  return filterCommonKeys(updatedData, baseObject)
}

const checkIfValueWasDeleted = (
  transformedObject: Record<string, any>,
  baseObject: Record<string, any>
) => {
  return Object.keys(transformedObject).reduce(
    (acc: Record<string, any>, key) => {
      if (transformedObject?.[key] === undefined && !!baseObject[key]) {
        acc[key] = null
      }

      return {...transformedObject, ...acc}
    },
    {}
  )
}

type TGetDataDifference = (
  object: Record<string, any>,
  baseObject: Record<string, any>
) => Record<string, any>

export const getDataDifference: TGetDataDifference = (object, baseObject) => {
  const updatedObject = addValueFromUuid(object, baseObject)
  const updatedBaseObject = resetNullValueToUndefined(
    changeDateFormatToISO(baseObject)
  )

  const transformedObject = transform(
    updatedBaseObject,
    (result: Record<string, any>, value, key) => {
      if (!isEqual(value, updatedObject[key])) {
        result[key] =
          isObject(value) &&
          isObject(updatedObject[key]) &&
          !Array.isArray(value) &&
          !Array.isArray(updatedObject[key])
            ? fromPairs(difference(toPairs(value), toPairs(updatedObject[key])))
            : value
      }
    }
  )

  return checkIfValueWasDeleted(transformedObject, object)
}
