export type TDataTablePreset = Array<{
  title: string
  selected: boolean
  index: number
  quickFilter: boolean
  filterType: FilterTypes
  dataIndex?: string | string[]
}>

export type TColumnDefault = TDataTablePreset
export type TFilterPayload = Record<string, Array<Record<string, unknown>>>

export enum FilterTypes {
  Search = 'Search',
  Select = 'Select',
  NA = 'NA',
}
