import {useState, useEffect} from 'react'

export const useOnScreen = (ref, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false)
  useEffect(() => {
    let newRef = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    if (newRef) {
      observer.observe(newRef)
    }
    return () => {
      observer.unobserve(newRef)
      newRef = null
    }
  }, [ref, rootMargin])

  return {isIntersecting}
}
