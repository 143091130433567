import React, {FC} from 'react'

import classnames from 'classnames'

import './services-styled-wrapper.scss'

import {TChildren} from '@/typedef'

export const ServicesStyledWrapper: FC<TChildren & {className?: string}> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classnames('services-styled-wrapper', {
        ...(className && {[className]: true}),
      })}
    >
      {children}
    </div>
  )
}
