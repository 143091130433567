import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {versionApi} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getVersion = async () => {
  return await request
    .options(versionApi().getVersion)
    .set(getHeadersWithGRPC())
}
