import React from 'react'

import {Tag} from 'antd'

import {translate} from '@/services/i18n'

const TierTag = ({tier}) => {
  if (tier === 0) return <Tag color={'#444'}>{translate('unspecified')}</Tag>
  if (tier === 1) return <Tag color={'green'}>{translate('free')}</Tag>
  if (tier === 2) return <Tag color={'orange'}>{translate('production')}</Tag>
  if (tier > 2) return <Tag color={'purple'}>{translate('custom')}</Tag>
}

export default TierTag
