import {saasColumnsDefaults} from '../../components/saas-list-table/constants/columns-defaults'
import {TDataTablePreset} from '../../components/saas-list-table/typedf'
import {SaasAssets, TFilterListTable} from '../../typedf'

import {ActionTypes, TAnyAction} from './action-types'

export type TColumns = {
  columns: TDataTablePreset
  filters: TFilterListTable
}

type TInitialState = Record<SaasAssets, TColumns>

const defaultAssetEntries = {
  columns: saasColumnsDefaults,
  filters: {},
}

const initialState: TInitialState = {
  [SaasAssets.Users]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.Users],
  },
  [SaasAssets.AppUsers]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.AppUsers],
  },
  [SaasAssets.AppGroups]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.AppGroups],
  },
  [SaasAssets.Apps]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.Apps],
  },
  [SaasAssets.SettingApps]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.SettingApps],
  },
  [SaasAssets.SystemUsers]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.SystemUsers],
  },
  [SaasAssets.SettingsNamespaces]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.SettingsNamespaces],
  },
  [SaasAssets.SettingsAppsDiscover]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.SettingsAppsDiscover],
  },
  [SaasAssets.GroupsSettings]: {
    ...defaultAssetEntries,
    columns: saasColumnsDefaults[SaasAssets.GroupsSettings],
  },
}

export default function saasDataTableReducer(
  state = initialState,
  action: TAnyAction
): TInitialState {
  switch (action.type) {
    case ActionTypes.SET_SAAS_LIST_TABLE_COLUMNS: {
      const {asset, columns} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          columns,
        },
      }
    }

    case ActionTypes.RESET_SAAS_LIST_TABLE_FILTERS: {
      const {asset} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          filters: {},
        },
      }
    }

    case ActionTypes.SET_SAAS_LIST_TABLE_FILTERS: {
      const {asset, filters} = action.payload
      return {
        ...state,
        [asset]: {
          ...state[asset],
          filters: {
            ...state[asset].filters,
            ...filters,
          },
        },
      }
    }

    case ActionTypes.RESET_SAAS_LIST_TABLE_FILTER: {
      const {asset, key} = action.payload

      const newFilters = {...state[asset].filters}

      delete newFilters[key]

      return {
        ...state,
        [asset]: {
          ...state[asset],
          filters: newFilters,
        },
      }
    }

    default:
      return state
  }
}
