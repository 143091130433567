import React from 'react'

import {Select as AntdSelect, SelectProps} from 'antd'
import classNames from 'classnames'

import {filterOption} from '@/utils/filter-option'
import './select.scss'

export const Select: React.FC<SelectProps<any>> = ({className, ...props}) => {
  const classes = classNames('select', className)

  return (
    <AntdSelect
      {...props}
      filterOption={filterOption}
      className={classes}
      getPopupContainer={e => e.parentNode as HTMLElement}
    />
  )
}

export const {Option} = AntdSelect
