import {Interval} from '@/modules/typedef'
import {
  ModuleFeatureFlags,
  TNormalizedProduct,
  TPurchasesModule,
} from '../typedef'

export const generateModules = (
  generalPrices: Record<Interval, TNormalizedProduct[]>,
  intervalType: Interval,
  selectedModules: TPurchasesModule
) => {
  return Object.values(ModuleFeatureFlags).map(module => {
    const moduleProduct = generalPrices[intervalType].find(
      ({priceType, category}) => {
        return priceType === 'module' && category === module
      }
    )
    const isSelectedModule = selectedModules.some(
      featureFlag => featureFlag === module
    )

    return {
      moduleProduct,
      isSelectedModule,
    }
  })
}
