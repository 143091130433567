import React, {createContext, ReactNode, useEffect} from 'react'

import {createContextualCan} from '@casl/react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {useAuth} from 'react-oidc-context'

import {useGetGrantLevel} from '@/modules/ITSM/components/ITSMSubmenu/hooks/use-grant-level'
import {selectBillingFeatureFlags} from '@/redux/billing/billing-selector'
import {generateConfig} from '@/generateConfig'
import {OverlaySpinner} from '@/components/overlay-spinner'
import {buildAbilityFor, TAppAbility} from './ability-config'
import {selectModuleProfile} from '@/redux/profile/profileSelector'

import {pathsWithoutGetProfile} from '@/constants'

export const AbilityContext = createContext<TAppAbility>(
  buildAbilityFor('user', [], false)
)

export const AbilityContextProvider = ({children}: {children: ReactNode}) => {
  const {pathname} = useLocation()
  const stripeFeatureFlagList = useSelector(selectBillingFeatureFlags)
  const {accountId: defaultAccountId} = useSelector(selectModuleProfile) || {
    accountId: null,
  }
  const auth = useAuth()

  const {isStripeEnabled} = generateConfig().FEATURE_FLAGS
  const isPathsWithoutGetProfile = pathsWithoutGetProfile.includes(pathname)

  const {getGrantLevel, userRole} = useGetGrantLevel()
  const ability = buildAbilityFor(
    userRole,
    stripeFeatureFlagList,
    isStripeEnabled
  )

  useEffect(() => {
    if (!isPathsWithoutGetProfile && defaultAccountId) {
      getGrantLevel(defaultAccountId)
    }
  }, [defaultAccountId, getGrantLevel, isPathsWithoutGetProfile])

  return (
    <AbilityContext.Provider value={ability}>
      {!auth.isAuthenticated || isPathsWithoutGetProfile || !!userRole ? (
        children
      ) : (
        <OverlaySpinner
          isLogoutButtonVisible={true}
          idName={'ability-context'}
        />
      )}
    </AbilityContext.Provider>
  )
}

export default createContextualCan(AbilityContext.Consumer)
