import React, {FC} from 'react'

import {Select} from 'antd'

import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {TIncidentValue} from '@/modules/ITSM/components/incident-k-request/typedf'
import {translate} from '@/services/i18n'
import {TUserAsset} from '@/modules/ITSM/typedef'

const Option = Select.Option

type TProps = {
  initialValue: string
  disabled?: boolean
  userAssetList: Array<TUserAsset>
}

const AssetSelect: FC<TProps> = ({initialValue, disabled, userAssetList}) => {
  return (
    <FormItemGeneric<TIncidentValue>
      name={'cmdb_ref'}
      label={translate('asset')}
      initialValue={initialValue}
    >
      <Select
        data-testid="asset"
        getPopupContainer={triggerNode => triggerNode.parentElement}
        disabled={disabled}
      >
        {userAssetList.map(asset => (
          <Option key={asset.id} value={asset.id || ''}>
            {asset.name}
          </Option>
        ))}
      </Select>
    </FormItemGeneric>
  )
}

export default AssetSelect
