import {TDataTablePreset} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {UserRole} from '@/typedef'

const supplierSums = 'supplier_sums'
const customerSums = 'customer_sums'

export const getUpdatedColumns = (
  columns: TDataTablePreset,
  role: UserRole
): TDataTablePreset => {
  const newColumns = [...columns] as TDataTablePreset

  const getIndex = (title: string) =>
    columns.findIndex(({dataIndex}) => dataIndex === title)

  const getTitle = (title: string, role: UserRole) => {
    switch (title) {
      case customerSums:
        if (role === UserRole.CUSTOMER) return 'cost'
        else return 'revenue'
      case supplierSums:
        if (role === UserRole.PROVIDER) return 'cost'
        else return 'revenue'
      default:
        break
    }
  }

  const updateTitle = (title: string) => {
    const columnIndex = getIndex(title)
    newColumns[columnIndex]['title'] = getTitle(title, role) || ''
  }

  if (role === UserRole.SUPPLIER) {
    const columnIndex = getIndex(customerSums)
    newColumns[columnIndex]['selected'] = false
  }

  if (role === UserRole.CUSTOMER) {
    const columnIndex = getIndex(supplierSums)
    newColumns[columnIndex]['selected'] = false
  }

  updateTitle(customerSums)
  updateTitle(supplierSums)

  return newColumns
}
