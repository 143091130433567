import {useCallback, useState} from 'react'

import {defaultCategory} from '@/modules/ITSM/components/incident-k-request/constants'
import {useGetCmdbAssets} from '@/hooks/use-get-cmdb-assets'
import {useGetSaasAssets} from '@/hooks/use-get-saas-assets'
import {TUserAsset} from '@/modules/ITSM/typedef'
import {otherAsset} from '@/modules/ITSM/constants/other-asset'
import {
  appsNormalize,
  assetsNormalize,
} from '@/modules/ITSM/components/form-components/utils/asset-select-normallize'

export const useGetCategoryAssets = () => {
  const [assets, setAssets] = useState<Array<TUserAsset>>([])

  const {getEntityData: getCmdbUserAssets} = useGetCmdbAssets()
  const {getSaasUserApps} = useGetSaasAssets()

  const getAssets = useCallback(
    async (uuid: string, callerUuid: string) => {
      if (uuid === defaultCategory[0].uuid) {
        const assets = await getCmdbUserAssets(callerUuid)
        setAssets([otherAsset, ...assetsNormalize(assets)])
      } else if (uuid === defaultCategory[1].uuid) {
        const apps = await getSaasUserApps(callerUuid)
        setAssets([otherAsset, ...appsNormalize(apps)])
      } else {
        setAssets([otherAsset])
      }
    },
    [getSaasUserApps, getCmdbUserAssets]
  )

  return {assets, getAssets}
}
