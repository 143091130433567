import {CMDBAssets, FilterTypes} from '@/modules/CMDB/typedef'

type TCmdbColumnsDefaults = {
  [key in CMDBAssets]: Array<{
    title: string
    selected: boolean
    index: number
    quickFilter: boolean
    filterType: FilterTypes
    filterAsset?: CMDBAssets
    dataIndex?: string | string[]
    advancedRender?: boolean
  }>
}

export const cmdbColumnsDefaults: TCmdbColumnsDefaults = {
  [CMDBAssets.Users]: [
    {
      title: 'name',
      selected: true,
      index: 0,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'role',
      selected: true,
      index: 1,
      quickFilter: false,
      filterType: FilterTypes.Select,
    },
    {
      title: 'createdAt',
      selected: true,
      index: 2,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'deletedAt',
      selected: false,
      index: 3,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
  ],
  [CMDBAssets.NameSpaces]: [
    {
      title: 'name',
      selected: true,
      index: 0,
      quickFilter: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'createdAt',
      selected: true,
      index: 1,
      quickFilter: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'deletedAt',
      selected: false,
      index: 2,
      quickFilter: true,
      filterType: FilterTypes.NA,
    },
  ],
  [CMDBAssets.Accounts]: [
    {
      title: 'name',
      selected: true,
      index: 0,
      quickFilter: true,
      filterType: FilterTypes.Search,
    },
    {
      title: 'createdAt',
      selected: true,
      index: 1,
      quickFilter: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'deletedAt',
      selected: false,
      index: 2,
      quickFilter: true,
      filterType: FilterTypes.NA,
    },
  ],
  [CMDBAssets.Assets]: [
    {
      title: 'name',
      dataIndex: 'name',
      selected: true,
      index: 0,
      quickFilter: false,
      filterType: FilterTypes.Search,
      advancedRender: true,
    },
    {
      title: 'assetType',
      dataIndex: ['assetType', 'displayName'],
      selected: true,
      index: 1,
      quickFilter: false,
      filterType: FilterTypes.SelectWithBM,
      filterAsset: CMDBAssets.AssetTypes,
    },
    {
      title: 'domain',
      dataIndex: ['fields', 'assetBasicInfo', 'domain'],
      selected: false,
      index: 2,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'manufacturer',
      dataIndex: ['fields', 'assetCustom', 'manufacturer'],
      selected: false,
      index: 3,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'model',
      dataIndex: 'model',
      selected: false,
      index: 4,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'lastUser',
      dataIndex: 'lastUser',
      selected: true,
      index: 5,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'ipAddress',
      dataIndex: ['fields', 'assetBasicInfo', 'ipAddress'],
      selected: true,
      index: 6,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'macAddress',
      dataIndex: ['fields', 'assetBasicInfo', 'mac'],
      selected: false,
      index: 7,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'company',
      dataIndex: 'company',
      selected: false,
      index: 8,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'operatingSystem',
      dataIndex: ['fields', 'operatingSystem', 'caption'],
      selected: true,
      index: 9,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'location',
      dataIndex: ['location', 'fullLocation'],
      selected: false,
      index: 10,
      quickFilter: false,
      filterType: FilterTypes.SelectWithBM,
      filterAsset: CMDBAssets.Locations,
    },

    {
      title: 'state',
      dataIndex: 'state',
      selected: true,
      index: 13,
      quickFilter: false,
      filterType: FilterTypes.Select,
    },
    {
      title: 'purchaseDate',
      dataIndex: 'purchaseDate',
      selected: false,
      index: 14,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },

    {
      title: 'serialNumber',
      dataIndex: 'serialNumber',
      selected: true,
      index: 15,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'firstSeen',
      dataIndex: 'firstSeen',
      selected: false,
      index: 16,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'lastSeen',
      dataIndex: 'lastSeen',
      selected: false,
      index: 17,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
    {
      title: 'description',
      dataIndex: ['fields', 'assetBasicInfo', 'description'],
      selected: false,
      index: 18,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
  ],
  [CMDBAssets.AssetTypes]: [
    {
      title: 'name',
      selected: true,
      index: 0,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'createdAt',
      selected: false,
      index: 2,
      quickFilter: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'deletedAt',
      selected: false,
      index: 3,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
  ],
  [CMDBAssets.Locations]: [
    {
      title: 'name',
      selected: true,
      index: 0,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'description',
      selected: true,
      index: 1,
      quickFilter: false,
      filterType: FilterTypes.Search,
    },
    {
      title: 'createdAt',
      selected: false,
      index: 2,
      quickFilter: true,
      filterType: FilterTypes.NA,
    },
    {
      title: 'deletedAt',
      selected: false,
      index: 3,
      quickFilter: false,
      filterType: FilterTypes.NA,
    },
  ],
}
