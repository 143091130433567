import {merge} from 'lodash'

import {ItsmAssets} from '@/modules/ITSM/typedef'

import {itsmColumnsDefaults} from '../constants/columns-defaults'
import {nestedFilters} from '../constants/filters-config'
import {searchFetchColumnKeys} from '../constants/search-fetch-column-keys'
import {FilterTypes, TDataTablePreset, TFilterListTable} from '../typedf'

type TProps<T> = {
  inputValues: TFilterListTable<T>
  asset: ItsmAssets
}

const checkIfSearchProperty = <T extends Record<string, any>>({
  inputValues,
  asset,
}: TProps<T>) => {
  const searchProperty = Object.keys(searchFetchColumnKeys?.[asset] || []).find(
    key => {
      return Object.prototype.hasOwnProperty.call(inputValues, key)
    }
  )

  if (searchProperty) delete inputValues[searchProperty]
  return inputValues
}

const checkIfRegexSearch = <T extends Record<string, any>>({
  inputValues,
  asset,
}: TProps<T>) => {
  const columnsDef: TDataTablePreset = itsmColumnsDefaults[asset]

  for (const key in inputValues) {
    if (
      columnsDef.find(col => col.title === key)?.filterType ===
        FilterTypes.Search &&
      inputValues[key]
    )
      inputValues = {
        ...inputValues,
        [key]: {
          $regex: `(?i)${inputValues[key]?.toString().trim()}`,
        },
      }
  }

  return inputValues
}

const checkIfNestedSearchObj = <T extends Record<string, any>>({
  inputValues,
  asset,
}: TProps<T>) => {
  for (const key in inputValues) {
    if (nestedFilters[asset]?.[key]) {
      const reducer = (acc: any, item: any) => ({[item]: acc})

      inputValues = merge(
        inputValues,
        Object.assign(
          {},
          inputValues,
          nestedFilters[asset]?.[key].reduceRight(reducer, inputValues[key])
        )
      )

      delete inputValues[key]
    }
  }

  return inputValues
}

const checkIfDateRange = <T extends Record<string, any>>({
  inputValues,
  asset,
}: TProps<T>) => {
  const columnsDef: TDataTablePreset = itsmColumnsDefaults[asset]

  for (const key in inputValues) {
    if (
      columnsDef.find(col => col.title === key)?.filterType ===
        FilterTypes.DateRange &&
      inputValues[key]
    ) {
      Object.assign(inputValues, {
        $and: [
          {[key]: {$gte: (inputValues[key] as Array<string>)[0]}},
          {[key]: {$lte: (inputValues[key] as Array<string>)[1]}},
        ],
      })

      delete inputValues[key]
    }
  }

  return inputValues
}

export const prepareSelector = <T extends Record<string, unknown>>({
  inputValues,
  asset,
}: TProps<T>) => {
  let newInputValues = {...inputValues}

  newInputValues = checkIfSearchProperty<T>({
    inputValues: newInputValues,
    asset,
  })
  newInputValues = checkIfRegexSearch<T>({inputValues: newInputValues, asset})
  newInputValues = checkIfNestedSearchObj<T>({
    inputValues: newInputValues,
    asset,
  })
  newInputValues = checkIfDateRange<T>({inputValues: newInputValues, asset})

  return newInputValues
}
