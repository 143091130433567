import React, {useRef} from 'react'

import {RequestStatus, TListOptions} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'

import {useSafeDispatch} from './useSafeDispatch'

type TAsyncState<T> = {
  status: RequestStatus
  data?: T[]
  error?: string
  bookMark?: string
  numberOfRecords?: number
}
type TAsyncAction<T> =
  | {
      type: RequestStatus.REQUESTED
    }
  | {type: RequestStatus.SUCCEEDED; data: Array<T>; bookmark: string}
  | {type: RequestStatus.FAILED; error: string}
  | {type: RequestStatus.INITIAL}

function asyncReducer<T>(state: TAsyncState<T>, action: TAsyncAction<T>) {
  switch (action.type) {
    case RequestStatus.INITIAL: {
      return {
        status: RequestStatus.INITIAL,
        data: undefined,
      }
    }

    case RequestStatus.REQUESTED: {
      return {
        status: RequestStatus.REQUESTED,
        data: state.data,
        bookMark: state.bookMark,
      }
    }
    case RequestStatus.SUCCEEDED: {
      return {
        status: RequestStatus.SUCCEEDED,
        error: '',
        bookMark: action.bookmark,
        data: (state.data || []).concat(action.data),
        numberOfRecords: action?.data?.length || 0,
      }
    }
    case RequestStatus.FAILED: {
      return {
        status: RequestStatus.FAILED,
        data: [],
        error: action.error,
        bookMark: '',
      }
    }

    default: {
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`)
    }
  }
}
export function useAsync<T>(
  promise: (options: TListOptions<T>) => Promise<ISuperAgentResMultiple<T>>,
  initialState?: TAsyncState<T>
) {
  const [state, unsafeDispatch] = React.useReducer<
    React.Reducer<TAsyncState<T>, TAsyncAction<T>>
  >(asyncReducer, {
    status: RequestStatus.INITIAL,
    error: '',
    bookMark: undefined,
    ...initialState,
  })

  const {data, bookMark, error, status, numberOfRecords} = state

  const dispatch = useSafeDispatch(unsafeDispatch)
  const selectorRef = useRef<Record<string, any>>({})

  const run = React.useCallback(
    async ({selector}: {selector: Record<string, any>}) => {
      dispatch({type: RequestStatus.REQUESTED})
      selectorRef.current = selector

      try {
        const {
          body: {bookmark, result},
        } = await promise({
          passedBookmark: bookMark,
          selector,
        })

        dispatch({
          type: RequestStatus.SUCCEEDED,
          data: result,
          bookmark,
        })
      } catch (err) {
        dispatch({type: RequestStatus.FAILED, err})
      }
    },
    [bookMark, dispatch, promise]
  )

  const setInitialState = () =>
    dispatch({
      type: RequestStatus.INITIAL,
    })

  return {
    data,
    bookMark,
    error,
    status,
    run,
    numberOfRecords,
    setInitialState,
  }
}
