export const getInitData = async (
  asyncF: any,
  nameProperty: string,
  valueProperty: string
): Promise<{value: any; name: any}[]> => {
  const {
    body: {result},
  } = await asyncF({})
  const newValues = []

  for (const key in result) {
    newValues.push({
      value: result[key][valueProperty],
      name: result[key][nameProperty],
    })
  }
  return newValues
}
