import React from 'react'

import moment from 'moment'
import {Tooltip} from 'antd'
import {CheckSquareOutlined, CloseSquareOutlined} from '@ant-design/icons'

import {DATE_FORMAT} from '@/constants'
import {getFullLocation, getTimeInTimezone} from '@/modules/ITSM/utils/Helpers'
import {translate} from '@/services/i18n'

import {TTimeLog} from '../../typedef'
import {TColumnRender} from '../itsm-list-table/typedf'

import './time-logs.scss'

export const TimeLogsRowValue = ({
  title,
  value,
  record,
}: TColumnRender<TTimeLog>) => {
  const productTimezone = record?.customer_product?.sla?.timezone
  const pluralizeHours = (seconds: number) =>
    seconds === 3600 ? ' hr' : ' hrs'

  const isCancelled = record?.is_cancelled

  const generateCell = () => {
    switch (title) {
      case 'user':
        return <div className="nowrap">{value}</div>

      case 'check_in_time':
        return (
          <div className="nowrap">
            {value &&
              (productTimezone
                ? getTimeInTimezone(value, productTimezone)
                : moment(value as string)
                    .utc()
                    .format(DATE_FORMAT))}
          </div>
        )

      case 'check_out_time':
        return (
          <div className="nowrap">
            {value &&
              (productTimezone
                ? getTimeInTimezone(value, productTimezone)
                : moment(value as string)
                    .utc()
                    .format(DATE_FORMAT))}
          </div>
        )

      case 'travel_time': {
        const travelTime = Number(value) + (record?.travel_back || 0)
        const units =
          travelTime > 3599 ? pluralizeHours(Number(travelTime)) : ' min'
        const endValue =
          record?.end !== undefined && record?.travel === undefined
            ? '0 min'
            : ''
        return (
          <div className="nowrap">
            {record?.end !== undefined
              ? moment.duration(travelTime, 'seconds').format('h:mm') + units
              : endValue}
          </div>
        )
      }
      case 'work_time': {
        const units = value > 3599 ? pluralizeHours(Number(value)) : ' min'
        const endValue =
          record?.end !== undefined && record?.break === undefined
            ? '0 min'
            : ''
        return (
          <div className="nowrap">
            {record?.end !== undefined
              ? moment.duration(value as string, 'seconds').format('h:mm') +
                units
              : endValue}
          </div>
        )
      }
      case 'break_time': {
        const units = value > 3599 ? pluralizeHours(Number(value)) : ' min'
        const endValue =
          record?.end !== undefined && record?.break === undefined
            ? '0 min'
            : ''
        return (
          <div className="nowrap">
            {record?.end !== undefined
              ? moment.duration(value as string, 'seconds').format('h:mm') +
                units
              : endValue}
          </div>
        )
      }

      case 'customer_location': {
        const loc = record?.location

        return <div>{loc && loc !== null ? getFullLocation(loc) : ''}</div>
      }

      case 'cmdb_updated': {
        return (
          <div>
            {value ? (
              <CheckSquareOutlined className="fs-24" />
            ) : (
              <CloseSquareOutlined className="fs-24" />
            )}
          </div>
        )
      }

      case 'visit_summary': {
        return (
          <div e2e-test="time-log-summary" className="fs-12">
            {value.toString().length > 30
              ? value
                  .toString()
                  .substring(0, 30)
                  .trim() + '...'
              : value}
          </div>
        )
      }

      case 'is_cancelled': {
        return (
          <div e2e-test="time-log-is_cancelled" className="fs-12">
            {value ? 'true' : 'false'}
          </div>
        )
      }

      default:
        return <div>{value}</div>
    }
  }

  const cell = generateCell()

  return isCancelled ? (
    <Tooltip title={translate('canceled')}>{cell}</Tooltip>
  ) : (
    cell
  )
}
