import {useSelector} from 'react-redux'

import {useFetchHook} from '@/hooks/useFetch'
import {
  approveExpenseApi,
  deleteExpenseApi,
} from '@/modules/ITSM/api/expensesRequest'
import {TListArgs} from '@/typedef'
import {selectItsmTableFilters} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'
import {ItsmAssets} from '@/modules/ITSM/typedef'

import {ExpensesActionType, TExpense} from '../typedef'

export const useDeleteExpense = (
  getEntityData: ({
    passedBookmark,
    resetBookmarks,
    passedFilters,
  }: TListArgs<TExpense>) => Promise<TExpense[] | undefined>,
  asset: ItsmAssets
) => {
  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<TExpense>(state, asset)
  )

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const approveDeleteExpense = async (
    uuid: string,
    action: ExpensesActionType
  ) => {
    handleRequested()

    try {
      if (action === ExpensesActionType.REMOVE)
        await deleteExpenseApi({
          expense: uuid,
        })
      else
        await approveExpenseApi({
          expense: uuid,
        })
      getEntityData({resetBookmarks: true, passedFilters: filters})
      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  return {approveDeleteExpense, approveDeleteExpenseStatus: status}
}
