import React from 'react'

import {useParams} from 'react-router-dom'

import {Entities} from '@/typedef'
import {Paper, PaperVariant} from '@/components/paper/paper'

import IncidentKrequestNew from '../../components/incident-k-request/incident-k-request-new/incident-k-request-new'
import {ItsmAssets} from '../../typedef'

const KRequestNew = () => {
  const {id} = useParams<{id: string}>()

  return (
    <Paper variant={PaperVariant.form}>
      <IncidentKrequestNew
        entity={Entities.K_REQUEST}
        id={id}
        asset={ItsmAssets.Requests}
      />
    </Paper>
  )
}

export default KRequestNew
