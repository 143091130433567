import {TAnomaly, TBillingItems} from '@/modules/ITSM/typedef'

export const createAnomaliesTableData = (
  anomalies: TAnomaly[],
  billingItems: TBillingItems
) => {
  const refreshedAnomalies = billingItems[0]?.billing_report?.anomalies
  const currentAnomalies = refreshedAnomalies ?? anomalies

  return (
    currentAnomalies?.map(newAnomaly => {
      return {
        item: billingItems.find(
          billingItem => billingItem.uuid === newAnomaly.uuid
        ),
        ...newAnomaly,
      }
    }) ?? []
  )
}
