import {fetchOrgsRole} from '@/modules/ITSM/api/itsmOrgRequests'

export const getOrgRoles = async () => {
  const res = await fetchOrgsRole()
  const result = res.body.result.value
  const newRoles = []

  for (const key in result) {
    newRoles.push({
      value: key,
      name: result[key].name,
    })
  }
  return newRoles
}
