import React, {useCallback, useEffect, useState} from 'react'

import {Editor} from 'react-draft-wysiwyg'
import {Checkbox, Col, Form, Row, Select, Tooltip} from 'antd'
import {withErrorBoundary} from '@sentry/react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'

import {Button} from '@/components/button'
import {EditorWrapper} from '@/components/editor'
import {translate} from '@/services/i18n'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {RESOLUTION_CODE_VALUES} from '@/constants'
import ErrorPage from '@/components/error/error-page/error-page'
import {apiService} from '@/modules/ITSM/api/api-service'
import {selectItsmAssetPagination} from '@/modules/ITSM/store/list-table/selectors'
import {usePrevious} from '@/hooks/usePrevious'
import {TAppState} from '@/redux/store'
import {ItsmAssets, TTimeLog} from '@/modules/ITSM/typedef'
import {checkAccess} from '@/modules/ITSM/utils/accessChecks'
import {draftToolbarOptions} from '@/modules/ITSM/utils/Constants'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import FormItemCustom from '@/components/form/form-item-custom'

import {useStateContext} from '../../incident/incident-context'
import {
  setCurrentTab,
  setIncidentTabIsBlocked,
} from '../../incident/incident-reducer'
import {fetchTicketsToCheck} from '../../../api/incidentsRequests'
import {IncidentState, IncTabs} from '../constants'
import {TIncident} from '../typedf'
import {useGetDataListTableNoFilters} from '../../itsm-list-table-no-filters/hooks/use-get-data-list-table-no-filters'
import ItsmListTableNoFilters from '../../itsm-list-table-no-filters/itsm-list-table-no-filters'
import {TimeLogsRowValue} from '../../time-logs/time-logs-row-value'
import {TColumnRender} from '../../itsm-list-table/typedf'
import TimeLogCheckout from '../../time-logs/time-log-checkout'
import {getWorkingTime} from '../utils/get-working-time'
import {isTimelogDisabled} from '../utils/is-timelog-diasabled'

import {getTimeLogsUuid, getWorkTime} from './utils'
import {TTimeLogsCheck} from './typedf'

import './incident-k-request-resolution-form.scss'

const Option = Select.Option

type TProps = {
  editableState: boolean
}

type TFormValues = Pick<TIncident, 'resolution_notes' | 'resolution_code'>

const ResolutionForm = ({editableState}: TProps) => {
  const [form] = Form.useForm()
  const asset = ItsmAssets.TimeLogsResolve

  const history = useHistory()
  const updatingTSright = checkAccess('updateTimelogTimespan')

  const [timeLogsUuid, setTimeLogsUuid] = useState<Array<{uuid: string}>>()

  const {
    entityData,
    updateEntity,
    getData,
    isLoading,
    dispatch,
    entity,
  } = useStateContext<TIncident>()

  const paginationAsset =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const prevPaginationAsset = usePrevious(paginationAsset)

  const {state_id, resolution_notes, uuid, timelogs: ticketTimeLogs} =
    entityData || {}

  const workTime = ticketTimeLogs && getWorkTime(ticketTimeLogs)

  const timelogOpened =
    ticketTimeLogs && ticketTimeLogs.filter((timelog: TTimeLog) => !timelog.end)

  const isTimeLogOpened = timelogOpened && timelogOpened.length > 0

  const {
    getEntityData: fetchTimeLogs,
    entityData: timeLogsData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTableNoFilters<TTimeLog>({
    asyncF: apiService[asset],
    asset,
    entity,
    initFetch: false,
  })

  const initialValuesState =
    state_id === IncidentState.Resolved ||
    state_id === IncidentState.Closed ||
    state_id === IncidentState.Cancelled

  const {
    editorState,
    onEditorStateChange,
    editorText,
    checkContent,
  } = useSetEditorState(initialValuesState ? resolution_notes : '')

  const handleSubmit = async (values: TIncident) => {
    const result = await updateEntity({
      resolution_code: values.resolution_code,
      resolution_notes: editorText,
      state_id: IncidentState.Resolved,
    })

    if (result !== undefined) getData(true)
  }

  const checkValues = useCallback(() => {
    dispatch(
      setIncidentTabIsBlocked(
        initialValuesState
          ? false
          : form.getFieldValue('resolution_code') || !!editorText
      )
    )
  }, [dispatch, editorText, form, initialValuesState])

  useEffect(() => {
    checkValues()
  }, [checkValues, editorText])

  const getTicketsToCheck = useCallback(async () => {
    const {
      body: {result},
    }: {body: {result: TTimeLogsCheck}} = await fetchTicketsToCheck({
      entity: `${entity}:${uuid}`,
    })

    const timeLogsUuid = getTimeLogsUuid(result).map(uuid => {
      return {
        uuid,
      }
    })

    setTimeLogsUuid(timeLogsUuid)

    if (timeLogsUuid.length)
      fetchTimeLogs({resetBookmarks: true, passedFilters: {$or: timeLogsUuid}})
  }, [entity, fetchTimeLogs, uuid])

  useEffect(() => {
    if (!timeLogsUuid) getTicketsToCheck()
  }, [entity, uuid, timeLogsUuid])

  const refetchTimeLogs = useCallback(() => {
    getTicketsToCheck()
    getData()
  }, [getTicketsToCheck, getData])

  useEffect(() => {
    if (
      prevPaginationAsset?.bookmarks.length &&
      !paginationAsset.bookmarks.length
    ) {
      refetchTimeLogs()
    }
  }, [paginationAsset, prevPaginationAsset, refetchTimeLogs])

  const setRedirect = (uuid: string) => {
    dispatch(setCurrentTab(IncTabs.TimeLogsTab))
    history.push('?timelog=' + uuid)
  }

  const handleGetRowValue = (props: TColumnRender<TTimeLog>) => (
    <TimeLogsRowValue {...props} />
  )

  const getRowClass = (record: TTimeLog) =>
    updatingTSright && editableState && record.end && !record.is_cancelled
      ? 'pointer-cursor'
      : ''

  const handleRowClick = (record: TTimeLog) =>
    updatingTSright &&
    editableState &&
    record.end &&
    !record.is_cancelled &&
    setRedirect(record.uuid)

  return (
    <Row>
      <Col span={24} className="resolution-form" e2e-test="resolution_editor">
        {entityData && !isTimelogDisabled(entityData) && (
          <>
            {workTime !== undefined && (
              <h4 className="mb-30">{getWorkingTime(workTime)}</h4>
            )}
            <p className="mb-30">{translate('resolve_ticket_message')}</p>
            <h4 className="fw-500">{translate('time_logs_to_check')}</h4>

            <ItsmListTableNoFilters<TTimeLog>
              {...{
                getEntityData: passedBookmark =>
                  fetchTimeLogs({
                    ...passedBookmark,
                    passedFilters: {$or: timeLogsUuid || []},
                  }),
                entityData: timeLogsData,
                numberOfRecords,
                fetchStatus,
                asset,
                onRowClick: handleRowClick,
                rowClassName: getRowClass,
                getRowValue: handleGetRowValue,
              }}
            />

            <div className="mt-30">
              {isTimeLogOpened && (
                <TimeLogCheckout
                  timelogOpened={timelogOpened}
                  refetchTimeLogs={refetchTimeLogs}
                />
              )}
            </div>
          </>
        )}
        <h4 className="fw-500 mt-30">{translate('resolution')}</h4>

        <Form
          layout="vertical"
          form={form}
          validateMessages={{
            // eslint-disable-next-line
            required: '${label} is required',
          }}
          onFinish={handleSubmit}
          onValuesChange={checkValues}
        >
          <FormItemGeneric<TFormValues>
            name="resolution_code"
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('resolution_code')}
          >
            <Select
              e2e-test="resolution-code-select"
              disabled={initialValuesState}
              getPopupContainer={e => e.parentNode}
            >
              {RESOLUTION_CODE_VALUES.map((codeValue, index) => (
                <Option key={index} value={codeValue.value}>
                  {codeValue.name}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>
          <FormItemGeneric<TFormValues>
            name="resolution_notes"
            label={translate('resolution_notes')}
            rules={[
              {
                required: true,
                validator: () =>
                  checkContent(translate('resolution_notes_req')),
              },
            ]}
          >
            <EditorWrapper>
              <Editor
                toolbar={draftToolbarOptions}
                editorState={editorState}
                wrapperClassName="wysiwyg-editor wysiwyg-editor--mb-0"
                onEditorStateChange={onEditorStateChange}
                readOnly={initialValuesState}
              />
            </EditorWrapper>
          </FormItemGeneric>
          {!initialValuesState && (
            <>
              {entityData && !isTimelogDisabled(entityData) && (
                <div>
                  <FormItemCustom
                    name="is_time_logs_reviewed"
                    initialValue={false}
                    valuePropName={'checked'}
                    rules={[
                      {
                        required: true,
                      },
                      () => ({
                        validator(_, value) {
                          if (value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(translate('this_field_is_required'))
                          )
                        },
                      }),
                    ]}
                  >
                    <Checkbox>
                      <div className="required">
                        <span>{translate('time_logs_reviewed')}</span>
                      </div>
                    </Checkbox>
                  </FormItemCustom>
                </div>
              )}
              <Tooltip
                placement="top"
                title={
                  isTimeLogOpened ? translate('timelog_must_be_closed') : null
                }
              >
                <span>
                  <Button
                    title={translate('resolve')}
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading || isTimeLogOpened}
                    e2e-test="resolve-btn"
                  />
                </span>
              </Tooltip>
            </>
          )}
        </Form>
      </Col>
    </Row>
  )
}

export default withErrorBoundary(ResolutionForm, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
