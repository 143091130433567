import {useEffect} from 'react'

import {useAuth} from 'react-oidc-context'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {
  getProfile,
  refreshBETokenReq,
  setAccessToken,
} from '@/redux/user/userActions'
import globalRoutes from '@/global-routes'
import {selectOrgId} from '@/redux/entries/entries-selector'
import {
  selectAuthFailed,
  selectBackEndTokenExpiry,
} from '@/redux/user/userSelector'
import {generateTokenExpiryInterval} from '@/utils/pasrse-dates/generate-token-expiry-interval'
import queryString from 'query-string'

import {useCustomAuth} from '@/hooks/use-custom-auth'
import {selectRedirectUrl} from '@/redux/ui/uiSelector'
import {setRedirection} from '@/redux/ui/uiActions'

import {TInviteQuery} from '@/typedef'
import {useQuery} from '@/utils/useQuery'
import {Flow} from '@/sites/account-default/typedef'
import {updateActiveCampaign} from '@/api/active-campaign-requests'

import {usePostDataGeneric} from './use-post-data-generic'
import {selectModuleProfile} from '@/redux/profile/profileSelector'
import {activeCampaignFieldsConfig} from '@/sites/services/constants/active-campaign-config'

import {orgInvitePath, userInvitePaths} from '@/constants'

export default function useInitialSettings() {
  const dispatch = useDispatch()
  const history = useHistory()
  const search = window.location.search
  const pathname = window.location.pathname
  const query = queryString.parse(search) as TInviteQuery &
    Record<string, string>
  const orgId = useSelector(selectOrgId)
  const beTokenExp = useSelector(selectBackEndTokenExpiry)
  const {accountId} = useSelector(selectModuleProfile)

  const isAuthFailed = useSelector(selectAuthFailed)
  const auth = useAuth()
  const redirectUrl = useSelector(selectRedirectUrl)

  const {signOutRedirect} = useCustomAuth()

  const flow = useQuery('flow')

  const {run: updateAC} = usePostDataGeneric<{
    passedArgs: {
      userSignupToBlits: number
    }
  }>(true)

  useEffect(() => {
    if (accountId && flow === Flow.AD_HOC) {
      updateAC(() =>
        updateActiveCampaign({
          field: activeCampaignFieldsConfig.userSignupToBlits,
          value: true,
        })
      )
    }
  }, [accountId, auth.isAuthenticated, flow, updateAC])

  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.access_token) {
      dispatch(setAccessToken({accessToken: auth.user.access_token}))
    }
  }, [auth.isAuthenticated, auth.user?.access_token, dispatch])

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      !(query?.grant || pathname === globalRoutes.general.accountDefault.path())
    ) {
      dispatch(getProfile())
    }
  }, [auth.isAuthenticated, dispatch, query?.grant])

  useEffect(() => {
    if (redirectUrl) {
      const parsedUrl = new URL(redirectUrl, window.location.origin)

      history.push({
        pathname: parsedUrl.pathname,
        ...(parsedUrl.search && {search: parsedUrl.search}),
      })
      dispatch(setRedirection(''))
    }
  }, [dispatch, history, redirectUrl])

  useEffect(() => {
    if (isAuthFailed) {
      signOutRedirect()
    }
  }, [isAuthFailed, signOutRedirect])

  useEffect(() => {
    /*
     * on success login `code` is passed and if page refreshes logs user out
     * */
    if (query.code) return history.push('/')
  }, [history, query.code])

  useEffect(() => {
    /*
     * User has an invitation flow
     */
    if (query?.grant) {
      if (orgInvitePath !== pathname && query?.channel_invite) {
        return history.push({
          pathname: globalRoutes.general.acceptOrgInvite.path(),
          search,
        })
      }

      if (!userInvitePaths.includes(pathname) && query?.org_id) {
        return history.push({
          pathname: globalRoutes.general.acceptInvitationITSM.path(),
          search,
        })
      }
    }
  }, [
    history,
    query?.grant,
    query?.channel_invite,
    query?.org_id,
    pathname,
    search,
  ])

  useEffect(() => {
    const interval = setInterval(() => {
      if (beTokenExp) {
        dispatch(refreshBETokenReq({id: orgId, scope: 'org'}))
      }
    }, generateTokenExpiryInterval(beTokenExp))

    return () => clearInterval(interval)
  }, [beTokenExp, dispatch, orgId])
}
