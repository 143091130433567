import {all, takeLatest} from 'redux-saga/effects'

import * as ActionTypes from '@/redux/actionTypes'
import {getSaasIdentityMeSaga} from '@/modules/SAAS/saga/get-saas-identity-me-saga'

function* watchGetIdentityMeSaga() {
  yield takeLatest(
    ActionTypes.FETCH_SAAS_IDENTITY_ME_REQUESTED,
    getSaasIdentityMeSaga
  )
}

export default function* rootSaga() {
  yield all([watchGetIdentityMeSaga()])
}
