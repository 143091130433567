import {lazy} from 'react'

import mainConfig from '@/global-routes'
import {TRouteData} from '@/typedef'
import withSuspense from '@/components/with-suspense/with-suspense'
import Home from '@/sites/home/home'
import FailedLogin from '@/sites/FailedLogin'
import checkIsMobile from '@/utils/check-is-mobile'
import {AcceptOrgInvite} from '@/sites/accept-org-invite/accept-org-invite'
import {FailedPayment} from '@/modules/Subscriptions/sites/failed-payment/failed-payment'
import SuccessLogin from '@/sites/success-login/SuccessLogin'
import {SuccessPayment} from '@/modules/Subscriptions/sites/success-payment/success-payment'
import {ProfileDefault} from '@/sites/profile/profile-default'
import {AccountDefault} from '@/sites/account-default/account-default'

const ITSMSCAcceptInvitationComponent = withSuspense(
  lazy(() =>
    import(
      /* webpackChunkName: "itsm-accept-invitation" */ '@/sites/accept-user-invite-modal/accept-user-invite-modal'
    )
  )
)

const ITSMSCAcceptInvitationComponentStepper = withSuspense(
  lazy(() =>
    import(
      /* webpackChunkName: "itsm-accept-invitation" */ '@/modules/ITSM/components/accept-invite/accept-invite-stepper/accept-invite-stepper-wrapper'
    )
  )
)

const OrgChannelDefaultComponent = withSuspense(
  lazy(() =>
    import(
      /* webpackChunkName: "org-channel-invitation" */ '@/sites/org-channel-default/org-channel-default'
    )
  )
)
const IdentityComponent = withSuspense(
  lazy(() =>
    import(
      /* webpackChunkName: "itsm-identity" */ '@/modules/ITSM/sites/identity/identity'
    )
  )
)

const CMDBModule = withSuspense(
  lazy(() =>
    import(/* webpackChunkName: "cmdb-module" */ '@/modules/CMDB/cmdb-resolver')
  )
)

const ITSMModule = withSuspense(
  lazy(() =>
    import(/* webpackChunkName: "itsm-module" */ '@/modules/ITSM/itsm-resolver')
  )
)

const SAASModule = withSuspense(
  lazy(() =>
    import(/* webpackChunkName: "saas-module" */ '@/modules/SAAS/saas-resolver')
  )
)

const SubscriptionsModule = withSuspense(
  lazy(() =>
    import(
      /* webpackChunkName: "subscriptions-module" */ '@/modules/Subscriptions/subscriptions-resolver'
    )
  )
)

const isMobile = checkIsMobile()

export const routes: Array<TRouteData> = [
  {
    component: ITSMModule,
    props: {
      path: mainConfig.moduleRoutes.itsm.path(),
    },
  },
  {
    component: CMDBModule,
    disabled: isMobile,
    props: {
      path: mainConfig.moduleRoutes.cmdb.path(),
    },
  },
  {
    component: SAASModule,
    props: {
      path: mainConfig.moduleRoutes.saas.path(),
    },
  },
  {
    component: SubscriptionsModule,
    props: {
      path: mainConfig.moduleRoutes.subscriptions.path(),
    },
  },
  {
    component: Home,
    props: {
      path: mainConfig.general.homePage.path(),
      exact: true,
      private: true,
    },
  },

  {
    // @ts-ignore needs to be refactored to ts
    component: SuccessLogin,
    props: {
      path: mainConfig.general.success.path(),
      exact: true,
    },
  },
  {
    component: FailedLogin,
    props: {
      path: mainConfig.general.failed.path(),
      exact: true,
    },
  },

  {
    component: FailedPayment,
    props: {
      path: mainConfig.general.paymentFailed.path(),
      exact: true,
    },
  },
  {
    component: ITSMSCAcceptInvitationComponent,
    props: {
      path: mainConfig.general.acceptInvitationITSM.path(),
      exact: true,
    },
  },
  {
    component: ITSMSCAcceptInvitationComponentStepper,
    props: {
      path: mainConfig.general.acceptInvitationITSMstepper.path(),
      exact: true,
    },
  },
  {
    component: AcceptOrgInvite,
    props: {
      path: mainConfig.general.acceptOrgInvite.path(),
      exact: true,
    },
  },
  {
    component: OrgChannelDefaultComponent,
    props: {
      path: mainConfig.general.orgChannelDefault.path(),
      exact: true,
      private: true,
    },
  },
  {
    component: IdentityComponent,
    props: {
      path: mainConfig.general.identity.path(),
      exact: true,
      private: true,
    },
  },
  {
    component: SuccessPayment,
    props: {
      path: mainConfig.general.paymentSuccess.path(),
      exact: true,
    },
  },
  {
    component: ProfileDefault,
    props: {
      path: mainConfig.general.profileDefault.path(),
      exact: true,
      private: true,
    },
  },
  {
    component: AccountDefault,
    props: {
      path: mainConfig.general.accountDefault.path(),
      exact: true,
    },
  },
]
