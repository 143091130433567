import React, {useEffect} from 'react'

import {InfoCircleOutlined} from '@ant-design/icons'

import {Button} from '@/components/button'
import history from '@/components/history/history'
import {translate} from '@/services/i18n'

import './error-page.scss'

type TProps = {
  mainErrorMsg?: string
  subErrorMsg?: string
  buttonTitle?: string
  redirectUrlOption?: string
  resolvers?: Array<() => void>
  noRedirect?: boolean
}

const ErrorPage: React.FC<TProps> = ({
  mainErrorMsg,
  subErrorMsg,
  buttonTitle,
  redirectUrlOption,
  resolvers,
  noRedirect,
}) => {
  const handleReset = () => {
    resolvers && resolvers.forEach(fn => fn && fn())
    if (!noRedirect) {
      redirectUrlOption ? history?.push(redirectUrlOption) : history?.back()
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      history?.back()
    }, 2000)
    return () => clearTimeout(timer)
  })

  return (
    <div className="error-page">
      <InfoCircleOutlined className="error-page__icon" />
      <h1>{translate('oops')}</h1>
      <p>{mainErrorMsg || translate('something_went_wrong')}</p>
      {subErrorMsg && <p>{subErrorMsg}</p>}
      <Button
        title={buttonTitle || translate('go_back')}
        type="primary"
        onClick={handleReset}
        size="large"
      />
    </div>
  )
}

export default ErrorPage
