import React from 'react'

import {Pagination as AntPagination, PaginationProps} from 'antd'

import './pagination.scss'

type TProps = Omit<PaginationProps, 'className'>

export const Pagination: React.FC<TProps> = props => {
  const {showSizeChanger = true} = props
  return (
    <AntPagination
      {...props}
      showLessItems={true}
      showSizeChanger={showSizeChanger}
      hideOnSinglePage={!showSizeChanger}
      showTotal={
        showSizeChanger
          ? (total: number, range: [number, number]) =>
              `${range[0]}-${range[1]} of ${total}`
          : undefined
      }
      className="pagination"
    />
  )
}
