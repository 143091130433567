import React from 'react'

import {Transfer, TransferProps} from 'antd'
import classNames from 'classnames'

import './transfer-for-modal.scss'

export const TransferForModal: React.FC<TransferProps<
  Record<string, any>
>> = props => {
  const {className, ...restProps} = props
  const transferClass = classNames('transfer-for-modal', className)
  return <Transfer className={transferClass} {...restProps} />
}
