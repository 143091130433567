import React from 'react'

import {Checkbox} from 'antd'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'

import './checkbox.scss'
import FormItemCustom from '../form/form-item-custom'
import {TKey, translate} from '@/services/i18n'

type TCheckbox = {
  title?: string
  onGroupValuesChange?: (checkedValues: CheckboxValueType[]) => void
  onSingleValueChange?: ((e: CheckboxChangeEvent) => void) | undefined
  options?: string[]
  checked?: boolean
  disabled?: boolean
  name?: string
}

export const CustomCheckbox = ({
  title,
  options,
  onGroupValuesChange,
  onSingleValueChange,
  checked,
  disabled,
  name,
}: TCheckbox) => {
  return (
    <FormItemCustom>
      <h6 className="checkbox-header">{title}</h6>
      {options ? (
        <Checkbox.Group options={options} onChange={onGroupValuesChange} />
      ) : (
        <div className="wrapper">
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onSingleValueChange}
          >
            {translate(name as TKey)}
          </Checkbox>
        </div>
      )}
    </FormItemCustom>
  )
}
