import React, {ReactNode} from 'react'

import SvgIcon from '@/components/svg-icon/svg-icon'

import './logo-wrapper.scss'

type TProps = {
  children: ReactNode
}

const LogoWrapper = ({children}: TProps) => {
  return (
    <div className="logo-wrapper">
      <SvgIcon name={'blits-logo'} className="logo-wrapper__logo" />
      {children}
    </div>
  )
}

export default LogoWrapper
