import {useCallback, useState} from 'react'

import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResSingle} from '@/api/response-typedf'

export function useGetSingleData<T extends Record<string, any>>(
  asyncF: ({
    id,
    selector,
  }: {
    id: string
    selector?: Record<string, any>
  }) => Promise<ISuperAgentResSingle<T>>
): {
  readonly getData: ({
    id,
    selector,
  }: {
    id: string
    selector?: Record<string, any>
  }) => Promise<T | undefined>
  readonly entityData: T | undefined
  readonly fetchStatus: RequestStatus
} {
  const [entityData, setEntityData] = useState<T>()

  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const getData = useCallback(
    async ({id, selector}: {id: string; selector?: Record<string, any>}) => {
      try {
        handleRequested()
        const {
          body: {result},
        } = await asyncF({id, selector})
        if (result) {
          setEntityData(result)
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      }
    },
    [asyncF, handleFail, handleRequested, handleSuccess]
  )

  return {
    getData,
    entityData,
    fetchStatus: status,
  }
}
