import {translate} from '@/services/i18n'
import {transformPrice} from '@/modules/Subscriptions/utils/transform-price'
import {Interval} from '@/modules/typedef'

export const formatPrice = (
  amount = 0,
  currency = 'usd',
  interval = Interval.YEAR
) =>
  amount
    ? transformPrice(amount / (interval === Interval.YEAR ? 12 : 1), currency)
    : translate('free')
