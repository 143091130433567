export const getNameAndExtension = (file?: string) => {
  if (file) {
    const splitName = file.split('.')
    const fileName = splitName.splice(0, splitName.length - 1).join()

    const extension = splitName[splitName.length - 1]

    return {fileName, extension}
  }
  return {}
}
