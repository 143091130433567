import {PublicPageLayout} from '@/components/layout/public-page-layout/public-page-layout'
import React from 'react'
import {Route} from 'react-router-dom'
import {publicRoutes} from '../public-routes'

export const generatePublicRoutes = () => {
  return publicRoutes.map(
    ({component: Component, props: {path, exact}}, index) => {
      const routeProps = {path, exact}

      return (
        <Route
          key={index}
          render={() => (
            <PublicPageLayout>
              <Component />
            </PublicPageLayout>
          )}
          {...routeProps}
        />
      )
    }
  )
}
