import moment from 'moment'

import {ItsmAssets} from '@/modules/ITSM/typedef'

export const defaultFilters = {
  [ItsmAssets.Identities]: {
    is_enabled: true,
  },
  [ItsmAssets.Announcements]: {
    $and: [
      {
        active_to: {$gte: moment().toISOString()},
      },
    ],
  },
}
