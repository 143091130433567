type TSAASUserActionsCreators = typeof import('./saas-user-actions')
type TActionCreators = TSAASUserActionsCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

export enum TActionTypes {
  RESET_SAAS_USER_STATE = 'RESET_SAAS_USER_STATE',
  FETCH_SAAS_IDENTITY_ME_SUCCESS = 'FETCH_SAAS_IDENTITY_ME_SUCCESS',
  FETCH_SAAS_USER_FAILED = 'FETCH_SAAS_USER_FAILED',
  SELECT_SAAS_NAMESPACE = 'SELECT_SAAS_NAMESPACE',
  ADD_SAAS_NAMESPACE = 'ADD_SAAS_NAMESPACE',
  SET_SAAS_NAMESPACES = 'SET_SAAS_NAMESPACES',
}
