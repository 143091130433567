import React from 'react'

import classNames from 'classnames'

import './accept-invite-step.scss'

type TProps = {
  active: boolean
  title: string
}

export const Step: React.FC<TProps> = ({active, title}: TProps) => {
  const blockClass = classNames('accept-invite-step', {
    'accept-invite-step--selected': active,
  })

  return (
    <div className={blockClass}>
      <div className="accept-invite-step__content">
        <span className="accept-invite-step__text">{title}</span>
      </div>
    </div>
  )
}
