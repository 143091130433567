import React, {FC} from 'react'
import {Paper} from '@/components/paper/paper'
import classnames from 'classnames'
import {PurchasePrice} from '@/sites/purchase/components/purchase-price/purchase-price'
import SvgIcon from '@/components/svg-icon/svg-icon'

import './purchase-module.scss'
import {parseSubscriptionsMessage} from '../../constants/parse-subscriptions-message'
import {ModuleFeatureFlags} from '../../typedef'

type TProps = {
  onClick?: () => void
  isSelected: boolean
  title: string
  icon?: string
  price: string
  pricingUnitMetadata?: string
  module: ModuleFeatureFlags
}

export const PurchaseModule: FC<TProps> = ({
  onClick,
  isSelected,
  title,
  icon,
  price,
  pricingUnitMetadata,
  module,
}) => {
  return (
    <Paper
      className={classnames('purchase-module', {
        'purchase-module--selected': isSelected,
      })}
      onClick={onClick}
    >
      {icon && <SvgIcon name={icon} className="purchase-module__icon " />}
      <div className="purchase-module__title text-center fw-500">{title}</div>
      <div className="purchase-module__price">
        <PurchasePrice
          price={price}
          pricingUnitLabel={
            pricingUnitMetadata || parseSubscriptionsMessage[module]
          }
        />
      </div>
    </Paper>
  )
}
