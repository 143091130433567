import React from 'react'

import classnames from 'classnames'
import {Layout as AntLayout} from 'antd'

import './header.scss'
import {TChildren} from '@/typedef'

type TProps = {
  className: string
} & TChildren

export const Header: React.FC<TProps> = ({className, ...props}) => {
  const classes = classnames('header', className)
  return <AntLayout.Header className={classes} {...props} />
}
