import React, {ReactNode} from 'react'

import classNames from 'classnames'

import LogoWrapper from '@/components/logo-wrapper/logo-wrapper'
import {Paper, PaperVariant} from '@/components/paper/paper'

import './profile-identity-wrapper.scss'

type TProps = {
  className?: string
  children: ReactNode
  header?: string
  subHeader?: string
  isFullWidth?: boolean
}

const ProfileIdentityWrapper = ({
  className,
  children,
  header,
  subHeader,
  isFullWidth,
}: TProps) => {
  const wrapperClass = classNames('profile-identity-wrapper__inner', {
    'profile-identity-wrapper__inner--full-width': isFullWidth,
  })

  return (
    <LogoWrapper>
      <Paper className={className} variant={PaperVariant.form}>
        <div className={wrapperClass}>
          <div className="profile-identity-wrapper__header">
            <h5>{header}</h5>
          </div>
          <div className="profile-identity-wrapper__subHeader">{subHeader}</div>
          {children}
        </div>
      </Paper>
    </LogoWrapper>
  )
}

export default ProfileIdentityWrapper
