import React, {useCallback, useEffect, useState} from 'react'

import {Col, Form, Row} from 'antd'
import isEqual from 'lodash/isEqual'
import {withErrorBoundary} from '@sentry/react'

import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import {setIncidentTabIsBlocked} from '@/modules/ITSM/components/incident/incident-reducer'
import ErrorPage from '@/components/error/error-page/error-page'

import {checkIfDisabled} from '../utils/check-if-disabled'
import {useStateContext} from '../../incident/incident-context'
import {TIncidentTask, TIncidentTaskReq} from '../typedf'
import {useGetPriorityValues} from '../../incident-k-request/hooks/useGetPriorityValues'
import IncidentAssignment from '../../incident/incident-assignment/incident-assignment'

import {getInitialValues} from './constants'

const IncidentTaskAssigneeStatusForm = () => {
  const [form] = Form.useForm()
  const {
    entityData,
    updateEntity,
    getData,
    isLoading,
    dispatch,
  } = useStateContext<TIncidentTask>()

  const [, setRefresh] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    state_id,
    supplier_product,
    customer_product,
    assignment_group,
    assigned_to,
  } = entityData || {}

  const isDisabled = state_id !== undefined && checkIfDisabled(state_id)

  useGetPriorityValues()

  const checkValues = useCallback(() => {
    entityData &&
      setBtnDisabled(
        isEqual(form.getFieldsValue(), getInitialValues(entityData))
      )
  }, [entityData, form])

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])

  const handleUpdateIncident = async (values: TIncidentTaskReq) => {
    const result = await updateEntity(values)

    if (result !== undefined) {
      setBtnDisabled(true)

      await getData(
        form.getFieldValue('assigned_to') !== entityData?.assigned_to
      )
      form.resetFields()
    }
  }

  return (
    <Form
      validateMessages={{
        // eslint-disable-next-line
        required: '${label} is required',
      }}
      onFinish={handleUpdateIncident}
      form={form}
      layout="vertical"
      onValuesChange={checkValues}
    >
      <IncidentAssignment
        form={form}
        setRefresh={() => setRefresh(Math.random())}
        isDisabled={isDisabled}
        supplierProduct={supplier_product}
        customerProduct={customer_product}
        assignmentGroup={assignment_group}
        assignedTo={assigned_to}
        stateId={state_id}
      />

      {!isDisabled && (
        <Row>
          <Col span={24} className="mb-30 mt-10">
            <Button
              title={translate('save')}
              disabled={isLoading || btnDisabled}
              htmlType="submit"
              type="primary"
              size="large"
            />
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default withErrorBoundary(IncidentTaskAssigneeStatusForm, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
