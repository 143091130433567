import React from 'react'

import {SkeletonItem} from '@/components/skeleton'
import {Paper} from '@/components/paper/paper'

import './statistics-card-skeleton.scss'

export const StatisticsCardSkeleton = () => {
  return (
    <Paper className="statistics-card-skeleton">
      <SkeletonItem
        className="statistics-card-skeleton__title"
        width={200}
        height={42}
      />
      <SkeletonItem
        className="statistics-card-skeleton__growth"
        width={250}
        height={18}
      />
      <div className="statistics-card-skeleton__tile-list">
        {Array.from(Array(2).keys()).map(key => (
          <SkeletonItem key={key} width={115} height={50} />
        ))}
      </div>
    </Paper>
  )
}
