import React, {useState} from 'react'

import {DownloadOutlined, UploadOutlined} from '@ant-design/icons'
import {Col, Row} from 'antd'
import {saveAs} from 'file-saver'
import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {translate} from '@/services/i18n'
import {Button} from '@/components/button'
import {Upload} from '@/components/upload'
import DataTable from '@/components/data-table/data-table'
import {TInvoiceBr} from '@/modules/ITSM/typedef'

import {getInvoice, postInvoice} from '../../api/billingReportsRequests'
import {extensionPicker} from '../../utils/Helpers'

type TTableDataItem = {
  org_display_name: string
  org_name: string
  ref_uuid: string
}

type TProps = {
  invoices?: Array<TInvoiceBr>
  uuid: string
  rehydrateBillingReport: () => void
}

const BillingReportInvoices: React.FC<TProps> = ({
  invoices = [],
  uuid,
  rehydrateBillingReport,
}) => {
  const [loadingInvoice, setLoadingInvoice] = useState(false)
  const [loadingState, setLoading] = useState<Record<string, boolean>>({})

  const dispatch = useDispatch()

  const getColumns = () => {
    return [
      {
        title: translate('organization'),
        dataIndex: 'org_display_name',
        width: '40%',
      },
      {
        title: translate('ref'),
        dataIndex: 'ref_uuid',
        width: '40%',
      },
      {
        title: translate('download'),
        width: '20%',
        render: (value: TTableDataItem, _: TTableDataItem, index: number) => {
          return (
            <Button
              title={translate('download')}
              icon={<DownloadOutlined />}
              loading={loadingState[`loading${index}`]}
              className="download-btn"
              onClick={() => handleDownload(value, index)}
            />
          )
        },
      },
    ]
  }

  const handleSubmit = async (file: string | Blob) => {
    const formData = new FormData()
    formData.append('file', file)
    if (typeof file !== 'string') formData.append('type', file.type)
    try {
      setLoadingInvoice(true)

      await postInvoice(uuid, formData)

      rehydrateBillingReport()
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    } finally {
      setLoadingInvoice(false)
    }
  }

  const handleDownload = async (value: TTableDataItem, index: number) => {
    try {
      setLoading({[`loading${index}`]: true})

      const res = await getInvoice(value.ref_uuid)
      const typeFile = extensionPicker(res.type)
      const file = new Blob([res.body], {
        type: res.type,
      })
      saveAs(file, value.org_display_name + '.' + typeFile)
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    } finally {
      setLoading({[`loading${index}`]: false})
    }
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Upload
            accept=".doc,.docx,.txt, .pdf, .xls, .xlsx,.jpg, .jpeg, .png, .svg, .zip, .eml, .msg, .csv"
            customRequest={option => {
              handleSubmit(option.file)
            }}
            fileList={[]}
          >
            <Button
              type="primary"
              className="ml-10"
              disabled={loadingInvoice}
              title={`${translate('import')} ${translate('invoice')}`}
              icon={<UploadOutlined />}
              size="large"
            />
          </Upload>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <DataTable
            loading={loadingInvoice}
            data={invoices}
            rowKey="ref_uuid"
            columns={getColumns()}
            rowClassName="pointer-cursor"
            scroll={{x: '100%'}}
          />
        </Col>
      </Row>
    </>
  )
}

export default BillingReportInvoices
