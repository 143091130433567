import React from 'react'
import './skeleton.scss'

type TProps = {
  view: React.ReactElement
  active: boolean
  className?: string
  children: React.ReactNode
}

export const Skeleton: React.FC<TProps> = ({
  active,
  view,
  children,
  className,
}) => <div className={className}>{active ? view : children}</div>
