import * as ActionTypes from '../actionTypes'

export const getVersionReq = () => ({
  type: ActionTypes.FETCH_VERSION_REQUESTED,
})

export const getVersionSuccess = (version: number | string) => ({
  type: ActionTypes.FETCH_VERSION_SUCCESS,
  payload: version,
})

export const getVersionFailed = (err: any) => ({
  type: ActionTypes.FETCH_VERSION_FAILED,
  payload: err,
})

export const setRedirection = (url: string) => ({
  type: ActionTypes.SET_REDIRECTION,
  payload: url,
})

export const clearUIState = () => ({
  type: ActionTypes.RESET_UI_STATE,
})

export const setRedirectToList = (redirect = false) => ({
  type: ActionTypes.SET_REDIRECT_TO_LIST,
  payload: redirect,
})

export const setTimeSessionModal = (data: any) => {
  return {
    type: ActionTypes.SET_TIME_SESSION_MODAL,
    payload: data,
  }
}

export const clearUiModalMessage = () => ({
  type: ActionTypes.CLEAR_UI_MODAL_MESSAGE,
})

export const setHistoryBlock = (isBlocked: boolean) => ({
  type: ActionTypes.SET_HISTORY_BLOCK,
  payload: isBlocked,
})
