import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TUserGroup} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'

import UserGroupsNewWrapper from './user-groups-new-wrapper'
import UserGroupsDetailWrapper from './user-groups-detail-wrapper'

const UserGroups = () => (
  <ItsmListTable<TUserGroup>
    asset={ItsmAssets.UserGroups}
    newComponent={<UserGroupsNewWrapper />}
    detailComponent={<UserGroupsDetailWrapper />}
    drawerTitleNew={translate('user_group')}
    rowKey={'uuid'}
  />
)

export default UserGroups
