export const requiredEditFields = [
  'email',
  'caller',
  'short_description',
  'contact',
  'customer_product',
  'requestedType',
  'requestedEta',
]

export enum EventsQuickFilters {
  All = 'all',
  Approved = 'approved',
  NotApproved = 'not-approved',
}

export const eventsOptions: {
  [key in EventsQuickFilters]: {endpoint: string}
} = {
  [EventsQuickFilters.All]: {endpoint: '/incidents'},
  [EventsQuickFilters.Approved]: {
    endpoint: '/incidents-approved',
  },
  [EventsQuickFilters.NotApproved]: {
    endpoint: '/incidents-not-approved',
  },
}
