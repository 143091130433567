import {TAssetQuickFilters, TQuickFilters} from '@/modules/ITSM/typedef'

export const getQuickFilter = (
  quickFilter: TQuickFilters,
  assetQuickFilters: TAssetQuickFilters
) => {
  const {query} =
    assetQuickFilters.find(({name}) => name === quickFilter) ||
    assetQuickFilters[0]
  return query
}
