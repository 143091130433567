import {useCallback, useRef, useLayoutEffect} from 'react'

export function useSafeDispatch(dispatch) {
  const mountedRef = useRef(false)

  useLayoutEffect(() => {
    mountedRef.current = true
    return () => (mountedRef.current = false)
  })

  return useCallback(
    (...args) => (mountedRef.current ? dispatch(...args) : void 0),
    [dispatch]
  )
}
