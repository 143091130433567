import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import arrayMove from 'array-move'
import {Drawer, Table} from 'antd'

import DataTable from '@/components/data-table/data-table'
import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import {TAppState} from '@/redux/store'
import {TableColumnsCustomizer} from '@/components/table-columns-customizer/table-columns-customizer'
import {TKey, translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {SortingKeys, SortingValues} from '@/typedef'
import {useQuery} from '@/utils/useQuery'
import ListTableHeader from '@/components/list-table-header/list-table-header'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import ErrorPage from '@/components/error/error-page/error-page'
import {useGenerateInputFiler} from '@/modules/ITSM/components/itsm-list-table/hooks/use-generate-input-filter'
import {
  generateActiveColumns,
  normalizeQueryColumns,
} from '@/modules/ITSM/components/itsm-list-table/utils/generate-active-columns'
import {itsmSorters} from '@/modules/ITSM/components/itsm-list-table/constants/filters-config'
import {createStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import {getNewAssetTitle} from '@/modules/ITSM/components/itsm-list-table/utils/get-new-asset-title'
import {usePrevious} from '@/hooks/usePrevious'
import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'

import {TPagination} from '../../../store/list-table/reducer'
import {ItsmAssets, TCategory} from '../../../typedef'
import {
  selectItsmAssetPagination,
  selectItsmTableSorters,
  selectItsmTableColumns,
  selectItsmTableFilters,
} from '../../../store/list-table/selectors'
import {
  resetItsmTableFilter,
  resetItsmTableFilters,
  setItsmTableColumns,
  setItsmTableFilters,
  setItsmTableSorters,
} from '../../../store/list-table/table-actions'

import {useGetCategories} from './hooks/use-get-categories'
import CategoriesDrawer from './category-drawer'
import {replacerFunc} from './utils'
import './categories.scss'
import {CategoriesRowValue} from './categories-row-value'

export const ListTableCotext = createStateContext()

const asset = ItsmAssets.Categories

const CategoriesListTable = () => {
  const dispatch = useDispatch()

  const [previewMode, setPreviewMode] = useState(false)

  const [drawerNew, setDrawerNew] = useState(false)
  const [drawerDetail, setDrawerDetail] = useState<TCategory | undefined>()

  const {
    getEntityData,
    entityData,
    numberOfRecords,
    fetchStatus,
  } = useGetCategories()

  const prevEntityData = usePrevious(entityData)

  useEffect(() => {
    if (
      JSON.stringify(prevEntityData, replacerFunc()) !==
        JSON.stringify(entityData, replacerFunc()) &&
      drawerDetail?.uuid
    ) {
      setDrawerDetail(entityData.find(({uuid}) => uuid === drawerDetail.uuid))
    }
  }, [entityData, drawerDetail, prevEntityData])

  const filtersQuery = useQuery('filters')
  const sortQuery = useQuery('sort')
  const columnsQuery = useQuery('columns')

  useEffect(() => {
    if (filtersQuery || sortQuery || columnsQuery) {
      setPreviewMode(true)
    }
  }, [columnsQuery, filtersQuery, sortQuery])

  const pagination = useSelector((state: TAppState) =>
    selectItsmAssetPagination(state, asset)
  )

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters(state, asset)
  )

  const handleNew = () => {
    setDrawerNew(true)
  }

  const {
    generatedFilters,
    inputValues,
    resetFilter,
    resetAllFilters,
  } = useGenerateInputFiler<TCategory>(asset)

  const handleSorter = (sorter: Record<SortingKeys, SortingValues> | null) => {
    dispatch(setItsmTableSorters(asset, sorter))
  }

  const handleResetFilters = (asset: ItsmAssets) => {
    dispatch(resetItsmTableFilters(asset))
    resetAllFilters()
  }

  const onSearch = () => {
    dispatch(setItsmTableFilters(asset, inputValues))
  }

  const onReset = (key: string) => {
    dispatch(resetItsmTableFilter(asset, key))
    resetFilter(key)
  }

  const handleActiveCols = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const movedItems = arrayMove(columns, oldIndex, newIndex)
      dispatch(setItsmTableColumns(asset, movedItems))
    }
  }

  const handleSetSelectedColumn = (selectedTitle: string, isAdded: boolean) => {
    const dataWithSelectedColumn = columns.map(data => {
      return data.title === selectedTitle ? {...data, selected: isAdded} : data
    })

    dispatch(setItsmTableColumns(asset, dataWithSelectedColumn))
  }

  const handleRowClick = (record: TCategory) => {
    setDrawerDetail(record)
  }

  const handleOnclose = () => {
    if (drawerNew) setDrawerNew(false)
    else setDrawerDetail(undefined)
  }

  const draggableData = columns.map(column => ({
    title: column.title as TKey,
    isChecked: column.selected,
  }))

  const getExpandedRowRender = (record: TCategory) => {
    return (
      <Table<TCategory>
        columns={generateActiveColumns({
          columns: previewMode
            ? normalizeQueryColumns(columnsQuery || '', asset)
            : columns,
          asset,
          getRowValue: handleGetRowValue,
        })}
        className="pointer-cursor"
        dataSource={record.subcat}
        pagination={false}
        rowKey={record => record.uuid}
        key="uuid"
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
      />
    )
  }

  const handleGetRowValue = ({title, value}: TColumnRender<TCategory>) => (
    <CategoriesRowValue title={title} value={value} />
  )

  const filterSelector = {
    ...((sortQuery || sorter) && {
      passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
    }),
    ...((filtersQuery || filters) && {
      passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
        ...filters,
      },
    }),
  }
  return (
    <>
      <SentryErrorBoundary
        fallback={
          <ErrorPage
            resolvers={[handleOnclose]}
            buttonTitle={translate('close')}
            noRedirect={true}
          />
        }
      >
        <ListTableCotext.Provider
          value={{
            getEntityData: () => {
              getEntityData({
                passedBookmark:
                  filters && Object.keys(filters).length
                    ? undefined
                    : pagination.bookmark,
                resetBookmarks: !!(filters && Object.keys(filters).length > 0),
                ...filterSelector,
              })
            },
            getEntityDataInit: () => {
              handleResetFilters(asset)
              getEntityData({
                passedBookmark: undefined,
                resetBookmarks: true,
              })
            },
            resetPagination: () => dispatch(resetItsmTableFilters(asset)),
            onClose: handleOnclose,
            record: drawerDetail,
            asset,
          }}
        >
          {(drawerNew || !!drawerDetail) && (
            <Drawer
              className="list-table-drawer"
              title={
                drawerDetail?.name || getNewAssetTitle(translate('category'))
              }
              onClose={handleOnclose}
              open={drawerNew || !!drawerDetail}
            >
              <CategoriesDrawer />
            </Drawer>
          )}
        </ListTableCotext.Provider>
      </SentryErrorBoundary>

      <ListTableHeader<ItsmAssets>
        title={asset}
        asset={asset}
        previewMode={previewMode}
        handleNew={handleNew}
        setSorting={handleSorter}
        generatedFilters={generatedFilters}
        resetFilters={handleResetFilters}
        {...(sorter && {sorter})}
        onSearch={onSearch}
        onReset={onReset}
        moduleSorters={itsmSorters}
        tableCustomizer={
          <TableColumnsCustomizer
            items={draggableData}
            updateItemOrder={handleActiveCols}
            handleActiveColumns={handleSetSelectedColumn}
            previewMode={previewMode}
          />
        }
      />
      <DataTable<TCategory>
        className="categories-table"
        data={entityData}
        columns={generateActiveColumns({
          columns: previewMode
            ? normalizeQueryColumns(columnsQuery || '', asset)
            : columns,
          asset,
          getRowValue: handleGetRowValue,
        })}
        rowKey="uuid"
        onRowClick={handleRowClick}
        loading={checkIsLoading(fetchStatus)}
        expandable={{
          expandedRowRender: getExpandedRowRender,
          rowExpandable: record => !!record.subcat,
        }}
        customPager={
          <PaginationWithBookmarks<TPagination<TCategory>>
            pagination={pagination}
            numberOfRecords={numberOfRecords}
            fetch={bookmark => {
              getEntityData({
                passedBookmark: bookmark,
                ...filterSelector,
              })
            }}
          />
        }
      />
    </>
  )
}

export default CategoriesListTable
