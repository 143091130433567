import {TActionMap} from '@/typedef'
import {InvitationStep} from '@/sites/accept-org-invite/typedef'
import {createContext, Dispatch} from 'react'

enum ContextType {
  SET_TOKEN = 'SET_TOKEN',
  SET_INVITE_CHANNELS = 'SET_INVITE_CHANNELS',
  SET_INVITE_STEP = 'SET_INVITE_STEP',
}

type TInitialState = {
  accessToken: string
  inviteChannels: Array<string>
  inviteStep: InvitationStep
}
export const orgInviteInitialState: TInitialState = {
  accessToken: '',
  inviteChannels: [],
  inviteStep: InvitationStep.ACCEPT_INVITE,
}

export const setInviteOrgToken = (token: string) =>
  ({
    type: ContextType.SET_TOKEN,
    payload: token,
  } as const)

export const setInviteOrgChannels = (channels: Array<string>) =>
  ({
    type: ContextType.SET_INVITE_CHANNELS,
    payload: channels,
  } as const)

export const setInviteOrgStep = (step: InvitationStep) =>
  ({
    type: ContextType.SET_INVITE_STEP,
    payload: step,
  } as const)

type TInviteOrgPayload = {
  [ContextType.SET_TOKEN]: string
  [ContextType.SET_INVITE_CHANNELS]: Array<string>
  [ContextType.SET_INVITE_STEP]: InvitationStep
}

export type TInviteOrgActionTypes = TActionMap<
  TInviteOrgPayload
>[keyof TActionMap<TInviteOrgPayload>]

export const orgInviteReducer = (
  state = orgInviteInitialState,
  action: TInviteOrgActionTypes
) => {
  switch (action.type) {
    case ContextType.SET_TOKEN:
      return {...state, accessToken: action.payload}
    case ContextType.SET_INVITE_CHANNELS:
      return {...state, inviteChannels: action.payload}
    case ContextType.SET_INVITE_STEP:
      return {...state, inviteStep: action.payload}
    default:
      return state
  }
}

export const InviteOrgContext = createContext<{
  state: TInitialState
  dispatch: Dispatch<TInviteOrgActionTypes>
}>({
  state: orgInviteInitialState,
  dispatch: () => null,
})
