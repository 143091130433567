import React, {useCallback} from 'react'

import {v4 as uuid} from 'uuid'

import {AssignedToMark} from '@/components/assigned-to-mark/assigned-to-mark'
import {Block, BlockSkeleton} from '@/components/block'
import {CustomCheckIcon} from '@/components/custom-check-icon/custom-check-icon'
import {setCurrentStep} from '@/modules/ITSM/components/incident/incident-reducer'
import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {Icon, IconType} from '@/components/icon'
import {Skeleton} from '@/components/skeleton'

import {TIncident} from '../../incident-k-request/typedf'

import './ticket-wrapper-steps.scss'

const TicketWrapperStepsSkeletonView = () => (
  <>
    {new Array(5).fill(1).map(() => (
      <BlockSkeleton key={uuid()} />
    ))}
  </>
)

type TProps = {
  steps: {
    activeStep: number | undefined
    stepFields: string[]
    blockedFields: number[]
    checkedFields: number[]
  }
  setTab: ({
    step,
    assignee_state,
    state_id,
  }: {
    step: string
    assignee_state: string
    state_id: number
  }) => string
}

const TicketWrapperSteps = ({
  steps: {activeStep, stepFields, blockedFields, checkedFields},
  setTab,
}: TProps) => {
  const {incState, entityData, dispatch} = useStateContext<TIncident>()

  const assignedTo = entityData?.assigned_to
  const assignedToName =
    assignedTo?.name && `${assignedTo.name} ${assignedTo.surname}`

  const {currentStep} = incState

  const onStepChangeAction = useCallback(
    (step: string) => {
      entityData &&
        dispatch(
          setCurrentStep({
            step,
            currentTab: setTab({
              step,
              assignee_state: entityData.assignee_state,
              state_id: entityData.state_id,
            }),
          })
        )
    },
    [dispatch, entityData, setTab]
  )

  const onStepChange = useCallback(
    (index: number) => {
      const tabHasChanged = index.toString() !== currentStep

      if (tabHasChanged) {
        onStepChangeAction(index.toString())
      }
    },
    [currentStep, onStepChangeAction]
  )

  return (
    <Skeleton
      className="ticket-wrapper-steps"
      active={stepFields.length === 0}
      view={<TicketWrapperStepsSkeletonView />}
    >
      {stepFields.map((field, index) => {
        const isActiveField = activeStep === index
        const isCursor =
          (activeStep && index < activeStep) ||
          (activeStep !== index && !blockedFields.includes(index))
        const isSelected =
          !!incState.currentStep &&
          (Number(incState.currentStep) === index || isActiveField)
        const onClick = () => {
          if (!blockedFields.includes(index) && entityData) {
            onStepChange(index)
          }
        }
        return (
          <Block
            key={index}
            active={isActiveField}
            cursor={isCursor}
            selected={isSelected}
            onClick={onClick}
          >
            {blockedFields.includes(index) && <Icon type={IconType.LOCK} />}
            {index === 1 && assignedToName ? (
              <AssignedToMark name={assignedToName} />
            ) : (
              checkedFields.includes(index) && <CustomCheckIcon />
            )}
            <span className="ticket-wrapper-steps__text">{field}</span>
          </Block>
        )
      })}
    </Skeleton>
  )
}
export default TicketWrapperSteps
