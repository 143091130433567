import React from 'react'

import moment from 'moment'

import {generateTitle} from '@/utils/table/generate-title'
import {DATE_FORMAT} from '@/constants'
import {TKey, translate} from '@/services/i18n'
import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {SortingKeys} from '@/typedef'

import {IExtendedColumnType, TColumnRender, TDataTablePreset} from '../typedf'
import {itsmColumnsDefaults} from '../constants/columns-defaults'
import {getBooleanOptions} from '../constants/filters-config'

type TProps<T> = {
  columns: TDataTablePreset
  asset: ItsmAssets
  dataResolved?: Record<string, Record<string, any>[]> | undefined
  getRowValue?: (props: TColumnRender<T>) => React.ReactNode
}

export const generateActiveColumns = <T extends Record<string, any>>({
  columns,
  asset,
  dataResolved,
  getRowValue,
}: TProps<T>): Array<IExtendedColumnType<T>> => {
  return columns.reduce((acc, {title, dataIndex, index, selected, options}) => {
    if (selected) {
      return acc.concat({
        title: (
          <div className="nowrap">
            {translate(title as TKey).includes('_')
              ? generateTitle(title)
              : parseToUpperCase(translate(title as TKey))}
          </div>
        ),
        index,
        key: title,
        dataIndex,
        selected,
        render: (value: string | number, record: T) => {
          if (
            itsmColumnsDefaults?.[asset]?.find(
              ({title: columnTitle}) => columnTitle === title
            )?.advancedRender &&
            getRowValue
          )
            return (
              <div>
                {getRowValue({
                  title,
                  value,
                  record,
                })}
              </div>
            )

          if (title in SortingKeys)
            return (
              <div className="nowrap">
                {value && moment(value).format(DATE_FORMAT)}
              </div>
            )

          if (options) return options[Number(value)]

          if (dataResolved?.[title]) {
            return (
              dataResolved[title].find(data => data.value === value)?.name ||
              value
            )
          }

          if (getBooleanOptions<T>()?.[asset]?.[title])
            return parseToUpperCase((!!value).toString())

          return value ? (
            <div className="nowrap">
              {value.toString().length > 30
                ? value
                    .toString()
                    .substring(0, 30)
                    .trim() + '...'
                : value}
            </div>
          ) : (
            ''
          )
        },
      })
    }

    return acc
  }, [] as Array<IExtendedColumnType<T>>)
}

export const normalizeQueryColumns = (
  columnsQuery: string,
  asset: ItsmAssets
): TDataTablePreset => {
  const columnsQueryArr = columnsQuery.split(',')

  const columnsDef: TDataTablePreset = itsmColumnsDefaults[asset]

  return columnsDef
    .filter(col => columnsQueryArr.indexOf(col.title) !== -1)
    .map(col => ({
      ...col,
      selected: true,
    }))
}
