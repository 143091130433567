import {useCallback, useEffect, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'

import {TTimeLog} from '../../../typedef'
import {getTimeLog} from '../../../api/timelogsRequests'

type TPropsGetTimelog = {
  id: string
}

export const useGetTimelog = ({id}: TPropsGetTimelog) => {
  const [timelog, setTimelog] = useState<TTimeLog | undefined>()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const loadData = useCallback(
    async (id: string) => {
      handleRequested()
      try {
        const {
          body: {result: timeLogRes},
        } = await getTimeLog(id)

        setTimelog(timeLogRes)

        handleSuccess()
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  useEffect(() => {
    if (!timelog) loadData(id)
  }, [loadData, timelog, id])

  return {fecthTimeLog: (id: string) => loadData(id), timelog, status}
}
