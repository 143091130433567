import React from 'react'

import classNames from 'classnames'

import {IconType} from './typedef'

import './assets/css/fontello.css'
import './assets/css/animation.css'
import './icon.scss'

type TProps = {
  type: IconType
  size?: number
  className?: string
  onClick?: () => void
}

const toRem = (size: number) => {
  return `${size / 16}rem`
}

const DEFAULT_SIZE = 16

export const Icon: React.FC<TProps> = props => {
  const {type, className, size, onClick} = props
  const iconSize = size || DEFAULT_SIZE
  const animation = type === IconType.LOADING && 'animate-spin'
  const classes = classNames('icon', type, animation, className)

  return (
    <span
      style={{fontSize: toRem(iconSize)}}
      className={classes}
      onClick={onClick}
    />
  )
}
