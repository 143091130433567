import {useCallback, useEffect, useReducer} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {selectEmptySpace} from '@/redux/user/userSelector'
import {RESOLVE} from '@/constants'
import {mergeBookmarks} from '@/utils/merge-bookmarks'
import {RequestStatus, TListArgs, TListOptions} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TAppState} from '@/redux/store'
import {usePrevious} from '@/hooks/usePrevious'
import {useQuery} from '@/utils/useQuery'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {
  getDataFailed,
  getDataRequested,
  getDataSuccess,
  initialState,
  itsmDataTableReducer,
  resetDataState,
  resetFetchDataStatus,
  setBookmark,
  setFetchMoreRecords,
  setNumberOfRecords,
} from '@/modules/ITSM/reducers/itsm-data-table-reducer'
import {useSafeDispatch} from '@/hooks/useSafeDispatch'
import {
  selectItsmAssetPagination,
  selectItsmTableFilters,
  selectItsmTableSorters,
} from '@/modules/ITSM/store/list-table/selectors'
import {
  resetItsmTableFilters,
  setItsmTableBookmarks,
} from '@/modules/ITSM/store/list-table/table-actions'
import {noPermission} from '@/modules/ITSM/utils/Constants'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {RequestStatusCode} from '@/components/error/typedef'

import {prepareSelector} from '../../utils/prepare-selector'
import {useGetFilterSearchIds} from '../../hooks/use-get-search-selector-ids'

import {useGetDefaultFilters} from './use-get-default-filters'

export function useGetDataListTable<T extends Record<string, any>>(
  asyncF: ({
    passedBookmark,
    selector,
    resolve,
    sort,
  }: TListOptions<T>) => Promise<ISuperAgentResMultiple<T>>,
  asset: ItsmAssets,
  selectorPassed?: Record<string, any>
): {
  readonly getEntityData: ({
    passedBookmark,
    passedSorter,
    resetBookmarks,
    passedFilters,
  }: TListArgs<T>) => Promise<T[] | undefined>
  readonly entityData: T[]
  readonly fetchStatus: RequestStatus
  readonly numberOfRecords: number
  readonly selectorIds: Record<string, unknown>
  readonly setInitSelectorIds: (name?: string, asset?: ItsmAssets) => void
} {
  const [state, unsafeDispatch] = useReducer(itsmDataTableReducer, initialState)
  const dispatch = useSafeDispatch(unsafeDispatch)

  const filtersQuery = useQuery('filters')
  const sortQuery = useQuery('sort')

  const {data, numberOfRecords, fetchMoreRecords, status, bookmark} = state
  const paginationAsset =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const prevSorter = usePrevious(sorter)
  const prevFilters = usePrevious(filters)

  const isEmptySpace = useSelector(selectEmptySpace)

  const defaultFilters = useGetDefaultFilters(asset)

  const {
    selectorIds,
    status: selectorIdsStatus,
    setInitSelectorIds,
    getFilterIds,
    getFilterProperty,
  } = useGetFilterSearchIds(asset)

  const prevSelectorIdsStatus = usePrevious(selectorIdsStatus)

  const rdxDispatch = useDispatch()

  const getEntityData = useCallback(
    async ({
      passedBookmark,
      passedSorter,
      resetBookmarks,
      passedFilters,
    }: TListArgs<T>) => {
      try {
        dispatch(getDataRequested())
        const {
          body: {result, bookmark},
        } = await asyncF({
          passedBookmark,
          resolve: fetchMoreRecords ? '' : RESOLVE,
          ...(passedSorter && {sort: [passedSorter]}),
          ...((passedFilters || defaultFilters || selectorPassed) && {
            selector: {
              ...prepareSelector<T>({
                inputValues: {
                  ...passedFilters,
                  ...defaultFilters,
                },
                asset,
              }),
              ...selectorPassed,
            },
          }),
        })

        if (result) {
          if (fetchMoreRecords) {
            dispatch(setFetchMoreRecords(false))
            dispatch(setNumberOfRecords(result.length))
            dispatch(setBookmark(undefined))
          } else if (!fetchMoreRecords) {
            if (
              result.some(
                e => e.error !== undefined && e.error.includes(noPermission)
              )
            ) {
              rdxDispatch(
                setToastMessage({
                  message: {
                    status: RequestStatusCode.STATUS_200,
                    message: noPermission,
                  },
                })
              )

              rdxDispatch(resetItsmTableFilters(asset))
              dispatch(getDataFailed(result))
              dispatch(resetDataState())

              return
            }

            const bookmarks = mergeBookmarks(
              bookmark,
              paginationAsset?.bookmarks,
              resetBookmarks
            )

            dispatch(getDataSuccess(result, result.length))
            rdxDispatch(
              setItsmTableBookmarks({
                asset,
                bookmarks,
                bookmark: passedBookmark,
              })
            )
            if (result.length === 10) {
              dispatch(setBookmark(bookmark))
              dispatch(setFetchMoreRecords(true))
            }

            return result
          }
          return undefined
        }
      } catch (err) {
        rdxDispatch(resetItsmTableFilters(asset))
        rdxDispatch(setToastMessage({message: err}))
        dispatch(getDataFailed(err))
        dispatch(resetDataState())
      } finally {
        dispatch(resetFetchDataStatus())
      }
    },
    [
      asset,
      asyncF,
      defaultFilters,
      dispatch,
      fetchMoreRecords,
      paginationAsset?.bookmarks,
      rdxDispatch,
      selectorPassed,
    ]
  )

  useEffect(() => {
    if (fetchMoreRecords && bookmark) {
      getEntityData({
        passedBookmark: bookmark,
        ...((sortQuery || sorter) && {
          passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
        }),
        ...((filtersQuery || filters || selectorIds) && {
          passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
            ...filters,
            ...selectorIds,
          },
        }),
      })
    }
  }, [fetchMoreRecords, bookmark, sorter, filters, sortQuery, filtersQuery])

  const prevselectorIds = usePrevious(selectorIds)
  useEffect(() => {
    if (
      prevSelectorIdsStatus === RequestStatus.REQUESTED &&
      selectorIdsStatus === RequestStatus.SUCCEEDED
    ) {
      ;(async () => {
        if (data) handleGetEntityData(selectorIds)
        else handleGetEntityDataInit(selectorIds)
      })()
    }
  }, [
    asset,
    prevSelectorIdsStatus,
    selectorIds,
    selectorIdsStatus,
    data,
    prevselectorIds,
  ])

  const handleGetEntityData = useCallback(
    (selectorIdsPassed?: Record<string, unknown>) => {
      getEntityData({
        passedBookmark: undefined,
        resetBookmarks: true,
        passedSorter: sorter,
        passedFilters: {
          ...filters,
          ...(selectorIdsPassed || selectorIds),
        },
      })
    },
    [filters, sorter, selectorIds]
  )

  const handleGetEntityDataInit = useCallback(
    (selectorIdsPassed?: Record<string, unknown>) => {
      getEntityData({
        passedBookmark:
          (paginationAsset.bookmarks || []).length > 0 &&
          !sortQuery &&
          !filtersQuery
            ? paginationAsset.bookmark
            : undefined,
        passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
        passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
          ...filters,
          ...(selectorIdsPassed || selectorIds),
        },
        resetBookmarks: !!(sortQuery || filtersQuery),
      })
    },
    [
      filters,
      filtersQuery,
      paginationAsset.bookmark,
      paginationAsset.bookmarks,
      sortQuery,
      sorter,
    ]
  )

  useEffect(() => {
    if (data) {
      if (
        JSON.stringify(prevSorter) !== JSON.stringify(sorter) ||
        JSON.stringify(prevFilters) !== JSON.stringify(filters)
      ) {
        if (filters && getFilterProperty()) getFilterIds()
        else handleGetEntityData()
      }
    }
  }, [asset, data, filters, prevFilters, prevSorter, sorter])

  useEffect(() => {
    if (isEmptySpace === null) {
      if (!data) {
        if (filters && getFilterProperty()) getFilterIds()
        else handleGetEntityDataInit()
      }
    }
  }, [asset, data, isEmptySpace, filters])

  return {
    getEntityData,
    entityData: data,
    numberOfRecords,
    fetchStatus: status,
    selectorIds,
    setInitSelectorIds,
  }
}
