import request from 'superagent'

import {getHeadersWithGRPC, grantApi} from '@/services/api'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import {getToken, invitationApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TOrgInviteReq} from '../sites/Invitation/org-invitation/typedf'
import {TInvitation} from '../typedef'

export const postAcceptInvite = async (id: string, auth: Record<string, any>) =>
  await request
    .post(invitationApi.acceptOrgInvite)
    .set(auth ? auth : getToken())
    .send({invite: id})

export const postGrant = async () => {
  return await request
    .post(grantApi.post)
    .set(getToken())
    .send({grantToken: localStorage.getItem('grant')})
}

export const postInvitation = async (data: TOrgInviteReq) =>
  await request
    .post(invitationApi.postInvitation)
    .set(getHeadersWithGRPC())
    .send(data)

export const patchInvitation = async <T extends Record<string, any>>(
  data: TInvitation,
  id: string
): Promise<ISuperAgentResSingle<T>> =>
  await request
    .patch(invitationApi.patch(id))
    .set(getHeadersWithGRPC())
    .send(data)

export const deleteInvitation = async (id: string) =>
  await request.delete(invitationApi.patch(id)).set(getHeadersWithGRPC())

export const postUserInvitation = async (data: Record<string, any>) =>
  await request
    .post(invitationApi.post)
    .set(getHeadersWithGRPC())
    .send(data)

export const getInvitations = async () => {
  const {
    body: {results},
  } = await request.get(invitationApi.get).set(getHeadersWithGRPC())

  return {body: {result: results}}
}
