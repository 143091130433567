import React from 'react'

import moment from 'moment'

import {DATE_FORMAT} from '@/constants'
import {TTimeLog} from '@/modules/ITSM/typedef'
import {getTimeInTimezone} from '@/modules/ITSM/utils/Helpers'
import {translate} from '@/services/i18n'

import {TTimeLogsCheck} from './typedf'

export const getTimeLogsUuid = (result: TTimeLogsCheck) => {
  return Object.values(result).reduce(
    (acc: Array<string>, item: Array<string> | null) => {
      if (item === null) item = []
      return acc.concat(item)
    },
    []
  )
}

export const getColumns = () => {
  return [
    {
      title: translate('check_in_time'),
      dataIndex: 'start',
      render: (val: string, record: TTimeLog) => {
        const productTimezone = record.customer_product?.sla?.timezone
        return (
          <div className="nowrap">
            {productTimezone
              ? getTimeInTimezone(val, productTimezone)
              : moment(val).format(DATE_FORMAT)}
          </div>
        )
      },
    },
    {
      title: translate('check_out_time'),
      dataIndex: 'end',
      render: (val: string, record: TTimeLog) => {
        const productTimezone = record.customer_product?.sla?.timezone

        return val !== undefined ? (
          <div className="nowrap">
            {productTimezone
              ? getTimeInTimezone(val, productTimezone)
              : moment(val).format(DATE_FORMAT)}
          </div>
        ) : null
      },
    },
  ]
}

export const getWorkTime = (timelogs: TTimeLog[]) => {
  return timelogs.reduce((acc, cur) => acc + (cur.work || 0), 0)
}
