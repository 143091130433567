import request from 'superagent'
import {
  getToken,
  itsmMySpacesApi,
  itsmOrganizationApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {THeaders} from '@/api/typedf'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {TItsmChannel} from '@/modules/ITSM/typedef'
import {TOrganization} from '@/typedef'

export const fetchMySpacesTS = async (
  headers: THeaders
): Promise<ISuperAgentResponse<{
  spaces: Array<TItsmChannel>
}>> => {
  return await request.get(itsmMySpacesApi().getSpaces).set(headers)
}

export const getOrgForAccountTS = async ({
  id,
  headers,
}: {
  id: string
  headers: THeaders
}): Promise<ISuperAgentResponse<Array<TOrganization>>> => {
  return await request
    .get(itsmOrganizationApi().getForAccount(id))
    .set(headers ? headers : getToken())
}
