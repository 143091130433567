import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {RESOLVE} from '@/constants'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {k_requestsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {
  TIncident,
  TIncidentReq,
  TIncidentRes,
} from '../components/incident-k-request/typedf'
import {TRequestsStats} from '../typedef'

export const getKrequests = async ({
  payloadBookmark,
  payloadSelector,
  payloadFields,
  payloadSort,
  resolve,
}: {
  payloadBookmark: string
  payloadSelector: string
  payloadFields: string
  payloadSort: string
  resolve: boolean
}): Promise<ISuperAgentResSingle<TIncident>> => {
  return await request
    .options(k_requestsApi().get + resolve)
    .set(getHeadersWithGRPC())
    .send({
      bookmark: payloadBookmark,
      selector: payloadSelector,
      fields: payloadFields,
      sort: payloadSort,
    })
}

export const fetchRequests = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
  fields,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(k_requestsApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
      fields,
    })

export const exportKrequests = async (
  query: Record<string, unknown>,
  fields: string[]
): Promise<ISuperAgentResSingle<TIncident>> => {
  return await request
    .options(k_requestsApi().getReports + RESOLVE)
    .send({
      selector: query,
      fields,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const postKRequest = async (
  data: TIncidentReq,
  id: string
): Promise<ISuperAgentResSingle<TIncidentRes>> => {
  return await request
    .post(k_requestsApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getKRequest = async (id: string) => {
  return await request
    .get(k_requestsApi(id).getOne)
    .set(getHeadersWithGRPC())
    .query({resolve: true})
}

export const patchKRequest = async (
  data: Partial<TIncidentReq>,
  id?: string
): Promise<ISuperAgentResSingle<TIncidentRes>> => {
  return await request
    .patch(k_requestsApi(id).getOne)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postCancelKRequest = async (data: {
  entity: string
  cancellation_notes: string
}) => {
  return await request
    .post(k_requestsApi().cancelIncident)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchRequestsStats = async (): Promise<ISuperAgentResSingle<
  TRequestsStats
>> =>
  await request
    .options(k_requestsApi().getRequestsStats)
    .set(getHeadersWithGRPC())
