import React, {useEffect, useMemo, useState} from 'react'

import {Collapse} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import classNames from 'classnames'

import {translate} from '@/services/i18n'
import {ModalType} from '@/components/modal/typedef'
import {Button} from '@/components/button/button'
import {Icon, IconType} from '@/components/icon'
import {clearToastMessage} from '@/redux/toast-message/toast-actions'
import {selectToastMessage} from '@/redux/toast-message/toast-selector'
import {copyToClipboard} from '@/components/modal/utils'
import {getErrorMessage} from '@/components/toast/utils/get-error-message'

import {getAlertType} from '../error/utils'

import './toast.scss'

const {Panel} = Collapse

const Toast: React.FC = () => {
  const dispatch = useDispatch()

  const [showMessage, setShowMessage] = useState(false)

  const {message: error} = useSelector(selectToastMessage)

  const handleCancel = () => {
    setShowMessage(false)
    dispatch(clearToastMessage())
  }

  const errorMessage = useMemo(
    () =>
      (error && error?.reponse?.body?.error?.message) ||
      error?.message ||
      error ||
      '',
    [error]
  )

  useEffect(() => {
    setShowMessage(!!errorMessage)
  }, [errorMessage])

  const alertType = error ? getAlertType(error) : ModalType.INFO

  const modalClass = classNames('toast', {
    'toast--is-visible': showMessage,
    'toast--info-type': alertType === ModalType.INFO,
    'toast--error-type': alertType === ModalType.ERROR,
    'toast--warning-type': alertType === ModalType.WARNING,
  })

  return (
    <Collapse className={modalClass} destroyInactivePanel>
      <Icon
        type={IconType.CLOSE}
        className={'toast__close-icon'}
        onClick={handleCancel}
      />

      <Panel
        key={'first'}
        className={'toast__panel'}
        header={
          <div className={'toast__message'}>
            <Icon
              type={IconType.FLAG}
              className={'toast__type-icon'}
              onClick={handleCancel}
              size={20}
            />
            {getErrorMessage(error)}
          </div>
        }
      >
        <div>
          <p>{translate('copy_error_msg')}</p>
          <Button
            title={translate('copy_error')}
            onClick={() => copyToClipboard(JSON.stringify(error))}
          />
        </div>
      </Panel>
    </Collapse>
  )
}

export default Toast
