import * as ActionTypes from '../actionTypes'
import {
  TAccountDefaultActionTypes,
  TInitialState,
} from '@/redux/entries/typedef'

const initialState: TInitialState = {
  accounts: [],
  channelName: '',
  orgName: '',
  orgID: '',
  channelID: '',
  cmdbNamespaceId: '',
  saasNamespaceId: '',
}

export default function entriesReducer(
  state = initialState,
  action: TAccountDefaultActionTypes
) {
  switch (action.type) {
    case ActionTypes.RESET_ENTRIES_REDUCER: {
      return initialState
    }

    case ActionTypes.SET_ENTRIES_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload,
      }
    }

    case ActionTypes.SET_ENTRIES_ITSM_CHANNEL: {
      return {
        ...state,
        channelName: action.payload.channelName,
        channelID: action.payload.channelID,
      }
    }

    case ActionTypes.SET_ENTRIES_CMDB_NAME_SPACE_ID: {
      return {
        ...state,
        cmdbNamespaceId: action.payload,
      }
    }

    case ActionTypes.SET_ENTRIES_SAAS_NAME_SPACE_ID: {
      return {
        ...state,
        saasNamespaceId: action.payload,
      }
    }

    case ActionTypes.SET_ENTRIES_ORG: {
      return {
        ...state,
        orgName: action.payload.orgName,
        orgID: action.payload.orgID,
      }
    }

    default:
      return state
  }
}
