import {useCallback, useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {selectUserID} from '@/redux/user/userSelector'
import {fetchUser} from '@/modules/ITSM/api/userRequests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {TAssetQuickFilters} from '@/modules/ITSM/typedef'
import {getIncidentRequestAssetQuickFilters} from '@/modules/ITSM/utils/getAssetQuickFilters'
import {TUser} from '@/typedef'

export const useGetIncidentRequestAssetQuickFilters = () => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserID)
  const [isLoading, setIsLoading] = useState(false)
  const [assetQuickFilters, setAssetQuickFilters] = useState<
    TAssetQuickFilters
  >([])

  const getMyAssignmentGroups = useCallback(async () => {
    setIsLoading(true)

    try {
      const {
        body: {result},
      } = await fetchUser<TUser>(userId)
      return result.assignment_groups || []
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      return []
    } finally {
      setIsLoading(false)
    }
  }, [dispatch, userId])

  useEffect(() => {
    getMyAssignmentGroups().then(assignmentGroups =>
      setAssetQuickFilters(
        getIncidentRequestAssetQuickFilters(assignmentGroups, userId)
      )
    )
  }, [getMyAssignmentGroups, userId])

  return {assetQuickFilters, isLoading}
}
