import isBoolean from 'lodash/isBoolean'
import {useSelector} from 'react-redux'

import {
  selectAppVersion,
  selectModuleName,
  selectUserOrgRole,
} from '@/redux/user/userSelector'
import {appItsmRouter} from '@/modules/ITSM/routes/app-itsm-router'
import {AppVersion, Modules, TRoute, UserRole} from '@/typedef'

import {PermissionMap} from './permission-map'
import {Feature} from './typedef'

export const useGetModulePremissions = () => {
  const userRole = useSelector(selectUserOrgRole) as UserRole | undefined
  const appVersion = useSelector(selectAppVersion) as AppVersion | undefined

  const module = useSelector(selectModuleName)

  const isModuleFeatureAllowed = (feature: Feature) => {
    const permissionFeat = PermissionMap.features?.[feature] || {} || {}

    switch (module) {
      case Modules.ITSM:
        if (appVersion && userRole) {
          return isBoolean(permissionFeat[appVersion])
            ? permissionFeat[appVersion] && permissionFeat[userRole]
            : permissionFeat[userRole]
        }

        break

      default:
        break
    }
  }

  const isModuleRouteAllowed = (route: TRoute) => {
    const {userPermissions = {}} = appItsmRouter[route]?.props || {}

    switch (module) {
      case Modules.ITSM:
        if (appVersion && userRole) {
          return isBoolean(userPermissions[appVersion])
            ? userPermissions[appVersion] && userPermissions[userRole]
            : userPermissions[userRole]
        }

        break

      default:
        break
    }
  }

  return {isModuleFeatureAllowed, isModuleRouteAllowed}
}
