import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {workNotesApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {Comment} from '@/modules/ITSM/services/openAPI/Api'

import {TITSMSelectorNoBC} from '../../typedef'

export const getWorkNote = async ({
  entity,
  limit,
  bookmark,
}: TITSMSelectorNoBC): Promise<ISuperAgentResMultiple<Comment>> =>
  await request
    .get(workNotesApi().get(entity, limit, bookmark))
    .set(getHeadersWithGRPC())

export const postWorkNote = async (
  external_id: string,
  text: string,
  entity: string
) =>
  await request
    .post(workNotesApi().post)
    .send({external_id, text, entity})
    .set(getHeadersWithGRPC())

export const markAsReadByWorkNoteUser = async (id: string) =>
  await request.post(workNotesApi().postReadBy(id)).set(getHeadersWithGRPC())

export const getSingleWorkNote = async (id: string) =>
  await request.get(workNotesApi().getOne(id)).set(getHeadersWithGRPC())
