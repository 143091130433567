import {call, put, select} from 'redux-saga/effects'

import {fetchSaasIdentityMeFailed} from '@/modules/modules-shared/shared-cmdb/redux/identity/identityActions'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {TSaasIdentityMe} from '@/modules/SAAS/typedf'
import {fetchSaasIdentityMe} from '@/modules/SAAS/api/identity-requests'
import {fetchSaasIdentityMeSuccess} from '../store/user/saas-user-actions'
import {selectSaasSpaceId} from '@/redux/entries/entries-selector'
import {setSaasNamespaceId} from '@/redux/entries/entries-actions'

type TParams = {body: TSaasIdentityMe}

export function* getSaasIdentityMeSaga() {
  try {
    const {
      body: {role: saasUserRole, name, namespaces, userId},
    }: TParams = yield call(fetchSaasIdentityMe)

    const selectedNamespaceId: string = yield select(selectSaasSpaceId)

    const hasPersistedSpaceInMySpaces = namespaces.some(
      space => space?.id === selectedNamespaceId
    )

    if (!hasPersistedSpaceInMySpaces) {
      yield put(setSaasNamespaceId(namespaces[0]?.id || ''))
    }

    yield put(
      fetchSaasIdentityMeSuccess({
        saasUserRole,
        saasUserNameInChannel: name,
        saasNamespaces: namespaces,
        saasUserId: userId,
      })
    )
  } catch (err) {
    yield put(fetchSaasIdentityMeFailed({err}))
    yield put(setToastMessage({message: err}))
  }
}
