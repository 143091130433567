type TItsmTableCreators = typeof import('./list-table/table-actions')

type TActionCreators = TItsmTableCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

export enum ActionTypes {
  SET_ITSM_LIST_TABLE_COLUMNS = 'SET_ITSM_LIST_TABLE_COLUMNS',
  SET_ITSM_LIST_TABLE_BOOKMARK = 'SET_ITSM_LIST_TABLE_BOOKMARK',
  SET_ITSM_LIST_TABLE_BOOKMARKS = 'SET_ITSM_LIST_TABLE_BOOKMARKS',
  SET_ITSM_LIST_TABLE_SORTERS = 'SET_ITSM_LIST_TABLE_SORTERS',
  SET_ITSM_LIST_TABLE_FILTER = 'SET_ITSM_LIST_TABLE_FILTER',
  SET_ITSM_LIST_TABLE_FILTERS = 'SET_ITSM_LIST_TABLE_FILTERS',
  SET_ITSM_LIST_TABLE_QUICK_FILTER = 'SET_ITSM_LIST_TABLE_QUICK_FILTER',
  RESET_ITSM_LIST_TABLE_FILTER = 'RESET_ITSM_LIST_TABLE_FILTER',
  RESET_ITSM_LIST_TABLE_FILTERS = 'RESET_ITSM_LIST_TABLE_FILTERS',
  RESET_ITSM_LIST_TABLE_BOOKMARKS = 'RESET_ITSM_LIST_TABLE_BOOKMARKS',
  RESET_ITSM_ALL_LIST_TABLE_FILTERS = 'RESET_ITSM_ALL_LIST_TABLE_FILTERS',
  RESET_ITSM_NUMBER_TABLE_FILTERS = 'RESET_ITSM_NUMBER_TABLE_FILTERS',
  REFRESH_INVITE_ORG_TOKEN_REQUESTED = 'REFRESH_INVITE_ORG_TOKEN_REQUESTED',
  REFRESH_INVITE_ORG_TOKEN_SUCCESS = 'REFRESH_INVITE_ORG_TOKEN_SUCCESS',
  REFRESH_INVITE_ORG_TOKEN_FAILED = 'REFRESH_INVITE_ORG_TOKEN_FAILED',
  POST_IDENTITY_CHANNEL_INVITE_REQUESTED = 'POST_IDENTITY_CHANNEL_INVITE_REQUESTED',
  POST_IDENTITY_CHANNEL_INVITE_FAILED = 'POST_IDENTITY_CHANNEL_INVITE_FAILED',
  POST_IDENTITY_CHANNEL_INVITE_SUCCESS = 'POST_IDENTITY_CHANNEL_INVITE_SUCCESS',
  SET_INVITE_LOADING_STATUS = 'SET_INVITE_LOADING_STATUS',
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_IDENTITY_EXISTS = 'SET_IDENTITY_EXISTS',
  SET_USER_DEFAULTS = 'SET_USER_DEFAULTS',
  SET_ACCEPT_INVITE_ERROR = 'SET_ACCEPT_INVITE_ERROR',
}
