import React, {useEffect, useState} from 'react'

import {Col, FormInstance, Popover, Row} from 'antd'

import CustomerProductSelect from '@/modules/ITSM/components/form-components/customer-product-select'
import SupplierProductSelect from '@/modules/ITSM/components/form-components/supplier-product-select'
import AssignmentGroupSelect from '@/modules/ITSM/components/form-components/assignment-group-select'
import AssignedToSelect from '@/modules/ITSM/components/form-components/assigned-to-select'
import {fetchSupplierProduct} from '@/modules/ITSM/api/productsRequests'

import {
  TAssignmentGroup,
  TCustomerProduct,
  TSupplierProduct,
  TUser,
} from '@/modules/ITSM/typedef'
import {IncidentTaskState} from '@/modules/ITSM/components/incident-task/typedf'
import {IncidentState} from '@/modules/ITSM/components/incident-k-request/constants'
import {translate} from '@/services/i18n'
import {twoColumns} from '@/utils/table/constants/grid-columns'

import {ProductContextProvider} from '../../incident-k-request/incident-k-request-detail-form/context/product-context'

type TProps = {
  form: FormInstance
  setRefresh: () => void
  isDisabled?: boolean
  supplierProduct?: TSupplierProduct
  customerProduct?: TCustomerProduct
  assignmentGroup?: TAssignmentGroup
  assignedTo?: TUser
  stateId?: IncidentTaskState | IncidentState
}

const IncidentAssignment = ({
  form,
  setRefresh,
  isDisabled,
  supplierProduct,
  customerProduct,
  assignmentGroup,
  assignedTo,
  stateId,
}: TProps) => {
  const [orgNameSupplierProduct, setOrgNameSupplierProduct] = useState(
    supplierProduct?.org_name
  )

  const disabledAssignedTo =
    form.getFieldValue('supplier_product') &&
    !form.getFieldValue('assignment_group')

  const handleFetchSupplierProduct = async (id: string) => {
    const {
      body: {
        result: {org_name},
      },
    } = await fetchSupplierProduct({id})

    setOrgNameSupplierProduct(org_name)
  }

  const handleSelect = () => {
    form.setFieldsValue({
      assignment_group: null,
      assigned_to: null,
    })
    setRefresh()
  }

  useEffect(() => {
    if (assignedTo?.uuid) form.setFieldsValue({assigned_to: assignedTo.uuid})

    if (assignmentGroup?.uuid)
      form.setFieldsValue({assignment_group: assignmentGroup.uuid})
  }, [assignedTo, assignmentGroup, form])

  const assignedToSelect = (
    <Col id="assigned-to-select" {...twoColumns}>
      <AssignedToSelect
        assignedToInit={assignedTo}
        setRefresh={setRefresh}
        assignmentGroupValue={form.getFieldValue('assignment_group')}
        assignedToValue={form.getFieldValue('assigned_to')}
        orgNameSupplierProduct={orgNameSupplierProduct}
        isDisabled={isDisabled || disabledAssignedTo}
      />
    </Col>
  )

  return (
    <>
      <ProductContextProvider>
        <Row gutter={[16, 16]}>
          <Col {...twoColumns}>
            <CustomerProductSelect
              customerProdInit={customerProduct}
              customerProdValue={form.getFieldValue('customer_product')}
              isRequired={!!form.getFieldValue('assigned_to')}
              resetSupplierProd={() => {
                form.setFieldsValue({
                  supplier_product: null,
                  assignment_group: null,
                  assigned_to: null,
                })
                setOrgNameSupplierProduct('')
              }}
              setRefresh={setRefresh}
              isDisabled={
                isDisabled || stateId === IncidentTaskState.In_progress
              }
            />
          </Col>
          <Col {...twoColumns}>
            <SupplierProductSelect
              supplierProdInit={supplierProduct}
              customerProdCode={customerProduct?.code}
              customerProdValue={form.getFieldValue('customer_product')}
              isDisabled={isDisabled}
              handleOnChange={(id?: string) => {
                if (id) handleFetchSupplierProduct(id)
                handleSelect()
              }}
              onClear={() => {
                setOrgNameSupplierProduct('')
                handleSelect()
              }}
            />
          </Col>
        </Row>
      </ProductContextProvider>
      <Row gutter={[16, 16]}>
        <Col {...twoColumns}>
          <AssignmentGroupSelect
            assignmentGroupInit={assignmentGroup}
            setRefresh={setRefresh}
            resetAssignedTo={() => form.setFieldsValue({assigned_to: null})}
            selector={{
              ...(orgNameSupplierProduct
                ? {org_name: orgNameSupplierProduct}
                : {}),
            }}
            isDisabled={isDisabled}
          />
        </Col>
        {disabledAssignedTo ? (
          <Popover
            trigger="hover"
            content={translate('requiredAssignmentGroup')}
          >
            {assignedToSelect}
          </Popover>
        ) : (
          assignedToSelect
        )}
      </Row>
    </>
  )
}

export default IncidentAssignment
