import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {incidentTaskApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {
  TIncidentTask,
  TIncidentTaskReq,
} from '../components/incident-task/typedf'

export const getIncidentTask = async (
  id: string
): Promise<ISuperAgentResSingle<TIncidentTask>> => {
  return await request
    .get(incidentTaskApi(id).getOne)
    .set(getHeadersWithGRPC())
    .query({resolve: true})
}

export const patchIncidentTask = async (data: TIncidentTaskReq, id: string) => {
  return await request
    .patch(incidentTaskApi(id).patch)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postIncidentTask = async (data: TIncidentTaskReq, id: string) => {
  return await request
    .post(incidentTaskApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchIncidentTasks = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(incidentTaskApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })
