import React from 'react'

export const Kompitech = ({width, height}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8853 12.0156H10.0443V0L0.114746 19.9426H5.91396V32L15.8853 12.0156Z"
      fill="#FF9501"
    />
  </svg>
)
