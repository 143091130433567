import request from 'superagent'

import {assetsApi, getCmdbHeaders} from '@/services/api'
import {generateSelector} from '@/utils/generate-selector'
import {ISuperAgentResSingle} from '@/modules/CMDB/api/typedf'
import {CmdbAsset} from '@/modules/CMDB/api-types/Api'
import {createSpaceHeader} from '@/utils/create-space-header'

export const fetchMyAssets = async ({
  userId,
  spaceId,
}: {
  userId: string
  spaceId?: string
}): Promise<ISuperAgentResSingle<Array<CmdbAsset>>> => {
  return await request
    .options(assetsApi().get)
    .set({
      ...getCmdbHeaders(),
      ...(spaceId ? createSpaceHeader(spaceId) : {}),
    })
    .send({
      filters: {
        ...generateSelector({user_id: userId}),
      },
    })
}
