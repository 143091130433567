import React from 'react'

import {Form, Row, Col, Collapse, FormInstance} from 'antd'
import moment, {Moment} from 'moment'
import {Rule} from 'antd/lib/form'

import {translate} from '@/services/i18n'
import {DAYS} from '@/modules/ITSM/utils/Constants'
import FormItemCustom from '@/components/form/form-item-custom'
import {TWeekdays} from '@/modules/ITSM/typedef'

import {weekdaysValidation} from './utils/utils'
import TimePicker from '@/components/time-picker/time-picker'
const {Panel} = Collapse

interface IWeekdayPricingPolicyItem {
  form: FormInstance<any>
  disabled?: boolean
}

const Weekdays = ({form, disabled}: IWeekdayPricingPolicyItem) => {
  const handleChange = (
    _: moment.Moment | null,
    date: string,
    interval: string,
    index: number
    // eslint-disable-next-line max-params
  ) => {
    const fieldToChange = form.getFieldValue('weekdays')
    const newPricingPolicies = fieldToChange.map(
      (val: {[key in string]: TWeekdays}, ind: number) =>
        index === ind
          ? {
              ...val,
              [interval]: date ? moment(date, 'HH:mm') : null,
            }
          : val
    )
    form.setFieldsValue({weekdays: newPricingPolicies})
    form.validateFields()
  }

  const validatorValue = (value: Moment, ind: number, intervalName: string) => {
    const weekdayInterval = form.getFieldValue(['weekdays'])[ind][intervalName]
    return {
      weekdayInterval,
      value,
      intervalName,
    }
  }

  return (
    <Form.List name={['weekdays']}>
      {fields => {
        return (
          <Collapse ghost>
            <Panel header={translate('weekdays')} key={1}>
              <div>
                {fields.map((field, ind) => {
                  return (
                    <Row key={ind}>
                      <Col className="col-flex--two col-flex--two-mobile">
                        <FormItemCustom
                          name={[field.name, 'support_from']}
                          label={`${DAYS[ind]} ` + translate('from')}
                          rules={[
                            {
                              validateTrigger: 'onChange',

                              validator: (_: Rule, value: Moment) =>
                                weekdaysValidation(
                                  _,
                                  validatorValue(value, ind, 'support_to')
                                ),
                            },
                          ]}
                        >
                          <TimePicker
                            disabled={disabled}
                            getPopupContainer={(e: any) =>
                              e.parentNode as HTMLElement
                            }
                            minuteStep={5}
                            onChange={(_: any, date: any) =>
                              handleChange(_, date, 'support_from', ind)
                            }
                          />
                        </FormItemCustom>
                        <FormItemCustom
                          name={[field.name, 'support_to']}
                          label={`${DAYS[ind]} ` + translate('to')}
                          rules={[
                            {
                              validateTrigger: 'onChange',

                              validator: (_: Rule, value: Moment) =>
                                weekdaysValidation(
                                  _,
                                  validatorValue(value, ind, 'support_from')
                                ),
                            },
                          ]}
                        >
                          <TimePicker
                            disabled={disabled}
                            getPopupContainer={(e: any) =>
                              e.parentNode as HTMLElement
                            }
                            minuteStep={5}
                            onChange={(_: any, date: any) =>
                              handleChange(_, date, 'support_to', ind)
                            }
                          />
                        </FormItemCustom>
                      </Col>
                    </Row>
                  )
                })}
              </div>
            </Panel>
          </Collapse>
        )
      }}
    </Form.List>
  )
}

export default Weekdays
