import React, {ReactNode} from 'react'

import {Collapse as AntCollapse, CollapsePanelProps, CollapseProps} from 'antd'
import './collapse.scss'

const {Panel: AntPanel} = AntCollapse

export const Collapse: React.FC<CollapseProps & {children: ReactNode}> = ({
  children,
  ...props
}) => {
  return <AntCollapse {...props}>{children}</AntCollapse>
}

interface IPanelProps extends CollapsePanelProps {
  header: string | React.ReactNode
}

export const Panel: React.FC<IPanelProps & {children: ReactNode}> = ({
  children,
  ...props
}) => {
  return (
    <AntPanel className={'collapse-wrapper'} {...props}>
      {children}
    </AntPanel>
  )
}
