import React, {MouseEvent, useEffect, useState} from 'react'

import {saveAs} from 'file-saver'
import {useDispatch} from 'react-redux'

import {PreviewModal} from '@/components/modal/preview-modal/preview-modal'
import {getAttachmentV2} from '@/modules/ITSM/api/attachmentsRequests'
import {TAttachmentData} from '@/modules/ITSM/typedef'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {getNameAndExtension} from '@/utils/get-name-and-extension'
import {checkExtension} from '@/components/modal/preview-modal/utils'

import {extensionPicker} from '../../utils/Helpers'

import './action-buttons.scss'

type TProps = {
  anchor?: HTMLElement
  uuid: string
  onClose: () => void
}

const onlyDownloadableExtensions = ['eml', 'msg', 'txt', 'html', 'zip', 'doc']
const buttonClass = 'action-buttons-container__button'
const INDENT = 70

export const ActionButtons: React.FC<TProps> = ({uuid, onClose, anchor}) => {
  const [record, setRecord] = useState<TAttachmentData>()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [position, setPosition] = useState<{
    top: number
    left: number
  }>()

  const dispatch = useDispatch()

  useEffect(() => {
    const getPosition = (element: HTMLElement) => {
      const top =
        element.getBoundingClientRect().top +
        element.offsetHeight +
        window.pageYOffset
      const left =
        window.innerWidth -
        (window.innerWidth - element.getBoundingClientRect().left) +
        INDENT
      setPosition({top, left})
    }

    if (anchor) {
      getPosition(anchor)
    }
  }, [anchor])

  useEffect(() => {
    const checkOuterClick: EventListener = (event: Event) => {
      const element = event.target as HTMLElement
      const outerClick = element.getAttribute('class') !== buttonClass
      if (outerClick) {
        onClose()
      }
    }
    window.addEventListener('click', checkOuterClick)

    return () => {
      window.removeEventListener('click', checkOuterClick)
    }
  }, [onClose])

  const onButtonClick = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    try {
      const {body, type, header} = await getAttachmentV2(uuid)
      setRecord({
        data: body as Blob,
        file_type: type,
        file_name: header['x-file-name'],
      })

      const action = (event.target as HTMLElement).getAttribute('data-action')
      if (action === 'preview') {
        setShowPreviewModal(true)
      } else {
        const fileType = extensionPicker(type)
        const file = new Blob([body], {
          type,
        })
        saveAs(file, header['x-file-name'] || 'download.' + fileType)
      }
    } catch (error) {
      dispatch(setToastMessage({message: error}))
    }
  }

  const previewButton = (
    <button
      className={buttonClass}
      onClick={onButtonClick}
      data-action="preview"
      data-uuid={uuid}
      type="button"
    >
      Preview
    </button>
  )
  const downLoadButton = (
    <button
      className={buttonClass}
      onClick={onButtonClick}
      data-action="download"
      data-uuid={uuid}
      type="button"
    >
      Download
    </button>
  )
  const hidePreviewModal = () => {
    setShowPreviewModal(false)
    onClose()
  }

  const fileData = getNameAndExtension(record?.file_name)

  const isOnlyDownloadable =
    fileData.extension &&
    onlyDownloadableExtensions.some(ext => ext === fileData.extension)

  const bothButtons = (
    <>
      {previewButton}
      {downLoadButton}
    </>
  )

  return (
    <span className="action-buttons-container" style={{...position}}>
      {isOnlyDownloadable ? downLoadButton : bothButtons}
      {fileData?.extension && showPreviewModal && record?.data ? (
        <PreviewModal
          extension={checkExtension(fileData.extension) || ''}
          data={record?.data}
          onCancel={hidePreviewModal}
        />
      ) : null}
    </span>
  )
}
