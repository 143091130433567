import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import parse from 'html-react-parser'
import {useDispatch} from 'react-redux'
import {Avatar, Card, Col, Form, List, Popover, Row, Spin} from 'antd'
import moment from 'moment'

import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import {setError} from '@/redux/actions'
import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import {ActionButtons} from '@/modules/ITSM/components/action-buttons'
import {Entities} from '@/typedef'
import {getNameAbbreviation} from '@/utils/get-name-abbreviation'
import {Skeleton} from '@/components/skeleton'

import {useOnScreen} from '../../hooks/chatHooks'

import {ChatRoomSkeleton} from './chat-room-skeleton'
import ErrorMessage from './ErrorMessage'

import './chat-room.scss'

function getTitle(text) {
  if (typeof text === 'object') {
    text = text[0]
  }

  return <span>{parse(text)}</span>
}

//

const Message = ({
  data,
  getFullMessageData,
  markReadComment,
  userID,
  entity,
}) => {
  const [showActionButtons, setShowActionButtons] = useState(false)
  const [wasRead, setWasRead] = useState(false)
  const [readByUserList, setReadByUserList] = useState([])
  const [uuid, setUuid] = useState('')
  const messageRef = useRef(null)
  const linkRef = useRef(null)
  const {isIntersecting} = useOnScreen(messageRef, '0px')

  useEffect(() => {
    if (
      (data && !data.read_by) ||
      data?.read_by.every(readBy => readBy.user.uuid !== userID)
    ) {
      if (!wasRead) {
        if (isIntersecting && data.read_by) {
          setWasRead(true)
          markReadComment(data.uuid)
        }
      }
    }
  }, [data, isIntersecting, markReadComment, userID, wasRead])

  const description = useMemo(() => {
    const {uuid: messageId} = data
    const createdDate = moment(data.created_at).format(DATE_FORMAT)
    const popoverContent =
      readByUserList?.length > 0 ? (
        readByUserList.map(({user}, index) => (
          <div key={index}>
            {user.name} {user.surname}
          </div>
        ))
      ) : (
        <Spin spinning={true} size="small" />
      )
    const onPopoverVisibleChange = async visible => {
      if (visible && getFullMessageData) {
        const {
          body: {read_by},
        } = await getFullMessageData(messageId)
        setReadByUserList(read_by)
      }
    }

    return (
      <div className="itsm-chatroom__title">
        <span>{`${data.created_by.name} ${data.created_by.surname} at ${createdDate}`}</span>
        <Popover
          overlayClassNamefd="itsm-chatroom__read-by-details"
          onOpenChange={onPopoverVisibleChange}
          content={popoverContent}
          getPopupContainer={e => e.parentNode}
        >
          <span className="itsm-chatroom__read-by">{translate('read_by')}</span>
        </Popover>
      </div>
    )
  }, [data, getFullMessageData, readByUserList])

  const onTitleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    const element = event.target
    if (element) {
      linkRef.current = element
    }
    const isLink = element.tagName === 'A'
    const hrefAttribute = element.getAttribute('href')
    const uuid = hrefAttribute?.split(':')[1]
    if (hrefAttribute && isLink) {
      setShowActionButtons(true)
      setUuid(uuid)
    }
  }

  const hideActionButtons = () => {
    setShowActionButtons(false)
    setUuid('')
  }

  const br_entity = entity === Entities.BILLING_REPORT

  return (
    <Col span={24} ref={messageRef}>
      <Form.Item>
        <Card>
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  shape="square"
                  className="itsm-chatroom__avatar"
                  style={{
                    backgroundColor:
                      '#' +
                      (data.created_by && data.created_by.uuid.substring(0, 6)),
                  }}
                  size="large"
                >
                  <b>
                    {data.created_by &&
                      getNameAbbreviation(
                        `${data.created_by.name} ${data.created_by.surname}`
                      )}
                  </b>
                </Avatar>
              }
              title={
                <span onClick={onTitleClick}>
                  {getTitle(data.text)}
                  {showActionButtons && !br_entity ? (
                    <ActionButtons
                      anchor={linkRef.current}
                      uuid={uuid}
                      onClose={hideActionButtons}
                    />
                  ) : null}
                </span>
              }
              description={description}
            />
          </List.Item>
        </Card>
      </Form.Item>
    </Col>
  )
}

const Chatroom = ({
  markReadComment,
  getFullMessageData,
  data,
  userID,
  entity,
  noDataMessage,
  loading,
  paginationComponent,
}) => {
  const dispatch = useDispatch()
  const setErrorOccurred = useCallback(
    error => {
      dispatch(setError(error))
    },
    [dispatch]
  )

  return (
    <Skeleton active={loading} view={<ChatRoomSkeleton />}>
      {data.length > 0 ? (
        <>
          <Row className="itsm-chatroom">
            {data.map(data => (
              <SentryErrorBoundary
                key={data.uuid}
                fallback={<ErrorMessage data={data} />}
              >
                <Message
                  key={data.uuid}
                  data={data}
                  getFullMessageData={getFullMessageData}
                  markReadComment={markReadComment}
                  userID={userID}
                  onError={setErrorOccurred}
                  entity={entity}
                />
              </SentryErrorBoundary>
            ))}
          </Row>
          <Row>
            <Col className="text-right" span={24}>
              {paginationComponent}
            </Col>
          </Row>
        </>
      ) : (
        <Row className="mt-20">
          <Col span={24}>{noDataMessage}</Col>
        </Row>
      )}
    </Skeleton>
  )
}

export default Chatroom
