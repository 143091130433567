import React, {useEffect, useState} from 'react'
import debounce from 'lodash/debounce'
import {withErrorBoundary} from '@sentry/react'
import queryString from 'query-string'
import {useDispatch, useSelector} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {hasAuthParams, useAuth} from 'react-oidc-context'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import {omit} from 'lodash'

// Please don't change the order of these style imports
import 'antd/dist/reset.css'
import 'react-dazzle/lib/style/style.css'
import './styles/main.scss'
import './App.scss'

import {useAppTheme} from '@/core/theme'
import HistoryListener from '@/components/historyListener/HistoryListener'
import {setWindowWidth} from '@/redux/actions'
import ErrorPage from '@/components/error/error-page/error-page'

import {useStonlyInit} from '@/hooks/useStonlyInit'
import globalRoutes from '@/global-routes'

import Toast from './components/toast/toast'

import useInitialSettings from '@/hooks/use-initial-settings'
import {useCustomAuth} from '@/hooks/use-custom-auth'
import {OverlaySpinner} from './components/overlay-spinner'
import {translate} from './services/i18n'
import NotFound from './components/error/not-found'
import {generatePrivateRoutes} from './routes/utils/generate-private-routes'
import {routes} from './routes/routes'
import {generatePublicRoutes} from './routes/utils/generate-public-routes'
import {useQuery} from './utils/useQuery'
import {publicRoutes} from './routes/public-routes'
import {ConfigProvider} from 'antd'
import {AbilityContextProvider} from '@/services/abilities/ability-context'
import {selectAccessToken} from '@/redux/user/user-selector'

const App = () => {
  useStonlyInit()
  const dispatch = useDispatch()
  const query = queryString.parse(decodeURI(window.location.search), {
    decode: false,
  }) as Record<string, string>

  const accessToken = useSelector(selectAccessToken)

  const {
    theme: {styles},
  } = useAppTheme()

  const auth = useAuth()
  const {signUpRedirect} = useCustomAuth()

  useInitialSettings()

  useEffect(() => {
    const onWindowResize = debounce(() => {
      dispatch(setWindowWidth(window.innerWidth))
    }, 1000)

    window.addEventListener('resize', onWindowResize)
    onWindowResize()

    return () => window.removeEventListener('resize', onWindowResize)
  }, [dispatch])

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      !query?.accept_invite &&
      query?.grant &&
      query?.redirect &&
      (query?.org_id || query?.channel_invite)
    ) {
      signUpRedirect({...query, accept_invite: 'true'})
    }
  }, [auth.isAuthenticated, query?.grant, signUpRedirect])

  useEffect(() => {
    if (!auth.isAuthenticated) {
      if (query?.mode === 'signup') {
        signUpRedirect(omit(query, ['mode']))
      }
    }
  }, [auth.isAuthenticated, query?.mode])

  const [
    attemptNonInteractiveSignIn,
    setAttemptNonInteractiveSignIn,
  ] = useState(false)

  const {pathname} = useLocation()
  const isPublic = publicRoutes.some(({props: {path}}) => path === pathname)

  useEffect(() => {
    if (
      !isPublic &&
      !attemptNonInteractiveSignIn &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect({prompt: 'none', state: query})
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth,
    attemptNonInteractiveSignIn,
    isPublic,
  ])

  const history = useHistory()
  const urlError = useQuery('error')

  useEffect(() => {
    if (
      urlError === 'login_required' ||
      urlError === 'interaction_required' ||
      urlError === 'consent_required'
    ) {
      history.push(globalRoutes.general.landingPage.path())
      setAttemptNonInteractiveSignIn(true)
    }
  }, [history, urlError])

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return (
        <div>
          <OverlaySpinner
            idName={'app-signinSilent'}
            text={translate('sign_in_loading')}
          />
        </div>
      )

    case 'signoutRedirect':
      return (
        <div>
          <OverlaySpinner
            idName={'app-signoutRedirect'}
            text={translate('sign_out_loading')}
          />
        </div>
      )
  }

  if (
    auth.isLoading ||
    query.mode === 'signup' ||
    (auth.isAuthenticated && !accessToken)
  ) {
    return (
      <div>
        <OverlaySpinner idName={'app-isLoading'} isLogoutButtonVisible={true} />
      </div>
    )
  }

  return (
    <ConfigProvider theme={styles}>
      <HistoryListener>
        <AbilityContextProvider>
          <Toast />
          <Switch>
            {generatePublicRoutes()}
            {auth.isAuthenticated ? (
              generatePrivateRoutes({routes})
            ) : (
              <Redirect
                to={globalRoutes.general.landingPage.path()}
                push={true}
              />
            )}
            <Route path="*" component={NotFound} />
          </Switch>
        </AbilityContextProvider>
      </HistoryListener>
    </ConfigProvider>
  )
}

export default withTranslation()(
  withErrorBoundary(App, {
    fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
  })
)
