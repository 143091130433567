import request from 'superagent'

import {authentication, grantApi} from '@/services/api'
import {TAuthResponse, THeaders, TTokenReqPayload} from '@/api/typedf'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {generateConfig} from '@/generateConfig'
import store from '@/redux/store'

export const getBEndToken = (
  payload?: TTokenReqPayload
): Promise<ISuperAgentResponse<TAuthResponse>> => {
  const {id, scope = 'org'} = payload || {}
  const {accessToken} = store.getState().itsmUser

  const blits_access_token_override = localStorage.getItem(
    'blits_access_token_override'
  )

  if (blits_access_token_override) {
    return Promise.resolve({
      body: {
        access_token: blits_access_token_override,
        issued_token_type: 'urn:ietf:params:oauth:token-type:jwt',
        token_type: 'Bearer',
        expires_in: 3600,
        scope: ``,
      },
    })
  }
  return request
    .post(authentication().getStsToken)
    .set({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    .send({
      subject_token: accessToken,
      ...(id && {
        scope: `urn:x-blits:scope:${scope}:${id}`,
      }),
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
      audience: generateConfig().STS_AUDIENCE,
    })
}

export const postAcceptInvite = ({
  grantToken,
  headers,
}: {
  grantToken: string
  headers: THeaders
}): Promise<ISuperAgentResponse<undefined>> => {
  return request
    .post(grantApi.post)
    .set(headers)
    .send({grantToken})
}
