import queryString from 'query-string'
import {useLocation} from 'react-router-dom'
import {Flow, TAccountDefaultQuery} from '@/sites/account-default/typedef'
import {hasPaymentDetails} from '@/sites/account-default/utils/has-payment-details'
import {generateConfig} from '@/generateConfig'
import {useContext} from 'react'
import {AccountDefaultContext} from '@/sites/account-default/account-default-context/account-default-context'

export const useShouldIncludeSteps = () => {
  const {search} = useLocation()
  const query = queryString.parse(search) as TAccountDefaultQuery
  const {hasPayment} = hasPaymentDetails(query)

  const {state} = useContext(AccountDefaultContext)

  return {
    hasPaymentStep:
      hasPayment ||
      (state.billingSubscriptions.length > 0 &&
        (query.flow === Flow.SERVICES || query.flow === Flow.PRODUCTS)),
    hasCalendlyStep: generateConfig().FEATURE_FLAGS.isCalendlyBookEnabled,
  }
}
