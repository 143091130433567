import React from 'react'

import {SkeletonItem} from '@/components/skeleton'
import {Block} from '@/components/block/block'

import './block.scss'

export const BlockSkeleton: React.FC = () => (
  <Block className="block__skeleton">
    <SkeletonItem className="block__skeleton-item" />
  </Block>
)
