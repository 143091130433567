import {useCallback, useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {ItsmAssets, TAssetQuickFilters} from '@/modules/ITSM/typedef'
import {TKey, translate} from '@/services/i18n'
import {
  setItsmTablePage,
  setItsmTableQuickFilters,
  setItsmTableSorters,
} from '@/modules/ITSM/store/list-table/table-actions'
import {
  selectItsmBookmark,
  selectItsmTableColumns,
  selectItsmTableFilters,
  selectItsmTableQuickFilter,
  selectItsmTableSorters,
} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'
import {selectEmptySpace} from '@/redux/user/userSelector'
import {SortingValues, TListArgs} from '@/typedef'
import {TDefaultQuickFilters} from '@/components/filter/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {useFetchHook} from '@/hooks/useFetch'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {RESOLVE} from '@/constants'
import {prepareSelector} from '@/modules/ITSM/components/itsm-list-table/utils/prepare-selector'
import {getDefaultFields} from '@/modules/ITSM/components/itsm-list-table/constants/filters-config'

import {getQuickFilter} from '../utils/get-quick-filter'

function useGetIncidentsRequestsListTableData<T extends Record<string, any>>(
  asset: ItsmAssets,
  assetQuickFilters: TAssetQuickFilters,
  asyncF: (options: TListArgs<T>) => Promise<ISuperAgentResMultiple<T>>
) {
  const dispatch = useDispatch()
  const [entityData, setEntityData] = useState<T[]>()
  const [numberOfRecords, setNumberOfRecords] = useState(1)

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const quickFilter = useSelector(
    (state: TAppState) =>
      selectItsmTableQuickFilter(state, asset) || TDefaultQuickFilters.All
  )

  const columns = useSelector((state: TAppState) =>
    selectItsmTableColumns(state, asset)
  )

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const columnList = (columns || [])?.map(({title}) => title)

  const bookmarkStore = useSelector((state: TAppState) =>
    selectItsmBookmark(state, asset)
  )

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const getEntityData = useCallback(
    async (passedOptions: TListArgs<T>) => {
      const {passedBookmark, passedSorter, passedFilters} = passedOptions

      try {
        handleRequested()
        const {
          body: {result, total_count, bookmark},
        } = await asyncF({
          passedBookmark,
          resolve: RESOLVE,
          ...(!passedSorter
            ? {sort: [{created_at: SortingValues.DESC}]}
            : {sort: [passedSorter]}),
          ...(passedSorter && {sort: [passedSorter]}),
          ...(passedFilters && {
            selector: prepareSelector<T>({
              inputValues: {...passedFilters},
              asset,
            }),
          }),
          ...(getDefaultFields<T>()[asset] && {
            fields: getDefaultFields<T>()
              ?.[asset]?.concat(columnList)
              .filter((v, i, a) => a.indexOf(v) === i),
          }),
        })

        if (result && bookmark) {
          setEntityData(result)
          total_count && setNumberOfRecords(total_count)

          const parsedBookmark = parseInt(bookmark)
          const remainder = parsedBookmark % 10

          dispatch(
            setItsmTablePage({
              asset,
              bookmark: (remainder
                ? parsedBookmark - remainder
                : parsedBookmark - 10
              ).toString(),
            })
          )
        }
        handleSuccess()
        return result
      } catch (err) {
        dispatch(setToastMessage({message: err}))
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [
      asset,
      asyncF,
      columnList,
      dispatch,
      handleFail,
      handleRequested,
      handleReset,
      handleSuccess,
    ]
  )

  const isEmptySpace = useSelector(selectEmptySpace)

  useEffect(() => {
    if (!sorter)
      dispatch(
        setItsmTableSorters(asset, {
          created_at: SortingValues.DESC,
        })
      )
  }, [asset, dispatch, sorter])

  useEffect(() => {
    if (isEmptySpace !== null) return

    if (!entityData && assetQuickFilters.length !== 0) {
      getEntityData({
        ...(bookmarkStore && {
          passedBookmark: bookmarkStore.toString(),
        }),
        passedSorter: sorter,
        passedFilters: {
          ...filters,
          ...getQuickFilter(quickFilter, assetQuickFilters),
        },
      })
    }
  }, [assetQuickFilters])

  const getQuickFilters = () => {
    return (assetQuickFilters || []).map(({name}) => ({
      title: translate(name as TKey),
      isActive: quickFilter === name,
      onClick: () => {
        dispatch(setItsmTableQuickFilters(asset, name))
        getEntityData({
          passedSorter: sorter,
          passedFilters: {
            ...filters,
            ...getQuickFilter(name, assetQuickFilters),
          },
        })
      },
    }))
  }
  return {
    entityData,
    getEntityData,
    numberOfRecords,
    fetchStatus: status,
    getQuickFilters,
  }
}

export default useGetIncidentsRequestsListTableData
