import * as ActionTypes from '@/redux/actionTypes'
import {TToastPayload} from '@/redux/toast-message/toast-typed'

export const setToastMessage = ({message, type}: TToastPayload) => ({
  type: ActionTypes.SET_TOAST_MESSAGE,
  payload: {message, type},
})
export const clearToastMessage = () => ({
  type: ActionTypes.CLEAR_TOAST_MESSAGE,
})

export const removeErrorMessage = (index: number) => ({
  type: ActionTypes.REMOVE_ERROR_MESSAGE,
  payload: index,
})

export const clearErrorMessages = () => ({
  type: ActionTypes.CLEAR_ERROR_MESSAGES,
})
