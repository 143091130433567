import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TRole} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'

import RoleNew from './role-new'
import RoleDetail from './role-detail'

const Roles = () => (
  <ItsmListTable<TRole>
    asset={ItsmAssets.Roles}
    newComponent={<RoleNew />}
    detailComponent={<RoleDetail />}
    drawerTitleNew={translate('role')}
    rowKey="uuid"
  />
)

export default Roles
