import React from 'react'

import classNames from 'classnames'
import {Upload as AntdUpload, UploadProps} from 'antd'
import noop from 'lodash/noop'

import './upload.scss'

export const Upload: React.FC<UploadProps> = props => {
  const {className, customRequest = noop, ...uploadProps} = props
  const uploadClass = classNames('upload', className)
  return (
    <AntdUpload
      className={uploadClass}
      customRequest={customRequest}
      {...uploadProps}
    />
  )
}
