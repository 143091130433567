import React from 'react'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {withErrorBoundary} from '@sentry/react'
import {useDispatch} from 'react-redux'

import {translate} from '@/services/i18n'
import {Assets} from '@/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import ErrorPage from '@/components/error/error-page/error-page'
import {TIncident} from '@/modules/ITSM/components/incident-k-request/typedf'
import {useGetDataList} from '@/modules/ITSM/hooks/use-get-data-list'
import {
  getSingleWorkNote,
  getWorkNote,
  markAsReadByWorkNoteUser,
  postWorkNote,
} from '@/modules/ITSM/api/ts-version/work-notes-requests'
import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {useStateContext} from '../incident/incident-context'
import {NotesCommentForm} from '../comments-list/notes-comments-form/notes-comments-form'
import {Comment} from '../../services/openAPI/Api'

type TProps = {
  isDisabled: boolean
}

const asset = Assets.WORK_NOTE

const WorkNotes = ({isDisabled}: TProps) => {
  const {entityData, entity} = useStateContext<TIncident>()

  const {uuid: id} = entityData || {}

  const {
    entityData: workNoteList,
    getEntityData: fetchWorkNotes,
    fetchStatus,
    recordCount,
    pagination,
    getSingleEntity,
  } = useGetDataList<Comment>({
    asyncF: getWorkNote,
    entity: `${entity}:${id}`,
    getSingleAsync: getSingleWorkNote,
  })

  const handlePagination = async (bookmark?: string) => {
    await fetchWorkNotes({bookmark})
  }

  const rdxDispatch = useDispatch()

  return (
    <NotesCommentForm
      markAsReadByUser={markAsReadByWorkNoteUser}
      fetchComments={fetchWorkNotes}
      asset={asset}
      id={id}
      isDisabled={isDisabled}
      loading={checkIsLoading(fetchStatus)}
      entity={entity}
      saveEntityAsync={postWorkNote}
      allComments={workNoteList}
      getComment={getSingleEntity}
      paginationComponent={
        <PaginationWithBookmarks
          pagination={pagination}
          fetch={async (bookmark?: string) => {
            try {
              await handlePagination(bookmark)
            } catch (err) {
              rdxDispatch(setToastMessage({message: err}))
            }
          }}
          numberOfRecords={recordCount}
        />
      }
      noDataMessage={translate('no_work_notes_added')}
    />
  )
}

export default withErrorBoundary(WorkNotes, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
