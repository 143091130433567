import React, {Component, createRef} from 'react'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {getDataDifference} from '@/utils/forms/get-data-difference'
import {setRefresh} from '@/redux/actions'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {Assets, RequestStatus} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import {RESOLVE} from '@/constants'
import {fetchOrgsRole} from '@/modules/ITSM/api/itsmOrgRequests'

import {patchAssignmentGroup} from '../../../../api/assignmentGroupRequests'
import {fetchUsers, patchUser} from '../../../../api/userRequests'
import AssignmentGroupsForm from '../assignment-groups-form/AssignmentGroupsForm'

import './assignment-groups-form-container.scss'

class AssignmentGroupsFormContainer extends Component {
  constructor(props) {
    super(props)
    this.formRef = createRef()
    this.state = {
      targetKeys: [],
      asset: Assets.assignmentGroupsAsset,
      usersFound: [],
      users: [],
      usersData: [],
      btnDisabled: true,
      data: [],
    }
  }

  createData = values => {
    const {targetKeys} = this.state
    const {...origin} = this.props.assignmentGroup
    const newValues = {...values}
    delete newValues['assignment_groups']
    delete newValues['user_search']

    if (!origin['notification_emails']) origin['notification_emails'] = []

    if (!origin['notification_users']) origin['notification_users'] = []

    if (newValues['notification_users'])
      newValues['notification_users'] = newValues['notification_users'].filter(
        e => targetKeys.indexOf(e) !== -1
      )

    return getDataDifference(origin, newValues)
  }

  patchAssignmentGroup = async (values, err, length) => {
    const {assignmentGroup} = this.props
    const data = this.createData(values)

    if (!length) {
      if (Object.keys(data).length > 0) {
        if (!err) {
          try {
            handleMessage(RequestStatus.REQUESTED)
            await patchAssignmentGroup(assignmentGroup.uuid, data)

            handleMessage(RequestStatus.SUCCEEDED)
          } catch (err) {
            handleMessage(RequestStatus.FAILED)
            throw new Error(err)
          }
        }
      } else {
        this.setState({loadingSubmitUsers: false})
      }
    } else {
      return Object.keys(data).length + Number(length)
    }
  }

  getUsersAssignmentGroups = async bookmark => {
    this.setState({loadingUsers: true})

    try {
      const res = await fetchUsers({
        selector: {
          assignment_groups: {
            $elemMatch: {
              $eq: this.props.assignmentGroup.uuid,
            },
          },
        },
        passedBookmark: bookmark,
        fields: ['uuid', 'assignment_groups', 'full_name', 'email'],
        resolve: RESOLVE,
      })

      this.setState(prevState => ({
        data: bookmark
          ? prevState.data.concat(res.body.result)
          : res.body.result,
      }))

      if (res.body.result.length === 10) {
        this.getUsersAssignmentGroups(res.body.bookmark)
      } else {
        const data = [...this.state.data, ...res.body.result]
        const dataUuid = data.map(e => e.uuid)
        await this.getUsersData(data)

        this.setState({
          targetKeys: dataUuid,
          initialUsers: data,
          bookmark: undefined,
          loadingUsers: false,
        })
      }
    } catch (err) {
      this.props.setToastMessage({message: err})
    }
  }

  getUsersData = usersData => {
    return new Promise(resolve => {
      const usersArr = []

      usersData.forEach(user => {
        const data = {
          key: user.uuid,
          title: user.full_name,
        }
        usersArr.push(data)
      })

      this.setState(
        prevState => ({
          usersData: [...prevState.usersData, ...usersData],
          users: [...prevState.users, ...usersArr],
        }),
        () => {
          resolve()
        }
      )
    })
  }

  updatingActions = (values, err) => {
    this.patchAssignmentGroup(values, err)
      .then(() => {
        this.setState({
          loadingSubmitUsers: false,
        })
        this.props.getEntityData()
      })
      .catch(err => {
        this.props.setToastMessage({message: err})
        this.setState({loadingSubmitUsers: false})
      })
  }

  patchUsers = async (uuid, data, resolve) => {
    try {
      handleMessage(RequestStatus.REQUESTED)
      await patchUser(uuid, data)

      handleMessage(RequestStatus.SUCCEEDED)
      resolve()
    } catch (err) {
      handleMessage(RequestStatus.FAILED)
      this.props.setToastMessage({message: err})
    }
  }

  getTargetUsers = checking => {
    const {uuid} = this.props.assignmentGroup
    return new Promise(resolve => {
      const usersAdded = this.state.usersData
        .filter(e => this.state.targetKeys.indexOf(e.uuid) !== -1)
        .filter(e =>
          e.assignment_groups
            ? e.assignment_groups.map(r => r.uuid).indexOf(uuid) === -1
            : true
        )

      const usersRemoved = (this.state.initialUsers || []).filter(
        e => this.state.targetKeys.indexOf(e.uuid) === -1
      )

      if (!checking) {
        let promisesAdded = []
        if (usersAdded.length > 0) {
          usersAdded.forEach(el => {
            const assignmentGroups = el.assignment_groups || []

            let data = {
              assignment_groups: [...assignmentGroups.map(e => e.uuid), uuid],
            }

            promisesAdded.push(
              new Promise((resolve, reject) => {
                this.patchUsers(el.uuid, data, resolve, reject)
              })
            )
          })
        }

        Promise.allSettled(promisesAdded).then(() => {
          let promisesRemoved = []

          if (usersRemoved.length > 0) {
            usersRemoved.forEach(el => {
              const assignmentGroups = el.assignment_groups || []

              let data = {
                assignment_groups: assignmentGroups
                  .filter(e => e.uuid !== uuid)
                  .map(e => e.uuid),
              }

              promisesRemoved.push(
                new Promise((resolve, reject) => {
                  this.patchUsers(el.uuid, data, resolve, reject)
                })
              )
            })
          }
          Promise.allSettled(promisesRemoved).then(() => {
            resolve()
          })
        })
      } else {
        if (usersAdded.length === 0 && usersRemoved.length === 0) {
          resolve([].length)
        } else {
          resolve([...usersAdded, ...usersRemoved].length)
        }
      }
    })
  }

  fetchTargetUsers = async bookmark => {
    try {
      const res = await fetchUsers({
        selector: {
          $and: [
            {
              $or: [
                {
                  assignment_groups: {
                    $allMatch: {
                      $ne: this.props.assignmentGroup.uuid,
                    },
                  },
                },
                {
                  assignment_groups: {$eq: []},
                },
                {
                  assignment_groups: {$exists: false},
                },
              ],
            },
            {
              full_name: {
                $regex: `(?i)${this.formRef.current &&
                  this.formRef.current.getFieldValue('user_search')}`,
              },
            },
          ],
        },
        passedBookmark: bookmark,
        fields: ['uuid', 'assignment_groups', 'org_name', 'full_name', 'email'],
        resolve: RESOLVE,
      })

      this.setState(prevState => ({
        usersFound: bookmark
          ? prevState.usersFound.concat(res.body.result)
          : res.body.result,
      }))

      if (res.body.result.length === 10) {
        this.fetchTargetUsers(res.body.bookmark)
      } else {
        await this.getUsersData(this.state.usersFound)

        this.setState({loadingSearch: false})
      }
    } catch (err) {
      this.props.setToastMessage({message: err})
    }
  }
  handleSubmitEdit = async () => {
    let values, error
    try {
      values =
        this.formRef.current && (await this.formRef.current.validateFields())
    } catch (err) {
      error = err
    }
    this.setState({loadingSubmitUsers: true}, () => {
      this.getTargetUsers()
        .then(() => {
          this.updatingActions(values, error)
        })
        .catch(err => {
          this.updatingActions(values, err)
          this.props.setToastMessage({message: err})
        })
    })
  }

  recognizeFieldChange = () => {
    this.getTargetUsers('checking').then(length => {
      this.patchAssignmentGroup(
        this.formRef.current && this.formRef.current.getFieldsValue(),
        undefined,
        length.toString()
      ).then(res => {
        this.setState({btnDisabled: res === 0})
      })
    })
  }

  handleSubmitSearch = () => {
    this.setState(
      prevState => ({
        loadingSearch: true,
        usersFound: [],
        usersData: prevState.usersData.filter(
          e => this.state.targetKeys.indexOf(e.uuid) !== -1
        ),
        users: prevState.users.filter(
          e => this.state.targetKeys.indexOf(e.key) !== -1
        ),
      }),
      () => {
        this.fetchTargetUsers()
      }
    )
  }

  onCancel = () => {
    const form = this.formRef.current

    this.props.closeFunction()
    form && form.resetFields()
  }

  getOrgRoles = async () => {
    try {
      const res = await fetchOrgsRole()
      const result = res.body.result.value
      const newRoles = []

      for (let key in result) {
        newRoles.push({
          value: key,
          name: result[key].name,
          role: result[key].role,
        })
      }
      this.setState({org_roles: newRoles})
    } catch (err) {
      this.props.setToastMessage({message: err})
    }
  }

  componentDidMount() {
    this.getUsersAssignmentGroups()
    this.getOrgRoles()
  }

  render() {
    const {loadingSubmitUsers, btnDisabled} = this.state
    return (
      <AssignmentGroupsForm
        props={this.props}
        state={this.state}
        handleSubmitSearch={this.handleSubmitSearch}
        handleSubmit={this.handleSubmitEdit}
        onRef={el => {
          this.formRef.current = el
        }}
        recognizeFieldChange={this.recognizeFieldChange}
        setTargetKeys={targetKeys =>
          this.setState({targetKeys}, () => this.recognizeFieldChange())
        }
        loadingSubmit={btnDisabled || loadingSubmitUsers}
        handleCancel={this.onCancel}
      />
    )
  }
}

export default withRouter(
  connect(null, {
    setToastMessage,
    setRefresh,
  })(AssignmentGroupsFormContainer)
)
