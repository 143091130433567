import {useCallback, useState} from 'react'

import {useDispatch} from 'react-redux'

import useNotifyAsyncProgress, {
  TLoadingMessageType,
} from '@/hooks/UseNotifyAsyncProgress'
import {RequestStatus} from '@/typedef'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

export function useFetchHook(messages?: TLoadingMessageType) {
  const [localError, setLocalError] = useState<string | unknown>('')
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const dispatch = useDispatch()

  useNotifyAsyncProgress(status, messages)
  return {
    status,
    error: localError,
    handleRequested: useCallback(() => setStatus(RequestStatus.REQUESTED), []),
    handleSuccess: useCallback(() => setStatus(RequestStatus.SUCCEEDED), []),
    handleFail: useCallback(
      (message: string | unknown) => {
        setStatus(RequestStatus.FAILED)
        setLocalError(message)
        dispatch(setToastMessage({message}))
      },
      [dispatch]
    ),

    handleReset: useCallback(() => {
      setStatus(RequestStatus.INITIAL)
      setLocalError('')
    }, []),
  } as const
}
