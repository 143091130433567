import React from 'react'

import {TCategory} from '@/modules/ITSM/typedef'
import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import {translate} from '@/services/i18n'
import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'

export const CategoriesRowValue = ({
  title,
  value,
}: TColumnRender<TCategory>) => {
  switch (title) {
    case 'type':
      return (
        <div>
          {parseToUpperCase(translate(value ? 'subcategory' : 'category'))}
        </div>
      )

    default:
      return <div>{value}</div>
  }
}
