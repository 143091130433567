import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

import {generateConfig} from '@/generateConfig'

import store from '../redux/store'

export const sentryInit = () => {
  const {
    ui: {
      version: {currentVersion},
    },
  } = store.getState()

  process.env.NODE_ENV === 'development' &&
    Sentry.init({
      dsn: generateConfig().SENTRY_DSN_KEY,
      autoSessionTracking: false,
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
      ],
      release: `blits@${currentVersion}`,
      environment: generateConfig().ENVIRONMENT,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    })
}

interface IAPIError {
  response?: {
    body?: {
      error?: string
    }
  }
}

const isAPIError = (error: unknown): error is IAPIError => {
  return typeof error === 'object' && error !== null && 'response' in error
}

export const handleAPIErrorBySentry = (
  err: unknown,
  userInfo: Record<string, any>
) => {
  Sentry.configureScope(scope => {
    scope.setTag('module', userInfo.module)
    scope.setTag('category', userInfo.category)

    if (isAPIError(err)) {
      scope.setExtras({
        errorResponse: err.response || err,
        userInfo,
      })
      Sentry.captureException(
        new Error(err.response?.body?.error || JSON.stringify(err))
      )
    } else {
      scope.setExtras({
        errorResponse: err,
        userInfo,
      })

      Sentry.captureException(new Error(JSON.stringify(err)))
    }
  })
}
