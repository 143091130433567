import React from 'react'

import {useHistory} from 'react-router-dom'

import SvgIcon from '@/components/svg-icon/svg-icon'
import '../success-payment/success-payment.scss'
import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import subscriptionsRoutes from '@/modules/Subscriptions/routes/subscriptions-routes'

export const FailedPayment = () => {
  const history = useHistory()
  const handleClick = () =>
    history.push(subscriptionsRoutes.plansPackages.path())

  return (
    <div className="wrapper">
      <SvgIcon
        name="blits-logo"
        width={128}
        height={42}
        className="logo-icon"
      />
      <div className="message-container">
        <SvgIcon
          name="error"
          height={32}
          stroke="#ff5757"
          className="message-container__icon"
        />
        <div className="mt-22 mb-16 ml-16 mr-16">
          <div className="message-container__text">
            {translate('payment_failed')}
          </div>
        </div>
        <Button
          title={translate('tryAgain')}
          className="message-container__button"
          onClick={handleClick}
        />
      </div>
    </div>
  )
}
