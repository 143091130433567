import {TAppState} from '@/redux/store'

import {TFilterListTable} from '../../components/itsm-list-table/typedf'
import {ItsmAssets} from '../../typedef'

export const selectItsmState = (state: TAppState) => {
  return state.itsmListTable
}

export const selectItsmAssetPagination = (
  state: TAppState,
  asset: ItsmAssets
) => {
  return selectItsmState(state)?.[asset]
}

export const selectItsmTableColumns = (state: TAppState, asset: ItsmAssets) => {
  return selectItsmState(state)?.[asset]?.columns
}

export const selectItsmTableSorters = (state: TAppState, asset: ItsmAssets) => {
  return selectItsmState(state)?.[asset]?.sort
}

export const selectItsmTableFilters = <T extends Record<string, any>>(
  state: TAppState,
  asset: ItsmAssets
) => {
  return (selectItsmState(state)?.[asset]?.filters || {}) as TFilterListTable<T>
}

export const selectItsmTableQuickFilter = (
  state: TAppState,
  asset: ItsmAssets
) => {
  return selectItsmState(state)?.[asset]?.quickFilter
}

export const selectItsmBookmark = (state: TAppState, asset: ItsmAssets) => {
  return selectItsmState(state)?.[asset]?.bookmark
}
