import {useCallback} from 'react'

import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import {THeaders} from '@/api/typedf'

export function usePatchEntity<T extends Record<string, any>, U = T>(
  asyncPatchF: (data: T, id: string) => Promise<ISuperAgentResSingle<U>>
): {
  readonly saveEntity: (
    data: T,
    id: string
  ) => Promise<ISuperAgentResSingle<U> | undefined>
  readonly saveEntityStatus: RequestStatus
} {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const saveEntity = useCallback(
    async (data: T, id: string) => {
      try {
        handleRequested()
        if (asyncPatchF) {
          const result = await asyncPatchF(data, id)
          if (result) {
            handleSuccess()
            return result
          }
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [asyncPatchF, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    saveEntity,
    saveEntityStatus: status,
  } as const
}

export function usePostEntity<T extends Record<string, any>, U = T>(
  asyncPostF: (
    data: T,
    id: string,
    headers?: THeaders
  ) => Promise<ISuperAgentResSingle<U>>
): {
  readonly saveEntity: (
    data: T,
    id?: string,
    headers?: THeaders
  ) => Promise<ISuperAgentResSingle<U> | undefined>
  readonly saveEntityStatus: RequestStatus
} {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const saveEntity = useCallback(
    async (data: T, id = '', headers?: THeaders) => {
      try {
        handleRequested()
        const result = await asyncPostF(data, id, headers)
        if (result) {
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [asyncPostF, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    saveEntity,
    saveEntityStatus: status,
  } as const
}
