import {Ability, AbilityBuilder, AbilityClass} from '@casl/ability'

import {Modules, PermissionsRole} from '@/typedef'
import {PriceFeatureFlagKeys} from '@/modules/typedef'

export type TActions = 'view' | 'edit'
type TSubjects = Modules | PriceFeatureFlagKeys | 'all' | 'userRole'
export type TAppAbility = Ability<[TActions, TSubjects]>
export const appAbility = Ability as AbilityClass<TAppAbility>

export default function defineRulesFor(
  role: string,
  featureFlag: PriceFeatureFlagKeys[],
  isStripeEnabled: boolean
) {
  const {can, cannot, rules} = new AbilityBuilder(appAbility)
  if (isStripeEnabled)
    rules.push({action: 'view', subject: [Modules.Subscriptions]})

  rules.push({
    action: 'view',
    subject: [Modules.ITSM, Modules.CMDB, Modules.SAAS, ...featureFlag],
  })
  switch (role) {
    case PermissionsRole.owner:
      can('edit', 'userRole')
      break
    default:
      cannot('view', [Modules.Subscriptions])
  }

  return rules
}

export function buildAbilityFor(
  role: string,
  featureFlag: PriceFeatureFlagKeys[],
  isStripeEnabled: boolean
): TAppAbility {
  return new appAbility(defineRulesFor(role, featureFlag, isStripeEnabled))
}
