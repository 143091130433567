import {generateConfig} from '@/generateConfig'

const mainURL = generateConfig().API_URL_STRIPE
const apiSuffix = generateConfig().API_SUFFIX_STRIPE
const apiPublicSuffix = generateConfig().API_SUFFIX_STRIPE_PUBLIC

const serviceAgreementsUrl = `${generateConfig().API_ITSM_URL}${
  generateConfig().API_ITSM_PATH_PREFIX
}${generateConfig().API_ITSM_V1}`

export const stripeApi = () => {
  return {
    getPaymentCards: () => `${mainURL}${apiSuffix}/payment-methods`,
    getProducts: () => `${mainURL}${apiSuffix}/products`,
    getPrices: () => `${mainURL}${apiPublicSuffix}/prices`,
    searchPrices: (metadata: string) =>
      `${mainURL}${apiSuffix}/prices/search?query=active:'true' AND ${metadata}&expand[]=data.product`,
    getSubscriptions: () => `${mainURL}${apiSuffix}/subscriptions`,
    postSubscriptions: () => `${mainURL}${apiSuffix}/subscriptions`,
    deleteSubscriptions: (subscriptionId: string) =>
      `${mainURL}${apiSuffix}/subscriptions/${subscriptionId}`,

    postSubscriptionItem: () => `${mainURL}${apiSuffix}/subscription-items`,

    deleteSubscriptionItem: (itemId: string) =>
      `${mainURL}${apiSuffix}/subscription-items/${itemId}`,

    createPaymentSession: () => `${mainURL}${apiSuffix}/sessions`,
    getInvoices: () => `${mainURL}${apiSuffix}/invoices`,
    getOneTimeInvoices: () =>
      `${mainURL}${apiSuffix}/invoices/search?query=customer:'_CUS_' AND metadata['type']:'one-time'`,
    stripeCustomer: () => `${mainURL}${apiSuffix}/customers`,
    createCheckoutSession: (cancel_url: string, success_url: string) =>
      `${mainURL}${apiSuffix}/checkout/sessions?cancel_url=${cancel_url}&success_url=${success_url}`,
    createCustomCheckoutSession: () =>
      `${mainURL}${apiSuffix}/custom/checkout/sessions`,
    getServiceAgreements: () => `${serviceAgreementsUrl}/accfiles`,
    uploadServiceAgreement: (name: string, queryParams?: string) =>
      `${serviceAgreementsUrl}/accfiles?name=${name}&${queryParams}`,
    downloadServiceAgreement: (id: string) =>
      `${serviceAgreementsUrl}/accfiles/${id}`,
  }
}
