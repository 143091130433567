import {translate} from '@/services/i18n'

export const rules = {
  phone: {
    pattern: new RegExp(/^[+]?([0-9]{6,})$/),
    message: translate('phone_number_is_not_valid'),
  },
  email: {
    pattern: new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
    message: translate('validate_email'),
  },
}
