import React, {useCallback, useEffect, useState} from 'react'

import {useDispatch} from 'react-redux'
import {Modal} from 'antd'
import moment from 'moment'
import {TableRowSelection} from 'antd/lib/table/interface'

import ErrorPage from '@/components/error/error-page/error-page'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import {Button} from '@/components/button'
import DataTable from '@/components/data-table/data-table'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {Pagination} from '@/components/pagination/pagination'

import {getAllAttachmentsV2} from '../../api/attachmentsRequests'
import {TAttachment} from '../../sites/Expenses/typedef'

import './attachments-modal.scss'

type TProps = {
  rowSelection: TableRowSelection<TAttachment>
  hide: () => void
  confirmAttachments: () => void
  visible: boolean
  entity: string
  id?: string
}

export const AttachmentsModal: React.FC<TProps> = props => {
  const {rowSelection, hide, confirmAttachments, visible, entity, id} = props
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(null)
  const [attachments, setAttachments] = useState<Array<TAttachment>>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const getColumns = () => {
    return [
      {
        title: translate('file_name'),
        dataIndex: ['metadata', 'file_name'],
        key: 'file_name',
        width: '60%',
      },
      {
        title: translate('createdAt'),
        dataIndex: ['metadata', 'created_at'],
        key: 'created_at',
        width: '40%',
        render: (val: string) => moment(val).format(DATE_FORMAT),
      },
    ]
  }

  const fetchAttachments = useCallback(
    async (pages?: number) => {
      setLoading(true)
      const offset = pages ? (pages - 1) * 10 : 0
      try {
        const res = await getAllAttachmentsV2(id, entity, offset)

        const attachmentsToSave = res.body.result.sort(
          (a: TAttachment, b: TAttachment) =>
            new Date(b?.metadata?.created_at || '').getTime() -
            new Date(a?.metadata?.created_at || '').getTime()
        )
        setTotalCount(res?.body?.total_count)
        setAttachments(attachmentsToSave)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        dispatch(setToastMessage({message: err}))
      }
    },
    [dispatch, entity, id]
  )

  useEffect(() => {
    if (visible) {
      fetchAttachments()
    }
  }, [visible, entity, id, dispatch, fetchAttachments])

  const handlePagination = (page: number) => {
    fetchAttachments(page)
    setPageNumber(page)
  }

  return (
    <Modal
      centered
      open={visible}
      onCancel={() => hide()}
      footer={[
        <Button
          key="back"
          title={translate('add')}
          type="primary"
          onClick={
            !rowSelection?.selectedRowKeys?.length
              ? hide
              : confirmAttachments || hide
          }
        />,
      ]}
      className="attachments-modal"
    >
      <SentryErrorBoundary
        fallback={
          <ErrorPage
            resolvers={[hide]}
            buttonTitle={translate('close')}
            noRedirect={true}
          />
        }
      >
        <DataTable<TAttachment>
          loading={loading}
          rowKey="uuid"
          scroll={{y: '60vh'}}
          columns={getColumns()}
          data={attachments}
          rowSelection={rowSelection}
          customPager={
            <Pagination
              current={pageNumber}
              onChange={handlePagination}
              total={totalCount || 10}
              pageSize={10}
              showSizeChanger={false}
            />
          }
        />
      </SentryErrorBoundary>
    </Modal>
  )
}
