import request from 'superagent'

import {
  IPrice,
  ISession,
  ISubscriptionDatum,
  TCustomer,
  TCustomerPayload,
} from '@/modules/typedef'
import {generateAuthHeaders, getBackendTokenHeaders} from '@/services/api'
import {stripeApi} from '@/modules/Subscriptions/api/api'

import {ISuperAgentResSingle} from './response-typedf'
import {THeaders, TPaymentSessionPayload} from './typedf'

export const fetchCustomer = (
  accessToken?: string
): Promise<ISuperAgentResSingle<TCustomer>> => {
  return request
    .get(stripeApi().stripeCustomer())
    .set(
      accessToken
        ? {Authorization: `Bearer ${accessToken}`}
        : getBackendTokenHeaders()
    )
}
export const getTopUpCheckoutSession = ({
  quantity,
  cancel_url,
  success_url,
}: {
  quantity: number
  cancel_url: string
  success_url: string
}) => {
  return request
    .post(stripeApi().createCheckoutSession(cancel_url, success_url))
    .set(getBackendTokenHeaders())
    .send({
      quantity,
    })
}

export const getCustomCheckoutSession = ({
  payload,
  headers,
}: {
  payload: TPaymentSessionPayload
  headers?: THeaders
}): Promise<ISuperAgentResSingle<ISession>> => {
  return request
    .post(stripeApi().createCustomCheckoutSession())
    .set(headers ? headers : getBackendTokenHeaders())
    .send(payload)
}

export const fetchPaymentCards = (token?: string) => {
  return request
    .get(stripeApi().getPaymentCards())
    .set(token ? generateAuthHeaders(token) : getBackendTokenHeaders())
}

export const fetchSubscriptions = (
  accessToken: string
): Promise<ISuperAgentResSingle<ISubscriptionDatum[]>> => {
  return request
    .get(stripeApi().getSubscriptions())
    .set({Authorization: `Bearer ${accessToken}`})
}

export const postSubscription = ({
  priceId,
  interval,
  type,
  trial = false,
  headers,
}: {
  priceId: string
  interval: string
  type: string
  trial?: boolean
  headers?: THeaders
}) => {
  return request
    .post(stripeApi().postSubscriptions())
    .set(headers || getBackendTokenHeaders())
    .send({price_id: priceId, trial, interval, type})
}

export const deleteSubscription = (subscriptionId: string) => {
  return request
    .delete(stripeApi().deleteSubscriptions(subscriptionId))
    .set(getBackendTokenHeaders())
}

export const postSubscriptionItem = ({
  priceId,
  subscriptionId,
}: {
  priceId: string
  subscriptionId: string
}) => {
  return request
    .post(stripeApi().postSubscriptionItem())
    .set(getBackendTokenHeaders())
    .send({
      price_id: priceId,
      subscription_id: subscriptionId,
    })
}

export const deleteSubscriptionItem = (item: string) => {
  return request
    .delete(stripeApi().deleteSubscriptionItem(item))
    .set(getBackendTokenHeaders())
}

export const fetchPrices = () => {
  return request.get(stripeApi().getPrices())
}

export const searchPricesByMetadata = ({
  queryParam,
  headers,
}: {
  queryParam: string
  headers?: THeaders
}): Promise<ISuperAgentResSingle<Array<IPrice>>> => {
  return request
    .get(stripeApi().searchPrices(queryParam))
    .set(headers || getBackendTokenHeaders())
}

export const fetchInvoices = () => {
  return request.get(stripeApi().getInvoices()).set(getBackendTokenHeaders())
}

export const fetchOneTimeInvoices = () => {
  return request
    .get(stripeApi().getOneTimeInvoices())
    .set(getBackendTokenHeaders())
}

export const getPortalSessionLink = (returnLink: string) => {
  return request
    .post(stripeApi().createPaymentSession())
    .set(getBackendTokenHeaders())
    .send({return_url: returnLink})
}

export const createCustomer = ({
  data,
  headers,
}: {
  data: TCustomerPayload
  headers?: THeaders
}): Promise<ISuperAgentResSingle<TCustomer>> => {
  return request
    .post(stripeApi().stripeCustomer())
    .set(headers || getBackendTokenHeaders())
    .send(data)
}

export const fetchServiceAgreements = () => {
  return request
    .get(stripeApi().getServiceAgreements())
    .set(getBackendTokenHeaders())
}

export const downloadServiceAgreement = (id: string) => {
  return request
    .get(stripeApi().downloadServiceAgreement(id))
    .set(getBackendTokenHeaders())
    .responseType('blob')
}

export const uploadServiceAgreement = (
  fileData: Record<string, any>,
  queryParams?: string
) => {
  const {data, name} = fileData
  return request
    .post(stripeApi().uploadServiceAgreement(name, queryParams))
    .send(data)
    .set(getBackendTokenHeaders())
}
