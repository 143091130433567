import React from 'react'

import IncidentsRequestsListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-incidents-requests/itsm-list-table-incidents-requests'

import {useGetIncidentRequestAssetQuickFilters} from '@/modules/ITSM/hooks/use-get-incident-request-asset-quick-filters'

import {ItsmAssets} from '../../typedef'
import {TIncident} from '@/modules/ITSM/components/incident-k-request/typedf'

const Incidents = () => {
  const {
    assetQuickFilters,
    isLoading,
  } = useGetIncidentRequestAssetQuickFilters()

  return (
    <IncidentsRequestsListTable<TIncident>
      asset={ItsmAssets.Incidents}
      assetQuickFilters={assetQuickFilters}
      isAssetQuickFiltersLoading={isLoading}
    />
  )
}

export default Incidents
