import React from 'react'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {withErrorBoundary} from '@sentry/react'
import {useDispatch} from 'react-redux'

import {Assets, Entities} from '@/typedef'
import {translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import ErrorPage from '@/components/error/error-page/error-page'
import {useGetDataList} from '@/modules/ITSM/hooks/use-get-data-list'
import {
  getComments,
  getSingleComment,
  markCommentAsReadByUser,
  postComment,
} from '@/modules/ITSM/api/ts-version/comment-requests'
import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {TIncident} from '@/modules/ITSM/components/incident-k-request/typedf'
import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {NotesCommentForm} from '../comments-list/notes-comments-form/notes-comments-form'
import {Comment} from '../../services/openAPI/Api'

type TProps = {
  isDisabled: boolean
  entityBR?: Entities
  idBR?: string
}

const asset = Assets.COMMENT

const Comments = ({isDisabled, entityBR, idBR}: TProps) => {
  const {entityData, entity} = useStateContext<TIncident>()
  const rdxDispatch = useDispatch()

  const {uuid: entityId} = entityData || {}

  const {
    entityData: commentsList,
    getEntityData: fetchComments,
    fetchStatus,
    recordCount,
    pagination,
    getSingleEntity,
  } = useGetDataList<Comment>({
    asyncF: getComments,
    entity: `${entityBR || entity}:${idBR || entityId}`,
    getSingleAsync: getSingleComment,
  })

  const handlePagination = async (bookmark?: string) => {
    await fetchComments({bookmark})
  }

  return (
    <NotesCommentForm
      id={idBR || entityId}
      isDisabled={isDisabled}
      loading={checkIsLoading(fetchStatus)}
      entity={entityBR || entity}
      saveEntityAsync={postComment}
      asset={asset}
      allComments={commentsList}
      fetchComments={fetchComments}
      getComment={getSingleEntity}
      markAsReadByUser={markCommentAsReadByUser}
      paginationComponent={
        <PaginationWithBookmarks
          pagination={pagination}
          fetch={async (bookmark?: string) => {
            try {
              await handlePagination(bookmark)
            } catch (err) {
              rdxDispatch(setToastMessage({message: err}))
            }
          }}
          numberOfRecords={recordCount}
        />
      }
      noDataMessage={translate('no_comments_added')}
    />
  )
}

export default withErrorBoundary(Comments, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
