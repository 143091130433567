import {AssigneeStates} from '../constants'
import {TIncident} from '../typedf'

export const findDisabledSteps = (entityData: TIncident | undefined) => {
  const {assignee_state, resolved_at, resolution_time_start} = entityData || {}

  if (resolved_at) {
    return []
  } else if (assignee_state === AssigneeStates.Unset) {
    return [1, 2, 3, 4]
  } else if (
    assignee_state === AssigneeStates.Proposed ||
    assignee_state === AssigneeStates.Rejected ||
    (assignee_state === AssigneeStates.Accepted && !resolution_time_start)
  ) {
    return [2, 3, 4]
  } else if (
    assignee_state === AssigneeStates.Accepted &&
    resolution_time_start
  ) {
    return [4]
  } else return []
}
