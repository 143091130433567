import React from 'react'

import classNames from 'classnames'

import './skeleton-item.scss'

export enum Type {
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
}

export type TProps = {
  width?: string | number
  height?: string | number
  type?: Type
  className?: string
}

export const SkeletonItem: React.FC<TProps> = ({
  width,
  height,
  className,
  type = Type.RECTANGLE,
}) => {
  const itemProps = {
    style: {width, height},
    className: classNames('skeleton-item', className, {
      'skeleton-item--circle': type === Type.CIRCLE,
    }),
  }

  return <div {...itemProps} />
}
