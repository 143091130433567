import {useCallback} from 'react'

import {RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResSingle} from '@/api/response-typedf'

import {postIncidentTask} from '../../../api/incidentTasksRequests'
import {TIncidentTaskReq} from '../typedf'

export function usePostIncidentTask(): {
  readonly saveEntity: (
    data: TIncidentTaskReq,
    id: string
  ) => Promise<ISuperAgentResSingle<TIncidentTaskReq> | undefined>
  readonly saveEntityStatus: RequestStatus
} {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const saveEntity = useCallback(
    async (data: TIncidentTaskReq, id: string) => {
      try {
        handleRequested()

        const {
          body: {result},
        } = await postIncidentTask(data, id)
        if (result) {
          handleSuccess()
          return result
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    saveEntity,
    saveEntityStatus: status,
  }
}
