import saveAs from 'file-saver'

import {getDoc} from '@/modules/ITSM/api/documents-api'
import {extensionPicker} from '@/modules/ITSM/utils/Helpers'
import {useFetchHook} from '@/hooks/useFetch'

export const useDownloadDocument = () => {
  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'downloading',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const downloadDocument = async (id: string, name: string) => {
    try {
      handleRequested()
      const res = await getDoc(id)

      const {body, type} = res
      const fileType = extensionPicker(type)
      const file = new Blob([body], {
        type,
      })
      saveAs(file, name + '.' + fileType)
      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  return {downloadDocument}
}
