export type TLoading = {
  [key: string]: boolean
}

export type TExpense = {
  attachment: TAttachment
  created_at?: string
  created_by?: string
  description: string
  docType?: string
  entity: string
  kind: string
  price: number
  uuid?: string
  xxx_version?: number
  approved?: boolean
  removed?: boolean
}

export type TAttachment = {
  data: Blob | MediaSource
  file_name: string
  file_type: string
  uuid?: string
  created_at?: string
  metadata?: {[key: string]: string}
}

export enum ExpensesActionType {
  APPROVE,
  REMOVE,
}

export enum ExpensesQuickFilters {
  None = 'none',
  Approved = 'approved',
  NotApproved = 'not-approved',
  Removed = 'removed',
  All = 'all',
}
