import React from 'react'

import './services-feature.scss'

import {ReactComponent as SvgTrue} from '../asserts/true.svg'

export const ServicesFeature = ({name}: {name: string}) => {
  return (
    <div className="services-feature mb-24" key={name}>
      <SvgTrue className="mr-12" />
      {name}
    </div>
  )
}
