import {translate} from '@/services/i18n/translate'
import React from 'react'

export const parseSubscriptionsMessage = {
  saas_management: (
    <>
      &#47;
      {translate('per_user')}
      &#47;
      {translate('per_month')}
    </>
  ),
  asset_management: (
    <>
      &#47;
      {translate('per_asset')}
      &#47;
      {translate('per_month')}
    </>
  ),
  ticket_management: (
    <>
      &#47;
      {translate('per_agent')}
      &#47;
      {translate('per_month')}
    </>
  ),
}
