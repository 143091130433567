import React from 'react'

import {Input, Select} from 'antd'

import {translate} from '@/services/i18n'
import {filterOption} from '@/utils/filter-option'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import FormItemCustom from '@/components/form/form-item-custom'

const {Option} = Select

const AssignmentGroupsFormNew = ({
  handleSubmit,
  recognizeFieldChange,
  orgRoles,
  form,
  loadingSubmit,
  handleCancel,
}) => {
  return (
    <DrawerForm
      // eslint-disable-next-line
      validateMessages={{required: '${label} is required'}}
      layout="vertical"
      name="drawer-category"
      onFinish={handleSubmit}
      form={form}
      onValuesChange={recognizeFieldChange}
      loadingSubmit={loadingSubmit}
      handleCancel={handleCancel}
      autoComplete="none"
    >
      <div>
        <FormItemCustom
          name="name"
          initialValue={''}
          rules={[
            {
              required: true,
            },
          ]}
          label={translate('name')}
        >
          <Input onFocus={setAutocompleteNone} />
        </FormItemCustom>

        <FormItemCustom
          name="org_name"
          initialValue={null}
          rules={[
            {
              required: true,
              message: `${translate('org_name')} ${translate('is_required')}`,
            },
          ]}
          label={translate('org_name')}
        >
          <Select
            allowClear
            showSearch
            filterOption={filterOption}
            getPopupContainer={e => e.parentNode}
          >
            {orgRoles &&
              Object.entries(orgRoles).map((e, i) => (
                <Option key={i} value={e[1].value}>
                  {e[1].name}
                </Option>
              ))}
          </Select>
        </FormItemCustom>
      </div>
    </DrawerForm>
  )
}

export default AssignmentGroupsFormNew
