export enum subscrCheckoutSessionSteps {
  INITIAL = 'INITIAL',
  ALL_INCLUSIVE_PRICE = 'ALL_INCLUSIVE_PRICE',
  CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION',
}

export const subscrCheckoutSessionStepProgress = {
  [subscrCheckoutSessionSteps.INITIAL]: 0,
  [subscrCheckoutSessionSteps.ALL_INCLUSIVE_PRICE]: 50,
  [subscrCheckoutSessionSteps.CREATE_CHECKOUT_SESSION]: 100,
}
