import {IncidentState} from '@/modules/ITSM/components/incident-k-request/constants'

import {TIncident} from '../../incident-k-request/typedf'

export const columns = [
  {
    title: 'number',
    dataIndex: 'number',
    index: 0,
    selected: true,
  },
  {
    title: 'short_description',
    dataIndex: 'short_description',
    index: 2,
    selected: true,
  },
  {
    title: 'priority',
    dataIndex: 'priority',
    index: 3,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'state_id',
    dataIndex: 'state_id',
    index: 4,
    selected: true,
    advancedRender: true,
  },
  {
    title: 'time_session',
    dataIndex: 'timesession',
    index: 5,
    selected: true,
    advancedRender: true,
  },
]

export const NON_ACTIVE_TICKET_STATE = [
  IncidentState.Resolved,
  IncidentState.Closed,
  IncidentState.Cancelled,
]

type TEntity = {
  [key: string]: string
}

export const EntityType: TEntity = {
  incidents: 'incident',
  requests: 'k_request',
}

export enum PricingType {
  daily = 'daily',
  monthly = 'monthly',
  per_visit = 'per_visit',
  per_ticket = 'per_ticket',
}

export const IncidentDefFields: Array<keyof TIncident> = [
  'uuid',
  'state_id',
  'customer_product',
  'supplier_product',
  'number',
  'short_description',
  'priority',
  'assigned_to',
  'assignee_state',
]
