import request from 'superagent'

import {getBackendTokenHeaders, organizationApi} from '@/services/api'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {TOrganization} from '@/typedef'
import {THeaders} from '@/api/typedf'

export const fetchOrganizations = async (
  headers: THeaders
): Promise<ISuperAgentResponse<Array<TOrganization>>> =>
  request
    .get(organizationApi.getOrg)
    .set({...getBackendTokenHeaders(), ...headers})
