import React, {useEffect, useState} from 'react'

import {Col, Form, Row, Select} from 'antd'
import {v4 as uuid} from 'uuid'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {TKey, translate} from '@/services/i18n'
import {TICKETS_TYPES} from '@/constants'
import {useFetchHook} from '@/hooks/useFetch'
import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {checkIsLoading} from '@/utils/check-is-loading'
import AssignmentGroupSelect from '@/modules/ITSM/components/form-components/assignment-group-select'
import {TAssignmentRule} from '@/modules/ITSM/typedef'
import AssignedToSelect from '@/modules/ITSM/components/form-components/assigned-to-select'
import {usePrevious} from '@/hooks/usePrevious'
import {
  categoriesApi,
  locationsApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {getFullLocation} from '../../../utils/Helpers'
import {
  patchAssignmentRule,
  postAssignmentRule,
} from '../../../api/assignmentRulesRequests'

const {Option} = Select

type TAssignmentRuleForm = Pick<TAssignmentRule, 'ticket_type'>

const AssignmentRulesDrawer = () => {
  const {
    getEntityData,
    getEntityDataInit,
    record,
    onClose,
  } = useListTableStateContext<TAssignmentRule>()

  const [form] = Form.useForm()

  const [, setRefresh] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
    status,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const prevRecord = usePrevious(record)

  useEffect(() => {
    if (!prevRecord && record) setRefresh(Math.random())
  }, [prevRecord, record])

  const {getFieldValue} = form

  const handleSubmit = async (values: TAssignmentRule) => {
    try {
      handleRequested()

      if (record?.uuid) {
        await patchAssignmentRule(record.uuid, values)
        getEntityData()
        setBtnDisabled(true)
      } else {
        await postAssignmentRule(uuid(), values)
        await getEntityDataInit()
        onClose()
      }

      handleSuccess()
    } catch (err) {
      setToastMessage({message: err})
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  const handleCategory = (val: string) => {
    form.setFieldsValue({subcategory: undefined})
    setRefresh(Math.random())
  }

  return (
    <>
      <DrawerForm<TAssignmentRule>
        form={form}
        onFinish={handleSubmit}
        loadingSubmit={checkIsLoading(status) || btnDisabled}
        handleCancel={onClose}
        onValuesChange={() => btnDisabled && setBtnDisabled(false)}
      >
        <div>
          <Row>
            <Col className="col-flex--two">
              <FormItemGeneric<TAssignmentRuleForm>
                name="ticket_type"
                initialValue={
                  record && record.ticket_type ? record.ticket_type : ''
                }
                rules={[
                  {
                    required: true,
                    message: translate('ticket_type_req'),
                  },
                ]}
                label={translate('ticket_type')}
              >
                <Select showSearch getPopupContainer={e => e.parentNode}>
                  {TICKETS_TYPES.map(ticket => (
                    <Option key={ticket} value={ticket.toUpperCase()}>
                      {translate(ticket as TKey)}
                    </Option>
                  ))}
                </Select>
              </FormItemGeneric>

              <SelectWithBM
                api={locationsApi().get}
                label={translate('location')}
                name="location"
                initialValue={record?.location ? [record.location] : []}
                rules={{
                  required: true,
                  message: translate('location_req'),
                }}
                optionContent={getFullLocation}
                searchKey="full_location"
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-flex--two">
              {/* @ts-ignore not refactored to typescript*/}
              <SelectWithBM
                api={categoriesApi().get}
                label={translate('category')}
                name="category"
                initialValue={record?.category ? [record.category] : []}
                selector={{parent: {$exists: false}}}
                searchKey="name"
                optionContent={(res: {name: string}) => res.name}
                onChange={handleCategory}
                onClear={handleCategory}
              />

              {/* @ts-ignore  not refactored to typescript*/}
              <SelectWithBM
                api={categoriesApi().get}
                label={translate('subcategory')}
                disabled={!getFieldValue('category')}
                name="subcategory"
                initialValue={record?.subcategory ? [record.subcategory] : []}
                selector={{
                  parent: {$in: [form.getFieldValue('category')]},
                }}
                searchKey="name"
                optionContent={(res: {name: string}) => res.name}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-flex--two">
              <AssignmentGroupSelect
                assignmentGroupInit={record?.assignment_group}
                setRefresh={() => setRefresh(Math.random())}
                resetAssignedTo={() => {
                  form.setFieldsValue({assignee: undefined})
                  setRefresh(Math.random())
                }}
                required={true}
              />

              <AssignedToSelect
                assignedToInit={record?.assignee}
                assignmentGroupValue={form.getFieldValue('assignment_group')}
                assignedToValue={form.getFieldValue('assignee')}
                setRefresh={() => setRefresh(Math.random())}
                isDisabled={!record && !form.getFieldValue('assignment_group')}
                name="assignee"
              />
            </Col>
          </Row>
        </div>
      </DrawerForm>
    </>
  )
}

export default AssignmentRulesDrawer
