import {call, put} from 'redux-saga/effects'
import {
  refreshOrgTokenFailed,
  setBETokenSuccess,
} from '@/redux/user/userActions'
import {getBEndToken} from '@/api/token-request'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

export function* backEndTokenSaga() {
  try {
    const {
      body: {access_token, expires_in},
    } = yield call(getBEndToken)
    yield put(setBETokenSuccess({token: access_token, tokenExp: expires_in}))
  } catch (err) {
    yield put(refreshOrgTokenFailed())
    yield put(
      setToastMessage({
        message: 'Getting token has failed, please report to you admin ',
      })
    )
  }
}
