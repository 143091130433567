import {AddonItemTypes} from '@/sites/services/typedef'

/*
 * If the addons are not required,
 * consider transforming the checkbox group into radio buttons to ensure
 * that only one option can be selected at a time.
 * This will help prevent users from selecting multiple options by accident.
 * */
export const validateInputValue = (
  addon: Partial<Record<AddonItemTypes, string | Array<string>>>,
  addonType: AddonItemTypes,
  item: string | Array<string>
) => {
  const isMultiple = [
    AddonItemTypes.RESPOND_TIME,
    AddonItemTypes.COUNTRY,
  ].includes(addonType)

  if (isMultiple) return item

  if (Array.isArray(item) && item.length > 1) {
    return item.filter(addonItem => addon[addonType]?.[0] !== addonItem)
  }

  return item
}
