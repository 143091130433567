import React, {FC, ReactNode} from 'react'

import {Checkbox, Tooltip} from 'antd'
import classnames from 'classnames'

import FormItemStyledWrapper from '@/components/form/form-item-styled-wrapper/form-item-styled-wrapper'

import './purchase-addon.scss'
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined'

type TProps = {
  title: string
  description?: string
  price: ReactNode
  checked: boolean
  disabled?: boolean
  onClickCheckbox?: (e: React.MouseEvent<HTMLElement>) => void
}

export const PurchaseAddon: FC<TProps> = ({
  title,
  description,
  price,
  checked,
  disabled,
  onClickCheckbox,
}) => {
  const handleClickCheckbox = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onClickCheckbox && onClickCheckbox(event)
  }

  return (
    <div
      className={classnames('purchase-addon', {
        'purchase-addon--checked': checked,
        'purchase-addon--active': !disabled,
      })}
      {...(!disabled ? {onClick: onClickCheckbox} : {})}
    >
      <div className="purchase-addon__header flex flex--alignCenter">
        <div className="purchase-addon__checkbox-wrapper">
          <FormItemStyledWrapper>
            <Checkbox
              className={classnames('purchase-addon__checkbox', {
                'purchase-addon__checkbox--required': !onClickCheckbox,
              })}
              name={title}
              {...(onClickCheckbox ? {onClick: handleClickCheckbox} : {})}
              checked={checked}
              disabled={disabled}
            />
          </FormItemStyledWrapper>
        </div>
        <span className="purchase-addon__title  mr-12 fw-500">{title}</span>
        <Tooltip title={description}>
          <InfoCircleOutlined />
        </Tooltip>
        <span className="purchase-addon__price  fw-700 text-right">
          {price}
        </span>
      </div>
    </div>
  )
}
