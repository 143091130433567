import React from 'react'

import {v4 as uuid} from 'uuid'
import {useHistory} from 'react-router-dom'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table/itsm-list-table'
import {ItsmAssets, TAnnouncement} from '@/modules/ITSM/typedef'
import {useGetDataListTable} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/hooks/use-get-data-list-table'
import {apiService} from '@/modules/ITSM/api/api-service'
import {entityNewPath} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/constants/routes-config'

import useGetQuickFilters from './hooks/use-get-quick-filters'

const Announcements = () => {
  const asset = ItsmAssets.Announcements
  const history = useHistory()

  const {
    getEntityData,
    entityData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTable<TAnnouncement>(apiService[asset], asset)

  const {getQuickFilters, setQuickFilters} = useGetQuickFilters(asset)

  const handleNew = () => {
    history.push(entityNewPath[asset]?.(uuid()))
  }

  return (
    <ItsmListTable<TAnnouncement>
      asset={ItsmAssets.Announcements}
      getEntityData={getEntityData}
      entityData={entityData}
      numberOfRecords={numberOfRecords}
      fetchStatus={fetchStatus}
      getQuickFilters={getQuickFilters}
      setQuickFilters={setQuickFilters}
      handleNew={handleNew}
    />
  )
}

export default Announcements
