import React from 'react'

import {Col, Row} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {translate} from '@/services/i18n'

import ErrorPage from '@/components/error/error-page/error-page'

import {TextContent} from '../text-content/text-content'
import {useStateContext} from '../incident/incident-context'

import {TIncidentTask} from './typedf'

const ResolutionSummary = () => {
  const {entityData} = useStateContext<TIncidentTask>()

  const {resolution_notes} = entityData || {}

  return (
    <>
      <Row>
        <Col span={24}>
          <TextContent
            text={resolution_notes || ''}
            isTextArea={true}
            className="mb-15"
            title={translate('resolution_notes')}
          />
        </Col>
      </Row>
    </>
  )
}

export default withErrorBoundary(ResolutionSummary, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
