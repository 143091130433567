import React from 'react'

import {ArrowLeftOutlined} from '@ant-design/icons'

import {Button, IButtonProps} from '@/components/button'
import {translate} from '@/services/i18n'

import './go-back-button.scss'

export const GoBackButton: React.FC<IButtonProps> = props => {
  return (
    <Button
      icon={<ArrowLeftOutlined />}
      type="text"
      title={translate('back')}
      className="go-back-button"
      e2e-test="go-back-button"
      {...props}
    />
  )
}
