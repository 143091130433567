import {ApiMethod, RequestStatusCode} from '@/components/error/typedef'
import {noPermission} from '@/modules/ITSM/utils/Constants'
import {translate} from '@/services/i18n'

const getMethodText = (error: Record<string, any> | null) => {
  switch (error?.response?.req?.method) {
    case ApiMethod.GET:
      return translate('unable_to_get_data')
    case ApiMethod.PATCH:
    case ApiMethod.POST:
      return translate('unable_to_submit_data')
    case ApiMethod.DELETE:
      return translate('unable_to_delete_data')
    default:
      return translate('oops_something_went_wrong')
  }
}

export const getErrorMessage = (error: Record<string, any> | null) => {
  const message = getMethodText(error)

  switch (true) {
    case error?.status === RequestStatusCode.STATUS_403:
      return `${message}. ${translate('no_permissions')}`

    case error?.status === RequestStatusCode.STATUS_200 &&
      error.message === noPermission:
      return `${message}. ${translate('no_permissions')}`

    case error?.status === RequestStatusCode.STATUS_500:
      return `${message}. ${translate('server_error')}`

    default:
      return message
  }
}
