import {countAddonsTotal} from '@/sites/purchase/utils/count-addons-total'
import {ModuleFeatureFlags, TNormalizedProduct} from '@/sites/purchase/typedef'

export const calculateTotalPrice = ({
  addons,
  extraAddons,
  modules,
}: {
  addons: Record<ModuleFeatureFlags, Array<TNormalizedProduct>>
  extraAddons: Array<TNormalizedProduct>
  modules: Array<TNormalizedProduct>
}) =>
  countAddonsTotal([
    ...modules,
    ...extraAddons,
    ...addons[ModuleFeatureFlags.ITSM],
    ...addons[ModuleFeatureFlags.CMDB],
    ...addons[ModuleFeatureFlags.SAAS],
  ])
