export type TRawData = {
  eurCustomer?: number
  eurSupplier?: number
  name?: string
  year?: string
  interval_end?: string
}

export type TLink = {
  Body: string
  Method: string
  URL: string
}

export type TBillingItem = {
  cost: number
  currency: string
  expenses: number
  interval_end: string
  interval_start: string
  link: TLink
  ref_no: string
  revenue: number
  travel_time: number
  uuid: string
  visits_count: number
  work_time: number
}

export type TBillingReportsFinanceDashboard = Array<TBillingItem>

export type TSelector = {
  date_from: string
  date_to: string
}

export type TMonth = {
  revenue?: number
  cost?: number
  month?: string
  year?: string
}

export type TYear = {
  year: string
  data: TBillingItem[]
}

export enum Stats {
  revenue = 'revenue',
  cost = 'cost',
}
