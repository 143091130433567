import React, {useCallback, useEffect, useRef, useState} from 'react'

import isEqual from 'lodash/isEqual'
import {Col, Form, Row} from 'antd'
import queryString from 'query-string'
import {
  AppstoreAddOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  PaperClipOutlined,
  ProfileOutlined,
  WechatOutlined,
} from '@ant-design/icons'

import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {getDetailsData} from '@/modules/ITSM/components/incident-k-request/incident-detail-mobile/get-details-data'
import './incident-detail-mobile.scss'
import IncidentAssignment from '@/modules/ITSM/components/incident/incident-assignment/incident-assignment'
import {scrollToField} from '@/utils/scroll-to-field'
import {
  TIncident,
  TIncidentReq,
  TIncidentValue,
} from '@/modules/ITSM/components/incident-k-request/typedf'
import {
  createData,
  getInitialValues,
} from '@/modules/ITSM/components/incident-k-request/incident-k-request-detail-form/utils'
import {Skeleton} from '@/components/skeleton'
import {Button} from '@/components/button'
import {DetailsCard} from '@/modules/ITSM/components/incident-k-request/incident-detail-mobile/details-card/details-card'
import {translate} from '@/services/i18n'
import {checkIfDisabled} from '@/modules/ITSM/components/incident-k-request/utils/check-if-disabled'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import {
  IncidentState,
  IncTabs,
} from '@/modules/ITSM/components/incident-k-request/constants'
import {TabTitle} from '@/components/tab-title/tab-title'
import Comments from '@/modules/ITSM/components/comments/comments'
import {
  getFromInProgressState,
  getFromOpenState,
} from '@/modules/ITSM/components/incident-k-request/incident-k-request-tabs/utils'
import TimeLogDetail from '@/modules/ITSM/components/time-logs/time-log-detail'
import TimeLogs from '@/modules/ITSM/components/time-logs/time-logs'
import WorkNotes from '@/modules/ITSM/components/work-notes/work-notes'
import AttachmentsTab from '@/modules/ITSM/components/attachments/attachments'
import {Tabs} from '@/components/tabs'
import {findDisabledSteps} from '@/modules/ITSM/components/incident-k-request/utils/find-disabled-steps'
import ResolutionForm from '@/modules/ITSM/components/incident-k-request/incident-k-request-resolution/incident-k-request-resolution-form'
import {setCurrentTab} from '@/modules/ITSM/components/incident/incident-reducer'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {RequestStatus} from '@/typedef'
import {usePrevious} from '@/hooks/usePrevious'

import {isTimelogDisabled} from '../utils/is-timelog-diasabled'
import DescriptionEditor from '../../description-editor/description-editor'
import {TOnUpdateParams} from '../../attachments/attachments-button'

import {IncidentDetailMobileSkeletonView} from './incident-detail-mobile-skeleton-view'

type TProps = {
  loading?: boolean
}

export const IncidentDetailMobile: React.FC<TProps> = ({loading}) => {
  const [form] = Form.useForm()

  const {
    incState,
    entityData,
    dispatch,
    updateEntity,
    getData,
    isLoading,
  } = useStateContext<TIncident>()
  const {
    state_id,
    timelogs,
    description,
    supplier_product,
    customer_product,
    assignment_group,
    assigned_to,
  } = entityData || {}

  const {currentTab} = incState || {}

  const isDisabled = !!(entityData && state_id && checkIfDisabled(state_id))

  const [, setRefresh] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const inProgressCondition = !!(state_id && getFromInProgressState(state_id))

  const detailsData = entityData && getDetailsData(entityData)
  const {
    editorState,
    onEditorStateChange,
    editorText,
    setText,
  } = useSetEditorState(description)

  const handleUploadAttachment = async () => {
    const {
      filesAmount = 0,
      addAttachments: addFileAttachments,
      status: filesAttachmentStatus = RequestStatus.INITIAL,
    } = filesDataRef.current || {}
    const saveAttachments = async () => {
      if (addFileAttachments) {
        await addFileAttachments()
      }
    }
    if (filesAmount > 0 && filesAttachmentStatus === RequestStatus.INITIAL) {
      saveAttachments()
    }
  }

  const getFormattedData = useCallback(
    (values: TIncidentReq) => {
      values['description'] = editorText
      return createData({...values})
    },
    [editorText]
  )
  const handleUpdateIncident = async (values: TIncidentValue) => {
    const data = getFormattedData({
      ...values,
      cmdb_ref: values.cmdb_ref ? [values.cmdb_ref] : undefined,
    })
    const result =
      entityData &&
      (await updateEntity(
        getDataDifference(getInitialValues(entityData), data)
      ))
    if (result !== undefined) {
      setBtnDisabled(true)
      await getData()
    }
  }
  const filesDataRef = useRef<TOnUpdateParams>()
  const prevEditorText = usePrevious(editorText)
  const checkValues = useCallback(() => {
    entityData &&
      setBtnDisabled(
        isEqual(form.getFieldsValue(), getInitialValues(entityData))
      )
  }, [entityData, form])

  useEffect(() => {
    if (prevEditorText !== editorText) checkValues()
  }, [checkValues, editorText, prevEditorText])

  const onAddFilesChange = (params: TOnUpdateParams) => {
    filesDataRef.current = {...params}
    if (params.filesAmount > 0) {
      setBtnDisabled(false)
    }
  }
  const onAddedAttachments = () => {
    form.submit()
  }
  // const isDisabled = state_id && checkIfDisabled(state_id)
  const fromOpenState = !!(entityData && getFromOpenState(entityData))

  const onTabChange = (val: IncTabs) => {
    dispatch(setCurrentTab(val))
  }

  return (
    <Tabs
      tabPosition={'bottom'}
      onTabClick={e => onTabChange(e as IncTabs)}
      activeKey={currentTab}
      className={'incident-detail-mobile'}
      items={[
        {
          label: (
            <div className="incident-detail-mobile__tab">
              <HomeOutlined />
              {translate('general')}
            </div>
          ),
          key: IncTabs.OverviewTab,
          children: (
            <div>
              <Skeleton
                active={Boolean(loading)}
                view={<IncidentDetailMobileSkeletonView />}
              >
                {detailsData?.length
                  ? detailsData.map((item, i) => (
                      <DetailsCard key={i} {...{...item}} />
                    ))
                  : null}
                <Form
                  validateMessages={{
                    // eslint-disable-next-line
                    required: '${label} is required',
                  }}
                  onFinish={(values: TIncidentValue) => {
                    filesDataRef.current &&
                    filesDataRef.current.filesAmount > 0 &&
                    filesDataRef.current.status === RequestStatus.INITIAL
                      ? handleUploadAttachment()
                      : handleUpdateIncident(values)
                  }}
                  form={form}
                  layout="vertical"
                  onValuesChange={checkValues}
                  onFinishFailed={errorFields =>
                    scrollToField({errorFields, form})
                  }
                >
                  <IncidentAssignment
                    form={form}
                    setRefresh={() => setRefresh(Math.random())}
                    supplierProduct={supplier_product}
                    customerProduct={customer_product}
                    assignmentGroup={assignment_group}
                    assignedTo={assigned_to}
                    stateId={state_id}
                  />
                  <DescriptionEditor
                    onEditorStateChange={onEditorStateChange}
                    editorState={editorState}
                    isDisabled={isDisabled}
                    description={description}
                    setText={setText}
                    editorText={editorText}
                    immediateAttachments={true}
                    onAddFiles={onAddFilesChange}
                    onAddAttachments={onAddedAttachments}
                  />
                  {!isDisabled && (
                    <Row>
                      <Col span={24} className="mb-30 mt-10">
                        <Button
                          title={translate('save')}
                          disabled={isLoading || btnDisabled}
                          htmlType="submit"
                          type="primary"
                          size="large"
                        />
                      </Col>
                    </Row>
                  )}
                </Form>
              </Skeleton>
            </div>
          ),
        },
        {
          label: (
            <div className="incident-detail-mobile__tab">
              <WechatOutlined />
              {translate('comments')}
            </div>
          ),

          key: IncTabs.CommentsTab,
          children: (
            <div>
              <Comments isDisabled={isDisabled} />
            </div>
          ),
        },

        ...(fromOpenState &&
        timelogs &&
        entityData &&
        !isTimelogDisabled(entityData)
          ? [
              {
                label: (
                  <div className="incident-detail-mobile__tab">
                    <FieldTimeOutlined />
                    {translate('time_logs')}
                  </div>
                ),
                key: IncTabs.TimeLogsTab,
                children: (
                  <div>
                    {queryString.parse(window.location.search).timelog ? (
                      <TimeLogDetail />
                    ) : (
                      <>
                        <TabTitle title={translate('time_logs')} />
                        <TimeLogs
                          editableState={inProgressCondition}
                          checkInBtnVisible={
                            state_id === IncidentState.Open ||
                            state_id === IncidentState.Pre_on_hold ||
                            state_id === IncidentState.In_progress ||
                            state_id === IncidentState.On_hold
                          }
                        />
                      </>
                    )}
                  </div>
                ),
              },
            ]
          : []),
        {
          label: (
            <div className="incident-detail-mobile__tab">
              <ProfileOutlined />
              {translate('work_notes')}
            </div>
          ),
          key: IncTabs.WorkNotesTab,
          children: (
            <div>
              <WorkNotes isDisabled={isDisabled} />
            </div>
          ),
        },
        {
          label: (
            <div className="incident-detail-mobile__tab">
              <PaperClipOutlined />
              {translate('attachments')}
            </div>
          ),
          key: IncTabs.AttachmentsTab,
          children: (
            <div>
              <AttachmentsTab disField={false} />
            </div>
          ),
        },

        ...(inProgressCondition ||
        (state_id === IncidentState.Cancelled &&
          findDisabledSteps(entityData).indexOf(3) === -1)
          ? [
              {
                label: (
                  <span className="incident-detail-mobile__tab">
                    <AppstoreAddOutlined />
                    {translate('resolution')}
                  </span>
                ),
                key: IncTabs.ResolutionTab,
                children: (
                  <div>
                    {currentTab === IncTabs.ResolutionTab && (
                      <>
                        <ResolutionForm editableState={inProgressCondition} />
                      </>
                    )}
                  </div>
                ),
              },
            ]
          : []),
      ]}
    />
  )
}
