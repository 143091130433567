import {ModuleFeatureFlags} from '@/sites/purchase/typedef'

export const basicList = {
  unlimited_support: {
    id: 'unlimited_support',
    title: 'Unlimited number of support agents and end-users.',
    description: 'Unlimited number of support agents and end-users',
  },
  service_desk_automation: {
    id: 'service_desk_automation',
    title: 'Service desk automation.',
    description: 'Service desk automation.',
  },
  end_user_self_service_portal: {
    id: 'end_user_self_service_portal',
    title: 'End-user self-service portal.',
    description: 'End-user self-service portal.',
  },
  support_for_one_channel: {
    id: 'support_for_one_channel',
    title: '1 Organization and 1 support channel.',
    description: '1 Organization and 1 support channel.',
  },
  one_sla: {
    id: 'one_sla',
    title: '1 SLA with multiple escalations matrix.',
    description: '1 SLA with multiple escalations matrix.',
  },
  workflows_automation: {
    id: 'workflows_automation',
    title: 'Workflows Automation.',
    description: 'Workflows Automation.',
  },
  service_catalog: {
    id: 'service_catalog',
    title: 'Service catalog.',
    description: 'Service catalog.',
  },
  documentation_management: {
    id: 'documentation_management',
    title: 'Documentation management.',
    description: 'Documentation management.',
  },
  basic_support: {
    id: 'basic_support',
    title: 'Basic support.',
    description: 'Basic support.',
  },
  azure_ad_free_plan: {
    id: 'azure_ad_free_plan',
    title: 'Use with Azure AD free plan.',
    description: 'Use with Azure AD free plan.',
  },
  manage_single_identity_provider: {
    id: 'manage_single_identity_provider',
    title: 'Manage 1 identity provider (Azure AD).',
    description: 'Manage 1 identity provider (Azure AD).',
  },
  manage_unlimited_saas_apps_and_users: {
    id: 'manage_unlimited_saas_apps_and_users',
    title: 'Manage unlimited SaaS apps and users.',
    description: 'Manage unlimited SaaS apps and users.',
  },
  manage_multiple_saas_apps: {
    id: 'manage_multiple_saas_apps',
    title: 'Add or remove users to multiple SaaS apps with a single click.',
    description:
      'Add or remove users to multiple SaaS apps with a single click.',
  },
  manage_user_groups_of_saas_apps: {
    id: 'manage_user_groups_of_saas_apps',
    title: 'Assign user groups to multiple SaaS apps with a single click.',
    description:
      'Assign user groups to multiple SaaS apps with a single click.',
  },
  retain_audit_logs: {
    id: 'retain_audit_logs',
    title: 'Retain audit logs indefinitely.',
    description: 'Retain audit logs indefinitely.',
  },
  on_premise_assets_discovery: {
    id: 'on_premise_assets_discovery',
    title: 'On-premise assets discovery.',
    description: 'On-premise assets discovery.',
  },
  could_assets_discovery: {
    id: 'could_assets_discovery',
    title: 'Could assets discovery.',
    description: 'Could assets discovery.',
  },
  remote_users_asset_discovery: {
    id: 'remote_users_asset_discovery',
    title: 'Remote users asset discovery.',
    description: 'Remote users asset discovery.',
  },
  seamless_integration: {
    id: 'seamless_integration',
    title: 'Seamless integration with BLiTS ticketing and SaaS system.',
    description: 'Seamless integration with BLiTS ticketing and SaaS system.',
  },
  assets_lifecycle_management: {
    id: 'assets_lifecycle_management',
    title: 'Assets lifecycle management.',
    description: 'Assets lifecycle management.',
  },
  assets_minimum_amount: {
    id: 'assets_minimum_amount',
    title: 'Minimum 100 assets.',
    description: 'Minimum 100 assets.',
  },
  users_minimum_amount: {
    id: 'users_minimum_amount',
    title: 'Minimum 10 users.',
    description: 'Minimum 10 users.',
  },
}

export const detailsModules = {
  [ModuleFeatureFlags.ITSM]: {
    basicAddons: [
      basicList.unlimited_support,
      basicList.service_desk_automation,
      basicList.end_user_self_service_portal,
      basicList.support_for_one_channel,
      basicList.one_sla,
      basicList.workflows_automation,
      basicList.service_catalog,
      basicList.documentation_management,
      basicList.basic_support,
    ],
  },
  [ModuleFeatureFlags.SAAS]: {
    basicAddons: [
      basicList.azure_ad_free_plan,
      basicList.manage_single_identity_provider,
      basicList.manage_unlimited_saas_apps_and_users,
      basicList.manage_multiple_saas_apps,
      basicList.manage_user_groups_of_saas_apps,
      basicList.retain_audit_logs,
      basicList.users_minimum_amount,
      basicList.basic_support,
    ],
  },
  [ModuleFeatureFlags.CMDB]: {
    basicAddons: [
      basicList.on_premise_assets_discovery,
      basicList.could_assets_discovery,
      basicList.remote_users_asset_discovery,
      basicList.seamless_integration,
      basicList.assets_lifecycle_management,
      basicList.assets_minimum_amount,
      basicList.basic_support,
    ],
  },
}
