import {useCallback, useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import {ItsmAssets} from '@/modules/ITSM/typedef'
import {TKey, translate} from '@/services/i18n'
import {setItsmTableQuickFilters} from '@/modules/ITSM/store/list-table/table-actions'
import {selectItsmTableQuickFilter} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

import {EventsQuickFilters} from '../constants'

const useGetQuickFilters = (asset: ItsmAssets) => {
  const {option: quickFilterParam} = useParams<{option: EventsQuickFilters}>()

  const dispatch = useDispatch()

  const history = useHistory()

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, asset)
  )

  useEffect(() => {
    if (quickFilterParam !== quickFilterActive) {
      history.replace(
        itsmRoutes.serviceManagementEventsPage.path(EventsQuickFilters.All, '1')
      )
    }
  }, [history, quickFilterActive, quickFilterParam])

  const getAssetQuickFilters = useCallback(
    (): Array<{
      name: EventsQuickFilters
    }> => [
      {name: EventsQuickFilters.All},
      {name: EventsQuickFilters.NotApproved},
      {name: EventsQuickFilters.Approved},
    ],
    []
  )

  const getQuickFilters = () => {
    return (getAssetQuickFilters() || []).map(({name}) => ({
      title: translate(name as TKey),
      isActive: quickFilterActive === name,
      onClick: () => {
        dispatch(setItsmTableQuickFilters(asset, name))
        history.replace(itsmRoutes.serviceManagementEventsPage.path(name, '1'))
      },
    }))
  }
  return {getQuickFilters}
}

export default useGetQuickFilters
