import {useEffect, useState} from 'react'

import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {checkNoIdentity} from '../utils/check-no-indentity'
import {
  fetchRoles,
  getRolesGrantableByMe,
  getUserRoles,
} from '../api/rolesRequests'

export const useGetRoles = id => {
  const dispatch = useDispatch()

  const [rolesToDisplay, setRolesToDisplay] = useState([])
  const [noIdentity, setNoIndentity] = useState(false)
  const [notGrantableByMe, setNotGrantableByMe] = useState([])
  const [userRoles, setUserRoles] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [myRoles, setMyRoles] = useState([])

  const getRoles = (rolesAll, user_roles, roles_grantableByMe) => {
    const userRolesData = user_roles
      ? rolesAll.filter(e => user_roles.indexOf(e.uuid) !== -1)
      : []

    const grantableByMe = [...roles_grantableByMe]

    const notGrantableByMe = userRolesData.filter(
      e => grantableByMe.map(r => r.uuid).indexOf(e.uuid) === -1
    )

    const allRoles = [...grantableByMe, ...notGrantableByMe]

    let indexArr = []
    notGrantableByMe.forEach(e => {
      indexArr.push(allRoles.map(r => r.uuid).indexOf(e.uuid))
    })

    const roles = []

    allRoles.forEach((role, i) => {
      const data = {
        key: role.uuid,
        title: role.name,
        disabled: indexArr.indexOf(i) !== -1,
      }
      roles.push(data)
    })

    setRolesToDisplay(roles)

    setNotGrantableByMe(notGrantableByMe)
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true)

        const userRolesRes = await getUserRoles(id)

        const {
          body: {
            result: {roles: userRolesData},
          },
        } = userRolesRes
        setUserRoles(userRolesData)

        const rolesGrantableByMeRes = await getRolesGrantableByMe()

        const {
          body: {result: rolesGrantableByMe},
        } = rolesGrantableByMeRes
        setMyRoles(rolesGrantableByMe)

        const allRolesRes = await fetchRoles({})

        const {
          body: {result: allRoles},
        } = allRolesRes

        getRoles(allRoles, userRolesData, rolesGrantableByMe)
        setLoading(false)
      } catch (err) {
        if (checkNoIdentity(err)) {
          setNoIndentity(true)
          setLoading(false)
          return
        }
        setLoading(false)
        dispatch(setToastMessage({message: err}))
      }
    }

    if (!userRoles && id) {
      loadData()
    }
  }, [userRoles, id, dispatch])

  return [
    noIdentity,
    rolesToDisplay,
    notGrantableByMe,
    userRoles,
    loading,
    myRoles,
  ]
}
