import * as ActionTypes from '@/redux/actionTypes'

export const fetchCmdbAccountIdentityMe = () => ({
  type: ActionTypes.FETCH_CMDB_IDENTITY_ME_REQUESTED,
})

export const fetchCmdbIdentityMeFailed = (err?: Record<string, unknown>) => ({
  type: ActionTypes.FETCH_CMDB_IDENTITY_ME_FAILED,
  payload: err,
})

export const fetchSaasIdentityMe = () => ({
  type: ActionTypes.FETCH_SAAS_IDENTITY_ME_REQUESTED,
})

export const fetchSaasIdentityMeFailed = (err?: Record<string, unknown>) => ({
  type: ActionTypes.FETCH_SAAS_IDENTITY_ME_FAILED,
  payload: err,
})

export const fetchIdentityMeFailed = (err?: Record<string, unknown>) => ({
  type: ActionTypes.FETCH_IDENTITY_ME_FAILED,
  payload: err,
})
