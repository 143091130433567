import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {notificationsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TNotification} from '../typedef'

export const postNotification = async (id: string, data: TNotification) => {
  return await request
    .post(notificationsApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const patchNotification = async (id: string, data: TNotification) => {
  return await request
    .patch(notificationsApi(id).patch)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchNotifications = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(notificationsApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })
