import {Coords} from 'google-map-react'

import {
  AddressTypes,
  filterByAddressType,
  getLongNames,
  mergeStreetsAndStreetsNumbers,
} from '@/utils/parse-geocoding-response'

type TParseGeocodingRequestData = (
  requestData: any
) => {
  streets: string[]
  zipCode: string
  coordinates: Coords
}

export const parseGeocodingResponse: TParseGeocodingRequestData = requestData => {
  const data = requestData[0]
  const {geometry = {}, address_components: addressComponents = []} = data

  const streets = getLongNames(
    filterByAddressType(addressComponents, AddressTypes.ROUTE)
  )
  const streetNumbers = getLongNames(
    filterByAddressType(addressComponents, AddressTypes.STREET_NUMBER)
  )

  const zipCode = getLongNames(
    filterByAddressType(addressComponents, AddressTypes.POSTAL_CODE)
  )

  const {lat, lng} = geometry.location

  return {
    streets: mergeStreetsAndStreetsNumbers(streets, streetNumbers),
    zipCode: zipCode[0],
    coordinates: {lat: lat(), lng: lng()},
  }
}
