type TCurrencyKeys = 'EUR' | 'USD'

export const generateCurrency = (
  costs: Record<TCurrencyKeys, Record<string, string>>
) => ` ${Object.keys(costs)[0] || 'EUR'}`

export const getTotalAmountOfTime = (EURtime: string, UStime: string) => {
  if (Number(EURtime) + Number(UStime) !== 0)
    return (
      Math.round((Number(EURtime) + Number(UStime)) / 3600).toLocaleString(
        undefined
      ) + ' h'
    )

  return 0 + ' h'
}
