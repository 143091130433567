import React, {useState} from 'react'

import {Tooltip} from 'antd'
import {useSelector} from 'react-redux'
import {CloseOutlined} from '@ant-design/icons'

import {
  AssigneeStates,
  IncidentState,
  IncTabs,
} from '@/modules/ITSM/components/incident-k-request/constants'
import {translate} from '@/services/i18n'
import {Button} from '@/components/button'
import {ShareButton} from '@/components/share-button/share-button'
import {generateCopyLink} from '@/modules/ITSM/utils/Helpers'
import {selectUserID, selectUserOrgRole} from '@/redux/user/userSelector'
import CheckInBtn from '@/modules/ITSM/components/time-logs/check-in-btn'
import {TTimeLog} from '@/modules/ITSM/typedef'
import CancellationModal from '@/modules/ITSM/components/incident-k-request/incident-k-request-cancellation-modal/incident-k-request-cancellation-modal'
import {checkIsLoading} from '@/utils/check-is-loading'
import {Icon, IconType} from '@/components/icon'
import {UserRole} from '@/typedef'
import {ModalType} from '@/components/modal/typedef'
import Modal from '@/components/modal/modal'

import {TIncident} from '../typedf'
import {useCloseIncident} from '../../incident/hooks/useCloseIncident'
import {useAcceptOrReject} from '../../incident/hooks/useAcceptOrReject'
import {useStateContext} from '../../incident/incident-context'
import {
  setCurrentTab,
  setOnHold,
  setRejectReason,
} from '../../incident/incident-reducer'
import './incident-k-request-btns.scss'
import {isTimelogDisabled} from '../utils/is-timelog-diasabled'

type TProps = {
  cancellationRight: boolean
  closingRight: boolean
}
const IncidentKrequestBtns = ({cancellationRight, closingRight}: TProps) => {
  const [cancelAction, setCancelAction] = useState(false)
  const [visible, setVisible] = useState(false)

  const {
    entityData,
    dispatch,
    updateEntity,
    getData,
    entity,
    isLoading,
    incState,
  } = useStateContext<TIncident>()

  const {
    saveEntity: acceptOrReject,
    saveEntityStatus: acceptOrRejectStatus,
  } = useAcceptOrReject()
  const {
    saveEntity: closeIncident,
    saveEntityStatus: closeIncidentStatus,
  } = useCloseIncident()

  const userID = useSelector(selectUserID)
  const userRole = useSelector(selectUserOrgRole)

  const {timelogs, state_id, customer_product, assigned_to, assignee_state} =
    entityData || {}

  const isTimelogOpen =
    timelogs && timelogs.some((timelog: TTimeLog) => !timelog.end)

  const assignedToCurrentUser = assigned_to?.uuid === userID

  const {currentTab, isRejectReason, isOnHold} = incState || {}

  const btnsGroup = [
    {
      visible:
        (state_id === IncidentState.In_progress ||
          state_id === IncidentState.On_hold) &&
        isTimelogOpen &&
        assignedToCurrentUser,

      title: translate('check_out'),
      action: () => dispatch(setCurrentTab(IncTabs.TimeLogsTab)),
      disabled: !!(currentTab && currentTab === IncTabs.TimeLogsTab),
    },
    {
      visible:
        (state_id === IncidentState.In_progress ||
          state_id === IncidentState.On_hold) &&
        assignedToCurrentUser,
      title: translate('resolve'),
      action: () => dispatch(setCurrentTab(IncTabs.ResolutionTab)),
      tooltipTitle: isTimelogOpen ? translate('timelog_must_be_closed') : null,
      disabled:
        !!isTimelogOpen ||
        !!(currentTab && currentTab === IncTabs.ResolutionTab),
    },
    {
      visible: state_id === IncidentState.Open && cancellationRight,
      title: translate('cancel'),
      action: () => setCancelAction(true),
      tooltipTitle: isTimelogOpen ? translate('timelog_must_be_closed') : null,
      disabled: !!isTimelogOpen,
      icon: <CloseOutlined />,
    },
    {
      visible:
        state_id === IncidentState.On_hold ||
        state_id === IncidentState.Pre_on_hold,
      title: translate('start'),
      e2eTest: 'start-btn',
      disabled: isLoading,
      action: async () => {
        const result = await updateEntity({
          state_id:
            state_id === IncidentState.On_hold
              ? IncidentState.In_progress
              : IncidentState.Open,
        })
        if (result !== undefined) getData(true)
      },
    },
    {
      visible:
        state_id === IncidentState.Open ||
        state_id === IncidentState.In_progress,
      title: translate('put_on_hold'),
      e2eTest: 'put-on-hold-btn',
      action: () => {
        dispatch(setOnHold(true))
      },
      tooltipTitle:
        customer_product === undefined
          ? translate('customer_product_must_be_selected')
          : null,
      disabled: customer_product === undefined || isOnHold,
      icon: <Icon type={IconType.PAUSE} size={15} />,
    },
    {
      visible:
        userRole !== UserRole.SUPPLIER && state_id === IncidentState.Resolved,
      title: translate('reopen'),
      disabled: isLoading,
      action: async () => {
        const result = await updateEntity({
          state_id: IncidentState.In_progress,
        })
        if (result !== undefined) getData(true)
      },
    },
    {
      visible: closingRight && state_id === IncidentState.Resolved,
      title: translate('close'),
      disabled: checkIsLoading(closeIncidentStatus),
      action: async () => {
        const result =
          entityData && (await closeIncident(`${entity}:${entityData.uuid}`))
        if (result !== undefined) getData(true)
      },
    },
  ]

  const handleAcceptIncident = async () => {
    const result =
      entityData &&
      (await acceptOrReject({
        entity: `${entity}:${entityData.uuid}`,
        decision: 'accepted',
      }))

    if (result !== undefined) getData(true)
  }

  const handleRejectIncident = () => {
    entityData && dispatch(setCurrentTab(entityData.assignee_state))
    dispatch(setRejectReason(true))
  }

  const resetCancelAction = () => {
    setCancelAction(false)
  }

  const incident_link = entityData && generateCopyLink(entity, entityData.uuid)

  const checkInBtnVisible =
    state_id === IncidentState.Open ||
    state_id === IncidentState.Pre_on_hold ||
    state_id === IncidentState.In_progress ||
    state_id === IncidentState.On_hold

  const isIncidentClosed =
    state_id === IncidentState.Cancelled || state_id === IncidentState.Closed

  return (
    <div className={'incident-k-request'}>
      {assignedToCurrentUser &&
        assignee_state === AssigneeStates.Proposed &&
        !isIncidentClosed && (
          <div className={'incident-k-request__accept-reject'}>
            <Button
              title={translate('accept')}
              disabled={checkIsLoading(acceptOrRejectStatus)}
              onClick={handleAcceptIncident}
              type="primary"
              size="middle"
              e2e-test="accept-btn"
            />

            <Button
              title={translate('reject')}
              disabled={isRejectReason}
              onClick={handleRejectIncident}
              size="middle"
              e2e-test="reject-btn"
            />
          </div>
        )}
      <div className="incident-k-request-btns">
        {!isTimelogOpen &&
          entityData &&
          !isTimelogDisabled(entityData) &&
          checkInBtnVisible &&
          assignedToCurrentUser &&
          assignee_state === AssigneeStates.Accepted && <CheckInBtn />}

        {btnsGroup.map(
          (btn, index) =>
            btn.visible && (
              <Tooltip
                placement="top"
                className="flex flex--justifyCenter flex--alignCenter"
                title={btn.tooltipTitle}
                key={index}
              >
                <span>
                  <Button
                    title={btn.title}
                    disabled={btn.disabled}
                    onClick={btn.action}
                    type="text"
                    size="large"
                    icon={btn.icon}
                    e2e-test={btn.e2eTest}
                  />
                </span>
              </Tooltip>
            )
        )}

        {incident_link && <ShareButton value={incident_link} type="text" />}
      </div>
      {cancelAction && (
        <CancellationModal resetCancelAction={resetCancelAction} />
      )}
      <Modal
        open={visible}
        info={translate('incident_must_be_assigned_and_accepted_by_user')}
        okText={translate('close')}
        handleAccept={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        modalType={ModalType.WARNING}
      />
    </div>
  )
}

export default IncidentKrequestBtns
