import {useCallback, useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {setRefresh} from '@/redux/actions'
import {RefreshType, RequestStatus} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {selectRefresh} from '@/redux/reducers/generalSelector'
import {getIncidentTask} from '@/modules/ITSM/api/incidentTasksRequests'

import {TIncidentTask} from '../typedf'

export function useGetIncidentTask(
  id: string
): {
  readonly getData: (timer?: boolean) => Promise<TIncidentTask | undefined>
  readonly entityData: TIncidentTask | undefined
  readonly fetchStatus: RequestStatus
} {
  const refresh = useSelector(selectRefresh)

  const [entityData, setEntityData] = useState<TIncidentTask>()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const dispatch = useDispatch()

  const getData = useCallback(
    async (timer?: boolean) => {
      try {
        if (!timer) handleRequested()
        const {
          body: {result},
        } = await getIncidentTask(id)
        if (result) {
          if (!timer) {
            setEntityData(result)
            handleSuccess()
          }
          return result
        }
        return undefined
      } catch (err) {
        if (!timer) handleFail(err)
      } finally {
        if (!timer) handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess, id]
  )

  const {isRefreshing, type} = refresh

  useEffect(() => {
    if (isRefreshing && type === RefreshType.IncidentTaskNotif) {
      setEntityData(undefined)
      getData()
      dispatch(setRefresh(false))
    }
  }, [id, getData, isRefreshing, type, setEntityData, dispatch])

  return {
    getData,
    entityData,
    fetchStatus: status,
  }
}
