import {useDispatch} from 'react-redux'
import saveAs from 'file-saver'

import {useFetchHook} from '@/hooks/useFetch'
import {exportType} from '@/constants'
import {exportBillingItems} from '@/modules/ITSM/api/exportRequests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

export const useExportBillingItems = () => {
  const dispatch = useDispatch()
  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const handleExportBillingItems = async (
    format: string,
    selector: Record<string, any>
  ) => {
    try {
      handleRequested()

      const {body} = await exportBillingItems(format, selector)
      const fileType = format === 'xlsx' ? exportType : 'text/csv'
      const file = new Blob([body], {type: fileType})
      const fileName =
        format === 'xlsx' ? 'billing_items.xlsx' : 'billing_items.csv'

      saveAs(file, fileName)

      handleSuccess()
    } catch (err) {
      handleFail(err)
      dispatch(setToastMessage({message: err}))
    } finally {
      handleReset()
    }
  }

  return {handleExportBillingItems}
}
