import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Col, Form, Input, InputNumber, Row, Transfer} from 'antd'
import {useSelector} from 'react-redux'

import {translate} from '@/services/i18n'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {
  getAssignmentGroupsName,
  setStateAction,
} from '@/modules/ITSM/utils/Helpers'
import {Button} from '@/components/button'
import {patchUser} from '@/modules/ITSM/api/userRequests'
import {selectUserSensitive} from '@/redux/settings/settingsSelector'
import {useFetchHook} from '@/hooks/useFetch'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import {patchUserRoles} from '@/modules/ITSM/api/rolesRequests'
import {useGetRoles} from '@/modules/ITSM/hooks/identityHooks'
import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import FormItemCustom from '@/components/form/form-item-custom'
import {assignmentGroupsApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {emailValidator} from '@/utils/form-validators/email-validator'

import {TUserDefaultsInvite} from '../../Invitation/user-invitation/typedf'

type TState = {
  resolvedAssignmentGroupsArr: string[]
  resolvedAssignmentGroups: Array<Record<string, any>>
  btnDisabled: boolean
  loading: boolean
  targetKeys: string[]
  initialFormValues: Record<string, any>
}

type TProps = {
  record: Record<string, any>
  handleCloseDrawer: () => void
  asset: ItsmAssets
}

export const UserForm: React.FC<TProps> = ({
  record,
  handleCloseDrawer,
  asset,
}) => {
  const [form] = Form.useForm()
  const [isEditWagesEnabled, setEditWagesEnabled] = useState(false)
  const [state, setNewState] = useState<TState>({
    resolvedAssignmentGroupsArr: [],
    resolvedAssignmentGroups: [],
    targetKeys: [],
    btnDisabled: true,
    loading: false,
    initialFormValues: {},
  })
  const viewSensitive: string[] = useSelector(selectUserSensitive)

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
    status,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const assignment_groups = useMemo(
    () =>
      asset === ItsmAssets.Users
        ? record?.assignment_groups || []
        : record?.users?.[0]?.assignment_groups || [],
    [asset, record.assignment_groups, record.users]
  )

  const [
    noIdentity,
    rolesToDisplay = [],
    notGrantableByMe,
    userRoles,
    loading,
  ] = useGetRoles(
    asset === ItsmAssets.Users ? record.uuid : record.users?.[0]?.uuid
  )

  const userRecord =
    asset === ItsmAssets.Users ? record : record.users && record.users[0]
  const {targetKeys, resolvedAssignmentGroups, btnDisabled} = state
  const {pricing_policy} = userRecord || {}

  const setState = (val: Partial<TState>) => setStateAction(val, setNewState)

  const handleChangeAssignedRoles = useCallback(
    (targetKeys: Array<string>) => {
      form.setFieldsValue({roles: targetKeys})
      setState({targetKeys})
    },
    [form]
  )

  useEffect(() => {
    if (viewSensitive) {
      const viewUser = viewSensitive.filter(
        permission => permission === '/user/*'
      )
      setEditWagesEnabled(viewUser.length > 0)
    }
  }, [viewSensitive])

  useEffect(() => {
    const resolveAssignmentGroups = (
      assignmentGroups: Array<Record<string, any>>
    ) => {
      const assignmentGroupsUuid = assignmentGroups.map(e => e.uuid)

      form.setFieldsValue({
        assignment_groups: assignmentGroupsUuid,
      })
      setState({
        resolvedAssignmentGroups: assignmentGroups,
        resolvedAssignmentGroupsArr: assignmentGroupsUuid,
      })
    }

    const setInitialState = () => {
      if (assignment_groups) {
        resolveAssignmentGroups(assignment_groups)
      } else resolveAssignmentGroups([])

      const values = form.getFieldsValue()
      const userRolesData: string[] = userRoles as any

      delete values['assignment_groups']

      setState({
        ...(userRoles ? {targetKeys: userRolesData} : null),
        initialFormValues: {
          ...values,
          ...(assignment_groups
            ? {
                assignment_groups: assignment_groups.map(
                  (item: Record<string, any>) => item.uuid
                ),
              }
            : {}),
          ...(userRoles ? {roles: userRoles} : {}),
        },
        loading: false,
      })

      if (userRoles) form.setFieldsValue({roles: [...userRolesData]})
    }

    if (userRoles || noIdentity) {
      setInitialState()
      if (Array.isArray(userRoles)) handleChangeAssignedRoles(userRoles)
    }
  }, [
    userRoles,
    handleChangeAssignedRoles,
    assignment_groups,
    noIdentity,
    form,
  ])

  const getValidRoles = (values: Array<Record<string, any>> = []) => {
    const notGrantableByMeData: Array<Record<
      string,
      any
    >> = notGrantableByMe as any
    return values.filter(
      e => notGrantableByMeData.map(r => r.uuid).indexOf(e) === -1
    )
  }

  const recognizeFieldChange = () => {
    if (state.btnDisabled)
      setState({
        btnDisabled: false,
      })
  }

  const handleFilterOption = (value: string, name: Record<string, string>) =>
    name['title'].toLowerCase().includes(value.toLowerCase())

  const createData = (values: Record<string, any>) => {
    const {...origin} = state.initialFormValues

    const {...newValues} = values
    delete newValues['email']
    delete newValues['contract_type']

    if (!noIdentity) {
      if (!origin.roles) {
        origin.roles = []
      }
      newValues.roles = getValidRoles([...newValues.roles])
      origin.roles = getValidRoles([...origin.roles])
    }

    return getDataDifference(origin, newValues)
  }

  const patchUserInfo = async (data: Record<string, any>) => {
    try {
      if (Object.keys(data).indexOf('roles') !== -1) {
        await patchUserRoles({
          user_id:
            asset === ItsmAssets.Users ? record.uuid : record.users[0].uuid,
          roles: data.roles || [],
        })
      }

      const newData = {...data}
      delete newData['roles']

      if (Object.entries(newData).length > 0) {
        await patchUser(
          asset === ItsmAssets.Users ? record.uuid : record.users[0].uuid,
          newData
        )
      }
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }

  const handleSubmit = async () => {
    try {
      const validatesValues = await form.validateFields()

      handleRequested()

      const data = createData(validatesValues)
      await patchUserInfo(data)
      setState({
        btnDisabled: true,
      })
      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
      setState({
        btnDisabled: true,
      })
    }
  }

  return (
    <Skeleton active={Boolean(loading)} view={<FormSkeleton />}>
      <h5 className="capitalize--words">{translate('user_data')}</h5>
      <Form
        layout="vertical"
        name="settings"
        onFinish={handleSubmit}
        form={form}
        onValuesChange={recognizeFieldChange}
      >
        {noIdentity ? (
          <FormItemCustom
            className="identity__roles"
            label={translate('roles')}
          >
            {translate('identity_not_created')}
          </FormItemCustom>
        ) : (
          <Form.Item name="roles" label={translate('roles')}>
            <Transfer
              titles={['All', 'Assigned']}
              operations={['Add', 'Remove']}
              dataSource={rolesToDisplay as Array<Record<string, string>>}
              showSearch
              targetKeys={targetKeys || []}
              onChange={handleChangeAssignedRoles as any}
              render={item => item.title}
              filterOption={handleFilterOption}
            />
          </Form.Item>
        )}
        {userRecord && (
          <FormItemGeneric<TUserDefaultsInvite>
            name="email"
            initialValue={userRecord.email || ''}
            label={translate('email')}
            rules={[{validator: emailValidator}]}
            className="full-width"
          >
            <Input readOnly />
          </FormItemGeneric>
        )}

        <Row>
          <Col className="col-flex--two">
            {userRecord && userRecord.contract_type && (
              <FormItemGeneric<TUserDefaultsInvite>
                name="contract_type"
                initialValue={userRecord?.contract_type || ''}
                label={translate('contract_type')}
              >
                <Input readOnly />
              </FormItemGeneric>
            )}
            {userRecord && userRecord.type && (
              <FormItemCustom
                initialValue={userRecord?.type || ''}
                label={translate('type')}
                className="full-width"
              >
                <Input readOnly />
              </FormItemCustom>
            )}
          </Col>
        </Row>

        <SelectWithBM
          api={assignmentGroupsApi().get}
          label={translate('assignment_groups')}
          name="assignment_groups"
          mode="multiple"
          initialValue={resolvedAssignmentGroups || []}
          optionContent={getAssignmentGroupsName}
          getPopupContainer={() => document.body}
        />

        {isEditWagesEnabled && (
          <>
            <Row>
              <Col className="col-flex--two">
                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'currency']}
                  initialValue={pricing_policy?.currency}
                  label={translate('currency')}
                >
                  <Input />
                </FormItemGeneric>
                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'rate']}
                  initialValue={pricing_policy?.rate || 0}
                  label={translate('rate')}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>
              </Col>
            </Row>
            <Row>
              <Col className="col-flex--two">
                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'pricing_unit']}
                  label={translate('pricing_unit')}
                  initialValue={pricing_policy?.pricing_unit || 0}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>

                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'primary_hours_number']}
                  label={translate('primary_units_number')}
                  initialValue={pricing_policy?.primary_hours_number || 0}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>
              </Col>
            </Row>
            <Row>
              <Col className="col-flex--two">
                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'primary_hour_rate']}
                  initialValue={pricing_policy?.primary_hour_rate || 0}
                  label={translate('primary_unit_rate')}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>

                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'additional_pricing_unit']}
                  initialValue={pricing_policy?.additional_pricing_unit || 0}
                  label={translate('additional_pricing_unit')}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>
              </Col>
            </Row>
            <Row>
              <Col className="col-flex--two">
                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'travel_fix_rate']}
                  initialValue={pricing_policy?.travel_fix_rate || 0}
                  label={translate('travel_fix_rate')}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>

                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'travel_distance_unit_rate']}
                  initialValue={pricing_policy?.travel_distance_unit_rate || 0}
                  label={translate('travel_distance_unit_rate')}
                >
                  <InputNumber className="full-width" />
                </FormItemGeneric>
              </Col>
            </Row>
            <Row>
              <Col className="col-flex--two">
                <FormItemGeneric<TUserDefaultsInvite>
                  name={['pricing_policy', 'travel_pricing_unit']}
                  initialValue={pricing_policy?.travel_pricing_unit || 0}
                  label={translate('travel_pricing_unit')}
                >
                  <InputNumber className="half-width" />
                </FormItemGeneric>
              </Col>
            </Row>
          </>
        )}
        <Button
          title={translate('save')}
          type="primary"
          size="large"
          htmlType="submit"
          onClick={handleSubmit}
          disabled={btnDisabled || checkIsLoading(status)}
        />
        <Button
          title={translate('cancel')}
          size="large"
          className="ml-10"
          onClick={handleCloseDrawer}
        />
      </Form>
    </Skeleton>
  )
}
