import React from 'react'

import {Form, Input} from 'antd'

import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {translate} from '@/services/i18n'
import {useFetchHook} from '@/hooks/useFetch'
import {TRejectReason} from '@/modules/ITSM/typedef'
import {patchReason, postReason} from '@/modules/ITSM/api/reasonsRequests'
import {checkIsLoading} from '@/utils/check-is-loading'
import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import FormItemCustom from '@/components/form/form-item-custom'

const ReasonsDrawer = () => {
  const [form] = Form.useForm()
  const {
    getEntityDataInit,
    getEntityData,
    onClose,
    record,
  } = useListTableStateContext()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const handleSubmit = async (values: TRejectReason) => {
    try {
      handleRequested()

      if (record) {
        await patchReason(record.uuid, values)
        getEntityData()
      } else {
        await postReason(values)
        await getEntityDataInit()
        onClose()
      }

      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  return (
    <DrawerForm
      form={form}
      onFinish={handleSubmit}
      loadingSubmit={checkIsLoading(status)}
      handleCancel={onClose}
    >
      <FormItemCustom
        initialValue={record?.name}
        name="name"
        label={translate('name')}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input onFocus={setAutocompleteNone} />
      </FormItemCustom>
    </DrawerForm>
  )
}

export default ReasonsDrawer
