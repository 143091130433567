import moment from 'moment'

import {IncidentState} from '../constants'
import {TIncident} from '../typedf'

export const getRemainingTime = (record: TIncident, endTime: string) => {
  const {state_id} = record
  if (state_id === IncidentState.Cancelled) {
    return 0
  } else if (
    state_id === IncidentState.Open ||
    state_id === IncidentState.In_progress
  ) {
    if (new Date().getTime() < new Date(endTime).getTime()) {
      return moment(endTime).diff(moment(), 'seconds')
    } else {
      return 0
    }
  } else if (
    state_id === IncidentState.Pre_on_hold ||
    state_id === IncidentState.On_hold
  ) {
    if (new Date().getTime() < new Date(endTime).getTime()) {
      return moment(endTime).diff(moment(record.on_hold_start), 'seconds')
    } else {
      return 0
    }
  } else if (
    state_id === IncidentState.Resolved ||
    state_id === IncidentState.Closed
  ) {
    if (new Date().getTime() < new Date(endTime).getTime()) {
      return moment(endTime).diff(moment(record.resolved_at), 'seconds')
    } else {
      return 0
    }
  } else {
    return 0
  }
}
