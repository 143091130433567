import React from 'react'

import {InputNumber} from 'antd'
import {FormInstance} from 'antd/es/form/Form'
import moment, {Moment} from 'moment'
import {FormListFieldData} from 'antd/lib/form/FormList'
import {Rule} from 'antd/lib/form'

import {translate} from '@/services/i18n'
import FormItemCustom from '@/components/form/form-item-custom'
import {TWeekdayPricingPolicy} from '@/typedef'

import {pricingPoliciesWeekdaysHolidaysValidation} from './utils/utils'
import TimePicker from '@/components/time-picker/time-picker'

interface IPricingPolicyItem {
  field: FormListFieldData
  dayNumber?: number
  fieldIndex: number
  form: FormInstance<any>
}

const PricingPolicyItem = ({
  field,
  form,
  fieldIndex,
  dayNumber,
}: IPricingPolicyItem) => {
  const handleChange = (_: Moment, date: string, interval: string) => {
    const fieldToChange = form.getFieldValue(
      dayNumber !== undefined
        ? 'weekday_pricing_policies'
        : 'holiday_pricing_policies'
    )

    const newPricingPolicies =
      dayNumber !== undefined
        ? fieldToChange.map(
            (
              day: {
                [key in number]: TWeekdayPricingPolicy
              }[][],
              dayIndex: number
            ) =>
              dayNumber === dayIndex
                ? day.map(
                    (
                      item: {[key in number]: TWeekdayPricingPolicy}[],
                      itemIndex: number
                    ) =>
                      fieldIndex === itemIndex
                        ? {
                            ...item,
                            [interval]: date ? moment(date, 'HH:mm') : null,
                          }
                        : item
                  )
                : day
          )
        : fieldToChange.map((item: any, itemIndex: number) =>
            fieldIndex === itemIndex
              ? {
                  ...item,
                  [interval]: date ? moment(date, 'HH:mm') : null,
                }
              : item
          )

    form.setFieldsValue(
      dayNumber !== undefined
        ? {
            weekday_pricing_policies: newPricingPolicies,
          }
        : {holiday_pricing_policies: newPricingPolicies}
    )
    form.validateFields()
  }

  const validatorValue = (
    value: Moment,
    fieldIndex: number,
    interval: string
  ) => {
    const fieldValue = form.getFieldValue('weekday_pricing_policies')
    const holidayArray =
      dayNumber !== undefined
        ? fieldValue[dayNumber].filter(
            (_: null, index: number) => index !== fieldIndex
          )
        : form
            .getFieldValue('holiday_pricing_policies')
            .filter((_: null, index: number) => index !== fieldIndex)

    const pairValue =
      dayNumber !== undefined
        ? fieldValue[dayNumber].find(
            (_: null, index: number) => index === fieldIndex
          )[interval]
        : form
            .getFieldValue('holiday_pricing_policies')
            .find((_: null, index: number) => index === fieldIndex)[interval]

    return {
      holidayArray,
      value,
      interval,
      pairValue,
    }
  }

  return (
    <>
      <FormItemCustom
        name={[field.name, 'from']}
        label={translate('from')}
        rules={[
          {
            required: true,
            validateTrigger: 'onChange',
            validator: (rule: Rule, value: Moment) =>
              pricingPoliciesWeekdaysHolidaysValidation(
                rule,
                validatorValue(value, fieldIndex, 'to')
              ),
          },
        ]}
      >
        <TimePicker
          getPopupContainer={(e: any) => e.parentNode as HTMLElement}
          minuteStep={5}
          allowClear
          onChange={(moment: any, date: any) =>
            handleChange(moment as Moment, date, 'from')
          }
        />
      </FormItemCustom>

      <FormItemCustom
        name={[field.name, 'to']}
        label={translate('to')}
        rules={[
          {
            required: true,
            validateTrigger: 'onChange',
            validator: (rule: Rule, value: Moment) =>
              pricingPoliciesWeekdaysHolidaysValidation(
                rule,
                validatorValue(value, fieldIndex, 'from')
              ),
          },
        ]}
      >
        <TimePicker
          getPopupContainer={(e: any) => e.parentNode as HTMLElement}
          minuteStep={5}
          allowClear
          onChange={(moment: any, date: any) =>
            handleChange(moment as Moment, date, 'to')
          }
        />
      </FormItemCustom>

      <FormItemCustom
        label={translate('price_multiplier')}
        name={[field.name, 'price_multiplier']}
        rules={[
          {
            required: true,
            validateTrigger: 'onChange',
            validator: (_: Rule, value: Moment) => {
              return new Promise<void>((resolve, reject) => {
                if (!value) {
                  reject(translate('field_not_empty'))
                }
                resolve()
              })
            },
          },
        ]}
      >
        <InputNumber type="number" />
      </FormItemCustom>
    </>
  )
}

export default PricingPolicyItem
