import React, {useRef} from 'react'

import {Input, message, Tooltip} from 'antd'

import {translate} from '@/services/i18n'
import {Button, IButtonProps} from '@/components/button'
import {Icon, IconType} from '@/components/icon'

import './share-button.scss'

type TProps = {
  value?: string
  tooltipMessage?: string
  successMessage?: string
  type?: IButtonProps['type']
}

const DEFAULT_TOOLTIP_MESSAGE = translate('copy_info')
const DEFAULT_SUCCESS_MESSAGE = translate('copy_success_info')

export const ShareButton: React.FC<TProps> = props => {
  const {value, tooltipMessage, successMessage, type} = props
  const inputRef = useRef(null)

  const copyLink = () => {
    const input = (inputRef.current as unknown) as HTMLInputElement
    input.select()
    document.execCommand('copy')
    const messageToShow = successMessage || DEFAULT_SUCCESS_MESSAGE
    message.success(messageToShow)
  }

  return (
    <div className="share-button">
      <Input ref={inputRef} id="share-button" readOnly value={value} />
      <Tooltip
        placement="top"
        title={tooltipMessage || DEFAULT_TOOLTIP_MESSAGE}
      >
        <Button
          disabled={!value}
          title={translate('share')}
          onClick={copyLink}
          icon={
            <Icon
              type={IconType.SHARE}
              size={14}
              className="share-button__icon"
            />
          }
          className="capitalize--first"
          {...(type ? {type} : null)}
        />
      </Tooltip>
    </div>
  )
}
