import * as ActionTypes from '../actionTypes'
import {TAccount} from '@/typedef'

export const setAccounts = (accounts: Array<TAccount>) => ({
  type: ActionTypes.SET_ENTRIES_ACCOUNTS,
  payload: accounts,
})

export const setOrganization = ({
  orgName,
  orgID,
}: {
  orgName: string
  orgID: string
}) => {
  return {
    type: ActionTypes.SET_ENTRIES_ORG,
    payload: {orgName, orgID},
  }
}

export const setItsmChannel = ({
  channelName,
  channelID,
}: {
  channelName: string
  channelID: string
}) => {
  return {
    type: ActionTypes.SET_ENTRIES_ITSM_CHANNEL,
    payload: {channelName, channelID},
  }
}

export const setCmdbNamespaceId = (channelID: string) => {
  return {
    type: ActionTypes.SET_ENTRIES_CMDB_NAME_SPACE_ID,
    payload: channelID,
  }
}

export const setSaasNamespaceId = (channelID: string) => {
  return {
    type: ActionTypes.SET_ENTRIES_SAAS_NAME_SPACE_ID,
    payload: channelID,
  }
}

export const resetEntriesReducer = () => ({
  type: ActionTypes.RESET_ENTRIES_REDUCER,
})
