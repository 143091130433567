import React from 'react'

import {Col, Row} from 'antd'
import {InlineWidget} from 'react-calendly'

import {Button} from '@/components/button'

import './setup-call-step.scss'

import {generateConfig} from '@/generateConfig'
import {useAccountDefaultRedirect} from '@/sites/account-default/hooks/use-account-default-redirect'

export const SetupCallStep = () => {
  const redirect = useAccountDefaultRedirect()

  return (
    <Row className="setup-call-step">
      <Col span={24}>
        <div className="App">
          <InlineWidget url={generateConfig().CALENDLY_URL} />
        </div>
      </Col>
      <Button
        data-testid={'skip-button'}
        type="primary"
        htmlType="submit"
        title={'skip'}
        onClick={() => redirect()}
        className="setup-call-step__button"
      />
    </Row>
  )
}
