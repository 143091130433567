type TMergeBookmark = (
  bookmark?: string,
  bookmarks?: string[],
  resetBookmarks?: boolean
) => string[]

export const NIL = 'nil'

export const mergeBookmarks: TMergeBookmark = (
  bookmark,
  bookmarks,
  resetBookmarks
) => {
  if (bookmark) {
    if (resetBookmarks) return [bookmark]
    if (bookmark !== NIL) {
      if (bookmarks) {
        return bookmarks.includes(bookmark)
          ? bookmarks
          : [...bookmarks, bookmark]
      }
      return [bookmark]
    }

    return []
  }
  return []
}
