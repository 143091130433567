export const generateTitle = (val: string) => {
  const titleArr = val.replace(/([A-Z])/g, ' $1').split(' ')

  return (
    titleArr[0].charAt(0).toUpperCase() +
    titleArr[0].slice(1) +
    (titleArr[1]
      ? ' ' + titleArr[1].charAt(0).toLowerCase() + titleArr[1].slice(1)
      : '')
  ).replace(/_/g, ' ')
}
