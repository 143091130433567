import React, {FC, useState} from 'react'
import {Collapse} from 'antd'

import {ServicesFeature} from '@/sites/services/components/services-feature'

import './services-feature-list.scss'

type TProps = {
  featureList: Array<string>
  title: string
}

export const ServicesFeatureList: FC<TProps> = ({
  featureList,
  title,
}: {
  featureList: Array<string>
  title: string
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  return (
    <div className="services-feature-list">
      <h5 className="services-feature-list__title mb-26">{title}</h5>
      <div className="services-feature-list__features">
        {featureList?.slice(0, 8).map(feature => (
          <ServicesFeature key={feature} name={feature} />
        ))}
      </div>
      {featureList.length > 8 && (
        <Collapse
          className="services-feature-list__collapse"
          ghost
          expandIcon={() => null}
          onChange={value => setIsCollapse(value.includes('1'))}
        >
          <Collapse.Panel
            header={isCollapse ? 'Hide Features' : 'More Features'}
            key="1"
          >
            <div className="services-feature-list__features ">
              {featureList?.slice(8).map(feature => (
                <ServicesFeature key={feature} name={feature} />
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  )
}
