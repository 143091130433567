import {useEffect, useState} from 'react'

import {fetchOrgsRole} from '@/modules/ITSM/api/itsmOrgRequests'

import {TOrgRoles} from './types'

export const useGetOrgsRoles = () => {
  const [orgsRoles, setOrgsRoles] = useState<Array<TOrgRoles>>()

  useEffect(() => {
    const getOrgsRoles = async () => {
      const {
        body: {
          result: {value: orgRolesRes},
        },
      } = await fetchOrgsRole()
      const internalOrgs = Object.keys(orgRolesRes).reduce(
        (acc: TOrgRoles[], id) => {
          if (orgRolesRes[id].role !== 'customer') {
            return [...acc, {value: id, name: orgRolesRes[id].name}]
          }
          return acc
        },
        []
      )
      setOrgsRoles(internalOrgs)
    }

    if (!orgsRoles) getOrgsRoles()
  }, [orgsRoles])

  return {
    orgsRoles,
  }
}
