import React, {FC} from 'react'
import {
  AccountBookOutlined,
  FieldTimeOutlined,
  UserOutlined,
} from '@ant-design/icons'
import classnames from 'classnames'

import {Paper} from '@/components/paper/paper'
import {translate} from '@/services/i18n'
import {getDaysFromHours} from '@/modules/ITSM/sites/BillingReports/utils/get-days-from-hours'
import {TBillingItem} from '@/modules/ITSM/sites/finance-dashboard/types'
import {differenceGrowth} from '@/utils/difference-growth'
import {kFormatter} from '@/utils/kFormatter'
import {Skeleton} from '@/components/skeleton'
import {StatisticsCardSkeleton} from '@/modules/ITSM/sites/finance-dashboard/statistics-card/statistics-card-skeleton'

import './statistics-card.scss'

enum Color {
  blue = 'blue',
  green = 'green',
  yellow = 'yellow',
}

type TProps = {
  currentMonth?: TBillingItem
  previousMonth?: TBillingItem
  loading: boolean
}

export const StatisticsCard: FC<TProps> = ({
  currentMonth,
  previousMonth,
  loading,
}) => {
  const items = [
    {
      title: translate('earning'),
      content: currentMonth
        ? `${kFormatter(currentMonth.revenue - currentMonth.cost)} EUR`
        : translate('no_data'),
      icon: <AccountBookOutlined />,
      color: Color.blue,
    },
    {
      title: translate('visits_number'),
      content: currentMonth ? currentMonth.visits_count : translate('no_data'),
      icon: <UserOutlined />,
      color: Color.green,
    },
    {
      title: translate('work_time'),
      content: currentMonth
        ? getDaysFromHours(currentMonth.work_time)
        : translate('no_data'),
      icon: <FieldTimeOutlined />,
      color: Color.yellow,
    },
  ]

  return (
    <Skeleton
      className="statistics-card__skeleton"
      view={<StatisticsCardSkeleton />}
      active={loading}
    >
      <Paper className="statistics-card">
        <h2 className="statistics-card__title">
          {translate('statisticsCard')}
        </h2>
        <p className="statistics-card__growth">
          {previousMonth && currentMonth ? (
            <>
              <span className="statistics-card__growth-total">
                {`${translate('total')} ${differenceGrowth(
                  currentMonth.revenue,
                  previousMonth.revenue,
                  1
                )} `}
              </span>
              <span className="statistics-card__growth-total">
                {currentMonth.revenue > previousMonth.revenue &&
                currentMonth.revenue > 0
                  ? `${translate('growth')} `
                  : `${translate('less_to_previous_month')} `}
              </span>
              {currentMonth.revenue > previousMonth.revenue &&
                currentMonth.revenue > 0 &&
                `😎 ${translate('current_month')}`}
            </>
          ) : (
            translate('no_data_available')
          )}
        </p>
        <div className="statistics-card__tile-list">
          {items.map(({title, content, icon, color}) => (
            <div className="statistics-card__tile" key={title}>
              <div
                className={classnames('statistics-card__icon-box', {
                  [`statistics-card__icon-box--${color}`]: color,
                })}
              >
                <div className="statistics-card__icon-wrapper">{icon}</div>
              </div>
              <div className="statistics-card__icon-info">
                <div className="statistics-card__icon-title">{title}</div>
                <div className="statistics-card__count">{content}</div>
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </Skeleton>
  )
}
