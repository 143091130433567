import React, {Key, useState} from 'react'

import {Editor} from 'react-draft-wysiwyg'
import {Col, Row} from 'antd'
import {EditorState} from 'draft-js'

import {Button} from '@/components/button'
import {EditorWrapper} from '@/components/editor'
import {draftToolbarOptions} from '@/modules/ITSM/utils/Constants'
import {translate} from '@/services/i18n'

import AttachmentsButton, {
  TOnUpdateParams,
} from '@/modules/ITSM/components/attachments/attachments-button'
import {AttachmentsModal} from '@/modules/ITSM/components/attachments-modal/attachments-modal'
import {getAttachmentLink} from '@/modules/ITSM/components/description-editor/get-attachment-link'
import {DescriptionContent} from '@/modules/ITSM/components/description-content/description-content'
import FormItemCustom from '@/components/form/form-item-custom'

import './description-editor.scss'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import {TAttachment} from '../../sites/Expenses/typedef'
import {useStateContext} from '../incident/incident-context'
import {TIncident} from '../incident-k-request/typedf'
import {postAttachmentV2} from '../../api/attachmentsRequests'

type TProps = {
  onEditorStateChange: (editorState: EditorState) => void
  editorState: EditorState
  isDisabled?: boolean
  showAttachButton?: boolean
  description?: string
  setText: (text?: string) => void
  withFileAttaching?: boolean
  editorText?: string
  onAddAttachments?: () => void
  immediateAttachments?: boolean
  onAddFiles?: (params: TOnUpdateParams) => void
}

const DescriptionEditor = ({
  onEditorStateChange,
  editorState,
  isDisabled,
  showAttachButton = true,
  setText,
  editorText,
  onAddAttachments,
  description = '',
  onAddFiles,
  withFileAttaching = true,
  immediateAttachments = false,
}: TProps) => {
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key>>()
  const [selectedAttachments, setSelectedAttachments] = useState<
    Array<TAttachment>
  >()

  const confirmExistingAttachments = () => {
    const links = getAttachmentLink({selectedAttachments})
    getDescription(links)
  }

  const confirmUploadedAttachments = (
    uploadedAttachments: Array<TAttachment>
  ) => {
    const links = getAttachmentLink({uploadedAttachments})
    getDescription(links)

    if (onAddAttachments) {
      onAddAttachments()
    }
  }
  const getDescription = (links: string) => {
    const descriptionToSave = (editorText || '') + (links !== '' && links)
    setText(descriptionToSave)
    setSelectedRowKeys([])
    setSelectedAttachments([])
    setShowAttachmentsModal(false)
  }

  const {entityData, entity} = useStateContext<TIncident>()

  const {uuid: id} = entityData || {}

  const onSelectChange = (
    selectedRowKeys: Array<Key>,
    selectedAttachments: Array<TAttachment>
  ) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedAttachments(selectedAttachments)
  }

  return (
    <Row>
      <Col span={24} e2e-test="description_editor">
        <FormItemCustom label={translate('description')}>
          {isDisabled ? (
            <DescriptionContent
              isDisabled={isDisabled}
              description={description}
              isTextArea={true}
            />
          ) : (
            <>
              <EditorWrapper>
                <Editor
                  toolbar={draftToolbarOptions}
                  editorState={editorState}
                  wrapperClassName="wysiwyg-editor wysiwyg-editor--mb-0"
                  onEditorStateChange={onEditorStateChange}
                />
              </EditorWrapper>
              {withFileAttaching && (
                <div className="description_editor__file-attaching">
                  <AttachmentsButton<TAttachment>
                    onAddAttachments={confirmUploadedAttachments}
                    immediateAttachments={immediateAttachments}
                    onUpdate={onAddFiles}
                    entity={entity}
                    entityId={id || ''}
                    asyncF={postAttachmentV2}
                  />
                  {showAttachButton && (
                    <Button
                      title={translate('attach_existing_files')}
                      onClick={() => setShowAttachmentsModal(true)}
                    />
                  )}
                </div>
              )}
            </>
          )}
          <AttachmentsModal
            id={id}
            entity={entity}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            visible={showAttachmentsModal}
            confirmAttachments={confirmExistingAttachments}
            hide={() => setShowAttachmentsModal(false)}
          />
        </FormItemCustom>
      </Col>
    </Row>
  )
}

export default DescriptionEditor
