import React, {FC} from 'react'

import './purchase-price.scss'

import {translate} from '@/services/i18n'

type TProps = {
  price: string
  contactSalesPrice?: string
  pricingUnitLabel: string | JSX.Element
}

export const PurchasePrice: FC<TProps> = ({
  price,
  contactSalesPrice,
  pricingUnitLabel,
}) => {
  const generateContent = () => {
    if (price !== translate('free'))
      return (
        <>
          {price}
          <span className="purchase-price__cycle">
            &nbsp;
            {pricingUnitLabel}
          </span>
        </>
      )

    if (price === translate('free') && contactSalesPrice)
      return contactSalesPrice

    return translate('free')
  }

  return (
    <div className="purchase-price fw-700 text-right">{generateContent()}</div>
  )
}
