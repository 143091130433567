export const FETCH_DATA_REQUESTED = 'FETCH_DATA_REQUESTED'
export const FETCH_DATA_SUCCEEDED = 'FETCH_DATA_SUCCEEDED'
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'

export function selectBMReducer(state, action) {
  switch (action.type) {
    case 'FETCH_DATA_REQUESTED': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'FETCH_DATA_SUCCEEDED': {
      return {
        ...state,
        loading: false,
        mountLoading: action.payload.mountLoading || false,
        fetched: true,
        apiResBookmark: action.payload.apiResBookmark,
        data: action.payload.passedBookmark
          ? [...state.data, ...action.payload.data]
          : action.payload.data,
        numberOfRecord: action.payload?.data?.length || 0,
        selector: action.payload.selector,
      }
    }
    case 'FETCH_DATA_FAILED': {
      return {
        ...state,
        loading: false,
        mountLoading: false,
      }
    }
    default: {
      throw new Error(`Unhanded action type: ${action.type}`)
    }
  }
}
