import React from 'react'

import {ClockCircleOutlined} from '@ant-design/icons'
import {Progress, Tooltip} from 'antd'
import moment from 'moment'
import isArray from 'lodash/isArray'

import {Assets} from '@/typedef'
import {DATE_FORMAT, INCIDENT_TASK_STATES} from '@/constants'
import {translate} from '@/services/i18n'
import TooltipWithTruncate from '@/components/TooltipWithTruncate'
import {getProgressColor} from '@/utils/get-progress-color'
import {ProgressTooltipData} from '@/modules/ITSM/components/incident/incident-progress-bar/incident-progress-bar'
import {ImportanceIndicator} from '@/components/importance-indicator/importance-indicator'

import './get-table-values.scss'

export const INCIDENT_STATES = [
  translate('open'),
  translate('on_hold'),
  translate('in_progress'),
  translate('on_hold'),
  translate('resolved'),
  translate('closed'),
  translate('cancelled'),
]
const REQUEST_STATES = [
  translate('request_stateid_0'),
  translate('open'),
  translate('awaiting_approval'),
  translate('approved'),
  translate('in_progress'),
  translate('closed_completed'),
  translate('closed_incomplete'),
  translate('cancelled'),
]

type TGetTableValuesParams = {
  val: any
  key: string
  record: Record<string, any>
  newAsset: Assets
  incidentsData?: Record<string, any>
  requestsData?: Record<string, any>
  newFetchedData?: Record<string, any>
}

export const getTableValues = ({
  val,
  key,
  record,
  newAsset,
  incidentsData = {},
  requestsData = {},
  newFetchedData = {},
}: TGetTableValuesParams) => {
  if (`${val}`) {
    if (`${val}`) {
      if (typeof val === 'object') {
        if (
          key === 'assigned_to' ||
          key === 'caller' ||
          key === 'closed_by' ||
          key === 'created_by' ||
          key === 'resolved_by' ||
          key === 'updated_by' ||
          key === 'assignee' ||
          key === 'opened_by' ||
          key === 'last_reopened_by'
        )
          return val?.full_name
        if (
          key === 'category' ||
          key === 'subcategory' ||
          key === 'customer_product' ||
          key === 'assignment_group' ||
          key === 'user_group'
        )
          return val.name
        if (
          newAsset === Assets.incidentsAsset ||
          newAsset === Assets.kRequestsAsset
        ) {
          if (key === 'country') {
            return (
              <div className="table-values__location-container">
                {val.country}
              </div>
            )
          }
        }
        if (key === 'location') {
          if (newAsset === Assets.supplierProductsAsset) return val.country
          else
            return (
              <div className="table-values__location-container">{val.name}</div>
            )
        }
      }
      if (key === 'cmdb_ref') {
        return <div>{val?.name}</div>
      }
      if (key === 'email') {
        if (record) {
          if (newAsset === Assets.identityAsset) {
            if (record.users?.[0]?.email) return record.users[0]?.email
            else return ''
          } else return record?.email
        } else return ''
      }
      if (key === 'full_name') {
        if (record) {
          if (newAsset === Assets.identityAsset) {
            if (record.users?.[0]?.full_name) return record.users[0].full_name
            else return ''
          } else return record?.full_name
        } else return ''
      }

      if (key === 'active') {
        if (newAsset === Assets.identityAsset) {
          if (record?.users?.[0]?.active) {
            const isActive = record.users[0].active
            return isActive ? 'Yes' : 'No'
          } else return ''
        }
      }

      if (newAsset !== Assets.suppliersAsset) {
        if (key === 'contract_type') {
          if (record) {
            if (newAsset === Assets.identityAsset) {
              if (record.users?.[0]?.contract_type)
                return record.users[0].contract_type
              else return ''
            } else return record.contract_type
          } else return ''
        }
      }
      if (key === 'priority') {
        return (
          <ImportanceIndicator importance={val} indicatorText={`P${val}`} />
        )
      }
      if (key === 'impact' || key === 'urgency') {
        return translate(record[`${key}_text`])
      }

      if (
        isArray(val) &&
        key === 'external_emails' &&
        newAsset === Assets.notificationsAsset
      ) {
        return val?.map(email => email + ' ')
      }

      if (
        key === 'state_id' &&
        (newAsset === Assets.incidentsAsset ||
          newAsset === Assets.kRequestsAsset ||
          newAsset === Assets.requestsAsset ||
          newAsset === Assets.initiatedFromAsset ||
          newAsset === Assets.incidentTasksAsset)
      ) {
        if (newAsset === Assets.initiatedFromAsset) return INCIDENT_STATES[val]
        if (newAsset === Assets.incidentTasksAsset)
          return INCIDENT_TASK_STATES[val]

        const getPopupContainer = () => {
          const progressBar: HTMLElement | null = document.querySelector(
            '.table-values__incidents-progressbar'
          )
          if (progressBar) {
            return () => progressBar
          }
        }

        if (
          newAsset === Assets.incidentsAsset ||
          newAsset === Assets.kRequestsAsset
        ) {
          const {elapsedTime, remainingTime, progress} =
            incidentsData.find(
              ({uuid}: {uuid: string}) => uuid === record.uuid
            ) || {}

          return (
            <div className="table-values__incidents-progressbar">
              <Tooltip
                getPopupContainer={getPopupContainer()}
                title={
                  <ProgressTooltipData
                    {...{elapsedTime, remainingTime, progress}}
                  />
                }
                placement="bottom"
              >
                <Progress
                  strokeColor={getProgressColor(progress)}
                  percent={progress}
                  showInfo={false}
                />
              </Tooltip>
              <span className="nowrap">{INCIDENT_STATES[val]}</span>
              {record.expected_start && (val === 0 || val === 1) && (
                <Tooltip
                  title={`${translate('expected_start')}: ${moment(
                    record.expected_start
                  ).format(DATE_FORMAT)}`}
                  placement="bottom"
                  getPopupContainer={getPopupContainer()}
                >
                  <ClockCircleOutlined />
                </Tooltip>
              )}
            </div>
          )
        } else {
          const {elapsedTime, remainingTime, progress} =
            requestsData.find(
              ({uuid}: {uuid: string}) => uuid === record.uuid
            ) || {}
          return (
            <div className="table-values__incidents-progressbar">
              <Tooltip
                getPopupContainer={getPopupContainer()}
                title={
                  <ProgressTooltipData
                    {...{elapsedTime, remainingTime, progress}}
                  />
                }
                placement="bottom"
              >
                <Progress
                  strokeColor={getProgressColor(progress)}
                  percent={progress}
                  showInfo={false}
                />
              </Tooltip>
              <span className="nowrap">{REQUEST_STATES[val]}</span>
            </div>
          )
        }
      }
      if (
        newAsset === Assets.incidentsAsset ||
        newAsset === Assets.kRequestsAsset ||
        newAsset === Assets.requestsAsset
      ) {
        if (key === 'short_description')
          return <TooltipWithTruncate name={val} />
        if (key === 'number') return <span className="fw-700">{val}</span>

        if (key === 'assignee_state' && val === 'unset') return 'unassigned'
      }
      if (
        val &&
        typeof val === 'string' &&
        !isNaN(Date.parse(val)) &&
        val.includes('T')
      ) {
        return <span className="nowrap">{moment(val).format(DATE_FORMAT)}</span>
      }

      if (`${val}` && typeof val === 'boolean') {
        return val ? 'Yes' : 'No'
      }
      if (
        (key === 'created_by' || key === 'updated_by') &&
        newFetchedData?.users
      ) {
        return (
          newFetchedData?.users.find(
            ({uuid}: {uuid: string}) => uuid === val
          ) || {}
        ).full_name
      }

      if (key === 'org_name' && newFetchedData?.org_roles)
        return (
          newFetchedData?.org_roles.find(
            (e: {value: string}) => e.value === val
          ) || {}
        ).name

      if (key === 'ticket_type') return val.toLowerCase().replace('_', ' ')

      return val
    } else return ''
  }
}
