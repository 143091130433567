import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {
  ISuperAgentResMultiple,
  ISuperAgentResponse,
} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {TSelector} from '@/modules/ITSM/sites/finance-dashboard/types'
import {
  billingItemsApi,
  billingReportsApi,
  BRattachmentsApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TTicketSelector} from '../sites/BillingReports/billing-items/typedf'

export const fetchCurrent = async <T extends Record<string, any>>({
  selector,
}: TListOptions<T>): Promise<ISuperAgentResponse<T>> => {
  return await request
    .options(billingReportsApi().getCurrent)
    .set(getHeadersWithGRPC())
    .send({
      ...selector,
    })
}

export const fetchBillingReport = async <T extends Record<string, any>>({
  id,
}: {
  id: string
}): Promise<ISuperAgentResponse<T>> => {
  return await request
    .get(billingReportsApi(id).getOne)
    .set(getHeadersWithGRPC())
}

export const getBillingReports = async (bookmark?: string) => {
  return await request
    .options(billingReportsApi().get)
    .send({
      bookmark,
      selector: {},
      sort: [{interval_start: 'desc'}],
    })
    .set(getHeadersWithGRPC())
}

export const approveBillingReport = async (data: {
  interval_start: string
  interval_end: string
}) => {
  return await request
    .post(billingReportsApi().approve)
    .send({
      ...data,
    })
    .set(getHeadersWithGRPC())
}

export const getApproved = async (data: {
  selector: {
    interval_start: string
    interval_end: string
  }
}) => {
  return await request
    .options(billingReportsApi().approved)
    .send({
      ...data,
    })
    .set(getHeadersWithGRPC())
}

export const getSingleton = async () => {
  return await request
    .get(billingReportsApi().singleton)
    .send()
    .set(getHeadersWithGRPC())
}

export const postInvoice = async (id: string, data: Record<string, any>) => {
  return await request
    .post(BRattachmentsApi().post(id))
    .send(data)
    .set(getHeadersWithGRPC())
}

export const getInvoice = async (ref: string) => {
  return await request
    .get(BRattachmentsApi().getAll(ref))
    .send()
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const fetchBillingItems = async <T extends Record<string, any>>({
  passedBookmark,
  selector,
  ticket_selector,
}: TListOptions<T> & TTicketSelector): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(billingItemsApi().get)
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector,
      ticket_selector,
    })

export const fetchBillingReportsList = async (selector: TSelector) => {
  return await request
    .options(billingReportsApi().getList)
    .send(selector)
    .set(getHeadersWithGRPC())
}
