import React from 'react'

import {TUser} from '@/typedef'

import {TColumnRender} from '../../components/itsm-list-table/typedf'

import {TEvent} from './typedf'

type TProps = TColumnRender<TEvent> & {callersResolved: Array<TUser>}

export const EventsRowValue = ({title, value, callersResolved}: TProps) => {
  switch (title) {
    case 'caller':
      return (
        <div>{callersResolved.find(({uuid}) => uuid === value)?.full_name}</div>
      )

    default:
      return <div>{value}</div>
  }
}
