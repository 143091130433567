import React from 'react'

import {SkeletonItem} from '@/components/skeleton'

import './tile.scss'

export const TileSkeletonView = () => (
  <div className="tile tile__skeleton">
    <SkeletonItem className="tile__skeleton-item" />
  </div>
)
