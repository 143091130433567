export const isApiError = (err: any) => {
  return (
    err?.response?.body &&
    (typeof err.response.body.error !== 'object'
      ? err.response.body.error.includes('Cannot read from Vault') ||
        err.response.body.error.includes('State entry not found: IDENTITY') ||
        err.response.body.error.includes(
          'cannot get identity for fingerprint'
        ) ||
        err.response.body.error.includes('Did you call identityAddMe')
      : err.response.body.error.message.includes('Did you call assetCreate'))
  )
}
