import React, {useState} from 'react'

import classnames from 'classnames'
import {
  Checkbox,
  Col,
  Divider,
  Form as AntForm,
  Input,
  Row,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {InfoCircleOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router'

import {Option, Select} from '@/components/select/select'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {TKey, translate} from '@/services/i18n'
import {emailValidator} from '@/utils/form-validators/email-validator'
import {
  app_version,
  contractRole,
  contractType,
} from '@/modules/ITSM/utils/Constants'
import {GET_USER_MANAGERS_SELECTOR, RESOLVE} from '@/constants'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {useGetChannels} from '@/hooks/orgChannelHooks'
import {checkIsLoading} from '@/utils/check-is-loading'
import {filterOption} from '@/utils/filter-option'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {selectPathname} from '@/redux/reducers/generalSelector'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {Form} from '@/components/form/form/form'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {Collapse, Panel} from '@/components/collapse/collapse'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {postUserInvitation} from '@/modules/ITSM/api/invitationsRequests'
import {getAssignmentGroupsName} from '@/modules/ITSM/utils/Helpers'
import {TUser} from '@/modules/ITSM/typedef'
import {useGetRoles} from '@/modules/ITSM/hooks/use-get-roles'
import UserInvitationForm from '@/modules/ITSM/sites/Invitation/user-invitation-form'
import {
  assignmentGroupsApi,
  getToken,
  userApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {TUserInviteReq} from './typedf'
import {createInviteData, createUserDefaults} from './utils'
import {parseNestedErrorMessage} from '@/utils/parse-nested-error-messege'

import '../invitation.scss'

const UserInvitation = () => {
  const [form] = AntForm.useForm()

  const {
    channels,
    getChannels: fetchChannels,
    status: channelFetchStatus,
  } = useGetChannels()

  const paths = useSelector(selectPathname)

  const history = useHistory()

  const {roles, getRoles, status: rolesFetchStatus} = useGetRoles()

  const {editorState, onEditorStateChange, editorText} = useSetEditorState()

  const dispatch = useDispatch()

  const [userDefaults, setUserDefaults] = useState(false)
  const [, setRefresh] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    visible: false,
    message: '',
    text: '',
    modalType: ModalType.INFO,
  })

  const handleGoBack = () => {
    history.push(
      paths[0] === paths[1]
        ? itsmRoutes.serviceManagementDashboard.path()
        : paths[0]
    )
  }

  const handleSubmitInvite = async (values: TUserInviteReq) => {
    try {
      setIsLoading(true)
      let userDefaultsData

      if (userDefaults)
        userDefaultsData = createUserDefaults({
          ...values,
          email_signature: editorText,
        })

      const data = createInviteData({
        values,
        user_defaults: userDefaultsData || {
          assignment_groups: values.assignment_groups,
          contract_type: values.contract_type,
          type: values.type,
          ...(values.manager ? {manager: values.manager} : {}),
          ...(values.app_version ? {app_version: values.app_version} : {}),
        },
      })

      const {body} = await postUserInvitation(data)
      if (body.Success !== null) {
        setIsLoading(false)
        setModal({
          visible: true,
          message: translate('invite_send_seccess'),
          text: translate('send_new_invite'),
          modalType: ModalType.SUCCESS,
        })
        form.resetFields()
      } else {
        setIsLoading(false)
        dispatch(
          setToastMessage({
            message: translate(['oops', 'something_went_wrong', 'fix_it_soon']),
          })
        )
      }
    } catch (err) {
      dispatch(setToastMessage({message: parseNestedErrorMessage(err)}))

      setIsLoading(false)
    }
  }

  const selectedChannelID = form.getFieldValue('channel')

  return (
    <>
      {modal.visible ? (
        <Modal
          visible
          info={modal.message}
          okText={modal.text}
          handleAccept={() => setModal({...modal, visible: false})}
          handleCancel={handleGoBack}
          cancelText={translate('back')}
          onCancel={() => setModal({...modal, visible: false})}
          modalType={modal.modalType}
        />
      ) : (
        <Paper className="user-invitation" variant={PaperVariant.form}>
          <Form
            form={form}
            initialValues={{
              noCmdbInvite: false,
              noSaasInvite: false,
            }}
            isLoading={isLoading}
            onSubmit={handleSubmitInvite}
            submitButtonTitle="submit"
            className={classnames('invite-form')}
            header={
              <Row>
                <Col span={24}>
                  <GoBackButton onClick={handleGoBack} />
                  <Divider />
                </Col>
              </Row>
            }
          >
            <Row gutter={24}>
              <Col {...twoColumns}>
                <FormItemGeneric<TUserInviteReq>
                  name="app_version"
                  label={translate('app_version')}
                >
                  <Select showSearch getPopupContainer={e => e.parentNode}>
                    {app_version.map((version, i) => (
                      <Option value={version} key={i}>
                        {version}
                      </Option>
                    ))}
                  </Select>
                </FormItemGeneric>
              </Col>

              <Col {...twoColumns}>
                <SelectWithBM
                  api={userApi().get + RESOLVE}
                  label={translate('manager')}
                  name="manager"
                  initialValue={[]}
                  optionContent={(rec: TUser) => rec.full_name}
                  selector={GET_USER_MANAGERS_SELECTOR}
                />
              </Col>

              <Col {...twoColumns}>
                <FormItemGeneric<TUserInviteReq>
                  name="email"
                  label={translate('email')}
                  validateTrigger={['onBlur']}
                  rules={[{required: true}, {validator: emailValidator}]}
                >
                  <Input onFocus={setAutocompleteNone} />
                </FormItemGeneric>
              </Col>

              <Col {...twoColumns}>
                <FormItemGeneric<TUserInviteReq>
                  name="channel"
                  label={translate('channel')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={e => e.parentNode}
                    onFocus={() => fetchChannels()}
                    onChange={() => {
                      form.resetFields([
                        'roles',
                        'user_groups',
                        'assignment_groups',
                      ])
                      setRefresh(Math.random())
                    }}
                    loading={checkIsLoading(channelFetchStatus)}
                  >
                    {checkIsLoading(channelFetchStatus) ? (
                      <Option>
                        <Spin />
                      </Option>
                    ) : (
                      channels?.map(({name, space}, i) => (
                        <Option value={space} key={i}>
                          {name}
                        </Option>
                      ))
                    )}
                  </Select>
                </FormItemGeneric>
              </Col>

              <Col {...twoColumns}>
                <SelectWithBM
                  mode="multiple"
                  required={true}
                  label={translate('roles')}
                  searchKey="name"
                  name="roles"
                  data={roles}
                  initialValue={[]}
                  optionContent={(rec: {name: string}) => rec.name}
                  onFocus={() => getRoles(form.getFieldValue('channel'))}
                  loadingSelect={checkIsLoading(rolesFetchStatus)}
                  disabled={!form.getFieldValue('channel')}
                  className="full-width"
                  rules={{
                    required: true,
                  }}
                  filterOption={filterOption}
                />
              </Col>

              <Col {...twoColumns}>
                <SelectWithBM
                  api={assignmentGroupsApi().get}
                  disabled={!selectedChannelID}
                  label={translate('assignmentGroups')}
                  name="assignment_groups"
                  mode="multiple"
                  optionContent={getAssignmentGroupsName}
                  getPopupContainer={() => document.body}
                  // @ts-ignore scAuthHeaders type error
                  scAuthHeaders={{
                    'Grpc-Metadata-space': selectedChannelID,
                    ...getToken(),
                  }}
                />
              </Col>

              <Col {...twoColumns}>
                <FormItemGeneric<TUserInviteReq>
                  name="contract_type"
                  label={translate('contract_type')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    {contractType.map((contract, index) => {
                      return (
                        <Option value={contract} key={index}>
                          {translate(contract as TKey)}
                        </Option>
                      )
                    })}
                  </Select>
                </FormItemGeneric>
              </Col>

              <Col {...twoColumns}>
                <FormItemGeneric<TUserInviteReq>
                  name="type"
                  label={
                    <span>
                      {translate('type')}
                      <Tooltip
                        placement="right"
                        title={translate('user_type_info')}
                      >
                        <InfoCircleOutlined className="ml-5" />
                      </Tooltip>
                    </span>
                  }
                  rules={[
                    {
                      message: `${translate('type')} ${translate(
                        'is_required'
                      )}`,
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    {contractRole.map((type, index) => {
                      return (
                        <Option value={type} key={index}>
                          {type}
                        </Option>
                      )
                    })}
                  </Select>
                </FormItemGeneric>
              </Col>

              <Col className={'mb-24'} span={24}>
                <Collapse>
                  <Panel key={'1'} header={'advanced'}>
                    <Row gutter={24}>
                      <Col {...twoColumns}>
                        <FormItemGeneric<TUserInviteReq>
                          name="noCmdbInvite"
                          initialValue={false}
                          valuePropName={'checked'}
                        >
                          <Checkbox>
                            <span>
                              {translate('no_cmdb_invite')}
                              <Tooltip
                                placement="right"
                                title={translate('no_cmdb_saas_invite')}
                              >
                                <InfoCircleOutlined className="ml-5" />
                              </Tooltip>
                            </span>
                          </Checkbox>
                        </FormItemGeneric>
                      </Col>

                      <Col {...twoColumns}>
                        <FormItemGeneric<TUserInviteReq>
                          name="noSaasInvite"
                          initialValue={false}
                          valuePropName={'checked'}
                        >
                          <Checkbox>
                            <span>
                              {translate('no_saas_invite')}
                              <Tooltip
                                placement="right"
                                title={translate('no_cmdb_saas_invite')}
                              >
                                <InfoCircleOutlined className="ml-5" />
                              </Tooltip>
                            </span>
                          </Checkbox>
                        </FormItemGeneric>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>

              <Col span={24}>
                <Switch
                  checked={userDefaults}
                  onChange={() => setUserDefaults(!userDefaults)}
                  className="mr-26"
                />
                <span>{translate('create_user')}</span>
              </Col>
            </Row>
            {userDefaults && (
              <UserInvitationForm
                onEditorStateChange={onEditorStateChange}
                editorState={editorState}
                selectedChannelID={selectedChannelID}
              />
            )}
          </Form>
        </Paper>
      )}
    </>
  )
}

export default UserInvitation
