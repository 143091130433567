import React from 'react'

import classnames from 'classnames'

import {FormSkeleton, SkeletonItem} from '@/components/skeleton'

import {Tabs} from './tabs'

import './tabs.scss'

const TABS_KEYS = ['0', '1', '2', '3']

type TProps = {
  className?: string
}

export const TabsSkeleton: React.FC<TProps> = ({className}) => {
  const tab = <SkeletonItem width={150} height={32} />
  const classes = classnames('tabs__skeleton', className)
  return (
    <Tabs
      tabPosition="left"
      activeKey={TABS_KEYS[0]}
      className={classes}
      items={TABS_KEYS.map(index => ({
        key: index,
        label: tab,
        children: <div>{index === TABS_KEYS[0] ? <FormSkeleton /> : null}</div>,
      }))}
    />
  )
}
