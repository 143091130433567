import React, {useCallback, useEffect, useState} from 'react'

import {Tag} from 'antd'
import {useHistory} from 'react-router-dom'
// import {PlusOutlined} from '@ant-design/icons'

// import {Button} from '@/components/button'

import DataTable from '@/components/data-table/data-table'

import {getAccounts} from '@/api/accountRequests'
import {translate} from '@/services/i18n'
import {Assets} from '@/typedef'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

import {getOrgForAccountTS} from '@/modules/ITSM/api/itsm-org-requests'

import TierTag from './TierTag'

const AccountsList = props => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(undefined)

  const history = useHistory()

  const getColumns = () => {
    return [
      {
        title: translate('name'),
        dataIndex: 'name',
        width: '25%',
      },
      {
        title: translate('tier'),
        dataIndex: 'tier',
        render: tier => <TierTag tier={tier} />,
      },
      {
        title: translate('organizations'),
        dataIndex: 'orgs',
        render: orgs => (
          <span>
            {orgs.map(org => (
              <Tag color="geekblue" key={org.id}>
                {org.name}
              </Tag>
            ))}
          </span>
        ),
      },
    ]
  }

  const {setToastMessage, myAccounts} = props

  const fetchAccounts = useCallback(async () => {
    try {
      const res = await getAccounts(myAccounts)
      setLoading(true)

      let requests = res.body.map(elem => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {
          try {
            const res = await getOrgForAccountTS({id: elem.id})
            elem.orgs = res.body
            resolve(elem)
          } catch (err) {
            setToastMessage({message: err})
          }
        })
      })
      Promise.all(requests).then(() => {
        setLoading(false)
        setAccounts(res.body)
      })
    } catch (err) {
      setToastMessage({message: err})
    }
  }, [setToastMessage, myAccounts])

  const rowClick = record => {
    history.push(
      props.myAccounts
        ? itsmRoutes.settingsITSMmyAccountsDetails.path(record.id)
        : itsmRoutes.settingsITSMusersAccountsDetails.path(record.id)
    )
  }

  const asset = props.myAccounts
    ? Assets.myAccountsAsset
    : Assets.usersAccountsAsset

  // const handleAddNew = () => {
  //   history.push(itsmRoutes.settingsITSMNewAccount.path())
  // }

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  return (
    <DataTable
      className="accounts-list"
      name={
        props.myAccounts
          ? translate('my_accounts')
          : translate('manage_accounts')
      }
      // tableHeader={
      //   <div className="flex flex--justifyEnd">
      //     <Button
      //       className={'buttons list-table-header__btn-new'}
      //       type="primary"
      //       title={translate('add_new')}
      //       onClick={handleAddNew}
      //       icon={<PlusOutlined />}
      //       e2e-test="add_new"
      //     />
      //   </div>
      // }
      loading={loading || accounts.length === 0}
      asset={asset}
      bordered={true}
      rowKey="id"
      data={accounts}
      onRowClick={rowClick}
      fetch={fetchAccounts}
      columns={getColumns()}
      rowClassName="pointer-cursor"
      pagination={
        accounts && accounts.length > 10
          ? {
              pageSize: 10,
              current: currentPage,
              onChange: e => {
                setCurrentPage(e)
              },
            }
          : false
      }
    />
  )
}

export default AccountsList
