import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {timeSessionApi} from '@/modules/ITSM/api/generate-itsm-api-url'

export const fetchTimeSessions = async query => {
  return await request
    .options(timeSessionApi().get)
    .send({
      selector: query,
      sort: [{created_at: 'desc'}],
    })
    .set(getHeadersWithGRPC())
}

export const postTimeSession = async data => {
  return await request
    .post(timeSessionApi().post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const patchTimeSession = async (id, data) => {
  return await request
    .patch(timeSessionApi(id).getOne)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postAddToTimeSession = async data => {
  return await request
    .post(timeSessionApi().add)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postTimeSessionTimespan = async data => {
  return await request
    .post(timeSessionApi().addTimeSessionTimespan)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postEditTimeSpan = async data => {
  return await request
    .post(timeSessionApi().updateTimeSessionTimespan)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const deleteTimeSpan = async data => {
  return await request
    .post(timeSessionApi().deleteTimeSessionTimespan)
    .set(getHeadersWithGRPC())
    .send(data)
}
