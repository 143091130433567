import {useState} from 'react'

import {saveAs} from 'file-saver'
import {useDispatch} from 'react-redux'

import {RequestStatus} from '@/typedef'
import {extensionPicker} from '@/modules/ITSM/utils/Helpers'
import {getCustomReports} from '@/modules/ITSM/api/customReportsRequests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

const useGetCustomReports = () => {
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const dispatch = useDispatch()

  const getReport = async (values: any) => {
    setStatus(RequestStatus.REQUESTED)
    try {
      const res = await getCustomReports(values.query, values.payload)
      const typeFile = extensionPicker(res.type)
      const file = new Blob([res.body], {
        type: res.type,
      })
      saveAs(file, `Custom_report.${typeFile}`)

      setStatus(RequestStatus.SUCCEEDED)
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      setStatus(RequestStatus.FAILED)
    }
    setStatus(RequestStatus.INITIAL)
  }
  return {
    getReport,
    status,
  }
}
export default useGetCustomReports
