import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {attachmentsV2} from '@/modules/ITSM/api/generate-itsm-api-url'

export const postAttachmentV2 = async fileData => {
  const {data, id, entity, name} = fileData

  return await request
    .post(attachmentsV2().post(id, entity, name))
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getAttachmentV2 = async uuid => {
  return await request
    .get(attachmentsV2().get(uuid))
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const getAllAttachmentsV2 = async (uuid, entity, offset) => {
  return await request
    .options(attachmentsV2().getAll(uuid, entity, offset))
    .set(getHeadersWithGRPC())
}
