import {
  AssigneeStates,
  ASSIGNEE_STATES_NAMES,
} from '../../incident-k-request/constants'
import {INCIDENT_TASK_STATES_NAMES} from '../constants'
import {IncidentTaskState, TIncidentTask} from '../typedf'

export const generateSteps = (entityData: TIncidentTask | undefined) => {
  let activeStep: number | undefined,
    stepFields: string[] = [],
    blockedFields: number[] = [],
    checkedFields: number[] = []

  const {state_id, assignee_state} = entityData || {}
  const assigneeState = assignee_state && ASSIGNEE_STATES_NAMES[assignee_state]

  if (assigneeState) {
    switch (true) {
      case state_id === IncidentTaskState.New &&
        assignee_state === AssigneeStates.Unset:
        stepFields = [
          INCIDENT_TASK_STATES_NAMES.New,
          assigneeState,
          INCIDENT_TASK_STATES_NAMES.In_progress,
          INCIDENT_TASK_STATES_NAMES.Resolution,
          INCIDENT_TASK_STATES_NAMES.Closed_complete,
        ]
        activeStep = 0
        blockedFields = [1, 2, 3, 4]
        checkedFields = []
        break
      case state_id === IncidentTaskState.New &&
        (assignee_state === AssigneeStates.Proposed ||
          assignee_state === AssigneeStates.Accepted ||
          assignee_state === AssigneeStates.Rejected):
        stepFields = [
          INCIDENT_TASK_STATES_NAMES.New,
          assigneeState,
          INCIDENT_TASK_STATES_NAMES.In_progress,
          INCIDENT_TASK_STATES_NAMES.Resolution,
          INCIDENT_TASK_STATES_NAMES.Closed_complete,
        ]
        activeStep = 1
        blockedFields = [2, 3, 4]
        checkedFields = [0]
        break
      case state_id === IncidentTaskState.In_progress:
        stepFields = [
          INCIDENT_TASK_STATES_NAMES.New,
          assigneeState,
          INCIDENT_TASK_STATES_NAMES.In_progress,
          INCIDENT_TASK_STATES_NAMES.Resolution,
          INCIDENT_TASK_STATES_NAMES.Closed_complete,
        ]
        activeStep = 2
        blockedFields = [4]
        checkedFields = [0]
        break

      case state_id === IncidentTaskState.Closed_complete:
        stepFields = [
          INCIDENT_TASK_STATES_NAMES.New,
          assigneeState,
          INCIDENT_TASK_STATES_NAMES.In_progress,
          INCIDENT_TASK_STATES_NAMES.Resolution,
          INCIDENT_TASK_STATES_NAMES.Closed_complete,
        ]
        activeStep = 4
        blockedFields = []
        checkedFields = [0, 2, 4]
        break

      case state_id === IncidentTaskState.Closed_incomplete:
        stepFields = [
          INCIDENT_TASK_STATES_NAMES.New,
          assigneeState,
          INCIDENT_TASK_STATES_NAMES.In_progress,
          INCIDENT_TASK_STATES_NAMES.Resolution,
          INCIDENT_TASK_STATES_NAMES.Closed_incomplete,
        ]
        activeStep = 4
        blockedFields = []
        checkedFields = [0, 2, 4]
        break
      case state_id === IncidentTaskState.Closed_skipped:
        stepFields = [
          INCIDENT_TASK_STATES_NAMES.New,
          assigneeState,
          INCIDENT_TASK_STATES_NAMES.In_progress,
          INCIDENT_TASK_STATES_NAMES.Resolution,
          INCIDENT_TASK_STATES_NAMES.Closed_skipped,
        ]
        activeStep = 4
        blockedFields = []
        checkedFields = [0, 2, 4]
        break

      default:
        break
    }
  }

  return {
    activeStep,
    stepFields,
    blockedFields,
    checkedFields,
  }
}
