import React, {ReactNode} from 'react'

import classNames from 'classnames'

import {TKey, translate} from '@/services/i18n'
import {FilterBar} from '@/components/filter/bar/filter-bar'
import {Filter} from '@/components/filter/filter'
import {Skeleton, SkeletonItem} from '@/components/skeleton'
import {Sorting} from '@/components/sorting/sorting'
import {TGeneratorFilters, TQuickFilter} from '@/components/filter/typedef'
import {Paper} from '@/components/paper/paper'
import {SortingKeys, SortingValues} from '@/typedef'

import './list-table-header.scss'

const skeletonItemMap = [160, 140, 170, 130]

type TCustomizeTableToggleProps<T> = {
  title: TKey
  asset: T
  handleNew?: () => void
  tableCustomizer?: ReactNode
  sorter?: Record<string, SortingValues> | null
  setSorting: (data: Record<string, SortingValues> | null) => void
  shareTable?: () => void
  generatedFilters: TGeneratorFilters
  previewMode?: boolean
  resetFilters: (asset: T) => void
  resetQuickFilter?: (asset: T) => void
  onSearch: (key: string) => void
  onReset?: (key: string) => void
  moduleSorters: Record<any, Array<SortingKeys>>
  extraBtns?: ReactNode
  quickFilters?: Array<TQuickFilter>
  newEntityTitle?: string
  isLoading?: boolean
}

const ListTableHeader = <T extends string>({
  asset,
  tableCustomizer,
  sorter,
  setSorting,
  generatedFilters,
  previewMode,
  resetFilters,
  resetQuickFilter,
  onSearch,
  onReset,
  moduleSorters,
  extraBtns,
  quickFilters,
  isLoading = false,
}: TCustomizeTableToggleProps<T>) => {
  const [filterKey] = Object.keys(sorter || {})
  const [filterValue] = Object.values(sorter || {})

  const appliedAdvancedFilters = generatedFilters.some(
    ({isApplied}) => isApplied
  )

  const sorting = moduleSorters[asset] && (
    <Sorting
      sortedColumns={moduleSorters[asset].map(sortKey => {
        return {
          name: translate(sortKey as TKey),
          isActive: sortKey === filterKey,
          onClick: (type: SortingValues) => setSorting({[sortKey]: type}),
        }
      })}
      filterKey={filterKey}
      filterValue={filterValue}
      previewMode={previewMode}
    />
  )

  return (
    <Paper className="list-table-header mb-12">
      <Skeleton
        view={
          <div className="list-table-header__skeleton">
            {skeletonItemMap.map(width => (
              <SkeletonItem key={width} height="40px" width={width} />
            ))}
          </div>
        }
        active={isLoading}
      >
        <div className="list-table-header__nav" e2e-test="table-actions">
          <div className="list-table-header__buttons">
            <Filter
              asset={asset}
              generatedFilters={generatedFilters}
              resetFilters={() => resetFilters(asset)}
              resetQuickFilter={() =>
                resetQuickFilter && resetQuickFilter(asset)
              }
              onSearch={onSearch}
              onReset={onReset}
              previewMode={previewMode}
              quickFilters={quickFilters}
            />
            {sorting}
            {tableCustomizer && (
              <div className="list-table-header__table-customizer">
                {tableCustomizer}
              </div>
            )}
            {/* {!shareTableHidden && (
            <Button title={translate('share')} onClick={shareTable}>
              <ShareAltOutlined />
            </Button>
          )} */}

            {extraBtns}
          </div>
        </div>
        {onReset && generatedFilters.length > 0 && (
          <div
            className={classNames('list-table-header__filter-bar', {
              'list-table-header__filter-bar--hidden': !appliedAdvancedFilters,
            })}
          >
            <FilterBar
              generatedFilters={generatedFilters}
              removeFilter={onReset}
            />
          </div>
        )}
      </Skeleton>
    </Paper>
  )
}

export default ListTableHeader
