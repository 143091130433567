import React, {MouseEvent, useState, useRef} from 'react'

import {TextContent} from '@/modules/ITSM/components/text-content/text-content'
import {ActionButtons} from '@/modules/ITSM/components/action-buttons'

import './description-content.scss'

type TProps = {
  description: string
  className?: string
  isTextArea?: boolean
  isDisabled?: boolean
}

export const DescriptionContent: React.FC<TProps> = ({
  description,
  className,
  isTextArea,
  isDisabled,
}) => {
  const [showActionButtons, setShowActionButtons] = useState(false)
  const [uuid, setUuid] = useState('')

  const buttonAnchor = useRef<HTMLElement>()
  const onLinkClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    const element = event.target as HTMLElement

    if (element) {
      buttonAnchor.current = element
    }

    const isLink = element.tagName === 'A'
    const hrefAttribute = element.getAttribute('href')
    const uuid = hrefAttribute?.split(':')[1]

    if (hrefAttribute && isLink && uuid) {
      setShowActionButtons(true)
      setUuid(uuid)
    }
  }

  const hideActionButtons = () => {
    setShowActionButtons(false)
    setUuid('')
  }
  return (
    <div className="description-content">
      <TextContent
        text={description}
        onClick={onLinkClick}
        isTextArea={isTextArea}
        className={className}
        isDisabled={isDisabled}
      />
      {showActionButtons && (
        <ActionButtons
          anchor={buttonAnchor.current}
          uuid={uuid}
          onClose={hideActionButtons}
        />
      )}
    </div>
  )
}
