import React, {useState} from 'react'

import {Col, Divider, Form as AntForm, Input, Row, Select} from 'antd'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {Form} from '@/components/form/form/form'
import {TKey, translate} from '@/services/i18n'
import {emailValidator} from '@/utils/form-validators/email-validator'
import {org_roles} from '@/modules/ITSM/utils/Constants'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {selectPathname} from '@/redux/reducers/generalSelector'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {useGetRoles} from '@/modules/ITSM/hooks/use-get-roles'
import {checkIsLoading} from '@/utils/check-is-loading'
import {filterOption} from '@/utils/filter-option'
import {useFetchHook} from '@/hooks/useFetch'
import {postInvitation} from '@/modules/ITSM/api/invitationsRequests'
import {selectChannelID} from '@/redux/entries/entries-selector'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {parseNestedErrorMessage} from '@/utils/parse-nested-error-messege'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {createInvitationData} from './utils'
import {TOrgInviteReq} from './typedf'

import '../invitation.scss'

const {Option} = Select

const OrgInvitation = () => {
  const [form] = AntForm.useForm()

  const paths = useSelector(selectPathname)

  const history = useHistory()

  const {roles, getRoles, status: rolesFetchStatus} = useGetRoles()
  const [modal, setModal] = useState({
    visible: false,
    message: '',
    text: '',
    modalType: ModalType.INFO,
  })

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const channelId = useSelector(selectChannelID)

  const handleSubmit = async (values: TOrgInviteReq) => {
    try {
      handleRequested()

      const data = createInvitationData(values)

      const {body} = await postInvitation(data)
      if (body.Success !== null) {
        setModal({
          visible: true,
          message: translate('invite_send_seccess'),
          text: translate('send_new_invite'),
          modalType: ModalType.SUCCESS,
        })
        handleSuccess()
        form.resetFields()
      } else {
        handleFail(translate('sending_invitation_has_failed'))
      }
    } catch (err) {
      handleFail(parseNestedErrorMessage(err))
    } finally {
      handleReset()
    }
  }

  const handleGoBack = () => {
    history.push(
      paths[0] === paths[1]
        ? itsmRoutes.serviceManagementDashboard.path()
        : paths[0]
    )
  }

  return (
    <>
      {modal.visible ? (
        <Modal
          visible
          info={modal.message}
          okText={modal.text}
          handleAccept={() => setModal({...modal, visible: false})}
          handleCancel={handleGoBack}
          cancelText={translate('back')}
          onCancel={() => setModal({...modal, visible: false})}
          modalType={modal.modalType}
        />
      ) : (
        <Paper className="org-invitation" variant={PaperVariant.form}>
          <Form
            form={form}
            isLoading={checkIsLoading(status)}
            submitButtonTitle="submit"
            onSubmit={handleSubmit}
            header={
              <Row>
                <Col span={24}>
                  <GoBackButton onClick={handleGoBack} />
                </Col>
                <Divider />
              </Row>
            }
          >
            <Row gutter={24}>
              <Col {...twoColumns}>
                <FormItemGeneric<TOrgInviteReq>
                  name="email"
                  rules={[{required: true}, {validator: emailValidator}]}
                  label={translate('email')}
                >
                  <Input autoComplete="email" />
                </FormItemGeneric>
              </Col>
              <Col {...twoColumns}>
                <FormItemGeneric<TOrgInviteReq>
                  name="org_role"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: translate('org_role_req'),
                    },
                  ]}
                  label={translate('org_role')}
                >
                  <Select showSearch getPopupContainer={e => e.parentNode}>
                    {Object.values(org_roles)
                      .slice(1)
                      .map((role, index) => {
                        return (
                          <Option key={index} value={role}>
                            {translate(role as TKey)}
                          </Option>
                        )
                      })}
                  </Select>
                </FormItemGeneric>
              </Col>
              <Col {...twoColumns}>
                <SelectWithBM
                  mode="multiple"
                  required={true}
                  label={translate('roles')}
                  searchKey="name"
                  name="roles"
                  data={roles}
                  initialValue={[]}
                  optionContent={(rec: {name: string}) => rec.name}
                  onFocus={() =>
                    getRoles(form.getFieldValue('channel') || channelId)
                  }
                  loadingSelect={checkIsLoading(rolesFetchStatus)}
                  className="full-width"
                  rules={{
                    required: true,
                  }}
                  filterOption={filterOption}
                />
              </Col>
            </Row>
          </Form>
        </Paper>
      )}
    </>
  )
}

export default OrgInvitation
