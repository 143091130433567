import {AssigneeStates, IncidentState} from '../constants'
import {TIncident} from '../typedf'
import {findDisabledSteps} from '../utils/find-disabled-steps'

export const getFromInProgressState = (state_id: IncidentState) =>
  state_id === IncidentState.In_progress ||
  state_id === IncidentState.On_hold ||
  state_id === IncidentState.Resolved ||
  state_id === IncidentState.Closed

export const getFromOpenState = (entityData: TIncident) =>
  ((entityData.state_id === IncidentState.Pre_on_hold ||
    entityData.state_id === IncidentState.Open) &&
    entityData.assignee_state !== AssigneeStates.Unset) ||
  getFromInProgressState(entityData.state_id) ||
  (entityData.state_id === IncidentState.Cancelled &&
    findDisabledSteps(entityData).indexOf(1) === -1)

export const getFromInProgressCancelState = (entityData: TIncident) =>
  getFromInProgressState(entityData.state_id) ||
  (entityData.state_id === IncidentState.Cancelled &&
    findDisabledSteps(entityData).indexOf(2) === -1)
