import {message} from 'antd'

import {translate, TKey} from '@/services/i18n'
import {RequestStatus} from '@/typedef'

type THandleMessage = (
  status: RequestStatus,
  description?: {
    requestingMessage?: TKey
    successMessage?: TKey
    errorMessage?: TKey
  }
) => void

export enum MessageKey {
  REQUESTING = 'requesting',
  SUCCEEDED = 'successed',
  FAILED = 'failed',
}

export const handleMessage: THandleMessage = (
  status,
  description = {
    requestingMessage: 'requesting',
    successMessage: 'successfully_saved',
    errorMessage: 'something_went_wrong',
  }
) => {
  const {requestingMessage, successMessage, errorMessage} = description

  switch (status) {
    case RequestStatus.REQUESTED:
      message.destroy(MessageKey.REQUESTING)
      message.loading({
        content: requestingMessage ? translate(requestingMessage) : '',
        key: MessageKey.REQUESTING,
      })
      break
    case RequestStatus.SUCCEEDED:
      message.destroy(MessageKey.REQUESTING)
      message.destroy(MessageKey.SUCCEEDED)

      message.success({
        content: successMessage ? translate(successMessage) : '',
        key: MessageKey.SUCCEEDED,
      })
      break
    case RequestStatus.FAILED:
      message.destroy(MessageKey.REQUESTING)
      message.destroy(MessageKey.FAILED)

      message.error({
        content: errorMessage ? translate(errorMessage) : '',
        key: MessageKey.FAILED,
      })
      break
    default:
      break
  }
}
