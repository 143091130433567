import React, {useCallback, useEffect, useState} from 'react'

import {InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons'
import {Popover} from 'antd'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {locationsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {getFullLocation} from '../../utils/Helpers'
import {TLocation} from '../../typedef'
import LocationDetails from '../location-details/LocationDetails'

import {useLocationData} from './hooks/useLocationData'

import './location-select.scss'

type TProps = {
  locationId: string
  recordLocationData?: TLocation | undefined
  onSelect?: () => Record<string, unknown>
  labelSuffix?: React.ReactNode
  setRefresh?: () => void
  isDisabled?: boolean
  required?: boolean
}

export const LocationSelect: React.FC<TProps> = ({
  onSelect,
  locationId,
  recordLocationData,
  labelSuffix,
  setRefresh,
  isDisabled,
  required,
}) => {
  // fix it
  const [infoIconBusy, setInfoIconBusy] = useState(false)
  const [shownPopover, setShownPopover] = useState(false)

  const {locationData, getLocationData, setLocationData} = useLocationData()
  const initialValue = recordLocationData ? [recordLocationData] : []

  useEffect(() => {
    if (locationData && !infoIconBusy) {
      setShownPopover(true)
    }
  }, [locationData, infoIconBusy])

  const getLocationInfo = async () => {
    if (locationId) {
      setInfoIconBusy(true)
      await getLocationData(locationId)
      setInfoIconBusy(false)
    }
  }

  const onVisiblePopupChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setShownPopover(false)
      }
    },
    [setShownPopover]
  )

  const onFocusSelect = () => {
    setShownPopover(false)
  }

  const handleDisplayInfo = () => {
    if (recordLocationData?.uuid !== locationId) getLocationInfo()
    else {
      setLocationData(recordLocationData)
      setShownPopover(true)
    }
  }

  const getInfoIcon = () => {
    if (locationId) {
      return infoIconBusy ? (
        <LoadingOutlined type="info-circle" />
      ) : (
        <InfoCircleOutlined
          onClick={handleDisplayInfo}
          type="info-circle"
          className="ml-5"
        />
      )
    }
    return null
  }

  const label = (
    <div className="location-select__label">
      <span>
        {translate('location')}
        {getInfoIcon()}
      </span>
      {labelSuffix}
    </div>
  )

  return (
    <div id="location-select">
      <Popover
        trigger="click"
        placement="bottom"
        open={shownPopover}
        content={<LocationDetails location={locationData} />}
        onOpenChange={onVisiblePopupChange}
        getPopupContainer={() =>
          document.getElementById('location-select') as HTMLElement
        }
      >
        <div className="location-select">
          <SelectWithBM
            searchKey="full_location"
            name="location"
            onFocusAction={onFocusSelect}
            onSelect={onSelect || setRefresh}
            label={label}
            disabled={isDisabled}
            initialValue={initialValue}
            api={locationsApi().get}
            rules={{required, message: translate('location_req')}}
            optionContent={getFullLocation}
            blockOnFocus={true}
            fetchOnOpen={true}
          />
        </div>
      </Popover>
    </div>
  )
}
