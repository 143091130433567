import React, {FC} from 'react'

import {Checkbox, Form, Radio, Select} from 'antd'

import {ServicesStyledWrapper} from '@/sites/services/components/services-styled-wrapper'
import {ServicesFeatureList} from '@/sites/services/components/services-feature-list'

import './services-tab-pane.scss'

import {translate} from '@/services/i18n'

import {AddonItemTypes, SideTabs} from '@/sites/services/typedef'

import {countryOptions} from '@/sites/services/constants/country-options'
import {viewServices} from '@/sites/services/constants/view-services'

type TProps = {
  displayCountry: boolean
  name: SideTabs
}

export const ServicesTabPane: FC<TProps> = ({name, displayCountry}) => {
  const {addons, title, required, featureList} = viewServices[name]

  return (
    <div className="services-tab-pane__prices mb-27" data-testid={title}>
      {Object.values(addons)?.map(({id, name, items, isMultiple}) => (
        <div className="services-tab-pane__addon mb-25" key={id}>
          <p className="services-tab-pane__title mb-12 ml-6">{name}</p>
          <div className="services-tab-pane__tab-content">
            <ServicesStyledWrapper>
              {isMultiple && (
                <Form.Item name={id} rules={[{required}]}>
                  <Checkbox.Group options={items} />
                </Form.Item>
              )}

              {!isMultiple && required && (
                <Form.Item name={id} rules={[{required}]}>
                  <Radio.Group options={items} />
                </Form.Item>
              )}

              {!isMultiple && !required && (
                <Form.Item name={id} rules={[{required}]}>
                  <Checkbox.Group options={items} />
                </Form.Item>
              )}
            </ServicesStyledWrapper>
            {id === AddonItemTypes.SUPPORT_TYPE && displayCountry && (
              <div className="services-tab-pane__country mt-12">
                <ServicesStyledWrapper>
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: 'Required country',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={translate('select_countries')}
                      options={countryOptions}
                    />
                  </Form.Item>
                </ServicesStyledWrapper>
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="mt-30">
        <ServicesFeatureList featureList={featureList} title={title} />
      </div>
    </div>
  )
}
