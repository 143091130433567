import {CmdbChartType} from '@/modules/CMDB/api-types/Api'
import {generateConfig} from '@/generateConfig'

import store from '../../../redux/store'
import {getBackendTokenHeaders} from '@/services/api'

const url = `${generateConfig().API_URL_CMDB}`

export function getToken() {
  const {backEndToken} = store.getState().itsmUser
  return {
    Authorization: `Bearer ${backEndToken}`,
  }
}

export function getSpace() {
  const {cmdbNamespaceId} = store.getState().entries

  return cmdbNamespaceId ? {space: cmdbNamespaceId} : {}
}

export function getHeaders() {
  return {
    ...getSpace(),
    ...getBackendTokenHeaders(),
  }
}

export function getCMDBUserID() {
  const {cmdbUserId} = store.getState().itsmUser
  return cmdbUserId ? cmdbUserId : ''
}

export const identityApi = () => {
  return {
    get: url + '/identity/me',
    post: url + '/identity/me',
  }
}

export const myAssetsApi = () => {
  return {
    get: url + '/user-assets',
  }
}

export const assetsApi = (id?: string) => {
  return {
    get: url + '/assets',
    post: url + '/assets',
    patch: url + '/assets/' + id,
    getOne: url + '/assets/' + id,
    deleteOne: `${url}/assets/${id}`,
  }
}

export const schamasApi = (id?: string) => {
  return {
    post: url + '/schemas',
    get: url + '/schemas',
    getOne: url + '/schemas/' + id,
    patch: url + '/schemas/' + id,
  }
}

export const assetTypesApi = (id?: string) => {
  return {
    post: url + '/asset-types',
    get: url + '/asset-types',
    patch: url + '/asset-types/' + id,
    getOne: url + '/asset-types/' + id,
    deleteOne: `${url}/asset-types/${id}`,
  }
}

export const assetsGraphApi = () => {
  return {
    get: url + '/graph/assets',
  }
}

export const assetRelationsApi = () => {
  return {
    get: url + '/relations',
  }
}

export const fieldMappingApi = (id?: string) => {
  return {
    post: url + '/fields-mappings',
    get: url + '/fields-mappings',
    patch: url + '/fields-mappings/' + id,
    getOne: url + '/fields-mappings/' + id,
  }
}

export const locationsApi = (id = '') => {
  return {
    post: url + '/locations',
    get: url + '/locations',
    getOne: url + '/locations/' + id,
    patch: url + '/locations/' + id,
  }
}

export const importCatApi = () => {
  return {
    get: url + '/import-categories',
  }
}

export const categoriesApi = () => {
  return {
    get: url + '/categories',
  }
}

export const accountsApi = (id?: string) => {
  return {
    post: url + '/accounts',
    get: url + '/accounts',
    getOne: url + '/accounts/' + id,
    patch: url + '/accounts/' + id,
  }
}

export const accountRegisterApi = {
  post: `${url}/accounts/register`,
}

export const namespacesApi = (id?: string) => {
  return {
    post: url + '/namespaces',
    get: url + '/namespaces',
    getOne: url + '/namespaces/' + id,
    patch: url + '/namespaces/' + id,
  }
}

export const generateUserUrl = (id?: string) => {
  return {
    getAll: `${url}/users`,
    getOne: `${url}/users/${id}`,
    update: `${url}/users`,
    patch: `${url}/users/${id}`,
  }
}

export const reportsApi = (reportType: CmdbChartType) => {
  return {
    post: `${url}/charts`,
    // get: url + '/accounts',
    // getOne: url + '/accounts/' + id,
    // patch: url + '/accounts/' + id,
  }
}

export const generateAppsUrl = (id?: string) => {
  return {
    getAll: `${url}/apps`,
    getOne: `${url}/apps/${id}`,
    patch: `${url}/apps/${id}`,
    update: `${url}/apps`,
    post: `${url}/apps`,
  }
}

export const generateAppVendorsUrl = (id?: string) => {
  return {
    getAll: `${url}/app-vendors`,
    post: `${url}/app-vendors`,
  }
}

export const generateMetricsUrl = () => {
  return {
    get: `${url}/metrics`,
  }
}
