import request from 'superagent'

import {googleMapApi} from '../services/api'

export const fetchGoogleMapAddressByQuery = async (query, options) => {
  return await request.get(googleMapApi().address(query, options))
}

export const fetchGoogleMapAddressByCoordinates = async ({lat, lng}) => {
  return await request.get(googleMapApi().coordinates({lat, lng}))
}
