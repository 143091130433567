import React, {useCallback, useEffect, useState} from 'react'

import {withErrorBoundary} from '@sentry/react'
import {useDispatch, useSelector} from 'react-redux'
import {Coords} from 'google-map-react'
import {Col, Spin} from 'antd'

import ErrorPage from '@/components/error/error-page/error-page'
import LocationMap from '@/components/location-map/location-map'
import {translate} from '@/services/i18n'
import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {selectTimeSession} from '../../store/time-session/selectors'
import {getLocation} from '../../api/incidentsRequests'

const Map = () => {
  const timeSession = useSelector(selectTimeSession)

  const [mapOptions, setMapOptions] = useState<Coords>({lat: 0, lng: 0})
  const [loadingMap, setLoadingMap] = useState(false)

  const {uuid} = timeSession || {}
  const dispatch = useDispatch()

  const fetchLocation = useCallback(async () => {
    setLoadingMap(true)

    try {
      if (uuid) {
        const {body} = await getLocation(uuid)

        const {latitude, longitude} = body

        setMapOptions({lat: latitude, lng: longitude})
      }
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    } finally {
      setLoadingMap(false)
    }
  }, [dispatch, uuid])

  useEffect(() => {
    if (timeSession) fetchLocation()
  }, [fetchLocation, timeSession])

  const locationExists = mapOptions.lat && mapOptions.lng

  return (
    <Spin spinning={loadingMap}>
      <div>
        {locationExists ? (
          <LocationMap viewOnly={true} coordinates={mapOptions} />
        ) : (
          <Col span={24}>{translate('location_currently_unavailable')}</Col>
        )}
      </div>
    </Spin>
  )
}

export default withErrorBoundary(Map, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
