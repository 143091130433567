import React, {useEffect} from 'react'

import {Form, Tooltip} from 'antd'

import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'

import {ItsmAssets, TTimeLog, TTimelogForm} from '../../typedef'
import {useStateContext} from '../incident/incident-context'
import {TIncident} from '../incident-k-request/typedf'
import {resetItsmTableFilters} from '../../store/list-table/table-actions'

import {useDeleteTimeLog} from './hooks/useDeleteTimeLog'
import {CheckOutForm} from './time-log-check-out-form'
import {useTimelogCheckOut} from './hooks/useTimelogCheckOut'
import {createDataCheckOut} from './utils'

type TProps = {
  timelogOpened: TTimeLog[] | undefined
  checkInBtnVisible?: boolean
  refetchTimeLogs?: () => void
}

const TimeLogCheckout = ({
  timelogOpened,
  checkInBtnVisible,
  refetchTimeLogs,
}: TProps) => {
  const [form] = Form.useForm()
  const timelogId = timelogOpened && timelogOpened[0].uuid
  const {getData, dispatch} = useStateContext<TIncident>()

  const {handleCheckOut, status} = useTimelogCheckOut()
  const {handleDeleteTimeLog, deleteStatus} = useDeleteTimeLog({
    id: timelogId || '',
  })

  const handleCheckout = async (values: TTimelogForm) => {
    const data = timelogOpened && createDataCheckOut(values, timelogOpened)
    const result = data && (await handleCheckOut(data))

    if (result) {
      if (refetchTimeLogs) {
        refetchTimeLogs()
      } else {
        getData()
        dispatch(resetItsmTableFilters(ItsmAssets.TimeLogs))
      }
    }
  }

  useEffect(() => {
    form.setFieldsValue({ignore_extra_pricing_policy: true})
  }, [form])

  return (
    <>
      <div className="flex flex--justifyEnd">
        <Button
          title={`${translate('cancel')} ${translate(
            'time_log'
          ).toLowerCase()}`}
          className="time-log-details__cancel"
          onClick={async () => {
            const result = await handleDeleteTimeLog()

            if (result && refetchTimeLogs) refetchTimeLogs()
          }}
          disabled={checkIsLoading(deleteStatus)}
          size="large"
        />
      </div>
      <Form
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{required: '${label} is required'}}
        form={form}
        layout="vertical"
        onFinish={handleCheckout}
      >
        <CheckOutForm />
        <Tooltip
          placement="top"
          title={
            checkInBtnVisible !== undefined
              ? !checkInBtnVisible && translate('ticket_have_to_be_in_progress')
              : null
          }
        >
          <Button
            title={translate('check_out')}
            type="primary"
            disabled={
              checkIsLoading(status) ||
              (checkInBtnVisible !== undefined ? !checkInBtnVisible : false)
            }
            className="mb-10"
            htmlType="submit"
            e2e-test="check-out-btn"
          />
        </Tooltip>
      </Form>
    </>
  )
}

export default TimeLogCheckout
