import orderBy from 'lodash/orderBy'

import {PriceFeatureFlagKeys} from '@/modules/typedef'

export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export type TItemSettingsSubMenu = {
  path: string
  title: string
  featureFlag?: PriceFeatureFlagKeys
}

type TSortArrByValue = (
  array: Array<TItemSettingsSubMenu>,
  key: string,
  order?: Order
) => Array<any>

type TSortArrCaseInsensitive = (array: Array<any>, order?: Order) => Array<any>

export const sortArrByValue: TSortArrByValue = (
  array,
  key,
  order = Order.asc
) => orderBy(array, [key], [order])

export const sortArrayCaseInsensitive: TSortArrCaseInsensitive = (
  array,
  order = Order.asc
) => orderBy(array, [entry => entry.name.toLowerCase()], [order])
