import {call, put} from 'redux-saga/effects'

import {getIdentityMe} from '@/redux/user/userActions'
import {setItsmChannel} from '@/redux/entries/entries-actions'
import {getModalErrorData} from '@/utils/get-modal-error-data'
import {translate} from '@/services/i18n'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {postIdentityMe} from '@/modules/ITSM/api/userRequests'
import {ModalType} from '@/components/modal/typedef'
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'
import {createMetadataSpaceHeader} from '@/utils/create-metadata-space-header'

export function* refreshIdentityAddMeSaga({payload: channel}) {
  try {
    const {
      body: {Fail, Success},
    } = yield call(postIdentityMe, {
      ...getToken(),
      ...createMetadataSpaceHeader(channel.space),
    })

    if (Success) {
      yield put(
        setToastMessage({
          message: `${translate('setting_up_access_for_the_channel')} "${
            channel.name
          }" ${translate('has_successfully_completed')}`,
          type: ModalType.SUCCESS,
        })
      )

      yield put(
        setItsmChannel({
          channelName: channel.name,
          channelID: channel.space,
        })
      )

      yield put(getIdentityMe(true))
    }
    if (Fail) {
      yield put(
        setToastMessage({
          message: getModalErrorData(
            {message: `org_token: ${Fail} is invalid`},
            `${translate('setting_up_access_for_the_channel')} "${
              channel.name
            }" ${translate('has_failed')}`
          ),
        })
      )
    }
  } catch (err) {
    yield put(
      setToastMessage({
        message: getModalErrorData(
          err,
          `${translate('setting_up_access_for_the_channel')} "${
            channel.name
          }" ${translate('has_failed')}`
        ),
      })
    )
  }
}
