import React from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {Redirect, useParams} from 'react-router-dom'

import {setItsmTableQuickFilters} from '@/modules/ITSM/store/list-table/table-actions'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {IncidentsQuickFiltersUP} from '@/modules/ITSM/sites/incident-user-portal/constants'
import {selectRedirectToList} from '@/redux/ui/uiSelector'
import {setRedirectToList} from '@/redux/ui/uiActions'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

import IncidentTaskDetail from '../../components/incident-task/incident-task-detail/incident-task-detail'

const IncidentTask = () => {
  const {taskId} = useParams<{taskId: string}>()

  const dispatch = useDispatch()

  if (useSelector(selectRedirectToList)) {
    dispatch(setRedirectToList())
    dispatch(
      setItsmTableQuickFilters(
        ItsmAssets.Incidents,
        IncidentsQuickFiltersUP.Opened
      )
    )

    return <Redirect push to={itsmRoutes.serviceManagementIncidents.path()} />
  }

  return <IncidentTaskDetail id={taskId} />
}

export default IncidentTask
