export enum CreateAccSteps {
  INITIAL = 'INITIAL',
  POST_CREATE_ACCOUNT = 'POST_CREATE_ACCOUNT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  CREATE_STRIPE_CUSTOMER = 'CREATE_STRIPE_CUSTOMER',
  CREATE_ORG = 'CREATE_ORG',
  REFRESH_ORG_TOKEN = 'REFRESH_ORG_TOKEN',
  CREATE_CHANNEL = 'CREATE_CHANNEL',
  POST_PROFILE = 'POST_PROFILE',
  SUBSCRIBE_TO_PLAN = 'SUBSCRIBE_TO_PLAN',
}

export const createAccountStepProgress: {
  [key in CreateAccSteps]: {
    progressStatus: number
    uiErrorMessage: string
  }
} = {
  [CreateAccSteps.INITIAL]: {
    progressStatus: 0,
    uiErrorMessage: '',
  },
  [CreateAccSteps.POST_CREATE_ACCOUNT]: {
    progressStatus: 15,
    uiErrorMessage: '',
  },
  [CreateAccSteps.REFRESH_TOKEN]: {
    progressStatus: 27,
    uiErrorMessage: '',
  },
  [CreateAccSteps.CREATE_STRIPE_CUSTOMER]: {
    progressStatus: 37,
    uiErrorMessage: '',
  },
  [CreateAccSteps.CREATE_ORG]: {
    progressStatus: 47,
    uiErrorMessage: '',
  },
  [CreateAccSteps.REFRESH_ORG_TOKEN]: {
    progressStatus: 62,
    uiErrorMessage: '',
  },
  [CreateAccSteps.CREATE_CHANNEL]: {
    progressStatus: 75,
    uiErrorMessage: '',
  },
  [CreateAccSteps.POST_PROFILE]: {
    progressStatus: 95,
    uiErrorMessage: '',
  },
  [CreateAccSteps.SUBSCRIBE_TO_PLAN]: {
    progressStatus: 100,
    uiErrorMessage: '',
  },
}

// test
