import {translate} from '../../../services/i18n'

//if is in request field origin, this should be places there
export const origin = 'blits'

/**
 * Constants of states with value for incident (for better readability)
 */
export const incidentState = {
  new: 0,
  pre_on_hold: 1,
  in_progress: 2, //1
  on_hold: 3, //2
  resolved: 4, //3
  closed: 5, //4
  cancelled: 6, //5
}

export const invitationState = [
  {value: '3', name: translate('pending')},
  {value: '4', name: translate('accepted')},
]

export const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 20, offset: 2},
    lg: {span: 20, offset: 2},
    xl: {span: 20, offset: 2},
    xxl: {span: 20, offset: 2},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 20, offset: 2},
    lg: {span: 20, offset: 2},
    xl: {span: 20, offset: 2},
    xxl: {span: 20, offset: 2},
  },
}

export const tags = [
  'html',
  'body',
  'style',
  'span',
  'head',
  'header',
  'img',
  'h1',
  'h2',
  '!DOCTYPE',
  'article',
  'aside',
  'button',
  'canvas',
  'figure',
  'footer',
  'form',
  'input',
  'label',
  'main',
  'nav',
  'option',
  'section',
  'select',
  'svg',
  'textarea',
  'tfoot',
  'title',
]

export const attributes = [
  'class',
  'id',
  'href',
  'name',
  'target',
  'src',
  'alt',
  'placeholder',
]

export const period = [
  translate('hourly'),
  translate('daily'),
  translate('weekly'),
  translate('monthly'),
  translate('quarterly'),
  translate('yearly'),
]

export const draftToolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'remove',
    'history',
  ],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
}

/**
 * All incident statuses with translations
 */

export const months = {
  '01': translate('january'),
  '02': translate('february'),
  '03': translate('march'),
  '04': translate('april'),
  '05': translate('may'),
  '06': translate('june'),
  '07': translate('july'),
  '08': translate('august'),
  '09': translate('september'),
  '10': translate('october'),
  '11': translate('november'),
  '12': translate('december'),
}

export const org_roles = {
  all: 'all',
  provider: 'provider',
  customer: 'customer',
  supplier: 'supplier',
}

export const timeSessionStatesT = [
  translate('time_session_stateid_0'),
  translate('time_session_stateid_1'),
  translate('time_session_stateid_2'),
  translate('time_session_stateid_3'),
  translate('closed'),
]
export const colorsChart = [
  '#B07C00',
  '#B05900',
  '#40c2b3',
  '#236bde',
  '#e6178f',
]
export const colorsChartProvider = [
  '#FCB814',
  '#FC8A14',
  '#1B756B',
  '#0c3f91',
  '#780849',
]

export const app_version = ['app', 'portal']

export const contractType = ['employee', 'contractor']

export const contractRole = [
  'engineer',
  'non-engineer',
  'engineer-manager',
  'non-engineer-manager',
]

export const brTabs = {
  overviewTab: 'overview',
  billingItemsTab: 'billing-items',
  statisticsTab: 'statistics',
  anomaliesTab: 'anomalies',
  commentsTab: 'comments',
  invoicesTab: 'invoices',
}

export const extensionfilters = [
  {
    name: 'pdf',
    value: 'pdf',
  },
  {
    name: 'doc',
    value: 'doc',
  },
  {
    name: 'docx',
    value: 'docx',
  },
  {
    name: 'txt',
    value: 'txt',
  },
  {
    name: 'xls',
    value: 'xls',
  },
  {
    name: 'xlsx',
    value: 'xlsx',
  },
  {
    name: 'jpg',
    value: 'jpg',
  },
  {
    name: 'jpeg',
    value: 'jpeg',
  },
  {
    name: 'png',
    value: 'png',
  },
  {
    name: 'svg',
    value: 'svg',
  },
  {
    name: 'zip',
    value: 'zip',
  },
  {
    name: 'msg',
    value: 'msg',
  },
  {
    name: 'csv',
    value: 'csv',
  },
  {
    name: 'html',
    value: 'html',
  },
  {
    name: 'htm',
    value: 'htm',
  },
  {
    name: 'mp4',
    value: 'mp4',
  },
]

export const noPermission = 'permission denied'

export const monthsDataChart = [
  {
    month: translate('january'),
  },
  {
    month: translate('february'),
  },
  {
    month: translate('march'),
  },
  {
    month: translate('april'),
  },
  {
    month: translate('may'),
  },
  {
    month: translate('june'),
  },
  {
    month: translate('july'),
  },
  {
    month: translate('august'),
  },
  {
    month: translate('september'),
  },
  {
    month: translate('october'),
  },
  {
    month: translate('november'),
  },
  {
    month: translate('december'),
  },
]

export const SlaTypes = {
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
  P5: 'P5',
  P6: 'P6',
  P7: 'P7',
  P8: 'P8',
  P9: 'P9',
  P10: 'P10',
}

export const ProductTypes = {
  HE: 'hw',
  SW: 'sw',
  SERVICES: 'services',
}
export const ResponseTypes = {
  HOURS: 'hours',
  DAYS: 'days',
}

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const timeFormat = 'HH:mm'

export const WEEKDAYS = {
  '1': {
    support_from: '08:00',
    support_to: '17:00',
  },
  '2': {
    support_from: '08:00',
    support_to: '17:00',
  },
  '3': {
    support_from: '08:00',
    support_to: '17:00',
  },
  '4': {
    support_from: '08:00',
    support_to: '17:00',
  },
  '5': {
    support_from: '08:00',
    support_to: '17:00',
  },
}
export const dayNumberFormat = 'YYYY-MM-DD'

export const currencies = [
  {value: 'AED', name: 'United Arab Emirates Dirham'},
  {value: 'AFN', name: 'Afghanistan Afghani'},
  {value: 'ALL', name: 'Albania Lek'},
  {value: 'AMD', name: 'Armenia Dram'},
  {value: 'ANG', name: 'Netherlands Antilles Guilder'},
  {value: 'AOA', name: 'Angola Kwanza'},
  {value: 'ARS', name: 'Argentina Peso'},
  {value: 'AUD', name: 'Australia Dollar'},
  {value: 'AWG', name: 'Aruba Guilder'},
  {value: 'AZN', name: 'Azerbaijan Manat'},
  {value: 'BAM', name: 'Bosnia and Herzegovina Convertible Mark'},
  {value: 'BBD', name: 'Barbados Dollar'},
  {value: 'BDT', name: 'Bangladesh Taka'},
  {value: 'BGN', name: 'Bulgaria Lev'},
  {value: 'BHD', name: 'Bahrain Dinar'},
  {value: 'BIF', name: 'Burundi Franc'},
  {value: 'BMD', name: 'Bermuda Dollar'},
  {value: 'BND', name: 'Brunei Darussalam Dollar'},
  {value: 'BOB', name: 'Bolivia Bolíviano'},
  {value: 'BRL', name: 'Brazil Real'},
  {value: 'BSD', name: 'Bahamas Dollar'},
  {value: 'BTN', name: 'Bhutan Ngultrum'},
  {value: 'BWP', name: 'Botswana Pula'},
  {value: 'BYN', name: 'Belarus Ruble'},
  {value: 'BZD', name: 'Belize Dollar'},
  {value: 'CAD', name: 'Canada Dollar'},
  {value: 'CDF', name: 'Congo/Kinshasa Franc'},
  {value: 'CHF', name: 'Switzerland Franc'},
  {value: 'CLP', name: 'Chile Peso'},
  {value: 'CNY', name: 'China Yuan Renminbi'},
  {value: 'COP', name: 'Colombia Peso'},
  {value: 'CRC', name: 'Costa Rica Colon'},
  {value: 'CUC', name: 'Cuba Convertible Peso'},
  {value: 'CUP', name: 'Cuba Peso'},
  {value: 'CVE', name: 'Cape Verde Escudo'},
  {value: 'CZK', name: 'Czech Republic Koruna'},
  {value: 'DJF', name: 'Djibouti Franc'},
  {value: 'DKK', name: 'Denmark Krone'},
  {value: 'DOP', name: 'Dominican Republic Peso'},
  {value: 'DZD', name: 'Algeria Dinar'},
  {value: 'EGP', name: 'Egypt Pound'},
  {value: 'ERN', name: 'Eritrea Nakfa'},
  {value: 'ETB', name: 'Ethiopia Birr'},
  {value: 'EUR', name: 'Euro Member Countries'},
  {value: 'FJD', name: 'Fiji Dollar'},
  {value: 'FKP', name: 'Falkland Islands (Malvinas) Pound'},
  {value: 'GBP', name: 'United Kingdom Pound'},
  {value: 'GEL', name: 'Georgia Lari'},
  {value: 'GGP', name: 'Guernsey Pound'},
  {value: 'GHS', name: 'Ghana Cedi'},
  {value: 'GIP', name: 'Gibraltar Pound'},
  {value: 'GMD', name: 'Gambia Dalasi'},
  {value: 'GNF', name: 'Guinea Franc'},
  {value: 'GTQ', name: 'Guatemala Quetzal'},
  {value: 'GYD', name: 'Guyana Dollar'},
  {value: 'HKD', name: 'Hong Kong Dollar'},
  {value: 'HNL', name: 'Honduras Lempira'},
  {value: 'HRK', name: 'Croatia Kuna'},
  {value: 'HTG', name: 'Haiti Gourde'},
  {value: 'HUF', name: 'Hungary Forint'},
  {value: 'IDR', name: 'Indonesia Rupiah'},
  {value: 'ILS', name: 'Israel Shekel'},
  {value: 'IMP', name: 'Isle of Man Pound'},
  {value: 'INR', name: 'India Rupee'},
  {value: 'IQD', name: 'Iraq Dinar'},
  {value: 'IRR', name: 'Iran Rial'},
  {value: 'ISK', name: 'Iceland Krona'},
  {value: 'JEP', name: 'Jersey Pound'},
  {value: 'JMD', name: 'Jamaica Dollar'},
  {value: 'JOD', name: 'Jordan Dinar'},
  {value: 'JPY', name: 'Japan Yen'},
  {value: 'KES', name: 'Kenya Shilling'},
  {value: 'KGS', name: 'Kyrgyzstan Som'},
  {value: 'KHR', name: 'Cambodia Riel'},
  {value: 'KMF', name: 'Comorian Franc'},
  {value: 'KPW', name: 'Korea (North) Won'},
  {value: 'KRW', name: 'Korea (South) Won'},
  {value: 'KWD', name: 'Kuwait Dinar'},
  {value: 'KYD', name: 'Cayman Islands Dollar'},
  {value: 'KZT', name: 'Kazakhstan Tenge'},
  {value: 'LAK', name: 'Laos Kip'},
  {value: 'LBP', name: 'Lebanon Pound'},
  {value: 'LKR', name: 'Sri Lanka Rupee'},
  {value: 'LRD', name: 'Liberia Dollar'},
  {value: 'LSL', name: 'Lesotho Loti'},
  {value: 'LYD', name: 'Libya Dinar'},
  {value: 'MAD', name: 'Morocco Dirham'},
  {value: 'MDL', name: 'Moldova Leu'},
  {value: 'MGA', name: 'Madagascar Ariary'},
  {value: 'MKD', name: 'Macedonia Denar'},
  {value: 'MMK', name: 'Myanmar (Burma) Kyat'},
  {value: 'MNT', name: 'Mongolia Tughrik'},
  {value: 'MOP', name: 'Macau Pataca'},
  {value: 'MRU', name: 'Mauritania Ouguiya'},
  {value: 'MUR', name: 'Mauritius Rupee'},
  {value: 'MVR', name: 'Maldives (Maldive Islands) Rufiyaa'},
  {value: 'MWK', name: 'Malawi Kwacha'},
  {value: 'MXN', name: 'Mexico Peso'},
  {value: 'MYR', name: 'Malaysia Ringgit'},
  {value: 'MZN', name: 'Mozambique Metical'},
  {value: 'NAD', name: 'Namibia Dollar'},
  {value: 'NGN', name: 'Nigeria Naira'},
  {value: 'NIO', name: 'Nicaragua Cordoba'},
  {value: 'NOK', name: 'Norway Krone'},
  {value: 'NPR', name: 'Nepal Rupee'},
  {value: 'NZD', name: 'New Zealand Dollar'},
  {value: 'OMR', name: 'Oman Rial'},
  {value: 'PAB', name: 'Panama Balboa'},
  {value: 'PEN', name: 'Peru Sol'},
  {value: 'PGK', name: 'Papua New Guinea Kina'},
  {value: 'PHP', name: 'Philippines Peso'},
  {value: 'PKR', name: 'Pakistan Rupee'},
  {value: 'PLN', name: 'Poland Zloty'},
  {value: 'PYG', name: 'Paraguay Guarani'},
  {value: 'QAR', name: 'Qatar Riyal'},
  {value: 'RON', name: 'Romania Leu'},
  {value: 'RSD', name: 'Serbia Dinar'},
  {value: 'RUB', name: 'Russia Ruble'},
  {value: 'RWF', name: 'Rwanda Franc'},
  {value: 'SAR', name: 'Saudi Arabia Riyal'},
  {value: 'SBD', name: 'Solomon Islands Dollar'},
  {value: 'SCR', name: 'Seychelles Rupee'},
  {value: 'SDG', name: 'Sudan Pound'},
  {value: 'SEK', name: 'Sweden Krona'},
  {value: 'SGD', name: 'Singapore Dollar'},
  {value: 'SHP', name: 'Saint Helena Pound'},
  {value: 'SLL', name: 'Sierra Leone Leone'},
  {value: 'SOS', name: 'Somalia Shilling'},
  {value: 'SPL*', name: 'Seborga Luigino'},
  {value: 'SRD', name: 'Suriname Dollar'},
  {value: 'STN', name: 'São Tomé and Príncipe Dobra'},
  {value: 'SVC', name: 'El Salvador Colon'},
  {value: 'SYP', name: 'Syria Pound'},
  {value: 'SZL', name: 'eSwatini Lilangeni'},
  {value: 'THB', name: 'Thailand Baht'},
  {value: 'TJS', name: 'Tajikistan Somoni'},
  {value: 'TMT', name: 'Turkmenistan Manat'},
  {value: 'TND', name: 'Tunisia Dinar'},
  {value: 'TOP', name: "Tonga Pa'anga"},
  {value: 'TRY', name: 'Turkey Lira'},
  {value: 'TTD', name: 'Trinidad and Tobago Dollar'},
  {value: 'TVD', name: 'Tuvalu Dollar'},
  {value: 'TWD', name: 'Taiwan New Dollar'},
  {value: 'TZS', name: 'Tanzania Shilling'},
  {value: 'UAH', name: 'Ukraine Hryvnia'},
  {value: 'UGX', name: 'Uganda Shilling'},
  {value: 'USD', name: 'United States Dollar'},
  {value: 'UYU', name: 'Uruguay Peso'},
  {value: 'UZS', name: 'Uzbekistan Som'},
  {value: 'VEF', name: 'Venezuela Bolívar'},
  {value: 'VND', name: 'Viet Nam Dong'},
  {value: 'VUV', name: 'Vanuatu Vatu'},
  {value: 'WST', name: 'Samoa Tala'},
  {
    value: 'XAF',
    name: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
  },
  {value: 'XCD', name: 'East Caribbean Dollar'},
  {
    value: 'XDR',
    name: 'International Monetary Fund (IMF) Special Drawing Rights',
  },
  {value: 'XOF', name: 'Communauté Financière Africaine (BCEAO) Franc'},
  {value: 'XPF', name: 'Comptoirs Français du Pacifique (CFP) Franc'},
  {value: 'YER', name: 'Yemen Rial'},
  {value: 'ZAR', name: 'South Africa Rand'},
  {value: 'ZMW', name: 'Zambia Kwacha'},
  {value: 'ZWD', name: 'Zimbabwe Dollar'},
]
