import React from 'react'

import {apiService} from '@/modules/ITSM/api/api-service'
import {ItsmAssets, TBillingItem} from '@/modules/ITSM/typedef'
import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {useGetDataListTable} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/hooks/use-get-data-list-table'
import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table/itsm-list-table'
import {translate} from '@/services/i18n'

import {BillingItemsRowValue} from './billing-items-row-value'
import {getUpdatedColumns} from './utils/get-updated-columns'
import {getExpandedRowRender} from './utils/get-expanded-columns'
import useGetQuickFilters from './hooks/use-get-quick-filters'
import {useExportBillingItems} from './hooks/use-export-billing-items'
import {ExportDropdown} from './billing-items-export'
import {parseAsyncFunction} from './utils/parse-async-function'

import './billing-items.scss'

const asset = ItsmAssets.BillingItems

type TProps = {
  billingItemsSelector: {uuid: {$or: Array<string>}} | {billing_report: string}
}

const BillingItems = ({billingItemsSelector}: TProps) => {
  const {handleExportBillingItems} = useExportBillingItems()

  const {
    getEntityData,
    entityData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTable<TBillingItem>(
    parseAsyncFunction(apiService[asset]),
    asset,
    billingItemsSelector
  )

  const {getQuickFilters, setQuickFilters} = useGetQuickFilters({
    asset,
  })

  const handleGetRowValue = (props: TColumnRender<TBillingItem>) => (
    <BillingItemsRowValue {...props} />
  )

  return (
    <div className="billing-items">
      <ItsmListTable<TBillingItem>
        asset={asset}
        entityData={entityData}
        getUpdatedColumns={columns =>
          getUpdatedColumns(columns, entityData?.[0])
        }
        getRowValue={handleGetRowValue}
        getEntityData={getEntityData}
        numberOfRecords={numberOfRecords}
        rowKey="uuid"
        fetchStatus={fetchStatus}
        expandable={{
          expandedRowRender: (row: TBillingItem) =>
            getExpandedRowRender(row, entityData),
        }}
        setQuickFilters={setQuickFilters}
        getQuickFilters={getQuickFilters}
        exportBtns={
          <div className="billing-items__export">
            <ExportDropdown
              value={translate('export_chargeable')}
              onChange={format =>
                handleExportBillingItems(format, {
                  ...billingItemsSelector,
                  chargeable: true,
                })
              }
            />
            <ExportDropdown
              value={translate('export_all')}
              onChange={format =>
                handleExportBillingItems(format, {
                  ...billingItemsSelector,
                })
              }
            />
          </div>
        }
      />
    </div>
  )
}

export default BillingItems
