import {Button} from '@/components/button'
import {RadioGroup} from '@/components/radio-group/radio-group'
import SvgIcon from '@/components/svg-icon/svg-icon'
import globalRoutes from '@/global-routes'
import {Interval} from '@/modules/typedef'
import {translate} from '@/services/i18n'
import {CheckOutlined} from '@ant-design/icons'
import {Col, Divider, Row} from 'antd'
import classnames from 'classnames'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {cyclePriceOptions} from '../../constants/cycle-prices-options'
import {detailsModules} from '../../constants/details-purchase-modules'
import {parseSubscriptionsMessage} from '../../constants/parse-subscriptions-message'
import {PurchaseAddon} from '../purchase-addon/purchase-addon'

import {PurchasePrice} from '../purchase-price/purchase-price'
import {
  ModuleFeatureFlags,
  TExtraAddons,
  TNormalizedProduct,
  TPurchasesAddons,
  TPurchasesModule,
} from '../../typedef'
import {countQuantityPurchases} from '../../utils/count-quantity-purchases'
import './mobile-purchase.scss'
import {PurchaseModule} from '../purchase-module/purchase-module'

const requiredModule = ModuleFeatureFlags.ITSM

type TProps = {
  selectedModules: TPurchasesModule
  selectedAddons: TPurchasesAddons
  selectedExtraAddons: TExtraAddons
  setIsDisplayCart: React.Dispatch<React.SetStateAction<boolean>>
  intervalType: Interval
  handleSelectedPurchaseModule: (module: ModuleFeatureFlags) => void
  formatPrice: (
    amount?: number,
    currency?: string,
    interval?: Interval
  ) => string
  modulesAddonList: Record<ModuleFeatureFlags, TNormalizedProduct[]>
  handleSelectAddon: (module: ModuleFeatureFlags, addon: string) => void
  supportServicesList: Record<ModuleFeatureFlags, TNormalizedProduct[]>
  handleSelectExtraAddon: (extraAddon: string) => void
  handleBuyNow: () => void
  setIntervalType: React.Dispatch<React.SetStateAction<Interval>>
  modulesList: {
    moduleProduct: TNormalizedProduct | undefined
    isSelectedModule: boolean
  }[]
}

export const MobilePurchase: React.FC<TProps> = ({
  selectedModules,
  selectedAddons,
  selectedExtraAddons,
  setIsDisplayCart,
  intervalType,
  handleSelectedPurchaseModule,
  formatPrice,
  modulesAddonList,
  handleSelectAddon,
  supportServicesList,
  handleSelectExtraAddon,
  handleBuyNow,
  setIntervalType,
  modulesList,
}) => {
  const history = useHistory()

  return (
    <div className="mobile-purchase" data-testid="mobile-purchase">
      <div className="mobile-purchase__header pb-16">
        <div className=" flex flex--spaceBetween pt-24 mb-24">
          <SvgIcon
            name="blits-logo"
            className="mobile-purchase__logo"
            width={100}
            onClick={() => history.push(globalRoutes.general.homePage.path())}
          />
          <div
            className="mobile-purchase__cart"
            onClick={() => setIsDisplayCart(true)}
          >
            <SvgIcon
              name="cart"
              className="mobile-purchase__cart-icon"
              width={28}
            />

            <div className="mobile-purchase__counter-wrapper">
              <span className="mobile-purchase__counter">
                {`${countQuantityPurchases(
                  selectedModules,
                  selectedAddons,
                  selectedExtraAddons
                )}`}
              </span>
            </div>
          </div>
        </div>
        <p className="mobile-purchase-title">
          {translate('finalise_your_purchase')}
        </p>
        <div className="flex flex--justifyCenter mt-16 mb-8">
          <RadioGroup
            options={cyclePriceOptions}
            onChange={({target: {value}}) => setIntervalType(value)}
            value={intervalType}
          />
        </div>
      </div>

      <Row className="mobile-purchase__content ml-4 mr-4">
        {modulesList.map(({isSelectedModule, moduleProduct}) => {
          return (
            <Col key={moduleProduct?.category} xs={24}>
              {moduleProduct && (
                <PurchaseModule
                  {...(requiredModule !== moduleProduct?.category &&
                  moduleProduct
                    ? {
                        onClick: () =>
                          handleSelectedPurchaseModule(moduleProduct?.category),
                      }
                    : {})}
                  isSelected={isSelectedModule}
                  title={moduleProduct?.name || moduleProduct?.category}
                  price={formatPrice(
                    moduleProduct?.unit_amount,
                    moduleProduct?.currency,
                    moduleProduct?.interval
                  )}
                  icon={moduleProduct?.icon}
                  pricingUnitMetadata={moduleProduct?.per_custom_unit}
                  module={moduleProduct?.category}
                />
              )}

              <Divider className={classnames('purchase__divider')}>
                {translate('basic')}
              </Divider>
              <div className="purchase__basic-list">
                {moduleProduct &&
                  detailsModules[moduleProduct?.category].basicAddons.map(
                    ({id, title}) => (
                      <div className="flex purchase__basic-list-title" key={id}>
                        <div>
                          <CheckOutlined className="purchase-addon__checkbox mr-8 pt-4" />
                        </div>
                        {title}
                      </div>
                    )
                  )}
              </div>
              <Divider className={classnames('purchase__divider')}>
                {translate('addons')}
              </Divider>
              {moduleProduct &&
                modulesAddonList[moduleProduct?.category].map(price => (
                  <div className="mb-16" key={price.id}>
                    <PurchaseAddon
                      title={price.name}
                      description={price.description || ''}
                      price={
                        <PurchasePrice
                          price={formatPrice(
                            price.unit_amount,
                            price.currency,
                            price.interval
                          )}
                          contactSalesPrice={price.contact_sales_price}
                          pricingUnitLabel={
                            price.per_custom_unit ||
                            parseSubscriptionsMessage[moduleProduct?.category]
                          }
                        />
                      }
                      checked={selectedAddons[moduleProduct?.category].some(
                        selectedAddon => selectedAddon === price.feature_flag
                      )}
                      disabled={
                        !selectedModules.some(
                          featureFlag => featureFlag === moduleProduct?.category
                        )
                      }
                      onClickCheckbox={e =>
                        handleSelectAddon(
                          moduleProduct?.category,
                          price.feature_flag
                        )
                      }
                    />
                  </div>
                ))}
              <Col xs={24}>
                <Divider className={classnames('purchase__divider')}>
                  {translate('support_services')}
                </Divider>
              </Col>
              {moduleProduct &&
                supportServicesList[moduleProduct?.category].map(
                  ({
                    name,
                    description,
                    interval,
                    currency,
                    unit_amount,
                    feature_flag,
                    contact_sales_price,
                    per_custom_unit,
                  }) => (
                    <div className="mb-16" key={name}>
                      <PurchaseAddon
                        title={name}
                        description={description}
                        price={
                          <PurchasePrice
                            price={formatPrice(unit_amount, currency, interval)}
                            contactSalesPrice={contact_sales_price}
                            pricingUnitLabel={
                              per_custom_unit ||
                              parseSubscriptionsMessage[moduleProduct?.category]
                            }
                          />
                        }
                        checked={selectedExtraAddons.includes(feature_flag)}
                        onClickCheckbox={() =>
                          handleSelectExtraAddon(feature_flag)
                        }
                      />
                    </div>
                  )
                )}
            </Col>
          )
        })}
      </Row>
      <Button
        className="mobile-purchase__buy-now"
        type="primary"
        title={translate('buy_now')}
        onClick={handleBuyNow}
      />
    </div>
  )
}
