import React from 'react'

import {Col, Form, Input, Row} from 'antd'
import {DownloadOutlined} from '@ant-design/icons'

import {twoColumns} from '@/utils/table/constants/grid-columns'
import {translate} from '@/services/i18n'
import {Button} from '@/components/button'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {checkIsLoading} from '@/utils/check-is-loading'
import {IconPosition} from '@/components/button/constants'
import {Paper, PaperVariant} from '@/components/paper/paper'

import useGetCustomReports from '../../hooks/use-get-custom-reports'

import './custom-reports.scss'

type TCustomReport = {
  query: string
  payload: string
}

const TextArea = Input.TextArea

const CustomReports = () => {
  const [form] = Form.useForm()

  const {getReport, status} = useGetCustomReports()

  const handleSubmit = async () => {
    const values = await form.getFieldsValue()

    getReport(values)
  }

  const prettyPrint = async () => {
    const value = await form.getFieldValue('payload')

    const obj = JSON.parse(value)

    const pretty = JSON.stringify(obj, undefined, 4)
    form.setFieldsValue({payload: pretty})
  }

  return (
    <Paper variant={PaperVariant.form}>
      <Form form={form} layout="vertical">
        <Row className="custom-reports-row" gutter={24}>
          <Col {...twoColumns}>
            <FormItemGeneric<TCustomReport> name="query" label={'Query'}>
              <Input />
            </FormItemGeneric>
          </Col>
        </Row>
        <Row className="custom-reports-row" gutter={24}>
          <Col {...twoColumns}>
            <FormItemGeneric<TCustomReport> name="payload" label={'Payload'}>
              <TextArea onChange={prettyPrint} rows={16} />
            </FormItemGeneric>
          </Col>
        </Row>
      </Form>
      <Row className="custom-reports-row" gutter={24}>
        <Col {...twoColumns}>
          <Button
            title={translate('get_report')}
            type="primary"
            onClick={handleSubmit}
            loading={checkIsLoading(status)}
            icon={<DownloadOutlined />}
            iconPosition={IconPosition.RIGHT}
          />
        </Col>
      </Row>
      <Row className="custom-reports-row" gutter={24}>
        <Col {...twoColumns}>
          <Button
            title="Sentry Check"
            type="primary"
            onClick={() => {
              throw Error('Oops! Something went wrong!')
            }}
          />
        </Col>
      </Row>
    </Paper>
  )
}
export default CustomReports
