import {TDocument} from '@/api/typedf'
import {SortingValues} from '@/typedef'

import {
  IRequestRes,
  TIncident,
} from '../../components/incident-k-request/typedf'
import {TIncidentTask} from '../../components/incident-task/typedf'
import {itsmColumnsDefaults} from '../../components/itsm-list-table/constants/columns-defaults'
import {defaultFilters} from '../../components/itsm-list-table/constants/default-filters'
import {
  TDataTablePreset,
  TFilterListTable,
} from '../../components/itsm-list-table/typedf'
import {BillingItemsQuickFilters} from '../../sites/BillingReports/billing-items/constants'
import {TCredit} from '../../sites/BillingReports/CreditSystem/typedf'
import {TRequest} from '../../sites/dashboard-user-portal/typedef'
import {TEvent} from '../../sites/Events/typedf'
import {TExpense} from '../../sites/Expenses/typedef'
import {
  ItsmAssets,
  TAnnouncement,
  TAssignmentGroup,
  TBillingItem,
  TCategory,
  TCustomerProduct,
  TIdentity,
  TInvitation,
  TLocation,
  TNotification,
  TQuickFilters,
  TRejectReason,
  TRole,
  TSupplierProduct,
  TTimeLog,
  TUser,
  TUserGroup,
} from '../../typedef'
import {ActionTypes, TAnyAction} from '../action-types'

export type TPagination<T> = {
  columns?: TDataTablePreset
  filters?: TFilterListTable<T>
  sort?: Record<string, SortingValues> | null
  bookmark?: string | undefined
  bookmarks: Array<string>
  quickFilter?: TQuickFilters
}

const defaultAssetEntries = {
  columns: itsmColumnsDefaults,
  filters: {},
  sort: null,
  bookmark: undefined,
  bookmarks: [],
  quickFilter: undefined,
}

const initalState = {
  [ItsmAssets.Invitations]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Invitations],
  } as TPagination<TInvitation>,
  [ItsmAssets.AssignmentGroups]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.AssignmentGroups],
  } as TPagination<TAssignmentGroup>,
  [ItsmAssets.Roles]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Roles],
  } as TPagination<TRole>,
  [ItsmAssets.Identities]: {
    ...defaultAssetEntries,
    filters: defaultFilters[ItsmAssets.Identities],
    columns: itsmColumnsDefaults[ItsmAssets.Identities],
  } as TPagination<TIdentity>,
  [ItsmAssets.Users]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Users],
  } as TPagination<TUser>,
  [ItsmAssets.IncidentsUP]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.IncidentsUP],
    sort: {
      created_at: 'desc',
    },
  } as TPagination<TIncident>,
  [ItsmAssets.RequestsUP]: {
    ...defaultAssetEntries,
    sort: {
      created_at: 'desc',
    },
    columns: itsmColumnsDefaults[ItsmAssets.RequestsUP],
  } as TPagination<IRequestRes>,
  [ItsmAssets.Suppliers]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Suppliers],
  } as TPagination<TUser>,
  [ItsmAssets.RejectReasons]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.RejectReasons],
  } as TPagination<TRejectReason>,
  [ItsmAssets.UserGroups]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.UserGroups],
  } as TPagination<TUserGroup>,
  [ItsmAssets.AssignmentRules]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.AssignmentRules],
  } as TPagination<TAssignmentGroup>,
  [ItsmAssets.Announcements]: {
    ...defaultAssetEntries,
    quickFilter: 'active',
    filters: defaultFilters[ItsmAssets.Announcements],
    columns: itsmColumnsDefaults[ItsmAssets.Announcements],
  } as TPagination<TAnnouncement>,
  [ItsmAssets.SupplierProducts]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.SupplierProducts],
  } as TPagination<TSupplierProduct>,
  [ItsmAssets.CustomerProducts]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.CustomerProducts],
  } as TPagination<TCustomerProduct>,
  [ItsmAssets.Notifications]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Notifications],
  } as TPagination<TNotification>,
  [ItsmAssets.Categories]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Categories],
  } as TPagination<TCategory>,
  [ItsmAssets.Incidents]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Incidents],
  } as TPagination<TIncident>,
  [ItsmAssets.Requests]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Requests],
  } as TPagination<TRequest>,
  [ItsmAssets.TimeLogs]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.TimeLogs],
  } as TPagination<TTimeLog>,
  [ItsmAssets.TimelogExpenses]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.TimeLogs],
  } as TPagination<TTimeLog>,
  [ItsmAssets.SVR]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.SVR],
  } as TPagination<TTimeLog>,
  [ItsmAssets.TimeLogsResolve]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.TimeLogsResolve],
  } as TPagination<TTimeLog>,
  [ItsmAssets.IncidentExpenses]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.IncidentExpenses],
  } as TPagination<TTimeLog>,
  [ItsmAssets.Expenses]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Expenses],
  } as TPagination<TExpense>,
  [ItsmAssets.IncidentTasks]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.IncidentTasks],
  } as TPagination<TIncidentTask>,
  [ItsmAssets.Credits]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Credits],
  } as TPagination<TCredit>,
  [ItsmAssets.BillingItems]: {
    ...defaultAssetEntries,
    quickFilter: BillingItemsQuickFilters.Chargeable,
    filters: {chargeable: true},
    columns: itsmColumnsDefaults[ItsmAssets.BillingItems],
  } as TPagination<TBillingItem>,
  [ItsmAssets.Locations]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Locations],
  } as TPagination<TLocation>,
  [ItsmAssets.Events]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Events],
  } as TPagination<TEvent>,
  [ItsmAssets.Documents]: {
    ...defaultAssetEntries,
    columns: itsmColumnsDefaults[ItsmAssets.Documents],
  } as TPagination<TDocument>,
}
type TInitialState = typeof initalState

export default function itsmDataTableReducer(
  state = initalState,
  action: TAnyAction
): TInitialState {
  switch (action.type) {
    case ActionTypes.SET_ITSM_LIST_TABLE_COLUMNS: {
      const {asset, columns} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          columns,
        },
      }
    }

    case ActionTypes.SET_ITSM_LIST_TABLE_BOOKMARK: {
      const {asset, bookmark} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          bookmark,
        },
      }
    }

    case ActionTypes.SET_ITSM_LIST_TABLE_BOOKMARKS: {
      const {asset, bookmark, bookmarks} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          bookmark,
          bookmarks,
        },
      }
    }

    case ActionTypes.SET_ITSM_LIST_TABLE_SORTERS: {
      const {asset, sort} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          sort,
        },
      }
    }

    case ActionTypes.SET_ITSM_LIST_TABLE_FILTER: {
      const {asset, filter} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          filters: {
            ...state[asset].filters,
            ...filter,
          },
        },
      }
    }

    case ActionTypes.SET_ITSM_LIST_TABLE_FILTERS: {
      const {asset, filters} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          filters,
        },
      }
    }

    case ActionTypes.SET_ITSM_LIST_TABLE_QUICK_FILTER: {
      const {asset, quickFilter} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          quickFilter,
        },
      }
    }

    case ActionTypes.RESET_ITSM_LIST_TABLE_FILTER: {
      const {asset, key} = action.payload

      const newFilters = {...state[asset].filters} as TFilterListTable<
        Record<string, unknown>
      >

      delete newFilters[key]

      return {
        ...state,
        [asset]: {
          ...state[asset],
          filters: newFilters,
        },
      }
    }

    case ActionTypes.RESET_ITSM_LIST_TABLE_BOOKMARKS: {
      const {asset} = action.payload

      return {
        ...state,
        [asset]: {
          ...state[asset],
          bookmark: undefined,
          bookmarks: [],
        },
      }
    }

    case ActionTypes.RESET_ITSM_LIST_TABLE_FILTERS: {
      const {asset} = action.payload
      initalState[asset]['filters'] = {}

      return {
        ...state,
        [asset]: {
          ...initalState[asset],
          ...(state[asset].quickFilter
            ? {quickFilter: state[asset].quickFilter}
            : {}),
        },
      }
    }

    case ActionTypes.RESET_ITSM_ALL_LIST_TABLE_FILTERS:
      return initalState

    default:
      return state
  }
}
