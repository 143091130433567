import React, {FC} from 'react'

import {translate} from '@/services/i18n'

import './services-tab.scss'

import {SideTabs} from '@/sites/services/typedef'

import {icons} from '@/sites/services/constants/icons'

import {viewSideTab} from '@/sites/services/constants/view-side-tab'

const findStepNum = (name: SideTabs) => {
  return viewSideTab.findIndex(item => item === name) + 1
}
export const ServicesTab: FC<{name: SideTabs; stepNum: number}> = ({name}) => {
  const Icon = icons[name]

  return (
    <div className="services-tab flex flex--spaceBetween full-width">
      <div className="services-tab__left flex flex--spaceBetween flex--column">
        <div className="services-tab__step text-left">{`${translate(
          'step'
        )} ${findStepNum(name)}`}</div>

        <h5 className="services-tab__title">{translate(name)}</h5>
      </div>

      <div className="services-tab__icon">
        <Icon />
      </div>
    </div>
  )
}
