import React, {useState} from 'react'

import {Form, Input} from 'antd'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {useFetchHook} from '@/hooks/useFetch'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import {TCategory} from '@/modules/ITSM/typedef'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {categoriesApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {getName} from '../../../utils/Helpers'
import {patchCategory, postCategory} from '../../../api/categoriesRequests'

const CategoriesDrawer = () => {
  const {
    getEntityDataInit,
    getEntityData,
    onClose,
    record,
  } = useListTableStateContext()

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const createData = (values: Record<string, unknown>) => {
    const newValues = {...values} as Record<string, unknown>
    if (newValues['parent']) newValues.parent = [newValues.parent]
    return newValues
  }

  const handleSubmit = async (values: Record<string, unknown>) => {
    const data = createData(values)
    try {
      setLoading(true)
      handleRequested()

      if (record) {
        await patchCategory(record.uuid, data)

        if (record.parent && !data.parent) {
          getEntityDataInit()
        } else getEntityData()
      } else {
        await postCategory(data)
        onClose()
        await getEntityDataInit()
      }

      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      setLoading(false)
      setBtnDisabled(true)
      handleReset()
    }
  }

  return (
    <>
      <DrawerForm<TCategory>
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => btnDisabled && setBtnDisabled(false)}
        loadingSubmit={loading || btnDisabled}
        handleCancel={onClose}
      >
        <>
          <div>
            <FormItemGeneric<TCategory>
              name="name"
              initialValue={record?.name ? record.name : ''}
              label={translate('name')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input autoComplete="none" onFocus={setAutocompleteNone} />
            </FormItemGeneric>

            <SelectWithBM
              api={categoriesApi().get}
              selector={{
                parent: {$exists: false},
                ...(record?.uuid ? {uuid: {$ne: record.uuid}} : {}),
              }}
              label={translate('parent_category')}
              name="parent"
              initialValue={record?.parent ? [record.parent[0]] : []}
              rules={{
                message: translate('parent_category'),
              }}
              optionContent={getName}
              searchKey="name"
            />
          </div>
        </>
      </DrawerForm>
    </>
  )
}

export default CategoriesDrawer
