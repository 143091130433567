import {AssigneeStates} from '../../incident-k-request/constants'
import {IncidentTaskState, IncTaskTabs} from '../typedf'

import {getCloseStateName} from './get-close-state-name'

export const getActiveTab = ({
  state_id,
  assignee_state,
}: {
  state_id: IncidentTaskState
  assignee_state: AssigneeStates
}) => {
  if (state_id === IncidentTaskState.New) {
    if (assignee_state === AssigneeStates.Unset) {
      return IncTaskTabs.OverviewTab
    } else return assignee_state
  }
  if (state_id === IncidentTaskState.In_progress) {
    return IncTaskTabs.InProgressTab
  }

  if (
    state_id === IncidentTaskState.Closed_complete ||
    state_id === IncidentTaskState.Closed_incomplete ||
    state_id === IncidentTaskState.Closed_skipped
  ) {
    return getCloseStateName(state_id)
      ?.toLowerCase()
      .replace(' ', '-')
  }
}
