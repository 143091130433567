import React, {useEffect, useReducer} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import queryString from 'query-string'

import {InvitationStep} from '@/sites/accept-org-invite/typedef'
import {AcceptOrgInviteModal} from '@/sites/accept-org-invite/modal/accept-org-invite-modal'
import {CreateUser} from '@/sites/accept-org-invite/create-user/create-user'
import {ProfileForm} from '@/sites/accept-org-invite/profile-form/profile-form'
import {CreateAccountAndOrganization} from '@/sites/accept-org-invite/create-account-and-organization/create-account-and-organization'

import {
  InviteOrgContext,
  orgInviteInitialState,
  orgInviteReducer,
} from '@/sites/accept-org-invite/context/org-invite-context'
import globalRoutes from '@/global-routes'
import {getProfile} from '@/redux/user/userActions'

import {TInviteQuery} from '@/typedef'

const steps = {
  [InvitationStep.ACCEPT_INVITE]: <AcceptOrgInviteModal />,
  [InvitationStep.CREATE_ACC_AND_ORG]: <CreateAccountAndOrganization />,
  [InvitationStep.CREATE_IDENTITY]: <CreateUser />,
  [InvitationStep.CREATE_PROFILE]: <ProfileForm />,
}

export const AcceptOrgInvite = () => {
  const rdxDispatch = useDispatch()
  const history = useHistory()
  const query = queryString.parse(history.location.search) as TInviteQuery
  const [state, dispatch] = useReducer(orgInviteReducer, orgInviteInitialState)

  useEffect(() => {
    if (!query?.grant && query.channel_invite) {
      rdxDispatch(getProfile())
      history.push(globalRoutes.general.homePage.path())
    }
  }, [query?.grant, query.channel_invite, history, rdxDispatch])

  return (
    <InviteOrgContext.Provider value={{state, dispatch}}>
      {steps[state.inviteStep]}
    </InviteOrgContext.Provider>
  )
}
