import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {announcementApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TAnnouncement} from '../typedef'

export const getAnnouncements = async (
  bookmark: string,
  dataRange: Record<string, unknown>
) => {
  return await request
    .options(announcementApi().get)
    .set(getHeadersWithGRPC())
    .send({
      bookmark,
      selector: {
        ...dataRange,
      },
    })
}

export const fetchAnnouncements = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(announcementApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })
export const fetchAnnouncement = async ({
  id,
}: {
  id: string
}): Promise<ISuperAgentResSingle<TAnnouncement>> => {
  return await request.get(announcementApi(id).getOne).set(getHeadersWithGRPC())
}

export const patchAnnouncement = async (uuid: string, data: TAnnouncement) => {
  return await request
    .patch(announcementApi(uuid).patch)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postAnnouncement = async (data: TAnnouncement) => {
  return await request
    .post(announcementApi().post)
    .set(getHeadersWithGRPC())
    .send(data)
}
