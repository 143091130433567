import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import Form, {Rule} from 'antd/lib/form'
import {FormInstance} from 'antd/lib/form/Form'
import {AutoComplete, Col, Input, InputNumber, Row, Select, Spin} from 'antd'
import {Coords} from 'google-map-react'
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete'

import {translate} from '@/services/i18n'
import {Footer} from '@/components/layout'
import {Button} from '@/components/button'
import {useFetchHook} from '@/hooks/useFetch'
import {checkIsLoading} from '@/utils/check-is-loading'
import {
  countriesList,
  parseCountryMap,
} from '@/modules/ITSM/components/createNewLocation/countries'
import {TLocation} from '@/modules/ITSM/typedef'
import {useGeocodingByPosition} from '@/hooks/map-hooks'
import {filterOption} from '@/utils/filter-option'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import LocationMap from '@/components/location-map/location-map'
import {parseGeocodingResponse} from '@/modules/ITSM/components/createNewLocation/utils'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

const Option = Select.Option
const RULES = {
  country: [{required: true}],
  city: [{required: true}],
  street: [{required: true}],
  latitude: [{required: true}],
  longitude: [{required: true}],
}

const getAutocompleteOptions = (data: string[]) =>
  data.map(item => ({value: item}))

export type TProps = {
  record?: TLocation
  isVisible?: boolean
  onClose?: () => void
  onSave?: (params: TLocation) => void
  formRules?: {[key: string]: Rule[]}
  formTitle?: string
  withoutSave?: boolean
  preSaveAction?: () => void
}

export const CreateCustomerProductLocation: React.FC<TProps> = ({
  record,
  isVisible,
  onClose,
  onSave,
  formRules,
}) => {
  const formRef = useRef<FormInstance | null>(null)

  const {status: saveStatus} = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  useEffect(() => {
    if (!isVisible) {
      if (formRef.current) {
        formRef.current.resetFields()
      }
    }
  }, [isVisible])

  const handleSubmit = async () => {
    const formData = await formRef?.current?.validateFields()

    const parsedDataWithLocation = {
      ...formData,
      country: parseCountryMap[formData.country] || formData.country,
    }
    if (onSave) onSave(parsedDataWithLocation)
    if (onClose) {
      onClose()
    }
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const [form] = Form.useForm()
  const [mapCoordinates, setMapCoordinates] = useState<Coords | undefined>()
  const [streetCoordinates, setStreetCoordinates] = useState<
    Coords | undefined
  >()

  const [countryCode, setCountryCode] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')

  const [streetSuggestions, setStreetSuggestions] = useState<
    string[] | undefined
  >()

  const rules: Record<string, Array<{required: boolean}>> = useMemo(
    () => ({...RULES, ...formRules}),
    [formRules]
  )

  const {
    setSearchPosition,
    country,
    city: cityByPosition,
    street: streetByPosition,
    zipCode: zipCodeByPosition,
    searchData: searchDataByPosition,
  } = useGeocodingByPosition()

  const locationDataByCoordinates = searchDataByPosition?.[0]?.formatted_address

  useEffect(() => {
    if (record?.country) {
      setCity(record?.city || '')
      setStreet(record?.street || '')
      setStreetSuggestions(streetByPosition ? [streetByPosition] : [])
      setCountryCode(
        countriesList.find(location => location.name === record?.country)
          ?.code || ''
      )
    }
    if (locationDataByCoordinates) {
      form.setFieldsValue({
        country: country || '',
        city: cityByPosition || '',
        street: streetByPosition || '',
        zip: zipCodeByPosition || '',
        location: locationDataByCoordinates || '',
      })
      setCity(cityByPosition || '')
      setStreet(streetByPosition || '')
      setStreetSuggestions(streetByPosition ? [streetByPosition] : [])
      if (country) {
        const {longName, shortName} = country
        form.setFieldsValue({country: longName})
        setCountryCode(shortName.toLowerCase())
      }
    }

    form.setFieldsValue({
      latitude: mapCoordinates && mapCoordinates.lat,
      longitude: mapCoordinates && mapCoordinates.lng,
    })
  }, [
    cityByPosition,
    country,
    form,
    locationDataByCoordinates,
    mapCoordinates,
    record?.city,
    record?.country,
    record?.street,
    streetByPosition,
    zipCodeByPosition,
  ])

  useEffect(() => {
    if (record && record.latitude && record.longitude) {
      setMapCoordinates({
        lat: Number(record.latitude),
        lng: Number(record.longitude),
      })
    }
  }, [record])

  const handleSelectStreet = () => {
    const {lat, lng} = streetCoordinates || {lat: 0, lng: 0}
    form.setFieldsValue({
      zip: zipCode,
    })
    setMapCoordinates({
      lat,
      lng,
    })
  }
  const handleSelectCountry = (value: string) => {
    setCountryCode(value)
    setCity('')
    setStreet('')
    setZipCode('')
    setStreetSuggestions([])

    form.setFieldsValue({
      city: null,
      street: null,
      zip: null,
      location: null,
    })
  }

  const handleSelectCity = (value: string) => {
    setCity(value)
    setStreet('')
    setZipCode('')
    setStreetSuggestions([])

    form.setFieldsValue({
      street: null,
      zip: null,
      location: null,
    })
  }

  const handleChangeStreet = async (addressValue: string) => {
    const result = await geocodeByAddress(addressValue)
    const {zipCode, streets, coordinates} = parseGeocodingResponse(result)
    const spaceBeforeCity = 2
    const startCityInStr = addressValue.length - city.length - spaceBeforeCity
    const streetValue = addressValue.slice(0, startCityInStr)

    if (streets.includes(streetValue)) {
      form.setFieldsValue({
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        zip: zipCode,
      })
    } else {
      form.setFieldsValue({
        latitude: null,
        longitude: null,
        zip: null,
      })
    }

    setStreet(addressValue)
    setStreetSuggestions(streets)
    setZipCode(zipCode)
    setStreetCoordinates({
      lat: coordinates.lat,
      lng: coordinates.lng,
    })
  }

  const onLocationMapChange = useCallback(
    ({lat, lng}: {lat: number; lng: number}) => {
      setMapCoordinates({lat, lng})
      setSearchPosition({lat, lng})
    },
    [setSearchPosition]
  )
  return (
    <>
      <Form
        ref={formRef}
        initialValues={record}
        layout="vertical"
        name="drawer-category"
        form={form}
        validateMessages={{required: '${label} is required'}} // eslint-disable-line
        className="location-form"
      >
        <Row>
          <Col className="col-flex--two">
            <FormItemGeneric<TLocation>
              name="country"
              label={translate('country')}
              rules={rules.country}
            >
              <Select
                onSelect={handleSelectCountry}
                filterOption={filterOption}
                showSearch={true}
                placeholder={translate('start_typing')}
                className="full-width"
                onChange={(value: string) => setCountryCode(value)}
                onFocus={setAutocompleteNone}
              >
                {countriesList.map(({name, code}) => (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItemGeneric>

            <PlacesAutocomplete
              value={city}
              onChange={setCity}
              searchOptions={{
                types: ['(cities)'],
                componentRestrictions: {country: countryCode},
              }}
            >
              {({getInputProps, suggestions, loading}) => {
                return (
                  <FormItemGeneric<TLocation>
                    name="city"
                    label={translate('city')}
                    rules={rules.city}
                  >
                    <Select
                      onSearch={e =>
                        getInputProps().onChange({target: {value: e}})
                      }
                      filterOption={false}
                      notFoundContent={null}
                      showSearch={true}
                      showArrow={false}
                      placeholder={translate('start_typing')}
                      className="full-width"
                      disabled={!countryCode}
                      onSelect={handleSelectCity}
                      onFocus={setAutocompleteNone}
                    >
                      {loading ? (
                        <Option value="loading" key="loading">
                          <Spin />
                        </Option>
                      ) : (
                        suggestions.map(
                          ({formattedSuggestion: {mainText}}, i) => (
                            <Option key={i} value={mainText}>
                              {mainText}
                            </Option>
                          )
                        )
                      )}
                    </Select>
                  </FormItemGeneric>
                )
              }}
            </PlacesAutocomplete>
          </Col>
        </Row>
        <Row>
          <Col className="col-flex--two">
            <PlacesAutocomplete
              value={street}
              onChange={handleChangeStreet}
              searchOptions={{
                types: ['adrress'],
                componentRestrictions: {country: countryCode},
              }}
            >
              {({getInputProps}) => {
                return (
                  <FormItemGeneric<TLocation>
                    name="street"
                    label={translate('street')}
                    rules={[
                      ...rules.street,
                      ({getFieldValue}) => ({
                        validator() {
                          if (
                            !getFieldValue('latitude') &&
                            getFieldValue('street')
                          ) {
                            return Promise.reject(
                              new Error(translate('street_not_found'))
                            )
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                    validateTrigger={['onBlur']}
                  >
                    <AutoComplete
                      options={
                        streetSuggestions &&
                        getAutocompleteOptions(streetSuggestions)
                      }
                      onSelect={handleSelectStreet}
                      className="full-width"
                      onSearch={e =>
                        getInputProps().onChange({
                          target: {value: `${e}, ${city}`},
                        })
                      }
                      placeholder={translate('start_typing')}
                      disabled={!city}
                      onFocus={setAutocompleteNone}
                    />
                  </FormItemGeneric>
                )
              }}
            </PlacesAutocomplete>

            <FormItemGeneric<TLocation>
              name="zip"
              label={translate('zip')}
              rules={rules.zipCode}
            >
              <Input disabled={!city} onFocus={setAutocompleteNone} />
            </FormItemGeneric>
          </Col>
        </Row>

        <Row gutter={24} hidden>
          <Col {...twoColumns}>
            <FormItemGeneric<TLocation>
              name="latitude"
              label={translate('latitude')}
              rules={rules.longitude}
            >
              <InputNumber disabled={true} />
            </FormItemGeneric>
          </Col>
          <Col {...twoColumns}>
            <FormItemGeneric<TLocation>
              name="longitude"
              label={translate('longitude')}
              rules={rules.latitude}
            >
              <Input disabled={true} />
            </FormItemGeneric>
          </Col>
        </Row>
        <Col className="location-form__map-wrapper">
          <LocationMap
            coordinates={mapCoordinates}
            onChange={onLocationMapChange}
          />
        </Col>
      </Form>

      <Footer className="drawer-footer">
        <Button
          title={translate('save')}
          disabled={checkIsLoading(saveStatus)}
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          size="large"
        />
        <Button
          title={translate('cancel')}
          onClick={handleClose}
          size="large"
        />
      </Footer>
    </>
  )
}
