import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TLocation} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n/translate'

import LocationDrawer from './location-drawer'
import LocationDrawerNew from './location-new-drawer'

const SettingsLocations = () => {
  return (
    <ItsmListTable<TLocation>
      asset={ItsmAssets.Locations}
      newComponent={<LocationDrawerNew />}
      detailComponent={<LocationDrawer />}
      drawerTitleNew={translate('location')}
      rowKey={'uuid'}
    />
  )
}

export default SettingsLocations
