import React, {SyntheticEvent} from 'react'

import {Spin, SelectProps} from 'antd'

import {Select, Option} from '@/components/select/select'

interface IProps<T> extends SelectProps {
  data: T[]
  optionContent: (dataOne: T) => string
  run: () => void
  numberOfRecords?: number
}

const MultiSelect = <T extends Record<string, any>>({
  onDropdownVisibleChange,
  onChange,
  disabled,
  loading,
  data = [],
  optionContent,
  run,
  numberOfRecords = 0,
  ...props
}: IProps<T>) => {
  const scrollInPopup = (e: SyntheticEvent<HTMLDivElement>) => {
    if (!loading) {
      if (
        e.currentTarget.scrollTop * 2 >
          (3 / 5) * e.currentTarget.scrollHeight - 65 &&
        numberOfRecords >= 5
      ) {
        run()
      }
    }
  }

  return (
    <Select
      className="full-width"
      disabled={disabled}
      mode="multiple"
      onDropdownVisibleChange={onDropdownVisibleChange}
      onChange={onChange}
      onPopupScroll={scrollInPopup}
      {...props}
    >
      <>
        {data.map(dataOne => (
          <Option key={dataOne.uuid} value={dataOne.uuid}>
            {optionContent(dataOne)}
          </Option>
        ))}
        {loading && (
          <Option value="loading" key="loading">
            <Spin />
          </Option>
        )}
      </>
    </Select>
  )
}

export default MultiSelect
