import React, {createContext, useState, ReactNode} from 'react'

export type TProductContext = {
  productCode: string
  setProductCode: (code: string) => void
}
export const ProductContext = createContext<TProductContext>({
  productCode: '',
  setProductCode: () => Promise.resolve(),
})

export const ProductContextProvider = ({children}: {children: ReactNode}) => {
  const [productCode, setProductCode] = useState('')

  return (
    <ProductContext.Provider value={{productCode, setProductCode}}>
      {children}
    </ProductContext.Provider>
  )
}
