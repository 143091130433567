export const assetTypeIcons: Record<string, string> = {
  'Alarm system': 'alarm system',
  Android: 'android',
  APC: 'apc',
  'Apple Mac': 'apple',
  Appliance: 'appliance',
  Arduino: 'arduino',
  Automotive: 'automotive',
  'AWS EC2 Instance': 'aws ec2 instance',
  'AWS EC2 VPC': 'AWSVPC',
  'Azure Resource Group': 'azure resource group',
  'Azure Virtual Machine': 'azure virtual machine',
  'Baby monitor': 'baby monitor',
  'Badge reader': 'badge reader',
  Balancer: 'load balancer',
  'Bar-code': 'bar-code',
  Battery: 'battery',
  Bell: 'bell',
  BlackBerry: 'blackberry',
  'Blade server': 'blade',
  Bridge: 'hub',
  'Cable modem': 'cable modem',
  Camera: 'camera',
  Car: 'car',
  'Cell phone': 'cell phone',
  'Chrome OS': 'chrome',
  Chromecast: 'chromecast',
  'Circuit card': 'circuit card',
  'Citrix Guest': 'citrix pool',
  'Citrix Pool': 'citrix pool',
  'Citrix XenServer': 'citrix xenserver',
  Cleaner: 'cleaner',
  Clock: 'clock',
  Cloud: 'cloud',
  Communication: 'communication',
  Computer: 'computer',
  Conferencing: 'conferencing',
  'Control Panel': 'control panel',
  Database: 'database',
  Desktop: 'desktop',
  'Device server': 'device server',
  'Disc Player': 'disc player',
  'DNS server': 'dns server',
  'Domain Server': 'domain server',
  'Domotz Box': 'domotz box',
  'DSLAM device': 'dslam',
  'DVD/Blu-Ray': 'dvd bluray',
  'E-reader': 'e-reader',
  Energy: 'energy',
  'Environment monitor': 'environment monitor',
  'ESXi server': 'ESXi server',
  'External disk': 'external disk',
  Fax: 'fax',
  'Fibre switch': 'fibre switch',
  'File Server': 'ftp',
  Fingbox: 'fingbox',
  Firewall: 'firewall',
  Fitness: 'fitness',
  Fridge: 'fridge',
  'FTP server': 'ftp',
  'Game device': 'game device',
  Garage: 'garage',
  Gateway: 'gateway',
  Handheld: 'handheld',
  'Health Monitor': 'health monitor',
  Heating: 'heating',
  'Home automation': 'home automation',
  Host: 'host',
  Hub: 'hub',
  Humidity: 'humidity',
  'Hyper-V guest': 'windows',
  'Identity mgmt device': 'network device',
  Industrial: 'industrial',
  'Intrusion detection system': 'intrusion detection system',
  IOS: 'ios',
  'IP gateway': 'ip gateway',
  iPad: 'ipad',
  iPhone: 'iphone',
  iPod: 'ipod',
  'Key Lock': 'key lock',
  'KVM switch': 'kvm switch',
  Laptop: 'laptop',
  Light: 'light',
  Linux: 'linux',
  'Load balancer': 'load balancer',
  Location: 'location',
  Loudspeaker: 'music system',
  'Mail server': 'mail server',
  'Management device': 'management device',
  'Media system': 'media system',
  Medical: 'medical',
  'Memory stick': 'memory stick',
  Microphone: 'microphone',
  Mobile: 'mobile',
  Monitor: 'monitor',
  'Motion Detector': 'motion detector',
  MSFC: 'network device',
  Multiplexer: 'multiplexer',
  Music: 'music',
  'Music system': 'music system',
  NAS: 'nas',
  'Network Appliance': 'network appliance',
  'Network device': 'network device',
  'Pet Monitor': 'pet monitor',
  Photos: 'photos',
  'Poe Plug': 'poe plug',
  Pool: 'pool',
  POS: 'pos',
  'Power distribution unit': 'power injector',
  'Power injector': 'power injector',
  'Power System': 'power system',
  Printer: 'printer',
  Probe: 'network device',
  Processor: 'processor',
  Projector: 'projector',
  'Proxy server': 'rack',
  'QOS device': 'voip gateway',
  Rack: 'rack',
  Radio: 'radio',
  Raspberry: 'raspberry',
  'Remote Access Controller': 'remote access controller',
  'Remote Control': 'remote control',
  RFID: 'rfid',
  Robot: 'robot',
  Router: 'router',
  RSFC: 'network device',
  RSM: 'network device',
  SAN: 'san',
  Satellite: 'satellite',
  Scale: 'scale',
  Scanner: 'scanner',
  'Security appliance': 'security',
  'Security system': 'security system',
  Sensor: 'sensor',
  Server: 'server',
  Sleep: 'sleep',
  'Small Cell': 'small cell',
  'Smart Controller': 'smart controller',
  'Smart Home': 'smart home',
  'Smart Meter': 'smart meter',
  'Smart Plug': 'smart plug',
  'Smart TV': 'smart tv',
  Smoke: 'smoke',
  'Solar Panel': 'solar panel',
  'Sound System': 'sound system',
  Sprinkler: 'sprinkler',
  'SSL/VPN device': 'ssl vpn',
  STB: 'stb',
  'Streaming Dongle': 'chromecast',
  'Surveillance Camera': 'surveillance camera',
  Switch: 'switch',
  Tablet: 'tablet',
  'Tape device': 'tape device',
  'Telephone system': 'telephone system',
  Terminal: 'terminal',
  'Terminal server': 'terminal',
  Thermostat: 'thermostat',
  Toy: 'toy',
  Unix: 'unix',
  Unknown: 'unknown',
  UPS: 'ups',
  USB: 'USB',
  'Video device': 'video device',
  'Virtual Machine': 'virtual machine',
  'VMware Guest': 'vmware guest',
  'VMware vCenter server': 'vmware server',
  'Voice Control': 'voice control',
  'VOIP Gateway': 'voip gateway',
  'VOIP phone': 'telephone system',
  'VPN device': 'vpn device',
  Washer: 'washer',
  Watch: 'watch',
  Wearable: 'wearable',
  Weather: 'weather',
  Webserver: 'webserver',
  Wifi: 'wireless',
  'Wifi Extender': 'wifi extender',
  Windows: 'windows',
  'Windows CE': 'windows ce',
  'Windows Phone': 'windows phone',
  'Wireless Access point': 'wireless',
  'Wireless controller': 'wireless',
  'xDSL modem': 'router',
  'xDSL router': 'router',
}
