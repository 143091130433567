import {
  initialSlaValues,
  TSlaValues,
} from '@/modules/ITSM/components/incident/incident-reducer'

import {IncidentTaskState, TIncidentTask} from '../typedf'

import {getRemainingTime} from './get-sla-ramaining-time'

export const getSLAtime = (record: TIncidentTask): TSlaValues => {
  const {state_id} = record

  let slaValues: TSlaValues = initialSlaValues

  if (state_id === IncidentTaskState.New) {
    slaValues = {
      endTime: record.response_time_due,
      startTime: record.created_at,
    }
  } else if (state_id === IncidentTaskState.In_progress) {
    slaValues = {
      endTime: record.resolution_time_due || record.response_time_due,
      startTime: record.resolution_time_start || record.response_time_start,
    }
  } else {
    slaValues = {
      endTime: record.closed_at,
      startTime: record.resolution_time_start || record.response_time_start,
    }
  }

  return {...slaValues, ...getRemainingTime(record, slaValues)}
}
