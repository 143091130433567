import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TRejectReason} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'

import ReasonsDrawer from './reasons-drawer'

const RejectReasons = () => (
  <ItsmListTable<TRejectReason>
    asset={ItsmAssets.RejectReasons}
    newComponent={<ReasonsDrawer />}
    detailComponent={<ReasonsDrawer />}
    drawerTitleNew={translate('reject_reason')}
    rowKey="uuid"
  />
)

export default RejectReasons
