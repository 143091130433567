type TBillingActionsCreators = typeof import('./billing/billing-action-creators')
type TActionCreators = TBillingActionsCreators
// & TBillingActionsCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

export enum TActionTypes {
  FETCH_BILLING_SUBSCRIPTIONS_REQUESTED = 'FETCH_BILLING_SUBSCRIPTIONS_REQUESTED',
  FETCH_BILLING_SUBSCRIPTIONS_SUCCEEDED = 'FETCH_BILLING_SUBSCRIPTIONS_SUCCEEDED',
  FETCH_BILLING_SUBSCRIPTIONS_FAILED = 'FETCH_BILLING_SUBSCRIPTIONS_FAILED',
  FETCH_BILLING_CUSTOMER = 'FETCH_BILLING_CUSTOMER',
  FETCH_BILLING_CUSTOMER_SUCCESS = 'FETCH_BILLING_CUSTOMER_SUCCESS',
  SET_CUSTOMER_BALANCE = 'SET_CUSTOMER_BALANCE',
  SET_BALANCE_TRANSACTION = 'SET_BALANCE_TRANSACTION',
  SET_TRANSACTION_NUMBER = 'SET_TRANSACTION_NUMBER',
  SET_REFETCH_DELAY = 'SET_REFETCH_DELAY',
}
