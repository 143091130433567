import {RequestStatus} from '@/typedef'

import * as ActionTypes from '../actionTypes'

const initialState = {
  creditAccess: {
    requestStatus: RequestStatus.INITIAL,
    mode: null,
    error: '',
  },
  myAccess: {
    requestStatus: RequestStatus.INITIAL,
    accessFunctions: {},
    error: '',
  },
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_SETTINGS_STATE: {
      return initialState
    }

    case ActionTypes.FETCH_CREDIT_ACCESS_REQUESTED: {
      return {
        ...state,
        creditAccess: {
          ...state.creditAccess,
          requestStatus: RequestStatus.REQUESTED,
        },
      }
    }

    case ActionTypes.FETCH_CREDIT_ACCESS_SUCCESS: {
      return {
        ...state,
        creditAccess: {
          ...state.creditAccess,
          requestStatus: RequestStatus.SUCCEEDED,
          mode: action.payload,
        },
      }
    }

    case ActionTypes.FETCH_CREDIT_ACCESS_FAILED: {
      return {
        ...state,
        creditAccess: {
          ...state.creditAccess,
          requestStatus: RequestStatus.FAILED,
          mode: false,
        },
      }
    }

    case ActionTypes.FETCH_MY_ACCESS_REQUESTED: {
      return {
        ...state,
        myAccess: {
          ...state.myAccess,
          requestStatus: RequestStatus.REQUESTED,
        },
      }
    }

    case ActionTypes.FETCH_MY_ACCESS_SUCCESS: {
      return {
        ...state,
        myAccess: {
          ...state.myAccess,
          requestStatus: RequestStatus.SUCCEEDED,
          accessFunctions: action.payload,
        },
      }
    }

    case ActionTypes.FETCH_MY_ACCESS_FAILED: {
      return {
        ...state,
        myAccess: {
          ...state.myAccess,
          requestStatus: RequestStatus.FAILED,
          accessFunctions: {},
        },
      }
    }

    default:
      return state
  }
}
