import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import invitation from '@/redux/invitation/invitationReducer'
import entries from '@/redux/entries/entries-reducer'
import profile from '@/redux/profile/profileReducer'
import general from '@/redux/reducers/general'
import settings from '@/redux/settings/settingsReducer'
import ui from '@/redux/ui/uiReducer'
import toast from '@/redux/toast-message/toast-reducer'
import itsmUser from '@/redux/user/userReducer'
import cmdbDataTable from '@/modules/CMDB/store/module-tables/cmdb-dataTable-reducer'
import timeSession from '@/modules/ITSM/store/time-session/reducer'
import itsmListTable from '@/modules/ITSM/store/list-table/reducer'
import saasListTable from '@/modules/SAAS/store/list-table/reducer'
import billingReducer from '@/redux/billing/billing-reducer'
import cmdbUser from '@/modules/CMDB/store/user/cmdb-user-reducer'
import saasUser from '@/modules/SAAS/store/user/saas-user-reducer'

import rootSaga from '../saga/rootSaga'

import storeProvider from './storeProvider'
import channelInvitation from './channel-invitation/channel-invitation-reducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    store: any
  }
}
export const rootReducer = combineReducers({
  general,
  itsmUser,
  cmdbUser,
  saasUser,
  ui,
  invitation,
  entries,
  settings,
  profile,
  cmdbDataTable: cmdbDataTable as any,
  itsmListTable,
  timeSession,
  toast,
  channelInvitation,
  saasListTable,
  billingReducer,
})

export type TAppState = ReturnType<typeof rootReducer>

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'cmdbDataTable',
    'itsmDataTable',
    'itsmListTable',
    'entries',
    'timeSession',
  ],
}

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const sagaMiddleware = createSagaMiddleware()

const configureStore = () =>
  createStore(
    persistReducer(persistConfig, rootReducer),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )
storeProvider.init(configureStore)
const store = storeProvider.getStore()

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
