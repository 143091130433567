export enum BlitsModule {
  saas_management = 'saas_management',
  asset_management = 'asset_management',
  ticket_management = 'ticket_management',
  all = 'all',
}

export const dependentProductGroups = [
  {
    product: 'prod_MBpsCOYnsf5OsI',
    year: ['price_1LS4MAFeIM34mIXSIzihLSkM', 'price_1LFywmFeIM34mIXSJaDARztB'],
    month: ['price_1LLrXZFeIM34mIXSmc1w0vZJ', 'price_1LIBUuFeIM34mIXSBr4IIl5O'],
  },
]

export enum SubscriptionModalState {
  INITIAL = '',
  CANCEL = 'cancel_confirm',
  SUBSCRIBE = 'subscribe_confirm',
  SUCCESS_SUBSCRIBE = 'subscription_added',
  SUCCESS_CANCEL = 'subscription_cancelled',
}

export enum MetadataType {
  BLITS_SOFTWARE_PRODUCT = 'blits_software_product',
  BLITS_SUPPORT_SERVICE = 'blits_support_service',
}
