import React, {useEffect, useState} from 'react'

import {withErrorBoundary} from '@sentry/react'
import GoogleMapReact, {Coords} from 'google-map-react'

import {generateConfig} from '@/generateConfig'
import ErrorPage from '@/components/error/error-page/error-page'

import './location-map.scss'

const DEFAULT_COORDINATES = {
  LAT: 46.9,
  LNG: 7,
}
const MIN_ZOOM = 4
const DEFAULT_ZOOM = 5
const FOCUSED_ZOOM = 8

type TProps = {
  coordinates?: Coords
  viewOnly?: boolean
  onChange?: (params: Coords) => void
}

const LocationMap: React.FC<TProps> = ({
  coordinates = {},
  onChange,
  viewOnly,
}) => {
  const [mapCoordinates, setMapCoordinates] = useState<Coords>({
    lat: DEFAULT_COORDINATES.LAT,
    lng: DEFAULT_COORDINATES.LNG,
  })
  const [mapZoom, setMapZoom] = useState<number>(DEFAULT_ZOOM)

  useEffect(() => {
    const {lat, lng} = coordinates

    if (lat && lng) {
      setMapCoordinates({lat, lng})
      setMapZoom(FOCUSED_ZOOM)
    }
  }, [coordinates])

  const handleClickMap = ({lat, lng}: Coords) => {
    if (!viewOnly) {
      setMapCoordinates({lat, lng})
    }
    setMapZoom(FOCUSED_ZOOM)

    if (onChange) {
      onChange({lat, lng})
    }
  }

  return (
    <div className="location-map">
      <GoogleMapReact
        bootstrapURLKeys={{key: generateConfig().GOOGLE_MAP_KEY}}
        center={mapCoordinates}
        zoom={mapZoom}
        resetBoundsOnResize={false}
        options={{minZoom: MIN_ZOOM}}
        onClick={handleClickMap}
        yesIWantToUseGoogleMapApiInternals
      />
      {/*  <Marker lat={mapCoordinates.lat} lng={mapCoordinates.lng} />*/}
      {/*</GoogleMapReact>*/}
    </div>
  )
}

export default withErrorBoundary(LocationMap, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
