import {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {resetItsmTableFilters} from '@/modules/ITSM/store/list-table/table-actions'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {selectPathname} from '@/redux/reducers/generalSelector'
import {selectItsmState} from '@/modules/ITSM/store/list-table/selectors'

export default function useRemovePagination() {
  const dispatch = useDispatch()
  const pathname = useSelector(selectPathname)

  const firstPath = pathname[0]
  const secondPath = pathname[1]

  const pagination = useSelector(selectItsmState)

  useEffect(() => {
    if (firstPath !== secondPath) {
      Array.from([
        ItsmAssets.Expenses,
        ItsmAssets.SVR,
        ItsmAssets.TimeLogsResolve,
        ItsmAssets.TimeLogs,
        ItsmAssets.IncidentExpenses,
        ItsmAssets.IncidentTasks,
      ]).forEach(asset => {
        if (pagination[asset].bookmarks.length > 0)
          dispatch(resetItsmTableFilters(asset))
      })
    }
  }, [dispatch, firstPath, secondPath])
}
