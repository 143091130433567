import {useEffect, useState} from 'react'

import {Breakpoints} from '@/typedef'

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const isMobile = () => window.innerWidth < Breakpoints.lg

    setIsMobile(isMobile())
    const handleResize = () => setIsMobile(isMobile())

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}
