import {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {
  resetItsmTableFilters,
  setItsmTableFilters,
  setItsmTableQuickFilters,
} from '@/modules/ITSM/store/list-table/table-actions'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {selectPathname} from '@/redux/reducers/generalSelector'
import {selectItsmState} from '@/modules/ITSM/store/list-table/selectors'

import {BillingItemsQuickFilters} from '../../billing-items/constants'

export default function useRemovePagination() {
  const dispatch = useDispatch()
  const pathname = useSelector(selectPathname)

  const asset = ItsmAssets.BillingItems

  const firstPath = pathname[0]
  const secondPath = pathname[1]

  const pagination = useSelector(selectItsmState)

  useEffect(() => {
    if (firstPath !== secondPath) {
      if (pagination[asset].bookmarks.length > 0) {
        dispatch(resetItsmTableFilters(asset))
        dispatch(
          setItsmTableQuickFilters(asset, BillingItemsQuickFilters.Chargeable)
        )
        dispatch(
          setItsmTableFilters(asset, {
            chargeable: true,
          })
        )
      }
    }
  }, [dispatch, firstPath, secondPath])
}
