export const prepareDataToCopy = (
  err: Record<string, any>,
  message: string,
  time: string
): string => {
  const {req: request} = err?.response || {}

  if (request) {
    return `
      method:${request.method}
      url:${request.url}face
      grpc_metadata_space:${request.header?.[`Grpc-Metadata-space`]}
      time:${time}
      status:${message} `
  }

  return `time:${time} status:${message}`
}
