import React from 'react'

import {ClockCircleOutlined} from '@ant-design/icons'
import {Progress, Tooltip} from 'antd'
import moment from 'moment'

import {DATE_FORMAT} from '@/constants'
import {translate} from '@/services/i18n'
import {getProgressColor} from '@/utils/get-progress-color'
import {INCIDENT_STATES} from '@/utils/table/get-table-values/get-table-values'

import {ProgressTooltipData} from '../../incident/incident-progress-bar/incident-progress-bar'
import {findHours} from '../utils'
import {IncidentState} from '../constants'

import './incident-request-progress-bar.scss'

type TProps<T> = {
  record: T
  value: string | number | boolean
}

const ProgressBar = <T extends Record<string, unknown>>({
  record,
  value,
}: TProps<T>) => {
  const progress = findHours(record).progress
  const remainingTime = findHours(record).remainingTime
  const elapsedTime = findHours(record).elapsedTime

  const getPopupContainer = () => {
    const progressBar: HTMLElement | null = document.querySelector(
      '.table-values__incidents-progressbar'
    )
    if (progressBar) {
      return () => progressBar
    }
  }

  return (
    <div className="progress-bar">
      <>
        <Tooltip
          getPopupContainer={getPopupContainer()}
          title={
            <ProgressTooltipData {...{elapsedTime, remainingTime, progress}} />
          }
          placement="bottom"
        >
          <Progress
            strokeColor={getProgressColor(progress)}
            percent={progress}
            showInfo={false}
          />
        </Tooltip>
        <div className="nowrap">{INCIDENT_STATES[value as number]}</div>
        {record.expected_start &&
          (value === IncidentState.Open ||
            value === IncidentState.Pre_on_hold) &&
          record.expected_start && (
            <Tooltip
              title={`${translate('expected_start')}: ${moment(
                record.expected_start as string
              ).format(DATE_FORMAT)}`}
              placement="bottom"
              getPopupContainer={getPopupContainer()}
            >
              <ClockCircleOutlined />
            </Tooltip>
          )}
      </>
    </div>
  )
}

export default ProgressBar
