import React from 'react'

import {Tooltip} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'

import {TKey, translate} from '@/services/i18n'
import {
  IExtendedColumnType,
  TColumnRender,
  TDataTablePreset,
} from '@/modules/ITSM/components/itsm-list-table/typedf'

import {billingReportColumns} from '../constants'

type TProps<T> = {
  columns: TDataTablePreset
  getRowValue?: (props: TColumnRender<T>) => React.ReactNode
}

const tooltipMessages: Record<string, string> = {
  customer_sums: translate('customer_price_info'),
  supplier_sums: translate('supplier_price_info'),
  total_amount_of_expenses: translate('expenses_sum_info'),
  total_amount_of_travel_time: translate('total_amount_of_travel_time_info'),
  total_amount_of_working_time: translate('total_amount_of_working_time_info'),
}

export const generateActiveColumns = <T extends Record<string, any>>({
  columns,
  getRowValue,
}: TProps<T>): Array<IExtendedColumnType<T>> => {
  return columns.reduce((acc, {title, dataIndex, index, selected}) => {
    if (selected) {
      const tooltipMessage = tooltipMessages[dataIndex as string]
      return acc.concat({
        title: (
          <div className="flex">
            {translate(title as TKey)}
            <Tooltip placement="top" title={tooltipMessage}>
              {tooltipMessage && <InfoCircleOutlined />}
            </Tooltip>
          </div>
        ),

        index,
        key: title,
        dataIndex,
        selected,
        render: (value: string | number, record: T) => {
          if (
            billingReportColumns?.find(
              ({title: columnTitle}) => columnTitle === title
            )?.advancedRender &&
            getRowValue
          )
            return (
              <div className="nowrap">
                {getRowValue({
                  title,
                  value,
                  record,
                })}
              </div>
            )

          return <div className="nowrap">{value}</div>
        },
      })
    }

    return acc
  }, [] as Array<IExtendedColumnType<T>>)
}
