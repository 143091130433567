import React from 'react'

import {translate} from '@/services/i18n'

const LocationDetails = props => {
  const {location = {}} = props
  return (
    <div className="fs-12">
      {location.name && <div>{`${translate('name')}: ${location.name}`}</div>}
      {location.street && (
        <div>{`${translate('street')}: ${location.street}`}</div>
      )}
      {location.city && <div>{`${translate('city')}: ${location.city}`}</div>}
      {location.zip && <div>{`${translate('zip')}: ${location.zip}`}</div>}
      {location.state_province && (
        <div>{`${translate('state_province')}: ${
          location.state_province
        }`}</div>
      )}
      {location.country && (
        <div>{`${translate('country')}: ${location.country}`}</div>
      )}
      {location.latitude && (
        <div>{`${translate('latitude')}: ${location.latitude}`}</div>
      )}
      {location.longitude && (
        <div>{`${translate('longitude')}: ${location.longitude}`}</div>
      )}
    </div>
  )
}

export default LocationDetails
