import {TAppState} from '@/redux/store'

export function selectToastMessage(state: TAppState) {
  return state.toast
}

export function selectToastErrorMessages(state: TAppState) {
  return state.toast.errorMessages
}

export function selectHasErrorMessages(state: TAppState) {
  return state.toast.errorMessages.length > 0
}
