import {useCallback, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {TDocument} from '@/api/typedf'

import {fetchDocs} from '../api/documents-api'
import {addfileType} from '../sites/Documents/utils'

export const useGetDocs = () => {
  const [docs, setDocs] = useState<Array<TDocument>>([])

  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const getDocs = useCallback(async () => {
    handleRequested()
    try {
      const {
        body: {docs},
      } = await fetchDocs()
      const newDocs = docs.map((doc: TDocument) => ({
        ...doc,
        file_type: addfileType(doc),
      }))

      setDocs(newDocs)

      handleSuccess()
    } catch (err) {
      handleFail(err)
    }
  }, [handleFail, handleRequested, handleSuccess])

  return {
    getDocs,
    docs,
    fetchDocsStatus: status,
  }
}
