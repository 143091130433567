import React from 'react'

import './svg-icon.scss'

type TSvgIconProps = {
  role?: string
  name: string
  className?: string
  width?: number
  height?: number
  stroke?: string
  onClick?: () => void
}

const SvgIcon: React.FC<TSvgIconProps> = ({
  role,
  name,
  className,
  width,
  height,
  stroke,
  onClick,
}) => {
  return (
    <>
      <svg
        role={role}
        className={className}
        width={width}
        height={height}
        onClick={onClick}
      >
        <use
          href={`/assets/icon-sprite/icon-sprite.svg#${name}`}
          stroke={stroke}
        />
      </svg>
    </>
  )
}

export default SvgIcon
