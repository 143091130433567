import {ItsmAssets} from '@/modules/ITSM/typedef'

import {getBooleanOptions} from '../constants/filters-config'

export const convertFilterValue = <T extends Record<string, any>>(
  val: string | string[],
  key: keyof T,
  asset: ItsmAssets
) => {
  const booleanOptions = getBooleanOptions<T>()[asset]?.[key]

  if (val === '') {
    return {}
  } else if (typeof val === 'string' && booleanOptions?.[val] !== undefined) {
    return booleanOptions?.[val]
  } else if (
    typeof val === 'string' &&
    !isNaN(Number(val)) &&
    !(val?.length !== 1 && val.startsWith('0'))
  ) {
    return {[key]: Number(val)}
  } else {
    return {[key]: val}
  }
}
