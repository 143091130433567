import React, {Component, createRef} from 'react'

import {Input, Form} from 'antd'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {setRefresh} from '@/redux/actions'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {RequestStatus, Assets} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import {translate} from '@/services/i18n'
import {RESOLVE} from '@/constants'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {TransferForModal} from '@/modules/ITSM/components/transfer-for-modal'
import FormItemCustom from '@/components/form/form-item-custom'

import {patchUserGroup} from '../../../../api/userGroupRequests'
import {fetchUsers, patchUser} from '../../../../api/userRequests'

import './user-groups-form-container.scss'

class UserGroupsFormContainer extends Component {
  constructor(props) {
    super(props)
    this.formRef = createRef()
    this.state = {
      loadingSubmit: false,
      users: [],
      asset: Assets.userGroupsAsset,
      btnDisabled: true,
      usersFound: [],
      usersData: [],
      targetKeys: [],
    }
  }

  getUsersData = usersData => {
    return new Promise(resolve => {
      const usersArr = []

      usersData.forEach(user => {
        const data = {
          key: user.uuid,
          title: user.full_name,
        }
        usersArr.push(data)
      })
      this.setState(
        prevState => ({
          usersData: [...prevState.usersData, ...usersData],
          users: [...prevState.users, ...usersArr],
        }),
        () => {
          resolve()
        }
      )
    })
  }

  handlePatchUserGroup = async () => {
    const {validateFields} = this.formRef.current || {}
    const {record, setToastMessage} = this.props

    const values = this.formRef.current && (await validateFields())
    try {
      handleMessage(RequestStatus.REQUESTED)

      await patchUserGroup(record.uuid, values.name)

      handleMessage(RequestStatus.SUCCEEDED)
      this.setState({loadingSubmitUsers: false, btnDisabled: true})
      this.props.getEntityData()
    } catch (err) {
      setToastMessage({message: err})
      handleMessage(RequestStatus.FAILED)
    }
  }

  patchUsers = async ({uuid, data, resolve, reject}) => {
    try {
      handleMessage(RequestStatus.REQUESTED)
      await patchUser(uuid, data)
      handleMessage(RequestStatus.SUCCEEDED)

      resolve()
    } catch (err) {
      handleMessage(RequestStatus.FAILED)
      reject(err)
    }
  }

  handleSubmitUserGroups = (e, checking) => {
    const {getFieldValue} = this.formRef.current || {}
    const {
      record: {uuid},
    } = this.props
    const {usersData, targetKeys, initialUsers} = this.state

    return new Promise(resolve => {
      const usersAdded = usersData
        .filter(e => targetKeys.indexOf(e.uuid) !== -1)
        .filter(e =>
          e.user_groups
            ? e.user_groups.map(r => r.uuid).indexOf(uuid) === -1
            : true
        )

      const usersRemoved = (initialUsers || []).filter(
        e => targetKeys.indexOf(e.uuid) === -1
      )

      if (!checking) {
        this.setState({loadingSubmitUsers: true})

        let promisesAdded = []
        if (usersAdded.length > 0) {
          usersAdded.forEach(el => {
            const userGroups = el.user_groups ? el.user_groups : []

            const data = {
              user_groups: [...userGroups.map(e => e.uuid), uuid],
            }

            promisesAdded.push(
              new Promise((resolve, reject) => {
                this.patchUsers({uuid: el.uuid, data, resolve, reject})
              })
            )
          })
        }

        Promise.allSettled(promisesAdded).then(() => {
          let promisesRemoved = []

          if (usersRemoved.length > 0) {
            usersRemoved.forEach(el => {
              const userGroups = el.user_groups ? el.user_groups : []

              const data = {
                user_groups: userGroups
                  .filter(e => e.uuid !== uuid)
                  .map(e => e.uuid),
              }

              promisesRemoved.push(
                new Promise((resolve, reject) => {
                  this.patchUsers({uuid: el.uuid, data, resolve, reject})
                })
              )
            })
          }

          Promise.allSettled(promisesRemoved).then(() => {
            if (
              this.formRef.current &&
              getFieldValue('name') !== this.props.record.name
            ) {
              this.handlePatchUserGroup()
            } else {
              this.props.getEntityData()
              this.setState({loadingSubmitUsers: false})
            }
          })
        })
      } else {
        resolve(
          usersAdded.length === 0 && usersRemoved.length === 0
            ? 0
            : [...usersAdded, ...usersRemoved].length
        )
      }
    })
  }

  fetchTargetUsers = async bookmark => {
    try {
      const res = await fetchUsers({
        selector: {
          $and: [
            {
              $or: [
                {
                  user_groups: {
                    $allMatch: {
                      $ne: this.props.record.uuid,
                    },
                  },
                },
                {
                  user_groups: {$eq: []},
                },
                {
                  user_groups: {$exists: false},
                },
              ],
            },
            {
              full_name: {
                $regex: `(?i)${this.formRef.current &&
                  this.formRef.current.getFieldValue('user_search')}`,
              },
            },
          ],
        },
        passedBookmark: bookmark,
        fields: ['uuid', 'user_groups', 'full_name'],
        resolve: RESOLVE,
      })

      this.setState(prevState => ({
        usersFound: bookmark
          ? prevState.usersFound.concat(res.body.result)
          : res.body.result,
      }))

      if (res.body.result.length === 10) {
        this.fetchTargetUsers(res.body.bookmark)
      } else {
        await this.getUsersData(this.state.usersFound)

        this.setState({loadingSearch: false})
      }
    } catch (err) {
      this.props.setToastMessage({message: err})
    }
  }

  handleSubmitSearch = () => {
    this.setState(
      prevState => ({
        loadingSearch: true,
        usersFound: [],
        usersData: prevState.usersData.filter(
          e => this.state.targetKeys.indexOf(e.uuid) !== -1
        ),
        users: prevState.users.filter(
          e => this.state.targetKeys.indexOf(e.key) !== -1
        ),
      }),
      () => {
        this.fetchTargetUsers()
      }
    )
  }

  getUsersOfGroup = async bookmark => {
    this.setState({loadingUsers: true})
    try {
      const res = await fetchUsers({
        selector: {
          user_groups: {
            $elemMatch: {
              $eq: this.props.record.uuid,
            },
          },
        },
        passedBookmark: bookmark,
        fields: ['uuid', 'user_groups', 'full_name'],
        resolve: RESOLVE,
      })

      this.setState(prevState => ({
        data: bookmark
          ? prevState.data.concat(res.body.result)
          : res.body.result,
      }))

      if (res.body.result.length === 10) {
        this.getUsersOfGroup(res.body.bookmark)
      } else {
        const data = [...this.state.data]
        const dataUuid = data.map(e => e.uuid)

        await this.getUsersData(data)

        this.setState({
          targetKeys: dataUuid,
          initialUsers: data,
          bookmark: undefined,
          loadingUsers: false,
        })
      }
    } catch (err) {
      this.props.setToastMessage({message: err})
    }
  }

  handleFilterOption = (value, name) => {
    if (name['title'].toLowerCase().includes(value.toLowerCase())) {
      return true
    }
  }

  componentDidMount() {
    this.getUsersOfGroup()
  }

  render() {
    const {record} = this.props
    const {
      loadingSubmitUsers,
      loadingSearch,
      users,
      targetKeys,
      selectedKeys,
    } = this.state

    return (
      <DrawerForm
        formRef={this.formRef}
        handleCancel={this.props.onClose}
        loadingSubmit={loadingSubmitUsers || this.state.btnDisabled}
        onFinish={this.handleSubmitUserGroups}
        onValuesChange={() => {
          this.state.btnDisabled && this.setState({btnDisabled: false})
        }}
      >
        <div className="user-groups-form">
          <FormItemCustom
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('name')}
            initialValue={record?.name || ''}
          >
            <Input />
          </FormItemCustom>
          <div className="fs-13">{`${translate('search_for_users')} ${translate(
            'user_group'
          ).toLowerCase()}`}</div>
          <FormItemCustom
            name="user_search"
            className="user-groups-form__search-container"
          >
            <Input.Search
              placeholder={translate('search')}
              loading={loadingSearch}
              onSearch={this.handleSubmitSearch}
            />
          </FormItemCustom>
          <Form.Item name="user_groups">
            <TransferForModal
              dataSource={users ? users : []}
              titles={['All', 'Assigned']}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              render={user => user.title}
              filterOption={this.handleFilterOption}
              showSearch={users.length > 0}
              onChange={targetKeys => this.setState({targetKeys})}
            />
          </Form.Item>
        </div>
      </DrawerForm>
    )
  }
}

const mapStateToProps = state => {
  return {state: state.general}
}

export default withRouter(
  connect(mapStateToProps, {
    setToastMessage,
    setRefresh,
  })(UserGroupsFormContainer)
)
