import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import backend from 'i18next-xhr-backend'

import {EN, DE, CZ, FR, IT, SP} from './translations'

i18n
  .use(LanguageDetector)
  .use(backend)
  .init({
    // we init with resources
    fallbackLng: ['en'],
    resources: {
      en: {
        translations: EN,
      },
      de: {
        translations: DE,
      },
      it: {
        translations: IT,
      },
      cz: {
        translations: CZ,
      },
      sp: {
        translations: SP,
      },
      fr: {
        translations: FR,
      },
    },
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format(value, formatting) {
        if (value instanceof Date) return moment(value).format(formatting)
        return value.toString()
      },
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
