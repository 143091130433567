import React, {Dispatch, ReactNode, SetStateAction} from 'react'

import {CustomIconComponentProps} from '@ant-design/icons/lib/components/Icon'
import {MenuProps} from 'rc-menu'
import {Moment} from 'moment'
import {UploadFile} from 'antd/lib/upload/interface'

import {IconType} from '@/components/icon'
import {PriceFeatureFlagKeys} from '@/modules/typedef'

import {TFilterListTable} from './modules/ITSM/components/itsm-list-table/typedf'
import {RouteComponentProps} from 'react-router-dom'

export type TStateDispatch<T> = Dispatch<SetStateAction<T>>

export type TAccount = {
  id: string
  name: string
  quota: string
  requestedTier: number
  state: string
  tier: number
}

export enum RefreshType {
  EmptyChannel = 'emptyChannel',
  WorkNoteNotif = 'work_note_notifications',
  CommentNotif = 'comment_notifications',
  Subs = 'subs',
  IncidentNotif = 'incident_notifications',
  KrequestNotif = 'k_request_notifications',
  IncidentTaskNotif = 'incident_task_notifications',
  RoleSettings = 'role_settings',
  RequestNotif = 'request_notifications',
  RequestItemNotif = 'request_item_notifications',
  RequestTaskNotif = 'request_task_notifications',
  Notifications = 'notifications',
}

export enum SubMenuOptions {
  SALES = 'sales',
  SERVICE_MANAGEMENT = 'service-management',
  MARKETING = 'marketing',
  FINANCE = 'finance',
  SETTINGS = 'settings',
  HUMAN_RESOURCES = 'human-resources',
  CMDB = 'cmdb',
  CMDB_ASSETS = 'assets',
  CMDB_MANAGE_ASSETS = 'manage-assets',
  CMDB_MANAGE_ACCOUNT = 'manage-account',
  CMDB_MANAGE_APPS = 'manage-apps',
  SASS_APP_USERS = 'app_users',
}

export enum Assets {
  myAccountsAsset = 'myAccounts',
  usersAccountsAsset = 'usersAccounts',
  attachmentsAsset = 'attachments',
  incidentsAsset = 'incidents',
  incidentAsset = 'incident',
  kRequestsAsset = 'k_requests',
  kRequestAsset = 'k_request',
  requestsAsset = 'requests',
  suppliersAsset = 'suppliers',
  identityAsset = 'identity',
  usersListAsset = 'users_list',
  supplierProductsAsset = 'supplier_products',
  announcementsAsset = 'announcements',
  rejectedReasonsAsset = 'rej_reason',
  categoriesAsset = 'categories',
  assignmentGroupsAsset = 'assignment_groups',
  userGroupsAsset = 'user_groups',
  assignmentRulesAsset = 'assignment_rules',
  creditAsset = 'credit',
  initiatedFromAsset = 'initiatedFrom',
  incidentTasksAsset = 'incident_tasks',
  commentsAsset = 'comments',
  timeLogsAsset = 'time_logs',
  timeLogsResolveAsset = 'time_logs_resolve',
  billingItemsAsset = 'billing_items',
  billingReportsAsset = 'billing_reports',
  eventsAsset = 'events',
  expensesAsset = 'expenses',
  timelogsExpensesAsset = 'timelogsExpenses',
  svrAsset = 'svr',
  invitationsAsset = 'invitations',
  rolesAsset = 'roles',
  requestItemsAsset = 'request_items',
  requestTasksAsset = 'request_tasks',
  timeSessionAsset = 'time_session',
  notificationsAsset = 'notifications',
  WORK_NOTE = 'work_note',
  COMMENT = 'comment',
}

export enum Entities {
  INCIDENT = 'incident',
  INCIDENTS = 'incidents',
  INCIDENT_TASK = 'incident_task',
  REQUEST_TASK = 'request_task',
  K_REQUEST = 'k_request',
  K_REQUESTS = 'k_requests',
  BILLING_REPORT = 'billing_report',
  REQUEST = 'request',
  REQUEST_ITEM = 'request_item',
  EXPENSES = 'expenses',
}

export enum RequestStatus {
  INITIAL = 'INITIAL',
  REQUESTED = 'REQUESTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum InvitationsStep {
  ONE = '1',
  TWO = '2',
}

export type TCountry = {
  longName: string
  shortName: string
}

export type TCustomIconProps =
  | CustomIconComponentProps
  | React.SVGProps<SVGSVGElement>

export type TSubMenuProps = {
  activeSubMenu: MenuProps['selectedKeys']
  handleClickMenu: (key: React.Key) => void
}

export type TSubMenuItem = {
  route: TRoute
  path: string
  iconType: IconType
  title: string
  e2eTestTag?: string
  availability?: boolean
  stripeFeatureFlag?: PriceFeatureFlagKeys
}

export type TMenuItem = {
  route: TRoute
  path: string
  title: string
  icon?: React.ReactNode
  stripeFeatureFlag?: PriceFeatureFlagKeys
}

export enum Modules {
  ITSM = 'itsm',
  HUMAN_RESOURCES = 'humanResources',
  CMDB = 'cmdb',
  SAAS = 'saas',
  Subscriptions = 'subscriptions',
}

export type TUserPermissions = {
  [key in UserRole | AppVersion]?: boolean
}

export enum UserRole {
  OPERATOR = 'OPERATOR',
  OWNER = 'OWNER',
  PROVIDER = 'provider',
  USER = 'USER',
  ALL = 'all',
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier',
}

export enum AppVersion {
  APP = 'app',
  PORTAL = 'portal',
}

export type TWeekdayPricingPolicy = {
  from: string | Moment
  price_multiplier: number
  to: string | Moment
}

export type TPricingPolicy = {
  additional_hour_rate: number
  additional_pricing_unit: number
  currency: string
  pricing_unit: number
  primary_hour_rate: number
  primary_hours_number: number
  rate: number
  travel_distance_unit_rate: number
  travel_fix_rate: number
  travel_hour_rate: number
  travel_pricing_unit: number
  weekday_pricing_policies: {
    [key in number]: {
      [key in number]: TWeekdayPricingPolicy
    }
  }
  holiday_pricing_policies: TWeekdayPricingPolicy[]
}

export type TUser = {
  active: boolean
  assignment_groups?: Array<string>
  contract_type: string
  docs_read: string
  email: string
  email_signature: string
  full_name: string
  name: string
  notification: boolean
  org_display_name: string
  org_name: string
  phone: string
  pricing_policy: TPricingPolicy
  sub_id: string
  surname: string
  timezone: string
  type: string
  unavailable: boolean
  org_role: string
  uuid: string
}

export type TRoutes = Record<TRoute, TRouteData>

export enum TRoute {
  // ITSM
  ITSM_USER_INVITATION = 'ITSM_USER_INVITATION',
  ITSM_ORG_INVITATION = 'ITSM_ORG_INVITATION',
  ITSM_SERVICE_MANAGEMENT_DASHBOARD = 'ITSM_SERVICE_MANAGEMENT_DASHBOARD',
  ITSM_SERVICE_MANAGEMENT_DASHBOARD_UP = 'ITSM_SERVICE_MANAGEMENT_DASHBOARD_UP',
  ITSM_FINANCE_DASHBOARD = 'ITSM_FINANCE_DASHBOARD',
  ITSM_BILLING_REPORTS = 'ITSM_BILLING_REPORTS',
  ITSM_CREDIT_SYSTEM = 'ITSM_CREDIT_SYSTEM',
  ITSM_BILLING_REPORT = 'ITSM_BILLING_REPORT',
  ITSM_INCIDENTS = 'ITSM_INCIDENTS',
  ITSM_INCIDENTS_UP = 'ITSM_INCIDENTS_UP',
  ITSM_INCIDENT_NEW = 'ITSM_INCIDENT_NEW',
  ITSM_INCIDENT_NEW_UP = 'ITSM_INCIDENT_NEW_UP',
  ITSM_INCIDENT_STATE = 'ITSM_INCIDENT_STATE',
  ITSM_INCIDENT_DETAIL = 'ITSM_INCIDENT_DETAIL',
  ITSM_INCIDENT_DETAIL_UP = 'ITSM_INCIDENT_DETAIL_UP',
  ITSM_INCIDENT_TASK_NEW = 'ITSM_INCIDENT_TASK_NEW',
  ITSM_INCIDENT_TASK_DETAIL = 'ITSM_INCIDENT_TASK_DETAIL',
  ITSM_DOCUMENTS = 'ITSM_DOCUMENTS',
  ITSM_REQUESTS = 'ITSM_REQUESTS',
  ITSM_REQUEST_NEW = 'ITSM_REQUEST_NEW',
  ITSM_REQUESTS_STATE = 'ITSM_REQUESTS_STATE',
  ITSM_REQUESTS_UP = 'ITSM_REQUESTS_UP',
  ITSM_REQUEST_NEW_UP = 'ITSM_REQUEST_NEW_UP',
  ITSM_REQUEST_DETAIL_UP = 'ITSM_REQUEST_DETAIL_UP',
  ITSM_EVENTS = 'ITSM_EVENTS',
  ITSM_EVENT_NEW = 'ITSM_EVENT_NEW',
  ITSM_EVENT = 'ITSM_EVENT',
  ITSM_TIME_SESSION = 'ITSM_TIME_SESSION',
  ITSM_EXPENSES = 'ITSM_EXPENSES',
  ITSM_SETTINGS_USER = 'ITSM_SETTINGS_USER',
  ITSM_SETTINGS_ROLE = 'ITSM_SETTINGS_ROLE',
  ITSM_SETTINGS_CHANNELS = 'ITSM_SETTINGS_CHANNELS',
  ITSM_SETTINGS_CHANNEL_DETAILS = 'ITSM_SETTINGS_CHANNEL_DETAILS',
  ITSM_SETTINGS_CHANNEL_NEW = 'ITSM_SETTINGS_CHANNEL_NEW',
  ITSM_SETTINGS_IDENTITIES = 'ITSM_SETTINGS_IDENTITIES',
  ITSM_SETTINGS_USERS = 'ITSM_SETTINGS_USERS',
  ITSM_SETTINGS_SUPPLIERS = 'ITSM_SETTINGS_SUPPLIERS',
  ITSM_SETTINGS_INVITATIONS = 'ITSM_SETTINGS_INVITATIONS',
  ITSM_SETTINGS_CATEGORIES = 'ITSM_SETTINGS_CATEGORIES',
  ITSM_SETTINGS_REASONS = 'ITSM_SETTINGS_REASONS',
  ITSM_SETTINGS_ASSIGNMENT_GROUPS = 'ITSM_SETTINGS_ASSIGNMENT_GROUPS',
  ITSM_SETTINGS_USER_GROUPS = 'ITSM_SETTINGS_USER_GROUPS',
  ITSM_SETTINGS_NOTIFICATIONS = 'ITSM_SETTINGS_NOTIFICATIONS',
  ITSM_SETTINGS_ASSIGNMENT_RULES = 'ITSM_SETTINGS_ASSIGNMENT_RULES',
  ITSM_SETTINGS_ANNOUNCEMENTS = 'ITSM_SETTINGS_ANNOUNCEMENTS',
  ITSM_SETTINGS_SUPPLIER_PRODUCTS = 'ITSM_SETTINGS_SUPPLIER_PRODUCTS',
  ITSM_SETTINGS_SUPPLIER_PRODUCT_DETAILS = 'ITSM_SETTINGS_SUPPLIER_PRODUCT_DETAILS',
  ITSM_SETTINGS_SUPPLIER_PRODUCT_NEW = 'ITSM_SETTINGS_SUPPLIER_PRODUCT_NEW',
  ITSM_SETTINGS_CUSTOMER_PRODUCTS = 'ITSM_SETTINGS_CUSTOMER_PRODUCTS',
  ITSM_SETTINGS_CUSTOMER_PRODUCT_DETAILS = 'ITSM_SETTINGS_CUSTOMER_PRODUCT_DETAILS',
  ITSM_SETTINGS_CUSTOMER_PRODUCT_NEW = 'ITSM_SETTINGS_CUSTOMER_PRODUCT_NEW',
  ITSM_SETTINGS_MY_ACCOUNTS = 'ITSM_SETTINGS_MY_ACCOUNTS',
  ITSM_SETTINGS_USER_ACCOUNTS = 'ITSM_SETTINGS_USER_ACCOUNTS',
  ITSM_SETTINGS_ACCOUNTS_DETAILS = 'ITSM_SETTINGS_ACCOUNTS_DETAILS',
  ITSM_SETTINGS_USERS_ACCOUNTS_DETAILS = 'ITSM_SETTINGS_USERS_ACCOUNTS_DETAILS',
  ITSM_SETTINGS_NEW_ACCOUNT = 'ITSM_SETTINGS_NEW_ACCOUNT',
  ITSM_SETTINGS_LOCATIONS = 'ITSM_SETTINGS_LOCATIONS',
  ITSM_NOT_FOUND = 'ITSM_NOT_FOUND',
  ITSM_CUSTOM_REPORTS = 'ITSM_CUSTOM_REPORTS',
  ITSM_IDENTITY = 'ITSM_IDENTITY',
  ITSM_SETTINGS_ANNOUNCEMENT_DETAILS = 'ITSM_SETTINGS_ANNOUNCEMENT_DETAILS',
  ITSM_SETTINGS_ANNOUNCEMENT_NEW = 'ITSM_SETTINGS_ANNOUNCEMENT_NEW',
  // CMDB
  CMDB_DASHBOARD = 'CMDB_DASHBOARD',
  CMDB_ASSETS_LIST = 'CMDB_ASSETS_LIST',
  CMDB_ASSET_DETAIL = 'CMDB_ASSET_DETAIL',
  CMDB_ASSET_NEW = 'CMDB_ASSET_NEW',
  CMDB_ASSET_TYPES = 'CMDB_ASSET_TYPES',
  CMDB_ASSET_TYPE_DETAIL = 'CMDB_ASSET_TYPE_DETAIL',
  CMDB_USER_LIST = 'CMDB_USER_LIST',
  CMDB_USER_NEW = 'CMDB_USER_NEW',
  CMDB_USER_DETAIL = 'CMDB_USER_DETAIL',
  CMDB_ACCOUNTS = 'CMDB_ACCOUNTS',
  CMDB_ACCOUNT_NEW = 'CMDB_ACCOUNT_NEW',
  CMDB_ACCOUNT_DETAIL = 'CMDB_ACCOUNT_DETAIL',
  CMDB_LOCATION_LIST = 'CMDB_LOCATION_LIST',
  CMDB_LOCATION_NEW = 'CMDB_LOCATION_NEW',
  CMDB_LOCATION_DETAIL = 'CMDB_LOCATION_DETAIL',
  CMDB_NAMESPACE_LIST = 'CMDB_NAMESPACE_LIST',
  CMDB_NAMESPACE_NEW = 'CMDB_NAMESPACE_NEW',
  CMDB_NAMESPACE_DETAIL = 'CMDB_NAMESPACE_DETAIL',
  CMDB_APP_LIST = 'CMDB_APP_LIST',
  CMDB_APP_NEW = 'CMDB_APP_NEW',
  CMDB_APP_DETAIL = 'CMDB_APP_DETAIL',
  CMDB_NOT_FOUND = 'CMDB_NOT_FOUND',
  // SASS
  SAAS_DASHBOARD = 'SAAS_DASHBOARD',
  SASS_USERS = 'SASS_USERS',
  SASS_APPS = 'SASS_APPS',
  SASS_USER_NEW = 'SASS_USER_NEW',
  SASS_USER_DETAIL = 'SASS_USER_DETAIL',
  SASS_APP_MANAGE = 'SASS_APP_MANAGE',
  SASS_GROUP_USERS = 'SASS_GROUP_USERS',
  SASS_APP_USER_DETAIL = 'SASS_APP_USER_DETAIL',
  SASS_APP_USER_NEW = 'SASS_APP_USER_NEW',
  SASS_SETTINGS_APPS = 'SASS_SETTINGS_APPS',
  SASS_APP_NEW = 'SASS_APP_NEW',
  SASS_APP = 'SASS_APP',
  SETUP_GUIDE = 'SETUP_GUIDE',
  //  Subscriptions
  CARD_DETAILS = 'CARD_DETAILS',
  PLANS_AND_PACKAGES = 'PLANS_AND_PACKAGES',
  INVOICE_LIST = 'INVOICE_LIST',
  PRODUCTS = 'PRODUCTS',
  SERVICES = 'SERVICES',
  SASS_SYSTEM_USERS = 'SASS_SYSTEM_USERS',
  SASS_SYSTEM_USERS_DETAIL = 'SASS_SYSTEM_USERS_DETAIL',
  SASS_SYSTEM_USERS_NEW = 'SASS_SYSTEM_USERS_NEW',
  SAAS_SETTINGS_IDENTITY_PROVIDER = 'SAAS_SETTINGS_IDENTITY_PROVIDER',
  SAAS_SETTINGS_NAMESPACES = 'SAAS_SETTINGS_NAMESPACES',
  SAAS_SETTINGS_NAMESPACE_NEW = 'SAAS_SETTINGS_NAMESPACE_NEW',
  SAAS_APPLICATION_DISCOVERY = 'SAAS_APPLICATION_DISCOVERY',
  GROUPS_LIST_SAAS = 'GROUPS_LIST_SAAS',
  GROUPS_LIST_NEW_SAAS = 'GROUPS_LIST_NEW_SAAS',
  GROUP_DETAIL_SAAS = 'GROUP_DETAIL_SAAS',
  SERVICE_AGREEMENTS = 'SERVICE_AGREEMENTS',
}

export type TRouteData = {
  component: (props: RouteComponentProps) => JSX.Element
  disabled?: boolean
  props: {
    path: string
    exact?: boolean
    private?: boolean
    userPermissions?: TUserPermissions
    featureFlag?: PriceFeatureFlagKeys
  }
}

export enum SortingValues {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortingKeys {
  created_at = 'created_at',
  updated_at = 'updated_at',
  first_seen = 'first_seen',
  last_seen = 'last_seen',
  active_from = 'active_from',
  active_to = 'active_to',
}

export type TSort = {[key in SortingKeys]?: SortingValues}

export type TListOptions<T> = {
  passedBookmark?: string
  selector?: Record<string, unknown>
  resolve?: string
  sort?: TSort[] | null
  fields?: Array<keyof T>
}

export type TListArgs<T> = {
  passedBookmark?: string
  selector?: Record<string, unknown>
  resolve?: string
  passedSorter?: TSort | null
  resetBookmarks?: boolean
  passedFilters?: TFilterListTable<T>
}

export enum Breakpoints {
  'xs' = 576,
  'sm' = 768,
  'md' = 992,
  'lg' = 1200,
}

export type TFile = {
  lastModified: number
  lastModifiedDate: Date
  name: string
  size: number
  type: string
  uid: string
  webkitRelativePath: string
}

export type TConvertedFile = {
  data: string
  file: UploadFile
}

export type TChildren = {
  children: ReactNode
}

export enum PermissionsRole {
  owner = 'owner',
  admin = 'admin',
  user = 'user',
}

export type TOrganization = {
  id: string
  name: string
  accountId: string
}

export type TActionMap<M extends {[index: string]: any}> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type TInviteQuery = {
  accept_invite?: string
  grant?: string
  channel_invite?: string
  org_id?: string
  redirect?: string
}
