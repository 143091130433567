import {TAppState} from '@/redux/store'
import {RequestStatus} from '@/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'

export function selectBillingFeatureFlags(state: TAppState) {
  return state.billingReducer.featureFlags
}

export function selectBillingSubscriptions(state: TAppState) {
  return state.billingReducer.subscriptions
}

export function selectBillingSubscriptionsStatus(state: TAppState) {
  return state.billingReducer.statuses.fetchBillingSubscriptions
}

export function selectCustomer(state: TAppState) {
  return state.billingReducer.customer
}

export function selectTransactionAmount(state: TAppState) {
  return state.billingReducer.transactionAmount
}

export function selectTransactionCounter(state: TAppState) {
  return state.billingReducer.maxTransactionNumber
}

export function selectRefetchDelay(state: TAppState) {
  return state.billingReducer.refetchDelay
}

export function selectorIsLoadedSubscriptions(state: TAppState) {
  const status = selectBillingSubscriptionsStatus(state)

  return status === RequestStatus.INITIAL || checkIsLoading(status)
}
