import {useParams} from 'react-router-dom'
import queryString from 'query-string'

import {ItsmAssets} from '@/modules/ITSM/typedef'

export const useGetDefaultFilters = (
  asset: ItsmAssets,
  entity: string | undefined
) => {
  const {id} = useParams<{id: string}>()

  switch (asset) {
    case ItsmAssets.TimeLogs:
      if (!entity) return {}
      return {
        entity: `${entity}:${id}`,
      }
    case ItsmAssets.TimelogExpenses:
      if (!entity) return {}
      return {
        entity: `${entity}:${id}`,
      }

    case ItsmAssets.SVR:
      if (!entity) return {}
      return {
        entity: `${entity}:${id}`,
      }

    case ItsmAssets.IncidentTasks:
      if (!entity) return {}
      return {
        [entity]: id,
      }

    case ItsmAssets.IncidentExpenses:
      return {
        entity: `timelog:${queryString.parse(window.location.search).timelog}`,
      }

    default:
      return {}
  }
}
