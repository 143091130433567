import request from 'superagent'

import {CmdbUserRequest, CmdbUserResponse} from '@/modules/CMDB/api-types/Api'
import {
  generateUserUrl,
  getHeaders,
} from '@/modules/CMDB/api/generate-cmdb-api-url'
import {TSelector} from '@/modules/CMDB/typedef'

import {ISuperAgentResponse} from './typedf'

export const fetchUsers = async <T extends Record<string, any>>(
  options: TSelector
): Promise<ISuperAgentResponse<T>> => {
  return await request
    .options(generateUserUrl().getAll)
    .set(getHeaders())
    .send(options)
}

export const fetchOneUser = async (
  id: string
): Promise<ISuperAgentResponse<CmdbUserResponse>> => {
  return await request.get(generateUserUrl(id).getOne).set(getHeaders())
}

export const postCmdbUser = async (
  data: CmdbUserRequest
): Promise<ISuperAgentResponse<CmdbUserResponse>> => {
  return await request
    .post(generateUserUrl().update)
    .set(getHeaders())
    .send({body: data})
}

export const patchCmdbUser = async (
  data: CmdbUserRequest,
  id = ''
): Promise<ISuperAgentResponse<CmdbUserResponse>> => {
  return await request
    .patch(generateUserUrl(id).patch)
    .set(getHeaders())
    .send({body: data})
}
