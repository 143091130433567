import React, {Fragment} from 'react'

import moment from 'moment'

import {translate} from '@/services/i18n'

import {TCustomerProduct} from '../../typedef'

import {convertSlaResponseTime} from './utils'
import './product-details.scss'

type TProps = {
  customer_product: TCustomerProduct
}

const ProductDetailsUP = ({customer_product}: TProps) => {
  const responseTime =
    customer_product.sla.max_response_time ||
    customer_product.sla.max_response_time_business_days

  return (
    <div className="itsm-product-details">
      {customer_product && (
        <Fragment>
          <div className="itsm-product-details__data-container">
            {customer_product.sla_type && (
              <div>{`${translate('sla_type')}: ${
                customer_product.sla_type
              }`}</div>
            )}

            <div>{`${translate('max_response_time')}: ${convertSlaResponseTime(
              responseTime
            )}`}</div>

            <div>{`${translate('working_days')}:`}</div>

            {customer_product.sla?.weekdays &&
              Object.entries(customer_product.sla.weekdays).map((el, index) => {
                return (
                  <div key={index}>
                    <div className="itsm-product-details__row">
                      {`${moment()
                        .day(el[0])
                        .format('dddd')}`}
                    </div>
                    <div className="itsm-product-details__row">
                      {`${translate('from')}: ${el[1].support_from} `}
                      {`${translate('to')}: ${el[1].support_to}`}
                    </div>
                  </div>
                )
              })}
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default ProductDetailsUP
