import React, {useState, useEffect} from 'react'

import {Modal, Row, Col, Form} from 'antd'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import {convertToRaw} from 'draft-js'

import {Button} from '@/components/button'
import {EditorWrapper} from '@/components/editor'
import ErrorPage from '@/components/error/error-page/error-page'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import {translate} from '@/services/i18n'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {usePostEntity} from '@/hooks/useUpdateData'
import {usePrevious} from '@/hooks/usePrevious'
import {postCancelIncident} from '@/modules/ITSM/api/incidentsRequests'
import {draftToolbarOptions} from '@/modules/ITSM/utils/Constants'
import {checkIsLoading} from '@/utils/check-is-loading'
import {Entities} from '@/typedef'
import FormItemCustom from '@/components/form/form-item-custom'

import {useStateContext} from '../../incident/incident-context'
import {TIncident} from '../typedf'

type TProps = {
  resetCancelAction: () => void
}

const CancellationModal = ({resetCancelAction}: TProps) => {
  const [form] = Form.useForm()

  const [modal, contextHolder] = Modal.useModal()

  const {entityData, getData, entity} = useStateContext<TIncident>()

  const {uuid} = entityData || {}

  const [cancelModalVisible, setCancelModalVisible] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const {editorState, onEditorStateChange} = useSetEditorState()

  const {saveEntity: saveCancellationNote, saveEntityStatus} = usePostEntity(
    postCancelIncident
  )

  const prevCancelModalVisible = usePrevious(cancelModalVisible)

  useEffect(() => {
    if (!isMounted) {
      modal.confirm({
        okButtonProps: {hidden: true},
        cancelButtonProps: {hidden: true},
        bodyStyle: {marginBottom: '-20px'},
        content: (
          <div>
            <div>
              {entity === Entities.INCIDENT
                ? translate('cancel_incident_question')
                : translate('cancel_k_request_question')}
            </div>
            <div className="flex flex--justifyCenter mt-10">
              <Button
                type="primary"
                title={translate('yes')}
                onClick={() => {
                  setCancelModalVisible(true)
                }}
                className="mr-10"
              />

              <Button
                title={translate('no')}
                onClick={() => {
                  resetCancelAction()
                }}
              />
            </div>
          </div>
        ),
      })
      setIsMounted(true)
    }
  }, [cancelModalVisible, entity, isMounted, modal, resetCancelAction])

  const handleCancel = async () => {
    const data = {
      entity: `${entity}:${uuid}`,
      cancellation_notes: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    }

    const result = await saveCancellationNote(data)
    setCancelModalVisible(false)

    if (result) getData(true)
  }

  useEffect(() => {
    if (!!prevCancelModalVisible && !cancelModalVisible) resetCancelAction()
  }, [cancelModalVisible, prevCancelModalVisible, resetCancelAction])

  return (
    <>
      {!cancelModalVisible && <div>{contextHolder}</div>}
      <Modal
        footer={null}
        open={cancelModalVisible}
        onCancel={() => {
          setCancelModalVisible(false)
        }}
        forceRender
      >
        <SentryErrorBoundary
          fallback={
            <ErrorPage
              resolvers={[
                () => {
                  setCancelModalVisible(false)
                },
              ]}
              buttonTitle={translate('close')}
              noRedirect={true}
            />
          }
        >
          <Form form={form} layout="vertical">
            <FormItemCustom
              name="cancellation_notes"
              label={translate('cancellationNotes')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <EditorWrapper>
                <Editor
                  toolbar={draftToolbarOptions}
                  editorState={editorState}
                  wrapperClassName="wysiwyg-editor wysiwyg-editor--mb-0"
                  onEditorStateChange={onEditorStateChange}
                />
              </EditorWrapper>
            </FormItemCustom>

            <Row>
              <Col>
                <Button
                  title={translate('confirm')}
                  type="primary"
                  disabled={
                    checkIsLoading(saveEntityStatus) ||
                    (editorState && !editorState.getCurrentContent().hasText())
                  }
                  onClick={handleCancel}
                />
                <Button
                  title={translate('close')}
                  className="ml-10"
                  onClick={() => {
                    setCancelModalVisible(false)
                  }}
                />
              </Col>
            </Row>
          </Form>
        </SentryErrorBoundary>
      </Modal>
    </>
  )
}

export default CancellationModal
