type TCMDBUserActionsCreators = typeof import('./cmdb-user-actions')
type TActionCreators = TCMDBUserActionsCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

export enum TActionTypes {
  RESET_CMDB_USER_STATE = 'RESET_CMDB_USER_STATE',
  FETCH_CMDB_IDENTITY_ME_REQUESTED = 'FETCH_CMDB_IDENTITY_ME_REQUESTED',
  FETCH_CMDB_USER_SUCCESS = 'FETCH_CMDB_USER_SUCCESS',
  FETCH_CMDB_USER_FAILED = 'FETCH_CMDB_USER_FAILED',
  ADD_CMDB_NAMESPACE = 'ADD_CMDB_NAMESPACE',
}
