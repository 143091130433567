import React, {Key} from 'react'

import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons'
import {Select} from 'antd'

import {translate} from '@/services/i18n'

import './export-btn.scss'

const {Option} = Select

type TProps = {
  onChange: (format: string, selectedRowKeys?: Array<Key>) => void
  isLoading?: boolean
  title?: string
  selectedRowKeys?: Array<Key>
  isDisabled?: boolean
}

export const ExportBtn = ({
  onChange,
  isLoading,
  title = translate('export'),
  selectedRowKeys,
  isDisabled,
}: TProps) => (
  <Select
    onChange={format => onChange(format, selectedRowKeys)}
    suffixIcon={isLoading ? <LoadingOutlined /> : <DownloadOutlined />}
    value={title}
    className="export-btn"
    disabled={isLoading || isDisabled}
  >
    <Option value="xlsx">.xlsx</Option>
    <Option value="csv">.csv</Option>
  </Select>
)
