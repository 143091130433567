import React, {useEffect} from 'react'

import {Col, Form} from 'antd'
import {useSelector} from 'react-redux'
import difference from 'lodash/difference'

import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {translate} from '@/services/i18n'
import {useGetRoles} from '@/modules/ITSM/hooks/identityHooks'
import {fetchUsers, patchUser} from '@/modules/ITSM/api/userRequests'
import {useFetchHook} from '@/hooks/useFetch'
import {ItsmAssets, TUser} from '@/modules/ITSM/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import FormItemCustom from '@/components/form/form-item-custom'
import {useAsync} from '@/hooks/scroll-select'
import MultiSelect from '@/components/MultiSelect/MultiSelect'
import {useGetAllBmData} from '@/hooks/use-get-all-bm-data'
import {selectOrgId} from '@/redux/entries/entries-selector'

type TProps = {
  asset: ItsmAssets
  record: Record<string, any>
}

export const TeamMembersForm: React.FC<TProps> = ({asset, record}) => {
  const [form] = Form.useForm()

  const {uuid: userId} =
    asset === ItsmAssets.Users ? record : record?.users?.[0] || {}

  const [, , , , loading] = useGetRoles(userId)
  const orgId = useSelector(selectOrgId)

  const fetchUsersSelector = {
    selector: {
      org_name: {$regex: orgId},
    },
  }

  const {
    data: allUsers,
    status: fetchUsersStatus,
    run: getUsers,
    numberOfRecords,
    setInitialState,
  } = useAsync<TUser>(fetchUsers)

  const {data: initialUsers, run: getInitialUsers} = useGetAllBmData<TUser>(
    fetchUsers
  )

  const initialUsersIds = initialUsers?.map(({uuid}) => uuid)

  useEffect(() => {
    if (userId)
      getInitialUsers({
        selector: {
          org_name: {$regex: orgId},
          ...(userId && {manager: userId}),
        },
      })
  }, [userId, orgId])

  useEffect(() => {
    if (initialUsers) form.setFieldsValue({team_members: initialUsersIds})
  }, [initialUsers, form, initialUsersIds])

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    status: saveStatus,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const onChange = async (value: string[]) => {
    const addedMembersIds = difference(value, initialUsersIds)
    const deletedMembersIds = difference(initialUsersIds, value)

    try {
      handleRequested()
      if (addedMembersIds.length > 0) {
        await patchUser(addedMembersIds[0], {manager: userId})
      }
      if (deletedMembersIds.length > 0) {
        await patchUser(deletedMembersIds[0], {manager: null})
      }
      handleSuccess()

      getInitialUsers({
        selector: {
          org_name: {$regex: orgId},
          ...(userId && {manager: userId}),
        },
        resetData: true,
      })
      setInitialState()
    } catch (err) {
      handleFail(err)
    }
  }

  return (
    <Skeleton active={Boolean(loading)} view={<FormSkeleton />}>
      <h5 className="capitalize--words">{translate('team_members')}</h5>
      <Col span={24} className="ml-0">
        <Form form={form} layout="vertical">
          <FormItemCustom name="team_members" initialValue={initialUsersIds}>
            <MultiSelect<TUser>
              onChange={onChange}
              disabled={checkIsLoading(saveStatus)}
              loading={checkIsLoading(fetchUsersStatus)}
              onDropdownVisibleChange={(open: boolean) =>
                open && getUsers(fetchUsersSelector)
              }
              run={() => getUsers(fetchUsersSelector)}
              data={allUsers || initialUsers || []}
              optionContent={(rec: TUser) => rec?.full_name}
              numberOfRecords={numberOfRecords}
            />
          </FormItemCustom>
        </Form>
      </Col>
    </Skeleton>
  )
}
