var store = undefined

const storeProvider = {
  init(configureStore) {
    store = configureStore()
  },
  getStore() {
    return store
  },
}

export default storeProvider
