import React from 'react'

import {Col, Row} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {translate} from '@/services/i18n'
import {useAcceptOrReject} from '@/modules/ITSM/components/incident/hooks/useAcceptOrReject'

import {checkIsLoading} from '@/utils/check-is-loading'
import ErrorPage from '@/components/error/error-page/error-page'
import {Button} from '@/components/button/button'

import {TIncident} from '../incident-k-request/typedf'

import {setRejectReason} from './incident-reducer'
import {useStateContext} from './incident-context'

const ConfirmButtons = () => {
  const {entityData, dispatch, getData, entity} = useStateContext<TIncident>()
  const {saveEntity: acceptOrReject, saveEntityStatus} = useAcceptOrReject()

  const handleAccept = async () => {
    const result =
      entityData &&
      (await acceptOrReject({
        entity: `${entity}:${entityData.uuid}`,
        decision: 'accepted',
      }))

    if (result !== undefined) getData(true)
  }
  return (
    <Row className="mb-30">
      <Col span={24}>
        <Button
          className="mr-10"
          type="primary"
          onClick={handleAccept}
          disabled={checkIsLoading(saveEntityStatus)}
          title={translate('accept')}
        />
        <Button
          onClick={() => dispatch(setRejectReason(true))}
          title={translate('reject')}
        />
      </Col>
    </Row>
  )
}

export default withErrorBoundary(ConfirmButtons, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
