// @ts-nocheck UserGroupsFormNewContainer is not refactored to typescript

import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'

import UserGroupsFormNewContainer from './UserGroupsFormNewContainer'

const UserGroupsNewWrapper = () => {
  const {getEntityDataInit, onClose} = useListTableStateContext()

  return (
    <UserGroupsFormNewContainer
      getEntityData={getEntityDataInit}
      onClose={onClose}
    />
  )
}

export default UserGroupsNewWrapper
