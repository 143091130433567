import React, {useEffect} from 'react'

import {Form, Input, Select} from 'antd'

import {filterOption} from '@/utils/filter-option'
import {translate} from '@/services/i18n'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {TransferForModal} from '@/modules/ITSM/components/transfer-for-modal'
import {FormSkeleton, Skeleton} from '@/components/skeleton'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import FormItemCustom from '@/components/form/form-item-custom'

import {removeDuplicatesFromObj} from '../../../../utils/Helpers'

import './assignment-groups-form.scss'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'

const Option = Select.Option

const AssignmentGroupsForm = allProps => {
  const [form] = Form.useForm()

  const {
    props,
    state,
    handleSubmitSearch,
    onRef,
    setTargetKeys,
    recognizeFieldChange,
    handleSubmit,
    loadingSubmit,
    handleCancel,
  } = allProps

  const {
    loadingSearch,
    targetKeys,
    users,
    selectedKeys,
    loadingUsers,
    org_roles,
  } = state

  const {assignmentGroup} = props

  useEffect(() => {
    onRef && onRef(form)
  }, [onRef, form])

  const handleFilterOption = (value, name) => {
    if (name['title'].toLowerCase().includes(value.toLowerCase())) {
      return true
    }
  }

  return (
    <Skeleton active={loadingUsers} view={<FormSkeleton />}>
      <div className={'assignment-groups-form'}>
        <DrawerForm
          // eslint-disable-next-line
          validateMessages={{required: '${label} is required'}}
          layout="vertical"
          name="drawer-category"
          onFinish={handleSubmit}
          form={form}
          onValuesChange={recognizeFieldChange}
          loadingSubmit={loadingSubmit}
          handleCancel={handleCancel}
        >
          <FormItemCustom
            name="name"
            initialValue={assignmentGroup?.name || ''}
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('name')}
          >
            <Input />
          </FormItemCustom>

          <FormItemCustom
            name="org_name"
            initialValue={assignmentGroup?.org_name || null}
            rules={[
              {
                required: true,
                message: `${translate('org_name')} ${translate('is_required')}`,
              },
            ]}
            label={translate('org_name')}
          >
            <Select
              allowClear
              showSearch
              filterOption={filterOption}
              getPopupContainer={e => e.parentNode}
            >
              {org_roles &&
                Object.entries(org_roles).map((e, i) => (
                  <Option key={i} value={e[1].value}>
                    {e[1].name}
                  </Option>
                ))}
            </Select>
          </FormItemCustom>
          <div className="fs-13">{`${translate('search_for_users')} ${translate(
            'assignmentGroup'
          ).toLowerCase()}`}</div>
          <FormItemCustom
            name="user_search"
            className="assignment-groups-form__search-container"
          >
            <Input.Search
              placeholder={translate('search')}
              loading={loadingSearch}
              onSearch={handleSubmitSearch}
              onFocus={setAutocompleteNone}
            />
          </FormItemCustom>
          <Form.Item name="assignment_groups">
            <TransferForModal
              dataSource={users ? users : []}
              titles={['All', 'Assigned']}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={setTargetKeys}
              render={user => user.title}
              filterOption={handleFilterOption}
              showSearch={users.length > 0}
            />
          </Form.Item>

          <SelectWithBM
            mode="multiple"
            required={true}
            label={translate('notification_users')}
            searchKey="full_name"
            name="notification_users"
            data={state.usersData.map(e => {
              return {full_name: e.full_name, uuid: e.uuid}
            })}
            initialValueMultiple={assignmentGroup?.notification_users || []}
            initialValueResolved={[]}
            optionContent={rec => rec.full_name}
            setToastMessage={props.setToastMessage}
            className="full-width"
          />
          <FormItemCustom
            name="notification_emails"
            initialValue={assignmentGroup?.notification_emails || []}
            label={translate('notification_emails')}
          >
            <Select className="full-width" mode="tags">
              {removeDuplicatesFromObj(
                state.usersData
                  .map(e => {
                    return {
                      email: e.email,
                    }
                  })
                  .concat(
                    (
                      (assignmentGroup &&
                        assignmentGroup.notification_emails) ||
                      []
                    ).map(e => {
                      return {
                        email: e,
                      }
                    })
                  ),
                'email'
              ).map((e, i) => (
                <Option key={i} value={e.email}>
                  {e.email}
                </Option>
              ))}
            </Select>
          </FormItemCustom>
        </DrawerForm>
      </div>
    </Skeleton>
  )
}

export default AssignmentGroupsForm
