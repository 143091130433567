import {useCallback, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResponse} from '@/api/response-typedf'

const isErrorWithStatus = (error: unknown): error is {status: number} =>
  typeof error === 'object' && error !== null && 'status' in error

const sleep = (duration: number) =>
  new Promise(resolve => setTimeout(resolve, duration))

const retryErrorStatuses = [408, 429, 500, 502, 503, 504]

const initialSettings = {
  retryErrorStatuses,
  requestRetryCount: 2,
  delay: 1000,
}

export function useApiRequestWithRetry<T extends Record<string, any>, U = T>(
  promise: (payload: T) => Promise<ISuperAgentResponse<U>>,
  settings?: {
    retryErrorStatuses?: number[]
    requestRetryCount?: number
    delay?: number
  }
) {
  const {retryErrorStatuses, requestRetryCount, delay} = {
    ...initialSettings,
    ...settings,
  }
  const {
    error,
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const [entityData, setEntityData] = useState<U>()

  const run = useCallback(
    async (payload: T, retryCount = 0): Promise<{body?: U; error?: any}> => {
      try {
        handleRequested()
        const result = await promise(payload)
        handleSuccess()
        setEntityData(result.body)

        return result
      } catch (error) {
        if (
          isErrorWithStatus(error) &&
          retryCount < requestRetryCount &&
          retryErrorStatuses.includes(error.status)
        ) {
          await sleep(delay)

          return run(payload, retryCount + 1)
        } else {
          handleFail(error)

          return {error}
        }
      } finally {
        handleReset()
      }
    },
    [
      promise,
      delay,
      handleFail,
      handleRequested,
      handleReset,
      handleSuccess,
      requestRetryCount,
      retryErrorStatuses,
    ]
  )

  return {
    error,
    entityData,
    run,
    status,
  }
}
