import {useCallback} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {ItsmAssets} from '@/modules/ITSM/typedef'
import {TKey, translate} from '@/services/i18n'
import {
  setItsmTableFilters,
  setItsmTableQuickFilters,
} from '@/modules/ITSM/store/list-table/table-actions'
import {selectItsmTableQuickFilter} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'

import {BillingItemsQuickFilters} from '../constants'

type TProps = {
  asset: ItsmAssets
}

const useGetQuickFilters = ({asset}: TProps) => {
  const dispatch = useDispatch()

  const quickFilterActive =
    useSelector((state: TAppState) =>
      selectItsmTableQuickFilter(state, asset)
    ) || BillingItemsQuickFilters.Chargeable

  const getAssetQuickFilters = useCallback((): Array<{
    name: string
    query?: Record<string, boolean>
  }> | null => {
    return [
      {
        name: BillingItemsQuickFilters.Chargeable,
        query: {chargeable: true},
      },
      {name: BillingItemsQuickFilters.All, query: {}},
    ]
  }, [])

  const setQuickFilters = useCallback(
    (quickFilterActivePassed?: BillingItemsQuickFilters) => {
      const {query} =
        (getAssetQuickFilters() || []).find(
          ({name}) => name === quickFilterActivePassed
        ) || {}

      if (query) {
        dispatch(
          setItsmTableFilters(asset, {
            ...query,
          })
        )
      }
    },
    [asset, dispatch, getAssetQuickFilters]
  )

  const getQuickFilters = () => {
    return (getAssetQuickFilters() || []).map(({name}) => ({
      title: translate(name as TKey),
      isActive: quickFilterActive === name,
      onClick: () => {
        dispatch(
          setItsmTableQuickFilters(asset, name as BillingItemsQuickFilters)
        )
        setQuickFilters(name as BillingItemsQuickFilters)
      },
    }))
  }
  return {getQuickFilters, setQuickFilters}
}

export default useGetQuickFilters
