import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {userGroupsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TUserGroup} from '../typedef'

export const fetchUserGroups = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(userGroupsApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })

export const patchUserGroup = async (uuid: string, name: string) => {
  return await request
    .patch(userGroupsApi(uuid).post)
    .set(getHeadersWithGRPC())
    .send({name})
}

export const postUserGroup = async (uuid: string, data: TUserGroup) => {
  return await request
    .post(userGroupsApi(uuid).post)
    .set(getHeadersWithGRPC())
    .send(data)
}
