import {generateConfig} from '@/generateConfig'

import store from '../../../redux/store'
import {getBackendTokenHeaders} from '@/services/api'

const url = `${generateConfig().BASE_URL_SAAS}`

export function getToken() {
  const {backEndToken} = store.getState().itsmUser
  return {
    Authorization: `Bearer ${backEndToken}`,
  }
}

export function getSpace() {
  const {saasNamespaceId} = store.getState().entries

  return saasNamespaceId ? {space: saasNamespaceId} : {}
}

export function getHeaders() {
  return {
    ...getSpace(),
    ...getBackendTokenHeaders(),
  }
}

export const identityApi = () => {
  return {
    get: `${url}/identity/me`,
    post: `${url}/identity/me`,
    provider: `${url}/identity-provider`,
  }
}

export const accountRegisterApi = {
  post: `${url}/account/register`,
}

export const userApi = {
  get: () => `${url}/users`,
  post: `${url}/user`,
  getOne: (id: string) => `${url}/user/${id}`,
  getApp: (appId: string) => `${url}/${appId}/users`,
  postAssign: (appId: string) => `${url}/${appId}/users/assign`,
  postUnassign: (appId: string) => `${url}/${appId}/users/unassign`,
}

export const systemUserApi = {
  get: () => `${url}/account/systemusers`,
  getInNamespace: () => `${url}/systemusers`,
  getDetail: (id: string) => `${url}/systemuser/${id}`,
  post: () => `${url}/systemuser`,
  assign: (space?: string) => `${url}/systemuser/namespace/${space}/assign`,
  unassign: (space?: string) => `${url}/systemuser/namespace/${space}/unassign`,
}

export const appApi = {
  get: `${url}/applications`,
  post: `${url}/application`,
  getOne: (id: string) => `${url}/application/${id}`,
  patch: (id: string) => `${url}/application/${id}`,
  user: (userId: string) => `${url}/user/${userId}/applications`,
  discoverApps: () => `${url}/applications/discover`,
  systemUser: (userId: string) => `${url}/systemuser/${userId}/applications`,
}

export const spaceApi = {
  get: `${url}/my-spaces`,
  post: `https://saas-stg.blits-apis.com/v0/namespace/create`,
  patch: (id = '') => `${url}/namespace/${id}`,
}

export const metricsApi = {
  get: `${url}/metrics`,
}

export const groupsApi = {
  get: () => `${url}/groups`,
  post: () => `${url}/group`,
  patch: (id?: string) => `${url}/group/${id}`,
  getSingle: (id: string) => `${url}/group/${id}`,
  assign: (applicationId: string) =>
    `${url}/application/${applicationId}/groups/assign`,
  unassign: (applicationId: string) =>
    `${url}/application/${applicationId}/groups/unassign`,
}
