import {ColumnType} from 'antd/lib/table'

import {ISuperAgentResMultiple} from '@/api/response-typedf'

import {ItsmAssets} from '../../typedef'

export enum FilterTypes {
  Search = 'Search',
  SearchNumber = 'SearchNumber',
  Select = 'Select',
  SelectWithBM = 'SelectBM',
  Date = 'Date',
  NA = 'NA',
  SelectFetch = 'SelectFetch',
  Boolean = 'Boolean',
  DateRange = 'DateRange',
}

export interface IExtendedColumnType<T> extends ColumnType<T> {
  index: number
  selected: boolean
  options?: string[]
}

export type TDataTablePreset = Array<{
  title: string
  dataIndex: string | string[]
  index: number
  filterType?: FilterTypes
  selected: boolean
  options?: string[]
  quickFilter?: boolean
  advancedRender?: boolean
}>

export type TFilterListTable<T> = {
  [key in keyof T | '$or' | '$and']?:
    | string
    | number
    | Record<string, any>
    | Record<string, any>[]
    | boolean
    | string[]
}

export enum QueryTypes {
  Number = 'number',
}

export type TColumnRender<T> = {
  title: string
  value: string | number | boolean
  record?: T
}

export type TOptionColumnKeys<T> = {
  [key in ItsmAssets]?: {
    [key in keyof T]?: Array<{value: string | number; name: string}>
  }
}

export type TOptionFetchColumnKeysOptions = {
  asyncF: ({
    selector,
  }: {
    selector: Record<string, unknown>
  }) => Promise<ISuperAgentResMultiple<Record<string, unknown>>>
  getOptionContent: (
    entityData: Record<string, any>
  ) => Array<{value: string | number; name: string}>
}

export type TOptionFetchColumnKeys<T> = {
  [key in ItsmAssets]?: {
    [key in keyof T]?: TOptionFetchColumnKeysOptions
  }
}

export type TOptionBMColumnKeysOptions = {
  api: string
  selector?: Record<string, unknown>
  optionContent?: string
}

export type TOptionBMColumnKeys<T> = {
  [key in ItsmAssets]?: {
    [key in keyof T]?: TOptionBMColumnKeysOptions
  }
}

export type TApiServiceInitFetch<T> = {
  [key in ItsmAssets]?: Array<{
    key: keyof T
    asyncF: () => Promise<Record<string, any>[]>
  }>
}
export type TBooleanOptions<T> = {
  [key in ItsmAssets]?: {
    [key in keyof T]?: {[key in string]: {[key in keyof T]?: boolean}}
  }
}

export type TDefaultFields<T> = {
  [key in ItsmAssets]?: Array<keyof T>
}
