import React from 'react'
import {Helmet} from 'react-helmet'

import {AccountDefaultProvider} from '@/sites/account-default/account-default-context/account-default-context'
import {AccountDefaultSteps} from '@/sites/account-default/components/account-default-steps'
import {LogoutButton} from '@/components/logout-button/logout-button'

export const AccountDefault = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <body>
          {`
            <noscript>
              <iframe
                title={'gtm'}
                src="https://www.googletagmanager.com/ns.html?id=GTM-NS68NXV"
                height="0"
                width="0"
                // style="display:none;visibility:hidden"
              ></iframe>
            </noscript>
          `}
        </body>
      </Helmet>

      <LogoutButton />

      <AccountDefaultProvider>
        <AccountDefaultSteps />
      </AccountDefaultProvider>
    </>
  )
}
