import {TUser} from '@/typedef'

export const UNKNOWN_USER = 'unknown'

export const getUserFullName = (data: TUser): string => {
  if (data) {
    if (data.full_name) {
      return data.full_name
    }

    const {name, surname, email, uuid} = data
    return name && surname ? `${name} ${surname}` : email || uuid
  }

  return UNKNOWN_USER
}
