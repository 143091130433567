import {useCallback, useState} from 'react'

import {useDispatch} from 'react-redux'

import {fetchMyAssets} from '@/api/assets-requests'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {IUser} from '@/modules/CMDB/api-types-extended'
import {fetchUsers} from '@/modules/CMDB/api/user-requests'
import {CmdbAsset} from '@/modules/CMDB/api-types/Api'
import {getRefCmdbSpaceId} from '@/modules/ITSM/api/ts-version/spaces-requests'

import {useApiData} from '@/hooks/use-api-data'
import {useGetFiltersData} from '@/components/select-with-fetch/use-get-filters-data'
import {useGetUsersIdentity} from '@/hooks/use-get-users-identity'

export function useGetCmdbAssets() {
  const {run: fetchRefCmdbSpaceId} = useApiData(getRefCmdbSpaceId)
  const {getEntityData: getCmdbUser} = useGetFiltersData<IUser>(fetchUsers)
  const {getEntityData: getUserIdentity} = useGetUsersIdentity()

  const [entityData, setEntityData] = useState<Array<CmdbAsset>>([])

  const dispatch = useDispatch()

  const getEntityData = useCallback(
    async (userId: string) => {
      try {
        setEntityData([])

        const resRefCmdbSpaceId = await fetchRefCmdbSpaceId({})
        const identity = await getUserIdentity(userId)
        const sub_id = identity?.fingerprint.split('.')[0]

        if (!sub_id || !resRefCmdbSpaceId) return

        const cmdbUsers = await getCmdbUser({
          filters: {
            $and: [
              {
                sub_id: {
                  $eq: sub_id,
                },
              },
            ],
          },
        })

        if (!cmdbUsers?.[0]) return

        const {
          body: {result, metadata: metaDataRes},
        } = await fetchMyAssets({
          userId: cmdbUsers[0].id,
          spaceId: resRefCmdbSpaceId.body.result.cmdb_space_id,
        })

        if (result && metaDataRes) {
          setEntityData(result)

          return result
        }
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    },
    [dispatch, fetchRefCmdbSpaceId, getCmdbUser, getUserIdentity]
  )

  return {
    getEntityData,
    entityData,
  }
}
