import globalRoutes from '@/global-routes'

const {
  moduleRoutes: {
    saas: {path: saasModulePath},
  },
} = globalRoutes

const saasRoutes = {
  users: {
    name: 'users',
    path: () => `${saasModulePath()}/users`,
  },
  appList: {
    name: 'appList',
    path: () => `${saasModulePath()}/apps`,
  },
  appManange: {
    name: 'appManange',
    path: (app = ':app?') => `${saasModulePath()}/apps/${app}/users`,
  },
  groupUsers: {
    name: 'groupUsers',
    path: (group = ':group?') => `${saasModulePath()}/groups/${group}/users`,
  },
  apps: {
    name: 'apps',
    path: () => `${saasModulePath()}/settings/apps`,
  },
  systemUsers: {
    name: 'systemUsers',
    path: () => `${saasModulePath()}/settings/system-users`,
  },
  systemUsersDetail: {
    name: 'systemUsers',
    path: (id = ':id?') => `${saasModulePath()}/settings/system-users/${id}`,
  },
  systemNew: {
    name: 'systemNew',
    path: () => `${saasModulePath()}/settings/system-users-new`,
  },
  applicationDiscover: {
    name: 'applicationDiscover',
    path: () => `${saasModulePath()}/settings/application-discover`,
  },
  appDetail: {
    name: 'appDetail',
    path: (id = ':id?') => `${saasModulePath()}/settings/app/${id}`,
  },
  appNew: {
    name: 'appDetailNew',
    path: () => `${saasModulePath()}/settings/app/new`,
  },
  settingsIdentityProvider: {
    name: 'identityProvider',
    path: () => `${saasModulePath()}/settings/identity-provider`,
  },
  settingsNamespaces: {
    name: 'apps',
    path: () => `${saasModulePath()}/settings/namespaces`,
  },
  settingsNamespaceNew: {
    name: 'apps',
    path: () => `${saasModulePath()}/settings/namespace/new`,
  },
  userNew: {
    name: 'userNew',
    path: () => `${saasModulePath()}/user/new`,
  },
  appUserNew: {
    name: 'appUserNew',
    path: (app = ':app?') =>
      `${saasModulePath()}/apps/${app ? app + '/' : '/'}user/new`,
  },
  dashboard: {
    name: 'dashboard',
    path: () => `${saasModulePath()}/dashboard`,
  },
  userDetail: {
    name: 'userDetail',
    path: (id = ':id?') => `${saasModulePath()}/user/${id}`,
  },
  appUserDetail: {
    name: 'appUserDetail',
    path: (app = ':app?', id = ':id?') =>
      `${saasModulePath()}/apps/${app}/user/${id}`,
  },
  groupsList: {
    name: 'groupsList',
    path: () => `${saasModulePath()}/settings/groups`,
  },
  createGroup: {
    name: 'groupsList',
    path: () => `${saasModulePath()}/settings/groups/new`,
  },
  groupsDetail: {
    name: 'groupsList',
    path: (id = ':id?') => `${saasModulePath()}/settings/groups${id}`,
  },
  setup: {
    name: 'setup',
    path: () => `${saasModulePath()}/settings/setup`,
  },
}

export default saasRoutes
