import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {Modules, TRoute} from '@/typedef'

type TInvitationMap = {
  [key in Modules]?: {
    userInvURl: {path: string; type?: TRoute}
    orgInvURl?: {path: string; type?: TRoute}
  }
}

export type TAccountDefaultMap = {
  [key: string]: {
    accountDefURl: string
  }
}

export const INVITATION_MAP: TInvitationMap = {
  [Modules.ITSM]: {
    userInvURl: {
      path: itsmRoutes.userInvitation.path(),
      type: TRoute.ITSM_USER_INVITATION,
    },
    orgInvURl: {
      path: itsmRoutes.channelInvitation.path(),
      type: TRoute.ITSM_ORG_INVITATION,
    },
  },
}
