import React from 'react'

import {SkeletonItem} from '@/components/skeleton'
import {Paper} from '@/components/paper/paper'

import './finance-dashboard-tile-skeleton.scss'

const FinanceDashboardTileSkeleton = () => {
  return (
    <Paper className="finance-dashboard-tile-skeleton">
      <SkeletonItem
        className="finance-dashboard-tile-skeleton__title"
        width={150}
        height={24}
      />
      <SkeletonItem
        className="finance-dashboard-tile-skeleton__total"
        width={100}
        height={30}
      />
      <SkeletonItem width={80} height={20} />
    </Paper>
  )
}

export default FinanceDashboardTileSkeleton
