import {useSelector} from 'react-redux'
import {saveAs} from 'file-saver'

import {exportType, RESOLVE} from '@/constants'
import {useFetchHook} from '@/hooks/useFetch'
import {exportIncidentList} from '@/modules/ITSM/api/exportRequests'
import {
  incidentsApi,
  k_requestsApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {
  selectItsmTableColumns,
  selectItsmTableFilters,
} from '@/modules/ITSM/store/list-table/selectors'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {TAppState} from '@/redux/store'
import {checkIsLoading} from '@/utils/check-is-loading'

const useExportAsset = (asset: ItsmAssets) => {
  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters(state, asset)
  )

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const exportAsset = async (format: string) => {
    const endpoint =
      (asset === ItsmAssets.IncidentsUP || ItsmAssets.Incidents
        ? incidentsApi().getReports(format)
        : k_requestsApi().getReports(format)) +
      '&' +
      RESOLVE

    try {
      handleRequested()
      const res = await exportIncidentList(
        endpoint,
        filters,
        columns.filter(({selected}) => selected).map(({title}) => title)
      )
      if (format === 'xlsx') {
        const file = new Blob([res.body], {
          type: exportType,
        })
        saveAs(file, asset + '.xlsx')
        handleSuccess()
      } else {
        const file = new Blob([res.body], {type: 'text/csv'})
        saveAs(file, asset + '.csv')
        handleSuccess()
      }
    } catch (err) {
      handleFail(err)
    }
  }

  return {exportAsset, isExportLoading: checkIsLoading(status)}
}

export default useExportAsset
