import React from 'react'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {ImportanceIndicator} from '@/components/importance-indicator/importance-indicator'
import IncidentKrequestBtns from '@/modules/ITSM/components/incident-k-request/incident-k-request-btns/incident-k-request-btns'
import {Skeleton} from '@/components/skeleton'

import {checkAccess} from '../../../utils/accessChecks'

import {IncidentHeaderTitleSkeletonView} from './incident-header-title-skeleton-view'
import {IncidentHeaderButtonsSkeletonView} from './incident-header-buttons-skeleton-view'

import './incident-header.scss'

type TProps = {
  backButtonHandler: () => void
  numberId?: string
  priority?: number
  priorityText?: string
  loading?: boolean
  btnVisible?: boolean
  onHoldReason?: string
}

const IncidentHeader: React.FC<TProps> = ({
  backButtonHandler,
  numberId,
  priority,
  priorityText,
  loading,
  btnVisible = true,
  onHoldReason,
}) => {
  const cancellationRight = checkAccess('cancelTicket')
  const closingRight = checkAccess('closeTicket')

  return (
    <>
      <div className="incident-header">
        <Skeleton
          className="incident-header__left-side"
          active={Boolean(loading)}
          view={<IncidentHeaderTitleSkeletonView />}
        >
          <GoBackButton onClick={backButtonHandler} />
          {numberId && (
            <div className="incident-header__number-id">{numberId}</div>
          )}
          {priority && (
            <ImportanceIndicator
              className="incident-header__indicator"
              importance={priority}
              indicatorText={`P${priority}`}
              importanceText={priorityText}
            />
          )}
        </Skeleton>
        {btnVisible && (
          <Skeleton
            className="incident-header__right-side"
            active={Boolean(loading)}
            view={<IncidentHeaderButtonsSkeletonView />}
          >
            <IncidentKrequestBtns
              cancellationRight={cancellationRight}
              closingRight={closingRight}
            />
          </Skeleton>
        )}
      </div>
      {onHoldReason && <h5 className=" mb-20">{`On hold: ${onHoldReason}`}</h5>}
    </>
  )
}

export default IncidentHeader
