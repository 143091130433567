import React from 'react'

import FileViewer from '@/components/file-viewer/file-viewer'

import './preview-modal.scss'
import Modal from '../modal'
import {ModalType} from '../typedef'

type TProps = {
  extension: string
  data?: Blob | MediaSource
  onCancel: () => void
}

export const PreviewModal: React.FC<TProps> = ({extension, data, onCancel}) => {
  const imgExtention = ['svg', 'png', 'jpeg']
  const isImg = imgExtention.includes(extension)

  const url = data && URL.createObjectURL(data)
  return (
    <Modal
      modalType={ModalType.INFO}
      className="preview-modal"
      open={true}
      onCancel={onCancel}
      isFullWidth
    >
      {!isImg ? (
        <div className="file-viewer-wrapper">
          <FileViewer fileType={extension} filePath={url} />
        </div>
      ) : (
        <img alt="attachment-preview" src={url} />
      )}
    </Modal>
  )
}
