import {INCIDENT_TASK_STATES_NAMES} from '../constants'
import {IncidentTaskState} from '../typedf'

export const getCloseStateName = (state_id: IncidentTaskState | undefined) => {
  if (state_id)
    switch (state_id) {
      case IncidentTaskState.Closed_complete:
        return INCIDENT_TASK_STATES_NAMES.Closed_complete

      case IncidentTaskState.Closed_incomplete:
        return INCIDENT_TASK_STATES_NAMES.Closed_incomplete

      case IncidentTaskState.Closed_skipped:
        return INCIDENT_TASK_STATES_NAMES.Closed_skipped

      default:
        break
    }
}
