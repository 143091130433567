import {useCallback, useState} from 'react'

import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {getRefSaasSpaceId} from '@/modules/ITSM/api/ts-version/spaces-requests'
import {fetchSystemUserApps} from '@/modules/SAAS/api/app-requests'
import {SaasApplication} from '@/modules/SAAS/services/openAPI/Api'

import {useApiData} from '@/hooks/use-api-data'
import {useGetRecursiveMultipleData} from '@/modules/SAAS/hooks/use-get-recursive-multiple-data'
import {useGetUsersIdentity} from '@/hooks/use-get-users-identity'

export function useGetSaasAssets() {
  const {getEntityData: getUserIdentity} = useGetUsersIdentity()

  const [saasUserAssets, setEntityData] = useState<Array<SaasApplication>>([])

  const {run: fetchRefSaasSpaceId} = useApiData(getRefSaasSpaceId)

  const {getData: getUserApps} = useGetRecursiveMultipleData<SaasApplication>(
    fetchSystemUserApps
  )

  const dispatch = useDispatch()

  const getSaasUserApps = useCallback(
    async (userId: string) => {
      try {
        setEntityData([])

        const resRefSaasSpaceId = await fetchRefSaasSpaceId({})
        const identity = await getUserIdentity(userId)
        const subId = identity?.fingerprint.split('.')[0]

        if (!subId || !resRefSaasSpaceId) return

        const result = await getUserApps({
          subId,
          spaceId: resRefSaasSpaceId.body.result.saas_space_id,
        })

        if (result) {
          setEntityData(result)

          return result
        }
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    },
    [dispatch, fetchRefSaasSpaceId, getUserApps, getUserIdentity]
  )

  return {
    getSaasUserApps,
    saasUserAssets,
  }
}
