import {call, put, StrictEffect} from 'redux-saga/effects'

import {
  postIdentityChannelInviteFailed,
  postIdentityChannelInviteReq,
  postIdentityChannelInviteSuccess,
  setIdentityExists,
  setUserDefaults,
} from '@/redux/channel-invitation/channel-invitation-actions'
import {setItsmChannel} from '@/redux/entries/entries-actions'
import {fetchIdentityMe} from '@/modules/ITSM/api/identityRequests'
import {getSpaces} from '@/modules/ITSM/api/ts-version/spaces-requests'
import {getChannelName} from '@/components/org-select-dropdown/utils/utils'
import {postIdentityMe} from '@/modules/ITSM/api/userRequests'
import {isApiError} from '@/utils/is-api-error'

export function* postIdentityMeIvitationSaga({
  payload: token,
}: ReturnType<typeof postIdentityChannelInviteReq>): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const {
      body: {Success},
    } = yield call(postIdentityMe, {Authorization: 'Bearer ' + token})

    if (Success) {
      const {
        body: {spaces},
      } = yield call(getSpaces, {Authorization: 'Bearer ' + token})

      yield put(
        setItsmChannel({
          channelName: getChannelName(spaces, Success[0]) || '',
          channelID: Success[0],
        })
      )

      const {
        body: {
          result: {users, user_defaults},
        },
      } = yield call(fetchIdentityMe, {
        Authorization: `Bearer ${token}`,
        'Grpc-Metadata-space': Success[0],
      })

      if (users?.length) {
        yield put(setIdentityExists())
      } else {
        yield put(postIdentityChannelInviteSuccess())
        yield put(setUserDefaults(user_defaults))
      }
    }
  } catch (err) {
    if (isApiError(err)) {
      yield put(postIdentityChannelInviteSuccess())
    } else yield put(postIdentityChannelInviteFailed(err))
  }
}
