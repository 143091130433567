import {RequestStatus, UserRole} from '@/typedef'
import {CmdbNamespace} from '../../api-types/Api'

import {TActionTypes, TAnyAction} from './actionTypes'

type TInitialState = {
  cmdbUserId: string
  cmdbUserNameInChannel: string
  cmdbNamespaces: Array<CmdbNamespace>

  cmdbUserRole: UserRole | string
  reqCMDBIdentityMeStatus: RequestStatus
}

const initialState: TInitialState = {
  cmdbUserId: '',
  cmdbUserNameInChannel: '',
  cmdbNamespaces: [],
  cmdbUserRole: '',
  reqCMDBIdentityMeStatus: RequestStatus.INITIAL,
}

export default function cmdbUser(
  state = initialState,
  action: TAnyAction
): TInitialState {
  switch (action.type) {
    case TActionTypes.RESET_CMDB_USER_STATE: {
      return {
        ...state,
        ...initialState,
      }
    }
    case TActionTypes.FETCH_CMDB_USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case TActionTypes.FETCH_CMDB_USER_FAILED: {
      return {
        ...state,
        cmdbUserId: '',
        cmdbUserNameInChannel: '',
        cmdbNamespaces: [],
        cmdbUserRole: '',
        reqCMDBIdentityMeStatus: RequestStatus.FAILED,
      }
    }

    case TActionTypes.ADD_CMDB_NAMESPACE: {
      return {
        ...state,
        cmdbNamespaces: [...state.cmdbNamespaces, action.payload],
      }
    }

    default:
      return state
  }
}
