import React from 'react'

import {translate} from '@/services/i18n'

const UserDetails = props => {
  const {userDetails} = props

  return (
    <div className="fs-12">
      {userDetails && userDetails.name && (
        <div>{`${translate('name')}: ${userDetails.name}`}</div>
      )}
      {userDetails && userDetails.surname && (
        <div>{`${translate('surname')}: ${userDetails.surname}`}</div>
      )}
      {userDetails && userDetails.email && (
        <div>{`${translate('email')}: ${userDetails.email}`}</div>
      )}
      {userDetails && userDetails.phone && (
        <div>{`${translate('phone')}: ${userDetails.phone}`}</div>
      )}
    </div>
  )
}

export default UserDetails
