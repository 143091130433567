import React from 'react'

import {FormItemProps} from 'antd/es/form/FormItem'
import {NamePath} from 'antd/es/form/interface'

import FormItemCustom from './form-item-custom'

type TNumberKeys<
  T extends Record<string, any> | never,
  A extends Array<keyof T> = []
> = {
  [K in keyof T]: T[K] extends undefined
    ? never
    : T[K] extends Record<string, unknown>
    ? TNumberKeys<Required<T[K]>, [...A, K]>
    : A['length'] extends 0
    ? K
    : [...A, K]
}[keyof T]

interface IFromItemProps<T extends Record<string, any>>
  extends Omit<FormItemProps, 'name'> {
  name: TNumberKeys<Required<T>>
  isGrey?: boolean
}

/**
 * FormItemGeneric to be used when Antd. form is managing state & used to generate api paylaod
 * will validate field names per api types so no api errors caused by 'wrong string' in payload
 * ps:
 *  * had to named FormItemGeneric vs FormItem as autoimport is alway suggesitng to import FormItem from antd
 *  * type assertion on name={name as NamePath} is requried to keep antd happy with FormItemProps => name
 * */

const FormItemGeneric = <T extends Record<string, any>>({
  name,
  ...props
}: IFromItemProps<T>) => {
  return <FormItemCustom name={name as NamePath} {...props} />
}

export default FormItemGeneric
