import {useCallback} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {deleteTimeLog} from '@/modules/ITSM/api/timelogsRequests'
import {checkAccess} from '@/modules/ITSM/utils/accessChecks'

export function useDeleteTimeLog({id}: {id: string}) {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'successfully_deleted',
    errorMessage: 'error',
  })

  enum CancelEndpointes {
    cancelTimelogByServiceDesk = 'cancelTimelogByServiceDesk',
    cancelTimelogByEngineer = 'cancelTimelogByEngineer',
  }

  const canelTimeLogEndpoint = checkAccess('cancelTimelogByServiceDesk')
    ? CancelEndpointes.cancelTimelogByServiceDesk
    : CancelEndpointes.cancelTimelogByEngineer

  const handleDeleteTimeLog = useCallback(async () => {
    try {
      handleRequested()
      const result = await deleteTimeLog(canelTimeLogEndpoint, {
        timelog_uuid: id,
      })

      if (result) {
        handleSuccess()
        return result
      }
      return undefined
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }, [
    canelTimeLogEndpoint,
    handleFail,
    handleRequested,
    handleReset,
    handleSuccess,
    id,
  ])

  return {
    handleDeleteTimeLog,
    deleteStatus: status,
  }
}
