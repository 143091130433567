import React, {useContext} from 'react'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {
  ProductContext,
  TProductContext,
} from '@/modules/ITSM/components/incident-k-request/incident-k-request-detail-form/context/product-context'
import {TSupplierProduct} from '@/modules/ITSM/typedef'
import {productApi} from '@/modules/ITSM/api/generate-itsm-api-url'

type TProps = {
  supplierProdInit?: TSupplierProduct | undefined
  customerProdCode?: string | undefined
  customerProdValue: string
  isDisabled?: boolean
  handleOnChange?: (value?: string) => void
  onClear: () => void
}

const SupplierProductSelect = ({
  supplierProdInit,
  customerProdCode,
  customerProdValue,
  isDisabled,
  handleOnChange,
  onClear,
}: TProps) => {
  const {productCode} = useContext<TProductContext>(ProductContext)

  const selector: {code?: string} = {}
  if (productCode) selector['code'] = productCode
  else if (customerProdCode) selector['code'] = customerProdCode

  return (
    <SelectWithBM
      api={productApi().supplier}
      label={translate('supplier_product')}
      onSelect={handleOnChange}
      disabled={!customerProdValue || isDisabled}
      onClear={onClear}
      name="supplier_product"
      selector={selector}
      initialValue={supplierProdInit ? [supplierProdInit] : []}
      optionContent={(rec: {name: string}) => {
        return rec.name
      }}
      searchKey="name"
      getPopupContainer={(e: HTMLElement) => e.parentNode}
    />
  )
}

export default SupplierProductSelect
