import {TFilterListTable} from '../typedf'

export const getRegexValue = <T extends Record<string, any>>(
  value: TFilterListTable<T>
) => {
  const newValue = {...value}

  for (const key in newValue) {
    if (Object.prototype.hasOwnProperty.call(newValue[key], '$regex')) {
      type TKey = Record<typeof key, {$regex: any}>
      ;(newValue as TKey)[key] = (newValue as TKey)[key]['$regex']
    }
  }
  return newValue
}
