import {createContext, useContext} from 'react'

import once from 'lodash/once'

import {ItsmAssets} from '../../../typedef'

type TProps<T> = {
  getEntityDataInit: () => void
  getEntityData: () => void
  onClose: () => void
  record?: T extends Record<string, any> ? T : Record<string, any>
  resetPagination: () => void
  asset?: ItsmAssets
}

export const createStateContext = once(<T,>() =>
  createContext<TProps<T>>({
    getEntityDataInit: () => new Promise<void>(resolve => resolve()),
    getEntityData: () => new Promise<void>(resolve => resolve()),
    onClose: () => Promise.resolve(),
    resetPagination: () => Promise.resolve(),
  })
)

export const useListTableStateContext = <T,>() =>
  useContext(createStateContext<T>())
