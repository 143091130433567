import React, {Component, createRef} from 'react'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {v4 as uuid} from 'uuid'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {setRefresh} from '@/redux/actions'
import {RequestStatus, Assets} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'

import {postUserGroup} from '../../../api/userGroupRequests'

import {UserGroupsFormNew} from './user-group-form-new'

class UserGroupsFormNewContainer extends Component {
  constructor(props) {
    super(props)
    this.formRef = createRef()
    this.state = {
      loadingSubmit: false,
      btnDisabled: true,
      asset: Assets.userGroupsAsset,
    }
  }

  handleSubmit = async values => {
    const {setToastMessage} = this.props

    try {
      handleMessage(RequestStatus.REQUESTED)
      this.setState({loadingSubmit: true})
      const data = {name: values.name}

      await postUserGroup(uuid(), data)

      handleMessage(RequestStatus.SUCCEEDED)
      this.setState({btnDisabled: true})
      this.props.getEntityData()
      this.props.onClose()
    } catch (err) {
      setToastMessage({message: err})
      handleMessage(RequestStatus.FAILED)
    } finally {
      this.setState({loadingSubmit: false})
    }
  }

  recognizeFieldChange = () => {
    this.setState({
      btnDisabled: !this.formRef.current.getFieldValue('name'),
    })
  }

  onCancel = () => {
    this.props.onClose()
  }

  render() {
    const {loadingSubmit} = this.state
    return (
      <UserGroupsFormNew
        handleSubmit={this.handleSubmit}
        loadingSubmit={loadingSubmit}
        handleCancel={this.onCancel}
      />
    )
  }
}

export default withRouter(
  connect(null, {
    setToastMessage,
    setRefresh,
  })(UserGroupsFormNewContainer)
)
