import {ItsmAssets} from '@/modules/ITSM/typedef'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

const {
  serviceManagementIncidentsNew,
  serviceManagementIncidentsDetail,
  serviceManagementRequestsNew,
  serviceManagementRequestsDetail,
  settingsITSMSupplierProductsDetail,
  settingsITSMCustomerProductsDetail,
  settingsITSMCustomerProductNew,
  settingsITSMSupplierProductNew,
  settingsITSMAnnouncementsDetail,
  settingsITSMAnnouncementNew,
  serviceManagementIncidentsState,
  serviceManagementRequestsState,
} = itsmRoutes

export const entityNewPath: {
  [key in ItsmAssets]?: (id: string) => string
} = {
  [ItsmAssets.IncidentsUP]: (id: string) =>
    serviceManagementIncidentsNew.path(id),
  [ItsmAssets.RequestsUP]: (id: string) =>
    serviceManagementRequestsNew.path(id),
  [ItsmAssets.Incidents]: (id: string) =>
    serviceManagementIncidentsNew.path(id),
  [ItsmAssets.CustomerProducts]: (id: string) =>
    settingsITSMCustomerProductNew.path(id),
  [ItsmAssets.SupplierProducts]: (id: string) =>
    settingsITSMSupplierProductNew.path(id),
  [ItsmAssets.Requests]: (id: string) => serviceManagementRequestsNew.path(id),
  [ItsmAssets.Announcements]: (id: string) =>
    settingsITSMAnnouncementNew.path(id),
}

export const entityDetailPath: {
  [key in ItsmAssets]?: (id: string) => string
} = {
  [ItsmAssets.IncidentsUP]: (id: string, state = 'overview') =>
    serviceManagementIncidentsState.path(id, state),
  [ItsmAssets.RequestsUP]: (id: string, state = 'overview') =>
    serviceManagementRequestsState.path(id, state),
  [ItsmAssets.Incidents]: (id: string) =>
    serviceManagementIncidentsDetail.path(id),
  [ItsmAssets.Requests]: (id: string) =>
    serviceManagementRequestsDetail.path(id),
  [ItsmAssets.CustomerProducts]: (id: string) =>
    settingsITSMCustomerProductsDetail.path(id),
  [ItsmAssets.SupplierProducts]: (id: string) =>
    settingsITSMSupplierProductsDetail.path(id),
  [ItsmAssets.Announcements]: (id: string) =>
    settingsITSMAnnouncementsDetail.path(id),
}
