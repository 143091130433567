import React from 'react'

import {useSelector} from 'react-redux'

import DataTable from '@/components/data-table/data-table'
import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import {TAppState} from '@/redux/store'
import {checkIsLoading} from '@/utils/check-is-loading'
import {RequestStatus, TListArgs} from '@/typedef'

import {
  selectItsmAssetPagination,
  selectItsmTableColumns,
} from '../../store/list-table/selectors'
import {generateActiveColumns} from '../itsm-list-table/utils/generate-active-columns'
import {TPagination} from '../../store/list-table/reducer'
import {ItsmAssets} from '../../typedef'
import {TColumnRender} from '../itsm-list-table/typedf'

type TItmsListTableNoFiltersProps<T> = {
  asset: ItsmAssets
  getRowValue?: (props: TColumnRender<T>) => React.ReactNode
  getEntityData: ({
    passedBookmark,
    resetBookmarks,
    passedFilters,
  }: TListArgs<T>) => Promise<T[] | undefined>
  entityData: T[]
  fetchStatus: RequestStatus
  numberOfRecords: number
  rowClassName?: (record: T) => string
  onRowClick?: (record: T, rowIndex: number | undefined, target?: any) => void
}

const ItsmListTableNoFilters = <T extends Record<string, unknown>>({
  asset,
  getRowValue,
  getEntityData,
  entityData,
  fetchStatus,
  numberOfRecords,
  rowClassName,
  onRowClick,
}: TItmsListTableNoFiltersProps<T>) => {
  const pagination =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []
  return (
    <>
      <DataTable<T>
        data={entityData}
        columns={generateActiveColumns({columns, asset, getRowValue})}
        loading={checkIsLoading(fetchStatus)}
        rowClassName={rowClassName}
        onRowClick={onRowClick}
        customPager={
          <PaginationWithBookmarks<TPagination<T>>
            pagination={pagination}
            numberOfRecords={numberOfRecords}
            fetch={bookmark => {
              getEntityData({
                passedBookmark: bookmark,
              })
            }}
          />
        }
      />
    </>
  )
}

export default ItsmListTableNoFilters
