import React, {useCallback, useEffect, useState} from 'react'

import {EuroCircleOutlined} from '@ant-design/icons'
import {Col, Form, Input, InputNumber, Row} from 'antd'
import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {translate} from '@/services/i18n'
import {RequestStatus} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import {Button} from '@/components/button'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import ItsmListTableNoFilters from '@/modules/ITSM/components/itsm-list-table-no-filters/itsm-list-table-no-filters'
import {useGetDataListTableNoFilters} from '@/modules/ITSM/components/itsm-list-table-no-filters/hooks/use-get-data-list-table-no-filters'
import {apiService} from '@/modules/ITSM/api/api-service'
import {resetItsmTableBookmarks} from '@/modules/ITSM/store/list-table/table-actions'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {Paper, PaperVariant} from '@/components/paper/paper'

import {checkAccess} from '../../../utils/accessChecks'
import {getBalance, postCredit} from '../../../api/creditRequests'

import {TCredit} from './typedf'

import './credit-system.scss'

function CreditSystem() {
  const asset = ItsmAssets.Credits

  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [balance, setBalance] = useState(null)
  const [loadingSubmit, setLoadingSub] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    getEntityData,
    entityData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTableNoFilters<TCredit>({
    asyncF: apiService[asset],
    asset,
  })

  const getAvailBalance = useCallback(async () => {
    try {
      const {
        body: {
          result: {credit_balance},
        },
      } = await getBalance()
      setBalance(credit_balance)
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    }
  }, [dispatch])

  const handleSubmit = async () => {
    const data = await form.validateFields()
    try {
      setLoadingSub(true)
      handleMessage(RequestStatus.REQUESTED)

      await postCredit(data)

      handleMessage(RequestStatus.SUCCEEDED)
      getAvailBalance()
      dispatch(resetItsmTableBookmarks(asset))
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      handleMessage(RequestStatus.FAILED)
    } finally {
      setBtnDisabled(true)
      setLoadingSub(false)
      form.resetFields()
    }
  }

  useEffect(() => {
    getAvailBalance()
  }, [getAvailBalance])

  const recognizeFieldChange = () => {
    setBtnDisabled(
      !form.getFieldValue('description') || !form.getFieldValue('amount')
    )
  }

  return (
    <Paper className="credit-system" variant={PaperVariant.form}>
      <div className="credit-system__top">
        <div className="credit-system__header">
          <EuroCircleOutlined />
          <span>{translate('total_credit')}:</span>
          {balance} &euro;
        </div>
      </div>
      {checkAccess('addCredit') && (
        <Form
          layout="vertical"
          // eslint-disable-next-line
          validateMessages={{required: '${label} is required'}}
          onValuesChange={recognizeFieldChange}
          form={form}
        >
          <Row>
            <Col span={18}>
              <FormItemGeneric<TCredit>
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="description"
                label={translate('description')}
              >
                <Input />
              </FormItemGeneric>
            </Col>
            <Col className="col_margin" span={4}>
              <FormItemGeneric<TCredit>
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="amount"
                label={translate('amount_in_eur')}
                required
              >
                <InputNumber step={0.5} />
              </FormItemGeneric>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                title={translate('save')}
                type="primary"
                size="large"
                loading={loadingSubmit}
                onClick={handleSubmit}
                disabled={btnDisabled}
              />
            </Col>
          </Row>
        </Form>
      )}

      <ItsmListTableNoFilters<TCredit>
        {...{
          getEntityData,
          entityData,
          numberOfRecords,
          fetchStatus,
          asset,
        }}
      />
    </Paper>
  )
}

export default CreditSystem
