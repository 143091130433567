import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TNotification} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'

import NotificationsDrawer from './notifications-drawer'

const Notifications = () => (
  <ItsmListTable<TNotification>
    asset={ItsmAssets.Notifications}
    newComponent={<NotificationsDrawer />}
    detailComponent={<NotificationsDrawer />}
    drawerTitleNew={translate('notification')}
    rowKey={'uuid'}
  />
)

export default Notifications
