export enum Type {
  category = 'category',
  datetime = 'datetime',
  numeric = 'numeric',
}

export enum Curve {
  smooth = 'smooth',
  straight = 'straight',
  stepline = 'stepline',
}

export enum Align {
  'left' = 'left',
  'center' = 'center',
  'right' = 'right',
}

export enum VerticalAlign {
  'top' = 'top',
  'middle' = 'middle',
  'bottom' = 'bottom',
}

export enum Position {
  topRight = 'topRight',
  topLeft = 'topLeft',
  bottomRight = 'bottomRight',
  bottomLeft = 'bottomLeft',
}

export enum ChartType {
  line = 'line',
  area = 'area',
  bar = 'bar',
}

export enum StackType {
  normal = 'normal',
  fullHeight = '100%',
}
