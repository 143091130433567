import React from 'react'

import {LogoutOutlined} from '@ant-design/icons'
import {Button} from '../button/button'

import {useCustomAuth} from '@/hooks/use-custom-auth'

import {IconPosition} from '../button/constants'

export const LogoutButton = () => {
  const {signOutRedirect} = useCustomAuth()

  return (
    <div className="flex flex--justifyEnd">
      <Button
        iconPosition={IconPosition.RIGHT}
        icon={<LogoutOutlined />}
        onClick={signOutRedirect}
        className={'logout-button-wrapper'}
      />
    </div>
  )
}
