import React from 'react'

import {Select} from 'antd'

import FormItemCustom from '@/components/form/form-item-custom'

const {Option} = Select

export const ExportDropdown = ({
  value,
  onChange,
}: {
  value: string
  onChange: (format: string) => void
}) => (
  <FormItemCustom>
    <Select onChange={onChange} value={value} className="select_format">
      <Option value="xlsx">xlsx</Option>
      <Option value="csv">csv</Option>
    </Select>
  </FormItemCustom>
)
