import {getStringBetween} from '@/utils/get-string-between'

export const findChannelNameInExistChannelError = (err: unknown) => {
  return err
    ? getStringBetween(
        JSON.parse(JSON.stringify(err))?.response?.text || '',
        'channel_name: ',
        ' is already taken'
      )
    : ''
}
