import React from 'react'

import {Col, Row} from 'antd'

import {translate} from '@/services/i18n'

import LocationDetails from '../location-details/location-details'
import {TLocation} from '../../typedef'

import './location-info.scss'

const LocationInfo = ({location}: {location: TLocation}) => {
  return (
    <Row>
      <Col className="location-info" span={24}>
        <div className="location-info__title">{translate('location')}</div>
        <LocationDetails location={location} />
      </Col>
    </Row>
  )
}

export default LocationInfo
