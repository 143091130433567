import React from 'react'

import moment from 'moment'

import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {DATE_FORMAT} from '@/constants'

import {parseToLocaleString} from '../utils/parse-to-local-string'
import {TBillingItem} from '../../finance-dashboard/types'

export const BillingReportRowValue = ({
  title,
  value,
  record,
}: TColumnRender<TBillingItem>) => {
  switch (title) {
    case 'interval_start':
      return (
        <div>
          {moment(value as string)
            .utc()
            .format(DATE_FORMAT)}
        </div>
      )
    case 'interval_end':
      return (
        <div>
          {moment(value as string)
            .utc()
            .format(DATE_FORMAT)}
        </div>
      )
    case 'month': {
      return (
        <div>
          {
            moment.months()[
              moment(record?.interval_end)
                .utc()
                .get('month')
            ]
          }
        </div>
      )
    }

    case 'expenses': {
      return (
        <div className="nowrap">
          {`${parseToLocaleString(Number(record?.expenses))} EUR`}
        </div>
      )
    }

    case 'travel_time': {
      return (
        <div className="nowrap">
          {`${(Number(record?.travel_time) !== 0
            ? Math.round(Number(record?.travel_time) / 3600)
            : 0
          ).toLocaleString()} h`}
        </div>
      )
    }

    case 'working_time': {
      return (
        <div className="nowrap">
          {`${record?.work_time &&
            (Number(record?.work_time) !== 0
              ? Math.round(Number(record?.work_time) / 3600)
              : 0
            ).toLocaleString(undefined)} h`}
        </div>
      )
    }

    case 'revenue': {
      return (
        <div className="nowrap">
          {`${parseToLocaleString(Number(record?.revenue))} EUR`}
        </div>
      )
    }

    case 'cost': {
      return (
        <div className="nowrap">
          {`${parseToLocaleString(Number(record?.cost))} EUR`}
        </div>
      )
    }

    default:
      return <div>{value}</div>
  }
}
