import React, {useContext, useState} from 'react'

import {Col, Form as AntdForm, Input, Row} from 'antd'

import {Form} from '@/components/form/form/form'
import {TCCreateAccount} from '@/components/accountForm/types'
import ProfileIdentityWrapper from '@/modules/ITSM/components/profile-identity-wrapper/profile-identity-wrapper'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {translate} from '@/services/i18n'

import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {getBEndToken} from '@/api/token-request'
import {postOrganization} from '@/modules/ITSM/api/ts-version/post-organization'
import {postIdentityMe} from '@/modules/ITSM/api/userRequests'
import {useApiData} from '@/hooks/use-api-data'
import {postAccountTsWithHeaders} from '@/modules/ITSM/api/ts-version/post-account-ts'
import {checkIsLoading} from '@/utils/check-is-loading'
import {createAccountPayload} from '@/sites/accept-org-invite/utils/create-account-payload'
import {
  InviteOrgContext,
  setInviteOrgChannels,
  setInviteOrgStep,
  setInviteOrgToken,
} from '@/sites/accept-org-invite/context/org-invite-context'
import {
  InvitationStep,
  TCreatorAccOrgForChannelInvite,
} from '@/sites/accept-org-invite/typedef'
import OrgInviteErrorModal from '@/sites/accept-org-invite/error-modal/org-invite-error-modal'

import './create-account-and-organization.scss'

export const CreateAccountAndOrganization = () => {
  const [form] = AntdForm.useForm()
  const [reloadCount, setReloadCount] = useState(0)
  const [hasError, setHasError] = useState(false)

  const {state, dispatch} = useContext(InviteOrgContext)

  const {run: postAccount, status: postAccountStatus} = useApiData(
    postAccountTsWithHeaders
  )

  const {run: getBackEndToken, status: getBackEndTokenStatus} = useApiData(
    getBEndToken
  )

  const {run: postIdentity, status: postIdentityMeStatus} = useApiData(
    postIdentityMe
  )

  const {run: createOrganization, status: postOrganizationStatus} = useApiData(
    postOrganization
  )

  const setAccountChannelField = (companyName: string) => {
    const {account} = form.getFieldsValue()

    if (!account && companyName) {
      form.setFieldsValue({account: `${companyName}-account`})
      form.setFieldsValue({org_name: `${companyName}-organization`})
    }
  }

  const onSubmit = async (values: TCreatorAccOrgForChannelInvite) => {
    const result = await postAccount({
      payload: createAccountPayload(values.account),
      headers: createAuthorizationHeader(state.accessToken),
    })

    if (!result?.body.id) return setHasError(true)

    const tokenWithScopeAccResponse = await getBackEndToken({
      scope: 'acc',
      id: result?.body.id,
    })
    const noOrgAccessToken = tokenWithScopeAccResponse?.body.access_token

    if (!noOrgAccessToken) return setHasError(true)

    const newOrgResponse = await createOrganization({
      data: {name: values.org_name},
      accId: result.body.id,
      headers: createAuthorizationHeader(noOrgAccessToken),
    })

    if (!newOrgResponse?.body.id) return setHasError(true)

    const tokenWithScopeOrgResponse = await getBackEndToken({
      scope: 'org',
      id: newOrgResponse?.body.id,
    })
    const accessToken = tokenWithScopeOrgResponse?.body.access_token

    if (!accessToken) return setHasError(true)

    dispatch(setInviteOrgToken(accessToken))

    const identityMeResponse = await postIdentity(
      createAuthorizationHeader(accessToken)
    )
    const invitedChannels = identityMeResponse?.body.Success

    if (!invitedChannels || invitedChannels.length === 0) {
      return setHasError(true)
    }

    dispatch(setInviteOrgChannels(invitedChannels))
    dispatch(setInviteOrgStep(InvitationStep.CREATE_IDENTITY))

    return
  }

  return (
    <ProfileIdentityWrapper
      header={translate('create_new_account')}
      isFullWidth
      className="create-account-and-organization__profile-wrapper"
    >
      <OrgInviteErrorModal
        handleReload={() => setHasError(false)}
        visible={hasError}
        setReloadCount={setReloadCount}
        reloadCount={reloadCount}
      />
      <Form<TCreatorAccOrgForChannelInvite>
        className="create-account-and-organization"
        form={form}
        onSubmit={onSubmit}
        saveButtonDisabled
        isLoading={checkIsLoading([
          postAccountStatus,
          getBackEndTokenStatus,
          postIdentityMeStatus,
          postOrganizationStatus,
        ])}
      >
        <Row gutter={32}>
          <Col span={12}>
            <FormItemGeneric<TCCreateAccount>
              name="companyName"
              label={translate('company')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onBlur={e => setAccountChannelField(e.target.value)} />
            </FormItemGeneric>
          </Col>
          <Col span={12}>
            <FormItemGeneric<TCCreateAccount>
              name="account"
              label={translate('account')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
          <Col span={12}>
            <FormItemGeneric<TCCreateAccount>
              name="org_name"
              label={translate('organization')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </FormItemGeneric>
          </Col>
        </Row>
      </Form>
    </ProfileIdentityWrapper>
  )
}
