import {TRequest} from '@/modules/ITSM/sites/dashboard-user-portal/typedef'

import {PricingType} from '../../time-session-modal/utils/constants'
import {TIncident} from '../typedf'

export const isTimelogDisabled = (data: TIncident | TRequest) => {
  const {
    customer_product: {pricing_type: customerPricingType} = {
      pricing_type: null,
    },
    supplier_product: {pricing_type: supplierPricingType} = {
      pricing_type: null,
    },
  } = data || {}

  return (
    customerPricingType === PricingType.monthly &&
    (!supplierPricingType || supplierPricingType === PricingType.monthly)
  )
}
