import moment from 'moment'

import {TSupplierProduct} from '@/modules/ITSM/typedef'
import {SlaTypes} from '@/modules/ITSM/utils/Constants'

import {PricingType} from '../../CustomerProducts/utils/constants'

import {TFormValues} from './types'

export const parseFetchedData = (values?: TSupplierProduct) => {
  const {
    name,
    code,
    org_display_name,
    org_name,
    pricing_type,
    sla_type,
    location,
    active_from,
    pricing_policy: {
      rate,
      primary_hour_rate,
      currency,
      pricing_unit,
      additional_hour_rate,
      additional_pricing_unit,
      primary_hours_number,
      travel_fix_rate,
      travel_hour_rate,
      travel_pricing_unit,
      travel_distance_unit_rate,
    } = {
      rate: 0,
      primary_hour_rate: 0,
      currency: '',
      pricing_unit: 0,
      additional_hour_rate: 0,
      additional_pricing_unit: 0,
      primary_hours_number: 0,
      travel_fix_rate: 0,
      travel_hour_rate: 0,
      travel_pricing_unit: 0,
      travel_distance_unit_rate: 0,
    },
  } = values || {
    name: '',
    code: '',
    org_name: '',
    org_display_name: '',
    pricing_type: '',
    sla_type: SlaTypes.P1,
  }

  const newData: TFormValues = {
    name,
    code,
    org_display_name,
    org_name,
    pricing_type: pricing_type
      ? PricingType[pricing_type]
      : PricingType.per_visit,
    sla_type,
    rate,
    primary_hour_rate,
    currency,
    location: `${location?.country || ''} ${location?.city ||
      ''} ${location?.street || ''}`,
    active_from: active_from ? moment(active_from) : moment(),
    pricing_unit: pricing_unit ? Math.round(pricing_unit / 60) : 0,
    additional_pricing_unit: additional_pricing_unit
      ? Math.round(additional_pricing_unit / 60)
      : 0,
    additional_hour_rate,
    primary_hours_number,
    travel_fix_rate,
    travel_hour_rate,
    travel_pricing_unit,
    travel_distance_unit_rate,
  }

  return newData
}
