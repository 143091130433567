import React from 'react'

import {translate} from '@/services/i18n'

import ErrorPage from './error-page/error-page'

const NotFound = () => {
  return (
    <ErrorPage
      mainErrorMsg={translate('error_404')}
      subErrorMsg={translate('page_not_found')}
    />
  )
}

export default NotFound
