import moment from 'moment'

import {TSlaValues} from '../../incident/incident-reducer'

export const refreshProgressBar = (slaValues: TSlaValues) => {
  const {startTime, endTime, elapsedTime} = slaValues

  if (startTime && endTime && elapsedTime !== undefined) {
    return Math.round(
      (elapsedTime * 100) / moment(endTime).diff(moment(startTime), 'seconds')
    )
  } else {
    return 0
  }
}
