import React, {useEffect, useState} from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table/itsm-list-table'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {useDownloadPreview} from '@/modules/ITSM/hooks/use-download-preview'
import {PreviewModal} from '@/components/modal/preview-modal/preview-modal'
import {getNameAndExtension} from '@/utils/get-name-and-extension'
import {apiService} from '@/modules/ITSM/api/api-service'
import {useGetDataListTable} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/hooks/use-get-data-list-table'
import {getExpensesSingleton} from '@/modules/ITSM/api/expensesRequest'
import {checkExtension} from '@/components/modal/preview-modal/utils'

import {ExpensesRowValue} from './expenses-row-value'
import {TExpense} from './typedef'
import {useDeleteExpense} from './hooks/use-approve-delete-expense'
import useGetQuickFilters from './hooks/use-get-quick-filters'

const Expenses = () => {
  const asset = ItsmAssets.Expenses
  const {
    getEntityData,
    entityData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTable<TExpense>(apiService[asset], asset)

  const {
    attachmentAction,
    previewAttachment,
    setPreviewAttachment,
  } = useDownloadPreview()

  const [approvalAccess, setApprovalAccess] = useState(false)

  const {extension: currentExtension} = getNameAndExtension(
    previewAttachment && previewAttachment.file_name
  )

  const {approveDeleteExpense, approveDeleteExpenseStatus} = useDeleteExpense(
    getEntityData,
    asset
  )

  const {getQuickFilters, setQuickFilters} = useGetQuickFilters({
    asset,
  })

  useEffect(() => {
    ;(async () => {
      if (!approvalAccess) {
        const res = await getExpensesSingleton()
        setApprovalAccess(res.body.result.value.expenses.approval_needed)
      }
    })()
  }, [approvalAccess])

  const handleGetRowValue = (props: TColumnRender<TExpense>) => (
    <ExpensesRowValue
      attachmentAction={attachmentAction}
      approveDeleteExpense={approveDeleteExpense}
      approveDeleteExpenseStatus={approveDeleteExpenseStatus}
      approvalAccess={approvalAccess}
      {...props}
    />
  )

  return (
    <>
      {previewAttachment?.file_type && previewAttachment?.data && (
        <PreviewModal
          extension={currentExtension ? checkExtension(currentExtension) : ''}
          data={previewAttachment.data}
          onCancel={() => setPreviewAttachment(undefined)}
        />
      )}
      <ItsmListTable<TExpense>
        asset={ItsmAssets.Expenses}
        getRowValue={handleGetRowValue}
        getEntityData={getEntityData}
        entityData={entityData}
        numberOfRecords={numberOfRecords}
        fetchStatus={fetchStatus}
        getQuickFilters={getQuickFilters}
        setQuickFilters={setQuickFilters}
      />
    </>
  )
}

export default Expenses
