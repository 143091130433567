import {ThemeConfig} from 'antd'

export const themeTeliaCarrie: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
    colorPrimary: '#990ae3',
    colorTextBase: 'rgb(58, 53, 65)',
  },
  components: {
    Layout: {
      colorBgHeader: '#380354',
    },
  },
}
