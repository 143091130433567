export const SET_REDIRECT = 'SET_REDIRECT'
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const SET_REFRESH = 'SET_REFRESH'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_MY_ACCESS = 'SET_MY_ACCESS'
export const SET_PRIORITY_VALUES = 'SET_PRIORITY_VALUES'
export const SET_WIDGETS = 'SET_WIDGETS'
export const RESET_SETTINGS_STATE = 'RESET_SETTINGS_STATE'

export const SET_AUTH_ORG_TOKEN = 'SET_AUTH_ORG_TOKEN'

export const FETCH_ORG_TOKEN_REQUESTED = 'FETCH_ORG_TOKEN_REQUESTED'
export const FETCH_ORG_TOKEN_SUCCESS = 'FETCH_ORG_TOKEN_SUCCESS'

export const FETCH_PROFILE_REQUESTED = 'FETCH_PROFILE_REQUESTED'
export const FETCH_PROFILE_SUCCESS_INIT = 'FETCH_PROFILE_SUCCESS_INIT'
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED'
export const SAVE_MODULE_PROFILE = 'SAVE_MODULE_PROFILE'

export const FETCH_IDENTITY_ME_REQUESTED = 'FETCH_IDENTITY_ME_REQUESTED'
export const FETCH_IDENTITY_ME_SUCCESS = 'FETCH_IDENTITY_ME_SUCCESS'
export const FETCH_IDENTITY_ME_FAILED = 'FETCH_IDENTITY_ME_FAILED'
export const RESET_IDENTITY_ME_STATUS = 'RESET_IDENTITY_ME_STATUS'
export const RESET_MODULE_MOUNT_STATUS = 'RESET_MODULE_MOUNT_STATUS'

export const FETCH_CMDB_IDENTITY_ME_REQUESTED =
  'FETCH_CMDB_IDENTITY_ME_REQUESTED'
export const FETCH_CMDB_IDENTITY_ME_SUCCESS = 'FETCH_CMDB_IDENTITY_ME_SUCCESS'
export const FETCH_CMDB_IDENTITY_ME_FAILED = 'FETCH_CMDB_IDENTITY_ME_FAILED'

export const FETCH_SAAS_IDENTITY_ME_REQUESTED =
  'FETCH_SAAS_IDENTITY_ME_REQUESTED'
export const FETCH_SAAS_IDENTITY_ME_SUCCESS = 'FETCH_SAAS_IDENTITY_ME_SUCCESS'
export const FETCH_SAAS_IDENTITY_ME_FAILED = 'FETCH_SAAS_IDENTITY_ME_FAILED'

export const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED'

export const FETCH_CMDB_USER_REF_SUCCESS = 'FETCH_CMDB_USER_REF_SUCCESS'
export const FETCH_CMDB_USER_REF_FAILED = 'FETCH_CMDB_USER_REF_FAILED'

export const REFRESH_GET_USER_STATUS = 'REFRESH_GET_USER_STATUS'

export const SET_ENTRIES_ACCOUNTS = 'SET_ENTRIES_ACCOUNTS'
export const SET_ENTRIES_ORG = 'SET_ENTRIES_ORG'
export const SET_ENTRIES_ITSM_CHANNEL = 'SET_ENTRIES_ITSM_CHANNEL'
export const SET_ENTRIES_CMDB_NAME_SPACE_ID = 'SET_ENTRIES_CMDB_NAME_SPACE_ID'
export const SET_ENTRIES_SAAS_NAME_SPACE_ID = 'SET_ENTRIES_SAAS_NAME_SPACE_ID'
export const RESET_ENTRIES_REDUCER = 'RESET_ENTRIES_REDUCER'

export const SET_MODULE_NAME = 'SET_MODULE_NAME'
export const RESET_USER_STATE = 'RESET_USER_STATE'
export const RESET_USER_NAME_ID = 'RESET_USER_NAME_ID'

export const FETCH_CREDIT_ACCESS = 'FETCH_CREDIT_ACCESS'
export const FETCH_CREDIT_ACCESS_SUCCESS = 'FETCH_CREDIT_ACCESS_SUCCESS'
export const FETCH_CREDIT_ACCESS_FAILED = 'FETCH_CREDIT_ACCESS_FAILED'
export const FETCH_CREDIT_ACCESS_REQUESTED = 'FETCH_CREDIT_ACCESS_REQUESTED'
export const FETCH_MY_ACCESS_SUCCESS = 'FETCH_MY_ACCESS_SUCCESS'
export const FETCH_MY_ACCESS_FAILED = 'FETCH_MY_ACCESS_FAILED'
export const FETCH_MY_ACCESS_REQUESTED = 'FETCH_MY_ACCESS_REQUESTED'
export const FETCH_VERSION_SUCCESS = 'FETCH_VERSION_SUCCESS'
export const FETCH_VERSION_FAILED = 'FETCH_VERSION_FAILED'
export const FETCH_VERSION_REQUESTED = 'FETCH_VERSION_REQUESTED'
export const SET_INVIT_ORG_ID_GRANT = 'SET_INVIT_ORG_ID_GRANT'
export const SET_CHANNEL_INVIT_ORG_ID_GRANT = 'SET_CHANNEL_INVIT_ORG_ID_GRANT'

export const SET_POST_GRANT_REQUESTED = 'SET_POST_GRANT_REQUESTED'
export const SET_POST_GRANT_SUCCEEDED = 'SET_POST_GRANT_SUCCEEDED'
export const SET_POST_GRANT_FAILED = 'SET_POST_GRANT_FAILED'

export const REFRESH_INVIT_ORG_TKN_REQUESTED = 'REFRESH_INVIT_ORG_TKN_REQUESTED'

export const POST_IDENTITY_REQUESTED = 'POST_IDENTITY_REQUESTED'
export const POST_IDENTITY_SUCCEEDED = 'POST_IDENTITY_SUCCEEDED'
export const POST_IDENTITY_FAILED = 'POST_IDENTITY_FAILED'

export const POST_INVIT_CHANNEL_ID_GRANT = 'POST_INVIT_CHANNEL_ID_GRANT'

export const GET_ACCOUNTS_REQUESTED = 'GET_ACCOUNTS_REQUESTED'
export const GET_ACCOUNT_SUCCEEDED = 'GET_ACCOUNT_SUCCEEDED'
export const GET_ACCOUNTS_SUCCEEDED = 'GET_ACCOUNTS_SUCCEEDED'
export const GET_ACCOUNTS_FAILED = 'GET_ACCOUNTS_FAILED'

export const GET_ORG_ACCOUNTS_REQUESTED = 'GET_ORG_ACCOUNTS_REQUESTED'
export const GET_ORG_ACCOUNTS_SUCCEEDED = 'GET_ORG_ACCOUNTS_SUCCEEDED'
export const GET_ORG_ACCOUNTS_FAILED = 'GET_ORG_ACCOUNTS_FAILED'

export const SET_CHANNEL_STEP = 'SET_CHANNEL_STEP'

export const ACCEPT_INVITE = 'ACCEPT_INVITE'
export const SET_USER_INVITE_DEFAULTS = 'SET_USER_INVITE_DEFAULTS'

export const ACCEPT_INVITE_REQUESTED = 'ACCEPT_INVITE_REQUESTED'
export const ACCEPT_INVITE_SUCCEEDED = 'ACCEPT_INVITE_SUCCEEDED'
export const ACCEPT_INVITE_FAILED = 'ACCEPT_INVITE_FAILED'
export const SET_INVITE_MODAL_MESSAGE = 'SET_INVITE_MODAL_MESSAGE'

export const INVITATION_ALREADY_ACCEPTED = 'INVITATION_ALREADY_ACCEPTED'
export const INVITATION_SET_REDIRECT = 'INVITATION_SET_REDIRECT'

export const POST_ORGANIZATION_REQUESTED = 'POST_ORGANIZATION_REQUESTED'
export const POST_ORGANIZATION_SUCCEEDED = 'POST_ORGANIZATION_SUCCEEDED'
export const POST_ORGANIZATION_FAILED = 'POST_ORGANIZATION_FAILED'
export const POST_ORGANIZATION_RESET = 'POST_ORGANIZATION_RESET'

export const SET_TIME_SESSION_MODAL = 'SET_TIME_SESSION_MODAL'
export const CLEAR_UI_MODAL_MESSAGE = 'CLEAR_UI_MODAL_MESSAGE'

export const SET_REDIRECTION = 'SET_REDIRECTION'
export const RESET_UI_STATE = 'RESET_UI_STATE'

export const SET_EMPTY_SPACE = 'SET_EMPTY_SPACE'

export const SET_REDIRECT_TO_LIST = 'SET_REDIRECT_TO_LIST'

export const REFRESH_IDENTITY_ADD_ME = 'REFRESH_IDENTITY_ADD_ME'
export const SET_HISTORY_BLOCK = 'SET_HISTORY_BLOCK'

export const SET_FETCH_GENERAL_PROFILE_SUCCESS =
  'SET_FETCH_GENERAL_PROFILE_SUCCESS'
export const SET_FETCH_GENERAL_PROFILE_REQUESTED =
  'SET_FETCH_GENERAL_PROFILE_REQUESTED'
export const SET_FETCH_GENERAL_PROFILE_FAILED =
  'SET_FETCH_GENERAL_PROFILE_FAILED'
export const RESET_GENERAL_PROFILE = 'RESET_GENERAL_PROFILE'

export const FETCH_ORG_TOKEN_FAILED = 'FETCH_ORG_TOKEN_FAILED'
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'

export const SET_COMPANY_TYPES = 'SET_COMPANY_TYPES'

export const SET_AUTH_FAILED = 'SET_AUTH_FAILED'

export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE'
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE'
export const CLEAR_ERROR_MESSAGES = 'CLEAR_ERROR_MESSAGES'

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_BACK_END_TOKEN_REQUESTED = 'SET_BACK_END_TOKEN_REQUESTED'
export const SET_BACK_END_TOKEN_SUCCESS = 'SET_BACK_END_TOKEN_SUCCESS'
export const SET_BACK_END_TOKEN_FAILED = 'SET_BACK_END_TOKEN_FAILED'

export const REFRESH_ORG_TOKEN_REQUESTED = 'REFRESH_ORG_TOKEN_REQUESTED'
export const REFRESH_ORG_TOKEN_SUCCESS = 'REFRESH_ORG_TOKEN_SUCCESS'
export const REFRESH_ORG_TOKEN_FAILED = 'REFRESH_ORG_TOKEN_FAILED'
export const REFRESH_ORG_TOKEN_RESET = 'REFRESH_ORG_TOKEN_RESET'

export const INITIATE_LOGIN = 'INITIATE_LOGIN'
