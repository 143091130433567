import * as ActionTypes from '../actionTypes'

export const getProfile = (orgQuery, channelQuery) => ({
  type: ActionTypes.FETCH_PROFILE_REQUESTED,
  payload: {orgQuery, channelQuery},
})

export const setOrgToken = token => {
  return {
    type: ActionTypes.SET_AUTH_ORG_TOKEN,
    payload: token,
  }
}

export const getOrgToken = () => ({
  type: ActionTypes.FETCH_ORG_TOKEN_REQUESTED,
})

export const getIdentityMe = (onSelect = false) => ({
  type: ActionTypes.FETCH_IDENTITY_ME_REQUESTED,
  payload: onSelect,
})

export const getIdentityMeSuccess = id => {
  return {
    type: ActionTypes.FETCH_IDENTITY_ME_SUCCESS,
    payload: id,
  }
}

export const fetchIdentityMeFailed = err => ({
  type: ActionTypes.FETCH_IDENTITY_ME_FAILED,
  payload: err,
})

export const getUser = (onSelect = false) => ({
  type: ActionTypes.FETCH_USER_REQUESTED,
  payload: onSelect,
})

export const getUserSuccess = user => {
  return {
    type: ActionTypes.FETCH_USER_SUCCESS,
    payload: user,
  }
}

export const getCmdbUserSuccess = user => {
  return {
    type: ActionTypes.FETCH_CMDB_USER_REF_SUCCESS,
    payload: user,
  }
}

export const getCmdbUserFailed = err => {
  return {
    type: ActionTypes.FETCH_CMDB_USER_REF_FAILED,
    payload: err,
  }
}

export const getUserFailed = err => ({
  type: ActionTypes.FETCH_USER_FAILED,
  payload: err,
})

export const setModuleName = name => {
  return {
    type: ActionTypes.SET_MODULE_NAME,
    payload: name,
  }
}

export const setInitialStateUser = () => {
  return {
    type: ActionTypes.RESET_USER_STATE,
  }
}

export const resetUserIdName = () => {
  return {
    type: ActionTypes.RESET_USER_NAME_ID,
  }
}

export const refreshBETokenReq = payload => ({
  type: ActionTypes.REFRESH_ORG_TOKEN_REQUESTED,
  payload,
})

export const refreshOrgTokenSuccess = () => ({
  type: ActionTypes.REFRESH_ORG_TOKEN_SUCCESS,
})

export const refreshResetStatus = () => ({
  type: ActionTypes.REFRESH_ORG_TOKEN_RESET,
})

export const refreshOrgTokenFailed = error => ({
  type: ActionTypes.REFRESH_ORG_TOKEN_FAILED,
  payload: error,
})

export const setEmptySpace = isEmpty => ({
  type: ActionTypes.SET_EMPTY_SPACE,
  payload: isEmpty,
})

export const resetIdentityMeReqStatus = () => ({
  type: ActionTypes.RESET_IDENTITY_ME_STATUS,
})

export const setUserInviteDefaults = user_defaults => ({
  type: ActionTypes.SET_USER_INVITE_DEFAULTS,
  payload: user_defaults,
})

export const setAccessToken = payload => ({
  type: ActionTypes.SET_ACCESS_TOKEN,
  payload,
})

export const getBckEndToken = () => ({
  type: ActionTypes.SET_BACK_END_TOKEN_REQUESTED,
})

export const setBETokenSuccess = payload => ({
  type: ActionTypes.SET_BACK_END_TOKEN_SUCCESS,
  payload,
})

export const setBETokenFailed = error => ({
  type: ActionTypes.SET_BACK_END_TOKEN_FAILED,
  payload: error,
})

export const setAuthFailed = isFailed => ({
  type: ActionTypes.SET_AUTH_FAILED,
  payload: isFailed,
})

export const initiateLogin = () => ({
  type: ActionTypes.INITIATE_LOGIN,
})
