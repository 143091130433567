import React, {useState} from 'react'

import {Col, Form, Modal, Row} from 'antd'
import {useDispatch} from 'react-redux'

import ErrorPage from '@/components/error/error-page/error-page'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import {setRefresh} from '@/redux/actions'
import {translate} from '@/services/i18n'
import {RefreshType} from '@/typedef'
import {Button} from '@/components/button'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {Select, Option} from '@/components/select/select'
import FormItemCustom from '@/components/form/form-item-custom'

import {patchGrant} from '../../api/accountRequests'

const EditGrant = ({account, record, onCancel, visible, grantLevels}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const {getFieldValue} = form

  const handleEdit = async e => {
    e.preventDefault()
    const values = await form.validateFields()
    try {
      setLoading(true)
      await patchGrant(account, record.id, values.grantLevel)
      onCancel()
      dispatch(setRefresh(true, RefreshType.Subs))
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      footer={''}
      open={visible}
      onCancel={handleCancel}
      closable={true}
      centered={true}
      destroyOnClose={true}
      bodyStyle={{padding: '20px 20px 0 20px'}}
      title={translate('permission_level')}
    >
      <SentryErrorBoundary
        fallback={({resetError}) => (
          <ErrorPage
            resolvers={[resetError, onCancel]}
            buttonTitle={translate('close')}
            noRedirect={true}
          />
        )}
      >
        <Form
          layout="vertical"
          onSubmit={handleEdit}
          form={form}
          // eslint-disable-next-line
          validateMessages={{required: '${label} is required'}}
        >
          {() => (
            <>
              <Col>
                <FormItemCustom
                  name="grantLevel"
                  initialValue={record?.grantLevel}
                  rules={[
                    {
                      required: true,
                      message: translate('select_grantLevel'),
                    },
                  ]}
                >
                  <Select className="select_required">
                    {grantLevels.map(gL => (
                      <Option value={gL} key={gL}>
                        {translate(gL)}
                      </Option>
                    ))}
                  </Select>
                </FormItemCustom>
              </Col>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      title={translate('save')}
                      className="button-bg-1-only"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                      onClick={handleEdit}
                      disabled={
                        getFieldValue('grantLevel') &&
                        getFieldValue('grantLevel') === record.grantLevel
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </SentryErrorBoundary>
    </Modal>
  )
}

export default EditGrant
