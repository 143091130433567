import React from 'react'

import ItsmListTable from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ItsmAssets, TSupplierProduct} from '@/modules/ITSM/typedef'

const SupplierProducts = () => (
  <ItsmListTable<TSupplierProduct>
    asset={ItsmAssets.SupplierProducts}
    rowKey={'uuid'}
  />
)

export default SupplierProducts
