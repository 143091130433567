export const convertTime = (sec: number) => {
  const secondsInMinute = 60
  const secondsInHour = secondsInMinute * 60
  const secondsInDay = secondsInHour * 24
  const days: number = Math.floor(sec / secondsInDay)
  const hours: number = Math.floor((sec - days * secondsInDay) / secondsInHour)
  const minutes: number = Math.floor(
    (sec - hours * secondsInHour - days * secondsInDay) / secondsInMinute
  )
  const seconds: number =
    sec -
    minutes * secondsInMinute -
    hours * secondsInHour -
    days * secondsInDay

  return {days, hours, minutes, seconds}
}
