import React, {useState, useEffect} from 'react'

import {
  PlusOutlined,
  MinusOutlined,
  LoadingOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import {Table, Select, Divider} from 'antd'
import moment from 'moment'
import 'moment-duration-format'
import {useDispatch, useSelector} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {RequestStatus, Assets} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import {translate} from '@/services/i18n'
import {checkAccess} from '@/modules/ITSM/utils/accessChecks'
import {DATE_FORMAT} from '@/constants'
import {Button} from '@/components/button'
import {selectMyAccess} from '@/redux/settings/settingsSelector'
import DataTable from '@/components/data-table/data-table'

import {
  postTimeSessionTimespan,
  postEditTimeSpan,
  deleteTimeSpan,
} from '../../api/timeSessionRequests'

import './time-session.scss'
import DatePicker from '@/components/date-picker/date-picker'

const {Option} = Select

const TimespansTable = ({
  timeSession,
  getTimeSessions,
  getClosedTimeSessions,
}) => {
  const asset = Assets.timeSessionAsset

  const dispatch = useDispatch()
  const [timespansFormVisible, setShowTimespansForm] = useState(false)
  const [loadingAddTimespan, setLoadingAddTimespan] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)

  const [timespanType, setTimespanType] = useState(null)
  const [timespanStartDate, setTimespanStartDate] = useState(null)
  const [timespanEndDate, setTimespanEndDate] = useState(null)
  const [timespanStartDateEdit, setTimespanStartDateEdit] = useState(null)
  const [timespanEndDateEdit, setTimespanEndDateEdit] = useState(null)

  const [timespanIndex, setTimespanIndex] = useState(null)
  const [timespanEditRecord, setTimespanEditRecord] = useState(null)
  const [loadingDeleteTimespan, setLoadingDeleteTimespan] = useState(null)

  const [paginationNumber, setPaginationNumber] = useState(1)

  const [deleteIndex, setDeleteIndex] = useState(null)
  const [, setRefresh] = useState(0)

  const myAccess = useSelector(selectMyAccess)
  const updateTimespanRight = checkAccess('updateTimeSessionTimespan')
  const deleteTimespanRight = checkAccess('deleteTimeSessionTimespan')
  const addTimespanRight = checkAccess('addTimeSessionTimespan')

  const disabledDate = d => {
    return moment(d).isBefore(
      timespanStartDate || timespanStartDateEdit,
      'second'
    )
  }

  useEffect(() => {
    if (myAccess && Object.keys(myAccess).length > 0) setRefresh(Math.random())
  }, [myAccess])

  const TimespanDatePicker = ({
    initialDate,
    dateState,
    setTimespanDate,
    resetTimespan,
    setTimespanEnd,
    type,
    index,
  }) => (
    <DatePicker
      showTime
      format={DATE_FORMAT}
      defaultValue={initialDate ? moment(initialDate) : undefined}
      value={dateState ? moment(dateState) : undefined}
      onChange={e => {
        setTimespanDate(moment(e).toISOString())
        ;((timespanIndex === null && timespanEndDate === null) ||
          moment(e).isAfter(
            timespanEndDate ||
              timespanEndDateEdit ||
              timeSession.timespans?.[index]?.end,
            'second'
          )) &&
          setTimespanEnd &&
          setTimespanEnd(moment(e).toISOString())
      }}
      placeholder={null}
      disabledDate={type === 'end' && disabledDate}
      onOpenChange={open => {
        open && resetTimespan()
      }}
    />
  )

  const getColumnsNewTimespan = () => {
    const resetTimespan = () => {
      setTimespanStartDateEdit(null)
      setTimespanEndDateEdit(null)
      setTimespanEditRecord(null)
      setTimespanIndex(null)
    }
    return [
      {
        title: (
          <div className="required">
            <span>{translate('start')}</span>
          </div>
        ),
        dataIndex: 'start',
        render: () => {
          return TimespanDatePicker({
            dateState: timespanStartDate,
            setTimespanDate: setTimespanStartDate,
            resetTimespan,
            setTimespanEnd: setTimespanEndDate,
          })
        },
      },
      {
        title: (
          <div className="required">
            <span>{translate('end')}</span>
          </div>
        ),
        dataIndex: 'end',
        render: () => {
          return TimespanDatePicker({
            dateState: timespanEndDate,
            setTimespanDate: setTimespanEndDate,
            resetTimespan,
            type: 'end',
          })
        },
      },

      {
        title: (
          <div className="required">
            <span>{translate('type')}</span>
          </div>
        ),
        dataIndex: 'type',
        width: 150,
        render: () => {
          return (
            <Select
              className="time-spans__select"
              value={timespanType ? timespanType : undefined}
              onSelect={type => setTimespanType(type)}
            >
              <Option value={'travel'}>
                {translate('time_session_stateid_0').toLowerCase()}
              </Option>

              <Option value={'work'}>
                {translate('time_session_stateid_1').toLowerCase()}
              </Option>

              <Option value={'travel_back'}>
                {translate('time_session_stateid_3').toLowerCase()}
              </Option>
            </Select>
          )
        },
      },
    ]
  }

  const getColumns = () => {
    const resetTimespan = () => {
      setTimespanStartDate(null)
      setTimespanEndDate(null)
      setTimespanType(null)
    }

    return [
      {
        title: translate('start'),
        dataIndex: 'start',
        render: (val, record, index) => {
          return timespanIndex &&
            timespanIndex === ((paginationNumber - 1) * 10 + index).toString()
            ? TimespanDatePicker({
                initialDate: val,
                setTimespanDate: setTimespanStartDateEdit,
                resetTimespan,
                setTimespanEnd: setTimespanEndDateEdit,
                index,
              })
            : val && (
                <div className="no-wrap">{moment(val).format(DATE_FORMAT)}</div>
              )
        },
      },
      {
        title: translate('end'),
        dataIndex: 'end',
        render: (val, record, index) => {
          return timespanIndex &&
            timespanIndex === ((paginationNumber - 1) * 10 + index).toString()
            ? TimespanDatePicker({
                initialDate: val,
                setTimespanDate: setTimespanEndDateEdit,
                resetTimespan,
                type: 'end',
                dateState: timespanEndDateEdit,
              })
            : val && (
                <div className="no-wrap">{moment(val).format(DATE_FORMAT)}</div>
              )
        },
      },
      {
        title: translate('type'),
        dataIndex: 'type',
        render: val => {
          return val === 'on_hold' ? 'break' : translate(val)
        },
      },
      {
        ...(updateTimespanRight
          ? {
              title: translate('edit'),
              render: (...args) => {
                return timespanIndex ===
                  ((paginationNumber - 1) * 10 + args[2]).toString() ? (
                  <span>
                    <span
                      className="ts_link mr-5"
                      loading={loadingUpdate}
                      onClick={handleEditTimespan}
                    >
                      {translate('save')}
                      {loadingUpdate && <LoadingOutlined />}
                    </span>
                    <Divider className="time-spans__divider" type="vertical" />
                    <span
                      className="ts_link ml-5"
                      onClick={() => {
                        setTimespanIndex(undefined)
                      }}
                    >
                      {translate('cancel')}
                    </span>
                  </span>
                ) : (
                  args[0].end && (
                    <span>
                      <span
                        className="ts_link"
                        onClick={() => {
                          setTimespanIndex(
                            ((paginationNumber - 1) * 10 + args[2]).toString()
                          )
                          setTimespanEditRecord(args[0])
                        }}
                      >
                        {translate('edit')}
                      </span>
                    </span>
                  )
                )
              },
            }
          : {}),
      },
      {
        ...(deleteTimespanRight
          ? {
              title: translate('delete'),
              render: (...args) => {
                return (
                  args[0].end && (
                    <span
                      className="ts_link mr-6"
                      onClick={() => {
                        setDeleteIndex(args[2].toString())
                        handleDeleteTimespan(args[2].toString())
                      }}
                    >
                      {deleteIndex === args[2].toString() &&
                      loadingDeleteTimespan ? (
                        <LoadingOutlined />
                      ) : (
                        <CloseOutlined />
                      )}
                    </span>
                  )
                )
              },
            }
          : {}),
      },
    ]
  }

  const resetTimeSpanState = () => {
    setTimespanType(null)
    setTimespanStartDate(null)
    setTimespanStartDateEdit(null)
    setTimespanEndDateEdit(null)
    setTimespanEndDate(null)
    setLoadingAddTimespan(false)
    setTimespanIndex(undefined)
  }

  const getIndex = () => {
    let index
    if (timespanType === 'travel') index = 0

    if (timespanType === 'travel_back') index = timeSession.timespans.length

    if (timespanType === 'work')
      for (let i = 0; i < timeSession.timespans.length - 1; i++) {
        const timespan = timeSession.timespans[i]
        if (
          timespan.start &&
          !moment(timespanStartDate).isBetween(timespan.start, timespan.end) &&
          moment(timespanStartDate).isAfter(timespan.end) &&
          moment(timespanStartDate).isBefore(timeSession.timespans[i + 1].start)
        ) {
          index = i + 1
          break
        } else {
          index = timeSession.timespans.length
        }
      }

    return index
  }

  const addNewTimespan = async () => {
    try {
      setLoadingAddTimespan(true)
      handleMessage(RequestStatus.REQUESTED)

      await postTimeSessionTimespan({
        time_session: timeSession.uuid,
        timespan_index: getIndex(),
        timespan: {
          type: timespanType,
          start: timespanStartDate,
          end: timespanEndDate,
        },
      })
      handleMessage(RequestStatus.SUCCEEDED)

      resetTimeSpanState()
      getTimeSessions({refresh: true})
      if (getClosedTimeSessions) {
        getTimeSessions({closedTimeSessions: true})
      }
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      setLoadingAddTimespan(false)
      handleMessage(RequestStatus.FAILED)
    }
  }

  const handleEditTimespan = async e => {
    e.preventDefault()

    try {
      setLoadingUpdate(true)
      handleMessage(RequestStatus.REQUESTED)

      await postEditTimeSpan({
        time_session: timeSession.uuid,
        timespan_index: Number(timespanIndex),
        timespan: {
          start: timespanStartDateEdit || timespanEditRecord.start,
          end: timespanEndDateEdit || timespanEditRecord.end,
        },
      })
      handleMessage(RequestStatus.SUCCEEDED)

      setLoadingUpdate(false)
      resetTimeSpanState()
      getTimeSessions({refresh: true})

      if (getClosedTimeSessions) {
        getTimeSessions({closedTimeSessions: true})
      }
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      handleMessage(RequestStatus.FAILED)
    } finally {
      setLoadingUpdate(false)
      resetTimeSpanState()
    }
  }

  const handleDeleteTimespan = async editIndex => {
    try {
      setLoadingDeleteTimespan(true)
      handleMessage(RequestStatus.REQUESTED)

      await deleteTimeSpan({
        time_session: timeSession.uuid,
        timespan_index: Number(editIndex),
      })
      handleMessage(RequestStatus.SUCCEEDED)

      getTimeSessions({refresh: true})

      setLoadingDeleteTimespan(false)
      setTimespanIndex(undefined)
      if (getClosedTimeSessions) {
        getTimeSessions({closedTimeSessions: true})
      }
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      handleMessage(RequestStatus.FAILED)
    } finally {
      setLoadingDeleteTimespan(false)
      setTimespanIndex(undefined)
    }
  }

  return (
    <div className="time-spans">
      {addTimespanRight && timeSession && Object.keys(timeSession).length > 0 && (
        <div className="flex flex--justifyEnd">
          <Button
            title={translate('add_timespan')}
            size="large"
            icon={timespansFormVisible ? <MinusOutlined /> : <PlusOutlined />}
            onClick={() => {
              resetTimeSpanState()
              setShowTimespansForm(!timespansFormVisible)
            }}
          />
        </div>
      )}
      {timespansFormVisible && (
        <div className="time-spans--new">
          <Table
            columns={getColumnsNewTimespan()}
            dataSource={[{start: '', end: '', type: null}]}
            pagination={false}
            bordered={false}
          />
          <div className="mt-20">
            <Button
              title={translate('save')}
              type="primary"
              className="mr-8"
              loading={loadingAddTimespan}
              onClick={addNewTimespan}
              disabled={!timespanStartDate || !timespanEndDate || !timespanType}
            />
            <Button title={translate('cancel')} onClick={resetTimeSpanState} />
          </div>
        </div>
      )}
      <DataTable
        asset={asset}
        bordered={true}
        rowKey="start"
        data={timeSession?.timespans}
        columns={getColumns()}
        pagination={{
          total: timeSession?.timespans?.length,
          defaultPageSize: 10,
          onChange: pagNumber => setPaginationNumber(pagNumber),
        }}
      />
    </div>
  )
}

export default TimespansTable
