import React, {useEffect, useState} from 'react'

import {Col, Form, Row, Select} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import ErrorPage from '@/components/error/error-page/error-page'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {setIncidentTabIsBlocked, setOnHold} from '../incident/incident-reducer'
import {useStateContext} from '../incident/incident-context'

import {TIncident} from './typedf'
import {IncidentState} from '@/modules/ITSM/components/incident-k-request/constants'

const {Option} = Select

type TOnHoldReason = {
  on_hold_reason: string
}

const OnHoldReason = () => {
  const [form] = Form.useForm()

  const {
    entityData,
    updateEntity,
    dispatch,
    getData,
    isLoading,
  } = useStateContext<TIncident>()

  const [, setRefresh] = useState(0)

  const onReset = () => {
    form.resetFields()
    dispatch(setOnHold(false))
    dispatch(setIncidentTabIsBlocked(false))
  }

  const handleSubmit = async (values: {on_hold_reason: string}) => {
    const result = await updateEntity({
      on_hold_reason: values.on_hold_reason,
      state_id:
        entityData?.state_id === IncidentState.Open
          ? IncidentState.Pre_on_hold
          : IncidentState.On_hold,
    })

    if (result !== undefined) {
      getData(true)
      onReset()
    }
  }

  const btnDisabled = isLoading || !form.getFieldValue('on_hold_reason')

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])

  return (
    <Row className="mb-30">
      <Col span={24}>
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          <FormItemGeneric<TOnHoldReason>
            name="on_hold_reason"
            initialValue={null}
            rules={[
              {
                required: true,
                message: translate('on_hold_reason_req'),
              },
            ]}
            label={translate('on_hold_reason')}
          >
            <Select
              getPopupContainer={e => e.parentNode}
              onChange={() => setRefresh(Math.random())}
              e2e-test="on-hold-reason-select"
            >
              <Option value="Awaiting Caller">
                {translate('awaiting_caller')}
              </Option>

              <Option value="Awaiting Vendor">
                {translate('awaiting_vendor')}
              </Option>
            </Select>
          </FormItemGeneric>
          <Button
            title={translate('save')}
            disabled={btnDisabled}
            className="mr-5"
            type="primary"
            htmlType="submit"
            size="large"
            e2e-test="on-hold-reason-save"
          />
          <Button title={translate('cancel')} onClick={onReset} size="large" />
        </Form>
      </Col>
    </Row>
  )
}

export default withErrorBoundary(OnHoldReason, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
