import React from 'react'

import {Col} from 'antd'
import moment, {Moment} from 'moment'

import {TKey, translate} from '@/services/i18n'
import FormItemCustom from '@/components/form/form-item-custom'
import DatePicker from '@/components/date-picker/date-picker'
import {twoColumns} from '@/utils/table/constants/grid-columns'

type TProps = {
  expectedDateInit: string | undefined
  name: string
  disabledDates?: (currentDate: Moment) => boolean
  isDisabled: boolean
}
const EtaPicker = ({
  expectedDateInit,
  name,

  disabledDates,
  isDisabled,
}: TProps) => {
  return (
    <Col {...twoColumns} id="eta-picker">
      <FormItemCustom
        initialValue={expectedDateInit ? moment(expectedDateInit) : undefined}
        name={name}
        label={translate(name as TKey)}
      >
        <DatePicker
          value={moment()}
          key={Math.random()}
          getPopupContainer={() =>
            document.getElementById('eta-picker') as HTMLElement
          }
          disabled={isDisabled}
          placeholder={translate('empty')}
          showTime
          showToday={false}
          className="full-width"
          disabledDate={disabledDates}
          format={'HH:mm DD-MMM-YYYY Z'}
        />
      </FormItemCustom>
    </Col>
  )
}

export default EtaPicker
