import {useCallback} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {postAcceptOrRejectTicket} from '@/modules/ITSM/api/requestTaskRequests'

export function useAcceptOrReject() {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const saveUser = useCallback(
    async ({
      entity,
      decision,
      rejected_reason,
    }: {
      entity: string
      decision: string
      rejected_reason?: string
    }) => {
      try {
        handleRequested()
        const result = await postAcceptOrRejectTicket({
          entity,
          decision,
          rejected_reason,
        })
        if (result) {
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    saveEntity: saveUser,
    saveEntityStatus: status,
  } as const
}
