import {useCallback} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {
  patchUpdateOHH,
  postChangeVisitSummary,
} from '@/modules/ITSM/api/timelogsRequests'
import {TTimeLog} from '@/modules/ITSM/typedef'

import {createData} from '../utils'

export function useUpdateTimeLog() {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const updateTimeLog = useCallback(
    async ({
      id,
      values,
      extraPolicyDiff,
      valuesDiff,
    }: {
      id: string
      values: TTimeLog
      extraPolicyDiff: boolean
      valuesDiff: boolean | undefined
    }) => {
      try {
        handleRequested()

        if (extraPolicyDiff) {
          const OOHrates = {
            ignore_extra_pricing_policy: !values.ignore_extra_pricing_policy,
          }
          const {
            body: {result},
          } = await patchUpdateOHH(OOHrates, id)
          if (result && !valuesDiff) {
            handleSuccess()
            return result
          }
        }

        if (valuesDiff) {
          const data = createData(id, values)
          const {
            body: {result},
          } = await postChangeVisitSummary(data)

          if (result) {
            handleSuccess()
            return result
          }
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    updateTimeLog,
    updateStatus: status,
  }
}
