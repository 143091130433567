import {useCallback, useState} from 'react'

import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {fetchUsersIdentity} from '@/modules/ITSM/api/ts-version/fetch-users-identity'
import {TIdentity} from '@/modules/ITSM/typedef'

export function useGetUsersIdentity() {
  const [entityData, setEntityData] = useState<TIdentity | null>(null)

  const dispatch = useDispatch()

  const getEntityData = useCallback(
    async (userId: string) => {
      try {
        const {
          body: {result},
        } = await fetchUsersIdentity(userId)
        setEntityData(result)

        return result
      } catch (err) {
        setEntityData(null)

        dispatch(setToastMessage({message: err}))
      }
    },
    [dispatch]
  )

  return {
    getEntityData,
    entityData: entityData || {},
  } as const
}
