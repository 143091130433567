import {IncTabs, IncidentState} from '../constants'

export const setTab = ({
  step,
  assignee_state,
  state_id,
}: {
  step: string
  assignee_state: string
  state_id?: number
}) => {
  switch (true) {
    case step === '0':
      return IncTabs.OverviewTab

    case step === '1':
      return assignee_state

    case step === '2':
      return state_id === IncidentState.On_hold
        ? IncTabs.OnHoldTab
        : IncTabs.InProgressTab

    case step === '3':
      return IncTabs.ResolutionTab

    case step === '4':
      return IncTabs.ResolvedTab

    default:
      return ''
  }
}
