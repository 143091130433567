import {useCallback, useState} from 'react'

import {useRequestStatusHooks} from '@/hooks/use-request-status-hooks'
import {getAccounts} from '@/api/accountRequests'
import {TAccount} from '@/typedef'

export const useGetMyAccounts = () => {
  const [accounts, setAccounts] = useState<TAccount[]>([])
  const {
    status,
    handleRequest,
    handleSuccess,
    handleFail,
    handleReset,
  } = useRequestStatusHooks()

  return {
    getAccounts: useCallback(async () => {
      try {
        handleRequest()
        const {body: accounts} = await getAccounts(true)
        setAccounts(accounts)
        handleSuccess()
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    }, [handleRequest, handleSuccess, handleFail, handleReset]),
    data: accounts,
    status,
  }
}
