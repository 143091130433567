import moment from 'moment'

import {TLocation, TSupplierProduct} from '@/modules/ITSM/typedef'

import {TimeTypes} from '../../CustomerProducts/utils/constants'

import {TOrgRoles} from './types'

export const changeDataFormat = ({
  values,
  entityData,
  orgsRoles,
  productLocation,
  pricingUnit,
  additionalPricingUnit,
}: {
  values: Record<string, any>
  entityData: TSupplierProduct | undefined
  orgsRoles: TOrgRoles[]
  productLocation: TLocation
  pricingUnit?: string
  additionalPricingUnit?: string
}) => {
  const {
    name,
    code,
    org_display_name,
    pricing_type,
    sla_type,
    rate,
    active_from,
    primary_hour_rate,
    currency,
    pricing_unit,
    additional_hour_rate,
    additional_pricing_unit,
    primary_hours_number,
    travel_fix_rate,
    travel_hour_rate,
    travel_pricing_unit,
    travel_distance_unit_rate,
  } = values

  const {country, city, street} = productLocation

  const newObject: TSupplierProduct = {
    name,
    code,
    org_display_name,
    org_name: orgsRoles?.find(org => org.name === org_display_name)?.value,
    active_from: active_from
      ? active_from.toISOString()
      : moment().toISOString(),
    pricing_type: pricing_type.replaceAll(' ', '_').toLowerCase(),
    sla_type,
    location: {
      country: country || '',
      city: city || '',
      street: street || '',
    },
    pricing_policy: {
      rate,
      primary_hour_rate,
      currency,
      pricing_unit:
        pricingUnit === TimeTypes.MIN
          ? pricing_unit * 60
          : pricing_unit * 60 ** 2,
      additional_hour_rate,
      additional_pricing_unit:
        additionalPricingUnit === TimeTypes.MIN
          ? additional_pricing_unit * 60
          : additional_pricing_unit * 60 ** 2,
      primary_hours_number,
      travel_fix_rate,
      travel_hour_rate,
      travel_pricing_unit,
      travel_distance_unit_rate,
    },
  }

  return newObject
}
