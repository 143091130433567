import React, {FC} from 'react'

import AntdChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'

import {Paper} from '@/components/paper/paper'
import {ApexChartSkeleton} from '@/components/charts/apex-chart/apex-chart-skeleton'
import {Skeleton} from '@/components/skeleton'
import {ChartType} from '@/components/charts/apex-chart/typed'

import './apex-chart.scss'

type TProps = {
  loading: boolean
  type: ChartType
  series: ApexOptions['series']
  options: ApexOptions
}

export const ApexChart: FC<TProps> = ({loading, type, series, options}) => {
  return (
    <Skeleton
      className="apex-chart__skeleton"
      view={<ApexChartSkeleton />}
      active={loading}
    >
      <Paper className="apex-chart__wrapper">
        <AntdChart type={type} height={285} options={options} series={series} />
      </Paper>
    </Skeleton>
  )
}
