import {TProfileData} from '@/api/typedf'
import * as ActionTypes from '../actionTypes'

export const saveGeneralProfileRequested = () => {
  return {
    type: ActionTypes.SET_FETCH_GENERAL_PROFILE_REQUESTED,
  }
}

export const saveGeneralProfileSuccess = (profile: TProfileData) => {
  return {
    type: ActionTypes.SET_FETCH_GENERAL_PROFILE_SUCCESS,
    payload: {userProfile: profile},
  }
}

export const saveGeneralProfileFailed = () => {
  return {
    type: ActionTypes.SET_FETCH_GENERAL_PROFILE_FAILED,
  }
}

export const resetGeneralProfile = () => {
  return {
    type: ActionTypes.RESET_GENERAL_PROFILE,
  }
}
