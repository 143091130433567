import React, {useEffect, useState} from 'react'

import {Form as AntdForm} from 'antd'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {Paper, PaperVariant} from '@/components/paper/paper'
import {ChannelForm} from '@/modules/ITSM/components/channel-form/channel-form'
import {checkIsLoading} from '@/utils/check-is-loading'
import {OverlaySpinner} from '@/components/overlay-spinner'
import {TItsmChannel} from '@/modules/ITSM/typedef'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {RequestStatus} from '@/typedef'
import {useRequestData} from '@/hooks/use-request-data'
import {
  getSpace,
  upgradeSpace,
} from '@/modules/ITSM/api/ts-version/spaces-requests'

import {setItsmChannel} from '@/redux/entries/entries-actions'
import {selectChannelID} from '@/redux/entries/entries-selector'
import {useApiData} from '@/hooks/use-api-data'
import Modal from '@/components/modal/modal'
import {createChannelExistErrorMessage} from '@/utils/create-channel-exist-error-message'
import {translate} from '@/services/i18n'
import {ModalType} from '@/components/modal/typedef'

const ChannelDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = AntdForm.useForm()
  const {id} = useParams<{id: string}>()

  const [isDisplayModal, setIsDisplayModal] = useState(false)

  const currentChannelId = useSelector(selectChannelID)

  const {
    entityData: channel,
    run: fetchChannel,
    runEntityStatus: saveChannelStatus,
  } = useRequestData<TItsmChannel>()

  const {
    run: updateChannel,
    status: updateChannelStatus,
    error: updateChannelError,
  } = useApiData(upgradeSpace)

  const handleUpdateChannel = async ({name}: {name: string}) => {
    if (channel?.space) {
      const resItsmSpace = await updateChannel({
        space: channel.space,
        channel_name: name,
      })

      if (resItsmSpace) {
        if (currentChannelId === channel.space) {
          dispatch(
            setItsmChannel({
              channelName: name,
              channelID: currentChannelId,
            })
          )
        }

        return history.push(itsmRoutes.settingsITSMChannels.path())
      }

      return setIsDisplayModal(true)
    }
  }

  useEffect(() => {
    if (id) fetchChannel(getSpace(id))
  }, [fetchChannel, id])

  useEffect(() => {
    if (saveChannelStatus === RequestStatus.FAILED)
      history.push(itsmRoutes.settingsITSMChannels.path())
  }, [history, saveChannelStatus])

  if (!channel) {
    return <OverlaySpinner idName={'channel-details'} />
  }

  return (
    <Paper variant={PaperVariant.form}>
      <Modal
        info={createChannelExistErrorMessage(updateChannelError)}
        open={isDisplayModal}
        okText={translate('ok')}
        handleAccept={() => setIsDisplayModal(false)}
        onCancel={() => setIsDisplayModal(false)}
        modalType={ModalType.WARNING}
        maskClosable
      />
      <ChannelForm
        handleSubmit={handleUpdateChannel}
        form={form}
        isLoading={checkIsLoading(updateChannelStatus)}
        initialValues={channel}
      />
    </Paper>
  )
}

export default ChannelDetails
