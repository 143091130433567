import {TAppState} from '@/redux/store'

export function selectAccounts(state: TAppState) {
  return state.entries.accounts
}

export function selectChannelID(state: TAppState) {
  return state.entries.channelID
}

export function selectOrgId(state: TAppState) {
  return state.entries.orgID
}

export function selectOrgName(state: TAppState) {
  return state.entries.orgName
}

export function selectChannelName(state: TAppState) {
  return state.entries.channelName
}

export function selectCmdbSpaceId(state: TAppState) {
  return state.entries.cmdbNamespaceId
}

export function selectSaasSpaceId(state: TAppState) {
  return state.entries.saasNamespaceId
}
