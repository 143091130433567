import React from 'react'

import {RequestStatus} from '@/typedef'
import {IPrice, Interval, PriceFeatureFlagKeys} from '@/modules/typedef'
import {fetchPrices, searchPricesByMetadata} from '@/api/billing-requests'
import {generateSearchPriceParam} from '@/modules/Subscriptions/utils/generate-search-price-param'

enum GetStripeDataAction {
  FETCH_DATA_FAILED = 'FETCH_DATA_FAILED',
  FETCH_DATA_REQUESTED = 'FETCH_DATA_REQUESTED',
  FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
  FETCH_ALL_INCLUSIVE_SUCCESS = 'FETCH_ALL_INCLUSIVE_SUCCESS',
}

type TAsyncState = {
  status: RequestStatus
  data: Array<IPrice>
  has_more?: boolean
  error?: string
  starting_after?: string
  all_inclusive_id: string
}
type TAsyncAction =
  | {
      type: GetStripeDataAction.FETCH_DATA_REQUESTED
    }
  | {
      initialLoad: boolean
      type: GetStripeDataAction.FETCH_DATA_SUCCESS
      data: Array<IPrice>
      has_more: boolean
      starting_after: string
    }
  | {
      type: GetStripeDataAction.FETCH_ALL_INCLUSIVE_SUCCESS
      all_inclusive_id: string
    }
  | {
      type: GetStripeDataAction.FETCH_DATA_FAILED
      error: any
    }

function asyncReducer(state: TAsyncState, action: TAsyncAction) {
  switch (action.type) {
    case GetStripeDataAction.FETCH_DATA_REQUESTED: {
      return {
        ...state,
        status: RequestStatus.REQUESTED,
        data: state.data,
      }
    }

    case GetStripeDataAction.FETCH_DATA_SUCCESS: {
      const {has_more, data, starting_after, initialLoad} = action

      return {
        ...state,
        initialLoad,
        status: RequestStatus.SUCCEEDED,
        data: initialLoad ? data : state.data.concat(data),
        has_more,
        starting_after,
      }
    }
    case GetStripeDataAction.FETCH_ALL_INCLUSIVE_SUCCESS: {
      const {all_inclusive_id} = action

      return {
        ...state,
        all_inclusive_id,
      }
    }

    case GetStripeDataAction.FETCH_DATA_FAILED: {
      return {
        ...state,
        status: RequestStatus.FAILED,
        error: action.error,
      }
    }
    default: {
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`)
    }
  }
}

export function useGetStripeListDataGeneric(
  initialState?: Partial<TAsyncState>
) {
  const [state, dispatch] = React.useReducer<
    React.Reducer<TAsyncState, TAsyncAction>
  >(asyncReducer, {
    status: RequestStatus.INITIAL,
    error: '',
    has_more: false,
    data: [],
    all_inclusive_id: '',
    ...initialState,
  })

  const getAllInclusive = React.useCallback(async () => {
    try {
      const {
        body: {result},
      } = await searchPricesByMetadata({
        queryParam: generateSearchPriceParam(
          'feature_flag',
          PriceFeatureFlagKeys.ALL_INCLUSIVE
        ),
      })
      if (!result) return
      const initialPriceId =
        result.find(
          (price: IPrice) => price.recurring?.interval === Interval.YEAR
        ) || result[0]
      dispatch({
        type: GetStripeDataAction.FETCH_ALL_INCLUSIVE_SUCCESS,
        all_inclusive_id: initialPriceId.product.id,
      })
    } catch (error) {
      dispatch({type: GetStripeDataAction.FETCH_DATA_FAILED, error})
    }
  }, [])

  const run = React.useCallback(async (initialLoad = false) => {
    dispatch({type: GetStripeDataAction.FETCH_DATA_REQUESTED})

    try {
      const {
        body: {result: data, has_more},
      } = await fetchPrices()

      if (data) {
        dispatch({
          initialLoad,
          type: GetStripeDataAction.FETCH_DATA_SUCCESS,
          data,
          has_more,
          ...(has_more && data && {starting_after: data[data?.length - 1].id}),
        })
      }
    } catch (error) {
      dispatch({type: GetStripeDataAction.FETCH_DATA_FAILED, error})
    }
  }, [])

  const {
    data,
    error,
    status,
    has_more,
    starting_after,
    all_inclusive_id,
  } = state

  return {
    data,
    error,
    status,
    has_more,
    starting_after,
    run,
    getAllInclusive,
    all_inclusive_id,
  }
}
