import {translate} from '@/services/i18n'

import {TAssigneeState, TIncidentState} from './typedf'

export const ASSIGNEE_STATES_NAMES: TAssigneeState = {
  unset: translate('unassigned'),
  proposed: translate('proposed'),
  accepted: translate('accepted'),
  rejected: translate('rejected'),
}

export const INCIDENT_STATES_NAMES: TIncidentState = {
  Open: translate('open'),
  Pre_on_hold: translate('pre_on_hold'),
  In_progress: translate('in_progress'),
  On_hold: translate('on_hold'),
  Resolved: translate('resolved'),
  Closed: translate('closed'),
  Cancelled: translate('cancelled'),
  Resolution: translate('resolution'),
}

export enum IncidentState {
  Open = 0,
  Pre_on_hold = 1,
  In_progress = 2,
  On_hold = 3,
  Resolved = 4,
  Closed = 5,
  Cancelled = 6,
}

export const INCIDENT_STATE_OPTIONS = [
  {value: '0', name: translate('open')},
  {value: '1', name: translate('pre_on_hold')},
  {value: '2', name: translate('in_progress')},
  {value: '3', name: translate('on_hold')},
  {value: '4', name: translate('resolved')},
  {value: '5', name: translate('closed')},
  {value: '6', name: translate('cancelled')},
]

export enum AssigneeStates {
  Unset = 'unset',
  Proposed = 'proposed',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export const ASSIGNEE_STATE_OPTIONS = [
  {value: 'unset', name: translate('unset')},
  {value: 'proposed', name: translate('proposed')},
  {value: 'accepted', name: translate('accepted')},
  {value: 'rejected', name: translate('rejected')},
]

export const CONTACT_TYPES_OPTIONS = [
  {
    value: 'unknown',
    name: translate('unknown'),
  },
  {
    value: 'portal',
    name: translate('portal'),
  },
  {
    value: 'email',
    name: translate('email'),
  },
  {
    value: 'email_worker',
    name: translate('email_worker'),
  },

  {
    value: 'phone',
    name: translate('phone'),
  },

  {
    value: 'customer',
    name: translate('customer'),
  },
]

export enum ApprovalStates {
  Pending = 'waiting',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const APPROVAL_STATE_OPTIONS = [
  {
    value: ApprovalStates.Pending,
    name: translate('pending'),
  },
  {
    value: ApprovalStates.Approved,
    name: translate('approved'),
  },
  {
    value: ApprovalStates.Rejected,
    name: translate('rejected'),
  },
]

export const APPROVAL_STATE_OPTIONS_FILTERS = [
  {
    value: '',
    label: translate('all'),
  },
  {
    value: ApprovalStates.Pending,
    label: translate('pending'),
  },
  {
    value: ApprovalStates.Approved,
    label: translate('approved'),
  },
  {
    value: ApprovalStates.Rejected,
    label: translate('rejected'),
  },
]

export enum IncTabs {
  OverviewTab = 'overview',
  IncidentStatusLogTab = 'incident_status_log',
  KRequestStatusLogTab = 'request_status_log',
  CommentsTab = 'comments',
  AttachmentsTab = 'attachments',
  WorkNotesTab = 'work-notes',
  TimeLogsTab = 'time-logs',
  MapTab = 'map',
  SvrTab = 'svr',
  IncidentTasksTab = 'incident-tasks',
  ExpensesTab = 'expenses',
  WatchListTab = 'watch-list',
  ResolutionTab = 'resolution',
  ResolvedTab = 'resolved',
  InProgressTab = 'in-progress',
  OnHoldTab = 'on-hold',
}

export const defaultCategory = [
  {
    name: 'Hardware',
    uuid: 'e72a0328-1be5-589b-64a0-ffdfc821feb2',
  },
  {
    name: 'Software',
    uuid: '24aa07c4-d64b-4c82-9fba-97631cf153d6',
  },
]

export const defaultSubCategory = [
  {
    name: 'Device',
    uuid: '73893294-da2d-433f-915b-fa4c39c40b18',
  },
  {
    name: 'App',
    uuid: 'cfbab08c-e3be-abf1-5f85-16cc2b74a9dc',
  },
]
