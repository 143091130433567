import React, {useEffect} from 'react'

import {useParams} from 'react-router-dom'

import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {TSupplierProduct} from '@/modules/ITSM/typedef'
import {useGetSingleData} from '@/hooks/useGetSignleData'
import {fetchSupplierProduct} from '@/modules/ITSM/api/productsRequests'
import {Paper, PaperVariant} from '@/components/paper/paper'

import SupplierProductForm from './supplier-product-form'

const CustomerProductDetails = () => {
  const {id} = useParams<{id: string}>()

  const {entityData, getData} = useGetSingleData<TSupplierProduct>(
    fetchSupplierProduct
  )

  useEffect(() => {
    if (!entityData) {
      getData({id})
    }
  }, [entityData, getData, id])

  return (
    <Paper variant={PaperVariant.form}>
      <Skeleton
        active={!entityData}
        view={<FormSkeleton className="incident-k-request-tabs" />}
      >
        <SupplierProductForm
          entityData={entityData}
          id={id.slice(0, 36)}
          getData={getData}
        />
      </Skeleton>
    </Paper>
  )
}

export default CustomerProductDetails
