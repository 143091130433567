import moment from 'moment'

import {TIncident, TIncidentReq} from '../typedf'

export const createData = (values: TIncidentReq) => {
  delete values['timezoneEnd']
  delete values['timezoneStart']
  delete values['created_by']
  delete values['number']
  delete values['priority']

  if (values['watch_list'] && values['watch_list'].length === 0)
    values['watch_list'] = undefined

  if (values.expected_start)
    values['expected_start'] = moment(values.expected_start).format()

  if (values.expected_end)
    values['expected_end'] = moment(values.expected_end).format()

  return values
}

export const getInitialValues = (values: TIncident) => {
  return {
    caller: values.caller?.uuid,
    category: values.category?.uuid,
    subcategory: values.subcategory?.uuid,
    impact_text: values.impact_text,
    urgency_text: values.urgency_text,
    location: values.location?.uuid,
    contact_type: values.contact_type,
    watch_list: values.watch_list?.map((user: {uuid: string}) => user.uuid),
    short_description: values.short_description,
    description: values.description,
    customer_product: values.customer_product?.uuid,
    supplier_product: values.supplier_product?.uuid,
    assignment_group: values.assignment_group?.uuid,
    assigned_to: values.assigned_to?.uuid,
  }
}
