import {Interval} from '@/modules/typedef'

export enum ModuleFeatureFlags {
  ITSM = 'ticket_management',
  CMDB = 'asset_management',
  SAAS = 'saas_management',
}
export enum ModuleLinks {
  ticket_management = 'https://www.blits-platform.com/service-management/',
  asset_management = 'https://www.blits-platform.com/assets-management/',
  saas_management = 'https://www.blits-platform.com/saas-management/',
}

export type TPurchasesModule = Array<ModuleFeatureFlags>
export type TPurchasesAddons = Record<ModuleFeatureFlags, Array<string>>
export type TExtraAddons = Array<string>

export type TNormalizedProduct = {
  id: string
  name: string
  description: string
  interval: Interval
  currency: string
  unit_amount: number
  feature_flag: ModuleFeatureFlags
  product_type: string
  icon: string
  category: ModuleFeatureFlags
  priceType?: 'module'
  usage_type: 'licensed' | 'metered'
  contact_sales_price: string
  per_custom_unit: string
  productId: string
}
