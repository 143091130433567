import {translate} from '@/services/i18n'

import {TPricingType, TResolutionTimeEval, TTimeUnit} from './types'

export const PricingType: TPricingType = {
  daily: translate('daily'),
  monthly: translate('monthly'),
  per_visit: translate('per_visit'),
  per_ticket: translate('per_ticket'),
}

export const ResolutionTimeEval: TResolutionTimeEval = {
  at_time_log: translate('at_time_log'),
  at_assign: translate('at_assign'),
  at_create: translate('at_create'),
}

export const TimeTypes: TTimeUnit = {
  HR: 'hours',
  MIN: 'minutes',
}
