import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {
  acceptOrRejectTicketApi,
  requestTaskApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'

export const patchRequestTask = async (id, data) => {
  return await request
    .patch(requestTaskApi(id).patch)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getRequestTask = async (id, resolve) => {
  return await request
    .get(requestTaskApi(id).getOne + resolve)
    .set(getHeadersWithGRPC())
}

export const postAcceptOrRejectTicket = async data => {
  return await request
    .post(acceptOrRejectTicketApi().post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postRequestTask = async (id, data) => {
  return await request
    .post(requestTaskApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getRequestTasks = async ({bookmark, query, sort, fields}) => {
  return await request
    .options(requestTaskApi().get)
    .send({
      bookmark,
      selector: query,
      sort,
      fields,
    })
    .set(getHeadersWithGRPC())
}
