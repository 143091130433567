import React from 'react'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {RESOLVE} from '@/constants'
import {getName} from '@/modules/ITSM/utils/Helpers'
import {TAssignmentGroup} from '@/modules/ITSM/typedef'
import {assignmentGroupsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

type TProps = {
  assignmentGroupInit?: TAssignmentGroup | undefined
  setRefresh: () => void
  resetAssignedTo?: () => void
  isDisabled?: boolean
  selector?: Record<string, string | undefined>
  required?: boolean
}

const AssignmentGroupSelect = ({
  assignmentGroupInit,
  setRefresh,
  resetAssignedTo,
  isDisabled,
  selector,
  required,
}: TProps) => {
  return (
    <>
      {/* @ts-ignore not refactored to typescript */}
      <SelectWithBM
        api={assignmentGroupsApi().get + RESOLVE}
        label={translate('assignment_group')}
        name="assignment_group"
        selector={selector}
        onClear={() => {
          resetAssignedTo && resetAssignedTo()
          setRefresh()
        }}
        initialValue={assignmentGroupInit ? [assignmentGroupInit] : []}
        disabled={isDisabled}
        optionContent={getName}
        searchKey="name"
        onSelect={() => {
          resetAssignedTo && resetAssignedTo()
          setRefresh()
        }}
        rules={{
          required: !!required,
        }}
        getPopupContainer={(e: HTMLElement) => e.parentNode}
      />
    </>
  )
}

export default AssignmentGroupSelect
