import request from 'superagent'

import {
  billingItemsApi,
  billingReportsApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {getHeadersWithGRPC} from '@/services/api'
import {TBillingItem} from '@/modules/ITSM/typedef'

import {ISuperAgentResMultiple} from './response-typedf'

export const fetchBillingItems = async (data: {
  bookmark?: string
  selector?: {$or?: {uuid: string}[]}
}): Promise<ISuperAgentResMultiple<TBillingItem>> =>
  await request
    .options(billingItemsApi().get)
    .send(data)
    .set(getHeadersWithGRPC())

export const postInvalidAnomaly = async (data: {
  billing_report: string
  anomaly: string
  invalid: boolean
}) =>
  await request
    .post(billingReportsApi().postInvalidAnomaly)
    .set(getHeadersWithGRPC())
    .send(data)
