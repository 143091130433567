export const parseNestedErrorMessage = (err: unknown) => {
  if (err instanceof Error) {
    const errorMessage = JSON.parse(err.message).error
    const startObj = errorMessage.indexOf('{')

    if (startObj !== -1) {
      const errorObj = JSON.parse(errorMessage.slice(startObj)).error

      if (errorObj?.status === 403) {
        return errorObj
      }
    }
  }

  return err
}
