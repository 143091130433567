import React, {useContext, useEffect, useState} from 'react'
import ProfileIdentityWrapper from '@/modules/ITSM/components/profile-identity-wrapper/profile-identity-wrapper'
import {TKey, translate} from '@/services/i18n'
import {useSelector} from 'react-redux'
import {selectUserDefaults} from '@/redux/user/userSelector'
import {TUserDefaults} from '@/modules/ITSM/typedef'
import {
  InviteOrgContext,
  setInviteOrgStep,
} from '@/sites/accept-org-invite/context/org-invite-context'
import {postUserWithHeaders} from '@/modules/ITSM/api/userRequests'
import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {createMetadataSpaceHeader} from '@/utils/create-metadata-space-header'
import {
  InvitationStep,
  TUserFormPayload,
} from '@/sites/accept-org-invite/typedef'
import {useApiData} from '@/hooks/use-api-data'
import {Checkbox, Form, Input, Select, Tooltip} from 'antd'
import {scrollToField} from '@/utils/scroll-to-field'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {emailValidator} from '@/utils/form-validators/email-validator'
import moment from 'moment-timezone'
import {TIME_ZONES} from '@/constants'
import {InfoCircleOutlined} from '@ant-design/icons'
import {Button} from '@/components/button'
import {checkIsLoading} from '@/utils/check-is-loading'
import {contractRole, contractType} from '@/modules/ITSM/utils/Constants'
import {createUserPayload} from '@/sites/accept-org-invite/utils/create-user-payload'

import './create-user.scss'
import OrgInviteErrorModal from '@/sites/accept-org-invite/error-modal/org-invite-error-modal'

const Option = Select.Option

export const CreateUser = () => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  const [reloadCount, setReloadCount] = useState(0)
  const [, setRefresh] = useState(0)
  const {state, dispatch} = useContext(InviteOrgContext)

  const userDefaults = useSelector(selectUserDefaults) as TUserDefaults

  const {run: postUser, status: postUserStatus} = useApiData(
    postUserWithHeaders
  )

  const handleSubmit = async (values: TUserFormPayload) => {
    if (state.inviteChannels[0]) {
      const result = await postUser({
        payload: createUserPayload(values),
        headers: {
          ...createAuthorizationHeader(state.accessToken),
          ...createMetadataSpaceHeader(state.inviteChannels[0]),
        },
      })

      if (!result) return setHasError(true)

      return dispatch(setInviteOrgStep(InvitationStep.CREATE_PROFILE))
    }

    return setHasError(true)
  }

  useEffect(() => {
    if (userDefaults) form.setFieldsValue(userDefaults)
  }, [form, userDefaults])

  return (
    <ProfileIdentityWrapper
      header={translate('create_user')}
      subHeader={translate('fill_identity_form')}
    >
      <OrgInviteErrorModal
        handleReload={() => setHasError(false)}
        visible={hasError}
        noBackground={false}
        setReloadCount={setReloadCount}
        reloadCount={reloadCount}
      />
      <Form
        layout="vertical"
        className="create-user__form"
        // eslint-disable-next-line
        validateMessages={{required: '${label} is required'}}
        form={form}
        onFinishFailed={errorFields => {
          setRefresh(Math.random())
          scrollToField({errorFields, form})
        }}
        onFinish={handleSubmit}
      >
        <FormItemGeneric<TUserFormPayload>
          isGrey
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
          label={translate('first_name')}
        >
          <Input onFocus={setAutocompleteNone} />
        </FormItemGeneric>

        <FormItemGeneric<TUserFormPayload>
          isGrey
          name="surname"
          rules={[
            {
              required: true,
            },
          ]}
          label={translate('last_name')}
        >
          <Input onFocus={setAutocompleteNone} />
        </FormItemGeneric>

        <FormItemGeneric<TUserFormPayload>
          isGrey
          name="email"
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              validator: emailValidator,
            },
          ]}
          label={translate('email')}
        >
          <Input onFocus={setAutocompleteNone} />
        </FormItemGeneric>

        <FormItemGeneric<TUserFormPayload>
          isGrey
          name="phone"
          label={translate('phone')}
        >
          <Input onFocus={setAutocompleteNone} />
        </FormItemGeneric>
        <FormItemGeneric<TUserFormPayload>
          isGrey
          name="timezone"
          label={translate('timezone')}
          initialValue={userDefaults?.timezone || moment.tz.guess()}
        >
          <Select showSearch getPopupContainer={e => e.parentNode}>
            {TIME_ZONES.map(val => (
              <Option value={val.value} key={val.value + 'timezone'}>
                {`${val.value} [UTC${val.time}]`}
              </Option>
            ))}
          </Select>
        </FormItemGeneric>
        {!userDefaults?.contract_type && (
          <FormItemGeneric<TUserFormPayload>
            isGrey
            name="contract_type"
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('contract_type')}
          >
            <Select>
              {contractType.map((contract, index) => {
                return (
                  <Option value={contract} key={index}>
                    {translate(contract as TKey)}
                  </Option>
                )
              })}
            </Select>
          </FormItemGeneric>
        )}

        {!userDefaults?.type && (
          <FormItemGeneric<TUserFormPayload>
            isGrey
            name="type"
            label={
              <span>
                {translate('type')}
                <Tooltip placement="right" title={translate('user_type_info')}>
                  <InfoCircleOutlined className="ml-5" />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: `${translate('type')} ${translate('is_required')}`,
              },
            ]}
          >
            <Select>
              {contractRole.map((type, index) => {
                return (
                  <Option value={type} key={index}>
                    {translate(type as TKey)}
                  </Option>
                )
              })}
            </Select>
          </FormItemGeneric>
        )}

        <div className="create-user__terms">
          <FormItemGeneric<TUserFormPayload>
            valuePropName="checked"
            name="terms"
            rules={[
              {
                required: true,
                message: ' ',
              },
              () => ({
                validator(_, value) {
                  if (value) return Promise.resolve()
                  return Promise.reject()
                },
              }),
            ]}
          >
            <Checkbox onChange={() => setRefresh(Math.random())} />
          </FormItemGeneric>
          <span>
            {translate('iAgreeWith')}
            <a href="https://www.kompitech.com/privacy-policy-statement/">
              {translate('terms_and_conditions')}
            </a>
          </span>
          {form.getFieldError('terms')[0] && (
            <div className="create-user__terms-error">
              {translate('agree_conditions')}
            </div>
          )}
        </div>

        <div className="create-user__btns">
          <Button
            type="primary"
            htmlType="submit"
            loading={checkIsLoading(postUserStatus)}
            title={translate('submit')}
            className="mr-10"
            disabled={checkIsLoading(postUserStatus)}
            e2e-test="aceept-invite-submit"
          />
          <Button
            title={translate('cancel')}
            onClick={() => form.resetFields()}
          />
        </div>
      </Form>
    </ProfileIdentityWrapper>
  )
}
