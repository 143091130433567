import React from 'react'

import {Col, Form as AntForm, Input, Row, Select} from 'antd'
import moment from 'moment'
import {useHistory} from 'react-router-dom'

import {TKey, translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import {useFetchHook} from '@/hooks/useFetch'
import {TAnnouncement} from '@/modules/ITSM/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {Form} from '@/components/form/form/form'
import {
  patchAnnouncement,
  postAnnouncement,
} from '@/modules/ITSM/api/announcementsRequests'
import {org_roles} from '@/modules/ITSM/utils/Constants'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import DatePicker from '@/components/date-picker/date-picker'

const {TextArea} = Input
const {Option} = Select

type TAnnouncementForm = {
  getData?: ({id}: {id: string}) => Promise<TAnnouncement | undefined>
  entityData?: TAnnouncement
  id: string
}

const AnnouncementForm = ({
  getData: getEntityData,
  entityData: record,
  id,
}: TAnnouncementForm) => {
  const history = useHistory()
  const [form] = AntForm.useForm()

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
    status,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const goBackHandler = () => {
    history.push(itsmRoutes.settingsITSMUAnnouncements.path())
  }

  const handleSubmit = async (values: TAnnouncement) => {
    try {
      handleRequested()

      if (id && getEntityData) {
        await patchAnnouncement(id, values)
        getEntityData({id})
      } else {
        await postAnnouncement(values)
      }

      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  return (
    <div className={'announcement-form'}>
      <GoBackButton onClick={goBackHandler} />
      <Form<TAnnouncement>
        onSubmit={handleSubmit}
        form={form}
        isLoading={checkIsLoading(status)}
      >
        <div>
          <Row>
            <Col className="col-flex--two">
              <FormItemGeneric<TAnnouncement>
                name="title"
                label={translate('title')}
                initialValue={record && record.title ? record.title : ''}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </FormItemGeneric>

              <FormItemGeneric<TAnnouncement>
                name="org_role"
                label={translate('org_role')}
                initialValue={record && record.org_role ? record.org_role : ''}
                rules={[
                  {
                    required: true,
                    message: translate('org_role_req'),
                  },
                ]}
              >
                <Select showSearch getPopupContainer={e => e.parentNode}>
                  {Object.values(org_roles).map((role, index) => {
                    return (
                      <Option key={index} value={role}>
                        {translate(role as TKey)}
                      </Option>
                    )
                  })}
                </Select>
              </FormItemGeneric>
            </Col>
          </Row>
          <FormItemGeneric<TAnnouncement>
            name="body"
            label={translate('body')}
            initialValue={record && record.body ? record.body : ''}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={5} />
          </FormItemGeneric>
          <Row>
            <Col className="col-flex--two">
              <FormItemGeneric<TAnnouncement>
                name="active_from"
                label={translate('active_from')}
                initialValue={
                  record && record.active_from
                    ? moment(record.active_from)
                    : null
                }
                rules={[
                  {
                    required: true,
                    message: translate('active_from_req'),
                  },
                ]}
              >
                <DatePicker format={DATE_FORMAT} />
              </FormItemGeneric>

              <FormItemGeneric<TAnnouncement>
                name="active_to"
                initialValue={
                  record && record.active_to ? moment(record.active_to) : null
                }
                label={translate('active_to')}
                rules={[
                  {
                    required: true,
                    message: translate('active_to_req'),
                  },
                ]}
              >
                <DatePicker format={DATE_FORMAT} />
              </FormItemGeneric>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}

export default AnnouncementForm
