import React, {Component, createRef, Fragment} from 'react'

import {v4 as uuid} from 'uuid'
import {PlusOutlined, DeleteOutlined} from '@ant-design/icons'
import {Input, Select, Form, Row, Col} from 'antd'
import {connect} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {setRefresh} from '@/redux/actions'
import {translate} from '@/services/i18n'
import {Assets, RequestStatus} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import {Button} from '@/components/button'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import FormItemCustom from '@/components/form/form-item-custom'

import {getRole, postRole, patchRole} from '../../../api/rolesRequests'

import './RoleDrawer.scss'

const Option = Select.Option

const grantActionOptions = [
  {
    value: 'create',
    name: translate('create'),
  },
  {
    value: 'read',
    name: translate('read'),
  },
  {
    value: 'update',
    name: translate('update'),
  },
  {
    value: 'create_comment',
    name: translate('create_comment'),
  },
  {
    value: 'read_comment',
    name: translate('read_comment'),
  },
  {
    value: 'create_worknote',
    name: translate('create_worknote'),
  },
  {
    value: 'read_worknote',
    name: translate('read_worknote'),
  },
  {
    value: 'assignable',
    name: translate('assignable'),
  },
  {
    value: 'update_overrides',
    name: translate('update_overrides'),
  },
  {
    value: 'execute',
    name: translate('execute'),
  },
]

class RoleDrawer extends Component {
  constructor(props) {
    super(props)
    this.formRef = createRef()
    this.state = {
      loading: false,
      loadingSubmit: false,
      role: [],
      grants: [],
      asset: Assets.rolesAsset,
      btnDisabled: !props.newRole,
    }
  }

  fetchRole = async () => {
    const {setFieldsValue} = this.formRef.current || {}

    try {
      this.setState({loading: true})
      const {
        body: {result},
      } = await getRole(this.props.uuid)

      const grants = result.grants ? [{}].concat(result.grants) : [{}]

      this.formRef.current &&
        setFieldsValue({
          name: result.name,
          grants,
        })

      this.setState({
        role: result,
        grants,
        numberOfRecords: result.length,
      })
    } catch (err) {
      this.props.setToastMessage({message: err})
    } finally {
      this.setState({loading: false})
    }
  }

  createData = values => {
    const newGrants = [...(this.state.grants || [])].filter((e, i) => i !== 0)

    if (this.props.newRole) {
      return {
        name: values.name,
        grants: newGrants,
      }
    } else {
      return {
        name: values.name,
        grants: newGrants.length === 0 ? [] : newGrants,
      }

      // return getDataDifference(origin, data)
    }
  }

  handleSubmit = async values => {
    const {newRole, onClose, setToastMessage} = this.props

    const data = this.createData(values)

    try {
      this.setState({loadingSubmit: true})
      handleMessage(RequestStatus.REQUESTED)

      if (newRole) {
        await postRole(uuid(), data)
        onClose()
      } else {
        await patchRole(this.props.uuid, data)
        this.setState({
          btnDisabled: true,
        })
      }

      handleMessage(RequestStatus.SUCCEEDED)

      this.props.getEntityData()

      this.setState({
        loadingSubmit: false,
      })
    } catch (err) {
      setToastMessage({message: err})
      this.setState({loadingSubmit: false})
      handleMessage(RequestStatus.FAILED)
    }
  }

  handleCloseDrawer = () => {
    this.props.onClose()
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  hideModal = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    const {setFieldsValue} = this.formRef.current || {}

    if (this.props.newRole) {
      this.formRef.current && setFieldsValue({grants: [{}]})
    } else {
      this.fetchRole()
    }
  }

  render() {
    const {grants, loadingSubmit, role, loading, btnDisabled} = this.state
    return (
      <DrawerForm
        // eslint-disable-next-line
        onFinish={this.handleSubmit}
        formRef={this.formRef}
        loadingSubmit={btnDisabled || loadingSubmit}
        handleCancel={this.handleCloseDrawer}
        loadingFetch={loading}
        onValuesChange={() => {
          btnDisabled && this.setState({btnDisabled: false})
        }}
      >
        <div>
          <FormItemCustom
            name="name"
            initialValue={role?.name || ''}
            rules={[
              {
                required: true,
              },
            ]}
            label={translate('name')}
          >
            <Input />
          </FormItemCustom>
          <div className="drawer-role__grants">
            <Form.List name="grants">
              {(fields, {add, remove}) => {
                return fields.map((field, index) => (
                  <Fragment key={index}>
                    {field.name === 0 ? (
                      <>
                        <Button
                          title={translate('add_permission')}
                          icon={<PlusOutlined />}
                          type="primary"
                          onClick={add}
                          size="large"
                        />
                        <Row className="mt-20">
                          <Col span={13}>
                            <div className="ant-form-item-label pl-12">
                              <span>{'Objects'}</span>
                            </div>
                          </Col>
                          <Col span={11}>
                            {/* eslint-disable-next-line max-len */}
                            <div className="ant-form-item-label drawer-role__grants__settings">
                              <span className="drawer-role__grants__settings--action">
                                {translate('action')}
                              </span>

                              <span className="drawer-role__grants__settings--delete">
                                {translate('delete')}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col span={13}>
                          <FormItemCustom
                            name={[field.name, 'object']}
                            key={field.key + 'object'}
                          >
                            <Input
                              onChange={e => {
                                const newGrants = [...grants]

                                newGrants[field.name] = {
                                  ...newGrants[field.name],
                                  object: e.target.value,
                                }

                                this.setState({grants: newGrants})
                              }}
                            />
                          </FormItemCustom>
                        </Col>
                        <Col span={8}>
                          <FormItemCustom
                            name={[field.name, 'action']}
                            key={field.key + 'action'}
                            validateTrigger={['onChange', 'onBlur']}
                          >
                            <Select
                              getPopupContainer={e => e.parentNode}
                              onChange={e => {
                                const newGrants = [...grants]

                                newGrants[field.name] = {
                                  ...newGrants[field.name],
                                  action: e,
                                }
                                this.setState({grants: newGrants})
                              }}
                            >
                              {grantActionOptions.map(action => (
                                <Option value={action.value} key={action.value}>
                                  {action.name}
                                </Option>
                              ))}
                            </Select>
                          </FormItemCustom>
                        </Col>
                        <Col span={3} className="text-center">
                          <span className="drawer-role__grants__remove-btn">
                            <DeleteOutlined
                              onClick={() => {
                                remove(field.name)
                                this.setState(prevState => ({
                                  grants: [...prevState.grants].filter(
                                    (e, i) => i !== field.name
                                  ),
                                }))
                              }}
                            />
                          </span>
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                ))
              }}
            </Form.List>
          </div>
        </div>
      </DrawerForm>
    )
  }
}

const mapStateToProps = state => {
  return {state: state.general}
}

export default connect(mapStateToProps, {
  setToastMessage,
  setRefresh,
})(RoleDrawer)
