import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {assignmentRulesApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TAssignmentRule} from '../typedef'

export const patchAssignmentRule = async (
  id: string,
  data: TAssignmentRule
) => {
  return await request
    .patch(assignmentRulesApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const postAssignmentRule = async (id: string, data: TAssignmentRule) => {
  return await request
    .post(assignmentRulesApi(id).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const fetchAssignmentRules = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(assignmentRulesApi().get + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })
