import {ModalType} from '@/components/modal/typedef'
import {noPermission} from '@/modules/ITSM/utils/Constants'

import {RequestStatusCode} from './typedef'

export const getAlertType = (err: Record<string, unknown>) => {
  if (
    err?.status === RequestStatusCode.STATUS_403 ||
    (err?.status === RequestStatusCode.STATUS_200 &&
      err.message === noPermission)
  ) {
    return ModalType.WARNING
  }
  return ModalType.ERROR
}
