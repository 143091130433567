import React, {Fragment, useState} from 'react'

import moment from 'moment'

import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'

import './product-details.scss'

const ProductDetails = ({customer_product}) => {
  const [moreInfo, getMoreInfo] = useState(false)
  return (
    <div className="itsm-product-details">
      {customer_product && (
        <Fragment>
          <div className="itsm-product-details__data-container">
            {customer_product.code && (
              <div>{`${translate('code')}: ${customer_product.code}`}</div>
            )}

            {customer_product.pricing_type && (
              <div>{`${translate('pricing_type')}: ${
                customer_product.pricing_type
              }`}</div>
            )}
            {customer_product.product_type && (
              <div>{`${translate('product_type')}: ${
                customer_product.product_type
              }`}</div>
            )}
            {customer_product.sla_type && (
              <div>{`${translate('sla_type')}: ${
                customer_product.sla_type
              }`}</div>
            )}

            <div>{`${translate('sla')}:`}</div>
            {customer_product.sla?.bank_holidays && (
              <Fragment>
                <div className="pl-10">{`- ${translate(
                  'bank_holidays'
                )}:`}</div>
                <div className="itsm-product-details__row">{`- ${translate(
                  'supportFrom'
                )}: ${customer_product.sla.bank_holidays.support_from}`}</div>
                <div className="itsm-product-details__row">{`- ${translate(
                  'supportTo'
                )}: ${customer_product.sla.bank_holidays.support_to}`}</div>
              </Fragment>
            )}
            {customer_product.sla?.inactive_timeout && (
              <div className="pl-10">{`- ${translate('inactive_timeout')}: ${
                customer_product.sla.inactive_timeout
              }`}</div>
            )}

            {customer_product.sla?.max_resolution_time && (
              <div className="pl-10">{`- ${translate('max_resolution_time')}: ${
                customer_product.sla.max_resolution_time
              }`}</div>
            )}
            {customer_product.sla?.max_response_time && (
              <div className="pl-10">{`- ${translate('max_response_time')}: ${
                customer_product.sla.max_response_time
              }`}</div>
            )}
            {customer_product.sla?.timezone && (
              <div className="pl-10">{`- ${translate('timezone')}: ${
                customer_product.sla.timezone
              }`}</div>
            )}
            <div className="pl-10">{`- ${translate('weekdays')}:`}</div>

            {customer_product.sla?.weekdays &&
              Object.entries(customer_product.sla.weekdays).map((el, index) => {
                return (
                  <div key={index}>
                    <div className="itsm-product-details__row">
                      {`${el[0]}.`}
                      <span className="itsm-product-details__support-data">{`${translate(
                        'supportFrom'
                      )}: ${el[1].support_from}`}</span>
                    </div>
                    <div className="itsm-product-details__row">
                      <span className="itsm-product-details__support-data">{`${translate(
                        'supportTo'
                      )}: ${el[1].support_to}`}</span>
                    </div>
                  </div>
                )
              })}

            {!moreInfo && (
              <div
                className="pointer-cursor text-right"
                onClick={() => getMoreInfo(true)}
              >
                {`${translate('more')}...`}
              </div>
            )}
          </div>
          {moreInfo && (
            <div className="itsm-product-details__data-container">
              <div>
                {customer_product.active_from && (
                  <div>{`${translate('active_from')}: ${moment(
                    customer_product.active_from
                  ).format(DATE_FORMAT)}`}</div>
                )}
                {customer_product.location && (
                  <div>{`${translate('location')}: ${
                    customer_product.location.country
                  }`}</div>
                )}
                {customer_product.category && (
                  <div>{`${translate('category')}: ${
                    customer_product.category
                  }`}</div>
                )}
                {customer_product.chargeable && (
                  <div>{`${translate('chargeable')}: ${
                    customer_product.chargeable
                  }`}</div>
                )}

                {customer_product.resolution_time_eval && (
                  <div>{`${translate('resolution_time_eval')}: ${
                    customer_product.resolution_time_eval
                  }`}</div>
                )}
                {customer_product.breach_sla && (
                  <Fragment>
                    {' '}
                    <div>{`${translate('breach_sla')}:`}</div>
                    <div className="pl-10">{`- ${translate('limit')}: ${
                      customer_product.breach_sla.limit
                    }`}</div>
                    <div className="pl-10">{`- ${translate('period')}: ${
                      customer_product.breach_sla.period
                    }`}</div>
                  </Fragment>
                )}
                {customer_product.breach_sla && (
                  <div>{`${translate('dispatch')}: ${
                    customer_product.dispatch
                  }`}</div>
                )}
                {customer_product.breach_sla && (
                  <Fragment>
                    <div>{`${translate('pricing_policy')}:`}</div>
                    <div className="pl-10">{`- ${translate(
                      'additional_hour_rate'
                    )}: ${
                      customer_product.pricing_policy.additional_hour_rate
                    }`}</div>
                    <div className="pl-10">{`- ${translate('pricing_unit')}: ${
                      customer_product.pricing_policy.pricing_unit
                    }`}</div>
                    <div className="pl-10">{`- ${translate('rate')}: ${
                      customer_product.pricing_policy.rate
                    }`}</div>
                    <div className="pl-10">{`- ${translate(
                      'primary_hour_rate'
                    )}: ${
                      customer_product.pricing_policy.primary_hour_rate
                    }`}</div>
                    <div className="pl-10">{`- ${translate(
                      'primary_hours_number'
                    )}: ${
                      customer_product.pricing_policy.primary_hours_number
                    }`}</div>
                    <div className="pl-10">{`- ${translate(
                      'travel_distance_unit_rate'
                    )}: ${
                      customer_product.pricing_policy.travel_distance_unit_rate
                    }`}</div>
                    <div className="pl-10">{`- ${translate(
                      'travel_fix_rate'
                    )}: ${
                      customer_product.pricing_policy.travel_fix_rate
                    }`}</div>
                    <div className="pl-10">{`- ${translate(
                      'travel_hour_rate'
                    )}: ${
                      customer_product.pricing_policy.travel_hour_rate
                    }`}</div>
                  </Fragment>
                )}
              </div>

              <div
                className="pointer-cursor text-right"
                onClick={() => getMoreInfo(false)}
              >
                {`${translate('less')}...`}
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default ProductDetails
