import {IncTaskTabs} from '../typedf'

import {getCloseStateName} from './get-close-state-name'

export const setTab = ({
  step,
  assignee_state,
  state_id,
}: {
  step: string
  assignee_state: string
  state_id: number
}) => {
  switch (true) {
    case step === '0':
      return IncTaskTabs.OverviewTab

    case step === '1':
      return assignee_state

    case step === '2':
      return IncTaskTabs.InProgressTab

    case step === '3':
      return IncTaskTabs.ResolutionTab

    case step === '4':
      return (
        getCloseStateName(state_id)
          ?.toLowerCase()
          .replace(' ', '-') || ''
      )

    default:
      return ''
  }
}
