import {lazy} from 'react'

import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {AppVersion, TRoute, TRoutes} from '@/typedef'

import NotFound from '../../../components/error/not-found'
import BillingReport from '../sites/BillingReports/billing-report/billing-report'
import BillingReports from '../sites/BillingReports/billing-reports/billing-reports'
import CreditSystem from '../sites/BillingReports/CreditSystem/credit-system'
import Documents from '../sites/Documents/documents'
import Event from '../sites/Events/event/event'
import EventNew from '../sites/Events/event-new'
import Events from '../sites/Events/events'
import FinanceDashboard from '../sites/finance-dashboard/finance-dashboard'
import Incident from '../sites/Incident/incident'
import IncidentNew from '../sites/Incident/incident-new'
import Incidents from '../sites/Incident/incidents'
import IncidentTask from '../sites/incident-task/Incident-task'
import IncidentTaskNew from '../sites/incident-task/incident-task-new'
import UserInvitation from '../sites/Invitation/user-invitation/user-invitation'
import SettingsAnnouncements from '../sites/ITSMSettings/Announcements/announcements'
import SettingsAssignmentGroups from '../sites/ITSMSettings/AssignmentGroups/assignment-groups'
import SettingsAssignmentRules from '../sites/ITSMSettings/AssignmentRules/assignment-rules'
import SettingsCategories from '../sites/ITSMSettings/Categories/categories'
import SettingsChannels from '../sites/ITSMSettings/channels/channels'
import SettingsChannelDetails from '../sites/ITSMSettings/channel-details/channel-details'
import SettingsChannelNew from '../sites/ITSMSettings/channel-new/channel-new'
import SettingsInvitations from '../sites/ITSMSettings/Invitations/invitations'
import SettingsReasons from '../sites/ITSMSettings/reasons/reasons'
import SettingsRole from '../sites/ITSMSettings/Roles/roles'
import SettingsSupplierProducts from '../sites/ITSMSettings/SupplierProducts/supplier-products'
import SettingsCustomerProducts from '../sites/ITSMSettings/CustomerProducts/customer-products'
import SettingsCustomerProductsDetails from '../sites/ITSMSettings/CustomerProducts/customer-product-details'
import SettingsCustomerProductsNew from '../sites/ITSMSettings/CustomerProducts/customer-product-new'
import SettingsSupplierProductsDetails from '../sites/ITSMSettings/SupplierProducts/supplier-products-details'
import SettingsSupplierProductsNew from '../sites/ITSMSettings/SupplierProducts/supplier-product-new'
import SettingsUserGroups from '../sites/ITSMSettings/UserGroups/user-groups'
import SettingsNotifications from '../sites/ITSMSettings/Notifications/notifications'
import KRequest from '../sites/KRequest/k-request'
import KRequestNew from '../sites/KRequest/k-request-new'
import Requests from '../sites/KRequest/requests'
import SettingsAccountDetails from '../sites/MyAccounts/AccountDetails'
import SettingsMyAccounts from '../sites/MyAccounts/MyAccounts'
import SettingsUsersAccounts from '../sites/UsersAccounts/UsersAccounts'
import SettingsIdentities from '../sites/ITSMSettings/users/identities'
import SettingsUsers from '../sites/ITSMSettings/users/users'
import SettingsSuppliers from '../sites/ITSMSettings/users/suppliers/suppliers'
import Expenses from '../sites/Expenses/expenses'
import TimeSession from '../sites/time-session/time-session'
import UserSettings from '../sites/ITSMSettings/userSettings/userSettings'
import CustomReports from '../sites/CustomReports/custom-reports'
import OrgInvitation from '../sites/Invitation/org-invitation/org-invitation'
import SettingsLocations from '../sites/ITSMSettings/locations/locations'
import SettingsAnnouncementDetails from '../sites/ITSMSettings/Announcements/announcement-details'
import SettingsITSMUAnnouncementNew from '../sites/ITSMSettings/Announcements/announcement-new'
//import {NewAccount} from '@/modules/ITSM/sites/new-account/new-account'
import withSuspense from '@/components/with-suspense/with-suspense'

import {PriceFeatureFlagKeys} from '@/modules/typedef'

const DynamicDashboard = withSuspense(
  lazy(() => import('../sites/app-itsm-dashboard/app-itsm-dashboard'))
)

export const appItsmRouter: Partial<TRoutes> = {
  [TRoute.ITSM_USER_INVITATION]: {
    component: UserInvitation,
    props: {
      path: itsmRoutes.userInvitation.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_ORG_INVITATION]: {
    component: OrgInvitation,
    props: {
      path: itsmRoutes.channelInvitation.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_SERVICE_MANAGEMENT_DASHBOARD]: {
    component: DynamicDashboard,
    props: {
      path: itsmRoutes.serviceManagementDashboard.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_FINANCE_DASHBOARD]: {
    component: FinanceDashboard,
    props: {
      path: itsmRoutes.financeDashboard.path(),
      featureFlag: PriceFeatureFlagKeys.BILLING_COST,
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_BILLING_REPORTS]: {
    component: BillingReports,
    props: {
      path: itsmRoutes.billingReports.path(),
      featureFlag: PriceFeatureFlagKeys.BILLING_COST,
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_CREDIT_SYSTEM]: {
    component: CreditSystem,
    props: {
      path: itsmRoutes.creditSystem.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_BILLING_REPORT]: {
    component: BillingReport,
    props: {
      path: itsmRoutes.billingReportsState.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_INCIDENTS]: {
    component: Incidents,
    props: {
      path: itsmRoutes.serviceManagementIncidents.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_INCIDENT_NEW]: {
    component: IncidentNew,
    props: {
      path: itsmRoutes.serviceManagementIncidentsNew.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_INCIDENT_STATE]: {
    component: Incident,
    props: {
      path: itsmRoutes.serviceManagementIncidentsState.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_INCIDENT_DETAIL]: {
    component: Incident,
    props: {
      path: itsmRoutes.serviceManagementIncidentsDetail.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_INCIDENT_TASK_NEW]: {
    component: IncidentTaskNew,
    props: {
      path: itsmRoutes.serviceManagementIncidentsTaskNew.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_INCIDENT_TASK_DETAIL]: {
    component: IncidentTask,
    props: {
      path: itsmRoutes.serviceManagementIncidentsTaskState.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_DOCUMENTS]: {
    component: Documents,
    props: {
      path: itsmRoutes.serviceManagementDocuments.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_REQUESTS]: {
    component: Requests,
    props: {
      path: itsmRoutes.serviceManagementRequests.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_REQUEST_NEW]: {
    component: KRequestNew,
    props: {
      path: itsmRoutes.serviceManagementRequestsNew.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_REQUESTS_STATE]: {
    component: KRequest,
    props: {
      path: itsmRoutes.serviceManagementRequestsState.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_EVENTS]: {
    component: Events,
    props: {
      path: itsmRoutes.serviceManagementEventsPage.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
        [AppVersion.APP]: false,
      },
    },
  },
  [TRoute.ITSM_EVENT_NEW]: {
    component: EventNew,
    props: {
      path: itsmRoutes.serviceManagementEventsNew.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
        [AppVersion.APP]: false,
      },
    },
  },
  [TRoute.ITSM_EVENT]: {
    component: Event,
    props: {
      path: itsmRoutes.serviceManagementEventsDetail.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
        [AppVersion.APP]: false,
      },
    },
  },
  [TRoute.ITSM_TIME_SESSION]: {
    // @ts-ignore needs to be refactored to TS
    component: TimeSession,
    props: {
      path: itsmRoutes.serviceManagementTimeSession.path(),
      exact: true,
      private: true,
      featureFlag: PriceFeatureFlagKeys.TIME_TRACKING,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_EXPENSES]: {
    component: Expenses,
    props: {
      path: itsmRoutes.serviceManagementExpenses.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_CUSTOM_REPORTS]: {
    component: CustomReports,
    props: {
      path: itsmRoutes.serviceManagementCustomReports.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_SETTINGS_USER]: {
    component: UserSettings,
    props: {
      path: itsmRoutes.settingsITSMUser.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_ROLE]: {
    component: SettingsRole,
    props: {
      path: itsmRoutes.settingsITSMRoles.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CHANNEL_NEW]: {
    component: SettingsChannelNew,
    props: {
      path: itsmRoutes.settingsITSMChannelNew.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CHANNELS]: {
    component: SettingsChannels,
    props: {
      path: itsmRoutes.settingsITSMChannels.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CHANNEL_DETAILS]: {
    component: SettingsChannelDetails,
    props: {
      path: itsmRoutes.settingsITSMChannelDetails.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_IDENTITIES]: {
    component: SettingsIdentities,
    props: {
      path: itsmRoutes.settingsITSMIdentities.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_USERS]: {
    component: SettingsUsers,
    props: {
      path: itsmRoutes.settingsITSMUsers.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_SUPPLIERS]: {
    component: SettingsSuppliers,
    props: {
      path: itsmRoutes.settingsITSMSuppliers.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_INVITATIONS]: {
    component: SettingsInvitations,
    props: {
      path: itsmRoutes.settingsITSMInvitations.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CATEGORIES]: {
    component: SettingsCategories,
    props: {
      path: itsmRoutes.settingsITSMCategories.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_REASONS]: {
    component: SettingsReasons,
    props: {
      path: itsmRoutes.settingsITSMReasons.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_ASSIGNMENT_GROUPS]: {
    component: SettingsAssignmentGroups,
    props: {
      path: itsmRoutes.settingsITSMAsignementGroups.path(),
      exact: true,
      private: true,
      featureFlag: PriceFeatureFlagKeys.ASSIGNMENT_GROUPS,
    },
  },
  [TRoute.ITSM_SETTINGS_ASSIGNMENT_RULES]: {
    component: SettingsAssignmentRules,
    props: {
      path: itsmRoutes.settingsITSMUAssignRules.path(),
      exact: true,
      private: true,
      featureFlag: PriceFeatureFlagKeys.ASSIGNMENT_GROUPS,
    },
  },
  [TRoute.ITSM_SETTINGS_USER_GROUPS]: {
    component: SettingsUserGroups,
    props: {
      path: itsmRoutes.settingsITSMUserGroups.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_NOTIFICATIONS]: {
    component: SettingsNotifications,
    props: {
      path: itsmRoutes.settingsITSMNotifications.path(),
      exact: true,
      private: true,
    },
  },

  [TRoute.ITSM_SETTINGS_ANNOUNCEMENTS]: {
    component: SettingsAnnouncements,
    props: {
      path: itsmRoutes.settingsITSMUAnnouncements.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_SUPPLIER_PRODUCTS]: {
    component: SettingsSupplierProducts,
    props: {
      path: itsmRoutes.settingsITSMSupplierProducts.path(),
      exact: true,
      private: true,
      featureFlag: PriceFeatureFlagKeys.CUSTOMER_PRODUCT,
    },
  },
  [TRoute.ITSM_SETTINGS_SUPPLIER_PRODUCT_DETAILS]: {
    component: SettingsSupplierProductsDetails,
    props: {
      path: itsmRoutes.settingsITSMSupplierProductsDetail.path(),
      exact: true,
      private: true,
      featureFlag: PriceFeatureFlagKeys.CUSTOMER_PRODUCT,
    },
  },
  [TRoute.ITSM_SETTINGS_ANNOUNCEMENT_NEW]: {
    component: SettingsITSMUAnnouncementNew,
    props: {
      path: itsmRoutes.settingsITSMAnnouncementNew.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_ANNOUNCEMENT_DETAILS]: {
    component: SettingsAnnouncementDetails,
    props: {
      path: itsmRoutes.settingsITSMAnnouncementsDetail.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CUSTOMER_PRODUCTS]: {
    component: SettingsCustomerProducts,
    props: {
      path: itsmRoutes.settingsITSMCustomerProducts.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CUSTOMER_PRODUCT_DETAILS]: {
    component: SettingsCustomerProductsDetails,
    props: {
      path: itsmRoutes.settingsITSMCustomerProductsDetail.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_CUSTOMER_PRODUCT_NEW]: {
    component: SettingsCustomerProductsNew,
    props: {
      path: itsmRoutes.settingsITSMCustomerProductNew.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_SUPPLIER_PRODUCT_NEW]: {
    component: SettingsSupplierProductsNew,
    props: {
      path: itsmRoutes.settingsITSMSupplierProductNew.path(),
      exact: true,
      private: true,
    },
  },

  [TRoute.ITSM_SETTINGS_MY_ACCOUNTS]: {
    component: SettingsMyAccounts,
    props: {
      path: itsmRoutes.settingsITSMmyAccounts.path(),
      exact: true,
      private: true,
    },
  },
  [TRoute.ITSM_SETTINGS_USER_ACCOUNTS]: {
    component: SettingsUsersAccounts,
    props: {
      path: itsmRoutes.settingsITSMusersAccounts.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_SETTINGS_ACCOUNTS_DETAILS]: {
    // @ts-ignore needs to be refactored to TS
    component: SettingsAccountDetails,
    props: {
      path: itsmRoutes.settingsITSMmyAccountsDetails.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  [TRoute.ITSM_SETTINGS_USERS_ACCOUNTS_DETAILS]: {
    // @ts-ignore needs to be refactored to TS
    component: SettingsAccountDetails,
    props: {
      path: itsmRoutes.settingsITSMusersAccountsDetails.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },
  //  [TRoute.ITSM_SETTINGS_NEW_ACCOUNT]: {
  //    component: NewAccount,
  //    props: {
  //      path: itsmRoutes.settingsITSMNewAccount.path(),
  //      exact: true,
  //      private: true,
  //      userPermissions: {
  //        [AppVersion.PORTAL]: false,
  //      },
  //    },
  //  },
  [TRoute.ITSM_SETTINGS_LOCATIONS]: {
    component: SettingsLocations,
    props: {
      path: itsmRoutes.settingsITSMlocations.path(),
      exact: true,
      private: true,
      userPermissions: {
        [AppVersion.PORTAL]: false,
      },
    },
  },

  [TRoute.ITSM_NOT_FOUND]: {
    component: NotFound,
    props: {
      path: '*',
    },
  },
}
