import {TDefaultQuickFilters} from '@/components/filter/typedef'

enum MainIncidentsQuickFiltersUP {
  Opened = 'opened',
  On_hold = 'on_hold',
  Resolved = 'resolved',
}

export type TIncidentsQuickFiltersUP =
  | MainIncidentsQuickFiltersUP
  | TDefaultQuickFilters

export const IncidentsQuickFiltersUP = {
  ...MainIncidentsQuickFiltersUP,
  ...TDefaultQuickFilters,
}

export enum IncRequestUPTabs {
  OverviewTab = 'overview',
  CommentsTab = 'comments',
  TimeLogsTab = 'time-logs',
  ResolvedTab = 'resolved',
}
