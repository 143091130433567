import {RequestStatus} from '@/typedef'
import {ModalType} from '@/components/modal/typedef'

import * as ActionTypes from '../actionTypes'

import {TUiActionTypes} from './typedef'

type TUiState = {
  uiModalMessage: string | React.ReactElement
  uiModalType: ModalType
  modalRedirectUrl: string
  error: any
  resolvers: (() => void)[]
  redirectUrl?: string
  version: {
    requestStatus: RequestStatus
    currentVersion: unknown
    error: any
  }
  redirectToList: boolean
  isHistoryBlock: boolean
}

const initialState: TUiState = {
  uiModalMessage: '',
  uiModalType: ModalType.INFO,
  modalRedirectUrl: '',
  error: {},
  resolvers: [],
  redirectUrl: undefined,
  version: {
    requestStatus: RequestStatus.INITIAL,
    currentVersion: null,
    error: '',
  },
  redirectToList: false,
  isHistoryBlock: false,
}

export default function uiReducer(
  state = initialState,
  action: TUiActionTypes
): TUiState {
  switch (action.type) {
    case ActionTypes.RESET_UI_STATE: {
      return {
        ...initialState,
      }
    }

    case ActionTypes.FETCH_VERSION_REQUESTED: {
      return {
        ...state,
        version: {
          ...state.version,
          requestStatus: RequestStatus.REQUESTED,
        },
      }
    }

    case ActionTypes.FETCH_VERSION_SUCCESS: {
      return {
        ...state,
        version: {
          ...state.version,
          requestStatus: RequestStatus.SUCCEEDED,
          currentVersion: action.payload,
        },
      }
    }

    case ActionTypes.FETCH_VERSION_FAILED: {
      return {
        ...state,
        version: {
          ...state.version,
          requestStatus: RequestStatus.FAILED,
          error: action.payload,
        },
      }
    }

    case ActionTypes.SET_TIME_SESSION_MODAL: {
      return {
        ...state,
        uiModalMessage: action.payload.message,
        uiModalType: action.payload.type,
        modalRedirectUrl: action.payload.modalRedirectUrl,
        resolvers: action.payload.resolvers || [],
        error: action.payload.err,
      }
    }

    case ActionTypes.CLEAR_UI_MODAL_MESSAGE: {
      return {
        ...state,
        uiModalMessage: '',
        uiModalType: ModalType.INFO,
        modalRedirectUrl: '',
        error: '',
      }
    }

    case ActionTypes.SET_REDIRECTION: {
      return {
        ...state,
        redirectUrl: action.payload,
      }
    }

    case ActionTypes.SET_REDIRECT_TO_LIST: {
      return {
        ...state,
        redirectToList: action.payload,
      }
    }

    case ActionTypes.SET_HISTORY_BLOCK: {
      return {
        ...state,
        isHistoryBlock: action.payload,
      }
    }

    default:
      return state
  }
}
