import {RequestStatus} from '@/typedef'
import {TActionTypes, TAnyAction} from '@/redux/types'
import {
  ISubscriptionDatum,
  PriceFeatureFlagKeys,
  TCustomer,
} from '@/modules/typedef'

type TInitialState = {
  statuses: {
    fetchBillingSubscriptions: RequestStatus
  }
  customer: TCustomer | undefined
  subscriptions: ISubscriptionDatum[] | undefined
  featureFlags: Array<PriceFeatureFlagKeys>
  transactionAmount: number
  maxTransactionNumber: number
  refetchDelay: number
}

const initialState: TInitialState = {
  statuses: {
    fetchBillingSubscriptions: RequestStatus.INITIAL,
  },
  customer: undefined,
  subscriptions: undefined,
  featureFlags: [],
  transactionAmount: 0,
  maxTransactionNumber: 0,
  refetchDelay: 5000,
}

export default function billingReducer(
  state = initialState,
  action: TAnyAction
): TInitialState {
  switch (action.type) {
    case TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_REQUESTED: {
      return {
        ...state,
        statuses: {fetchBillingSubscriptions: RequestStatus.REQUESTED},
      }
    }

    case TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_SUCCEEDED: {
      return {
        ...state,
        ...action.payload,
        statuses: {fetchBillingSubscriptions: RequestStatus.SUCCEEDED},
      }
    }
    case TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        statuses: {fetchBillingSubscriptions: RequestStatus.FAILED},
      }
    }

    case TActionTypes.FETCH_BILLING_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customer: action.payload,
      }
    }

    case TActionTypes.SET_BALANCE_TRANSACTION: {
      return {
        ...state,
        transactionAmount: action.payload,
      }
    }

    case TActionTypes.SET_TRANSACTION_NUMBER: {
      return {
        ...state,
        maxTransactionNumber: action.payload,
      }
    }
    case TActionTypes.SET_REFETCH_DELAY: {
      return {
        ...state,
        ...(action.payload && {refetchDelay: action.payload}),
      }
    }

    default: {
      return state
    }
  }
}
