import {translate} from '@/services/i18n'

import {TIncidentState, TIncidentTask} from './typedf'

export const INCIDENT_TASK_STATES_NAMES: TIncidentState = {
  New: translate('new'),
  In_progress: translate('in_progress'),
  Closed_complete: translate('closed_completed'),
  Closed_incomplete: translate('closed_incomplete'),
  Closed_skipped: translate('closed_skipped'),
  Resolution: translate('resolution'),
}

export const INCIDENT_TASK_STATE_OPTIONS = [
  {value: '0', name: translate('open')},
  {value: '1', name: translate('in_progress')},
  {value: '2', name: translate('closed_completed')},
  {value: '3', name: translate('closed_incomplete')},
  {value: '4', name: translate('closed_skipped')},
  {value: '5', name: translate('resolution')},
]

export const getInitialValues = (values: TIncidentTask) => {
  return {
    number: values.number,
    priority: values.priority,
    caller: values.caller?.uuid,
    location: values.location?.uuid,
    item: values.item,
    short_description: values.short_description,
    description: values.description,
    customer_product: values.customer_product?.uuid,
    supplier_product: values.supplier_product?.uuid,
    assignment_group: values.assignment_group?.uuid,
    assigned_to: values.assigned_to?.uuid,
  }
}
