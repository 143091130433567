import request from 'superagent'

import {getHeaders, identityApi} from '@/modules/CMDB/api/generate-cmdb-api-url'
import {getBackendTokenHeaders} from '@/services/api'
import {ISuperAgentResponse} from '@/modules/CMDB/api/typedf'
import {TCmdbIdentityMe} from '@/modules/CMDB/typedef'

export const fetchCmdbIdentityMe = async (): Promise<ISuperAgentResponse<
  TCmdbIdentityMe
>> => {
  return await request.get(identityApi().get).set(getBackendTokenHeaders())
}

export const postCmdbIdentityMe = async (id: string) => {
  return await request
    .post(identityApi().post)
    .set(getHeaders())
    .send({
      accountID: id,
    })
}
