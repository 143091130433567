import React, {useCallback} from 'react'

import 'moment-duration-format'
import queryString from 'query-string'
import {useSelector} from 'react-redux'

import {translate} from '@/services/i18n'
import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import {selectUserID} from '@/redux/user/userSelector'
import {Entities} from '@/typedef'
import {TabTitle} from '@/components/tab-title/tab-title'
import {Tabs} from '@/components/tabs/tabs'
import {Skeleton} from '@/components/skeleton'
import {TabsSkeleton} from '@/components/tabs'
import {useIsStripeFeatureAllowed} from '@/hooks/use-stripe-feature-flags'
import {Paper} from '@/components/paper/paper'

import Comments from '../../comments/comments'
import WorkNotes from '../../work-notes/work-notes'
import AttachmentsTab from '../../attachments/attachments'
import TimeLogs from '../../time-logs/time-logs'
import TimeLogDetail from '../../time-logs/time-log-detail'
import Map from '../../map/map'
import LocationInfo from '../../location-info/location-info'
import {setCurrentTab, setOnHold} from '../../incident/incident-reducer'
import ExpenseDetail from '../../incident/incident-expenses/incident-expense-detail'
import {useStateContext} from '../../incident/incident-context'
import RejectedReason from '../../incident/incident-rejected-reason'
import Expenses from '../../incident/incident-expenses/expenses'
import ConfirmButtons from '../../incident/incident-confirm-btns'
import WatchList from '../incident-k-request-watch-list'
import {AssigneeStates, IncidentState, IncTabs} from '../constants'
import OnHoldReason from '../incident-k-request-on-hold-reason'
import CancellationNote from '../incident-k-request-cancellation-note'
import IncidentAssigneeStatusForm from '../incident-k-request-assignee-status-form/incident-k-request-assignee-status-form'
import ResolutionForm from '../incident-k-request-resolution/incident-k-request-resolution-form'
import ResolutionSummary from '../incident-k-request-resolution-summary'
import {checkIfDisabled} from '../utils/check-if-disabled'
import IncidentStatusLog from '../incident-k-request-status-log'
import SVR from '../incident-k-request-svr/incident-k-request-svr'
import {findDisabledSteps} from '../utils/find-disabled-steps'
import {TIncident} from '../typedf'
import IncidentDetailForm from '../incident-k-request-detail-form/incident-k-request-detail-form'
import {isTimelogDisabled} from '../utils/is-timelog-diasabled'
import Modal from '@/components/modal/modal'

import {ModalType} from '@/components/modal/typedef'
import {PriceFeatureFlagKeys} from '@/modules/typedef'

import {
  getFromInProgressCancelState,
  getFromInProgressState,
  getFromOpenState,
} from './utils'

import './incident-k-request-tabs.scss'

const IncidentKRequestTabs = () => {
  const {incState, entityData, dispatch, entity} = useStateContext<TIncident>()

  const {isFeatureAllowed} = useIsStripeFeatureAllowed()

  const {currentTab, isRejectReason, isOnHold} = incState

  const {assignee_state, state_id, location, cancellation_notes, timelogs} =
    entityData || {}

  const isDisabled = !!(entityData && state_id && checkIfDisabled(state_id))
  const userID = useSelector(selectUserID)

  const onTabChange = useCallback(
    (tab: string) => {
      const tabHasChanged = tab !== currentTab

      if (tabHasChanged) {
        dispatch(setCurrentTab(tab))
      }
    },
    [currentTab, dispatch]
  )

  const inProgressCondition = !!(state_id && getFromInProgressState(state_id))

  const fromOpenState = !!(entityData && getFromOpenState(entityData))

  const fromInProgress = !!(
    entityData && getFromInProgressCancelState(entityData)
  )

  const assignedToCurrentUser = entityData?.assigned_to?.uuid === userID

  const statusTitle =
    entity === Entities.INCIDENT
      ? translate('incident_status_log')
      : translate('k_request_status_log')
  return (
    <Skeleton active={!currentTab} view={<TabsSkeleton />}>
      <Modal
        modalType={ModalType.INFO}
        open={isOnHold}
        onCancel={() => dispatch(setOnHold(false))}
      >
        <OnHoldReason />
      </Modal>
      <Tabs
        tabPosition={'left'}
        onTabClick={onTabChange}
        activeKey={currentTab || IncTabs.OverviewTab}
        className="incident-k-request-tabs"
        items={[
          {
            key: IncTabs.OverviewTab,
            label: (
              <span className="incident-k-request-tabs__state-tab">
                {translate('overview')}
              </span>
            ),
            children: (
              <div>
                {currentTab === IncTabs.OverviewTab && entityData && (
                  <Paper>
                    <TabTitle title={translate('overview')} />
                    {state_id !== undefined &&
                      IncidentState.Cancelled &&
                      cancellation_notes && (
                        <CancellationNote
                          cancellationNote={cancellation_notes}
                        />
                      )}
                    {location && <LocationInfo location={location} />}
                    <IncidentDetailForm />
                  </Paper>
                )}
              </div>
            ),
          },
          {
            key:
              entity === Entities.INCIDENT
                ? IncTabs.IncidentStatusLogTab
                : IncTabs.KRequestStatusLogTab,
            label: statusTitle,
            children: (
              <div>
                <Paper>
                  <TabTitle title={statusTitle} />
                  <IncidentStatusLog record={entityData} />
                </Paper>
              </div>
            ),
          },
          {
            key: IncTabs.CommentsTab,
            label: translate('comments'),
            children: (
              <div>
                {currentTab === IncTabs.CommentsTab && (
                  <Paper>
                    <TabTitle title={translate('comments')} />
                    <Comments isDisabled={isDisabled} />
                  </Paper>
                )}
              </div>
            ),
          },
          {
            key: IncTabs.AttachmentsTab,
            label: translate('attachments'),
            children: (
              <div>
                {currentTab === IncTabs.AttachmentsTab && (
                  <Paper>
                    <TabTitle title={translate('attachments')} />
                    <AttachmentsTab disField={isDisabled} />
                  </Paper>
                )}
              </div>
            ),
          },
          {
            key: IncTabs.WorkNotesTab,
            label: translate('work_notes'),
            children: (
              <div>
                {currentTab === IncTabs.WorkNotesTab && (
                  <Paper>
                    <TabTitle title={translate('work_notes')} />
                    <WorkNotes isDisabled={isDisabled} />
                  </Paper>
                )}
              </div>
            ),
          },
          ...(fromOpenState
            ? [
                {
                  key: assignee_state || AssigneeStates.Unset,
                  label: (
                    <span className="incident-k-request-tabs__state-tab">
                      {assignee_state && parseToUpperCase(assignee_state)}
                    </span>
                  ),
                  children: (
                    <div>
                      {currentTab === assignee_state && (
                        <Paper>
                          {assignedToCurrentUser &&
                            assignee_state === AssigneeStates.Proposed &&
                            (isRejectReason ? (
                              <RejectedReason />
                            ) : (
                              <ConfirmButtons />
                            ))}
                          {location && <LocationInfo location={location} />}
                          <IncidentAssigneeStatusForm />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),
          ...(isFeatureAllowed(PriceFeatureFlagKeys.TIME_TRACKING) &&
          fromOpenState &&
          !!timelogs &&
          entityData &&
          !isTimelogDisabled(entityData)
            ? [
                {
                  key: IncTabs.TimeLogsTab,
                  label: translate('time_logs'),
                  children: (
                    <div>
                      {currentTab === IncTabs.TimeLogsTab && (
                        <Paper>
                          {queryString.parse(window.location.search).timelog ? (
                            <TimeLogDetail />
                          ) : (
                            <>
                              <TabTitle title={translate('time_logs')} />
                              <TimeLogs
                                editableState={inProgressCondition}
                                checkInBtnVisible={
                                  state_id === IncidentState.Open ||
                                  state_id === IncidentState.Pre_on_hold ||
                                  state_id === IncidentState.In_progress ||
                                  state_id === IncidentState.On_hold
                                }
                              />
                            </>
                          )}
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(fromInProgress
            ? [
                {
                  key:
                    state_id === IncidentState.On_hold
                      ? IncTabs.OnHoldTab
                      : IncTabs.InProgressTab,
                  label: (
                    <span className="incident-k-request-tabs__state-tab">
                      {state_id === IncidentState.On_hold
                        ? translate('on_hold')
                        : translate('in_progress')}
                    </span>
                  ),
                  children: (
                    <div>
                      {currentTab ===
                        (state_id === IncidentState.On_hold
                          ? IncTabs.OnHoldTab
                          : IncTabs.InProgressTab) && (
                        <Paper>
                          <TabTitle title={translate('comments')} />
                          <Comments isDisabled={isDisabled} />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(fromInProgress
            ? [
                {
                  key: IncTabs.MapTab,
                  label: translate('engineer_location'),
                  children: (
                    <div>
                      {currentTab === IncTabs.MapTab && (
                        <Paper>
                          <TabTitle title={translate('engineer_location')} />
                          <Map />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(isFeatureAllowed(PriceFeatureFlagKeys.SVR) && fromInProgress
            ? [
                {
                  key: IncTabs.SvrTab,
                  label: translate('SVR'),
                  children: (
                    <div>
                      {currentTab === IncTabs.SvrTab && (
                        <Paper>
                          <TabTitle title={translate('SVR')} />
                          <SVR />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(isFeatureAllowed(PriceFeatureFlagKeys.EXPENSES) && fromInProgress
            ? [
                {
                  key: IncTabs.ExpensesTab,
                  label: translate('expenses'),
                  children: (
                    <div>
                      {currentTab === IncTabs.ExpensesTab && (
                        <Paper>
                          {queryString.parse(window.location.search).timelog ? (
                            <ExpenseDetail isDisabled={isDisabled} />
                          ) : (
                            <>
                              <TabTitle title={translate('expenses')} />
                              <Expenses
                                entityData={entityData}
                                entity={entity}
                              />
                            </>
                          )}
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(fromInProgress
            ? [
                {
                  key: IncTabs.WatchListTab,
                  label: translate('watch_list'),
                  children: (
                    <div>
                      {currentTab === IncTabs.WatchListTab && (
                        <Paper>
                          <TabTitle title={translate('watch_list')} />
                          {currentTab === IncTabs.WatchListTab && (
                            <WatchList isDisabled={isDisabled} />
                          )}
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(inProgressCondition ||
          (state_id === IncidentState.Cancelled &&
            findDisabledSteps(entityData).indexOf(3) === -1)
            ? [
                {
                  key: IncTabs.ResolutionTab,
                  label: (
                    <span className="incident-k-request-tabs__state-tab">
                      {translate('resolution')}
                    </span>
                  ),
                  children: (
                    <div>
                      {currentTab === IncTabs.ResolutionTab && (
                        <Paper>
                          <ResolutionForm editableState={inProgressCondition} />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id === IncidentState.Resolved ||
          state_id === IncidentState.Closed ||
          (state_id === IncidentState.Cancelled &&
            findDisabledSteps(entityData).indexOf(4) === -1)
            ? [
                {
                  key: IncTabs.ResolutionTab,
                  label: (
                    <span className="incident-k-request-tabs__state-tab">
                      {translate('resolved')}
                    </span>
                  ),
                  children: (
                    <div>
                      {currentTab === IncTabs.ResolvedTab && (
                        <Paper>
                          <ResolutionSummary />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </Skeleton>
  )
}

export default IncidentKRequestTabs
