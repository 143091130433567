import React from 'react'

import classNames from 'classnames'
import {Drawer as AntDrawer, DrawerProps} from 'antd'

import './drawer.scss'

export const Drawer: React.FC<DrawerProps> = ({className, ...props}) => {
  const classes = classNames('drawer', className)

  return <AntDrawer className={classes} {...props} />
}
