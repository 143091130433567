import React, {FC} from 'react'

import classnames from 'classnames'

import {Paper} from '@/components/paper/paper'
import {translate} from '@/services/i18n'
import {kFormatter} from '@/utils/kFormatter'
import {differenceGrowth} from '@/utils/difference-growth'
import {Skeleton} from '@/components/skeleton'
import FinanceDashboardTileSkeleton from '@/modules/ITSM/sites/finance-dashboard/tile/finance-dashboard-tile-skeleton'

import './finance-dashboard-tile.scss'

type TProps = {
  isRevenue?: boolean
  currentTotal?: number
  previousTotal?: number
  loading: boolean
}

export const FinanceDashboardTile: FC<TProps> = ({
  isRevenue,
  currentTotal,
  previousTotal,
  loading,
}) => {
  const totalInfo =
    currentTotal !== undefined && previousTotal !== undefined ? (
      <>
        {kFormatter(currentTotal)} EUR
        <span
          className={classnames('finance-dashboard-tile__compare-total', {
            'finance-dashboard-tile__compare-total--negative':
              currentTotal - previousTotal < 0,
          })}
        >
          {`${currentTotal - previousTotal > 0 ? '+' : ''}${differenceGrowth(
            currentTotal,
            previousTotal
          )}`}
        </span>
      </>
    ) : (
      translate('no_data_available')
    )

  return (
    <Skeleton
      className="finance-dashboard-tile__skeleton"
      view={<FinanceDashboardTileSkeleton />}
      active={loading}
    >
      <Paper className="finance-dashboard-tile">
        <div className="finance-dashboard-tile__title">
          {translate(isRevenue ? 'total_costs' : 'total_revenue')}
        </div>
        <div className="finance-dashboard-tile__total">{totalInfo}</div>
        <div className="finance-dashboard-tile__label">
          {translate('last_month')}
        </div>
        {/*// TODO: add images with licence*/}
        {/*<img*/}
        {/*  className="finance-dashboard-tile__image"*/}
        {/*  src={isRevenue ? womanImage : manImage}*/}
        {/*  title={isRevenue ? 'Total costs' : 'Total revenue'}*/}
        {/*/>*/}
      </Paper>
    </Skeleton>
  )
}
