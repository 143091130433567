import request from 'superagent'

import {ISaasApplicationDiscoverReq} from '@/modules/SAAS/typedf'
import {
  appApi,
  getHeaders,
  groupsApi,
} from '@/modules/SAAS/api/generate-saas-api-url'
import {createSpaceHeader} from '@/utils/create-space-header'

import {SaasApplicationCreateRequest} from '../services/openAPI/Api'

export const fetchApps = async () =>
  await request.post(appApi.get).set(getHeaders())

export const postApp = async (data: SaasApplicationCreateRequest) =>
  await request
    .post(appApi.post)
    .set(getHeaders())
    .send(data)

// export async function postApp<T extends Record<string, any>>(data: T) {
//   await request
//     .post(appApi.post)
//     .set(getHeaders())
//     .send(data)

export const postAppDiscover = async (data: ISaasApplicationDiscoverReq) =>
  await request
    .post(appApi.post)
    .set(getHeaders())
    .send(data)

export const fetchApp = async ({id}: {id: string}) =>
  await request.get(appApi.getOne(id)).set(getHeaders())

export const patchApp = async (
  values: SaasApplicationCreateRequest,
  id: string
) =>
  await request
    .patch(appApi.patch(id))
    .set(getHeaders())
    .send(values)

export const fetchUserApps = async ({id}: {id: string}) =>
  await request.get(appApi.user(id)).set(getHeaders())

export const fetchDiscoverApps = async () =>
  await request.get(appApi.discoverApps()).set(getHeaders())

export const fetchSystemUserApps = async ({
  subId,
  spaceId,
}: {
  subId: string
  spaceId?: string
}) =>
  await request
    .get(appApi.systemUser(subId))
    .set({...getHeaders(), ...(spaceId ? createSpaceHeader(spaceId) : {})})

export type TUnassignGroupData = {
  appId: string
  groups: Array<string>
}

export const postUnassignGroup = async ({appId, groups}: TUnassignGroupData) =>
  await request
    .post(groupsApi.unassign(appId))
    .set(getHeaders())
    .send({groups})

export const postAssignGroup = async ({appId, groups}: TUnassignGroupData) =>
  await request
    .post(groupsApi.assign(appId))
    .set(getHeaders())
    .send({groups})
