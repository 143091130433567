import React from 'react'

import {Step} from '@/modules/ITSM/components/accept-invite/accept-invite-step/accept-invite-step'
import {AccountCreateStep, TSignupSteps} from '@/sites/account-default/typedef'

import './signup-stepper.scss'

import {findActiveStepIndex} from '@/sites/account-default/utils/find-active-step-index'

type TProps = {
  steps: TSignupSteps
  currentStep: AccountCreateStep
}

export const SignupStepper: React.FC<TProps> = ({steps, currentStep}) => {
  const activeStepIndex = findActiveStepIndex(steps, currentStep)

  return (
    <div className="signup-stepper">
      {Object.values(steps).map(({header}, index) => {
        return (
          <Step key={index} active={activeStepIndex === index} title={header} />
        )
      })}
    </div>
  )
}
