import React, {useState} from 'react'

import {Form, Input, Select} from 'antd'
import {v4 as uuid} from 'uuid'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {isEmailValid} from '@/utils/is-email-valid'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'
import {useFetchHook} from '@/hooks/useFetch'
import {
  patchNotification,
  postNotification,
} from '@/modules/ITSM/api/notificationRequests'
import {TNotification} from '@/modules/ITSM/typedef'
import {checkIsLoading} from '@/utils/check-is-loading'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {userGroupsApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {getAssignmentGroupsName} from '../../../utils/Helpers'

export default function NotificationsDrawer() {
  const {
    getEntityDataInit,
    getEntityData,
    onClose,
    record,
  } = useListTableStateContext()

  const [form] = Form.useForm()
  const [btnDisabled, setBtnDisabled] = useState(true)

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const handleSubmit = async (data: TNotification) => {
    try {
      handleRequested()

      if (record?.uuid) {
        await patchNotification(record?.uuid, data)
        getEntityData()
        setBtnDisabled(true)
      } else {
        await postNotification(uuid(), data)
        getEntityDataInit()
        onClose()
      }

      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  return (
    <DrawerForm
      form={form}
      onFinish={handleSubmit}
      loadingSubmit={checkIsLoading(status) || btnDisabled}
      handleCancel={onClose}
      onValuesChange={() => btnDisabled && setBtnDisabled(false)}
    >
      <div>
        <FormItemGeneric<TNotification>
          label={translate('name')}
          name="name"
          initialValue={record?.name}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            autoComplete="off"
            onFocus={setAutocompleteNone}
            disabled={record && !!Object.keys(record).length}
          />
        </FormItemGeneric>

        <SelectWithBM
          disabled={record && !!Object.keys(record).length}
          api={userGroupsApi().get}
          label={translate('user_group')}
          name="user_group"
          initialValue={record?.user_group ? [record.user_group] : []}
          optionContent={getAssignmentGroupsName}
          getPopupContainer={() => document.body}
        />

        <FormItemGeneric<TNotification>
          label={translate('external_emails')}
          name="external_emails"
          initialValue={record?.external_emails || []}
          rules={[
            {
              required: true,
              message: translate('external_emails_req'),
            },
            () => ({
              validator(_, value) {
                if (
                  !value ||
                  value.some((email: string) => !isEmailValid(email))
                ) {
                  return Promise.reject(translate('validate_external_email'))
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Select mode="tags" open={false} className="full-width"></Select>
        </FormItemGeneric>
      </div>
    </DrawerForm>
  )
}
