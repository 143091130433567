import React, {useContext, useState} from 'react'

import {Popconfirm, Row} from 'antd'
import {connect, useDispatch} from 'react-redux'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {useEffectAfterMount} from '@/hooks/useEffectAfterMount'
import {setRefresh} from '@/redux/actions'
import {translate} from '@/services/i18n'
import {RefreshType, RequestStatus} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import DataTable from '@/components/data-table/data-table'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {AbilityContext} from '@/services/abilities/ability-context'

import {deleteGrant} from '../../api/accountRequests'
import EditGrant from '../../components/accountsList/EditGrant'
import {
  useFetchOrgs,
  useFetchSubs,
  useGetAccount,
} from '../../hooks/accountHooks'

import './AccountDetails.scss'

const orgColumns = [
  {
    title: translate('name'),
    dataIndex: 'name',
  },
  {
    title: translate('id'),
    dataIndex: 'id',
  },
]

const Account = ({
  match: {
    params: {id},
  },
  location: {pathname},
  general,
  history,
}) => {
  const hasUserAccounts = pathname.includes('itsm-users-accounts')
  const ability = useContext(AbilityContext)
  const [record, setRecord] = useState({})
  const [edit_visibility, setEditVisibility] = useState(false)

  const [account] = useGetAccount(id)
  const [orgs, loadingOrgs] = useFetchOrgs(id)
  const [subs, grantLevels, loadingSubs] = useFetchSubs(id, hasUserAccounts)

  const dispatch = useDispatch()

  const handleDeleteGrant = async recordId => {
    try {
      handleMessage(RequestStatus.REQUESTED)

      await deleteGrant(id, {sub: recordId})

      handleMessage(RequestStatus.SUCCEEDED, {
        successMessage: 'successfully_deleted',
      })
      dispatch(setRefresh(true, RefreshType.Subs))
    } catch (err) {
      dispatch(setToastMessage({message: err}))
      handleMessage(RequestStatus.FAILED)
    }
  }

  const getSubsColumns = () => {
    return [
      {
        title: translate('name'),
        dataIndex: 'name',
      },
      {
        title: translate('email'),
        dataIndex: 'email',
      },
      {
        title: translate('grantLevel'),
        dataIndex: 'grantLevel',
        render: text => translate(text),
      },
      {
        title: translate('action'),
        render: (text, record) => {
          return (
            ability.can('edit', 'userRole') && (
              <span className="flex">
                <Popconfirm
                  title={translate('delete_confirmation')}
                  onConfirm={() => handleDeleteGrant(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    type="danger"
                    className="my-accounts__delete-btn-table btn-table"
                  >
                    {translate('delete')}
                  </div>
                </Popconfirm>
                <div
                  type="link"
                  className="my-accounts__edit-btn-table btn-table"
                  onClick={() => editRow(record)}
                >
                  {translate('edit')}
                </div>
              </span>
            )
          )
        },
      },
    ]
  }

  const editRow = record => {
    setRecord(record)
    setEditVisibility(true)
  }

  const onCancel = () => {
    setEditVisibility(false)
  }

  useEffectAfterMount(() => {
    if (general.refresh.isRefreshing) {
      dispatch(setRefresh(false, undefined))
    }
  }, [general.refresh, setRefresh])

  return (
    <>
      <Row className="title-button-row">
        <GoBackButton
          onClick={() =>
            history.push(
              itsmRoutes[
                hasUserAccounts
                  ? 'settingsITSMusersAccounts'
                  : 'settingsITSMmyAccounts'
              ].path()
            )
          }
        />
        <h2>{account?.name}</h2>
      </Row>
      <div>
        <div className="data-table-title my-accounts__header">
          <h4 className="my-accounts__header_bold">
            {translate('organizations')}
          </h4>
        </div>
        <div>
          <DataTable
            rowKey="id"
            loading={loadingOrgs}
            data={orgs}
            columns={orgColumns}
          />
        </div>
        <div className="data-table-title my-accounts__header">
          <h4 className="my-accounts__header_bold">{translate('users')}</h4>
        </div>
        <div>
          <DataTable
            rowKey="id"
            data={subs}
            loading={loadingSubs}
            columns={getSubsColumns()}
            expandable={{
              expandedRowRender: record => (
                <div className="my-accounts__id-container">{record.id}</div>
              ),
            }}
          />
        </div>
      </div>

      <EditGrant
        open={edit_visibility}
        onCancel={onCancel}
        account={id}
        record={record}
        grantLevels={hasUserAccounts ? ['owner', 'admin', 'user'] : grantLevels}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {general: state.general}
}

export default connect(mapStateToProps, {setToastMessage, setRefresh})(Account)
