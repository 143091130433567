import './customerly.scss'

declare global {
  interface Window {
    customerlySettings: Record<string, unknown>
    customerly_queue: unknown[]
    customerly: (commandName: string) => void
  }
}

const CUSTOMERLY_LOCAL_STORAGE_KEY = 'customerly'

const APP_ID = '652cdbbd'
const OPTIONS = {
  user_id: localStorage.getItem('userID'),
  name: localStorage.getItem('user'),
  attributes: {
    org: localStorage.getItem('org'),
    orgID: localStorage.getItem('org_id'),
    channel: localStorage.getItem('channel'),
  },
  company: {
    company_id: localStorage.getItem('org_id'),
    name: localStorage.getItem('org'),
  },
  // Set the widget color
  widget_color: '000000',
  // Set the widget position
  widget_position: window.location.pathname.includes('login')
    ? 'right'
    : 'left',
  // Shows the widget when the user has conversations
  show_with_conversation: true,
  // Hide the screenshot button
  screenshot_available: true,
  // Hide the attachments button
  attachments_available: true,
  // Hide the widget on mobile only when true
  widget_hide_mobile: true,
}

const customerlyScriptInit = () => {
  window.customerlySettings = {
    app_id: APP_ID,
    ...OPTIONS,
  }

  window.customerly_queue = []
  const n = (...args: unknown[]) => {
    n.c(args)
  }
  n.c = function(e: unknown) {
    window.customerly_queue.push(e)
  }
  window.customerly = n

  return new Promise(resolve => {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.addEventListener('load', function listener() {
      this.removeEventListener('load', listener)
      resolve(script)
    })
    script.src = `https://widget.customerly.io/widget/${APP_ID}`
    head.appendChild(script)
  })
}

const redesignCustomerlyIcon = () => {
  const customerlyContainer = document.getElementById('customerly-container')
  const customerlyBubbleWrapper = document.getElementById(
    'customerly-launcher-button-icon'
  )
  const customerlyBubbleIcon: HTMLElement | null = document.querySelector(
    '.customerly-bubble-background svg'
  )
  const customerlyBubbleBg = document.querySelector(
    '.customerly-bubble-background'
  )
  const customerlyLauncherButtonBg = document.getElementById(
    'customerly-launcher-button-background'
  )
  const customerlyLauncherIcon = document.querySelector(
    '#customerly-launcher-button-icon svg'
  )
  const customerlyLauncher = document.querySelector(
    '#customerly-container .customerly-launcher'
  )

  const iconDimensions = 'width:40px !important; height:40px !important;'
  const iconsPosition = 'bottom: -6px !important; left: -6px !important;'

  if (
    customerlyContainer &&
    customerlyBubbleIcon &&
    customerlyLauncherIcon &&
    customerlyBubbleWrapper &&
    customerlyBubbleBg &&
    customerlyLauncherButtonBg &&
    customerlyLauncher
  ) {
    customerlyBubbleIcon.setAttribute('style', iconDimensions)
    customerlyLauncherIcon.setAttribute('style', iconDimensions)
    customerlyBubbleWrapper.setAttribute(
      'style',
      `${iconDimensions} ${iconsPosition}`
    )
    customerlyBubbleBg.setAttribute(
      'style',
      `${iconDimensions} ${iconsPosition}`
    )
    customerlyLauncherButtonBg.setAttribute(
      'style',
      `${iconDimensions} ${iconsPosition}`
    )
    customerlyBubbleIcon.style.fill = '#ffe000'
    customerlyLauncher.setAttribute('style', iconDimensions)
  }
}

const runAfterInit = () => {
  if (!localStorage.getItem(CUSTOMERLY_LOCAL_STORAGE_KEY)) {
    window.customerly('open')
    localStorage.setItem(CUSTOMERLY_LOCAL_STORAGE_KEY, 'true')
  }

  redesignCustomerlyIcon()
}

export const customerlyInit = async () => {
  await customerlyScriptInit()

  const cusomerlyInitInterval = setInterval(() => {
    const customerlyContainer = document.getElementById('customerly-container')
    if (customerlyContainer) {
      clearInterval(cusomerlyInitInterval)
      runAfterInit()
    }
  }, 500)
}

export const toggleCustomerlyIcon = (condition: boolean) => {
  const customerlyContainer = document.getElementById(
    'customerly-container'
  ) as HTMLElement

  if (customerlyContainer) {
    if (condition) {
      customerlyContainer.classList.add('customerly-wrapper--show')
    } else {
      customerlyContainer.classList.remove('customerly-wrapper--show')
    }
  }
}
