import React from 'react'

import classNames from 'classnames'

import './importance-indicator.scss'

type TProps = {
  importance: number
  indicatorText?: string
  importanceText?: string
  className?: string
}

const importanceIndicatorColorClass: Record<number, string> = {
  1: 'importance-indicator--critical',
  2: 'importance-indicator--high',
  3: 'importance-indicator--moderate',
  4: 'importance-indicator--low',
  5: 'importance-indicator--planning',
}

export const ImportanceIndicator: React.FC<TProps> = (props: TProps) => {
  const {importance, indicatorText, importanceText, className} = props
  const classes = classNames(
    'importance-indicator flex flex--alignCenter',
    importanceIndicatorColorClass[importance],
    className
  )
  return (
    <div className={classes}>
      <span className="importance-indicator__badge">
        {indicatorText || importance}
      </span>
      {importanceText && (
        <span className="importance-indicator__label capitalize--first">
          {importanceText}
        </span>
      )}
    </div>
  )
}
