import React, {useCallback, useEffect, useState} from 'react'

import {Col, Divider, Form, Row, Select, Spin} from 'antd'
import isEqual from 'lodash/isEqual'
import queryString from 'query-string'
import {useHistory} from 'react-router'
import moment from 'moment'
import {withErrorBoundary} from '@sentry/react'

import {Button} from '@/components/button'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {DATE_FORMAT} from '@/constants'
import {usePostEntity} from '@/hooks/useUpdateData'
import ErrorPage from '@/components/error/error-page/error-page'
import DataTable from '@/components/data-table/data-table'

import {postEditTimeLog} from '../../api/timelogsRequests'
import {setIncidentTabIsBlocked} from '../incident/incident-reducer'
import {TTimeLog, TTimespans} from '../../typedef'
import {useStateContext} from '../incident/incident-context'
import {IncTabs} from '../incident-k-request/constants'
import {TIncident} from '../incident-k-request/typedf'
import {generateIncidentStatePathITSM} from '../incident-k-request/utils'

import {CheckOutForm} from './time-log-check-out-form'
import {useGetTimelog} from './hooks/useGetTimeLogs'
import './time-log-details.scss'
import {useDeleteTimeLog} from './hooks/useDeleteTimeLog'
import {useUpdateTimeLog} from './hooks/useUpdateTimeLog'
import {getInitialValues} from './utils'
import DatePicker from '@/components/date-picker/date-picker'

import classNames from 'classnames'

const Option = Select.Option

const TimeLogDetail = () => {
  const [form] = Form.useForm()

  const {saveEntity} = usePostEntity(postEditTimeLog)

  const timelogId = (queryString.parse(window.location.search).timelog ||
    '') as string
  const {entityData, entity, dispatch} = useStateContext<TIncident>()
  const history = useHistory()

  const {fecthTimeLog, timelog, status} = useGetTimelog({id: timelogId})

  const [btnDisabled, setBtnDisabled] = useState(true)
  const [timespanType, setTimespanType] = useState<string | undefined>()
  const [timespanStartDate, setTimespanStartDate] = useState<
    string | undefined
  >()
  const [timespanEndDate, setTimespanEndDate] = useState<string | undefined>()

  const [timespanIndex, setTimespanIndex] = useState<string | undefined>()
  const [timespanEditRecord, setTimespanEditRecord] = useState<
    TTimespans | undefined
  >()

  const [dateValidationStatus, setDateValidationStatus] = useState<
    undefined | 'error'
  >()

  const {updateTimeLog, updateStatus} = useUpdateTimeLog()

  const {handleDeleteTimeLog, deleteStatus} = useDeleteTimeLog({id: timelogId})

  const checkValues = useCallback(() => {
    if (timelog) {
      const initialValues = getInitialValues(timelog)

      setBtnDisabled(isEqual(form.getFieldsValue(), initialValues))
    }
  }, [form, timelog])

  useEffect(() => {
    if (timelog) {
      const initialValues = getInitialValues(timelog)

      form.setFieldsValue(initialValues)
    }
  }, [form, timelog])

  const handleSubmit = async (values: TTimeLog) => {
    const newValues = {...values}

    const newInitValues = (timelog && {
      ...getInitialValues(timelog),
    }) as TTimeLog | undefined

    delete newInitValues?.['ignore_extra_pricing_policy']
    delete newValues?.['ignore_extra_pricing_policy']

    const result = await updateTimeLog({
      id: timelogId,
      values,
      extraPolicyDiff:
        !values.ignore_extra_pricing_policy !==
        timelog?.ignore_extra_pricing_policy,
      valuesDiff: timelog && !isEqual(newValues, newInitValues),
    })
    if (result) await fecthTimeLog(timelogId)
    setBtnDisabled(true)
  }

  const goBack = () => {
    entityData &&
      history.push(
        generateIncidentStatePathITSM(
          entity,
          entityData.uuid,
          IncTabs.TimeLogsTab
        )
      )
  }

  const handleEdit = async () => {
    const data = {
      timelog: timelogId,
      timespan_index: Number(timespanIndex),
      timespan: {
        start: timespanStartDate
          ? timespanStartDate
          : timespanEditRecord?.start,
        end: timespanEndDate ? timespanEndDate : timespanEditRecord?.end,
        type: timespanType ? timespanType : timespanEditRecord?.type,
        ...(timespanType === 'on_hold' ||
        (timespanEditRecord?.type === 'on_hold' &&
          timespanType !== 'work' &&
          timespanType !== 'travel')
          ? {on_hold_reason: 'Break'}
          : {}),
      },
    }
    if (
      data.timespan.start &&
      data.timespan.end &&
      data.timespan.start > data.timespan.end
    ) {
      setDateValidationStatus('error')
      return
    }
    const result = await saveEntity(data)

    if (result) {
      await fecthTimeLog(timelogId)
      setDateValidationStatus(undefined)
    }

    setTimespanIndex(undefined)
    setTimespanEditRecord(undefined)
  }

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])
  const getColumns = () => {
    return [
      {
        title: translate('start'),
        dataIndex: 'start',
        render: (val: string, _: any, index: number) => {
          return timespanIndex && timespanIndex === index.toString() ? (
            <DatePicker
              showTime
              className="full-width"
              format={DATE_FORMAT}
              defaultValue={val ? moment(val) : undefined}
              status={dateValidationStatus}
              onChange={e => setTimespanStartDate(moment(e).toISOString())}
            />
          ) : (
            val && (
              <div className="nowrap">{moment(val).format(DATE_FORMAT)}</div>
            )
          )
        },
      },
      {
        title: translate('end'),
        dataIndex: 'end',
        render: (val: string, _: any, index: number) => {
          return timespanIndex && timespanIndex === index.toString() ? (
            <DatePicker
              showTime
              className="full-width"
              defaultValue={val ? moment(val) : undefined}
              format={DATE_FORMAT}
              onChange={e => setTimespanEndDate(moment(e).toISOString())}
              status={dateValidationStatus}
            />
          ) : (
            val && (
              <div className="nowrap">{moment(val).format(DATE_FORMAT)}</div>
            )
          )
        },
      },

      {
        title: translate('type'),
        dataIndex: 'type',
        width: 150,
        render: (val: string, _: any, index: number) => {
          const holdValue = val === 'on_hold' ? 'break' : val
          return timespanIndex && timespanIndex === index.toString() ? (
            <Select
              className="full-width"
              defaultValue={val}
              onSelect={(type: string) => setTimespanType(type)}
            >
              <Option value={'work'}>
                {translate('time_session_stateid_1').toLowerCase()}
              </Option>
              <Option value={'on_hold'}>
                {translate('time_session_stateid_2').toLowerCase()}
              </Option>
              <Option value={'travel'}>
                {translate('time_session_stateid_0').toLowerCase()}
              </Option>
            </Select>
          ) : (
            holdValue
          )
        },
      },
      {
        title: translate('edit'),
        width: 200,
        render: (...args: any[]) => {
          return (
            args[0].start &&
            args[0].end &&
            (timespanIndex === args[2].toString() ? (
              <span>
                <span
                  className="time-log-details__link pr-25"
                  onClick={handleEdit}
                >
                  {translate('save')}
                </span>
                <Divider
                  className="time-log-details__divider"
                  type="vertical"
                />
                <span
                  className="time-log-details__link pr-25"
                  onClick={() => {
                    setTimespanEditRecord(undefined)
                    setTimespanIndex(undefined)
                    setDateValidationStatus(undefined)
                  }}
                >
                  {translate('cancel')}
                </span>
              </span>
            ) : (
              <span
                className="time-log-details__link"
                onClick={() => {
                  setTimespanEditRecord(args[0])
                  setTimespanIndex(args[2].toString())
                }}
              >
                {translate('edit')}
              </span>
            ))
          )
        },
      },
    ]
  }

  return (
    <Spin spinning={checkIsLoading(status)}>
      <Row className="time-log-details">
        <Col span={24}>
          <div className="flex flex--spaceBetween">
            <h3 className="flex flex--alignCenter fs-20">
              <GoBackButton onClick={goBack} />

              <span> {translate('time_log')}</span>
            </h3>

            <Button
              title={`${translate('cancel')} ${translate(
                'time_log'
              ).toLowerCase()}`}
              className="time-log-details__cancel"
              onClick={async () => {
                const result = await handleDeleteTimeLog()
                if (result) goBack()
              }}
              disabled={checkIsLoading(deleteStatus)}
              size="large"
              e2e-test="cancel-button"
            />
          </div>

          <Form
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{required: '${label} is required'}}
            onValuesChange={checkValues}
            layout="vertical"
            initialValues={timelog && getInitialValues(timelog)}
            form={form}
            onFinish={handleSubmit}
          >
            <CheckOutForm />

            <Button
              title={translate('save')}
              type="primary"
              disabled={checkIsLoading(updateStatus) || btnDisabled}
              htmlType="submit"
              size="large"
              e2e-test="submit-button"
            />
          </Form>
        </Col>
        <Col span={24}>
          <DataTable<TTimespans>
            data={(timelog && timelog.timespans) || []}
            columns={getColumns()}
            scroll={{x: 200}}
          />
          <div
            className={classNames({
              'time-log-details__error-hidden': !dateValidationStatus,
              'time-log-details__error': dateValidationStatus === 'error',
            })}
          >
            {translate('end_date_should_start_date')}
          </div>
        </Col>
      </Row>
    </Spin>
  )
}

export default withErrorBoundary(TimeLogDetail, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
