import {AddonItemTypes, SideTabs} from '@/sites/services/typedef'
import {translate} from '@/services/i18n'

const addons = {
  [AddonItemTypes.NUMBER_OF_USERS]: {
    id: AddonItemTypes.NUMBER_OF_USERS,
    name: translate('number-of-users'),
    isMultiple: false,

    items: [
      {
        label: '1-100',
        value: 'one-oneHundred',
      },
      {
        label: '100-1000',
        value: 'hundred-plus',
      },
      {
        label: '1000+',
        value: 'thousand-plus',
      },
    ],
  },
  [AddonItemTypes.SUPPORT_TYPE]: {
    id: AddonItemTypes.SUPPORT_TYPE,
    name: translate('support-type'),
    isMultiple: false,
    items: [
      {
        label: translate('remote'),
        value: 'remote-support',
      },
      {
        label: translate('onsite'),
        value: 'onsite-support',
      },
      {
        label: translate('both'),
        value: 'both-support',
      },
    ],
  },
  [AddonItemTypes.SUPPORT]: {
    id: AddonItemTypes.SUPPORT,
    name: translate('support'),
    isMultiple: false,
    items: [
      {
        label: '8x5',
        value: 'support-8-5',
      },
      {
        label: '24x5',
        value: 'support-24-5',
      },
      {
        label: '24x7',
        value: 'support-24-7',
      },
    ],
  },
  [AddonItemTypes.RESPOND_TIME]: {
    id: AddonItemTypes.RESPOND_TIME,
    name: translate('respond-time'),
    isMultiple: true,
    items: [
      {
        label: `2-5 ${translate('days')}`,
        value: '2-5-days',
      },
      {
        label: `24 ${translate('option_hours')}`,
        value: '24-hours',
      },
      {
        label: `8 ${translate('option_hours')}`,
        value: '8-hours',
      },
      {
        label: translate('asap'),
        value: 'asap',
      },
    ],
  },
}

export const viewServices = {
  [SideTabs.END_USER_SUPPORT]: {
    title: translate('end_user_support_title'),
    addons,
    required: true,
    featureList: [
      translate('incidents_and_request_management'),
      translate('employees_self_service_portal'),
      translate('define_service_main_and_sub_categories'),
      translate('slas_matrix_management'),
      translate('documentation_management'),
      translate('unlimited_organizations'),
      translate('unlimited_channels'),
      translate('svr_automation'),
      translate('billing_automation'),
      translate('billing_automation_it_provider_module'),
      translate('work_time_tracker'),
      translate('workflows_and_processes_automation'),
      translate('expenses_report_management'),
      translate('audit_log_and_retention'),
      translate('premium_support'),
      translate('custom_integration'),
    ],
  },
  [SideTabs.BUSINESS_APPS_SUPPORT_MANAGEMENT]: {
    title: translate('business_apps_support_management_title'),
    addons,
    required: false,
    featureList: [
      translate('auto_discovery_of_your_entire_saas_apps'),
      translate('saas_users_onboarding_automation'),
      translate('auto_provisioning_of_saas_users_and_groups'),
      translate(
        'auto_discovery_of_all_unassigned_saas_licenses_and_save_up_to_40_percent_cost'
      ),
      translate(
        'auto_discovery_of_all_unutilized_or_under_utilized_apps_auto_discovery'
      ),
      translate('use_azure_ad_free_plan_azure_ad_p1_p2_optional'),
      translate('use_with_g_suite_or_okta_identity_providers'),
      translate(
        'view_users_access_audit_logs_with_unlimited_retention_duration'
      ),
    ],
  },
  [SideTabs.IT_ASSETS_SUPPORT_MANAGEMENT]: {
    title: translate('it_assets_support_management_tittle'),
    addons,
    required: false,
    featureList: [
      translate('on_premise_assets_discovery'),
      translate('cloud_assets_discovery'),
      translate('remote_users_asset_discovery'),
      translate('assets_lifecycle_management'),
      translate('vulnerabilities_scanning'),
      translate('seamless_integration'),
    ],
  },
}
