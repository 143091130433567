import {
  ModuleFeatureFlags,
  TExtraAddons,
  TPurchasesAddons,
} from '@/sites/purchase/typedef'

export const countQuantityPurchases = (
  modules: Array<ModuleFeatureFlags>,
  addons: TPurchasesAddons,
  extraAddons: TExtraAddons
) => {
  const quantityAddons = Object.values(addons).reduce(
    (accQuantityAddons, moduleAddons) => {
      return accQuantityAddons + moduleAddons.length
    },
    0
  )

  return modules.length + extraAddons.length + quantityAddons
}
