import globalRoutes from '@/global-routes'

const {
  moduleRoutes: {
    subscriptions: {path: subscriptionsModulePath},
  },
} = globalRoutes

const subscriptionsRoutes = {
  cardDetails: {
    name: 'cardDetails',
    path: () => `${subscriptionsModulePath()}/card-details`,
  },
  invoiceList: {
    name: 'invoicesList',
    path: () => `${subscriptionsModulePath()}/invoices-list`,
  },
  plansPackages: {
    name: 'plansPackages',
    path: () => `${subscriptionsModulePath()}/plans`,
  },
  services: {
    name: 'services',
    path: () => `${subscriptionsModulePath()}/services`,
  },
  products: {
    name: 'products',
    path: () => `${subscriptionsModulePath()}/products`,
  },
  serviceAgreements: {
    name: 'serviceAgreements',
    path: () => `${subscriptionsModulePath()}/service-agreements`,
  },
}

export default subscriptionsRoutes
