// @ts-nocheck AssignmentGroupsFormContainer is not refactored to typescript

import React from 'react'

import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'

import AssignmentGroupsFormContainer from './AssignmentGroupsFormContainer'

const AsignmentGroupsWrapper = () => {
  const {getEntityData, onClose, record} = useListTableStateContext()

  return (
    <AssignmentGroupsFormContainer
      getEntityData={getEntityData}
      closeFunction={onClose}
      assignmentGroup={record}
    />
  )
}

export default AsignmentGroupsWrapper
