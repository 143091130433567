import React from 'react'

import {ItsmAssets, TUser} from '@/modules/ITSM/typedef'
import ItsmListTableSettings from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {ExportBtn} from '@/modules/ITSM/components/export-btn/export-btn'

import useExportSuppliers from './hooks/use-export-suppliers'

const Suppliers = () => {
  const {exportSuppliers, isExportLoading} = useExportSuppliers()

  return (
    <ItsmListTableSettings<TUser>
      asset={ItsmAssets.Suppliers}
      exportBtn={
        <ExportBtn onChange={exportSuppliers} isLoading={isExportLoading} />
      }
      rowKey="uuid"
    />
  )
}

export default Suppliers
