import {useSelector} from 'react-redux'
import {saveAs} from 'file-saver'

import {useFetchHook} from '@/hooks/useFetch'
import {exportSupliers} from '@/modules/ITSM/api/exportRequests'
import {selectItsmTableFilters} from '@/modules/ITSM/store/list-table/selectors'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {TAppState} from '@/redux/store'
import {checkIsLoading} from '@/utils/check-is-loading'
import {exportType} from '@/constants'

const useExportSuppliers = () => {
  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters(state, ItsmAssets.Suppliers)
  )

  const exportSuppliers = async (format: string) => {
    try {
      handleRequested()
      const res = await exportSupliers(format, filters)
      if (format === 'xlsx') {
        const file = new Blob([res.body], {
          type: exportType,
        })
        saveAs(file, 'suppliersList.xlsx')
        handleSuccess()
      } else {
        const file = new Blob([res.body], {type: 'text/csv'})
        saveAs(file, 'suppliersList.csv')

        handleSuccess()
      }
    } catch (err) {
      handleFail(err)
    }
  }

  return {exportSuppliers, isExportLoading: checkIsLoading(status)}
}

export default useExportSuppliers
