import {setStepNumber} from '../incident-k-request/utils/set-step-number'

export type TSlaValues = {
  endTime: string
  startTime: string
  elapsedTime?: number
  remainingTime?: number
  progress?: number
}

export type TInitialState = {
  currentStep: string
  currentTab: string
  isOnHold: boolean
  isRejectReason: boolean
  slaValues: TSlaValues
  isRedirectBlocked: boolean
}

export const initialSlaValues = {
  endTime: '',
  startTime: '',
  elapsedTime: 0,
}

export const initialState: TInitialState = {
  currentStep: '',
  currentTab: '',
  isOnHold: false,
  isRejectReason: false,
  slaValues: initialSlaValues,
  isRedirectBlocked: false,
}

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const SET_ON_HOLD = 'SET_ON_HOLD'
export const SET_REJECT_REASON = 'SET_REJECT_REASON'
export const SET_SLA_VALUES = 'SET_SLA_VALUES'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_INCIDENT_TAB_REDIRECT_IS_BLOCKED =
  'SET_INCIDENT_TAB_REDIRECT_IS_BLOCKED'

export const setCurrentStep = ({
  step,
  currentTab,
}: {
  step: string
  currentTab: string
}) => ({
  type: SET_CURRENT_STEP,
  payload: {step, currentTab},
})

export const setCurrentTab = (tab: string) => ({
  type: SET_CURRENT_TAB,
  payload: tab,
})

export const setOnHold = (isOnHold: boolean) => ({
  type: SET_ON_HOLD,
  payload: isOnHold,
})

export const setRejectReason = (isRejectReason: boolean) => ({
  type: SET_REJECT_REASON,
  payload: isRejectReason,
})

export const setSlaValues = (slaValues: TSlaValues) => ({
  type: SET_SLA_VALUES,
  payload: slaValues,
})

export const setProgress = (progress: number) => ({
  type: SET_PROGRESS,
  payload: progress,
})

export const setIncidentTabIsBlocked = (isBlocked: boolean) => ({
  type: SET_INCIDENT_TAB_REDIRECT_IS_BLOCKED,
  payload: isBlocked,
})

export const incidentActionCreators = {
  setCurrentStep: ({step, currentTab}: {step: string; currentTab: string}) =>
    ({
      type: SET_CURRENT_STEP,
      payload: {step, currentTab},
    } as const),

  setCurrentTab: (tab: string) =>
    ({
      type: SET_CURRENT_TAB,
      payload: tab,
    } as const),

  setOnHold: (isOnHold: boolean) =>
    ({
      type: SET_ON_HOLD,
      payload: isOnHold,
    } as const),

  setRejectReason: (isRejectReason: boolean) =>
    ({
      type: SET_REJECT_REASON,
      payload: isRejectReason,
    } as const),

  setSlaValues: (slaValues: TSlaValues) =>
    ({
      type: SET_SLA_VALUES,
      payload: slaValues,
    } as const),

  setProgress: (progress: number) =>
    ({
      type: SET_PROGRESS,
      payload: progress,
    } as const),

  setIncidentTabIsBlocked: (isBlocked: boolean) =>
    ({
      type: SET_INCIDENT_TAB_REDIRECT_IS_BLOCKED,
      payload: isBlocked,
    } as const),
}

export type TIncidentActionTypes = {
  [Name in keyof typeof incidentActionCreators]: typeof incidentActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<typeof incidentActionCreators[Name]>
    : never
}[keyof typeof incidentActionCreators]

export function incidentReducer(
  state = initialState,
  action: TIncidentActionTypes
) {
  switch (action.type) {
    case SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload.step,
        currentTab: action.payload.currentTab,
        isPreOnHold: false,
        isRejectReason: false,
        isOnHold: false,
      }
    }

    case SET_CURRENT_TAB: {
      return {
        ...state,
        currentStep: setStepNumber(action.payload),
        currentTab: action.payload,
        isPreOnHold: false,
        isRejectReason: false,
        isOnHold: false,
      }
    }

    case SET_ON_HOLD: {
      return {
        ...state,
        isOnHold: action.payload,
      }
    }

    case SET_REJECT_REASON: {
      return {
        ...state,
        isRejectReason: action.payload,
      }
    }

    case SET_SLA_VALUES: {
      return {
        ...state,
        slaValues: {...state.slaValues, ...action.payload},
      }
    }

    case SET_PROGRESS: {
      return {
        ...state,
        slaValues: {...state.slaValues, progress: action.payload},
      }
    }

    case SET_INCIDENT_TAB_REDIRECT_IS_BLOCKED: {
      return {
        ...state,
        isRedirectBlocked: action.payload,
      }
    }

    default:
  }
}
