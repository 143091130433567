import React, {useRef} from 'react'

import {Tooltip} from 'antd'

import {getNameAbbreviation} from '@/utils/get-name-abbreviation'

import './assigned-to-mark.scss'

type TProps = {
  name?: string
}

export const AssignedToMark: React.FC<TProps> = (props: TProps) => {
  const {name} = props

  const popupContainer = useRef(null)

  return (
    <div className="assigned-to-mark" ref={popupContainer}>
      <Tooltip
        placement="topLeft"
        className="ml-4"
        getPopupContainer={() => popupContainer.current || document.body}
        title={
          name && (
            <div className="assigned-to-mark__tooltip-title">
              <div>{name}</div>
            </div>
          )
        }
      >
        <span className="assigned-to-mark__name">
          {name && getNameAbbreviation(name)}
        </span>
      </Tooltip>
    </div>
  )
}
