import {AddonItemTypes, SideTabs} from '@/sites/services/typedef'

const initialAddons = {
  [AddonItemTypes.NUMBER_OF_USERS]: undefined,
  [AddonItemTypes.SUPPORT_TYPE]: undefined,
  [AddonItemTypes.SUPPORT]: undefined,
  [AddonItemTypes.RESPOND_TIME]: undefined,
  [AddonItemTypes.COUNTRY]: undefined,
}
export const defaultSelectAddons = {
  [SideTabs.END_USER_SUPPORT]: {
    [AddonItemTypes.NUMBER_OF_USERS]: 'one-oneHundred',
    [AddonItemTypes.SUPPORT_TYPE]: 'remote-support',
    [AddonItemTypes.SUPPORT]: 'support-8-5',
    [AddonItemTypes.RESPOND_TIME]: ['2-5-days'],
  },
  [SideTabs.BUSINESS_APPS_SUPPORT_MANAGEMENT]: initialAddons,
  [SideTabs.IT_ASSETS_SUPPORT_MANAGEMENT]: initialAddons,
}
