import React from 'react'

import {Typography as AntdTypography} from 'antd'
import {TitleProps} from 'antd/lib/typography/Title'
import {ParagraphProps} from 'antd/lib/typography/Paragraph'

import {TKey, translate} from '@/services/i18n'

const {Title, Paragraph} = AntdTypography

interface ITypographyProps extends TitleProps {
  title: TKey
}

export const Typography: React.FC<ITypographyProps> = ({title, ...props}) => {
  return <Title {...props}>{translate(title)}</Title>
}

export const TextBlock: React.FC<ParagraphProps> = ({title, ...props}) => {
  return <Paragraph {...props}>{title}</Paragraph>
}
