import {TProductPayloadItem} from '@/api/typedf'

export const transformProductsPayload = (
  purchases: Array<{id: string; usage_type: string}>
) =>
  purchases.reduce<Array<TProductPayloadItem>>((acc, {id, usage_type}) => {
    return [
      ...acc,
      {
        price: id,
        ...(usage_type !== 'metered' && {quantity: 1}),
      },
    ]
  }, [])
