import React from 'react'

import {Col, Form, Row} from 'antd'

import {SkeletonItem} from '@/components/skeleton'

import './chat-room.scss'

export const ChatRoomSkeleton = () => (
  <Row className="itsm-chatroom">
    <Col span={24}>
      <Form.Item>
        <SkeletonItem width="100%" height={82} />
      </Form.Item>
    </Col>
  </Row>
)
