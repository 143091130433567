import {AppVersion, UserRole} from '@/typedef'

import {Feature, TPermissionMap} from './typedef'

export const PermissionMap: TPermissionMap = {
  features: {
    [Feature.DAILY_REPORTS]: {
      [UserRole.PROVIDER]: true,
    },
    [Feature.FINANCE]: {
      [AppVersion.PORTAL]: false,
    },
  },
}
