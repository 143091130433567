import {all, fork, takeLatest} from 'redux-saga/effects'

import {ActionTypes as ItsmActionTypes} from '@/modules/ITSM/store/action-types'
import {postIdentityMeIvitationSaga} from '@/redux/channel-invitation/sagas/post-identity-saga'
import {getBillingSubscriptionsSaga} from '@/saga/billing-subscriptions-saga'
import {getBillingCustomerSaga} from '@/saga/billing-customer-saga'
import {TActionTypes} from '@/redux/types'

import cmdbRootSaga from '../modules/CMDB/saga/rootSaga'
import saasRootSaga from '../modules/SAAS/saga/rootSaga'
import * as ActionTypes from '../redux/actionTypes'
import {SET_BACK_END_TOKEN_REQUESTED} from '@/redux/actionTypes'

import {refreshIdentityAddMeSaga} from './identitySaga'
import {
  acceptChannelInviteSaga,
  getAccountsSaga,
  getOrgAccountSaga,
  postOrgGrantSaga,
} from './organizationInvitationSaga'
import {profileSaga} from './profileSaga'
import {getCreditModeSaga, geyMyAccess} from './settingsSaga'
import {getVersionSaga} from './uiSaga'
import {getIdentityMeSaga, getUserSaga} from './userSaga'
import {backEndTokenSaga} from '@/saga/back-end-token-saga'
import {refreshTokenSaga} from '@/saga/refresh-token-saga'
import {refreshTokenInvitationSaga} from '@/redux/channel-invitation/sagas/resfresh-invite-token-saga'

function* watchIdentityMe() {
  yield takeLatest(ActionTypes.FETCH_IDENTITY_ME_REQUESTED, getIdentityMeSaga)
}

function* watchChannelInviteSaga() {
  yield takeLatest(
    ItsmActionTypes.REFRESH_INVITE_ORG_TOKEN_REQUESTED,
    refreshTokenInvitationSaga
  )
}

function* watchChannelInvitePostIdentitySaga() {
  yield takeLatest(
    ItsmActionTypes.POST_IDENTITY_CHANNEL_INVITE_REQUESTED,
    postIdentityMeIvitationSaga
  )
}

function* watchProfile() {
  yield takeLatest(ActionTypes.FETCH_PROFILE_REQUESTED, profileSaga)
}

function* watchUser() {
  yield takeLatest(ActionTypes.FETCH_USER_REQUESTED, getUserSaga)
}

function* watchCreditMode() {
  yield takeLatest(ActionTypes.FETCH_CREDIT_ACCESS_REQUESTED, getCreditModeSaga)
}

function* watchMyAccess() {
  yield takeLatest(ActionTypes.FETCH_MY_ACCESS_REQUESTED, geyMyAccess)
}

function* watchVersion() {
  yield takeLatest(ActionTypes.FETCH_VERSION_REQUESTED, getVersionSaga)
}

function* watchPostChannelGrant() {
  yield takeLatest(ActionTypes.POST_INVIT_CHANNEL_ID_GRANT, postOrgGrantSaga)
}

function* watchGetAccounts() {
  yield takeLatest(ActionTypes.GET_ACCOUNTS_REQUESTED, getAccountsSaga)
}

function* watchOrgAccounts() {
  yield takeLatest(ActionTypes.GET_ORG_ACCOUNTS_REQUESTED, getOrgAccountSaga)
}

function* watchAcceptInvite() {
  yield takeLatest(ActionTypes.ACCEPT_INVITE_REQUESTED, acceptChannelInviteSaga)
}

function* watchRefreshOrgTokenSaga() {
  yield takeLatest(ActionTypes.REFRESH_ORG_TOKEN_REQUESTED, refreshTokenSaga)
}

function* watchRefreshIdentityAddMe() {
  yield takeLatest(
    ActionTypes.REFRESH_IDENTITY_ADD_ME,
    refreshIdentityAddMeSaga
  )
}

function* watchGetBillingSubscriptionsSaga() {
  yield takeLatest(
    TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_REQUESTED,
    getBillingSubscriptionsSaga
  )
}

function* watchGetBillingCustomerSaga() {
  yield takeLatest(TActionTypes.FETCH_BILLING_CUSTOMER, getBillingCustomerSaga)
}

function* watchBackendTokenSaga() {
  yield takeLatest(SET_BACK_END_TOKEN_REQUESTED, backEndTokenSaga)
}

export default function* rootSaga() {
  yield all([
    watchIdentityMe(),
    watchProfile(),
    watchUser(),
    watchCreditMode(),
    watchMyAccess(),
    watchVersion(),
    watchChannelInviteSaga(),
    watchChannelInvitePostIdentitySaga(),
    watchPostChannelGrant(),
    watchGetAccounts(),
    watchOrgAccounts(),
    watchAcceptInvite(),
    watchRefreshOrgTokenSaga(),
    watchRefreshIdentityAddMe(),
    fork(saasRootSaga),
    fork(cmdbRootSaga),
    watchGetBillingSubscriptionsSaga(),
    watchGetBillingCustomerSaga(),
    watchBackendTokenSaga(),
  ])
}
