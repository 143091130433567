import {useContext} from 'react'

import isBoolean from 'lodash/isBoolean'

import {generateConfig} from '@/generateConfig'
import {AbilityContext} from '@/services/abilities/ability-context'

import {PriceFeatureFlagKeys} from '@/modules/typedef'

export const useIsStripeFeatureAllowed = () => {
  const {isStripeEnabled} = generateConfig().FEATURE_FLAGS
  const ability = useContext(AbilityContext)

  const isFeatureAllowed = (feature: PriceFeatureFlagKeys) => {
    if (ability.can('view', PriceFeatureFlagKeys.ALL_INCLUSIVE)) return true

    const featureAllowed = ability.can('view', feature)

    if (!isStripeEnabled) return true

    return isBoolean(featureAllowed) ? featureAllowed : true
  }

  return {
    isFeatureAllowed,
  }
}
