export function selectCreditAccess(state) {
  return state.settings.creditAccess.mode
}

export function selectMyAccess(state) {
  return state.settings.myAccess
}

export function selectUserSensitive(state) {
  return state.settings.myAccess.accessFunctions?.custom_grants?.view_sensitive
}

export function selectAccessFunctions(state) {
  return state.settings.myAccess.accessFunctions
}
