import React, {useEffect, useState} from 'react'

import {Col, Form, Row} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {Button} from '@/components/button'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {useAcceptOrReject} from '@/modules/ITSM/components/incident/hooks/useAcceptOrReject'
import {checkIsLoading} from '@/utils/check-is-loading'
import ErrorPage from '@/components/error/error-page/error-page'
import {rejectedReasonsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {TIncident} from '../incident-k-request/typedf'

import {setIncidentTabIsBlocked, setRejectReason} from './incident-reducer'
import {useStateContext} from './incident-context'

const RejectedReason = () => {
  const [form] = Form.useForm()

  const {entityData, getData, dispatch, entity} = useStateContext<TIncident>()
  const {saveEntity: acceptOrReject, saveEntityStatus} = useAcceptOrReject()

  const [, setRefresh] = useState(0)

  const btnDisabled =
    checkIsLoading(saveEntityStatus) || !form.getFieldValue('rejected_reason')

  useEffect(() => {
    dispatch(setIncidentTabIsBlocked(!btnDisabled))
  }, [btnDisabled, dispatch])

  const handleSubmit = async (values: {
    entity: string
    decision: string
    rejected_reason?: string
  }) => {
    const result =
      entityData &&
      (await acceptOrReject({
        entity: `${entity}:${entityData.uuid}`,
        decision: 'rejected',
        rejected_reason: values.rejected_reason,
      }))

    if (result !== undefined) {
      dispatch(setIncidentTabIsBlocked(false))
      getData(true)
    }
  }

  return (
    <Row className="mb-30">
      <Col span={24}>
        <Form
          layout="vertical"
          //eslint-disable-next-line no-template-curly-in-string
          validateMessages={{required: '${label} is required'}}
          form={form}
          onFinish={handleSubmit}
        >
          {/* @ts-ignore not refactored to typescript*/}
          <SelectWithBM
            api={rejectedReasonsApi().get}
            label={translate('rejected_reason')}
            name="rejected_reason"
            // initialValue={record && record.name ? record.name : {}}
            keyName="name"
            optionContent={(rec: {name: string}) => {
              return rec.name
            }}
            rules={{required: true}}
            onChange={() => setRefresh(Math.random())}
          />
          <Button
            title={translate('reject')}
            htmlType="submit"
            type="primary"
            className="mr-5"
            disabled={btnDisabled}
            size="large"
          />
          <Button
            title={translate('cancel')}
            size="large"
            onClick={() => {
              form.resetFields()
              dispatch(setRejectReason(false))
              dispatch(setIncidentTabIsBlocked(false))
            }}
          />
        </Form>
      </Col>
    </Row>
  )
}

export default withErrorBoundary(RejectedReason, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
