import React from 'react'

import {Col, Input, Row} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {translate} from '@/services/i18n'
import {usePostEntity} from '@/hooks/useUpdateData'
import {checkIsLoading} from '@/utils/check-is-loading'
import ErrorPage from '@/components/error/error-page/error-page'
import {Button} from '@/components/button/button'
import FormItemCustom from '@/components/form/form-item-custom'

import {postCloseIncident} from '../../api/incidentsRequests'
import {useStateContext} from '../incident/incident-context'
import {TextContent} from '../text-content/text-content'

import {getWorkingTime} from './utils/get-working-time'
import {IncidentState} from './constants'
import {TIncident} from './typedf'
import {getWorkTime} from './incident-k-request-resolution/utils'

const ResolutionSummary = () => {
  const {
    entityData,
    updateEntity,
    getData,
    entity,
    isLoading,
  } = useStateContext<TIncident>()

  const {timelogs: ticketTimeLogs} = entityData || {}

  const workTime = ticketTimeLogs && getWorkTime(ticketTimeLogs)

  const {saveEntity: postCloseTicket, saveEntityStatus} = usePostEntity(
    postCloseIncident
  )

  const handleReopen = async () => {
    const result = await updateEntity({state_id: IncidentState.In_progress})
    if (result !== undefined) getData(true)
  }

  const {uuid, resolution_code, resolution_notes, state_id} = entityData || {}

  const handleClose = async () => {
    const result = await postCloseTicket({data: `${entity}:${uuid}`})
    if (result !== undefined) getData(true)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          {workTime !== undefined && (
            <h4 className="mb-30">{getWorkingTime(workTime)}</h4>
          )}

          <FormItemCustom label={translate('resolution_code')}>
            <Input
              e2e-test="resolution-code"
              value={resolution_code}
              readOnly
            />
          </FormItemCustom>

          <TextContent
            text={resolution_notes || ''}
            className="mb-15"
            isTextArea={true}
            isDisabled={true}
            title={translate('resolution_notes')}
          />
          {state_id === IncidentState.Resolved && (
            <div className="flex">
              <Button
                className="mr-10 capitalize--first"
                type="primary"
                title={translate(['close', 'ticket']).toLowerCase()}
                onClick={handleClose}
                disabled={checkIsLoading(saveEntityStatus)}
                e2e-test="close-btn"
              />
              <Button
                title={translate('reopen')}
                onClick={handleReopen}
                disabled={isLoading}
                e2e-test="reopen-btn"
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default withErrorBoundary(ResolutionSummary, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
