export enum IconType {
  ARROW_DOWN = 'icon-down',
  ARROW_LEFT = 'icon-left',
  ARROW_RIGHT = 'icon-right',
  ARROW_UP = 'icon-up',
  AT = 'icon-at',
  ATTACHMENT = 'icon-attachment',
  BANK = 'icon-bank',
  BATTERY = 'icon-battery',
  BELL = 'icon-bell',
  BRAILLE_SIX_DOTS = 'icon-braille-six-dots',
  BRIEF_CASE = 'icon-briefcase',
  BUILDING = 'icon-building',
  CAR = 'icon-car',
  CARD = 'icon-card',
  CARET_LEFT = 'icon-caret-left',
  CARET_RIGHT = 'icon-caret-right',
  CHART = 'icon-chart-bar-outline',
  CHECK = 'icon-check',
  CHECK_CIRCLE = 'icon-check-circle',
  CHECK_SQUARE = 'icon-check-square',
  CLOCK = 'icon-clock',
  CLOSE = 'icon-close',
  CLOSE_CIRCLE = 'icon-close-circle',
  CLOSE_SQUARE = 'icon-close-square',
  CLOUD_SQUARE = 'icon-cloud',
  CODE = 'icon-code',
  COMPUTER = 'icon-computer',
  CONTROLS = 'icon-controls',
  CONNECTION = 'icon-connection',
  COPY = 'icon-copy',
  DELETE = 'icon-trash',
  DIRECTIONS = 'icon-directions',
  DOWNLOAD = 'icon-download',
  DRAWER = 'icon-drawer',
  EDIT = 'icon-edit',
  ELLIPSIS = 'icon-ellipsis',
  EMAIL = 'icon-email',
  EMAIL_SERVER = 'icon-emailserver',
  EURO = 'icon-euro',
  EYE = 'icon-eye',
  FIELD_TIME = 'icon-field-time',
  FILE_EMPTY = 'icon-file-empty',
  FILE_FILLED = 'icon-file-filled',
  FILE_SEALED = 'icon-file-sealed',
  FILTER = 'icon-filter',
  FILTER_STRIPES = 'icon-filter-stripes',
  FLAG = 'icon-flag',
  FOLDER = 'icon-folder',
  HISTORY = 'icon-history',
  HEADSET = 'icon-headset',
  HEARTBEAT = 'icon-heartbeat',
  INFO = 'icon-info',
  KEY_BOARD = 'icon-keyboard',
  LINUX_OS = 'icon-linuxos',
  LOADING = 'icon-loading',
  LOCATION = 'icon-location',
  LOCK = 'icon-lock',
  LOGOUT = 'icon-logout',
  LONG_ARROW_LEFT = 'icon-long-arrow-left',
  MAC = 'icon-mac',
  MAC_OS = 'icon-mac-os',
  MENU = 'icon-menu',
  MINUS = 'icon-minus',
  MONITOR = 'icon-desktop',
  MORE = 'icon-more',
  MOUSE = 'icon-mouse',
  NOTEBOOK = 'icon-notebook',
  PAUSE = 'icon-pause-outline',
  PCB = 'icon-pcb',
  PDF_FILE = 'icon-pdf-file',
  PHONE = 'icon-phone',
  PLUS = 'icon-plus',
  POWER_SUPPLY = 'icon-powersupply',
  PRICE = 'icon-price',
  PRINTER = 'icon-printer',
  PROJECT = 'icon-project',
  QUESTION = 'icon-question',
  SDCARD = 'icon-sdcard',
  SEARCH = 'icon-search',
  SEND = 'icon-send',
  SERVERS = 'icon-servers',
  SETTING = 'icon-setting',
  SHARE = 'icon-share',
  SIM = 'icon-sim',
  SNIPPET = 'icon-snippet',
  SORT_ASC = 'icon-sort-asc',
  SORT_DESC = 'icon-sort-desc',
  SQUARE_INTERSECTION = 'icon-squares',
  TARGET = 'icon-target',
  TETRIS_FOUR = 'icon-tetris-four',
  UNDO = 'icon-undo',
  UNORDERED_LIST = 'icon-unordered-list',
  UPLOAD = 'icon-upload',
  UPLOAD_CLOUD = 'icon-cloud-upload',
  USB = 'icon-usb',
  USER = 'icon-user-o',
  USERS = 'icon-users',
  WARNING = 'icon-warning',
  WARNING_ROUNDED = 'icon-warning-rounded',
  WIFI = 'icon-wifi',
  WINDOW_PANE = 'icon-th-large',
  WINDOWS_OS = 'icon-windows',
  WRENCH = 'icon-wrench',
  FILE_EXCEL = 'icon-file-excel',
  ENVELOPE = 'envelope',
  ERROR = 'error',
  EXCLAMATION = 'exclamation',
  DEVICE = 'device',
  KEY = 'key',
  USER_GROUP = 'user-group',
  CLIPBOARD_LIST = 'clipboard-list',
  CLIPBOARD_CHECK = 'clipboard-check',
  CLIPBOARD = 'clipboard',
  X_CIRCLE = 'x-circle',
}
