import {useCallback, useEffect, useState} from 'react'

import {RequestStatus} from '@/typedef'
import {TITSMSelectorNoBC} from '@/modules/ITSM/typedef'
import {mergeBookmarks} from '@/utils/merge-bookmarks'
import {useFetchHook} from '@/hooks/useFetch'
import {
  ISuperAgentResMultiple,
  ISuperAgentResSingle,
} from '@/api/response-typedf'

export function useGetDataList<T extends Record<string, any>>({
  asyncF,
  entity,
  getSingleAsync,
}: {
  asyncF: (options: TITSMSelectorNoBC) => Promise<ISuperAgentResMultiple<T>>
  getSingleAsync: (id: string) => Promise<ISuperAgentResSingle<T>>
  entity: string
}): {
  readonly getEntityData: (
    options: TITSMSelectorNoBC
  ) => Promise<T[] | undefined>
  readonly entityData: T[]
  readonly fetchStatus: RequestStatus
  readonly recordCount: number
  readonly pagination: {bookmark: string | undefined; bookmarks: Array<string>}
  readonly getSingleEntity: (id: string) => void
} {
  const [entityData, setEntityData] = useState<T[]>([])
  const [pagination, setPagination] = useState<{
    bookmark: string | undefined
    bookmarks: Array<string>
  }>({bookmark: undefined, bookmarks: []})
  const [numberOfRecords, setNumberOfRecords] = useState(0)

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getEntityData = useCallback(
    async (options: TITSMSelectorNoBC = {entity, limit: 10}) => {
      const {bookmark: passedBM, resetBookmarks} = options
      try {
        handleRequested()
        const {
          body: {result, bookmark},
        } = await asyncF({entity, limit: 10, ...options})

        if (result) {
          setEntityData(result)
          handleSuccess()
          const bookmarks = mergeBookmarks(
            result.length < 10 ? 'lastBookmark' : bookmark,
            pagination.bookmarks,
            resetBookmarks
          )

          setPagination({
            bookmarks,
            bookmark: passedBM || undefined,
          })

          setNumberOfRecords(10)
          if (result.length >= 10) {
            const {
              body: {result},
            } = await asyncF({
              entity,
              limit: 10,
              bookmark,
            })
            if (result) {
              if (result.length > 0) {
                setNumberOfRecords(11)
              } else {
                setNumberOfRecords(10)
              }
            }
          }
        }
        return result
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [
      asyncF,
      entity,
      handleFail,
      handleRequested,
      handleReset,
      handleSuccess,
      pagination.bookmarks,
    ]
  )

  const getSingleEntity = async (commentId: string) => {
    try {
      const result = await getSingleAsync(commentId)
      if (result) {
        const {uuid} = result
        const updatedComments = entityData.map(entity =>
          entity.uuid === uuid ? {...entity, ...result} : entity
        )
        setEntityData(updatedComments)
        return result
      }
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  useEffect(() => {
    if (entityData.length === 0) {
      getEntityData()
    }
  }, [entityData.length, getEntityData])

  return {
    getEntityData,
    entityData,
    fetchStatus: status,
    recordCount: numberOfRecords,
    pagination,
    getSingleEntity,
  } as const
}
