import React, {useCallback} from 'react'

import {withErrorBoundary} from '@sentry/react'
import {Col, Form, Input, Radio, Row} from 'antd'
import queryString from 'query-string'
import 'moment-duration-format'
import {v4} from 'uuid'
import {useHistory} from 'react-router-dom'

import ErrorPage from '@/components/error/error-page/error-page'
import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {useStateContext} from '@/modules/ITSM/components/incident/incident-context'
import {usePostEntity} from '@/hooks/useUpdateData'
import {PreviewModal} from '@/components/modal/preview-modal/preview-modal'
import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {setIncidentTabIsBlocked} from '@/modules/ITSM/components/incident/incident-reducer'
import {postAttachmentV2} from '@/modules/ITSM/api/attachmentsRequests'
import {UploadButton} from '@/components/upload-button/upload-button'
import {getNameAndExtension} from '@/utils/get-name-and-extension'
import {apiService} from '@/modules/ITSM/api/api-service'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {useDownloadPreview} from '@/modules/ITSM/hooks/use-download-preview'
import {checkExtension} from '@/components/modal/preview-modal/utils'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {useUploadFiles} from '@/hooks/use-upload-files'

import {generateIncidentStatePathITSM} from '../../incident-k-request/utils'
import {options} from '../../../sites/Expenses/constants'
import {postExpense} from '../../../api/expensesRequest'
import {TExpense} from '../../../sites/Expenses/typedef'
import {IncTabs} from '../../incident-k-request/constants'
import {TIncident} from '../../incident-k-request/typedf'
import ItsmListTableNoFilters from '../../itsm-list-table-no-filters/itsm-list-table-no-filters'
import {useGetDataListTableNoFilters} from '../../itsm-list-table-no-filters/hooks/use-get-data-list-table-no-filters'
import {TColumnRender} from '../../itsm-list-table/typedf'

import {IncidentExpensessRowValue} from './incident-expenses-row-value'

import './incident-expense-detail.scss'

const TextArea = Input.TextArea

const asset = ItsmAssets.IncidentExpenses

type TProps = {
  isDisabled: boolean
}
const ExpenseDetail = ({isDisabled}: TProps) => {
  const [form] = Form.useForm()

  const {
    uploadFiles,
    resetUploadedFiles,
    fileList,
    filesConverted,
  } = useUploadFiles()

  const {entityData, entity, dispatch} = useStateContext<TIncident>()
  const history = useHistory()

  const {customer_product, uuid} = entityData || {}

  const {saveEntity: saveExpense} = usePostEntity<TExpense>(postExpense)

  const {
    attachmentAction,
    previewAttachment,
    setPreviewAttachment,
  } = useDownloadPreview()

  const {
    getEntityData: getExpenses,
    entityData: expenses,
    numberOfRecords,
    fetchStatus: expensesFetchStatus,
  } = useGetDataListTableNoFilters<TExpense>({
    asyncF: apiService[asset],
    asset,
    entity,
  })

  const {
    status: saveEntityStatus,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const checkValues = () => {
    dispatch(
      setIncidentTabIsBlocked(
        Object.values(form.getFieldsValue()).some(value => !!value)
      )
    )
  }

  const upload_btn = (
    <UploadButton
      uploadProps={uploadFiles()}
      text={translate('upload_docs')}
      multiple={false}
    />
  )

  const onPreviewModalCancel = () => {
    setPreviewAttachment(undefined)
  }

  const goBack = () => {
    history.push(
      generateIncidentStatePathITSM(entity, uuid, IncTabs.ExpensesTab)
    )
  }

  const currency = customer_product?.pricing_policy?.currency

  const {extension: currentExtension} = getNameAndExtension(
    previewAttachment && previewAttachment.file_name
  )

  const addAttachment = useCallback(
    async (file: any, values: any) => {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('type', file.file.type)

      const fileData = {
        data: formData,
        id: queryString.parse(window.location.search).timelog,
        entity: 'timelog',
        name: file.file.name,
      }

      try {
        const {
          body: {result},
        } = await postAttachmentV2(fileData)

        const fileType = result.metadata.file_name.split('.')

        return {
          entity: `timelog:${
            queryString.parse(window.location.search).timelog
          }`,
          kind: values.kind,
          attachment: {
            file_name: result.metadata.file_name,
            file_type: fileType[fileType.length - 1],
            data: result.uuid,
          },
          price: Number(values.price),
          description: values.description,
        }
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleReset]
  )

  const handleSubmit = useCallback(
    (values: TExpense) => {
      handleRequested()
      try {
        filesConverted.forEach(async file => {
          const result = await addAttachment(file, values)

          const resultTest = result && (await saveExpense(result, v4()))

          if (resultTest) {
            getExpenses({resetBookmarks: true})
            form.resetFields()
            resetUploadedFiles()
            dispatch(setIncidentTabIsBlocked(false))
            handleSuccess()
          }
          return result
        })
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [
      handleRequested,
      filesConverted,
      addAttachment,
      saveExpense,
      getExpenses,
      form,
      resetUploadedFiles,
      dispatch,
      handleSuccess,
      handleFail,
      handleReset,
    ]
  )

  const handleGetRowValue = (props: TColumnRender<TExpense>) => (
    <IncidentExpensessRowValue {...props} attachmentAction={attachmentAction} />
  )

  return (
    <div className="itsm-expense-detail__form">
      <h3 className="flex flex--alignCenter expense-details">
        <GoBackButton onClick={goBack} />

        <span>{translate('expense')}</span>
      </h3>
      {!isDisabled && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={checkValues}
        >
          <Row>
            <Col span={24}>
              <div className="ant-form-item-label ant-form-item-required">
                <label>{translate('price')}</label>
              </div>
              <div className="itsm-expense-detail__price-container">
                <FormItemGeneric<TExpense>
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: `${translate('price')} ${translate(
                        'is_required'
                      )}`,
                    },
                  ]}
                >
                  <Input type="number" />
                </FormItemGeneric>
                <div className="itsm-expense-detail__currency-container">
                  {currency}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItemGeneric<TExpense>
                name="kind"
                label={translate('kind')}
                rules={[
                  {
                    required: true,
                    message: `${translate('kind')} ${translate('is_required')}`,
                  },
                ]}
              >
                <Radio.Group options={options} optionType="button" />
              </FormItemGeneric>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FormItemGeneric<TExpense>
                name="description"
                initialValue={''}
                label={translate('description')}
                rules={[
                  {
                    required: true,
                    message: `${translate('description')} ${translate(
                      'is_required'
                    )}`,
                  },
                ]}
              >
                <TextArea rows={5} />
              </FormItemGeneric>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {upload_btn}
              {fileList.length > 0 && (
                <div className="mb-15 mt-15">
                  <Button
                    type="primary"
                    title={translate('save')}
                    htmlType="submit"
                    disabled={checkIsLoading(saveEntityStatus)}
                    size="large"
                  />
                  <Button
                    className="ml-10"
                    title={translate('cancel')}
                    onClick={resetUploadedFiles}
                    size="large"
                  />
                </div>
              )}
            </Col>
          </Row>
        </Form>
      )}

      <Row>
        <Col span={24}>
          <div className="itsm-expense-detail__table">
            <ItsmListTableNoFilters<TExpense>
              {...{
                getEntityData: getExpenses,
                entityData: expenses,
                numberOfRecords,
                fetchStatus: expensesFetchStatus,
                asset,
                getRowValue: handleGetRowValue,
                loading: checkIsLoading(expensesFetchStatus),
              }}
            />
          </div>
        </Col>
      </Row>
      {previewAttachment?.file_type && previewAttachment?.data && (
        <PreviewModal
          extension={currentExtension ? checkExtension(currentExtension) : ''}
          data={previewAttachment.data}
          onCancel={onPreviewModalCancel}
        />
      )}
    </div>
  )
}

export default withErrorBoundary(ExpenseDetail, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
