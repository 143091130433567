export const USER_DEFAULT = {
  email: '',
  notification: false,
  unavailable: false,
  app_version: '',
  assignment_groups: [],
  company: '',
  contract_type: '',
  type: '',
  email_signature: ' ',
  name: '',
  phone: '',
  surname: '',
  timezone: '',
  user_groups: [],
  pricing_policy: {
    currency: '',
    rate: 0,
    pricing_unit: 0,
    primary_hours_number: 0,
    primary_hour_rate: 0,
    additional_pricing_unit: 0,
    travel_fix_rate: 0,
    travel_distance_unit_rate: 0,
    travel_pricing_unit: 0,
  },
}
