export function selectGrantOrgId(state) {
  return state.invitation.org_id
}

export function selectInvitationStatus(state) {
  return state.invitation.invitationStatus
}

export function selectInvitationStep(state) {
  return state.invitation.step
}

export function selectAccounts(state) {
  return state.invitation.accounts
}

export function selectOrganizations(state) {
  return state.invitation.organizations
}

export function selectAccountID(state) {
  return state.invitation.accountId
}

export function selectChannelInvite(state) {
  return state.invitation.channel_invite
}

export function selectInfoMessage(state) {
  return state.invitation.infoMessage
}

export function selectInvitationAccepted(state) {
  return state.invitation.invitationAccepted
}

export function selectRedirectURL(state) {
  return state.invitation.redirectUrl
}

export function selectGrant(state) {
  return state.invitation.grant
}

export function selectOrgID(state) {
  return state.invitation.grant
}

export function selectPostOrgStatus(state) {
  return state.invitation.postOrganizationStatus
}

export function selectCompanyInvite(state) {
  return state.invitation.companyInvite
}
