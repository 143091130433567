import React, {FC} from 'react'
import {Radio} from 'antd'
import FormItemStyledWrapper from '@/components/form/form-item-styled-wrapper/form-item-styled-wrapper'

import {RadioGroupProps} from 'antd/lib/radio/interface'

export const RadioGroup: FC<RadioGroupProps> = ({
  optionType = 'button',
  buttonStyle = 'solid',
  ...restProps
}) => {
  return (
    <FormItemStyledWrapper>
      <Radio.Group
        {...restProps}
        optionType={optionType}
        buttonStyle={buttonStyle}
      />
    </FormItemStyledWrapper>
  )
}
