import React, {useCallback, useEffect, useState} from 'react'

import {QuestionCircleOutlined} from '@ant-design/icons'
import {Checkbox, Col, Form, Input, Row, Select, Tooltip} from 'antd'
import {ContentState, convertToRaw, EditorState} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import moment from 'moment-timezone'
import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {translate} from '@/services/i18n'
import {getUser} from '@/redux/user/userActions'
import {getDataDifference} from '@/utils/forms/get-data-difference'
import {usePrevious} from '@/hooks/usePrevious'
import {Editor, EditorWrapper} from '@/components/editor'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {RESOLVE} from '@/constants'
import {Button} from '@/components/button'
import {useFetchHook} from '@/hooks/useFetch'
import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import FormItemCustom from '@/components/form/form-item-custom'
import {saveLocation} from '@/modules/ITSM/api/locationRequests'
import {Paper} from '@/components/paper/paper'

import {fetchIdentityMe} from '../../../api/identityRequests'
import {patchUser} from '../../../api/userRequests'
import {NewLocationButton} from '../../../components/createNewLocation/newLocationButton'
import {draftToolbarOptions} from '../../../utils/Constants'
import {OrgChannelDefault} from '../../../../../components/org-channel-default'

import {getUserFullLocation} from './utils'
import {emailValidator} from '@/utils/form-validators/email-validator'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './userSettings.scss'

const Option = Select.Option

const UserSettings = () => {
  const [loading, setLoading] = useState(false)
  const [loadingSettingSubmit, setLoadingSettingSubmit] = useState(false)
  const [userMe, setUserMe] = useState({})
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const {email_signature: emailSignature, location} = userMe
  const prevEmailSignature = usePrevious(emailSignature)
  const prevLocation = usePrevious(location)

  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const saveEmailSignature = useCallback(email_signature => {
    if (email_signature) {
      const contentBlock = htmlToDraft(email_signature)

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )

        setEditorState(EditorState.createWithContent(contentState))
      }
    } else setEditorState(EditorState.createEmpty())
  }, [])

  useEffect(() => {
    if (emailSignature && prevEmailSignature !== emailSignature) {
      saveEmailSignature(emailSignature)
    }
  }, [prevEmailSignature, emailSignature, saveEmailSignature])

  const handleCancel = () => {
    form.resetFields()
    saveEmailSignature(userMe.email_signature)
  }

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
  }

  const createData = useCallback(
    values => {
      let emailSignatureConverted

      if (editorState) {
        emailSignatureConverted = draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        )
      }

      for (let key in values) {
        if (
          key === 'name' ||
          key === 'surname' ||
          key === 'phone' ||
          key === 'email'
        ) {
          values[key] = (values[key] || '').trim()
        }
      }

      const newValues = {
        ...values,
        email_signature:
          emailSignatureConverted === '<p></p>\n'
            ? undefined
            : emailSignatureConverted,
      }
      const changedData = getDataDifference(userMe, newValues)
      if (changedData.location !== undefined) {
        changedData.location = userMe.location
      }

      return changedData
    },
    [editorState, userMe]
  )

  const getUserData = useCallback(async () => {
    setLoading(true)
    try {
      const res = await fetchIdentityMe(null, RESOLVE)

      const userMe = res.body.result?.users[0]
      if (userMe.location) {
        userMe.location = {
          ...userMe.location,
          full_location: getUserFullLocation(userMe.location),
        }

        form.setFieldsValue({location: getUserFullLocation(userMe.location)})
      }

      setUserMe(userMe)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      dispatch(setToastMessage({message: err}))
    }
  }, [dispatch, form])

  const handleSubmitSettings = useCallback(
    async e => {
      if (e) e.preventDefault()

      try {
        const values = await form.validateFields()

        const data = createData(values)
        setLoadingSettingSubmit(true)
        handleRequested()

        await patchUser(userMe.uuid, data)

        handleSuccess()

        dispatch(getUser())
        getUserData()
      } catch (err) {
        handleFail(err)
      } finally {
        setLoadingSettingSubmit(false)
        handleReset()
      }
    },
    [
      form,
      createData,
      handleRequested,
      userMe.uuid,
      handleSuccess,
      dispatch,
      getUserData,
      handleFail,
      handleReset,
    ]
  )

  useEffect(() => {
    getUserData()
  }, [getUserData])

  useEffect(() => {
    if (prevLocation && userMe.location) {
      if (prevLocation.full_location !== userMe.location.full_location) {
        handleSubmitSettings()
      }
    }
  }, [userMe.location, prevLocation, handleSubmitSettings])

  const setLocation = locationData => {
    const updatedLocationData = {
      latitude: locationData.latitude,
      longitude: locationData.longitude,
      city: locationData.city,
      country: locationData.country,
      state_province: locationData.state_province,
      street: locationData.street,
      zip: locationData.zip,
      full_location: getUserFullLocation(locationData),
    }

    form.setFieldsValue({location: updatedLocationData.full_location})
    setUserMe({
      ...userMe,
      location: updatedLocationData,
    })
  }

  return (
    <div className="user-settings">
      <Skeleton active={loading} view={<FormSkeleton />}>
        <Paper>
          <div className="user-settings__title">
            {translate('set_your_default_org_and_channel')}
          </div>
          <OrgChannelDefault />
        </Paper>

        <Paper className={'mt-18'}>
          <Form
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{required: '${label} is required'}}
            form={form}
            layout="vertical"
            name="userSettings"
          >
            {!!Object.keys(userMe).length && (
              <>
                <Row gutter={24}>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      initialValue={userMe.name}
                      name="name"
                      label={translate('name')}
                    >
                      <Input onFocus={setAutocompleteNone} />
                    </FormItemCustom>
                  </Col>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      initialValue={userMe.surname}
                      name="surname"
                      label={translate('surname')}
                    >
                      <Input onFocus={setAutocompleteNone} />
                    </FormItemCustom>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      initialValue={userMe.email}
                      name="email"
                      rules={[{validator: emailValidator}]}
                      label={translate('email')}
                    >
                      <Input onFocus={setAutocompleteNone} />
                    </FormItemCustom>
                  </Col>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      initialValue={userMe.phone}
                      name="phone"
                      label={translate('phone')}
                    >
                      <Input onFocus={setAutocompleteNone} />
                    </FormItemCustom>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      initialValue={userMe.timezone}
                      name="timezone"
                      label={translate('timezone')}
                    >
                      <Select showSearch getPopupContainer={e => e.parentNode}>
                        {moment.tz.names().map(val => (
                          <Option value={val} key={val}>
                            {`${val} [UTC ${moment
                              .tz(moment(), val)
                              .parseZone()
                              .format('Z')}]`}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCustom>
                  </Col>
                  <Col className="user-settings__location" {...twoColumns}>
                    <FormItemCustom
                      initialValue={getUserFullLocation(userMe.location)}
                      name="location"
                      label={
                        <div className="user-settings__location-label">
                          <span>{translate('location')}</span>
                          <span>
                            <NewLocationButton
                              onSave={setLocation}
                              record={userMe.location}
                              withoutSave={true}
                              formRules={{name: [{required: true}]}}
                              asyncF={saveLocation}
                            />
                          </span>
                        </div>
                      }
                    >
                      <Input disabled={true} />
                    </FormItemCustom>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      name="unavailable"
                      label={translate('unavailable')}
                      valuePropName="checked"
                      initialValue={userMe.unavailable}
                    >
                      <Checkbox />
                    </FormItemCustom>
                  </Col>
                  <Col {...twoColumns}>
                    <FormItemCustom
                      name="notification"
                      valuePropName={'checked'}
                      initialValue={userMe.notification}
                      label={
                        <span>
                          {translate('notifications')}&nbsp;
                          <Tooltip
                            placement="top"
                            title={translate('email_notifications_description')}
                          >
                            <QuestionCircleOutlined className="question-circle" />
                          </Tooltip>
                        </span>
                      }
                    >
                      <Checkbox />
                    </FormItemCustom>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <EditorWrapper>
                      <h6>{translate('email_signature')}</h6>
                      <Editor
                        editorState={editorState}
                        toolbar={draftToolbarOptions}
                        onEditorStateChange={onEditorStateChange}
                      />
                    </EditorWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="user-settings__submit-button">
                      <Button
                        title={translate('save')}
                        type="primary"
                        html="submit"
                        disabled={loadingSettingSubmit}
                        onClick={handleSubmitSettings}
                        size="large"
                      />
                      <Button
                        title={translate('cancel')}
                        className="ml-10"
                        onClick={handleCancel}
                        size="large"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Paper>
      </Skeleton>
    </div>
  )
}
export default UserSettings
