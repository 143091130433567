import React, {useState} from 'react'

import {CheckOutlined} from '@ant-design/icons'

import {Dropdown} from '@/components/dropdown'
import {Icon, IconType} from '@/components/icon'
import {TKey, translate} from '@/services/i18n'

import {Button} from '../button'

import './sorting.scss'
import {MenuProps} from 'antd'

const SORTING_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
}

type TProps = {
  sortedColumns: Array<{name: string; isActive: boolean; onClick: any}>
  filterKey?: string
  filterValue?: string
  previewMode?: boolean
}

export const Sorting: React.FC<TProps> = props => {
  const {sortedColumns, filterKey, filterValue, previewMode} = props
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const value: TKey = filterKey as any

  const getDirectionIcon = () => {
    if (filterValue) {
      const type =
        filterValue === SORTING_DIRECTION.ASC
          ? IconType.SORT_ASC
          : IconType.SORT_DESC
      return <Icon type={type} size={14} />
    }
  }

  const handleVisibleChange = (flag: boolean) => {
    setDropdownVisible(flag)
  }

  const menu =
    sortedColumns &&
    sortedColumns.map((column, i) => {
      const {name, onClick} = column
      const itemClass = 'table-sorting__item'

      const isActive = column.isActive

      return (
        <div key={i}>
          <div
            className={
              isActive ? `${itemClass} ${itemClass}--active` : itemClass
            }
          >
            <div className="table-sorting__inner-container">
              <span className="table-sorting__name">{name}</span>

              <div className="table-sorting__buttons-container">
                <button
                  className={
                    filterValue?.toUpperCase() === SORTING_DIRECTION.DESC &&
                    isActive
                      ? 'table-sorting__inner-button--active table-sorting__inner-button'
                      : 'table-sorting__inner-button'
                  }
                  onClick={() => onClick(SORTING_DIRECTION.DESC.toLowerCase())}
                >
                  <Icon type={IconType.SORT_DESC} size={14} />
                  {filterValue?.toUpperCase() === SORTING_DIRECTION.DESC &&
                    isActive && <CheckOutlined />}
                </button>
                <button
                  className={
                    filterValue?.toUpperCase() === SORTING_DIRECTION.ASC &&
                    isActive
                      ? 'table-sorting__inner-button--active table-sorting__inner-button'
                      : 'table-sorting__inner-button'
                  }
                  onClick={() => onClick(SORTING_DIRECTION.ASC.toLowerCase())}
                >
                  <Icon type={IconType.SORT_ASC} size={14} />
                  {filterValue?.toUpperCase() === SORTING_DIRECTION.ASC &&
                    isActive && <CheckOutlined />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    })

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <div className="table-sorting__container">
          <div className="table-sorting__menu-header capitalize--first">
            {translate('sort_by')}
          </div>
          {menu}
        </div>
      ),
    },
  ]

  return (
    <Dropdown
      menu={{items}}
      trigger={['click']}
      open={!previewMode && dropdownVisible}
      onOpenChange={handleVisibleChange}
      getPopupContainer={e => e.parentNode as HTMLElement}
    >
      <Button
        icon={getDirectionIcon() || <Icon type={IconType.SORT_ASC} size={14} />}
        title={translate(value) || translate('sort')}
      />
    </Dropdown>
  )
}
