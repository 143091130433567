import {createContext, Dispatch, useContext} from 'react'

import once from 'lodash/once'

import {Entities} from '@/typedef'

import {initialState, TInitialState} from './incident-reducer'

type TProps<T> = {
  incState: TInitialState
  entityData?: T
  dispatch: Dispatch<{type: any}>
  getData: (setTab?: boolean) => void
  updateEntity: (values: any) => void
  getTimeSession?: () => void
  entity: Entities | string
  isLoading?: boolean
}

export const createStateContext = once(<T,>() =>
  createContext<TProps<T>>({
    incState: initialState,
    entityData: undefined,
    dispatch: () => Promise.resolve(),
    getData: () => new Promise<void>(resolve => resolve()),
    updateEntity: () => new Promise<void>(resolve => resolve()),
    getTimeSession: () => Promise.resolve(),
    entity: '',
    isLoading: false,
  })
)

export const useStateContext = <T,>() => useContext(createStateContext<T>())
