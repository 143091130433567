export function selectUserID(state) {
  return state.itsmUser.userID
}

export function selectOrgID(state) {
  return state.user.orgID
}

export function selectRestoreInterfaceStatus(state) {
  return state.itsmUser.reAuthStatus
}

export function selectModuleName(state) {
  return state.itsmUser.module
}

export function selectUserName(state) {
  return state.itsmUser.userNameInChannel
}

export function selectAuthFailed(state) {
  return state.itsmUser.authFailed
}

export function selectRefreshTokenStatus(state) {
  return state.itsmUser.refreshOrgTokenStatus
}

export function selectRequestIdentityMe(state) {
  return state.itsmUser.reqIdentityMeStatus
}

export function selectDocsRead(state) {
  return state.itsmUser.docs_read
}

export function selectUserOrgRole(state) {
  return state.itsmUser.orgRole
}

export function selectAppVersion(state) {
  return state.itsmUser.app_version
}

export function selectEmptySpace(state) {
  return state.itsmUser.emptySpace
}

export function selectUserDefaults(state) {
  return state.itsmUser.user_defaults
}

export function selectUserRoleType(state) {
  return state.itsmUser.type
}

export function selectBackEndToken(state) {
  return state.itsmUser.backEndToken
}

export function selectBackEndTokenExpiry(state) {
  return state.itsmUser.tokenExp
}

export function selectAccessToken(state) {
  return state.itsmUser.accessToken
}
export function selectUserEmail(state) {
  return state.itsmUser.email
}
