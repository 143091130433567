import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { useCheckAccess, TYPE } from '@/hooks/useCheckAccess';
import { TKey, translate } from '@/services/i18n';
import { Drawer } from '@/components/drawer';
import { selectWindowWidth } from '@/redux/reducers/generalSelector';
import styles from '@/styles/_variables.module.scss';
import SentryErrorBoundary from '@/components/error/sentry-error-boundary';
import ErrorPage from '@/components/error/error-page/error-page';

import { CreateCustomerProductLocation } from '../../sites/ITSMSettings/CustomerProducts/create-location';
import { ItsmAssets, TCustomerLocationRecord, TLocation } from '../../typedef';

import { CreateNewLocation } from './createNewLocation';
import './new-location-button.scss';
import { Tooltip } from 'antd';

interface IProps extends TCustomerLocationRecord {
  label?: TKey;
  itemType?: ItsmAssets;
  record?: TLocation;
  formTitle?: string;
}

export const NewLocationButton: React.FC<IProps> = ({
  label = 'new_location',
  itemType,
  record,
  formTitle = translate('create_new') + translate('location').toLowerCase(),

  ...props
}) => {
  const [shown, setShown] = useState(false);
  const isLocationAccess = useCheckAccess({ type: TYPE.LOCATION });
  const windowWidth = useSelector(selectWindowWidth);

  const showCreateNewLocation = () => {
    setShown(true);
  };

  const closeCreateNewLocation = () => {
    setShown(false);
  };

  return isLocationAccess ? (
    <div>
      <span
        className="new-location-button__label"
        onClick={showCreateNewLocation}
      >
        {translate(label)}
        <PlusOutlined />
      </span>
      <Drawer
        title={formTitle}
        placement="right"
        onClose={closeCreateNewLocation}
        open={shown}
        width={windowWidth <= parseInt(styles.mediumScreen) ? windowWidth : 800}
        destroyOnClose={true}
      >
        <SentryErrorBoundary
          fallback={
            <ErrorPage
              resolvers={[closeCreateNewLocation]}
              buttonTitle={translate('close')}
              noRedirect={true}
            />
          }
        >
          {itemType === ItsmAssets.CustomerProducts ||
          itemType === ItsmAssets.SupplierProducts ? (
            <CreateCustomerProductLocation
              {...{
                ...props,
                record,
                isVisible: shown,
                onClose: closeCreateNewLocation,
              }}
            />
          ) : (
            <CreateNewLocation
              {...{
                ...props,
                isVisible: shown,
                onClose: closeCreateNewLocation,
              }}
            />
          )}
        </SentryErrorBoundary>
      </Drawer>
    </div>
  ) : (
    <Tooltip title={translate('add_location_permission')}>
      <span className="new-location-button__label-disabled">
        {translate(label)}
        <PlusOutlined />
      </span>
    </Tooltip>
  );
};
