// @ts-nocheck ToDo fix type
import React, {Suspense} from 'react'

import {OverlaySpinner} from '@/components/overlay-spinner'
import {RouteComponentProps} from 'react-router-dom'

type TWithSuspense = <Props extends RouteComponentProps>(
  WithSuspenseComponent: React.ComponentType<Props>,
  fallback?: React.ReactNode
) => (props: Props) => JSX.Element

const withSuspense: TWithSuspense = (
  WithSuspenseComponent,
  fallback
) => props => {
  const fallbackComponent = fallback ?? (
    <OverlaySpinner idName={'with-suspense'} />
  )

  return (
    <Suspense fallback={fallbackComponent}>
      <WithSuspenseComponent {...props} />
    </Suspense>
  )
}

export default withSuspense
