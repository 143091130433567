import React, {useCallback, useEffect} from 'react'

import {Col, Row} from 'antd'
import {useHistory} from 'react-router'
import {useSelector} from 'react-redux'
import {withErrorBoundary} from '@sentry/react'

import {usePrevious} from '@/hooks/usePrevious'
import ErrorPage from '@/components/error/error-page/error-page'
import {TAppState} from '@/redux/store'
import {ItsmAssets, TTimeLog} from '@/modules/ITSM/typedef'
import {apiService} from '@/modules/ITSM/api/api-service'
import {selectItsmAssetPagination} from '@/modules/ITSM/store/list-table/selectors'

import {useGetDataListTableNoFilters} from '../../itsm-list-table-no-filters/hooks/use-get-data-list-table-no-filters'
import {TIncident} from '../../incident-k-request/typedf'
import {useStateContext} from '../incident-context'
import {TimeLogsRowValue} from '../../time-logs/time-logs-row-value'
import ItsmListTableNoFilters from '../../itsm-list-table-no-filters/itsm-list-table-no-filters'
import {TColumnRender} from '../../itsm-list-table/typedf'

const Expenses = () => {
  const history = useHistory()

  const asset = ItsmAssets.TimelogExpenses

  const {entity} = useStateContext<TIncident>()

  const {
    getEntityData: fetchTimeLogs,
    entityData: timeLogsData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTableNoFilters<TTimeLog>({
    asyncF: apiService[asset],
    asset,
    entity,
  })

  const paginationAsset =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const prevPaginationAsset = usePrevious(paginationAsset)

  const refetchTimeLogs = useCallback(() => {
    fetchTimeLogs({resetBookmarks: true})
  }, [fetchTimeLogs])

  useEffect(() => {
    if (
      prevPaginationAsset?.bookmarks.length &&
      !paginationAsset.bookmarks.length
    ) {
      refetchTimeLogs()
    }
  }, [paginationAsset, prevPaginationAsset, refetchTimeLogs])

  const handleRowClick = (record: TTimeLog) =>
    history.push('?timelog=' + record.uuid)

  const handleGetRowValue = (props: TColumnRender<TTimeLog>) => (
    <TimeLogsRowValue {...props} />
  )

  return (
    <Row>
      <Col xs={24}>
        <div className="time-logs-table" e2e-test="time-logs-table">
          <ItsmListTableNoFilters<TTimeLog>
            {...{
              getEntityData: fetchTimeLogs,
              entityData: timeLogsData,
              numberOfRecords,
              fetchStatus,
              asset,
              onRowClick: handleRowClick,
              getRowValue: handleGetRowValue,
            }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withErrorBoundary(Expenses, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
