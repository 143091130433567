const selectGeneralState = state => state.general

export function selectRefresh(state) {
  return state.general.refresh
}

export function selectPagination(state) {
  return state.general.pagination
}

export function selectGeneralRedirect(state) {
  return state.general.redirect
}

export function selectPathname(state) {
  return state.general.pathname
}

export function selectError(state) {
  return state.general.err
}

export const selectWindowWidth = state => {
  return selectGeneralState(state).windowWidth
}

export const selectAllPriorityValues = state => {
  return selectGeneralState(state).priority_values
}

export function selectPriorityValues(state, val) {
  return state.general.priority_values.find(e =>
    Object.prototype.hasOwnProperty.call(e, val)
  )?.[val]
}
