import isBoolean from 'lodash/isBoolean'

import {TRoute} from '@/typedef'

import {Feature} from './typedef'
import {useGetModulePremissions} from './use-get-module-permissions'

type TUseFeatureAllowed = () => {
  isFeatureAllowed: (feature: Feature) => boolean
  isRouteAllowedByUserRole: (route: TRoute) => boolean
}

export const useFeatureAllowed: TUseFeatureAllowed = () => {
  const {
    isModuleFeatureAllowed,
    isModuleRouteAllowed,
  } = useGetModulePremissions()

  const isFeatureAllowed = (feature: Feature) => {
    const featureAllowed = isModuleFeatureAllowed(feature)

    return isBoolean(featureAllowed) ? featureAllowed : true
  }

  const isRouteAllowedByUserRole = (route: TRoute) => {
    const routeAllowed = isModuleRouteAllowed(route)

    return isBoolean(routeAllowed) ? routeAllowed : true
  }

  return {isFeatureAllowed, isRouteAllowedByUserRole}
}
