import React, {FC} from 'react'
import {Form} from '@/components/form/form/form'
import {Col, Input, Row} from 'antd'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {translate} from '@/services/i18n'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import TextArea from 'antd/lib/input/TextArea'
import {useForm} from 'antd/lib/form/Form'

import {rules} from '@/components/form/constants/rules'

type TForm = {
  name: string
  phone: string
  email: string
  notes?: string
}

type TProps = {
  onSubmit: (values: TForm) => void
}

export const ContactForm: FC<TProps> = ({onSubmit}) => {
  const [form] = useForm()

  return (
    <Form<TForm> className="contact-form" form={form} onSubmit={onSubmit}>
      <Row>
        <Col span={24}>
          <FormItemGeneric
            name="name"
            label={translate('name')}
            rules={[{required: true}]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col span={24}>
          <FormItemGeneric
            name="phone"
            label={translate('phone')}
            validateTrigger={['onBlur']}
            rules={[{required: true}, rules.phone]}
          >
            <Input onFocus={setAutocompleteNone} />
          </FormItemGeneric>
        </Col>
        <Col span={24}>
          <FormItemGeneric
            name="email"
            label={translate('email')}
            rules={[{required: true}, rules.email]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col span={24}>
          <FormItemGeneric name="notes" label={translate('notes')}>
            <TextArea />
          </FormItemGeneric>
        </Col>
      </Row>
    </Form>
  )
}
