import React, {useEffect, useMemo, useState} from 'react'

import {PlusOutlined} from '@ant-design/icons'
import {Col, Divider, FormInstance, Input, InputNumber, Row, Select} from 'antd'
import {Coords} from 'google-map-react'

import {Button} from '@/components/button'
import LocationMap from '@/components/location-map/location-map'
import {countries} from '@/services/countries'
import {translate} from '@/services/i18n'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {useGeocodingByPosition} from '@/hooks/map-hooks'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {fillFormWithMap} from '../../utils/Helpers'
import {TLocation} from '../../typedef'

import {useCities} from './hooks/useCities'
import './event-form.scss'

const Option = Select.Option

type TProps = {
  form: FormInstance
}
const EventLocation = ({form}: TProps) => {
  const {getCities, cities, setCities} = useCities()
  const [toggleDropDown, setToggleDropDown] = useState(false)
  const [newCityName, setNewCityName] = useState('')
  const [coordinates, setCoordinates] = useState<Coords>({lat: 0, lng: 0})

  const fillCities = async (arg?: string) => {
    getCities(form.getFieldValue('country'))
    if (arg !== 'init') {
      form.setFieldsValue({city: ''})
    }
  }

  const {setSearchPosition, searchData} = useGeocodingByPosition()

  const searchDataByPosition = useMemo(
    () => searchData?.[0]?.address_components || [],
    [searchData]
  )

  useEffect(() => {
    if (searchDataByPosition.length) {
      fillFormWithMap(searchDataByPosition, form)
    }
  }, [searchDataByPosition, form])

  useEffect(() => {
    form.setFieldsValue({latitude: coordinates.lat})
    form.setFieldsValue({longitude: coordinates.lng})
  }, [coordinates, form])

  const addCityItem = () => {
    setCities([...cities, newCityName])
    form.setFieldsValue({city: newCityName})
    setToggleDropDown(false)
    setNewCityName('')
  }

  const getMapLatLong = async ({lat, lng}: Coords) => {
    setSearchPosition({lat, lng})
    setCoordinates({lat, lng})
    fillCities('init')
  }

  return (
    <>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TLocation>
            label={translate('country')}
            name="country"
            initialValue={null}
          >
            <Select
              allowClear
              showSearch
              getPopupContainer={e => e.parentNode}
              onSelect={() => {
                fillCities()
                form.setFieldsValue({city: null})
              }}
              onChange={e => !e && setCities([])}
            >
              {countries.map(country => (
                <Option value={country.name} key={country.name}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TLocation>
            name="state_province"
            initialValue={null}
            label={translate('state_province')}
          >
            <Input />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TLocation>
            name="city"
            initialValue={null}
            label={translate('city')}
          >
            <Select
              allowClear
              showSearch
              getPopupContainer={e => e.parentNode}
              open={toggleDropDown}
              onDropdownVisibleChange={open => setToggleDropDown(open)}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider />
                  <div className="itsm-add-city-btn-wrap">
                    <Input
                      value={newCityName}
                      onChange={e => setNewCityName(e.target.value)}
                    />
                    <Button
                      type="primary"
                      className="itsm-add-city-btn"
                      onClick={addCityItem}
                      icon={<PlusOutlined />}
                      title={`${translate('add')} ${translate('city')}`}
                    />
                  </div>
                </div>
              )}
            >
              {cities.map((city, index) => (
                <Option value={city} key={index}>
                  {city}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TLocation>
            name="street"
            initialValue={null}
            label={`${translate('street')} & ${translate('number')}`}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TLocation>
            name="zip"
            initialValue={null}
            label={translate('zip')}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns} className="flex">
          <FormItemGeneric<TLocation>
            className="event-form__hideMsg mr-15"
            name="latitude"
            initialValue={null}
            label={translate('latitude')}
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(`${translate('latitude_req')}`)
                },
              }),
            ]}
          >
            <InputNumber min={-90} max={90} />
          </FormItemGeneric>
          <FormItemGeneric<TLocation>
            className="event-form__hideMsg"
            name="longitude"
            initialValue={''}
            label={translate('longitude')}
            rules={[
              {
                required: true,
              },
              () => ({
                validator(rule, value) {
                  if (value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(`${translate('longitude_req')}`)
                },
              }),
            ]}
          >
            <InputNumber min={-180} max={180} />
          </FormItemGeneric>
        </Col>
      </Row>

      <Col span={24} className="mb-15 mt-15">
        <LocationMap onChange={getMapLatLong} />
      </Col>
    </>
  )
}

export default EventLocation
