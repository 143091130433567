import draftToHtml from 'draftjs-to-html'
import {convertToRaw, EditorState} from 'draft-js'

type TData = {
  id: string
  entity: string
  editorState?: EditorState
}

export const createCommentData = (data: TData) => {
  const {id, entity, editorState} = data

  let comment

  if (editorState && editorState.getCurrentContent().hasText()) {
    comment = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  }

  return {
    entity: `${entity}:${id}`,
    text: comment,
  }
}
