import {useCallback, useState} from 'react'
import {useFetchHook} from '@/hooks/useFetch'

import {getGrantLevel as getGrantLevelRequest} from '../../../api/accountRequests'

export const useGetGrantLevel = () => {
  const [userRole, setUserRole] = useState('')

  const {status, handleRequested, handleSuccess, handleFail} = useFetchHook()

  const getGrantLevel = useCallback(
    async (id: string) => {
      try {
        handleRequested()
        const {body} = await getGrantLevelRequest(id)
        if (body) {
          handleSuccess()
          setUserRole(body?.grantLevel)
        }
      } catch (err) {
        setUserRole('user')
        handleFail(err)
      }
    },
    [handleFail, handleRequested, handleSuccess]
  )

  return {
    getGrantLevel,
    userRole,
    status,
  }
}
