import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {
  AutoComplete,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd'
import {Rule} from 'antd/lib/form'
import {FormInstance} from 'antd/lib/form/Form'
import {Coords} from 'google-map-react'
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete'

import {TIME_ZONES} from '@/constants'
import {translate} from '@/services/i18n'
import {filterOption} from '@/utils/filter-option'
import LocationMap from '@/components/location-map/location-map'
import {useGeocodingByPosition} from '@/hooks/map-hooks'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {Footer} from '@/components/layout'
import {Button} from '@/components/button'
import {checkIsLoading} from '@/utils/check-is-loading'
import {RequestStatus} from '@/typedef'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {TLocation} from '../../typedef'

import './location-form.scss'

import {parseGeocodingResponse} from './utils'
import {countriesList} from './countries'

const Option = Select.Option
const RULES = {
  country: [{required: true}],
  city: [{required: true}],
  street: [{required: true}],
  latitude: [{required: true}],
  longitude: [{required: true}],
}
const getAutocompleteOptions = (data: string[]) =>
  data.map(item => ({value: item}))

type TProps = {
  initial?: React.RefObject<FormInstance>
  form: FormInstance
  initialValues?: TLocation
  onChange?: (args: {isValid: boolean; isSomeFieldsFilled: boolean}) => void
  rules?: {[key: string]: Rule[]}
  handleSubmit: () => void
  saveStatus: RequestStatus
  handleClose: () => void
}

export const LocationForm: React.FC<TProps> = ({
  initialValues = {},
  onChange,
  form,
  handleSubmit,
  saveStatus,
  handleClose,
  ...props
}) => {
  const [mapCoordinates, setMapCoordinates] = useState<Coords | undefined>()
  const [streetCoordinates, setStreetCoordinates] = useState<
    Coords | undefined
  >()

  const [countryCode, setCountryCode] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')

  const [streetSuggestions, setStreetSuggestions] = useState<
    string[] | undefined
  >()

  const rules: Record<string, Array<{required: boolean}>> = useMemo(
    () => ({...RULES, ...props.rules}),
    [props.rules]
  )

  const {
    setSearchPosition,
    country,
    city: cityByPosition,
    street: streetByPosition,
    zipCode: zipCodeByPosition,
    searchData: searchDataByPosition,
  } = useGeocodingByPosition()

  const locationDataByCoordinates = searchDataByPosition?.[0]?.formatted_address

  useEffect(() => {
    if (locationDataByCoordinates) {
      form.setFieldsValue({
        city: cityByPosition || null,
        street: streetByPosition || null,
        zip: zipCodeByPosition || null,
        location: locationDataByCoordinates || null,
      })

      setCity(cityByPosition || '')
      setStreet(streetByPosition || '')
      setStreetSuggestions(streetByPosition ? [streetByPosition] : [])

      if (country) {
        const countryByPosition = (country as unknown) as {
          longName: string
          shortName: string
        }
        const {longName, shortName} = countryByPosition
        form.setFieldsValue({country: longName})
        setCountryCode(shortName.toLowerCase())
      }
    }
  }, [
    cityByPosition,
    country,
    form,
    locationDataByCoordinates,
    streetByPosition,
    zipCodeByPosition,
  ])

  useEffect(() => {
    form.setFieldsValue({
      latitude: mapCoordinates && mapCoordinates.lat,
      longitude: mapCoordinates && mapCoordinates.lng,
    })
  }, [form, mapCoordinates])

  useEffect(() => {
    if (initialValues && initialValues.latitude && initialValues.longitude) {
      setMapCoordinates({
        lat: Number(initialValues.latitude),
        lng: Number(initialValues.longitude),
      })
    }
  }, [initialValues])

  const handleSelectStreet = () => {
    const {lat, lng} = streetCoordinates || {lat: 0, lng: 0}
    form.setFieldsValue({
      zip: zipCode,
    })

    setMapCoordinates({
      lat,
      lng,
    })
  }

  const handleSelectCountry = (value: string) => {
    setCountryCode(value)
    setCity('')
    setStreet('')
    setZipCode('')
    setStreetSuggestions([])

    form.setFieldsValue({
      city: null,
      street: null,
      zip: null,
      location: null,
    })
  }

  const handleSelectCity = (value: string) => {
    setCity(value)
    setStreet('')
    setZipCode('')
    setStreetSuggestions([])

    form.setFieldsValue({
      street: null,
      zip: null,
      location: null,
    })
  }

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      setCountryCode(
        countriesList.find(({name}) => name === initialValues.country)?.code ||
          ''
      )
      setCity(initialValues.city || '')
    }
  }, [initialValues])

  const handleChangeStreet = async (addressValue: string) => {
    const result = await geocodeByAddress(addressValue)
    const {zipCode, streets, coordinates} = parseGeocodingResponse(result)
    const spaceBeforeCity = 2
    const startCityInStr = addressValue.length - city.length - spaceBeforeCity
    const streetValue = addressValue.slice(0, startCityInStr)

    if (streets.includes(streetValue)) {
      form.setFieldsValue({
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        zip: zipCode,
      })
    } else {
      form.setFieldsValue({
        latitude: null,
        longitude: null,
        zip: null,
      })
    }

    setStreet(addressValue)
    setStreetSuggestions(streets)
    setZipCode(zipCode)
    setStreetCoordinates({
      lat: coordinates.lat,
      lng: coordinates.lng,
    })
  }

  const onLocationMapChange = useCallback(
    ({lat, lng}: {lat: number; lng: number}) => {
      setMapCoordinates({lat, lng})
      setSearchPosition({lat, lng})
    },
    [setSearchPosition]
  )

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      name="drawer-category"
      form={form}
      validateMessages={{required: '${label} is required'}} // eslint-disable-line
      className="location-form drawer-form"
    >
      <Row>
        <Col className="col-flex--two">
          <FormItemGeneric<TLocation>
            name="name"
            rules={rules.name}
            label={translate('name')}
          >
            <Input onFocus={setAutocompleteNone} />
          </FormItemGeneric>

          <FormItemGeneric<TLocation>
            name="time_zone"
            label={translate('time_zone')}
            rules={rules.time_zone}
          >
            <Select showSearch={true} allowClear={true}>
              {TIME_ZONES.map(({value, time}) => (
                <Option value={value} key={value}>
                  {`${value} [UTC${time}]`}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>
        </Col>
      </Row>
      <Row>
        <Col className="col-flex--two">
          <FormItemGeneric<TLocation>
            name="phone"
            label={translate('phone')}
            rules={rules.phone}
          >
            <Input onFocus={setAutocompleteNone} />
          </FormItemGeneric>

          <FormItemGeneric<TLocation>
            name="fax_phone"
            label={translate('fax_phone')}
            rules={rules.fax_phone}
          >
            <Input onFocus={setAutocompleteNone} />
          </FormItemGeneric>
        </Col>
      </Row>

      <Row>
        <Col className="col-flex--two">
          <FormItemGeneric<TLocation>
            name="country"
            label={translate('country')}
            rules={rules.country}
          >
            <Select
              onSelect={handleSelectCountry}
              filterOption={filterOption}
              showSearch={true}
              placeholder={translate('start_typing')}
              className="full-width"
              onChange={(value: string) => setCountryCode(value)}
              onFocus={setAutocompleteNone}
            >
              {countriesList.map(({name, code}) => (
                <Option key={code} value={code}>
                  {name}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>

          <PlacesAutocomplete
            value={city}
            onChange={setCity}
            searchOptions={{
              types: ['(cities)'],
              componentRestrictions: {country: countryCode},
            }}
          >
            {({getInputProps, suggestions, loading}) => {
              return (
                <FormItemGeneric<TLocation>
                  name="city"
                  label={translate('city')}
                  rules={rules.city}
                >
                  <Select
                    onSearch={e =>
                      getInputProps().onChange({target: {value: e}})
                    }
                    filterOption={false}
                    notFoundContent={null}
                    showSearch={true}
                    showArrow={false}
                    placeholder={translate('start_typing')}
                    className="full-width"
                    disabled={!countryCode}
                    onSelect={handleSelectCity}
                    onFocus={setAutocompleteNone}
                  >
                    {loading ? (
                      <Option value="loading" key="loading">
                        <Spin />
                      </Option>
                    ) : (
                      suggestions.map(
                        ({formattedSuggestion: {mainText}}, i) => (
                          <Option key={i} value={mainText}>
                            {mainText}
                          </Option>
                        )
                      )
                    )}
                  </Select>
                </FormItemGeneric>
              )
            }}
          </PlacesAutocomplete>
        </Col>
      </Row>
      <Row>
        <Col className="col-flex--two">
          <PlacesAutocomplete
            value={street}
            onChange={handleChangeStreet}
            searchOptions={{
              types: ['adrress'],
              componentRestrictions: {country: countryCode},
            }}
          >
            {({getInputProps}) => {
              return (
                <FormItemGeneric<TLocation>
                  name="street"
                  label={translate('street')}
                  rules={[
                    ...rules.street,
                    ({getFieldValue}) => ({
                      validator() {
                        if (
                          !getFieldValue('latitude') &&
                          getFieldValue('street')
                        ) {
                          return Promise.reject(
                            new Error(translate('street_not_found'))
                          )
                        }

                        return Promise.resolve()
                      },
                    }),
                  ]}
                  validateTrigger={['onBlur']}
                >
                  <AutoComplete
                    options={
                      streetSuggestions &&
                      getAutocompleteOptions(streetSuggestions)
                    }
                    onSelect={handleSelectStreet}
                    className="full-width"
                    onSearch={e =>
                      getInputProps().onChange({
                        target: {value: `${e}, ${city}`},
                      })
                    }
                    placeholder={translate('start_typing')}
                    disabled={!city}
                    onFocus={setAutocompleteNone}
                  />
                </FormItemGeneric>
              )
            }}
          </PlacesAutocomplete>

          <FormItemGeneric<TLocation>
            name="zip"
            label={translate('zip')}
            rules={rules.zipCode}
          >
            <Input disabled={!city} onFocus={setAutocompleteNone} />
          </FormItemGeneric>
        </Col>
      </Row>

      <Row hidden>
        <Col className="col-flex--two">
          <FormItemGeneric<TLocation>
            name="latitude"
            label={translate('latitude')}
            rules={rules.longitude}
          >
            <InputNumber disabled={true} />
          </FormItemGeneric>

          <FormItemGeneric<TLocation>
            name="longitude"
            label={translate('longitude')}
            rules={rules.latitude}
          >
            <Input disabled={true} />
          </FormItemGeneric>
        </Col>
      </Row>
      <Col className="location-form__map-wrapper">
        <LocationMap
          coordinates={mapCoordinates}
          onChange={onLocationMapChange}
        />
      </Col>
      <Footer className="drawer-footer">
        <Button
          title={translate('save')}
          disabled={checkIsLoading(saveStatus)}
          type="primary"
          htmlType="submit"
          size="large"
          onClick={handleSubmit}
        />
        <Button
          title={translate('cancel')}
          onClick={handleClose}
          size="large"
        />
      </Footer>
    </Form>
  )
}
