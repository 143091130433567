import React, {Key, useEffect, useState} from 'react'

import {useSelector} from 'react-redux'
import moment from 'moment'
import {useHistory} from 'react-router-dom'

import DataTable from '@/components/data-table/data-table'
import {selectUserOrgRole} from '@/redux/user/userSelector'
import {translate} from '@/services/i18n'
import {Button} from '@/components/button'
import {useGetBillingReportsList} from '@/hooks/use-get-billing-reports-list'
import {checkIsLoading} from '@/utils/check-is-loading'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {ExportBtn} from '@/modules/ITSM/components/export-btn/export-btn'

import {TColumnRender} from '../../../components/itsm-list-table/typedf'
import {getUpdatedColumns} from '../utils/get-updated-columns'
import {generateActiveColumns} from '../utils/generate-active-columns'
import {billingReportColumns, BillingReportTabs} from '../constants'
import {TBillingItem} from '../../finance-dashboard/types'

import {BillingReportRowValue} from './billing-reports-row-value'
import {useExportBillingReports} from './hooks/use-export-billing-reports'
import {useExportBillingItems} from './hooks/use-export-billing-items'

import './billing-reports.scss'

const selector = {
  date_from: '2020-01-01T00:00:00Z',
  date_to: '2100-03-31T00:00:00Z',
}

const BillingReports = () => {
  const {
    getData,
    fetchStatus,
    flatBillingReportsData,
  } = useGetBillingReportsList()

  const {exportBillingReports} = useExportBillingReports()
  const {exportBillingItems} = useExportBillingItems()

  const role = useSelector(selectUserOrgRole)

  const history = useHistory()

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key>>([])

  const handleGetRowValue = (props: TColumnRender<TBillingItem>) => (
    <BillingReportRowValue {...props} />
  )

  const newData = flatBillingReportsData.sort(
    (a, b) =>
      new Date(b?.interval_start || '').getTime() -
      new Date(a?.interval_start || '').getTime()
  )

  const handleRowClick = (record: TBillingItem) => {
    const {interval_start, uuid} = record || {}

    history.push(
      itsmRoutes.billingReportsState.path(
        uuid || moment(interval_start).format('MMM'),
        BillingReportTabs.OverviewTab,
        !uuid
          ? '?month=' +
              (interval_start.substring(0, interval_start.indexOf('T')) +
                'T00:00:00Z')
          : ''
      )
    )
  }

  const handleRedirectToCurrentBR = () =>
    history.push(
      itsmRoutes.billingReportsState.path(
        'current',
        BillingReportTabs.OverviewTab
      )
    )

  const rowSelection = {
    onChange: (selectedRowKeys: Array<Key>) => {
      setSelectedRowKeys(selectedRowKeys?.filter(uuid => uuid))
    },
  }
  useEffect(() => {
    getData(selector)
  }, [getData])

  return (
    <div className="billing-reports">
      <DataTable<TBillingItem>
        tableHeader={
          <div className="billing-reports__header">
            <div className="billing-reports__header-exports">
              <ExportBtn
                onChange={exportBillingReports}
                title={translate('export_all')}
              />
              <ExportBtn
                onChange={exportBillingReports}
                isDisabled={!selectedRowKeys.length}
                title={translate('export_selected_br')}
                selectedRowKeys={selectedRowKeys}
              />
              <ExportBtn
                onChange={exportBillingItems}
                isDisabled={!selectedRowKeys.length}
                title={translate('export_bi')}
                selectedRowKeys={selectedRowKeys}
              />
            </div>

            <Button
              title={translate('current_br')}
              onClick={handleRedirectToCurrentBR}
              type="primary"
              className="ml-10 billing-reports__header-button"
              size="large"
            />
          </div>
        }
        loading={checkIsLoading(fetchStatus)}
        data={newData}
        columns={generateActiveColumns({
          columns: getUpdatedColumns(billingReportColumns, role),
          getRowValue: handleGetRowValue,
        })}
        onRowClick={handleRowClick}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="generatedRowKey"
        rowClassName={record => {
          return `${!record.uuid ? 'checkbox-hidden' : ''} pointer-cursor`
        }}
      />
    </div>
  )
}

export default BillingReports
