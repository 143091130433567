import React, {useEffect, useState} from 'react'

import {useDispatch} from 'react-redux'
import {Form} from 'antd'
import {v4 as uuid} from 'uuid'

import {useFetchHook} from '@/hooks/useFetch'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {fetchOrgsRole} from '@/modules/ITSM/api/itsmOrgRequests'
import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'

import {postAssignmentGroup} from '../../../api/assignmentGroupRequests'

import AssignmentGroupsFormNew from './AssignmentGroupsFormNew'

const AssignmentGroupsFormNewContainer = () => {
  const {getEntityDataInit, onClose} = useListTableStateContext()

  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [, setBtnDisabled] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [orgRoles, setOrgRoles] = useState()
  const {getFieldValue, resetFields} = form

  const recognizeFieldChange = () => {
    setBtnDisabled(!(getFieldValue('name') && getFieldValue('org_name')))
  }

  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const onCancel = () => {
    resetFields()
  }

  const handleSubmit = async () => {
    try {
      const values = await form.getFieldsValue()

      setLoadingSubmit(true)
      handleRequested()

      await postAssignmentGroup(uuid(), values)

      handleSuccess()
      getEntityDataInit()
      onClose()
    } catch (err) {
      handleFail(err)
    } finally {
      setLoadingSubmit(false)
      handleReset()
    }
  }

  useEffect(() => {
    const getOrgRoles = async () => {
      try {
        const res = await fetchOrgsRole()
        const result = res.body.result.value
        const newRoles = []

        for (let key in result) {
          newRoles.push({
            value: key,
            name: result[key].name,
            role: result[key].role,
          })
        }
        setOrgRoles(newRoles)
      } catch (err) {
        dispatch(setToastMessage({message: err}))
      }
    }

    if (!orgRoles) getOrgRoles()
  }, [dispatch, orgRoles])

  return (
    <>
      <AssignmentGroupsFormNew
        recognizeFieldChange={recognizeFieldChange}
        handleSubmit={handleSubmit}
        orgRoles={orgRoles}
        form={form}
        loadingSubmit={loadingSubmit}
        handleCancel={onCancel}
      />
    </>
  )
}

export default AssignmentGroupsFormNewContainer
