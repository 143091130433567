import {IncidentsQuickFilters} from '@/modules/ITSM/sites/Incident/constants'
import {incidentState} from '@/modules/ITSM/utils/Constants'
import {RequestsQuickFilters} from '@/modules/ITSM/sites/request-user-portal/constants'
import {ApprovalStates} from '@/modules/ITSM/components/incident-k-request/constants'

const openIncidentsQuery = {
  $and: [
    {
      state_id: {$ne: incidentState.closed},
    },
    {
      state_id: {$ne: incidentState.cancelled},
    },
  ],
}

export const getIncidentRequestAssetQuickFilters = (
  assignmentGroups: Array<string>,
  userId: string
) => [
  {
    name: IncidentsQuickFilters.All,
    query: {},
  },
  {
    name: IncidentsQuickFilters.Opened,
    query: openIncidentsQuery,
  },
  {
    name: IncidentsQuickFilters.ToAction,
    query: {
      ...openIncidentsQuery,
      $and: [
        {
          state_id: {
            $ne: 4,
          },
        },
        {
          state_id: {
            $ne: 5,
          },
        },
        {
          state_id: {
            $ne: 6,
          },
        },
      ],
    },
  },
  {
    name: IncidentsQuickFilters.AssignedToMe,
    query: {...openIncidentsQuery, assigned_to: userId},
  },

  {
    name: IncidentsQuickFilters.NotAssignedToMe,
    query: {
      ...openIncidentsQuery,

      $or: [
        {
          assigned_to: {$ne: userId},
        },
        {
          assigned_to: {$exists: false},
        },
      ],
    },
  },
  {
    name: IncidentsQuickFilters.OpenedByMe,
    query: {
      ...openIncidentsQuery,
      created_by: userId,
    },
  },
  {
    name: IncidentsQuickFilters.ClosedOrCancelled,
    query: {
      $or: [
        {
          state_id: {$eq: incidentState.closed},
        },
        {
          state_id: {$eq: incidentState.cancelled},
        },
      ],
    },
  },
  {
    name: IncidentsQuickFilters.AssignedToMyGroups,
    query: {
      $and: [
        {
          $or:
            assignmentGroups.length > 0
              ? assignmentGroups.map(uuid => ({
                  assignment_group: uuid,
                }))
              : [{assignment_group: ''}],
        },
        openIncidentsQuery,
      ],
    },
  },
  {
    name: IncidentsQuickFilters.Scheduled,
    query: {
      $and: [
        {expected_start: {$exists: true}},
        {
          $or: [
            {
              state_id: {$eq: incidentState.new},
            },
            {
              state_id: {$eq: incidentState.pre_on_hold},
            },
          ],
        },
      ],
    },
  },
  {
    name: IncidentsQuickFilters.Resolved,
    query: {
      $and: [
        {
          state_id: {$eq: incidentState.resolved},
        },
      ],
    },
  },
  {
    name: IncidentsQuickFilters.Unassigned,
    query: {
      ...openIncidentsQuery,

      $and: [
        {
          assigned_to: {$exists: false},
        },
      ],
    },
  },
]

export const getIncidentRequestAssetQuickFiltersUP = (
  isManager: boolean,
  userId: string
) => {
  const STATE_FILTERS = [
    {
      name: RequestsQuickFilters.All,
      query: {},
    },
    {
      name: RequestsQuickFilters.Opened,
      query: openIncidentsQuery,
    },
    {
      name: RequestsQuickFilters.On_hold,
      query: {
        state_id: incidentState.on_hold,
      },
    },
    {
      name: RequestsQuickFilters.Resolved,
      query: {
        state_id: incidentState.resolved,
      },
    },
  ]

  if (!isManager)
    return STATE_FILTERS.map(selector => ({
      ...selector,
      query: {...selector.query, caller: userId},
    }))

  return [
    {
      name: RequestsQuickFilters.MyRequests,
      query: {caller: userId},
    },
    {
      name: RequestsQuickFilters.Pending,
      query: {approval_state: ApprovalStates.Pending, approver: userId},
    },
    {
      name: RequestsQuickFilters.Approved,
      query: {approval_state: ApprovalStates.Approved, approver: userId},
    },
    {
      name: RequestsQuickFilters.Rejected,
      query: {approval_state: ApprovalStates.Rejected, approver: userId},
    },
    ...STATE_FILTERS,
  ]
}
