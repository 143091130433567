import globalRoutes from '@/global-routes'
import {generateConfig} from '@/generateConfig'

import {TOrgInviteReq} from './typedf'

export const createInvitationData = (values: TOrgInviteReq) => {
  return {
    email: values.email,
    roles: values.roles,
    org_role: values.org_role,
    signingPath: globalRoutes.general.acceptInvitationITSM.path(),
    redirectUrl: `${generateConfig().BASE_URL}${generateConfig().BASEPATH}/`,
  }
}
