export const setTooltip = (
  element: HTMLElement | null,
  title?: string
): boolean => {
  if (element && element instanceof HTMLElement) {
    if (element.offsetWidth < element.scrollWidth) {
      element.setAttribute('title', title || element.innerText)
      return true
    } else {
      element.removeAttribute('title')
      return false
    }
  }
  return false
}
