import React from 'react'

import {v4 as uuid} from 'uuid'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {TKey, translate} from '@/services/i18n'
import {SkeletonItem} from '@/components/skeleton'

import {DetailsCardSkeletonView} from './details-card/details-card-skeleton-view'

import './incident-detail-mobile.scss'

const ITEMS: Array<TKey> = ['priority', 'impact', 'urgency']

export const IncidentDetailMobileSkeletonView = () => (
  <div className="incident-detail-mobile">
    <div className="flex mb-3">
      <GoBackButton disabled={true} />
      <span className="incident-detail-mobile__header">
        <SkeletonItem width={110} height={31} />
      </span>
    </div>
    <div className="flex ml-8">
      {ITEMS.map(label => (
        <div key={uuid()} className="incident-detail-mobile__indicator">
          <div className="incident-detail-mobile__label">
            {translate(label)}
          </div>
          <div className="flex flex--alignCenter">
            <SkeletonItem className="mr-8" width={21} height={20} />
            <SkeletonItem className="mr-8" width={30} height={15} />
          </div>
        </div>
      ))}
    </div>
    {[200, 150, 100, 300].map(height => (
      <DetailsCardSkeletonView key={uuid()} height={height} />
    ))}
  </div>
)
