export const COLUMN_TYPE = {
  SORT: 'sort',
  FILTER: 'filter',
}

export type TCreditCard = {
  customer: TCustomer
  exp_month: number
  exp_year: number
  last4: string
  brand: 'Visa' | 'Master' | 'American #xpress'
}

export type TCustomer = {
  address: {
    city: string
    country: string
    line1: string
    line2: string
    postal_code: string
    state: string
  }
  balance: number
  currency: string
  email: string
  id: string
  metadata: any
  name: string
  object: string
  phone: string
  shipping: null
}

export interface ISubscription {
  object: string
  data: ISubscriptionDatum[]
  has_more: boolean
  url: string
}

export interface ISubscriptionDatum {
  id: string
  object: string
  application: null
  application_fee_percent: null
  automatic_tax: IAutomaticTax
  billing_cycle_anchor: number
  billing_thresholds: null
  cancel_at: null
  cancel_at_period_end: boolean
  canceled_at: null
  collection_method: string
  created: number
  current_period_end: number
  current_period_start: number
  customer: string
  days_until_due: null
  default_payment_method: null
  default_source: null
  default_tax_rates: any[]
  description: null
  discount: IDiscount
  ended_at: null
  items: IItems
  latest_invoice: string
  livemode: boolean
  metadata: {name?: string; interval: string; type?: string}
  next_pending_invoice_item_invoice: null
  pause_collection: null
  payment_settings: IPaymentSettings
  pending_invoice_item_interval: null
  pending_setup_intent: null
  pending_update: null
  plan: IPlan
  quantity: number
  schedule: null
  start_date: number
  status: string
  test_clock: null
  transfer_data: null
  trial_end: number
  trial_start: number
  metaData: {feature_flag: string}
}

export interface IDiscount {
  id: string
  coupon: ICoupon
  customer: string
  end: number
  invoice: string
  invoice_item: string
  promotion_code: string
  start: number
  subscription: string
}

export interface ICoupon {
  id: string
  amount_off: number
  duration: string
  duration_in_months: 'forever' | 'once' | 'repeating'
  percent_off: number
  valid: boolean
}

interface IAutomaticTax {
  enabled: boolean
}

export interface IItems {
  object: string
  data: IItemsDatum[]
  has_more: boolean
  total_count: number
  url: string
}

interface IItemsDatum {
  id: string
  object: string
  billing_thresholds: null
  created: number
  metadata: Record<string, string>
  plan: IPlan
  price: IPrice
  quantity: number
  subscription: string
  tax_rates: any[]
}

interface IPlan {
  id: string
  object: string
  active: boolean
  aggregate_usage: null
  amount: number
  amount_decimal: string
  billing_scheme: string
  created: number
  currency: string
  interval: string
  interval_count: number
  livemode: boolean
  metadata: Record<string, string>
  nickname: null
  product: IProduct
  tiers_mode: null
  transform_usage: null
  trial_period_days: null
  usage_type: string
}

export interface IPrice {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  custom_unit_amount: null
  livemode: boolean
  lookup_key: string
  metadata: Record<string, string>
  nickname: null
  product: IProduct
  recurring: IRecurring
  tax_behavior: string
  tiers_mode: null
  transform_quantity: null
  type: string
  unit_amount: number
  unit_amount_decimal: string
  subscriptionId?: string
}

export enum Interval {
  MONTH = 'month',
  YEAR = 'year',
}

export enum ProductType {
  REGULAR = 'REGULAR',
  SUPPORT_SERVICES = 'SUPPORT_SERVICES',
}

export enum UsageType {
  METERED = 'metered',
  LICENSED = 'licensed',
}
export interface IRecurring {
  aggregate_usage: null
  interval: Interval
  interval_count: number
  trial_period_days: null
  usage_type: UsageType
}

interface IPaymentSettings {
  payment_method_options: null
  payment_method_types: null
  save_default_payment_method: string
}

export type TProductCategory =
  | 'saas_management'
  | 'asset_management'
  | 'ticket_management'

export interface IProduct {
  id: string
  object: string
  active: boolean
  attributes: any[]
  created: number
  default_price: string
  description: null
  images: any[]
  livemode: boolean
  metadata: {
    name: string
    category: TProductCategory
    usp: string
    product_detail_link: string
    type: string
    feature_flag: string
    icon: string
    product_type: string
    per_custom_unit: string
  }
  name: string
  package_dimensions: null
  shippable: null
  statement_descriptor: null
  tax_code: null
  type: string
  unit_label: null
  updated: number
  url: null
}

type TMetadata = Record<string, string>

export interface IInvoice {
  id: string
  object: string
  account_country: string
  account_name: string
  account_tax_ids: null
  amount_due: number
  amount_paid: number
  amount_remaining: number
  application: null
  application_fee_amount: null
  attempt_count: number
  attempted: boolean
  auto_advance: boolean
  automatic_tax: IAutomaticTax
  billing_reason: string
  charge: string
  collection_method: string
  created: number
  currency: string
  custom_fields: null
  customer: string
  customer_address: null
  customer_email: string
  customer_name: string
  customer_phone: string
  customer_shipping: null
  customer_tax_exempt: string
  customer_tax_ids: any[]
  default_payment_method: null
  default_source: null
  default_tax_rates: any[]
  description: null
  discount: null
  discounts: any[]
  due_date: null
  ending_balance: number
  footer: null
  hosted_invoice_url: string
  invoice_pdf: string
  last_finalization_error: null
  lines: ILines
  livemode: boolean
  metadata: TMetadata
  next_payment_attempt: null
  number: string
  on_behalf_of: null
  paid: boolean
  paid_out_of_band: boolean
  payment_intent: string
  payment_settings: IPaymentSettings
  period_end: number
  period_start: number
  post_payment_credit_notes_amount: number
  pre_payment_credit_notes_amount: number
  quote: null
  receipt_number: null
  rendering_options: null
  starting_balance: number
  statement_descriptor: null
  status: string
  status_transitions: IStatusTransitions
  subscription: ISubscription
  subtotal: number
  subtotal_excluding_tax: number
  tax: null
  test_clock: null
  total: number
  total_discount_amounts: any[]
  total_excluding_tax: number
  total_tax_amounts: any[]
  transfer_data: null
  webhooks_delivered_at: number
}

export interface ILines {
  object: string
  data: IDatum[]
  has_more: boolean
  total_count: number
  url: string
}

export interface IDatum {
  id: string
  object: string
  amount: number
  amount_excluding_tax: number
  currency: string
  description: string
  discount_amounts: any[]
  discountable: boolean
  discounts: any[]
  livemode: boolean
  metadata: TMetadata
  period: IPeriod
  plan: IPlan
  price: IPrice
  proration: boolean
  proration_details: IProrationDetails
  quantity: number
  subscription: string
  subscription_item: string
  tax_amounts: any[]
  tax_rates: any[]
  type: string
  unit_amount_excluding_tax: string
}

export interface IPeriod {
  end: number
  start: number
}

export interface IPrice {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  custom_unit_amount: null
  livemode: boolean
  lookup_key: string
  metadata: TMetadata
  nickname: null
  product: IProduct
  recurring: IRecurring
  tax_behavior: string
  tiers_mode: null
  transform_quantity: null
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export interface IProrationDetails {
  credited_items: null
}

export interface IStatusTransitions {
  finalized_at: number
  marked_uncollectible_at: null
  paid_at: number
  voided_at: null
}

export interface ISubscription {
  id: string
  object: string
  application: null
  application_fee_percent: null
  billing_cycle_anchor: number
  billing_thresholds: null
  cancel_at: null
  cancel_at_period_end: boolean
  canceled_at: number
  collection_method: string
  created: number
  current_period_end: number
  current_period_start: number
  customer: string
  days_until_due: null
  default_payment_method: null
  default_source: null
  default_tax_rates: any[]
  description: null
  discount: null
  ended_at: number
  latest_invoice: string
  livemode: boolean
  next_pending_invoice_item_invoice: null
  pause_collection: null
  pending_invoice_item_interval: null
  pending_setup_intent: null
  pending_update: null
  plan: ISubscriptionPlan
  quantity: number
  schedule: null
  start_date: number
  status: string
  test_clock: null
  transfer_data: null
  trial_end: null
  trial_start: null
}

export interface ISubscriptionPlan {
  id: string
  object: string
  active: boolean
  aggregate_usage: null
  amount: number
  amount_decimal: string
  billing_scheme: string
  created: number
  currency: string
  interval: string
  interval_count: number
  livemode: boolean
  nickname: null
  product: IProduct
  tiers_mode: null
  transform_usage: null
  trial_period_days: null
  usage_type: string
}

export type TCustomerPayload = {
  name: string
  'address[country]': string
  'address[city]': string
  'address[state]': string
  'address[line1]': string
  'address[line2]': string
  'address[postal_code]': string
  email: string
  phone: string
}

export interface IPaymentMethods {
  id: string
  billing_details: {
    address: {
      city: string
      country: string
      line1: string
      line2?: string
      postal_code: string
      state: string
    }
    email: string
    name: string
    phone: string
  }
  card: TCreditCard
  created: string
  customer: TCustomer
  metadata: Record<string, any>
  type: string
}

export enum SubscriptionMode {
  SUBSCRIPTION = 'subscription',
  PAYMENT = 'payment',
  SETUP = 'setup',
}

export interface ISession {
  after_expiration: null
  allow_promotion_codes: boolean
  amount_subtotal: number
  amount_total: number
  automatic_tax: {
    enabled: boolean
    status: string
  }
  billing_address_collection: string
  cancel_url: string
  client_reference_id: string
  consent: null
  consent_collection: null
  currency: string
  customer: TCustomer
  customer_creation: string
  customer_details: {
    address: null
    email: string
    name: string
    phone: string
    tax_exempt: string
    tax_ids: null | any[]
  }
  customer_email: string
  deleted: boolean
  expires_at: number
  id: string
  line_items: null
  livemode: boolean
  locale: string
  metadata: Record<string, any>
  mode: SubscriptionMode
  object: string
  payment_intent: null
  payment_link: null
  payment_method_options: null
  payment_method_types: null | Array<'card'>
  payment_status: string
  phone_number_collection: {
    enabled: boolean
  }
  recovered_from: string
  setup_intent: null
  shipping: null
  shipping_address_collection: null
  shipping_options: any[]
  shipping_rate: null
  status: string
  submit_type: string
  subscription: null
  success_url: string
  tax_id_collection: null
  total_details: {
    amount_discount: number
    amount_shipping: number
    amount_tax: number
    breakdown: null
  }
  url: string
}

export enum PriceFeatureFlagKeys {
  ALL_INCLUSIVE = 'all_inclusive',
  CUSTOMER_PRODUCT = 'customer_product',
  SVR = 'svr',
  BILLING_COST = 'billing_cost',
  BILLING_REVENUE = 'billing_revenue',
  EXPENSES = 'expenses',
  TIME_TRACKING = 'time_tracking',
  MULTIPLE_CHANNELS = 'multiple_channels',
  ASSIGNMENT_GROUPS = 'assignment_groups',
  SERVICE_DESK_ROUND_CLOCK = 'service_desk_round_clock',
}

export enum TransferDirection {
  'left' = 'left',
  'right' = 'right',
}

export type TAppSettingsModal = {
  appRoleId: string
  groupAzureId: string
}

export type TDocument = {
  id: string
  name: string
  file_type: string
  size: number
}
export interface IPriceExtended extends IPrice {
  subscriptionStatus: string
  subscriptionDiscount: IDiscount
  subscriptionId: string
  subscriptionTrialEnd: number
  subscriptionItemId: string
}
export interface IItemsDatumExtended extends IItemsDatum {
  subscriptionId: string
  subscriptionStatus: string
  subscriptionDiscount: IDiscount
  subscriptionTrialEnd: number
  subscriptionItemId: string
}
