export enum SideTabs {
  END_USER_SUPPORT = 'endToEndUser',
  BUSINESS_APPS_SUPPORT_MANAGEMENT = 'supportManagement',
  IT_ASSETS_SUPPORT_MANAGEMENT = 'itAssets',
}

export type TPriceConfig = {
  [key in string]: {
    [key in string]: {
      name: string
      isMultiple: boolean
      active: boolean
      items: {
        [key in string]: number | null
      }
    }
  }
}

export enum AddonItemTypes {
  NUMBER_OF_USERS = 'number-of-users',
  SUPPORT_TYPE = 'support-type',
  SUPPORT = 'support',
  RESPOND_TIME = 'respond-time',
  COUNTRY = 'country',
}
export type TViewPrice = {
  [key in string]: {
    [key in string]: {
      name: string
      isMultiple: boolean
      active: boolean
      items: Array<{
        label: string
        value: string
      }>
    }
  }
}

export type TServicesForm = {
  [AddonItemTypes.NUMBER_OF_USERS]?: string | Array<string>
  [AddonItemTypes.SUPPORT_TYPE]?: string | Array<string>
  [AddonItemTypes.SUPPORT]?: string | Array<string>
  [AddonItemTypes.RESPOND_TIME]?: Array<string>
  country?: Array<string>
}

export type TServicePayload = {
  [key in SideTabs]: Record<string, string | string[]>
}
