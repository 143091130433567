import {activeCampaignApi, getBackendTokenHeaders} from '@/services/api'
import request from 'superagent'

export const updateActiveCampaign = async (
  payload: Record<string, number | boolean>
) =>
  await request
    .patch(activeCampaignApi.patch)
    .set(getBackendTokenHeaders())
    .send(payload)
