import {
  availableLanguages,
  DEFAULT_LANGUAGE,
} from '@/components/language-switcher/constants'

export const getAvailableLanguage = (language: string | null) => {
  if (!language) {
    return DEFAULT_LANGUAGE
  } else {
    const isChosenAvailable = availableLanguages.some(lng => lng === language)

    if (isChosenAvailable) {
      return language
    } else {
      return DEFAULT_LANGUAGE
    }
  }
}
