import {TAppState} from '../store'

export function selectGeneralProfile(state: TAppState) {
  return state.profile.userProfile
}

export function selectModuleProfile(state: TAppState) {
  return state.profile.userProfile
}

export function selectGeneralProfileFetchStatus(state: TAppState) {
  return state.profile.requestStatus
}
