import {useHistory} from 'react-router-dom'

import {generateConfig} from '@/generateConfig'

export const useGenerateReturnLink = () => {
  const history = useHistory()
  const mainURL = generateConfig().BASE_URL

  return ({pathname, search}: {pathname?: string; search?: string}) => {
    const passedSearch = search ? `?${search}` : ''

    return process.env.NODE_ENV === 'development'
      ? `http://localhost:3000${pathname ||
          history.location.pathname}${passedSearch}`
      : `${mainURL}${pathname || history.location.pathname}${passedSearch}`
  }
}
