import React from 'react'

import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {translate} from '@/services/i18n'
import {TInvitation} from '@/modules/ITSM/typedef'

type TInvitationsRowValue = {
  props: TColumnRender<TInvitation>
}
type TRole = {
  name: string
}
export const InvitationsRowValue = ({props}: TInvitationsRowValue) => {
  const {title, value, record} = props

  switch (title) {
    case 'roles':
      return (
        <div>
          {value &&
            JSON.parse(value as string)
              .map((e: TRole) => e.name)
              .join(', ')}
        </div>
      )

    case 'type':
      return (
        <div>
          {record?.orgRole
            ? translate('org_invitation')
            : translate('user_invitation')}
        </div>
      )
    case 'state':
      return (
        <div>{value === 3 ? translate('pending') : translate('accepted')}</div>
      )
    case 'invitorOrg':
      return <div>{value}</div>

    default:
      return <div>{value}</div>
  }
}
