import React from 'react'

import {v4 as uuid} from 'uuid'

import {SkeletonItem, TSkeletonItemProps} from '@/components/skeleton'

import './details-card.scss'

type TProps = {
  height: TSkeletonItemProps['height']
}

export const DetailsCardSkeletonView: React.FC<TProps> = ({height = 150}) => (
  <div className="details-card details-card__skeleton" key={uuid()}>
    <SkeletonItem width="100%" height={height} />
  </div>
)
