import {useState} from 'react'

import sanitizeHtml from 'xss'
import {UploadFile} from 'antd/lib/upload/interface'

import {TConvertedFile} from '@/typedef'

export const useUploadFiles = () => {
  const [filesConverted, setConvertedFiles] = useState<Array<TConvertedFile>>(
    []
  )
  const [fileList, setFiles] = useState<Array<UploadFile>>([])

  const resetUploadedFiles = () => {
    setConvertedFiles([])
    setFiles([])
  }

  const uploadFiles = () => {
    let newFileList: UploadFile[] = []
    let newFileListConverted: Array<TConvertedFile> = []

    return {
      onRemove: (file: UploadFile) => {
        const index = fileList.indexOf(file)

        const newFileList = fileList.filter((e, i) => i !== index)

        const newFilesConverted = filesConverted.filter(e => e.file !== file)
        setFiles(newFileList)
        setConvertedFiles(newFilesConverted)
      },
      beforeUpload: (file: UploadFile) => {
        newFileList = newFileList.concat(file)

        const reader = new FileReader()
        const fileToRead: Blob = file as any

        if (file.type === 'text/html') {
          let sanitizedHtml

          reader.onload = evt => {
            const text: string = evt.target?.result as any
            sanitizedHtml = sanitizeHtml(text)
            const blob = new Blob([sanitizedHtml], {
              type: 'text/html',
            })

            reader.onload = evt => {
              const result: string = evt.target?.result as any
              const data = result.split(',')[1]

              if (evt.target?.readyState === 2) {
                newFileListConverted = [...newFileListConverted, {data, file}]
              }

              setFiles([...fileList, ...newFileList])

              if (newFileListConverted.length === newFileList.length)
                setConvertedFiles([...filesConverted, ...newFileListConverted])
            }
            reader.readAsDataURL(blob)
          }

          reader.readAsText(fileToRead, 'UTF-8')
        } else {
          reader.onload = () => {
            let binary = ''
            const result: ArrayBufferLike = reader.result as any
            const bytes = new Uint8Array(result)
            const len = bytes.byteLength
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i])
            }

            newFileListConverted = [
              ...newFileListConverted,
              {data: window.btoa(binary), file},
            ]

            setFiles([...fileList, ...newFileList])

            if (newFileListConverted.length === newFileList.length)
              setConvertedFiles([...filesConverted, ...newFileListConverted])
          }
          reader.readAsArrayBuffer(fileToRead)
        }
      },
      fileList,
    }
  }
  return {fileList, filesConverted, uploadFiles, resetUploadedFiles}
}
