import React, {useCallback} from 'react'

import 'moment-duration-format'
import queryString from 'query-string'
import {useSelector} from 'react-redux'

import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import {translate} from '@/services/i18n'
import {Entities} from '@/typedef'
import {selectUserID} from '@/redux/user/userSelector'
import {TabTitle} from '@/components/tab-title/tab-title'
import {Tabs} from '@/components/tabs/tabs'
import {Skeleton} from '@/components/skeleton'
import {TabsSkeleton} from '@/components/tabs'
import {Paper} from '@/components/paper/paper'

import AttachmentsTab from '../../attachments/attachments'
import TimeLogDetail from '../../time-logs/time-log-detail'
import TimeLogs from '../../time-logs/time-logs'
import ExpenseDetail from '../../incident/incident-expenses/incident-expense-detail'
import Expenses from '../../incident/incident-expenses/expenses'
import {setCurrentTab} from '../../incident/incident-reducer'
import ConfirmButtons from '../../incident/incident-confirm-btns'
import {checkIfClosedState} from '../../incident/utils/check-of-closed-state'
import {useStateContext} from '../../incident/incident-context'
import RejectedReason from '../../incident/incident-rejected-reason'
import {AssigneeStates} from '../../incident-k-request/constants'
import WorkNotes from '../../work-notes/work-notes'
import {checkIfDisabled} from '../utils/check-if-disabled'
import IncidentTaskAssigneeStatusForm from '../incident-task-assignee-status-form/incident-task-assignee-status-form'
import IncidentTaskForm from '../incident-task-form/incident-task-form'
import {IncidentTaskState, IncTaskTabs, TIncidentTask} from '../typedf'
import {getCloseStateName} from '../utils/get-close-state-name'
import ResolutionSummary from '../incident-task-resolution-summary'
import ResolutionForm from '../incident-task-resolution-form'

import './incident-task-tabs.scss'

const IncidentTaskTabs = () => {
  const {incState, dispatch, entityData, entity} = useStateContext<
    TIncidentTask
  >()

  const {currentTab, isRejectReason} = incState

  const onTabChange = useCallback(
    (tab: string) => {
      const tabHasChanged = tab !== currentTab
      if (tabHasChanged) {
        dispatch(setCurrentTab(tab))
      }
    },
    [currentTab, dispatch]
  )

  const {state_id, assignee_state, timelogs} = entityData || {}

  const isDisabled = !!(entityData && state_id && checkIfDisabled(state_id))

  const userID = useSelector(selectUserID)

  const assignedToCurrentUser = entityData?.assigned_to?.uuid === userID

  return (
    <Skeleton
      active={!currentTab}
      view={<TabsSkeleton className="incident-task-tabs" />}
    >
      <Tabs
        tabPosition={'left'}
        onTabClick={onTabChange}
        activeKey={currentTab}
        className="incident-task-tabs"
        items={[
          {
            label: (
              <span className="incident-task-tabs__state-tab">
                {translate('overview')}
              </span>
            ),
            key: IncTaskTabs.OverviewTab,
            children: (
              <div>
                {currentTab === IncTaskTabs.OverviewTab && (
                  <Paper>
                    <IncidentTaskForm />
                  </Paper>
                )}
              </div>
            ),
          },
          {
            label: translate('attachments'),
            key: IncTaskTabs.AttachmentsTab,
            children: (
              <div>
                {currentTab === IncTaskTabs.AttachmentsTab && (
                  <Paper>
                    <TabTitle title={translate('attachments')} />
                    <AttachmentsTab disField={false} />
                  </Paper>
                )}
              </div>
            ),
          },

          ...(assignee_state !== AssigneeStates.Unset
            ? [
                {
                  label: (
                    <span className="incident-task-tabs__state-tab">
                      {assignee_state && parseToUpperCase(assignee_state)}
                    </span>
                  ),
                  key: assignee_state || AssigneeStates.Unset,
                  children: (
                    <div>
                      <Paper>
                        {state_id === IncidentTaskState.New &&
                          assignedToCurrentUser &&
                          assignee_state === AssigneeStates.Proposed &&
                          (isRejectReason ? (
                            <RejectedReason />
                          ) : (
                            <ConfirmButtons />
                          ))}
                        {currentTab === assignee_state && (
                          <IncidentTaskAssigneeStatusForm />
                        )}
                      </Paper>
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id !== IncidentTaskState.New && timelogs
            ? [
                {
                  label: translate('time_logs'),
                  key: IncTaskTabs.TimeLogsTab,
                  children: (
                    <div>
                      {currentTab === IncTaskTabs.TimeLogsTab && (
                        <Paper>
                          {queryString.parse(window.location.search).timelog ? (
                            <TimeLogDetail />
                          ) : (
                            <>
                              <TabTitle title={translate('time_logs')} />
                              <TimeLogs
                                editableState={
                                  state_id === IncidentTaskState.In_progress
                                }
                                checkInBtnVisible={
                                  state_id === IncidentTaskState.In_progress
                                }
                              />
                            </>
                          )}
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id !== IncidentTaskState.New
            ? [
                {
                  label: (
                    <span className="incident-task-tabs__state-tab">
                      {translate('in_progress')}
                    </span>
                  ),
                  key: IncTaskTabs.InProgressTab,
                  children: (
                    <div>
                      {currentTab === IncTaskTabs.InProgressTab && (
                        <Paper>
                          <TabTitle title={translate('work_notes')} />
                          <WorkNotes isDisabled={isDisabled} />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id !== IncidentTaskState.New
            ? [
                {
                  label: (
                    <span className="incident-task-tabs__state-tab">
                      {translate('work_notes')}
                    </span>
                  ),
                  key: IncTaskTabs.WorkNotesTab,
                  children: (
                    <div>
                      {currentTab === IncTaskTabs.WorkNotesTab && (
                        <Paper>
                          <TabTitle title={translate('work_notes')} />
                          <WorkNotes isDisabled={isDisabled} />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id !== IncidentTaskState.New
            ? [
                {
                  label: translate('expenses'),
                  key: IncTaskTabs.ExpensesTab,
                  children: (
                    <div>
                      {currentTab === IncTaskTabs.ExpensesTab && (
                        <Paper>
                          {queryString.parse(window.location.search).timelog ? (
                            <ExpenseDetail isDisabled={isDisabled} />
                          ) : (
                            <>
                              <TabTitle title={translate('expenses')} />
                              <Expenses
                                entityData={entityData}
                                entity={Entities.INCIDENT_TASK}
                              />
                            </>
                          )}
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id !== IncidentTaskState.New
            ? [
                {
                  label: (
                    <span className="incident-task-tabs__state-tab">
                      {translate('resolution')}
                    </span>
                  ),
                  key: IncTaskTabs.ResolutionTab,
                  children: (
                    <div>
                      {currentTab === IncTaskTabs.ResolutionTab && (
                        <Paper>
                          <ResolutionForm />
                        </Paper>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(state_id !== undefined && checkIfClosedState(state_id, entity)
            ? [
                {
                  label: (
                    <span className="incident-task-tabs__state-tab">
                      {state_id !== undefined && getCloseStateName(state_id)}
                    </span>
                  ),
                  key:
                    getCloseStateName(state_id)
                      ?.toLowerCase()
                      .replace(' ', '-') || translate('new'),
                  children: (
                    <div>
                      <Paper>
                        <ResolutionSummary />
                      </Paper>
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </Skeleton>
  )
}

export default IncidentTaskTabs
