import React from 'react'

import {Col, Divider, FormInstance, Input, Row} from 'antd'
import {useHistory} from 'react-router-dom'

import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {translate} from '@/services/i18n'
import {Form} from '@/components/form/form/form'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {TItsmChannel} from '@/modules/ITSM/typedef'
import {threeColumns} from '@/utils/table/constants/grid-columns'

type TProps = {
  handleSubmit: (values: TItsmChannel) => void
  form: FormInstance
  initialValues?: TItsmChannel
  isLoading: boolean
}

export const ChannelForm = ({
  handleSubmit,
  form,
  initialValues,
  isLoading,
}: TProps) => {
  const history = useHistory()

  const header = (
    <Row>
      <Col span={24}>
        <div className="flex flex--spaceBetween">
          <GoBackButton
            onClick={() => history.push(itsmRoutes.settingsITSMChannels.path())}
          />
        </div>
        <Divider />
      </Col>
    </Row>
  )

  return (
    <Form<TItsmChannel>
      onSubmit={handleSubmit}
      form={form}
      header={header}
      initialValues={initialValues}
      isLoading={isLoading}
    >
      <Row gutter={24}>
        <Col {...threeColumns}>
          <FormItemGeneric<TItsmChannel>
            name="name"
            label={translate('name')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col {...threeColumns}>
          <FormItemGeneric<TItsmChannel>
            name="name"
            label={translate('name')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col {...threeColumns}>
          <FormItemGeneric<TItsmChannel>
            name="name"
            label={translate('name')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItemGeneric>
        </Col>
      </Row>
    </Form>
  )
}
