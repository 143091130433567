import React, {useContext} from 'react'

import {ModalType} from '@/components/modal/typedef'
import {CreateChannelForm} from '@/components/form/create-channel-form/create-channel-form'
import Modal from '@/components/modal/modal'
import {AccountDefaultContext} from '@/sites/account-default/account-default-context/account-default-context'
import {AccountDefaultModal} from '@/sites/account-default/typedef'

import {useCreateAccount} from '@/sites/account-default/hooks/use-create-account'
import {useCustomAuth} from '@/hooks/use-custom-auth'

import {findChannelNameInExistChannelError} from '@/utils/find-channel-name-in-exist-channel-error'

import './account-default-error-modal.scss'

export const AccountDefaultErrorModal = () => {
  const {state} = useContext(AccountDefaultContext)

  const hasCreateChannel = state.errorType !== AccountDefaultModal.GENERAL_ERROR

  const {createChannel} = useCreateAccount()

  const {signOutRedirect} = useCustomAuth()

  return (
    <Modal
      className="account-default-error-modal"
      open
      title={state.errorTitle}
      info={state.errorDescription}
      modalType={ModalType.INFO}
      haveIcon={false}
      loading={state.isLoading}
      loadingText={state.loadingText}
      {...(!hasCreateChannel && {
        handleAccept: () => signOutRedirect(),
      })}
    >
      {hasCreateChannel && (
        <CreateChannelForm
          onSubmit={({channel_name}) =>
            createChannel({
              channel_name,
              accountId: state.accountId,
              orgId: state.orgId,
            })
          }
          initialValues={{
            channel_name: findChannelNameInExistChannelError(state.error),
          }}
        />
      )}
    </Modal>
  )
}
