import {call, put, select} from 'redux-saga/effects'

import {
  getAccountsFailed,
  getAccountsRequested,
  getAccountsSuccess,
  getAccountSuccess,
  getOrgAccountsRequested,
  getOrgAccountsSuccess,
  postGrantFailed,
  postGrantSuccess,
  requestAcceptInvite,
  setChannelStep,
  setInviteModalMessage,
} from '@/redux/invitation/invitationActions'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {InvitationsStep} from '@/typedef'
import {getOrgAccount, postGrant} from '@/api/accountRequests'
import {selectAccounts} from '@/redux/invitation/invitationSelector'
import {getIdentityMe} from '@/redux/user/userActions'
import {setItsmChannel} from '@/redux/entries/entries-actions'
import {getModalErrorData} from '@/utils/get-modal-error-data'
import {fetchAccountsSaga} from '@/modules/ITSM/api/accountRequests'
import {postIdentity} from '@/modules/ITSM/api/identityRequests'
import {refreshTokenSaga} from '@/saga/refresh-token-saga'
import {getToken} from '@/modules/ITSM/api/generate-itsm-api-url'

export function* postOrgGrantSaga({payload: grant}) {
  try {
    yield call(postGrant, grant, getToken())
    yield put(postGrantSuccess('succeeded'))
    yield put(getAccountsRequested())
  } catch (err) {
    yield put(setToastMessage({message: getModalErrorData(err)}))
    yield put(postGrantFailed(err))
  }
}

export function* getAccountsSaga() {
  try {
    const {body: accounts} = yield call(
      fetchAccountsSaga,
      'myAccounts',
      getToken()
    )
    // const {body: accounts} = {
    //   body: {
    //     accounts: [],
    //   },
    // }

    if (accounts.length === 1) {
      yield put(getAccountsSuccess(accounts))
      yield put(getAccountSuccess(accounts[0].id))
      yield put(getOrgAccountsRequested(accounts[0].id))
    } else if (accounts.length > 1) {
      yield put(getAccountsSuccess(accounts))
      yield put(setChannelStep(InvitationsStep.TWO))
    } else {
      yield put(setChannelStep(InvitationsStep.ONE))
    }
  } catch (err) {
    yield put(setToastMessage({message: getModalErrorData(err)}))
    yield put(getAccountsFailed(err))
  }
}

export function* getOrgAccountSaga({payload}) {
  const accounts = yield select(selectAccounts)

  try {
    // getOrgAccount end point is the same for itsm & sc
    const {body: accountOrgs} = yield call(getOrgAccount, payload, getToken())
    if (accountOrgs.length === 1 && accounts.length === 1) {
      yield put(getOrgAccountsSuccess(accountOrgs))
      yield put(requestAcceptInvite(accountOrgs[0].id))
    } else if (accountOrgs.length >= 1) {
      yield put(getOrgAccountsSuccess(accountOrgs))
      yield put(setChannelStep(InvitationsStep.TWO))
    } else {
      yield put(getOrgAccountsSuccess(accountOrgs))
    }
  } catch (err) {
    yield put(setToastMessage({message: getModalErrorData(err)}))
  }
}

// pass org_id as payload to refresh token
export function* acceptChannelInviteSaga({payload: org_id}) {
  yield put(
    setInviteModalMessage('we_are_setting_up_your_workspace_please_wait')
  )
  yield* refreshTokenSaga({payload: {id: org_id}})

  try {
    const {body: acceptInvite} = yield call(postIdentity)
    if (acceptInvite.Success) {
      yield put(
        setItsmChannel({channelName: '', channelID: acceptInvite.Success[0]})
      )
    }

    yield put(getIdentityMe())
  } catch (err) {
    yield put(setChannelStep(undefined))
  }
}
