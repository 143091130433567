import React from 'react'

import {Form, Input} from 'antd'

import {translate} from '@/services/i18n'
import DrawerForm from '@/modules/ITSM/components/drawer-form/drawer-form/drawer-form'
import {TUserGroup} from '@/modules/ITSM/typedef'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import FormItemCustom from '@/components/form/form-item-custom'

type TProps = {
  handleCancel: () => void
  handleSubmit: (values: TUserGroup) => void
  loadingSubmit: boolean
}

export const UserGroupsFormNew = ({
  handleCancel,
  loadingSubmit,
  handleSubmit,
}: TProps) => {
  const [form] = Form.useForm()

  return (
    <DrawerForm
      form={form}
      handleCancel={handleCancel}
      loadingSubmit={loadingSubmit}
      onFinish={handleSubmit}
    >
      <FormItemCustom
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
        label={translate('name')}
        initialValue={''}
      >
        <Input autoComplete="off" onFocus={setAutocompleteNone} />
      </FormItemCustom>
    </DrawerForm>
  )
}
