import {TDocument} from '@/api/typedf'

import {extensionfilters} from '../../utils/Constants'

export const addfileType = (value: TDocument) => {
  const possibleExts = extensionfilters.map(({name}) => name)

  const extension = typeof value.name === 'string' ? value.name.split('.') : ''
  const extName = extension[extension.length - 1]

  return possibleExts.indexOf(extName) !== -1 ? extName : ''
}
