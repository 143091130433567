import React, {useEffect} from 'react'

import {useHistory, useParams} from 'react-router-dom'

import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {useGetSingleData} from '@/hooks/useGetSignleData'
import {fetchOneCustomerProduct} from '@/modules/ITSM/api/productsRequests'
import {TCustomerProduct} from '@/modules/ITSM/typedef'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {useQuery} from '@/utils/useQuery'

import CustomerProductForm from './customer-product-form'

const CustomerProductDetails = () => {
  const {id} = useParams() as {
    id: string
  }
  const isCopy = useQuery('copy')
  const history = useHistory()

  const {entityData, getData} = useGetSingleData<TCustomerProduct>(
    fetchOneCustomerProduct
  )

  useEffect(() => {
    if (!entityData) {
      if (isCopy) {
        history.push(itsmRoutes.settingsITSMCustomerProducts.path())
      } else {
        getData({id})
      }
    }
  }, [entityData, getData, history, id, isCopy])

  return (
    <Skeleton
      active={!entityData}
      view={<FormSkeleton className="incident-k-request-tabs" />}
    >
      <CustomerProductForm
        entityData={entityData}
        id={id.slice(0, 36)}
        getData={getData}
      />
    </Skeleton>
  )
}

export default CustomerProductDetails
