import styles from '@/styles/_variables.module.scss';

const { priorityRed, priorityOrange, priorityGreen } = styles;

const PROGRESS_BAR_COLORS = { priorityGreen, priorityOrange, priorityRed };

export const getProgressColor = (percent: number) => {
  if (percent < 50) {
    return PROGRESS_BAR_COLORS.priorityGreen;
  } else if (percent < 75) {
    return PROGRESS_BAR_COLORS.priorityOrange;
  }
  return PROGRESS_BAR_COLORS.priorityRed;
};
