import React, {useEffect, useState} from 'react'

import {Col} from 'antd'
import {useDispatch} from 'react-redux'

import {AppVersion, RequestStatus} from '@/typedef'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {Option, Select} from '@/components/select/select'
import {app_version} from '@/modules/ITSM/utils/Constants'
import {useGetRoles} from '@/modules/ITSM/hooks/identityHooks'
import {FormSkeleton, Skeleton} from '@/components/skeleton'
import {patchUser} from '@/modules/ITSM/api/userRequests'

import {useFetchHook} from '@/hooks/useFetch'
import {translate} from '@/services/i18n'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import FormItemCustom from '@/components/form/form-item-custom'
import {setRefresh} from '@/redux/actions'

import {getUser} from '@/redux/user/userActions'

type TProps = {
  asset: ItsmAssets
  record: Record<string, any>
}

export const AppVersionForm: React.FC<TProps> = ({asset, record}) => {
  const userRecord =
    asset === ItsmAssets.Users ? record : record.users && record.users[0]
  const [, , , , loading] = useGetRoles(userRecord.uuid)
  const [appVersion, setAppVesrion] = useState<AppVersion>(
    userRecord.app_version
  )

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      if (appVersion !== userRecord.app_version) {
        // @ts-ignore Argument of type 'ItsmAssets' is not assignable to parameter of type 'undefined'
        dispatch(setRefresh(true, asset))
      }
    }
  }, [appVersion, asset, dispatch, userRecord.app_version])

  const {
    status: updateStatus,
    handleRequested,
    handleSuccess,
    handleFail,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })
  const isUserUpdating = updateStatus === RequestStatus.REQUESTED

  const onChange = async (appVersion: AppVersion) => {
    try {
      handleRequested()

      const data = {app_version: appVersion}
      await patchUser(userRecord.uuid, data)
      setAppVesrion(appVersion)
      handleSuccess()
      dispatch(getUser())
    } catch (err) {
      handleFail(err)
    }
  }

  return userRecord ? (
    <Skeleton active={Boolean(loading)} view={<FormSkeleton />}>
      <h5>{translate('app_version')}</h5>
      <Col {...twoColumns} className="ml-0">
        <FormItemCustom>
          <Select
            className="full-width"
            value={appVersion}
            disabled={isUserUpdating}
            loading={isUserUpdating}
            onChange={onChange}
          >
            {app_version.map((version, i) => (
              <Option value={version} key={i}>
                {version}
              </Option>
            ))}
          </Select>
        </FormItemCustom>
      </Col>
    </Skeleton>
  ) : null
}
