import globalRoutes from '@/global-routes'
import {translate} from '@/services/i18n'
import {ModalType} from '@/components/modal/typedef'
import queryString from 'query-string'

export const REDIRECT_PATH = {
  HOME: globalRoutes.general.homePage.path(),
  LOGIN: globalRoutes.general.login.path(),
}
export const modalResolversMap = {
  // deAuth: () => authService.deauthenticate(),

  // TODO: need to implement logout

  deAuth: () => 'deauth',
}

export const getModalErrorData = (
  err: any,
  message: string = translate('something_went_wrong')
) => {
  const {grant} = queryString.parse(window.location.search) as {grant?: string}

  return {
    message,
    type: ModalType.ERROR,
    modalRedirectUrl: grant ? REDIRECT_PATH.LOGIN : REDIRECT_PATH.HOME,
    resolvers: grant ? [modalResolversMap.deAuth] : [],
    ...(err ? {err} : {}),
  }
}
