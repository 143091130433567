import React from 'react'
import {TLocation} from '@/modules/ITSM/typedef'
import {NewLocationButton} from '../../createNewLocation/newLocationButton'
import {FormInstance} from 'antd'
import {ISuperAgentResSingle} from '@/api/response-typedf'

export const getNewLocationButton = ({
  setNewLocation,
  form,
  saveLocation,
  setRefresh,
}: {
  setNewLocation: (value?: TLocation) => void
  form: FormInstance
  saveLocation: (
    params: TLocation,
    id?: string | undefined
  ) => Promise<ISuperAgentResSingle<TLocation>>
  setRefresh: (value: number) => void
}) => {
  const onSave = (locationData: TLocation) => {
    setNewLocation(locationData)

    form.setFieldsValue({location: locationData.uuid})
    setRefresh(Math.random())
  }

  return (
    <NewLocationButton
      formRules={{name: [{required: true}]}}
      onSave={onSave}
      preSaveAction={() => setNewLocation(undefined)}
      asyncF={saveLocation}
    />
  )
}
