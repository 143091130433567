import {useEffect} from 'react'

import {message} from 'antd'

import {RequestStatus} from '@/typedef'
import {translate, TKey} from '@/services/i18n'

const key = 'updatable'

export type TLoadingMessageType = {
  loadingMessage: TKey
  successMessage: TKey
  errorMessage: TKey
}
export default function useNotifyAsyncProgress(
  requestStatus = RequestStatus.INITIAL,
  messages?: TLoadingMessageType
) {
  useEffect(() => {
    if (messages) {
      const {loadingMessage, successMessage, errorMessage} = messages
      if (requestStatus === RequestStatus.REQUESTED) {
        message.loading({
          content: translate(loadingMessage),
          key,
        })
      }
      if (requestStatus === RequestStatus.SUCCEEDED) {
        message.success({
          content: translate(successMessage),
          key,
        })
      }
      if (requestStatus === RequestStatus.FAILED) {
        message.error({
          content: translate(errorMessage),
          key,
        })
      }
    }
  }, [requestStatus, messages])
}
