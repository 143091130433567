import {useEffect, useCallback, useReducer} from 'react'

import {useSelector} from 'react-redux'

import {usePrevious} from '@/hooks/usePrevious'
import {useSafeDispatch} from '@/hooks/useSafeDispatch'
import {RequestStatus} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {selectItsmTableFilters} from '@/modules/ITSM/store/list-table/selectors'
import {TAppState} from '@/redux/store'

import {searchFetchColumnKeys} from '../constants/search-fetch-column-keys'

type TAsyncF = ({
  bookmark,
  selector,
}: {
  bookmark?: string | undefined
  selector: Record<string, any>
}) => Promise<ISuperAgentResMultiple<Record<string, any>>> | undefined

type TInitialState = {
  status: RequestStatus
  selectorIds: Record<string, any> | undefined
  numberOfRecords: number
  bookmark: string | undefined
  selector: Record<string, unknown>
  asyncF?: TAsyncF
  propertyName: string
}

const initialState: TInitialState = {
  status: RequestStatus.INITIAL,
  selectorIds: {},
  numberOfRecords: 0,
  bookmark: undefined,
  selector: {},
  asyncF: undefined,
  propertyName: '',
}

const FETCH_DATA_REQUESTED = 'FETCH_DATA_REQUESTED'
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'
const SET_INITIAL_STATE = 'SET_INITIAL_STATE'

const SET_BOOKMARK = 'SET_BOOKMARK'
const SET_NUMBER_OF_RECORDS = 'SET_NUMBER_OF_RECORDS'
const SET_DATA = 'SET_DATA'
const SET_API_SELECTOR = 'SET_API_SELECTOR'
const SET_ASYNC_F = 'SET_ASYNC_F'
const SET_PROPERTY_NAME = 'SET_PROPERTY_NAME'

export const actionCreators = {
  getSelectorIdsRequested: () =>
    ({
      type: FETCH_DATA_REQUESTED,
    } as const),

  getSelectorIdsSuccess: () =>
    ({
      type: FETCH_DATA_SUCCESS,
    } as const),

  getSelectorIdsFailed: (err: string) =>
    ({
      type: FETCH_DATA_FAILED,
      payload: err,
    } as const),

  setBookmark: (bookmark: string | undefined) =>
    ({
      type: SET_BOOKMARK,
      payload: bookmark,
    } as const),

  setNumberOfRecords: (count: number) =>
    ({
      type: SET_NUMBER_OF_RECORDS,
      payload: count,
    } as const),

  setData: ({
    result,
    propertyName,
    fetchMoreRecords,
  }: {
    result: Record<string, unknown>[]
    propertyName: string
    fetchMoreRecords: boolean
  }) =>
    ({
      type: SET_DATA,
      payload: {result, propertyName, fetchMoreRecords},
    } as const),

  setInitialState: (name?: string, asset?: ItsmAssets) =>
    ({
      type: SET_INITIAL_STATE,
      payload: {name, asset},
    } as const),

  setSelector: (selector: Record<string, unknown>) =>
    ({
      type: SET_API_SELECTOR,
      payload: selector,
    } as const),

  setAsyncF: (asyncF: TAsyncF) =>
    ({
      type: SET_ASYNC_F,
      payload: asyncF,
    } as const),

  setPropertyName: (propertyName: string) =>
    ({
      type: SET_PROPERTY_NAME,
      payload: propertyName,
    } as const),
}

type TItsmTableCreators = typeof actionCreators

type TActionCreators = TItsmTableCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

function selectorReducer(state = initialState, action: TAnyAction) {
  switch (action.type) {
    case FETCH_DATA_REQUESTED: {
      return {...state, status: RequestStatus.REQUESTED}
    }
    case FETCH_DATA_SUCCESS: {
      return {
        ...state,
        status: RequestStatus.SUCCEEDED,
      }
    }

    case FETCH_DATA_FAILED: {
      return {...state, error: action.payload, status: RequestStatus.FAILED}
    }

    case SET_BOOKMARK: {
      return {...state, bookmark: action.payload}
    }

    case SET_INITIAL_STATE: {
      const {name, asset} = action.payload

      if (name && asset) {
        const {propertyName} = searchFetchColumnKeys?.[asset]?.[name] || {}

        if (propertyName) delete state.selectorIds?.[propertyName as string]

        return state
      }

      return initialState
    }

    case SET_NUMBER_OF_RECORDS: {
      return {
        ...state,
        numberOfRecords: action.payload,
      }
    }

    case SET_DATA: {
      const {propertyName, result, fetchMoreRecords} = action.payload

      return {
        ...state,
        selectorIds: {
          [propertyName]: (
            (fetchMoreRecords ? state.selectorIds?.[propertyName] : []) || []
          ).concat(result.map(({uuid}) => uuid)),
        },
      }
    }
    case SET_API_SELECTOR: {
      return {
        ...state,
        selector: action.payload,
      }
    }

    case SET_ASYNC_F: {
      return {
        ...state,
        asyncF: action.payload,
      }
    }

    case SET_PROPERTY_NAME: {
      return {
        ...state,
        propertyName: action.payload,
      }
    }

    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

const {
  getSelectorIdsFailed,
  getSelectorIdsRequested,
  setNumberOfRecords,
  setData,
  getSelectorIdsSuccess,
  setBookmark,
  setSelector,
  setInitialState,
  setAsyncF,
  setPropertyName,
} = actionCreators

export const useGetFilterSearchIds = <T extends Record<string, any>>(
  asset: ItsmAssets
) => {
  const [state, unsafeDispatch] = useReducer(selectorReducer, initialState)
  const dispatch = useSafeDispatch(unsafeDispatch)

  const {
    bookmark: bookmarkCurrent,
    numberOfRecords,
    status,
    selector,
    selectorIds,
    asyncF,
    propertyName,
  } = state

  const prevBookmark = usePrevious(bookmarkCurrent)

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const getSelectorIds = useCallback(
    async ({
      asyncF,
      bookmark,
      selector,
      propertyName,
      fetchMoreRecords,
    }: any) => {
      try {
        dispatch(getSelectorIdsRequested())

        const {
          body: {result, bookmark: bookmarkRes},
        } = await asyncF({
          passedBookmark: bookmark,
          selector: {
            [Object.keys(selector)[0]]: {
              $regex: `(?i)` + Object.values(selector)[0],
            },
          },
        })

        dispatch(setData({result, propertyName, fetchMoreRecords}))
        dispatch(setNumberOfRecords(result.length))

        if (result.length !== 10) {
          dispatch(getSelectorIdsSuccess())
          dispatch(setBookmark(undefined))
        } else {
          dispatch(setAsyncF(asyncF))
          dispatch(setSelector(selector))
          dispatch(setPropertyName(propertyName))
          dispatch(setBookmark(bookmarkRes))
        }
      } catch (err) {
        // @ts-ignore Argument of type 'unknown' is not assignable to parameter of type 'string'
        dispatch(getSelectorIdsFailed(err))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      numberOfRecords === 10 &&
      prevBookmark !== bookmarkCurrent &&
      asyncF &&
      Object.keys(selector).length &&
      propertyName
    ) {
      getSelectorIds({
        asyncF,
        bookmark: bookmarkCurrent,
        selector,
        propertyName,
        fetchMoreRecords: true,
      })
      dispatch(setNumberOfRecords(0))
    }
  }, [
    asyncF,
    bookmarkCurrent,
    dispatch,
    getSelectorIds,
    numberOfRecords,
    prevBookmark,
    selector,
    propertyName,
  ])

  const getFilterProperty = () => {
    return Object.keys(searchFetchColumnKeys?.[asset] || []).find(key => {
      return Object.prototype.hasOwnProperty.call(filters, key)
    })
  }
  const getFilterIds = async () => {
    const property = getFilterProperty()

    if (property) {
      const {asyncF, propertyName} =
        searchFetchColumnKeys?.[asset]?.[property] || {}

      getSelectorIds({
        bookmark: undefined,
        asyncF,
        selector: {[property]: filters[property]},
        propertyName,
      })
    }
  }

  const getSelector = () => {
    const property = getFilterProperty()

    for (const key in selectorIds) {
      return (
        property &&
        searchFetchColumnKeys[asset]?.[property]?.prepareSelector(
          selectorIds,
          key
        )
      )
    }
  }

  return {
    selectorIds: getSelector() || {},
    status,
    setInitSelectorIds: (name?: string, asset?: ItsmAssets) =>
      dispatch(setInitialState(name, asset)),
    getFilterIds,
    getFilterProperty,
  }
}
