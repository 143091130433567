import React from 'react'

import {Col, Row} from 'antd'
import {v4 as uuid} from 'uuid'

import {SkeletonItem} from '@/components/skeleton'
import {twoColumns} from '@/utils/table/constants/grid-columns'

type TProps = {
  className?: string
}

export const FormSkeleton: React.FC<TProps> = ({className}) => (
  <div className={className}>
    <Row>
      <Col span={24}>
        <div>
          <h3>{<SkeletonItem className="mb-5" width={110} height={27} />}</h3>
        </div>
      </Col>
    </Row>
    {new Array(3).fill(1).map(() => (
      <Row key={uuid()}>
        <Col {...twoColumns}>
          <SkeletonItem width={75} height={14} />
          <SkeletonItem width="100%" height={32} />
        </Col>
        <Col {...twoColumns}>
          <SkeletonItem width={75} height={14} />
          <SkeletonItem width="100%" height={32} />
        </Col>
      </Row>
    ))}
  </div>
)
