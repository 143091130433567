import React from 'react'

import {Divider} from 'antd'

import {AppVersionForm} from '@/modules/ITSM/sites/ITSMSettings/users/app-version-form'
import {useListTableStateContext} from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings-context'

import {TeamMembersForm} from './team-members-form'
import {UserForm} from './user-form'

import './IdentitiesDrawer.scss'

const IdentitiesDrawer = () => {
  const {onClose, record, asset} = useListTableStateContext()

  const handleCloseDrawer = () => {
    onClose()
  }

  return (
    <div className="identities">
      <AppVersionForm asset={asset} record={record} onClose={onClose} />
      <Divider />
      <TeamMembersForm asset={asset} record={record} />
      <Divider />
      <UserForm
        formId="userForm"
        asset={asset}
        record={record}
        onClose={onClose}
        handleCloseDrawer={handleCloseDrawer}
      />
    </div>
  )
}

export default IdentitiesDrawer
