import {TFilterListTable} from '@/modules/ITSM/components/itsm-list-table/typedf'

export const generateFilteredData = <T>(
  entityData: T[],
  filters: TFilterListTable<T>
) => {
  if (Object.keys(filters).length > 0) {
    let newEntityData = [...entityData]

    for (const key in filters) {
      const filteredData = newEntityData.filter(it => {
        const searchPattern = new RegExp(`^${filters[key as keyof T]}`, 'i')
        return searchPattern.test(`${it[key as keyof T]}`)
      })
      newEntityData = filteredData
    }
    return newEntityData
  }
  return entityData
}
