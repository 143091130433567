import {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import queryString from 'query-string'

import {
  AccountDefaultContext,
  setAccountDefaultAccId,
  setAccountDefaultError,
  setAccountDefaultOrgId,
  setAccountDefaultProgressStatus,
  setAccountDefaultStep,
  setAccountDefaultToken,
} from '@/sites/account-default/account-default-context/account-default-context'
import {
  createAccountStepProgress,
  CreateAccSteps,
} from '@/sites/account-default/utils/constants'
import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import {
  AccountCreateStep,
  Flow,
  TAccountDefaultQuery,
  TPrefillCreateAccountData,
} from '@/sites/account-default/typedef'
import {generateConfig} from '@/generateConfig'
import {TCreatorAccAndOrg} from '@/components/accountForm/types'
import {createAccountPayload} from '@/sites/accept-org-invite/utils/create-account-payload'
import {createCustomerPayload} from '@/modules/Subscriptions/utils/create-customer-payload'
import {createCustomer} from '@/api/billing-requests'
import {postAccountTs} from '@/modules/ITSM/api/ts-version/post-account-ts'
import {postMyProfile} from '@/api/my-profile-requests'
import {postOrganization} from '@/modules/ITSM/api/ts-version/post-organization'
import {getBEndToken} from '@/api/token-request'
import {useSubscribeAllInclusive} from '@/sites/account-default/hooks/use-subscribe-all-inclusive'
import {hasPaymentDetails} from '@/sites/account-default/utils/has-payment-details'
import {useAccountDefaultRedirect} from '@/sites/account-default/hooks/use-account-default-redirect'
import {postNewChannel} from '@/modules/ITSM/api/ts-version/post-new-channel'

type TCreateChannel = {
  channel_name: string
  orgId: string
  accountId: string
}

export const useCreateAccount = () => {
  const history = useHistory()
  const query = queryString.parse(
    history.location.search
  ) as TPrefillCreateAccountData & TAccountDefaultQuery

  const {hasPayment: hasPaymentStep} = hasPaymentDetails(query)

  const {dispatch} = useContext(AccountDefaultContext)

  const {subscribeAllInclusive} = useSubscribeAllInclusive()

  const redirect = useAccountDefaultRedirect()

  const handleCreateChannel = async ({
    channel_name,
    orgId,
    accountId,
  }: TCreateChannel) => {
    try {
      const responseOrgToken = await getBEndToken({
        id: orgId,
      })

      dispatch(setAccountDefaultToken(responseOrgToken?.body.access_token))

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.REFRESH_ORG_TOKEN]
            .progressStatus
        )
      )

      const orgToken = responseOrgToken.body.access_token

      const channelResponse = await postNewChannel({
        data: {
          channel_name,
          ...(query?.flow === Flow.AD_HOC ? {ad_hoc: true} : {}),
        },
        headers: createAuthorizationHeader(orgToken),
      })

      if (query.flow === Flow.AD_HOC) delete query.flow

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.CREATE_CHANNEL]
            .progressStatus
        )
      )

      await postMyProfile(
        {
          accountId,
          orgId,
          channelId: channelResponse.body.channel_id,
        },
        createAuthorizationHeader(orgToken)
      )

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.POST_PROFILE].progressStatus
        )
      )

      if (hasPaymentStep) {
        dispatch(setAccountDefaultStep(AccountCreateStep.PAYMENT))

        return
      }

      const responseSubscr = await subscribeAllInclusive(orgToken)

      if (responseSubscr instanceof Error) {
        return dispatch(setAccountDefaultError(responseSubscr))
      }

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.SUBSCRIBE_TO_PLAN]
            .progressStatus
        )
      )

      if (!generateConfig().FEATURE_FLAGS.isCalendlyBookEnabled) {
        return redirect(query)
      }

      dispatch(setAccountDefaultStep(AccountCreateStep.SCHEDULE_CALENDLY))
    } catch (error) {
      dispatch(setAccountDefaultError(error))
    }
  }

  const handleCreateAccount = async (values: TCreatorAccAndOrg) => {
    try {
      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.INITIAL].progressStatus
        )
      )

      const responseInitialToken = await getBEndToken()

      dispatch(setAccountDefaultToken(responseInitialToken?.body.access_token))

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.POST_CREATE_ACCOUNT]
            .progressStatus
        )
      )

      const accountResponse = await postAccountTs({
        payload: createAccountPayload(values.account),
        headers: createAuthorizationHeader(
          responseInitialToken?.body.access_token
        ),
      })

      const accountId = accountResponse?.body.id

      dispatch(setAccountDefaultAccId(accountId))

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.REFRESH_TOKEN].progressStatus
        )
      )

      const responseAccToken = await getBEndToken({
        scope: 'acc',
        id: accountId,
      })

      dispatch(setAccountDefaultToken(responseAccToken?.body.access_token))
      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.CREATE_STRIPE_CUSTOMER]
            .progressStatus
        )
      )

      const accToken = responseAccToken?.body?.access_token

      await createCustomer({
        data: createCustomerPayload(values),
        headers: createAuthorizationHeader(accToken),
      })

      dispatch(
        setAccountDefaultProgressStatus(
          createAccountStepProgress[CreateAccSteps.CREATE_ORG].progressStatus
        )
      )

      const {
        body: {id},
      } = await postOrganization({
        data: {name: values.companyName},
        accId: accountId,
        headers: createAuthorizationHeader(accToken),
      })

      dispatch(setAccountDefaultOrgId(id))

      await handleCreateChannel({
        channel_name: values.channel_name,
        orgId: id,
        accountId,
      })
    } catch (error) {
      dispatch(setAccountDefaultError(error))
    }
  }

  const handleOnlyCreateChannel = async (props: TCreateChannel) => {
    dispatch(setAccountDefaultProgressStatus(0))

    await handleCreateChannel(props)
  }

  return {
    createAccount: handleCreateAccount,
    createChannel: handleOnlyCreateChannel,
  }
}
