import queryString from 'query-string'
import {omit} from 'lodash'

import {generateConfig} from '@/generateConfig'
import {generateRedirectPath} from '@/utils/generate-redirect-path'

import {useGenerateReturnLink} from '@/modules/Subscriptions/hooks/use-generate-return-link'

import {
  PaymentStatus,
  TAccountDefaultQuery,
} from '@/sites/account-default/typedef'

export const useGenerateSessionReturnLinks = () => {
  const generateLink = useGenerateReturnLink()

  return (query?: TAccountDefaultQuery) => {
    const cancelSearch = queryString.stringify({
      ...query,
      'payment-status': PaymentStatus.CANCEL,
    })

    const cleanQuery = omit(query, [
      'payload',
      'unit_amount',
      'period',
      'payment-status',
    ])
    const successSearch = generateConfig().FEATURE_FLAGS.isCalendlyBookEnabled
      ? {
          search: queryString.stringify({
            ...cleanQuery,
            'payment-status': PaymentStatus.SUCCESS,
          }),
        }
      : generateRedirectPath(queryString.stringify(cleanQuery))

    return {
      cancel_url: generateLink({search: cancelSearch}),
      success_url: generateLink(successSearch),
    }
  }
}
