type TServicePriceConfig = {
  [key: string]: {
    name: string
    items: {
      [key: string]: number | null
    }
  }
}

export const servicePriceConfig: TServicePriceConfig = {
  'number-of-users': {
    name: 'number-of-users',
    items: {
      'one-oneHundred': null,
      'hundred-plus': 5,
      'thousand-plus': 8,
    },
  },
  'support-type': {
    name: 'support-type',
    items: {
      'remote-support': null,
      'onsite-support': 5,
      'both-support': 8,
    },
  },
  support: {
    name: 'support',
    items: {
      'support-8-5': null,
      'support-24-5': 50,
      'support-24-7': 100,
    },
  },
  'respond-time': {
    name: 'respond-time',
    items: {
      '2-5-days': null,
      '24-hours': 50,
      '8-hours': 100,
      asap: 150,
    },
  },
}
