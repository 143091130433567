import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TListOptions} from '@/typedef'
import {roleApi} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getUserRoles = async (id: string) => {
  return await request
    .options(roleApi().getUserRoles)
    .set(getHeadersWithGRPC())
    .send({
      user_id: id,
    })
}

export const getRolesGrantableByMe = async (headers: {
  'Grpc-Metadata-space': string
  Authorization: string
}) => {
  return await request
    .options(roleApi().getMyRoles)
    .set(headers || getHeadersWithGRPC())
    .send({selector: {}})
}

export const fetchRoles = async <T extends Record<string, any>>({
  resolve,
  passedBookmark,
  selector,
  sort,
}: TListOptions<T>): Promise<ISuperAgentResMultiple<T>> =>
  await request
    .options(roleApi().options + (resolve || ''))
    .set(getHeadersWithGRPC())
    .send({
      bookmark: passedBookmark,
      selector: {
        ...selector,
      },
      sort,
    })

export const getAllRoles = async () => {
  return await request
    .options(roleApi().options)
    .set(getHeadersWithGRPC())
    .send({selector: {}})
}

export const patchUserRoles = async (data: Record<string, any>) => {
  return await request
    .post(roleApi().patchUserRoles)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const getRole = async (uuid: string) => {
  return await request.get(roleApi(uuid).getOne).set(getHeadersWithGRPC())
}

export const postRole = async (uuid: string, data: Record<string, any>) => {
  return await request
    .post(roleApi(uuid).post)
    .set(getHeadersWithGRPC())
    .send(data)
}

export const patchRole = async (uuid: string, data: Record<string, any>) => {
  return await request
    .patch(roleApi(uuid).patch)
    .set(getHeadersWithGRPC())
    .send(data)
}
