import React, {useCallback, useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import {useHistory} from 'react-router'
import {useSelector} from 'react-redux'
import {withErrorBoundary} from '@sentry/react'

import {selectUserID} from '@/redux/user/userSelector'
import {usePrevious} from '@/hooks/usePrevious'
import ErrorPage from '@/components/error/error-page/error-page'
import {TAppState} from '@/redux/store'

import {AssigneeStates} from '../incident-k-request/constants'
import {useStateContext} from '../incident/incident-context'
import {TIncident} from '../incident-k-request/typedf'
import ItsmListTableNoFilters from '../itsm-list-table-no-filters/itsm-list-table-no-filters'
import {ItsmAssets, TTimeLog} from '../../typedef'
import {TColumnRender} from '../itsm-list-table/typedf'
import {apiService} from '../../api/api-service'
import {useGetDataListTableNoFilters} from '../itsm-list-table-no-filters/hooks/use-get-data-list-table-no-filters'
import {fetchTimeLogs as fetchOpenTimmelog} from '../../api/timelogsRequests'
import {selectItsmAssetPagination} from '../../store/list-table/selectors'
import {checkAccess} from '../../utils/accessChecks'

import TimeLogCheckout from './time-log-checkout'
import CheckInBtn from './check-in-btn'
import {TimeLogsRowValue} from './time-logs-row-value'

import './time-logs.scss'

type TProps = {
  editableState: boolean
  checkInBtnVisible: boolean
}

const TimeLogs = ({editableState, checkInBtnVisible}: TProps) => {
  const {entityData, entity, getData} = useStateContext<TIncident>()
  const history = useHistory()

  const asset = ItsmAssets.TimeLogs

  const [openTimeLog, setOpenTimeLog] = useState<TTimeLog[] | undefined>()

  const {uuid = '', assigned_to, assignee_state} = entityData || {}
  const updatingTSright = checkAccess('updateTimelogTimespan')

  const {
    getEntityData: fetchTimeLogs,
    entityData: timeLogsData,
    numberOfRecords,
    fetchStatus,
  } = useGetDataListTableNoFilters<TTimeLog>({
    asyncF: apiService[asset],
    asset,
    entity,
  })

  const paginationAsset =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const prevPaginationAsset = usePrevious(paginationAsset)

  const getOpenTimeLog = useCallback(async () => {
    const result = await fetchOpenTimmelog({
      selector: {
        end: {$exists: false},
        entity: `${entity}:${uuid}`,
      },
    })

    if (result) setOpenTimeLog(result.body.result as TTimeLog[])
  }, [entity, uuid])

  const refetchTimeLogs = useCallback(() => {
    getOpenTimeLog()
    fetchTimeLogs({resetBookmarks: true})
    getData()
  }, [fetchTimeLogs, getData, getOpenTimeLog])

  useEffect(() => {
    if (!openTimeLog) getOpenTimeLog()
  }, [getOpenTimeLog, openTimeLog])

  const userID = useSelector(selectUserID)

  const setRedirect = (uuid: string) => {
    history.push('?timelog=' + uuid)
  }

  useEffect(() => {
    if (
      prevPaginationAsset?.bookmarks.length &&
      !paginationAsset.bookmarks.length
    ) {
      refetchTimeLogs()
    }
  }, [paginationAsset, prevPaginationAsset, refetchTimeLogs])

  const getRowClass = (record: TTimeLog) =>
    updatingTSright && editableState && record.end && !record.is_cancelled
      ? 'pointer-cursor'
      : 'time-logs__row-disabled'

  const handleRowClick = (record: TTimeLog) =>
    updatingTSright &&
    editableState &&
    record.end &&
    !record.is_cancelled &&
    setRedirect(record.uuid)

  const handleGetRowValue = (props: TColumnRender<TTimeLog>) => (
    <TimeLogsRowValue {...props} />
  )

  const assignedToCurrentUser = assigned_to?.uuid === userID

  return (
    <Row className="time-logs">
      <Col xs={24}>
        {openTimeLog && (
          <>
            {openTimeLog?.length === 0 &&
              checkInBtnVisible &&
              assignedToCurrentUser &&
              assignee_state === AssigneeStates.Accepted && (
                <CheckInBtn refetchTimeLogs={refetchTimeLogs} />
              )}

            {openTimeLog?.length > 0 && assignedToCurrentUser && (
              <TimeLogCheckout
                timelogOpened={openTimeLog}
                checkInBtnVisible={checkInBtnVisible}
                refetchTimeLogs={refetchTimeLogs}
              />
            )}
          </>
        )}
        <div className="time-logs__table" e2e-test="time-logs-table">
          <ItsmListTableNoFilters<TTimeLog>
            {...{
              getEntityData: fetchTimeLogs,
              entityData: timeLogsData,
              numberOfRecords,
              fetchStatus,
              asset,
              getRowValue: handleGetRowValue,
              rowClassName: getRowClass,
              onRowClick: handleRowClick,
            }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withErrorBoundary(TimeLogs, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
