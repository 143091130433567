import {CmdbNamespace} from '../../api-types/Api'
import {TActionTypes} from './actionTypes'

export const setCMDBUserInitialState = () =>
  ({
    type: TActionTypes.RESET_CMDB_USER_STATE,
  } as const)

export const fetchCmdbAccountIdentityMe = () =>
  ({
    type: TActionTypes.FETCH_CMDB_IDENTITY_ME_REQUESTED,
  } as const)

export const fetchCmdbIdentityMeSuccess = (cmdbUser: {
  cmdbUserRole?: string
  cmdbUserNameInChannel?: string
  cmdbNamespaces?: Array<CmdbNamespace>
  cmdbUserId: string
}) =>
  ({
    type: TActionTypes.FETCH_CMDB_USER_SUCCESS,
    payload: cmdbUser,
  } as const)

export const fetchCmdbIdentityMeFailed = (payload: unknown) =>
  ({
    type: TActionTypes.FETCH_CMDB_USER_FAILED,
    payload,
  } as const)

export const addCmdbNamespace = (namespace: CmdbNamespace) =>
  ({
    type: TActionTypes.ADD_CMDB_NAMESPACE,
    payload: namespace,
  } as const)
