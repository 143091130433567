import React, {useEffect, useState} from 'react'

import {Input, Select, Spin} from 'antd'
import moment from 'moment'
import {useSelector} from 'react-redux'
import request from 'superagent'
import {SelectValue} from 'antd/lib/select'

import {TAppState} from '@/redux/store'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {filterOption} from '@/utils/filter-option'
import {useGetSingleData} from '@/hooks/useGetSignleData'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {selectItsmTableFilters} from '@/modules/ITSM/store/list-table/selectors'
import {useGetMultipleData} from '@/hooks/useGetMultipleData'
import {checkIsLoading} from '@/utils/check-is-loading'
import {getHeadersWithGRPC} from '@/services/api'
import FormItemWrapper from '@/components/form/form-item-styled-wrapper/form-item-styled-wrapper'

import {
  getOptionBMColumnKeys,
  getOptionColumnKeys,
  getOptionFetchColumnKeys,
} from '../constants/filters-config'
import {getRegexValue} from '../utils/get-regex-value'
import {itsmColumnsDefaults} from '../constants/columns-defaults'
import {convertFilterValue} from '../utils/get-filter-value'
import {FilterTypes, TDataTablePreset, TFilterListTable} from '../typedf'
import DatePicker from '@/components/date-picker/date-picker'

type TOnchange<T> = (value: string | string[], key: keyof T) => void
type TFormInputEvent = React.FormEvent<HTMLInputElement>
type TValue = string | number | Record<string, any> | boolean | string[]

const {Option} = Select
const {RangePicker} = DatePicker

const generateFilter = <T extends Record<string, any>>(
  asset: ItsmAssets,
  key: keyof T,
  filterType?: FilterTypes
) => {
  const optionsList = getOptionColumnKeys<T>()[asset]
  const optionsListBM = getOptionBMColumnKeys<T>()[asset]?.[key]
  const optionsFetch = getOptionFetchColumnKeys<T>()[asset]?.[key]

  switch (filterType) {
    case FilterTypes.Date:
      return (value: TValue, onChange: TOnchange<T>) => {
        return (
          <FormItemWrapper>
            <DatePicker
              format={'YYYY-MMM-DD'}
              value={moment(value.toString())}
              onChange={e =>
                onChange(e ? moment(e).format('YYYY-MM-DD') : '', key)
              }
            />
          </FormItemWrapper>
        )
      }

    case FilterTypes.Select:
      return (value: TValue, onChange: TOnchange<T>) => {
        return (
          <FormItemWrapper>
            <Select
              filterOption={filterOption}
              showSearch
              allowClear
              value={value.toString()}
              onChange={(e: SelectValue) =>
                onChange(e !== undefined ? e.toString() : '', key)
              }
            >
              {optionsList &&
                optionsList[key]?.map(filter => (
                  <Option key={filter.value} value={filter.value}>
                    {filter.name}
                  </Option>
                ))}
            </Select>
          </FormItemWrapper>
        )
      }
    case FilterTypes.Search:
      return (value: TValue, onChange: TOnchange<T>) => {
        return (
          <FormItemWrapper>
            <Input
              value={value.toString()}
              onChange={(e: TFormInputEvent) => {
                onChange(e.currentTarget.value, key)
              }}
            />
          </FormItemWrapper>
        )
      }

    case FilterTypes.SearchNumber:
      return (value: TValue, onChange: TOnchange<T>) => {
        return (
          <FormItemWrapper>
            <Input
              value={Number(value)}
              onChange={(e: TFormInputEvent) => {
                onChange(e.currentTarget.value, key)
              }}
            />
          </FormItemWrapper>
        )
      }

    case FilterTypes.SelectWithBM: {
      return (value: TValue, onChange: TOnchange<T>) => {
        if (optionsListBM?.api) {
          const promise = async ({id}: {id: string}) =>
            await request
              .options(optionsListBM.api)
              .set(getHeadersWithGRPC())
              .send({selector: {uuid: id}})

          const {entityData, getData} = useGetSingleData<Record<string, any>>(
            promise
          )

          useEffect(() => {
            if (value) getData({id: value.toString()})
          }, [value])

          return (
            <FormItemWrapper>
              <SelectWithBM
                api={optionsListBM.api}
                name={key}
                filterData={value ? entityData?.[0] : {}}
                selector={optionsListBM.selector}
                searchKey={optionsListBM.optionContent || 'name'}
                optionContent={(res: {[key: string]: any}) =>
                  res[optionsListBM.optionContent || 'name']
                }
                onChange={(val: string) => onChange(val, key)}
              />
            </FormItemWrapper>
          )
        }
      }
    }
    case FilterTypes.SelectFetch:
      return (value: TValue, onChange: TOnchange<T>) => {
        if (optionsFetch?.asyncF && !!optionsFetch?.getOptionContent) {
          const {getData, entityData, fetchStatus} = useGetMultipleData<
            Record<string, any>
          >(optionsFetch?.asyncF)

          const optionsList =
            entityData && optionsFetch?.getOptionContent(entityData)

          useEffect(() => {
            if (value) getData({selector: {}})
          }, [value])

          return (
            <FormItemWrapper>
              <Select
                filterOption={filterOption}
                showSearch
                allowClear
                onFocus={() => getData({selector: {}})}
                value={value.toString()}
                onChange={(e: SelectValue) =>
                  onChange(e !== undefined ? e.toString() : '', key)
                }
                loading={checkIsLoading(fetchStatus)}
              >
                {checkIsLoading(fetchStatus) ? (
                  <Option value="loading" key="loading">
                    <Spin />
                  </Option>
                ) : (
                  (optionsList || []).map(filter => (
                    <Option key={filter.value} value={filter.value}>
                      {filter.name}
                    </Option>
                  ))
                )}
              </Select>
            </FormItemWrapper>
          )
        }
      }

    case FilterTypes.Boolean:
      return (value: TValue, onChange: TOnchange<T>) => {
        return (
          <FormItemWrapper>
            <Select
              showSearch
              allowClear
              onChange={(e: SelectValue) =>
                onChange(e !== undefined ? e.toString() : '', key)
              }
            >
              <Option key={'true'} value={'true'}>
                {'true'}
              </Option>
              <Option key={'false'} value={'false'}>
                {'false'}
              </Option>
            </Select>
          </FormItemWrapper>
        )
      }

    case FilterTypes.DateRange:
      return (value: TValue, onChange: TOnchange<T>) => {
        return (
          <FormItemWrapper>
            <RangePicker
              showTime
              value={
                Array.isArray(value)
                  ? [moment(value[0]), moment(value[1])]
                  : null
              }
              onChange={dates => {
                const rangeDates = dates?.map(date =>
                  moment(date)
                    .utc()
                    .format()
                )
                if (rangeDates) onChange(rangeDates, key)
              }}
            />
          </FormItemWrapper>
        )
      }

    default:
      return () => undefined
  }
}

export const useGenerateInputFiler = <T extends Record<string, any>>(
  asset: ItsmAssets
) => {
  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<T>(state, asset)
  )

  const [inputValues, setInputValues] = useState<TFilterListTable<T>>(
    getRegexValue(filters)
  )

  useEffect(() => {
    setInputValues(getRegexValue<T>(filters))
  }, [filters])

  const inputFilterChange = (val: string | string[], key: keyof T) => {
    const {[key]: _, ...inputsWithoutChangingValue} = inputValues

    setInputValues({
      ...inputsWithoutChangingValue,
      ...convertFilterValue(val, key, asset),
    })
  }

  const resetFilter = (key: string) => {
    const newInputValues = {...inputValues}

    delete newInputValues[key]
    setInputValues(newInputValues)
  }

  const resetAllFilters = () => {
    setInputValues({})
  }

  const columnsDef: TDataTablePreset = itsmColumnsDefaults[asset]

  const quickFilterList = columnsDef.filter(({quickFilter}) => quickFilter)

  const generatedFilters = quickFilterList
    .map(({title, filterType}) => ({
      title,
      filter: generateFilter<T>(
        asset,
        title,
        filterType
      )(
        (title && inputValues?.[title]) !== undefined
          ? inputValues?.[title] ?? ''
          : '',
        inputFilterChange
      ),
    }))
    .filter(res => res.filter)
    .map(filter => {
      return {
        ...filter,
        isApplied: (filters && filters[filter.title] !== undefined) || false,
      }
    })

  return {generatedFilters, inputValues, resetFilter, resetAllFilters}
}
