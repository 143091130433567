import {translate} from '@/services/i18n'
import globalRoutes from '@/global-routes'

export const DATE_FORMAT = 'HH:mm DD-MMM-YYYY'
export const RESOLVE = '?resolve=true'
export const GET_USER_MANAGERS_SELECTOR = {
  type: {$in: ['engineer-manager', 'non-enginneer-manager']},
}

type TTimeZones = Array<{
  value: string
  time: string
}>

export const TIME_ZONES: TTimeZones = [
  {value: 'Africa/Abidjan', time: '+00:00'},
  {value: 'Africa/Accra', time: '+00:00'},
  {value: 'Africa/Addis_Ababa', time: '+03:00'},
  {value: 'Africa/Algiers', time: '+01:00'},
  {value: 'Africa/Asmara', time: '+03:00'},
  {value: 'Africa/Asmera', time: '+03:00'},
  {value: 'Africa/Bamako', time: '+00:00'},
  {value: 'Africa/Bangui', time: '+01:00'},
  {value: 'Africa/Banjul', time: '+00:00'},
  {value: 'Africa/Blantyre', time: '+02:00'},
  {value: 'Africa/Brazzaville', time: '+01:00'},
  {value: 'Africa/Bujumbura', time: '+02:00'},
  {value: 'Africa/Cairo', time: '+02:00'},
  {value: 'Africa/Casablanca', time: '+00:00'},
  {value: 'Africa/Ceuta', time: '+01:00'},
  {value: 'Africa/Conakry', time: '+00:00'},
  {value: 'Africa/Dakar', time: '+00:00'},
  {value: 'Africa/Dar_es_Salaam', time: '+03:00'},
  {value: 'Africa/Djibouti', time: '+03:00'},
  {value: 'Africa/Douala', time: '+01:00'},
  {value: 'Africa/El_Aaiun', time: '+00:00'},
  {value: 'Africa/Freetown', time: '+00:00'},
  {value: 'Africa/Gaborone', time: '+02:00'},
  {value: 'Africa/Harare', time: '+02:00'},
  {value: 'Africa/Johannesburg', time: '+02:00'},
  {value: 'Africa/Juba', time: '+03:00'},
  {value: 'Africa/Kampala', time: '+03:00'},
  {value: 'Africa/Khartoum', time: '+03:00'},
  {value: 'Africa/Kigali', time: '+02:00'},
  {value: 'Africa/Kinshasa', time: '+01:00'},
  {value: 'Africa/Lagos', time: '+01:00'},
  {value: 'Africa/Libreville', time: '+01:00'},
  {value: 'Africa/Lome', time: '+00:00'},
  {value: 'Africa/Luanda', time: '+01:00'},
  {value: 'Africa/Lubumbashi', time: '+02:00'},
  {value: 'Africa/Lusaka', time: '+02:00'},
  {value: 'Africa/Malabo', time: '+01:00'},
  {value: 'Africa/Maputo', time: '+02:00'},
  {value: 'Africa/Maseru', time: '+02:00'},
  {value: 'Africa/Mbabane', time: '+02:00'},
  {value: 'Africa/Mogadishu', time: '+03:00'},
  {value: 'Africa/Monrovia', time: '+00:00'},
  {value: 'Africa/Nairobi', time: '+03:00'},
  {value: 'Africa/Ndjamena', time: '+01:00'},
  {value: 'Africa/Niamey', time: '+01:00'},
  {value: 'Africa/Nouakchott', time: '+00:00'},
  {value: 'Africa/Ouagadougou', time: '+00:00'},
  {value: 'Africa/Porto-Novo', time: '+01:00'},
  {value: 'Africa/Sao_Tome', time: '+00:00'},
  {value: 'Africa/Timbuktu', time: '+00:00'},
  {value: 'Africa/Tripoli', time: '+02:00'},
  {value: 'Africa/Tunis', time: '+01:00'},
  {value: 'Africa/Windhoek', time: '+01:00'},
  {value: 'America/Adak', time: '-10:00'},
  {value: 'America/Anchorage', time: '-09:00'},
  {value: 'America/Anguilla', time: '-04:00'},
  {value: 'America/Antigua', time: '-04:00'},
  {value: 'America/Araguaina', time: '-03:00'},
  {value: 'America/Argentina/Buenos_Aires', time: '-03:00'},
  {value: 'America/Argentina/Catamarca', time: '-03:00'},
  {value: 'America/Argentina/ComodRivadavia', time: '-03:00'},
  {value: 'America/Argentina/Cordoba', time: '-03:00'},
  {value: 'America/Argentina/Jujuy', time: '-03:00'},
  {value: 'America/Argentina/La_Rioja', time: '-03:00'},
  {value: 'America/Argentina/Mendoza', time: '-03:00'},
  {value: 'America/Argentina/Rio_Gallegos', time: '-03:00'},
  {value: 'America/Argentina/Salta', time: '-03:00'},
  {value: 'America/Argentina/San_Juan', time: '-03:00'},
  {value: 'America/Argentina/San_Luis', time: '-03:00'},
  {value: 'America/Argentina/Tucuman', time: '-03:00'},
  {value: 'America/Argentina/Ushuaia', time: '-03:00'},
  {value: 'America/Aruba', time: '-04:00'},
  {value: 'America/Asuncion', time: '-04:00'},
  {value: 'America/Atikokan', time: '-05:00'},
  {value: 'America/Atka', time: '-10:00'},
  {value: 'America/Bahia', time: '-03:00'},
  {value: 'America/Bahia_Banderas', time: '-06:00'},
  {value: 'America/Barbados', time: '-04:00'},
  {value: 'America/Belem', time: '-03:00'},
  {value: 'America/Belize', time: '-06:00'},
  {value: 'America/Blanc-Sablon', time: '-04:00'},
  {value: 'America/Boa_Vista', time: '-04:00'},
  {value: 'America/Bogota', time: '-05:00'},
  {value: 'America/Boise', time: '-07:00'},
  {value: 'America/Buenos_Aires', time: '-03:00'},
  {value: 'America/Cambridge_Bay', time: '-07:00'},
  {value: 'America/Campo_Grande', time: '-04:00'},
  {value: 'America/Cancun', time: '-06:00'},
  {value: 'America/Caracas', time: '-04:30'},
  {value: 'America/Catamarca', time: '-03:00'},
  {value: 'America/Cayenne', time: '-03:00'},
  {value: 'America/Cayman', time: '-05:00'},
  {value: 'America/Chicago', time: '-06:00'},
  {value: 'America/Chihuahua', time: '-07:00'},
  {value: 'America/Coral_Harbour', time: '-05:00'},
  {value: 'America/Cordoba', time: '-03:00'},
  {value: 'America/Costa_Rica', time: '-06:00'},
  {value: 'America/Creston', time: '-07:00'},
  {value: 'America/Cuiaba', time: '-04:00'},
  {value: 'America/Curacao', time: '-04:00'},
  {value: 'America/Danmarkshavn', time: '+00:00'},
  {value: 'America/Dawson', time: '-08:00'},
  {value: 'America/Dawson_Creek', time: '-07:00'},
  {value: 'America/Denver', time: '-07:00'},
  {value: 'America/Detroit', time: '-05:00'},
  {value: 'America/Dominica', time: '-04:00'},
  {value: 'America/Edmonton', time: '-07:00'},
  {value: 'America/Eirunepe', time: '-05:00'},
  {value: 'America/El_Salvador', time: '-06:00'},
  {value: 'America/Ensenada', time: '-08:00'},
  {value: 'America/Fort_Wayne', time: '-05:00'},
  {value: 'America/Fortaleza', time: '-03:00'},
  {value: 'America/Glace_Bay', time: '-04:00'},
  {value: 'America/Godthab', time: '-03:00'},
  {value: 'America/Goose_Bay', time: '-04:00'},
  {value: 'America/Grand_Turk', time: '-05:00'},
  {value: 'America/Grenada', time: '-04:00'},
  {value: 'America/Guadeloupe', time: '-04:00'},
  {value: 'America/Guatemala', time: '-06:00'},
  {value: 'America/Guayaquil', time: '-05:00'},
  {value: 'America/Guyana', time: '-04:00'},
  {value: 'America/Halifax', time: '-04:00'},
  {value: 'America/Havana', time: '-05:00'},
  {value: 'America/Hermosillo', time: '-07:00'},
  {value: 'America/Indiana/Indianapolis', time: '-05:00'},
  {value: 'America/Indiana/Knox', time: '-06:00'},
  {value: 'America/Indiana/Marengo', time: '-05:00'},
  {value: 'America/Indiana/Petersburg', time: '-05:00'},
  {value: 'America/Indiana/Tell_City', time: '-06:00'},
  {value: 'America/Indiana/Valparaiso', time: '-06:00'},
  {value: 'America/Indiana/Vevay', time: '-05:00'},
  {value: 'America/Indiana/Vincennes', time: '-05:00'},
  {value: 'America/Indiana/Winamac', time: '-05:00'},
  {value: 'America/Indianapolis', time: '-05:00'},
  {value: 'America/Inuvik', time: '-07:00'},
  {value: 'America/Iqaluit', time: '-05:00'},
  {value: 'America/Jamaica', time: '-05:00'},
  {value: 'America/Jujuy', time: '-03:00'},
  {value: 'America/Juneau', time: '-09:00'},
  {value: 'America/Kentucky/Louisville', time: '-05:00'},
  {value: 'America/Kentucky/Monticello', time: '-05:00'},
  {value: 'America/Knox_IN', time: '-06:00'},
  {value: 'America/Kralendijk', time: '-04:00'},
  {value: 'America/La_Paz', time: '-04:00'},
  {value: 'America/Lima', time: '-05:00'},
  {value: 'America/Los_Angeles', time: '-08:00'},
  {value: 'America/Louisville', time: '-05:00'},
  {value: 'America/Lower_Princes', time: '-04:00'},
  {value: 'America/Maceio', time: '-03:00'},
  {value: 'America/Managua', time: '-06:00'},
  {value: 'America/Manaus', time: '-04:00'},
  {value: 'America/Marigot', time: '-04:00'},
  {value: 'America/Martinique', time: '-04:00'},
  {value: 'America/Matamoros', time: '-06:00'},
  {value: 'America/Mazatlan', time: '-07:00'},
  {value: 'America/Mendoza', time: '-03:00'},
  {value: 'America/Menominee', time: '-06:00'},
  {value: 'America/Merida', time: '-06:00'},
  {value: 'America/Metlakatla', time: '-08:00'},
  {value: 'America/Mexico_City', time: '-06:00'},
  {value: 'America/Miquelon', time: '-03:00'},
  {value: 'America/Moncton', time: '-04:00'},
  {value: 'America/Monterrey', time: '-06:00'},
  {value: 'America/Montevideo', time: '-03:00'},
  {value: 'America/Montreal', time: '-05:00'},
  {value: 'America/Montserrat', time: '-04:00'},
  {value: 'America/Nassau', time: '-05:00'},
  {value: 'America/New_York', time: '-05:00'},
  {value: 'America/Nipigon', time: '-05:00'},
  {value: 'America/Nome', time: '-09:00'},
  {value: 'America/Noronha', time: '-02:00'},
  {value: 'America/North_Dakota/Beulah', time: '-06:00'},
  {value: 'America/North_Dakota/Center', time: '-06:00'},
  {value: 'America/North_Dakota/New_Salem', time: '-06:00'},
  {value: 'America/Ojinaga', time: '-07:00'},
  {value: 'America/Panama', time: '-05:00'},
  {value: 'America/Pangnirtung', time: '-05:00'},
  {value: 'America/Paramaribo', time: '-03:00'},
  {value: 'America/Phoenix', time: '-07:00'},
  {value: 'America/Port_of_Spain', time: '-04:00'},
  {value: 'America/Port-au-Prince', time: '-05:00'},
  {value: 'America/Porto_Acre', time: '-05:00'},
  {value: 'America/Porto_Velho', time: '-04:00'},
  {value: 'America/Puerto_Rico', time: '-04:00'},
  {value: 'America/Rainy_River', time: '-06:00'},
  {value: 'America/Rankin_Inlet', time: '-06:00'},
  {value: 'America/Recife', time: '-03:00'},
  {value: 'America/Regina', time: '-06:00'},
  {value: 'America/Resolute', time: '-06:00'},
  {value: 'America/Rio_Branco', time: '-05:00'},
  {value: 'America/Rosario', time: '-03:00'},
  {value: 'America/Santa_Isabel', time: '-08:00'},
  {value: 'America/Santarem', time: '-03:00'},
  {value: 'America/Santiago', time: '-03:00'},
  {value: 'America/Santo_Domingo', time: '-04:00'},
  {value: 'America/Sao_Paulo', time: '-03:00'},
  {value: 'America/Scoresbysund', time: '-01:00'},
  {value: 'America/Shiprock', time: '-07:00'},
  {value: 'America/Sitka', time: '-09:00'},
  {value: 'America/St_Barthelemy', time: '-04:00'},
  {value: 'America/St_Johns', time: '-03:30'},
  {value: 'America/St_Kitts', time: '-04:00'},
  {value: 'America/St_Lucia', time: '-04:00'},
  {value: 'America/St_Thomas', time: '-04:00'},
  {value: 'America/St_Vincent', time: '-04:00'},
  {value: 'America/Swift_Current', time: '-06:00'},
  {value: 'America/Tegucigalpa', time: '-06:00'},
  {value: 'America/Thule', time: '-04:00'},
  {value: 'America/Thunder_Bay', time: '-05:00'},
  {value: 'America/Tijuana', time: '-08:00'},
  {value: 'America/Toronto', time: '-05:00'},
  {value: 'America/Tortola', time: '-04:00'},
  {value: 'America/Vancouver', time: '-08:00'},
  {value: 'America/Virgin', time: '-04:00'},
  {value: 'America/Whitehorse', time: '-08:00'},
  {value: 'America/Winnipeg', time: '-06:00'},
  {value: 'America/Yakutat', time: '-09:00'},
  {value: 'America/Yellowknife', time: '-07:00'},
  {value: 'Antarctica/Casey', time: '+11:00'},
  {value: 'Antarctica/Davis', time: '+05:00'},
  {value: 'Antarctica/DumontDUrville', time: '+10:00'},
  {value: 'Antarctica/Macquarie', time: '+11:00'},
  {value: 'Antarctica/Mawson', time: '+05:00'},
  {value: 'Antarctica/McMurdo', time: '+12:00'},
  {value: 'Antarctica/Palmer', time: '-04:00'},
  {value: 'Antarctica/Rothera', time: '-03:00'},
  {value: 'Antarctica/South_Pole', time: '+12:00'},
  {value: 'Antarctica/Syowa', time: '+03:00'},
  {value: 'Antarctica/Troll', time: '+00:00'},
  {value: 'Antarctica/Vostok', time: '+06:00'},
  {value: 'Arctic/Longyearbyen', time: '+01:00'},
  {value: 'Asia/Aden', time: '+03:00'},
  {value: 'Asia/Almaty', time: '+06:00'},
  {value: 'Asia/Amman', time: '+02:00'},
  {value: 'Asia/Anadyr', time: '+12:00'},
  {value: 'Asia/Aqtau', time: '+05:00'},
  {value: 'Asia/Aqtobe', time: '+05:00'},
  {value: 'Asia/Ashgabat', time: '+05:00'},
  {value: 'Asia/Ashkhabad', time: '+05:00'},
  {value: 'Asia/Baghdad', time: '+03:00'},
  {value: 'Asia/Bahrain', time: '+03:00'},
  {value: 'Asia/Baku', time: '+04:00'},
  {value: 'Asia/Bangkok', time: '+07:00'},
  {value: 'Asia/Beirut', time: '+02:00'},
  {value: 'Asia/Bishkek', time: '+06:00'},
  {value: 'Asia/Brunei', time: '+08:00'},
  {value: 'Asia/Calcutta', time: '+05:30'},
  {value: 'Asia/Choibalsan', time: '+08:00'},
  {value: 'Asia/Chongqing', time: '+08:00'},
  {value: 'Asia/Chungking', time: '+08:00'},
  {value: 'Asia/Colombo', time: '+05:30'},
  {value: 'Asia/Dacca', time: '+06:00'},
  {value: 'Asia/Damascus', time: '+02:00'},
  {value: 'Asia/Dhaka', time: '+06:00'},
  {value: 'Asia/Dili', time: '+09:00'},
  {value: 'Asia/Dubai', time: '+04:00'},
  {value: 'Asia/Dushanbe', time: '+05:00'},
  {value: 'Asia/Gaza', time: '+02:00'},
  {value: 'Asia/Harbin', time: '+08:00'},
  {value: 'Asia/Hebron', time: '+02:00'},
  {value: 'Asia/Ho_Chi_Minh', time: '+07:00'},
  {value: 'Asia/Hong_Kong', time: '+08:00'},
  {value: 'Asia/Hovd', time: '+07:00'},
  {value: 'Asia/Irkutsk', time: '+08:00'},
  {value: 'Asia/Istanbul', time: '+02:00'},
  {value: 'Asia/Jakarta', time: '+07:00'},
  {value: 'Asia/Jayapura', time: '+09:00'},
  {value: 'Asia/Jerusalem', time: '+02:00'},
  {value: 'Asia/Kabul', time: '+04:30'},
  {value: 'Asia/Kamchatka', time: '+12:00'},
  {value: 'Asia/Karachi', time: '+05:00'},
  {value: 'Asia/Kashgar', time: '+08:00'},
  {value: 'Asia/Kathmandu', time: '+05:45'},
  {value: 'Asia/Katmandu', time: '+05:45'},
  {value: 'Asia/Khandyga', time: '+09:00'},
  {value: 'Asia/Kolkata', time: '+05:30'},
  {value: 'Asia/Krasnoyarsk', time: '+07:00'},
  {value: 'Asia/Kuala_Lumpur', time: '+08:00'},
  {value: 'Asia/Kuching', time: '+08:00'},
  {value: 'Asia/Kuwait', time: '+03:00'},
  {value: 'Asia/Macao', time: '+08:00'},
  {value: 'Asia/Macau', time: '+08:00'},
  {value: 'Asia/Magadan', time: '+10:00'},
  {value: 'Asia/Makassar', time: '+08:00'},
  {value: 'Asia/Manila', time: '+08:00'},
  {value: 'Asia/Muscat', time: '+04:00'},
  {value: 'Asia/Nicosia', time: '+02:00'},
  {value: 'Asia/Novokuznetsk', time: '+07:00'},
  {value: 'Asia/Novosibirsk', time: '+06:00'},
  {value: 'Asia/Omsk', time: '+06:00'},
  {value: 'Asia/Oral', time: '+05:00'},
  {value: 'Asia/Phnom_Penh', time: '+07:00'},
  {value: 'Asia/Pontianak', time: '+07:00'},
  {value: 'Asia/Pyongyang', time: '+09:00'},
  {value: 'Asia/Qatar', time: '+03:00'},
  {value: 'Asia/Qyzylorda', time: '+06:00'},
  {value: 'Asia/Rangoon', time: '+06:30'},
  {value: 'Asia/Riyadh', time: '+03:00'},
  {value: 'Asia/Saigon', time: '+07:00'},
  {value: 'Asia/Sakhalin', time: '+11:00'},
  {value: 'Asia/Samarkand', time: '+05:00'},
  {value: 'Asia/Seoul', time: '+09:00'},
  {value: 'Asia/Shanghai', time: '+08:00'},
  {value: 'Asia/Singapore', time: '+08:00'},
  {value: 'Asia/Taipei', time: '+08:00'},
  {value: 'Asia/Tashkent', time: '+05:00'},
  {value: 'Asia/Tbilisi', time: '+04:00'},
  {value: 'Asia/Tehran', time: '+03:30'},
  {value: 'Asia/Tel_Aviv', time: '+02:00'},
  {value: 'Asia/Thimbu', time: '+06:00'},
  {value: 'Asia/Thimphu', time: '+06:00'},
  {value: 'Asia/Tokyo', time: '+09:00'},
  {value: 'Asia/Ujung_Pandang', time: '+08:00'},
  {value: 'Asia/Ulaanbaatar', time: '+08:00'},
  {value: 'Asia/Ulan_Bator', time: '+08:00'},
  {value: 'Asia/Urumqi', time: '+08:00'},
  {value: 'Asia/Ust-Nera', time: '+10:00'},
  {value: 'Asia/Vientiane', time: '+07:00'},
  {value: 'Asia/Vladivostok', time: '+10:00'},
  {value: 'Asia/Yakutsk', time: '+09:00'},
  {value: 'Asia/Yekaterinburg', time: '+05:00'},
  {value: 'Asia/Yerevan', time: '+04:00'},
  {value: 'Atlantic/Azores', time: '-01:00'},
  {value: 'Atlantic/Bermuda', time: '-04:00'},
  {value: 'Atlantic/Canary', time: '+00:00'},
  {value: 'Atlantic/Cape_Verde', time: '-01:00'},
  {value: 'Atlantic/Faeroe', time: '+00:00'},
  {value: 'Atlantic/Faroe', time: '+00:00'},
  {value: 'Atlantic/Jan_Mayen', time: '+01:00'},
  {value: 'Atlantic/Madeira', time: '+00:00'},
  {value: 'Atlantic/Reykjavik', time: '+00:00'},
  {value: 'Atlantic/South_Georgia', time: '-02:00'},
  {value: 'Atlantic/St_Helena', time: '+00:00'},
  {value: 'Atlantic/Stanley', time: '-03:00'},
  {value: 'Australia/ACT', time: '+10:00'},
  {value: 'Australia/Adelaide', time: '+09:30'},
  {value: 'Australia/Brisbane', time: '+10:00'},
  {value: 'Australia/Broken_Hill', time: '+09:30'},
  {value: 'Australia/Canberra', time: '+10:00'},
  {value: 'Australia/Currie', time: '+10:00'},
  {value: 'Australia/Darwin', time: '+09:30'},
  {value: 'Australia/Eucla', time: '+08:45'},
  {value: 'Australia/Hobart', time: '+10:00'},
  {value: 'Australia/LHI', time: '+10:30'},
  {value: 'Australia/Lindeman', time: '+10:00'},
  {value: 'Australia/Lord_Howe', time: '+10:30'},
  {value: 'Australia/Melbourne', time: '+10:00'},
  {value: 'Australia/North', time: '+09:30'},
  {value: 'Australia/NSW', time: '+10:00'},
  {value: 'Australia/Perth', time: '+08:00'},
  {value: 'Australia/Queensland', time: '+10:00'},
  {value: 'Australia/South', time: '+09:30'},
  {value: 'Australia/Sydney', time: '+10:00'},
  {value: 'Australia/Tasmania', time: '+10:00'},
  {value: 'Australia/Victoria', time: '+10:00'},
  {value: 'Australia/West', time: '+08:00'},
  {value: 'Australia/Yancowinna', time: '+09:30'},
  {value: 'Brazil/Acre', time: '-05:00'},
  {value: 'Brazil/DeNoronha', time: '-02:00'},
  {value: 'Brazil/East', time: '-03:00'},
  {value: 'Brazil/West', time: '-04:00'},
  {value: 'Canada/Atlantic', time: '-04:00'},
  {value: 'Canada/Central', time: '-06:00'},
  {value: 'Canada/Eastern', time: '-05:00'},
  {value: 'Canada/East-Saskatchewan', time: '-06:00'},
  {value: 'Canada/Mountain', time: '-07:00'},
  {value: 'Canada/Newfoundland', time: '-03:30'},
  {value: 'Canada/Pacific', time: '-08:00'},
  {value: 'Canada/Saskatchewan', time: '-06:00'},
  {value: 'Canada/Yukon', time: '-08:00'},
  {value: 'Chile/Continental', time: '-03:00'},
  {value: 'Chile/EasterIsland', time: '-05:00'},
  {value: 'Cuba', time: '-05:00'},
  {value: 'Egypt', time: '+02:00'},
  {value: 'Eire', time: '+00:00'},
  {value: 'Etc/GMT', time: '+00:00'},
  {value: 'Etc/GMT+0', time: '+00:00'},
  {value: 'Etc/UCT', time: '+00:00'},
  {value: 'Etc/Universal', time: '+00:00'},
  {value: 'Etc/UTC', time: '+00:00'},
  {value: 'Etc/Zulu', time: '+00:00'},
  {value: 'Europe/Amsterdam', time: '+01:00'},
  {value: 'Europe/Andorra', time: '+01:00'},
  {value: 'Europe/Athens', time: '+02:00'},
  {value: 'Europe/Belfast', time: '+00:00'},
  {value: 'Europe/Belgrade', time: '+01:00'},
  {value: 'Europe/Berlin', time: '+01:00'},
  {value: 'Europe/Bratislava', time: '+01:00'},
  {value: 'Europe/Brussels', time: '+01:00'},
  {value: 'Europe/Bucharest', time: '+02:00'},
  {value: 'Europe/Budapest', time: '+01:00'},
  {value: 'Europe/Busingen', time: '+01:00'},
  {value: 'Europe/Chisinau', time: '+02:00'},
  {value: 'Europe/Copenhagen', time: '+01:00'},
  {value: 'Europe/Dublin', time: '+00:00'},
  {value: 'Europe/Gibraltar', time: '+01:00'},
  {value: 'Europe/Guernsey', time: '+00:00'},
  {value: 'Europe/Helsinki', time: '+02:00'},
  {value: 'Europe/Isle_of_Man', time: '+00:00'},
  {value: 'Europe/Istanbul', time: '+02:00'},
  {value: 'Europe/Jersey', time: '+00:00'},
  {value: 'Europe/Kaliningrad', time: '+02:00'},
  {value: 'Europe/Kiev', time: '+02:00'},
  {value: 'Europe/Lisbon', time: '+00:00'},
  {value: 'Europe/Ljubljana', time: '+01:00'},
  {value: 'Europe/London', time: '+00:00'},
  {value: 'Europe/Luxembourg', time: '+01:00'},
  {value: 'Europe/Madrid', time: '+01:00'},
  {value: 'Europe/Malta', time: '+01:00'},
  {value: 'Europe/Mariehamn', time: '+02:00'},
  {value: 'Europe/Minsk', time: '+03:00'},
  {value: 'Europe/Monaco', time: '+01:00'},
  {value: 'Europe/Moscow', time: '+03:00'},
  {value: 'Europe/Nicosia', time: '+02:00'},
  {value: 'Europe/Oslo', time: '+01:00'},
  {value: 'Europe/Paris', time: '+01:00'},
  {value: 'Europe/Podgorica', time: '+01:00'},
  {value: 'Europe/Prague', time: '+01:00'},
  {value: 'Europe/Riga', time: '+02:00'},
  {value: 'Europe/Rome', time: '+01:00'},
  {value: 'Europe/Samara', time: '+04:00'},
  {value: 'Europe/San_Marino', time: '+01:00'},
  {value: 'Europe/Sarajevo', time: '+01:00'},
  {value: 'Europe/Simferopol', time: '+03:00'},
  {value: 'Europe/Skopje', time: '+01:00'},
  {value: 'Europe/Sofia', time: '+02:00'},
  {value: 'Europe/Stockholm', time: '+01:00'},
  {value: 'Europe/Tallinn', time: '+02:00'},
  {value: 'Europe/Tirane', time: '+01:00'},
  {value: 'Europe/Tiraspol', time: '+02:00'},
  {value: 'Europe/Uzhgorod', time: '+02:00'},
  {value: 'Europe/Vaduz', time: '+01:00'},
  {value: 'Europe/Vatican', time: '+01:00'},
  {value: 'Europe/Vienna', time: '+01:00'},
  {value: 'Europe/Vilnius', time: '+02:00'},
  {value: 'Europe/Volgograd', time: '+03:00'},
  {value: 'Europe/Warsaw', time: '+01:00'},
  {value: 'Europe/Zagreb', time: '+01:00'},
  {value: 'Europe/Zaporozhye', time: '+02:00'},
  {value: 'Europe/Zurich', time: '+01:00'},
  {value: 'GB', time: '+00:00'},
  {value: 'GB-Eire', time: '+00:00'},
  {value: 'GMT', time: '+00:00'},
  {value: 'GMT+0', time: '+00:00'},
  {value: 'GMT0', time: '+00:00'},
  {value: 'GMT-0', time: '+00:00'},
  {value: 'Greenwich', time: '+00:00'},
  {value: 'Hongkong', time: '+08:00'},
  {value: 'Iceland', time: '+00:00'},
  {value: 'Indian/Antananarivo', time: '+03:00'},
  {value: 'Indian/Chagos', time: '+06:00'},
  {value: 'Indian/Christmas', time: '+07:00'},
  {value: 'Indian/Cocos', time: '+06:30'},
  {value: 'Indian/Comoro', time: '+03:00'},
  {value: 'Indian/Kerguelen', time: '+05:00'},
  {value: 'Indian/Mahe', time: '+04:00'},
  {value: 'Indian/Maldives', time: '+05:00'},
  {value: 'Indian/Mauritius', time: '+04:00'},
  {value: 'Indian/Mayotte', time: '+03:00'},
  {value: 'Indian/Reunion', time: '+04:00'},
  {value: 'Iran', time: '+03:30'},
  {value: 'Israel', time: '+02:00'},
  {value: 'Jamaica', time: '-05:00'},
  {value: 'Japan', time: '+09:00'},
  {value: 'Kwajalein', time: '+12:00'},
  {value: 'Libya', time: '+02:00'},
  {value: 'Mexico/BajaNorte', time: '-08:00'},
  {value: 'Mexico/BajaSur', time: '-07:00'},
  {value: 'Mexico/General', time: '-06:00'},
  {value: 'Navajo', time: '-07:00'},
  {value: 'NZ', time: '+12:00'},
  {value: 'NZ-CHAT', time: '+12:45'},
  {value: 'Pacific/Apia', time: '+13:00'},
  {value: 'Pacific/Auckland', time: '+12:00'},
  {value: 'Pacific/Chatham', time: '+12:45'},
  {value: 'Pacific/Chuuk', time: '+10:00'},
  {value: 'Pacific/Easter', time: '-06:00'},
  {value: 'Pacific/Efate', time: '+11:00'},
  {value: 'Pacific/Enderbury', time: '+13:00'},
  {value: 'Pacific/Fakaofo', time: '+13:00'},
  {value: 'Pacific/Fiji', time: '+12:00'},
  {value: 'Pacific/Funafuti', time: '+12:00'},
  {value: 'Pacific/Galapagos', time: '-06:00'},
  {value: 'Pacific/Gambier', time: '-09:00'},
  {value: 'Pacific/Guadalcanal', time: '+11:00'},
  {value: 'Pacific/Guam', time: '+10:00'},
  {value: 'Pacific/Honolulu', time: '-10:00'},
  {value: 'Pacific/Johnston', time: '-10:00'},
  {value: 'Pacific/Kiritimati', time: '+14:00'},
  {value: 'Pacific/Kosrae', time: '+11:00'},
  {value: 'Pacific/Kwajalein', time: '+12:00'},
  {value: 'Pacific/Majuro', time: '+12:00'},
  {value: 'Pacific/Marquesas', time: '-09:30'},
  {value: 'Pacific/Midway', time: '-11:00'},
  {value: 'Pacific/Nauru', time: '+12:00'},
  {value: 'Pacific/Niue', time: '-11:00'},
  {value: 'Pacific/Norfolk', time: '+11:30'},
  {value: 'Pacific/Noumea', time: '+11:00'},
  {value: 'Pacific/Pago_Pago', time: '-11:00'},
  {value: 'Pacific/Palau', time: '+09:00'},
  {value: 'Pacific/Pitcairn', time: '-08:00'},
  {value: 'Pacific/Pohnpei', time: '+11:00'},
  {value: 'Pacific/Ponape', time: '+11:00'},
  {value: 'Pacific/Port_Moresby', time: '+10:00'},
  {value: 'Pacific/Rarotonga', time: '-10:00'},
  {value: 'Pacific/Saipan', time: '+10:00'},
  {value: 'Pacific/Samoa', time: '-11:00'},
  {value: 'Pacific/Tahiti', time: '-10:00'},
  {value: 'Pacific/Tarawa', time: '+12:00'},
  {value: 'Pacific/Tongatapu', time: '+13:00'},
  {value: 'Pacific/Truk', time: '+10:00'},
  {value: 'Pacific/Wake', time: '+12:00'},
  {value: 'Pacific/Wallis', time: '+12:00'},
  {value: 'Pacific/Yap', time: '+10:00'},
  {value: 'Poland', time: '+01:00'},
  {value: 'Portugal', time: '+00:00'},
  {value: 'PRC', time: '+08:00'},
  {value: 'ROC', time: '+08:00'},
  {value: 'ROK', time: '+09:00'},
  {value: 'Singapore', time: '+08:00'},
  {value: 'Turkey', time: '+02:00'},
  {value: 'UCT', time: '+00:00'},
  {value: 'Universal', time: '+00:00'},
  {value: 'US/Alaska', time: '-09:00'},
  {value: 'US/Aleutian', time: '-10:00'},
  {value: 'US/Arizona', time: '-07:00'},
  {value: 'US/Central', time: '-06:00'},
  {value: 'US/Eastern', time: '-05:00'},
  {value: 'US/East-Indiana', time: '-05:00'},
  {value: 'US/Hawaii', time: '-10:00'},
  {value: 'US/Indiana-Starke', time: '-06:00'},
  {value: 'US/Michigan', time: '-05:00'},
  {value: 'US/Mountain', time: '-07:00'},
  {value: 'US/Pacific', time: '-08:00'},
  {value: 'US/Samoa', time: '-11:00'},
  {value: 'UTC', time: '+00:00'},
  {value: 'W-SU', time: '+03:00'},
  {value: 'Zulu', time: '+00:00'},
]

export const ASSIGNEE_TRANSLATIONS = {
  unset: translate('assignee_state_0'),
  proposed: translate('proposed'),
  accepted: translate('accepted'),
  rejected: translate('rejected'),
}

export const TICKETS_TYPES = [
  'incident',
  'incident_task',
  'request',
  'request_item',
  'request_task',
]

export const RESOLUTION_CODE_VALUES = [
  {value: 'Solved (Work around)', name: translate('solved_work_around')},
  {value: 'Solved (Permanently)', name: translate('solved_permanently')},
  {
    value: 'Solved Remotely (Work around)',
    name: translate('solved_remotely_work_around'),
  },
  {
    value: 'Solved Remotely (Permanently)',
    name: translate('solved_remotely_permanently'),
  },
  {
    value: 'Not Solved (Not reproducible)',
    name: translate('not_solved_not_rep'),
  },
  {
    value: 'Not Solved (Too costly)',
    name: translate('not_solved_too_costly'),
  },
  {
    value: 'Closed/Resolved by Caller',
    name: translate('closed_by_caller'),
  },
]

export const INCIDENT_TASK_STATES = [
  translate('new'),
  translate('in_progress'),
  translate('closed_completed'),
  translate('closed_incomplete'),
  translate('closed_skipped'),
]

export const LOCAL_STORAGE_BLITS_ACCESS_TOKEN_KEY = 'blits_access_token'

export const SCREEN_SIZES = {
  FULL: 1024,
}

export const superUserId = 'a00a1f64-01a1-4153-b22e-35cf7026ba7e'

export const exportType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const orgInvitePath = globalRoutes.general.acceptOrgInvite.path()
export const userInvitePaths = [
  globalRoutes.general.acceptInvitationITSMstepper.path(),
  globalRoutes.general.acceptInvitationITSM.path(),
]
export const pathsWithoutGetProfile = [
  globalRoutes.general.accountDefault.path(),
  orgInvitePath,
  ...userInvitePaths,
]
