import request from 'superagent'

import {getBackendTokenHeaders} from '@/services/api'
import {accountApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {
  TCreateAccountPayload,
  TCreateAccountRes,
} from '@/components/accountForm/types'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {THeaders} from '@/api/typedf'

export const postAccountTs = async ({
  payload,
  headers,
}: {
  payload: TCreateAccountPayload
  headers?: THeaders
}): Promise<ISuperAgentResponse<TCreateAccountRes>> => {
  return await request
    .post(accountApi().create)
    .set(headers ? headers : getBackendTokenHeaders())
    .send(payload)
}

export const postAccountTsWithHeaders = async ({
  payload,
  headers,
}: {
  payload: TCreateAccountPayload
  headers: Record<string, any>
}): Promise<ISuperAgentResponse<TCreateAccountRes>> => {
  return await request
    .post(accountApi().create)
    .set(headers)
    .send(payload)
}
