import React from 'react'

import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {TDocument} from '@/api/typedf'
import {Button} from '@/components/button/button'
import {translate} from '@/services/i18n'

export const DocumentsRowValue = ({
  title,
  value,
  record,
  downloadDocument,
}: TColumnRender<TDocument> & {
  downloadDocument: (id: string, name: string) => void
}) => {
  switch (title) {
    case 'actions': {
      const extension = record?.name.split('.')
      extension?.pop()
      const name = extension?.join('.') || ''
      return (
        <Button
          title={translate('download')}
          onClick={() => record && downloadDocument(record.id, name)}
          className="btn-download btn-sm"
        />
      )
    }
    default:
      return <div>{value}</div>
  }
}
