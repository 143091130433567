import React, {useState} from 'react'

import {InfoCircleOutlined} from '@ant-design/icons'
import {Popover} from 'antd'

import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {RESOLVE} from '@/constants'
import {getUserFullName} from '@/utils/get-user-full-name'
import {fetchUsers} from '@/modules/ITSM/api/userRequests'
import {TUser} from '@/modules/ITSM/typedef'
import {userApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import UserDetails from '../user-details/user-details'

type TProps = {
  callerInit?: Partial<TUser>
  callerValue: string
  setRefresh: () => void
  isDisabled?: boolean
  onChange?: (val: string, user?: TUser) => void
}

const CallerSelect = ({
  callerInit,
  callerValue,
  setRefresh,
  isDisabled,
  onChange,
}: TProps) => {
  const [newCaller, setNewCaller] = useState<
    Record<string, unknown> | undefined
  >()

  let initialValueNew: Record<string, unknown>[] = []

  if (callerInit) initialValueNew = [callerInit]

  const getCaller = async () => {
    const {
      body: {result},
    } = await fetchUsers({
      selector: {uuid: callerValue},
    })

    if (result) setNewCaller(result[0])
  }

  const handleDisplayInfo = () => {
    if (callerValue !== callerInit?.uuid) getCaller()
    else setNewCaller(callerInit)
  }

  return (
    <div id="caller-select">
      <Popover
        open={!!newCaller}
        content={<UserDetails userDetails={newCaller} />}
        trigger="click"
        placement="bottom"
        getPopupContainer={() =>
          document.getElementById('caller-select') as HTMLElement
        }
        onOpenChange={open => {
          !open && setNewCaller(undefined)
        }}
      >
        {/* @ts-ignore not refactored to typescript */}
        <SelectWithBM
          onFocusAction={() => {
            setNewCaller(undefined)
          }}
          onChange={onChange}
          api={userApi().get + RESOLVE}
          label={
            <span>
              {translate('caller')}
              {callerValue && (
                <InfoCircleOutlined
                  className="ml-5"
                  onClick={handleDisplayInfo}
                  type="info-circle"
                />
              )}
            </span>
          }
          name="caller"
          initialValue={initialValueNew}
          rules={{
            required: true,
            message: translate('caller_req'),
          }}
          searchKey="full_name"
          optionContent={getUserFullName}
          onSelect={setRefresh}
          disabled={isDisabled}
          blockOnFocus={true}
          fetchOnOpen={true}
        />
      </Popover>
    </div>
  )
}

export default CallerSelect
