import {Entities} from '@/typedef'

const ENTITIES_PATHS = [
  Entities.INCIDENT,
  Entities.K_REQUEST,
  Entities.REQUEST,
  Entities.BILLING_REPORT,
  Entities.INCIDENT_TASK,
  Entities.REQUEST_TASK,
  Entities.REQUEST_ITEM,
]
export const checkRedirectToListEntity = (pathname: string): boolean =>
  ENTITIES_PATHS.map(path => `${path.replace('_', '-')}`).includes(
    pathname.split('/')[3]
  )

type TGetOrganizationName = (
  organizations: Array<{id: string; name: string}>,
  orgId: string
) => string | undefined

export const getOrganizationName: TGetOrganizationName = (
  organizations,
  orgId
) => {
  const {name} = organizations?.find(({id}) => id === orgId) || {}
  return name
}

type TGetChannelName = (
  channels: Array<{space: string; name: string}>,
  channelId: string
) => string | undefined

export const getChannelName: TGetChannelName = (channels, channelId) => {
  const {name} = channels.find(({space}) => space === channelId) || {}
  return name
}
