import {Moment} from 'moment'
import {Rule} from 'antd/lib/form'

import {
  AppVersion,
  Entities,
  TPricingPolicy,
  TUser as TUserGeneral,
} from '@/typedef'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import {AssigneeStates} from '@/modules/ITSM/components/incident-k-request/constants'
import {TKey} from '@/services/i18n'
import {TRequestsQuickFilters} from '@/modules/ITSM/sites/request-user-portal/constants'
import {TIncident} from '@/modules/ITSM/components/incident-k-request/typedf'
import {TFilterListTable} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {GraphTypes} from '@/modules/ITSM/api/dashboardRequests'

import {TIncidentsQuickFiltersUP} from './sites/incident-user-portal/constants'
import {TIncidentsQuickFilters} from './sites/Incident/constants'
import {ExpensesQuickFilters} from './sites/Expenses/typedef'
import {BillingItemsQuickFilters} from './sites/BillingReports/billing-items/constants'
import {EventsQuickFilters} from './sites/Events/constants'

export type TFetchCommentsData = {
  bookmark?: string
  uuid?: string
}

export type TReadBy = {
  time: string
  user: string
}

export const View = {
  TABLE: 'TABLE',
  TILE: 'TILE',
}

export type TComment = {
  created_at: string
  created_by: TUser
  docType: Entities
  read_by: Array<TReadBy>
  text: string
  uuid: string
  file_name?: string
  file_type?: string
  data?: string
  xxx_version: number
}

export type TAttachmentData = {
  data: Blob | MediaSource
  file_type: string
  file_name: string
}

export type TTimespans = {
  end: string
  start: string
  type: string
}

export type TTimeLog = {
  cmdb_updated: boolean
  created_at: string
  created_by: TUser
  end: string
  entity: string
  ignore_extra_pricing_policy?: boolean
  is_cancelled: boolean
  remote: boolean
  start: string
  timezone: string
  travel: number
  break: number
  travel_back: number
  travel_distance_units: number
  uuid: string
  visit_summary: string
  work: number
  timespans: TTimespans[]
  customer_product: TCustomerProduct
  location: TLocation
}

export type TTimelogForm = {
  ignore_extra_pricing_policy: string
  cmdb_updated: boolean
  actions_performed_onsite: string
  unexpected_issues_on_field_engineer_visit: string
}
export type TTimelogReq = {
  timelog: string
  cmdb_updated: boolean
  ignore_extra_pricing_policy?: boolean
  visit_summary: string
}

export enum TProduct {
  HE = 'he',
  SW = 'sw',
  SERVICES = 'services',
}

export type TWeekdays = {
  support_from?: string | Moment
  support_to?: string | Moment
}
export type TBankHolidays = {
  support_from?: string | Moment | null
  support_to?: string | Moment | null
}

export enum Weeks {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export type TTicket = {
  assigned_to?: string
  assignee_state?: AssigneeStates
  attachments?: Array<string>
  caller: string
  category: string
  comments?: Array<string>
  contact_type: TKey
  created_at: string
  created_by: string
  customer_product?: string
  description?: string
  docType: Entities
  impact: number
  location?: string
  made_sla: boolean
  number: string
  priority: number
  reassignment_count: number
  reopen_count: number
  resolution_time_due?: string
  resolution_time_start?: string
  response_time_due?: string
  response_time_start?: string
  short_description?: string
  state_id?: number
  subcategory: string
  timelogs?: Array<string>
  updated_at?: string
  updated_by?: string
  urgency: number
  uuid?: string
  virtual_resolution_time_start?: string
  virtual_response_time_start?: string
  watch_list?: Array<string>
  work_notes?: Array<string>
  xxx_version: number
}

export type TCustomerProduct = {
  uuid?: string
  name: string
  code: string
  dispatch: boolean
  pricing_policy?: TPricingPolicy
  product_type: TProduct
  resolution_time_eval: string
  chargeable: boolean
  holidays: string[]
  product_group?: string
  active_from: string
  active_to: string
  pricing_type: string
  location?: TLocation
  sla_type: string
  sla: {
    inactive_timeout: number
    max_resolution_time: number
    max_resolution_time_business_days: number
    max_response_time: number
    max_response_time_business_days: number
    timezone: string
    safe_time_interval: number
    weekdays: {[key in string]: TWeekdays}
    bank_holidays?: TWeekdays
  }
}

export type TSupplierProduct = {
  uuid?: string
  name: string
  active_from?: string
  active_to?: string
  code: string
  docType?: string
  location: TLocation
  org_display_name: string
  org_name?: string
  pricing_policy: {
    rate: number
    primary_hour_rate: number
    currency: string
    pricing_unit: number
    additional_hour_rate: number
    additional_pricing_unit: number
    primary_hours_number: number
    travel_fix_rate: number
    travel_hour_rate: number
    travel_pricing_unit: number
    travel_distance_unit_rate: number
  }
  pricing_type: string
  sla_type: string
}

export type TAssignmentGroup = {
  uuid: string
  name: string
  org_name: string
  created_at: string
  created_by: TUser
}

export type TUser = TUserGeneral & {app_version: AppVersion}

export type TUserGroup = {
  uuid: string
  name: string
  created_at: string
  created_by: TUser
}

export type TCategory = {
  uuid: string
  name: string
  subcat: TCategory[]
  parent: TCategory[]
}
export type TSubCategory = {
  uuid: string
  name: string
  created_at: string
  created_by: TUser
}
export type TLocation = {
  uuid?: string
  name?: string
  street?: string
  city?: string
  zip?: string
  full_location?: string
  state_province?: string
  country?: string
  latitude?: number
  longitude?: number
  time_zone?: string
  phone?: string
  fax_phone?: string
}

export type TCustomerLocationRecord = {
  record?: TLocation
  isVisible?: boolean
  onClose?: () => void
  onSave?: (params: TLocation) => void
  formRules?: {[key: string]: Rule[]}
  formTitle?: string
  withoutSave?: boolean
  preSaveAction?: () => void
  asyncF: (
    params: TLocation,
    id?: string
  ) => Promise<ISuperAgentResSingle<TLocation>>
}

export type TRejectReason = {
  uuid: string
  name: string
  created_at: string
  created_by: TUser
}

export type TNotification = {
  uuid: string
  name: string
  created_at: string
  created_by: TUser
  external_emails: string[]
  updated_at: string
  updated_by: TUser
  user_group: TUserGroup
}

export type TAnnouncement = {
  uuid: string
  title: string
  active_from: string
  active_to: string
  body: string
  created_at: string
  created_by: TUser
  org_role: string
  updated_at: string
  updated_by: TUser
}

export type TAssignmentRule = {
  ticket_type: string
  uuid: string
  name: string
  assignee: TUser
  assignment_group: TAssignmentGroup
  category: TCategory
  created_at: string
  created_by: TUser
  location: TLocation
  subcategory: TCategory
}

export type TITSMSelectorNoBC = {
  entity?: string
  limit?: number
  bookmark?: string
  resetBookmarks?: boolean
}

export type TMonth = {
  eurCustomer?: number
  eurSupplier?: number
  name?: string
  year?: string
}

export type TRole = {
  uuid: string
  name: string
}

export enum ItsmAssets {
  AssignmentGroups = 'assignment_groups',
  Roles = 'roles',
  Identities = 'identities',
  Users = 'users',
  IncidentsUP = 'incidents_up',
  RequestsUP = 'requests_up',
  Suppliers = 'suppliers',
  RejectReasons = 'reject_reasons',
  UserGroups = 'user_groups',
  AssignmentRules = 'assignment_rules',
  Announcements = 'announcements',
  SupplierProducts = 'supplier_products',
  CustomerProducts = 'customer_products',
  Notifications = 'notifications',
  Categories = 'categories',
  Incidents = 'incidents',
  Requests = 'requests',
  TimeLogs = 'time_logs',
  TimelogExpenses = 'timelog_expenses',
  SVR = 'SVR',
  TimeLogsResolve = 'time_logs_resolve',
  IncidentExpenses = 'incident_expenses',
  Expenses = 'expenses',
  IncidentTasks = 'incident_tasks',
  Credits = 'credits',
  BillingItems = 'billing_items',
  Invitations = 'invitations',
  Locations = 'locations',
  Events = 'events',
  Documents = 'documents',
}

export type TUserDefaults = {
  email: string
  notification: boolean
  unavailable: boolean
  app_version: string
  assignment_groups: string[]
  company: string
  contract_type: string
  type: string
  email_signature: ' '
  name: string
  phone: string
  surname: string
  timezone: string
  user_groups: string[]
  pricing_policy: {
    currency: string
    rate: number
    pricing_unit: number
    primary_hours_number: number
    primary_hour_rate: number
    additional_pricing_unit: number
    travel_fix_rate: number
    travel_distance_unit_rate: number
    travel_pricing_unit: number
  }
}

export type TIdentity = {
  users: TUser[]
  fingerprint: string
}

export type TQuickFilters =
  | TIncidentsQuickFilters
  | TRequestsQuickFilters
  | TIncidentsQuickFiltersUP
  | ExpensesQuickFilters
  | BillingItemsQuickFilters
  | EventsQuickFilters
  | AnnouncenentsQuickFilters

export type TBillingSum = {
  EUR: {expense: string; travel_time: number; work_time: number; amount: number}
  USD: {expense: string; travel_time: number; work_time: number; amount: number}
}

export type TInvoiceBr = {
  org_display_name: string
  org_name: string
  ref_uuid: string
}

export enum Reasons {
  std_deviation_work_time = 'std_deviation_work_time',
  std_deviation_price = 'std_deviation_price',
  std_deviation_cost = 'std_deviation_cost',
  median_absolute_deviation_work_time = 'median_absolute_deviation_work_time',
  median_absolute_deviation_price = 'median_absolute_deviation_price',
  median_absolute_deviation_cost = 'median_absolute_deviation_cost',
  neg_price_spread = 'neg_price_spread',
}

export type TAnomaly = {
  invalid?: boolean
  reasons: Array<Reasons>
  uuid: string
  updated_at?: string
  updated_by?: string
}

export type TInvitation = {
  uuid?: string
  email: string
  roles: {value: string}
  state: number
  orgRole: number
  invitorName: string
  invitorOrg: {
    display_name: string
  }
  type: string
  ccInviteId: {value: string}
  redirectUrl: string
  signingPath: string
  channelInviteId: string
}

export type TInviteResponse = {Success: string[] | null; Fail: string[] | null}

export type TBillingReport = {
  anomalies: Array<TAnomaly>
  billing_items: Array<string>
  billing_items_software: Array<string>
  comments: Array<string>
  created_at: string
  created_by: string
  currency_rates: {USDEUR: number}
  customer_sums: {currency_sums: TBillingSum}
  docType: Entities
  interval_end: string
  interval_start: string
  ref_no: string
  stats: {
    median_work_time: number
    avg_work_time: number
    max_work_time: number
    min_work_time: number
    std_deviation_work_time: number
    mad_work_time: number
    median_price: number
    avg_price: number
    max_price: number
    min_price: number
    std_deviation_price: number
    mad_price: number
    median_cost: number
    avg_cost: number
    max_cost: number
    min_cost: number
    std_deviation_cost: number
    mad_cost: number
  }
  supplier_sums: {
    [key in string]: {
      currency_sums: TBillingSum
    }
  }
  updated_at: string
  updated_by: string
  uuid?: string
  supplier_invoice_refs?: Array<TInvoiceBr>
  xxx_version: number
}

export type TBillingReportsList = Array<TBillingReport>

export type TCustomer = {
  currency: string
  docType: string
  expenses: string
  product_name: string
  product_uuid: string
  rate: string
  total_price: string
  travel_distance_price: string
  travel_fix_price: string
  travel_hourly_price: string
  travel_price: string
  uuid: string
  work_price_details: Array<{
    duration_in_s?: number
    multiplier?: string
    primary_hours?: boolean
    rate?: string
    start: string
    end?: string
    timelog_uuid?: string
    total?: string
    type?: string
    work?: string
  }>
  work_time_additional_hours_rate: string
  work_time_price: string
  work_time_primary_hours_rate: string
  xxx_version: number
}

type TWorkPriceCostDetails = {
  duration_in_s: number
  start: string
  end: string
  total: string
  work: string
}

export type TBillingItem = {
  billing_report?: TBillingReport
  chargeable: true
  created_from: string
  customer: TCustomer
  docType: Entities
  invoiceable: boolean
  location: TLocation
  remote: boolean
  supplier: {
    currency: string
    docType: Entities
    expenses: string
    product_name: string
    product_uuid: string
    supplier_id: string
    supplier_name: string
    total_cost: string
    travel_distance_cost: string
    travel_fix_cost: string
    travel_hourly_cost: string
    uuid: string
    work_time_cost: string
    work_cost_details?: Array<TWorkPriceCostDetails>
    xxx_version: number
  }
  supplier_org_name: string
  ticket: TTicket
  timezone: string
  travel_distance_units: number
  travel_time: number
  user: TUser
  uuid?: string
  visit_summary: string
  work_end: string
  work_start: string
  work_time: string
  xxx_version: number
}

export type TBillingItems = Array<TBillingItem>

export enum ActionType {
  DOWNLOAD = 'download',
  PREVIEW = 'preview',
}

export type TTicketByState = {
  [key in
    | 'ALL'
    | 'CANCELLED'
    | 'CLOSED'
    | 'IN PROGRESS'
    | 'NEW'
    | 'ON HOLD'
    | 'PRE ON HOLD'
    | 'RESOLVED']: number
}

export type TIncidentsStats = {
  incidentsAverageResolutionTime: {
    average: number
  }
  incidentsAverageResolutionTimeByPriority: Record<string, number>
  incidentsByPriority: Record<string, number>
  incidentsByState: TTicketByState
  incidentsCriticalNewPercentage: {
    percentage: number
  }
  incidentsNewByCategory: Record<string, number>
  incidentsNewByPriority: Record<string, number>
  incidentsOpenAssignedToMe: {
    count: number
  }
  incidentsOpenAverageAgeByPriority: Record<string, number>
  incidentsOpenByAge: Record<string, number>
  incidentsOpenByPriority: Record<string, number>
  incidentsOpenOlderThanWeek: {
    count: number
  }
  incidentsResolvedByMe: {
    count: number
  }
  incidentsResolvedByPriority: Record<string, number>
  incidentsTotalCountDateHistogram: {
    histogram: Record<string, number>
  }
}

export type TRequestsStats = {
  requestsAverageResolutionTime: {
    average: number
  }
  requestsAverageResolutionTimeByPriority: Record<string, number>
  requestsByPriority: Record<string, number>
  requestsByState: TTicketByState
  requestsCriticalNewPercentage: {
    percentage: number
  }
  requestsNewByCategory: Record<string, number>
  requestsNewByPriority: Record<string, number>
  requestsOpenAssignedToMe: {
    count: number
  }
  requestsOpenAverageAgeByPriority: Record<string, number>
  requestsOpenByAge: Record<string, number>
  requestsOpenByPriority: Record<string, number>
  requestsOpenOlderThanWeek: {
    count: number
  }
  requestsResolvedByMe: {
    count: number
  }
  requestsResolvedByPriority: Record<string, number>
  requestsTotalCountDateHistogram: {
    histogram: Record<string, number>
  }
}

export type TAssetQuickFilters = Array<{
  name: TQuickFilters
  query: TFilterListTable<TIncident>
}>

export type TItsmChannel = {
  space: string
  name: string
  active: boolean
  state: string
  created_at: string
  org_name: string
}

type TChannelBillingReport = {
  approval_needed: boolean
}

type TCurrencyRates = {
  USDEUR: number
}

type TExpenses = {
  approval_needed: boolean
}

type TKRequest = {
  approval_needed: boolean
}

type TOwner = {
  acc: string
  org: string
  orgName: string
  sub: string
}

type TPaymentMode = {
  credit: boolean
}

type TProviderMode = {
  strict: boolean
}

type TAppVersion = {
  customer: string
  provider: string
  supplier: string
}

type TVersionUserDefaults = {
  app_version: TAppVersion
}

type TValue = {
  billing_report: TChannelBillingReport
  billing_report_only_closed_tickets: boolean
  channel_name: string
  channel_type: string
  cmdb_space_id: string
  created_at: Date
  currency_rates: TCurrencyRates
  customer_id: string
  customer_single_user_mode: boolean
  expenses: TExpenses
  k_request: TKRequest
  owner: TOwner
  payment_mode: TPaymentMode
  provider_mode: TProviderMode
  saas_space_id: string
  support_contacts: string[]
  ticket_cancellation_until: string
  user_defaults: TVersionUserDefaults
}

export type TResPatchChannel = {
  name: string
  value: TValue
  version: number
}

export type TUserAsset = {
  name: string
  id: string
}

export enum AnnouncenentsQuickFilters {
  Active = 'active',
  All = 'all',
  None = 'none',
}

export enum Granularity {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type TGraphReportPayload = {
  date_start: string
  date_end: string
  granularity: Granularity
  data_types: Array<GraphTypes>
  location?: string
  assignment_group?: string
}

export enum ChangeStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export enum PriorityStatus {
  LOW = '1',
  MEDIUM = '2',
  HIGH = '3',
  CRITICAL = '4',
}

export type TChangeStatus = Record<
  string,
  | {
      [ChangeStatus.CANCELLED]: number
      [ChangeStatus.CREATED]: number
      [ChangeStatus.RESOLVED]: number
      [ChangeStatus.CLOSED]: number
    }
  | number
>

export type TPriorityStatus = Record<
  string,
  | {
      1: number
      2: number
      3: number
      4: number
    }
  | number
>

export type TResultResponse<T> = {
  result: T | null
}

export type TIncidentsGraphReport = {
  incidentsByStateChange: TChangeStatus
  incidentsClosedByPriority: TPriorityStatus
  incidentsCreatedByPriority: TPriorityStatus
  incidentsResolvedByPriority: TPriorityStatus
}
export type TRequestGraphReport = {
  k_requestsByStateChange: TChangeStatus
  k_requestsClosedByPriority: TPriorityStatus
  k_requestsCreatedByPriority: TPriorityStatus
  k_requestsResolvedByPriority: TPriorityStatus
}
export type TChannelResponse = {
  result: string
  channel_id: string
  channel_name: string
}
