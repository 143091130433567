import {TIncident} from '@/modules/ITSM/components/incident-k-request/typedf'
import {TDetailsCard} from '@/modules/ITSM/components/incident-k-request/incident-detail-mobile/details-card/details-card'
import {TUser} from '@/modules/ITSM/typedef'
import {translate} from '@/services/i18n'

export const getDetailsData = (entityData: TIncident) => {
  const {
    created_by,
    category,
    subcategory,
    location,
    created_at,
    short_description,
    supplier_product,
    customer_product,
    assignment_group,
    assigned_to,
  } = entityData

  const caller = (entityData.caller as any) as TUser

  const mainDetails: TDetailsCard = {
    header: translate('details'),
    items: [
      {
        label: translate('short_description'),
        value: short_description || '-',
        fullWidth: true,
      },
      {
        label: translate('category'),
        value: category?.name || '-',
      },
      {
        label: translate('subcategory'),
        value: subcategory?.name || '-',
      },
      {
        label: translate('assigned_to_id'),
        value: assigned_to?.full_name || '-',
      },
      {
        label: translate('location_id'),
        value: location?.name || '-',
      },
    ],
  }

  const origin: TDetailsCard = {
    header: translate('origin'),
    items: [
      {
        label: translate('createdAt'),
        value: created_at || '-',
        fullWidth: true,
      },
      {
        label: translate('created_by_id'),
        value: created_by?.full_name || '-',
      },
      {
        label: translate('caller'),
        value: caller.full_name || '-',
      },
    ],
  }

  const additionalInfo: TDetailsCard = {
    header: 'Additional Info',
    items: [
      {
        label: translate('customerProduct'),
        value: customer_product?.name || '-',
        fullWidth: true,
      },
      {
        label: translate('supplierProduct'),
        value: supplier_product?.name || '-',
        fullWidth: true,
      },
      {
        label: translate('assignmentGroup'),
        value: assignment_group?.name || '-',
        fullWidth: true,
      },
    ],
  }

  return [mainDetails, origin, additionalInfo]
}
