import {TTimeSession} from '@/modules/ITSM/sites/time-session/typedef'

import {ActionType} from './typedef'

export const setTimeSessionData = (data: TTimeSession) => ({
  type: ActionType.SET_TIME_SESSION,
  payload: data,
})

export const clearTimeSessionData = () => ({
  type: ActionType.CLEAR_TIME_SESSION,
})

export const setTicketType = (type: string) => ({
  type: ActionType.SET_TICKET_TYPE,
  payload: type,
})
