import React from 'react'

import moment from 'moment'
import parse from 'html-react-parser'

import {TColumnRender} from '@/modules/ITSM/components/itsm-list-table/typedf'
import {DATE_FORMAT} from '@/constants'
import {PRIORITY_OPTIONS} from '@/modules/ITSM/components/incident/constants'
import {INCIDENT_STATE_OPTIONS} from '@/modules/ITSM/components/incident-k-request/constants'
import {INCIDENT_TASK_STATE_OPTIONS} from '@/modules/ITSM/components/incident-task/constants'
import {translate} from '@/services/i18n'
import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import {TBillingItem} from '@/modules/ITSM/typedef'
import {Entities} from '@/typedef'

import {parseToLocaleString} from '../utils/parse-to-local-string'

const formatDate = (date: string) => moment(date).format(DATE_FORMAT)

const getBooleanValue = (value: boolean) =>
  value ? (
    <span>{parseToUpperCase(translate('true'))}</span>
  ) : (
    <span>{parseToUpperCase(translate('false'))}</span>
  )

export const BillingItemsRowValue = ({
  title,
  value,
  record,
}: TColumnRender<TBillingItem>) => {
  switch (title) {
    case 'date':
      return <div>{value ? formatDate(value.toString()) : ''}</div>

    case 'billing_response_sla_expiry':
      return <div>{value ? formatDate(value.toString()) : ''}</div>

    case 'billing_fix_sla_expiry':
      return <div>{value ? formatDate(value.toString()) : ''}</div>

    case 'billing_resolved_at':
      return <div>{value ? formatDate(value.toString()) : ''}</div>

    case 'work_start':
      return <div>{value ? formatDate(value.toString()) : ''}</div>

    case 'work_end':
      return <div>{value ? formatDate(value.toString()) : ''}</div>

    case 'billing_total_cost':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_total_price':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'travelPrice':
      return <div>{parseToLocaleString(Number(value))}</div>
    case 'billing_travel_hourly_cost':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_travel_hourly_price':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_travel_fix_cost':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_travel_fix_price':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_travel_distance_cost':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_travel_distance_price':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_work_time_cost':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'billing_work_time_price':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'supplier_expenses':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'customer_expenses':
      return <div>{parseToLocaleString(Number(value))}</div>

    case 'closure_notes':
      return <div>{value && parse(value.toString())}</div>

    case 'chargeable':
      return getBooleanValue(!!value)

    case 'remote':
      return getBooleanValue(!!value)

    case 'billing_made_sla':
      return getBooleanValue(!!value)

    case 'priority':
      return (
        <div>{(PRIORITY_OPTIONS as Record<number, string>)[Number(value)]}</div>
      )

    case 'status': {
      if (
        record?.ticket.docType === Entities.INCIDENT ||
        record?.ticket.docType === Entities.K_REQUEST
      )
        return <div>{INCIDENT_STATE_OPTIONS[Number(value)].name}</div>
      if (record?.ticket.docType === Entities.INCIDENT_TASK)
        return <div>{INCIDENT_TASK_STATE_OPTIONS[Number(value)].name}</div>
      return <div>{value}</div>
    }
    default:
      return <div>{value}</div>
  }
}
