import React from 'react'

import {Tabs as AntTabs} from 'antd'
import {TabsProps} from 'antd/lib/tabs'
import classNames from 'classnames'

import './tabs.scss'

export const Tabs: React.FC<TabsProps> = ({className, ...props}) => {
  const classes = classNames('tabs', className)

  return <AntTabs className={classes} {...props} />
}
