import React, {useEffect} from 'react'

import {Editor} from 'react-draft-wysiwyg'
import {Col, Form, Row, Tooltip} from 'antd'
import {withErrorBoundary} from '@sentry/react'

import {Button} from '@/components/button'
import {EditorWrapper} from '@/components/editor'
import {translate} from '@/services/i18n'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import ErrorPage from '@/components/error/error-page/error-page'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import TimeLogCheckout from '../time-logs/time-log-checkout'
import {draftToolbarOptions} from '../../utils/Constants'
import {TTimeLog} from '../../typedef'
import {useStateContext} from '../incident/incident-context'
import {setIncidentTabIsBlocked} from '../incident/incident-reducer'

import {IncidentTaskState, TIncidentTask} from './typedf'
import {checkIfDisabled} from './utils/check-if-disabled'

type TFormValues = Pick<TIncidentTask, 'resolution_notes'>

const ResolutionForm = () => {
  const [form] = Form.useForm()

  const {
    entityData,
    updateEntity,
    getData,
    isLoading,
    dispatch,
  } = useStateContext<TIncidentTask>()

  const {state_id, resolution_notes, timelogs} = entityData || {}

  const initialValuesState = !!(state_id && checkIfDisabled(state_id))

  const {
    editorState,
    onEditorStateChange,
    editorText,
    checkContent,
  } = useSetEditorState(initialValuesState ? resolution_notes : '')

  const timelogOpened =
    timelogs && timelogs.filter((timelog: TTimeLog) => !timelog.end)

  const handleSubmit = async () => {
    const result = await updateEntity({
      resolution_notes: editorText,
      state_id: IncidentTaskState.Closed_complete,
    })
    dispatch(setIncidentTabIsBlocked(false))

    if (result !== undefined) await getData(true)
  }

  const isTimeLogOpened = timelogOpened && timelogOpened.length > 0

  useEffect(() => {
    !initialValuesState && dispatch(setIncidentTabIsBlocked(!!editorText))
  }, [dispatch, editorText, initialValuesState])

  return (
    <Row>
      <Col span={24}>
        {isTimeLogOpened && <TimeLogCheckout timelogOpened={timelogOpened} />}

        <Form
          layout="vertical"
          form={form}
          validateMessages={{
            // eslint-disable-next-line
            required: '${label} is required',
          }}
          onFinish={handleSubmit}
        >
          <FormItemGeneric<TFormValues>
            name="resolution_notes"
            label={translate('resolution_notes')}
            rules={[
              {
                required: true,
                validator: () =>
                  checkContent(translate('resolution_notes_req')),
              },
            ]}
          >
            <EditorWrapper>
              <Editor
                toolbar={draftToolbarOptions}
                editorState={editorState}
                wrapperClassName="wysiwyg-editor wysiwyg-editor--mb-0"
                onEditorStateChange={onEditorStateChange}
                readOnly={initialValuesState}
              />
            </EditorWrapper>
          </FormItemGeneric>
          {!initialValuesState && (
            <Tooltip
              placement="top"
              title={
                isTimeLogOpened ? translate('timelog_must_be_closed') : null
              }
            >
              <Button
                title={translate('resolve')}
                type="primary"
                htmlType="submit"
                disabled={isLoading || isTimeLogOpened}
                size="large"
              />
            </Tooltip>
          )}
        </Form>
      </Col>
    </Row>
  )
}

export default withErrorBoundary(ResolutionForm, {
  fallback: ({resetError}) => <ErrorPage resolvers={[resetError]} />,
})
