import React from 'react'

import {UploadOutlined} from '@ant-design/icons'
import {UploadProps} from 'antd/lib/upload/interface'

import {Upload} from '@/components/upload'
import {Button} from '@/components/button'

type TProps = {
  uploadProps: UploadProps
  text: string
  multiple?: boolean
}

const FILE_EXTENSIONS =
  '.doc,.docx,.txt, .pdf, .xls, .xlsx,.jpg, .jpeg, .png, .svg, .zip, .eml, .msg, .csv, .html, .htm'

export const UploadButton: React.FC<TProps> = (props: TProps) => {
  const {uploadProps, text, multiple = true} = props

  return (
    <Upload multiple={multiple} accept={FILE_EXTENSIONS} {...uploadProps}>
      <Button title={text} icon={<UploadOutlined />} />
    </Upload>
  )
}
