import {RequestStatus} from '@/typedef'
import {TSaasSpace} from '../../typedf'

import {TActionTypes, TAnyAction} from './actionTypes'

type TInitialState = {
  saasUserId: string
  saasUserNameInChannel: string
  saasNamespaces: Array<TSaasSpace>
  saasUserRole: string
  reqSaasIdentityMeStatus: RequestStatus
}

const initialState: TInitialState = {
  saasUserId: '',
  saasUserNameInChannel: '',
  saasNamespaces: [],
  saasUserRole: '',
  reqSaasIdentityMeStatus: RequestStatus.INITIAL,
}

export default function RESET_SAAS_USER_STATE(
  state = initialState,
  action: TAnyAction
): TInitialState {
  switch (action.type) {
    case TActionTypes.RESET_SAAS_USER_STATE: {
      return {
        ...state,
        ...initialState,
      }
    }
    case TActionTypes.FETCH_SAAS_IDENTITY_ME_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case TActionTypes.FETCH_SAAS_USER_FAILED: {
      return {
        ...state,
        saasUserId: '',
        saasUserNameInChannel: '',
        saasNamespaces: [],
        saasUserRole: '',
        reqSaasIdentityMeStatus: RequestStatus.FAILED,
      }
    }

    case TActionTypes.ADD_SAAS_NAMESPACE: {
      return {
        ...state,
        saasNamespaces: [...state.saasNamespaces, action.payload],
      }
    }

    default:
      return state
  }
}
