import React from 'react'

import {ImportanceIndicator} from '@/components/importance-indicator/importance-indicator'

import {TColumnRender} from '../typedf'
import ProgressBar from '../../incident-k-request/incident-request-progress-bar/incident-request-progress-bar'
import {IncidentState} from '../../incident-k-request/constants'

import ItsmListTableStateLabel from './itsm-list-table-state-label/itsm-list-table-state-label'

export const IncidentsRequestdRowValue = <T extends Record<string, any>>({
  title,
  value,
  record,
}: TColumnRender<T>) => {
  switch (title) {
    case 'priority': {
      return (
        <ImportanceIndicator
          importance={Number(value)}
          indicatorText={`P${value}`}
        />
      )
    }

    case 'state_id': {
      if (
        record?.state_id === IncidentState.Closed ||
        record?.state_id === IncidentState.Cancelled
      ) {
        return <ItsmListTableStateLabel state_id={record?.state_id} />
      }

      if (record) return <ProgressBar<T> record={record} value={value} />
      return <div>{value}</div>
    }

    default: {
      return <div>{value}</div>
    }
  }
}
