import {useSelector} from 'react-redux'

import {TYPE} from './constants'

export const useCheckAccess = ({type}) => {
  const {accessFunctions} = useSelector(({settings}) => settings.myAccess)
  const functionType =
    type === TYPE.LOCATION ? 'assets_create' : 'functions_invoke'

  return accessFunctions?.[functionType]?.includes(type)
}
