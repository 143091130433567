import {call, put} from 'redux-saga/effects'
import {getBEndToken} from '@/api/token-request'
import {
  refreshOrgTokenSuccess,
  setAuthFailed,
  setBETokenFailed,
  setBETokenSuccess,
} from '@/redux/user/userActions'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {getModalErrorData} from '@/utils/get-modal-error-data'
import {TTokenReqPayload} from '@/api/typedf'
import {AuthTokenError, hasAuthTokenError} from '@/utils/has-auth-token-error'

export function* refreshTokenSaga(data: {payload: Partial<TTokenReqPayload>}) {
  const {id, scope} = data.payload
  try {
    const {
      body: {access_token, expires_in},
    } = yield call(getBEndToken, {
      scope,
      id,
    })

    yield put(setBETokenSuccess({token: access_token, tokenExp: expires_in}))
    yield put(refreshOrgTokenSuccess())
    return {token: access_token, expires_in}
  } catch (err) {
    if (err instanceof Error) {
      if (hasAuthTokenError(err.message, AuthTokenError.INVALID_SCOPE)) {
        yield put(setAuthFailed(true))
      }
    }
    yield put(setBETokenFailed())
    yield put(
      setToastMessage({
        message: getModalErrorData(err, `Getting token for ${id} has failed`),
      })
    )
  }
}
