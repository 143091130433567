import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import classNames from 'classnames'
import throttle from 'lodash/throttle'
import ResizeObserver from 'resize-observer-polyfill'

import {TChildren} from '@/typedef'

import {setTooltip} from './set-tooltip'

import './ellipsis-text-hint.scss'

type TProps = {
  title?: string
  className?: string
} & TChildren

const DELAY = 200

export const EllipsisTextHint: React.FC<TProps> = props => {
  const {title, className} = props
  const textRef = useRef(null)
  const [width, setWidth] = useState(0)

  const handleResize = useCallback((observed: any) => {
    const {width: newWidth} = observed.contentRect
    setWidth(newWidth)
  }, [])

  useLayoutEffect(() => {
    const OBSERVED: HTMLElement = textRef.current as any
    const resizeObserver = new ResizeObserver(
      throttle(([observed]) => handleResize(observed), DELAY)
    )
    resizeObserver.observe(OBSERVED)
    return () => resizeObserver.unobserve(OBSERVED)
  }, [handleResize])

  useEffect(() => {
    setTooltip(textRef.current, title)
  }, [width, title])

  const classes = classNames('ellipsis-text-hint', className)

  return (
    <span className={classes} ref={textRef}>
      <span className="ellipsis-text-hint__item">{props.children}</span>
    </span>
  )
}
