import React, {lazy, Suspense} from 'react'

import {Skeleton} from '@/components/skeleton'

const FileViewerModule = lazy(() =>
  import(
    /* webpackChunkName: "react-file-viewer package" */ 'react-file-viewer'
  )
)

const FileViewer = props => {
  return (
    <Suspense fallback={<Skeleton />}>
      <FileViewerModule {...props} />
    </Suspense>
  )
}

export default FileViewer
