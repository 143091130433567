import {useCallback} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {postCloseIncident} from '@/modules/ITSM/api/incidentsRequests'

export function useCloseIncident() {
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook({
    loadingMessage: 'saving',
    successMessage: 'success',
    errorMessage: 'error',
  })

  const closeIncident = useCallback(
    async (entity: string) => {
      try {
        handleRequested()
        const result = await postCloseIncident({data: entity})
        if (result) {
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    saveEntity: closeIncident,
    saveEntityStatus: status,
  } as const
}
