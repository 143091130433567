import React from 'react'

import {SortableContainer} from 'react-sortable-hoc'

import {SortableItem} from './sortable-item'
import {TSortableItem} from './typedef'

type TProps = {
  items: Array<TSortableItem>
  blockUnChecking: boolean
  handleActiveColumns: (columnName: string, isAdded: boolean) => void
}

const SortableItemList: React.FC<TProps> = (props: TProps) => {
  const {items, blockUnChecking, handleActiveColumns} = props

  return (
    <ul>
      {items.map((item: TSortableItem, index: number) => (
        <SortableItem
          key={`item-${item.title}`}
          index={index}
          value={item.name || item.title}
          isChecked={item.isChecked}
          isRequired={item.isChecked && blockUnChecking}
          handleActiveColumns={handleActiveColumns}
        />
      ))}
    </ul>
  )
}

export const SortableList = SortableContainer<TProps>(SortableItemList)
