import React from 'react'

import classNames from 'classnames'
import {Button as AntButton} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'

import {IButtonProps} from './typedef'
import {IconPosition} from './constants'

import './button.scss'

export const Button: React.FC<IButtonProps> = ({
  title,
  loading,
  icon,
  iconPosition = IconPosition.LEFT,
  className = '',
  ...props
}) => {
  const isIconLeft = iconPosition === IconPosition.LEFT
  const isIconRight = iconPosition === IconPosition.RIGHT
  const classes = classNames('button', {
    'button_icon-left': isIconLeft,
    'button_icon-right': isIconRight,
    [className]: className,
  })
  const getIcon = (icon?: React.ReactNode) =>
    loading ? <LoadingOutlined /> : icon

  return (
    <AntButton className={classes} {...props}>
      {isIconLeft && getIcon(icon)}
      {title && <span className="button__title">{title}</span>}
      {isIconRight && getIcon(icon)}
    </AntButton>
  )
}
