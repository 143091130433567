import {translate} from '@/services/i18n'
import {findChannelNameInExistChannelError} from '@/utils/find-channel-name-in-exist-channel-error'

export const createChannelExistErrorMessage = (err: unknown) => {
  const foundedChannelExistError = findChannelNameInExistChannelError(err)

  if (foundedChannelExistError) {
    return `"${foundedChannelExistError}" ${translate('channel_exist_info')}`
  }

  return translate('oops_something_went_wrong')
}
