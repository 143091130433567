import {useCallback, useEffect, useReducer, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {selectEmptySpace} from '@/redux/user/userSelector'
import {RESOLVE} from '@/constants'
import {mergeBookmarks} from '@/utils/merge-bookmarks'
import {RequestStatus, SortingValues, TListArgs, TListOptions} from '@/typedef'
import {ISuperAgentResMultiple} from '@/api/response-typedf'
import {TAppState} from '@/redux/store'
import {ItsmAssets} from '@/modules/ITSM/typedef'
import {
  getDataFailed,
  getDataRequested,
  getDataSuccess,
  initialState,
  itsmDataTableReducer,
  resetDataState,
  resetFetchDataStatus,
  setBookmark,
  setFetchMoreRecords,
  setNumberOfRecords,
} from '@/modules/ITSM/reducers/itsm-data-table-reducer'
import {useSafeDispatch} from '@/hooks/useSafeDispatch'
import {selectItsmAssetPagination} from '@/modules/ITSM/store/list-table/selectors'
import {
  resetItsmTableFilters,
  setItsmTableBookmarks,
} from '@/modules/ITSM/store/list-table/table-actions'
import {noPermission} from '@/modules/ITSM/utils/Constants'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {RequestStatusCode} from '@/components/error/typedef'

import {TFilterListTable} from '../../itsm-list-table/typedf'

import {useGetDefaultFilters} from './use-get-default-filters'

export function useGetDataListTableNoFilters<T extends Record<string, any>>({
  asyncF,
  asset,
  entity,
  initFetch = true,
}: {
  asyncF: <T extends Record<string, any>>({
    passedBookmark,
    selector,
    resolve,
    sort,
  }: TListOptions<T>) => Promise<ISuperAgentResMultiple<T>>
  asset: ItsmAssets
  entity?: string
  initFetch?: boolean
}): {
  readonly getEntityData: ({
    passedBookmark,
    resetBookmarks,
    passedFilters,
  }: TListArgs<T>) => Promise<T[] | undefined>
  readonly entityData: T[]
  readonly fetchStatus: RequestStatus
  readonly numberOfRecords: number
} {
  const [state, unsafeDispatch] = useReducer(itsmDataTableReducer, initialState)
  const dispatch = useSafeDispatch(unsafeDispatch)

  const defaultFilters = useGetDefaultFilters(asset, entity) as Record<
    string,
    unknown
  >

  const {data, numberOfRecords, fetchMoreRecords, status, bookmark} = state
  const paginationAsset =
    useSelector((state: TAppState) =>
      selectItsmAssetPagination(state, asset)
    ) || {}

  const [passedFilters, setPassedFilters] = useState<
    TFilterListTable<T> | undefined
  >()
  const isEmptySpace = useSelector(selectEmptySpace)

  const rdxDispatch = useDispatch()

  const getEntityData = useCallback(
    async ({passedBookmark, resetBookmarks, passedFilters}: TListArgs<T>) => {
      try {
        dispatch(getDataRequested())
        const {
          body: {result, bookmark},
        } = await asyncF<T>({
          passedBookmark,
          resolve: fetchMoreRecords ? '' : RESOLVE,
          sort: [{created_at: SortingValues.DESC}],
          selector: {...passedFilters, ...defaultFilters},
        })

        if (result) {
          if (fetchMoreRecords) {
            dispatch(setFetchMoreRecords(false))
            dispatch(setNumberOfRecords(result.length))
            dispatch(setBookmark(undefined))
            setPassedFilters(undefined)
          } else if (!fetchMoreRecords) {
            if (
              result.some(
                e => e.error !== undefined && e.error.includes(noPermission)
              )
            ) {
              rdxDispatch(
                setToastMessage({
                  message: {
                    status: RequestStatusCode.STATUS_200,
                    message: noPermission,
                  },
                })
              )

              rdxDispatch(resetItsmTableFilters(asset))
              dispatch(getDataFailed(result))
              dispatch(resetDataState())

              return
            }

            const bookmarks = mergeBookmarks(
              bookmark,
              paginationAsset?.bookmarks,
              resetBookmarks
            )

            dispatch(getDataSuccess(result, result.length))
            rdxDispatch(
              setItsmTableBookmarks({
                asset,
                bookmarks,
                bookmark: passedBookmark,
              })
            )

            setPassedFilters(passedFilters || {})
            if (result.length === 10) {
              dispatch(setBookmark(bookmark))
              dispatch(setFetchMoreRecords(true))
            }

            return result
          }
          return undefined
        }
      } catch (err) {
        rdxDispatch(resetItsmTableFilters(asset))
        rdxDispatch(setToastMessage({message: err}))
        dispatch(getDataFailed(err))
        dispatch(resetDataState())
      } finally {
        dispatch(resetFetchDataStatus())
      }
    },
    [
      asset,
      asyncF,
      defaultFilters,
      dispatch,
      fetchMoreRecords,
      paginationAsset?.bookmarks,
      rdxDispatch,
    ]
  )

  useEffect(() => {
    if (fetchMoreRecords && bookmark && passedFilters) {
      getEntityData({
        passedBookmark: bookmark,
        passedFilters,
      })
    }
  }, [fetchMoreRecords, bookmark, passedFilters])

  useEffect(() => {
    if (isEmptySpace === null && initFetch) {
      if (!data) {
        getEntityData({
          passedBookmark:
            (paginationAsset.bookmarks || []).length > 0
              ? paginationAsset.bookmark
              : undefined,
        })
      }
    }
  }, [asset, data, isEmptySpace, initFetch])

  return {
    getEntityData,
    entityData: data,
    numberOfRecords,
    fetchStatus: status,
  }
}
