import {translate} from '@/services/i18n'

export const getProgressDisplay = (progress?: number) => {
  if (!progress) {
    return '0%'
  }
  if (progress > 100) {
    return `${translate('over')} ${100}%`
  }
  return `${progress}%`
}
