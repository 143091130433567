import {useState} from 'react'

import saveAs from 'file-saver'
import {useDispatch} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'

import {getAttachmentV2} from '../api/attachmentsRequests'
import {TAttachment} from '../sites/Expenses/typedef'
import {extensionPicker} from '../utils/Helpers'
import {ActionType} from '../typedef'

export const useDownloadPreview = () => {
  const [previewAttachment, setPreviewAttachment] = useState<
    TAttachment | undefined
  >()

  const dispatch = useDispatch()

  const attachmentAction = async (
    record: TAttachment,
    docAction: ActionType
  ) => {
    try {
      const res = await getAttachmentV2(record.data)

      const {body, type, header} = res
      if (docAction === ActionType.DOWNLOAD) {
        const fileType = extensionPicker(type)
        const file = new Blob([body], {
          type,
        })
        saveAs(file, header['x-file-name'] || 'download.' + fileType)
      } else {
        setPreviewAttachment({
          data: body,
          file_type: type,
          file_name: header['x-file-name'],
        })
      }
    } catch (err) {
      dispatch(setToastMessage({message: err}))
    }
  }

  return {attachmentAction, previewAttachment, setPreviewAttachment}
}
