import React, {useRef} from 'react'

import {Progress, Tooltip} from 'antd'

import {translate} from '@/services/i18n'
import {getProgressColor} from '@/utils/get-progress-color'

import {getSlaName} from '../utils/get-sla-name'
import {getProgressDisplay} from '@/modules/ITSM/components/incident/utils/get-progress-display'
import {useStateContext} from '../incident-context'
import {TIncident} from '../../incident-k-request/typedf'

import './incident-progress-bar.scss'

type TProgressTooltipData = {
  elapsedTime?: number
  remainingTime?: number
  progress?: number
}

export const IncidentProgressBar = () => {
  const {incState, entityData, entity} = useStateContext<TIncident>()

  const {slaValues} = incState
  const {remainingTime, elapsedTime, progress} = slaValues

  const popupContainer = useRef(null)

  const slaName =
    entityData && getSlaName(entityData.state_id, entity, progress)

  return (
    <div className="incident-progress-bar" ref={popupContainer}>
      <div className="incident-progress-bar__incident-status">
        {translate('sla_status')}: {slaName}
      </div>
      <div className="incident-progress-bar__content">
        <Tooltip
          getPopupContainer={() => popupContainer.current || document.body}
          title={
            <ProgressTooltipData {...{elapsedTime, remainingTime, progress}} />
          }
          placement="bottom"
        >
          <Progress
            strokeColor={getProgressColor(progress || 0)}
            percent={progress}
            showInfo={false}
          />
        </Tooltip>
        <div className="incident-progress-bar__progress-number">
          {getProgressDisplay(progress)}
        </div>
      </div>
    </div>
  )
}

export const ProgressTooltipData = ({
  elapsedTime,
  remainingTime,
  progress,
}: TProgressTooltipData) => {
  return (
    <div className="incident-progress-bar__tooltip">
      <div className="fw-600 nowrap">
        <span className="incident-progress-bar__number-container">
          {translate('elapsed_time')}
        </span>
        <span data-test="elapsed-time">
          {Math.round(elapsedTime ? elapsedTime / 3600 : 0)}h{' '}
          {Math.round(elapsedTime ? (elapsedTime % 3600) / 60 : 0)}m
        </span>
      </div>
      <div className="fw-600 nowrap">
        <span className="incident-progress-bar__number-container">
          {translate('remaining_time')}
        </span>
        <span data-test="remaining-time">
          {Math.round(remainingTime ? remainingTime / 3600 : 0)}h{' '}
          {Math.round(remainingTime ? (remainingTime % 3600) / 60 : 0)}m
        </span>
      </div>
      <div className="fw-600 nowrap">
        <span className="incident-progress-bar__number-container">
          {translate('elapsed_time')}
        </span>
        <span data-test="progress">{progress || 0}%</span>
      </div>
    </div>
  )
}
