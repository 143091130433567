import {TIncidentTask} from '../typedf'

export const getInitialValues = (values: TIncidentTask) => {
  return {
    customer_product: values.customer_product?.uuid,
    supplier_product: values.supplier_product?.uuid,
    assignment_group: values.assignment_group?.uuid,
    assigned_to: values.assigned_to?.uuid,
  }
}
