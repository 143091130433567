import {Rule} from 'antd/lib/form'
import {Moment} from 'moment'

import {translate} from '@/services/i18n'
import {TWeekdayPricingPolicy} from '@/typedef'

export const validateBankHolidays = (
  rule: Rule,
  {value, pairValue, array}: {value: Moment; pairValue: Moment; array: string[]}
) => {
  return new Promise<void>((resolve, reject) => {
    if (!value && pairValue) {
      array?.includes('support_from')
        ? reject(reject(translate('support_to_is_required')))
        : reject(translate('support_from_is_required'))
    }
    if (
      value &&
      array?.includes('support_to') &&
      pairValue?.isSameOrBefore(value)
    ) {
      reject(reject(translate('support_from_less')))
    }
    if (
      value &&
      array?.includes('support_from') &&
      pairValue?.isSameOrAfter(value)
    ) {
      reject(translate('support_to_more'))
    }
    resolve()
  })
}

export const validateHoliday = (
  rule: Rule,
  {value, holidayString}: {holidayString: string[]; value?: Moment}
) => {
  return new Promise<void>((resolve, reject) => {
    if (!value) {
      reject(translate('holiday_cannot_be_empty_field'))
    }
    if (
      holidayString?.filter(it => it === value?.format('YYYY-MM-DD')).length > 1
    ) {
      reject(translate('holiday_is_duplicated'))
    }
    resolve()
  })
}

export const pricingPoliciesWeekdaysHolidaysValidation = (
  rule: Rule,
  {
    value,
    holidayArray,
    interval,
    pairValue,
  }: {
    value: Moment
    holidayArray: TWeekdayPricingPolicy[]
    interval: string
    pairValue: Moment
  }
) => {
  const isOverlap = holidayArray?.some((holiday: TWeekdayPricingPolicy) =>
    value?.isBetween(holiday.from, holiday.to)
  )

  const isSameInterval = holidayArray.some((holiday: TWeekdayPricingPolicy) => {
    if (interval === 'from') {
      return value?.isSame(holiday.to) && pairValue?.isSame(holiday.from)
    } else {
      return value?.isSame(holiday.from) && pairValue?.isSame(holiday.to)
    }
  })

  return new Promise<void>((resolve, reject) => {
    if (!value) {
      reject(translate('field_not_empty'))
    }
    if (isOverlap || isSameInterval) {
      reject(translate('interval_overlap'))
    }
    if (
      value &&
      interval &&
      interval === 'from' &&
      pairValue?.isSameOrAfter(value)
    ) {
      reject(reject(translate('support_from_less')))
    }
    if (
      value &&
      interval &&
      interval === 'to' &&
      pairValue?.isSameOrBefore(value)
    ) {
      reject(translate('support_to_more'))
    }

    resolve()
  })
}

export const activeValidation = (
  rule: Rule,
  {
    value,
    intervalName,
    interval,
  }: {value: Moment; intervalName: string; interval?: Moment}
) => {
  return new Promise<void>((resolve, reject) => {
    if (!value) {
      reject(translate('field_not_empty'))
    }
    if (
      value &&
      interval &&
      intervalName === 'active_from' &&
      interval?.isSameOrBefore(value)
    ) {
      reject(reject(translate('active_from_less')))
    }
    if (
      value &&
      interval &&
      intervalName === 'active_to' &&
      interval?.isSameOrAfter(value)
    ) {
      reject(translate('active_to_more'))
    }
    resolve()
  })
}

export const weekdaysValidation = (
  rule: Rule,
  {
    value,
    intervalName,
    weekdayInterval,
  }: {value: Moment; intervalName: string; weekdayInterval?: Moment}
) => {
  return new Promise<void>((resolve, reject) => {
    if (!value && weekdayInterval) {
      reject(translate('field_not_empty'))
    }
    if (
      value &&
      weekdayInterval &&
      intervalName === 'support_from' &&
      weekdayInterval?.isSameOrAfter(value)
    ) {
      reject(reject(translate('active_from_less')))
    }
    if (
      value &&
      weekdayInterval &&
      intervalName === 'support_to' &&
      weekdayInterval?.isSameOrBefore(value)
    ) {
      reject(translate('active_to_more'))
    }
    resolve()
  })
}
