import {kFormatter} from '@/utils/kFormatter'

export const differenceGrowth = (
  currentNumber: number,
  comparedNumber: number,
  fixed?: number
) => {
  if (currentNumber === 0) {
    return `${kFormatter(comparedNumber === 0 ? 0 : -comparedNumber)} EUR`
  }

  if (comparedNumber === 0) {
    return `${kFormatter(currentNumber)} EUR`
  }

  return `${(((currentNumber - comparedNumber) / currentNumber) * 100).toFixed(
    fixed
  )}%`
}
