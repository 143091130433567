import {RequestStatus} from '@/typedef'

const checkRequest = (status: RequestStatus) => {
  return status === RequestStatus.REQUESTED
}

const checkSucceed = (status: RequestStatus) => {
  return status === RequestStatus.SUCCEEDED
}

const checkFail = (status: RequestStatus) => {
  return status === RequestStatus.FAILED
}

export const isRequesting = (status: RequestStatus | RequestStatus[]) => {
  if (Array.isArray(status)) {
    return status.some(status => checkRequest(status))
  }
  return checkRequest(status)
}

export const isSucceeded = (status: RequestStatus | RequestStatus[]) => {
  if (Array.isArray(status)) {
    return status.some(status => checkSucceed(status))
  }
  return checkSucceed(status)
}

export const isFailed = (status: RequestStatus | RequestStatus[]) => {
  if (Array.isArray(status)) {
    return status.some(status => checkFail(status))
  }
  return checkFail(status)
}
