import globalRoutes from '@/global-routes'

const {
  moduleRoutes: {
    itsm: {path: itsmModulePath},
  },
} = globalRoutes

const itsmRoutes = {
  itsmHome: {
    name: 'itsmHome',
    path: () => `${itsmModulePath()}`,
  },
  serviceManagementDashboard: {
    name: 'serviceManagementDashboard',
    path: (space_id?: string, org_id?: string) =>
      `${itsmModulePath()}/service-management/dashboard${
        space_id ? `?space_id=${space_id}` : ''
      }${org_id ? `&org_id=${org_id}` : ''}`,
  },
  itsmSettings: {
    name: 'itsmSettings',
    path: () => `${itsmModulePath()}/settings`,
  },
  userInvitation: {
    name: 'UserInvitation',
    path: () => `${itsmModulePath()}/user-invitation`,
  },
  channelInvitation: {
    name: 'OrgInvitation',
    path: () => `${itsmModulePath()}/org-invitation`,
  },
  billingReportsState: {
    name: 'BillingReportsState',
    path: (id = ':id?', tab = ':tab?', query = '') =>
      `${itsmModulePath()}/finance/billing-report/${id}${tab ? '/' : ''}${tab}${
        query ? query : ''
      }`,
  },
  serviceManagementIncidents: {
    name: 'serviceManagementIncidents',
    path: () => `${itsmModulePath()}/service-management/incidents`,
  },

  serviceManagemenIncidentsExpenses: {
    name: 'serviceManagementRequestsTimelogs',
    path: (ticketId = ':id?') =>
      `${itsmModulePath()}/service-management/incident/${ticketId}/expenses`,
  },

  serviceManagementIncidentsNew: {
    name: 'serviceManagementIncidentsNew',
    path: (id = ':id?') =>
      `${itsmModulePath()}/service-management/incident-new/${id}`,
  },
  serviceManagementIncidentsState: {
    name: 'serviceManagementIncidentsState',
    path: (id = ':id?', state = ':state?') =>
      `${itsmModulePath()}/service-management/incident/${id}/${state}`,
  },
  serviceManagementIncidentsDetail: {
    name: 'serviceManagementIncidentsDetail',
    path: (id = ':id?') =>
      `${itsmModulePath()}/service-management/incident/${id}`,
  },

  serviceManagementIncidentsTaskNew: {
    name: 'serviceManagementIncidentsTaskNew',
    path: (taskId = ':taskId?', query = '') =>
      `${itsmModulePath()}/service-management/incident-task-new/${taskId}${
        query ? `?incident=${query}` : ''
      }`,
  },
  serviceManagementIncidentsTaskDetail: {
    name: 'serviceManagementIncidentsTaskDetail',
    path: (taskId = ':taskId?') =>
      `${itsmModulePath()}/service-management/incident-task/${taskId}`,
  },
  serviceManagementIncidentsTaskState: {
    name: 'serviceManagementIncidentsTaskNew',
    path: (taskId = ':taskId?', state = ':state?') =>
      `${itsmModulePath()}/service-management/incident-task/${taskId}/${state}`,
  },
  serviceManagementDocuments: {
    name: 'serviceManagementDocuments',
    path: () => `${itsmModulePath()}/service-management/documents`,
  },
  serviceManagementRequests: {
    name: 'serviceManagementRequests',
    path: () => `${itsmModulePath()}/service-management/requests`,
  },
  serviceManagementRequestsDetail: {
    name: 'serviceManagementRequestsState',
    path: (id = ':id?') =>
      `${itsmModulePath()}/service-management/request/${id}`,
  },
  serviceManagementRequestsNew: {
    name: 'serviceManagementRequestsNew',
    path: (id = ':id?') =>
      `${itsmModulePath()}/service-management/request-new/${id}`,
  },
  serviceManagementRequestsState: {
    name: 'serviceManagementRequestsNew',
    path: (id = ':id?', state = ':state?') =>
      `${itsmModulePath()}/service-management/request/${id}/${state}`,
  },
  serviceManagemenTicketState: {
    name: 'serviceManagementIncidentsState',
    path: (entity = ':entity?', id = ':id?', state = ':state?') =>
      `${itsmModulePath()}/service-management/${entity}/${id}/${state}`,
  },

  serviceManagementEventsPage: {
    name: 'serviceManagementRequestsState',
    path: (option = ':option?', page = ':page?') =>
      `${itsmModulePath()}/service-management/events/${option}${
        page ? '/' : ''
      }${page}`,
  },
  serviceManagementEventsNew: {
    name: 'serviceManagementEventsNew',
    path: () => `${itsmModulePath()}/service-management/event-new`,
  },
  serviceManagementEventsDetail: {
    name: 'serviceManagementEventsDetail',
    path: (id = ':id?') => `${itsmModulePath()}/service-management/event/${id}`,
  },
  serviceManagementExpenses: {
    name: 'serviceManagementExpenses',
    path: () => `${itsmModulePath()}/service-management/expenses`,
  },
  serviceManagementCustomReports: {
    name: 'serviceManagementExpenses',
    path: () => `${itsmModulePath()}/service-management/custom-reports`,
  },
  serviceManagementReports: {
    name: 'serviceManagementReports',
    path: () => `${itsmModulePath()}/service-management/reports`,
  },
  serviceManagementTimeSession: {
    name: 'serviceManagementTimeSession',
    path: () => `${itsmModulePath()}/service-management/time-session`,
  },
  settingsITSMUser: {
    name: 'settingsITSMUser',
    path: () => `${itsmModulePath()}/settings/itsm-user`,
  },
  settingsITSMRoles: {
    name: 'settingsITSMRoles',
    path: () => `${itsmModulePath()}/settings/itsm-roles`,
  },
  settingsITSMChannels: {
    name: 'settingsITSMChannels',
    path: () => `${itsmModulePath()}/settings/itsm-channels`,
  },
  settingsITSMChannelNew: {
    name: 'settingsITSMChannelNew',
    path: () => `${itsmModulePath()}/settings/itsm-channel-new`,
  },
  settingsITSMChannelDetails: {
    name: 'settingsITSMChannelDetails',
    path: (id = ':id?') => `${itsmModulePath()}/settings/itsm-channels/${id}`,
  },
  settingsITSMIdentities: {
    name: 'settingsITSMIdentities',
    path: () => `${itsmModulePath()}/settings/itsm-identities/`,
  },
  settingsITSMUsers: {
    name: 'settingsITSMUsers',
    path: () => `${itsmModulePath()}/settings/itsm-users/`,
  },
  settingsITSMSuppliers: {
    name: 'settingsITSMSuppliers',
    path: () => `${itsmModulePath()}/settings/itsm-suppliers/`,
  },
  settingsITSMInvitations: {
    name: 'settingsITSMInvitations',
    path: () => `${itsmModulePath()}/settings/itsm-invitations`,
  },
  settingsITSMCategories: {
    name: 'settingsITSMCategories',
    path: () => `${itsmModulePath()}/settings/itsm-categories`,
  },
  settingsITSMReasons: {
    name: 'settingsITSMReasons',
    path: () => `${itsmModulePath()}/settings/itsm-reasons`,
  },
  settingsITSMAsignementGroups: {
    name: 'settingsITSMAsignementGroups',
    path: () => `${itsmModulePath()}/settings/itsm-assignment-groups`,
  },
  settingsITSMUAssignRules: {
    name: 'settingsITSMUAssignRules',
    path: () => `${itsmModulePath()}/settings/itsm-assignment-rules`,
    featureFlags: 'assignment_groups',
  },
  settingsITSMUserGroups: {
    name: 'settingsITSMUserGroups',
    path: () => `${itsmModulePath()}/settings/itsm-user-groups`,
  },
  settingsITSMNotifications: {
    name: 'settingsITSMNotifications',
    path: () => `${itsmModulePath()}/settings/itsm-notifications`,
  },
  settingsITSMExtraNotifications: {
    name: 'settingsITSMExtraNotifications',
    path: () => `${itsmModulePath()}/settings/itsm_extraNotifications`,
  },
  settingsITSMUAnnouncements: {
    name: 'settingsITSMUAnnouncements',
    path: () => `${itsmModulePath()}/settings/itsm-announcements`,
  },
  settingsITSMAnnouncementsDetail: {
    name: 'settingsITSMSupplierProductsDetail',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-announcements/${id}`,
  },
  settingsITSMAnnouncementNew: {
    name: 'settingsITSMUAnnouncementNew',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/announcements/new/${id}`,
  },
  settingsITSMSupplierProducts: {
    name: 'settingsITSMSupplierProducts',
    path: () => `${itsmModulePath()}/settings/itsm-supplier-products`,
  },
  settingsITSMCustomerProducts: {
    name: 'settingsITSMCustomerProducts',
    path: () => `${itsmModulePath()}/settings/itsm-customer-products`,
  },
  settingsITSMCustomerProductsDetail: {
    name: 'settingsITSMCustomerProductsDetail',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-customer-products/${id}`,
  },
  settingsITSMSupplierProductsDetail: {
    name: 'settingsITSMSupplierProductsDetail',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-supplier-products/${id}`,
  },
  settingsITSMCustomerProductNew: {
    name: 'settingsITSMCustomerProductNew',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-customer-products/new/${id}`,
  },
  settingsITSMSupplierProductNew: {
    name: 'settingsITSMSupplierProductNew',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-supplier-products/new/${id}`,
  },
  settingsITSMmyAccounts: {
    name: 'settingsITSMmyAccounts',
    path: () => `${itsmModulePath()}/settings/itsm-my-accounts`,
  },
  settingsITSMusersAccounts: {
    name: 'settingsITSMusersAccounts',
    path: () => `${itsmModulePath()}/settings/itsm-users-accounts`,
  },
  // settingsITSMNewAccount: {
  //   name: 'settingsITSMNewAccount',
  //   path: () => `${itsmModulePath()}/settings/new-account`,
  // },
  settingsITSMmyAccountsDetails: {
    name: 'settingsITSMmyAccountsDetails',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-my-accounts/${id}/details`,
  },
  settingsITSMusersAccountsDetails: {
    name: 'settingsITSMusersAccountsDetails',
    path: (id = ':id?') =>
      `${itsmModulePath()}/settings/itsm-users-accounts/${id}/details`,
  },
  settingsITSMlocations: {
    name: 'settingsITSMlocations',
    path: () => `${itsmModulePath()}/settings/itsm-locations`,
  },
  ///new settings
  settingsITSMTab: {
    name: 'settingsITSMTab',
    path: () => `${itsmModulePath()}/settings`,
  },
  financeDashboard: {
    name: 'financeDashboard',
    path: () => `${itsmModulePath()}/finance/dashboard`,
  },
  billingReports: {
    name: 'BillingReports',
    path: () => `${itsmModulePath()}/finance/billing-reports`,
  },
  creditSystem: {
    name: 'CreditSystem',
    path: () => `${itsmModulePath()}/finance/credit`,
  },
}

export default itsmRoutes
