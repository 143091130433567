import {Entities} from '@/typedef'

export type TTimeSpan = {
  end: string
  start: string
  type: string
}

export type TTimeSession = {
  created_at: string
  created_by: string
  docType: string
  entities: Array<Entities>
  owner: string
  state_id: number
  timespans: TTimeSpan[]
  updated_at: string
  updated_by: string
  uuid: string
  xxx_version: number
}

export type TProps = {
  timeSessions: Array<TTimeSession> | []
  getTimeSessions: () => void
}

export enum TimeSessionState {
  Travel = 0,
  Work = 1,
  Break = 2,
  TravelBack = 3,
  Closed = 4,
}
