import React from 'react'

import {useHistory} from 'react-router-dom'

import SvgIcon from '@/components/svg-icon/svg-icon'
import './success-payment.scss'
import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import subscriptionsRoutes from '@/modules/Subscriptions/routes/subscriptions-routes'
import {useQuery} from '@/utils/useQuery'
import {useDispatch} from 'react-redux'
import {
  fetchBillingSubscriptions,
  fetchCustomerRequested,
  setBalanceTransaction,
  setTransactionCounter,
} from '@/redux/billing/billing-action-creators'
import {transformPrice} from '../../utils/transform-price'

export const SuccessPayment = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const prevBalance = useQuery('balance')
  const balance = useQuery('amount')

  const handleClick = () => {
    if (balance) {
      dispatch(setBalanceTransaction(parseInt(balance)))
      dispatch(setTransactionCounter(5))
      dispatch(fetchCustomerRequested(prevBalance))
      dispatch(fetchBillingSubscriptions())
    }
    history.push(subscriptionsRoutes.plansPackages.path())
  }

  return (
    <div className="content-wrapper">
      <SvgIcon
        name="blits-logo"
        width={128}
        height={42}
        className="logo-icon"
      />
      <div className="message-container">
        <SvgIcon
          name="mail"
          width={55}
          height={55}
          className="message-container__icon"
        />
        <div className="mt-20 mb-20">
          <p className="message-container__text">
            {balance ? (
              <>
                {translate('payment_of')}
                &nbsp;{transformPrice(parseInt(balance))}
              </>
            ) : (
              translate('the_payment')
            )}
            &nbsp;
            {translate('has_successfully_completed')}
          </p>
          <div className="message-container__text">
            {translate('thank_you')}
          </div>
        </div>
        <Button
          title={translate('ok')}
          className="message-container__button"
          onClick={handleClick}
        />
      </div>
    </div>
  )
}
