import {all, takeLatest} from 'redux-saga/effects'

import * as ActionTypes from '@/redux/actionTypes'

import {getCmdbIdentityMeSaga} from './get-cmdb-identity-me-saga'

function* watchGetIdentityMeCMDB() {
  yield takeLatest(
    ActionTypes.FETCH_CMDB_IDENTITY_ME_REQUESTED,
    getCmdbIdentityMeSaga
  )
}

export default function* rootSaga() {
  yield all([watchGetIdentityMeCMDB()])
}
