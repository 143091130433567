import {ModalType} from '@/components/modal/typedef'

import {
  CLEAR_ERROR,
  SET_ERROR,
  SET_INITIAL_STATE,
  SET_LOCATION,
  SET_MY_ACCESS,
  SET_PRIORITY_VALUES,
  SET_REDIRECT,
  SET_REFRESH,
  SET_WIDGETS,
  SET_WINDOW_WIDTH,
} from './actionTypes'

export const setRedirect = (redirect, values) => {
  return {
    type: SET_REDIRECT,
    payload: {redirect, ...(values ? {values} : null)},
  }
}

export const setError = (err, onCancel, type = ModalType.ERROR) => ({
  type: SET_ERROR,
  payload: {err, onCancel, type},
})

export const clearError = () => ({
  type: CLEAR_ERROR,
})

export const setRefresh = (
  isRefreshing = false,
  type = undefined,
  uuid = undefined
) => ({
  type: SET_REFRESH,
  payload: {isRefreshing, type, uuid},
})

export const setInitialStateGeneral = () => ({
  type: SET_INITIAL_STATE,
})

export const setCurrentLocation = path => ({
  type: SET_LOCATION,
  payload: {pathname: path},
})

export const setMyAccess = my_access => ({
  type: SET_MY_ACCESS,
  payload: {my_access},
})

export const setPriorityValues = priority_values => ({
  type: SET_PRIORITY_VALUES,
  payload: {priority_values},
})

export const setWidgets = widgets => ({
  type: SET_WIDGETS,
  payload: {widgets},
})

export const setWindowWidth = width => ({
  type: SET_WINDOW_WIDTH,
  payload: width,
})
