import React, {useEffect} from 'react'

import {useHistory} from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'

import DataTable from '@/components/data-table/data-table'
import {useGetChannels} from '@/hooks/orgChannelHooks'
import {translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {Button} from '@/components/button'
import {TItsmChannel} from '@/modules/ITSM/typedef'

import './channels.scss'

const Channels = () => {
  const history = useHistory()

  const {
    channels,
    getChannels: fetchChannels,
    status: channelFetchStatus,
  } = useGetChannels()

  useEffect(() => {
    fetchChannels()
  }, [fetchChannels])

  const getColumns = [
    {
      title: translate('name'),
      key: 'name',
      dataIndex: 'name',
      render: (name: TItsmChannel['name']) => {
        return <div>{name}</div>
      },
    },
    {
      title: translate('org_name'),
      key: 'org_name',
      dataIndex: 'org_name',
      render: (orgName: TItsmChannel['org_name']) => {
        return <div>{orgName}</div>
      },
    },
    {
      title: translate('created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (createdAt: TItsmChannel['created_at']) => {
        return <div>{createdAt?.slice(0, 10)}</div>
      },
    },
  ]

  const handleRowClick = ({space: id}: TItsmChannel) => {
    history.push(itsmRoutes.settingsITSMChannelDetails.path(id))
  }
  const header = (
    <div className="itsm-settings-channel__header">
      <Button
        className={'buttons list-table-header__btn-new'}
        type="primary"
        title={translate('add_new')}
        onClick={() => history.push(itsmRoutes.settingsITSMChannelNew.path())}
        icon={<PlusOutlined />}
        e2e-test="add_new"
      />
    </div>
  )
  return (
    <DataTable<TItsmChannel>
      data={channels}
      columns={getColumns}
      tableHeader={header}
      onRowClick={handleRowClick}
      loading={checkIsLoading(channelFetchStatus)}
      rowKey="space"
    />
  )
}

export default Channels
