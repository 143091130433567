import {TActionTypes} from '@/redux/types'
import {
  ISubscriptionDatum,
  PriceFeatureFlagKeys,
  TCustomer,
} from '@/modules/typedef'

export const fetchBillingSubscriptions = () =>
  ({
    type: TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_REQUESTED,
  } as const)

export const fetchBillingSubscriptionsSucceeded = (payload: {
  subscriptions: ISubscriptionDatum[]
  featureFlags: Array<PriceFeatureFlagKeys>
}) =>
  ({
    type: TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_SUCCEEDED,
    payload,
  } as const)

export const fetchBillingSubscriptionsFailed = (payload: unknown) =>
  ({
    type: TActionTypes.FETCH_BILLING_SUBSCRIPTIONS_FAILED,
    payload,
  } as const)

export const fetchCustomerRequested = (balance?: string) =>
  ({
    type: TActionTypes.FETCH_BILLING_CUSTOMER,
    payload: {balance},
  } as const)

export const fetchCustomerSucceeded = (customer: TCustomer) =>
  ({
    type: TActionTypes.FETCH_BILLING_CUSTOMER_SUCCESS,
    payload: customer,
  } as const)

export const setBalanceTransaction = (quantity: number) =>
  ({
    type: TActionTypes.SET_BALANCE_TRANSACTION,
    payload: quantity,
  } as const)

export const setTransactionCounter = (quantity: number) =>
  ({
    type: TActionTypes.SET_TRANSACTION_NUMBER,
    payload: quantity,
  } as const)

export const setRefetchDelay = (interval?: number) =>
  ({
    type: TActionTypes.SET_REFETCH_DELAY,
    payload: interval,
  } as const)
