import React from 'react'

import {Divider} from 'antd'

import {Button} from '@/components/button/button'
import {TAttachment, TExpense} from '@/modules/ITSM/sites/Expenses/typedef'
import {getNameAndExtension} from '@/utils/get-name-and-extension'
import {checkIsOnlyDownloadableFile} from '@/utils/check-is-only-downloadable-file'
import {translate} from '@/services/i18n'
import {ActionType} from '@/modules/ITSM/typedef'

import {TColumnRender} from '../../itsm-list-table/typedf'

export const IncidentExpensessRowValue = ({
  title,
  value,
  record,
  attachmentAction,
}: TColumnRender<TExpense> & {
  attachmentAction: (attachment: TAttachment, action: ActionType) => void
}) => {
  switch (title) {
    case 'actions': {
      const {extension} = getNameAndExtension(record?.attachment.file_name)

      return (
        <div className="flex">
          <Button
            disabled={!!(extension && checkIsOnlyDownloadableFile(extension))}
            onClick={() =>
              record && attachmentAction(record.attachment, ActionType.PREVIEW)
            }
            title={translate('preview')}
            className="btn-preview btn-sm"
          />

          <Divider type="vertical" />
          <Button
            onClick={() =>
              record && attachmentAction(record.attachment, ActionType.DOWNLOAD)
            }
            title={translate('download')}
            className="btn-download btn-sm"
          />
        </div>
      )
    }
    default:
      return <div>{value}</div>
  }
}
