import {Key} from 'react'

import saveAs from 'file-saver'

import {useFetchHook} from '@/hooks/useFetch'
import {exportBIfile} from '@/modules/ITSM/api/exportRequests'
import {exportType} from '@/constants'

export const useExportBillingItems = () => {
  const {
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const exportBillingItems = async (
    format: string,
    selectedRowKeys?: Array<Key>
  ) => {
    try {
      handleRequested()

      const billingReports = selectedRowKeys?.map(uuid => ({
        billing_report: uuid,
      }))

      const {body} = await exportBIfile(format, {
        $or: billingReports,
        chargeable: true,
      })
      const fileType = format === 'xlsx' ? exportType : 'text/csv'
      const file = new Blob([body], {type: fileType})
      const fileName =
        format === 'xlsx' ? 'billing_items.xlsx' : 'billing_items.csv'

      saveAs(file, fileName)

      handleSuccess()
    } catch (err) {
      handleFail(err)
    } finally {
      handleReset()
    }
  }

  return {exportBillingItems}
}
