import React, {Component, Fragment} from 'react'

import {Col, Row, Spin, Tooltip} from 'antd'
import moment from 'moment'
import 'moment-duration-format'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import request from 'superagent'

import {translate} from '@/services/i18n'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {setRefresh} from '@/redux/actions'
import {Assets, Entities, RequestStatus} from '@/typedef'
import {getHeadersWithGRPC} from '@/services/api'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {DATE_FORMAT} from '@/constants'
import {handleMessage} from '@/utils/handle-message'
import {Button} from '@/components/button'
import {updateTimeSessionStoreData} from '@/modules/ITSM/components/incident-k-request/incident-k-request-btns/incident-k-request-time-session/utils'
import DataTable from '@/components/data-table/data-table'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {
  incidentsApi,
  incidentTaskApi,
  k_requestsApi,
  requestTaskApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'

import {
  fetchTimeSessions,
  patchTimeSession,
} from '../../api/timeSessionRequests'
import {timeSessionStatesT} from '../../utils/Constants'

import TimeSessionsTable from './time-sessions-closed'
import TimespansTable from './time-session-timespans'

import './time-session.scss'

class TimeSession extends Component {
  state = {
    asset: Assets.timeSessionAsset,
    tickets: [],
    count: 0,
    loading: false,
    closedTimeSessions: [],
  }

  createData = state => {
    return {
      entities: [`${this.props.entity}:${this.props.id}`],
      state_id: state,
    }
  }

  getTicketsColumns() {
    return [
      {
        title: translate('number'),
        dataIndex: 'number',
      },
      {
        title: translate('short_description'),
        dataIndex: 'short_description',
      },
      {
        title: translate('type'),
        dataIndex: 'docType',
        render: val => val && val.replace('_', ' ').toLowerCase(),
      },
      {
        title: translate('current_time_log'),
        dataIndex: 'timelogs',
        render: val => {
          const timelog =
            val &&
            val.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            ) &&
            val.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )[0]

          return (
            <div>
              <div>
                {timelog && timelog.start && (
                  <Fragment>
                    <span>{`start: `}</span>
                    {moment(timelog.start).format(DATE_FORMAT)}
                  </Fragment>
                )}
              </div>
              <div>
                {timelog && timelog.end && (
                  <Fragment>
                    <span>{`end: `}</span>
                    {moment(timelog.end).format(DATE_FORMAT)}
                  </Fragment>
                )}
              </div>
            </div>
          )
        },
      },
    ]
  }

  fetchTickets = tickets => {
    for (let entity in tickets) {
      let endpoint

      if (entity === Entities.INCIDENT) endpoint = incidentsApi().get
      if (entity === Entities.INCIDENT_TASK) endpoint = incidentTaskApi().get
      if (entity === Entities.REQUEST_TASK) endpoint = requestTaskApi().get
      if (entity === Entities.K_REQUEST) endpoint = k_requestsApi().get

      request
        .options(endpoint)
        .send({
          selector: {$or: tickets[entity]},
        })
        .set(getHeadersWithGRPC())
        .query({resolve: true})
        .then(res => {
          this.setState(prevState => ({
            tickets: prevState.tickets.concat(res.body.result),
            count: prevState.count + 1,
          }))

          if (this.state.count === Object.keys(tickets).length)
            this.setState({loading: false, count: 0})
        })
        .catch(err => {
          this.setState({loading: false})
          this.props.setToastMessage({message: err})
        })
    }
  }

  updateTimeSession = async ({e, state, loading, general}) => {
    e.preventDefault()

    try {
      this.setState({[loading]: true})
      handleMessage(RequestStatus.REQUESTED)
      const data = {state_id: state}

      const responseData = await patchTimeSession(
        this.props.timeSession.uuid,
        data
      )
      if (responseData) {
        const {
          body: {result: timeSession},
        } = responseData
        updateTimeSessionStoreData({state, timeSession})
      }

      handleMessage(RequestStatus.SUCCEEDED)
      if (!general) this.getTimeSession()
      return Promise.resolve()
    } catch (err) {
      if (err?.response?.body?.error?.message?.includes('is not closed')) {
        this.setState({open_timelog_warning: true})
      } else {
        this.props.setToastMessage({message: err})
        handleMessage(RequestStatus.FAILED)
      }

      return Promise.reject()
    } finally {
      this.setState({[loading]: false})
    }
  }

  getTimeSessions = async ({refresh = false, closedTimeSessions = false}) => {
    this.setState({loading: true})

    if (this.props.userState.userID !== null) {
      if (closedTimeSessions) {
        try {
          const res = await fetchTimeSessions({
            owner: this.props.userState.userID,
            state_id: 4,
          })

          this.setState({closedTimeSessions: res.body.result, loading: false})
          return
        } catch (err) {
          this.props.setToastMessage({message: err})
          this.setState({loading: false})
        }
      } else {
        try {
          const res = await fetchTimeSessions({
            owner: this.props.userState.userID,
            state_id: {$ne: 4},
          })

          const timeSession = res.body.result
          updateTimeSessionStoreData({timeSession: timeSession[0]})

          if (timeSession && timeSession[0]) {
            const tickets = timeSession[0].entities.map(e => {
              return {entity: e.split(':')[0], uuid: e.split(':')[1]}
            })

            const result = tickets.reduce((r, a) => {
              r[a.entity] = r[a.entity] || []
              r[a.entity].push({uuid: a.uuid})
              return r
            }, {})

            if (refresh) this.fetchTickets(result)
            else this.setState({loading: false})
          } else {
            this.setState({tickets: [], loading: false})
          }
        } catch (err) {
          this.props.setToastMessage({message: err})
          this.setState({
            loading: false,
          })
        }
      }
    }
  }

  rowClick = record => {
    const asset = record.docType.toLowerCase().split('_')
    const assetPath = asset[1]
      ? `${asset[1].charAt(0).toUpperCase()}${asset[1].slice(1)}`
      : ''
    const redirectTo =
      record.docType.toLowerCase() === Assets.kRequestAsset
        ? itsmRoutes.serviceManagementRequestsDetail.path(record.uuid)
        : `/itsm/service-management/${asset[0]}${assetPath}/${record.uuid}`
    this.setState({
      redirectTo,
      redirect: true,
    })
  }

  componentDidMount() {
    if (this.props.userState.emptySpace === null) {
      this.getTimeSessions({refresh: true})
      this.getTimeSessions({closedTimeSessions: true})
    }
  }

  render() {
    const {
      closedTimeSessions,
      redirect,
      redirectTo,
      tickets,
      loading,
    } = this.state

    let btnGroup = []

    const state = this.props.timeSession?.state_id

    const openTimelog =
      tickets.filter(
        e => e.timelogs && e.timelogs.find(t => t.end === undefined)
      ) &&
      tickets.filter(
        e => e.timelogs && e.timelogs.find(t => t.end === undefined)
      ).length > 0

    if (`${state}`) {
      if (`${state}` === '0') {
        btnGroup = [
          {
            param: 1,
            btnText: translate('time_session_stateid_1'),
            loading: 'work',
          },
        ]
      }

      if (state === 1) {
        btnGroup = [
          {
            param: 2,
            btnText: translate('time_session_stateid_2'),
            loading: 'break',
          },
          {
            param: 3,
            btnText: translate('time_session_stateid_3'),
            loading: 'travel_back',
            disabled: openTimelog,
          },
          {
            param: 4,
            btnText: translate('close'),
            loading: 'close',
            disabled: openTimelog,
          },
        ]
      }

      if (state === 2) {
        btnGroup = [
          {
            param: 1,
            btnText: translate('time_session_stateid_1'),
            loading: 'work',
          },
          {
            param: 3,
            btnText: translate('time_session_stateid_3'),
            loading: 'travel_back',
            disabled: openTimelog,
          },
          {
            param: 4,
            btnText: translate('close'),
            loading: 'close',
            disabled: openTimelog,
          },
        ]
      }

      if (state === 3) {
        btnGroup = [
          {
            param: 4,
            btnText: translate('close'),
            loading: 'close',
            disabled: openTimelog,
          },
        ]
      }
    }

    const btns = (el, i) => (
      <Tooltip
        key={i}
        placement="top"
        className="time-session__flex-container"
        title={el.disabled ? translate('all_timelogs_must_be_closed') : null}
      >
        <span>
          <Button
            key={i}
            title={el.btnText}
            type="primary"
            disabled={el.disabled}
            loading={this.state[el.loading]}
            size="large"
            onClick={e =>
              this.updateTimeSession({
                e,
                state: el.param,
                loading: el.loading,
                general: true,
              }).then(() => {
                this.getTimeSessions({})
                this.getTimeSessions({closedTimeSessions: true})
              })
            }
          />
        </span>
      </Tooltip>
    )
    if (redirect)
      return (
        <Redirect to={{pathname: redirectTo, state: {callGoBack: true}}} push />
      )

    return (
      <>
        <Spin spinning={loading}>
          <Row className="time-session">
            <Col span={24}>
              <div className="time-session__flex-container">
                {btnGroup.map((el, i) => btns(el, i))}
              </div>
              <h4>{translate('current_time_session')}</h4>
              {this.props.timeSession &&
                `${this.props.timeSession.state_id}` && (
                  <div className="fs-14">{`${translate('status')}: ${
                    timeSessionStatesT[this.props.timeSession.state_id]
                  }`}</div>
                )}
              <TimespansTable
                timeSession={this.props.timeSession}
                getTimeSessions={this.getTimeSessions}
              />
              <h4>{translate('tickets')}</h4>
              <DataTable
                bordered={true}
                rowKey="uuid"
                data={this.state.tickets}
                columns={this.getTicketsColumns()}
                rowClassName="pointer-cursor"
                onRowClick={this.rowClick}
              />
              <h4>{translate('resolved_timesessions')}</h4>
              <TimeSessionsTable
                timeSessions={closedTimeSessions}
                getTimeSessions={this.getTimeSessions}
              />
            </Col>
          </Row>
        </Spin>
        <Modal
          open={this.state.open_timelog_warning}
          modalType={ModalType.INFO}
          cancelText={translate('close')}
          handleCancel={() => {
            this.setState({open_timelog_warning: undefined})
          }}
          content={translate('all_timelogs_must_be_closed')}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    state: state.general,
    userState: state.itsmUser,
    timeSession: state.timeSession.timeSession,
  }
}

export default connect(mapStateToProps, {
  setToastMessage,
  setRefresh,
})(TimeSession)
