import moment from 'moment'

import {TSlaValues} from '@/modules/ITSM/components/incident/incident-reducer'

import {IncidentTaskState, TIncidentTask} from '../typedf'

export const getRemainingTime = (
  record: TIncidentTask,
  slaValues: TSlaValues
) => {
  const {state_id} = record

  if (
    state_id === IncidentTaskState.New ||
    state_id === IncidentTaskState.In_progress
  ) {
    if (new Date().getTime() < new Date(slaValues.endTime).getTime()) {
      return {
        remainingTime: moment(slaValues.endTime).diff(moment(), 'seconds'),
        elapsedTime: moment().diff(
          moment(slaValues.startTime).utc(),
          'seconds'
        ),
      }
    } else {
      return {
        remainingTime: 0,
        elapsedTime: moment().diff(moment(slaValues.startTime), 'seconds'),
      }
    }
  } else {
    return {
      remainingTime: 0,
      elapsedTime: moment(slaValues.endTime).diff(
        moment(slaValues.startTime),
        'seconds'
      ),
    }
  }
}
