import {TUserPermissions} from '@/typedef'

export enum Feature {
  DAILY_REPORTS = 'DAILY_REPORTS',
  FINANCE = 'FINANCE',
}

export type TPermissionMap = {
  features?: {
    [key in Feature]: TUserPermissions
  }
}
