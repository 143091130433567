import {useCallback, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResponse} from '@/api/response-typedf'

export function useApiData<T extends Record<string, any>, U = T>(
  asyncPatchF: (payload: T) => Promise<ISuperAgentResponse<U>>
) {
  const {
    error,
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const [entityData, setEntityData] = useState<U>()

  const run = useCallback(
    async (payload: T) => {
      try {
        handleRequested()
        if (asyncPatchF) {
          const result = await asyncPatchF(payload)
          if (result) {
            handleSuccess()
            setEntityData(result.body)
            return result
          }
        }

        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [asyncPatchF, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    error,
    entityData,
    run,
    status,
  }
}
