import React from 'react'

import {Tooltip} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {v4} from 'uuid'

import {Button} from '@/components/button'
import {AssigneeStates} from '@/modules/ITSM/components/incident-k-request/constants'
import {translate} from '@/services/i18n'
import {
  setCurrentTab,
  setRejectReason,
} from '@/modules/ITSM/components/incident/incident-reducer'
import {ShareButton} from '@/components/share-button/share-button'
import {generateCopyLink} from '@/modules/ITSM/utils/Helpers'
import {selectUserID} from '@/redux/user/userSelector'
import CheckInBtn from '@/modules/ITSM/components/time-logs/check-in-btn'
import {TTimeLog} from '@/modules/ITSM/typedef'
import {useAcceptOrReject} from '@/modules/ITSM/components/incident/hooks/useAcceptOrReject'
import {checkIsLoading} from '@/utils/check-is-loading'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'

import {useStateContext} from '../../incident/incident-context'
import {IncidentTaskState, IncTaskTabs, TIncidentTask} from '../typedf'

import './incident-task-btns.scss'

const IncidenTaskBtns = () => {
  const {entityData, dispatch, getData, incState} = useStateContext<
    TIncidentTask
  >()

  const {
    saveEntity: acceptOrReject,
    saveEntityStatus: acceptOrRejectStatus,
  } = useAcceptOrReject()

  const userID = useSelector(selectUserID)
  const history = useHistory()

  const {timelogs, state_id, uuid, assigned_to, incident, assignee_state} =
    entityData || {}

  const isTimelogOpen =
    timelogs && timelogs.some((timelog: TTimeLog) => !timelog.end)

  const assignedToCurrentUser = assigned_to?.uuid === userID

  const {currentTab, isRejectReason} = incState || {}

  const btnsGroup = [
    {
      visible:
        state_id === IncidentTaskState.In_progress &&
        isTimelogOpen &&
        assignedToCurrentUser,

      title: translate('check_out'),
      action: () => dispatch(setCurrentTab(IncTaskTabs.TimeLogsTab)),
    },
    {
      visible:
        state_id === IncidentTaskState.In_progress && assignedToCurrentUser,
      title: translate('resolve'),
      action: () => dispatch(setCurrentTab(IncTaskTabs.ResolutionTab)),
      tooltipTitle: isTimelogOpen ? translate('timelog_must_be_closed') : null,
      disabled:
        !!isTimelogOpen ||
        !!(currentTab && currentTab === IncTaskTabs.ResolutionTab),
    },

    {
      visible:
        state_id === IncidentTaskState.New &&
        assignedToCurrentUser &&
        assignee_state === AssigneeStates.Proposed,
      title: translate('accept'),
      disabled: checkIsLoading(acceptOrRejectStatus),
      action: async () => {
        const result =
          entityData &&
          (await acceptOrReject({
            entity: `incident_task:${entityData.uuid}`,
            decision: 'accepted',
          }))

        if (result !== undefined) getData(true)
      },
    },

    {
      visible:
        state_id === IncidentTaskState.New &&
        assignedToCurrentUser &&
        assignee_state === AssigneeStates.Proposed,
      title: translate('reject'),
      action: () => {
        entityData && dispatch(setCurrentTab(entityData.assignee_state))
        dispatch(setRejectReason(true))
      },
      disabled: isRejectReason,
    },

    {
      title: translate('go_to_parent'),
      action: () =>
        entityData &&
        incident?.uuid &&
        history.push(
          itsmRoutes.serviceManagementIncidentsDetail.path(incident.uuid)
        ),
      visible: true,
      tooltipTitle: '',
    },
  ]

  const incident_task_link = generateCopyLink('incident-task', uuid)

  return (
    <>
      <div className="incident-task-btns">
        <div className="flex">
          {!isTimelogOpen &&
            assignedToCurrentUser &&
            assignee_state === AssigneeStates.Accepted && (
              <CheckInBtn isEnabled />
            )}
          {btnsGroup.map(
            btn =>
              btn.visible && (
                <Tooltip
                  placement="top"
                  key={btn.title}
                  className="flex flex--justifyCenter flex--alignCenter mr-10"
                  title={btn.tooltipTitle}
                >
                  <span>
                    <Button
                      title={btn.title}
                      disabled={btn.disabled}
                      onClick={btn.action}
                      type={'text'}
                      size="large"
                    />
                  </span>
                </Tooltip>
              )
          )}
          {incident_task_link && (
            <ShareButton value={incident_task_link} type="text" />
          )}

          <Button
            title={translate('incident_task')}
            icon={<PlusOutlined />}
            className="ml-10"
            size="large"
            type="primary"
            onClick={() =>
              history.push(
                itsmRoutes.serviceManagementIncidentsTaskNew.path(
                  v4(),
                  incident?.uuid
                )
              )
            }
          />
        </div>
      </div>
    </>
  )
}

export default IncidenTaskBtns
