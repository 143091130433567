import {SortingKeys} from '@/typedef'
import {
  assignmentGroupsApi,
  categoriesApi,
  locationsApi,
  productApi,
  userApi,
  userGroupsApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'
import {extensionfilters, invitationState} from '@/modules/ITSM/utils/Constants'

import {fetchOrgsRole} from '../../../api/itsmOrgRequests'
import {getOrgRoles} from '../../../sites/ITSMSettings/AssignmentGroups/utils/get-orgs-roles'
import {ItsmAssets} from '../../../typedef'
import {fetchUsers} from '../../../api/userRequests'
import {getOptionContent} from '../utils/get-option-content'
import {getInitData} from '../utils/get-init-data'
import {priorityOptions} from '../../incident/constants'
import {
  ASSIGNEE_STATE_OPTIONS,
  CONTACT_TYPES_OPTIONS,
  INCIDENT_STATE_OPTIONS,
} from '../../incident-k-request/constants'
import {IncidentDefFields} from '../itsm-list-table-incidents-requests/column-table-config'
import {countriesList} from '../../createNewLocation/countries'
import {
  TApiServiceInitFetch,
  TBooleanOptions,
  TDefaultFields,
  TOptionBMColumnKeys,
  TOptionColumnKeys,
  TOptionFetchColumnKeys,
} from '../typedf'
import {prepareOptionSelect} from '../utils/prepare-option-select'

const baseFilters = [SortingKeys.created_at, SortingKeys.updated_at]

export const itsmSorters: {[key in ItsmAssets]?: Array<SortingKeys>} = {
  [ItsmAssets.AssignmentGroups]: [SortingKeys.created_at],
  [ItsmAssets.IncidentsUP]: [...baseFilters],
  [ItsmAssets.RequestsUP]: [...baseFilters],
  [ItsmAssets.RejectReasons]: [SortingKeys.created_at],
  [ItsmAssets.UserGroups]: [SortingKeys.created_at],
  [ItsmAssets.AssignmentRules]: [SortingKeys.created_at],
  [ItsmAssets.Notifications]: [...baseFilters],
  [ItsmAssets.Categories]: [SortingKeys.created_at],
  [ItsmAssets.Incidents]: [...baseFilters],
  [ItsmAssets.Requests]: [...baseFilters],
  [ItsmAssets.Expenses]: [SortingKeys.created_at],
  [ItsmAssets.Locations]: [...baseFilters],
}

export const getOptionColumnKeys = <
  T extends Record<string, any>
>(): TOptionColumnKeys<T> => ({
  [ItsmAssets.IncidentsUP]: {
    priority: priorityOptions,
    state_id: INCIDENT_STATE_OPTIONS,
    assignee_state: ASSIGNEE_STATE_OPTIONS,
    contact_type: CONTACT_TYPES_OPTIONS,
  },
  [ItsmAssets.Incidents]: {
    priority: priorityOptions,
    state_id: INCIDENT_STATE_OPTIONS,
    assignee_state: ASSIGNEE_STATE_OPTIONS,
    contact_type: CONTACT_TYPES_OPTIONS,
  },
  [ItsmAssets.RequestsUP]: {
    priority: priorityOptions,
    state_id: INCIDENT_STATE_OPTIONS,
    assignee_state: ASSIGNEE_STATE_OPTIONS,
    contact_type: CONTACT_TYPES_OPTIONS,
  },
  [ItsmAssets.Requests]: {
    priority: priorityOptions,
    state_id: INCIDENT_STATE_OPTIONS,
    assignee_state: ASSIGNEE_STATE_OPTIONS,
    contact_type: CONTACT_TYPES_OPTIONS,
  },
  [ItsmAssets.BillingItems]: {
    country: prepareOptionSelect(countriesList, 'name', 'name'),
  },
  [ItsmAssets.Locations]: {
    country: prepareOptionSelect(countriesList, 'name', 'name'),
  },
  [ItsmAssets.Invitations]: {
    state: invitationState.map(({name, value}) => ({
      name,
      value,
    })),
  },
  [ItsmAssets.Documents]: {
    file_type: extensionfilters,
  },
})

export const getOptionFetchColumnKeys = <
  T extends Record<string, any>
>(): TOptionFetchColumnKeys<T> => {
  const orgNameFetch = {
    asyncF: fetchOrgsRole,
    getOptionContent: (entityData: Record<string, unknown>) =>
      getOptionContent(entityData, 'org_name'),
  }

  return {
    [ItsmAssets.AssignmentGroups]: {
      org_name: orgNameFetch,
    },

    [ItsmAssets.SupplierProducts]: {
      org_name: orgNameFetch,
    },
  }
}

export const getOptionBMColumnKeys = <
  T extends Record<string, any>
>(): TOptionBMColumnKeys<T> => ({
  [ItsmAssets.IncidentsUP]: {
    location: {
      api: locationsApi().get,
    },
    created_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    updated_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    caller: {
      api: userApi().get,
      optionContent: 'full_name',
    },
  },
  [ItsmAssets.Incidents]: {
    location: {
      api: locationsApi().get,
    },
    caller: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    created_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    updated_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    assigned_to: {
      api: userApi().get,
      optionContent: 'full_name',
    },
  },
  [ItsmAssets.Requests]: {
    location: {
      api: locationsApi().get,
    },
    caller: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    created_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    updated_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    assigned_to: {
      api: userApi().get,
      optionContent: 'full_name',
    },
  },
  [ItsmAssets.RequestsUP]: {
    location: {
      api: locationsApi().get,
    },
    caller: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    created_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    updated_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    approver: {
      api: userApi().get,
      optionContent: 'full_name',
    },
  },
  [ItsmAssets.AssignmentRules]: {
    assignment_group: {
      api: assignmentGroupsApi().get,
    },
    location: {
      api: locationsApi().get,
    },
    category: {
      api: categoriesApi().get,
      selector: {parent: {$exists: false}},
    },
    subcategory: {
      api: categoriesApi().get,
      selector: {parent: {$exists: true}},
    },
    assignee: {
      api: userApi().get,
      optionContent: 'full_name',
    },
  },
  [ItsmAssets.Notifications]: {
    user_group: {
      api: userGroupsApi().get,
    },
  },
  [ItsmAssets.BillingItems]: {
    user: {
      api: userApi().get,
      optionContent: 'full_name',
    },
    customer_product: {
      api: productApi().customer,
    },
  },
  [ItsmAssets.Locations]: {
    created_by: {
      api: userApi().get,
      optionContent: 'full_name',
    },
  },
})

export const apiServiceInitFetch = <
  T extends Record<string, any>
>(): TApiServiceInitFetch<T> => ({
  [ItsmAssets.AssignmentGroups]: [
    {
      key: 'org_name',
      asyncF: getOrgRoles,
    },
  ],
  [ItsmAssets.Identities]: [
    {
      key: 'email',
      asyncF: () => getInitData(fetchUsers, 'email', 'uuid'),
    },
    {
      key: 'full_name',
      asyncF: () => getInitData(fetchUsers, 'full_name', 'uuid'),
    },
  ],
  // [ItsmAssets.IncidentsUP]: [
  //   {
  //     key: 'cmdb_ref',
  //     asyncF: () => getInitData(fetchAssets, 'name', 'id'),
  //   },
  // ],
  // [ItsmAssets.RequestsUP]: [
  //   {
  //     key: 'cmdb_ref',
  //     asyncF: () => getInitData(fetchAssets, 'name', 'id'),
  //   },
  // ],
  [ItsmAssets.SupplierProducts]: [
    {
      key: 'org_name',
      asyncF: getOrgRoles,
    },
  ],
})

export const getBooleanOptions = <
  T extends Record<string, any>
>(): TBooleanOptions<T> => ({
  [ItsmAssets.Identities]: {
    is_enabled: {
      true: {is_enabled: true},
      false: {is_enabled: false},
    },
  },
})

export const getDefaultFields = <
  T extends Record<string, any>
>(): TDefaultFields<T> => ({
  [ItsmAssets.IncidentsUP]: IncidentDefFields,
  [ItsmAssets.Incidents]: IncidentDefFields,
  [ItsmAssets.Requests]: IncidentDefFields,
  [ItsmAssets.RequestsUP]: [
    'uuid',
    'state_id',
    'response_time_due',
    'resolution_time_due',
    'resolution_time_start',
    'resolved_at',
    'closed_at',
    'expected_start',
    'on_hold_start',
    'sla_elapsed_time',
    'virtual_resolution_time_start',
    'virtual_response_time_start',
    'created_by',
    'updated_by',
    'approval_state',
    'approver',
    'approval_state_updated_at',
  ],
})

export const keysToDelete: {
  [key in ItsmAssets]?: Record<string, string>
} = {
  [ItsmAssets.IncidentsUP]: {
    location: 'country',
    country: 'location',
  },
  [ItsmAssets.Incidents]: {
    location: 'country',
    country: 'location',
  },
  [ItsmAssets.Requests]: {
    location: 'country',
    country: 'location',
  },
  [ItsmAssets.RequestsUP]: {
    location: 'country',
    country: 'location',
  },
  [ItsmAssets.Identities]: {
    full_name: 'email',
    email: 'full_name',
  },
  [ItsmAssets.BillingItems]: {
    work_start: 'work_end',
    work_end: 'work_start',
  },
}

export const nestedFilters: {
  [key in ItsmAssets]?: Record<string, Array<string>>
} = {
  [ItsmAssets.BillingItems]: {
    country: ['location', 'country'],
    city: ['location', 'city'],
  },
  [ItsmAssets.CustomerProducts]: {
    country: ['location', 'country'],
    city: ['location', 'city'],
    rate: ['pricing_policy', 'rate'],
    primary_hour_rate: ['pricing_policy', 'primary_hour_rate'],
    additional_hour_rate: ['pricing_policy', 'additional_hour_rate'],
  },
  [ItsmAssets.BillingItems]: {
    country: ['location', 'country'],
    city: ['location', 'city'],
  },
}
