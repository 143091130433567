import React, {useContext, useState} from 'react'

import {Form, Select, Spin} from 'antd'
import {useDispatch, useSelector} from 'react-redux'

import {translate} from '@/services/i18n'
import {Modules, RequestStatus} from '@/typedef'
import {filterOption} from '@/utils/filter-option'
import {Button} from '@/components/button'
import {checkIsLoading} from '@/utils/check-is-loading'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import ProfileIdentityWrapper from '@/modules/ITSM/components/profile-identity-wrapper/profile-identity-wrapper'
import {postMyProfileWithHeaders} from '@/api/my-profile-requests'
import {createAuthorizationHeader} from '@/utils/create-authorization-header'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {useHistory} from 'react-router-dom'
import {InviteOrgContext} from '@/sites/accept-org-invite/context/org-invite-context'
import {getProfile, setModuleName} from '@/redux/user/userActions'
import {useApiData} from '@/hooks/use-api-data'
import {getMyAccountsTS, getOrgsTS} from '@/api/account-requests'
import {fetchMySpacesTS} from '@/modules/ITSM/api/itsm-org-requests'
import {getBEndToken} from '@/api/token-request'
import {TProfileForm} from '@/sites/accept-org-invite/typedef'
import OrgInviteErrorModal from '@/sites/accept-org-invite/error-modal/org-invite-error-modal'
import {selectBEndToken} from '@/redux/user/user-selector'
import {resetModuleState} from '@/utils/reset-module-state'

const {Option} = Select

type TOrgChannelDef = {
  account: string
  organization: string
  channel: string
}

export const ProfileForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {state} = useContext(InviteOrgContext)
  const [reloadCount, setReloadCount] = useState(0)
  const [hasError, setHasError] = useState(false)
  const token = useSelector(selectBEndToken)

  const {run: saveBEndToken, status: saveBEndTokenStatus} = useApiData(
    getBEndToken
  )

  const {
    entityData: accounts,
    run: getMyAccounts,
    status: getMyAccountsStatus,
  } = useApiData(getMyAccountsTS)

  const {
    entityData: organizations,
    run: getOrganizations,
    status: getOrganizationsStatus,
  } = useApiData(getOrgsTS)

  const {
    entityData: channels,
    run: getChannels,
    status: getChannelsStatus,
  } = useApiData(fetchMySpacesTS)

  const {run: postMyProfile, status: postMyProfileStatus} = useApiData(
    postMyProfileWithHeaders
  )

  const handleOrgSelect = async (orgID: string) => {
    form.resetFields(['channel'])

    const tokenResponse = await saveBEndToken({
      scope: 'org',
      id: orgID,
    })
    const tokenWithScopeAccResponse = tokenResponse?.body.access_token

    if (tokenWithScopeAccResponse) {
      await getChannels(createAuthorizationHeader(tokenWithScopeAccResponse))
    }
  }

  const handleAccountSelect = async (accountId: string) => {
    form.resetFields(['organization', 'channel'])
    await getOrganizations({
      accountId,
      headers: createAuthorizationHeader(state.accessToken || token),
    })
  }

  const handleSubmitProfile = async (profileData: TProfileForm) => {
    const profileResponse = await postMyProfile({
      payload: {
        accountId: profileData.account,
        orgId: profileData.organization,
        channelId: profileData.channel,
      },

      headers: createAuthorizationHeader(state.accessToken || token),
    })

    if (!profileResponse) return setHasError(true)

    resetModuleState()
    dispatch(setModuleName(Modules.ITSM))
    dispatch(getProfile())

    history.push(itsmRoutes.serviceManagementDashboard.path())
  }

  return (
    <ProfileIdentityWrapper
      header={translate('org_and_channel')}
      subHeader={translate('org_channel_selector_header')}
    >
      <OrgInviteErrorModal
        handleReload={() => setHasError(false)}
        visible={hasError}
        setReloadCount={setReloadCount}
        reloadCount={reloadCount}
      />
      <Form<TProfileForm>
        layout="vertical"
        className="profile-form"
        e2e-test="profile-form"
        form={form}
        name={'orgChannelDefault'}
        onFinish={handleSubmitProfile}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{required: '${label} is required'}}
      >
        <FormItemGeneric<TOrgChannelDef>
          e2e-test="account-select"
          isGrey
          label={translate('account')}
          name={'account'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            onDropdownVisibleChange={(open: boolean) =>
              open &&
              getMyAccounts(
                createAuthorizationHeader(state.accessToken || token)
              )
            }
            onChange={handleAccountSelect}
            filterOption={filterOption}
            getPopupContainer={e => e.parentNode}
          >
            {getMyAccountsStatus === RequestStatus.REQUESTED ? (
              <Option value="loading" key="loading">
                <Spin />
              </Option>
            ) : (
              accounts?.map(account => {
                return (
                  <Option key={account.id} value={account.id}>
                    {account.name}
                  </Option>
                )
              })
            )}
          </Select>
        </FormItemGeneric>
        <FormItemGeneric<TOrgChannelDef>
          e2e-test="organization-select"
          isGrey
          label={translate('organization')}
          name={'organization'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            onChange={handleOrgSelect}
            filterOption={filterOption}
            disabled={!form.getFieldValue('account')}
            getPopupContainer={e => e.parentNode}
          >
            {getOrganizationsStatus === RequestStatus.REQUESTED ? (
              <Option value="loading" key="loading">
                <Spin />
              </Option>
            ) : (
              organizations?.map(organization => {
                return (
                  <Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Option>
                )
              })
            )}
          </Select>
        </FormItemGeneric>
        <FormItemGeneric<TOrgChannelDef>
          e2e-test="channel-select"
          isGrey
          label={translate('channel')}
          name={'channel'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            className="full-width"
            filterOption={filterOption}
            disabled={!form.getFieldValue('organization')}
            getPopupContainer={e => e.parentNode}
          >
            {checkIsLoading([saveBEndTokenStatus, getChannelsStatus]) ? (
              <Option value="loading" key="loading">
                <Spin />
              </Option>
            ) : (
              channels?.spaces
                ?.filter(space => space.active)
                .map(space => (
                  <Option value={space.space} key={space.space}>
                    {space.name}
                  </Option>
                ))
            )}
          </Select>
        </FormItemGeneric>
        <div className="flex flex--justifyCenter mt-24">
          <Button
            title={translate('submit')}
            type="primary"
            htmlType="submit"
            disabled={checkIsLoading(postMyProfileStatus)}
            loading={checkIsLoading(postMyProfileStatus)}
            className="mr-10"
            e2e-test="profile-submit"
          />
        </div>
      </Form>
    </ProfileIdentityWrapper>
  )
}
