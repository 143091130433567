import {useEffect} from 'react'

import {useSelector} from 'react-redux'

import {selectAppVersion, selectUserID} from '@/redux/user/userSelector'
import useIsMobile from '@/hooks/use-is-mobile'

type TStonlyInitProperties = {
  app_version?: 'portal' | 'app'
  mobile?: boolean
}

declare global {
  interface Window {
    stonlyTrack: (
      type: string,
      userId?: string,
      properties?: TStonlyInitProperties
    ) => void
  }
}

export const useStonlyInit = () => {
  const userId = useSelector(selectUserID)
  const app_version = useSelector(selectAppVersion)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (userId) {
      if (typeof window.stonlyTrack === 'function') {
        window.stonlyTrack('identify', userId, {app_version, mobile: isMobile})
      }
    }
  }, [userId, app_version, isMobile])
}
