import request from 'superagent'
import {organizationApi} from '@/services/api'
import {accountApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {THeaders} from '@/api/typedf'
import {ISuperAgentResponse} from '@/api/response-typedf'
import {TAccount, TOrganization} from '@/typedef'

export const getMyAccountsTS = async (
  headers: THeaders
): Promise<ISuperAgentResponse<Array<TAccount>>> =>
  await request.get(accountApi().get).set(headers)

export const getOrgsTS = async ({
  accountId,
  headers,
}: {
  accountId: string
  headers: THeaders
}): Promise<ISuperAgentResponse<Array<TOrganization>>> =>
  await request.get(organizationApi.getForAccount(accountId)).set(headers)
