export enum defineStepProgressSteps {
  INITIAL = 'INITIAL',
  MY_ACCOUNTS = 'MY_ACCOUNTS',
  REFRESH_ACC_TOKEN = 'REFRESH_ACC_TOKEN',
  MY_ORGS = 'MY_ORGS',
  REFRESH_ORG_TOKEN = 'REFRESH_ORG_TOKEN',
  MY_SPACES = 'MY_SPACES',
  MY_SUBSCRIPTIONS = 'MY_SUBSCRIPTIONS',
  REDIRECT = 'REDIRECT',
}

export const defineStepProgress = {
  [defineStepProgressSteps.INITIAL]: 0,
  [defineStepProgressSteps.MY_ACCOUNTS]: 17,
  [defineStepProgressSteps.REFRESH_ACC_TOKEN]: 25,
  [defineStepProgressSteps.MY_ORGS]: 45,
  [defineStepProgressSteps.REFRESH_ORG_TOKEN]: 52,
  [defineStepProgressSteps.MY_SPACES]: 73,
  [defineStepProgressSteps.MY_SUBSCRIPTIONS]: 87,
  [defineStepProgressSteps.REDIRECT]: 100,
}
