import {useEffect, useRef} from 'react'

import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {setCurrentLocation} from '@/redux/actions'

const HistoryListener = ({children}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isInitialMount = useRef(true)

  useEffect(() => {
    dispatch(setCurrentLocation(history.location.pathname))
    return history.listen(location => {
      dispatch(setCurrentLocation(location.pathname))
    })
  }, [history, dispatch])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
  }, [])

  return children
}

export default HistoryListener
