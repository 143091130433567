import React, {useContext} from 'react'

import {CreateAccountStep} from '@/sites/account-default/components/create-account-step'
import {PaymentStep} from '@/sites/account-default/components/payment-step'
import ProfileIdentityWrapper from '@/modules/ITSM/components/profile-identity-wrapper/profile-identity-wrapper'
import {SetupCallStep} from '@/sites/account-default/components/setup-call-step'
import {SignupStepper} from '@/sites/account-default/components/signup-stepper'

import './account-default-steps.scss'

import {translate} from '@/services/i18n'

import {OverlaySpinner} from '@/components/overlay-spinner'
import {AccountDefaultContext} from '@/sites/account-default/account-default-context/account-default-context'
import {useDefineAccountDefaultStep} from '@/sites/account-default/hooks/use-define-account-default-step'

import {AccountCreateStep, TSignupSteps} from '@/sites/account-default/typedef'
import {AccountDefaultErrorModal} from '@/sites/account-default/components/account-default-error-modal'
import {useShouldIncludeSteps} from '@/sites/account-default/hooks/use-should-include-steps'
import {Progress} from 'antd'

export const AccountDefaultSteps = () => {
  const {state} = useContext(AccountDefaultContext)

  useDefineAccountDefaultStep()

  const {hasPaymentStep, hasCalendlyStep} = useShouldIncludeSteps()

  const steps: TSignupSteps = {
    [AccountCreateStep.CREATE_ACCOUNT]: {
      id: AccountCreateStep.CREATE_ACCOUNT,
      content: <CreateAccountStep />,
      header: translate('create_new_account'),
    },
    ...(hasPaymentStep
      ? {
          [AccountCreateStep.PAYMENT]: {
            id: AccountCreateStep.PAYMENT,
            content: <PaymentStep />,
            header: translate(
              state.billingSubscriptions.length > 0 ? 'paid' : 'payment'
            ),
          },
        }
      : {}),
    ...(hasCalendlyStep
      ? {
          [AccountCreateStep.SCHEDULE_CALENDLY]: {
            id: AccountCreateStep.SCHEDULE_CALENDLY,
            content: <SetupCallStep />,
            header: translate('set_up_call'),
          },
        }
      : {}),
  }

  if (state.errorType) {
    return <AccountDefaultErrorModal />
  }

  return (
    <ProfileIdentityWrapper
      isFullWidth
      className="account-default-steps__wrapper"
    >
      {state.progressStatus !== null && (
        <div className={'mb-14'}>
          <Progress percent={state.progressStatus} />
        </div>
      )}
      {(state.step === null && state.isLoading) || state.step === null ? (
        <OverlaySpinner
          idName={'account-default'}
          text={translate('we_are_setting_up_your_workspace_please_wait')}
        />
      ) : (
        <>
          <SignupStepper steps={steps} currentStep={state.step} />
          {steps[state.step].content}
        </>
      )}
    </ProfileIdentityWrapper>
  )
}
