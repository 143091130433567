export const generateSelector = (filters: Record<string, any>) => {
  const filter: Record<string, any> = {
    $and: [],
  }

  for (const filtersKey in filters) {
    if (
      Object.prototype.hasOwnProperty.call(filters, filtersKey) &&
      filters[filtersKey]
    )
      filter.$and.push({[filtersKey]: {$eq: `${filters[filtersKey]}`}})
  }
  return filter
}
