import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import arrayMove from 'array-move'
import {v4 as uuid} from 'uuid'

import DataTable from '@/components/data-table/data-table'
import {PaginationWithBookmarks} from '@/components/pagination/pagination-with-bookmarks'
import {TAppState} from '@/redux/store'
import {TableColumnsCustomizer} from '@/components/table-columns-customizer/table-columns-customizer'
import {TKey, translate} from '@/services/i18n'
import {checkIsLoading} from '@/utils/check-is-loading'
import {SortingKeys, SortingValues} from '@/typedef'
import {useQuery} from '@/utils/useQuery'
import ListTableHeader from '@/components/list-table-header/list-table-header'
import SentryErrorBoundary from '@/components/error/sentry-error-boundary'
import ErrorPage from '@/components/error/error-page/error-page'
import {Drawer} from '@/components/drawer'

import {apiService} from '../../../api/api-service'
import {TPagination} from '../../../store/list-table/reducer'
import {ItsmAssets} from '../../../typedef'
import {
  selectItsmAssetPagination,
  selectItsmTableColumns,
  selectItsmTableFilters,
  selectItsmTableSorters,
} from '../../../store/list-table/selectors'
import {
  resetItsmTableFilter,
  resetItsmTableFilters,
  setItsmTableColumns,
  setItsmTableFilters,
  setItsmTableSorters,
} from '../../../store/list-table/table-actions'
import {
  generateActiveColumns,
  normalizeQueryColumns,
} from '../utils/generate-active-columns'
import {useGenerateInputFiler} from '../hooks/use-generate-input-filter'
import {useInitFetchData} from '../hooks/use-get-init-fetch'
import {getNewAssetTitle} from '../utils/get-new-asset-title'
import {
  apiServiceInitFetch,
  itsmSorters,
  keysToDelete,
} from '../constants/filters-config'
import {TColumnRender} from '../typedf'

import {useGetDataListTable} from './hooks/use-get-data-list-table'
import {createStateContext} from './itsm-list-table-settings-context'
import './itsm-list-table-settings.scss'
import {entityDetailPath, entityNewPath} from './constants/routes-config'
import {Button} from '@/components/button'
import {PlusOutlined} from '@ant-design/icons'

type TItsmListTableSettingsProps<T> = {
  asset: ItsmAssets
  newComponent?: React.ReactNode
  detailComponent?: React.ReactNode
  drawerTitleNew?: string
  drawerTitleDetail?: string
  exportBtn?: React.ReactNode
  getDetailComponentTitle?: (record: T) => string
  getRowValue?: (props: TColumnRender<T>) => React.ReactNode
  rowKey: keyof T
}

export const ListTableCotext = createStateContext()

const ItsmListTableSettings = <T extends Record<string, any>>({
  asset,
  newComponent,
  detailComponent,
  drawerTitleNew,
  drawerTitleDetail,
  exportBtn,
  getDetailComponentTitle,
  getRowValue,
  rowKey,
}: TItsmListTableSettingsProps<T>) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {quickFilter} = useParams<{quickFilter: string}>()

  const [previewMode, setPreviewMode] = useState(false)

  const [drawerNew, setDrawerNew] = useState(false)
  const [drawerDetail, setDrawerDetail] = useState<T | undefined>()

  const {
    getEntityData,
    entityData,
    numberOfRecords,
    fetchStatus,
    selectorIds,
    setInitSelectorIds,
  } = useGetDataListTable<T>(apiService[asset], asset)

  const {initFetchData} = useInitFetchData(apiServiceInitFetch<T>()[asset])

  useEffect(() => {
    if (entityData && drawerDetail?.uuid) {
      setDrawerDetail(entityData.find(({uuid}) => uuid === drawerDetail.uuid))
    }
  }, [entityData, drawerDetail])

  const filtersQuery = useQuery('filters')
  const sortQuery = useQuery('sort')
  const columnsQuery = useQuery('columns')

  useEffect(() => {
    if (filtersQuery || sortQuery || columnsQuery) {
      setPreviewMode(true)
    }
  }, [columnsQuery, filtersQuery, sortQuery])

  const pagination = useSelector((state: TAppState) =>
    selectItsmAssetPagination(state, asset)
  )

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const columns =
    useSelector((state: TAppState) => selectItsmTableColumns(state, asset)) ||
    []

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters(state, asset)
  )

  const handleNew = () => {
    if (
      asset === ItsmAssets.CustomerProducts ||
      asset === ItsmAssets.SupplierProducts
    ) {
      history.push(entityNewPath[asset]?.(uuid()))
    }
    setDrawerNew(true)
  }

  const {
    generatedFilters,
    inputValues,
    resetFilter,
    resetAllFilters,
  } = useGenerateInputFiler<T>(asset)

  const handleSorter = (sorter: Record<SortingKeys, SortingValues> | null) => {
    dispatch(setItsmTableSorters(asset, sorter))
  }

  const handleResetFilters = (asset: ItsmAssets) => {
    dispatch(resetItsmTableFilters(asset))
    resetAllFilters()
    setInitSelectorIds()
  }

  const onSearch = (key: string) => {
    if (keysToDelete[asset]?.[key]) {
      delete inputValues[keysToDelete[asset]?.[key] as string]
      setInitSelectorIds(keysToDelete[asset]?.[key], asset)
    }

    dispatch(setItsmTableFilters(asset, inputValues))
  }

  const onReset = (key: string) => {
    dispatch(resetItsmTableFilter(asset, key))
    resetFilter(key)
    setInitSelectorIds(key, asset)
  }

  const handleActiveCols = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const movedItems = arrayMove(columns, oldIndex, newIndex)
      dispatch(setItsmTableColumns(asset, movedItems))
    }
  }

  const handleSetSelectedColumn = (selectedTitle: string, isAdded: boolean) => {
    const dataWithSelectedColumn = columns.map(data => {
      return data.title === selectedTitle ? {...data, selected: isAdded} : data
    })

    dispatch(setItsmTableColumns(asset, dataWithSelectedColumn))
  }

  const handleRowClick = (record: T) => {
    if (
      record?.docType === 'CUSTOMER_PRODUCT' ||
      record?.docType === 'SUPPLIER_PRODUCT'
    ) {
      history.push(entityDetailPath[asset]?.(record.uuid), {
        quickFilter,
      })
    } else {
      setDrawerDetail(record)
    }
  }

  const getDrawerTitle = () => {
    if (drawerDetail) {
      if (drawerTitleDetail) return drawerTitleDetail
      if (getDetailComponentTitle) return getDetailComponentTitle(drawerDetail)
      return drawerDetail?.name
    }

    return drawerTitleNew && getNewAssetTitle(drawerTitleNew)
  }
  const handleOnclose = () => {
    if (drawerNew) setDrawerNew(false)
    else setDrawerDetail(undefined)
  }

  const draggableData = columns.map(column => ({
    title: column.title as TKey,
    isChecked: column.selected,
  }))

  const filterSelector = {
    ...((sortQuery || sorter) && {
      passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
    }),
    ...((filtersQuery || filters || selectorIds) && {
      passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
        ...filters,
        ...(selectorIds || {}),
      },
    }),
  }

  const isAllowedAddNew = entityData?.every(product => product.pricing_policy)

  const header = (
    <div className="flex flex--justifyEnd">
      <Button
        className={'buttons list-table-header__btn-new'}
        type="primary"
        title={translate('add_new')}
        onClick={handleNew}
        icon={<PlusOutlined />}
        e2e-test="add_new"
      />
    </div>
  )
  return (
    <>
      {(newComponent || detailComponent) && (
        <SentryErrorBoundary
          fallback={
            <ErrorPage
              resolvers={[handleOnclose]}
              buttonTitle={translate('close')}
              noRedirect={true}
            />
          }
        >
          <ListTableCotext.Provider
            value={{
              getEntityData: () => {
                getEntityData({
                  passedBookmark:
                    filters && Object.keys(filters).length
                      ? undefined
                      : pagination.bookmark,
                  resetBookmarks: !!(
                    filters && Object.keys(filters).length > 0
                  ),
                  ...filterSelector,
                })
              },
              getEntityDataInit: () => {
                handleResetFilters(asset)
                getEntityData({
                  passedBookmark: undefined,
                  resetBookmarks: true,
                })
              },
              resetPagination: () => dispatch(resetItsmTableFilters(asset)),
              onClose: handleOnclose,
              record: drawerDetail,
              asset,
            }}
          >
            {(drawerNew || !!drawerDetail) && (
              <Drawer
                rootClassName="list-table-drawer"
                title={getDrawerTitle()}
                onClose={handleOnclose}
                open={drawerNew || !!drawerDetail}
              >
                {drawerNew ? newComponent : detailComponent}
              </Drawer>
            )}
          </ListTableCotext.Provider>
        </SentryErrorBoundary>
      )}

      <ListTableHeader<ItsmAssets>
        title={asset as TKey}
        asset={asset}
        previewMode={previewMode}
        setSorting={handleSorter}
        generatedFilters={generatedFilters}
        resetFilters={handleResetFilters}
        {...(sorter && {sorter})}
        onSearch={onSearch}
        onReset={onReset}
        moduleSorters={itsmSorters}
        extraBtns={exportBtn}
        tableCustomizer={
          <TableColumnsCustomizer
            items={draggableData}
            updateItemOrder={handleActiveCols}
            handleActiveColumns={handleSetSelectedColumn}
            previewMode={previewMode}
          />
        }
      />

      <DataTable<T>
        tableHeader={
          (newComponent ||
            (asset === ItsmAssets.CustomerProducts && isAllowedAddNew) ||
            asset === ItsmAssets.SupplierProducts) &&
          header
        }
        data={entityData}
        columns={generateActiveColumns({
          columns: previewMode
            ? normalizeQueryColumns(columnsQuery || '', asset)
            : columns,
          asset,
          dataResolved: initFetchData,
          getRowValue,
        })}
        onRowClick={handleRowClick}
        loading={
          checkIsLoading(fetchStatus) ||
          ((apiServiceInitFetch || []).length > 0 &&
            Object.keys(initFetchData || []).length !==
              apiServiceInitFetch?.length)
        }
        rowKey={rowKey.toString()}
        customPager={
          <PaginationWithBookmarks<TPagination<T>>
            pagination={pagination}
            numberOfRecords={numberOfRecords}
            fetch={bookmark => {
              getEntityData({
                passedBookmark: bookmark,
                ...((sortQuery || sorter) && {
                  passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
                }),
                ...((filtersQuery || filters || selectorIds) && {
                  passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
                    ...filters,
                    ...(selectorIds || {}),
                  },
                }),
              })
            }}
          />
        }
      />
    </>
  )
}

export default ItsmListTableSettings
