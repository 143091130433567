import {translate} from '@/services/i18n'
import {kFormatter} from '@/utils/kFormatter'
import {
  Align,
  Position,
  Type,
  VerticalAlign,
} from '@/components/charts/apex-chart/typed'

const fontColor = 'rgba(58, 53, 65, 0.87)'

export const yearlyBarOptions = {
  title: {
    text: translate('overview_yearly'),
  },
  yaxis: {
    labels: {
      formatter: (value: number) => kFormatter(value),
    },
  },
  xaxis: {
    type: Type.datetime,
    labels: {
      format: 'yyyy',
    },
  },
  dataLabels: {
    enabled: false,
  },

  tooltip: {
    y: {
      formatter: (value: number) => `${kFormatter(value)} EUR`,
    },
    x: {
      format: 'yyyy',
    },
    fixed: {
      enabled: true,
      position: Position.topLeft,
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['transparent'],
  },
  noData: {
    text: translate('no_data_available'),
    align: Align.center,
    verticalAlign: VerticalAlign.middle,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '18px',
      fontWeight: 600,
      color: fontColor,
    },
  },
}
