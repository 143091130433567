import {message} from 'antd'
import moment from 'moment'

import {translate} from '@/services/i18n'
import {DATE_FORMAT} from '@/constants'
import {prepareDataToCopy} from '@/utils/prepare-data-to-copy'

import {IconType} from '../icon'

import {ModalType} from './typedef'

export const copyToClipboard = (errorMessage: string) => {
  const time = moment(new Date()).format(DATE_FORMAT)
  navigator.clipboard.writeText(prepareDataToCopy({}, errorMessage, time))
  message.success(translate('error_copied'))
}

export const ModalIcon = {
  [ModalType.INFO]: IconType.EXCLAMATION,
  [ModalType.SUCCESS]: IconType.CHECK_CIRCLE,
  [ModalType.ERROR]: IconType.FLAG,
  [ModalType.WARNING]: IconType.WARNING,
}
