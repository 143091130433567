import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {customReports} from '@/modules/ITSM/api/generate-itsm-api-url'

export const getCustomReports = async (query: string, payload: string) => {
  return await request
    .options(customReports().get(query))
    .set(getHeadersWithGRPC())
    .send(payload)
    .responseType('blob')
}
