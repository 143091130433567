import React from 'react'

import moment from 'moment'
import {Table} from 'antd'
import {v4 as uuid} from 'uuid'

import {DATE_FORMAT} from '@/constants'
import {translate} from '@/services/i18n'
import {TBillingItem} from '@/modules/ITSM/typedef'

export const getExpandedRowRender = (
  row: TBillingItem,
  entityData: Array<TBillingItem>
) => {
  const workPriceDetailsColumns = [
    {
      title: translate('work_price_details_duration'),
      key: 'duration',
      render: (val: TBillingItem) => {
        return val.customer.work_price_details.map(e => (
          <div key={uuid()}>{e.duration_in_s}</div>
        ))
      },
    },
    {
      title: translate('work_price_details_start'),
      key: 'work_price_details_start',
      render: (val: TBillingItem) => {
        return val.customer.work_price_details.map(e => (
          <div key={uuid()}> {moment(e.start).format(DATE_FORMAT)}</div>
        ))
      },
    },
    {
      title: translate('work_price_details_end'),
      key: 'work_price_details_end',
      render: (val: TBillingItem) => {
        return val.customer.work_price_details.map(e => (
          <div key={uuid()}> {moment(e.end).format(DATE_FORMAT)}</div>
        ))
      },
    },
    {
      title: translate('work_price_details_total'),
      key: 'work_price_details_total',
      render: (val: TBillingItem) => {
        return val.customer.work_price_details.map(e => (
          <div key={uuid()}>{Number(e.total).toFixed(2)}</div>
        ))
      },
    },
    {
      title: translate('work_price_details_work'),
      key: 'work_price_details_work',
      render: (val: TBillingItem) => {
        return val.customer.work_price_details.map(
          e => e.work && <div key={uuid()}>{Number(e.work).toFixed(2)}</div>
        )
      },
    },
  ]

  const workCostDetailsColumns = [
    {
      title: translate('work_cost_details_duration'),
      key: 'work_cost_details_duration',
      render: (val: TBillingItem) => {
        return val.supplier.work_cost_details?.map(e => (
          <div key={uuid()}>{e.duration_in_s}</div>
        ))
      },
    },
    {
      title: translate('work_cost_details_start'),
      key: 'work_cost_details_start',
      render: (val: TBillingItem) => {
        return val.supplier?.work_cost_details?.map(e => (
          <div key={uuid()}> {moment(e.start).format(DATE_FORMAT)}</div>
        ))
      },
    },
    {
      title: translate('work_cost_details_end'),
      key: 'work_cost_details_end',
      render: (val: TBillingItem) => {
        return val.supplier?.work_cost_details?.map((e, i) => (
          <div key={uuid()}>{moment(e.end).format(DATE_FORMAT)}</div>
        ))
      },
    },
    {
      title: translate('work_cost_details_total'),
      key: 'work_cost_details_total',
      render: (val: TBillingItem) => {
        return val.supplier?.work_cost_details?.map(e => (
          <div key={uuid()}>{e.total}</div>
        ))
      },
    },
    {
      title: translate('work_cost_details_work'),
      key: 'work_cost_details_work',
      render: (val: TBillingItem) => {
        return val.supplier?.work_cost_details?.map(e => (
          <div key={uuid()}>{e.work}</div>
        ))
      },
    },
  ]

  return (
    <>
      <Table
        className="table_inner"
        columns={workPriceDetailsColumns}
        dataSource={entityData.filter(e => e.uuid === row.uuid)}
        pagination={false}
        rowKey="uuid"
        key="a"
      />
      <Table
        className="table_inner"
        columns={workCostDetailsColumns}
        dataSource={entityData.filter(e => e.uuid === row.uuid)}
        pagination={false}
        rowKey="uuid"
        key="b"
      />
    </>
  )
}
