import {SideTabs, TServicesForm} from '@/sites/services/typedef'

import {calculateModuleTotalPrice} from '@/sites/services/utils/calculate-module-total-price'
import {numberWithCommas} from '@/utils/number-with-commas'

export const calculateServicesTotalPrice = (
  basePrice: number,
  values: Record<SideTabs, TServicesForm>
) => {
  const endUserSupport = calculateModuleTotalPrice(
    basePrice,
    values[SideTabs.END_USER_SUPPORT]
  )
  const businessAppsSupport = calculateModuleTotalPrice(
    endUserSupport,
    values[SideTabs.BUSINESS_APPS_SUPPORT_MANAGEMENT]
  )

  const itAssetsSupport = calculateModuleTotalPrice(
    businessAppsSupport,
    values[SideTabs.IT_ASSETS_SUPPORT_MANAGEMENT]
  )

  return numberWithCommas(itAssetsSupport)
}
