import {useCallback, useState} from 'react'

import {v4 as uuid} from 'uuid'

import {useFetchHook} from '@/hooks/useFetch'
import {
  TBillingItem,
  TSelector,
  TYear,
} from '@/modules/ITSM/sites/finance-dashboard/types'
import {fetchBillingReportsList} from '@/modules/ITSM/api/billingReportsRequests'
import {parseDataPerYearMapNewApi} from '@/modules/ITSM/sites/finance-dashboard/utils/parse-data-per-year-map-new-api'

export function useGetBillingReportsList() {
  const [entityData, setEntityData] = useState<Array<TYear>>([])
  const [flatBillingReportsData, setFlatBillingReportsData] = useState<
    TBillingItem[]
  >([])
  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getData = useCallback(
    async (selector: TSelector) => {
      try {
        handleRequested()
        const {
          body: {
            result: {reports},
          },
        } = await fetchBillingReportsList(selector)

        const reportsWithId = reports.map((report: TBillingItem) => ({
          ...report,
          generatedRowKey: uuid(),
        }))

        const parsedReports = parseDataPerYearMapNewApi(reports)
        setEntityData(parsedReports)
        setFlatBillingReportsData(reportsWithId)

        handleSuccess()
        return reports
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    getData,
    entityData,
    fetchStatus: status,
    flatBillingReportsData,
  }
}
