import React from 'react'

import {EditorState} from 'react-draft-wysiwyg'
import {Checkbox, Col, Divider, Input, InputNumber, Row, Select} from 'antd'

import {Editor, EditorWrapper} from '@/components/editor'
import SelectWithBM from '@/components/SelectWithBM/SelectWithBM'
import {translate} from '@/services/i18n'
import {TIME_ZONES} from '@/constants'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {getToken, userGroupsApi} from '@/modules/ITSM/api/generate-itsm-api-url'

import {draftToolbarOptions} from '../../utils/Constants'
import {getAssignmentGroupsName} from '../../utils/Helpers'

import {TUserDefaultsInvite} from './user-invitation/typedf'

const Option = Select.Option

type TProps = {
  onEditorStateChange: (editorState: EditorState) => void
  editorState: any
  selectedChannelID: () => void
}

const UserInvitationForm = ({
  onEditorStateChange,
  editorState,
  selectedChannelID,
}: TProps) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Divider />
          <div className="invite-form__header">{translate('create_user')}</div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TUserDefaultsInvite>
            name="name"
            label={translate('name')}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TUserDefaultsInvite>
            name="surname"
            label={translate('surname')}
          >
            <Input step={10} />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...twoColumns}>
          <FormItemGeneric<TUserDefaultsInvite>
            name="phone"
            label={translate('phone')}
          >
            <Input />
          </FormItemGeneric>
        </Col>
        <Col {...twoColumns}>
          <FormItemGeneric<TUserDefaultsInvite>
            name="timezone"
            label={translate('time_zone')}
            rules={[
              {
                message: `${translate('time_zone')} ${translate(
                  'is_required'
                )}`,
                required: true,
              },
            ]}
          >
            <Select showSearch allowClear getPopupContainer={e => e.parentNode}>
              {TIME_ZONES.map(val => (
                <Option value={val.value} key={val.value + 'timezone'}>
                  {`${val.value} [UTC${val.time}]`}
                </Option>
              ))}
            </Select>
          </FormItemGeneric>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemGeneric<TUserDefaultsInvite>
            name="company"
            label={translate('companyName')}
          >
            <Input />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="invite-form__header">
            {translate('rate_and_pricing')}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="col-flex">
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'currency']}
              label={translate('currency')}
            >
              <Input />
            </FormItemGeneric>
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'rate']}
              label={translate('rate')}
            >
              <InputNumber />
            </FormItemGeneric>
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'pricing_unit']}
              label={translate('pricing_unit')}
            >
              <InputNumber />
            </FormItemGeneric>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="col-flex">
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'primary_hours_number']}
              label={translate('primary_units_number')}
            >
              <InputNumber />
            </FormItemGeneric>
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'primary_hour_rate']}
              label={translate('primary_unit_rate')}
            >
              <InputNumber />
            </FormItemGeneric>
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'additional_pricing_unit']}
              label={translate('additional_pricing_unit')}
            >
              <InputNumber />
            </FormItemGeneric>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div className="col-flex">
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'travel_fix_rate']}
              label={translate('travel_fix_rate')}
            >
              <InputNumber />
            </FormItemGeneric>
            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'travel_distance_unit_rate']}
              label={translate('travel_distance_unit_rate')}
            >
              <InputNumber />
            </FormItemGeneric>

            <FormItemGeneric<TUserDefaultsInvite>
              name={['pricing_policy', 'travel_pricing_unit']}
              label={translate('travel_pricing_unit')}
            >
              <InputNumber />
            </FormItemGeneric>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <SelectWithBM
            api={userGroupsApi().get}
            label={translate('user_groups')}
            name="user_groups"
            mode="multiple"
            initialValue={[]}
            optionContent={getAssignmentGroupsName}
            getPopupContainer={() => document.body}
            disabled={!selectedChannelID}
            // @ts-ignore scAuthHeaders type error
            scAuthHeaders={{
              'Grpc-Metadata-space': selectedChannelID,
              ...getToken(),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <EditorWrapper>
            <FormItemGeneric<TUserDefaultsInvite>
              name="email_signature"
              label={translate('email_signature')}
            >
              <Editor
                editorState={editorState}
                toolbar={draftToolbarOptions}
                onEditorStateChange={onEditorStateChange}
              />
            </FormItemGeneric>
          </EditorWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="invite-form__checkbox">
            <FormItemGeneric<TUserDefaultsInvite>
              name="notification"
              label={translate('notification')}
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox />
            </FormItemGeneric>
          </div>
          <div className="invite-form__checkbox">
            <FormItemGeneric<TUserDefaultsInvite>
              name="unavailable"
              label={translate('unavailable')}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox />
            </FormItemGeneric>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default UserInvitationForm
