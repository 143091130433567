import {TCreatorAccAndOrg} from '@/components/accountForm/types'

export const createCustomerPayload = (data: TCreatorAccAndOrg) => ({
  name: `${data.firstname} ${data.surname}`,
  'address[country]': '',
  'address[city]': '',
  'address[state]': '',
  'address[line1]': '',
  'address[line2]': '',
  'address[postal_code]': '',
  email: data.email.trim(),
  phone: data.phone.trim(),
})
