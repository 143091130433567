import React from 'react'

import {SkeletonItem} from '@/components/skeleton'
import {GoBackButton} from '@/components/go-back-button/go-back-button'

import './incident-header.scss'

export const IncidentHeaderTitleSkeletonView = () => (
  <>
    <GoBackButton disabled />
    <SkeletonItem width={110} height={31} />
    <div className="incident-header__indicator flex flex--alignCenter">
      <SkeletonItem width={23} height={20} />
      <SkeletonItem className="ml-10" width={20} height={15} />
    </div>
  </>
)
