import React, {Fragment, useCallback, useEffect, useState} from 'react'

import {connect} from 'react-redux'
import {useParams, withRouter} from 'react-router-dom'
import moment from 'moment'
import {Col, Form, Input, Row} from 'antd'
import {CheckOutlined, WarningOutlined} from '@ant-design/icons'

import {Button} from '@/components/button'
import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {setRefresh} from '@/redux/actions'
import {translate} from '@/services/i18n'
import {usePrevious} from '@/hooks/usePrevious'
import {RequestStatus} from '@/typedef'
import {handleMessage} from '@/utils/handle-message'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import {DATE_FORMAT} from '@/constants'
import {
  generateCurrency,
  getTotalAmountOfTime,
} from '@/modules/ITSM/sites/BillingReports/billing-report-detail/utils'
import FormItemCustom from '@/components/form/form-item-custom'

import {
  approveBillingReport,
  getApproved,
  getSingleton,
} from '../../../api/billingReportsRequests'
import {checkAccess} from '../../../utils/accessChecks'

import './billing-report-detail.scss'

const BillingReportDetail = ({record}) => {
  const [hideApproval, setHideApproval] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingCheckApproval, setLoadingCheckApproval] = useState(false)

  const [form] = Form.useForm()

  const prevRecord = usePrevious(record)

  const {id} = useParams()

  const isCurrent = id === 'current'

  const getValues = val => {
    let valuesObj = {}
    if (val.supplier_sums && Object.keys(val.supplier_sums).length === 1)
      valuesObj =
        val.supplier_sums[Object.keys(val.supplier_sums)[0]].currency_sums
    else if (val.customer_sums) valuesObj = val.customer_sums.currency_sums

    return valuesObj
  }

  let costs = getValues(record)

  let EURtime = costs && costs.EUR ? costs.EUR.travel_time : 0
  let UStime = costs && costs.USD ? costs.USD.travel_time : 0

  let EURtimeWork = costs && costs.EUR ? costs.EUR.work_time : 0
  let UStimeWork = costs && costs.USD ? costs.USD.work_time : 0

  const getTotalAmountOfExpenses = useCallback(() => {
    if (costs) {
      if (
        (costs.EUR && costs.EUR.expense) ||
        (costs.USD && costs.USD.expense)
      ) {
        return (
          Number(
            Number(costs.EUR && costs.EUR.expense ? costs.EUR.expense : 0) +
              (costs.USD && costs.USD.expense
                ? Number(costs.USD.expense) * record.currency_rates.USDEUR
                : 0)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + generateCurrency(costs)
        )
      }
      return ''
    }
    return null
  }, [costs, record])

  const {supplier_sums, customer_sums} = record

  const getSupplierPrice = useCallback(() => {
    let supplierPrice = {}
    if (supplier_sums && Object.keys(supplier_sums).length === 1)
      supplierPrice = supplier_sums[Object.keys(supplier_sums)[0]].currency_sums
    else if (customer_sums?.total)
      supplierPrice = supplier_sums.total.currency_sums
    if (supplierPrice) {
      if (
        (supplierPrice.EUR && supplierPrice.EUR.amount) ||
        (supplierPrice.USD && supplierPrice.USD.amount)
      ) {
        return (
          Number(
            Number(
              supplierPrice.EUR && supplierPrice.EUR.amount
                ? supplierPrice.EUR.amount
                : 0
            ) +
              (supplierPrice.USD && supplierPrice.USD.amount
                ? Number(supplierPrice.USD.amount) *
                  record.currency_rates.USDEUR
                : 0)
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + generateCurrency(supplier_sums)
        )
      }
      return ''
    }
    return null
  }, [record, customer_sums, supplier_sums])

  const getCustomerPrice = useCallback(() => {
    if (costs) {
      if (costs.EUR && costs.EUR.amount) {
        return (
          Number(costs.EUR.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + generateCurrency(costs)
        )
      }
      return ''
    }
    return null
  }, [costs])

  useEffect(() => {
    if (prevRecord !== record) {
      form.setFieldsValue({
        interval_start: record.interval_start
          ? moment(record.interval_start)
              .utc()
              .format(DATE_FORMAT)
          : null,
        interval_end: record.interval_end
          ? moment(record.interval_end)
              .utc()
              .format(DATE_FORMAT)
          : null,

        total_amount_of_working_time: costs
          ? (Number(EURtimeWork) + Number(UStimeWork) !== 0
              ? Math.round(
                  (Number(EURtimeWork) + Number(UStimeWork)) / 3600
                ).toLocaleString(undefined)
              : 0) + ' h'
          : null,
        total_amount_of_travel_time: costs
          ? (Number(EURtime) + Number(UStime) !== 0
              ? Math.round(
                  (Number(EURtime) + Number(UStime)) / 3600
                ).toLocaleString(undefined)
              : 0) + ' h'
          : null,
        total_amount_of_expenses: getTotalAmountOfExpenses(),
        supplier_price: getSupplierPrice(),
        customer_price: getCustomerPrice(),
      })
    }
  }, [
    record,
    EURtime,
    EURtimeWork,
    UStime,
    UStimeWork,
    costs,
    prevRecord,
    form,
    getTotalAmountOfExpenses,
    getSupplierPrice,
    getCustomerPrice,
  ])

  useEffect(() => {
    const approvedBR = async () => {
      try {
        const res = await getApproved({
          selector: {
            interval_start: record.interval_start,
            interval_end: record.interval_end,
          },
        })
        setHideApproval(res.body.result.length)
        setLoadingCheckApproval(false)
      } catch (err) {
        setToastMessage({message: err})
        setLoadingCheckApproval(false)
      }
    }

    const checkSingleton = async () => {
      try {
        const res = await getSingleton()
        if (res.body.result.value.billing_report.approval_needed) {
          approvedBR()
        } else setLoadingCheckApproval(false)
      } catch (err) {
        setLoadingCheckApproval(false)
        setToastMessage({message: err})
      }
    }

    if (!isCurrent) {
      if (record?.interval_end) {
        setLoadingCheckApproval(true)
        checkSingleton()
      }
    }
  }, [record, isCurrent])

  const handleApproval = async () => {
    try {
      setLoading(true)
      handleMessage(RequestStatus.REQUESTED)

      await approveBillingReport({
        interval_start: record.interval_start,
        interval_end: record.interval_end,
      })

      handleMessage(RequestStatus.SUCCEEDED)
      setHideApproval(true)
    } catch (err) {
      handleMessage(RequestStatus.FAILED)
      setToastMessage({message: err})
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Form layout="vertical" form={form}>
        <Fragment>
          <Row gutter={24}>
            <Col {...twoColumns}>
              <FormItemCustom
                name="interval_start"
                label={translate('interval_start')}
                initialValue={
                  record.interval_start
                    ? moment(record.interval_start)
                        .utc()
                        .format(DATE_FORMAT)
                    : null
                }
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
            <Col {...twoColumns}>
              <FormItemCustom
                name="interval_end"
                label={translate('interval_end')}
                initialValue={
                  record.interval_end
                    ? moment(record.interval_end)
                        .utc()
                        .format(DATE_FORMAT)
                    : null
                }
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col {...twoColumns}>
              <FormItemCustom
                name="total_amount_of_working_time"
                label={translate('total_amount_of_working_time')}
                initialValue={
                  costs ? getTotalAmountOfTime(EURtimeWork, UStimeWork) : null
                }
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
            <Col {...twoColumns}>
              <FormItemCustom
                name="total_amount_of_travel_time"
                label={translate('total_amount_of_travel_time')}
                initialValue={
                  costs ? getTotalAmountOfTime(EURtime, UStime) : null
                }
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col {...twoColumns}>
              <FormItemCustom
                name="total_amount_of_expenses"
                label={translate('total_amount_of_expenses')}
                initialValue={getTotalAmountOfExpenses()}
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
            <Col {...twoColumns}>
              <FormItemCustom
                name="supplier_price"
                label={translate('supplier_price')}
                initialValue={getSupplierPrice()}
              >
                <Input readOnly />
              </FormItemCustom>
            </Col>
          </Row>

          {record && record.customer_sums && (
            <Row gutter={24}>
              <Col {...twoColumns}>
                <FormItemCustom
                  name="customer_price"
                  label={translate('customer_price')}
                  initialValue={getCustomerPrice()}
                >
                  <Input readOnly />
                </FormItemCustom>
              </Col>

              {record && record.ref_no && (
                <Col {...twoColumns}>
                  <FormItemCustom
                    name="ref_no"
                    label={translate('ref_number')}
                    initialValue={record.ref_no ? record.ref_no : null}
                  >
                    <Input readOnly />
                  </FormItemCustom>
                </Col>
              )}
            </Row>
          )}
        </Fragment>
      </Form>

      {checkAccess('billing_report_approval') && !loadingCheckApproval && (
        <>
          <Row
            className="mt-16"
            gutter={24}
            hidden={!hideApproval || isCurrent}
          >
            <Col {...twoColumns}>
              <CheckOutlined className="billing-report-detail__check-icon" />
              {translate('billing_report_approved')}
            </Col>
          </Row>

          <Row className="mt-16" gutter={24} hidden={hideApproval}>
            <Col {...twoColumns}>
              <WarningOutlined className="billing-report-detail__warning-icon" />
              {translate('billing_report_pending')}
            </Col>
          </Row>

          <Row gutter={24} hidden={hideApproval}>
            <Col {...twoColumns}>
              <Button
                className="mt-16"
                title={translate('approve')}
                type="primary"
                onClick={handleApproval}
                loading={loading}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default withRouter(
  connect(null, {setToastMessage, setRefresh})(BillingReportDetail)
)
