import moment from 'moment'

import {TEvent} from './typedf'

export const createData = (values: TEvent) => {
  if (values.requested_eta)
    values['requested_eta'] = moment(values.requested_eta).format()

  if (!values['description']) values['description'] = '<p></p>\n'

  if (values.contact) {
    values['contact'] = values.contact.toLowerCase()
  }
  return values
}

export const getInitialValues = (values: TEvent) => {
  return {
    caller: values.caller,
    email: values.email,
    contact: values.contact.toLocaleLowerCase(),
    customer_product: values.customer_product,
    requestedType: values.requestedType,
    requested_eta: values.requested_eta || null,
    short_description: values.short_description,
    description: values.description,
  }
}

export const getUpdateValues = (values: TEvent & Record<string, unknown>) => {
  delete values['location']
  delete values['country']
  delete values['state_province']
  delete values['city']
  delete values['street']
  delete values['zip']
  delete values['latitude']
  delete values['longitude']

  return values
}

export const getCallersUuid = (events: Array<TEvent>, currentPage: number) =>
  events
    ?.slice((Number(currentPage) - 1) * 10, Number(currentPage) * 10)
    .map(({caller}) => ({
      uuid: caller,
    }))
