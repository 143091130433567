import {Button} from '@/components/button'
import Modal from '@/components/modal/modal'
import {ModalType} from '@/components/modal/typedef'
import {OverlaySpinner} from '@/components/overlay-spinner'
import {useGenerateReturnLink} from '@/modules/Subscriptions/hooks/use-generate-return-link'
import {translate} from '@/services/i18n'
import {InputNumber} from 'antd'
import React, {useState} from 'react'
import './payment-modal.scss'
import {selectCustomer} from '@/redux/billing/billing-selector'
import {useSelector} from 'react-redux'
import {checkIsLoading} from '@/utils/check-is-loading'
import {usePostDataGeneric} from '@/hooks/use-post-data-generic'
import {getTopUpCheckoutSession} from '@/api/billing-requests'
import globalRoutes from '@/global-routes'
import {selectValueOnFocus} from '@/utils/select-value-on-focus'

import topUpImage from './images/top-up.png'

type TProps = {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
}

export const PaymentModal: React.FC<TProps> = ({modalOpen, setModalOpen}) => {
  const customer = useSelector(selectCustomer)
  const {
    run: getLink,
    status: redirectToCheckoutPortalStatus,
  } = usePostDataGeneric<{
    passedArgs: {quantity: number; cancel_url: string; success_url: string}
  }>()

  const [quantity, setQuantity] = useState(0)

  const handleChange = (value: number | null) => {
    setQuantity(value || 0)
  }
  const handleCancel = () => {
    setModalOpen(false)
    setQuantity(0)
  }

  const generateReturnLink = useGenerateReturnLink()

  const handleSubmit = async () => {
    if (quantity > 0) {
      const {body} =
        (await getLink(() =>
          getTopUpCheckoutSession({
            quantity,
            cancel_url: generateReturnLink({}),
            success_url: generateReturnLink({
              pathname: `${globalRoutes.general.paymentSuccess.path()}&balance=${
                customer?.balance
              }`,
            }),
          })
        )) || {}
      if (!body?.result?.url || !customer) return

      window.location.replace(body.result.url)
    }
  }
  return (
    <div className="payment-modal-wrapper">
      <Modal
        open={modalOpen}
        modalType={ModalType.INFO}
        closable
        onCancel={handleCancel}
      >
        <p className="payment-modal-title">
          {translate('account_balance_info')}
        </p>
        <div className="flex ">
          <div className="payment-modal-amount">
            <div className="flex ">
              <InputNumber
                autoFocus
                min={0}
                className="payment-modal-input"
                placeholder="Balance amount"
                value={quantity}
                onChange={handleChange}
                onFocus={selectValueOnFocus}
                type="number"
                onKeyDown={e => {
                  if (e.key === '-') {
                    e.preventDefault()
                  }
                }}
              />
              <Button
                className="payment-modal-button"
                type="primary"
                title={translate('submit')}
                onClick={handleSubmit}
              />
            </div>
          </div>
          <div className="payment-modal-image">
            <img src={topUpImage} alt={translate('top_up')} width={100} />
          </div>
        </div>
        {checkIsLoading(redirectToCheckoutPortalStatus) && (
          <div className="payment-modal-spinner">
            <OverlaySpinner idName={'payment-modal'} />
          </div>
        )}
      </Modal>
    </div>
  )
}
