// @ts-nocheck AssignmentGroupsFormContainer is not refactored to typescript

import React from 'react'

import {ItsmAssets, TAssignmentRule} from '@/modules/ITSM/typedef'
import ItsmListTableSettings from '@/modules/ITSM/components/itsm-list-table/itsm-list-table-settings/itsm-list-table-settings'
import {translate} from '@/services/i18n'

import AssignmentRulesDrawer from './assignment-rule-drawer'
import {AssignmentRulesRowValue} from './assignment-rules-row-value'

const AssignmentRules = () => {
  const handleGetRowValue = ({title, value}: TColumnRender<TCategory>) => (
    <AssignmentRulesRowValue title={title} value={value} />
  )

  return (
    <ItsmListTableSettings<TAssignmentRule>
      asset={ItsmAssets.AssignmentRules}
      newComponent={<AssignmentRulesDrawer />}
      detailComponent={<AssignmentRulesDrawer />}
      drawerTitleNew={translate('assignment_rule')}
      drawerTitleDetail={translate('assignment_rule')}
      getRowValue={handleGetRowValue}
      rowKey="uuid"
    />
  )
}

export default AssignmentRules
