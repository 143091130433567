import {useLocation} from 'react-router-dom'

export function useQuery(name: string): string | undefined {
  const query = new URLSearchParams(useLocation().search)
  const retrieved = query.get(name)
  if (retrieved) {
    return retrieved.trim()
  }
  return undefined
}
