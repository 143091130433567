import request from 'superagent'

import {getHeadersWithGRPC} from '@/services/api'
import {
  billingItemsApi,
  billingReportsApi,
  customReportApi,
  requestApi,
  userApi,
} from '@/modules/ITSM/api/generate-itsm-api-url'

export const exportBRfile = async (format, exportQuery) => {
  return await request
    .options(billingReportsApi().getReports(format))
    .send({
      ...exportQuery,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const exportBIfile = async (format, selector) => {
  return await request
    .options(billingItemsApi().getReports(format))
    .send({
      selector,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}
export const exportIncidentList = async (endpointQuery, query, fields) => {
  return await request
    .options(endpointQuery)
    .send({
      selector: query,
      fields,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}
export const exportRequest = async (format, resolve) => {
  return await request
    .options(requestApi().getReports(format) + '&' + resolve)
    .set(getHeadersWithGRPC())
}
export const exportSupliers = async (exportQuery, data) => {
  return await request
    .options(userApi().getSuppliers('?export=true&type' + exportQuery))
    .send({
      selector: data,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}
export const exportBillingItems = async (format, selector) => {
  return await request
    .options(billingItemsApi().getReports(format))
    .send({
      selector,
    })
    .set(getHeadersWithGRPC())
    .responseType('blob')
}

export const exportCustomProducts = async data => {
  return await request
    .options(customReportApi().get)
    .send(data)
    .set(getHeadersWithGRPC())
    .responseType('blob')
}
