import React, {useEffect, useState} from 'react'

import {Redirect, useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {translate} from '@/services/i18n'
import {Entities} from '@/typedef'
import {Tabs, TabsSkeleton} from '@/components/tabs'
import {Skeleton} from '@/components/skeleton'
import {useGetSingleData} from '@/hooks/useGetSignleData'
import {TBillingReport} from '@/modules/ITSM/typedef'
import {
  fetchBillingReport,
  fetchCurrent,
} from '@/modules/ITSM/api/billingReportsRequests'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {useQuery} from '@/utils/useQuery'
import {setRedirectToList} from '@/redux/ui/uiActions'
import {selectRedirectToList} from '@/redux/ui/uiSelector'
import {Paper, PaperVariant} from '@/components/paper/paper'

import BillingReportDetail from '../billing-report-detail/BillingReportDetail'
import BillingItems from '../billing-items/billing-items'
import Statistics from '../Statistics'
import {Anomalies} from '../anomalies'
import BillingReportInvoices from '../billing-report-invoices'
import Comments from '../../../components/comments/comments'
import {BillingReportTabs} from '../constants'

import useRemovePagination from './hooks/use-remove-pagination'

const BillingReport = () => {
  const [refreshBillingReport, setRefreshBillingReport] = useState(false)
  useRemovePagination()

  const history = useHistory()
  const {tab, id} = useParams() as {
    tab: string
    id: string
  }

  const dispatch = useDispatch()

  const month = useQuery('month')

  const {
    getData: getCurrentBillingReport,
    entityData: currentBr,
  } = useGetSingleData<TBillingReport>(fetchCurrent)

  const {
    getData: getBillingReport,
    entityData: billingReport,
  } = useGetSingleData<TBillingReport>(fetchBillingReport)

  const [currentTab, setCurrentTab] = useState(tab)
  const record = (currentBr || billingReport || {}) as TBillingReport

  const handleSetCurrentTab = (tab: string) => {
    setCurrentTab(tab)

    history.replace(
      itsmRoutes.billingReportsState.path(
        id,
        tab,
        month ? '?month=' + month : ''
      )
    )
  }

  useEffect(() => {
    if (id === 'current' || month) {
      getCurrentBillingReport({selector: {date: month}, id: ''})
    } else {
      getBillingReport({id})
    }

    setRefreshBillingReport(false)
  }, [
    getBillingReport,
    getCurrentBillingReport,
    id,
    month,
    refreshBillingReport,
  ])

  const billing_items =
    currentBr?.billing_items || currentBr?.billing_items_software || []

  const billingItemsSelector = currentBr
    ? {
        uuid: {$or: billing_items},
      }
    : {
        billing_report: id,
      }

  if (useSelector(selectRedirectToList)) {
    dispatch(setRedirectToList())
    return <Redirect push to={itsmRoutes.billingReports.path()} />
  }

  return (
    <Paper className="billing-report" variant={PaperVariant.tab}>
      <div className="mb-20">
        <GoBackButton
          onClick={() => history.push(itsmRoutes.billingReports.path())}
        />
      </div>
      <Skeleton
        active={Object.keys(record).length === 0}
        view={<TabsSkeleton />}
      >
        <Tabs
          onChange={handleSetCurrentTab}
          activeKey={currentTab}
          tabPosition={'left'}
          items={[
            {
              label: (
                <span className="billing-report-container__state-tab">
                  {translate('overview')}
                </span>
              ),
              key: BillingReportTabs.OverviewTab,
              children: (
                <div>
                  <Paper>
                    {/* @ts-ignore not refactored to typescript*/}
                    <BillingReportDetail record={record || {}} />
                  </Paper>
                </div>
              ),
            },
            {
              label: <span>{translate('billing_items')}</span>,
              key: BillingReportTabs.BillingItemsTab,
              children: (
                <div>
                  {currentTab === BillingReportTabs.BillingItemsTab && (
                    <BillingItems billingItemsSelector={billingItemsSelector} />
                  )}
                </div>
              ),
            },
            {
              label: <span>{translate('statistics')}</span>,
              key: BillingReportTabs.StatisticsTab,
              children: (
                <div>
                  <Paper>
                    <Statistics stats={record && record.stats} />
                  </Paper>
                </div>
              ),
            },
            ...(record?.anomalies
              ? [
                  {
                    label: <span>{translate('anomalies')}</span>,
                    key: BillingReportTabs.AnomaliesTab,
                    children: (
                      <div>
                        <Anomalies
                          anomalies={record && record.anomalies}
                          uuid={record.uuid}
                        />
                      </div>
                    ),
                  },
                ]
              : []),
            ...(record && record.uuid
              ? [
                  {
                    label: <span>{translate('invoices')}</span>,
                    key: BillingReportTabs.InvoicesTab,
                    children: (
                      <div>
                        <Paper>
                          <BillingReportInvoices
                            invoices={record && record.supplier_invoice_refs}
                            uuid={record.uuid}
                            rehydrateBillingReport={() =>
                              setRefreshBillingReport(true)
                            }
                          />
                        </Paper>
                      </div>
                    ),
                  },
                  {
                    label: <span>{translate('comments')}</span>,
                    key: BillingReportTabs.CommentsTab,
                    children: (
                      <div>
                        <Paper>
                          <Comments
                            entityBR={Entities.BILLING_REPORT}
                            idBR={id}
                            isDisabled={false}
                          />
                        </Paper>
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Skeleton>
    </Paper>
  )
}

export default BillingReport
