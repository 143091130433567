import {useCallback, useEffect, useReducer, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {setToastMessage} from '@/redux/toast-message/toast-actions'
import {selectEmptySpace} from '@/redux/user/userSelector'
import {RESOLVE} from '@/constants'
import {mergeBookmarks} from '@/utils/merge-bookmarks'
import {RequestStatus, TListArgs} from '@/typedef'
import {TAppState} from '@/redux/store'
import {usePrevious} from '@/hooks/usePrevious'
import {useQuery} from '@/utils/useQuery'
import {
  getDataFailed,
  getDataRequested,
  getDataSuccess,
  initialState,
  itsmDataTableReducer,
  resetFetchDataStatus,
  setBookmark,
  setFetchMoreRecords,
  setNumberOfRecords,
} from '@/modules/ITSM/reducers/itsm-data-table-reducer'
import {useSafeDispatch} from '@/hooks/useSafeDispatch'
import {
  selectItsmAssetPagination,
  selectItsmTableFilters,
  selectItsmTableSorters,
} from '@/modules/ITSM/store/list-table/selectors'
import {
  resetItsmTableFilters,
  setItsmTableBookmarks,
} from '@/modules/ITSM/store/list-table/table-actions'
import {ItsmAssets, TCategory} from '@/modules/ITSM/typedef'
import {fetchCategories} from '@/modules/ITSM/api/categoriesRequests'
import {prepareSelector} from '@/modules/ITSM/components/itsm-list-table/utils/prepare-selector'

import {replacerFunc} from '../utils'

const asset = ItsmAssets.Categories

export function useGetCategories(): {
  readonly getEntityData: ({
    passedBookmark,
    passedSorter,
    resetBookmarks,
    passedFilters,
  }: TListArgs<TCategory>) => Promise<TCategory[] | undefined>
  readonly entityData: TCategory[]
  readonly fetchStatus: RequestStatus
  readonly numberOfRecords: number
} {
  const [
    {data, numberOfRecords, fetchMoreRecords, status, bookmark},
    unsafeDispatch,
  ] = useReducer(itsmDataTableReducer, initialState)
  const dispatch = useSafeDispatch(unsafeDispatch)

  const filtersQuery = useQuery('filters')
  const sortQuery = useQuery('sort')

  const [categoriesSub, setCategoriesSub] = useState<TCategory[]>([])

  const paginationAsset = useSelector((state: TAppState) =>
    selectItsmAssetPagination(state, asset)
  )

  const sorter = useSelector((state: TAppState) =>
    selectItsmTableSorters(state, asset)
  )

  const filters = useSelector((state: TAppState) =>
    selectItsmTableFilters<TCategory>(state, asset)
  )

  const prevSorter = usePrevious(sorter)
  const prevFilters = usePrevious(filters)

  const isEmptySpace = useSelector(selectEmptySpace)

  const rdxDispatch = useDispatch()

  const getEntityData = useCallback(
    async ({
      passedBookmark,
      passedSorter,
      resetBookmarks,
      passedFilters,
    }: TListArgs<TCategory>) => {
      try {
        dispatch(getDataRequested())
        const {
          body: {result: categories, bookmark},
        } = await fetchCategories<TCategory>({
          passedBookmark,
          resolve: fetchMoreRecords ? '' : RESOLVE,
          ...(passedSorter && {sort: [passedSorter]}),
          selector: prepareSelector({
            inputValues: {
              ...passedFilters,
              parent: {$exists: false},
            },
            asset,
          }),
        })

        if (categories) {
          if (fetchMoreRecords) {
            dispatch(setFetchMoreRecords(false))
            dispatch(setNumberOfRecords(categories.length))
            dispatch(setBookmark(undefined))
            //    dispatch(resetFetchDataStatus())
          } else if (!fetchMoreRecords) {
            dispatch(
              getDataSuccess(
                categories,
                categories.length,
                categories.length ? RequestStatus.REQUESTED : ''
              )
            )

            const bookmarks = mergeBookmarks(
              bookmark,
              paginationAsset?.bookmarks,
              resetBookmarks
            )

            rdxDispatch(
              setItsmTableBookmarks({
                asset,
                bookmarks,
                bookmark: passedBookmark,
              })
            )

            if (categories.length === 10) {
              dispatch(setBookmark(bookmark))
              dispatch(setFetchMoreRecords(true))
            }

            return categories
          }
          return undefined
        }
      } catch (err) {
        rdxDispatch(resetItsmTableFilters(asset))

        rdxDispatch(setToastMessage({message: err}))
        dispatch(getDataFailed(err))
      }
    },
    [dispatch, fetchMoreRecords, paginationAsset?.bookmarks, rdxDispatch]
  )

  const prevData = usePrevious(data)

  useEffect(() => {
    if (
      JSON.stringify(prevData, replacerFunc()) !==
      JSON.stringify(data, replacerFunc())
    ) {
      if (data?.length) {
        dispatch(getDataRequested())

        let sequence = Promise.resolve()
        const loadingArr = Array(data.length).fill(true)

        const newData = [...data]

        newData?.forEach(async (cat: TCategory, index: number) => {
          sequence = sequence
            .then(async () => {
              const {
                body: {result},
              } = await fetchCategories<TCategory>({
                selector: {parent: {$in: [cat.uuid]}},
              })

              return result
            })
            .then(async (result: TCategory[] | undefined) => {
              if (result && result.length > 0) {
                if (newData[index].subcat === undefined) {
                  newData[index].subcat = []
                }

                result.map(categResult =>
                  newData[index].subcat.push({
                    ...categResult,
                    parent: [categResult],
                  })
                )
              }

              loadingArr[index] = false

              if (!loadingArr.includes(true)) {
                setCategoriesSub(newData)
                dispatch(resetFetchDataStatus())
              }
            })
        })
      } else setCategoriesSub([])
    }
  }, [data, dispatch, prevData])

  useEffect(() => {
    if (fetchMoreRecords && bookmark) {
      getEntityData({
        passedBookmark: bookmark,
        ...((sortQuery || sorter) && {
          passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
        }),
        ...((filtersQuery || filters) && {
          passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
            ...filters,
          },
        }),
      })
    }
  }, [fetchMoreRecords, bookmark, sorter, filters, sortQuery, filtersQuery])

  useEffect(() => {
    if (data) {
      if (
        JSON.stringify(prevSorter) !== JSON.stringify(sorter) ||
        JSON.stringify(prevFilters) !== JSON.stringify(filters)
      )
        getEntityData({
          passedBookmark: undefined,
          resetBookmarks: true,
          passedSorter: sorter,
          passedFilters: {...filters},
        })
    }
  }, [sorter, data, prevSorter, prevFilters, filters])

  const {bookmarks, bookmark: paginationBookmark} = paginationAsset

  useEffect(() => {
    if (isEmptySpace === null) {
      if (!data) {
        getEntityData({
          passedBookmark:
            (bookmarks || []).length > 0 && !sortQuery && !filtersQuery
              ? paginationBookmark
              : undefined,
          passedSorter: (sortQuery && JSON.parse(sortQuery)) || sorter,
          passedFilters: (filtersQuery && JSON.parse(filtersQuery)) || {
            ...filters,
          },
          resetBookmarks: !!(sortQuery || filtersQuery),
        })
      }
    }
  }, [
    data,
    isEmptySpace,
    bookmarks,
    sorter,
    sortQuery,
    filtersQuery,
    filters,
    paginationBookmark,
  ])

  return {
    getEntityData,
    entityData: categoriesSub,
    numberOfRecords,
    fetchStatus: status,
  }
}
