import {useCallback, useState} from 'react'

import {RequestStatus, SortingValues} from '@/typedef'
import {useFetchHook} from '@/hooks/useFetch'
import {ISuperAgentResMultiple} from '@/api/response-typedf'

export function useGetMultipleData<T extends Record<string, any>>(
  asyncF: ({
    selector,
  }: {
    selector: Record<string, any>
  }) => Promise<ISuperAgentResMultiple<T>>
): {
  readonly getData: ({
    selector,
    sort,
  }: {
    selector?: Record<string, any>
    sort?: Array<Record<string, SortingValues>>
  }) => Promise<T[] | undefined>
  readonly entityData: T[] | undefined
  readonly fetchStatus: RequestStatus
} {
  const [entityData, setEntityData] = useState<T[]>()

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getData = useCallback(
    async ({
      selector,
      sort,
    }: {
      selector?: Record<string, any>
      sort?: Array<Record<string, SortingValues>>
    }) => {
      try {
        handleRequested()
        const {
          body: {result},
        } = await asyncF({selector: selector || {}, ...({sort} || {})})

        if (result) {
          setEntityData(result)
          handleSuccess()
          return result
        }
        return undefined
      } catch (err) {
        handleFail(err)
      } finally {
        handleReset()
      }
    },
    [asyncF, handleFail, handleRequested, handleReset, handleSuccess]
  )

  return {
    getData,
    entityData,
    fetchStatus: status,
  } as const
}
