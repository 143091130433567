import {useSelector} from 'react-redux'

import {ItsmAssets} from '@/modules/ITSM/typedef'
import {selectOrgId} from '@/redux/entries/entries-selector'

export const useGetDefaultFilters = (asset: ItsmAssets) => {
  const orgId = useSelector(selectOrgId)

  switch (asset) {
    case ItsmAssets.Users:
      return {
        org_name: {$regex: orgId},
        active: true,
      }

    case ItsmAssets.SupplierProducts:
      return {
        $or: [{active_to: {$exists: false}}, {active_to: {$gte: new Date()}}],
      }

    default:
      return {}
  }
}
