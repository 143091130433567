import React from 'react'

import {Route} from 'react-router-dom'

import {TRouteData} from '@/typedef'

type TGenerateRoutesParams = {
  routes: Array<TRouteData>
}

type TGenerateRoutes = (params: TGenerateRoutesParams) => Array<React.ReactNode>

export const generatePrivateRoutes: TGenerateRoutes = ({
  routes: protectedRoutes,
}) => {
  return protectedRoutes.reduce<JSX.Element[]>(
    (accRoutes, {component, disabled = false, props}, index) => {
      return disabled
        ? accRoutes
        : [...accRoutes, <Route key={index} component={component} {...props} />]
    },
    []
  )
}
