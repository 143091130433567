type TCmdbTableCreators = typeof import('./table-actions')

type TActionCreators = TCmdbTableCreators

export type TAnyAction = {
  [Name in keyof TActionCreators]: TActionCreators[Name] extends (
    ...args: unknown[]
  ) => unknown
    ? ReturnType<TActionCreators[Name]>
    : never
}[keyof TActionCreators]

export enum ActionTypes {
  SET_SMDB_TABLE_MODIFIERS = 'SET_SMDB_TABLE_MODIFIERS',

  SET_SMDB_LIST_TABLE_FILTERS = 'SET_SMDB_LIST_TABLE_FILTERS',
  SET_CMDB_LIST_TABLE_COLUMNS = 'SET_CMDB_LIST_TABLE_COLUMNS',
  RESET_CMDB_LIST_TABLE_COLUMNS = 'RESET_CMDB_LIST_TABLE_COLUMNS',

  SET_SMDB_LIST_TABLE_SORTERS = 'SET_SMDB_LIST_TABLE_SORTERS',
  SET_CMDB_LIST_TABLE_SHARED_URL_FILTERS = 'SET_CMDB_LIST_TABLE_SHARED_URL_FILTERS',
  RESET_SMDB_LIST_TABLE_FILTERS = 'RESET_SMDB_LIST_TABLE_FILTERS',
  RESET_CMDB_LIST_TABLE_FILTER = 'RESET_CMDB_LIST_TABLE_FILTER',
}
