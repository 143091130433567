import React from 'react'
import {Interval} from '@/modules/typedef'

export enum AccountCreateStep {
  CREATE_ACCOUNT,
  PAYMENT,
  SCHEDULE_CALENDLY,
}

export type TSignupSteps = {
  [key: string]: {
    id: AccountCreateStep
    content: React.ReactNode
    header: string
  }
}

export enum PaymentStatus {
  CANCEL = 'cancel',
  SUCCESS = 'success',
}

export enum Flow {
  PRODUCTS = 'products',
  SERVICES = 'services',
  AD_HOC = 'ad-hoc',
}

export type TAccountDefaultQuery = {
  flow?: Flow
  interval?: Interval
  payload?: string
  unit_amount?: string
  'payment-status'?: PaymentStatus
}

export type TPrefillCreateAccountData = {
  'first-name'?: string
  'last-name'?: string
  email?: string
  company?: string
  'phone-number'?: string
}

export enum AccountDefaultError {
  NO_ORG = 'no organizations',
  NO_SPACE = 'no spaces',
  NO_SUBSCRIPTION = 'no subscription',
  NO_FOUND_ALL_INCLUSIVE_PRICE = 'no found all inclusive price',
}

export enum AccountDefaultModal {
  CHANNEL_EXIST = 'CHANNEL_EXIST',
  NO_CHANNEL = 'NO_CHANNEL',
  GENERAL_ERROR = 'GENERAL_ERROR',
}
