export const themeKompiTech = {
  token: {
    fontFamily: 'Inter, sans-serif',
    colorTextBase: 'rgb(58, 53, 65)',
    colorPrimary: '#1e90ff',
    colorBgBase: '#f4f5fa',
    colorBgContainer: '#ffffff',
    colorBgElevated: '#ffffff',
    borderRadius: 2,
  },
  components: {
    Layout: {
      colorBgHeader: '#f4f5f',
    },
    Input: {
      borderRadius: 2,
    },

    Checkbox: {
      colorPrimary: '#fa8c16',
      colorPrimaryHover: '#fa8c16',
    },
    Table: {
      fontWeightStrong: 500,
      colorBorderSecondary: '#f0f0f0',
      colorSplit: '#f0f0f0',
    },
    Dropdown: {
      colorText: 'rgba(0,0,0,.85)',
    },
  },
}
