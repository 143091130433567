import {useEffect, useState} from 'react'

import {useFetchHook} from '@/hooks/useFetch'
import {TAnomaly, TBillingItems} from '@/modules/ITSM/typedef'
import {TTableItem} from '@/modules/ITSM/sites/BillingReports/billing-items/typedf'
import {
  fetchBillingItems,
  postInvalidAnomaly,
} from '@/api/billing-reports-requests'

const LIMIT_FETCH_BILLING_ITEMS = 10

type TUseGetBillingItems = {
  anomalies: Array<TAnomaly>
}

export const useGetBillingItems = ({anomalies}: TUseGetBillingItems) => {
  const [billingItems, setBillingItems] = useState<TBillingItems>([])
  const [validationLoadingStates, setValidationLoadingStates] = useState<
    Record<string, boolean>
  >({})

  const {
    status,
    handleRequested,
    handleSuccess,
    handleFail,
    handleReset,
  } = useFetchHook()

  const getBillingItem = async (uuid: string) => {
    const selector = uuid
      ? {
          $or: [{uuid}],
        }
      : {}

    try {
      const data = await fetchBillingItems({selector})

      const result = data.body.result ?? []
      const filterValue = billingItems.filter(
        billingItem => billingItem.uuid !== uuid
      )

      setBillingItems(result.concat(filterValue))
    } catch (err) {
      handleFail(err)
    } finally {
      setValidationLoadingStates({[uuid]: false})
    }
  }

  const handleValidation = async (
    e: React.MouseEvent,
    val: TTableItem,
    uuid: string
  ) => {
    setValidationLoadingStates({[val.uuid]: true})

    try {
      await postInvalidAnomaly({
        billing_report: `${uuid}`,
        anomaly: `${val.uuid}`,
        invalid: !val.invalid,
      }).then(() => {
        getBillingItem(val?.uuid)
      })
    } catch (err) {
      setValidationLoadingStates({[val.uuid]: false})

      handleFail(err)
    }
  }

  useEffect(() => {
    const getBillingItems = async (bookmark?: string) => {
      handleRequested()

      try {
        const selector = {
          $or: anomalies.map(anomaly => {
            return {
              uuid: anomaly.uuid,
            }
          }),
        }

        const data = await fetchBillingItems({bookmark, selector})
        const result = data.body.result ?? []

        setBillingItems(prevState => (prevState || []).concat(result))

        if (result.length === LIMIT_FETCH_BILLING_ITEMS) {
          await getBillingItems(data.body.bookmark)

          handleSuccess()
        }
      } catch (err) {
        handleFail(err)
      }
    }

    getBillingItems().then(() => handleReset())
  }, [anomalies, handleFail, handleRequested, handleReset, handleSuccess])

  return {
    billingItems,
    fetchStatus: status,
    validationLoadingStates,
    handleValidation,
  } as const
}
