import {translate} from '@/services/i18n'
import {isEmailValid} from '@/utils/is-email-valid'

import {TValidator} from './typedef'

export const emailValidator: TValidator = (rule, value) => {
  return new Promise((resolve, reject) => {
    value && !isEmailValid(value)
      ? reject(translate('validate_email'))
      : resolve('')
  })
}
