import React, {useEffect, useMemo, useState} from 'react'

import isEqual from 'lodash/isEqual'
import {EyeOutlined, SearchOutlined} from '@ant-design/icons'
import {Input, MenuProps} from 'antd'
import classNames from 'classnames'

import {Dropdown} from '@/components/dropdown'
import {translate} from '@/services/i18n'

import {Button} from '../button'

import {SortableList} from './sortable-list'
import {TSortableItem} from './typedef'

import './table-columns-customizer.scss'

type TProps = {
  items: Array<TSortableItem>
  handleActiveColumns: (columnName: string, isAdded: boolean) => void
  updateItemOrder: (oldIndex: number, newIndex: number) => void
  previewMode?: boolean
}

const MINIMUM_ACTIVE_COLUMNS = 2

export const TableColumnsCustomizer: React.FC<TProps> = props => {
  const {items = [], handleActiveColumns, updateItemOrder, previewMode} = props

  const [orderedItems, setOrderedItems] = useState<Array<TSortableItem>>([])
  const [filterValue, setFilterValue] = useState('')
  const [dropdownVisible, setDropdownVisible] = useState(false)

  useEffect(() => {
    if (!isEqual(items, orderedItems)) {
      setOrderedItems(items)
    }
  }, [items, orderedItems])

  const handleVisibleChange = (flag: boolean) => {
    setDropdownVisible(flag)
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) => {
    updateItemOrder(oldIndex, newIndex)
  }

  const handleSearch = (value: React.FormEvent<HTMLInputElement>) => {
    const newValue = value.currentTarget.value
    setFilterValue(newValue)
  }
  const filteredItems = useMemo(() => {
    if (filterValue) {
      return orderedItems.filter(item =>
        item.title.toLowerCase().includes(filterValue.toLowerCase())
      )
    }
    return undefined
  }, [orderedItems, filterValue])

  const containerClass = classNames('table-columns-customizer__container', {
    'table-columns-customizer__container--filtered': filteredItems,
  })

  const buttonClass = classNames('table-columns-customizer__trigger-button', {
    'table-columns-customizer__trigger-button--active': dropdownVisible,
  })

  const noFilteredDataForThisQuery = filteredItems && !filteredItems.length
  const blockUnChecking =
    orderedItems.filter(item => item.isChecked).length <= MINIMUM_ACTIVE_COLUMNS

  const menuItems: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <div className={containerClass}>
          <div className="table-columns-customizer__input-container">
            <Input
              onChange={value => handleSearch(value)}
              className="table-columns-customizer__input"
              prefix={
                <SearchOutlined className="table-columns-customizer__search-icon" />
              }
              allowClear
            />
          </div>
          {noFilteredDataForThisQuery ? (
            <div className="table-columns-customizer__no-data">
              No items match this query
            </div>
          ) : (
            <div className="table-columns-customizer__body">
              <SortableList
                items={filteredItems ? filteredItems : orderedItems}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                handleActiveColumns={handleActiveColumns}
                blockUnChecking={blockUnChecking}
                helperClass="table-columns-customizer__sortable-items"
              />
            </div>
          )}
        </div>
      ),
    },
  ]

  return (
    <Dropdown
      menu={{items: menuItems}}
      trigger={['click']}
      open={!previewMode && dropdownVisible}
      onOpenChange={handleVisibleChange}
      getPopupContainer={e => e.parentNode as HTMLElement}
    >
      <Button
        icon={<EyeOutlined />}
        title={translate('view_columns')}
        className={buttonClass}
      />
    </Dropdown>
  )
}
