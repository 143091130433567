import React, {useState} from 'react'

import {Badge, Collapse, MenuProps} from 'antd'
import {CheckOutlined} from '@ant-design/icons'
import classNames from 'classnames'
import {useSelector} from 'react-redux'

import {TKey, translate} from '@/services/i18n'
import {Drawer} from '@/components/drawer'
import {Button} from '@/components/button'
import {Dropdown} from '@/components/dropdown'
import {selectWindowWidth} from '@/redux/reducers/generalSelector'
import {Icon, IconType} from '@/components/icon'
import {generateTitle} from '@/utils/table/generate-title'
import {parseToUpperCase} from '@/utils/parse-to-uppercase'
import useIsMobile from '@/hooks/use-is-mobile'
import {TGeneratorFilters, TQuickFilter} from '@/components/filter/typedef'

import './filter.scss'

const {Panel} = Collapse

type TProps = {
  generatedFilters: TGeneratorFilters
  resetFilters?: () => void
  resetQuickFilter?: () => void
  quickFilters?: Array<TQuickFilter>
  onSearch?: (key: string) => void
  onReset?: (key: string) => void
  previewMode?: boolean
  asset?: string
}

export const Filter: React.FC<TProps> = props => {
  const {
    generatedFilters = [],
    resetFilters,
    resetQuickFilter,
    quickFilters = [],
    onSearch,
    onReset,
    previewMode,
    asset,
  } = props
  const [isDrawerShown, setIsDrawerShown] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const windowWidth = useSelector(selectWindowWidth)

  const isMobile = useIsMobile()

  const appliedAdvancedFilters = generatedFilters.filter(item => item.isApplied)
    .length

  const activeQuickFilter =
    quickFilters.find(({isActive}) => isActive)?.title || ''

  const activeQuickFilterAll = !!quickFilters[0]?.isActive

  const toggleDrawer = () => setIsDrawerShown(!isDrawerShown)

  const closeDrawer = () => setIsDrawerShown(false)

  const handleVisibleChange = (flag: boolean) => setDropdownVisible(flag)

  const resetAllFilters = () => {
    if (appliedAdvancedFilters && resetFilters) {
      resetFilters()
    }
  }

  const handleResetQuickFilter = (e: React.MouseEvent<HTMLElement>) => {
    if (!activeQuickFilterAll && resetQuickFilter) {
      e.stopPropagation()
      resetQuickFilter()
    }
  }

  const quickFiltersList = quickFilters.map((filter: TQuickFilter) => {
    const {title, isActive, iconAfter, onClick} = filter

    const handleClick = () => {
      onClick()
      isMobile ? toggleDrawer() : setDropdownVisible(false)
    }
    const itemClass = classNames('table-advanced-filter__item', {
      'table-advanced-filter__item--active': isActive,
    })
    return (
      <div className={itemClass} key={title} onClick={handleClick}>
        <div className="flex">
          <span className="table-advanced-filter__name">{title}</span>
          {iconAfter}
        </div>
        {isActive && <CheckOutlined />}
      </div>
    )
  })

  const quickFilterButtonInner = (
    <div className="table-quick-filter">
      <Button
        title={
          <>
            {translate('quick_filter')}&#58;
            <span className="table-quick-filter__applied">
              {activeQuickFilter.toUpperCase()}
            </span>
          </>
        }
        icon={
          <Icon type={IconType.FILTER_STRIPES} size={12} className="pb-4" />
        }
        {...(isMobile && {onClick: toggleDrawer})}
      />
      {resetQuickFilter && !activeQuickFilterAll && (
        <button
          className="table-quick-filter__cross"
          onClick={handleResetQuickFilter}
        >
          <Icon type={IconType.CLOSE} size={10} />
        </button>
      )}
    </div>
  )

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <div className="table-advanced-filter__container">
          <div className="table-advanced-filter__menu-header capitalize--first">
            {translate('filter_by')}
          </div>
          {quickFiltersList}
        </div>
      ),
    },
  ]

  const getQuickFilterButton = () => {
    return (
      <Dropdown
        menu={{items}}
        trigger={['click']}
        open={dropdownVisible}
        onOpenChange={handleVisibleChange}
        getPopupContainer={e => e.parentNode as HTMLElement}
      >
        {quickFilterButtonInner}
      </Dropdown>
    )
  }

  return (
    <>
      {isMobile
        ? quickFilters.length > 0 && quickFilterButtonInner
        : quickFilters.length > 0 && getQuickFilterButton()}
      {!isMobile && generatedFilters.length > 0 && (
        <Badge
          count={appliedAdvancedFilters}
          showZero={false}
          size="small"
          className="table-advanced-filter__badge"
          offset={[10, 5]}
        >
          <Button
            title={
              quickFilters.length > 0
                ? translate('advanced_filters')
                : translate('filters')
            }
            icon={
              <Icon type={IconType.FILTER_STRIPES} size={12} className="pb-4" />
            }
            onClick={() => {
              toggleDrawer()
              handleVisibleChange(false)
            }}
          />
        </Badge>
      )}
      {!!appliedAdvancedFilters && (
        <Button
          title={translate('reset_all')}
          type="text"
          className="table-advanced-filter__reset-button--sm ml-8"
          onClick={resetAllFilters}
        />
      )}
      <Drawer
        title={
          <span className="capitalize--first">
            {quickFilters.length > 0
              ? translate('advanced_filters')
              : translate('filters')}
          </span>
        }
        className={`table-advanced-filter table-advanced-filter--${asset}`}
        placement={isMobile ? 'bottom' : 'right'}
        onClose={closeDrawer}
        open={!previewMode && isDrawerShown}
        closable={true}
        destroyOnClose={true}
        {...(isMobile && {height: '60%'})}
        width={isMobile ? windowWidth : 300}
        getContainer={() =>
          document.getElementsByClassName('app-shell')[0] as HTMLElement
        }
      >
        {!isMobile && !!appliedAdvancedFilters && (
          <Button
            title={translate('reset_all')}
            type="primary"
            className="table-advanced-filter__reset-button"
            onClick={resetAllFilters}
          />
        )}
        {isMobile ? (
          quickFiltersList
        ) : (
          <Collapse accordion>
            {generatedFilters.map((item, i) => {
              const panelClass = classNames({
                'table-advanced-filter__panel--active': item.isApplied,
              })
              return (
                <Panel
                  header={
                    translate(item.title as TKey).includes('_')
                      ? generateTitle(item.title)
                      : parseToUpperCase(translate(item.title as TKey))
                  }
                  key={i}
                  className={panelClass}
                >
                  <>
                    {item.filter}
                    {
                      <div className="mt-10">
                        <Button
                          title={translate('search')}
                          type="primary"
                          onClick={() => onSearch && onSearch(item.title)}
                          className="mr-5"
                        />
                        <Button
                          title={translate('reset')}
                          onClick={() => onReset && onReset(item.title)}
                        />
                      </div>
                    }
                  </>
                </Panel>
              )
            })}
          </Collapse>
        )}
      </Drawer>
    </>
  )
}
