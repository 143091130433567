import * as ActionTypes from '../actionTypes'

export const setUserInvitOrgIdGrant = ({org_id, grant}) => {
  return {
    type: ActionTypes.SET_INVIT_ORG_ID_GRANT,
    payload: {org_id, grant},
  }
}

export const setChannelInvitOrgIdGrant = ({channel_invite, grant}) => {
  return {
    type: ActionTypes.SET_CHANNEL_INVIT_ORG_ID_GRANT,
    payload: {channel_invite, grant},
  }
}

export const postGrantRequest = grant => {
  return {
    type: ActionTypes.SET_POST_GRANT_REQUESTED,
    payload: grant,
  }
}

export const postGrantSuccess = message => {
  return {
    type: ActionTypes.SET_POST_GRANT_SUCCEEDED,
    payload: message,
  }
}

export const postGrantFailed = error => {
  return {
    type: ActionTypes.SET_POST_GRANT_FAILED,
    payload: error,
  }
}

export const refreshOrgTokenReq = () => {
  return {
    type: ActionTypes.REFRESH_INVIT_ORG_TKN_REQUESTED,
  }
}

export const postIdentityAction = () => {
  return {
    type: ActionTypes.POST_IDENTITY_REQUESTED,
  }
}

export const postIdentityActionSuccess = () => {
  return {
    type: ActionTypes.POST_IDENTITY_SUCCEEDED,
  }
}

export const postIdentityActionFailed = error => {
  return {
    type: ActionTypes.POST_IDENTITY_FAILED,
    payload: error,
  }
}

export const postChannelInvitOrgIdGrant = grant => {
  return {
    type: ActionTypes.POST_INVIT_CHANNEL_ID_GRANT,
    payload: grant,
  }
}

export const getAccountsRequested = () => {
  return {
    type: ActionTypes.GET_ACCOUNTS_REQUESTED,
  }
}

export const getAccountSuccess = accountID => {
  return {
    type: ActionTypes.GET_ACCOUNT_SUCCEEDED,
    payload: accountID,
  }
}

export const getAccountsSuccess = accounts => {
  return {
    type: ActionTypes.GET_ACCOUNTS_SUCCEEDED,
    payload: accounts,
  }
}

export const getAccountsFailed = error => {
  return {
    type: ActionTypes.GET_ACCOUNTS_FAILED,
    payload: error,
  }
}

export const getOrgAccountsRequested = id => {
  return {
    type: ActionTypes.GET_ORG_ACCOUNTS_REQUESTED,
    payload: id,
  }
}

export const getOrgAccountsSuccess = accounts => {
  return {
    type: ActionTypes.GET_ORG_ACCOUNTS_SUCCEEDED,
    payload: accounts,
  }
}

export const getOrgAccountsFailed = error => {
  return {
    type: ActionTypes.GET_ORG_ACCOUNTS_FAILED,
    payload: error,
  }
}

export const setChannelStep = step => {
  return {
    type: ActionTypes.SET_CHANNEL_STEP,
    payload: step,
  }
}

export const requestAcceptInvite = org_id => {
  return {
    type: ActionTypes.ACCEPT_INVITE_REQUESTED,
    payload: org_id,
  }
}

export const acceptInviteSuccess = ({infoMessage, redirectUrl}) => {
  return {
    type: ActionTypes.ACCEPT_INVITE_SUCCEEDED,
    payload: {infoMessage, redirectUrl},
  }
}

export const setInviteModalMessage = message => {
  return {
    type: ActionTypes.SET_INVITE_MODAL_MESSAGE,
    payload: message,
  }
}

export const invitationAlreadyAccepted = message => {
  return {
    type: ActionTypes.INVITATION_ALREADY_ACCEPTED,
    payload: message,
  }
}

export const acceptInviteFailed = ({error, infoMessage, redirectUrl}) => {
  return {
    type: ActionTypes.ACCEPT_INVITE_FAILED,
    payload: {error, infoMessage, redirectUrl},
  }
}

export const inviteSetRedirect = url => {
  return {
    type: ActionTypes.INVITATION_SET_REDIRECT,
    payload: url,
  }
}

export const postOrganizationRequested = id => {
  return {
    type: ActionTypes.POST_ORGANIZATION_REQUESTED,
    payload: id,
  }
}

export const postOrganizationSuccess = () => {
  return {
    type: ActionTypes.POST_ORGANIZATION_SUCCEEDED,
  }
}

export const postOrganizationFailed = error => {
  return {
    type: ActionTypes.POST_ORGANIZATION_FAILED,
    payload: error,
  }
}

export const resetOrganizationStatus = () => {
  return {
    type: ActionTypes.POST_ORGANIZATION_RESET,
  }
}
