import {transformPrice} from './transform-price'

export const getAccountBalanceAmount = (
  transactionAmount: number,
  storageBalance?: string,
  rdxBalance = 0
) => {
  const balanceValue = storageBalance
    ? parseInt(storageBalance) + transactionAmount
    : rdxBalance
  return transformPrice(balanceValue)
}
