import React from 'react'

import {Button} from '@/components/button'
import {translate} from '@/services/i18n'
import {Footer} from '@/components/layout'

import './drawer-form-submit-btns.scss'

type TProps = {
  loadingSubmit: boolean
  handleCancel: () => void
}

export const DrawerFormSubmitBtns = ({loadingSubmit, handleCancel}: TProps) => (
  <Footer className="submit-form-btns">
    <Button
      title={translate('save')}
      type="primary"
      htmlType="submit"
      disabled={loadingSubmit}
      size="large"
      className="mr-5"
    />
    <Button title={translate('cancel')} onClick={handleCancel} size="large" />
  </Footer>
)
