import React, {useEffect, useState} from 'react'

import {Col, DatePicker, Form, FormInstance, Input, Row, Select} from 'antd'
import {useHistory} from 'react-router'
import moment from 'moment'
import {useSelector} from 'react-redux'

import {GoBackButton} from '@/components/go-back-button/go-back-button'
import {translate} from '@/services/i18n'
import itsmRoutes from '@/modules/ITSM/routes/itsm-routes'
import {twoColumns} from '@/utils/table/constants/grid-columns'
import FormItemGeneric from '@/components/form/form-item-custom-generic'
import {DATE_FORMAT} from '@/constants'
import {useSetEditorState} from '@/hooks/useSetEditorState'
import {usePrevious} from '@/hooks/usePrevious'
import {setAutocompleteNone} from '@/utils/set-autocomplete-none'
import {TAppState} from '@/redux/store'

import {emailValidator} from '@/utils/form-validators/email-validator'
import {scrollToField} from '@/utils/scroll-to-field'
import CallerSelect from '../../components/form-components/caller-select'
import CustomerProductSelect from '../../components/form-components/customer-product-select'
import DescriptionEditor from '../../components/description-editor/description-editor'
import {ItsmAssets, TCustomerProduct, TUser} from '../../typedef'
import {selectItsmTableQuickFilter} from '../../store/list-table/selectors'

import {TEvent} from './typedf'
import {EventsQuickFilters} from './constants'

import './event-form.scss'

const Option = Select.Option
const TextArea = Input.TextArea

type TProps = {
  form: FormInstance
  handleSubmit?: (values: TEvent) => void
  record?: TEvent
  callerInit?: TUser
  customerProdInit?: TCustomerProduct
  children: React.ReactNode
  headerBtns?: React.ReactNode
  checkValues?: (values: TEvent) => void
  customerProdRequired?: boolean
}

const EventForm = ({
  form,
  handleSubmit,
  record,
  callerInit,
  customerProdInit,
  children,
  headerBtns,
  checkValues,
  customerProdRequired,
}: TProps) => {
  const history = useHistory()

  const [, setRefresh] = useState(0)

  const quickFilterActive = useSelector((state: TAppState) =>
    selectItsmTableQuickFilter(state, ItsmAssets.Events)
  ) as EventsQuickFilters

  const {
    editorState,
    onEditorStateChange,
    editorText,
    setText,
  } = useSetEditorState(record?.description)

  const prevEditorText = usePrevious(editorText)

  const goBack = () => {
    history.push(
      itsmRoutes.serviceManagementEventsPage.path(
        quickFilterActive || EventsQuickFilters.NotApproved,
        '1'
      )
    )
  }

  useEffect(() => {
    if (prevEditorText !== editorText && callerInit) {
      if (checkValues) {
        const values = form.getFieldsValue()
        values['description'] = editorText
        checkValues(values)
      }
    }
  }, [callerInit, checkValues, editorText, form, prevEditorText])

  return (
    <div className="event-form">
      <Row e2e-test={'event-form'}>
        <div className="event-form__header">
          <GoBackButton onClick={goBack} />

          {headerBtns}
        </div>
      </Row>
      <Form
        validateMessages={{
          // eslint-disable-next-line
          required: '${label} is required',
        }}
        onFinish={values => {
          values['description'] = editorText
          handleSubmit && handleSubmit(values)
        }}
        form={form}
        layout="vertical"
        onFinishFailed={errorFields => scrollToField({errorFields, form})}
        onValuesChange={(_: any, values: TEvent) => {
          values['description'] = editorText
          checkValues && checkValues(values)
        }}
      >
        <div className="event-form__ticket-details">
          <Row gutter={24}>
            <Col {...twoColumns}>
              <CallerSelect
                callerValue={form.getFieldValue('caller')}
                setRefresh={() => setRefresh(Math.random())}
                callerInit={callerInit}
              />
            </Col>

            <Col {...twoColumns}>
              <FormItemGeneric<TEvent>
                name="email"
                label={translate('email')}
                rules={[{required: true}, {validator: emailValidator}]}
                initialValue={record?.email}
                e2e-test="email"
              >
                <Input autoComplete="off" onFocus={setAutocompleteNone} />
              </FormItemGeneric>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col {...twoColumns}>
              <FormItemGeneric<TEvent>
                name="contact_type"
                label={translate('source')}
                initialValue={record?.contact}
              >
                <Select getPopupContainer={e => e.parentNode} e2e-test="source">
                  <Option value="unknown" e2e-test="unknown">
                    {translate('unknown')}
                  </Option>
                  <Option value="portal" e2e-test="portal">
                    {translate('portal')}
                  </Option>
                  <Option value="email" e2e-test="email">
                    {translate('email')}
                  </Option>
                  <Option value="email_worker" e2e-test="email_worker">
                    {translate('email_worker')}
                  </Option>
                  <Option value="phone" e2e-test="telephone">
                    {translate('telephone')}
                  </Option>
                  <Option value="customer" e2e-test="customer">
                    {translate('customer')}
                  </Option>
                </Select>
              </FormItemGeneric>
            </Col>
            <Col {...twoColumns}>
              <CustomerProductSelect
                customerProdValue={form.getFieldValue('customer_product')}
                resetSupplierProd={() =>
                  form.setFieldsValue({supplier_product: undefined})
                }
                setRefresh={() => setRefresh(Math.random())}
                customerProdInit={customerProdInit}
                isRequired={customerProdRequired}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col {...twoColumns}>
              <FormItemGeneric<TEvent>
                name="requested_type"
                label={translate('request_type')}
                initialValue={record?.requestedType}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  className="event-form__requested-type"
                  e2e-test={'requestedType'}
                >
                  <Option value="incident" e2e-test="incident">
                    {translate('incident')}
                  </Option>
                  <Option value="k_request" e2e-test="requests">
                    {translate('requests')}
                  </Option>
                </Select>
              </FormItemGeneric>
            </Col>
            <Col {...twoColumns}>
              <FormItemGeneric<TEvent>
                name="requested_eta"
                label={translate('expected_start')}
                initialValue={
                  record?.requested_eta ? moment(record.requested_eta) : null
                }
              >
                <DatePicker
                  placeholder={translate('empty')}
                  showTime
                  format={DATE_FORMAT}
                />
              </FormItemGeneric>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItemGeneric<TEvent>
                name="short_description"
                initialValue={record?.short_description}
                e2e-test={'short-description'}
                rules={[
                  {
                    required: true,
                  },
                ]}
                label={translate('title')}
              >
                <TextArea rows={5} />
              </FormItemGeneric>
            </Col>
          </Row>
          <DescriptionEditor
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            setText={setText}
            withFileAttaching={false}
          />
          {children}
        </div>
      </Form>
    </div>
  )
}

export default EventForm
