import {parseCountryMap} from '@/modules/ITSM/components/createNewLocation/countries'

export const servicesReverseMap: Record<string, Record<string, string>> = {
  numberOfUsers: {
    'one-oneHundred': '1-100',
    'hundred-plus': '100+',
    'thousand-plus': '1000+',
  },
  supportType: {
    'remote-support': 'remote',
    'onsite-support': 'onsite',
    'both-support': 'both',
  },
  support: {
    'support-8-5': '8x5',
    'support-24-5': '24x5',
    'support-24-7': '24x7',
  },
  respondTime: {
    '2-5-days': '2-5 days',
    '24-hours': '24 hours',
    '8-hours': '8 hours',
    asap: 'asap',
  },
  country: parseCountryMap,
}
