import { translate } from '@/services/i18n';
import styles from '@/styles/_variables.module.scss';

const {
  closed,
  priorityCyan,
  priorityGreen,
  priorityOrange,
  priorityRed,
  priorityYellow,
} = styles;

type TSettings = {
  behavior: ScrollBehavior;
  block: ScrollLogicalPosition;
};

type TPriorityOption = {
  value: string;
  name: string;
};

export const scrollSettings: TSettings = { behavior: 'smooth', block: 'start' };

export const PRIORITY_OPTIONS: Record<string, string> = {
  '1': translate('critical'),
  '2': translate('high'),
  '3': translate('moderate'),
  '4': translate('low'),
};

export const priorityOptions: TPriorityOption[] = [
  { value: '1', name: translate('critical') },
  { value: '2', name: translate('high') },
  { value: '3', name: translate('moderate') },
  { value: '4', name: translate('low') },
];

export const colorsIncidentsByState: Record<string, string> = {
  NEW: priorityCyan,
  'IN PROGRESS': priorityGreen,
  'ON HOLD': priorityYellow,
  RESOLVED: priorityOrange,
  CLOSED: closed,
  CANCELLED: priorityRed,
};

export const labelsIncidentsByState: Record<string, string> = {
  NEW: translate('open'),
  'IN PROGRESS': translate('on_hold'),
  'ON HOLD': translate('in_progress'),
  RESOLVED: translate('on_hold'),
  CLOSED: translate('resolved'),
  CANCELLED: translate('closed'),
};

export const colors: Record<string, string> = {
  '1': priorityRed,
  '2': priorityOrange,
  '3': priorityYellow,
  '4': priorityGreen,
  '5': priorityCyan,
};
