import {AssigneeStates, IncidentState, IncTabs} from '../constants'

export const getActiveTab = ({
  state_id,
  assignee_state,
}: {
  state_id: IncidentState
  assignee_state: AssigneeStates
}) => {
  if (
    state_id === IncidentState.Open ||
    state_id === IncidentState.Pre_on_hold
  ) {
    if (assignee_state === AssigneeStates.Unset) {
      return IncTabs.OverviewTab
    } else return assignee_state
  }

  if (state_id === IncidentState.In_progress) return IncTabs.InProgressTab

  if (state_id === IncidentState.On_hold) return IncTabs.OnHoldTab

  if (state_id === IncidentState.Resolved || state_id === IncidentState.Closed)
    return IncTabs.ResolvedTab

  if (state_id === IncidentState.Cancelled) return IncTabs.OverviewTab
}
