import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useEffect,
  useReducer,
} from 'react'

import {useAuth} from 'react-oidc-context'

import {normalizeError} from '@/sites/account-default/utils/normalize-error'
import {handleAPIErrorBySentry} from '@/services/sentry'
import {normalizeSentryUserInfo} from '@/sites/account-default/utils/normalize-sentry-user-info'

import {TActionMap} from '@/typedef'
import {
  AccountCreateStep,
  AccountDefaultModal,
} from '@/sites/account-default/typedef'
import {ISubscriptionDatum} from '@/modules/typedef'

enum ContextType {
  SET_CREATE_ACCOUNT_PROGRESS_STATUS = 'SET_CREATE_ACCOUNT_PROGRESS_STATUS',
  SET_BILLING_SUBSCRIPTIONS = 'SET_BILLING_SUBSCRIPTIONS',
  SET_TOKEN = 'SET_TOKEN',
  SET_ACCOUNT_ID = 'SET_ACCOUNT_ID',
  SET_ORG_ID = 'SET_ORG_ID',
  SET_ORG_NAME = 'SET_ORG_NAME',
  SET_ACCOUNT_DEFAULT_STEP = 'SET_ACCOUNT_DEFAULT_STEP',
  SET_ERROR = 'SET_ERROR',
}

export type TInitialState = {
  progressStatus: number | null
  billingSubscriptions: Array<ISubscriptionDatum>
  accessToken: string
  accountId: string
  orgId: string
  orgName: string
  step: null | AccountCreateStep
  isLoading: boolean
  error: unknown
  errorType: null | AccountDefaultModal
  errorTitle: string
  errorDescription: string
  loadingText: string
}

export const accountDefaultInitialState: TInitialState = {
  progressStatus: null,
  billingSubscriptions: [],
  accessToken: '',
  accountId: '',
  orgId: '',
  orgName: '',
  step: null,
  isLoading: false,
  error: null,
  errorType: null,
  errorTitle: '',
  errorDescription: '',
  loadingText: '',
}

export const setAccountDefaultProgressStatus = (progress: number) =>
  ({
    type: ContextType.SET_CREATE_ACCOUNT_PROGRESS_STATUS,
    payload: progress,
  } as const)

export const setAccountDefaultSubscriptions = (
  billingSubscriptions: Array<ISubscriptionDatum>
) =>
  ({
    type: ContextType.SET_BILLING_SUBSCRIPTIONS,
    payload: billingSubscriptions,
  } as const)

export const setAccountDefaultToken = (token: string) =>
  ({
    type: ContextType.SET_TOKEN,
    payload: token,
  } as const)

export const setAccountDefaultAccId = (accId: string) =>
  ({
    type: ContextType.SET_ACCOUNT_ID,
    payload: accId,
  } as const)

export const setAccountDefaultOrgId = (orgId: string) =>
  ({
    type: ContextType.SET_ORG_ID,
    payload: orgId,
  } as const)

export const setAccountDefaultOrgName = (orgName: string) =>
  ({
    type: ContextType.SET_ORG_NAME,
    payload: orgName,
  } as const)

export const setAccountDefaultStep = (step: AccountCreateStep) =>
  ({
    type: ContextType.SET_ACCOUNT_DEFAULT_STEP,
    payload: step,
  } as const)

export const setAccountDefaultError = (error: unknown) =>
  ({
    type: ContextType.SET_ERROR,
    payload: error,
  } as const)

type TAccountDefaultPayload = {
  [ContextType.SET_CREATE_ACCOUNT_PROGRESS_STATUS]: number
  [ContextType.SET_BILLING_SUBSCRIPTIONS]: Array<ISubscriptionDatum>
  [ContextType.SET_TOKEN]: string
  [ContextType.SET_ACCOUNT_ID]: string
  [ContextType.SET_ORG_ID]: string
  [ContextType.SET_ORG_NAME]: string
  [ContextType.SET_ACCOUNT_DEFAULT_STEP]: AccountCreateStep
  [ContextType.SET_ERROR]: unknown
}

export type TAccountDefaultActionTypes = TActionMap<
  TAccountDefaultPayload
>[keyof TActionMap<TAccountDefaultPayload>]

export const accountDefaultReducer = (
  state = accountDefaultInitialState,
  action: TAccountDefaultActionTypes
) => {
  switch (action.type) {
    case ContextType.SET_CREATE_ACCOUNT_PROGRESS_STATUS:
      return {
        ...state,
        isLoading: true,
        progressStatus: action.payload,
      }
    case ContextType.SET_BILLING_SUBSCRIPTIONS:
      return {...state, billingSubscriptions: action.payload}
    case ContextType.SET_TOKEN:
      return {...state, accessToken: action.payload}
    case ContextType.SET_ACCOUNT_ID:
      return {...state, accountId: action.payload}
    case ContextType.SET_ORG_ID:
      return {...state, orgId: action.payload}
    case ContextType.SET_ORG_NAME:
      return {...state, orgName: action.payload}
    case ContextType.SET_ACCOUNT_DEFAULT_STEP:
      return {
        ...state,
        step: action.payload,
        progressStatus: null,
        isLoading: false,
        error: null,
        errorType: null,
        errorTitle: '',
        errorDescription: '',
        loadingText: '',
      }
    case ContextType.SET_ERROR:
      return {
        ...state,
        ...normalizeError(action.payload, state.orgName),
        isLoading: false,
        progressStatus: null,
      }
    default:
      return state
  }
}

export const AccountDefaultContext = createContext<{
  state: TInitialState
  dispatch: Dispatch<TAccountDefaultActionTypes>
}>({
  state: accountDefaultInitialState,
  dispatch: () => null,
})

export const AccountDefaultProvider: FC<{children: ReactNode}> = ({
  children,
}) => {
  const auth = useAuth()

  const [state, dispatch] = useReducer(
    accountDefaultReducer,
    accountDefaultInitialState
  )

  useEffect(() => {
    if (state.errorType === AccountDefaultModal.GENERAL_ERROR) {
      handleAPIErrorBySentry(
        state.error,
        normalizeSentryUserInfo(state, auth.user)
      )
    }
  }, [state.error])

  return (
    <AccountDefaultContext.Provider value={{state, dispatch}}>
      {children}
    </AccountDefaultContext.Provider>
  )
}
