export const kFormatter = (num: number, fixed?: number) => {
  if (Math.abs(num) >= 1000000) {
    return (num / 1000000).toFixed(fixed).replace(/\.0$/, '') + ' M'
  }
  if (Math.abs(num) >= 1000) {
    return (num / 1000).toFixed(fixed).replace(/\.0$/, '') + ' K'
  }

  return num.toFixed()
}
