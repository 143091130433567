import * as React from 'react'

import DatePicker from '../date-picker/date-picker'

export type TTimePickerProps = any

const TimePicker = React.forwardRef<any, TTimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
))

TimePicker.displayName = 'TimePicker'

export default TimePicker
