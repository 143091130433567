import React, {useState} from 'react'

import {InfoCircleOutlined} from '@ant-design/icons'
import {Input, Popover} from 'antd'
import {useSelector} from 'react-redux'

import {translate} from '@/services/i18n'
import {fetchUsers} from '@/modules/ITSM/api/userRequests'
import {selectUserName} from '@/redux/user/userSelector'
import {TUser} from '@/modules/ITSM/typedef'
import FormItemCustom from '@/components/form/form-item-custom'

import UserDetails from '../user-details/user-details'

type TProps = {
  createdByInit: Partial<TUser> | undefined
  requested_by?: string
}

const CreatedByInput = ({createdByInit, requested_by}: TProps) => {
  const [createdByVisible, setCreatedByVisible] = useState<
    Record<string, unknown> | undefined
  >()

  const userName = useSelector(selectUserName)
  const getCreatedBy = async () => {
    if (createdByInit) {
      const {
        body: {result},
      } = await fetchUsers({
        selector: {uuid: createdByInit.uuid},
      })

      if (result) setCreatedByVisible(result[0])
    }
  }

  const handleDisplayInfo = () => {
    if (createdByInit) {
      if (createdByInit?.name) setCreatedByVisible(createdByInit)
      else getCreatedBy()
    }
  }

  return (
    <div id="created-by-input">
      <Popover
        open={!!createdByVisible}
        content={<UserDetails userDetails={createdByVisible} />}
        trigger="click"
        placement="bottom"
        getPopupContainer={() =>
          document.getElementById('created-by-input') as HTMLElement
        }
        onOpenChange={open => !open && setCreatedByVisible(undefined)}
      >
        <FormItemCustom
          initialValue={createdByInit ? createdByInit.full_name : userName}
          name="created_by"
          label={
            <span>
              {requested_by || translate('created_by')}
              <InfoCircleOutlined
                onClick={handleDisplayInfo}
                type="info-circle"
                className="ml-5"
              />
            </span>
          }
        >
          <Input readOnly={!!createdByInit} />
        </FormItemCustom>
      </Popover>
    </div>
  )
}

export default CreatedByInput
