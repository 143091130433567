import React from 'react'

import {Col, Input, Row, Switch, Tooltip} from 'antd'
import {CheckOutlined, InfoCircleOutlined} from '@ant-design/icons'

import {translate} from '@/services/i18n'
import FormItemGeneric from '@/components/form/form-item-custom-generic'

import {TTimelogForm} from '../../typedef'

const TextArea = Input.TextArea

export const CheckOutForm = () => {
  return (
    <div>
      <Row>
        <Col>
          <FormItemGeneric<TTimelogForm>
            name="cmdb_updated"
            label={translate('cmdb_updated')}
            valuePropName={'checked'}
          >
            <Switch checkedChildren={<CheckOutlined />} />
          </FormItemGeneric>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItemGeneric<TTimelogForm>
            name="ignore_extra_pricing_policy"
            label={
              <span>
                {translate('OOHrates')}
                <Tooltip placement="right" title={translate('OOHratesInfo')}>
                  <InfoCircleOutlined className="ml-5" />
                </Tooltip>
              </span>
            }
            valuePropName={'checked'}
          >
            <Switch checkedChildren={<CheckOutlined />} />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row>
        <Col className="full-width">
          <FormItemGeneric<TTimelogForm>
            rules={[
              {
                required: true,
              },
            ]}
            name="actions_performed_onsite"
            label={translate('actions_performed_onsite')}
            required
          >
            <TextArea rows={5} />
          </FormItemGeneric>
        </Col>
      </Row>
      <Row>
        <Col className="full-width">
          <FormItemGeneric<TTimelogForm>
            rules={[
              {
                required: true,
              },
            ]}
            name="unexpected_issues_on_field_engineer_visit"
            label={translate('unexpected_issues_on_field_engineer_visit')}
            required
          >
            <TextArea rows={5} />
          </FormItemGeneric>
        </Col>
      </Row>
    </div>
  )
}
