import request from 'superagent'

import {myCmdbUserIdApi} from '@/modules/ITSM/api/generate-itsm-api-url'
import {getHeadersWithGRPC} from '@/services/api'
import {ISuperAgentResSingle} from '@/api/response-typedf'
import {TIdentity} from '@/modules/ITSM/typedef'

export const fetchUsersIdentity = async (
  user_id: string
): Promise<ISuperAgentResSingle<TIdentity>> => {
  return await request
    .options(myCmdbUserIdApi().get)
    .send({
      user_id,
    })
    .set(getHeadersWithGRPC())
}
